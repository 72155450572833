import {
  EntityKindFilter,
  useEntityList,
} from '@backstage/plugin-catalog-react';
import { ToolEntity } from '@lego/plugin-baseplate-common';
import {
  Icon,
  SideMenu,
  Spinner,
  baseplateStyles,
} from '@lego/plugin-baseplate-core-components';
import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router';
import { GlobalSearchField } from '../../../search/GlobalSearchField';
import { toolCategorySlugify } from '../utils/tool-category-slugify';
import {
  ToolCategoryType,
  groupedToolCategories,
  toolCategories,
} from '../utils/toolCategories';
import { ToolsCategoryPage } from './ToolsCategoryPage';
import { ToolsHomePage } from './ToolsHomePage';

const sidenavWidth = 300;

const useStyles = baseplateStyles(theme => ({
  pageWrapper: {
    marginLeft: sidenavWidth,
  },
  sideNavigation: {
    position: 'fixed',
    top: theme.primitive.dimensions.nav.height,
    left: 0,
    height: `calc(100vh - ${theme.primitive.dimensions.nav.height})`,
    width: sidenavWidth,
    paddingLeft: theme.primitive.dimensions.page.horizontalPadding,
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      // '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.semantic.background.neutral[4],
    },
  },
}));

export const ToolsEntitiesList = () => {
  const classes = useStyles();
  const { entities, updateFilters, loading } = useEntityList();

  const popularTools = React.useMemo(() => {
    const popular = [
      'jira',
      'confluence',
      'github',
      'miro',
      'visual-studio-code-free',
    ];
    return entities.filter(entity => popular.includes(entity.metadata.name));
  }, [entities]);

  const dataPlatformTools = React.useMemo(() => {
    const dataPlatformCategory = 'Data Platform';
    return entities.filter(entity =>
      (entity as ToolEntity).metadata.categories?.includes(
        dataPlatformCategory,
      ),
    );
  }, [entities]);

  useEffect(() => {
    updateFilters({
      kind: new EntityKindFilter('tool'),
    });
  }, [updateFilters]);

  const { pathname } = useLocation();

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className={classes.pageWrapper}>
      <nav className={classes.sideNavigation}>
        <SideMenu.Wrapper>
          <GlobalSearchField scope="Tool" />
          <br />
          <SideMenu.Item
            label="Home"
            href="/tools"
            active={pathname.endsWith('/tools')}
            icon={<Icon icon="house" />}
          />

          {groupedToolCategories.map(group => (
            <>
              <SideMenu.Section
                key={`group-${group.title}`}
                label={group.title}
              />
              {group.categories.map((category: ToolCategoryType) => (
                <SideMenu.Item
                  key={`nav-item-${category.name}`}
                  label={category.title}
                  icon={category.icon}
                  href={category.name}
                  active={pathname.includes(`/tools/${category.name}`)}
                />
              ))}
            </>
          ))}
        </SideMenu.Wrapper>
      </nav>
      <Routes>
        <Route
          path="/"
          element={
            <ToolsHomePage
              popularTools={popularTools as ToolEntity[]}
              dataPlatformTools={dataPlatformTools as ToolEntity[]}
            />
          }
        />
        {toolCategories.map(category => {
          const tools = entities.filter(entity => {
            let categoryFound = false;
            if (
              !entity.metadata.categories ||
              !Array.isArray(entity.metadata.categories)
            ) {
              return false;
            }
            entity.metadata.categories.forEach(entityCategory => {
              if (
                categoryFound ||
                !entityCategory ||
                typeof entityCategory !== 'string'
              ) {
                return;
              }
              const slugifiedCategory = toolCategorySlugify(
                entityCategory.toString(),
              );
              categoryFound = slugifiedCategory === category.name;
            });
            return categoryFound;
          });

          return (
            <Route
              path={`/${category.name}`}
              key={`page-${category.name}`}
              element={
                <ToolsCategoryPage
                  title={category.title}
                  description={category.description}
                  entities={tools as ToolEntity[]}
                />
              }
            />
          );
        })}
      </Routes>
    </div>
  );
};
