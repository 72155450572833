import { baseplateStyles } from '../../utils';

export const useModalStyles = baseplateStyles(_ => ({
  popupContainer: {
    position: 'fixed',
    bottom: '1.5rem',
    right: '3.5rem',
    zIndex: 1198, // ToastContainer is 1199
    width: '25.5rem',
    maxWidth: 'calc(100% - 4.5rem)',
    boxShadow:
      '0px 1.4px 1.4px -0.7px rgba(0, 0, 0, 0.06), 0px 5.2px 4.8px -2.6px rgba(0, 0, 0, 0.09), 0px 16px 16px -8px rgba(0, 0, 0, 0.15)',
  },
  image: {
    width: '100%',
    marginBottom: '1rem',
    // aspectRatio: 16 / 9, makes too much padding
    objectFit: 'contain',
  },
  button: {
    width: '100%',
  },
  buttonWrapper: {
    width: '100%',
    marginTop: '1rem',
  },
}));
