import React from 'react';
import { useEventsPreviewStyles } from './EventsPreview.style';
import { Typography } from '@material-ui/core';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { Event } from '@lego/plugin-baseplate-learning-and-development-common';
import { mediaPlaceholder } from '../../../assets/images';
import { EventSchedule } from '../../Shared';
import { Spinner } from '@lego/plugin-baseplate-core-components';

type EventsPreviewProps = {
  events: Event[];
  onEventClick: (
    eventId: string,
    participationRole?: string,
    eventStatus?: string,
  ) => void;
  isLoading: boolean;
};

/**
 * Displays a list of events.
 * Each event item in the list is clickable and triggers a callback function.
 *
 * @param events - An array of event objects to be displayed.
 * @param onEventClick - A callback function that is triggered when an event item is clicked.
 * @param isLoading - Flag to display the loader.
 *
 * @returns A list of event items.
 */
export const EventsPreview = ({
  events,
  onEventClick,
  isLoading,
}: EventsPreviewProps) => {
  const classes = useEventsPreviewStyles();

  return (
    <>
      {isLoading && <Spinner align="center" />}

      <List className={classes.events}>
        {events.map((event: any) => {
          return (
            <ListItem
              key={event.id}
              alignItems="flex-start"
              className={classes.eventsItem}
              onClick={() =>
                onEventClick(
                  event.id,
                  event.currentUserParticipation,
                  event.status,
                )
              }
            >
              <ListItemAvatar className={classes.photo}>
                <img
                  alt="media"
                  src={
                    event.bannerPhotoUrl
                      ? event.bannerPhotoUrl
                      : mediaPlaceholder
                  }
                  className={classes.image}
                />
              </ListItemAvatar>

              <ListItemText
                primary={
                  <div className={classes.dateTime}>
                    <EventSchedule data={event} variant="body2" />
                  </div>
                }
                secondary={
                  <Typography
                    title={event.title}
                    variant="body1"
                    color="textPrimary"
                    className={classes.eventTitle}
                  >
                    {event.title}
                  </Typography>
                }
                className={classes.eventDetail}
              />
            </ListItem>
          );
        })}
      </List>
    </>
  );
};
