import {
  Content,
  PageWithHeader,
  ResponseErrorPanel,
} from '@backstage/core-components';
import {
  Callout,
  Spinner,
  Status,
  baseplateStyles,
} from '@lego/plugin-baseplate-core-components';
import { Box, Grid, Typography } from '@material-ui/core';
import { capitalize } from 'lodash';
import React from 'react';
import { EspStatusContextProvider, useEspStatus } from '../../contexts';

const useStyles = baseplateStyles(theme => ({
  container: {
    backgroundColor: theme.palette.background.default,
  },
}));

export const EspStatusPageContent = () => {
  const classes = useStyles();
  const { status, loading, error } = useEspStatus();

  const getHealthColor = (health: string) => {
    switch (health.toLowerCase()) {
      case 'healthy':
        return 'green';
      case 'degraded':
        return 'red';
      case 'unknown':
        return 'neutral';
      default:
        return 'neutral';
    }
  };

  if (loading) {
    return (
      <PageWithHeader themeId="home" title="Event Streaming Platform Status">
        <Content>
          <Spinner />
        </Content>
      </PageWithHeader>
    );
  }

  if (error) {
    return (
      <PageWithHeader themeId="home" title="Event Streaming Platform Status">
        <Content>
          <ResponseErrorPanel error={error} />
        </Content>
      </PageWithHeader>
    );
  }

  if (!status) {
    return (
      <PageWithHeader themeId="home" title="Event Streaming Platform Status">
        <Content>
          <Callout
            title="No data available"
            type="warning"
            body={
              <Typography variant="body1">
                {`No status available for Event Streaming Platform`}
              </Typography>
            }
          />
        </Content>
      </PageWithHeader>
    );
  }

  // Sort status so that 'production' comes first
  const sortedStatus = status.sort((a, b) => {
    if (a.environment === 'prod') return -1;
    if (b.environment === 'prod') return 1;
    return 0;
  });

  return (
    <PageWithHeader themeId="home" title="Event Streaming Platform Status">
      <Content className={classes.container}>
        <Grid container spacing={4}>
          {sortedStatus.map(s => (
            <Grid item xs={12} md={6} key={s.environment}>
              <Typography variant="h4">
                {s.environment === 'prod'
                  ? capitalize('Production')
                  : capitalize(s.environment)}{' '}
                Environment
              </Typography>
              <Grid
                container
                spacing={4}
                direction="column"
                style={{ padding: 0 }}
              >
                <Grid item>
                  <Callout
                    title="Produce uptime over the past year"
                    type={
                      (s.produceUptime ?? 0) >= (s.produceUptimeTarget ?? 0)
                        ? 'success'
                        : 'warning'
                    }
                    body={
                      <Box>
                        <Typography variant="h3" color="inherit">
                          {(s.produceUptime ?? 0) + '%'}
                        </Typography>
                        <Typography variant="body1" color="inherit">
                          {'Target: ' +
                            (s.produceUptimeTarget?.toFixed(3) ?? '0.000') +
                            '%'}
                        </Typography>
                      </Box>
                    }
                  />
                </Grid>
                <Grid item>
                  <Callout
                    title="Consume uptime over the past year"
                    type={
                      s.consumeUptime >= s.consumeUptimeTarget
                        ? 'success'
                        : 'warning'
                    }
                    body={
                      <Box>
                        <Typography variant="h3" color="inherit">
                          {s.consumeUptime + '%'}
                        </Typography>
                        <Typography variant="body1" color="inherit">
                          {'Target: ' + s.consumeUptimeTarget.toFixed(3) + '%'}
                        </Typography>
                      </Box>
                    }
                  />
                </Grid>
              </Grid>
              <Box paddingY={1}>
                <Box paddingY={1}>
                  <Typography variant="subtitle1">
                    {'Current status'}
                  </Typography>
                  <Status
                    status={getHealthColor(s.produceHealth)}
                    label={`Produce - ${s.produceHealth ?? 'Unknown'}`}
                  />
                  <Status
                    status={getHealthColor(s.consumeHealth)}
                    label={`Consume - ${s.consumeHealth ?? 'Unknown'}`}
                  />
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Content>
    </PageWithHeader>
  );
};

export const EspStatusPage = () => {
  return (
    <EspStatusContextProvider>
      <EspStatusPageContent />
    </EspStatusContextProvider>
  );
};
