import { useSearch } from '@backstage/plugin-search-react';
import { useEffect } from 'react';

export const useBackstageSearch = (
  searchTerm: string,
  setCursor: React.Dispatch<React.SetStateAction<number>>,
) => {
  const { result, setTerm, setFilters, setTypes } = useSearch();

  // Update Backstage search term when the search term changes
  // Cannot be done in handleInputChange because it the search term ends up being one character behind
  useEffect(() => {
    setTerm(searchTerm);
    if (searchTerm) {
      setCursor(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  return { result, setTerm, setFilters, setTypes };
};
