import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function MinifigureFaceless5(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M11.93 28.945c-2.41 0-4.36 1.55-5.28 3.28-.91 1.71-1.47 4.35-1.47 5.72h3c.119-2.03 1.201-6.05 3.75-6.05h16.5c2.548 0 3.63 4.02 3.75 6.05h3c0-1.37-.56-4.01-1.47-5.72-.92-1.74-2.78-3.28-5.28-3.28h-16.5ZM29.7 14.036c0-.38-.02-.74-.15-1.11-.27-.77-.92-1.51-1.42-2.1a5.66 5.66 0 0 0-1.78-1.39c-.67-.33-1.38-.5-2.11-.51-.19 0-.35.03-.51.1l-3.1 1.4a11.35 11.35 0 0 1-4.65 1.08c-1.3.02-5.27.07-5.27 3.77v7.14c0 2.19 1.92 4.23 4.23 4.28H25.5c2.3-.09 4.21-2.12 4.21-4.31v-8.35h-.01Zm-3 8.16c0 .81-.69 1.5-1.5 1.5h-10c-.81 0-1.5-.71-1.5-1.51v-7.19c.31-.14.94-.29 2.25-.3 1.94-.03 3.83-.47 5.62-1.31l2.57-1.17c.17-.08.35-.11.52-.08.64.15 1.21.54 1.62 1.11.41.57.42.96.42 1.7v7.25Z" />
      <path d="M36.02 21.816c-2.23-2.61-1.35-6.76-1.32-6.91.48-1.87.48-3.85 0-5.75-.29-.84-.8-1.59-1.47-2.18-.67-.58-1.49-.98-2.37-1.16a5.76 5.76 0 0 0-2.11-.04c-1.78-2.4-5.05-3.72-9.21-3.72a15.9 15.9 0 0 0-10.2 3.59 12.16 12.16 0 0 0-3.18 4.08c-.77 1.58-1.17 3.34-1.16 5.11l.4 4.05c.03.54-.06 1.08-.26 1.58l-.04.11c-.19.48-.38.92-.74 1.24-.34.31-.49.46-.72.71l-.26.29c-.49.69-.54.95-.61 1.44v.09c-.01.55.07.97.26 1.38.43.86 1.07 1.42 1.97 1.73l4.26 1.22c.19.03.38.06.61.03.21-.03.41-.1.6-.22.18-.12.34-.26.46-.44s.2-.37.24-.58c.04-.21.03-.42-.02-.63a1.5 1.5 0 0 0-.75-.98c-.15-.08-.29-.11-.43-.15l-3.25-.92c-.14-.05-.57-.27-.62-.57-.05-.29.25-.61.52-.84.67-.56 1.19-1.28 1.51-2.09.32-.8.45-1.69.36-2.54l-.37-4.04c0-1.26.3-2.52.85-3.65a9.057 9.057 0 0 1 2.42-3.04c2.27-1.85 5.13-2.87 8.08-2.87 2.1-.02 5.82.38 7.09 3.13.08.19.2.35.35.5.15.14.32.25.52.32.19.07.39.11.6.1.21 0 .41-.05.59-.14.53-.23 1.12-.31 1.66-.25a2.135 2.135 0 0 1 1.54 1.41c.24 1.37.2 2.75-.11 4.12-.05.22-1.12 5.36 2.11 9.21.07.12.24.51.16.81-.04.13-.13.24-.26.31-.01 0-1.51.66-3.38 1.08a1.552 1.552 0 0 0-1.19 1.66c.04.37.22.72.5.97s.64.39 1.02.4h.32c1.31-.25 2.61-.63 3.85-1.12 1.06-.38 1.75-.95 2.12-1.73.25-.53.27-1.26.27-1.59a4.26 4.26 0 0 0-1.24-2.52h.03Z" />
    </SvgIcon>
  );
}
