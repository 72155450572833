import { Entity } from '@backstage/catalog-model';
import { useEntity } from '@backstage/plugin-catalog-react';
import LanguageIcon from '@material-ui/icons/Language';
import React from 'react';
import { LinksGridList } from './LinksGridList';
import { ColumnBreakpoints } from './types';

import { IconComponent, useApp } from '@backstage/core-plugin-api';
import { InfoCard } from '@backstage/core-components';
import { EntityLinksEmptyState } from './EntityLinksEmptyState';

type Props = {
  /** @deprecated The entity is now grabbed from context instead */
  entity?: Entity;
  cols?: ColumnBreakpoints | number;
  variant?: 'gridItem';
};

export const EntityLinksCard = ({ cols = undefined, variant }: Props) => {
  const { entity } = useEntity();
  const app = useApp();

  const iconResolver = (key?: string): IconComponent =>
    key ? app.getSystemIcon(key) ?? LanguageIcon : LanguageIcon;

  const links = entity?.metadata?.links;

  return (
    <InfoCard title="Links" variant={variant}>
      {!links || links.length === 0 ? (
        <EntityLinksEmptyState entity={entity} />
      ) : (
        <LinksGridList
          cols={cols}
          items={links.map(({ url, title, icon }) => ({
            text: title ?? url,
            href: url,
            Icon: iconResolver(icon),
          }))}
        />
      )}
    </InfoCard>
  );
};
