import {
  BackstageUserIdentity,
  ProfileInfo,
  useApi,
} from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { useUserProfile } from '@backstage/plugin-user-settings';
import {
  createVersionedContext,
  createVersionedValueMap,
} from '@backstage/version-bridge';
import {
  EmployeeEntity,
  ProductEntityV1alpha1,
} from '@lego/plugin-baseplate-common';
import React, { ReactNode } from 'react';
import useAsync from 'react-use/lib/useAsync';

export type UserContextProps = {
  user: ProfileInfo;
  loading: boolean;
  userEntity?: EmployeeEntity;
  backstageIdentity?: BackstageUserIdentity;
  product?: ProductEntityV1alpha1;
};

type Props = {
  children?: ReactNode;
};

export const UserContext = createVersionedContext<{
  1: UserContextProps;
}>('current-user-context');

export const UserContextProvider = ({ children }: Props) => {
  const {
    profile,
    backstageIdentity,
    loading: loadingProfile,
  } = useUserProfile();
  const catalogApi = useApi(catalogApiRef);

  const { value: product, loading: loadingProduct } = useAsync(async () => {
    if (!backstageIdentity) return undefined;
    const { ownershipEntityRefs } = backstageIdentity;
    const ref = ownershipEntityRefs.find(o => o.startsWith('product:'));
    if (!ref) return undefined;
    return (await catalogApi.getEntityByRef(ref)) as ProductEntityV1alpha1;
  }, [backstageIdentity, catalogApi]);

  const { value: userEntity, loading: loadingUser } = useAsync(async () => {
    if (!backstageIdentity) return undefined;
    const { userEntityRef } = backstageIdentity;
    return (await catalogApi.getEntityByRef(userEntityRef)) as EmployeeEntity;
  }, [backstageIdentity, catalogApi]);

  const versionedValue = createVersionedValueMap({
    1: {
      user: profile,
      backstageIdentity,
      product,
      loading: loadingProduct || loadingUser || loadingProfile,
      userEntity,
    },
  });
  return (
    <UserContext.Provider value={{ ...versionedValue }}>
      {children}
    </UserContext.Provider>
  );
};
