import { DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';
import { ResponseError } from '@backstage/errors';
import {
  AnalyticsData,
  SearchParams,
  SearchResponse,
} from '@lego/plugin-baseplate-analytics-common';
import { AnalyticsApi } from './AnalyticsApi';
import { createQuery } from '../searches';

export class AnalyticsClient implements AnalyticsApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi: FetchApi;

  public constructor(options: {
    discoveryApi: DiscoveryApi;
    fetchApi: FetchApi;
  }) {
    this.discoveryApi = options.discoveryApi;
    this.fetchApi = options.fetchApi;
  }

  async fetchDocsAnalytics(
    params: SearchParams,
  ): Promise<AnalyticsData | undefined> {
    const { entityRef, startTime, endTime } = params;
    const url = `${await this.discoveryApi.getBaseUrl(
      'proxy',
    )}/elastic-search/_msearch`;
    const query = createQuery({ entityRef, endTime, startTime });

    if (!query) {
      throw new Error('Failed to create query');
    }

    const requestBody = `${query
      .map(search => JSON.stringify(search))
      .join('\n')}\n`;

    const response = await this.fetchApi.fetch(url, {
      method: 'POST',
      body: requestBody,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }

    const { responses } = (await response.json()) as {
      responses: SearchResponse[];
    };

    const { aggregations } = responses[0];
    const { aggregations: pageVisitAggregations } = responses[1];

    if (!aggregations || !pageVisitAggregations) {
      return undefined;
    }

    const totalUniqueVisits = aggregations.unique_users.value;
    const uniqueVisits = aggregations.unique_visits.value;

    const hitsOverTimeLabel = aggregations?.hits_over_time.buckets.map(bucket =>
      new Date(bucket.key).toLocaleDateString(),
    );
    const hitsOverTimeCount = aggregations?.hits_over_time.buckets.map(
      bucket => bucket.doc_count,
    );

    const productVisitors = aggregations?.total_visits_from_product.buckets.map(
      bucket => bucket.key,
    );
    const productVisitorsCount =
      aggregations?.total_visits_from_product.buckets.map(
        bucket => bucket.doc_count,
      );
    const pageVisits = pageVisitAggregations.page_visits.buckets;

    const domainVisitors = aggregations?.total_visits_from_domain.buckets.map(
      bucket => bucket.key,
    );

    const domainVisitorsCount =
      aggregations?.total_visits_from_domain.buckets.map(
        bucket => bucket.doc_count,
      );

    const subdomainVisitors =
      aggregations?.total_visits_from_subdomain.buckets.map(
        bucket => bucket.key,
      );

    const subdomainVisitorsCount =
      aggregations?.total_visits_from_subdomain.buckets.map(
        bucket => bucket.doc_count,
      );

    return {
      uniqueVisits,
      totalUniqueVisits,
      hitsOverTimeLabel,
      hitsOverTimeCount,
      productVisitors,
      productVisitorsCount,
      domainVisitors,
      domainVisitorsCount,
      subdomainVisitors,
      subdomainVisitorsCount,
      pageVisits,
    };
  }
}
