import {
  Entity,
  RELATION_OWNER_OF,
  stringifyEntityRef,
} from '@backstage/catalog-model';
import { useApi } from '@backstage/core-plugin-api';
import {
  catalogApiRef,
  getEntityRelations,
  useEntity,
} from '@backstage/plugin-catalog-react';
import { useQuery } from '@tanstack/react-query';

export const useApplications = () => {
  const { entity } = useEntity();
  const catalogApi = useApi(catalogApiRef);
  const applicationRefs = getEntityRelations(entity, RELATION_OWNER_OF, {
    kind: 'application',
  });

  const {
    data: applications,
    error,
    isLoading: loading,
  } = useQuery<Array<Entity>>(['applications'], async () => {
    const apps = await catalogApi
      .getEntitiesByRefs({
        entityRefs: applicationRefs.map(ref => stringifyEntityRef(ref)),
      })
      .then(response => response.items);

    // Remove all undefined applications
    return apps
      .filter((app): app is Entity => !!app)
      .sort((a, b) => {
        if (a.metadata.title && b.metadata.title)
          return a.metadata.title > b.metadata.title ? 1 : -1;
        return 1;
      });
  });

  return { applications, error, loading };
};
