import { FilterChip } from '@lego/plugin-baseplate-core-components';
import { Employee } from '@lego/plugin-baseplate-people-to-product-common';
import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { NavigateOptions, URLSearchParamsInit } from 'react-router-dom';
import {
  filterEmployeesWithProducts,
  filterEmployeesWithoutProducts,
  filterExternalEmployees,
  filterInternalEmployees,
} from '../../hooks/filters/filters';

interface HeaderProps {
  loading: boolean;
  reports: Employee[];
  productsFilter: boolean | string[] | null;
  externalFilter: boolean | null;
  setSearchParams: (
    nextInit?:
      | URLSearchParamsInit
      | ((prev: URLSearchParams) => URLSearchParamsInit)
      | undefined,
    navigateOpts?: NavigateOptions | undefined,
  ) => void;
}

export function Header({
  loading,
  reports,
  productsFilter,
  externalFilter,
  setSearchParams,
}: HeaderProps) {
  return (
    <>
      {loading ? (
        <>Loading...</>
      ) : (
        <>
          <Typography variant="h5" style={{ padding: 2 }}>
            Reports
          </Typography>
          <Grid container spacing={1} style={{ paddingLeft: 0 }}>
            <Grid item>
              <FilterChip
                label="All"
                count={reports.length}
                selected={productsFilter === null && externalFilter === null}
                onClick={() =>
                  setSearchParams(prevParams => {
                    if (productsFilter !== null) {
                      prevParams.delete('products');
                    }
                    if (externalFilter !== null) {
                      prevParams.delete('external');
                    }
                    return prevParams;
                  })
                }
              />
            </Grid>
            <Grid item>
              <FilterChip
                label="Without products"
                count={reports.filter(filterEmployeesWithoutProducts).length}
                selected={productsFilter === false}
                onClick={(_, selectedState: boolean) =>
                  setSearchParams(prevParams => {
                    if (productsFilter !== false) {
                      prevParams.set('products', String(selectedState));
                    }
                    if (externalFilter !== null) {
                      prevParams.delete('external');
                    }
                    return prevParams;
                  })
                }
              />
            </Grid>
            <Grid item>
              <FilterChip
                label="With products"
                count={reports.filter(filterEmployeesWithProducts).length}
                selected={productsFilter === true}
                onClick={() =>
                  setSearchParams(prevParams => {
                    if (productsFilter !== true) {
                      prevParams.set('products', 'true');
                    }
                    if (externalFilter !== null) {
                      prevParams.delete('external');
                    }
                    return prevParams;
                  })
                }
              />
            </Grid>
            <Grid item>
              <FilterChip
                label="Externals"
                count={reports.filter(filterExternalEmployees).length}
                selected={externalFilter === true}
                onClick={() =>
                  setSearchParams(prevParams => {
                    if (externalFilter !== true) {
                      prevParams.set('external', 'true');
                    }
                    if (productsFilter !== null) {
                      prevParams.delete('products');
                    }
                    return prevParams;
                  })
                }
              />
            </Grid>
            <Grid item>
              <FilterChip
                label="Internals"
                count={reports.filter(filterInternalEmployees).length}
                selected={externalFilter === false}
                onClick={() =>
                  setSearchParams(prevParams => {
                    if (externalFilter !== false) {
                      prevParams.set('external', 'false');
                    }
                    if (productsFilter !== null) {
                      prevParams.delete('products');
                    }
                    return prevParams;
                  })
                }
              />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}
