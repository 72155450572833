import { Table } from '@backstage/core-components';
import { showToast } from '@lego/plugin-baseplate-core-components';
import { Employee } from '@lego/plugin-baseplate-people-to-product-common';
import { MouseEvent, default as React, useState } from 'react';
import EmployeeProductSidePanel from '../../features/EmployeeProductSidePanel/EmployeeProductSidePanel';
import { useReport } from '../../hooks';
import { useReportSearchParams } from '../../hooks/useReportSearchParams';
import { columns } from './columns';
import { Header } from './Header';

export function EmployeeTable() {
  const [selectedEmployee, setSelectedEmployee] = useState<Employee>();
  const [isOpen, setIsOpen] = useState(false);
  const { setSearchParams, products, external } = useReportSearchParams();

  const { reports, loading, error, filteredReports } = useReport();

  const handleRowClick = (_event?: MouseEvent, rowData?: any) => {
    const clickedEmployee = reports.find(
      (employee: Employee) => employee.fullName === rowData.fullName,
    );
    setSelectedEmployee(clickedEmployee);
    setIsOpen(true);
  };

  const showErrorPopup = () => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    showToast({
      type: 'error',
      message: 'Error happened while fetching employees.',
    });
  };

  return (
    <>
      <Table
        title={
          <Header
            loading={loading}
            reports={reports}
            productsFilter={products}
            externalFilter={external}
            setSearchParams={setSearchParams}
          />
        }
        options={{ search: true, paging: false, sorting: true }}
        columns={columns}
        data={filteredReports}
        style={{ width: '100%' }}
        onRowClick={handleRowClick}
        isLoading={loading}
        parentChildData={(row, rows) =>
          rows.find(a => a.id === row.peopleLeaderId)
        }
      />
      {selectedEmployee && (
        <EmployeeProductSidePanel
          employee={selectedEmployee}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      )}
      {error && showErrorPopup()}
    </>
  );
}
