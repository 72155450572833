import React from 'react';
import { type ReactElement } from 'react';
import { UiMessageType } from '../../../types';
import { Typography } from '@material-ui/core';
import { ParameterGroup } from '../parameters/ParameterGroup';
import { ExampleCodeBlock } from '../example/ExampleCodeBlock';
import { baseplateStyles } from '@lego/plugin-baseplate-core-components';
import { Parameter } from '../parameters/Parameter';
import { getNavId } from '../sidenav/getNavId';

const useStyles = baseplateStyles(() => ({
  root: {
    margin: '4rem 0',
  },
  twoColumns: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '1rem',
  },
  textContainer: {
    marginBottom: '1rem',
  },
}));

export function Message({
  name,
  summary,
  payload,
  metadata,
  examples,
  messageNumber,
  totalMessageTypes,
}: UiMessageType): ReactElement {
  const classes = useStyles();
  return (
    <div className={classes.root} id={getNavId(name)}>
      <div className={classes.twoColumns}>
        <div>
          <div className={classes.textContainer}>
            {totalMessageTypes && totalMessageTypes > 1 && (
              <>
                <Typography variant="body2" color="secondary">
                  Message type {messageNumber}/{totalMessageTypes}
                </Typography>
              </>
            )}
            <Typography variant="subtitle1">{name}</Typography>
            <Typography variant="body1">{summary}</Typography>
          </div>
          {metadata && metadata?.length > 0 && (
            <ParameterGroup name="headers" parameters={metadata} />
          )}
          {payload?.length > 0 &&
            payload.map((parameter, index) => (
              <Parameter key={index} {...parameter} />
            ))}
        </div>
        <div>
          <ExampleCodeBlock title="Example payload" examples={examples} />
        </div>
      </div>
    </div>
  );
}
