import React from 'react';
import { Spinner } from '@lego/plugin-baseplate-core-components';
import { useStyles } from './Loader.styles';

type LoaderProps = {
  displayed: boolean;
};

export const Loader = ({ displayed }: LoaderProps) => {
  const styles = useStyles();

  if (!displayed) {
    return null;
  }

  return (
    <div className={styles.spinnerContainer}>
      <Spinner align="center" />
    </div>
  );
};
