import { IconComponent } from '@backstage/core-plugin-api';
import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';
import { useStyles } from './EntityIcon.styles';

export const ProductIcon: React.FC<IconComponent> = props => {
  const classes = useStyles();
  const iconClassName = `svg-icon-32`;

  return (
    <SvgIcon {...props}>
      <svg
        width={32}
        height={32}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        className={classes[iconClassName]}
      >
        <rect
          width="24"
          height="24"
          rx="8"
          className={classes['product-layer-3']}
        />
        <path
          d="M7.05 4.8c.788 0 1.519.45 1.94 1.125.394.703.394 1.575 0 2.25C8.57 8.878 7.839 9.3 7.05 9.3a2.278 2.278 0 0 1-1.969-1.125c-.394-.675-.394-1.547 0-2.25C5.503 5.25 6.234 4.8 7.05 4.8Zm10.35 0c.788 0 1.519.45 1.94 1.125.394.703.394 1.575 0 2.25-.421.703-1.152 1.125-1.94 1.125a2.278 2.278 0 0 1-1.969-1.125c-.393-.675-.393-1.547 0-2.25C15.853 5.25 16.584 4.8 17.4 4.8ZM3 13.21c0-1.66 1.322-3.01 2.981-3.01h1.21c.45 0 .872.113 1.265.281-.056.197-.056.422-.056.619 0 1.097.45 2.053 1.21 2.7H3.59A.577.577 0 0 1 3 13.21Zm11.363.59c.759-.647 1.209-1.603 1.209-2.7 0-.197 0-.422-.028-.619a2.961 2.961 0 0 1 1.237-.281h1.21C19.65 10.2 21 11.55 21 13.21c0 .337-.281.59-.619.59h-6.018Z"
          className={classes['product-layer-2']}
        />
        <path
          d="M10.65 8.766c-.844.506-1.35 1.378-1.35 2.334 0 .984.506 1.856 1.35 2.363.816.478 1.856.478 2.7 0 .816-.507 1.35-1.379 1.35-2.363 0-.956-.534-1.828-1.35-2.334-.844-.479-1.884-.479-2.7 0ZM10.34 14.7c-2.08 0-3.74 1.688-3.74 3.769 0 .422.338.731.731.731h9.31c.422 0 .759-.337.759-.731a3.769 3.769 0 0 0-3.769-3.769h-3.29Z"
          className={classes['product-layer-1']}
        />
      </svg>
    </SvgIcon>
  );
};
