import { useEntity, useRelatedEntities } from '@backstage/plugin-catalog-react';
import {
  ANNOTATION_LAST_INGESTION,
  ToolEntity,
} from '@lego/plugin-baseplate-common';
import {
  Card,
  EntityLink,
  LinkCard,
  baseplateStyles,
} from '@lego/plugin-baseplate-core-components';
import { Grid, Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import { ToolsAboutContent } from './ToolsAboutContent';
import { DateTime } from 'luxon';
import { RELATION_PART_OF } from '@backstage/catalog-model';
import { ToolsActionsBar } from './ToolsActionsBar';
import { ToolLicence } from '../components/ToolLicence';
import { LifecycleTimeline } from '../components/LifecycleTimeline';

const useStyles = baseplateStyles(theme => ({
  cardFooter: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'space-between',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: '0.75rem',
    marginTop: '1rem',
    '& a': {
      color: theme.semantic.text.primary,
    },
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    width: '100%',
    marginTop: '1rem',
  },
}));

export const ToolsContent = () => {
  const classes = useStyles();
  const { entity: tool } = useEntity<ToolEntity>();
  const { entities: applicationPartOf } = useRelatedEntities(tool, {
    type: RELATION_PART_OF,
  });
  const appId =
    Array.isArray(applicationPartOf) && applicationPartOf.length > 0
      ? applicationPartOf[0].metadata.name
      : null;

  const links = tool?.metadata?.links;

  const timeSinceIngestion = useMemo(() => {
    if (tool.metadata.annotations?.[ANNOTATION_LAST_INGESTION]) {
      return DateTime.fromISO(
        tool.metadata.annotations?.[ANNOTATION_LAST_INGESTION],
      ).toRelative({ locale: 'en' });
    }
    return '';
  }, [tool]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <ToolsActionsBar tool={tool} />
      </Grid>
      <Grid item xs={12} md={6}>
        <Card title="About">
          <ToolsAboutContent entity={tool} />
          {links && links.length > 0 && (
            <div className={classes.section}>
              <Typography variant="overline" color="secondary">
                Links
              </Typography>
              {tool?.metadata?.links && tool?.metadata?.links.length > 0
                ? tool.metadata.links.map((link, index) => (
                    <LinkCard title={link.title} url={link.url} key={index} />
                  ))
                : null}
            </div>
          )}
          <div className={classes.section}>
            {/* Owner */}
            <Typography variant="overline" color="secondary">
              Owner
            </Typography>
            {tool?.spec.owner ? (
              <EntityLink variant="link" entityRef={tool?.spec.owner} />
            ) : (
              'No owner...'
            )}
          </div>
          <div className={classes.cardFooter}>
            <div>
              Source:{' '}
              {appId !== null ? (
                <span>
                  <a
                    href={`https://lego.leanix.net/architecturegateway/external/applicationId/${appId}`}
                  >
                    LeanIX
                  </a>
                  {' & '}
                </span>
              ) : null}
              <span>
                <a href={tool.metadata.annotations!['backstage.io/view-url']}>
                  GitHub
                </a>
              </span>
            </div>{' '}
            <div>Last updated: {timeSinceIngestion}</div>
          </div>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card title="compliance">
          <LifecycleTimeline lifecycle={tool?.spec.lifecycle} />
          <ToolLicence
            licence={tool?.metadata.licence}
            editUrl={tool.metadata.name}
          />
        </Card>
      </Grid>
    </Grid>
  );
};
