import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function House(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="m20.969 4.352 16.5 14c.625.562.687 1.5.187 2.125-.562.625-1.5.687-2.125.187l-1.5-1.312v11.625c0 2.812-2.25 5-5 5h-18c-2.812 0-5-2.188-5-5V19.352L4.47 20.664c-.625.5-1.563.438-2.125-.187a1.558 1.558 0 0 1 .187-2.125l16.5-14a1.594 1.594 0 0 1 1.938 0ZM9.03 30.977c0 1.125.875 2 2 2h3v-9.5c0-1.375 1.063-2.5 2.5-2.5h7c1.375 0 2.5 1.125 2.5 2.5v9.5h3c1.063 0 2-.875 2-2V16.789l-11-9.312-11 9.312v14.188Zm8 2h6v-9h-6v9Z" />
    </SvgIcon>
  );
}
