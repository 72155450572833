import { EntityLayout } from '@lego/plugin-baseplate-catalog';
import {
  EntityConsumedApisCard,
  EntityProvidedApisCard,
} from '@backstage/plugin-api-docs';
import {
  EntityDependsOnComponentsCard,
  EntityDependsOnResourcesCard,
  EntityLinksCard,
  EntityOrphanWarning,
  EntityProcessingErrorsPanel,
  EntitySwitch,
  hasCatalogProcessingErrors,
  isOrphan,
} from '@backstage/plugin-catalog';
import { EntityCatalogGraphCard } from '@backstage/plugin-catalog-graph';
import { EntityTechdocsContent } from '@backstage/plugin-techdocs';
import {
  LightBox,
  ReportIssue,
} from '@backstage/plugin-techdocs-module-addons-contrib';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import {
  hasAmmaProcessingErrors,
  isDuplicatedEntity,
  isMissingLifecycle,
  isMissingOwner,
} from '@lego/plugin-baseplate-common';
import { Box, Grid } from '@material-ui/core';
import { Mermaid } from 'backstage-plugin-techdocs-addon-mermaid';
import React from 'react';
import {
  ColorLinks,
  Codeblock,
  MagicLinks,
  GlobalStyles,
  TableOfContentActions,
} from '../documentation';
import { BaseplateAboutCard } from './CustomComponents';
import { DuplicatedEntityWarning } from './DuplicatedEntityWarning';
import { EntityAmmaProcessingErrors } from './EntityAmmaProcessingErrors';
import { EntityLifecycleWarning } from './EntityLifecycleWarning';
import { EntityMissingOwnerWarning } from './EntityOwnerWarning';
import { FeatureFlagged } from '@backstage/core-app-api';
import { EntityTechdocs } from '../documentation/EntityTechdocs';

export const techdocsContent = (
  <EntityTechdocsContent>
    <TechDocsAddons>
      <Mermaid
        config={{ theme: 'forest', themeVariables: { lineColor: '#000000' } }}
      />
      <ReportIssue />
      <ColorLinks />
      <MagicLinks />
      <LightBox />
      <Codeblock />
      <GlobalStyles />
      <TableOfContentActions />
    </TechDocsAddons>
  </EntityTechdocsContent>
);

export const entityWarningContent = (
  <>
    <EntitySwitch>
      <EntitySwitch.Case if={isOrphan}>
        <Grid item xs={12}>
          <EntityOrphanWarning />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
    <EntitySwitch>
      <EntitySwitch.Case if={isDuplicatedEntity}>
        <Grid item xs={12}>
          <DuplicatedEntityWarning />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={hasCatalogProcessingErrors}>
        <Grid item xs={12}>
          <EntityProcessingErrorsPanel />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={isMissingOwner}>
        <Grid item xs={12}>
          <EntityMissingOwnerWarning />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={hasAmmaProcessingErrors}>
        <Grid item xs={12}>
          <EntityAmmaProcessingErrors />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
    <EntitySwitch>
      <EntitySwitch.Case if={isMissingLifecycle}>
        <Grid item xs={12}>
          <EntityLifecycleWarning />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
  </>
);

export const overviewContent = (
  <Grid container spacing={4} alignItems="stretch">
    {entityWarningContent}
    <Grid item md={8} xs={12}>
      <BaseplateAboutCard />
    </Grid>
    <Grid item md={4} xs={12}>
      <EntityLinksCard variant="fullHeight" />
    </Grid>
    <Grid item md={12} xs={12}>
      <EntityCatalogGraphCard variant="gridItem" height={400} />
    </Grid>
  </Grid>
);

export const serviceEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/api" title="API">
      <Box m={3}>
        <Grid container spacing={5} alignItems="stretch">
          <Grid item md={6}>
            <EntityProvidedApisCard />
          </Grid>
          <Grid item md={6}>
            <EntityConsumedApisCard />
          </Grid>
        </Grid>
      </Box>
    </EntityLayout.Route>

    <EntityLayout.Route path="/dependencies" title="Dependencies">
      <Grid container spacing={4} alignItems="stretch">
        <Grid item md={6}>
          <EntityDependsOnComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityDependsOnResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>
  </EntityLayout>
);

export const websiteEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/dependencies" title="Dependencies">
      <Grid container spacing={4} alignItems="stretch">
        <Grid item md={6}>
          <EntityDependsOnComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityDependsOnResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>
  </EntityLayout>
);

export const defaultEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      <EntityTechdocs />
    </EntityLayout.Route>
  </EntityLayout>
);
