import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function AddressCard(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M34 9H6c-.563 0-1 .5-1 1v20c0 .563.438 1 1 1h28c.5 0 1-.438 1-1V10c0-.5-.5-1-1-1ZM6 6h28c2.188 0 4 1.813 4 4v20c0 2.25-1.813 4-4 4H6c-2.25 0-4-1.75-4-4V10c0-2.188 1.75-4 4-4Zm9 14c-1.438 0-2.75-.75-3.5-2-.688-1.188-.688-2.75 0-4 .75-1.188 2.063-2 3.5-2 1.375 0 2.688.813 3.438 2 .687 1.25.687 2.813 0 4-.75 1.25-2.063 2-3.438 2Zm-2 2h4c2.75 0 5 2.25 5 5 0 .563-.5 1-1 1H9c-.563 0-1-.438-1-1 0-2.75 2.188-5 5-5Zm12.5-9h5c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5h-5c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5Zm0 6h5c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5h-5c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5Z" />
    </SvgIcon>
  );
}
