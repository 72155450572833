import * as React from 'react';
import { useInputStyles } from '../input/Input.styles';
import TextField from '@mui/material/TextField';
import { Icon } from '../icon';
import { baseplateStyles } from '../../utils';

type Props = {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const useSearchFieldStyles = baseplateStyles(theme => ({
  root: {
    '& .v5-MuiOutlinedInput-input': {
      paddingLeft: '0.5rem',
    },
    '& svg': {
      fill: theme.semantic.icon.secondary,
    },
  },
}));

export const SearchField = ({ value, onChange }: Props) => {
  const classes = useInputStyles();
  const searchClasses = useSearchFieldStyles();
  return (
    <div className={[classes.root, searchClasses.root].join(' ')}>
      <TextField
        type="search"
        variant="outlined"
        placeholder="Search..."
        value={value}
        onChange={onChange}
        InputProps={{
          startAdornment: <Icon icon="magnifying-glass" />,
        }}
      />
    </div>
  );
};
