import { ResponseErrorPanel } from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import { Button, Spinner } from '@lego/plugin-baseplate-core-components';
import { Typography } from '@material-ui/core';
import { Add } from '@mui/icons-material';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router';
import useAsync from 'react-use/lib/useAsync';
import { eventsApiRef } from '../../../api';
import { EventsPreview } from '../../Events/EventsPreview';
import { useComingEventsStyles } from './UpcomingEventsCard.styles';

export const Content = () => {
  const classes = useComingEventsStyles();
  const eventsAPI = useApi(eventsApiRef);
  const navigate = useNavigate();

  const {
    loading,
    error,
    value: events,
  } = useAsync(async () => {
    const searchParams = new URLSearchParams({});
    const startDate = new Date();
    const endDate = new Date();
    startDate.setDate(startDate.getDate());
    endDate.setDate(endDate.getFullYear() + 1);
    searchParams.append(
      'startDateTime',
      `${startDate.toISOString().split('T')[0]}T00:00:00.000Z`,
    );
    searchParams.append(
      'endDateTime',
      `${endDate.toISOString().split('T')[0]}T23:59:00.000Z`,
    );
    searchParams.append('perPage', '5');
    const { data } = await eventsAPI.getEvents(searchParams.toString());

    return data ?? [];
  }, []);

  const goToEvents = useCallback(
    (newEvent: boolean) => {
      const url = newEvent ? 'manage-events/new' : `events`;
      navigate(`/learning-and-development/${url}`);
    },
    [navigate],
  );

  const goToEvent = useCallback(
    (eventId: string) => {
      navigate(`/learning-and-development/events/${eventId}`);
    },
    [navigate],
  );

  if (error) {
    return <ResponseErrorPanel error={error} />;
  }

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      {events && events.length > 0 ? (
        <>
          <EventsPreview
            events={events}
            onEventClick={goToEvent}
            isLoading={loading}
          />
          <Button
            variant="primary"
            startIcon={<Add />}
            onClick={() => goToEvents(true)}
            style={{ marginRight: '10px' }}
          >
            Host an Event
          </Button>
          <Button
            variant="text"
            onClick={() => {
              navigate(`/learning-and-development/events`);
            }}
          >
            View all
          </Button>
        </>
      ) : (
        <div className={classes.noEventGrp}>
          <Typography variant="body1">There are no upcoming events.</Typography>
          <Button
            variant="primary"
            startIcon={<Add />}
            onClick={() => goToEvents(true)}
          >
            Host an Event
          </Button>
          <Typography variant="body1">
            Empower your product teams by hosting your next event!
          </Typography>
        </div>
      )}
    </>
  );
};
