import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Camera(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M16.438 6H23.5c1.563 0 2.875 1 3.375 2.438l.5 1.562H32c2.188 0 4 1.813 4 4v16c0 2.25-1.813 4-4 4H8c-2.25 0-4-1.75-4-4V14c0-2.188 1.75-4 4-4h4.563l.562-1.563 1.375.438-1.375-.438C13.563 7 14.938 6 16.438 6Zm-.5 3.375L15.062 12c-.187.625-.75 1-1.437 1H8c-.563 0-1 .5-1 1v16c0 .563.438 1 1 1h24c.5 0 1-.438 1-1V14c0-.5-.5-1-1-1h-5.688c-.687 0-1.25-.375-1.437-1L24 9.375A.544.544 0 0 0 23.5 9h-7.063a.544.544 0 0 0-.5.375ZM20 29c-2.563 0-4.813-1.313-6.063-3.5-1.312-2.125-1.312-4.813 0-7 1.25-2.125 3.5-3.5 6.063-3.5 2.5 0 4.75 1.375 6 3.5 1.313 2.188 1.313 4.875 0 7-1.25 2.188-3.5 3.5-6 3.5Zm-4-7c0 1.438.75 2.75 2 3.5 1.188.688 2.75.688 4 0 1.188-.75 2-2.063 2-3.5 0-1.375-.813-2.688-2-3.438-1.25-.687-2.813-.687-4 0-1.25.75-2 2.063-2 3.438Z" />
    </SvgIcon>
  );
}
