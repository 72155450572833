import { FormControl, Typography } from '@material-ui/core';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import React from 'react';
import { useInputStyles } from './Input.styles';
import { BaseplateInputProps } from './InputProps.type';
import { Spinner } from '../spinner';

type Props = TextFieldProps & BaseplateInputProps;

/**
 * A custom form control input component that extends the MUI TextField component.
 * @docs https://baseplate.legogroup.io/docs/default/component/baseplate
 */
export const Input = ({
  fullWidth = true,
  helperText,
  errorText,
  label,
  required,
  formControlOptions,
  success,
  successText,
  loading,
  ...props
}: Props): JSX.Element => {
  const classes = useInputStyles();

  if (props.multiline && !props.minRows) {
    props.minRows = 3;
  }

  return (
    <FormControl
      {...formControlOptions}
      className={[
        classes.root,
        success && classes.success,
        props.error && classes.error,
      ].join(' ')}
      fullWidth
      margin="none"
      required={required}
    >
      <Typography variant="body2" component="label" htmlFor={props.id}>
        {label}{' '}
        {!required && (
          <Typography variant="caption" color="secondary">
            (optional)
          </Typography>
        )}
      </Typography>
      <TextField
        {...props}
        label=""
        placeholder={props.placeholder || label}
        className={classes.textField}
        fullWidth={fullWidth}
        InputProps={{
          ...props.InputProps,
          endAdornment: loading ? (
            <Spinner type="circle" />
          ) : (
            props.InputProps?.endAdornment
          ),
        }}
      />
      <div className={classes.descriptiveTextContainer}>
        {helperText && (
          <Typography variant="body2" className={classes.helperText}>
            {helperText}
          </Typography>
        )}
        {props.error && errorText && (
          <Typography variant="body2" className={classes.errorText}>
            {errorText}
          </Typography>
        )}
        {success && successText && (
          <Typography variant="body2" className={classes.successText}>
            {successText}
          </Typography>
        )}
      </div>
    </FormControl>
  );
};
