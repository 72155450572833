import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function PenToSquare(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M31.688 7.563h-.063a1.471 1.471 0 0 0-2.063 0l-1.875 1.812 2.938 2.938 1.813-1.876c.562-.562.562-1.5 0-2.124l-.75-.75Zm-14.5 12.375c-.188.125-.313.375-.375.625l-1.063 3.625 3.688-1c.25-.063.437-.188.625-.375l8.437-8.438-2.875-2.875-8.438 8.438Zm10.25-14.5a4.451 4.451 0 0 1 6.312 0l.813.812a4.451 4.451 0 0 1 0 6.313L22.187 24.937c-.5.5-1.187.875-1.937 1.125L14 27.813c-.5.188-1.063.063-1.438-.375-.437-.375-.562-.937-.437-1.437l1.813-6.25c.187-.75.624-1.438 1.124-1.938L27.438 5.438ZM9.625 7.874h7c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5h-7c-1.438 0-2.5 1.125-2.5 2.5v17c0 1.438 1.063 2.5 2.5 2.5h17c1.375 0 2.5-1.063 2.5-2.5v-7c0-.813.625-1.5 1.5-1.5.813 0 1.5.688 1.5 1.5v7c0 3.063-2.5 5.5-5.5 5.5h-17a5.468 5.468 0 0 1-5.5-5.5v-17c0-3 2.438-5.5 5.5-5.5Z" />
    </SvgIcon>
  );
}
