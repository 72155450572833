import React from 'react';
import { Content, Page } from '@backstage/core-components';
import { useUserContext } from '@lego/plugin-baseplate-core-components';
import {
  RELATION_OWNER_OF,
  stringifyEntityRef,
} from '@backstage/catalog-model';
import { getEntityRelations } from '@backstage/plugin-catalog-react';
import { ManageDocumentationTable } from './ManageDocumentationTable';
import { RelatedDocumentationPicker } from './RelatedDocumentationFilter';

export const ManageDocumentation = () => {
  const { product } = useUserContext();
  const relatedEntities = getEntityRelations(product, RELATION_OWNER_OF);
  const userProductRelatedEntityRefs = relatedEntities.map(
    entity => entity && stringifyEntityRef(entity),
  );

  return (
    <Page themeId="home">
      <Content>
        <RelatedDocumentationPicker
          userRelatedEntityRefs={[
            ...userProductRelatedEntityRefs,
            ...(product ? [stringifyEntityRef(product)] : []),
          ]}
        />
        <ManageDocumentationTable />
      </Content>
    </Page>
  );
};
