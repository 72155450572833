import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function StackedBricksSideBlueYellow(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <g fill="#FFD502">
        <path d="M34.712 24.99 9.696 20.363l-2.775 15.01L31.937 40l2.775-15.01Z" />
        <path d="M12.437 18.656a.684.684 0 0 1 .797-.548l6.474 1.197a.684.684 0 0 1 .548.797l-.396 2.141-7.816-1.445.396-2.141h-.003Zm19.93 5.9.396-2.142a.684.684 0 0 0-.548-.797L25.74 20.42a.684.684 0 0 0-.796.548l-.396 2.142 7.82 1.446Z" />
      </g>
      <g fill="#0DADE4">
        <path d="M31.359 2.61 6 4.64l1.218 15.217 25.36-2.03-1.22-15.217Z" />
        <path d="M8.203 2.28a.684.684 0 0 1 .627-.737l6.563-.526c.377-.03.706.25.737.628l.174 2.17-7.924.635-.174-2.171h-.003Zm20.778.52L28.808.63a.684.684 0 0 0-.737-.628l-6.563.526a.684.684 0 0 0-.627.736l.174 2.17L28.98 2.8Z" />
      </g>
    </SvgIcon>
  );
}
