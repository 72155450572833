import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles<any>(theme => ({
  autoCompleteInput: {
    '& > .MuiInputBase-root > input': {
      textAlign: 'start',
      color: `${theme.semantic.text.primary}`,
    },
  },
  loader: {
    padding: 10,
    border: `1px solid ${theme.semantic.border.default}`,
    borderTop: 'none',
    borderRadius: 4,
  },
}));
