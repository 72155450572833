import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  fetchApiRef,
} from '@backstage/core-plugin-api';
import { TemplateApiRef, TemplateClient } from './api';
import { rootRouteRef } from './routes';

export const particlesPlugin = createPlugin({
  id: 'particles',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: TemplateApiRef,
      deps: {
        fetchApi: fetchApiRef,
        discoveryApi: discoveryApiRef,
      },
      factory: ({ fetchApi, discoveryApi }) =>
        new TemplateClient({
          discoveryApi,
          fetchApi,
        }),
    }),
  ],
});

export const ParticlesPage: () => JSX.Element = particlesPlugin.provide(
  createRoutableExtension({
    name: 'ParticlesPage',
    component: () =>
      import('./components/ParticlesPage/ParticlesPage').then(
        m => m.ParticlesPage,
      ),
    mountPoint: rootRouteRef,
  }),
);
