import { baseplateStyles } from '../../utils';

export const useButtonStyle = baseplateStyles(theme => ({
  root: {
    '&.v5-MuiButton-root': {
      borderRadius: theme.primitive.borderRadius.default,
      textTransform: 'none',
      fontSize: theme.typography.body1.fontSize,
      fontFamily: theme.typography.fontFamily,
      boxShadow: 'none',
      '&:hover': {
        boxShadow: 'none',
      },
      '&.Mui-disabled': {
        color: theme.semantic.text.tertiary,
        backgroundColor: theme.semantic.background.neutral[3],
      },
    },
    '&.v5-MuiButton-containedPrimary': {
      backgroundColor: theme.component.button.primary.background.default,
      color: theme.component.button.primary.text,
      '& span': {
        color: theme.component.button.primary.text,
      },
      '&:hover': {
        backgroundColor: theme.component.button.primary.background.hover,
      },
    },
    '&.v5-MuiButton-outlinedPrimary': {
      color: theme.component.button.outlined.text,
      border: `1px solid ${theme.component.button.outlined.border.default}`,
      backgroundColor: theme.component.button.outlined.background.default,
      '&:hover': {
        backgroundColor: theme.component.button.outlined.background.hover,
        borderColor: theme.component.button.outlined.border.hover,
      },
    },
    '&.v5-MuiButton-containedError': {
      backgroundColor: theme.component.button.destructive.background.default,
      color: theme.component.button.destructive.text,
      '&:hover': {
        backgroundColor: theme.component.button.destructive.background.hover,
      },
    },
    '&.v5-MuiButton-textPrimary': {
      color: theme.component.button.text.text,
      '&:hover': {
        backgroundColor: theme.component.button.text.background.hover,
      },
    },

    '&.v5-MuiButton-containedSizeMedium': {
      padding: '0.375rem 1rem',
    },
    '&.v5-MuiButton-sizeSmall': {
      fontSize: theme.typography.body2.fontSize,
      padding: '0.25rem 0.5rem',
    },
  },
}));
