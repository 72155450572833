import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ArrowsLeftRight(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M35.586 21.031h-.063l-6 6c-.562.625-1.5.625-2.062 0-.625-.562-.625-1.5 0-2.125l3.437-3.437H9.086l3.437 3.437c.625.625.625 1.563 0 2.125-.562.625-1.5.625-2.062 0l-6-6c-.625-.562-.625-1.5 0-2.125l6-5.937a1.369 1.369 0 0 1 2.062 0c.625.562.625 1.5 0 2.062L9.086 18.47h21.812l-3.437-3.438a1.369 1.369 0 0 1 0-2.062c.562-.625 1.5-.625 2.125 0l6 6c.562.562.562 1.5 0 2.062Z" />
    </SvgIcon>
  );
}
