import { Role } from '@lego/plugin-baseplate-eagraph-common';
import { createApiRef } from '@backstage/core-plugin-api';
import {
  EmployeeProductRoleRecord,
  OpenPosition,
  OpenPositionProductRecord,
  OverviewStatistics,
  ProductTcoInput,
  Employee,
  GeneralOverheadProduct,
} from '@lego/plugin-baseplate-people-to-product-common';

/** @public */
export const peopleToProductApiRef = createApiRef<PeopleToProductApi>({
  id: 'plugin.people-to-product.core',
});

export type PeopleToProductApi = {
  // Methods for fetching data for the overview cards
  getReportsMappingStatistics(
    externalJobCodes: string,
  ): Promise<OverviewStatistics>;
  getOpenPositionsMappingStatistics(
    externalJobCodes: string,
  ): Promise<OverviewStatistics>;
  getNonDigitalProductManagersStatistics(
    externalJobCodes: string,
  ): Promise<OverviewStatistics>;

  // Methods for exporting CSV data
  getReportsMappingForCsv(): Promise<EmployeeProductRoleRecord[]>;
  getOpenPositionsMappingForCsv(): Promise<OpenPositionProductRecord[]>;

  // Methods for fetching data for the tables
  getReports(): Promise<Employee[]>;
  getOpenPositions(): Promise<OpenPosition[]>;
  getNonDigitalProductManagers(): Promise<Employee[]>;

  deleteProductFromEmployee(
    employeeId: number,
    productId: string,
  ): Promise<void>;
  addProductToEmployee(
    employeeId: number,
    productId: string,
    roles: Role[],
    isFirstProduct: boolean,
  ): Promise<void>;
  updateTcoProductForEmployee(
    employeeId: number,
    productId: string,
    productTcoMapping: ProductTcoInput[],
  ): Promise<void>;
  addProductToOpenPosition(
    openPositionId: number,
    productId: string,
  ): Promise<void>;
  deleteProductFromOpenPosition(
    openPositionId: number,
    productId: string,
  ): Promise<void>;
  updateRolesOnProductForEmployee(
    employeeId: number,
    productId: string,
    rolesToRemove: Role[],
    rolesToAdd: Role[],
  ): Promise<void>;
  fetchGeneralOverheadProducts(): Promise<GeneralOverheadProduct[]>;
  addGeneralOverheadProductToEmployee(
    employeeId: number,
    productId: string,
  ): Promise<void>;
  addGeneralOverheadProductToOpenPosition(
    openPositionId: number,
    GeneralOverheadProductId: string,
  ): Promise<void>;
  deleteGeneralOverheadProductFromOpenPosition(
    openPositionId: number,
  ): Promise<void>;
};
