import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function WavePulse(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M19.938 3.997c.687 0 1.312.5 1.5 1.125l4.937 19 1.688-3.562a3.502 3.502 0 0 1 3.187-2.063h7.25c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5h-7.25a.47.47 0 0 0-.438.313l-3.5 7.375c-.25.562-.875.875-1.5.812a1.556 1.556 0 0 1-1.312-1.062l-4.375-16.813-4.688 22.688A1.471 1.471 0 0 1 14 35.997a1.419 1.419 0 0 1-1.5-1.062L8.937 21.872a.544.544 0 0 0-.5-.375H1.5c-.875 0-1.5-.625-1.5-1.5 0-.812.625-1.5 1.5-1.5h6.938c1.562 0 2.937 1.063 3.374 2.625l1.938 7L18.5 5.247c.125-.687.75-1.187 1.438-1.25Z" />
    </SvgIcon>
  );
}
