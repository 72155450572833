import { useEffect } from 'react';
import { css } from './stylesheet';

// TODO: This addon currently does not work, and is a work in progress.
// the css is present in the DOM, but the icons for the pull requests and so on, are not showing. Needs further investigating.
export const MagicLinksAddons = () => {
  useEffect(() => {
    const styleSheet = document.createElement('style');
    styleSheet.innerText = css;
    document.head.appendChild(styleSheet);
  }, []);

  // Nothing to render directly, so we can just return null.
  return null;
};
