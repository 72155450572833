import {
  EntityAutocompletePicker,
  EntityTagFilter,
} from '@backstage/plugin-catalog-react';
import React from 'react';

export const ToolTagsPicker = () => {
  return (
    <EntityAutocompletePicker
      label="Tag"
      name="tags"
      path="metadata.tags"
      Filter={EntityTagFilter}
    />
  );
};
