import { ONE_MONTH_IN_MILLISECONDS } from './utils';

const SPAM_TOASTIFY_PROTECTION = 'SPAM_TOASTIFY_PROTECTION';
const SPAM_TOASTIFY_PROTECTION_TIME_IN_MILLISECONDS = ONE_MONTH_IN_MILLISECONDS;
const SUPPRESS_FEEDBACK = 'suppressFeedback';

interface FeedbackGiven {
  [feedbackIdentifier: string]: number; // true if feedback given, false if not
}

// Load the feedback given data from local storage
function loadFeedbackGiven(): FeedbackGiven {
  const feedbackGivenJson = localStorage.getItem(SUPPRESS_FEEDBACK);
  if (feedbackGivenJson) {
    return JSON.parse(feedbackGivenJson);
  }
  return {};
}

// Save the feedback given data to local storage
function saveFeedbackGiven(feedbackGiven: FeedbackGiven) {
  localStorage.setItem(SUPPRESS_FEEDBACK, JSON.stringify(feedbackGiven));
}

// Check if feedback was given for the feedbackIdentifier
export function wasFeedbackGiven(
  feedbackIdentifier: string,
): [boolean, number | undefined] {
  const feedbackGiven = loadFeedbackGiven();
  const feedbackSuppressedUntilTimeInMilliseconds =
    feedbackGiven[feedbackIdentifier];
  if (feedbackSuppressedUntilTimeInMilliseconds) {
    const currentTime = Date.now();
    if (currentTime - feedbackSuppressedUntilTimeInMilliseconds <= 0) {
      return [true, feedbackSuppressedUntilTimeInMilliseconds];
    }
    return [false, feedbackSuppressedUntilTimeInMilliseconds];
  }
  return [false, undefined];
}

// Save the feedback given for the feedbackIdentifier
export function doNotAskAgainForFeedback(
  feedbackIdentifier: string | undefined,
  suppressForMilliSeconds: number,
) {
  if (
    feedbackIdentifier &&
    feedbackIdentifier.length > 0 &&
    (suppressForMilliSeconds > 0 || suppressForMilliSeconds < 0) // negative values are used for testing
  ) {
    const feedbackGiven = loadFeedbackGiven();
    feedbackGiven[feedbackIdentifier] = Date.now() + suppressForMilliSeconds;
    saveFeedbackGiven(feedbackGiven);
  }
}

export function suppressAnyToastifiedFeedbackFor1Month() {
  doNotAskAgainForFeedback(
    SPAM_TOASTIFY_PROTECTION,
    SPAM_TOASTIFY_PROTECTION_TIME_IN_MILLISECONDS,
  );
}

export function isAnyToastifiedFeedbackSuppressed() {
  return wasFeedbackGiven(SPAM_TOASTIFY_PROTECTION)[0];
}
