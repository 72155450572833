import React from 'react';
import {
  baseplateStyles,
  getLeanixLifecycleLabel,
} from '@lego/plugin-baseplate-core-components';
import { Tooltip, Typography } from '@material-ui/core';
import { lifecycleDescriptions } from './lifecycleDescriptions';
import { LeanIxPhase } from '@lego/plugin-baseplate-common';

const useStyles = baseplateStyles(theme => {
  const circleSize = 16;
  return {
    timeline: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '1rem',
    },
    line: {
      position: 'absolute',
      top: circleSize / 2,
      left: 0,
      transform: 'translateY(-50%)',
      width: '100%',
      height: 1,
      backgroundColor: theme.semantic.border.default,
    },
    phase: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '0.25rem',
    },
    circle: {
      position: 'relative',
      width: circleSize,
      height: circleSize,
      borderRadius: theme.primitive.borderRadius.full,
      border: `1px solid ${theme.semantic.border.default}`,
      zIndex: 1,
      backgroundColor: theme.semantic.shape.neutral,
      '&$active': {
        backgroundColor: theme.semantic.shape.info,
      },
      '&$inactive': {
        background: theme.semantic.background.neutral[1],
      },
    },
    active: {},
    inactive: {},
  };
});

type ToolLifecycle = LeanIxPhase | 'experimental';

export const LifecycleTimeline = ({
  lifecycle,
}: {
  lifecycle?: LeanIxPhase | ToolLifecycle;
}) => {
  const lifecycleParsed = lifecycle ?? 'experimental';
  const classes = useStyles();
  const phases: ToolLifecycle[] = [
    'experimental',
    'phaseIn',
    'active',
    'phaseOut',
    'endOfLife',
  ];

  const indexOfPhase =
    phases.findIndex(phase => phase === lifecycleParsed) || 0;

  const getLabel = (phase: ToolLifecycle) => {
    if (phase === 'experimental') return 'Experimental';
    return getLeanixLifecycleLabel(phase);
  };

  const Circle = ({
    phase,
    state,
  }: {
    phase: ToolLifecycle;
    state: 'active' | 'inactive' | undefined;
  }) => {
    return (
      <div className={classes.phase}>
        <Tooltip title={lifecycleDescriptions[phase]}>
          <div className={[classes.circle, classes[state || '']].join(' ')} />
        </Tooltip>
        <Typography
          variant="caption"
          color={state === 'inactive' ? 'secondary' : 'primary'}
        >
          {getLabel(phase)}
        </Typography>
      </div>
    );
  };

  return (
    <div className={classes.wrapper}>
      <Typography variant="subtitle2">{getLabel(lifecycleParsed)}</Typography>
      <Typography variant="body1">
        {lifecycleDescriptions[lifecycleParsed]}
      </Typography>
      <div className={classes.timeline}>
        <div className={classes.line} />
        {phases.map((phase, i) => {
          let classType: 'active' | 'inactive' | undefined = undefined;
          if (i === indexOfPhase) classType = 'active';
          else if (i > indexOfPhase) classType = 'inactive';

          return <Circle phase={phase} state={classType} />;
        })}
      </div>
    </div>
  );
};
