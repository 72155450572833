/*
 * Copyright 2022 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  ConfigApi,
  DiscoveryApi,
  FetchApi,
  OAuthApi,
} from '@backstage/core-plugin-api';
import { MentorMatchingApi } from './MentorMatchingApi';
import { ApiUtils } from './APIUtils';
import {
  LearningProfile,
  MentorshipConnection,
  Skill,
  UpsertLearningProfileRequest,
  UpsertMentorConnectionRequest,
  UpsertMentorConnectionResponse,
} from '@lego/plugin-baseplate-learning-and-development-common';

export class MentorMatchingClient implements MentorMatchingApi {
  private readonly apiUtils: ApiUtils;

  public constructor(options: {
    discoveryApi: DiscoveryApi;
    fetchApi: FetchApi;
    oAuthApi: OAuthApi;
    configApi: ConfigApi;
  }) {
    this.apiUtils = new ApiUtils(
      options.discoveryApi,
      options.fetchApi,
      options.oAuthApi,
      options.configApi,
    );
  }

  public async getSkills(keyword: string): Promise<Skill[]> {
    const skills = await this.apiUtils.get<Skill[]>(
      `skills${
        // eslint-disable-next-line eqeqeq
        keyword !== '' && keyword != null
          ? `?search=${keyword}&active=true`
          : ''
      }`,
    );
    return skills;
  }

  public async getMentors(): Promise<LearningProfile[]> {
    const mentors = await this.apiUtils.get<LearningProfile[]>('mentors');
    return mentors;
  }

  public async getMentorById(
    userId: string | number,
  ): Promise<LearningProfile> {
    const mentors = await this.apiUtils.get<LearningProfile>(
      `mentors/${userId}`,
    );
    return mentors;
  }

  public async getMyProfile(): Promise<LearningProfile> {
    const customHeaders = await this.apiUtils.getMsAccessTokenHeader();

    const mentors = await this.apiUtils.get<LearningProfile>(
      `me`,
      customHeaders,
    );
    return mentors;
  }

  public async updateProfile(
    profile: UpsertLearningProfileRequest,
  ): Promise<any> {
    const mentor = await this.apiUtils.put<any>('profiles', profile);
    return mentor;
  }

  public async getMentorsByParams(
    queryParams: any,
  ): Promise<LearningProfile[]> {
    let searchMentor = '';
    const queries = Object.entries(queryParams);
    queries.forEach(([key, value], index) => {
      if (value) {
        searchMentor += `?${key}=${encodeURIComponent(value.toString())}${
          index < queries.length - 1 ? '&' : ''
        }`;
      }
    });

    const mentors = await this.apiUtils.get<LearningProfile[]>(
      `mentors${searchMentor}`,
    );
    return mentors;
  }

  public async logMentorshipConnection(
    request: UpsertMentorConnectionRequest,
  ): Promise<UpsertMentorConnectionResponse> {
    const res = await this.apiUtils.put<UpsertMentorConnectionResponse>(
      'mentor-connections',
      request,
    );
    return res;
  }

  public async getMentorshipConnection(): Promise<MentorshipConnection[]> {
    const mentorConnection = await this.apiUtils.get<MentorshipConnection[]>(
      'mentor-connections',
    );
    return mentorConnection;
  }
}
