import { TableHeaderType, TableRowType } from './types';
import { getColumnType } from './utils';

export const validateTableData = <T>(
  data: TableRowType<T>[],
  headers: TableHeaderType[],
) => {
  if (!data) {
    throw new Error('Table data is undefined');
  }

  if (!headers) {
    throw new Error('Table headers are undefined');
  }

  // Check that the headers are not empty
  if (Object.keys(headers).length === 0) {
    throw new Error('Table headers are empty');
  }

  if (data.length === 0) {
    return;
  }

  // Check that the column keys in the header and the keys used in the data match
  if (
    headers
      .map(header => header.key)
      .some(headerKey => !Object.keys(data[0].columns).includes(headerKey))
  ) {
    throw new Error(
      `The column keys in the header and data do not match. Please check the column keys in the header and data
      \nHeader keys: ${headers.map(header => header.key).join(', ')}
      \nData keys: ${Object.keys(data[0].columns).join(', ')}`,
    );
  }
  // Allow `filterable: true` only on columns with type 'label' or 'status'
  if (
    headers
      .filter(header => header.filterable)
      .some(header => {
        const columnType = getColumnType(header.key, data);
        return columnType !== 'label' && columnType !== 'status';
      })
  ) {
    throw new Error(
      `You can only use 'filterable: true' on a column with type "label" or "status".
      Please check the header data you have passed to the <Table /> component.
      `,
    );
  }
};
