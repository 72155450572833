import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function AlignCenter(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M13.5 6.5h13c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5h-13c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5Zm-6 8h25c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5h-25c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5ZM12 24c0-.813.625-1.5 1.5-1.5h13c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5h-13c-.875 0-1.5-.625-1.5-1.5Zm-4.5 6.5h25c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5h-25c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5Z" />
    </SvgIcon>
  );
}
