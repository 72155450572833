import { Entity } from '@backstage/catalog-model';
import {
  DefaultEntityFilters,
  EntityAutocompletePicker,
  EntityFilter,
} from '@backstage/plugin-catalog-react';
import React from 'react';

class ToolCategoryFilter implements EntityFilter {
  constructor(readonly values: string[]) {}
  filterEntity(entity: Entity): boolean {
    const categories = entity.metadata.categories as string[];
    const filteredValues = this.values.filter(value =>
      categories.includes(value),
    );
    return (
      categories !== undefined &&
      categories.length !== 0 &&
      filteredValues.length !== 0
    );
  }

  toQueryValue(): string[] {
    return this.values;
  }
}

export type CustomFilters = DefaultEntityFilters & {
  categories?: ToolCategoryFilter;
};

export const ToolCategoryPicker = () => {
  return (
    <EntityAutocompletePicker<CustomFilters>
      label="Category"
      name="categories"
      path="metadata.categories"
      Filter={ToolCategoryFilter}
    />
  );
};
