import React from 'react';
import MuiButton, { ButtonProps } from '@mui/material/Button';
import { BaseplateAnalyticsEventAttributes } from '@lego/plugin-baseplate-common';
import { useAnalytics } from '@backstage/core-plugin-api';
import { getNodeText } from './getNodeText';
import { useButtonStyle } from './Button.style';
import { Spinner } from '../spinner';

export type ButtonVariants = 'primary' | 'secondary' | 'text' | 'destructive';

type Props = Omit<
  ButtonProps,
  'variant' | 'color' | 'size' | 'disableFocusRipple' | 'disableRipple'
> & {
  target?: string;
  variant: ButtonVariants;
  size?: 'small' | 'medium';
  loading?: boolean;
  noTrack?: boolean;
  trackingAttributes?: BaseplateAnalyticsEventAttributes;
};

/**
 * A custom button component that extends the MUI Button component.
 * @docs https://baseplate.legogroup.io/docs/default/component/baseplate
 */
export const Button = ({
  variant = 'primary',
  type = 'button',
  loading,
  noTrack,
  trackingAttributes,
  onClick,
  ...props
}: Props) => {
  const classes = useButtonStyle();
  const variantProps = (v: ButtonVariants) =>
    ((
      {
        primary: { variant: 'contained', color: 'primary' },
        secondary: { variant: 'outlined', color: 'primary' },
        text: { variant: 'text' },
        destructive: { variant: 'contained', color: 'error' },
      } as const
    )[v]);

  const analytics = useAnalytics();

  const handleClick = (event: React.MouseEvent<any, MouseEvent>) => {
    onClick?.(event);
    if (!noTrack) {
      analytics.captureEvent('click', getNodeText(props.children), {
        attributes: trackingAttributes,
      });
    }
  };

  return (
    <MuiButton
      {...props}
      type={type}
      className={[props.className, classes.root].join(' ')}
      endIcon={
        loading ? (
          <>
            {props.endIcon} <Spinner type="circle" />
          </>
        ) : (
          props.endIcon
        )
      }
      {...variantProps(variant)}
      onClick={handleClick}
    />
  );
};
