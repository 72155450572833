import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ArrowsUpDownLeftRight(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="m21.086 4.414 4.437 4.5c.625.625.625 1.563 0 2.125-.562.625-1.5.625-2.062 0l-1.938-1.937v9.375h9.375l-1.937-1.938c-.625-.562-.625-1.5 0-2.125.562-.562 1.5-.562 2.125 0l4.5 4.5c.562.625.562 1.563 0 2.125l-4.5 4.5c-.625.625-1.563.625-2.125 0-.625-.562-.625-1.5 0-2.125l1.937-1.937h-9.375v9.437l1.938-1.937c.562-.625 1.5-.625 2.125 0 .562.562.562 1.5 0 2.062l-4.5 4.5c-.625.625-1.563.625-2.125 0l-4.5-4.5a1.369 1.369 0 0 1 0-2.062c.562-.625 1.5-.625 2.125 0l1.937 1.937v-9.437H9.086l1.937 1.937c.625.625.625 1.563 0 2.125-.562.625-1.5.625-2.062 0l-4.5-4.5c-.625-.562-.625-1.5 0-2.125l4.5-4.437a1.369 1.369 0 0 1 2.062 0c.625.562.625 1.5 0 2.062l-1.937 1.938h9.375V9.102l-1.938 1.937c-.562.625-1.5.625-2.062 0-.625-.562-.625-1.5 0-2.125l4.5-4.5c.562-.562 1.5-.562 2.125 0Z" />
    </SvgIcon>
  );
}
