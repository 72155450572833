import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Square(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M30 9H10c-.563 0-1 .5-1 1v20c0 .563.438 1 1 1h20c.5 0 1-.438 1-1V10c0-.5-.5-1-1-1ZM10 6h20c2.188 0 4 1.813 4 4v20c0 2.25-1.813 4-4 4H10c-2.25 0-4-1.75-4-4V10c0-2.188 1.75-4 4-4Z" />
    </SvgIcon>
  );
}
