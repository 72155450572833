import { PopupCardShowOnce } from '@lego/plugin-baseplate-core-components';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserSubscriptionDetails } from '../../hooks/useUserSubscriptionDetails';
import { StatusMessage } from '../../types';
import GithubCopilotPopupBanner from './GithubCopilotPopupBanner.svg';

export const GithubCopilotPopupCard = () => {
  const { statusMessage } = useUserSubscriptionDetails();
  const navigate = useNavigate();
  const handleGetAccessClick = () => {
    navigate('/catalog/default/tool/github-copilot');
  };

  return (
    <PopupCardShowOnce
      showOnceIdentifier="tool-github-copilot"
      show={statusMessage === StatusMessage.INACTIVE}
      title="GitHub Copilot is here!"
      image={GithubCopilotPopupBanner}
      description="Code less, create more! Unleash AI for instant code magic."
      confirmButton={{
        text: 'Get access',
        onClick: handleGetAccessClick,
      }}
      cancelButton={{
        text: 'Not interested',
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onClick: () => {},
      }}
    />
  );
};
