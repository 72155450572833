import React from 'react';
import {
  FiveStarFeedbackToastify,
  ONE_MONTH_IN_MILLISECONDS,
} from '@lego/plugin-baseplate-core-components';
import { useLocation } from 'react-router-dom';
import { PREFIX_FEEDBACK_SUBJECT } from '@lego/plugin-baseplate-core-components';
import { deduceFeedbackDetails } from './deducesFeedbackDetails';

function renderFiveStarFeedbackToastify(
  feedbackSubject: string,
  trackingIdentifier: string,
) {
  return (
    <FiveStarFeedbackToastify
      feedbackSubject={`${PREFIX_FEEDBACK_SUBJECT} ${feedbackSubject}`}
      trackingIdentifier={trackingIdentifier}
      doNotAskAgainForFeedbackWithSameTrackingIdentifierInMilliSeconds={
        ONE_MONTH_IN_MILLISECONDS
      }
      showToastDelay={60000}
    />
  );
}

export function FiveStarFeedbackToastifyPathTrigger() {
  const { pathname } = useLocation();
  const feedbackDetails = deduceFeedbackDetails(pathname);

  if (feedbackDetails) {
    return renderFiveStarFeedbackToastify(
      feedbackDetails.feedbackSubject,
      feedbackDetails.trackingIdentifier,
    );
  }

  return <></>;
}
