import React, { useEffect } from 'react';
import { useTheme } from '@material-ui/core';
import { ToastPosition, toast } from 'react-toastify';
import { BackstageTheme } from '@backstage/theme';
import {
  doNotAskAgainForFeedback,
  suppressAnyToastifiedFeedbackFor1Month,
  isAnyToastifiedFeedbackSuppressed,
  wasFeedbackGiven,
} from './feedbackGivenLocalStorageTTL';
import { FiveStarFeedback, FiveStarFeedbackProps } from './FiveStarFeedback';
import { ONE_MONTH_IN_MILLISECONDS } from './utils';
import {
  FEEDBACK_SUBJECT_DEFAULT_OBJECT,
  PREFIX_FEEDBACK_SUBJECT,
} from './feedbackSubjectConstants';

export interface FiveStarFeedbackToastifyProps {
  position?: ToastPosition;
  /**
   * The delay in milliseconds after which the toast should be shown. The counter is reset if the trackingIdentifier of FiveStarFeedbackProps changes.
   */
  showToastDelay?: number;
  autoCloseToastAfter?: number;
}

export function FiveStarFeedbackToastify({
  feedbackSubject = `${PREFIX_FEEDBACK_SUBJECT} ${FEEDBACK_SUBJECT_DEFAULT_OBJECT}`,
  onFeedbackSubmitted,
  onClicked,
  onClosed,
  noTrack,
  trackingAttributes,
  position,
  showToastDelay = 60000, // 1 minute
  trackingIdentifier,
  doNotAskAgainForFeedbackWithSameTrackingIdentifierInMilliSeconds = ONE_MONTH_IN_MILLISECONDS,
  autoCloseToastAfter = 20000, // 20 seconds
  webhook,
}: FiveStarFeedbackProps & FiveStarFeedbackToastifyProps) {
  const toastId = React.useRef<number | string | null>(null);
  const theme = useTheme<BackstageTheme>();

  useEffect(() => {
    const toastOnClicked = () => {
      if (toastId.current) {
        toast.update(toastId.current, {
          autoClose: false,
          containerId: 'behind-side-panel',
        });
      }
      if (onClicked) {
        onClicked();
      }
    };

    const toastOnClosed = () => {
      if (toastId.current) {
        toast.dismiss(toastId.current);
      }
      if (onClosed) {
        onClosed();
      }
    };

    const triggerToast = toastFiveStarFeedback({
      feedbackSubject,
      onFeedbackSubmitted,
      onClicked: toastOnClicked,
      onClosed: toastOnClosed,
      noTrack,
      trackingAttributes,
      position,
      trackingIdentifier,
      doNotAskAgainForFeedbackWithSameTrackingIdentifierInMilliSeconds,
      autoCloseToastAfter,
      webhook,
      themePalette: theme.palette.type,
    });

    function scheduleToast() {
      return setTimeout(() => {
        if (
          !(trackingIdentifier && wasFeedbackGiven(trackingIdentifier)[0]) &&
          !isAnyToastifiedFeedbackSuppressed()
        ) {
          toastId.current = triggerToast();
        }
      }, showToastDelay);
    }

    let timeoutId = scheduleToast();

    const handleVisibilityChange = () => {
      if (document.hidden) {
        clearTimeout(timeoutId);
      } else {
        timeoutId = scheduleToast();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      clearTimeout(timeoutId);
    };
  }, [
    autoCloseToastAfter,
    feedbackSubject,
    noTrack,
    onClicked,
    onClosed,
    onFeedbackSubmitted,
    position,
    showToastDelay,
    doNotAskAgainForFeedbackWithSameTrackingIdentifierInMilliSeconds,
    trackingAttributes,
    trackingIdentifier,
    webhook,
    theme,
  ]);

  return <> </>;
}

export function toastFiveStarFeedback({
  feedbackSubject = `${PREFIX_FEEDBACK_SUBJECT} ${FEEDBACK_SUBJECT_DEFAULT_OBJECT}`,
  onFeedbackSubmitted,
  onClicked,
  onClosed,
  noTrack,
  trackingAttributes,
  position,
  trackingIdentifier,
  doNotAskAgainForFeedbackWithSameTrackingIdentifierInMilliSeconds = ONE_MONTH_IN_MILLISECONDS,
  autoCloseToastAfter = 20000, // 20 seconds
  webhook,
  themePalette,
}: FiveStarFeedbackProps &
  FiveStarFeedbackToastifyProps & { themePalette: 'light' | 'dark' }) {
  return () => {
    doNotAskAgainForFeedback(
      trackingIdentifier,
      doNotAskAgainForFeedbackWithSameTrackingIdentifierInMilliSeconds,
    );
    suppressAnyToastifiedFeedbackFor1Month();
    return toast(
      <FiveStarFeedback
        feedbackSubject={feedbackSubject}
        onFeedbackSubmitted={onFeedbackSubmitted}
        onClicked={onClicked}
        onClosed={onClosed}
        noTrack={noTrack}
        trackingAttributes={trackingAttributes}
        trackingIdentifier={trackingIdentifier}
        doNotAskAgainForFeedbackWithSameTrackingIdentifierInMilliSeconds={
          doNotAskAgainForFeedbackWithSameTrackingIdentifierInMilliSeconds
        }
        webhook={webhook}
      />,
      {
        position: position ? position : 'bottom-right',
        autoClose: autoCloseToastAfter,
        hideProgressBar: false,
        closeOnClick: false,
        closeButton: true,
        pauseOnHover: true,
        draggable: true,
        theme: themePalette,
        containerId: 'behind-side-panel',
      },
    );
  };
}
