import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function MinifigureFaceless1(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M11.75 27c-2.41 0-4.36 1.55-5.28 3.28C5.56 31.99 5 34.63 5 36h3c.12-2.03 1.202-6.05 3.75-6.05h16.5c2.548 0 3.63 4.02 3.75 6.05h3c0-1.37-.56-4.01-1.47-5.72-.92-1.74-2.78-3.28-5.28-3.28h-16.5Zm2.98-2.25h10.56c2.301-.09 4.21-2.118 4.21-4.31v-8.45c0-2.274-1.67-4.31-3.98-4.31V6.1a2.1 2.1 0 0 0-2.1-2.1h-6.85a2.1 2.1 0 0 0-2.1 2.1v1.58c-2.296 0-4.01 2.02-3.97 4.32v8.47c0 2.194 1.919 4.232 4.23 4.28Zm2.24-17.07V6.5h6.08v1.18h-6.08Zm8.07 3c.837 0 1.46.71 1.46 1.53v8.04c0 .814-.692 1.5-1.5 1.5H15c-.806 0-1.5-.712-1.5-1.51v-8.01c0-.828.649-1.55 1.49-1.55h10.05Z" />
    </SvgIcon>
  );
}
