import {
  Entity,
  entityKindSchemaValidator,
  EntityMeta,
  KindValidator,
} from '@backstage/catalog-model';
import schema from './Service.v1.alpha.schema.json';

export interface ServiceMetadata extends EntityMeta {
  name: string;
  description: string;
  title: string;
}

export interface ServiceEntity extends Entity {
  apiVersion: 'baseplate.legogroup.io/v1alpha1';
  kind: 'PlatformService';
  metadata: ServiceMetadata;
  spec: {
    type: string;
    defaultServiceForm: string;
  };
}

const validator = entityKindSchemaValidator(schema);

export const serviceEntityV1alpha1Validator: KindValidator = {
  // eslint-disable-next-line @typescript-eslint/require-await
  async check(data: Entity) {
    return validator(data) === data;
  },
};
