import { SidePanel } from '@lego/plugin-baseplate-core-components';
import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import ProductManagerProductTable from './ProductManagerProductTable';
import { Employee } from '@lego/plugin-baseplate-people-to-product-common';

interface Props {
  employee: Employee;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

function ProductManagerSidePanel({ employee, isOpen, setIsOpen }: Props) {
  return (
    <SidePanel title={employee.fullName} isOpen={isOpen} setIsOpen={setIsOpen}>
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <Typography variant="h6">User actions</Typography>
          <ul>
            <Typography component="li">
              Click on the add button to add a product.
            </Typography>
            <Typography component="li">
              Click on the remove button to remove a product.
            </Typography>
          </ul>
        </Grid>
        <Grid item>
          <ProductManagerProductTable employeeId={employee.id} />
        </Grid>
      </Grid>
    </SidePanel>
  );
}

export default ProductManagerSidePanel;
