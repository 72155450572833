import { Typography } from '@material-ui/core';
import React from 'react';
import { useSupportStyles } from './support.styles';

interface Props {
  title: string;
  subtitle: string;
}

export const SupportPageTitle = ({ title, subtitle }: Props) => {
  const classes = useSupportStyles();
  return (
    <div className={classes.titleWrapper}>
      <Typography
        variant="subtitle2"
        color="secondary"
        style={{ textTransform: 'capitalize' }}
      >
        {subtitle}
      </Typography>
      <Typography variant="h4">{title}</Typography>
    </div>
  );
};
