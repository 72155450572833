import { useSearchParams } from 'react-router-dom';

export function useNonDigitalProductManagerSearchParams() {
  const [searchParams, setSearchParams] = useSearchParams();
  const productsParams = searchParams.get('products');

  let products: boolean | null = null;

  if (productsParams !== null) {
    if (productsParams === 'true' || productsParams === 'false') {
      products = productsParams === 'true';
    }
  }

  return {
    setSearchParams,
    products,
    searchParams,
  };
}
