import { UiParameterType } from '../../types';

/**
 * Recursive function to format parameters into object with the
 * { name: exampleValue } format.
 */
export const formatParameterArray = (params: UiParameterType[]) => {
  if (!params) return undefined;
  const formattedParams: { [key: string]: any } = {};

  params.forEach(param => {
    if (param.items) {
      formattedParams[param.name] = [formatParameterArray(param.items)];
    } else {
      formattedParams[param.name] = param.example || param.type;
    }
  });

  return formattedParams;
};
