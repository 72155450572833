import { githubAuthApiRef, useApi } from '@backstage/core-plugin-api';
import { useTemplateSecrets } from '@backstage/plugin-scaffolder-react';
import useAsync from 'react-use/esm/useAsync';

export const GithubTokenExtension = () => {
  const githubAuth = useApi(githubAuthApiRef);
  const { setSecrets } = useTemplateSecrets();

  useAsync(async () => {
    const token = await githubAuth.getAccessToken();
    setSecrets({ GITHUB_TOKEN: token });
  }, []);

  return null;
};
