import { DesignTokens } from '../types';

export const search = (_tokens: DesignTokens) => ({
  BackstageHighlightedSearchResultText: {
    highlight: {
      color: 'inherit',
      fontWeight: 'bold',
      background: 'none',
    },
  },
});
