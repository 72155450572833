import { DesignTokens } from '../types';

export const banner = (tokens: DesignTokens) => ({
  BackstageDismissableBanner: {
    topPosition: {
      marginBottom: tokens.primitive.dimensions[300],
    },
    content: {
      padding: `1rem ${tokens.primitive.dimensions.page.horizontalPadding}`,
      borderRadius: '0',
      boxShadow:
        '0px -4px 2px -1px rgba(0, 0, 0, 0.09) inset, 0px -2px 2px -1px rgba(0, 0, 0, 0.07) inset, 0px -1px 1px 0px rgba(0, 0, 0, 0.05) inset',
    },
    message: {
      '& a': { color: 'inherit' },
      '& a:hover': { color: 'inherit', textDecoration: 'none' },
    },
    icon: {
      color: tokens.semantic.icon.primary,
      fontSize: '1rem',
    },
    error: {
      color: tokens.semantic.text.error,
      backgroundColor: tokens.semantic.background.error,
    },
    info: {
      color: tokens.semantic.text.info,
      backgroundColor: tokens.semantic.background.info,
    },
    warning: {
      color: tokens.semantic.text.warning,
      backgroundColor: tokens.semantic.background.warning,
    },
  },
  BackstageWarningPanel: {
    panel: {
      backgroundColor: tokens.semantic.background.warning,
    },
    details: {
      backgroundColor: tokens.semantic.background.warning,
      color: tokens.semantic.text.warning,
      borderColor: tokens.semantic.border.warning,
    },
  },
});
