import {
  Button,
  baseplateStyles,
} from '@lego/plugin-baseplate-core-components';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@material-ui/core';
import Box from '@mui/material/Box';
import React, { useState } from 'react';
import NewProductManagerProductTableRow from './NewProductManagerProductTableRow';
import ProductManagerProductTableRow from './ProductManagerProductTableRow';
import { useNonDigitalProductManager } from '../../../hooks';

const useStyles = baseplateStyles(theme => ({
  tableHeader: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    borderTop: `1px solid ${theme.semantic.border.default}`,
    borderBottom: `1px solid ${theme.semantic.border.default}`,
  },
}));

interface EmployeeProductTableProps {
  employeeId: number;
}

function ProductManagerProductTable({ employeeId }: EmployeeProductTableProps) {
  const classes = useStyles();
  const { nonDigitalProductManagers } = useNonDigitalProductManager();
  const [isAddingProduct, setIsAddingProduct] = useState<boolean>(false);

  const productManager = nonDigitalProductManagers.find(
    e => e.id === employeeId,
  );

  const products = productManager?.products || [];

  const tableColumns = [
    { title: 'Name' },
    { title: 'Roles' },
    { title: 'Actions' },
  ];

  const handleOnAddProductButtonClick = () => {
    setIsAddingProduct(true);
  };

  if (!productManager) return null;

  return (
    <Paper>
      <Toolbar>
        <Typography variant="h5">Products</Typography>
        <Box flexGrow={1} />
        <Button
          onClick={handleOnAddProductButtonClick}
          disabled={isAddingProduct}
          variant="primary"
        >
          Add Product
        </Button>
      </Toolbar>
      <Table component={Paper}>
        <TableHead>
          <TableRow>
            {tableColumns.map((column, index) => (
              <TableCell key={index} className={classes.tableHeader}>
                {column.title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {products.map(product => (
            <ProductManagerProductTableRow
              rowData={product}
              employee={productManager}
              disabled={isAddingProduct}
            />
          ))}
          {isAddingProduct && (
            <NewProductManagerProductTableRow
              employee={productManager}
              setIsAddingProduct={setIsAddingProduct}
            />
          )}
        </TableBody>
      </Table>
    </Paper>
  );
}

export default ProductManagerProductTable;
