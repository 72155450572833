import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function TableColumns(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M7 29.8c0 .563.438 1 1 1h10.5v-17H7v16Zm14.5 1H32c.5 0 1-.438 1-1v-16H21.5v17ZM8 5.8h24c2.188 0 4 1.813 4 4v20c0 2.25-1.813 4-4 4H8c-2.25 0-4-1.75-4-4v-20c0-2.187 1.75-4 4-4Z" />
    </SvgIcon>
  );
}
