import { OpenAPIV3_1 } from 'openapi-types';
import { UiParameterType } from '../../../types';

const setEnumProperties = (enumValues: any[]): [string, string[]] => {
  const type = 'enum';
  const enums = Object.values(enumValues) as string[];

  return [type, enums];
};

export const transformSchemaPropertiesToUiProperties = (
  properties: {
    [key: string]: OpenAPIV3_1.SchemaObject;
  },
  required: string[] | undefined,
): UiParameterType[] =>
  Object.entries(properties).map(([name, property]) => {
    let type = property.type as string;
    let enums = undefined;
    let properties = property.properties;

    if (property.enum) {
      [type, enums] = setEnumProperties(property.enum);
    }

    if (property.type === 'array') {
      // This is not a ReferenceObject as it's being resolved recursively in `resolveSchema`
      const items = (property.items as OpenAPIV3_1.SchemaObject) || {};
      if (items.enum) {
        [type, enums] = setEnumProperties(items.enum);
      } else {
        type = items.type as string;
      }
      properties = items.properties;
      type += '[]';
    }

    return {
      name,
      description: property.description,
      type,
      items:
        properties &&
        transformSchemaPropertiesToUiProperties(properties, required),
      enums,
      required: required?.includes(name),
      example: property.example,
    };
  });
