import { ImageList, ImageListItem } from '@material-ui/core';
import React from 'react';
import { IconLink } from './IconLink';
import { ColumnBreakpoints } from './types';
import { useDynamicColumns } from './useDynamicColumns';
import { IconComponent } from '@backstage/core-plugin-api';

export type LinksGridListItem = {
  href: string;
  text?: string;
  Icon?: IconComponent;
};

type Props = {
  items: LinksGridListItem[];
  cols?: ColumnBreakpoints | number;
};

export const LinksGridList = ({ items, cols = undefined }: Props) => {
  const numOfCols = useDynamicColumns(cols);

  return (
    <ImageList rowHeight="auto" cols={numOfCols}>
      {items.map(({ text, href, Icon }, i) => (
        <ImageListItem key={i}>
          <IconLink href={href} text={text ?? href} Icon={Icon} />
        </ImageListItem>
      ))}
    </ImageList>
  );
};
