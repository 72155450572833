import { parseEntityRef } from '@backstage/catalog-model';
import { Table, TableColumn } from '@backstage/core-components';
import { AnalyticsData } from '@lego/plugin-baseplate-analytics-common';
import { Card } from '@lego/plugin-baseplate-core-components';
import { Grid } from '@material-ui/core';
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  BarElement,
} from 'chart.js';
import React from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { Link } from 'react-router-dom';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
);

export const options = {
  responsive: true,
};

interface Props {
  data: AnalyticsData;
  entityRef: string;
}

export const columns: TableColumn[] = [
  {
    title: 'Page',
    field: 'key',
  },
  {
    title: 'Unique visitors',
    field: 'unique_visitors',
  },
  {
    title: 'Total visits',
    field: 'doc_count',
  },
];

export const AnalyticsPageCharts = ({ data, entityRef }: Props) => {
  const { kind, name, namespace } = parseEntityRef(entityRef);
  const ref = `/docs/${namespace}/${kind}/${name}`;

  return (
    <Grid container spacing={3} style={{ padding: 0 }}>
      <Grid item xs={12} md={12} lg={6}>
        <Card
          title="Visitors by page"
          tooltip="
          This chart shows the number of unique visitors and total visits for each page in the documentation.
        "
        >
          <Table
            options={{
              pageSize: 7,
            }}
            columns={[
              {
                title: 'Page',
                field: 'key',
                render: rowData => (
                  <Link to={`${rowData.key}`}>
                    {rowData.key.replace(ref, '')}
                  </Link>
                ),
              },
              {
                title: 'Unique visitors',
                field: 'unique_visitors',
                render: rowData => rowData.unique_visitors.value,
              },
              {
                title: 'Total visits',
                field: 'doc_count',
              },
            ]}
            style={{
              boxShadow: 'none',
              width: '100%',
            }}
            data={data.pageVisits.filter(r => r.key !== ref)}
          />
        </Card>
      </Grid>
      <Grid item xs={12} md={12} lg={6}>
        <Card
          title="Daily hits"
          tooltip="
          This chart shows the number of hits on the documentation over time.
        "
        >
          <Line
            options={options}
            data={{
              labels: data.hitsOverTimeLabel,
              datasets: [
                {
                  label: 'Daily hits',
                  data: data.hitsOverTimeCount,
                  borderColor: 'rgb(75, 192, 192)',
                  backgroundColor: 'rgba(75, 192, 192, 0.2)',
                },
              ],
            }}
          />
        </Card>
      </Grid>
      <Grid item xs={12} md={12} lg={4}>
        <Card
          title="Visitors by product"
          tooltip="This chart shows the number of visitors for each product in the documentation."
        >
          <Bar
            options={options}
            data={{
              labels: data.productVisitors,
              datasets: [
                {
                  label: 'Visitors',
                  data: data.productVisitorsCount,
                  borderColor: 'rgb(75, 192, 192)',
                  backgroundColor: 'rgba(75, 192, 192, 0.2)',
                },
              ],
            }}
          />
        </Card>
      </Grid>
      <Grid item xs={12} md={12} lg={4}>
        <Card
          title="Visitors by sub-domain"
          tooltip="This chart shows the number of visitors for each sub-domain in the documentation."
        >
          <Bar
            options={options}
            data={{
              labels: data.subdomainVisitors,
              datasets: [
                {
                  label: 'Visitors',
                  data: data.subdomainVisitorsCount,
                  borderColor: 'rgb(75, 192, 192)',
                  backgroundColor: 'rgba(75, 192, 192, 0.2',
                },
              ],
            }}
          />
        </Card>
      </Grid>
      <Grid item xs={12} md={12} lg={4}>
        <Card
          title="Visitors by domain"
          tooltip="This chart shows the number of visitors for each domain in the documentation."
        >
          <Bar
            options={options}
            data={{
              labels: data.domainVisitors,
              datasets: [
                {
                  label: 'Visitors',
                  data: data.domainVisitorsCount,
                  borderColor: 'rgb(75, 192, 192)',
                  backgroundColor: 'rgba(75, 192, 192, 0.2)',
                },
              ],
            }}
          />
        </Card>
      </Grid>
    </Grid>
  );
};
