import { useApi } from '@backstage/core-plugin-api';
import { Spinner, showToast } from '@lego/plugin-baseplate-core-components';
import {
  GeneralOverheadProduct,
  OpenPosition,
} from '@lego/plugin-baseplate-people-to-product-common';
import { TableCell, TableRow, TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useState } from 'react';
import useAsync from 'react-use/lib/useAsync';
import { peopleToProductApiRef } from '../../../api/PeopleToProductApi';
import ConfirmationActions from '../../../components/ConfirmationActions/ConfirmationActions';
import { useOpenPosition } from '../../../hooks';

type Props = {
  openPosition: OpenPosition;
  setIsAddingProduct: (isAddingProduct: boolean) => void;
};

function NewOpenPositionGeneralOverheadProductTableRow({
  openPosition,
  setIsAddingProduct,
}: Props) {
  const [selectedGop, setSelectedGop] = useState<GeneralOverheadProduct>();
  const { addGeneralOverheadProductToOpenPosition } = useOpenPosition();

  const peopleToProductApi = useApi(peopleToProductApiRef);

  const { value: generalOverheadProducts, loading } = useAsync(async () => {
    return await peopleToProductApi.fetchGeneralOverheadProducts();
  });

  const handleSave = async () => {
    if (!selectedGop) {
      void showToast({
        type: 'error',
        message:
          'Please select a product that you want to assign to this employee',
      });

      return;
    }
    try {
      await addGeneralOverheadProductToOpenPosition(openPosition, selectedGop);

      void showToast({
        type: 'success',
        message: (
          <>
            <Typography>
              Successfully assigned <strong>{openPosition.position}</strong> to
              the following product:
            </Typography>
            <Typography style={{ fontWeight: 'bold' }}>
              {selectedGop.name} ({selectedGop.id})
            </Typography>
          </>
        ),
      });
      setIsAddingProduct(false);
    } catch (error: any) {
      try {
        const messages = JSON.parse(error.body.error.message);

        void showToast({
          type: 'error',
          message: (
            <>
              <Typography>Failed to update roles. Reasons:</Typography>
              <ul>
                {messages.map((e: Error) => (
                  <li>
                    <Typography>{e.message}</Typography>
                  </li>
                ))}
              </ul>
            </>
          ),
        });
      } catch (_) {
        void showToast({
          type: 'error',
          message:
            'Something went wrong. Please refresh and try again. If the issue persists, please reach out to Enterprise Transparency.',
        });
      }
    }
  };

  const handleCancel = () => {
    setIsAddingProduct(false);
  };

  return (
    <TableRow>
      <TableCell>
        <Autocomplete
          id="gop-dropdown"
          options={generalOverheadProducts ?? []}
          autoSelect
          getOptionLabel={option => option.name}
          onChange={(_, value) => value && setSelectedGop(value)}
          loading={loading}
          style={{ minWidth: 200 }}
          renderInput={params => (
            <TextField
              {...params}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? <Spinner size="large" width={30} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      </TableCell>
      <TableCell />
      <TableCell>
        <ConfirmationActions
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          handleOnSaveButtonClick={handleSave}
          handleOnCancelButtonClick={handleCancel}
        />
      </TableCell>
    </TableRow>
  );
}

export default NewOpenPositionGeneralOverheadProductTableRow;
