/*
 * Copyright 2022 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';
import { AbstractClient } from '../AbstractClient';
import {
  DataProductPrincipal,
  DataProductsApiResponse,
} from '@lego/plugin-baseplate-nexus-databricks-storage-credentials-and-external-locations-common';
import { DataProductsApi } from './DataProductsApi';

export class DataProductsClient
  extends AbstractClient
  implements DataProductsApi
{
  public constructor(options: {
    discoveryApi: DiscoveryApi;
    fetchApi: FetchApi;
  }) {
    super(
      'nexus-databricks-storage-credentials-and-external-locations',
      options.discoveryApi,
      options.fetchApi,
    );
  }

  public async getDataProducts(): Promise<DataProductsApiResponse> {
    return this.fetch({ path: 'data-products', method: 'GET' });
  }

  public async getDataProductPrincipals(
    dataProduct: string,
  ): Promise<DataProductPrincipal[]> {
    return this.fetch({
      path: `data-product/${dataProduct}/principals`,
      method: 'GET',
      showProgress: {
        error: 'Failed to list Data Products',
        pending: false,
        success: false,
      },
    });
  }
}
