import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ChartLineUp(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M7 7.5v22c0 .875.625 1.5 1.5 1.5h26c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5h-26C6 34 4 32 4 29.5v-22C4 6.687 4.625 6 5.5 6 6.313 6 7 6.688 7 7.5Zm17 6c0-.813.625-1.5 1.5-1.5h6.938c.874 0 1.5.688 1.5 1.5v7c0 .875-.626 1.5-1.5 1.5a1.48 1.48 0 0 1-1.5-1.5v-3.375L24 24.063a1.37 1.37 0 0 1-2.063 0L17 19.125l-3.938 3.938c-.624.625-1.562.625-2.124 0a1.369 1.369 0 0 1 0-2.063l5-5c.562-.625 1.5-.625 2.124 0L23 20.938 28.875 15H25.5c-.875 0-1.5-.625-1.5-1.5Z" />
    </SvgIcon>
  );
}
