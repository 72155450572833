import {
  discoveryApiRef,
  fetchApiRef,
  useAnalytics,
  useApi,
} from '@backstage/core-plugin-api';
import { BackstageTheme } from '@backstage/theme';
import { BaseplateAnalyticsEventAttributes } from '@lego/plugin-baseplate-common';
import {
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useCurrentUser, useEnvironmentStage } from '../../hooks';
import { Spinner } from '../../components/spinner';
import {
  BASEPLATE_TEAMS_FEEDBACK_WEBHOOK,
  ONE_MONTH_IN_MILLISECONDS,
  TEAMS_WEBHOOK_BASE_URL,
  getTeamsPostBody,
} from './utils';
import {
  doNotAskAgainForFeedback,
  suppressAnyToastifiedFeedbackFor1Month,
} from './feedbackGivenLocalStorageTTL';
import {
  FEEDBACK_SUBJECT_DEFAULT_OBJECT,
  PREFIX_FEEDBACK_SUBJECT,
} from './feedbackSubjectConstants';

const ASK_OPTIONAL_FEEDBACK_BARRIER = 5;

export interface FiveStarFeedbackProps {
  /**
   * feedbackSubject (string): The subject or title of the feedback prompt that will be shown to the user.
   */
  feedbackSubject?: string;
  /**
   * onFeedbackSubmitted (optional function): A callback function that will be invoked when the user submits their feedback.
   * The function will be passed two arguments: the rating (a number between 1 and 5) and the comment (a string).
   */
  onFeedbackSubmitted?: (rating: number, comment: string) => void;
  /**
   *  onClick (optional function): A callback function that will be invoked when the feedback component is clicked on.
   */
  onClicked?: () => void;
  /**
   *  onClosed (optional function): A callback function that will be invoked when the feedback prompt is closed without the user submitting feedback.
   */
  onClosed?: () => void;
  /**
   *  noTrack (optional boolean): If set to true, no tracking data will be sent.
   */
  noTrack?: boolean;
  /**
   * trackingAttributes (optional object): An object containing key-value pairs that will be sent as tracking data.
   */
  trackingAttributes?: BaseplateAnalyticsEventAttributes;
  /**
   * trackingIdentifier (optional string): A unique identifier for the feedback prompt. If provided, the prompt will not be shown again for the specified duration (in milliseconds)
   * if the user has already submitted feedback with the same identifier
   */
  trackingIdentifier?: string;
  /**
   * doNotAskAgainForFeedbackWithSameTrackingIdentifierInMilliSeconds (optional number): The duration (in milliseconds) for which the feedback prompt should not be shown again
   if the user has already submitted feedback or cancelled with the same tracking identifier.
   */
  doNotAskAgainForFeedbackWithSameTrackingIdentifierInMilliSeconds?: number;
  /**
   * Webook url, used for sending a message to a teams channel. Configured via Connectors - Incoming Webhook
   */
  webhook?: string;
}

export function FiveStarFeedback({
  feedbackSubject = `${PREFIX_FEEDBACK_SUBJECT} ${FEEDBACK_SUBJECT_DEFAULT_OBJECT}`,
  onFeedbackSubmitted,
  onClicked,
  onClosed,
  noTrack,
  trackingAttributes,
  trackingIdentifier,
  doNotAskAgainForFeedbackWithSameTrackingIdentifierInMilliSeconds = ONE_MONTH_IN_MILLISECONDS,
  webhook,
}: FiveStarFeedbackProps) {
  const useStyles = makeStyles<BackstageTheme>(theme => ({
    background: {
      backgroundColor: theme.palette.type === 'light' ? '#fff' : '#121212',
    },
    star: {
      fontSize: 30,
      color: '#ccc',
      cursor: 'pointer',
      transition: 'color 0.2s ease-in-out',
    },
    starFilled: {
      color: ' #f1c40f',
    },
  }));

  const [rating, setRating] = useState(0);
  const [feedbackGiven, setFeedbackGiven] = useState(false);
  const [sending, setSending] = useState(false);
  const [comment, setComment] = useState('');
  const [closed, setClosed] = useState(false);
  const classes = useStyles();
  const analytics = useAnalytics();
  const fetchApi = useApi(fetchApiRef);
  const discoveryApi = useApi(discoveryApiRef);
  const { environment } = useEnvironmentStage();
  const { user } = useCurrentUser();

  const [shouldReporterBeKnown, setShouldReporterBeKnown] = useState(true);

  const handleShouldReporterBeKnownCheckboxChange = () => {
    setShouldReporterBeKnown(!shouldReporterBeKnown);
  };

  useEffect(() => {
    if (feedbackGiven) {
      setTimeout(() => setClosed(true), 4000);
    }
  }, [feedbackGiven, onClosed]);

  useEffect(() => {
    if (closed && onClosed) {
      onClosed();
    }
  }, [closed, onClosed]);

  const addAnalytics = (submittedRating: number) => {
    if (!noTrack) {
      const enrichedTrackingAttributes = {
        ...trackingAttributes,
        rating: submittedRating,
        key: 'five_star_feedback',
        feedbackSubject,
        trackingIdentifier: trackingIdentifier
          ? trackingIdentifier
          : 'undefined',
      };
      analytics.captureEvent('click', 'five_star_feedback', {
        attributes: enrichedTrackingAttributes,
      });
    }
  };

  const handleRatingClick = (
    event:
      | React.MouseEvent<HTMLSpanElement, MouseEvent>
      | React.KeyboardEvent<HTMLSpanElement>,
  ) => {
    if (onClicked) {
      onClicked();
    }
    const newRating = Number(event.currentTarget.dataset.value);
    setRating(newRating);
    if (newRating > ASK_OPTIONAL_FEEDBACK_BARRIER) {
      addAnalytics(newRating);
      if (onFeedbackSubmitted) {
        onFeedbackSubmitted(newRating, '');
      }
      setTimeout(() => setFeedbackGiven(true), 1000);
    }
  };

  const handleCommentChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const newComment = event.currentTarget.value;
    setComment(newComment);
  };

  const sendMessageToTeams = async (
    shouldReporterBeKnownTeams: boolean,
    trackingIdentifierTeams: string,
    feedbackSubjectTeams: string,
    feedbackRating: number,
    feedback: string,
    specificWebhook: string,
    isCarbonCopy: boolean,
  ) => {
    const webhookUrl = specificWebhook?.replace(TEAMS_WEBHOOK_BASE_URL, '');
    try {
      setSending(true);
      const proxUrl = await discoveryApi.getBaseUrl('proxy');

      const response = await fetchApi.fetch(
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        `${proxUrl}/teams/webhook/${webhookUrl}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: getTeamsPostBody({
            user: shouldReporterBeKnownTeams
              ? user
              : {
                  email: 'anonymous.baseplate@lego.com',
                  displayName: 'I prefer to be anonymous',
                },
            environment,
            trackingIdentifier: trackingIdentifierTeams,
            feedbackSubject: feedbackSubjectTeams,
            feedbackRating,
            feedback,
            webhook: specificWebhook,
            isCarbonCopy,
          }),
        },
      );
      if (!response.ok) {
        throw new Error();
      }
      // eslint-disable-next-line no-empty
    } catch (_) {
      // not yet frontend instrumentation available
    } finally {
      setSending(false);
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    addAnalytics(rating);

    const webhooks = [
      BASEPLATE_TEAMS_FEEDBACK_WEBHOOK,
      ...(webhook ? [webhook] : []),
    ];
    await Promise.all([
      webhooks.map(
        async specificWebhook =>
          await sendMessageToTeams(
            shouldReporterBeKnown,
            trackingIdentifier ? trackingIdentifier : 'undefined',
            feedbackSubject,
            rating,
            comment ? comment : 'No comment provided by user',
            specificWebhook,
            !!webhook && specificWebhook === BASEPLATE_TEAMS_FEEDBACK_WEBHOOK,
          ),
      ),
    ]);

    if (onFeedbackSubmitted) {
      onFeedbackSubmitted(rating, comment);
    }
    setFeedbackGiven(true);

    doNotAskAgainForFeedback(
      trackingIdentifier,
      doNotAskAgainForFeedbackWithSameTrackingIdentifierInMilliSeconds,
    );
    suppressAnyToastifiedFeedbackFor1Month();
  };

  const handleCancel = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setClosed(true);
    if (onClosed) {
      onClosed();
    }

    doNotAskAgainForFeedback(
      trackingIdentifier,
      doNotAskAgainForFeedbackWithSameTrackingIdentifierInMilliSeconds,
    );
    suppressAnyToastifiedFeedbackFor1Month();
  };

  if (feedbackGiven) {
    return (
      <div className={classes.background}>
        <Typography variant="h4">Thanks for your feedback! 👍🏼</Typography>
      </div>
    );
  } else if (closed) {
    return <></>;
  }
  return (
    <div className={classes.background}>
      <Typography variant="h4">Give Feedback</Typography>
      <Typography variant="body1">{feedbackSubject}</Typography>
      <div>
        {Array.from({ length: 5 }).map((_, index) => (
          <span
            key={index}
            className={`${classes.star} ${
              rating >= index + 1 ? classes.starFilled : ''
            }`}
            data-value={index + 1}
            onClick={handleRatingClick}
            onKeyDown={handleRatingClick}
            role="button"
            tabIndex={0}
          >
            ★
          </span>
        ))}
      </div>
      {rating <= ASK_OPTIONAL_FEEDBACK_BARRIER && rating > 0 && (
        <div>
          <form
            onSubmit={event => {
              (async () => await handleSubmit(event))();
            }}
          >
            <br />
            <label htmlFor="feedback">
              <Typography variant="body1">
                Do you have any thoughts you would like to share with us?
              </Typography>
            </label>
            <TextField
              id="feedback"
              name="feedback"
              multiline
              minRows={5}
              value={comment}
              onChange={handleCommentChange}
              label="Feedback optional"
              placeholder="Please help us improve brick by brick. Your feedback starts a conversation with us in Teams."
              variant="outlined"
              margin="normal"
              fullWidth
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={shouldReporterBeKnown}
                  onChange={handleShouldReporterBeKnownCheckboxChange}
                  inputProps={{
                    'aria-label':
                      'Baseplate team may contact me with further questions concerning this feedback',
                  }}
                />
              }
              label={
                <Typography variant="body2">
                  Baseplate team may contact me with further questions
                  concerning this feedback
                </Typography>
              }
            />
            <br /> <br />
            <Button variant="contained" color="secondary" type="submit">
              {sending ? (
                <>
                  <Spinner width={25} size="small" />
                </>
              ) : (
                'Send'
              )}
            </Button>
            <Button
              variant="contained"
              style={{ marginLeft: '10px' }}
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </form>
        </div>
      )}
    </div>
  );
}
