import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Bullseye(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M33 19.969c0-4.625-2.5-8.875-6.5-11.25-4.063-2.313-9-2.313-13 0C9.437 11.094 7 15.344 7 19.969c0 4.687 2.438 8.937 6.5 11.312 4 2.313 8.938 2.313 13 0 4-2.375 6.5-6.625 6.5-11.312Zm-29 0C4 14.28 7 9.03 12 6.156c4.938-2.875 11-2.875 16 0 4.938 2.875 8 8.125 8 13.813 0 5.75-3.063 11-8 13.875-5 2.875-11.063 2.875-16 0-5-2.875-8-8.125-8-13.875Zm16 7c2.5 0 4.75-1.313 6-3.5 1.313-2.125 1.313-4.813 0-7-1.25-2.125-3.5-3.5-6-3.5-2.563 0-4.813 1.375-6.063 3.5-1.312 2.187-1.312 4.875 0 7 1.25 2.187 3.5 3.5 6.063 3.5Zm0-17c3.563 0 6.813 1.937 8.625 5 1.813 3.125 1.813 6.937 0 10-1.813 3.125-5.063 5-8.625 5-3.625 0-6.875-1.875-8.688-5-1.812-3.063-1.812-6.875 0-10 1.813-3.063 5.063-5 8.688-5Zm0 11c.5 0 1-.438 1-1 0-.5-.5-1-1-1-.563 0-1 .5-1 1 0 .562.438 1 1 1Zm0-5c1.375 0 2.688.812 3.438 2 .687 1.25.687 2.812 0 4-.75 1.25-2.063 2-3.438 2-1.438 0-2.75-.75-3.5-2-.688-1.188-.688-2.75 0-4 .75-1.188 2.063-2 3.5-2Z" />
    </SvgIcon>
  );
}
