import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ChevronRight(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M28.086 18.906c.562.625.562 1.563 0 2.125l-12 12c-.625.625-1.563.625-2.125 0a1.369 1.369 0 0 1 0-2.062L24.898 20.03l-10.937-11a1.369 1.369 0 0 1 0-2.062 1.369 1.369 0 0 1 2.062 0l12.063 11.937Z" />
    </SvgIcon>
  );
}
