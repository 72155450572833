import { baseplateStyles } from '../../utils';

export const useDropdownMenuStyles = baseplateStyles(theme => ({
  menu: {
    '&.v5-MuiList-root, & .v5-MuiList-root': {
      backgroundColor: theme.semantic.background.neutral[1],
    },
    '& .v5-MuiMenuItem-root': {
      color: theme.semantic.text.primary,
      '&:hover': {
        backgroundColor: theme.semantic.background.neutral[2],
      },
      '&.Mui-selected, &.Mui-selected:hover': {
        backgroundColor: theme.semantic.background.info,
      },
    },
  },
}));
