import { createCardExtension } from '@backstage/plugin-home-react';
import {
  configApiRef,
  createApiFactory,
  createComponentExtension,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  fetchApiRef,
  microsoftAuthApiRef,
} from '@backstage/core-plugin-api';
import {
  EventsClient,
  mentorMatchingApiRef,
  MentorMatchingClient,
  serviceApiRef,
  ServicesClient,
} from './api';
import { rootRouteRef } from './utils/routes';
import { eventsApiRef } from './api/EventsApi';

export const learnDevPlugin = createPlugin({
  id: 'learning-and-development',
  routes: {
    root: rootRouteRef,
  },
  featureFlags: [{ name: 'recurring-events' }],
  apis: [
    createApiFactory({
      api: mentorMatchingApiRef,
      deps: {
        fetchApi: fetchApiRef,
        discoveryApi: discoveryApiRef,
        oAuthApi: microsoftAuthApiRef,
        configApi: configApiRef,
      },
      factory: ({ fetchApi, discoveryApi, oAuthApi, configApi }) =>
        new MentorMatchingClient({
          discoveryApi,
          fetchApi,
          oAuthApi,
          configApi,
        }),
    }),
    createApiFactory({
      api: serviceApiRef,
      deps: {
        fetchApi: fetchApiRef,
        discoveryApi: discoveryApiRef,
        oAuthApi: microsoftAuthApiRef,
        configApi: configApiRef,
      },
      factory: ({ fetchApi, discoveryApi, oAuthApi, configApi }) =>
        new ServicesClient({
          discoveryApi,
          fetchApi,
          oAuthApi,
          configApi,
        }),
    }),
    createApiFactory({
      api: eventsApiRef,
      deps: {
        fetchApi: fetchApiRef,
        discoveryApi: discoveryApiRef,
        oAuthApi: microsoftAuthApiRef,
        configApi: configApiRef,
      },
      factory: ({ fetchApi, discoveryApi, oAuthApi, configApi }) =>
        new EventsClient({
          discoveryApi,
          fetchApi,
          oAuthApi,
          configApi,
        }),
    }),
  ],
});

export const LearnDevPage = learnDevPlugin.provide(
  createRoutableExtension({
    name: 'LearnDevExplorer',
    component: () => import('./components/Router').then(m => m.Router),
    mountPoint: rootRouteRef,
  }),
);

/**
 * A card that displays the events of a product entity.
 *
 * @public
 */
export const EntityEventsCard = learnDevPlugin.provide(
  createComponentExtension({
    name: 'EntityEventsCard',
    component: {
      lazy: () => import('./components/EntityEvents').then(m => m.EntityEvents),
    },
  }),
);

/**
 * A component for the homepage of baseplate, showing the upcoming events.
 *
 * @public
 */
export const HomepageUpcomingEvents = learnDevPlugin.provide(
  createCardExtension({
    name: 'HomepageUpcomingEvents',
    title: 'Upcoming Events',
    description: 'Shows the upcoming events',
    components: () => import('./components/Homepage/UpcomingEvents'),
  }),
);

/**
 * A component for the homepage of baseplate, showing the upcoming events.
 *
 * @public
 */
export const HomepageMyEvents = learnDevPlugin.provide(
  createCardExtension({
    name: 'HomepageMyEvents',
    title: 'My Events',
    description: 'Shows your upcoming events',
    components: () => import('./components/Homepage/MyEvents'),
  }),
);
