import { SchemaObject } from '@asyncapi/parser/esm/spec-types/v2';
import { UiParameterType } from '../../../types';

export const parsePayload = (
  properties: SchemaObject,
  required: string[],
): UiParameterType[] => {
  if (!properties) return [];

  // Recursively parse the payload object
  return Object.entries(properties).map(([key, value]) => {
    return {
      name: key,
      type: value.type,
      value: value.value,
      description: value.description,
      required: required?.includes(key),
      items: value.properties
        ? parsePayload(value.properties, required)
        : undefined,
    };
  });
};
