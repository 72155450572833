import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Backward(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M22 9.875v6.25l11-7.75A1.7 1.7 0 0 1 34.063 8C35.124 8 36 8.875 36 9.938v20.187A1.88 1.88 0 0 1 34.125 32c-.438 0-.813-.063-1.125-.313l-11.063-7.75v6.188A1.842 1.842 0 0 1 20.063 32a2.1 2.1 0 0 1-1.062-.313L4.562 21.25C4.188 20.937 4 20.5 4 20c0-.438.188-.875.563-1.188L19 8.376c.313-.25.688-.375 1.125-.375 1 0 1.875.875 1.875 1.875Zm0 9.875v.563L33 28V12.062L22 19.75ZM8 20l11 7.938V12.124L8 20Z" />
    </SvgIcon>
  );
}
