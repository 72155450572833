import { baseplateStyles } from '@lego/plugin-baseplate-core-components';

export const useTemplateStepStyles = baseplateStyles(theme => ({
  workflowStepContainer: {
    backgroundColor: theme.semantic.background.neutral[1],
    border: `1px solid ${theme.semantic.border.default}`,
    borderRadius: theme.primitive.borderRadius.default,
    overflow: 'hidden',
    transition: 'border-color 200ms ease-in-out',
    marginTop: '1rem',
    '&:hover': {
      borderColor: theme.semantic.border.hover,
    },
  },
  titleContainer: {
    background: 'none',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'left',
    gap: '1rem',
    width: '100%',
    padding: '0.5rem 1rem',
  },
  title: {
    flexGrow: 1,
  },
  statusText: {
    textTransform: 'capitalize',
  },
  chevron: {
    '&$open': {
      transform: 'rotate(180deg)',
    },
  },
  circle: {
    height: 24,
    width: 24,
    borderRadius: theme.primitive.borderRadius.full,
    backgroundColor: theme.semantic.shape.neutral,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logsContainer: {
    backgroundColor: theme.semantic.background.neutral[3],
    borderTop: `1px solid ${theme.semantic.border.default}`,
    padding: '1rem',
    maxHeight: '300px',
    overflowY: 'auto',
  },
  logMessage: {
    display: 'block',
    whiteSpace: 'nowrap',
  },
  open: {},
  failed: {
    backgroundColor: theme.semantic.shape.error,
  },
  completed: {
    backgroundColor: theme.semantic.shape.success,
  },
}));
