import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Bullhorn(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="m31 8.512-.688.687C26.75 12.762 22 14.762 17 14.887v8.062c5 .063 9.75 2.125 13.313 5.688l.687.687V8.512Zm-17 3.375h2.5c4.375 0 8.563-1.688 11.688-4.813L30.563 4.7C31.813 3.45 34 4.324 34 6.137v9c1.125.562 2 2.062 2 3.75 0 1.75-.875 3.25-2 3.812v9c0 1.813-2.188 2.688-3.438 1.438l-2.375-2.375c-3-3-7-4.75-11.187-4.813v6.438c0 1.937-1.625 3.5-3.5 3.5h-2a3.494 3.494 0 0 1-3.5-3.5v-6.5c-2.25 0-4-1.75-4-4v-6c0-2.188 1.75-4 4-4h6Zm-3 14v6.5c0 .312.188.5.5.5h2c.25 0 .5-.188.5-.5v-6.5h-3Zm-3-11c-.563 0-1 .5-1 1v6c0 .562.438 1 1 1h6v-8H8Z" />
    </SvgIcon>
  );
}
