import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Expand(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M14.5 6c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5H9v5.5c0 .875-.688 1.5-1.5 1.5-.875 0-1.5-.625-1.5-1.5v-7C6 6.687 6.625 6 7.5 6h7ZM6 25.5c0-.813.625-1.5 1.5-1.5.813 0 1.5.688 1.5 1.5V31h5.5c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5h-7c-.875 0-1.5-.625-1.5-1.5v-7ZM32.5 6c.813 0 1.5.688 1.5 1.5v7c0 .875-.688 1.5-1.5 1.5-.875 0-1.5-.625-1.5-1.5V9h-5.5c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5h7ZM31 25.5c0-.813.625-1.5 1.5-1.5.813 0 1.5.688 1.5 1.5v7c0 .875-.688 1.5-1.5 1.5h-7c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5H31v-5.5Z" />
    </SvgIcon>
  );
}
