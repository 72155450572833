import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Sitemap(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M22 9h-4v4h4V9Zm-4-3h4c1.625 0 3 1.375 3 3v4a3 3 0 0 1-3 3h-.5v2.5H31c1.875 0 3.5 1.625 3.5 3.5v2h.5c1.625 0 3 1.375 3 3v4a3 3 0 0 1-3 3h-4c-1.688 0-3-1.313-3-3v-4a3 3 0 0 1 3-3h.5v-2c0-.25-.25-.5-.5-.5h-9.5V24h.5c1.625 0 3 1.375 3 3v4a3 3 0 0 1-3 3h-4c-1.688 0-3-1.313-3-3v-4a3 3 0 0 1 3-3h.5v-2.5H9c-.313 0-.5.25-.5.5v2H9c1.625 0 3 1.375 3 3v4a3 3 0 0 1-3 3H5c-1.688 0-3-1.313-3-3v-4a3 3 0 0 1 3-3h.5v-2c0-1.875 1.563-3.5 3.5-3.5h9.5V16H18c-1.688 0-3-1.313-3-3V9a3 3 0 0 1 3-3ZM5 27v4h4v-4H5Zm13 0v4h4v-4h-4Zm13 0v4h4v-4h-4Z" />
    </SvgIcon>
  );
}
