import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function HandPointer(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M16.01 8h-.063v17.25c0 .688-.375 1.25-1.062 1.438-.625.187-1.313-.063-1.625-.563L10.822 22.5c-.312-.5-.937-.625-1.375-.313-.5.313-.625.938-.312 1.375l4.25 6.375A7.008 7.008 0 0 0 19.197 33h7.313c2.437 0 4.5-2 4.5-4.5v-7.438c0-.562-.5-1-1-1-.25 0-.5.063-.625.188-.438.313-1 .438-1.5.25a1.707 1.707 0 0 1-.938-1.125c-.062-.5-.5-.813-.937-.813-.375 0-.625.125-.813.375-.375.5-1.062.75-1.687.5-.625-.187-1-.75-1-1.375 0-.562-.5-1-1-1-.375 0-.625.125-.813.375-.375.5-1.062.75-1.687.5-.625-.187-1-.75-1-1.437V8c0-.5-.5-1-1-1-.563 0-1 .5-1 1Zm1-4c2.187 0 4 1.813 4 4v6.063c.125 0 .312-.063.5-.063 1.312 0 2.5.688 3.25 1.75.375-.125.812-.25 1.25-.25 1.25 0 2.437.625 3.187 1.625.25-.063.5-.125.813-.125 2.187 0 4 1.813 4 4v7.5c0 4.188-3.375 7.5-7.5 7.5h-7.313c-3.375 0-6.437-1.625-8.312-4.438l-4.25-6.312c-1.188-1.813-.688-4.313 1.125-5.563a4.022 4.022 0 0 1 5.25.688V8c0-2.188 1.75-4 4-4Zm3 19v6c0 .563-.5 1-1 1-.563 0-1-.438-1-1v-6c0-.5.437-1 1-1 .5 0 1 .5 1 1Zm3-1c.5 0 1 .5 1 1v6c0 .563-.5 1-1 1-.563 0-1-.438-1-1v-6c0-.5.437-1 1-1Zm5 1v6c0 .563-.5 1-1 1-.563 0-1-.438-1-1v-6c0-.5.437-1 1-1 .5 0 1 .5 1 1Z" />
    </SvgIcon>
  );
}
