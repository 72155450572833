import { format } from 'date-fns';
import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { Typography } from '@material-ui/core';
import { useEventScheduleStyles } from './EventSchedule.styles';
import { Variant } from '@material-ui/core/styles/createTypography';
import { dateFormats } from '@lego/plugin-baseplate-learning-and-development-common';
import { handleDateFormatRangeMonth } from '../../../utils';

type EventScheduleProps = {
  data: any;
  variant?: Variant;
};

export const EventSchedule: React.FC<EventScheduleProps> = ({
  data,
  variant = 'body1',
}: EventScheduleProps) => {
  const styles = useEventScheduleStyles();
  const isTomorrow = (givenDate: any) => {
    const currentDate = new Date();
    const tomorrow = new Date(currentDate);
    tomorrow.setDate(currentDate.getDate() + 1);

    const formattedTomorrow = new Date(tomorrow).toISOString().split('T')[0];
    const formattedGivenDate = new Date(givenDate).toISOString().split('T')[0];
    return formattedGivenDate === formattedTomorrow;
  };

  const isCurrentDate = (givenDate: any) => {
    const formattedCurrentDate = new Date().toISOString().split('T')[0];
    const formattedGivenDate = new Date(givenDate).toISOString().split('T')[0];

    return formattedGivenDate === formattedCurrentDate;
  };

  const pastCurrentDateTime = (endDateTime: any) => {
    return new Date(endDateTime) < new Date();
  };

  const renderDateSchedule = () => {
    const startDate: any = new Date(data.schedules[0].startDateTime);
    const endDate: any = new Date(data.schedules[0].endDateTime);
    const startTime = format(startDate, dateFormats.time);
    const formatedSched = handleDateFormatRangeMonth(
      data.schedules[0].startDateTime,
      data.schedules[0].endDateTime,
    );

    let schedule = formatedSched;
    let className = '';
    if (isCurrentDate(endDate) && pastCurrentDateTime(endDate)) {
      schedule = 'JUST ENDED';
    } else if (isCurrentDate(startDate) && !pastCurrentDateTime(startDate)) {
      schedule = `HAPPENING TODAY AT ${startTime}`;
    } else if (
      isCurrentDate(startDate) &&
      pastCurrentDateTime(startDate) &&
      !pastCurrentDateTime(endDate)
    ) {
      className = 'happeningNow';
      schedule = 'HAPPENING NOW';
    } else if (isTomorrow(startDate)) {
      schedule = `TOMORROW AT ${startTime}`;
    }

    return (
      <Tooltip title={formatedSched || ''} placement="top-start" style={styles}>
        <Typography
          variant={variant}
          className={`${styles[className]} ${styles.schedule} textOverflowEllipsis`}
        >
          {schedule}
        </Typography>
      </Tooltip>
    );
  };

  return <>{renderDateSchedule()}</>;
};

export default EventSchedule;
