import React, { SyntheticEvent, useMemo } from 'react';

import DateRangePicker from 'rsuite/DateRangePicker';
import 'rsuite/DateRangePicker/styles/index.css';

import SingleDatePicker from 'rsuite/DatePicker';
import 'rsuite/DatePicker/styles/index.css';

import { DatePickerProps as PickerProps } from 'rsuite';

import { BaseplateInputProps } from '../input/InputProps.type';
import { useInputStyles } from '../input/Input.styles';
import { Typography } from '@material-ui/core';
import { generateRangeButtons, predefinedRanges } from './predefinedRanges';
import { useDatePickerStyles } from './DatePicker.styles';
import { RangeButtonType } from './types';

type CommonProps = BaseplateInputProps & {
  id: string;
  error?: boolean;
  required?: boolean;
  allowedRange?: { start: Date; end: Date };
};

type SingleDateProps = {
  range?: false;
  value: Date | null;
  predefinedRanges?: never;
  onChange: (value: Date | null, event: SyntheticEvent) => void;
};

type RangeDateProps = {
  range: true;
  predefinedRanges?: RangeButtonType[];
  value: [Date, Date] | null;
  onChange: (value: [Date, Date] | null, event: SyntheticEvent) => void;
};

type DatePickerProps = CommonProps & (SingleDateProps | RangeDateProps);

export const DatePicker = ({
  label,
  id,
  value,
  required,
  onChange,
  helperText,
  error,
  errorText,
  success,
  successText,
  loading,
  range,
  predefinedRanges: customRanges = predefinedRanges,
  allowedRange,
}: DatePickerProps) => {
  const classes = useInputStyles();
  const dateClasses = useDatePickerStyles();
  const { allowedRange: calculateAllowedRange } = DateRangePicker;

  const datePickerProps: Pick<
    PickerProps,
    | 'id'
    | 'size'
    | 'block'
    | 'format'
    | 'showWeekNumbers'
    | 'label'
    | 'cleanable'
    | 'loading'
    | 'className'
    | 'menuClassName'
  > = {
    id,
    block: true,
    size: 'lg',
    format: 'MMMM dd, yyyy',
    showWeekNumbers: true,
    label: '',
    cleanable: false,
    loading,
    className: dateClasses.picker,
    menuClassName: dateClasses.menu,
  };

  const rangeButtons = useMemo(
    () => generateRangeButtons(customRanges ? customRanges : predefinedRanges),
    [customRanges],
  );

  return (
    <div
      className={[
        classes.root,
        dateClasses.root,
        success && classes.success,
        error && classes.error,
      ].join(' ')}
    >
      <Typography variant="body2" component="label" htmlFor={id}>
        {label}{' '}
        {!required && (
          <Typography variant="caption" color="secondary">
            (optional)
          </Typography>
        )}
      </Typography>
      {range ? (
        <DateRangePicker
          value={value}
          placeholder="Select date range"
          onChange={onChange}
          character=" - "
          ranges={rangeButtons}
          shouldDisableDate={
            allowedRange &&
            calculateAllowedRange(allowedRange.start, allowedRange.end)
          }
          {...datePickerProps}
        />
      ) : (
        <SingleDatePicker
          placeholder="Select date"
          value={value}
          onChange={onChange}
          shouldDisableDate={
            allowedRange &&
            calculateAllowedRange(allowedRange.start, allowedRange.end)
          }
          oneTap
          {...datePickerProps}
        />
      )}
      <div className={classes.descriptiveTextContainer}>
        {helperText && (
          <Typography variant="body2" className={classes.helperText}>
            {helperText}
          </Typography>
        )}
        {error && errorText && (
          <Typography variant="body2" className={classes.errorText}>
            {errorText}
          </Typography>
        )}
        {success && successText && (
          <Typography variant="body2" className={classes.successText}>
            {successText}
          </Typography>
        )}
      </div>
    </div>
  );
};
