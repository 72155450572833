import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function AlignLeft(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M7.5 6.5h15c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5h-15C6.625 9.5 6 8.875 6 8c0-.813.625-1.5 1.5-1.5Zm0 8h25c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5h-25c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5ZM6 24c0-.813.625-1.5 1.5-1.5h15c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5h-15c-.875 0-1.5-.625-1.5-1.5Zm1.5 6.5h25c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5h-25c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5Z" />
    </SvgIcon>
  );
}
