import { baseplateStyles } from '../../utils';

export const useEntityLinkStyles = baseplateStyles(token => ({
  wrapper: {
    border: `1px solid ${token.semantic.border.default}`,
    borderRadius: token.primitive.borderRadius.default,
    backgroundColor: token.semantic.background.neutral[1],
    color: token.semantic.text.primary,
    '&:hover': {
      borderColor: token.semantic.border.hover,
      color: token.semantic.text.primary,
      textDecoration: 'none',
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    '&$thumbnail': {
      padding: token.primitive.dimensions[200],
    },
    '&$link': {
      padding: token.spacing(1, 2),
      flexDirection: 'row',
      alignItems: 'center',
      gap: '0.5rem',
    },
    '&$inline-link': {
      display: 'inline-flex',
      // gridTemplateColumns: '24px 1fr',
      padding: token.spacing(0.5, 1),
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      gap: '0.5rem',
      background: 'none',
      border: 0,
      '&:hover': {
        background: token.semantic.background.neutral[3],
      },
    },
  },
  thumbnail: {},
  link: {},
  'inline-link': {},
  highlight: {
    boxShadow: token.component.entity.highlight.shadow,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '0.5rem',
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    width: 32,
    height: 32,
    borderRadius: token.primitive.borderRadius.default,
    objectFit: 'contain',
  },
  title: {
    paddingBottom: 0,
    flexGrow: 1,
    wordBreak: 'break-all',
    overflowWrap: 'break-word',
  },
  starGridItem: {
    padding: 6,
  },
  body: {
    fontSize: 14,
    marginTop: '8px',
    marginBottom: '8px',
  },
  footer: {
    marginTop: '4px',
    display: 'flex',
    gap: '0.25rem',
    justifyContent: 'space-between',
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      gap: '0.25rem',
    },
  },
  linkFooterWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
  },
  linkIcon: {
    display: 'flex',
    alignItems: 'center',
  },
  linkBody: {
    display: 'flex',
    flexDirection: 'row',
    gap: '0.5rem',
  },
  externalLink: {
    display: 'flex',
    justifyContent: 'start',
  },
  linkInFooter: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));
