import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function EyeSlash(props) {
  return (
    <SvgIcon
      viewBox="0 0 41 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="m2.417 4.344 7 5.437c2.812-2.125 6.312-3.812 10.625-3.812 5 0 9.062 2.312 12 5.062 2.937 2.688 4.875 5.938 5.812 8.188a2.24 2.24 0 0 1 0 1.562c-.812 2-2.5 4.875-5 7.375l6.563 5.188c.687.5.812 1.437.25 2.062-.5.688-1.438.813-2.063.25l-37-29c-.687-.5-.812-1.437-.25-2.062.5-.688 1.438-.813 2.063-.25Zm9.437 7.375 2.875 2.25c1.438-1.25 3.25-2 5.313-2 4.375 0 8 3.625 8 8 0 1.375-.313 2.625-.938 3.687l3.375 2.625c2.188-2.125 3.688-4.562 4.438-6.312-.813-1.875-2.5-4.5-4.938-6.75-2.562-2.375-5.875-4.25-9.937-4.25-3.188 0-5.875 1.125-8.188 2.75ZM24.667 21.78a4.48 4.48 0 0 0 .375-1.812c0-2.75-2.25-5-5-5h-.125c.062.375.125.687.125 1 0 .687-.188 1.25-.438 1.812l5.063 4Zm.625 8.125 2.625 2.063a16.36 16.36 0 0 1-7.875 2c-5.063 0-9.125-2.25-12.063-5-2.937-2.75-4.875-6-5.812-8.188a2.24 2.24 0 0 1 0-1.562c.625-1.438 1.625-3.25 3.062-5.125l2.313 1.875c-1.125 1.437-1.938 2.875-2.438 4 .875 1.875 2.5 4.562 4.938 6.812 2.562 2.375 5.875 4.188 10 4.188 1.875 0 3.625-.375 5.25-1.063Zm-13.25-9.937h-.063c0-.125.063-.313.063-.5l3.5 2.75c.625 1.312 1.875 2.312 3.375 2.625l3.5 2.812a9.892 9.892 0 0 1-2.375.313c-4.438 0-8-3.563-8-8Z" />
    </SvgIcon>
  );
}
