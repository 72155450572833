import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Globe(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M20.031 33c.438 0 1.688-.438 2.938-3a15.774 15.774 0 0 0 1.375-4h-8.688c.375 1.563.813 2.875 1.375 4 1.313 2.563 2.5 3 3 3Zm-4.875-10h9.688a22.74 22.74 0 0 0 .187-3c0-1-.062-2-.187-3h-9.688c-.125 1-.125 2-.125 3 0 1.063 0 2.063.125 3Zm.5-9h8.688a16.16 16.16 0 0 0-1.375-3.938C21.719 7.5 20.469 7 20.03 7c-.5 0-1.687.5-3 3.063-.562 1.124-1 2.437-1.375 3.937Zm12.188 3c.125 1 .125 2 .125 3 0 1.063 0 2.063-.125 3h4.812c.25-.938.375-1.938.375-3 0-1-.125-2-.375-3h-4.812Zm3.687-3a12.86 12.86 0 0 0-6.125-5.813c.875 1.626 1.563 3.626 2 5.813h4.125Zm-18.937 0c.437-2.188 1.125-4.188 2-5.813A12.86 12.86 0 0 0 8.469 14h4.125Zm-5.25 3c-.188 1-.313 2-.313 3 0 1.063.063 2.063.313 3h4.812c-.125-.938-.125-1.938-.125-3 0-1 0-2 .125-3H7.344Zm18.062 14.875A12.703 12.703 0 0 0 31.531 26h-4.125c-.437 2.25-1.125 4.25-2 5.875Zm-10.812 0c-.875-1.625-1.563-3.625-2-5.875H8.469a12.703 12.703 0 0 0 6.125 5.875ZM20.03 36c-5.75 0-11-3-13.875-8-2.875-4.938-2.875-11 0-16C9.03 7.062 14.28 4 20.03 4c5.688 0 10.938 3.063 13.813 8 2.875 5 2.875 11.063 0 16-2.875 5-8.125 8-13.813 8Z" />
    </SvgIcon>
  );
}
