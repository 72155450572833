import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function CloudFolderOpen(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M25.625 12.438a3.096 3.096 0 0 1-3.5-.813C20.75 10.062 18.75 9 16.5 9A7.482 7.482 0 0 0 9 16.5v.063c0 1.25-.813 2.375-2 2.812A5.937 5.937 0 0 0 3 25a6.003 6.003 0 0 0 3.501 5.456l-.004.008a1.5 1.5 0 0 1-1.434 2.635l-.004.006A8.962 8.962 0 0 1 0 25c0-3.875 2.5-7.25 6-8.438V16.5C6 10.75 10.688 6 16.5 6c3.125 0 6 1.438 7.938 3.688A7.102 7.102 0 0 1 27.5 9c4.125 0 7.5 3.375 7.5 7.5 0 .388-.05.727-.104 1.105l-.021.145c3 1.313 5.125 4.313 5.125 7.75a8.496 8.496 0 0 1-2.81 6.33 1.5 1.5 0 0 1-1.956-2.274h-.002A5.537 5.537 0 0 0 37 25.5a5.428 5.428 0 0 0-3.313-5c-1.25-.563-2-1.875-1.75-3.25 0-.125.016-.25.032-.375.015-.125.031-.25.031-.375 0-2.438-2.063-4.5-4.5-4.5-.688 0-1.313.188-1.875.438Z" />
      <path d="m13.25 24.388.016 7.841 1.772-3.039c.218-.363.581-.58 1.017-.58h14.521c.4 0 .8.253.98.617.218.363.218.798 0 1.161l-4.065 6.97a1.192 1.192 0 0 1-.98.545l-13.272.007c-1.322 0-2.973-.97-2.973-2.946V23.275a3 3 0 0 1 .646-1.804c.52-.657 1.343-1.12 2.42-1.12h4.113c.813 0 1.433.325 1.887.68.314.246.624.574.835.798.049.052.092.098.13.136.46.476.765.702 1.369.702h4.12c.951 0 1.804.307 2.41.955.585.623.786 1.397.786 2.053v1.131h-3v-.54c0-.371-.224-.6-.654-.6h-3.662c-1.822 0-2.863-.93-3.524-1.613a1.978 1.978 0 0 1-.113-.134c-.18-.222-.458-.567-.836-.567h-3.037c-.63 0-.907.282-.907 1.036Z" />
    </SvgIcon>
  );
}
