import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ThumbsDown(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M24.188 33.851c-2.375.688-4.875-.75-5.563-3.125l-.313-1.25a5.876 5.876 0 0 0-1.25-2.187l-3.187-3.5a1.549 1.549 0 0 1 .063-2.125 1.493 1.493 0 0 1 2.124.125l3.25 3.5c.875.937 1.5 2.125 1.875 3.375l.313 1.25c.25.812 1.063 1.25 1.875 1.062.813-.25 1.25-1.062 1.063-1.875l-.375-1.25a12.083 12.083 0 0 0-1.688-3.5c-.313-.437-.313-1.062-.063-1.562s.75-.813 1.313-.813H32c.5 0 1-.437 1-1 0-.375-.313-.75-.688-.937-.437-.125-.812-.563-.937-1-.125-.5 0-1 .375-1.375.125-.188.25-.375.25-.688 0-.437-.375-.875-.813-.937-.562-.125-1-.5-1.125-.938a1.606 1.606 0 0 1 .188-1.5.955.955 0 0 0 .25-.625c0-.375-.313-.75-.688-.937-.687-.25-1.062-1-.875-1.75 0-.125.063-.188.063-.313 0-.5-.5-1-1-1h-6.125c-.813 0-1.563.25-2.188.688l-3.875 2.562a1.4 1.4 0 0 1-2.062-.437c-.5-.688-.313-1.563.375-2.063L18 7.164a7.076 7.076 0 0 1 3.875-1.188H28c2.125 0 3.875 1.75 4 3.875.875.75 1.5 1.875 1.5 3.125 0 .313-.063.563-.125.813 1 .75 1.625 1.875 1.625 3.187 0 .438-.063.813-.188 1.188.688.75 1.188 1.75 1.188 2.812 0 2.25-1.813 4-4 4h-5.813c.313.688.563 1.375.75 2.063l.375 1.25c.688 2.375-.687 4.875-3.125 5.562ZM6 27.976c-1.125 0-2-.875-2-2v-14c0-1.062.875-2 2-2h4c1.063 0 2 .938 2 2v14c0 1.125-.938 2-2 2H6Z" />
    </SvgIcon>
  );
}
