import { tokens } from '@lego/core-colors';

export const primitive = {
  color: {
    grey: {
      0: tokens.color.core.white,
      ...tokens.color.core.slate,
    },
    red: tokens.color.core.red,
    orange: tokens.color.core.orange,
    yellow: tokens.color.core.yellow,
    green: tokens.color.core.green,
    teal: tokens.color.core.teal,
    blue: tokens.color.core.blue,
    purple: tokens.color.core.purple,
    pink: tokens.color.core.pink,
    azure: tokens.color.core.azur,
    // Legacy
    // @deprecated Use primitive.color.yellow instead
    warning: tokens.color.core.yellow,
    // @deprecated Use primitive.color.red instead
    error: tokens.color.core.red,
    // @deprecated Use primitive.color.green instead
    success: tokens.color.core.green,
  },
  dimensions: {
    '10': 1,
    '25': 2,
    '50': 4,
    '100': 8,
    '150': 12,
    '200': 16,
    '225': 18,
    '300': 24,
    '400': 32,
    '500': 40,
    '600': 48,
    '700': 56,
    '800': 64,
    '900': 72,
    '1000': 80,
    '1200': 96,
    '1400': 112,
    '1600': 128,
    page: {
      horizontalPadding: 'var(--page-horizontal-padding)',
    },
    nav: {
      height: '4rem',
    },
    detailHeader: {
      // Title + tabs height
      height: `${7.8125 + 3}rem`,
    },
  },
  borderRadius: {
    full: 9999,
    lg: 12,
    default: 8,
    sm: 4,
    xs: 2,
  },
  font: {
    family: {
      mono: `monospace`,
    },
  },
};
