import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const baseplatePagerDutyFormPlugin = createPlugin({
  id: 'baseplate-pager-duty-form',
  routes: {
    root: rootRouteRef,
  },
});

export const PagerDutyPageContainer = baseplatePagerDutyFormPlugin.provide(
  createRoutableExtension({
    name: 'PagerDutyPage',
    component: () =>
      import('./components/PagerDutyPageContainer/PagerDutyPageContainer').then(
        m => m.PagerDutyPageContainer,
      ),
    mountPoint: rootRouteRef,
  }),
);

export const EntityComponent = baseplatePagerDutyFormPlugin.provide(
  createRoutableExtension({
    name: 'EntityComponent',
    component: () =>
      import('./components/EntityComponent/EntityComponent').then(
        m => m.EntityComponent,
      ),
    mountPoint: rootRouteRef,
  }),
);
