import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function FilePdf(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M8.5 33h2v3h-2c-2.25 0-4-1.75-4-4V8c0-2.188 1.75-4 4-4h10.313c1.062 0 2.062.438 2.812 1.188l5.688 5.687c.75.75 1.187 1.75 1.187 2.813V22h-3v-8h-5c-1.125 0-2-.875-2-2V7h-10c-.563 0-1 .5-1 1v24c0 .563.438 1 1 1Zm7-7h2c1.875 0 3.5 1.625 3.5 3.5 0 1.938-1.625 3.5-3.5 3.5h-1v2c0 .563-.5 1-1 1-.563 0-1-.438-1-1v-8c0-.5.438-1 1-1Zm2 5c.813 0 1.5-.625 1.5-1.5 0-.813-.688-1.5-1.5-1.5h-1v3h1Zm6-5h2c1.625 0 3 1.375 3 3v4a3 3 0 0 1-3 3h-2c-.563 0-1-.438-1-1v-8c0-.5.438-1 1-1Zm2 8c.5 0 1-.438 1-1v-4c0-.5-.5-1-1-1h-1v6h1Zm5-7c0-.5.438-1 1-1h3c.5 0 1 .5 1 1 0 .563-.5 1-1 1h-2v2h2c.5 0 1 .5 1 1 0 .563-.5 1-1 1h-2v3c0 .563-.5 1-1 1-.563 0-1-.438-1-1v-8Z" />
    </SvgIcon>
  );
}
