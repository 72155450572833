import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Reddit(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M16.452 23.206c-.904 0-1.613-.71-1.613-1.613 0-.838.71-1.613 1.613-1.613s1.612.775 1.612 1.613c0 .904-.71 1.613-1.612 1.613ZM36 19.98c0 8.84-7.161 16-16 16s-16-7.16-16-16c0-8.838 7.161-16 16-16s16 7.162 16 16Zm-8.58-2.645c-.581 0-1.097.258-1.549.645-1.42-.967-3.355-1.612-5.548-1.677l1.16-5.097 3.55.839c0 .839.709 1.548 1.612 1.548.903 0 1.613-.71 1.613-1.548 0-.903-.71-1.613-1.613-1.613-.645 0-1.161.323-1.42.839l-4-.839c-.193-.064-.386.065-.386.258l-1.29 5.613c-2.13.065-4.065.71-5.484 1.677a2.253 2.253 0 0 0-1.549-.645c-2.258 0-2.968 3.033-.968 4.065-.064.322-.064.645-.064 1.032 0 3.355 3.806 6.13 8.516 6.13s8.516-2.775 8.516-6.13c0-.387-.064-.71-.129-1.032 2-1.032 1.29-4.065-.968-4.065Zm-4.452 7.484c-1.162 1.226-4.904 1.162-6 0-.13-.129-.387-.129-.58 0-.13.194-.13.452 0 .58 1.483 1.485 5.676 1.485 7.16 0 .13-.128.13-.386 0-.58a.484.484 0 0 0-.58 0Zm.516-4.838c-.903 0-1.613.774-1.613 1.612 0 .904.774 1.613 1.613 1.613.903 0 1.613-.71 1.613-1.613 0-.838-.71-1.613-1.613-1.613Z" />
    </SvgIcon>
  );
}
