import { getNavId } from '../../components/DefinitionTab/sidenav/getNavId';
import { UiChannelType, UiMessageType, UiNavItem } from '../../types';

export const getAsyncApiSideNavItems = (
  channels: UiChannelType[],
): UiNavItem[] => {
  const processMessage = (message: UiMessageType, index: number) => {
    const label = message.name || `Message type #${index + 1}`;
    return {
      key: getNavId(label),
      label: label,
    };
  };

  const processChannels = ({ name, messages }: UiChannelType) => ({
    key: name,
    label: name,
    children: messages.length > 1 ? messages.map(processMessage) : undefined,
  });

  return channels.map(processChannels);
};
