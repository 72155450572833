import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Ellipsis(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M33.023 19.977c0 1.125-.625 2.062-1.5 2.625-.937.562-2.125.562-3 0-.937-.563-1.5-1.5-1.5-2.625 0-1.063.563-2 1.5-2.563.875-.562 2.063-.562 3 0 .875.563 1.5 1.5 1.5 2.563Zm-10 0c0 1.125-.625 2.062-1.5 2.625-.937.562-2.125.562-3 0-.937-.563-1.5-1.5-1.5-2.625 0-1.063.563-2 1.5-2.563.875-.562 2.063-.562 3 0 .875.563 1.5 1.5 1.5 2.563Zm-13 3c-1.125 0-2.062-.563-2.625-1.5-.562-.875-.562-2.063 0-3 .563-.875 1.5-1.5 2.625-1.5 1.063 0 2 .625 2.563 1.5.562.937.562 2.125 0 3-.563.937-1.5 1.5-2.563 1.5Z" />
    </SvgIcon>
  );
}
