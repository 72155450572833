import { Callout } from '@lego/plugin-baseplate-core-components';
import React from 'react';
import { CalloutExtensionProps } from './schema';
import DOMPurify from 'dompurify';
import { useCallOutExtensionStyles } from './CalloutExtenstion.styles';

export const CalloutExtension = ({
  uiSchema,
  schema: { title, description },
}: CalloutExtensionProps) => {
  const styles = useCallOutExtensionStyles();
  const type = uiSchema['ui:options']?.type ?? 'info';
  const sanitizedBody = DOMPurify.sanitize(description ?? '');

  return (
    <div className={styles.calloutExtension}>
      <Callout
        type={type}
        title={title ?? type}
        body={<div dangerouslySetInnerHTML={{ __html: sanitizedBody }} />}
      />
    </div>
  );
};
