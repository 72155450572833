import React, { Dispatch, ReactNode, useEffect, useState } from 'react';
import {
  createVersionedContext,
  createVersionedValueMap,
  useVersionedContext,
} from '@backstage/version-bridge';
import { EntityKindFilter } from '../types';
import { useLocation } from 'react-router';
import { getSearchScope } from '../utils/allSearchFilters';

type SearchModalContextProps = {
  modalOpen: boolean;
  openModal: (scope?: EntityKindFilter) => void;
  closeModal: () => void;
  selectedFilter: EntityKindFilter | null;
  initialFilter: EntityKindFilter | null;
  setSelectedFilter: Dispatch<React.SetStateAction<EntityKindFilter | null>>;
};

export const SearchModalContext = createVersionedContext<{
  1: SearchModalContextProps;
}>('search-modal-context');

export const useSearchModalContext = (): SearchModalContextProps => {
  const context = useVersionedContext<{ 1: SearchModalContextProps }>(
    'search-modal-context',
  );

  if (!context) {
    throw new Error('Search modal context is not available.');
  }

  const contextValues = context.atVersion(1);
  if (!contextValues) {
    throw new Error('No context found for version 1.');
  }

  return contextValues;
};

export const SearchModalContextProvider = ({
  children,
}: {
  children?: ReactNode;
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [initialFilter, setInitialFilter] = useState<EntityKindFilter | null>(
    null,
  );
  const [selectedFilter, setSelectedFilter] = useState<EntityKindFilter | null>(
    null,
  );

  const pathname = useLocation().pathname;

  const openModal = (scope?: EntityKindFilter) => {
    setModalOpen(true);
    setSelectedFilter(scope || null);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  // Logic to open modal on ctrl/cmd + k
  useEffect(() => {
    const handleModalKeyDown = (event: any) => {
      if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 'k') {
        event.preventDefault();
        setModalOpen(prev => !prev);
      }
    };

    document.addEventListener('keydown', handleModalKeyDown);
    return () => {
      document.removeEventListener('keydown', handleModalKeyDown);
    };
  }, [setModalOpen]);

  // Set scope based on current pathname
  useEffect(() => {
    let scope = null;
    if (pathname.startsWith('/tools')) {
      scope = getSearchScope('Tool') || null;
    } else if (pathname.startsWith('/products')) {
      scope = getSearchScope('Product') || null;
    } else if (pathname.startsWith('/api-docs')) {
      scope = getSearchScope('API') || null;
    }
    setInitialFilter(scope);
    setSelectedFilter(scope);
  }, [pathname]);

  const versionedValue = createVersionedValueMap({
    1: {
      modalOpen,
      openModal,
      closeModal,
      selectedFilter,
      setSelectedFilter,
      initialFilter,
    },
  });
  return (
    <SearchModalContext.Provider value={versionedValue}>
      {children}
    </SearchModalContext.Provider>
  );
};
