import { showToast } from '@lego/plugin-baseplate-core-components';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { AddOrRemovalStatusMessage, SubscriptionResponse } from '../types';
import { LogLevel } from '../types/Logging';
import { logToElastic } from '../util/logs';
import { useConfig } from './useConfig';
import { useLoggingData } from './useLoggingData';
import { useUserSubscriptionDetails } from './useUserSubscriptionDetails';

export const useUserSubscription = () => {
  const { BACKEND_BASE_URL, azureAccessToken } = useConfig();
  const { email: userEmail, environment } = useLoggingData();
  const { refetch: refetchUserSubscriptionDetails } =
    useUserSubscriptionDetails();

  const subscribeUser = useMutation<SubscriptionResponse, Error, string>(
    async email => {
      try {
        const response = await axios.post<SubscriptionResponse>(
          `${BACKEND_BASE_URL}/v1/copilot/user-subscription`,
          { email },
          {
            headers: {
              authorization: `Bearer ${azureAccessToken}`,
            },
          },
        );
        return response.data;
      } catch (error) {
        const err = error as Error;
        await logToElastic(
          `Error subscribing user: ${err.message}`,
          LogLevel.ERROR,
          'useUserSubscription.ts',
          userEmail,
          environment,
          BACKEND_BASE_URL,
          azureAccessToken,
        );
        throw error;
      }
    },
    {
      onError: error => {
        void showToast({
          type: 'error',
          message: 'Failed to subscribe user',
          hideProgressBar: true,
        });
        throw error;
      },
      onSuccess: data => {
        switch (data.statusMessage) {
          case AddOrRemovalStatusMessage.USER_ADDED:
            void showToast({
              type: 'success',
              message: 'User subscribed successfully',
              autoClose: 2000,
              hideProgressBar: false,
            });
            break;
          case AddOrRemovalStatusMessage.NOT_IN_ORGANIZATION:
            void showToast({
              type: 'warning',
              message:
                'Could not add user - not found in the GitHub organization',
              hideProgressBar: true,
            });
            break;
          default:
            void showToast({
              type: 'error',
              message: 'Failed to subscribe user',
              hideProgressBar: true,
            });
        }
      },
      onSettled: () => {
        void refetchUserSubscriptionDetails();
      },
    },
  );

  return {
    subscribeUser: (email: string) => subscribeUser.mutate(email, {}),
    isSubscribing: subscribeUser.isLoading,
  };
};
