import {
  createComponentExtension,
  createPlugin,
} from '@backstage/core-plugin-api';
import { PLUGIN_ID } from './config';
import { createCardExtension } from '@backstage/plugin-home-react';

export const finopsScorecardPlugin = createPlugin({
  id: PLUGIN_ID,
});

/**
 * A component for displaying the health of the product that the user is part of.
 *
 * @public
 */
export const ProductHealthCard = finopsScorecardPlugin.provide(
  createCardExtension({
    name: 'ProductHealthCard',
    title: 'Product Health',
    description:
      'Shows a card with the health of the product that the you are part of',
    components: () => import('./components/ProductHealthCard'),
  }),
);

export const ProductHealthBetaCard = finopsScorecardPlugin.provide(
  createComponentExtension({
    component: {
      lazy: () =>
        import('./components/ProductHealthCard').then(
          m => m.ProductHealthBetaCard,
        ),
    },
  }),
);
