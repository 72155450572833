import React, { useEffect, useState } from 'react';
import { Content, Page } from '@backstage/core-components';
import { type ReactElement } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useAsync from 'react-use/lib/useAsync';
import {
  discoveryApiRef,
  fetchApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { Typography } from '@material-ui/core';
import {
  Input,
  EntityDropdown,
  Dropdown,
  Button,
  baseplateStyles,
  Callout,
  Spinner,
} from '@lego/plugin-baseplate-core-components';
import { Link } from '@lego/plugin-baseplate-links-common';
import {
  AvailableKinds,
  AvailableKindsEnum,
} from '@lego/plugin-baseplate-common';
import { parseEntityRef, stringifyEntityRef } from '@backstage/catalog-model';
import { TechDocsPicker } from '@backstage/plugin-techdocs';
import { EntityFilter } from '@backstage/plugin-catalog-react';

const useStyles = baseplateStyles(theme => ({
  formSpacing: {
    marginTop: theme.spacing(2),
  },
  buttonSpacing: {
    marginRight: '12px',
  },
}));

export const ManageLinkDocumentation = () => {
  const classes = useStyles();
  const { linkId } = useParams();
  const navigate = useNavigate();
  const fetchApi = useApi(fetchApiRef);
  const discoveryApi = useApi(discoveryApiRef);
  const [localLink, setLocalLink] = useState<Link | undefined>(undefined);
  const [selectedKind, setSelectedKind] = useState<AvailableKinds>('Product');

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [updateError, setUpdateError] = React.useState<string | null>(null);
  const [updateSuccess, setUpdateSuccess] = React.useState<string | null>(null);

  const { value: currentLink } = useAsync(async () => {
    const baseUrl = await discoveryApi.getBaseUrl('links');
    const response = await fetchApi.fetch(`${baseUrl}/${linkId}`, {
      method: 'GET',
    });

    const result = (await response.json()) as Link;
    return result;
  }, [linkId]);

  const updateLink = async () => {
    try {
      setIsLoading(true);
      const baseUrl = await discoveryApi.getBaseUrl('links');
      const response = await fetchApi.fetch(`${baseUrl}/${linkId}`, {
        method: 'PUT',
        body: JSON.stringify(localLink),
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error(
          `Failed to delete link with status: ${response.statusText}`,
        );
      }
      const data: Link = await response.json();
      setUpdateSuccess(
        "Documentation link has been successfully updated! It might take up to 30 minutes for the link to be updated all over Baseplate, due to Baseplate's ingestion schedules.",
      );
      return data;
    } catch (error) {
      return setUpdateError((error as Error).message);
    } finally {
      setIsLoading(false);
    }
  };

  const getMatchingKind = (lowercaseString: string): string | undefined => {
    // Get all enum entries and find the matching value
    for (const [_key, value] of Object.entries(AvailableKindsEnum)) {
      if (value.toLowerCase() === lowercaseString) {
        return value;
      }
    }
    return undefined;
  };

  useEffect(() => {
    if (currentLink) {
      setLocalLink(currentLink);
      const { kind } = parseEntityRef(currentLink.ownerEntityRef);
      setSelectedKind((getMatchingKind(kind) as AvailableKinds) || 'Product');
    }
  }, [currentLink]);

  const handleInputChange = (field: keyof Link, updatedValue: string) => {
    setLocalLink(prevLink => {
      return prevLink ? { ...prevLink, [field]: updatedValue } : prevLink;
    });
  };

  return (
    <Page themeId="home">
      <Content className="narrow-layout">
        <Typography variant="h3">Edit link</Typography>
        <Typography variant="subtitle2">
          Edit your linked documentation
        </Typography>
        {localLink && (
          <div className={classes.formSpacing}>
            <Input
              label="Title"
              value={localLink.title}
              onChange={e => handleInputChange('title', e.target.value)}
              required
            />
            <Input
              label="Description"
              value={localLink.description}
              onChange={e => handleInputChange('description', e.target.value)}
              required
            />
            <Input
              label="URL"
              value={localLink.url}
              onChange={e => handleInputChange('url', e.target.value)}
              required
            />
            <div />
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2,1fr)',
                alignItems: 'flex-start',
                gap: '1rem',
              }}
            >
              <Dropdown
                label="What's being documented"
                onChange={e =>
                  setSelectedKind(e.target.value as AvailableKinds)
                }
                value={selectedKind}
                options={['Product', 'API', 'Tool', 'Application']}
                required
              />
              <EntityDropdown
                kind={selectedKind as AvailableKinds}
                label={selectedKind}
                handleChange={entity =>
                  handleInputChange(
                    'ownerEntityRef',
                    stringifyEntityRef(entity),
                  )
                }
                value={localLink.ownerEntityRef}
                required
              />
            </div>
          </div>
        )}
        <div className={classes.formSpacing}>
          <Button
            variant="secondary"
            style={{ marginRight: '12px' }}
            onClick={() => navigate(-1)}
          >
            Cancel
          </Button>
          {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
          <Button variant="primary" onClick={async () => updateLink()}>
            Save
          </Button>
        </div>
        <div className={classes.formSpacing}>
          {isLoading && <Spinner size="large" />}
          {!!updateSuccess && (
            <Callout
              type="success"
              title="Successfully updated the link details"
              body={updateSuccess}
            />
          )}
          {!!updateError && (
            <Callout
              type="error"
              title="Something went wrong"
              body={updateError}
            />
          )}
        </div>
      </Content>
    </Page>
  );
};
