import { baseplateStyles } from '@lego/plugin-baseplate-core-components';
import Box from '@mui/material/Box';
import LinearProgress, {
  LinearProgressProps,
} from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import React from 'react';

const useStyles = baseplateStyles(theme => ({
  percentage: {
    color: theme.semantic.text.secondary,
  },
}));

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number; threshold?: number },
) {
  const classes = useStyles();

  const { value, threshold = 90 } = props;

  const getColor = () => {
    if (value === 100) {
      return 'success';
    } else if (value >= threshold) {
      return 'primary';
    }

    return 'secondary';
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" color={getColor()} {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography
          variant="body2"
          className={classes.percentage}
          fontSize="large"
        >{`${Math.round(value)}%`}</Typography>
      </Box>
    </Box>
  );
}

export default LinearProgressWithLabel;
