/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { createApiRef } from '@backstage/core-plugin-api';
import {
  RequestParams,
  ResponseParams,
} from '@lego/plugin-baseplate-ai-common';

/** @public */
export const AiApiRef = createApiRef<AiApi>({
  id: 'plugin.ai.core',
});

/**
 * A client for interacting with the AI plugin API.
 *
 * @public
 */
export type AiApi = {
  getResponse(
    req: RequestParams,
    setLatestResponse: React.Dispatch<React.SetStateAction<string>>,
  ): Promise<ResponseParams>;
  getSummary(req: RequestParams): Promise<ResponseParams>;
};
