import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function AppleMacos(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M27.294 4.273a1.26 1.26 0 0 0-.453-.966 1.32 1.32 0 0 0-1.044-.292c-2.672.402-4.45 1.846-5.551 3.454-1.08 1.575-1.53 3.322-1.615 4.5-.025.337.088.67.314.924.226.255.546.412.89.436 2.976.208 4.924-1.182 6.051-2.964 1.075-1.699 1.408-3.734 1.408-5.092Zm4.673 12.413c-.5-.869-1.691-2.111-3.44-2.854-1.813-.77-4.192-.988-6.93.217l-.044.02c-.783.379-1.28.497-1.67.497-.375 0-.801-.108-1.445-.464-2.124-1.174-4.286-.922-5.963-.232-1.636.672-2.96 1.814-3.59 2.729-3.33 4.832-2.32 12.619 3.605 18.79.793.826 1.641 1.328 2.559 1.52.905.191 1.732.051 2.42-.153.462-.138.958-.34 1.365-.506h.001c.151-.062.29-.119.41-.165.524-.202.872-.292 1.153-.292.322 0 .722.095 1.299.286.133.044.281.096.44.151.445.156.973.34 1.478.468.721.182 1.567.303 2.472.137.926-.171 1.805-.623 2.62-1.411 2.287-2.212 3.76-5.444 4.226-6.798a1.245 1.245 0 0 0-.63-1.515c-1.207-.615-2.575-1.786-3.046-3.234-.42-1.295-.241-3.198 2.464-5.66a1.231 1.231 0 0 0 .246-1.531Z" />
    </SvgIcon>
  );
}
