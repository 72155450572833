import { Link } from '@backstage/core-components';
import { usePermission } from '@backstage/plugin-permission-react';
import { useAADGroupMembership } from '@lego/plugin-baseplate-access-control';
import {
  EntityIcon,
  EnvironmentStages,
  useEnvironmentStage,
} from '@lego/plugin-baseplate-core-components';
import {
  ADMIN_GROUP_ID,
  peopleLeaderAccess,
} from '@lego/plugin-baseplate-people-to-product-common';
import { useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import { useLocation } from 'react-router';
import { GlobalSearchField } from '../search/GlobalSearchField';
import { AccountMenu } from './AccountMenu';
import { MobileNavigation } from './MobileNavigation';
import { useNavigationStyles } from './Navigation.styles';
import { NavigationLogo } from './NavigationLogo';

export type NavigationItem = {
  label: string;
  href: string;
  condition?: boolean;
  icon?: React.ReactNode;
  activeUrls?: RegExp[];
};

export const getLabel = ({
  isLocalEnvironment,
  isDevEnvironment,
  isTestEnvironment,
}: EnvironmentStages) => {
  if (isLocalEnvironment) return 'Local env';
  else if (isDevEnvironment) return 'Dev env';
  else if (isTestEnvironment) return 'Test env';
  return 'Baseplate';
};

export const testNavigationItemCondition = (navigationItem: NavigationItem) => {
  if (navigationItem.condition !== undefined) return navigationItem.condition;
  return true;
};

export const isUrlActive = (url: string, activeUrls?: RegExp[]) => {
  if (!activeUrls) return false;
  return activeUrls.some(regexp => new RegExp(regexp).test(url));
};

export const Navigation = () => {
  const classes = useNavigationStyles();
  const environmentStages = useEnvironmentStage();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { isUserMember: isUserMemberOfPeopleToProductAdminGroup } =
    useAADGroupMembership([ADMIN_GROUP_ID]);

  const location = useLocation();

  const items: NavigationItem[] = [
    {
      label: 'Products',
      href: '/products',
      icon: <EntityIcon kind="Product" size="sm" />,
      activeUrls: [/^\/products/, /^\/catalog\/default\/product\//],
    },
    {
      label: 'APIs',
      href: '/api-docs',
      icon: <EntityIcon kind="API" size="sm" />,
      activeUrls: [/^\/api-docs/, /catalog\/.*\/api\//],
    },
    {
      label: 'Docs',
      href: '/docs',
      icon: <EntityIcon kind="Component" size="sm" />,
      activeUrls: [/^\/docs/, /^\/docs\/default\//],
    },
    {
      label: 'Tools',
      href: '/tools',
      icon: <EntityIcon kind="Tool" size="sm" />,
      activeUrls: [/^\/tools/, /^\/catalog\/default\/tool\//],
    },
    {
      label: 'Templates',
      href: '/create',
      activeUrls: [/^\/create/],
    },
    {
      label: 'P2P',
      href: '/people-to-product',
      condition:
        usePermission({ permission: peopleLeaderAccess }).allowed ||
        isUserMemberOfPeopleToProductAdminGroup,
      activeUrls: [/^\/people-to-product/],
    },
    {
      label: 'Communities',
      icon: <EntityIcon kind="Community" size="sm" />,
      href: '/learning-and-development',
      activeUrls: [/^\/learning-and-development/],
    },
  ].filter(testNavigationItemCondition);

  if (isSmallScreen) {
    return (
      <MobileNavigation items={items} label={getLabel(environmentStages)} />
    );
  }

  return (
    <nav className={classes.navigation}>
      <NavigationLogo label={getLabel(environmentStages)} />
      <div className={classes.navigationItems}>
        {items.map((item, i) => {
          const active = isUrlActive(location.pathname, item.activeUrls);

          return (
            <Link
              key={i}
              to={item.href}
              className={[
                classes.navigationItem,
                active && classes.active,
              ].join(' ')}
              underline="none"
            >
              {item.icon}
              <span>{item.label}</span>
            </Link>
          );
        })}
      </div>
      <div className={classes.settings}>
        <GlobalSearchField scope="Global" color="default" />
        <AccountMenu />
      </div>
    </nav>
  );
};
