import { makeStyles } from '@material-ui/core';

export const useEventsPreviewStyles = makeStyles<any>(theme => ({
  events: {
    padding: 0,
  },
  eventsItem: {
    padding: 16,
    minHeight: 80,

    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.semantic.background.neutral[2],
    },

    '& > .v5-MuiListItemAvatar-root': {
      display: 'flex',
      marginTop: 0,
    },
  },

  photo: {
    display: 'contents',
    margin: 'auto 0',
  },

  eventDetail: {
    margin: 'auto !important',
    marginLeft: '10px !important',
  },

  image: {
    width: '110px',
    height: '70px',
    objectFit: 'cover',
  },
  eventTitle: {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    flex: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    lineClamp: 2,
    '-webkit-box-orient': 'vertical',
    color: theme.semantic.default,
  },
  eventTitleDiv: {
    width: '280px',
  },

  dateTime: {
    fontSize: '12px',

    '& > .happeningNow': {
      color: theme.semantic.text.error,
    },
  },

  eventBtn: {
    width: '100%',
    marginTop: '20px',
    marginBottom: '20px',
    height: '45px',
  },

  imageDiv: {
    display: 'inline-block',
    marginRight: '10px',
    height: '90px',
  },
}));
