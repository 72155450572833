import { createRouteRef, createSubRouteRef } from '@backstage/core-plugin-api';

export const rootEdgeRef = createRouteRef({
  id: 'edge-platform',
});

export const mssqlRouteRef = createSubRouteRef({
  id: 'mssql',
  path: '/mssql',
  parent: rootEdgeRef,
});

export const rootCreateMSSQLPageRef = createRouteRef({
  id: 'Create MsSQL',
});

export const rootMSSQLDetailPageRef = createRouteRef({
  id: 'MSSQL Detail',
});
