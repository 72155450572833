import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Robot(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M20 4c.813 0 1.5.688 1.5 1.5V10H28c3.313 0 6 2.688 6 6v14c0 3.313-2.688 6-6 6H12c-3.313 0-6-2.688-6-6V16c0-3.313 2.688-6 6-6h6.5V5.5c0-.813.625-1.5 1.5-1.5Zm-8 9a3 3 0 0 0-3 3v14c0 1.688 1.313 3 3 3h16a3 3 0 0 0 3-3V16c0-1.625-1.375-3-3-3H12Zm-9 5h1v12H3c-1.688 0-3-1.313-3-3v-6a3 3 0 0 1 3-3Zm34 0c1.625 0 3 1.375 3 3v6a3 3 0 0 1-3 3h-1V18h1ZM13 28h2c.5 0 1 .5 1 1 0 .563-.5 1-1 1h-2c-.563 0-1-.438-1-1 0-.5.438-1 1-1Zm6 0h2c.5 0 1 .5 1 1 0 .563-.5 1-1 1h-2c-.563 0-1-.438-1-1 0-.5.438-1 1-1Zm6 0h2c.5 0 1 .5 1 1 0 .563-.5 1-1 1h-2c-.563 0-1-.438-1-1 0-.5.438-1 1-1Zm-12.5-8c0-.875.438-1.688 1.25-2.125.75-.438 1.688-.438 2.5 0 .75.438 1.25 1.25 1.25 2.125 0 .938-.5 1.75-1.25 2.188-.813.437-1.75.437-2.5 0-.813-.438-1.25-1.25-1.25-2.188ZM25 17.5c.875 0 1.688.5 2.125 1.25.438.813.438 1.75 0 2.5-.438.813-1.25 1.25-2.125 1.25-.938 0-1.75-.438-2.188-1.25-.437-.75-.437-1.688 0-2.5A2.52 2.52 0 0 1 25 17.5Z" />
    </SvgIcon>
  );
}
