import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function BullhornOn(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="m29.191 4.482 1.157-2.335c.376-.723.07-1.628-.653-2.005-.776-.345-1.682-.039-2.059.684L26.48 3.16c-.346.776-.04 1.682.738 2.027.722.377 1.628.07 1.973-.706Zm5.369 2.972-2.582 1.48a1.48 1.48 0 0 0-.549 2.05c.438.758 1.292.986 2.05.549l2.58-1.481a1.48 1.48 0 0 0 .55-2.05 1.52 1.52 0 0 0-2.05-.548Zm4.029 8.944-2.617-.17a1.435 1.435 0 0 0-1.598 1.355c-.09.846.542 1.564 1.387 1.653l2.616.17c.814.036 1.532-.595 1.622-1.44.035-.815-.596-1.533-1.41-1.568Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.08 6.229c-1.143 4.269-3.926 7.824-7.715 10.011l-7.361 4.25A3.988 3.988 0 0 0 .54 25.954l3 5.197c1.125 1.948 3.515 2.589 5.464 1.464l3.25 5.629a3.494 3.494 0 0 0 4.78 1.28l1.733-1c1.624-.937 2.25-3.102 1.28-4.78l-3.218-5.575c3.658-2.04 7.997-2.524 12.095-1.426l3.244.87c1.708.457 3.165-1.395 2.259-2.965l-4.5-7.794c.693-1.05.7-2.786-.175-4.301-.843-1.462-2.351-2.324-3.607-2.248l-4.5-7.794c-.906-1.57-3.238-1.234-3.695.474l-.87 3.244Zm2.903.778.252-.94L30.64 24.092l-.94-.252c-4.866-1.304-10.01-.715-14.372 1.73l-4.031-6.982c4.268-2.608 7.381-6.715 8.685-11.581Zm-5.131 29.737-3.25-5.63 2.598-1.5 3.25 5.63a.493.493 0 0 1-.183.683l-1.732 1c-.27.156-.527.087-.683-.183ZM3.138 24.454c-.25-.433-.121-1.084.366-1.366l5.196-3 4 6.929-5.196 3a.987.987 0 0 1-1.366-.366l-3-5.197Z"
      />
    </SvgIcon>
  );
}
