import React from 'react';
import {
  Callout,
  Chip,
  baseplateStyles,
} from '@lego/plugin-baseplate-core-components';
import { Typography } from '@material-ui/core';
import { LicenceType } from '@lego/plugin-baseplate-common';

type Props = {
  licence?: LicenceType;
  editUrl: string;
};

const useStyles = baseplateStyles(_theme => ({
  wrapper: {
    marginTop: '1rem',
  },
  content: {
    marginTop: '0.5rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '0.5rem',
  },
}));

export const ToolLicence = ({ licence, editUrl }: Props) => {
  const classes = useStyles();

  const Content = () => {
    if (licence?.type === 'paid') {
      return (
        <div className={classes.content}>
          <Typography variant="subtitle2">Paid licence</Typography>
          <Chip
            color="green"
            label={licence.label || 'Provided by the LEGO Group'}
          />
          {licence.description && (
            <Typography variant="body1">{licence.description}</Typography>
          )}
        </div>
      );
    } else if (licence?.type === 'open-source') {
      return (
        <div className={classes.content}>
          <Typography variant="subtitle2">Open source</Typography>
          <Chip color="blue" label={licence?.label} />
          <Typography variant="body1">
            Read more about the LEGO Group's{' '}
            <a href="https://legogroup.sharepoint.com/sites/ITatLEGO/Shared%20Documents/Forms/AllItems.aspx?id=/sites/ITatLEGO/Shared%20Documents/IT%20Security%20Engineering/Open%20Source%20License%20Roadmap.pdf&parent=/sites/ITatLEGO/Shared%20Documents/IT%20Security%20Engineering">
              open source guidelines
            </a>
            .
          </Typography>
        </div>
      );
    } else if (licence?.type === 'internal') {
      return (
        <div className={classes.content}>
          <Typography variant="subtitle2">Internal</Typography>
          <Chip color="purple" label={licence?.label} />
          {licence.description && (
            <Typography variant="body1">{licence.description}</Typography>
          )}
        </div>
      );
    }

    return (
      <div className={classes.content}>
        <Callout
          title="Unknown licence"
          type="error"
          body={
            <>
              <p>
                There’s no information about this softwares licence. Please
                don’t use this software unless you can verify that there is an
                open source licence or that the LEGO® Group has purchased
                licences.
              </p>
              <p>
                <strong>Open source:</strong> If you know the tool is open
                source, please update the licence in this tools{' '}
                <a href={editUrl}>configuration in GitHub</a>.
              </p>
              <p>
                <strong>Licenced tool:</strong> If you own this tool, please
                update the application link in the{' '}
                <a href={editUrl}>GitHub configuration</a> and update any
                necessary fields in the Architecture gateway.
              </p>
            </>
          }
        />
      </div>
    );
  };

  return (
    <div className={classes.wrapper}>
      <Typography
        variant="overline"
        color="secondary"
        className={classes.label}
      >
        Licence
      </Typography>
      <Content />
    </div>
  );
};
