import { baseplateStyles } from '@lego/plugin-baseplate-core-components';

export const useNavigationStyles = baseplateStyles(theme => ({
  navigation: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    height: theme.primitive.dimensions.nav.height,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.semantic.background.neutral[1],
    zIndex: 1200,
    padding: `0 ${theme.primitive.dimensions.page.horizontalPadding}`,
    fontSize: '1rem',
    boxShadow: `inset 0 -1px 0 0 ${theme.semantic.border.default}`,
  },
  logo: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    color: `${theme.semantic.text.primary} !important`,
    padding: '0 0.5rem',
    textDecoration: 'none !important',
    svg: {
      height: 30,
    },
    '&:hover': {
      background: theme.semantic.background.neutral[2],
      boxShadow: `inset 0 -1px 0 0 ${theme.semantic.border.default}`,
    },
  },
  navigationItems: {
    display: 'flex',
    marginLeft: '2rem',
    flexGrow: 1,
    height: '100%',
  },
  navigationItem: {
    height: '100%',
    color: `${theme.semantic.text.primary} !important`,
    textDecoration: 'none !important',
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    padding: '0.5rem 1rem',
    '&:hover': {
      color: theme.semantic.text.primary,
      background: theme.semantic.background.neutral[2],
      boxShadow: `inset 0 -1px 0 0 ${theme.semantic.border.default}`,
    },
    '&$active': {
      boxShadow: `inset 0 -2px 0 0 ${theme.semantic.border.hover}`,
    },
  },
  userProduct: {
    color: theme.semantic.text.primary,
  },
  settings: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '1rem',
    [theme.breakpoints.up('md')]: {
      minWidth: 350,
    },
  },
  active: {},
}));
