import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Linkedin(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M33.714 3.98C34.93 3.98 36 5.052 36 6.338v27.357c0 1.285-1.071 2.285-2.286 2.285h-27.5C5 35.98 4 34.98 4 33.695V6.338C4 5.052 5 3.98 6.214 3.98h27.5ZM13.643 31.41V16.195H8.929v15.214h4.714Zm-2.357-17.357c1.5 0 2.714-1.214 2.714-2.714s-1.214-2.786-2.714-2.786A2.79 2.79 0 0 0 8.5 11.338c0 1.5 1.214 2.714 2.786 2.714ZM31.429 31.41v-8.357c0-4.072-.929-7.286-5.715-7.286-2.285 0-3.857 1.286-4.5 2.5h-.071v-2.071h-4.5V31.41h4.714v-7.5c0-2 .357-3.928 2.857-3.928 2.429 0 2.429 2.285 2.429 4v7.428h4.786Z" />
    </SvgIcon>
  );
}
