import React, { useEffect } from 'react';
import {
  FormHelperText,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Button, Input } from '@lego/plugin-baseplate-core-components';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import {
  CommunityV2,
  CommunityLink,
} from '@lego/plugin-baseplate-communities-common';
import { FormikErrors } from 'formik';

export const CreateCommunityLinks = ({
  title,
  formValues,
  setFormValues,
  setValues,
}: {
  title?: string;
  formValues: CommunityV2;
  setFormValues: React.Dispatch<React.SetStateAction<CommunityV2>>;
  setValues: (
    values: React.SetStateAction<CommunityV2>,
    shouldValidate?: boolean,
  ) => Promise<void | FormikErrors<CommunityV2>>;
}) => {
  const handleAdd = () => {
    const updatedLinks = [...formValues.links, { url: '', label: '' }];
    setFormValues({
      ...formValues,
      links: updatedLinks,
    });
  };

  const handleRemove = (index: number) => {
    const updatedLinks = formValues.links.filter((_, i) => i !== index);
    setFormValues({
      ...formValues,
      links: updatedLinks,
    });
  };

  const handleUpdate = (
    index: number,
    field: keyof CommunityLink,
    value: string,
  ) => {
    const updatedLinks = formValues.links.map((link, i) =>
      i === index ? { ...link, [field]: value } : link,
    );
    setFormValues({
      ...formValues,
      links: updatedLinks,
    });
  };

  return (
    <div>
      <Typography component="label" variant="body2">
        {!!title ? title : 'Links'}
      </Typography>
      {formValues.links &&
        !!formValues.links.length &&
        formValues.links.map((entry, index) => (
          <Grid container key={index} style={{ padding: 0 }}>
            <Grid item xs={3}>
              <Input
                label="Label"
                id={`validateLabel-${index}`}
                value={entry.label}
                onChange={e => handleUpdate(index, 'label', e.target.value)}
                helperText="E.g Baseplate "
              />
            </Grid>
            <Grid item xs={8}>
              <Input
                label="URL"
                id={`validateURL-${index}`}
                value={entry.url}
                required
                onChange={e => handleUpdate(index, 'url', e.target.value)}
                helperText="E.g https://baseplate.io"
              />
            </Grid>
            <Grid
              item
              xs={1}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <IconButton
                onClick={() => handleRemove(index)}
                style={{ height: 'fit-content' }}
              >
                <RemoveCircleOutlineIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}
      <Button
        onClick={handleAdd}
        startIcon={<AddCircleOutlineIcon />}
        fullWidth
        variant="secondary"
      >
        Add link
      </Button>
      <FormHelperText style={{ padding: '0 1rem' }}>
        <Typography variant="body2" color="secondary">
          You can add links to your community. These will show on the
          community's page.
        </Typography>
      </FormHelperText>
    </div>
  );
};
