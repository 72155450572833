import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function HandSwipe(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.51 17.573c.486.297.912.7 1.25 1.177.374-.125.812-.25 1.25-.25 1.25 0 2.437.625 3.187 1.625.25-.063.5-.125.812-.125 2.188 0 4 1.813 4 4v7.5c0 4.188-3.375 7.5-7.5 7.5h-7.312c-3.375 0-6.438-1.625-8.313-4.438l-4.25-6.312c-1.187-1.813-.687-4.313 1.125-5.563a4.022 4.022 0 0 1 5.25.688v-4.222A10.01 10.01 0 0 1 10.203 12h-5.11v.031l2.438 2.438c.625.562.625 1.5 0 2.062v.063c-.562.562-1.5.562-2.062 0l-5-5c-.625-.625-.625-1.563 0-2.125l5-5a1.369 1.369 0 0 1 2.062 0c.625.562.625 1.5 0 2.125L5.125 9h4.924A9.997 9.997 0 0 1 20 0a9.997 9.997 0 0 1 9.951 9h4.955v-.016L32.47 6.547a1.369 1.369 0 0 1 0-2.063v-.062a1.471 1.471 0 0 1 2.062 0l5 5c.625.625.625 1.562 0 2.125l-5 5c-.562.625-1.5.625-2.062 0-.625-.563-.625-1.5 0-2.125L34.89 12h-5.093a9.98 9.98 0 0 1-3.287 5.573Zm-10.5-1.84a6.98 6.98 0 0 1-3.007-5.743C13.003 6.13 16.136 3 20 3a6.994 6.994 0 0 1 6.997 6.99 6.98 6.98 0 0 1-2.988 5.73V11c0-2.188-1.812-4-4-4-2.25 0-4 1.813-4 4v4.732Zm2.937 12.517L19.009 11c0-.5.438-1 1-1 .5 0 1 .5 1 1v8.5c0 .688.375 1.25 1 1.438.625.25 1.313 0 1.688-.5.187-.25.437-.375.812-.375.5 0 1 .437 1 1 0 .625.375 1.187 1 1.375.625.25 1.313 0 1.688-.5.187-.25.437-.375.812-.375.438 0 .875.312.938.812.125.5.5.938.937 1.125.5.188 1.063.063 1.5-.25.125-.125.375-.188.625-.188.5 0 1 .438 1 1V31.5c0 2.5-2.062 4.5-4.5 4.5h-7.312a7.008 7.008 0 0 1-5.813-3.063l-4.25-6.374c-.312-.438-.187-1.063.313-1.375.437-.313 1.062-.188 1.375.312l2.437 3.625c.313.5 1 .75 1.625.563.688-.188 1.063-.75 1.063-1.438Z"
      />
    </SvgIcon>
  );
}
