import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function SlidersSimple(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M9 25c-1.125 0-2 .938-2 2 0 1.125.875 2 2 2 1.063 0 2-.875 2-2 0-1.063-.938-2-2-2Zm4.75.5H34.5c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5H13.75C13.125 30.563 11.187 32 9 32c-2.813 0-5-2.188-5-5 0-2.75 2.188-5 5-5 2.188 0 4.125 1.5 4.75 3.5ZM29 13c0 1.125.875 2 2 2 1.063 0 2-.875 2-2 0-1.063-.938-2-2-2-1.125 0-2 .938-2 2Zm-2.813-1.5C26.813 9.5 28.75 8 31 8c2.75 0 5 2.25 5 5 0 2.813-2.25 5-5 5-2.25 0-4.188-1.438-4.813-3.5H5.5c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5h20.688Z" />
    </SvgIcon>
  );
}
