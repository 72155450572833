import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

export default function Share(props: any) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M16 11.5775V11V9.8C16 9.47 15.73 9.2 15.4 9.2H14.2H13.6H11.8563C9.9475 9.2 8.335 10.4563 7.795 12.185C7.67125 11.8325 7.6 11.4425 7.6 11C7.6 8.67875 9.47875 6.8 11.8 6.8H13.6H14.2H15.4C15.73 6.8 16 6.53 16 6.2V5V4.4225L19.975 8L16 11.5775ZM13.6 11H14.2V12.8C14.2 13.4638 14.7363 14 15.4 14H15.5388C15.835 14 16.12 13.8912 16.3413 13.6925L21.5537 9.00125C21.8388 8.74625 22 8.3825 22 8C22 7.6175 21.8388 7.25375 21.5537 6.99875L16.3713 2.33375C16.1313 2.12 15.8238 2 15.5013 2C14.7813 2 14.2 2.58125 14.2 3.30125V5H13.6H12.4H11.8C8.485 5 5.8 7.685 5.8 11C5.8 13.265 7.0975 14.7163 8.19625 15.5338C8.4175 15.6988 8.6275 15.8375 8.8225 15.9538C8.9875 16.055 9.14125 16.1375 9.26875 16.2013C9.39625 16.265 9.50125 16.3138 9.57625 16.3475C9.65875 16.385 9.74875 16.4 9.8425 16.4H9.93625C10.3038 16.4 10.6038 16.1 10.6038 15.7325C10.6038 15.44 10.405 15.1813 10.1688 15.0013C10.1538 14.99 10.1425 14.9825 10.1275 14.9713C10.0638 14.93 10 14.8775 9.94 14.8175C9.91 14.7875 9.87625 14.7575 9.84625 14.72C9.81625 14.6825 9.78625 14.6488 9.75625 14.6112C9.68875 14.5175 9.625 14.4125 9.56875 14.2925C9.47125 14.0675 9.4075 13.7937 9.4075 13.4525C9.4075 12.0988 10.5063 10.9963 11.8638 10.9963H12.4H13.6V11ZM3.7 3.2C2.2075 3.2 1 4.4075 1 5.9V18.5C1 19.9925 2.2075 21.2 3.7 21.2H16.3C17.7925 21.2 19 19.9925 19 18.5V16.1C19 15.6013 18.5988 15.2 18.1 15.2C17.6013 15.2 17.2 15.6013 17.2 16.1V18.5C17.2 18.9988 16.7988 19.4 16.3 19.4H3.7C3.20125 19.4 2.8 18.9988 2.8 18.5V5.9C2.8 5.40125 3.20125 5 3.7 5H6.1C6.59875 5 7 4.59875 7 4.1C7 3.60125 6.59875 3.2 6.1 3.2H3.7Z" />
    </SvgIcon>
  );
}
