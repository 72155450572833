import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function NetworkWired(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M25 7H15v6h10V7ZM15 4h10c1.625 0 3 1.375 3 3v6a3 3 0 0 1-3 3h-3.5v2.5h17c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5h-7V24H35c1.625 0 3 1.375 3 3v6a3 3 0 0 1-3 3H25c-1.688 0-3-1.313-3-3v-6a3 3 0 0 1 3-3h3.5v-2.5h-17V24H15c1.625 0 3 1.375 3 3v6a3 3 0 0 1-3 3H5c-1.688 0-3-1.313-3-3v-6a3 3 0 0 1 3-3h3.5v-2.5h-7C.625 21.5 0 20.875 0 20c0-.813.625-1.5 1.5-1.5h17V16H15c-1.688 0-3-1.313-3-3V7a3 3 0 0 1 3-3ZM5 27v6h10v-6H5Zm30 0H25v6h10v-6Z" />
    </SvgIcon>
  );
}
