import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

export default function Brick(props: any) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <g transform="translate(8 8)">
        <mask
          id="path-1-outside-1_4162_4816"
          maskUnits="userSpaceOnUse"
          x="0"
          y="-0.000244141"
          width="22"
          height="22"
          fill="currentColor"
        >
          <rect fill="white" y="-0.000244141" width="22" height="22" />
          <path d="M18.7877 21H3.26517C2.66512 20.9977 2.09031 20.7583 1.66601 20.334C1.24171 19.9097 1.00231 19.3348 1 18.7348V3.26492C1.00231 2.66488 1.24171 2.09006 1.66601 1.66576C2.09031 1.24146 2.66512 1.00207 3.26517 0.999756H18.735C19.3351 1.00207 19.9099 1.24146 20.3342 1.66576C20.7585 2.09006 20.9979 2.66488 21.0002 3.26492V18.7875C21.0002 19.3743 20.7671 19.937 20.3522 20.3519C19.9373 20.7669 19.3745 21 18.7877 21ZM3.26517 2.31671C3.0144 2.319 2.77454 2.41964 2.59721 2.59697C2.41988 2.7743 2.31925 3.01415 2.31696 3.26492V18.7348C2.31925 18.9856 2.41988 19.2254 2.59721 19.4027C2.77454 19.5801 3.0144 19.6807 3.26517 19.683H18.7877C19.0206 19.683 19.2439 19.5905 19.4085 19.4259C19.5732 19.2612 19.6657 19.0379 19.6657 18.805V3.26492C19.6634 3.01415 19.5628 2.7743 19.3854 2.59697C19.2081 2.41964 18.9682 2.319 18.7175 2.31671H3.26517Z" />
          <path d="M6.78584 9.73558C6.22843 9.73558 5.68354 9.57029 5.22008 9.26061C4.75661 8.95093 4.39539 8.51078 4.18208 7.9958C3.96877 7.48083 3.91295 6.91416 4.0217 6.36747C4.13044 5.82077 4.39886 5.3186 4.793 4.92446C5.18715 4.53031 5.68932 4.2619 6.23602 4.15315C6.78271 4.04441 7.34937 4.10022 7.86435 4.31353C8.37932 4.52684 8.81948 4.88806 9.12916 5.35153C9.43884 5.815 9.60413 6.35988 9.60413 6.91729C9.60413 7.66475 9.3072 8.38159 8.77867 8.91012C8.25014 9.43865 7.53329 9.73558 6.78584 9.73558ZM6.78584 5.41596C6.4889 5.41596 6.19863 5.50401 5.95174 5.66898C5.70485 5.83394 5.51242 6.06842 5.39879 6.34275C5.28515 6.61709 5.25542 6.91895 5.31335 7.21018C5.37128 7.50141 5.51427 7.76893 5.72423 7.97889C5.9342 8.18886 6.20171 8.33185 6.49294 8.38977C6.78417 8.4477 7.08604 8.41797 7.36037 8.30434C7.6347 8.19071 7.86918 7.99828 8.03415 7.75138C8.19912 7.50449 8.28717 7.21422 8.28717 6.91729C8.28486 6.51982 8.12595 6.13929 7.84489 5.85823C7.56384 5.57718 7.1833 5.41826 6.78584 5.41596Z" />
          <path d="M15.0651 9.73558C14.5077 9.73558 13.9628 9.57029 13.4994 9.26061C13.0359 8.95093 12.6747 8.51078 12.4614 7.9958C12.248 7.48083 12.1922 6.91416 12.301 6.36747C12.4097 5.82077 12.6781 5.3186 13.0723 4.92446C13.4664 4.53031 13.9686 4.2619 14.5153 4.15315C15.062 4.04441 15.6287 4.10022 16.1436 4.31353C16.6586 4.52684 17.0988 4.88806 17.4084 5.35153C17.7181 5.815 17.8834 6.35988 17.8834 6.91729C17.8834 7.66475 17.5865 8.38159 17.0579 8.91012C16.5294 9.43865 15.8126 9.73558 15.0651 9.73558ZM15.0651 5.41596C14.7682 5.41596 14.4779 5.50401 14.231 5.66898C13.9841 5.83394 13.7917 6.06842 13.6781 6.34275C13.5644 6.61709 13.5347 6.91895 13.5926 7.21018C13.6506 7.50141 13.7935 7.76893 14.0035 7.97889C14.2135 8.18886 14.481 8.33185 14.7722 8.38977C15.0634 8.4477 15.3653 8.41797 15.6396 8.30434C15.914 8.19071 16.1485 7.99828 16.3134 7.75138C16.4784 7.50449 16.5664 7.21422 16.5664 6.91729C16.5641 6.51982 16.4052 6.13929 16.1242 5.85823C15.8431 5.57718 15.4626 5.41826 15.0651 5.41596Z" />
          <path d="M6.93509 17.9007C6.37769 17.9007 5.8328 17.7354 5.36933 17.4258C4.90587 17.1161 4.54464 16.6759 4.33133 16.1609C4.11802 15.646 4.06221 15.0793 4.17095 14.5326C4.2797 13.9859 4.54811 13.4837 4.94226 13.0896C5.3364 12.6955 5.83858 12.427 6.38527 12.3183C6.93196 12.2095 7.49863 12.2654 8.0136 12.4787C8.52858 12.692 8.96874 13.0532 9.27841 13.5167C9.58809 13.9801 9.75338 14.525 9.75338 15.0824C9.75338 15.8299 9.45646 16.5467 8.92792 17.0753C8.39939 17.6038 7.68255 17.9007 6.93509 17.9007ZM6.93509 13.5811C6.63816 13.5811 6.34789 13.6691 6.101 13.8341C5.8541 13.9991 5.66167 14.2336 5.54804 14.5079C5.43441 14.7822 5.40468 15.0841 5.46261 15.3753C5.52054 15.6666 5.66352 15.9341 5.87349 16.144C6.08345 16.354 6.35097 16.497 6.6422 16.5549C6.93343 16.6128 7.23529 16.5831 7.50963 16.4695C7.78396 16.3559 8.01843 16.1634 8.1834 15.9165C8.34837 15.6696 8.43642 15.3794 8.43642 15.0824C8.43642 14.6843 8.27825 14.3024 7.99669 14.0208C7.71514 13.7393 7.33327 13.5811 6.93509 13.5811Z" />
          <path d="M15.2144 17.9007C14.6566 17.9025 14.1108 17.7386 13.6462 17.43C13.1816 17.1214 12.819 16.6819 12.6044 16.167C12.3897 15.6522 12.3326 15.0853 12.4404 14.538C12.5481 13.9907 12.8159 13.4877 13.2097 13.0927C13.6034 12.6977 14.1056 12.4284 14.6525 12.319C15.1995 12.2095 15.7666 12.2648 16.2821 12.4778C16.7976 12.6909 17.2382 13.0521 17.5483 13.5158C17.8584 13.9794 18.0239 14.5247 18.0239 15.0824C18.0239 15.8284 17.7282 16.5439 17.2015 17.0722C16.6749 17.6004 15.9603 17.8984 15.2144 17.9007ZM15.2144 13.5811C14.9171 13.5794 14.6259 13.6659 14.3779 13.8298C14.1299 13.9937 13.9361 14.2276 13.8211 14.5018C13.7061 14.776 13.6751 15.0781 13.732 15.3699C13.789 15.6617 13.9313 15.93 14.1409 16.1409C14.3505 16.3517 14.618 16.4956 14.9094 16.5542C15.2009 16.6129 15.5032 16.5837 15.7781 16.4703C16.0529 16.3569 16.2879 16.1645 16.4533 15.9174C16.6186 15.6704 16.7069 15.3797 16.7069 15.0824C16.7046 14.6865 16.5469 14.3072 16.2678 14.0264C15.9886 13.7456 15.6103 13.5857 15.2144 13.5811Z" />
        </mask>
        <path d="M18.7877 21H3.26517C2.66512 20.9977 2.09031 20.7583 1.66601 20.334C1.24171 19.9097 1.00231 19.3348 1 18.7348V3.26492C1.00231 2.66488 1.24171 2.09006 1.66601 1.66576C2.09031 1.24146 2.66512 1.00207 3.26517 0.999756H18.735C19.3351 1.00207 19.9099 1.24146 20.3342 1.66576C20.7585 2.09006 20.9979 2.66488 21.0002 3.26492V18.7875C21.0002 19.3743 20.7671 19.937 20.3522 20.3519C19.9373 20.7669 19.3745 21 18.7877 21ZM3.26517 2.31671C3.0144 2.319 2.77454 2.41964 2.59721 2.59697C2.41988 2.7743 2.31925 3.01415 2.31696 3.26492V18.7348C2.31925 18.9856 2.41988 19.2254 2.59721 19.4027C2.77454 19.5801 3.0144 19.6807 3.26517 19.683H18.7877C19.0206 19.683 19.2439 19.5905 19.4085 19.4259C19.5732 19.2612 19.6657 19.0379 19.6657 18.805V3.26492C19.6634 3.01415 19.5628 2.7743 19.3854 2.59697C19.2081 2.41964 18.9682 2.319 18.7175 2.31671H3.26517Z" />
        <path d="M6.78584 9.73558C6.22843 9.73558 5.68354 9.57029 5.22008 9.26061C4.75661 8.95093 4.39539 8.51078 4.18208 7.9958C3.96877 7.48083 3.91295 6.91416 4.0217 6.36747C4.13044 5.82077 4.39886 5.3186 4.793 4.92446C5.18715 4.53031 5.68932 4.2619 6.23602 4.15315C6.78271 4.04441 7.34937 4.10022 7.86435 4.31353C8.37932 4.52684 8.81948 4.88806 9.12916 5.35153C9.43884 5.815 9.60413 6.35988 9.60413 6.91729C9.60413 7.66475 9.3072 8.38159 8.77867 8.91012C8.25014 9.43865 7.53329 9.73558 6.78584 9.73558ZM6.78584 5.41596C6.4889 5.41596 6.19863 5.50401 5.95174 5.66898C5.70485 5.83394 5.51242 6.06842 5.39879 6.34275C5.28515 6.61709 5.25542 6.91895 5.31335 7.21018C5.37128 7.50141 5.51427 7.76893 5.72423 7.97889C5.9342 8.18886 6.20171 8.33185 6.49294 8.38977C6.78417 8.4477 7.08604 8.41797 7.36037 8.30434C7.6347 8.19071 7.86918 7.99828 8.03415 7.75138C8.19912 7.50449 8.28717 7.21422 8.28717 6.91729C8.28486 6.51982 8.12595 6.13929 7.84489 5.85823C7.56384 5.57718 7.1833 5.41826 6.78584 5.41596Z" />
        <path d="M15.0651 9.73558C14.5077 9.73558 13.9628 9.57029 13.4994 9.26061C13.0359 8.95093 12.6747 8.51078 12.4614 7.9958C12.248 7.48083 12.1922 6.91416 12.301 6.36747C12.4097 5.82077 12.6781 5.3186 13.0723 4.92446C13.4664 4.53031 13.9686 4.2619 14.5153 4.15315C15.062 4.04441 15.6287 4.10022 16.1436 4.31353C16.6586 4.52684 17.0988 4.88806 17.4084 5.35153C17.7181 5.815 17.8834 6.35988 17.8834 6.91729C17.8834 7.66475 17.5865 8.38159 17.0579 8.91012C16.5294 9.43865 15.8126 9.73558 15.0651 9.73558ZM15.0651 5.41596C14.7682 5.41596 14.4779 5.50401 14.231 5.66898C13.9841 5.83394 13.7917 6.06842 13.6781 6.34275C13.5644 6.61709 13.5347 6.91895 13.5926 7.21018C13.6506 7.50141 13.7935 7.76893 14.0035 7.97889C14.2135 8.18886 14.481 8.33185 14.7722 8.38977C15.0634 8.4477 15.3653 8.41797 15.6396 8.30434C15.914 8.19071 16.1485 7.99828 16.3134 7.75138C16.4784 7.50449 16.5664 7.21422 16.5664 6.91729C16.5641 6.51982 16.4052 6.13929 16.1242 5.85823C15.8431 5.57718 15.4626 5.41826 15.0651 5.41596Z" />
        <path d="M6.93509 17.9007C6.37769 17.9007 5.8328 17.7354 5.36933 17.4258C4.90587 17.1161 4.54464 16.6759 4.33133 16.1609C4.11802 15.646 4.06221 15.0793 4.17095 14.5326C4.2797 13.9859 4.54811 13.4837 4.94226 13.0896C5.3364 12.6955 5.83858 12.427 6.38527 12.3183C6.93196 12.2095 7.49863 12.2654 8.0136 12.4787C8.52858 12.692 8.96874 13.0532 9.27841 13.5167C9.58809 13.9801 9.75338 14.525 9.75338 15.0824C9.75338 15.8299 9.45646 16.5467 8.92792 17.0753C8.39939 17.6038 7.68255 17.9007 6.93509 17.9007ZM6.93509 13.5811C6.63816 13.5811 6.34789 13.6691 6.101 13.8341C5.8541 13.9991 5.66167 14.2336 5.54804 14.5079C5.43441 14.7822 5.40468 15.0841 5.46261 15.3753C5.52054 15.6666 5.66352 15.9341 5.87349 16.144C6.08345 16.354 6.35097 16.497 6.6422 16.5549C6.93343 16.6128 7.23529 16.5831 7.50963 16.4695C7.78396 16.3559 8.01843 16.1634 8.1834 15.9165C8.34837 15.6696 8.43642 15.3794 8.43642 15.0824C8.43642 14.6843 8.27825 14.3024 7.99669 14.0208C7.71514 13.7393 7.33327 13.5811 6.93509 13.5811Z" />
        <path d="M15.2144 17.9007C14.6566 17.9025 14.1108 17.7386 13.6462 17.43C13.1816 17.1214 12.819 16.6819 12.6044 16.167C12.3897 15.6522 12.3326 15.0853 12.4404 14.538C12.5481 13.9907 12.8159 13.4877 13.2097 13.0927C13.6034 12.6977 14.1056 12.4284 14.6525 12.319C15.1995 12.2095 15.7666 12.2648 16.2821 12.4778C16.7976 12.6909 17.2382 13.0521 17.5483 13.5158C17.8584 13.9794 18.0239 14.5247 18.0239 15.0824C18.0239 15.8284 17.7282 16.5439 17.2015 17.0722C16.6749 17.6004 15.9603 17.8984 15.2144 17.9007ZM15.2144 13.5811C14.9171 13.5794 14.6259 13.6659 14.3779 13.8298C14.1299 13.9937 13.9361 14.2276 13.8211 14.5018C13.7061 14.776 13.6751 15.0781 13.732 15.3699C13.789 15.6617 13.9313 15.93 14.1409 16.1409C14.3505 16.3517 14.618 16.4956 14.9094 16.5542C15.2009 16.6129 15.5032 16.5837 15.7781 16.4703C16.0529 16.3569 16.2879 16.1645 16.4533 15.9174C16.6186 15.6704 16.7069 15.3797 16.7069 15.0824C16.7046 14.6865 16.5469 14.3072 16.2678 14.0264C15.9886 13.7456 15.6103 13.5857 15.2144 13.5811Z" />
        <path
          d="M18.7877 21H3.26517C2.66512 20.9977 2.09031 20.7583 1.66601 20.334C1.24171 19.9097 1.00231 19.3348 1 18.7348V3.26492C1.00231 2.66488 1.24171 2.09006 1.66601 1.66576C2.09031 1.24146 2.66512 1.00207 3.26517 0.999756H18.735C19.3351 1.00207 19.9099 1.24146 20.3342 1.66576C20.7585 2.09006 20.9979 2.66488 21.0002 3.26492V18.7875C21.0002 19.3743 20.7671 19.937 20.3522 20.3519C19.9373 20.7669 19.3745 21 18.7877 21ZM3.26517 2.31671C3.0144 2.319 2.77454 2.41964 2.59721 2.59697C2.41988 2.7743 2.31925 3.01415 2.31696 3.26492V18.7348C2.31925 18.9856 2.41988 19.2254 2.59721 19.4027C2.77454 19.5801 3.0144 19.6807 3.26517 19.683H18.7877C19.0206 19.683 19.2439 19.5905 19.4085 19.4259C19.5732 19.2612 19.6657 19.0379 19.6657 18.805V3.26492C19.6634 3.01415 19.5628 2.7743 19.3854 2.59697C19.2081 2.41964 18.9682 2.319 18.7175 2.31671H3.26517Z"
          stroke="currentColor"
          strokeWidth="0.4"
          mask="url(#path-1-outside-1_4162_4816)"
        />
        <path
          d="M6.78584 9.73558C6.22843 9.73558 5.68354 9.57029 5.22008 9.26061C4.75661 8.95093 4.39539 8.51078 4.18208 7.9958C3.96877 7.48083 3.91295 6.91416 4.0217 6.36747C4.13044 5.82077 4.39886 5.3186 4.793 4.92446C5.18715 4.53031 5.68932 4.2619 6.23602 4.15315C6.78271 4.04441 7.34937 4.10022 7.86435 4.31353C8.37932 4.52684 8.81948 4.88806 9.12916 5.35153C9.43884 5.815 9.60413 6.35988 9.60413 6.91729C9.60413 7.66475 9.3072 8.38159 8.77867 8.91012C8.25014 9.43865 7.53329 9.73558 6.78584 9.73558ZM6.78584 5.41596C6.4889 5.41596 6.19863 5.50401 5.95174 5.66898C5.70485 5.83394 5.51242 6.06842 5.39879 6.34275C5.28515 6.61709 5.25542 6.91895 5.31335 7.21018C5.37128 7.50141 5.51427 7.76893 5.72423 7.97889C5.9342 8.18886 6.20171 8.33185 6.49294 8.38977C6.78417 8.4477 7.08604 8.41797 7.36037 8.30434C7.6347 8.19071 7.86918 7.99828 8.03415 7.75138C8.19912 7.50449 8.28717 7.21422 8.28717 6.91729C8.28486 6.51982 8.12595 6.13929 7.84489 5.85823C7.56384 5.57718 7.1833 5.41826 6.78584 5.41596Z"
          stroke="currentColor"
          strokeWidth="0.4"
          mask="url(#path-1-outside-1_4162_4816)"
        />
        <path
          d="M15.0651 9.73558C14.5077 9.73558 13.9628 9.57029 13.4994 9.26061C13.0359 8.95093 12.6747 8.51078 12.4614 7.9958C12.248 7.48083 12.1922 6.91416 12.301 6.36747C12.4097 5.82077 12.6781 5.3186 13.0723 4.92446C13.4664 4.53031 13.9686 4.2619 14.5153 4.15315C15.062 4.04441 15.6287 4.10022 16.1436 4.31353C16.6586 4.52684 17.0988 4.88806 17.4084 5.35153C17.7181 5.815 17.8834 6.35988 17.8834 6.91729C17.8834 7.66475 17.5865 8.38159 17.0579 8.91012C16.5294 9.43865 15.8126 9.73558 15.0651 9.73558ZM15.0651 5.41596C14.7682 5.41596 14.4779 5.50401 14.231 5.66898C13.9841 5.83394 13.7917 6.06842 13.6781 6.34275C13.5644 6.61709 13.5347 6.91895 13.5926 7.21018C13.6506 7.50141 13.7935 7.76893 14.0035 7.97889C14.2135 8.18886 14.481 8.33185 14.7722 8.38977C15.0634 8.4477 15.3653 8.41797 15.6396 8.30434C15.914 8.19071 16.1485 7.99828 16.3134 7.75138C16.4784 7.50449 16.5664 7.21422 16.5664 6.91729C16.5641 6.51982 16.4052 6.13929 16.1242 5.85823C15.8431 5.57718 15.4626 5.41826 15.0651 5.41596Z"
          stroke="currentColor"
          strokeWidth="0.4"
          mask="url(#path-1-outside-1_4162_4816)"
        />
        <path
          d="M6.93509 17.9007C6.37769 17.9007 5.8328 17.7354 5.36933 17.4258C4.90587 17.1161 4.54464 16.6759 4.33133 16.1609C4.11802 15.646 4.06221 15.0793 4.17095 14.5326C4.2797 13.9859 4.54811 13.4837 4.94226 13.0896C5.3364 12.6955 5.83858 12.427 6.38527 12.3183C6.93196 12.2095 7.49863 12.2654 8.0136 12.4787C8.52858 12.692 8.96874 13.0532 9.27841 13.5167C9.58809 13.9801 9.75338 14.525 9.75338 15.0824C9.75338 15.8299 9.45646 16.5467 8.92792 17.0753C8.39939 17.6038 7.68255 17.9007 6.93509 17.9007ZM6.93509 13.5811C6.63816 13.5811 6.34789 13.6691 6.101 13.8341C5.8541 13.9991 5.66167 14.2336 5.54804 14.5079C5.43441 14.7822 5.40468 15.0841 5.46261 15.3753C5.52054 15.6666 5.66352 15.9341 5.87349 16.144C6.08345 16.354 6.35097 16.497 6.6422 16.5549C6.93343 16.6128 7.23529 16.5831 7.50963 16.4695C7.78396 16.3559 8.01843 16.1634 8.1834 15.9165C8.34837 15.6696 8.43642 15.3794 8.43642 15.0824C8.43642 14.6843 8.27825 14.3024 7.99669 14.0208C7.71514 13.7393 7.33327 13.5811 6.93509 13.5811Z"
          stroke="currentColor"
          strokeWidth="0.4"
          mask="url(#path-1-outside-1_4162_4816)"
        />
        <path
          d="M15.2144 17.9007C14.6566 17.9025 14.1108 17.7386 13.6462 17.43C13.1816 17.1214 12.819 16.6819 12.6044 16.167C12.3897 15.6522 12.3326 15.0853 12.4404 14.538C12.5481 13.9907 12.8159 13.4877 13.2097 13.0927C13.6034 12.6977 14.1056 12.4284 14.6525 12.319C15.1995 12.2095 15.7666 12.2648 16.2821 12.4778C16.7976 12.6909 17.2382 13.0521 17.5483 13.5158C17.8584 13.9794 18.0239 14.5247 18.0239 15.0824C18.0239 15.8284 17.7282 16.5439 17.2015 17.0722C16.6749 17.6004 15.9603 17.8984 15.2144 17.9007ZM15.2144 13.5811C14.9171 13.5794 14.6259 13.6659 14.3779 13.8298C14.1299 13.9937 13.9361 14.2276 13.8211 14.5018C13.7061 14.776 13.6751 15.0781 13.732 15.3699C13.789 15.6617 13.9313 15.93 14.1409 16.1409C14.3505 16.3517 14.618 16.4956 14.9094 16.5542C15.2009 16.6129 15.5032 16.5837 15.7781 16.4703C16.0529 16.3569 16.2879 16.1645 16.4533 15.9174C16.6186 15.6704 16.7069 15.3797 16.7069 15.0824C16.7046 14.6865 16.5469 14.3072 16.2678 14.0264C15.9886 13.7456 15.6103 13.5857 15.2144 13.5811Z"
          stroke="currentColor"
          strokeWidth="0.4"
          mask="url(#path-1-outside-1_4162_4816)"
        />{' '}
      </g>
    </SvgIcon>
  );
}
