import React, { useEffect, useState, useRef } from 'react';
import { useAnalytics, useApi } from '@backstage/core-plugin-api';
import { ResponseError } from '@backstage/errors';
import yaml from 'js-yaml';
import {
  RequestParams,
  ResponseParams,
  History,
} from '@lego/plugin-baseplate-ai-common';
import { Button } from '@lego/plugin-baseplate-core-components';
import { Box, makeStyles } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import { identityApiRef } from '@backstage/core-plugin-api';
import { AiApiRef } from '../../api';
import { ChatLine } from '../ChatLine';
import { EmptyState } from '../EmptyState';
import { TextInput } from '../TextInput';
import { BackstageTheme } from '@backstage/theme';
import { LangChainDrawer } from './LangChainDrawer';
import {
  BaseplateAnalyticsEventAttributes,
  EmployeeEntity,
} from '@lego/plugin-baseplate-common';
import { Entity, UserEntity } from '@backstage/catalog-model';
import { SamplePrompt } from '../SamplePrompt/SamplePrompt';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import useAsync from 'react-use/lib/useAsync';

export interface LangChainChatProps {
  verbose: boolean;
  userHint: string;
  context?: string;
  entity?: Entity;
  model?: 'gpt-4o';
}

const useStyles = makeStyles<BackstageTheme>(theme => ({
  settingsContainer: {
    background: theme.palette.background.default,
    position: 'absolute',
    width: 'fit-content',
    padding: '10px 10px',
    top: '10px',
    right: '2%',
    borderRadius: '8px',
  },
  chatContainer: {
    minHeight: '72vh',
    overflow: 'auto',
    background: theme.palette.background.paper,
    borderRadius: '8px',
    paddingTop: '4rem',
  },
}));

export const LangChainChat = ({
  verbose = false,
  context,
  userHint,
  entity,
  model = 'gpt-4o',
}: LangChainChatProps) => {
  const classes = useStyles();
  const api = useApi(AiApiRef);
  const identityApi = useApi(identityApiRef);
  const catalogApi = useApi(catalogApiRef);
  const [statusCode, setStatusCode] = useState<number>();
  const [userYamlContent, setUserYamlContent] = useState<string>('');
  const [aiResponse, setAiResponse] = useState<ResponseParams>({
    aiMessage: { role: 'AI', content: '' },
    verboseLog: '',
  });
  const [prompt, setPrompt] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const textAreaRef = React.useRef<HTMLTextAreaElement>(null);
  const [aiRequest, setAiRequest] = useState<RequestParams>({
    prompt: '',
    pastMessages: [],
    azureOpenAIApiDeploymentName: model,
    temperature: 0.2,
    verbose,
  });
  const [latestResponse, setLatestResponse] = useState<string>('');
  const analytics = useAnalytics();
  const [histories] = useState<History>(new History());
  const [selectedSession, setSelectedSession] = useState<number | null>(null);
  const [aiSummaryRequest] = useState<RequestParams>({
    prompt: '',
    pastMessages: [],
    azureOpenAIApiDeploymentName: 'gpt-4o',
    temperature: 0.2,
    verbose,
  });

  useEffect(() => {
    histories.load();
  }, [histories]);

  useAsync(async () => {
    const identity = await identityApi.getBackstageIdentity();
    const userEntity = (await catalogApi.getEntityByRef(
      identity.userEntityRef,
    )) as EmployeeEntity;

    setUserYamlContent(
      `Hej, I am an employee at the LEGO Group chatting with you :) . My name is ${userEntity.spec.profile.displayName}. My position is ${userEntity.spec.position}.
      General rules: Present yourself as Basebot the Baseplate assistant at beginning of chat. It is not rude to omit my last name. Do not answer in yaml unless the user asks you explicitely to do so. If you want to mention entities(consists of kind, namespace,id) then create on that entity a RELATIVE , never absolute, link with the path like /catalog/namespace/kind/id with solely the id as the link label. Do not create link twice."`,
    );
  }, [identityApi, catalogApi]);

  const fetchOpenAiSummary = async (): Promise<string> => {
    if (selectedSession === null) return '';
    const _prompt = JSON.stringify(
      histories.items[selectedSession].data.pastMessages,
    );
    aiSummaryRequest.prompt = _prompt;
    try {
      const responseParams = await api.getSummary({
        ...aiSummaryRequest,
        context: context ? `${context}\n${userYamlContent}` : userYamlContent,
      });
      return responseParams.aiMessage.content;
    } catch {
      return '';
    }
  };

  const fetchOpenAiData = async () => {
    if (loading) return;
    if (selectedSession === null) {
      setSelectedSession(histories.items.length);
    }
    const _prompt = prompt;
    setPrompt('');
    setAiRequest({
      ...aiRequest,
      pastMessages: [
        ...aiRequest.pastMessages,
        { role: 'Human', content: _prompt },
      ],
    });
    setLoading(true);
    try {
      setLatestResponse('');
      const responseParams = await api.getResponse(
        {
          ...aiRequest,
          context: context ? `${context}\n${userYamlContent}` : userYamlContent,
        },
        setLatestResponse,
      );
      setAiResponse(responseParams);
      setAiRequest({
        ...aiRequest,
        pastMessages: [
          ...aiRequest.pastMessages,
          { role: 'Human', content: _prompt },
          responseParams.aiMessage,
        ],
      });
      setStatusCode(200);
    } catch (error: any) {
      if (error instanceof ResponseError && error.body && error.body.response) {
        const { response } = error.body;
        setStatusCode(response.statusCode);
      } else {
        setStatusCode(500);
      }
    } finally {
      setLoading(false);
      if (selectedSession !== null) {
        histories.order();
        setSelectedSession(histories.items.length - 1);
      }
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;
    setPrompt(value);
    setAiRequest({
      ...aiRequest,
      prompt: value,
    });
  };

  const handleGenerateButtonClick = () => {
    analytics.captureEvent('click', 'Ask_via_button_or_enter_key', {
      attributes: {
        key: 'Ask_via_button_or_enter_key',
        'overwrites.derived.plugin_identifier': 'genai', // because if used as modal window the path is not /ai
        'overwrites.derived.plugin_subidentifier': 'undetermined',
      } as BaseplateAnalyticsEventAttributes,
    });
    void fetchOpenAiData();
  };

  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const handleSamplePrompt = (samplePrompt: string) => {
    setPrompt(samplePrompt);
    setAiRequest({
      ...aiRequest,
      prompt: samplePrompt,
    });
    textAreaRef.current?.focus();
  };

  useEffect(() => {
    histories.update(selectedSession, aiRequest);
    histories.save();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aiRequest.pastMessages]);

  useEffect(() => {
    if (
      selectedSession !== null &&
      histories.items &&
      histories.items[selectedSession]
    ) {
      if (
        (histories.items[selectedSession].summary === '' &&
          histories.items[selectedSession].data.pastMessages.length > 0) ||
        histories.items[selectedSession].data.pastMessages.length % 6 === 0
      ) {
        void fetchOpenAiSummary().then(data => {
          if (data === '') return;
          histories.newSummary(selectedSession, data);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aiRequest.pastMessages, selectedSession]);
  const samples = {
    api: [
      'What can this API do and what are its primary use cases?',
      'Can you provide a code example of how to consume this API in TypeScript/Python/curl?',
      'What might be the value proposition for this API for the LEGO Group?',
    ],
    product: [
      'What are the capabilities of this product?',
      'What might be the value proposition for this product for the LEGO Group?',
      'Whom should I contact for more information about this product?',
    ],
    group: [
      'What are the capabilities of this subdomain/domain?',
      'What might be the value proposition for this subdomain/domain for the LEGO Group?',
      'Whom should I contact for more information about this subdomain/domain?',
    ],
    application: [
      'What might be the value proposition for this application for the LEGO Group?',
      'Whom should I contact for more information about this application?',
    ],
    community: [
      'What might be interesting for me to know about this community in my current job?',
      'What can I expect from this community when I join it and contribute to the discussions and joinging their events?',
      'Whom should I contact for more information about this community? Any maintainers?',
    ],
    tool: [
      'What might be the efficiency gain to use this tool in my current job?',
    ],
    nosingleentitiycontext: [
      'Is there any API I can use to access EntraID?',
      'Are there any planned products in Digital Technology? Tell me about them',
      'We would like to make our APIs and data available to other teams. How can we do that?',
    ],
  }[entity?.kind.toLowerCase() ?? 'nosingleentitiycontext'];

  return (
    <div style={{ position: 'relative' }}>
      <Button
        variant="primary"
        className={classes.settingsContainer}
        aria-label="open drawer"
        onClick={() => setOpen(!open)}
      >
        <SettingsIcon color="secondary" />
      </Button>

      <LangChainDrawer
        open={open}
        aiRequest={aiRequest}
        setAiRequest={setAiRequest}
        setAiResponse={setAiResponse}
        setOpen={setOpen}
        verbose={verbose}
        histories={histories}
        selectedSession={selectedSession}
        setSelectedSession={setSelectedSession}
      />
      <Box className={classes.chatContainer}>
        {aiRequest.pastMessages.length > 0 ? (
          <ChatLine
            currentResponse={latestResponse}
            messages={aiRequest.pastMessages}
            statusCode={statusCode}
            loading={loading}
          />
        ) : (
          <EmptyState entity={entity} />
        )}
      </Box>
      <TextInput
        handleChange={handleChange}
        loading={loading}
        handleGenerateButtonClick={handleGenerateButtonClick}
        prompt={prompt}
        placeholder={
          aiResponse.aiMessage.content.length === 0
            ? userHint ?? 'Ask anything...'
            : 'Ask follow-up...'
        }
        samples={samples?.map(sample => (
          <SamplePrompt sample={sample} setPrompt={handleSamplePrompt} />
        ))}
        entity={entity}
        pastMessages={aiRequest.pastMessages}
        context={context ? `${context}\n${userYamlContent}` : userYamlContent}
        textAreaRef={textAreaRef}
      />
    </div>
  );
};
