import { Grid, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import {
  Button,
  EntityLink,
  baseplateStyles,
} from '@lego/plugin-baseplate-core-components';
import { TechDocsSearchResultListItem } from './resultItems/TechDocsSearchResultListItem';
import { DefaultSearchResultListItem } from './resultItems/DefaultSearchResultListItem';
import { mapSearchResultToEntity } from '../utils/mapSearchResultToEntity';

const useStyles = baseplateStyles(() => ({
  group: {
    marginBottom: '1.5rem',
  },
  searchResultItem: {
    marginBottom: '0.5rem',
  },
}));

export const SearchPageResultGroup = ({
  results,
  title,
  typeFilter,
  hideMoreButton = false,
}: {
  results: any[];
  title?: string;
  typeFilter?: string;
  hideMoreButton?: boolean;
}) => {
  const classes = useStyles();
  const filteredResults = typeFilter
    ? results.filter(({ type }) => type === typeFilter)
    : results;
  const [entityResultsToShow, setEntityResultsToShow] = useState(3);
  const displayedEntityResults = filteredResults.slice(0, entityResultsToShow);

  const SearchPageResultItem = ({ result }: { result: any }) => {
    if (result.type === 'software-catalog') {
      const entity = mapSearchResultToEntity(result);
      return <EntityLink variant="link" entity={entity} />;
    } else if (result.type === 'techdocs') {
      return <TechDocsSearchResultListItem result={result} />;
    }
    return <DefaultSearchResultListItem result={result} />;
  };

  return (
    <Grid className={classes.group}>
      {title ?? <Typography variant="subtitle2">{title}</Typography>}
      {displayedEntityResults.map(result => (
        <div className={classes.searchResultItem}>
          <SearchPageResultItem
            key={`${result.document.kind}:${result.document.namespace}/${result.document.name}`}
            result={result}
          />
        </div>
      ))}
      {!(
        filteredResults.length === displayedEntityResults.length ||
        hideMoreButton
      ) && (
        <Button
          id="show-more-search-results-button"
          variant="secondary"
          startIcon={<AddIcon />}
          onClick={() => setEntityResultsToShow(prev => prev + 5)}
        >
          Show 5 more
        </Button>
      )}
    </Grid>
  );
};
