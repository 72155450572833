import { Card, EntityLink } from '@lego/plugin-baseplate-core-components';
import { Grid } from '@material-ui/core';
import React from 'react';
import { useCommunity } from '../../hooks';
import { CommunityEntityV2 } from '../../types';

export const CommunityMembers = ({ entity }: { entity: CommunityEntityV2 }) => {
  const { communityMembersResponse } = useCommunity();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={6}>
        <Card title="Members">
          <div style={{ textAlign: 'center' }}>
            {communityMembersResponse &&
              communityMembersResponse.members.map(member => (
                <EntityLink variant="link" entityRef={member} />
              ))}
          </div>
        </Card>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Card title="Maintainers">
          <div style={{ display: 'flex', flexDirection: 'column', gap: 6 }}>
            {entity &&
              entity.spec &&
              entity.spec.owners &&
              entity.spec.owners.map(owner => (
                <EntityLink variant="link" entityRef={owner} />
              ))}
          </div>
        </Card>
      </Grid>
    </Grid>
  );
};
