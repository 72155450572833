import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function CalendarCheck(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M14 4c.813 0 1.5.688 1.5 1.5V8h9V5.5c0-.813.625-1.5 1.5-1.5.813 0 1.5.688 1.5 1.5V8H30c2.188 0 4 1.813 4 4v20c0 2.25-1.813 4-4 4H10c-2.25 0-4-1.75-4-4V12c0-2.188 1.75-4 4-4h2.5V5.5c0-.813.625-1.5 1.5-1.5Zm17 12H9v16c0 .563.438 1 1 1h20c.5 0 1-.438 1-1V16Zm-4.438 6.563-7 7c-.625.625-1.562.625-2.125 0l-4-4a1.369 1.369 0 0 1 0-2.063c.563-.625 1.5-.625 2.126 0l2.937 2.938 5.938-5.938a1.369 1.369 0 0 1 2.062 0 1.37 1.37 0 0 1 0 2.063h.063Z" />
    </SvgIcon>
  );
}
