import React from 'react';
import { Spinner } from '@lego/plugin-baseplate-core-components';
import { DestructiveActionWarningDialog } from '@lego/plugin-baseplate-core-components';
import {
  discoveryApiRef,
  fetchApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { LinkDeletionResult } from '@lego/plugin-baseplate-links-common';

export const ManageDocumentationLinkDeletionDialog = ({
  selectedEntityRef,
  deleteDialogOpen,
  setDeleteDialogOpen,
}: {
  selectedEntityRef: string;
  deleteDialogOpen: boolean;
  setDeleteDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [deletionError, setDeletionError] = React.useState<string | null>(null);
  const [success, setSuccess] = React.useState<string | null>(null);
  const discoveryApi = useApi(discoveryApiRef);
  const catalogApi = useApi(catalogApiRef);
  const fetchApi = useApi(fetchApiRef);

  const deleteLink = async () => {
    try {
      setIsLoading(true);
      const componentEntity = await catalogApi.getEntityByRef(
        selectedEntityRef,
      );
      if (componentEntity?.spec?.type === 'external-link') {
        const linkId = componentEntity.metadata.name;
        const baseUrl = await discoveryApi.getBaseUrl('links');
        const response = await fetchApi.fetch(`${baseUrl}/${linkId}`, {
          method: 'DELETE',
        });
        if (!response.ok) {
          throw new Error(
            `Failed to delete link with status: ${response.statusText}`,
          );
        }
        const data: LinkDeletionResult = await response.json();
        if (data.isDeleted) {
          setSuccess(
            "Documentation link has been successfully deleted! It might take up to 30 minutes for the link to disappear, due to Baseplate's ingestion schedules.",
          );
        }
      } else {
        setDeletionError(
          'This documentation type is unknown. Please contact the Baseplate team.',
        );
      }
    } catch (error) {
      setDeletionError((error as Error).message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <DestructiveActionWarningDialog
      title="Delete documentation link"
      description={
        !success
          ? 'Are you sure you want to delete this documentation link? This action cannot be undone, but you can always re-link the documentation.'
          : undefined
      }
      onClose={() => {
        setDeleteDialogOpen(false);
        setSuccess(null);
        setDeletionError(null);
      }}
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onConfirm={async () => await deleteLink()}
      open={deleteDialogOpen}
      buttonText="Delete"
      error={deletionError}
      success={success}
    >
      {isLoading && <Spinner size="large" />}
    </DestructiveActionWarningDialog>
  );
};
