import { useVersionedContext } from '@backstage/version-bridge';
import { UserContextProps } from '../contexts';

export const useUserContext = (): UserContextProps => {
  const context = useVersionedContext<{ 1: UserContextProps }>(
    'current-user-context',
  );

  if (!context) {
    throw new Error('current-user-context is not available.');
  }

  const contextValues = context.atVersion(1);
  if (!contextValues) {
    throw new Error('No current-user-context found for version 1.');
  }

  return contextValues;
};
