import { TableColumn } from '@backstage/core-components';
import { Chip } from '@lego/plugin-baseplate-core-components';
import { OpenPosition } from '@lego/plugin-baseplate-people-to-product-common';
import React from 'react';
import ProductTags from '../../../components/ProductTags/ProductTags';

export const columns: TableColumn<OpenPosition>[] = [
  {
    title: 'People leader',
    field: 'peopleLeaderName',
    defaultSort: 'asc',
  },
  { title: 'Id', field: 'positionId' },
  { title: 'Position', field: 'position' },
  {
    title: 'Status',
    field: 'status',
    render(openPosition) {
      const statusText = openPosition.status === 'FILLED' ? 'Filled' : 'Open';
      const color = openPosition.status === 'FILLED' ? 'green' : 'info';

      return <Chip label={statusText} color={color} />;
    },
    customSort: (data1, data2) => {
      const status1 = data1.status === 'FILLED' ? 'Filled' : 'Open';
      const status2 = data2.status === 'FILLED' ? 'Filled' : 'Open';
      return status1.localeCompare(status2);
    },
  },
  { title: 'Employee name', field: 'newEmployeeName' },
  { title: 'Start date', field: 'startDate', type: 'date' },
  {
    title: 'Product',
    field: 'product',
    align: 'center',
    render(openPosition) {
      const product = openPosition.product ? [openPosition.product] : [];
      const generalOverheadProduct = openPosition.generalOverheadProduct
        ? [openPosition.generalOverheadProduct]
        : [];
      return <ProductTags products={[...product, ...generalOverheadProduct]} />;
    },
  },
];
