import { Entity } from '@backstage/catalog-model';
import { humanizeEntityRef } from '@backstage/plugin-catalog-react';
import { get } from 'lodash';

export function humanizeEntity(
  entity: Entity,
  opts?: {
    defaultKind?: string;
    defaultNamespace?: string | false;
  },
) {
  for (const path of ['spec.profile.displayName', 'metadata.title']) {
    const value = get(entity, path);
    if (value && typeof value === 'string') {
      return value;
    }
  }

  return humanizeEntityRef(entity, opts);
}
