/* eslint-disable eqeqeq */
import useAsync from 'react-use/lib/useAsync';
import { serviceApiRef } from '../../api/ServicesApi';
import { useApi } from '@backstage/core-plugin-api';
import React from 'react';

type UserProfilePictureProps = {
  profileId: string;
  size?: string;
  styles?: any;
  name: string;
  placeholderStyles?: any;
  displayPic?: string;
};

const renderPlaceholder = (
  profilePic: any,
  styles: any,
  height?: any,
  width?: any,
) => {
  return (
    <svg
      width={profilePic && height ? height : '240'}
      height={profilePic && width ? width : '240'}
      viewBox="0 0 240 240"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={styles}
    >
      <rect width="240" height="240" fill="white" />
      <g clipPath="url(#clip0_1822_13004)">
        <circle cx="120" cy="120" r="92" fill="#FFD500" />
        <path
          d="M148.387 137.109C148.512 136.968 148.745 137.05 148.735 137.238C148.258 145.686 137.219 161.4 120 161.4C102.723 161.4 91.3396 146.64 90.922 137.387C90.9138 137.203 91.1387 137.118 91.266 137.251C97.4877 143.722 110.702 145.313 120 145.313C129.285 145.313 142.447 143.812 148.387 137.109Z"
          fill="black"
        />
        <path
          d="M141.829 144.108C141.897 144.065 141.984 144.112 141.98 144.192C141.751 149.949 130.902 158.009 120 158.009C109.082 158.009 98.2186 150.846 98.0184 144.193C98.016 144.113 98.1031 144.065 98.1702 144.108C102.617 146.974 111.354 148.483 120.001 148.483C128.649 148.483 137.382 146.974 141.829 144.108Z"
          fill="white"
        />
        <path
          d="M146.067 110.8C143.055 110.8 140.783 107.843 138.524 107.736C138.442 107.733 138.36 107.72 138.281 107.695C136.472 107.112 134.663 106.996 132.854 106.973C132.258 106.965 131.896 106.318 132.247 105.837C134.442 102.829 137.188 101.6 139.933 101.6C144.533 101.6 143 103.133 149.9 103.9C152.791 104.382 155.076 104.864 156.756 105.155C157.249 105.241 157.371 106.125 156.905 106.311C156.469 106.486 156.551 107.127 157.018 107.186L157.635 107.263C158.328 107.35 158.367 108.362 157.684 108.51C151.993 109.745 147.975 110.8 146.067 110.8Z"
          fill="black"
        />
        <path
          d="M93.1665 110.8C96.178 110.8 98.4501 107.843 100.709 107.736C100.791 107.733 100.873 107.72 100.952 107.695C102.68 107.138 104.409 107.007 106.138 106.976C106.781 106.965 107.136 106.223 106.724 105.73C104.138 102.644 102.021 100.833 99.2999 100.833C94.6999 100.833 96.2332 102.367 89.3332 103.9C85.8795 104.476 83.2901 104.619 81.5651 104.979C80.9938 105.099 80.9898 105.776 81.5317 105.993L81.7503 106.08C82.3408 106.317 82.2291 107.184 81.598 107.263C80.9049 107.35 80.8649 108.369 81.5465 108.522C87.0463 109.752 90.6307 110.8 93.1665 110.8Z"
          fill="black"
        />
        <path
          d="M96.9999 123.067C101.234 123.067 104.667 118.948 104.667 113.867C104.667 108.786 101.234 104.667 96.9999 104.667C92.7657 104.667 89.3332 108.786 89.3332 113.867C89.3332 118.948 92.7657 123.067 96.9999 123.067Z"
          fill="black"
        />
        <path
          d="M143 123.067C147.234 123.067 150.667 118.948 150.667 113.867C150.667 108.786 147.234 104.667 143 104.667C138.766 104.667 135.333 108.786 135.333 113.867C135.333 118.948 138.766 123.067 143 123.067Z"
          fill="black"
        />
        <path
          d="M96.9998 112.334C99.1169 112.334 100.833 110.961 100.833 109.267C100.833 107.573 99.1169 106.2 96.9998 106.2C94.8827 106.2 93.1665 107.573 93.1665 109.267C93.1665 110.961 94.8827 112.334 96.9998 112.334Z"
          fill="white"
        />
        <path
          d="M143 112.334C145.117 112.334 146.833 110.961 146.833 109.267C146.833 107.573 145.117 106.2 143 106.2C140.883 106.2 139.167 107.573 139.167 109.267C139.167 110.961 140.883 112.334 143 112.334Z"
          fill="white"
        />
        <path
          d="M153.733 85.4999C148.367 82.4333 142.233 81.6666 135.333 82.4333C134.567 82.4333 133.8 83.1999 133.8 84.7333C133.8 85.4999 136.1 87.7999 137.633 87.7999C143 87.0333 147.6 87.0333 153.733 89.3333C159.867 91.6333 159.867 93.9333 162.933 97.7666C163.7 98.5333 164.467 98.5333 165.233 98.5333C166 96.9999 166 96.2333 165.233 95.4666C162.933 90.8666 158.333 87.7999 153.733 85.4999Z"
          fill="black"
        />
        <path
          d="M87.0334 85.4999C92.4001 82.4333 97.7668 81.6666 104.667 82.4333C105.433 82.4333 106.2 83.1999 106.2 84.7333C106.2 85.4999 103.9 87.7999 102.367 87.7999C97.0001 87.0333 92.4001 87.0333 87.0334 89.3333C80.9001 91.6333 80.1334 93.9333 77.8334 97.7666C77.0668 98.5333 76.3001 98.5333 75.5334 97.7666C74.0001 96.9999 74.0001 96.2333 74.7668 95.4666C77.8334 90.8666 81.6668 87.7999 87.0334 85.4999Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_1822_13004">
          <rect x="28" y="28" width="184" height="184" rx="92" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

function UserProfilePicture({
  profileId,
  size,
  styles,
  name,
  placeholderStyles,
  displayPic,
}: UserProfilePictureProps) {
  const serviceApi = useApi(serviceApiRef);
  const { value: profilePic, loading } = useAsync(async () => {
    if (displayPic != null) {
      return displayPic;
    }
    try {
      const profilePicture = await serviceApi.getProfilePictureById(
        profileId,
        size ?? '240x240',
      );

      return profilePicture.data;
    } catch (_error: any) {
      return null;
    }
  }, [profileId]);

  return profilePic && !loading ? (
    <img src={profilePic} alt={name} style={styles} className="lndProfilePic" />
  ) : (
    <div style={placeholderStyles} className="lndProfilePicPlaceholder">
      {renderPlaceholder(
        profilePic,
        styles,
        placeholderStyles?.height,
        placeholderStyles?.width,
      )}
    </div>
  );
}

export default UserProfilePicture;
