import { Entity } from '@backstage/catalog-model';
import { AvailableKinds } from '@lego/plugin-baseplate-common';
import React from 'react';
import { useStyles } from './EntityIcon.styles';
import { LegoFigureHead } from '@lego/plugin-baseplate-core-components';

type Props = {
  entity?: Entity;
  kind: AvailableKinds;
  size: 'sm' | 'md';
};

export const EntityIconSvg = ({ entity, size, kind }: Props) => {
  const iconSize = size === 'sm' ? 24 : 32;
  const iconClassName = `svg-icon-${size}`;
  const classes = useStyles();

  if (kind === 'Product') {
    return (
      <svg
        width={iconSize}
        height={iconSize}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        className={classes[iconClassName]}
      >
        <rect
          width="24"
          height="24"
          rx="8"
          className={classes['product-layer-3']}
        />
        <path
          d="M7.05 4.8c.788 0 1.519.45 1.94 1.125.394.703.394 1.575 0 2.25C8.57 8.878 7.839 9.3 7.05 9.3a2.278 2.278 0 0 1-1.969-1.125c-.394-.675-.394-1.547 0-2.25C5.503 5.25 6.234 4.8 7.05 4.8Zm10.35 0c.788 0 1.519.45 1.94 1.125.394.703.394 1.575 0 2.25-.421.703-1.152 1.125-1.94 1.125a2.278 2.278 0 0 1-1.969-1.125c-.393-.675-.393-1.547 0-2.25C15.853 5.25 16.584 4.8 17.4 4.8ZM3 13.21c0-1.66 1.322-3.01 2.981-3.01h1.21c.45 0 .872.113 1.265.281-.056.197-.056.422-.056.619 0 1.097.45 2.053 1.21 2.7H3.59A.577.577 0 0 1 3 13.21Zm11.363.59c.759-.647 1.209-1.603 1.209-2.7 0-.197 0-.422-.028-.619a2.961 2.961 0 0 1 1.237-.281h1.21C19.65 10.2 21 11.55 21 13.21c0 .337-.281.59-.619.59h-6.018Z"
          className={classes['product-layer-2']}
        />
        <path
          d="M10.65 8.766c-.844.506-1.35 1.378-1.35 2.334 0 .984.506 1.856 1.35 2.363.816.478 1.856.478 2.7 0 .816-.507 1.35-1.379 1.35-2.363 0-.956-.534-1.828-1.35-2.334-.844-.479-1.884-.479-2.7 0ZM10.34 14.7c-2.08 0-3.74 1.688-3.74 3.769 0 .422.338.731.731.731h9.31c.422 0 .759-.337.759-.731a3.769 3.769 0 0 0-3.769-3.769h-3.29Z"
          className={classes['product-layer-1']}
        />
      </svg>
    );
  } else if (kind === 'Community') {
    return (
      <svg
        width={iconSize}
        height={iconSize}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        className={classes[iconClassName]}
      >
        <rect
          width="24"
          height="24"
          rx="8"
          className={classes['community-layer-3']}
        />
        <path
          d="M12.2768 19.2C11.0112 19.2 10.0268 18.2156 10.0268 16.95V16.5H14.5268V16.95C14.5268 18.2156 13.5143 19.2 12.2768 19.2Z"
          className={classes['community-layer-1']}
        />
        <path
          d="M11.2924 15.6H9.94241C9.85803 15.2625 9.77366 14.9531 9.60491 14.6438C9.32366 14.1375 8.98616 13.6594 8.62053 13.1813L8.19866 12.5906C7.63616 11.775 7.32678 10.8188 7.32678 9.75C7.32678 7.02188 9.52053 4.8 12.2768 4.8C15.0049 4.8 17.2268 7.02188 17.2268 9.75C17.2268 10.8188 16.8893 11.775 16.3268 12.5906L15.9049 13.1813C15.5393 13.6594 15.2018 14.1375 14.9205 14.6438C14.7518 14.9531 14.6674 15.2625 14.583 15.6H13.233H11.2924Z"
          className={classes['community-layer-1']}
        />
        <path
          d="M11.5 7.75C10.6563 7.75 10 8.4375 10 9.25C10 9.53125 9.75 9.75 9.5 9.75C9.21875 9.75 9 9.53125 9 9.25C9 7.875 10.0938 6.75 11.5 6.75C11.75 6.75 12 7 12 7.25C12 7.53125 11.75 7.75 11.5 7.75Z"
          className={classes['community-layer-2']}
        />
      </svg>
    );
  } else if (kind === 'Tool') {
    if (entity?.spec?.image) {
      return (
        <img
          className={classes.img}
          style={{ width: iconSize, height: iconSize }}
          src={entity.spec.image as string}
          alt={entity.metadata.name}
        />
      );
    }
    return (
      <svg
        width={iconSize}
        height={iconSize}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        className={classes[iconClassName]}
      >
        <rect
          width="24"
          height="24"
          rx="8"
          className={classes['tool-layer-3']}
        />
        <path
          d="M16.8 11.629a3.735 3.735 0 0 1-3.189 1.056.85.85 0 0 0-.729.218L8.427 17.36a1.87 1.87 0 0 1-2.645-2.645l4.456-4.455a.85.85 0 0 0 .218-.73 3.735 3.735 0 0 1 1.056-3.189 3.733 3.733 0 0 1 3.031-1.075c.53.054.663.686.287 1.062L13.567 7.59a.75.75 0 0 0-.219.5l-.04.962a.75.75 0 0 0 .78.78l.963-.04a.75.75 0 0 0 .5-.218l1.263-1.263c.376-.376 1.008-.242 1.062.287a3.733 3.733 0 0 1-1.075 3.031Z"
          className={classes['tool-layer-1']}
        />
        <circle
          cx="7.102"
          cy="16.034"
          r=".624"
          className={classes['tool-layer-3']}
        />
      </svg>
    );
  } else if (kind === 'Component') {
    if (entity?.spec?.type === 'external-link') {
      return (
        <svg
          width={iconSize}
          height={iconSize}
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          className={classes[iconClassName]}
        >
          <rect
            width="24"
            height="24"
            rx="8"
            className={classes['documentation-layer-3']}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.3267 12.7401C18.3034 11.885 19.7754 11.9347 20.6922 12.8515C21.6551 13.8145 21.6551 15.3755 20.6922 16.3385L18.8689 18.1618C17.9059 19.1248 16.3448 19.1248 15.3819 18.1618C14.4651 17.245 14.4154 15.7732 15.2686 14.7965L15.3447 14.7091C15.54 14.4872 15.8777 14.4612 16.102 14.6586C16.3247 14.8546 16.3474 15.1919 16.1531 15.4152L16.0774 15.5021C15.595 16.0542 15.6225 16.8854 16.1411 17.4041C16.6844 17.9474 17.5679 17.9474 18.1112 17.4041L19.9343 15.5825C20.4778 15.0375 20.4776 14.1555 19.9345 13.6124C19.4159 13.0938 18.5852 13.0661 18.0331 13.5481L17.9343 13.6356C17.7114 13.8295 17.3719 13.8077 17.1771 13.5838C16.9833 13.3611 17.0049 13.022 17.2284 12.8271L17.3267 12.7401ZM12.1022 15.7658L13.9287 13.9409C14.8917 12.978 16.4527 12.978 17.4157 13.9409C18.3323 14.8593 18.3822 16.3312 17.5271 17.308L17.5265 17.3087L17.4399 17.4065C17.2446 17.6283 16.9069 17.6543 16.6827 17.457C16.46 17.261 16.4373 16.9237 16.6316 16.7004L16.6324 16.6994L16.7187 16.602C17.2007 16.0499 17.1734 15.2189 16.6549 14.7004C16.1099 14.1568 15.228 14.157 14.6848 14.7002L12.8616 16.5202C12.3181 17.0652 12.3183 17.9472 12.8614 18.4903C13.38 19.0089 14.2107 19.0366 14.7629 18.5546L14.8616 18.4672C15.0846 18.2732 15.424 18.295 15.6188 18.5189C15.8126 18.7416 15.791 19.0807 15.5676 19.2756L15.4693 19.3626C14.4926 20.2176 13.0207 20.1697 12.1022 19.2513C11.1391 18.2898 11.1393 16.7287 12.1022 15.7658Z"
            className={classes['documentation-layer-1']}
          />
          <path
            d="M10.5122 17.7957C9.83259 17.56 8.93405 17.3187 8.25 17.3187C7.23438 17.3187 5.93229 17.6724 5.125 17.9445C4.8125 18.0533 4.5 17.8084 4.5 17.5091V7.41484C4.5 7.08834 4.65625 6.78904 4.96875 6.68021C5.69792 6.38092 6.92188 6 8.25 6C9.76042 6 10.9323 6.54417 11.4531 6.9795C11.5312 7.06113 11.5833 7.16996 11.5833 7.27879V15.0404L11.48 15.1436C10.7539 15.8697 10.4311 16.8468 10.5122 17.7957Z"
            className={classes['documentation-layer-1']}
          />
          <path
            d="M19.5 11.2954V7.41484C19.5 7.08834 19.3177 6.78904 19.0052 6.68021C18.276 6.38092 17.0521 6 15.75 6C14.2135 6 13.0417 6.54417 12.5469 6.9795C12.4427 7.06113 12.4167 7.16996 12.4167 7.27879V14.2078L13.3065 13.3187C14.1507 12.4747 15.334 12.1757 16.4179 12.4225C16.4821 12.3302 16.5585 12.2442 16.647 12.1666L16.7471 12.078C17.5285 11.3939 18.5435 11.1365 19.5 11.2954Z"
            className={classes['documentation-layer-1']}
          />
        </svg>
      );
    } else if (entity?.spec?.type === 'confluence') {
      return (
        <svg
          width={iconSize}
          height={iconSize}
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          className={classes[iconClassName]}
        >
          <rect
            width="24"
            height="24"
            rx="8"
            className={classes['documentation-layer-3']}
          />
          <path
            d="M5.04386 15.7292C4.88886 15.968 4.71261 16.2761 4.56698 16.5074C4.50305 16.615 4.48393 16.7434 4.51374 16.865C4.54355 16.9865 4.6199 17.0915 4.72636 17.1574L7.82948 19.0661C7.88342 19.0994 7.94344 19.1216 8.00606 19.1314C8.06867 19.1413 8.13261 19.1386 8.19416 19.1234C8.25571 19.1083 8.31363 19.0811 8.36455 19.0434C8.41546 19.0056 8.45836 18.9581 8.49073 18.9036C8.61511 18.6961 8.77448 18.4267 8.94885 18.1405C10.1782 16.1111 11.4145 16.3574 13.6414 17.4242L16.7395 18.8849C16.7972 18.9124 16.8598 18.928 16.9237 18.9308C16.9875 18.9337 17.0513 18.9237 17.1112 18.9014C17.1712 18.8792 17.226 18.8451 17.2725 18.8012C17.319 18.7574 17.3562 18.7046 17.382 18.6461L18.8595 15.3049C18.9095 15.1902 18.9125 15.0604 18.8679 14.9436C18.8232 14.8267 18.7345 14.732 18.6207 14.6799C17.5841 14.1927 16.5497 13.7009 15.5176 13.2042C11.3195 11.1749 7.76511 11.3092 5.04386 15.7292ZM18.957 7.908C19.1126 7.65487 19.2889 7.36112 19.4345 7.12675C19.4972 7.0194 19.5156 6.89182 19.4857 6.77114C19.4558 6.65046 19.38 6.54619 19.2745 6.4805L16.172 4.57112C16.118 4.53784 16.058 4.51563 15.9954 4.50579C15.9328 4.49595 15.8688 4.49867 15.8073 4.5138C15.7458 4.52892 15.6878 4.55615 15.6369 4.59389C15.586 4.63163 15.5431 4.67913 15.5107 4.73362C15.3606 4.99047 15.2076 5.2457 15.052 5.49925C13.8232 7.528 12.5864 7.2805 10.3595 6.2155L7.27323 4.75237C7.21556 4.72497 7.15297 4.70941 7.08918 4.70662C7.02539 4.70383 6.96169 4.71386 6.90185 4.73612C6.842 4.75838 6.78723 4.79242 6.74077 4.83622C6.69431 4.88002 6.6571 4.93269 6.63136 4.99112L5.15385 8.33237C5.10383 8.44706 5.10082 8.57679 5.14547 8.69368C5.19012 8.81056 5.27886 8.90525 5.3926 8.95737C6.04198 9.26362 7.33323 9.87425 8.49573 10.433C12.682 12.4617 16.2407 12.3261 18.957 7.908Z"
            className={classes['documentation-layer-1']}
          />
        </svg>
      );
    }
    return (
      <svg
        width={iconSize}
        height={iconSize}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        className={classes[iconClassName]}
      >
        <rect
          width="24"
          height="24"
          rx="8"
          className={classes['documentation-layer-3']}
        />
        <path
          d="M10.984 17.972c-.677-.272-1.875-.653-2.734-.653-1.016 0-2.318.353-3.125.626a.473.473 0 0 1-.625-.436V7.415c0-.327.156-.626.469-.735C5.698 6.38 6.922 6 8.25 6c1.51 0 2.682.544 3.203.98.078.081.13.19.13.299v10.257c0 .327-.312.544-.599.436Zm2.006 0c-.287.108-.573-.11-.573-.436V7.28c0-.109.026-.218.13-.3C13.042 6.545 14.213 6 15.75 6c1.302 0 2.526.38 3.255.68.313.109.495.408.495.735v10.094c0 .3-.338.544-.651.436-.807-.273-2.11-.626-3.099-.626-.885 0-2.057.38-2.76.653Z"
          className={classes['documentation-layer-1']}
        />
      </svg>
    );
  } else if (kind === 'API') {
    const apiAccentColor =
      {
        openapi: 'api',
        asyncapi: 'product',
        graphql: 'application',
      }[entity?.spec?.type as string] ?? 'api';

    return (
      <svg
        width={iconSize}
        height={iconSize}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={classes[iconClassName]}
      >
        <rect
          width="24"
          height="24"
          rx="8"
          className={classes['api-layer-3']}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.746 16.851v-2.737h.782v2.737h-.782ZM11.226 18.025v-3.911h.782v3.91h-.782ZM7.706 16.851v-2.737h.782v2.737h-.782Z"
          className={classes[`${apiAccentColor}-layer-2`]}
        />
        <circle
          cx="15.137"
          cy="16.851"
          r="1.173"
          className={classes[`${apiAccentColor}-layer-2`]}
        />
        <circle
          cx="11.617"
          cy="18.416"
          className={classes[`${apiAccentColor}-layer-2`]}
          r="1.173"
        />
        <circle
          cx="8.097"
          cy="16.851"
          r="1.173"
          className={classes[`${apiAccentColor}-layer-2`]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.506 14.7a3.325 3.325 0 0 0 3.128-3.324c0-1.344-.83-2.517-2.004-3.03l.008-.058c.022-.147.04-.28.04-.432a2.942 2.942 0 0 0-2.932-2.933c-.44 0-.831.098-1.198.27a4.116 4.116 0 0 0-3.104-1.443 4.115 4.115 0 0 0-4.107 4.106v.025a3.492 3.492 0 0 0-2.346 3.3 3.505 3.505 0 0 0 3.52 3.52h8.995Z"
          className={classes['api-layer-1']}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="m8.838 12.342.197.475v.002a.396.396 0 0 0 .518.207.396.396 0 0 0 .207-.516L8.575 9.665a.397.397 0 0 0-.36-.244.4.4 0 0 0-.366.244L6.664 12.51a.396.396 0 0 0 .207.516l.003.001a.393.393 0 0 0 .514-.208l.198-.477h1.252Zm-.92-.787.298-.713.298.713h-.597ZM11.536 9.42a.63.63 0 0 0-.63.632v2.608c0 .22.173.394.393.394.209 0 .393-.17.393-.394v-.555h.555c.738 0 1.342-.595 1.342-1.342 0-.739-.603-1.342-1.342-1.342h-.711Zm.711 1.898h-.555v-1.11h.555a.555.555 0 0 1 0 1.11Z"
          className={classes['api-layer-3']}
        />
        <path
          d="M15.093 9.42h1.897a.4.4 0 0 1 .394.394.395.395 0 0 1-.394.394h-.555v2.059h.555a.4.4 0 0 1 .394.393.395.395 0 0 1-.394.394h-1.897a.39.39 0 0 1-.393-.394c0-.209.17-.393.393-.393h.555v-2.059h-.555a.39.39 0 0 1-.393-.394c0-.209.17-.393.393-.393Z"
          className={classes['api-layer-3']}
        />
      </svg>
    );
  } else if (kind === 'Application') {
    return (
      <svg
        width={iconSize}
        height={iconSize}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={classes[iconClassName]}
      >
        <rect
          width="24"
          height="24"
          rx="8"
          className={classes['application-layer-3']}
        />
        <path
          d="M9.3 4.8C9.67407 4.8 9.975 5.10094 9.975 5.475V6.6H8.625V5.475C8.625 5.10094 8.92594 4.8 9.3 4.8Z"
          className={classes['application-layer-2']}
        />
        <path
          d="M5.475 8.625H6.6V9.975H5.475C5.10094 9.975 4.8 9.67407 4.8 9.3C4.8 8.92594 5.10094 8.625 5.475 8.625Z"
          className={classes['application-layer-2']}
        />
        <path
          d="M5.475 11.325H6.6V12.675H5.475C5.10094 12.675 4.8 12.3741 4.8 12C4.8 11.6259 5.10094 11.325 5.475 11.325Z"
          className={classes['application-layer-2']}
        />
        <path
          d="M5.475 14.025H6.6V15.375H5.475C5.10094 15.375 4.8 15.0741 4.8 14.7C4.8 14.3259 5.10094 14.025 5.475 14.025Z"
          className={classes['application-layer-2']}
        />
        <path
          d="M8.625 18.525V17.4H9.975V18.525C9.975 18.8991 9.67407 19.2 9.3 19.2C8.92594 19.2 8.625 18.8991 8.625 18.525Z"
          className={classes['application-layer-2']}
        />
        <path
          d="M11.325 18.525V17.4H12.675V18.525C12.675 18.8991 12.3741 19.2 12 19.2C11.6259 19.2 11.325 18.8991 11.325 18.525Z"
          className={classes['application-layer-2']}
        />
        <path
          d="M14.025 18.525V17.4H15.375V18.525C15.375 18.8991 15.0741 19.2 14.7 19.2C14.3259 19.2 14.025 18.8991 14.025 18.525Z"
          className={classes['application-layer-2']}
        />
        <path
          d="M18.525 15.375H17.4V14.025H18.525C18.8991 14.025 19.2 14.3259 19.2 14.7C19.2 15.0741 18.8991 15.375 18.525 15.375Z"
          className={classes['application-layer-2']}
        />
        <path
          d="M18.525 12.675H17.4V11.325H18.525C18.8991 11.325 19.2 11.6259 19.2 12C19.2 12.3741 18.8991 12.675 18.525 12.675Z"
          className={classes['application-layer-2']}
        />
        <path
          d="M18.525 9.975H17.4V8.625H18.525C18.8991 8.625 19.2 8.92594 19.2 9.3C19.2 9.67407 18.8991 9.975 18.525 9.975Z"
          className={classes['application-layer-2']}
        />
        <path
          d="M15.375 5.475V6.6H14.025V5.475C14.025 5.10094 14.3259 4.8 14.7 4.8C15.0741 4.8 15.375 5.10094 15.375 5.475Z"
          className={classes['application-layer-2']}
        />
        <path
          d="M12.675 5.475V6.6H11.325V5.475C11.325 5.10094 11.6259 4.8 12 4.8C12.3741 4.8 12.675 5.10094 12.675 5.475Z"
          className={classes['application-layer-2']}
        />
        <path
          d="M6.60001 8.4C6.60001 7.40719 7.40719 6.6 8.40001 6.6H15.6C16.5928 6.6 17.4 7.40719 17.4 8.4V15.6C17.4 16.5928 16.5928 17.4 15.6 17.4H8.40001C7.40719 17.4 6.60001 16.5928 6.60001 15.6V8.4Z"
          className={classes['application-layer-1']}
        />
        <path
          d="M10.2 9.3C9.70219 9.3 9.3 9.70219 9.3 10.2V13.8C9.3 14.2978 9.70219 14.7 10.2 14.7H13.8C14.2978 14.7 14.7 14.2978 14.7 13.8V10.2C14.7 9.70219 14.2978 9.3 13.8 9.3H10.2Z"
          className={classes['application-layer-2']}
        />
      </svg>
    );
  } else if (kind === 'Identity') {
    return (
      <svg
        width={iconSize}
        height={iconSize}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={classes[iconClassName]}
      >
        <rect
          width="24"
          height="24"
          rx="8"
          className={classes['it-component-layer-3']}
        />
        <path
          d="M12.3 5.85C13.5431 5.85 14.55 6.85687 14.55 8.1V10.5H15.9V8.1C15.9 6.11156 14.2884 4.5 12.3 4.5C10.3115 4.5 8.69995 6.11156 8.69995 8.1V10.5H10.05V8.1C10.05 6.85687 11.0568 5.85 12.3 5.85Z"
          className={classes['it-component-layer-2']}
        />
        <path
          d="M14.55 9.89999H10.05H8.7H7.8C6.80719 9.89999 6 10.7072 6 11.7V17.1C6 18.0928 6.80719 18.9 7.8 18.9H16.8C17.7928 18.9 18.6 18.0928 18.6 17.1V11.7C18.6 10.7072 17.7928 9.89999 16.8 9.89999H15.9H14.55Z"
          className={classes['it-component-layer-1']}
        />
      </svg>
    );
  } else if (kind === 'Workspace') {
    return (
      <svg
        width={iconSize}
        height={iconSize}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={classes[iconClassName]}
      >
        <rect
          width="24"
          height="24"
          rx="8"
          className={classes['api-layer-3']}
        />
        <g clipPath="url(#clip0_1942_3528)">
          <path
            d="M3 18.225C3 18.5991 3.30094 18.9 3.675 18.9H5.625C5.99906 18.9 6.3 18.5991 6.3 18.225V11.25H17.7V18.225C17.7 18.5991 18.0009 18.9 18.375 18.9H20.3278C20.7019 18.9 21.0028 18.5991 21.0028 18.225V9.45848C21.0028 8.63442 20.5022 7.89192 19.7372 7.58255L12.5934 4.68567C12.2137 4.53098 11.7891 4.53098 11.4094 4.68567L4.26281 7.58255C3.50063 7.89192 3 8.63442 3 9.45848V18.225Z"
            className={classes['api-layer-1']}
          />
          <path
            d="M15.7662 16.2H13.7662C13.3506 16.2 13.0162 16.501 13.0162 16.875V18.225C13.0162 18.5991 13.3506 18.9 13.7662 18.9H15.7662C16.1819 18.9 16.5162 18.5991 16.5162 18.225V16.875C16.5162 16.501 16.1819 16.2 15.7662 16.2Z"
            className={classes['api-layer-2']}
          />
          <path
            d="M8.26624 12.6C7.85061 12.6 7.51624 12.901 7.51624 13.275V14.625C7.51624 14.9991 7.85061 15.3 8.26624 15.3H11.2662C11.6819 15.3 12.0162 14.9991 12.0162 14.625V13.275C12.0162 12.901 11.6819 12.6 11.2662 12.6H8.26624Z"
            className={classes['api-layer-2']}
          />
          <path
            d="M11.2662 16.2H8.26624C7.85061 16.2 7.51624 16.501 7.51624 16.875V18.225C7.51624 18.5991 7.85061 18.9 8.26624 18.9H11.2662C11.6819 18.9 12.0162 18.5991 12.0162 18.225V16.875C12.0162 16.501 11.6819 16.2 11.2662 16.2Z"
            className={classes['api-layer-2']}
          />
        </g>
        <defs>
          <clipPath id="clip0_1942_3528">
            <rect
              width="18"
              height="18"
              fill="white"
              transform="translate(3 3)"
            />
          </clipPath>
        </defs>
      </svg>
    );
  } else if (kind === 'User') {
    if (!entity) {
      return null;
    }
    return (
      <img
        className={classes.img}
        style={{ width: iconSize, height: iconSize }}
        // eslint-disable-next-line
        // @ts-ignore We don't have proper Entity types for users yet
        src={entity.spec?.profile?.picture ?? LegoFigureHead}
        alt={entity.metadata.name}
      />
    );
  } else if (kind === 'Group') {
    return (
      <svg
        width={iconSize}
        height={iconSize}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        className={classes[iconClassName]}
      >
        <rect
          width="24"
          height="24"
          rx="8"
          className={classes['group-layer-3']}
        />
        <path
          d="M9.751 6.6a.9.9 0 0 1 .9-.9h2.698a.9.9 0 0 1 .9.9v2.7a.9.9 0 0 1-.9.9H10.65a.9.9 0 0 1-.9-.9V6.6Z"
          className={classes['group-layer-1']}
        />
        <path
          d="M3.9 14.7a.9.9 0 0 1 .9-.9h2.698a.9.9 0 0 1 .9.9v2.7a.9.9 0 0 1-.9.9H4.8a.9.9 0 0 1-.9-.9v-2.7ZM9.751 14.7a.9.9 0 0 1 .9-.9h2.698a.9.9 0 0 1 .9.9v2.7a.9.9 0 0 1-.9.9H10.65a.9.9 0 0 1-.9-.9v-2.7ZM15.602 14.7a.9.9 0 0 1 .9-.9H19.2a.9.9 0 0 1 .9.9v2.7a.9.9 0 0 1-.9.9h-2.698a.9.9 0 0 1-.9-.9v-2.7Z"
          className={classes['group-layer-2']}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.675 10.2h-1.35v1.125H6.824a1.35 1.35 0 0 0-1.35 1.35V13.8h1.35v-.9c0-.124.1-.225.225-.225h4.276V13.8h1.35v-1.125h4.276c.124 0 .225.1.225.225v.9h1.35v-1.125a1.35 1.35 0 0 0-1.35-1.35h-4.501V10.2Z"
          className={classes['group-layer-2']}
        />
      </svg>
    );
  }
  return null;
};
