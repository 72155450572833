import { Content, PageWithHeader } from '@backstage/core-components';
import { EntityListProvider } from '@backstage/plugin-catalog-react';
import { FilterRowLayout } from '@lego/plugin-baseplate-catalog';
import {
  FavoriteEntitiesCardsList,
  OverviewPageHeader,
} from '@lego/plugin-baseplate-core-components';
import React from 'react';
import { CustomFilter } from './CustomFilter';
import { ProductsEntitiesList } from './ProductsEntitiesList';

export const ProductsExplorerPage = () => {
  return (
    <PageWithHeader
      pageTitleOverride="Products"
      title={
        <>
          <OverviewPageHeader
            title="Products"
            description="The products are the canvas through which we do our job, enabling
              the day-to-day dialogues between how we do business and how we do
              technology."
          />
          <FavoriteEntitiesCardsList kind="Product" />
        </>
      }
      themeId="products"
    >
      <Content>
        <EntityListProvider>
          <FilterRowLayout
            pickers={[<CustomFilter />]}
            reingestion={{
              provider: 'LeanIxProvider:refresh',
              kind: 'Product',
            }}
          />
          <ProductsEntitiesList />
        </EntityListProvider>
      </Content>
    </PageWithHeader>
  );
};
