import { baseplateStyles } from '../../utils';

export const useCodeChipStyles = baseplateStyles(theme => ({
  root: {
    padding: '0.25rem 0.5rem',
    backgroundColor: theme.semantic.background.code,
    fontFamily: theme.primitive.font.family.mono,
    display: 'inline-flex',
    alignItems: 'center',
    borderRadius: theme.primitive.borderRadius.default,
  },
  content: {
    // Hide scrollbar
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  copyButton: {
    background: theme.component.button.outlined.background.default,
    border: `1px solid ${theme.component.button.outlined.border.default}`,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.primitive.borderRadius.default,
    cursor: 'pointer',
    marginLeft: '0.5rem',
    transition: 'background 0.2s, border 0.2s',
    '&:hover': {
      background: theme.component.button.outlined.background.hover,
      borderColor: theme.component.button.outlined.border.hover,
    },
  },
}));
