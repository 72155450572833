import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Pause(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M13 11v18h3V11h-3Zm-3 0a3 3 0 0 1 3-3h3c1.625 0 3 1.375 3 3v18a3 3 0 0 1-3 3h-3c-1.688 0-3-1.313-3-3V11Zm14 0v18h3V11h-3Zm-3 0a3 3 0 0 1 3-3h3c1.625 0 3 1.375 3 3v18a3 3 0 0 1-3 3h-3c-1.688 0-3-1.313-3-3V11Z" />
    </SvgIcon>
  );
}
