import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ArrowsDimensions(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M12.717 33.921a1.466 1.466 0 0 1-1.78 1.03L4.13 33.123c-.85-.229-1.318-1.04-1.059-1.839l1.824-6.825a1.364 1.364 0 0 1 1.78-1.03c.82.175 1.287.986 1.06 1.839l-.89 3.327.018.031 11.637-6.28V9.107h-.016l-2.437 2.444c-.563.626-1.5.626-2.063 0h-.062a1.478 1.478 0 0 1 0-2.068l5-5.013c.625-.627 1.562-.627 2.125 0l5 5.013a1.375 1.375 0 0 1 0 2.068c-.563.626-1.5.626-2.125 0L21.5 9.123v13.395l11.606 5.902-.842-3.152c-.228-.853.239-1.664 1.059-1.839a1.364 1.364 0 0 1 1.78 1.03l1.824 6.825c.259.799-.209 1.61-1.06 1.839l-6.806 1.829a1.466 1.466 0 0 1-1.78-1.03l.03-.055a1.368 1.368 0 0 1 1.029-1.785l3.318-.891.06-.104-11.885-6.044-11.425 6.166 3.25.873c.82.175 1.287.986 1.028 1.785l.031.054Z" />
    </SvgIcon>
  );
}
