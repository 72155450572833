import { BackstageOverrides } from '@backstage/core-components';
import { DesignTokens } from '../types';

export const card = (
  tokens: DesignTokens,
): Pick<
  BackstageOverrides,
  | 'BackstageItemCardHeader'
  | 'MuiCardHeader'
  | 'MuiCardContent'
  | 'MuiCardActions'
  | 'BackstageInfoCard'
> => ({
  BackstageItemCardHeader: {
    root: {
      color: tokens.semantic.text.primary,
    },
  },
  MuiCardHeader: {
    root: {
      margin: tokens.primitive.dimensions[300],
      padding: 0,
    },
    action: {
      marginTop: 0,
      marginRight: 0,
    },
    title: {
      margin: 0,
    },
    content: {
      padding: 0,
    },
  },
  MuiCardContent: {
    root: {
      margin: tokens.primitive.dimensions[300],
      padding: 0,
      // this is needed for the tool cards (so the button is at the bottom)
      flexGrow: 1,
    },
  },
  MuiCardActions: {
    root: {
      marginTop: 40,
    },
  },
  BackstageInfoCard: {
    header: {
      padding: 0,
    },
  },
});
