import { customIcons } from './custom-icons/customIcons';
import AccountProfile from './icons/AccountProfile';
import AccountProfileColor from './icons/AccountProfileColor';
import AccountProfileColorFlat from './icons/AccountProfileColorFlat';
import AddressCard from './icons/AddressCard';
import AlignCenter from './icons/AlignCenter';
import AlignLeft from './icons/AlignLeft';
import AlignRight from './icons/AlignRight';
import AppleIos from './icons/AppleIos';
import AppleMacos from './icons/AppleMacos';
import Arrow360Oval from './icons/Arrow360Oval';
import ArrowDown from './icons/ArrowDown';
import ArrowDownLeftAndArrowUpRightToCenter from './icons/ArrowDownLeftAndArrowUpRightToCenter';
import ArrowDownToLine from './icons/ArrowDownToLine';
import ArrowLeft from './icons/ArrowLeft';
import ArrowRight from './icons/ArrowRight';
import ArrowRightArrowLeft from './icons/ArrowRightArrowLeft';
import ArrowRightFromArc from './icons/ArrowRightFromArc';
import ArrowRightFromBracket from './icons/ArrowRightFromBracket';
import ArrowRightToBracket from './icons/ArrowRightToBracket';
import ArrowsDimensions from './icons/ArrowsDimensions';
import ArrowsLeftRight from './icons/ArrowsLeftRight';
import ArrowsMaximize from './icons/ArrowsMaximize';
import ArrowsMinimize from './icons/ArrowsMinimize';
import ArrowSplitThree from './icons/ArrowSplitThree';
import ArrowsRotate from './icons/ArrowsRotate';
import ArrowsToDot from './icons/ArrowsToDot';
import ArrowsUpDown from './icons/ArrowsUpDown';
import ArrowsUpDownLeftRight from './icons/ArrowsUpDownLeftRight';
import ArrowTurnDownLeft from './icons/ArrowTurnDownLeft';
import ArrowTurnDownRight from './icons/ArrowTurnDownRight';
import ArrowUp from './icons/ArrowUp';
import ArrowUpArrowDown from './icons/ArrowUpArrowDown';
import ArrowUpFromSquare from './icons/ArrowUpFromSquare';
import ArrowUpRight from './icons/ArrowUpRight';
import ArrowUpRightAndArrowDownLeftFromCenter from './icons/ArrowUpRightAndArrowDownLeftFromCenter';
import ArrowUpRightFromSquare from './icons/ArrowUpRightFromSquare';
import ArrowUpToLine from './icons/ArrowUpToLine';
import Award from './icons/Award';
import Backward from './icons/Backward';
import BackwardStep from './icons/BackwardStep';
import BadgePercent from './icons/BadgePercent';
import Balloon from './icons/Balloon';
import Ban from './icons/Ban';
import BarcodeRead from './icons/BarcodeRead';
import Bars from './icons/Bars';
import BatteryExclamation from './icons/BatteryExclamation';
import BatteryFull from './icons/BatteryFull';
import BatteryHalf from './icons/BatteryHalf';
import BatteryLow from './icons/BatteryLow';
import BatteryThreeQuarters from './icons/BatteryThreeQuarters';
import Bell from './icons/Bell';
import BellLock from './icons/BellLock';
import BellOn from './icons/BellOn';
import BellPlus from './icons/BellPlus';
import BirthdayCake from './icons/BirthdayCake';
import Bluetooth from './icons/Bluetooth';
import Bold from './icons/Bold';
import Bolt from './icons/Bolt';
import BoltSlash from './icons/BoltSlash';
import Bookmark from './icons/Bookmark';
import BookOpen from './icons/BookOpen';
import BookOpenReader from './icons/BookOpenReader';
import BoxArchive from './icons/BoxArchive';
import BoxMagnifying from './icons/BoxMagnifying';
import BrickOneByOneIso from './icons/BrickOneByOneIso';
import BrickOneByOneSideBlue from './icons/BrickOneByOneSideBlue';
import BrickOneByOneSideOutline from './icons/BrickOneByOneSideOutline';
import BrickTwoByTwoInstructions from './icons/BrickTwoByTwoInstructions';
import BrickTwoByTwoIso from './icons/BrickTwoByTwoIso';
import BrickTwoByTwoSide from './icons/BrickTwoByTwoSide';
import BrickTwoByTwoSideScan from './icons/BrickTwoByTwoSideScan';
import BrickTwoByTwoStudiofile from './icons/BrickTwoByTwoStudiofile';
import BrickTwoByTwoTop from './icons/BrickTwoByTwoTop';
import Bucket from './icons/Bucket';
import Bug from './icons/Bug';
import Bullhorn from './icons/Bullhorn';
import BullhornOn from './icons/BullhornOn';
import Bullseye from './icons/Bullseye';
import Calendar from './icons/Calendar';
import CalendarCheck from './icons/CalendarCheck';
import CalendarClock from './icons/CalendarClock';
import Camera from './icons/Camera';
import CartFlatbed from './icons/CartFlatbed';
import ChartLine from './icons/ChartLine';
import ChartLineDown from './icons/ChartLineDown';
import ChartLineUp from './icons/ChartLineUp';
import ChartLineUpDown from './icons/ChartLineUpDown';
import ChartPieSimple from './icons/ChartPieSimple';
import ChartSimple from './icons/ChartSimple';
import ChevronDown from './icons/ChevronDown';
import ChevronLeft from './icons/ChevronLeft';
import ChevronRight from './icons/ChevronRight';
import ChevronUp from './icons/ChevronUp';
import CircleInfo from './icons/CircleInfo';
import CircleMinus from './icons/CircleMinus';
import CirclePlus from './icons/CirclePlus';
import CircleQuestion from './icons/CircleQuestion';
import CircleXmark from './icons/CircleXmark';
import ClipboardCheck from './icons/ClipboardCheck';
import ClipboardList from './icons/ClipboardList';
import ClipboardPlus from './icons/ClipboardPlus';
import ClipboardQuestion from './icons/ClipboardQuestion';
import Clock from './icons/Clock';
import Clone from './icons/Clone';
import CloudApi from './icons/CloudApi';
import CloudArrowUpArrowDown from './icons/CloudArrowUpArrowDown';
import CloudFolderOpen from './icons/CloudFolderOpen';
import Code from './icons/Code';
import Coins from './icons/Coins';
import Compass from './icons/Compass';
import ComputerMouseScrollwheel from './icons/ComputerMouseScrollwheel';
import ControlCheck from './icons/ControlCheck';
import ControlClose from './icons/ControlClose';
import ControlMinus from './icons/ControlMinus';
import ControlPlus from './icons/ControlPlus';
import ConveyorBeltArm from './icons/ConveyorBeltArm';
import CreditCardCvc from './icons/CreditCardCvc';
import CreditCardFront from './icons/CreditCardFront';
import CubeArrows3d from './icons/CubeArrows3d';
import Cubes from './icons/Cubes';
import Desktop from './icons/Desktop';
import DollarArrowUpArrowDown from './icons/DollarArrowUpArrowDown';
import DollarSign from './icons/DollarSign';
import DoubleChevron from './icons/DoubleChevron';
import Ellipsis from './icons/Ellipsis';
import EllipsisVertical from './icons/EllipsisVertical';
import EmissionFree from './icons/EmissionFree';
import Envelope from './icons/Envelope';
import Expand from './icons/Expand';
import Eye from './icons/Eye';
import EyeSlash from './icons/EyeSlash';
import Facebook from './icons/Facebook';
import Family from './icons/Family';
import FileCertificate from './icons/FileCertificate';
import FileDatabase from './icons/FileDatabase';
import FileInvoiceDollar from './icons/FileInvoiceDollar';
import FilePdf from './icons/FilePdf';
import FilePen from './icons/FilePen';
import FilePlus from './icons/FilePlus';
import FileQuery from './icons/FileQuery';
import FileText from './icons/FileText';
import FileXmark from './icons/FileXmark';
import Film from './icons/Film';
import Filter from './icons/Filter';
import FlagCode from './icons/FlagCode';
import FlagGo from './icons/FlagGo';
import FlagSwallowtail from './icons/FlagSwallowtail';
import FloppyDisk from './icons/FloppyDisk';
import Font from './icons/Font';
import Forward from './icons/Forward';
import ForwardStep from './icons/ForwardStep';
import GamepadModern from './icons/GamepadModern';
import Gauge from './icons/Gauge';
import Gear from './icons/Gear';
import Gem from './icons/Gem';
import Gift from './icons/Gift';
import Globe from './icons/Globe';
import GraduationCap from './icons/GraduationCap';
import Grid from './icons/Grid';
import Grid2 from './icons/Grid2';
import Grid245 from './icons/Grid245';
import GripDotsVertical from './icons/GripDotsVertical';
import Hand from './icons/Hand';
import HandHoldingDollar from './icons/HandHoldingDollar';
import HandHoldingUser from './icons/HandHoldingUser';
import HandPinch from './icons/HandPinch';
import HandPointer from './icons/HandPointer';
import HandScreenLock from './icons/HandScreenLock';
import HandshakeSimple from './icons/HandshakeSimple';
import HandsHoldingDollar from './icons/HandsHoldingDollar';
import HandSwipe from './icons/HandSwipe';
import HandTap from './icons/HandTap';
import HardDrive from './icons/HardDrive';
import Hashtag from './icons/Hashtag';
import Headset from './icons/Headset';
import Heart from './icons/Heart';
import HelmetSafety from './icons/HelmetSafety';
import HourglassHalf from './icons/HourglassHalf';
import HourglassStart from './icons/HourglassStart';
import House from './icons/House';
import Image from './icons/Image';
import Industry from './icons/Industry';
import Instagram from './icons/Instagram';
import Italic from './icons/Italic';
import Key from './icons/Key';
import Language from './icons/Language';
import Laptop from './icons/Laptop';
import LaptopMobile from './icons/LaptopMobile';
import LaptopSmiley from './icons/LaptopSmiley';
import LayerArrowDown from './icons/LayerArrowDown';
import LayerArrowUp from './icons/LayerArrowUp';
import LayerGroup from './icons/LayerGroup';
import LegoGift from './icons/LegoGift';
import Lightbulb from './icons/Lightbulb';
import LightbulbOn from './icons/LightbulbOn';
import Line from './icons/Line';
import Link from './icons/Link';
import Linkedin from './icons/Linkedin';
import List from './icons/List';
import ListCheck from './icons/ListCheck';
import ListMinusCircle from './icons/ListMinusCircle';
import ListMusic from './icons/ListMusic';
import ListPlusCircle from './icons/ListPlusCircle';
import Loader from './icons/Loader';
import LocationDot from './icons/LocationDot';
import Lock from './icons/Lock';
import LockOpen from './icons/LockOpen';
import MagnifyingGlass from './icons/MagnifyingGlass';
import MagnifyingGlassMinus from './icons/MagnifyingGlassMinus';
import MagnifyingGlassPlus from './icons/MagnifyingGlassPlus';
import Message from './icons/Message';
import MessageDots from './icons/MessageDots';
import MessageExclamation from './icons/MessageExclamation';
import MessageQuestion from './icons/MessageQuestion';
import MessagesDollar from './icons/MessagesDollar';
import MicrosoftWindowsos from './icons/MicrosoftWindowsos';
import MinifigureFaceless1 from './icons/MinifigureFaceless1';
import MinifigureFaceless2 from './icons/MinifigureFaceless2';
import MinifigureFaceless3 from './icons/MinifigureFaceless3';
import MinifigureFaceless4 from './icons/MinifigureFaceless4';
import MinifigureFaceless5 from './icons/MinifigureFaceless5';
import Mobile from './icons/Mobile';
import Mouse from './icons/Mouse';
import MusicNote from './icons/MusicNote';
import NetworkWired from './icons/NetworkWired';
import Newspaper from './icons/Newspaper';
import NotesPlus from './icons/NotesPlus';
import ObjectsColumn from './icons/ObjectsColumn';
import PaintbrushFine from './icons/PaintbrushFine';
import Palette from './icons/Palette';
import Paperclip from './icons/Paperclip';
import PaperPlane from './icons/PaperPlane';
import Pause from './icons/Pause';
import Pen from './icons/Pen';
import PenToSquare from './icons/PenToSquare';
import Percent from './icons/Percent';
import Phone from './icons/Phone';
import Pinterest from './icons/Pinterest';
import Play from './icons/Play';
import PowerOff from './icons/PowerOff';
import PresentationScreen from './icons/PresentationScreen';
import Print from './icons/Print';
import QrcodeRead from './icons/QrcodeRead';
import RectangleBarcode from './icons/RectangleBarcode';
import Recycle from './icons/Recycle';
import Reddit from './icons/Reddit';
import Robot from './icons/Robot';
import RocketLaunch from './icons/RocketLaunch';
import RotateLeft from './icons/RotateLeft';
import RotateRight from './icons/RotateRight';
import Ruler from './icons/Ruler';
import SackDollar from './icons/SackDollar';
import ScaleBalanced from './icons/ScaleBalanced';
import Scissors from './icons/Scissors';
import ScrewdriverWrench from './icons/ScrewdriverWrench';
import Server from './icons/Server';
import Shapes from './icons/Shapes';
import ShareNodes from './icons/ShareNodes';
import Shield from './icons/Shield';
import ShieldCheck from './icons/ShieldCheck';
import ShoppingBag from './icons/ShoppingBag';
import Shuffle from './icons/Shuffle';
import SignsPost from './icons/SignsPost';
import Sitemap from './icons/Sitemap';
import SlidersSimple from './icons/SlidersSimple';
import SlidersUp from './icons/SlidersUp';
import SpinnerThird from './icons/SpinnerThird';
import Split from './icons/Split';
import Square from './icons/Square';
import StackedBricksSideBluePurple from './icons/StackedBricksSideBluePurple';
import StackedBricksSideBlueYellow from './icons/StackedBricksSideBlueYellow';
import StackedImages from './icons/StackedImages';
import Star from './icons/Star';
import StatusCircleCheck from './icons/StatusCircleCheck';
import StatusCircleExclamation from './icons/StatusCircleExclamation';
import StatusTriangleExclamation from './icons/StatusTriangleExclamation';
import Stop from './icons/Stop';
import Stopwatch from './icons/Stopwatch';
import StopwatchExclamation from './icons/StopwatchExclamation';
import SustrainableDelivery from './icons/SustrainableDelivery';
import TableColumns from './icons/TableColumns';
import TableRows from './icons/TableRows';
import Tablet from './icons/Tablet';
import TableTennisPaddleBall from './icons/TableTennisPaddleBall';
import Tag from './icons/Tag';
import TagBarcode from './icons/TagBarcode';
import TagDollar from './icons/TagDollar';
import TagEuro from './icons/TagEuro';
import Text from './icons/Text';
import ThumbsDown from './icons/ThumbsDown';
import ThumbsUp from './icons/ThumbsUp';
import Thumbtack from './icons/Thumbtack';
import TicketSimple from './icons/TicketSimple';
import Trash from './icons/Trash';
import Trophy from './icons/Trophy';
import TrophyStar from './icons/TrophyStar';
import Truck from './icons/Truck';
import Tv from './icons/Tv';
import Underline from './icons/Underline';
import UsbDrive from './icons/UsbDrive';
import UserGroupSimple from './icons/UserGroupSimple';
import Users from './icons/Users';
import UsersLightbulbOn from './icons/UsersLightbulbOn';
import VolumeHigh from './icons/VolumeHigh';
import VolumeLow from './icons/VolumeLow';
import VolumeMedium from './icons/VolumeMedium';
import VolumeOff from './icons/VolumeOff';
import VrCardboard from './icons/VrCardboard';
import Warehouse from './icons/Warehouse';
import WatchApple from './icons/WatchApple';
import WavePulse from './icons/WavePulse';
import WeightScale from './icons/WeightScale';
import Whatsapp from './icons/Whatsapp';
import Wifi from './icons/Wifi';
import WifiSlash from './icons/WifiSlash';
import WindowMagnifyingGllass from './icons/WindowMagnifyingGllass';
import WindowRestore from './icons/WindowRestore';
import WindowStopwatch from './icons/WindowStopwatch';
import XTwitter from './icons/XTwitter';
import Youtube from './icons/Youtube';

export const iconLib = {
  'account-profile': AccountProfile,
  'account-profile-color': AccountProfileColor,
  'account-profile-color-flat': AccountProfileColorFlat,
  'address-card': AddressCard,
  'align-center': AlignCenter,
  'align-left': AlignLeft,
  'align-right': AlignRight,
  'apple-ios': AppleIos,
  'apple-macos': AppleMacos,
  'arrow-360-oval': Arrow360Oval,
  'arrow-down': ArrowDown,
  'arrow-down-left-and-arrow-up-right-to-center':
    ArrowDownLeftAndArrowUpRightToCenter,
  'arrow-down-to-line': ArrowDownToLine,
  'arrow-left': ArrowLeft,
  'arrow-right': ArrowRight,
  'arrow-right-arrow-left': ArrowRightArrowLeft,
  'arrow-right-from-arc': ArrowRightFromArc,
  'arrow-right-from-bracket': ArrowRightFromBracket,
  'arrow-right-to-bracket': ArrowRightToBracket,
  'arrows-dimensions': ArrowsDimensions,
  'arrows-left-right': ArrowsLeftRight,
  'arrows-maximize': ArrowsMaximize,
  'arrows-minimize': ArrowsMinimize,
  'arrow-split-three': ArrowSplitThree,
  'arrows-rotate': ArrowsRotate,
  'arrows-to-dot': ArrowsToDot,
  'arrows-up-down': ArrowsUpDown,
  'arrows-up-down-left-right': ArrowsUpDownLeftRight,
  'arrow-turn-down-left': ArrowTurnDownLeft,
  'arrow-turn-down-right': ArrowTurnDownRight,
  'arrow-up': ArrowUp,
  'arrow-up-arrow-down': ArrowUpArrowDown,
  'arrow-up-from-square': ArrowUpFromSquare,
  'arrow-up-right': ArrowUpRight,
  'arrow-up-right-and-arrow-down-left-from-center':
    ArrowUpRightAndArrowDownLeftFromCenter,
  'arrow-up-right-from-square': ArrowUpRightFromSquare,
  'arrow-up-to-line': ArrowUpToLine,
  award: Award,
  backward: Backward,
  'backward-step': BackwardStep,
  'badge-percent': BadgePercent,
  balloon: Balloon,
  ban: Ban,
  'barcode-read': BarcodeRead,
  bars: Bars,
  'battery-exclamation': BatteryExclamation,
  'battery-full': BatteryFull,
  'battery-half': BatteryHalf,
  'battery-low': BatteryLow,
  'battery-three-quarters': BatteryThreeQuarters,
  bell: Bell,
  'bell-lock': BellLock,
  'bell-on': BellOn,
  'bell-plus': BellPlus,
  'birthday-cake': BirthdayCake,
  bluetooth: Bluetooth,
  bold: Bold,
  bolt: Bolt,
  'bolt-slash': BoltSlash,
  bookmark: Bookmark,
  'book-open': BookOpen,
  'book-open-reader': BookOpenReader,
  'box-archive': BoxArchive,
  'box-magnifying': BoxMagnifying,
  'brick-one-by-one-iso': BrickOneByOneIso,
  'brick-one-by-one-side-blue': BrickOneByOneSideBlue,
  'brick-one-by-one-side-outline': BrickOneByOneSideOutline,
  'brick-two-by-two-instructions': BrickTwoByTwoInstructions,
  'brick-two-by-two-iso': BrickTwoByTwoIso,
  'brick-two-by-two-side': BrickTwoByTwoSide,
  'brick-two-by-two-side-scan': BrickTwoByTwoSideScan,
  'brick-two-by-two-studiofile': BrickTwoByTwoStudiofile,
  'brick-two-by-two-top': BrickTwoByTwoTop,
  bucket: Bucket,
  bug: Bug,
  bullhorn: Bullhorn,
  'bullhorn-on': BullhornOn,
  bullseye: Bullseye,
  calendar: Calendar,
  'calendar-check': CalendarCheck,
  'calendar-clock': CalendarClock,
  camera: Camera,
  'cart-flatbed': CartFlatbed,
  'chart-line': ChartLine,
  'chart-line-down': ChartLineDown,
  'chart-line-up': ChartLineUp,
  'chart-line-up-down': ChartLineUpDown,
  'chart-pie-simple': ChartPieSimple,
  'chart-simple': ChartSimple,
  'chevron-down': ChevronDown,
  'chevron-left': ChevronLeft,
  'chevron-right': ChevronRight,
  'chevron-up': ChevronUp,
  'circle-info': CircleInfo,
  'circle-minus': CircleMinus,
  'circle-plus': CirclePlus,
  'circle-question': CircleQuestion,
  'circle-xmark': CircleXmark,
  'clipboard-check': ClipboardCheck,
  'clipboard-list': ClipboardList,
  'clipboard-plus': ClipboardPlus,
  'clipboard-question': ClipboardQuestion,
  clock: Clock,
  clone: Clone,
  'cloud-api': CloudApi,
  'cloud-arrow-up-arrow-down': CloudArrowUpArrowDown,
  'cloud-folder-open': CloudFolderOpen,
  code: Code,
  coins: Coins,
  compass: Compass,
  'computer-mouse-scrollwheel': ComputerMouseScrollwheel,
  'control-check': ControlCheck,
  'control-close': ControlClose,
  'control-minus': ControlMinus,
  'control-plus': ControlPlus,
  'conveyor-belt-arm': ConveyorBeltArm,
  'credit-card-cvc': CreditCardCvc,
  'credit-card-front': CreditCardFront,
  'cube-arrows-3d': CubeArrows3d,
  cubes: Cubes,
  desktop: Desktop,
  'dollar-arrow-up-arrow-down': DollarArrowUpArrowDown,
  'dollar-sign': DollarSign,
  'double-chevron': DoubleChevron,
  ellipsis: Ellipsis,
  'ellipsis-vertical': EllipsisVertical,
  'emission-free': EmissionFree,
  envelope: Envelope,
  expand: Expand,
  eye: Eye,
  'eye-slash': EyeSlash,
  facebook: Facebook,
  family: Family,
  'file-certificate': FileCertificate,
  'file-database': FileDatabase,
  'file-invoice-dollar': FileInvoiceDollar,
  'file-pdf': FilePdf,
  'file-pen': FilePen,
  'file-plus': FilePlus,
  'file-query': FileQuery,
  'file-text': FileText,
  'file-xmark': FileXmark,
  film: Film,
  filter: Filter,
  'flag-code': FlagCode,
  'flag-go': FlagGo,
  'flag-swallowtail': FlagSwallowtail,
  'floppy-disk': FloppyDisk,
  font: Font,
  forward: Forward,
  'forward-step': ForwardStep,
  'gamepad-modern': GamepadModern,
  gauge: Gauge,
  gear: Gear,
  gem: Gem,
  gift: Gift,
  globe: Globe,
  'graduation-cap': GraduationCap,
  grid: Grid,
  'grid-2': Grid2,
  'grid-2-45': Grid245,
  'grip-dots-vertical': GripDotsVertical,
  hand: Hand,
  'hand-holding-dollar': HandHoldingDollar,
  'hand-holding-user': HandHoldingUser,
  'hand-pinch': HandPinch,
  'hand-pointer': HandPointer,
  'hand-screen-lock': HandScreenLock,
  'handshake-simple': HandshakeSimple,
  'hands-holding-dollar': HandsHoldingDollar,
  'hand-swipe': HandSwipe,
  'hand-tap': HandTap,
  'hard-drive': HardDrive,
  hashtag: Hashtag,
  headset: Headset,
  heart: Heart,
  'helmet-safety': HelmetSafety,
  'hourglass-half': HourglassHalf,
  'hourglass-start': HourglassStart,
  house: House,
  image: Image,
  industry: Industry,
  instagram: Instagram,
  italic: Italic,
  key: Key,
  language: Language,
  laptop: Laptop,
  'laptop-mobile': LaptopMobile,
  'laptop-smiley': LaptopSmiley,
  'layer-arrow-down': LayerArrowDown,
  'layer-arrow-up': LayerArrowUp,
  'layer-group': LayerGroup,
  'lego-gift': LegoGift,
  lightbulb: Lightbulb,
  'lightbulb-on': LightbulbOn,
  line: Line,
  link: Link,
  linkedin: Linkedin,
  list: List,
  'list-check': ListCheck,
  'list-minus-circle': ListMinusCircle,
  'list-music': ListMusic,
  'list-plus-circle': ListPlusCircle,
  loader: Loader,
  'location-dot': LocationDot,
  lock: Lock,
  'lock-open': LockOpen,
  'magnifying-glass': MagnifyingGlass,
  'magnifying-glass-minus': MagnifyingGlassMinus,
  'magnifying-glass-plus': MagnifyingGlassPlus,
  message: Message,
  'message-dots': MessageDots,
  'message-exclamation': MessageExclamation,
  'message-question': MessageQuestion,
  'messages-dollar': MessagesDollar,
  'microsoft-windowsos': MicrosoftWindowsos,
  'minifigure-faceless-1': MinifigureFaceless1,
  'minifigure-faceless-2': MinifigureFaceless2,
  'minifigure-faceless-3': MinifigureFaceless3,
  'minifigure-faceless-4': MinifigureFaceless4,
  'minifigure-faceless-5': MinifigureFaceless5,
  mobile: Mobile,
  mouse: Mouse,
  'music-note': MusicNote,
  'network-wired': NetworkWired,
  newspaper: Newspaper,
  'notes-plus': NotesPlus,
  'objects-column': ObjectsColumn,
  'paintbrush-fine': PaintbrushFine,
  palette: Palette,
  paperclip: Paperclip,
  'paper-plane': PaperPlane,
  pause: Pause,
  pen: Pen,
  'pen-to-square': PenToSquare,
  percent: Percent,
  phone: Phone,
  pinterest: Pinterest,
  play: Play,
  'power-off': PowerOff,
  'presentation-screen': PresentationScreen,
  print: Print,
  'qrcode-read': QrcodeRead,
  'rectangle-barcode': RectangleBarcode,
  recycle: Recycle,
  reddit: Reddit,
  robot: Robot,
  'rocket-launch': RocketLaunch,
  'rotate-left': RotateLeft,
  'rotate-right': RotateRight,
  ruler: Ruler,
  'sack-dollar': SackDollar,
  'scale-balanced': ScaleBalanced,
  scissors: Scissors,
  'screwdriver-wrench': ScrewdriverWrench,
  server: Server,
  shapes: Shapes,
  'share-nodes': ShareNodes,
  shield: Shield,
  'shield-check': ShieldCheck,
  'shopping-bag': ShoppingBag,
  shuffle: Shuffle,
  'signs-post': SignsPost,
  sitemap: Sitemap,
  'sliders-simple': SlidersSimple,
  'sliders-up': SlidersUp,
  spinnerThird: SpinnerThird,
  split: Split,
  square: Square,
  'stacked-bricks-side-blue-purple': StackedBricksSideBluePurple,
  'stacked-bricks-side-blue-yellow': StackedBricksSideBlueYellow,
  'stacked-images': StackedImages,
  star: Star,
  'status-circle-check': StatusCircleCheck,
  'status-circle-exclamation': StatusCircleExclamation,
  'status-triangle-exclamation': StatusTriangleExclamation,
  stop: Stop,
  stopwatch: Stopwatch,
  'stopwatch-exclamation': StopwatchExclamation,
  'sustrainable-delivery': SustrainableDelivery,
  'table-columns': TableColumns,
  'table-rows': TableRows,
  tablet: Tablet,
  'table-tennis-paddle-ball': TableTennisPaddleBall,
  tag: Tag,
  'tag-barcode': TagBarcode,
  'tag-dollar': TagDollar,
  'tag-euro': TagEuro,
  text: Text,
  'thumbs-down': ThumbsDown,
  'thumbs-up': ThumbsUp,
  thumbtack: Thumbtack,
  'ticket-simple': TicketSimple,
  trash: Trash,
  trophy: Trophy,
  'trophy-star': TrophyStar,
  truck: Truck,
  tv: Tv,
  underline: Underline,
  'usb-drive': UsbDrive,
  'user-group-simple': UserGroupSimple,
  users: Users,
  'users-lightbulb-on': UsersLightbulbOn,
  'volume-high': VolumeHigh,
  'volume-low': VolumeLow,
  'volume-medium': VolumeMedium,
  'volume-off': VolumeOff,
  'vr-cardboard': VrCardboard,
  warehouse: Warehouse,
  'watch-apple': WatchApple,
  'wave-pulse': WavePulse,
  'weight-scale': WeightScale,
  whatsapp: Whatsapp,
  wifi: Wifi,
  'wifi-slash': WifiSlash,
  'window-magnifying-gllass': WindowMagnifyingGllass,
  'window-restore': WindowRestore,
  'window-stopwatch': WindowStopwatch,
  'x-twitter': XTwitter,
  youtube: Youtube,
  ...customIcons,
};
