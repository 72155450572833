import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Split(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M28.453 5.914a1.471 1.471 0 0 1 2.063 0l5 5c.625.625.625 1.563 0 2.125l-5 5a1.37 1.37 0 0 1-2.063 0c-.625-.562-.625-1.5 0-2.125l2.438-2.437h-5.25c-.688 0-1.313.312-1.75.75l-5.813 5.75 5.813 5.812c.437.438 1.062.688 1.75.688h5.25l-2.438-2.438a1.369 1.369 0 0 1 0-2.062 1.369 1.369 0 0 1 2.063 0l5 5c.625.562.625 1.5 0 2.062l-5 5a1.37 1.37 0 0 1-2.063 0 1.369 1.369 0 0 1 0-2.062l2.438-2.438h-5.25c-1.438 0-2.875-.625-3.875-1.625l-6.375-6.437H5.516c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5h9.875l6.375-6.375c1-1 2.437-1.625 3.875-1.625h5.25l-2.438-2.438a1.369 1.369 0 0 1 0-2.062v-.063Z" />
    </SvgIcon>
  );
}
