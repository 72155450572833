import { rootRouteRef } from './routes';
import {
  configApiRef,
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  githubAuthApiRef,
} from '@backstage/core-plugin-api';
import { PLUGIN_ID, PLUGIN_NAME } from './constants';
import { MBCPluginGithubApiRef, MBCPluginGithubClient } from './api';

export const macBuildCloudPlugin = createPlugin({
  id: PLUGIN_ID,
  routes: {
    root: rootRouteRef,
  },
  featureFlags: [{ name: PLUGIN_ID }],
  apis: [
    createApiFactory({
      api: MBCPluginGithubApiRef,
      deps: {
        configApi: configApiRef,
        githubAuthApi: githubAuthApiRef,
      },
      factory: ({ configApi, githubAuthApi }) =>
        new MBCPluginGithubClient({
          configApi,
          githubAuthApi,
        }),
    }),
  ],
});

export const MacBuildCloudPage = macBuildCloudPlugin.provide(
  createRoutableExtension({
    name: PLUGIN_NAME,
    component: () => import('./pages/Dashboard').then((m) => m.Dashboard),
    mountPoint: rootRouteRef,
  })
);
