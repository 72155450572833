import React from 'react';
import {
  Dropdown,
  EntityDropdown,
  FormStepTitle,
  Input,
} from '@lego/plugin-baseplate-core-components';
import { Box, Grid, Typography } from '@material-ui/core';
import { StepProps } from '../types';
import { getCloudAccountName } from '../utils/getCloudAccountName';
import { stringifyEntityRef } from '@backstage/catalog-model';

export const StepInformation = ({ values, updateValue }: StepProps) => {
  return (
    <Grid>
      <FormStepTitle
        formTitle="Create Cloud Account"
        stepTitle="Account information"
        step={2}
      />
      <Box display="flex" mt={1} mb={1}>
        <Input
          label="Name"
          helperText='format the name like this: "name-secondary"'
          value={values.name}
          onChange={e => updateValue('name', e.target.value)}
          required
        />
        <Dropdown
          label="Environment"
          required
          options={[
            { label: 'SANDBOX', value: 'Sandbox' },
            { label: 'DEV', value: 'Test' },
            { label: 'QA', value: 'QA' },
            { label: 'UAT', value: 'UAT' },
            { label: 'STAGING', value: 'Staging' },
            { label: 'PROD', value: 'Prod' },
          ]}
          onChange={e => updateValue('environment', e.target.value as string)}
        />
      </Box>
      <Typography variant="body1">
        Final account name:{' '}
        {getCloudAccountName(
          values.name as string,
          values.environment as string,
        )}
      </Typography>
      <EntityDropdown
        kind="Application"
        value={values.application as string}
        handleChange={entity =>
          updateValue('application', stringifyEntityRef(entity))
        }
        required
      />
      <Input
        label="Purpose"
        helperText="Describe what this account is used for. To be able to figure out what this account does in 3 years."
        value={values.purpose}
        onChange={e => updateValue('purpose', e.target.value)}
        multiline
        required
      />
      <EntityDropdown
        label="Owner"
        kind="User"
        value={values.owner as string}
        handleChange={entity =>
          updateValue('owner', stringifyEntityRef(entity))
        }
        required
      />
      <EntityDropdown
        kind="User"
        label="Backup owner"
        value={values.backupOwner as string}
        handleChange={entity =>
          updateValue('backupOwner', stringifyEntityRef(entity))
        }
        required
      />
    </Grid>
  );
};
