import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ComputerMouseScrollwheel(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M29 26V14c0-3.813-3.188-7-7-7h-4c-3.875 0-7 3.188-7 7v12c0 3.875 3.125 7 7 7h4c3.813 0 7-3.125 7-7ZM8 14C8 8.5 12.438 4 18 4h4c5.5 0 10 4.5 10 10v12c0 5.563-4.5 10-10 10h-4A9.95 9.95 0 0 1 8 26V14Zm12-4c1.063 0 2 .938 2 2v2c0 1.125-.938 2-2 2-1.125 0-2-.875-2-2v-2c0-1.063.875-2 2-2Z" />
    </SvgIcon>
  );
}
