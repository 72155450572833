import React, { useMemo } from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useStyles } from './CountStepper.styles';
import { Button, TextField, FormHelperText } from '@material-ui/core';

type CountStepperProps = {
  value: number;
  handleFieldChange: (newCount: number) => void;
  className?: string | undefined;
  error?: string;
  disabled?: boolean;
  min?: number;
  id?: string;
};
export const CountStepper = ({
  value,
  handleFieldChange,
  className = '',
  error,
  min = 0,
  disabled = false,
  id,
}: CountStepperProps) => {
  const count = useMemo(() => value, [value]);

  const styles = useStyles();

  const countError = useMemo(() => {
    return error ?? null;
  }, [error]);

  return (
    <>
      <div
        className={`${className && className} ${
          styles.countStepperGrp
        } countStepperGrp`}
      >
        <Button
          variant="contained"
          disabled={disabled || count === min}
          className={styles.countStepperBtn}
          onClick={() => {
            if (count !== 0) {
              handleFieldChange(Number(count) - 1);
            }
          }}
        >
          <RemoveIcon />
        </Button>
        <TextField
          value={count}
          disabled={disabled}
          className={styles.countStepperTxtField}
          onChange={e => {
            handleFieldChange(
              e?.target?.value === '' ||
                Number(e?.target?.value) < min ||
                isNaN(Number(e?.target?.value))
                ? min
                : Number(e?.target?.value),
            );
          }}
          id={id}
          variant="outlined"
          error={countError ? true : false}
        />
        <Button
          disabled={disabled}
          variant="contained"
          className={styles.countStepperBtn}
          onClick={() => {
            handleFieldChange(Number(count) + 1);
          }}
        >
          <AddIcon />
        </Button>
      </div>
      {countError && (
        <FormHelperText error={countError ? true : false}>
          {countError}
        </FormHelperText>
      )}
    </>
  );
};
