import React, { Fragment } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { countries } from '../../data/countries';

type ContryToFlagType = {
  countryName: string;
  isoCode?: string;
  width?: number;
  height?: number;
};

export const CountryToFlag = ({
  countryName,
  isoCode,
  width,
  height,
}: ContryToFlagType) => {
  const countryCode = isoCode
    ? isoCode
    : countries.find(c => c.name === countryName)?.abbr ?? '';
  return (
    <Fragment>
      <ReactCountryFlag
        countryCode={countryCode}
        svg
        title={countryName}
        style={{ marginRight: 10, width: width ?? 12, height: height ?? 12 }}
      />
    </Fragment>
  );
};
