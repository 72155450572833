import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function LaptopSmiley(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M8 7h24c.5 0 1 .5 1 1v18h3V8c0-2.188-1.813-4-4-4H8C5.75 4 4 5.813 4 8v18h3V8c0-.5.438-1 1-1Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 28c-1.125 0-2 .938-2 2 0 3.313 2.688 6 6 6h28c3.313 0 6-2.688 6-6 0-1.063-.938-2-2-2H2Zm32 5H6a3.05 3.05 0 0 1-2.875-2h33.688c-.438 1.188-1.563 2-2.813 2Z"
      />
      <path d="M20.02 22c-2.375 0-4-1.063-4.938-2.063a1.493 1.493 0 0 0-2.125-.125 1.549 1.549 0 0 0-.062 2.125C14.27 23.438 16.645 25 20.02 25c3.312 0 5.687-1.563 7.062-3.063.563-.625.563-1.562-.062-2.125a1.493 1.493 0 0 0-2.125.125c-.938 1-2.563 2.063-4.875 2.063Zm-5-11c-1.125 0-2 .938-2 2 0 1.125.875 2 2 2s2-.875 2-2c0-1.063-.875-2-2-2Zm12 2c0-1.063-.875-2-2-2s-2 .938-2 2c0 1.125.875 2 2 2s2-.875 2-2Z" />
    </SvgIcon>
  );
}
