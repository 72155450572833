import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ArrowTurnDownLeft(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M33.016 8.734c0-.812.625-1.5 1.5-1.5.812 0 1.5.688 1.5 1.5v10.5c0 3.063-2.5 5.5-5.5 5.5H9.078l5.438 5.438c.625.625.625 1.562 0 2.125a1.37 1.37 0 0 1-2.063 0l-8-8a1.369 1.369 0 0 1 0-2.063l8-8.062a1.471 1.471 0 0 1 2.063 0c.625.625.625 1.562 0 2.125l-5.438 5.437h21.438c1.375 0 2.5-1.125 2.5-2.5v-10.5Z" />
    </SvgIcon>
  );
}
