import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Youtube(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M36.344 11.813c.75 2.624.75 8.25.75 8.25s0 5.562-.75 8.25c-.375 1.5-1.563 2.625-3 3C30.656 32 20.03 32 20.03 32s-10.687 0-13.375-.688c-1.437-.375-2.625-1.5-3-3-.75-2.687-.75-8.25-.75-8.25s0-5.625.75-8.25c.375-1.5 1.563-2.687 3-3.062C9.344 8 20.031 8 20.031 8s10.625 0 13.313.75c1.437.375 2.625 1.563 3 3.063ZM16.53 25.124l8.875-5.063L16.531 15v10.125Z" />
    </SvgIcon>
  );
}
