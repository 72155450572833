import React from 'react';
import { DateTime } from 'luxon';
import { Button } from '../button';
import { RangeType } from 'rsuite/esm/DateRangePicker';
import { RangeButtonType } from './types';

const dateRangeButton = (label: string) => (
  <Button variant="secondary" size="small">
    {label}
  </Button>
);

export const generateRangeButtons = (ranges: RangeButtonType[]): RangeType[] =>
  ranges.map(({ label, value }) => ({
    label: dateRangeButton(label),
    value,
    placement: 'left',
  }));

export const predefinedRanges: RangeButtonType[] = [
  {
    label: 'Past 7 days',
    value: [
      DateTime.now().minus({ days: 7 }).toJSDate(),
      DateTime.now().toJSDate(),
    ],
  },
  {
    label: 'Past 30 days',
    value: [
      DateTime.now().minus({ days: 30 }).toJSDate(),
      DateTime.now().toJSDate(),
    ],
  },
  {
    label: 'Past 3 months',
    value: [
      DateTime.now().minus({ months: 3 }).toJSDate(),
      DateTime.now().toJSDate(),
    ],
  },
  {
    label: 'Past 6 months',
    value: [
      DateTime.now().minus({ months: 6 }).toJSDate(),
      DateTime.now().toJSDate(),
    ],
  },
  {
    label: 'Past 12 months',
    value: [
      DateTime.now().minus({ months: 12 }).toJSDate(),
      DateTime.now().toJSDate(),
    ],
  },
  {
    label: 'All time',
    value: [
      DateTime.fromISO('2010-01-01').toJSDate(),
      DateTime.now().toJSDate(),
    ],
  },
];
