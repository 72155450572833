import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { Button, ListItemIcon, Menu, MenuItem } from '@mui/material';
import { useActionMenuButtonStyles } from './ActionMenuButton.styles';
import { ActionButtonProperty } from '..';
import { Typography } from '@material-ui/core';

type ActionMenuButtonProps = {
  actions: ActionButtonProperty[];
  button: ActionButtonProperty;
  styles?: any;
};

export const ActionMenuButton = ({
  actions,
  button,
  styles,
}: ActionMenuButtonProps) => {
  const actionStyles = useActionMenuButtonStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderMenuItem = () => {
    return actions.map(action => {
      return (
        <MenuItem
          onClick={() => {
            action.handleActionClick(action.action);
            handleClose();
          }}
          key={action.action}
          style={{ width: document.getElementById('action-btn')?.clientWidth }}
        >
          {action.startIcon && (
            <ListItemIcon style={{ color: 'black' }}>
              {action.startIcon}
            </ListItemIcon>
          )}
          <Typography className={actionStyles.itemLabel}>
            {action.label}
          </Typography>
        </MenuItem>
      );
    });
  };

  return (
    <div>
      <Button
        variant="outlined"
        id="action-btn"
        style={styles}
        className={actionStyles.button}
        aria-controls={open ? 'action-btn' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        startIcon={button.startIcon ? button.startIcon : null}
        endIcon={button.endIcon ? button.endIcon : null}
      >
        {button.label}
      </Button>
      {actions.length > 0 && (
        <Menu
          id="action-btn"
          anchorEl={anchorEl}
          open={actions.length === 0 ? false : open}
          className={actionStyles.menu}
          onClose={handleClose}
          style={{
            width: anchorEl?.clientWidth
              ? anchorEl?.clientWidth + 31
              : 'inherit',
          }}
          aria-labelledby="action-btn"
        >
          {renderMenuItem()}
        </Menu>
      )}
    </div>
  );
};
