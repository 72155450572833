import { ResponseErrorPanel, Table } from '@backstage/core-components';
import { Banner } from '@lego/plugin-baseplate-admin-common';
import {
  BaseplateButton,
  Spinner,
} from '@lego/plugin-baseplate-core-components';
import {
  Card,
  Grid,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import Box from '@mui/material/Box';
import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';
import { useBanner } from '../../hooks';
import { columns } from './columns';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      padding: theme.spacing(3, 3, 2.5, 2.5),
    },
  }),
);

export const BannerTable = ({
  handleOpenEdit,
  handleOpenCreate,
}: {
  handleOpenEdit: (banner: Banner) => void;
  handleOpenCreate: () => void;
}) => {
  const { banners, handleDeletingBanner, loading, error } = useBanner();
  const classes = useStyles();

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <ResponseErrorPanel error={error} />;
  }

  const deleteBanner = async (id: string) => {
    // eslint-disable-next-line no-alert
    if (window.confirm('Are you sure you want to delete this banner?')) {
      await handleDeletingBanner(id);
    }
  };

  return (
    <Card>
      <Table
        title="Active Banners"
        columns={columns}
        data={banners ?? []}
        options={{
          actionsColumnIndex: -1,
        }}
        actions={[
          {
            icon: DeleteIcon,
            tooltip: 'Delete Banner',
            onClick: (_, banner) => void deleteBanner((banner as Banner).id),
          },
        ]}
        onRowClick={(_, banner) =>
          banner &&
          handleOpenEdit({
            created_by: banner.created_by,
            id: banner.id,
            text_body: banner.text_body,
            title: banner.title,
            severity: banner.severity,
            theme: banner.theme,
            link_text: banner.link_text,
            link_url: banner.link_url,
            pages: banner.pages,
          })
        }
        components={{
          Toolbar: () => (
            <Box className={classes.root}>
              <Grid
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <Typography variant="h6">Active Banners</Typography>
                <BaseplateButton
                  variant="contained"
                  color="primary"
                  onClick={() => handleOpenCreate()}
                >
                  Create
                </BaseplateButton>
              </Grid>
            </Box>
          ),
        }}
      />
    </Card>
  );
};
