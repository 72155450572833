import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Star(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M19.969 3.985c.625 0 1.125.375 1.375.875l4.312 8.813 9.563 1.437c.562.063 1 .438 1.187 1 .188.563.063 1.125-.375 1.563l-6.937 6.875 1.625 9.687c.125.563-.125 1.188-.563 1.5-.5.313-1.125.375-1.625.125l-8.562-4.625-8.5 4.625c-.563.25-1.125.188-1.625-.125-.438-.375-.688-.937-.563-1.5l1.625-9.687-6.937-6.875c-.438-.438-.563-1-.375-1.563.187-.5.625-.937 1.187-1l9.563-1.437 4.312-8.813c.25-.5.75-.875 1.313-.875Zm0 4.938-3.25 6.812c-.25.438-.625.75-1.125.813L8.219 17.61l5.312 5.313c.375.375.563.875.438 1.312l-1.25 7.5 6.562-3.5c.438-.25 1-.25 1.438 0l6.562 3.5-1.25-7.437c-.125-.5.063-1 .438-1.313l5.312-5.375-7.375-1.062c-.5-.063-.875-.375-1.125-.813L19.97 8.923Z" />
    </SvgIcon>
  );
}
