import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Code(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M24.938 4.094c.75.187 1.187 1.062.937 1.875l-9 29c-.188.75-1.063 1.187-1.875.937a1.493 1.493 0 0 1-.938-1.875l9-29a1.493 1.493 0 0 1 1.875-.937Zm4.437 7.375c.563-.625 1.5-.625 2.125-.063l8 7.5c.313.25.5.688.5 1.063 0 .437-.188.812-.5 1.125l-8 7.5c-.625.562-1.563.5-2.125-.063-.563-.625-.563-1.562.063-2.125l6.812-6.437-6.813-6.375c-.625-.563-.625-1.5-.062-2.125Zm-18.813 0c.563.625.563 1.562-.062 2.125l-6.813 6.375 6.813 6.437c.625.563.625 1.5.063 2.125-.563.625-1.5.625-2.126.063l-8-7.5C.126 20.78 0 20.406 0 19.969c0-.375.125-.813.438-1.063l8-7.5c.624-.562 1.562-.562 2.124.063Z" />
    </SvgIcon>
  );
}
