import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';
import {
  Button,
  Input,
  Dropdown,
} from '@lego/plugin-baseplate-core-components';
import {
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import React, { useEffect, useState } from 'react';

type ToolLinks = {
  url: string;
  title?: string | undefined;
  color?: string | undefined;
  variant?: string | undefined;
};

export const Links = ({
  onChange,
  rawErrors,
  required,
  formData,
  uiSchema,
}: FieldExtensionComponentProps<ToolLinks[]>) => {
  const isActions = Boolean(uiSchema['ui:options']?.isAction) ?? false;
  const [entries, setEntries] = useState<ToolLinks[] | undefined>(
    formData || [],
  );

  useEffect(() => {
    if (entries && entries.length > 0) {
      let newEntries = entries;
      if (isActions) {
        entries.forEach(entry => {
          if (!entry.variant) {
            entry.variant = 'contained';
          }
          if (!entry.color) {
            entry.color = 'primary';
          }
        });
        newEntries = entries;
      }
      onChange(newEntries);
    } else {
      onChange(undefined);
    }
  }, [entries, onChange, isActions]);

  const handleUpdate = (
    index: number,
    key: keyof ToolLinks,
    newValue: string,
  ) => {
    const newEntries = (entries ?? []).map((entry, i) =>
      i === index ? { ...entry, [key]: newValue } : entry,
    );
    setEntries(newEntries);
  };

  const handleAdd = () => {
    setEntries([...(entries ?? []), { title: '', url: '' }]);
  };

  const handleRemove = (index: number) => {
    const newEntries = (entries ?? []).filter((_, i) => i !== index);
    setEntries(newEntries);
  };

  const actionColour = [
    'inherit',
    'primary',
    'secondary',
    'success',
    'error',
    'info',
    'warning',
    'string',
  ];
  const actionVariants = ['contained', 'outlined', 'text', 'string'];

  return (
    <FormControl required={required} error={rawErrors?.length > 0 && !formData}>
      {entries &&
        !!entries.length &&
        entries.map((entry, index) => (
          <Grid container key={index} style={{ padding: 0 }}>
            <Grid item xs={3}>
              <Input
                label="Label"
                id={`validateLabel-${index}`}
                value={entry.title}
                onChange={e => handleUpdate(index, 'title', e.target.value)}
                helperText={isActions ? 'E.g Get Access' : 'E.g. Baseplate'}
                required={isActions}
              />
            </Grid>
            {isActions ? (
              <>
                <Grid item xs={2}>
                  <Dropdown
                    label="Variant"
                    id={`validateVariant-${index}`}
                    defaultValue="contained"
                    options={actionVariants}
                    value={entry.variant || 'contained'}
                    onChange={e =>
                      handleUpdate(index, 'variant', e.target.value as string)
                    }
                  />
                </Grid>
                <Grid item xs={2}>
                  <Dropdown
                    label="Color"
                    id={`validateColor-${index}`}
                    defaultValue="primary"
                    options={actionColour}
                    value={entry.color || 'primary'}
                    onChange={e =>
                      handleUpdate(index, 'color', e.target.value as string)
                    }
                  />
                </Grid>
              </>
            ) : null}
            {isActions ? (
              <Grid item xs={4}>
                <Input
                  label="URL"
                  id={`validateURL-${index}`}
                  value={entry.url}
                  required
                  onChange={e => handleUpdate(index, 'url', e.target.value)}
                  helperText="E.g https://baseplate.io"
                />
              </Grid>
            ) : (
              <Grid item xs={8}>
                <Input
                  label="URL"
                  id={`validateURL-${index}`}
                  value={entry.url}
                  required
                  onChange={e => handleUpdate(index, 'url', e.target.value)}
                  helperText="E.g https://baseplate.io"
                />
              </Grid>
            )}
            <Grid item xs={1} style={{ display: 'flex' }}>
              <IconButton onClick={() => handleRemove(index)}>
                <RemoveCircleOutlineIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}
      <Typography component="label" variant="body2">
        {isActions ? 'Actions' : 'Links'}
      </Typography>
      <Button
        onClick={handleAdd}
        startIcon={<AddCircleOutlineIcon />}
        fullWidth
        variant="secondary"
      >
        Add {isActions ? 'Actions' : 'Links'}
      </Button>
      <FormHelperText style={{ padding: '0 1rem' }}>
        <Typography variant="body2" color="secondary">
          You can add {isActions ? 'Actions' : 'Links'} to your tool. These will
          show on the tool's page.
        </Typography>
      </FormHelperText>
    </FormControl>
  );
};
