import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Bolt(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M26.094 4c1.187 0 2.062 1.188 1.75 2.313L24.469 18h5.375c1.187 0 2.125 1 2.125 2.188 0 .687-.25 1.25-.75 1.687L15.03 35.625c-.312.25-.687.375-1.125.375-1.187 0-2.062-1.125-1.75-2.25L15.531 22h-5.437a2.08 2.08 0 0 1-2.063-2.063c0-.625.25-1.187.688-1.625l16.25-13.875c.312-.25.687-.437 1.125-.437Zm-2.25 5.313L12.53 19h5c.438 0 .875.25 1.188.625.25.375.375.875.25 1.313l-2.813 9.812L27.594 21H22.53c-.5 0-.937-.188-1.25-.563-.25-.375-.375-.875-.25-1.312l2.813-9.813Z" />
    </SvgIcon>
  );
}
