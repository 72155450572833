import { BackstageOverrides } from '@backstage/core-components';
import { DesignTokens } from '../types';

export const navigation = (
  tokens: DesignTokens,
): Pick<
  BackstageOverrides,
  | 'BackstageHeader'
  | 'BackstageSidebarItem'
  | 'BackstageHeaderTabs'
  | 'MuiTab'
  | 'MuiTabs'
> => ({
  BackstageHeader: {
    header: {
      backgroundColor: tokens.semantic.background.neutral[1],
      boxShadow: 'none',
      backgroundImage: tokens.semantic.background.neutral[1],
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'right',
      backgroundSize: 'contain',
      paddingLeft: tokens.primitive.dimensions.page.horizontalPadding,
      paddingRight: tokens.primitive.dimensions.page.horizontalPadding,
    },
    title: {
      fontSize: '2.375rem',
      fontWeight: 500,
      lineHeight: '115%',
      letterSpacing: '-0.02em',
    },
    breadcrumb: {
      color: tokens.semantic.interactive.default,
    },
  },

  // Sidebar
  BackstageSidebarItem: {
    root: {
      textDecoration: 'none',
      fontSize: '1rem',
      '& h6': {
        fontSize: '1rem',
      },
    },
    selected: {
      backgroundColor: tokens.semantic.background.neutral[2],
    },
  },
  // Tabs
  BackstageHeaderTabs: {
    selected: {
      backgroundColor: tokens.semantic.background.neutral[2],
    },
  },
  // Tabs
  MuiTab: {
    wrapper: {
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '32px',
      fontStyle: 'normal',
      color: tokens.semantic.text.primary,
    },
  },
  MuiTabs: {
    indicator: {
      backgroundColor: tokens.semantic.border.default,
    },
  },
});
