import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function RotateLeft(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="m13.25 11.688 2.563 2.562c.437.438.687 1 .687 1.563 0 1.25-1 2.187-2.25 2.187H7c-.875 0-1.5-.625-1.5-1.5V9.25C5.5 8 6.438 7 7.688 7c.562 0 1.125.25 1.562.688l1.875 1.875C13.625 7.374 16.875 6 20.5 6c7.688 0 14 6.313 14 14 0 7.75-6.313 14-14 14-3.188 0-6.063-1-8.438-2.75-.624-.5-.812-1.438-.312-2.125.5-.688 1.438-.813 2.125-.313C15.688 30.188 18 31 20.5 31c6.063 0 11-4.875 11-11 0-6.063-4.938-11-11-11-2.75 0-5.313 1.063-7.25 2.688Zm-4.75-.563V15h3.875L8.5 11.125Z" />
    </SvgIcon>
  );
}
