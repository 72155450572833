import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function EllipsisVertical(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M20.023 26.992c1.063 0 2 .625 2.563 1.5.562.938.562 2.125 0 3-.563.938-1.5 1.5-2.563 1.5-1.125 0-2.062-.562-2.625-1.5-.562-.875-.562-2.062 0-3 .563-.875 1.5-1.5 2.625-1.5Zm0-10c1.063 0 2 .625 2.563 1.5.562.938.562 2.125 0 3-.563.938-1.5 1.5-2.563 1.5-1.125 0-2.062-.562-2.625-1.5-.562-.875-.562-2.062 0-3 .563-.875 1.5-1.5 2.625-1.5Zm3-7c0 1.125-.625 2.063-1.5 2.625-.937.563-2.125.563-3 0-.937-.562-1.5-1.5-1.5-2.625 0-1.062.563-2 1.5-2.562.875-.563 2.063-.563 3 0 .875.562 1.5 1.5 1.5 2.562Z" />
    </SvgIcon>
  );
}
