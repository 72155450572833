import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function LegoGift(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M9.635 19.757h.802l-.003.003a.18.18 0 0 0 .18-.185.183.183 0 0 0-.18-.186h-.578l.735-2.882a.157.157 0 0 0-.148-.198h-.041c-.07 0-.13.049-.148.117l-.792 3.1a.183.183 0 0 0 .173.23Zm2.035-1.591h.386v.007a.174.174 0 0 0 .173-.18c0-.1-.079-.178-.173-.178h-.294l.294-1.148h.647a.174.174 0 0 0 .173-.18c0-.1-.079-.178-.173-.178h-.821c-.057 0-.104.042-.12.097l-.801 3.133a.173.173 0 0 0 .164.218h.798a.18.18 0 0 0 .18-.186.183.183 0 0 0-.18-.185h-.565l.313-1.22Zm2.156 1.041.3-1.174.006-.007a.176.176 0 0 0-.167-.22h-.597a.174.174 0 0 0-.173.178c0 .1.079.179.173.179h.37l-.24.94a.364.364 0 0 1-.354.28.375.375 0 0 1-.353-.472l.498-1.958a.364.364 0 0 1 .354-.28c.24 0 .413.234.353.472a.167.167 0 0 0 .158.208h.016a.18.18 0 0 0 .17-.133v-.01c.117-.456-.22-.905-.678-.905a.737.737 0 0 0-.71.563l-.502 1.965c-.12.465.22.92.688.92a.716.716 0 0 0 .688-.546Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.845 19.754a.716.716 0 0 0 .688-.547l.505-1.98c.12-.466-.221-.922-.688-.922a.716.716 0 0 0-.688.547l-.505 1.981c-.12.465.221.92.688.92Zm.357-.648.498-1.958a.374.374 0 0 0-.353-.472.364.364 0 0 0-.354.28l-.498 1.958a.375.375 0 0 0 .353.472.364.364 0 0 0 .354-.28Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 11.136h13.383v13.796H6V11.137Zm2.597 6.897c0-2.381 1.871-4.31 4.181-4.31s4.181 1.929 4.181 4.31c0 2.38-1.871 4.31-4.18 4.31-2.31 0-4.182-1.93-4.182-4.31Z"
      />
      <path d="M9.635 34.825h.802l-.003.003a.18.18 0 0 0 .18-.185.183.183 0 0 0-.18-.186h-.578l.735-2.882a.157.157 0 0 0-.148-.198h-.041c-.07 0-.13.049-.148.117l-.792 3.1a.183.183 0 0 0 .173.23Zm2.035-1.591h.386v.007a.174.174 0 0 0 .173-.18c0-.1-.079-.178-.173-.178h-.294l.294-1.148h.647a.174.174 0 0 0 .173-.18c0-.1-.079-.178-.173-.178h-.821c-.057 0-.104.042-.12.098l-.801 3.132a.173.173 0 0 0 .164.218h.798a.18.18 0 0 0 .18-.186.183.183 0 0 0-.18-.185h-.565l.313-1.22Zm2.156 1.041.3-1.174.006-.007a.176.176 0 0 0-.167-.22h-.597a.174.174 0 0 0-.173.178c0 .101.079.179.173.179h.37l-.24.94a.364.364 0 0 1-.354.28.375.375 0 0 1-.353-.472l.498-1.958a.364.364 0 0 1 .354-.28c.24 0 .413.234.353.472a.167.167 0 0 0 .158.208h.016a.18.18 0 0 0 .17-.133v-.01c.117-.455-.22-.904-.678-.904a.737.737 0 0 0-.71.562l-.502 1.965c-.12.465.22.92.688.92a.716.716 0 0 0 .688-.546Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.845 34.822a.716.716 0 0 0 .688-.547l.505-1.98c.12-.466-.221-.921-.688-.921a.716.716 0 0 0-.688.546l-.505 1.981c-.12.465.221.92.688.92Zm.357-.648.498-1.958a.375.375 0 0 0-.353-.472.364.364 0 0 0-.354.28l-.498 1.959a.374.374 0 0 0 .353.471.364.364 0 0 0 .354-.28Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 26.205h13.383V40H6V26.205ZM8.597 33.1c0-2.381 1.871-4.31 4.181-4.31s4.181 1.929 4.181 4.31c0 2.381-1.871 4.31-4.18 4.31-2.31 0-4.182-1.929-4.182-4.31Z"
      />
      <path d="M25.054 19.757h-.801c-.117 0-.202-.114-.174-.231l.792-3.1a.156.156 0 0 1 .148-.117h.041c.101 0 .174.097.149.198l-.735 2.882h.577c.098 0 .18.082.18.186a.18.18 0 0 1-.18.185l.003-.003Zm1.619-1.591h-.385l-.313 1.22h.565c.098 0 .18.081.18.185a.18.18 0 0 1-.18.186h-.798a.173.173 0 0 1-.164-.218l.801-3.133c.016-.055.064-.097.12-.097h.82c.096 0 .174.078.174.179 0 .1-.075.179-.173.179h-.647l-.294 1.148h.294c.095 0 .174.078.174.179 0 .1-.076.179-.174.179v-.007Zm2.07-.133-.3 1.174a.716.716 0 0 1-.688.547c-.467 0-.808-.456-.688-.921l.502-1.965a.737.737 0 0 1 .71-.563c.458 0 .795.45.678.905v.01a.18.18 0 0 1-.17.133h-.016a.167.167 0 0 1-.157-.208.375.375 0 0 0-.354-.472.364.364 0 0 0-.353.28l-.499 1.958a.374.374 0 0 0 .354.472.364.364 0 0 0 .353-.28l.24-.94h-.37a.176.176 0 0 1-.173-.179c0-.1.076-.179.174-.179h.596c.114 0 .196.11.167.221l-.006.007Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.15 19.207a.716.716 0 0 1-.688.547c-.467 0-.807-.456-.688-.921l.505-1.981a.716.716 0 0 1 .688-.547c.467 0 .808.456.688.921l-.505 1.981Zm.168-2.059-.499 1.958a.364.364 0 0 1-.354.28.375.375 0 0 1-.353-.472l.499-1.958a.364.364 0 0 1 .353-.28c.24 0 .414.235.354.472Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34 11.136H20.617v13.796H34V11.137Zm-6.605 2.587c-2.31 0-4.18 1.929-4.18 4.31 0 2.38 1.87 4.31 4.18 4.31 2.31 0 4.182-1.93 4.182-4.31 0-2.381-1.872-4.31-4.182-4.31Z"
      />
      <path d="M24.253 34.825h.801l-.003.003a.18.18 0 0 0 .18-.185.183.183 0 0 0-.18-.186h-.577l.735-2.882a.157.157 0 0 0-.149-.198h-.04c-.07 0-.13.049-.149.117l-.792 3.1a.184.184 0 0 0 .174.23Zm2.035-1.591h.385v.007a.174.174 0 0 0 .174-.18c0-.1-.08-.178-.174-.178h-.294l.294-1.148h.647a.174.174 0 0 0 .173-.18c0-.1-.078-.178-.173-.178h-.82a.127.127 0 0 0-.12.098l-.802 3.132a.173.173 0 0 0 .164.218h.798a.18.18 0 0 0 .18-.186.183.183 0 0 0-.18-.185h-.564l.312-1.22Zm2.155 1.041.3-1.174.006-.007a.176.176 0 0 0-.167-.22h-.596a.174.174 0 0 0-.174.178c0 .101.079.179.174.179h.369l-.24.94a.364.364 0 0 1-.353.28.375.375 0 0 1-.354-.472l.499-1.958a.364.364 0 0 1 .353-.28c.24 0 .413.234.354.472a.167.167 0 0 0 .157.208h.016a.18.18 0 0 0 .17-.133v-.01c.117-.455-.22-.904-.678-.904a.737.737 0 0 0-.71.562l-.502 1.965c-.12.465.221.92.688.92a.716.716 0 0 0 .688-.546Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.462 34.822a.716.716 0 0 0 .688-.547l.505-1.98c.12-.466-.22-.921-.688-.921a.716.716 0 0 0-.688.546l-.505 1.981c-.12.465.221.92.688.92Zm.357-.648.499-1.958a.375.375 0 0 0-.354-.472.364.364 0 0 0-.353.28l-.499 1.959a.375.375 0 0 0 .354.471.364.364 0 0 0 .353-.28Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.617 26.205H34V40H20.617V26.205Zm2.597 6.896c0-2.381 1.872-4.31 4.181-4.31 2.31 0 4.182 1.929 4.182 4.31 0 2.381-1.872 4.31-4.182 4.31-2.31 0-4.18-1.929-4.18-4.31ZM26.68 2.227h-.617c-1.704 0-3.086 1.425-3.086 3.182v1.91h3.704c1.363 0 2.469-1.14 2.469-2.546s-1.106-2.546-2.47-2.546ZM26.064 0c-2.898 0-5.247 2.422-5.247 5.41v4.136h5.865c2.557 0 4.63-2.137 4.63-4.773C31.31 2.137 29.238 0 26.68 0h-.618ZM13.408 2.227h.617c1.705 0 3.087 1.425 3.087 3.182v1.91h-3.704c-1.364 0-2.47-1.14-2.47-2.546s1.106-2.546 2.47-2.546ZM14.025 0c2.898 0 5.248 2.422 5.248 5.41v4.136h-5.865c-2.557 0-4.63-2.137-4.63-4.773C8.778 2.137 10.851 0 13.408 0h.617Z"
      />
    </SvgIcon>
  );
}
