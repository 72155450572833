import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Link(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M36.281 20.719 29.22 27.78c-3.563 3.5-9.25 3.5-12.75 0-3.313-3.375-3.5-8.687-.438-12.25l.313-.375a1.493 1.493 0 0 1 2.125-.125c.625.563.687 1.5.125 2.125l-.25.313c-2.125 2.375-2 5.937.25 8.187 2.312 2.313 6.125 2.313 8.5 0l7.062-7.062a6.074 6.074 0 0 0 0-8.5c-2.25-2.188-5.812-2.313-8.187-.25l-.375.312c-.625.563-1.563.5-2.125-.125a1.493 1.493 0 0 1 .125-2.125l.375-.312c3.562-3.125 8.937-2.938 12.312.375 3.5 3.5 3.5 9.187 0 12.75ZM3.72 19.28l7.125-7.062c3.5-3.5 9.187-3.5 12.687 0 3.375 3.312 3.563 8.687.438 12.25l-.375.375c-.5.625-1.5.75-2.125.187a1.493 1.493 0 0 1-.125-2.125l.375-.375c2.062-2.375 1.937-5.937-.313-8.187a5.886 5.886 0 0 0-8.437 0l-7.125 7.062c-2.313 2.313-2.313 6.125 0 8.5 2.25 2.188 5.812 2.313 8.187.25l.375-.312a1.493 1.493 0 0 1 2.125.125c.563.625.5 1.562-.125 2.125l-.375.312C12.47 35.53 7.095 35.343 3.72 32.03c-3.5-3.5-3.5-9.25 0-12.75Z" />
    </SvgIcon>
  );
}
