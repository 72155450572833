import { BackstageTheme } from '@backstage/theme';
import { Grid, Link, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import HomeIcon from '@material-ui/icons/Home';
import React from 'react';
import { NavLink } from 'react-router-dom';
import httpNotFoundImage from '../assets/404-image.svg';

const useStyles = makeStyles<BackstageTheme>(
  theme => ({
    container: {
      height: '100vh',
      background: 'linear-gradient(180deg, #5B3A8C 0%, #734CAD 100%)',
    },
    item: {
      color: theme.palette.common.white,
      maxWidth: '50%',
      [theme.breakpoints.down('md')]: {
        maxWidth: '100%',
        width: '100%',
      },
    },
    textContainer: {
      maxWidth: '350px',
      margin: theme.spacing(0, 2.5),
      color: theme.palette.common.white,
    },
    title: {
      margin: theme.spacing(2.5, 0),
    },
    home: {
      color: theme.palette.common.white,
      display: 'flex',
    },
  }),
  { name: 'NotFoundErrorPage' },
);

const TextGridItem = ({ ...breakpoints }) => {
  const classes = useStyles();
  return (
    <Grid item className={classes.textContainer} {...breakpoints}>
      <Typography className={classes.title} variant="h1">
        404
      </Typography>
      <Typography className={classes.title} variant="h5">
        Billund, we have a problem.
      </Typography>
      <Typography className={classes.title}>
        That link may be broken or the page may have been moved.
      </Typography>
      <div className={`${classes.home} ${classes.title}`}>
        <Link
          className={classes.home}
          component={NavLink}
          to="/"
          underline="none"
        >
          <HomeIcon />
          <Typography>Bring me home</Typography>
        </Link>
      </div>
    </Grid>
  );
};

export default function NotFoundErrorPage() {
  const classes = useStyles();
  const theme = useTheme();
  const isUpMdScreen = useMediaQuery<BackstageTheme>(
    theme.breakpoints.up('md'),
    { noSsr: true },
  );

  return (
    <Grid
      className={classes.container}
      container
      spacing={0} // overwrite because the CSS calculated width is per default spacing{2}==='100% + 16px'
      direction={isUpMdScreen ? 'row' : 'column-reverse'} // Note: xs={12} ommited in items below due to https://mui.com/material-ui/react-grid/#direction-column-column-reverse
      justifyContent={isUpMdScreen ? 'flex-start' : 'flex-end'}
      alignItems="center"
    >
      {isUpMdScreen ? (
        <Grid className={classes.item} item md={6}>
          <img
            className={classes.httpNotFoundImage}
            src={httpNotFoundImage}
            alt="404"
          />
        </Grid>
      ) : (
        <Grid className={classes.item} item>
          <img
            className={classes.httpNotFoundImage}
            src={httpNotFoundImage}
            alt="404"
          />
        </Grid>
      )}
      <TextGridItem />
    </Grid>
  );
}
