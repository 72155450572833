/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  Content,
  Header,
  Link,
  Page,
  Progress,
  RoutedTabs,
  WarningPanel,
} from '@backstage/core-components';
import { useRouteRefParams } from '@backstage/core-plugin-api';
import {
  entityRouteRef,
  InspectEntityDialog,
  useAsyncEntity,
} from '@backstage/plugin-catalog-react';
import Box from '@material-ui/core/Box';
import Alert from '@mui/material/Alert';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  EntityContextMenu,
  ExtraContextMenuItem,
} from '../entity-context-menu';
import { useEntityLayoutStyles } from './EntityLayout.styles';
import { EntityLayoutTitle, EntityPageLabels } from './EntityLayoutTitle';
import { Route, useRoutes } from './routes';

export type BreadcrumbLink = { href?: string; label: string };

/** @public */
export interface EntityLayoutProps {
  extraContextMenuItems?: ExtraContextMenuItem[];
  breadcrumbLinks?: BreadcrumbLink[];
  actions?: React.ReactNode[];
  children?: React.ReactNode;
  NotFoundComponent?: React.ReactNode;
  labels?: EntityPageLabels;
}

/**
 * EntityLayout is a compound component, which allows you to define a layout for
 * entities using a sub-navigation mechanism.
 *
 * Consists of two parts: EntityLayout and EntityLayout.Route
 *
 * @example
 * ```jsx
 * <EntityLayout>
 *   <EntityLayout.Route path="/example" title="Example tab">
 *     <div>This is rendered under /example/anything-here route</div>
 *   </EntityLayout.Route>
 * </EntityLayout>
 * ```
 *
 * @public
 */
export const EntityLayout = ({
  actions,
  extraContextMenuItems,
  children,
  NotFoundComponent,
  labels,
  ...props
}: EntityLayoutProps) => {
  const { kind } = useRouteRefParams(entityRouteRef);
  const { entity, loading, error } = useAsyncEntity();
  const location = useLocation();
  const routes = useRoutes(entity, children);
  const classes = useEntityLayoutStyles();

  // Setting a default for breadcrumbs
  const breadcrumbLinks =
    props.breadcrumbLinks ||
    (entity && [
      {
        label: entity?.kind,
      },
    ]);

  const [inspectionDialogOpen, setInspectionDialogOpen] = useState(false);

  // Make sure to close the dialog if the user clicks links in it that navigate
  // to another entity.
  useEffect(() => {
    setInspectionDialogOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <Page themeId={entity?.spec?.type?.toString() ?? 'home'}>
      <Header
        pageTitleOverride={entity?.metadata.title ?? entity?.metadata.name}
        title={
          <EntityLayoutTitle
            entity={entity}
            breadcrumbLinks={breadcrumbLinks}
            actions={actions}
            labels={labels}
          />
        }
      >
        <>
          <Box display="flex" gridGap="1rem" alignItems="center">
            {actions}
            <EntityContextMenu
              extraContextMenuItems={extraContextMenuItems}
              onInspectEntity={() => setInspectionDialogOpen(true)}
              entity={entity}
            />
          </Box>
        </>
      </Header>

      {loading && <Progress />}

      {entity && (
        <div className={classes.tabsContainer}>
          <RoutedTabs routes={routes} />
        </div>
      )}

      {error && (
        <Content>
          <Alert severity="error">{error.toString()}</Alert>
        </Content>
      )}

      {!loading && !error && !entity && (
        <Content>
          {NotFoundComponent ? (
            NotFoundComponent
          ) : (
            <WarningPanel title="Entity not found">
              There is no {kind} with the requested{' '}
              <Link to="https://backstage.io/docs/features/software-catalog/references">
                kind, namespace, and name
              </Link>
              .
            </WarningPanel>
          )}
        </Content>
      )}

      <InspectEntityDialog
        open={inspectionDialogOpen}
        entity={entity!}
        onClose={() => setInspectionDialogOpen(false)}
      />
    </Page>
  );
};

EntityLayout.Route = Route;
