export const pavedPathTools = [
  'jira',
  'confluence',
  'microsoft-teams',
  'figma',
  'miro',
  'github',
  'github-copilot',
  'lego-chatai',
  'aws',
  'azure',
  'github-actions',
  'snyk',
  'apptio',
  'elastic-stack',
  'amazon-web-services-aws',
  'microsoft-azure',
];
