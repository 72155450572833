/* eslint-disable react-hooks/exhaustive-deps */
import { useApi } from '@backstage/core-plugin-api';
import {
  Skill,
  regexPatterns,
} from '@lego/plugin-baseplate-learning-and-development-common';
import TextField from '@material-ui/core/TextField';
import { Autocomplete } from '@material-ui/lab';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { mentorMatchingApiRef } from '../../../api';
import { sanitizeSkill } from '../../MentorMatching/UpdateProfile/util/skillNameSanitation';
import { useStyles } from './AutocompleteSkill.styles';

type SkillSearchProps = {
  handleSkillSelection: (skill: Skill) => void;
  skillList: Skill[];
  customStyles?: any;
  disabled?: boolean;
};

const skillError =
  "Please use only letters, numbers, space and the symbols '& _ . #' without consecutive spaces or symbols.";

/**
 * Checks the validation of the input value for skill
 * @param {string} text
 * @return {*}  {boolean}
 */
const isValidSkill = (text: string): boolean => {
  const regex = regexPatterns.skill;
  return regex.test(text);
};

export const AutocompleteSkill = ({
  handleSkillSelection,
  skillList,
  customStyles,
  disabled = false,
}: SkillSearchProps) => {
  const styles = useStyles();
  const timer = useRef<any>(undefined);
  const mentorMatchingApi = useApi(mentorMatchingApiRef);
  const [_inputValue, setInputValue] = useState('');
  const [selectedValue, setSelectedValue] = useState(null);
  const [skillOptions, setSkillOptions] = useState<Skill[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState(false);

  const fetchSkillsDebounced = useCallback(
    async (searchTerm: any) => {
      if (!searchTerm) {
        setSkillOptions([]);
        setLoading(false);
        return;
      }
      setLoading(true);
      try {
        const response = await mentorMatchingApi.getSkills(
          sanitizeSkill(searchTerm),
        );
        const dif = response.filter(
          (sk: any) =>
            !skillList.some(
              (rsk: any) => rsk.skill.display_name === sk.display_name,
            ),
        );

        setSkillOptions(dif);
      } catch (error) {
        setHasError(true);
      } finally {
        setLoading(false);
      }
    },
    [mentorMatchingApi],
  );

  useEffect(() => {
    if (_inputValue) {
      timer.current = setTimeout(() => {
        void fetchSkillsDebounced(_inputValue);
      }, 1000);
    } else {
      setSkillOptions([]);
      setLoading(false);
    }

    return () => {
      clearTimeout(timer.current);
    };
  }, [_inputValue]);

  const handleInputChange = (newinputValue: string) => {
    if (newinputValue === '') {
      setInputValue('');
      setHasError(false);
      return;
    }
    const isValid = isValidSkill(newinputValue);
    setHasError(!isValid);
    if (isValid) {
      setInputValue(newinputValue);
    }
  };

  const filterOptions = (_options: any[], params: any) => {
    const filteredOptions = _options.filter(option => {
      if (params.display_name === '') {
        return true;
      }

      return option.display_name
        .toLowerCase()
        .includes(params.inputValue.toLowerCase());
    });

    if (filteredOptions.length === 0 && isValidSkill(params.inputValue)) {
      filteredOptions.push({
        id: 0,
        display_name: `Add "${sanitizeSkill(params.inputValue)}"`,
      });
    }

    return filteredOptions;
  };

  const onHandleChange = (newValue: any) => {
    if (typeof newValue === 'string') {
      if (!isValidSkill(newValue)) {
        return;
      }
    } else if (newValue && newValue.display_name) {
      const sanitizedName = sanitizeSkill(newValue.display_name);
      const selectedVal = {
        ...newValue,
        display_name: sanitizedName.replace('Add ', ''),
        isActive: true,
      };
      handleSkillSelection(selectedVal);
    }
    setSkillOptions([]);
    setSelectedValue(null);
  };
  return (
    <Autocomplete
      fullWidth
      clearOnBlur
      blurOnSelect
      options={skillOptions}
      value={selectedValue}
      disabled={disabled}
      openOnFocus
      onChange={(_e, newValue) => onHandleChange(newValue)}
      filterOptions={filterOptions}
      getOptionLabel={option => {
        if (option.id) {
          return option;
        }
        if (option.display_name) {
          return option.display_name;
        }
        // Regular option
        return option.display_name;
      }}
      renderOption={option => option.display_name}
      freeSolo
      renderInput={params => (
        <TextField
          {...params}
          disabled={disabled}
          error={hasError}
          className={`${customStyles} ${styles.autoCompleteInput}`}
          placeholder="Select skills"
          variant="outlined"
          id="lndSkillsInput"
          helperText={hasError && skillError}
          InputProps={{
            ...params.InputProps,
            endAdornment: <>{params.InputProps.endAdornment}</>,
          }}
        />
      )}
      onInputChange={(_e, v) => handleInputChange(v)}
      PopperComponent={({ children, anchorEl, ...rest }) => (
        <div {...rest}>
          <>
            {loading ? (
              <div className={styles.loader}>
                <CircularProgress color="primary" size={20} />
                <span style={{ marginLeft: '10px' }}>
                  Fetching available skills...
                </span>
              </div>
            ) : (
              children
            )}
          </>
        </div>
      )}
    />
  );
};
