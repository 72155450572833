import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function FileCertificate(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M32.023 32.988c.5 0 1-.438 1-1v-18h-5c-1.125 0-2-.875-2-2v-5h-10c-.562 0-1 .5-1 1v4.25c-.812-.313-1.75-.313-2.625 0-.125.062-.312.187-.375.187v.063-4.5c0-2.188 1.75-4 4-4h10.313c1.062 0 2.062.437 2.812 1.187l5.688 5.688c.75.75 1.187 1.75 1.187 2.812v18.313c0 2.25-1.812 4-4 4H17.836c.125-.313.187-.625.187-1v-2h14ZM10.836 14.113l.937.375a.534.534 0 0 0 .438 0l1-.375c.625-.25 1.312-.063 1.75.5l.625.812a.344.344 0 0 0 .312.188l1.063.125a1.593 1.593 0 0 1 1.312 1.312l.125 1.063c0 .125.063.25.188.312l.875.625c.5.438.687 1.188.437 1.813l-.375.937c-.062.125-.062.313 0 .375l.375 1c.25.625.063 1.375-.5 1.75l-.812.688a.344.344 0 0 0-.188.312l-.125 1.063a1.593 1.593 0 0 1-1.312 1.312l-.938.125v6.563c0 .375-.25.75-.562.937a1.131 1.131 0 0 1-1-.062l-2.438-1.625-2.5 1.625c-.25.187-.687.187-1 .062-.312-.187-.5-.562-.5-.937v-6.563l-1-.125a1.593 1.593 0 0 1-1.312-1.312l-.125-1.063a.344.344 0 0 0-.188-.312l-.812-.688c-.563-.375-.75-1.125-.5-1.75l.437-.937V21.8l-.437-.937c-.25-.625-.063-1.375.5-1.75l.812-.688a.344.344 0 0 0 .188-.312l.187-1.063c.063-.687.563-1.187 1.25-1.312l1.063-.125c.125 0 .25-.063.312-.188l.688-.812c.375-.563 1.125-.75 1.75-.5Zm-2.813 7.875c0 1.437.75 2.75 2 3.5 1.188.687 2.75.687 4 0 1.188-.75 2-2.063 2-3.5 0-1.375-.812-2.688-2-3.438-1.25-.687-2.812-.687-4 0-1.25.75-2 2.063-2 3.438Z" />
    </SvgIcon>
  );
}
