import { useApi } from '@backstage/core-plugin-api';
import { AdminApiRef } from '../api';
import useAsync from 'react-use/lib/useAsync';

export const useCurrentBaseplateVersion = () => {
  const api = useApi(AdminApiRef);
  return useAsync(async () => {
    const { currentVersion } = await api.getCurrentBaseplateVersion();
    return currentVersion;
  });
};
