import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function FlagCode(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M20.349 8.753c.679.2 1.095.89.89 1.59v.002l-3.997 14.011c-.2.68-.89 1.098-1.59.891-.68-.2-1.096-.89-.89-1.59v-.002l3.996-14.011c.2-.68.89-1.098 1.59-.891Zm2.231 3.828a1.262 1.262 0 0 1 1.835.005l3.49 3.495a1.266 1.266 0 0 1 0 1.839l-3.49 3.495a1.262 1.262 0 0 1-1.834.004 1.266 1.266 0 0 1 .003-1.835l2.566-2.598-2.565-2.57a1.266 1.266 0 0 1-.004-1.835Zm-9.16 0a1.266 1.266 0 0 1-.005 1.836l-2.564 2.569 2.565 2.598c.526.492.527 1.341.003 1.835a1.262 1.262 0 0 1-1.833-.004l-3.49-3.495a1.266 1.266 0 0 1 0-1.84l3.49-3.494a1.262 1.262 0 0 1 1.833-.005Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.048 3.005v-.5c0-.812-.688-1.5-1.5-1.5-.875 0-1.5.688-1.5 1.5v34.843c0 .875.625 1.5 1.5 1.5.812 0 1.5-.625 1.5-1.5v-6.5h33.22c.813 0 1.5-.625 1.5-1.5a1.98 1.98 0 0 0-.183-.865L34.653 17l3.865-11.518c.02-.056.04-.11.06-.16.105-.277.19-.501.19-.817 0-.812-.688-1.5-1.5-1.5H4.048Zm0 24.843V6.005H34.97l-3.35 9.245c-.4 1.085-.474 2.43 0 3.563l3.126 9.035h-30.7Z"
      />
    </SvgIcon>
  );
}
