import { CatalogApi } from '@backstage/plugin-catalog-react';
import { ProductEntityV1alpha1 } from '@lego/plugin-baseplate-common';

export const fetchUserData = async (
  userEntityRef: string,
  catalogApi: CatalogApi,
  abortSignal?: AbortSignal,
) => {
  if (abortSignal?.aborted) {
    throw new Error('Fetch aborted');
  }

  const user = await catalogApi.getEntityByRef(`user:default/${userEntityRef}`);

  const allProducts = user?.spec?.products as { entityRef: string }[];
  let productForDomainAndSubdomain;
  if (allProducts?.[0]?.entityRef) {
    productForDomainAndSubdomain = (await catalogApi.getEntityByRef(
      allProducts?.[0]?.entityRef ?? '',
    )) as ProductEntityV1alpha1;
  }
  const products = allProducts?.map((product: any) => product?.title as string);
  const position = user?.spec?.position as string;
  const domain = productForDomainAndSubdomain?.spec?.domain?.name ?? '';
  const subdomain = productForDomainAndSubdomain?.spec?.domain?.subDomain.name;

  return { products, position, subdomain, domain };
};
