import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function LightbulbOn(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="m4.313 4.25 3 2c.687.5.875 1.438.437 2.125-.5.688-1.438.875-2.125.375l-3-2a1.435 1.435 0 0 1-.375-2.063C2.688 4 3.625 3.813 4.313 4.25Zm33 2.5-3 2c-.688.5-1.626.313-2.063-.375-.5-.688-.313-1.625.375-2.125l3-2c.688-.438 1.625-.25 2.125.438.438.687.25 1.625-.438 2.062ZM1.5 14h4c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5h-4C.625 17 0 16.375 0 15.5c0-.813.625-1.5 1.5-1.5Zm33 0h4c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5h-4c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5ZM7.312 24.75l-3 2c-.687.5-1.625.313-2.062-.375-.5-.688-.313-1.625.375-2.125l3-2c.688-.438 1.625-.25 2.125.438.438.687.25 1.625-.438 2.062Zm27-2.438 3 2c.688.438.876 1.375.438 2.063-.5.688-1.438.875-2.125.438l-3-2c-.688-.5-.875-1.438-.375-2.125a1.435 1.435 0 0 1 2.063-.375ZM28 15c0-4.375-3.625-8-8-8-4.438 0-8 3.625-8 8 0 1.75.5 3.313 1.375 4.563.25.375.5.75.813 1.125.812 1.125 1.75 2.437 2.5 3.75.625 1.187 1 2.437 1.125 3.562h-3c-.188-.75-.376-1.438-.75-2.125-.626-1.125-1.376-2.188-2.188-3.25l-.938-1.313C9.688 19.5 9 17.375 9 15 9 8.937 13.875 4 20 4c6.063 0 11 4.938 11 11 0 2.375-.75 4.5-2 6.313l-.938 1.312c-.75 1.063-1.562 2.125-2.187 3.25-.375.688-.563 1.375-.688 2.125h-3.062c.188-1.125.5-2.375 1.125-3.563.75-1.312 1.688-2.625 2.5-3.75.313-.375.563-.75.813-1.125A7.922 7.922 0 0 0 28 15Zm-11 0c0 .563-.5 1-1 1-.563 0-1-.438-1-1 0-2.75 2.188-5 5-5 .5 0 1 .5 1 1 0 .563-.5 1-1 1a3 3 0 0 0-3 3Zm8 16c0 2.813-2.25 5-5 5-2.813 0-5-2.188-5-5v-1h10v1Z" />
    </SvgIcon>
  );
}
