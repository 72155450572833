import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function QrcodeRead(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M5 7.5c0-.25.188-.5.5-.5h5c.813 0 1.5-.625 1.5-1.5 0-.813-.688-1.5-1.5-1.5h-5C3.562 4 2 5.625 2 7.5v5c0 .875.625 1.5 1.5 1.5.813 0 1.5-.625 1.5-1.5v-5ZM34.5 7c.25 0 .5.25.5.5v5c0 .875.625 1.5 1.5 1.5.813 0 1.5-.625 1.5-1.5v-5C38 5.625 36.375 4 34.5 4h-5c-.875 0-1.5.688-1.5 1.5 0 .875.625 1.5 1.5 1.5h5Zm-24 26h-5c-.313 0-.5-.188-.5-.5v-5c0-.813-.688-1.5-1.5-1.5-.875 0-1.5.688-1.5 1.5v5C2 34.438 3.563 36 5.5 36h5c.813 0 1.5-.625 1.5-1.5 0-.813-.688-1.5-1.5-1.5ZM35 27.5v5c0 .313-.25.5-.5.5h-5c-.875 0-1.5.688-1.5 1.5 0 .875.625 1.5 1.5 1.5h5c1.875 0 3.5-1.563 3.5-3.5v-5c0-.813-.688-1.5-1.5-1.5-.875 0-1.5.688-1.5 1.5ZM12.536 13.321c0-.392.344-.785.785-.785h.786c.393 0 .786.393.786.785v.786c0 .442-.393.786-.786.786h-.786a.776.776 0 0 1-.785-.786v-.786Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.357 9h4.714c1.277 0 2.358 1.08 2.358 2.357v4.714a2.358 2.358 0 0 1-2.358 2.358h-4.714A2.327 2.327 0 0 1 9 16.07v-4.714A2.358 2.358 0 0 1 11.357 9Zm4.714 2.357h-4.714v4.714h4.714v-4.714Z"
      />
      <path d="M13.321 25.107h.786c.393 0 .786.393.786.786v.786c0 .442-.393.785-.786.785h-.786a.776.776 0 0 1-.785-.785v-.786c0-.393.344-.786.785-.786Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.357 21.571h4.714c1.277 0 2.358 1.08 2.358 2.358v4.714A2.358 2.358 0 0 1 16.07 31h-4.714A2.327 2.327 0 0 1 9 28.643v-4.714a2.358 2.358 0 0 1 2.357-2.358Zm4.714 2.358h-4.714v4.714h4.714v-4.714Z"
      />
      <path d="M25.107 13.321c0-.392.344-.785.786-.785h.786c.392 0 .785.393.785.785v.786c0 .442-.393.786-.785.786h-.786a.776.776 0 0 1-.786-.786v-.786Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.571 11.357A2.358 2.358 0 0 1 23.93 9h4.714C29.92 9 31 10.08 31 11.357v4.714a2.358 2.358 0 0 1-2.357 2.358h-4.714a2.327 2.327 0 0 1-2.358-2.358v-4.714Zm2.358 0v4.714h4.714v-4.714h-4.714Z"
      />
      <path d="M21.571 22.357c0-.393.344-.785.786-.785H25.5c.393 0 .786.392.786.785 0 .442.343.786.785.786h1.572c.393 0 .785-.344.785-.786 0-.393.344-.785.786-.785.393 0 .786.392.786.785v4.715c0 .442-.393.785-.786.785h-3.143a.776.776 0 0 1-.785-.785c0-.393-.393-.786-.786-.786-.442 0-.786.393-.786.785v3.143c0 .442-.393.786-.786.786h-1.571a.776.776 0 0 1-.786-.786v-7.857Z" />
      <path d="M27.071 31a.776.776 0 0 1-.785-.786c0-.392.343-.785.785-.785.393 0 .786.393.786.785 0 .442-.393.786-.786.786Zm3.143 0a.776.776 0 0 1-.786-.786c0-.392.344-.785.786-.785.393 0 .786.393.786.785 0 .442-.393.786-.786.786Z" />
    </SvgIcon>
  );
}
