import { BannerCreateParams } from '@lego/plugin-baseplate-admin-common';
import React, { useState } from 'react';
import { useBanner } from '../../hooks';
import { BannerForm } from './BannerForm';

interface Props {
  setIsOpen: (isOpen: boolean) => void;
}

export function CreateBanner({ setIsOpen }: Props) {
  const { handleCreateBanner } = useBanner();

  const [formData, setFormData] = useState<BannerCreateParams>({
    title: '',
    text_body: '',
    severity: 'info',
  });

  const onSubmit = async () => {
    await handleCreateBanner(formData);
    setIsOpen(false);
  };

  return (
    <BannerForm
      setFormData={setFormData}
      formData={formData}
      onSubmit={onSubmit}
      type="Create"
    />
  );
}
