import * as React from 'react';
import Slider from '@mui/material/Slider';
import { Grid, Typography } from '@material-ui/core';

interface SliderProps {
  value: number;
  onChange: (value: number) => void;
}

export const TemperatureSlider: React.FC<SliderProps> = ({
  value,
  onChange,
}) => {
  const handleChange = (_event: Event, newValue: number | number[]) => {
    // Ensure newValue is a number (in case of an array for range sliders)
    const selectedValue = typeof newValue === 'number' ? newValue : 0;
    const clampedValue = Math.min(2, Math.max(0, selectedValue));
    onChange(clampedValue);
  };

  return (
    <Grid style={{ display: 'flex', alignItems: 'center' }}>
      <Typography style={{ marginRight: 15 }}>0</Typography>
      <Slider
        value={value}
        valueLabelDisplay="auto"
        marks
        defaultValue={0.2}
        onChange={handleChange}
        step={0.1}
        min={0}
        max={2}
      />
      <Typography style={{ marginLeft: 15 }}>2</Typography>
    </Grid>
  );
};
