import * as Yup from 'yup';

function isValidObjectName(value: unknown): boolean {
  return (
    typeof value === 'string' &&
    value.length >= 3 &&
    value.length <= 62 &&
    /^([A-Za-z0-9][-A-Za-z0-9_. ]*)?[A-Za-z0-9]$/.test(value)
  );
}

const tagRegex = /^[a-z0-9:+#]+(?:-[a-z0-9:+#]+)*$/;
// we don't need to prevent the spaces here, it will be handled in the CommunitiesProvider.ts
export const CreateCommunitySchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Too Short!')
    .max(62, 'Too Long! Maximum length is 62 characters')
    .required('This is Required')
    .test(
      'is-valid-name',
      'Must start and end with an alphanumeric character, and contain only spaces, alphanumeric characters, hyphens (-), underscores (_), and periods (.)',
      value => isValidObjectName(value ?? ''),
    ),
  description: Yup.string().required('This is Required'),
  links: Yup.array().of(
    Yup.object().shape({
      url: Yup.string().required('This is Required').url('Invalid URL'),
      label: Yup.string(),
    }),
  ),
  githubRepoLink: Yup.string()
    .url('Invalid URL')
    .test(
      'is-github-repo-link',
      'URL must start with https://github.com/LEGO/',
      value => (value ? value.startsWith('https://github.com/LEGO/') : true),
    )
    .test('does-not-end-with-slash', "Link cannot end with '/'", value =>
      value ? !value.endsWith('/') : true,
    ),

  teamsChannelLink: Yup.string().url('Invalid URL'),
  maintainerEntityRefs: Yup.array()
    .of(Yup.string().required('This is Required'))
    .min(1, 'At least one maintainer is required'),
  tags: Yup.string().test(
    'is-valid-tags',
    'Tags can only contain lowercase letters, numbers, and the characters +, -, :, and #. Tags cannot start or end with a dash (-).',
    value => {
      if (!value) return true;
      const tags = value.split(';');
      return tags.every(tag => tagRegex.test(tag));
    },
  ),
});
