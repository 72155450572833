import { CommunityV2 } from '@lego/plugin-baseplate-communities-common';
import { v4 as uuidv4 } from 'uuid';

export const initiateCommunityFormValues = (): CommunityV2 => {
  return {
    id: uuidv4(),
    name: '',
    links: [],
    description: '',
    aboutSource: 'form',
    about: '',
    maintainerEntityRefs: [],
  };
};
