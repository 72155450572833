import React, { useEffect, useState } from 'react';
import {
  Button,
  Icon,
  baseplateStyles,
  semantic,
} from '@lego/plugin-baseplate-core-components';
import { type ReactElement } from 'react';
import { DevModePanelFieldType } from './types';
import { IconButton, Typography } from '@material-ui/core';
import { DevModePanelField } from './DevModePanelField';
import { useEntityStatus } from './utils/useEntityStatus';
import { storageApiRef, useApi } from '@backstage/core-plugin-api';

const useStyles = baseplateStyles(theme => ({
  devModeButtonContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    position: 'fixed',
    bottom: '20%',
    right: 0,
    zIndex: 999,
    transform: 'translate(100%) rotate(-90deg)',
    transformOrigin: 'bottom left',
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    color: semantic.dark.text.primary,
    backgroundColor: semantic.dark.background.neutral[4],
    cursor: 'pointer',
    border: 0,
    fontSize: '1rem',
    transform: 'translateY(0.5rem) translateX(calc(-100% - 0.5rem))',
    padding: '0.5rem 1rem 1rem',
    borderRadius: `${theme.primitive.borderRadius.default}px ${theme.primitive.borderRadius.default}px 0 0`,
    boxShadow: theme.shadows[4],
    transition: 'background-color 200ms ease-in-out, transform 200ms ease-out',
    '&:hover': {
      backgroundColor: semantic.dark.background.neutral[3],
      transform: 'translateY(0) translateX(calc(-100% - 0.5rem))',
    },
    '&:active, &:focus-visible': {
      transform: 'translateY(0.25rem) translateX(calc(-100% - 0.5rem))',
    },
  },
  panel: {
    position: 'fixed',
    color: semantic.dark.text.primary,
    backgroundColor: semantic.dark.background.neutral[4],
    borderRadius: `${theme.primitive.borderRadius.sm}px 0 0 ${theme.primitive.borderRadius.sm}px`,
    bottom: '2rem',
    right: 0,
    zIndex: 1001,
    boxShadow: theme.shadows[1],
    minWidth: 300,
  },
  panelTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.semantic.border.default}`,
    padding: '0.5rem 1.5rem',
    '& svg': {
      color: semantic.dark.icon.primary,
    },
    '& button': {
      padding: 0,
    },
  },
  panelContent: {
    padding: '1.5rem',
  },
  panelActionContainer: {
    marginTop: '1rem',
  },
}));

const STORAGE_KEY = 'devModePanelOpen';

export function DevModePanel(): ReactElement {
  const classes = useStyles();
  const storageApi = useApi(storageApiRef).forBucket('DevModePanel');
  const [panelOpen, setPanelOpen] = useState(false);
  useEffect(() => {
    // Retrieve the panelOpen state from storage
    const storedPanelOpen = storageApi.snapshot<boolean>(STORAGE_KEY);
    if (storedPanelOpen.value !== undefined) {
      setPanelOpen(storedPanelOpen.value);
    }
  }, [storageApi]);

  useEffect(() => {
    async function updateStorage() {
      await storageApi.set(STORAGE_KEY, panelOpen);
    }

    void updateStorage();
  }, [panelOpen, storageApi]); // Make sure to specify dependencies
  const { entitiesFields, isTimerActive, toggleTimer } = useEntityStatus();

  const fields: DevModePanelFieldType[] = [...entitiesFields];

  return (
    <>
      {panelOpen && (
        <div className={classes.panel}>
          <div className={classes.panelTitle}>
            <Typography variant="subtitle2" color="inherit">
              Dev Cockpit
            </Typography>
            <IconButton onClick={() => setPanelOpen(false)}>
              <Icon icon="control-close" />
            </IconButton>
          </div>
          <div className={classes.panelContent}>
            {fields.map(field => (
              <DevModePanelField {...field} />
            ))}

            <div className={classes.panelActionContainer}>
              <Button
                onClick={toggleTimer}
                variant="secondary"
                size="small"
                loading={isTimerActive}
              >
                {isTimerActive
                  ? 'Stop periodic querying'
                  : 'Start periodic querying'}
              </Button>
            </div>
          </div>
        </div>
      )}
      <div className={classes.devModeButtonContainer}>
        <button className={classes.button} onClick={() => setPanelOpen(true)}>
          Dev Cockpit
        </button>
      </div>
    </>
  );
}
