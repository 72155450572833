import React from 'react';
import { Icon } from '@lego/plugin-baseplate-core-components';
import { makeStyles } from '@material-ui/core';
import { BaseplateTheme } from '../../../../style/types';

const useStyles = makeStyles<BaseplateTheme>(() => ({
  titleContainer: {
    gap: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export const RouteTitleWithIcon = ({ title }: { title: string }) => {
  const classes = useStyles();
  return (
    <span className={classes.titleContainer}>
      <div>{title}</div>
      <Icon icon="arrow-up-right-from-square" />
    </span>
  );
};
