import React, { useMemo } from 'react';
import { type ReactElement } from 'react';
import { useCodeBlockStyles } from './CodeBlock.styles';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { useTheme } from '@material-ui/core';
import { getTheme } from './theme';

export interface CodeBlockProps {
  title?: string;
  language: string;
  children: string;
  showLineNumbers?: boolean;
  maxHeight?: number;
}

export function CodeBlock({
  title,
  language,
  children,
  showLineNumbers,
  maxHeight,
}: CodeBlockProps): ReactElement {
  const classes = useCodeBlockStyles();
  const { palette } = useTheme();

  const { codeBlockTheme } = useMemo(() => getTheme(palette.type), [palette]);

  return (
    <div className={classes.wrapper}>
      {title && <div className={classes.title}>{title}</div>}

      <div className={classes.content} style={{ maxHeight }}>
        <SyntaxHighlighter
          wrapLines
          wrapLongLines
          language={language}
          style={codeBlockTheme}
          showLineNumbers={showLineNumbers}
          lineNumberStyle={{ color: codeBlockTheme['hljs-comment'].color }}
        >
          {children}
        </SyntaxHighlighter>
      </div>
    </div>
  );
}
