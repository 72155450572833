import React from 'react';

export const DevLogo = () => {
  return (
    <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#a)">
        <rect width="30" height="30" rx="2" fill="#FFD502" />
        <path
          fill="#000"
          d="m8.376-7 2.828 2.828-47.376 47.377L-39 40.376zM18.376-7l2.828 2.828-47.376 47.377L-29 40.376zM28.376-7l2.828 2.828-47.376 47.377L-19 40.376zM38.376-7l2.828 2.828-47.376 47.377L-9 40.376zM48.376-7l2.828 2.828L3.828 43.205 1 40.376zM58.376-7l2.828 2.828-47.376 47.377L11 40.376zM68.376-7l2.828 2.828-47.376 47.377L21 40.376z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <rect width="30" height="30" rx="2" fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  );
};
