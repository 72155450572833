import { createApiRef } from '@backstage/core-plugin-api';
import {
  CreateEventRequest,
  Event,
  EventLocation,
  EventParticipant,
  PaginatedEventResponse,
  UpdateEventRequest,
} from '@lego/plugin-baseplate-learning-and-development-common';

export const eventsApiRef = createApiRef<EventsApi>({
  id: 'plugin.learning-and-development.events',
});

export interface EventsApi {
  getEventById(eventId: string): Promise<Event>;
  manageEvent(
    event: CreateEventRequest | UpdateEventRequest,
    isNewEvent: boolean,
    id: string,
  ): Promise<any>;
  uploadBanner(eventId: string | number, img: any): Promise<any>;
  removeBanner(evendId: string): Promise<any>;
  updateParticipation(payload: any): Promise<any>;
  getEvents(queryParams: any): Promise<PaginatedEventResponse>;
  getEventParticipants(eventId: string): Promise<EventParticipant[]>;
  getLocations(queryParams: any): Promise<EventLocation[]>;
  getLocationsAvailabilities(queryParams: any): Promise<any>;
}
