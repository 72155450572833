import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function CircleXmark(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M20.031 7c-4.687 0-8.937 2.5-11.312 6.5-2.313 4.063-2.313 9 0 13 2.375 4.063 6.625 6.5 11.312 6.5 4.625 0 8.875-2.438 11.25-6.5 2.313-4 2.313-8.938 0-13-2.375-4-6.625-6.5-11.25-6.5Zm0 29c-5.75 0-11-3-13.875-8-2.875-4.938-2.875-11 0-16 2.875-4.938 8.125-8 13.875-8 5.688 0 10.938 3.063 13.813 8 2.875 5 2.875 11.063 0 16-2.875 5-8.125 8-13.813 8ZM14.97 14.937a1.471 1.471 0 0 1 2.062 0l2.938 2.938 2.937-2.938c.625-.562 1.563-.562 2.125 0 .625.626.625 1.563 0 2.126L22.094 20l2.937 2.938c.625.625.625 1.562 0 2.125-.562.625-1.5.625-2.125 0l-2.937-2.938-2.938 2.938c-.562.625-1.5.625-2.062 0-.625-.563-.625-1.5 0-2.125L17.906 20l-2.937-2.938c-.625-.562-.625-1.5 0-2.125Z" />
    </SvgIcon>
  );
}
