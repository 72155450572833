import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ArrowUp(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="m21.094 6.5 10.5 11c.562.625.562 1.563-.063 2.125-.625.563-1.562.563-2.125-.063l-7.875-8.312V32.5c0 .875-.687 1.5-1.5 1.5-.875 0-1.5-.625-1.5-1.5V11.25l-7.937 8.313c-.563.625-1.5.625-2.125.062s-.625-1.563-.063-2.125l10.5-11c.313-.313.688-.5 1.125-.5.375 0 .75.188 1.063.5Z" />
    </SvgIcon>
  );
}
