import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  fetchApiRef,
} from '@backstage/core-plugin-api';
import {
  ExternalLocationsClient,
  ExternalLocationsMockClient,
  DataProductsClient,
  StorageCredentialsClient,
  StorageCredentialsMockClient,
  TemplateApiRef,
  TemplateClient,
  DataProductsApiRef,
} from './api';

import { rootRouteRef } from './routes';
import {
  ExternalLocationsApiRef,
  StorageCredentialsApiRef,
  StorageCredentialsApiMockRef,
  ExternalLocationsApiMockRef,
} from '@lego/plugin-baseplate-nexus-databricks-storage-credentials-and-external-locations-common';

export const NexusDatabricksStorageCredentialsExternalLocationsPlugin =
  createPlugin({
    id: 'nexus-databricks-storage-credentials-and-external-locations',
    routes: {
      root: rootRouteRef,
    },
    featureFlags: [
      { name: 'nexus-databricks-storage-credentials-and-external-locations' },
    ],
    apis: [
      createApiFactory({
        api: TemplateApiRef,
        deps: {
          fetchApi: fetchApiRef,
          discoveryApi: discoveryApiRef,
        },
        factory: ({ fetchApi, discoveryApi }) =>
          new TemplateClient({
            discoveryApi,
            fetchApi,
          }),
      }),
      createApiFactory({
        api: StorageCredentialsApiMockRef,
        deps: {
          fetchApi: fetchApiRef,
          discoveryApi: discoveryApiRef,
        },
        factory: ({ fetchApi, discoveryApi }) =>
          new StorageCredentialsMockClient({
            discoveryApi,
            fetchApi,
          }),
      }),
      createApiFactory({
        api: ExternalLocationsApiMockRef,
        deps: {
          fetchApi: fetchApiRef,
          discoveryApi: discoveryApiRef,
        },
        factory: ({ fetchApi, discoveryApi }) =>
          new ExternalLocationsMockClient({
            discoveryApi,
            fetchApi,
          }),
      }),
      createApiFactory({
        api: StorageCredentialsApiRef,
        deps: {
          fetchApi: fetchApiRef,
          discoveryApi: discoveryApiRef,
        },
        factory: ({ fetchApi, discoveryApi }) =>
          new StorageCredentialsClient({
            discoveryApi,
            fetchApi,
          }),
      }),
      createApiFactory({
        api: ExternalLocationsApiRef,
        deps: {
          fetchApi: fetchApiRef,
          discoveryApi: discoveryApiRef,
        },
        factory: ({ fetchApi, discoveryApi }) =>
          new ExternalLocationsClient({
            discoveryApi,
            fetchApi,
          }),
      }),
      createApiFactory({
        api: DataProductsApiRef,
        deps: {
          fetchApi: fetchApiRef,
          discoveryApi: discoveryApiRef,
        },
        factory: ({ fetchApi, discoveryApi }) =>
          new DataProductsClient({
            discoveryApi,
            fetchApi,
          }),
      }),
    ],
  });

export const NexusDatabricksStorageCredentialsExternalLocationsPage: () => JSX.Element =
  NexusDatabricksStorageCredentialsExternalLocationsPlugin.provide(
    createRoutableExtension({
      name: 'LandingPage',
      component: () =>
        import('./components/MainComponent').then(m => m.MainComponent),
      mountPoint: rootRouteRef,
    }),
  );
