import {
  Chip,
  baseplateStyles,
  showToast,
} from '@lego/plugin-baseplate-core-components';
import {
  Employee,
  Product,
  ProductConnection,
  getReadableRole,
} from '@lego/plugin-baseplate-people-to-product-common';
import { TableCell, TableRow, Typography } from '@material-ui/core';
import Box from '@mui/material/Box';
import React from 'react';
import DeleteProductButton from '../../DeleteProductButton/DeleteProductButton';
import { useNonDigitalProductManager } from '../../../hooks';

const useStyles = baseplateStyles(theme => ({
  disabled: {
    backgroundColor: theme.semantic.background.info,
  },
}));

type Props = {
  rowData: ProductConnection;
  employee: Employee;
  disabled: boolean;
};

function ProductManagerProductTableRow({ rowData, employee, disabled }: Props) {
  const classes = useStyles();

  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { removeProductFromNonDigitalProductManager } =
    useNonDigitalProductManager();

  const handleOnDeleteButtonClick = async (product: Product) => {
    try {
      if (employee) {
        await removeProductFromNonDigitalProductManager(employee, product);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        showToast({
          type: 'success',
          message: (
            <>
              <Typography>
                Successfully removed <strong>{employee.fullName}</strong> from
                the following product:
              </Typography>
              <Typography style={{ fontWeight: 'bold' }}>
                {product.name} ({product.id})
              </Typography>
            </>
          ),
        });
      }
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      showToast({
        type: 'error',
        message: 'Error happened while removing product.',
      });
    }
  };

  return (
    <TableRow className={disabled ? classes.disabled : ''}>
      <TableCell>{rowData.node.name}</TableCell>
      <TableCell>
        {rowData.roles.map((role: string) => (
          <Box padding={0.5}>
            <Chip label={getReadableRole(role)} />
          </Box>
        ))}
      </TableCell>
      <TableCell>
        <DeleteProductButton
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={() => handleOnDeleteButtonClick(rowData.node)}
          product={rowData.node}
          isDisabled={false}
        />
      </TableCell>
    </TableRow>
  );
}

export default ProductManagerProductTableRow;
