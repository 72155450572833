import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Print(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M11 14H8V8c0-2.188 1.75-4 4-4h14.313c1.062 0 2.062.438 2.812 1.188l1.688 1.687c.75.75 1.187 1.75 1.187 2.813V14h-3V9.687c0-.25-.125-.5-.313-.687L27 7.312c-.188-.187-.438-.312-.688-.312H12c-.563 0-1 .5-1 1v6Zm1 13v6h16v-6H12Zm-1-3h18c1.063 0 2 .938 2 2h2v-6c0-.5-.5-1-1-1H8c-.563 0-1 .5-1 1v6h2c0-1.063.875-2 2-2Zm20 5v5c0 1.125-.938 2-2 2H11c-1.125 0-2-.875-2-2v-5H6c-1.125 0-2-.875-2-2v-7c0-2.188 1.75-4 4-4h24c2.188 0 4 1.813 4 4v7c0 1.125-.938 2-2 2h-3Z" />
    </SvgIcon>
  );
}
