import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ArrowDownToLine(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M30.5 34h-21c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5h21c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5Zm-11.625-8.438-8-8.5a1.549 1.549 0 0 1 .063-2.125c.624-.562 1.562-.562 2.124.063l5.438 5.75V7.5c0-.813.625-1.5 1.5-1.5.813 0 1.5.688 1.5 1.5v13.25L26.875 15c.563-.625 1.5-.625 2.125-.063.625.563.625 1.5.063 2.126l-8 8.5A1.464 1.464 0 0 1 20 26c-.438 0-.813-.125-1.125-.438Z" />
    </SvgIcon>
  );
}
