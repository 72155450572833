import React from 'react';
import {
  CodeBlock,
  baseplateStyles,
} from '@lego/plugin-baseplate-core-components';
import { type ReactElement } from 'react';

export interface RawDefinitionProps {
  rawSpecification: string;
}

const useStyles = baseplateStyles(() => ({
  wrapper: {
    padding: '1rem',
    gridArea: 'content',
  },
}));

export function RawDefinition({
  rawSpecification,
}: RawDefinitionProps): ReactElement {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <CodeBlock title="Raw definition" language="json" showLineNumbers>
        {JSON.stringify(JSON.parse(rawSpecification), null, 2)}
      </CodeBlock>
    </div>
  );
}
