import React, { useEffect, useState } from 'react';
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';
import { Typography } from '@material-ui/core';
import {
  BaseplateOption,
  RadioButton,
} from '@lego/plugin-baseplate-core-components';
import { isNil } from 'lodash';

export const RadioExtension = (props: FieldExtensionComponentProps<string>) => {
  const { onChange, uiSchema, value, schema } = props;
  const fieldProps = uiSchema['ui:options'];
  const defaultValue: any = fieldProps?.defaultValue ?? '';
  const label = fieldProps?.label ?? schema.title;
  const [changedValue, setChangedValue] = useState<string | BaseplateOption>(
    value,
  );

  useEffect(() => {
    if (isNil(changedValue) && !isNil(defaultValue)) {
      onChange(defaultValue);
      setChangedValue(defaultValue);
    }
  }, [changedValue, defaultValue, onChange, setChangedValue]);

  return (
    <div>
      {label !== '' && <label>{label}</label>}
      <RadioButton
        label=""
        value={changedValue}
        onChange={(val: any) => {
          setChangedValue(val);
          onChange(val);
        }}
        options={fieldProps?.options ?? ([] as any)}
      />
    </div>
  );
};
