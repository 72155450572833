import { Callout } from '@lego/plugin-baseplate-core-components';
import React from 'react';

export const IntegrationApiCallout = () => {
  return (
    <div
      style={{
        margin: '1rem 0',
      }}
    >
      <Callout
        title="Integration API"
        type="warning"
        body={
          <>
            This is an integration API. The integration environment is for
            testing, development, integration, or just playing around.{' '}
            <a href="/docs/default/component/amma/about-the-api-management-platform/concepts/test-versus-production-environment">
              Learn more
            </a>
          </>
        }
      />
    </div>
  );
};
