export const productColumns = [
  { title: 'Name' },
  { title: 'Roles' },
  { title: 'TCO' },
  { title: 'Actions' },
];

export const gopColumns = [
  { title: 'Name' },
  { title: 'TCO' },
  { title: 'Actions' },
];
