import React, { ChangeEvent, useState } from 'react';
import {
  FormControl,
  FormHelperText,
  Button,
  Typography,
} from '@material-ui/core';
import { Icon } from '@lego/plugin-baseplate-core-components';
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';

export const ImageUploadField = (
  props: FieldExtensionComponentProps<string>,
) => {
  const { schema, idSchema, rawErrors, onChange, uiSchema } = props;
  const [fileName, setFileName] = useState<string>('');
  const helperText = uiSchema['ui:options']?.helperText as string;

  // Handle the file input change
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setFileName(file.name);
      const reader = new FileReader();
      reader.onloadend = () => {
        if (typeof reader.result !== 'string') {
          throw new Error('Expected reader.result to be a string');
        }
        onChange(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div>
      <Typography component="label" variant="body2">
        {schema.title}
      </Typography>
      <FormControl fullWidth margin="none">
        <Button
          variant="outlined"
          component="label"
          style={{ margin: 0, textAlign: 'center' }}
        >
          <div style={{ marginTop: 6, marginRight: 6 }}>
            <Icon icon="image" />
          </div>
          <Typography variant="body1" style={{ color: 'grey' }}>
            {fileName || 'Upload Image'}{' '}
            {/* Display the file name if a file has been uploaded, otherwise display the default text */}
          </Typography>

          <input
            type="file"
            id={idSchema.$id}
            accept="image/*"
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
        </Button>
        {helperText && (
          <FormHelperText style={{ padding: '0 1rem' }}>
            <Typography variant="body2" color="secondary">
              {helperText}
            </Typography>
          </FormHelperText>
        )}
        <FormHelperText
          style={{ padding: '0 1rem' }}
          error={rawErrors?.length > 0}
        >
          {rawErrors?.[0]}
        </FormHelperText>
      </FormControl>
    </div>
  );
};
