import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function HandScreenLock(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M6 33c-2.25 0-4-1.813-4-4V8c0-2.25 1.75-4 4-4h24c2.188 0 4 1.75 4 4v12.5a1.5 1.5 0 0 1-3 0V8c0-.563-.5-1-1-1H6c-.563 0-1 .438-1 1v21c0 .5.438 1 1 1h7.5a1.5 1.5 0 0 1 0 3H6Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.714 16.187V14.89c0-2.134 2.143-3.89 4.286-3.89 2.116 0 4.286 1.756 4.286 3.89v1.297c.937 0 1.714.783 1.714 1.729v5.355c0 .973-.777 1.729-1.714 1.729h-8.572C9.75 25 9 24.244 9 23.271v-5.355c0-.946.75-1.73 1.714-1.73Zm2.693-.688v.718h3.106v-.718c0-.78-.413-1.552-1.513-1.552-1.125 0-1.593.772-1.593 1.552Z"
      />
      <path d="M26.263 15a1.932 1.932 0 0 0-1.963 1.953V32.72l-2.747-3.662c-.687-.83-1.913-1.025-2.748-.39-.883.683-1.079 1.904-.392 2.733l3.532 4.687a9.594 9.594 0 0 0 6.869 3.856c.25.063.51.057.767.052l.214-.003h3.925c3.434 0 6.28-2.782 6.28-6.248v-5.468c0-1.27-1.08-2.343-2.355-2.343-.638 0-1.177.244-1.619.635-.049-1.22-1.08-2.197-2.306-2.197-.687 0-1.325.293-1.717.78-.343-.927-1.227-1.561-2.208-1.561a2.19 2.19 0 0 0-1.57.634v-7.273A1.963 1.963 0 0 0 26.263 15Z" />
    </SvgIcon>
  );
}
