import { baseplateStyles } from '@lego/plugin-baseplate-core-components';

export const useDependencyDisplayExtensionStyles = baseplateStyles(theme => ({
  dependencyInput: {
    '& > div > .v5-MuiFormControl-root > .v5-MuiInputBase-root': {
      border: `solid 1px ${theme.semantic.border.default}`,
      '& > input': {
        color: theme.semantic.text.primary,
        borderColor: theme.semantic.border.default,
        '-webkit-text-fill-color': theme.semantic.text.primary,
        '&:hover': {
          borderColor: theme.semantic.border.default,
        },
        '&:focus': {
          borderColor: theme.semantic.border.error,
          boxShadow: `0 0 0 2px ${theme.semantic.border.error}80`,
        },
      },
    },
  },
}));
