// dummy
import { Link } from '@backstage/core-components';
import { Tooltip, Typography } from '@material-ui/core';
import React from 'react';
import { useCardStyles } from './Card.styles';

interface Props {
  children: React.ReactNode;
  className?: string;
  title?: string;
  tooltip?: string;
  padding?: 'none' | 'xs' | 'md';
  href?: string;
}

export const Card = ({
  children,
  className,
  title,
  tooltip,
  padding = 'xs',
  href,
}: Props) => {
  const classes = useCardStyles();

  // Use link or div depending on if href is set.
  const Container = ({
    children: containerChildren,
    ...props
  }: {
    children: React.ReactNode;
    className: string;
  }) =>
    href ? (
      <Link
        {...props}
        to={href}
        underline="none"
        className={[classes.clickable, props.className].join(' ')}
      >
        {containerChildren}
      </Link>
    ) : (
      <div {...props}>{containerChildren}</div>
    );

  return (
    <Container
      className={[classes.card, classes[`${padding}Padding`], className].join(
        ' ',
      )}
    >
      {/* Use the same padding in the title section as in the content section. Cannot set noPadding to this container */}
      {title && (
        <div className={classes.titleContainer}>
          <Typography variant="overline" component="p" color="secondary">
            {title}
          </Typography>
          {tooltip && (
            <Tooltip title={tooltip} classes={{ tooltip: classes.tooltipBox }}>
              <div className={classes.tooltip}>?</div>
            </Tooltip>
          )}
        </div>
      )}
      {/* Set the right padding if noPadding is false otherwise no padding class is added */}
      <div className={classes.contentContainer}>{children}</div>
    </Container>
  );
};
