import { baseplateStyles } from '../../utils';

export const useChipStyles = baseplateStyles(theme => ({
  root: {
    '&.v5-MuiChip-root': {
      display: 'inline-flex',
      alignItems: 'center',
      height: '1.5rem',
      fontFamily: theme.typography.fontFamily,
      borderRadius: theme.primitive.borderRadius.default,
      fontSize: theme.typography.body2.fontSize,
      color: theme.semantic.text.tertiary,
      background: 'none',
      padding: 0,
    },
    '& .v5-MuiChip-label': {
      padding: '0.125rem 0.5rem',
    },
    '& .v5-MuiChip-deleteIcon': {
      width: 16,
      height: 16,
      marginRight: 4,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
        borderRadius: '50%',
      },
    },
  },
  neutral: {
    '&.v5-MuiChip-root': {
      backgroundColor: theme.component.tag.neutral.background,
      color: theme.component.tag.neutral.text,
    },
  },
  blue: {
    '&.v5-MuiChip-root': {
      backgroundColor: theme.component.tag.blue.background,
      color: theme.component.tag.blue.text,
    },
  },
  purple: {
    '&.v5-MuiChip-root': {
      backgroundColor: theme.component.tag.purple.background,
      color: theme.component.tag.purple.text,
    },
  },
  green: {
    '&.v5-MuiChip-root': {
      backgroundColor: theme.component.tag.green.background,
      color: theme.component.tag.green.text,
    },
  },
  yellow: {
    '&.v5-MuiChip-root': {
      backgroundColor: theme.component.tag.yellow.background,
      color: theme.component.tag.yellow.text,
    },
  },
  red: {
    '&.v5-MuiChip-root': {
      backgroundColor: theme.component.tag.red.background,
      color: theme.component.tag.red.text,
    },
  },
  success: {
    '&.v5-MuiChip-root': {
      backgroundColor: theme.component.tag.green.background,
      color: theme.component.tag.green.text,
    },
  },
  warning: {
    '&.v5-MuiChip-root': {
      backgroundColor: theme.component.tag.yellow.background,
      color: theme.component.tag.yellow.text,
    },
  },
  error: {
    '&.v5-MuiChip-root': {
      backgroundColor: theme.component.tag.red.background,
      color: theme.component.tag.red.text,
    },
  },
}));
