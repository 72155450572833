import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Clock(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M33 19.969c0-4.625-2.5-8.875-6.5-11.25-4.063-2.313-9-2.313-13 0C9.437 11.094 7 15.344 7 19.969c0 4.687 2.438 8.937 6.5 11.312 4 2.313 8.938 2.313 13 0 4-2.375 6.5-6.625 6.5-11.312Zm-29 0C4 14.28 7 9.03 12 6.156c4.938-2.875 11-2.875 16 0 4.938 2.875 8 8.125 8 13.813 0 5.75-3.063 11-8 13.875-5 2.875-11.063 2.875-16 0-5-2.875-8-8.125-8-13.875Zm14.5-8.5c0-.813.625-1.5 1.5-1.5.813 0 1.5.687 1.5 1.5v7.75l5.313 3.5c.687.5.875 1.437.437 2.125-.5.687-1.438.875-2.125.375l-6-4c-.375-.25-.625-.75-.625-1.25v-8.5Z" />
    </SvgIcon>
  );
}
