import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function MessageQuestion(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M17 29.99v1l4.5-3.375a3.025 3.025 0 0 1 1.813-.625H32c.5 0 1-.437 1-1v-18c0-.5-.5-1-1-1H8c-.563 0-1 .5-1 1v18c0 .563.438 1 1 1h6c1.625 0 3 1.375 3 3Zm-.063 4.813-.312.187-1.063.813c-.312.25-.687.25-1.062.125-.313-.188-.5-.5-.5-.938v-5H8c-2.25 0-4-1.75-4-4v-18c0-2.187 1.75-4 4-4h24c2.188 0 4 1.813 4 4v18c0 2.25-1.813 4-4 4h-8.688L17 34.74l-.063.063Zm-2.375-23.438c.5-1.437 1.813-2.375 3.313-2.375H21.5c2.188 0 3.938 1.813 3.938 4 0 1.375-.75 2.688-1.938 3.375l-2.063 1.188c0 .812-.625 1.437-1.5 1.437a1.48 1.48 0 0 1-1.5-1.5v-.812c0-.563.313-1.063.813-1.313L22 13.803c.313-.188.438-.5.438-.813 0-.562-.375-1-.938-1h-3.625c-.188 0-.375.188-.438.375l-.062.063c-.25.75-1.125 1.187-1.875.937a1.56 1.56 0 0 1-.938-1.937v-.063ZM22 22.99c0 1.125-.938 2-2 2-1.125 0-2-.875-2-2 0-1.062.875-2 2-2 1.063 0 2 .938 2 2Z" />
    </SvgIcon>
  );
}
