import { Entity } from '@backstage/catalog-model';
import React from 'react';
import { EntityLinkVariant } from '../types';
import { Typography } from '@material-ui/core';

interface Props {
  entity: Entity;
  variant: EntityLinkVariant;
  // Add your prop types here
}

export const EntityLinkApplicationFooter = ({ entity, variant }: Props) => {
  switch (variant) {
    case 'link':
      return (
        <div>
          <Typography variant="overline" color="secondary">
            {entity.kind}
          </Typography>
        </div>
      );
    default:
      return <div />;
  }
};
