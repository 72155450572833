import { FormValidation } from '@backstage/plugin-scaffolder-react/alpha';
import type { FieldValidation } from '@rjsf/utils';
import { ScaffolderTaskStatus } from '@backstage/plugin-scaffolder-react';

export function getStatusText(stepStatus: ScaffolderTaskStatus) {
  return (
    {
      completed: 'Completed',
      open: 'Pending',
      failed: 'Failed',
      processing: 'In progress',
      skipped: 'Skipped',
      cancelled: 'Cancelled',
    }[stepStatus] || 'Unknown Status'
  );
}

function isFieldValidation(error: any): error is FieldValidation {
  return !!error && '__errors' in error;
}

export function hasErrors(errors?: FormValidation): boolean {
  if (!errors) {
    return false;
  }

  for (const error of Object.values(errors)) {
    if (isFieldValidation(error)) {
      if ((error.__errors ?? []).length > 0) {
        return true;
      }

      continue;
    }

    if (hasErrors(error)) {
      return true;
    }
  }

  return false;
}
