import { baseplateStyles } from '../../utils';

export const useSideMenuStyles = baseplateStyles(theme => ({
  menu: {
    borderRight: `1px solid ${theme.semantic.border.default}`,
    height: '100%',
    margin: 0,
    padding: '1rem 0.5rem',
    overflowY: 'auto',
    fontFamily: 'inherit',
    '& li': {
      listStyleType: 'none',
    },
  },
  section: {
    display: 'block',
    padding: theme.spacing(0, 2),
    '&:not(:first-child)': {
      margin: theme.spacing(2, 0, 0),
    },
  },
  item: {
    display: 'flex',
    color: theme.semantic.text.primary,
    alignItems: 'center',
    gap: '0.5rem',
    fontFamily: theme.typography.fontFamily,
    padding: theme.spacing(1, 2),
    marginTop: theme.spacing(0.5),
    borderRadius: theme.primitive.borderRadius.default,
    background: 'none',
    width: '100%',
    textAlign: 'left',
    cursor: 'pointer',
    border: 0,
    transition: 'background-color 150ms ease-in',
    wordBreak: 'break-word',
    '& p': {
      flexGrow: 1,
    },
    '&:hover, &:focus-visible, &$active': {
      backgroundColor: theme.semantic.background.neutral[3],
      color: theme.semantic.text.primary,
      textDecoration: 'none',
    },
    '&:hover $external': {
      visibility: 'visible',
    },
  },
  active: {},
  external: {
    display: 'flex',
    alignItems: 'center',
    visibility: 'hidden',
    color: theme.semantic.text.tertiary,
  },
  childContainer: {
    marginLeft: '1rem',
    padding: '0 0 0 0.5rem',
    borderLeft: `1px solid ${theme.semantic.border.default}`,
    opacity: 0,
    willChange: 'opacity, max-height',
    height: 0,
    overflow: 'hidden',
    pointerEvents: 'none',
    visibility: 'hidden',
    '&$open': {
      transition: 'opacity 200ms ease-in-out, max-height 200ms ease-in-out',
      height: 'auto',
      opacity: 1,
      pointerEvents: 'auto',
      visibility: 'visible',
    },
  },
  chevron: {
    transition: 'transform 200ms ease-in-out',
    '&$open': {
      transform: 'rotate(90deg)',
    },
  },
  open: {},
}));
