import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function VolumeHigh(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M33.313 6.031C37.374 9.344 40 14.344 40 19.97c0 5.687-2.625 10.687-6.688 14-.624.5-1.562.437-2.124-.188-.5-.687-.375-1.625.25-2.125C34.813 28.906 37 24.72 37 19.97c0-4.688-2.188-8.875-5.563-11.625-.625-.5-.75-1.438-.25-2.125a1.558 1.558 0 0 1 2.125-.188Zm-3.75 4.625v.063C32.25 12.906 34 16.219 34 20.03c0 3.75-1.75 7.063-4.438 9.313-.687.5-1.625.375-2.125-.25a1.558 1.558 0 0 1 .188-2.125c2.063-1.625 3.375-4.125 3.375-7a8.8 8.8 0 0 0-3.375-6.938c-.625-.562-.75-1.5-.188-2.125.5-.625 1.438-.75 2.125-.25Zm-3.813 4.688c1.375 1.125 2.25 2.75 2.25 4.625 0 1.937-.875 3.562-2.25 4.687-.625.5-1.563.438-2.125-.187-.5-.688-.375-1.625.25-2.125A3.09 3.09 0 0 0 25 19.969c0-.938-.438-1.75-1.125-2.313-.625-.5-.75-1.437-.25-2.125a1.558 1.558 0 0 1 2.125-.187Zm-15.813 1.25c-.25.25-.624.375-.937.375H3.5c-.313 0-.5.25-.5.5v5c0 .312.188.5.5.5H9c.313 0 .688.187.938.437L17 29.594V10.406l-7.063 6.188Zm8.188-10.625c1 0 1.875.875 1.875 1.875v24.312c0 1-.875 1.813-1.875 1.813-.5 0-.938-.125-1.25-.438l-8.5-7.562H3.5a3.494 3.494 0 0 1-3.5-3.5v-5c0-1.875 1.563-3.5 3.5-3.5h4.875l8.5-7.5c.313-.313.75-.5 1.25-.5Z" />
    </SvgIcon>
  );
}
