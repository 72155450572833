import { baseplateStyles } from '@lego/plugin-baseplate-core-components';

const breakpoint = 768;
const sidebarWidth = 300;
const tableOfContentsWidth = 200;
const padding = 24;

export const useTechdocsPageStyles = baseplateStyles(theme => ({
  '@global': {
    body: {
      background: theme.semantic.background.neutral[1],
    },
    article: {
      paddingTop: '0px !important',
    },
  },
  techdocsPage: {
    '&:not($entityPage)': {
      paddingTop: theme.primitive.dimensions.nav.height,
    },
    margin: -24,
    gridArea: 'pageContent',
    '& > .techdocs-reader-page > main': {
      '& > article': {
        marginTop: 0,
        paddingTop: 0,
        background: theme.semantic.background.neutral[1],
        [`@media screen and (min-width: ${breakpoint})`]: {
          marginLeft: `calc(${sidebarWidth} + ${padding})`,
          marginRight: `calc(${tableOfContentsWidth} + ${padding})`,
        },
        '& > div': {
          padding: `0 !important`,
        },
        '& > div > div': {
          padding: `0 !important`,
        },
      },
    },
  },
  entityPage: {},
}));
