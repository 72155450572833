import React from 'react';
import { useSideMenuStyles } from './SideMenu.styles';

interface Props {
  /**  Children must be of type SideMenu.Item or SideMenu.Section */
  children: React.ReactNode;
  className?: string;
}

export const SideMenuWrapper = ({ children, className }: Props) => {
  const classes = useSideMenuStyles();
  return (
    <ul className={[classes.menu, className].filter(Boolean).join(' ')}>
      {children}
    </ul>
  );
};
