import { Role } from '@lego/plugin-baseplate-eagraph-common';
import { getReadableRole } from '@lego/plugin-baseplate-people-to-product-common';
import { Checkbox, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useState } from 'react';

interface RoleDropdownProps {
  setSelectedRoles: (role: Role[]) => void;
  defaultRoles?: Role[];
}

type RoleOption = {
  label: string;
  role: Role;
};

function RoleDropdown({ setSelectedRoles, defaultRoles }: RoleDropdownProps) {
  const roleOptions = Object.entries(Role).map(role => ({
    label: getReadableRole(role[1]),
    role: role[1],
  }));

  // Keep the defaultRole labels unformatted
  const getDefaultRoleOptions = (roles: Role[] | undefined) => {
    if (!roles) return [];
    return roles.map(role => ({
      label: getReadableRole(role),
      role,
    }));
  };

  const [selectedOptions, setSelectedOptions] = useState<RoleOption[]>(
    getDefaultRoleOptions(defaultRoles),
  );

  const handleOnChange = (_: any, newSelectedOptions: any) => {
    setSelectedOptions(newSelectedOptions);
    setSelectedRoles(newSelectedOptions.map((option: any) => option.role));
  };

  return (
    <Autocomplete
      id="role-dropdown"
      multiple
      options={roleOptions || []}
      value={selectedOptions}
      disableCloseOnSelect
      onChange={handleOnChange}
      getOptionSelected={(option, value) =>
        option.label === getReadableRole(value.role)
      }
      getOptionLabel={option => option.label}
      renderInput={params => (
        <TextField
          {...params}
          label="Select roles"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
          }}
        />
      )}
      renderOption={(option, { selected }) => (
        <>
          <Checkbox checked={selected} />
          {option.label}
        </>
      )}
      style={{ width: 200 }}
    />
  );
}

export default RoleDropdown;
