import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function PaintbrushFine(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M32.68 7.477 21.242 18.914l1.875 1.875L34.555 9.352c.5-.5.5-1.313 0-1.875a1.44 1.44 0 0 0-1.938 0h.063Zm-17.438 16.75a4.695 4.695 0 0 0-1.625-.313c-2.5 0-4.5 2.063-4.5 4.5v.75c.25 1.313-.125 2.688-.812 3.75h-.063 5.375c2.438 0 4.5-2 4.5-4.5 0-.562-.125-1.125-.312-1.625l-2.563-2.562Zm-.375-3.188L30.555 5.352a4.263 4.263 0 0 1 6.062 0 4.338 4.338 0 0 1 0 6.125L20.992 27.164c.063.438.125.875.125 1.25 0 4.188-3.375 7.5-7.5 7.5h-9.5c-1.125 0-2-.875-2-2 0-1.062.875-2 2-2h.375c1.125 0 1.875-1.187 1.688-2.312-.063-.375-.063-.75-.063-1.188 0-4.125 3.313-7.5 7.5-7.5.375 0 .813.063 1.25.125Z" />
    </SvgIcon>
  );
}
