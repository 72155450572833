import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function GraduationCap(props) {
  return (
    <SvgIcon
      viewBox="0 0 41 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M20.023 8.988c-.187 0-.312.062-.5.125L5.898 13.988l2.813 1.062c.312-.187.625-.312.937-.437l10-3.75a1.02 1.02 0 0 1 1.313.625c.187.5-.063 1.062-.625 1.25l-8.813 3.312 8 2.875c.188.063.313.063.5.063.125 0 .313 0 .438-.063l13.625-4.937L20.46 9.113a1.159 1.159 0 0 0-.438-.125ZM.961 15.425c-.563-.187-.938-.75-.938-1.437 0-.625.375-1.188.938-1.375L18.523 6.3a3.776 3.776 0 0 1 1.5-.312c.5 0 1 .125 1.438.312l17.562 6.313c.563.187 1 .75 1 1.375 0 .687-.437 1.25-1 1.437l-17.562 6.313a3.707 3.707 0 0 1-1.438.312 3.776 3.776 0 0 1-1.5-.312L7.898 17.925c-1.687 1.5-2.75 3.563-2.875 5.875.375.875.625 1.75.813 2.625.375 1.688.687 4.125.125 6.813-.063.25-.188.5-.438.625s-.5.187-.75.125l-4-1c-.375-.063-.625-.313-.75-.625-.062-.313 0-.688.188-.938.5-.75 1-1.562 1.375-2.375.75-1.437 1.437-3.25 1.437-5.062v-.063c.063-2.562 1.125-4.937 2.813-6.75L.96 15.425Zm30.062 5 1 9.063c0 2.25-5.375 4.5-12 4.5s-12-2.25-12-4.5l.938-9.063 2.875 1.063-.75 7.5c.25.25.75.5 1.5.812 1.812.688 4.437 1.188 7.437 1.188s5.625-.5 7.375-1.188c.75-.312 1.25-.562 1.5-.812l-.75-7.5 2.875-1.063Zm-1.812 8.313c0 .062 0 .062-.063.062v-.125c.063 0 .063.063.063.063Zm-18.438 0c0-.063 0-.063.063-.063v.063c.062 0 .062 0 0 .062-.063 0-.063 0-.063-.062Z" />
    </SvgIcon>
  );
}
