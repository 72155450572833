import { getNavId } from '../../sidenav/getNavId';

/**
 * Generates a valid HTML id that can be used for scroll linking
 */
export const getEndpointId = ({
  path,
  method,
}: {
  path: string;
  method: string;
}) => {
  // Normalize the method to lowercase
  const normalizedMethod = method.toLowerCase();

  // Create a base ID from the path
  const baseId = getNavId(path);

  // Prepend id to make sure it starts with a letter
  return `id-${baseId}-${normalizedMethod}`;
};
