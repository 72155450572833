import { useApi } from '@backstage/core-plugin-api';
import { DestructiveActionWarningDialog } from '@lego/plugin-baseplate-core-components';
import React, { useState } from 'react';
import { communitiesV2ApiRef } from '../../apiV2';
import { useCommunity } from '../../hooks';
import { showToast } from '@lego/plugin-baseplate-core-components';

export const LeaveDialog = () => {
  const [mutationError, setMutationError] = useState<string | null>(null);
  const communitiesV2Api = useApi(communitiesV2ApiRef);
  const {
    entity,
    backstageIdentity,
    setMembershipUpdateLoading,
    isLeaveDialogOpen,
    setIsLeaveDialogOpen,
  } = useCommunity();

  const leaveCommunity = async () => {
    try {
      setMembershipUpdateLoading(true);
      if (entity && backstageIdentity) {
        const communityWithoutTheUser = await communitiesV2Api.leaveCommunity(
          entity.metadata.dbId,
          backstageIdentity?.userEntityRef,
        );
        if (communityWithoutTheUser.id) {
          setIsLeaveDialogOpen(false);

          void showToast({
            type: 'success',
            message: 'Successfully left community!',
          });
        }
      } else {
        throw new Error(
          'Something went wrong while reading user data, please try again later.',
        );
      }
    } catch (error) {
      setMutationError((error as Error).message);
    } finally {
      setMembershipUpdateLoading(false);
    }
  };

  const handleLeaveClick = async () => {
    await leaveCommunity();
  };

  return (
    <DestructiveActionWarningDialog
      title="Would you like to leave this community?"
      description="Even if you are leaving for now, you are always welcome to come back!"
      open={isLeaveDialogOpen}
      buttonText="Leave"
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onConfirm={handleLeaveClick}
      onClose={() => setIsLeaveDialogOpen(false)}
      error={mutationError}
    />
  );
};
