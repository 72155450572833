import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Palette(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M33 20.188V20c0-7.125-5.875-13-13-13-7.188 0-13 5.875-13 13 0 7.188 5.813 13 13 13h.625l.125-.125c.125-.125.25-.375.25-.75 0-.063-.063-.313-.375-1.063-.063-.062-.063-.187-.125-.25-.25-.625-.688-1.562-.875-2.562-.125-.375-.125-.813-.125-1.25 0-3.313 2.688-6 6-6h6.125c.438 0 .875-.125 1.063-.313.25-.187.312-.375.312-.5Zm3 0C35.937 22.5 33.875 24 31.625 24H25.5a3 3 0 0 0-3 3c0 .25 0 .438.063.625.125.688.375 1.25.625 1.875.375.875.812 1.75.812 2.625 0 2-1.375 3.813-3.375 3.875H20c-8.875 0-16-7.125-16-16 0-8.813 7.125-16 16-16 8.813 0 16 7.188 16 16v.188ZM14 20c0 1.125-.938 2-2 2-1.125 0-2-.875-2-2 0-1.063.875-2 2-2 1.063 0 2 .938 2 2Zm0-4c-1.125 0-2-.875-2-2 0-1.063.875-2 2-2 1.063 0 2 .938 2 2 0 1.125-.938 2-2 2Zm8-4c0 1.125-.938 2-2 2-1.125 0-2-.875-2-2 0-1.063.875-2 2-2 1.063 0 2 .938 2 2Zm4 4c-1.125 0-2-.875-2-2 0-1.063.875-2 2-2 1.063 0 2 .938 2 2 0 1.125-.938 2-2 2Z" />
    </SvgIcon>
  );
}
