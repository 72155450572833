import { baseplateStyles } from '@lego/plugin-baseplate-core-components';

export const useComingEventsStyles = baseplateStyles(_theme => ({
  card: {
    width: '100%',
    padding: 0,
    '& > div > .v5-MuiList-root > .v5-MuiListItem-root': {
      '& > .v5-MuiListItemText-root': {
        marginTop: '0 !important',
      },

      '& > .v5-MuiListItemAvatar-root': {
        display: 'flex',
        '& > img': {
          width: 200,
          height: 120,
        },
      },
    },
  },

  noEventGrp: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    '& > button': {
      margin: '10px auto',
      width: 'max-content',
    },
    '& > p:first-of-type': {
      fontWeight: 500,
    },
  },
}));
