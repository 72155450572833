import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function SignsPost(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M18.523 5.5c0-.813.625-1.5 1.5-1.5.813 0 1.5.688 1.5 1.5V6h10c.313 0 .563.188.75.375l3.25 4c.25.375.25.938 0 1.25l-3.25 4a.89.89 0 0 1-.75.375h-10v2h10.5c1.063 0 2 .938 2 2v6c0 1.125-.937 2-2 2h-10.5v6.5c0 .875-.687 1.5-1.5 1.5-.875 0-1.5-.625-1.5-1.5V28H8.461a.89.89 0 0 1-.75-.375l-3.188-4c-.312-.313-.312-.875 0-1.25l3.188-4c.187-.188.437-.375.75-.375h10.062v-2h-10.5c-1.125 0-2-.875-2-2V8c0-1.063.875-2 2-2h10.5v-.5ZM9.023 9v4h21.5l1.625-2-1.625-2h-21.5Zm22 16v-4H9.461l-1.625 2 1.625 2h21.562Z" />
    </SvgIcon>
  );
}
