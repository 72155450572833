import {
  Icon,
  baseplateStyles,
  semantic,
} from '@lego/plugin-baseplate-core-components';
import { Modal, IconButton, Tooltip } from '@material-ui/core';
import React from 'react';
import { LangChainChat } from '../LangChainComponent';
import { useAsyncEntity } from '@backstage/plugin-catalog-react';
import yaml from 'js-yaml';

const useStyles = baseplateStyles(theme => ({
  paper: {
    position: 'absolute',
    width: '60%',
    maxHeight: '100%',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: '50%',
    left: '50%',
    transform: `translate(-${50}%, -${50}%)`,
    overflow: 'auto',
  },
}));

export interface AiEntityHelperProps {
  userHint?: string;
  userHintInModal?: string;
  model?: 'gpt-4o';
}

export const AiEntityHelper = ({
  userHint = 'Ask Basebot anything about this page...',
  userHintInModal = 'Ask me anything about the page...',
  model = 'gpt-4o',
}: AiEntityHelperProps) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const { entity, loading, error } = useAsyncEntity();
  const [yamlContent, setYamlContent] = React.useState<string | null>(null);

  React.useEffect(() => {
    if (entity) {
      if (entity.kind.toLowerCase() === 'tool' && entity.spec?.image) {
        delete entity.spec?.image;
      }
      const yamlString = yaml.dump(entity);
      setYamlContent(
        entity.kind.toLowerCase() === 'api'
          ? `${yamlString}. Do not forget to inform your chat partner about the AMMA documentation for best practices to produce and consume APIs https://baseplate.legogroup.io/docs/default/Component/amma`
          : yamlString,
      );
    }
  }, [entity]);

  if (
    loading ||
    error ||
    !entity ||
    (entity.kind.toLowerCase() !== 'api' &&
      entity.kind.toLowerCase() !== 'product' &&
      entity.kind.toLowerCase() !== 'group' &&
      // entity.kind.toLowerCase() !== 'tool')&&
      entity.kind.toLowerCase() !== 'application' &&
      entity.kind.toLowerCase() !== 'community')
  ) {
    return null;
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Tooltip title={userHint} arrow>
        <IconButton
          color="primary"
          onClick={handleOpen}
          style={{ padding: 16 }}
        >
          <Icon icon="sparkles" />
        </IconButton>
      </Tooltip>
      <Modal open={open} onClose={handleClose}>
        <div className={classes.paper}>
          <LangChainChat
            verbose
            userHint={userHintInModal}
            context={
              `you get now the following presentation of this UI page, which I am seeing right now and want to MAINLY chat with you about begin: ${yamlContent} :end` ||
              ''
            }
            entity={entity}
            model={model}
          />
        </div>
      </Modal>
    </React.Fragment>
  );
};
