import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function VolumeMedium(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M9.938 16.625A1.38 1.38 0 0 1 9 17H3.5c-.313 0-.5.25-.5.5v5c0 .313.188.5.5.5H9c.313 0 .688.188.938.438L17 29.625V10.437l-7.063 6.188ZM18.124 6c1 0 1.875.875 1.875 1.875v24.313c0 1-.875 1.812-1.875 1.812-.5 0-.938-.125-1.25-.438L8.375 26H3.5A3.494 3.494 0 0 1 0 22.5v-5C0 15.625 1.563 14 3.5 14h4.875l8.5-7.5c.313-.313.75-.5 1.25-.5Zm11.438 4.688v.062C32.25 12.938 34 16.25 34 20.063c0 3.75-1.75 7.062-4.438 9.312-.687.5-1.625.375-2.125-.25A1.558 1.558 0 0 1 27.625 27C29.688 25.375 31 22.875 31 20a8.8 8.8 0 0 0-3.375-6.938c-.625-.562-.75-1.5-.188-2.124.5-.626 1.438-.75 2.125-.25Zm-3.813 4.687C27.125 16.5 28 18.125 28 20c0 1.938-.875 3.563-2.25 4.688-.625.5-1.563.437-2.125-.188-.5-.688-.375-1.625.25-2.125A3.09 3.09 0 0 0 25 20c0-.938-.438-1.75-1.125-2.313-.625-.5-.75-1.437-.25-2.125a1.558 1.558 0 0 1 2.125-.187Z" />
    </SvgIcon>
  );
}
