import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Shield(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M20.001 7.188 8.564 12c-.375.188-.625.5-.563.813 0 5.75 2.375 15.624 11.625 20 .25.124.5.124.688 0 9.25-4.438 11.625-14.25 11.687-20 0-.313-.25-.626-.562-.813L20 7.187Zm.813-2.938 11.75 5C33.939 9.875 35 11.188 35 12.813c-.062 6.25-2.625 17.562-13.375 22.75-1.062.5-2.25.5-3.312 0C7.564 30.375 5 19.063 5 12.813c-.062-1.626 1-2.938 2.375-3.563l11.75-5a1.996 1.996 0 0 1 1.688 0Z" />
    </SvgIcon>
  );
}
