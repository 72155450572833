import { DesignTokens } from '../types';

export const entity = (tokens: DesignTokens) => ({
  CatalogReactEntityDisplayName: {
    icon: {
      position: 'relative',
      borderRadius: tokens.primitive.borderRadius.default,
      overflow: 'hidden',
      '& svg': {
        verticalAlign: 'middle',
        width: 24,
        height: 24,
      },
    },
    root: {
      display: 'inline-flex',
      padding: '4px 8px',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      gap: '0.5rem',
      background: 'none',
      border: 0,
      '&:hover': {
        background: tokens.semantic.background.neutral[3],
      },
    },
  },
});
