import { useEffect } from 'react';
import { useShadowRootElements } from '@backstage/plugin-techdocs-react';
import { makeStyles } from '@material-ui/core';
import { BackstageTheme } from '@backstage/theme';

const useStyles = makeStyles<BackstageTheme>(theme => ({
  link: {
    color: `${theme.palette.link}`,
  },
}));
export const ColorLinksAddon = () => {
  const classes = useStyles();
  // This hook can be used to get references to specific elements. If you need
  // access to the whole shadow DOM, use the the underlying useShadowRoot()
  // hook instead.
  const links = useShadowRootElements(['a']);
  useEffect(() => {
    links.forEach(link => {
      if (!link.className && !link.className.includes('md-nav__link')) {
        link.classList.add(classes.link);
      }
    });
  }, [classes.link, links]);
  // Nothing to render directly, so we can just return null.
  return null;
};
