import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { Duration } from 'luxon';
import useAsync from 'react-use/lib/useAsync';

/**
 * Returns the backstage.io/time-saved annotation (as a number of minutes) for
 * a given template entity ref.
 */
export const useTemplateTimeSavedMinutes = (templateRef: string) => {
  const catalogApi = useApi(catalogApiRef);

  const { value: timeSavedMinutes } = useAsync(async () => {
    const entity = await catalogApi.getEntityByRef(templateRef);
    const timeSaved = entity?.metadata.annotations?.['backstage.io/time-saved'];

    // This is not a valid template or the template has no time-saved value.
    if (!entity || !timeSaved) {
      return undefined;
    }

    const durationMs = Duration.fromISO(timeSaved).as('minutes');

    // The time-saved annotation has an invalid value. Ignore.
    if (Number.isNaN(durationMs)) {
      return undefined;
    }

    return durationMs;
  }, [catalogApi, templateRef]);

  return timeSavedMinutes;
};
