import { DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';

const lndBaseUrl = 'communities';

const processHeader = (headers: any) => {
  return headers
    ? { ...headers, 'Content-Type': 'application/json' }
    : { 'Content-Type': 'application/json' };
};
export class ApiUtils {
  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi: FetchApi;

  public constructor(discoveryApi: DiscoveryApi, fetchApi: FetchApi) {
    this.discoveryApi = discoveryApi;
    this.fetchApi = fetchApi;
  }

  private async getBaseUrl(
    path: string,
    callBaseUrl?: string,
  ): Promise<string> {
    const baseUrl = `${await this.discoveryApi.getBaseUrl(
      callBaseUrl ?? lndBaseUrl,
    )}/`;
    return new URL(path, baseUrl).toString();
  }

  public async get<T>(
    path: string,
    callBaseUrl?: string,
    headers?: any,
  ): Promise<T> {
    const response = await this.fetchApi.fetch(
      await this.getBaseUrl(path, callBaseUrl),
      {
        headers: { ...processHeader(headers) },
      },
    );

    if (!response.ok) {
      throw await response.json();
    }

    return response.json() as Promise<T>;
  }

  public async post<T>(
    path: string,
    requestBody?: any,
    callBaseUrl?: string,
    headers?: any,
    isGetPostMethod: boolean = false,
  ): Promise<T> {
    const response = await this.fetchApi.fetch(
      await this.getBaseUrl(path, callBaseUrl),
      {
        method: 'POST',
        headers: processHeader(headers),
        body: JSON.stringify(requestBody),
      },
    );
    if (!response.ok) {
      throw await response.json();
    }

    let result: Response | Promise<T>;
    if (isGetPostMethod || this.hasBody(response)) {
      result = response.json() as Promise<T>;
    } else {
      result = response;
    }
    return result as T;
  }

  public async put<T>(
    path: string,
    requestBody?: any,
    callBaseUrl?: string,
  ): Promise<T> {
    const response = await this.fetchApi.fetch(
      await this.getBaseUrl(path, callBaseUrl),
      {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody),
      },
    );
    if (!response.ok) {
      throw await response.json();
    }

    return (this.hasBody(response) ? response.json() : response) as Promise<T>;
  }

  public async patch<T>(
    path: string,
    requestBody?: any,
    callBaseUrl?: string,
  ): Promise<T> {
    const response = await this.fetchApi.fetch(
      await this.getBaseUrl(path, callBaseUrl),
      {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      },
    );
    if (!response.ok) {
      throw await response.json();
    }

    return (this.hasBody(response) ? response.json() : response) as Promise<T>;
  }

  public async delete<T>(path: string, callBaseUrl?: string): Promise<T> {
    const response = await this.fetchApi.fetch(
      await this.getBaseUrl(path, callBaseUrl),
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
    if (!response.ok) {
      throw await response.json();
    }

    return (this.hasBody(response) ? response.json() : response) as Promise<T>;
  }

  private hasBody(response: Response): boolean {
    const contentLength = response.headers.get('Content-Length');
    return contentLength !== null && contentLength !== '0';
  }
}
