import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ArrowsRotate(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M35 16.5c0 .875-.688 1.5-1.5 1.5h-9c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5h5.375l-3.25-3.25C24.812 10 22.437 9 20 9a10.98 10.98 0 0 0-10.125 6.75 1.561 1.561 0 0 1-2 .75c-.75-.313-1.125-1.188-.813-1.938C9.188 9.563 14.188 6 20 6c3.25 0 6.375 1.313 8.688 3.625L32 12.938V7.5c0-.813.625-1.5 1.5-1.5.813 0 1.5.688 1.5 1.5v9ZM6.5 22h7c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5h-3.438l3.313 3.313C15.125 30.063 17.5 31 20 31c4.5 0 8.438-2.688 10.063-6.625.375-.813 1.25-1.125 2-.813.75.313 1.124 1.188.812 2C30.687 30.5 25.75 34 20 34c-3.313 0-6.438-1.25-8.75-3.563L8 27.125V30.5c0 .875-.688 1.5-1.5 1.5-.875 0-1.5-.625-1.5-1.5v-7c0-.813.625-1.5 1.5-1.5Z" />
    </SvgIcon>
  );
}
