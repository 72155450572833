import { baseplateStyles } from '../../utils';

export const useRadioStyles = baseplateStyles(theme => ({
  radioControlLabel: {
    '& > .v5-MuiRadio-root': {
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '& > span > svg': {
        width: 30,
        height: 30,
        color: theme.semantic.border.default,
      },
      '&.Mui-checked': {
        '& > span > svg': {
          color: theme.component.button.primary.background.default,
        },
      },
    },
  },
  disabledOption: {
    color: theme.semantic.text.tertiary,
  },
  descriptiveTextContainer: {
    padding: '0 1rem',
  },
  helperText: {
    color: theme.semantic.text.secondary,
  },
  errorText: {
    color: theme.semantic.text.error,
  },
  successText: {
    color: theme.semantic.text.success,
  },

  // Declaring classes, supposed to be empty
  error: {},
  success: {},
  loadingIcon: {},
}));
