import { InteractionRequiredAuthError } from '@azure/msal-browser';
import {
  AuthRequestOptions,
  microsoftAuthApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { showToast } from '@lego/plugin-baseplate-core-components';

const showPopUpError = () =>
  showToast({
    type: 'info',
    message:
      'It seems the pop-up we use to authenticate you was blocked! Please go to your browser`s settings and ensure that pop-ups are enabled for Baseplate.',
  });

export const useAzureTokenProvider = (): {
  getAccessToken: (scope: string) => Promise<string>;
  options?: AuthRequestOptions;
} => {
  const authApi = useApi(microsoftAuthApiRef);

  const getAccessToken = async (
    scope: string,
    options?: AuthRequestOptions,
  ): Promise<string> => {
    try {
      const token = await authApi.getAccessToken(scope, options);
      return token;
    } catch (error) {
      if (!(error instanceof InteractionRequiredAuthError)) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        showPopUpError();
        throw error;
      }
      throw error;
    }
  };
  return { getAccessToken };
};
