import React, { useState } from 'react';
import { FormControl, Select, MenuItem } from '@material-ui/core';

interface DeploymentSelectionBoxProps {
  onChange: (selectedValue: string) => void;
  model: string;
}

export const DeploymentSelectionBox: React.FC<DeploymentSelectionBoxProps> = ({
  onChange,
  model = 'gpt-4o',
}) => {
  const [selectedValue, setSelectedValue] = useState<string>(model);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newValue = event.target.value as string;
    setSelectedValue(newValue);
    onChange(newValue);
  };

  return (
    <FormControl variant="outlined" fullWidth>
      <Select id="select" value={selectedValue} onChange={handleChange}>
        <MenuItem value="gpt-4o">
          gpt-4o
          {/* (pro: smartest GenAI model in Azure OpenAI cons: nothing relevant to mention in the use case Baseplate currently provides with the GenAI models) */}
        </MenuItem>
      </Select>
    </FormControl>
  );
};
