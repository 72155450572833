import { ProfileInfo } from '@backstage/core-plugin-api';
import { CatalogApi } from '@backstage/plugin-catalog-react';
import { fetchUserData } from './fetchUserData';

export const updateUserDataLayer = async (
  user: ProfileInfo,
  catalogApi: CatalogApi,
  abortSignal: AbortSignal,
) => {
  const userEntityRef = user?.email?.split('@')[0];
  if (!userEntityRef) return;

  const { products, position, subdomain, domain } = await fetchUserData(
    userEntityRef,
    catalogApi,
    abortSignal,
  );

  const extendedUser = {
    email: user.email || 'undefined',
    products: products,
    position: position,
    subdomain: subdomain,
    domain: domain,
  };

  const _paq = (window._paq = window._paq || []);
  const CUSTOM_DIMENSION_ORG_DOMAIN = 1; // ID defined in Matomo
  const CUSTOM_DIMENSION_ORG_SUBDOMAIN = 2; // ID defined in Matomo
  const CUSTOM_DIMENSION_ORG_POSITION = 3; // ID defined in Matomo
  const CUSTOM_DIMENSION_ORG_PRODUCT_1 = 4; // ID defined in Matomo
  const CUSTOM_DIMENSION_ORG_PRODUCT_2 = 5; // ID defined in Matomo
  const CUSTOM_DIMENSION_ORG_PRODUCT_3 = 6; // ID defined in Matomo

  _paq.push(['setUserId', extendedUser?.email]);
  _paq.push([
    'setCustomDimension',
    CUSTOM_DIMENSION_ORG_DOMAIN,
    extendedUser?.domain,
  ]);
  _paq.push([
    'setCustomDimension',
    CUSTOM_DIMENSION_ORG_SUBDOMAIN,
    extendedUser?.subdomain,
  ]);
  _paq.push([
    'setCustomDimension',
    CUSTOM_DIMENSION_ORG_POSITION,
    extendedUser?.position,
  ]);
  _paq.push([
    'setCustomDimension',
    CUSTOM_DIMENSION_ORG_PRODUCT_1,
    extendedUser?.products[0],
  ]);
  _paq.push([
    'setCustomDimension',
    CUSTOM_DIMENSION_ORG_PRODUCT_2,
    extendedUser?.products[1],
  ]);
  _paq.push([
    'setCustomDimension',
    CUSTOM_DIMENSION_ORG_PRODUCT_3,
    extendedUser?.products[2],
  ]);
};
