import { createApiRef } from '@backstage/core-plugin-api';

export const MBCPluginGithubApiRef = createApiRef<MBCPluginGithubApi>({
  id: 'plugin.github.mbc',
});

export type MBCPluginGithubApi = {
  submitIssue(
    leanixId: string,
    imageName: string,
    gitHubTeamName: string,
    macOSVersion: string,
    chipArchitecture: string,
    xCodeVersion: string
  ): Promise<string>;
};
