import React from 'react';
import { SupportLink } from '../SupportLink';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { useApp } from '@backstage/core-plugin-api';
import { SupportPageTitle } from '../SupportPageTitle';
import { useSupportStyles } from '../support.styles';

export const BaseplateSupport = () => {
  const classes = useSupportStyles();
  const app = useApp();
  const BugIconComponent = app.getSystemIcon('bug');

  return (
    <div>
      <SupportPageTitle title="Get help with Baseplate" subtitle="Baseplate" />
      <div className={classes.linkGrid}>
        <SupportLink
          title="Open Teams to chat with the team"
          href="https://teams.microsoft.com/l/channel/19%3A157d74ce5bc74251b992283e7f0abbc1%40thread.skype/Baseplate%20(Developer%20Portal)?groupId=ecf9587b-7f13-48da-85fa-d6e62472a771&tenantId=1d063515-6cad-4195-9486-ea65df456faa"
          icon={<ChatBubbleOutlineIcon />}
          description="If you encounter an issue that significantly disrupts your work, please don't hesitate to contact us for support."
        />
        <SupportLink
          title="Report a Bug"
          href="https://github.com/LEGO/baseplate/issues"
          icon={BugIconComponent && <BugIconComponent />}
          description="Help us maintain the platform's stability by reporting any bugs, glitches, or unexpected behaviors you encounter during use."
        />
      </div>
    </div>
  );
};
