import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ChartPieSimple(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M34.938 19H21V5.062C21 4.5 21.438 4 22 4c7.688 0 14 6.313 14 14 0 .563-.5 1-1.063 1ZM15 22V9.687C10.312 11.376 7 15.814 7 21c0 6.625 5.375 12 12 12 5.188 0 9.625-3.313 11.313-8H18c-1.688 0-3-1.313-3-3Zm3-13.813V22h13.813c1.125 0 2.062 1 1.812 2.125C32.187 30.938 26.187 36 19 36c-8.313 0-15-6.688-15-15C4 13.812 9.063 7.812 15.875 6.375 17 6.125 18 7.063 18 8.188Z" />
    </SvgIcon>
  );
}
