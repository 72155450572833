import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function BellLock(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.714 7.143V5.857C27.714 3.741 29.857 2 32 2c2.116 0 4.286 1.741 4.286 3.857v1.286c.937 0 1.714.777 1.714 1.714V14c0 .964-.777 1.714-1.714 1.714h-8.572C26.75 15.714 26 14.964 26 14V8.857c0-.937.75-1.714 1.714-1.714Zm2.693-.682v.711h3.106v-.711c0-.774-.413-1.539-1.513-1.539-1.125 0-1.593.765-1.593 1.539Z"
      />
      <path d="M32.719 26.438a12.74 12.74 0 0 1-2.588-6.23c-.109-.863-.53-1.494-1.507-1.494-1.358 0-1.57.948-1.516 1.494A15.079 15.079 0 0 0 29.468 27H10.532c1.625-2.5 2.5-5.375 2.5-8.375V16.5c0-3.563 2.875-6.5 6.5-6.5h1c.422.023.693.054 1.002.103.514.08 1.185-.124 1.528-1.203.273-.858-.533-1.664-1.03-1.775V6c0-1.063-.937-2-2-2-1.125 0-2 .938-2 2v1.125c-4.562.75-8 4.688-8 9.375v2.125c0 2.813-1 5.563-2.75 7.813l-.937 1.125c-.375.5-.438 1.125-.188 1.625s.75.812 1.375.812h25c.563 0 1.063-.313 1.313-.813.25-.5.187-1.125-.188-1.625l-.937-1.125Zm-9.875 8.437A4.122 4.122 0 0 0 24.03 32h-8c0 1.063.375 2.125 1.125 2.875S18.97 36 20.031 36c1.063 0 2.063-.375 2.813-1.125Z" />
    </SvgIcon>
  );
}
