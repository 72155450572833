import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ArrowsMaximize(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M24.5 6h8c.813 0 1.5.688 1.5 1.5v8c0 .875-.688 1.5-1.5 1.5-.875 0-1.5-.625-1.5-1.5v-4.375L22.062 20 31 28.938V24.5c0-.813.625-1.5 1.5-1.5.813 0 1.5.688 1.5 1.5v8c0 .875-.688 1.5-1.5 1.5h-8c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5h4.375L20 22.125 11.062 31H15.5c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5h-8c-.875 0-1.5-.625-1.5-1.5v-8c0-.813.625-1.5 1.5-1.5.813 0 1.5.688 1.5 1.5v4.438L17.875 20 9 11.125V15.5c0 .875-.688 1.5-1.5 1.5-.875 0-1.5-.625-1.5-1.5v-8C6 6.687 6.625 6 7.5 6h8c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5h-4.438L20 17.938 28.875 9H24.5c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5Z" />
    </SvgIcon>
  );
}
