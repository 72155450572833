import React from 'react';
import { Typography } from '@material-ui/core';
import { baseplateStyles } from '@lego/plugin-baseplate-core-components';

const useStyles = baseplateStyles(() => ({
  searchResultContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  searchResultIcon: {
    marginRight: 10,
  },
}));

export const DefaultSearchResultListItem = ({ result }: { result: any }) => {
  const classes = useStyles();
  return (
    <div className={classes.searchResultContainer}>
      <div>
        <Typography variant="body1">{result.document.title}</Typography>
        <Typography variant="caption">
          {result.document.kind.toUpperCase()} • {result.type}
        </Typography>
      </div>
    </div>
  );
};
