import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ThumbsUp(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M24.188 6.15c2.437.688 3.812 3.188 3.125 5.563l-.375 1.25a10.968 10.968 0 0 1-.75 2H32c2.188 0 4 1.813 4 4 0 1.125-.5 2.125-1.188 2.875.126.375.188.75.188 1.188 0 1.25-.625 2.437-1.625 3.187.063.25.125.5.125.813 0 1.25-.625 2.375-1.5 3.125-.125 2.125-1.875 3.875-4 3.875h-6.125A7.076 7.076 0 0 1 18 32.838l-3.875-2.562c-.688-.5-.875-1.438-.375-2.125a1.435 1.435 0 0 1 2.063-.375l3.874 2.562c.625.438 1.375.625 2.188.625H28c.5 0 1-.437 1-1 0-.062-.063-.187-.063-.25-.187-.75.188-1.5.875-1.812.375-.125.688-.5.688-.938 0-.187-.125-.437-.25-.562-.313-.438-.375-1-.188-1.5.125-.5.563-.813 1.125-.938.438-.062.813-.5.813-1 0-.25-.125-.437-.25-.625a1.41 1.41 0 0 1-.375-1.375c.125-.5.5-.875.938-1.062.374-.125.687-.5.687-.938 0-.5-.5-1-1-1h-8.375a1.458 1.458 0 0 1-1.313-.812c-.25-.5-.25-1.063.063-1.5a12.083 12.083 0 0 0 1.688-3.5l.375-1.25c.187-.813-.25-1.625-1.063-1.875-.813-.188-1.625.25-1.875 1.062l-.313 1.25a9.001 9.001 0 0 1-1.875 3.375l-3.25 3.5c-.562.625-1.5.688-2.125.125a1.549 1.549 0 0 1-.062-2.125l3.188-3.5c.562-.625 1-1.375 1.25-2.187l.312-1.25c.688-2.438 3.188-3.813 5.563-3.125ZM6 15.964h4c1.063 0 2 .938 2 2v14c0 1.125-.938 2-2 2H6c-1.125 0-2-.875-2-2v-14c0-1.062.875-2 2-2Z" />
    </SvgIcon>
  );
}
