/*
 * Copyright 2022 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { createApiRef } from '@backstage/core-plugin-api';
import {
  DataProductStorageCredential,
  StorageCredentialCreateRequest,
  StorageCredentialUpdateRequest,
} from './types';
import { UpdatePermissionsRequest, PermissionsResponse } from '../permissions';
import { DataProductExternalLocation } from '../externalLocations';

export const StorageCredentialsApiRef = createApiRef<StorageCredentialsApi>({
  id: 'api.plugin.nexus.databricks.storage.credentials',
});

export const StorageCredentialsApiMockRef = createApiRef<StorageCredentialsApi>(
  {
    id: 'api.plugin.nexus.databricks.storage.credentials.mock',
  },
);

export interface StorageCredentialsApi {
  list(dataProduct?: string): Promise<DataProductStorageCredential[]>;

  create(
    data_product: string,
    storageCredentialCreateRequest: StorageCredentialCreateRequest,
  ): Promise<DataProductStorageCredential>;

  update(
    data_product: string,
    name: string,
    storageCredentialUpdateRequest: Partial<StorageCredentialUpdateRequest>,
  ): Promise<DataProductStorageCredential>;

  delete(data_product: string, name: string): Promise<object>;

  getPermissions(
    dataProduct: string,
    storageCredentialName: string,
  ): Promise<PermissionsResponse>;

  updatePermissions(
    data_product: string,
    name: string,
    updatePermissionsRequest: Partial<UpdatePermissionsRequest>,
  ): Promise<PermissionsResponse>;

  getUsages(name: string): Promise<DataProductExternalLocation[]>;
}
