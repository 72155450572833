export const NEW_BASEPLATE_THEME_ID = 'new-baseplate-theme';

export const colors = {
  blue: {
    50: '#E7F4FDFF',
    75: '#DBEEFBFF',
    100: '#D1E8F9FF',
    200: '#BBDEF6FF',
    300: '#A0D2F2FF',
    400: '#87C6EDFF',
    500: '#6EB9E7FF',
    600: '#56ABE1FF',
    700: '#3F9CDAFF',
    800: '#298BD1FF',
    900: '#1579C4FF',
    1000: '#0067B2FF',
    1100: '#005595FF',
    1200: '#004370FF',
    1300: '#092D49FF',
  },
  slate: {
    50: '#F1F1F4FF',
    75: '#EAEBF0FF',
    100: '#E4E5ECFF',
    200: '#D7D9E1FF',
    300: '#CACCD5FF',
    400: '#BDBEC9FF',
    500: '#AFB1BDFF',
    600: '#A1A3B1FF',
    700: '#9394A3FF',
    800: '#848595FF',
    900: '#737587FF',
    1000: '#636577FF',
    1100: '#525466FF',
    1200: '#404152FF',
    1300: '#2A2B37FF',
  },
  red: {
    50: '#FCEEEDFF',
    75: '#FFE6E5FF',
    100: '#FFDDDCFF',
    200: '#FFCDCBFF',
    300: '#FFBBB9FF',
    400: '#FDA8A6FF',
    500: '#FA9594FF',
    600: '#F58081FF',
    700: '#F0686BFF',
    800: '#ED464AFF',
    900: '#E2222FFF',
    1000: '#C3061BFF',
    1100: '#A10D1DFF',
    1200: '#7C131BFF',
    1300: '#501414FF',
  },
  green: {
    50: '#E9F7E9FF',
    75: '#DBF1DAFF',
    100: '#D0EDCFFF',
    200: '#B6E4B8FF',
    300: '#9DDBA3FF',
    400: '#80D08BFF',
    500: '#61C574FF',
    600: '#3CB95EFF',
    700: '#00AA47FF',
    800: '#009944FF',
    900: '#008744FF',
    1000: '#00743FFF',
    1100: '#006037FF',
    1200: '#004B2EFF',
    1300: '#0A3220FF',
  },
  yellow: {
    50: '#FFF5ADFF',
    75: '#FFEE7AFF',
    100: '#FFE633FF',
    200: '#FFD502FF',
    300: '#FFC20EFF',
    400: '#FFAE18FF',
    500: '#EFA118FF',
    600: '#DD9417FF',
    700: '#C98715FF',
    800: '#B67913FF',
    900: '#A16B11FF',
    1000: '#8B5C0FFF',
    1100: '#734C0CFF',
    1200: '#5A3B0AFF',
    1300: '#3B2706FF',
  },
};

export const dimensions = {
  '10': 1,
  '25': 2,
  '50': 4,
  '100': 8,
  '150': 12,
  '200': 16,
  '225': 18,
  '300': 24,
  '400': 32,
  '500': 40,
  '600': 48,
  '700': 56,
  '800': 64,
  '900': 72,
  '1000': 80,
  '1200': 96,
  '1400': 112,
  '1600': 128,
};

export const backgrounds = {
  error: colors.red[1300],
  info: colors.blue[1300],
  neutral: {
    '1': '#24242E',
    '2': '#1B1B22',
    '3': '#121217',
  },
  success: colors.green[1300],
  warning: colors.yellow[1200],
};

export const radius = {
  xs: '2px',
  sm: '4px',
  default: '8px',
  lg: '12px',
  full: '9999px',
};

export const borders = {
  default: colors.slate[1200],
  error: colors.red[1100],
  hover: colors.slate[1100],
  info: colors.blue[1000],
  success: colors.green[1000],
  warning: colors.yellow[1000],
};

export const icons = {
  default: '24px',
  primary: colors.slate[1200],
  secondary: colors.slate[1000],
  sm: '16px',
  xs: '12px',
};

export const interactive = {
  default: colors.blue[1000],
  hover: colors.blue[1100],
};

export const tag = {
  blue: backgrounds.info,
};

export const text = {
  error: colors.red[75],
  info: colors.blue[50],
  primary: colors.slate[1300],
  secondary: colors.slate[1000],
  success: colors.green[75],
  tertiary: colors.slate[700],
  warning: colors.yellow[50],
};
