import React from 'react';
import { OpenPositionProvider } from '../../hooks';
import { OpenPositionTable } from '../../features/OpenPositions/OpenPositionTable/OpePositionTable';

export function OpenPositionsPage() {
  return (
    <OpenPositionProvider>
      <OpenPositionTable />
    </OpenPositionProvider>
  );
}
