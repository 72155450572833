import { ProfileInfo } from '@backstage/core-plugin-api';

export const BASEPLATE_TEAMS_FEEDBACK_WEBHOOK =
  'https://legogroup.webhook.office.com/webhookb2/97992861-3229-436b-9e0a-1cf1c6ab3268@1d063515-6cad-4195-9486-ea65df456faa/IncomingWebhook/feb75a64578f40e2b0bf24511b9f561d/55fa7cc7-c91c-494d-bfaf-d6f108b956d3'; // FiveStarRatings private teams channel in Baseplate General -> Connectors -> Incoming Webhook
export const TEAMS_WEBHOOK_BASE_URL = 'https://legogroup.webhook.office.com';
export const ONE_MONTH_IN_MILLISECONDS = 30 * 24 * 60 * 60 * 1000; // 1 month in milliseconds

type TeamsBodyType = {
  user: ProfileInfo;
  environment: string;
  trackingIdentifier: string;
  feedbackSubject: string;
  feedbackRating: number;
  feedback: string;
  webhook: string;
  isCarbonCopy: boolean;
};

export const getTeamsPostBody = ({
  user,
  environment,
  trackingIdentifier,
  feedbackSubject,
  feedbackRating: rating,
  feedback,
  webhook,
  isCarbonCopy,
}: TeamsBodyType) => {
  const currentUrl = window.location.href;

  const body = {
    type: 'message',
    attachments: [
      {
        contentType: 'application/vnd.microsoft.card.adaptive',
        contentUrl: null,
        content: {
          $schema: 'http://adaptivecards.io/schemas/adaptive-card.json',
          type: 'AdaptiveCard',
          body: [
            {
              type: 'ColumnSet',
              columns: [
                {
                  type: 'Column',
                  items: [
                    {
                      type: 'Image',
                      style: 'Person',
                      url: user?.picture,
                      size: 'Small',
                    },
                  ],
                  width: 'auto',
                },
                {
                  type: 'Column',
                  items: [
                    {
                      type: 'TextBlock',
                      weight: 'Bolder',
                      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                      text: `${user?.displayName} gave feedback on Baseplate (${environment})`,
                      wrap: true,
                    },
                  ],
                  width: 'stretch',
                },
              ],
            },
            {
              type: 'FactSet',
              facts: [
                {
                  title:
                    'Is this Feedback a carbon copy, thus to be handled by another webhook Teams channel?: ',
                  value: isCarbonCopy
                    ? `Yes, to be handled by webhook (${webhook})`
                    : 'No',
                },
                {
                  title: 'Environment: ',
                  value: environment,
                },
                {
                  title: 'Reporter: ',
                  value: user?.displayName,
                },
                {
                  title: 'Origin: ',
                  value: currentUrl,
                },
                {
                  title: 'Tracking Identifier: ',
                  value: trackingIdentifier,
                },
                {
                  title: 'Feedback subject: ',
                  value: `${feedbackSubject}`,
                },
                {
                  title: '5 star Rating(1-5): ',
                  value: `${rating}`,
                },
              ],
            },
            {
              type: 'TextBlock',
              text: feedback,
              wrap: true,
            },
          ],
          version: '1.4',
          actions: [],
          msteams: {
            width: 'Full',
          },
        },
      },
    ],
  };
  return JSON.stringify(body);
};
