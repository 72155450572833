import {
  Reingestion,
  ReingestionProps,
} from '@lego/plugin-baseplate-core-components';
import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { EntitySearchBar } from '../entity-search';

interface Props {
  pickers?: React.ReactNode[];
  searchPlaceholder?: string;
  reingestion?: ReingestionProps;
}

const useStyles = makeStyles(() => ({
  picker: {
    width: 150,
  },
}));

export const FilterRowLayout = ({
  pickers,
  searchPlaceholder,
  reingestion,
}: Props) => {
  const classes = useStyles();

  return (
    <Grid container alignItems="center" style={{ padding: 0 }}>
      <Grid item xs={12} sm={12} md={9}>
        <EntitySearchBar placeholder={searchPlaceholder ?? 'Search'} />
      </Grid>
      <Grid item sm={12} md={3} container style={{ padding: 0 }}>
        <Grid
          container
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
          }}
        >
          {pickers?.map((picker, index) => (
            <Grid item key={index} className={classes.picker}>
              {picker}
            </Grid>
          ))}
          {reingestion && (
            <Grid item>
              <Reingestion {...reingestion} />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
