import { Link } from '@backstage/core-components';
import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { UserSettingsThemeToggle } from '@backstage/plugin-user-settings';
import { useCurrentBaseplateVersion } from '@lego/plugin-baseplate-admin';
import { adminPageReadPermission } from '@lego/plugin-baseplate-admin-common';
import { Button } from '@lego/plugin-baseplate-core-components';
import {
  ClickAwayListener,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  makeStyles,
} from '@material-ui/core';
import WrenchIcon from '@material-ui/icons/BuildOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import React from 'react';
import { useNavigate } from 'react-router';
import { BaseplateTheme } from '../../style/types';
import { AccountMenuButton } from './AccountMenuButton';
import { DevModeSwitch } from './DevModeSwitch';

const useStyles = makeStyles<BaseplateTheme>(theme => ({
  menuWrapper: {
    zIndex: 1200,
    right: 0,
    width: 'fit-content',
  },
  menuItem: {
    color: theme.semantic.text.primary,
    padding: '0.5rem 1rem',
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    fontSize: '1rem',
  },
  menu: {},
}));

export const AccountMenu = () => {
  const classes = useStyles();
  const identityApi = useApi(identityApiRef);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const navigate = useNavigate();
  const { loading, error, value } = useCurrentBaseplateVersion();

  const handleMenuLinkClick = (to: string) => {
    setOpen(false);
    navigate(to);
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (!anchorRef.current) return;
    if (anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current?.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <IconButton ref={anchorRef} aria-haspopup="true" onClick={handleToggle}>
        <AccountMenuButton />
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        className={classes.menuWrapper}
      >
        <Paper>
          <ClickAwayListener onClickAway={handleClose}>
            <MenuList
              autoFocusItem={open}
              id="menu-list-grow"
              onKeyDown={handleListKeyDown}
              className={classes.menu}
            >
              <MenuItem
                onClick={() => handleMenuLinkClick('/settings')}
                className={classes.menuItem}
              >
                <SettingsIcon />
                <span>Settings</span>
              </MenuItem>
              <RequirePermission
                permission={adminPageReadPermission}
                errorPage={<></>}
              >
                <MenuItem
                  onClick={() => handleMenuLinkClick('/admin')}
                  className={classes.menuItem}
                >
                  <WrenchIcon />
                  <span>Admin</span>
                </MenuItem>
                {value && (
                  <Link
                    target="_blank"
                    to={`https://github.com/LEGO/baseplate/releases/tag/v${value}`}
                  >
                    <MenuItem className={classes.menuItem}>
                      <span>Version: {value}</span>
                    </MenuItem>
                  </Link>
                )}
              </RequirePermission>
              <Divider />
              <UserSettingsThemeToggle />

              <Grid
                container
                justifyContent="space-between"
                style={{ padding: 0, paddingRight: '1rem' }}
              >
                <Grid item>
                  <DevModeSwitch />
                </Grid>
                <Grid item>
                  <Button
                    onClick={() => void identityApi.signOut()}
                    variant="destructive"
                  >
                    Sign Out
                  </Button>
                  <Grid />
                </Grid>
              </Grid>
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </>
  );
};
