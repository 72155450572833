import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  fetchApiRef,
} from '@backstage/core-plugin-api';
import { AiApiRef, AiClient } from './api';
import { rootRouteRef } from './routes';

export const langChainPlugin = createPlugin({
  id: 'ai',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: AiApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        fetchApi: fetchApiRef,
      },
      factory: ({ discoveryApi, fetchApi }) =>
        new AiClient({ discoveryApi, fetchApi }),
    }),
  ],
});

export const LangChainPage: () => JSX.Element = langChainPlugin.provide(
  createRoutableExtension({
    name: 'LangChainPage',
    component: () =>
      import('./components/LangChainComponent').then(
        m => m.LangChainPageComponent,
      ),
    mountPoint: rootRouteRef,
  }),
);

export { LangChainChat } from './components/LangChainComponent';
export { AiHelper } from './components/AiHelper';
export { AiEntityHelper } from './components/AiEntityHelper';
