import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  fetchApiRef,
} from '@backstage/core-plugin-api';
import { AnalyticsClient } from './api';
import { AnalyticsApiApiRef } from './api/AnalyticsApi';
import { rootRouteRef } from './routes';

export const analyticsPlugin = createPlugin({
  id: 'analytics',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: AnalyticsApiApiRef,
      deps: {
        fetchApi: fetchApiRef,
        discoveryApi: discoveryApiRef,
      },
      factory: ({ fetchApi, discoveryApi }) =>
        new AnalyticsClient({
          discoveryApi,
          fetchApi,
        }),
    }),
  ],
});

export const DocsAnalyticsPage: () => JSX.Element = analyticsPlugin.provide(
  createRoutableExtension({
    name: 'DocsAnalyticsPage',
    component: () =>
      import('./components/DocsAnalyticsPage').then(m => m.DocsAnalyticsPage),
    mountPoint: rootRouteRef,
  }),
);

export * from './BaseplateAnalytics';
export * from './client-side-analytics';
export * from './components';
export * from './api';
