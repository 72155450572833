import { EntityKindFilter } from '../types';

export type SearchScope =
  | 'API'
  | 'Product'
  | 'Application'
  | 'Documentation'
  | 'User'
  | 'Tool'
  | 'Global';

export const allSearchFilters: EntityKindFilter[] = [
  { name: 'Product', title: 'Products', href: '/products' },
  { name: 'API', title: 'APIs', href: '/api-docs' },
  { name: 'Documentation', title: 'Documentation', href: '/docs' },
  { name: 'Tool', title: 'Tools', href: '/tools' },
  {
    name: 'User',
    title: 'People',
    href: '/catalog?filters%5Bkind%5D=user&filters%5Buser%5D=all',
  },
  {
    name: 'Application',
    title: 'Applications',
    href: '/catalog?filters%5Bkind%5D=application&filters%5Buser%5D=all',
  },
];

export const getSearchScope = (kind?: SearchScope) => {
  if (!kind) {
    return undefined;
  }
  if (kind === 'Global') {
    return { name: 'global', title: 'Everything', href: '' };
  }
  return allSearchFilters.find(filter => filter.name === kind);
};
