import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function FilePlus(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M11 32c0 .563.438 1 1 1h16c.5 0 1-.438 1-1V14h-5c-1.125 0-2-.875-2-2V7H12c-.563 0-1 .5-1 1v24Zm1-28h10.313c1.062 0 2.062.438 2.812 1.188l5.688 5.687c.75.75 1.187 1.75 1.187 2.813V32c0 2.25-1.813 4-4 4H12c-2.25 0-4-1.75-4-4V8c0-2.188 1.75-4 4-4Zm8 13c.813 0 1.5.688 1.5 1.5v3h3c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5h-3v3c0 .875-.688 1.5-1.5 1.5-.875 0-1.5-.625-1.5-1.5v-3h-3c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5h3v-3c0-.813.625-1.5 1.5-1.5Z" />
    </SvgIcon>
  );
}
