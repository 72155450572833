import { SidePanel } from '@lego/plugin-baseplate-core-components';
import { OpenPosition } from '@lego/plugin-baseplate-people-to-product-common';
import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import OpenPositionProductTable from '../OpenPositionProductTable/OpenPositionProductTable';

interface OpenPositionSidePanelProps {
  openPosition: OpenPosition;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

function OpenPositionSidePanel({
  openPosition,
  isOpen,
  setIsOpen,
}: OpenPositionSidePanelProps) {
  return (
    <SidePanel
      title={openPosition.position}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <Typography variant="h6">User actions</Typography>
          <ul>
            <Typography component="li">
              Click on the add button to add a product.
            </Typography>
            <Typography component="li">
              Click on the remove button to remove a product.
            </Typography>
          </ul>
        </Grid>
        <Grid item>
          <OpenPositionProductTable openPositionId={openPosition.positionId} />
        </Grid>
      </Grid>
    </SidePanel>
  );
}

export default OpenPositionSidePanel;
