import { rootRouteRef } from './routes';
import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

export const vmManagementPlugin = createPlugin({
  id: 'vm-management-form',
  routes: {
    root: rootRouteRef,
  },
});

export const VmCreationPage = vmManagementPlugin.provide(
  createRoutableExtension({
    name: 'VmCreationPage',
    component: () =>
      import('./components/creation/VmCreationPage/VmCreationPage').then(
        m => m.VmCreationPage,
      ),
    mountPoint: rootRouteRef,
  }),
);

export const DecommissionPage = vmManagementPlugin.provide(
  createRoutableExtension({
    name: 'DecommissionOpenPage',
    component: () =>
      import(
        './components/decommission/DecommissionOpenPage/DecommissionOpenPage'
      ).then(m => m.DecommissionOpenPage),
    mountPoint: rootRouteRef,
  }),
);

export const ExamplePage = vmManagementPlugin.provide(
  createRoutableExtension({
    name: 'VmCreationPage',
    component: () =>
      import('./components/creation/ExamplePage/ExamplePage').then(
        m => m.ExamplePage,
      ),
    mountPoint: rootRouteRef,
  }),
);
export const CreationOpenPage = vmManagementPlugin.provide(
  createRoutableExtension({
    name: 'CreationOpenPage',
    component: () =>
      import('./components/creation/CreationOpenPage/CreationOpenPage').then(
        m => m.CreationOpenPage,
      ),
    mountPoint: rootRouteRef,
  }),
);
