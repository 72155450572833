import { Entity, stringifyEntityRef } from '@backstage/catalog-model';
import { useRelatedEntities } from '@backstage/plugin-catalog-react';
import { isTechDocsAvailable } from '@backstage/plugin-techdocs';
import { Button } from '@lego/plugin-baseplate-core-components';
import { Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { EntityLink } from '../entity-link';
import EmptyProductRelationsTree from './EmptyProductRelationsTree';
import { useTreeViewStyles } from './ProductRelationsTree.styles';

type Props = {
  product: Entity;
  showProduct?: boolean;
};

export const ProductRelationsTree = ({ product, showProduct }: Props) => {
  const classes = useTreeViewStyles();
  const { entities, error, loading } = useRelatedEntities(product, {
    type: 'ownerOf',
  });

  const [showAll, setShowAll] = useState<{ [kind: string]: boolean }>({});

  const handleShowAllClick = (kind: string) => {
    setShowAll(prevShowAll => ({ ...prevShowAll, [kind]: !prevShowAll[kind] }));
  };

  if (error) return null;

  if (!entities || entities.length === 0) {
    return <EmptyProductRelationsTree {...product} />;
  }

  const filteredItems = entities.filter(e => {
    if (
      e.kind === 'Component' &&
      !(isTechDocsAvailable(e) || (e.spec && e.spec.type === 'external-link'))
    ) {
      return false;
    }
    return true;
  });

  const groupedItems = filteredItems.reduce(
    (
      acc: {
        [kind: string]: Entity[];
      },
      item,
    ) => {
      const kind = item.kind;
      if (!acc[kind]) {
        acc[kind] = [];
      }
      acc[kind].push(item);
      return acc;
    },
    {},
  );

  if (!product) return null;

  if (loading) return <div>Loading...</div>;

  const CategoryLabel = ({ label }: { label: string }) => {
    return (
      <Typography
        component="div"
        color="secondary"
        variant="overline"
        className={classes.categoryLabel}
      >
        {label}
      </Typography>
    );
  };

  return (
    <div className={classes.container}>
      {showProduct && <EntityLink entity={product} variant="link" />}
      <ul className={classes.rootLevel}>
        {Object.keys(groupedItems).map((kind, index) => {
          const length = groupedItems[kind].length;
          const items = showAll[kind]
            ? groupedItems[kind]
            : groupedItems[kind].slice(0, 5);
          const isLastCategory = Object.keys(groupedItems).length - 1 === index;

          return (
            <li key={index}>
              <CategoryLabel
                label={
                  kind === 'Component'
                    ? `Documentation (${length})`
                    : `${kind}s (${length})`
                }
              />
              <ul
                className={[
                  classes.level1,
                  isLastCategory && classes.last,
                ].join(' ')}
              >
                {items.map((entity, i) => {
                  const isLastIIndex = length - 1 === i;
                  return (
                    <li key={i}>
                      <div
                        className={[
                          classes.item,
                          isLastIIndex && classes.last,
                        ].join(' ')}
                      >
                        <EntityLink
                          entityRef={stringifyEntityRef(entity)}
                          isTechdocs={kind === 'Component'}
                          variant="link"
                        />
                      </div>
                    </li>
                  );
                })}
                {length > 5 && (
                  <Button
                    variant="text"
                    size="small"
                    style={{ marginLeft: '20px' }}
                    onClick={() => handleShowAllClick(kind)}
                  >
                    {showAll[kind] ? '- Hide' : `+ Show all ${length} ${kind}s`}
                  </Button>
                )}
              </ul>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
