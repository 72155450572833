import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Text(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M9 9v3c0 .875-.688 1.5-1.5 1.5-.875 0-1.5-.625-1.5-1.5V8.5C6 7.125 7.063 6 8.5 6h23C32.875 6 34 7.125 34 8.5V12c0 .875-.688 1.5-1.5 1.5-.875 0-1.5-.625-1.5-1.5V9h-9.5v22h3c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5h-9c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5h3V9H9Z" />
    </SvgIcon>
  );
}
