export type UserSubscriptionDetails = {
  githubHandle: string;
  statusMessage: StatusMessage;
};

export type SubscriptionResponse = {
  seatsCreated: number;
  statusMessage: AddOrRemovalStatusMessage;
};

export enum AddOrRemovalStatusMessage {
  USER_ADDED = 'USER_ADDED',
  USER_REMOVED = 'USER_REMOVED',
  NO_SEAT_FOUND_FOR_USER = 'NO_SEAT_FOUND_FOR_USER',
  NOT_IN_ORGANIZATION = 'NOT_IN_ORGANIZATION',
  FAILED = 'FAILED',
}

export enum StatusMessage {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  NOT_IN_ORGANISATION = 'NOT_IN_ORGANISATION',
  ERROR = 'ERROR',
}
