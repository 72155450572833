import { useSearchParams } from 'react-router-dom';

export function useReportSearchParams() {
  const [searchParams, setSearchParams] = useSearchParams();
  const productsParams = searchParams.get('products');
  const directReportsParams = searchParams.get('directReports');
  const externalParams = searchParams.get('external');

  let products: boolean | string[] | null = null;
  let reportsType: 'direct' | 'aggregated' | null = 'direct';
  let external: boolean | null = null;

  if (directReportsParams !== null) {
    if (directReportsParams === 'aggregated') {
      reportsType = 'aggregated';
    } else if (directReportsParams === 'direct') {
      reportsType = 'direct';
    }
  }

  if (productsParams !== null) {
    if (productsParams === 'true' || productsParams === 'false') {
      products = productsParams === 'true';
    } else {
      products = productsParams.split(',');
    }
  }

  if (externalParams !== null) {
    if (externalParams === 'true' || externalParams === 'false') {
      external = externalParams === 'true';
    }
  }

  return {
    setSearchParams,
    products,
    external,
    searchParams,
    reportsType,
  };
}
