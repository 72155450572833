import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';
import { Button, Input } from '@lego/plugin-baseplate-core-components';
import {
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import React, { useEffect, useState } from 'react';
import { MarkdownContent } from '@backstage/core-components';

import { MultiStringFieldExtensionProps } from './schema';

export const MultiStringField = ({
  onChange,
  rawErrors,
  required,
  formData,
  uiSchema,
  schema: { title, description },
}: MultiStringFieldExtensionProps) => {
  const [entries, setEntries] = useState<string[]>(formData || []);
  const fieldName = uiSchema['ui:options']?.fieldName as string;
  const sampleValue = uiSchema['ui:options']?.sampleValue as string;
  const maxItems = uiSchema['ui:options']?.maxItems as number;

  useEffect(() => {
    if (entries && entries.length > 0) {
      onChange(entries);
    } else {
      onChange(undefined);
    }
  }, [entries, onChange]);

  const handleUpdate = (index: number, newValue: string) => {
    const newEntries = (entries ?? []).map((entry, i) =>
      i === index ? newValue : entry,
    );
    setEntries(newEntries);
  };

  const handleAdd = () => {
    setEntries([...(entries ?? []), '']);
  };

  const handleRemove = (index: number) => {
    const newEntries = (entries ?? []).filter((_, i) => i !== index);
    setEntries(newEntries);
  };

  return (
    <FormControl required={required} error={rawErrors?.length > 0 && !formData}>
      <Typography variant="h6">{title}</Typography>
      {entries &&
        !!entries.length &&
        entries.map((entry, index) => (
          <Grid container key={index} style={{ padding: 1 }}>
            <Grid item xs={10}>
              <Input
                label={fieldName}
                helperText={sampleValue}
                id={index.toString()}
                value={entry}
                onChange={e => handleUpdate(index, e.target.value)}
                required={required}
              />
            </Grid>
            <Grid item xs={1} style={{ display: 'flex' }}>
              <IconButton onClick={() => handleRemove(index)}>
                <RemoveCircleOutlineIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}
      <br />
      <br />
      <Button
        onClick={handleAdd}
        startIcon={<AddCircleOutlineIcon />}
        fullWidth
        variant="secondary"
        disabled={maxItems && entries.length >= maxItems ? true : undefined}
      >
        Add an {fieldName}
      </Button>
      <FormHelperText style={{ padding: '0 1rem' }}>
        <Typography variant="caption">
          <MarkdownContent content={description as string} dialect="gfm" />
        </Typography>
      </FormHelperText>
    </FormControl>
  );
};
