import { FormLabel, Tooltip, Typography } from '@material-ui/core';
import { InfoOutlined } from '@mui/icons-material';
import React from 'react';
import { useFormLabelStyles } from './FormLabel.styles';

type LnDFormLabelProps = {
  styles?: any;
  startIcon?: any;
  label: string;
  tooltipLabel?: string;
  error?: boolean;
  subTitle?: string;
  required?: boolean;
  hasRequirementLabel?: boolean;
};
export const LnDFormLabel = ({
  styles,
  startIcon,
  label,
  tooltipLabel,
  error = false,
  subTitle,
  required = false,
  hasRequirementLabel = true,
}: LnDFormLabelProps) => {
  const formStyles = useFormLabelStyles();
  return (
    <div
      className={`${styles && styles} ${!subTitle ? 'bottomPad5' : ''} ${
        formStyles.label
      } displayFlexRow`}
    >
      {startIcon && startIcon}
      <div className="displayFlexColumn">
        <div className="displayFlexRow">
          <FormLabel component="label" error={error}>
            {label}{' '}
          </FormLabel>
          {!required && hasRequirementLabel && (
            <Typography
              variant="caption"
              color="secondary"
              className={formStyles.optional}
            >
              (optional)
            </Typography>
          )}
          {tooltipLabel && (
            <Tooltip
              className={formStyles.tooltipInfo}
              arrow
              title={tooltipLabel}
            >
              <InfoOutlined className={formStyles.tooltipInfoIcon} />
            </Tooltip>
          )}{' '}
        </div>
        {subTitle && (
          <Typography variant="caption" color="secondary">
            {subTitle}
          </Typography>
        )}
      </div>
    </div>
  );
};
