import {
  Button,
  baseplateStyles,
} from '@lego/plugin-baseplate-core-components';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Box from '@mui/material/Box';
import React, { useState } from 'react';
import { useReport } from '../../hooks';
import EmployeeProductTableRow from '../EmployeeProductTableRow/EmployeeProductTableRow';
import GeneralOverheadProductsTable from '../GeneralOverheadProductTable/GeneralOverheadProductsTable';
import NewEmployeeProductTableRow from '../NewEmployeeProductTableRow/NewEmployeeProductTableRow';
import { productColumns } from './columns';

const useStyles = baseplateStyles(theme => ({
  tableHeader: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    borderTop: `1px solid ${theme.semantic.border.default}`,
    borderBottom: `1px solid ${theme.semantic.border.default}`,
  },
}));

interface EmployeeProductTableProps {
  employeeId: number;
}

function EmployeeProductTable({ employeeId }: EmployeeProductTableProps) {
  const { reports } = useReport();
  const [isAddingProduct, setIsAddingProduct] = useState<boolean>(false);
  const [isEditingProduct, setIsEditingProduct] = useState<boolean>(false);
  const [editingProductId, setEditingProductId] = useState<string | null>(null);

  const classes = useStyles();

  const selectedEmployee = reports.find(e => e.id === employeeId);
  const productConnections = selectedEmployee?.products || [];

  const handleOnAddProductButtonClick = () => {
    setIsAddingProduct(true);
  };

  return (
    <>
      {selectedEmployee && (
        <>
          <Paper>
            <Toolbar>
              <Typography variant="h5">Products</Typography>
              <Box flexGrow={1} />
              <Button
                onClick={handleOnAddProductButtonClick}
                disabled={isAddingProduct}
                variant="primary"
              >
                <AddIcon />
                Add
              </Button>
            </Toolbar>
            <Table component={Paper}>
              <TableHead>
                <TableRow>
                  {productColumns.map((column, index) => (
                    <TableCell key={index} className={classes.tableHeader}>
                      {column.title}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {productConnections.map(productConnection => (
                  <EmployeeProductTableRow
                    rowData={productConnection}
                    employee={selectedEmployee}
                    disabled={
                      isAddingProduct ||
                      (isEditingProduct &&
                        editingProductId !== productConnection.node.id)
                    }
                    isEditing={
                      isEditingProduct &&
                      editingProductId === productConnection.node.id
                    }
                    setIsEditing={setIsEditingProduct}
                    setEditingProductId={setEditingProductId}
                  />
                ))}
                {isAddingProduct && (
                  <NewEmployeeProductTableRow
                    employee={selectedEmployee}
                    setIsAddingProduct={setIsAddingProduct}
                  />
                )}
              </TableBody>
            </Table>
          </Paper>
          <GeneralOverheadProductsTable employeeId={employeeId} />
        </>
      )}
    </>
  );
}

export default EmployeeProductTable;
