import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ArrowRightToBracket(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M17.563 29.063c-.625.625-1.563.625-2.125 0-.626-.563-.626-1.5 0-2.125l5.437-5.438H5.5c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5h15.375l-5.438-5.438c-.624-.562-.624-1.5 0-2.124.563-.563 1.5-.563 2.126 0l8 8.062c.562.563.562 1.5 0 2.063l-8 8ZM25.5 9c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5h5c3 0 5.5 2.5 5.5 5.5v17c0 3.063-2.5 5.5-5.5 5.5h-5c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5h5c1.375 0 2.5-1.063 2.5-2.5v-17c0-1.375-1.125-2.5-2.5-2.5h-5Z" />
    </SvgIcon>
  );
}
