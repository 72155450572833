import { baseplateStyles } from '@lego/plugin-baseplate-core-components';

export const useSupportStyles = baseplateStyles(theme => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '280px 1fr',
    position: 'absolute',
    width: 1200,
    maxWidth: '90%',
    height: '60%',
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.primitive.borderRadius.default,
    boxShadow: theme.shadows[5],
    top: '20%',
    left: '50%',
    transform: 'translateX(-50%)',
    transformOrigin: 'top center',
    overflow: 'auto',
  },
  content: { padding: '1.5rem' },
  linkGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '1rem',
    '@media (max-width: 900px)': {
      gridTemplateColumns: '1fr',
    },
  },
  titleWrapper: {
    marginBottom: '1rem',
  },
  sectionTitle: {
    marginTop: '1rem',
  },
}));
