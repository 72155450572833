import { GetEntityFacetsRequest } from '@backstage/catalog-client';
import { makeValidator } from '@backstage/catalog-model';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';
import { AutocompleteDropdown } from '@lego/plugin-baseplate-core-components';
import { FormControl } from '@material-ui/core';
import React, { useState } from 'react';
import useAsync from 'react-use/lib/useAsync';
import useEffectOnce from 'react-use/lib/useEffectOnce';

export const EntityTagsPicker = (
  props: FieldExtensionComponentProps<string[]>,
) => {
  const { formData, onChange, uiSchema } = props;

  const catalogApi = useApi(catalogApiRef);
  const [tagOptions, setTagOptions] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [inputError, setInputError] = useState(false);
  const tagValidator = (tag: string) => makeValidator().isValidTag(tag);
  const kinds = uiSchema['ui:options']?.kinds;
  const showCounts = uiSchema['ui:options']?.showCounts;
  const helperText = uiSchema['ui:options']?.helperText?.toString();
  const label = uiSchema['ui:options']?.label?.toString();

  const { loading } = useAsync(async () => {
    const facet = 'metadata.tags';
    const tagsRequest: GetEntityFacetsRequest = { facets: [facet] };

    if (!Array.isArray(kinds)) {
      throw new Error('Expected kinds to be an array in EntityTagsPicker');
    }
    if (kinds) {
      tagsRequest.filter = { kind: kinds };
    }

    const { facets } = await catalogApi.getEntityFacets(tagsRequest);

    const tagFacets = Object.fromEntries(
      facets[facet].map(({ value, count }) => [value, count]),
    );

    setTagOptions(
      Object.keys(tagFacets).sort((a, b) =>
        showCounts ? tagFacets[b] - tagFacets[a] : a.localeCompare(b),
      ),
    );

    return tagFacets;
  });

  const setValues = (
    _: React.ChangeEvent<object>,
    values: string[] | string | null,
  ) => {
    // Reset error state in case all tags were removed
    let hasError = false;
    let addDuplicate = false;
    const currentTags = formData || [];

    // If adding a new tag
    if (
      Array.isArray(values) &&
      values.length &&
      currentTags.length < values.length
    ) {
      const newValues = Array.isArray(values) ? values : [values];
      const newTag = (newValues[newValues.length - 1] = newValues[
        newValues.length - 1
      ]
        .toLocaleLowerCase('en-US')
        .trim());
      hasError = !tagValidator(newTag);
      addDuplicate = currentTags.indexOf(newTag) !== -1;
    }

    setInputError(hasError);
    setInputValue(!hasError ? '' : inputValue);

    if (!Array.isArray(values)) {
      throw new Error('Expected values to be an array in EntityTagsPicker');
    }

    if (!hasError && !addDuplicate) {
      onChange(values || []);
    }
  };

  // Initialize field to always return an array
  useEffectOnce(() => onChange(formData || []));

  return (
    <FormControl margin="none">
      <AutocompleteDropdown
        label={label ?? ''}
        multiple
        freeSolo
        filterSelectedOptions
        onChange={setValues}
        value={formData || []}
        inputValue={inputValue}
        loading={loading}
        error={inputError}
        options={tagOptions}
        helperText={helperText}
        ChipProps={{ size: 'small' }}
        setInputValue={setInputValue}
      />
    </FormControl>
  );
};
