import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Balloon(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M11 16c0 2.25 1.125 4.688 2.875 6.938s3.75 4 4.938 4.937c.5.375.875.875 1.187 1.375.25-.5.625-.938 1.125-1.375 1.188-.938 3.25-2.688 4.938-4.938C27.813 20.688 29 18.25 29 16c0-4.938-4.063-9-9-9-5 0-9 4.063-9 9Zm6.375 16.188c.375-.688.125-1.5-.438-1.938C14.376 28.187 8 22.437 8 16 8 9.375 13.375 4 20 4s12 5.375 12 12c0 6.438-6.438 12.188-9 14.25-.625.438-.813 1.25-.438 1.938l.375.812v.063l.188.374.75 1.438c.063.125.063.25.063.375 0 .438-.313.75-.813.75H16.75c-.438 0-.813-.313-.813-.75 0-.125.063-.25.126-.375l.687-1.438.188-.374L17 33l.375-.813ZM16 15.5c0 .875-.688 1.5-1.5 1.5-.875 0-1.5-.625-1.5-1.5 0-3.563 2.875-6.5 6.5-6.5.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5-1.938 0-3.5 1.625-3.5 3.5Z" />
    </SvgIcon>
  );
}
