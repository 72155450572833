import { parseEntityRef } from '@backstage/catalog-model';
import { SearchRequest } from '@elastic/elasticsearch/lib/api/types';
import { SearchParams } from '@lego/plugin-baseplate-analytics-common';

export const createQuery = (params: SearchParams): SearchRequest[] => {
  const { entityRef, startTime, endTime } = params;
  const { kind, name, namespace } = parseEntityRef(entityRef);

  const allDocsAnalyticsQuery: SearchRequest = {
    query: {
      bool: {
        must: [],
        filter: [
          {
            bool: {
              should: [
                {
                  term: {
                    'bp.event_action': {
                      value: 'navigate',
                    },
                  },
                },
              ],
              minimum_should_match: 1,
            },
          },
          {
            match: {
              'bp.fe_path': `/docs/${namespace}/${kind}/${name}`,
            },
          },
          {
            range: {
              '@timestamp': {
                format: 'strict_date_optional_time',
                gte: startTime,
                lte: endTime,
              },
            },
          },
        ],
        should: [],
        must_not: [],
      },
    },
    aggs: {
      unique_users: {
        cardinality: {
          field: 'bp.users_email',
        },
      },
      unique_visits: {
        value_count: {
          field: 'bp.users_email',
        },
      },
      total_visits_from_product: {
        terms: {
          field: 'bp.users_product_id',
        },
      },
      total_visits_from_subdomain: {
        terms: {
          field: 'bp.users_subdomain_title',
        },
      },
      total_visits_from_domain: {
        terms: {
          field: 'bp.users_domain_title',
        },
      },
      hits_over_time: {
        date_histogram: {
          field: '@timestamp',
          fixed_interval: '1d', // Adjust this based on your needs
        },
      },
      feedback_counts: {
        terms: {
          field: 'bp.attribute.feedback',
        },
      },
    },
  };

  const pageVisitsQuery: SearchRequest = {
    query: {
      bool: {
        must: [],
        filter: [
          {
            bool: {
              should: [
                {
                  term: {
                    'bp.event_action': {
                      value: 'navigate',
                    },
                  },
                },
              ],
              minimum_should_match: 1,
            },
          },
          {
            wildcard: {
              'bp.fe_path': {
                value: `/docs/${namespace}/${kind}/${name}/*`,
              },
            },
          },
          {
            range: {
              '@timestamp': {
                format: 'strict_date_optional_time',
                gte: startTime,
                lte: endTime,
              },
            },
          },
        ],
        should: [],
        must_not: [],
      },
    },
    aggs: {
      page_visits: {
        terms: {
          field: 'bp.fe_path',
          order: { unique_visitors: 'desc' },
          size: 1000,
        },
        aggs: {
          unique_visitors: {
            cardinality: {
              field: 'bp.users_email',
            },
          },
        },
      },
    },
  };

  return [
    {
      index: 'logs-*',
    },
    allDocsAnalyticsQuery,
    {
      index: 'logs-*',
    },
    pageVisitsQuery,
  ];
};
