import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Bluetooth(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M19.386 4.158c.5-.25 1.125-.187 1.562.188l8.5 7.5c.375.312.563.75.563 1.187a1.94 1.94 0 0 1-.563 1.125l-7.125 5.813 7.125 5.875c.313.25.5.687.563 1.125 0 .437-.188.875-.563 1.125l-8.5 7.5a1.34 1.34 0 0 1-1.563.25c-.562-.25-.875-.75-.875-1.375V23.158l-6.062 5c-.625.5-1.625.438-2.125-.187-.5-.688-.438-1.625.188-2.125l7.125-5.875-7.125-5.813c-.626-.5-.688-1.5-.188-2.125.563-.625 1.5-.75 2.125-.187l6.063 5V5.47c0-.563.312-1.125.875-1.313Zm2.125 19v8l4.687-4.125-4.688-3.875Zm0-6.312 4.687-3.875-4.688-4.125v8Z" />
    </SvgIcon>
  );
}
