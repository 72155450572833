import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function BookOpen(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M11.75 6c2.25 0 4.438.438 6.063.813a15.42 15.42 0 0 1 1.937.562c.063 0 .125.063.25.063.063 0 .125-.063.188-.063.5-.188 1.125-.375 1.937-.563C23.75 6.438 25.938 6 28.25 6c3.438 0 6.688.938 8 1.375C37.313 7.688 38 8.688 38 9.75v19.438c0 1.687-1.625 2.812-3.063 2.562-1.187-.25-3.25-.5-6.187-.5-3.688 0-6.125 1.563-7 2.188a3.124 3.124 0 0 1-1.813.562 3.143 3.143 0 0 1-1.687-.5c-.938-.625-3.625-2.25-7-2.25-2.875 0-5.063.313-6.25.5-1.5.25-3-.875-3-2.5V9.75c0-1.063.625-2.063 1.688-2.375C5 6.937 8.25 6 11.75 6Zm6.75 4.125c-.375-.125-.875-.25-1.438-.375C15.626 9.375 13.688 9 11.75 9 9 9 6.375 9.688 5 10.125V28.75c1.375-.25 3.563-.5 6.25-.5 3 0 5.625 1.063 7.25 1.875v-20Zm3 19.938c1.563-.875 4-1.813 7.25-1.813 2.813 0 4.875.25 6.25.438V10.125C33.562 9.687 30.937 9 28.25 9c-2 0-3.938.375-5.375.75-.563.125-1 .25-1.375.375v19.938Z" />
    </SvgIcon>
  );
}
