import { useEffect, useMemo, useState } from 'react';
import { TableFilterType, TableHeaderType, TableRowType } from '../types';

const filterNotUndefined = <T>(x: T | undefined): x is T => Boolean(x);

const initialFilters = <T>(
  data: TableRowType<T>[],
  headers: TableHeaderType[],
): TableFilterType[] => {
  return headers
    .filter(x => x.filterable)
    .map(({ key, label, filterDefaultActive }) => {
      const options = Array.from(
        new Set(data.map(row => row.columns[key].value?.toLocaleString())),
      );

      const type = data.map(row => row.columns[key].type).find(Boolean);

      if (!type) {
        return undefined;
      }

      return {
        key,
        type,
        label,
        options,
        active: filterDefaultActive?.filter(x => options.includes(x)) ?? [],
      } as TableFilterType;
    })
    .filter(filterNotUndefined);
};

export const filterData = <T>(
  data: TableRowType<T>[],
  filters: TableFilterType[],
): TableRowType<T>[] => {
  return data.filter(row => {
    return filters.every(filter => {
      if (filter.active.length === 0) return true;
      return filter.active.includes(row.columns[filter.key].value as string);
    });
  });
};

export const useTableFilter = <T>(
  data: TableRowType<T>[],
  headers: TableHeaderType[],
) => {
  const [filters, setFilters] = useState<TableFilterType[]>(
    initialFilters(data, headers),
  );

  useEffect(() => {
    setFilters(initialFilters(data, headers));
  }, [data, headers]);

  const updateFilter = (key: string, values: string[]) => {
    setFilters(
      filters.map(filter => {
        if (filter.key === key) {
          return {
            ...filter,
            active: values,
          };
        }
        return filter;
      }),
    );
  };

  const filteredData = useMemo(
    () => filterData(data, filters),
    [data, filters],
  );

  return { filteredData, filters, updateFilter };
};
