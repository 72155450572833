import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ChartLineDown(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M7 7.5v22c0 .875.625 1.5 1.5 1.5h26c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5h-26C6 34 4 32 4 29.5v-22C4 6.687 4.625 6 5.5 6 6.313 6 7 6.688 7 7.5Zm17 15c0-.813.625-1.5 1.5-1.5h3.375L23 15.125l-4.938 4.938c-.625.625-1.562.625-2.125 0l-5-5c-.624-.563-.624-1.5 0-2.126.563-.562 1.5-.562 2.126 0L17 16.875l4.938-4.938c.562-.562 1.5-.562 2.125 0l6.937 7V15.5c0-.813.625-1.5 1.5-1.5.813 0 1.5.688 1.5 1.5v7c0 .875-.688 1.5-1.5 1.5h-7c-.875 0-1.5-.625-1.5-1.5Z" />
    </SvgIcon>
  );
}
