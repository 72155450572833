import { baseplateStyles } from '../../utils';

export const useCalloutStyles = baseplateStyles(theme => ({
  root: {
    // '&, admonition': {
    borderRadius: theme.primitive.borderRadius.default,
    padding: '1rem',
    border: `1px solid ${theme.semantic.border.default}`,
    fontSize: theme.typography.body1.fontSize,
    // },
    '& .admonition-title': {
      background: 'none',
    },
    '& p, & .MuiTypography-root': {
      color: 'inherit',
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
  },
  content: {
    marginTop: '0.5rem',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
  },
  success: {
    // '&, admonition.success': {
    backgroundColor: theme.semantic.background.success,
    color: theme.semantic.text.success,
    borderColor: theme.semantic.border.success,
    // },
  },
  error: {
    // '&, admonition.error': {
    backgroundColor: theme.semantic.background.error,
    color: theme.semantic.text.error,
    borderColor: theme.semantic.border.error,
    // },
  },
  warning: {
    // '&, admonition.warning': {
    backgroundColor: theme.semantic.background.warning,
    color: theme.semantic.text.warning,
    borderColor: theme.semantic.border.warning,
    // },
  },
  info: {
    // '&, admonition.info': {
    backgroundColor: theme.semantic.background.info,
    color: theme.semantic.text.info,
    borderColor: theme.semantic.border.info,
    // },
  },
}));
