import { useApi } from '@backstage/core-plugin-api';
import React, { FC, PropsWithChildren, createContext, useContext } from 'react';
import useAsync from 'react-use/lib/useAsync';
import { espStatusApiRef } from '../../api/IEspClient';
import { IEspStatus } from '../../types';

export interface IEspStatusContext {
  status?: IEspStatus[];
  error?: Error;
  loading: boolean;
}

export const EspStatusContext = createContext({} as IEspStatusContext);

export const EspStatusContextProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const espApi = useApi(espStatusApiRef);

  const {
    loading,
    value: status,
    error,
  } = useAsync(async () => {
    const integrationResponse = await espApi.getStatus('integration');
    const productionResponse = await espApi.getStatus('production');

    return [integrationResponse, productionResponse];
  }, []);

  return (
    <EspStatusContext.Provider value={{ status, error, loading }}>
      {children}
    </EspStatusContext.Provider>
  );
};

export const useEspStatus = (): IEspStatusContext =>
  useContext(EspStatusContext);
