import { Table } from '@backstage/core-components';
import { OpenPosition } from '@lego/plugin-baseplate-people-to-product-common';
import React, { MouseEvent } from 'react';
import { useOpenPosition } from '../../../hooks';
import OpenPositionSidePanel from '../OpenPositionSidePanel/OpenPositionSidePanel';
import { useOpenPositionSearchParams } from '../../../hooks/useOpenPositionsSearchParams';
import { columns } from './columns';
import { Header } from './Header';

export function OpenPositionTable() {
  const { openPositions, loading, filteredOpenPositions } = useOpenPosition();
  const { setSearchParams, products } = useOpenPositionSearchParams();
  const [selectedOpenPosition, setSelectedOpenPosition] = React.useState<
    OpenPosition | undefined
  >(undefined);
  const [isOpen, setIsOpen] = React.useState(false);

  const handleRowClick = (_event?: MouseEvent, rowData?: any) => {
    const clickedOpenPosition = openPositions.find(
      (openPosition: OpenPosition) =>
        openPosition.positionId === rowData.positionId,
    );
    setSelectedOpenPosition(clickedOpenPosition);
    setIsOpen(true);
  };

  return (
    <>
      <Table
        title={
          <Header
            loading={loading}
            openPositions={openPositions}
            productsFilter={products}
            setSearchParams={setSearchParams}
          />
        }
        columns={columns}
        data={filteredOpenPositions}
        onRowClick={handleRowClick}
        options={{ search: true, paging: false, sorting: true }}
        isLoading={loading}
      />
      {selectedOpenPosition && (
        <OpenPositionSidePanel
          openPosition={selectedOpenPosition}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      )}
    </>
  );
}
