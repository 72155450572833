import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';
import {
  Button,
  Input,
  Dropdown,
} from '@lego/plugin-baseplate-core-components';
import { FormControl, Grid, IconButton, Typography } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import React, { useEffect, useState } from 'react';
import { useSupportLinkExtensionStyles } from './SupportLinkExtension.styles';

type SupportLink = {
  title: string;
  description?: string | undefined;
  link?: string | undefined;
  actionLabel?: string | undefined;
  supportType: 'Chat' | 'Docs' | 'Email' | 'Call' | 'Ideas' | 'Survey';
};

const SupportTypes = ['Chat', 'Docs', 'Email', 'Call', 'Ideas', 'Survey'];

export const SupportLinkExtension = ({
  onChange,
  rawErrors,
  required,
  formData,
}: any) => {
  const styles = useSupportLinkExtensionStyles();
  const [entries, setEntries] = useState<SupportLink[] | undefined>(
    formData || [],
  );
  useEffect(() => {
    if (entries && entries.length > 0) {
      onChange(entries);
    } else {
      onChange(undefined);
    }
  }, [entries, onChange]);

  const handleUpdate = (
    index: number,
    key: keyof SupportLink,
    newValue: string,
  ) => {
    const newEntries = (entries ?? []).map((entry, i) =>
      i === index ? { ...entry, [key]: newValue } : entry,
    );
    setEntries(newEntries);
  };

  const handleAdd = () => {
    setEntries([
      ...(entries ?? []),
      {
        title: '',
        description: '',
        link: '',
        actionLabel: '',
        supportType: 'Chat',
      },
    ]);
  };

  const handleRemove = (index: number) => {
    const newEntries = (entries ?? []).filter((_, i) => i !== index);
    setEntries(newEntries);
  };

  return (
    <FormControl required={required} error={rawErrors?.length > 0 && !formData}>
      {entries &&
        !!entries.length &&
        entries.map((entry, index) => (
          <Grid container key={index} className={styles.supportLinkContainer}>
            <Typography
              key={entry.title}
              variant="subtitle2"
              className={styles.supportLinkTitle}
            >
              Support Link - {entry.title === '' ? index + 1 : entry.title}
            </Typography>
            <Grid item className={styles.supportLinkItem} xs={12}>
              <Grid item xs={11}>
                <Input
                  label="Title"
                  id={`validateLabel-${index}`}
                  value={entry.title}
                  onChange={e => handleUpdate(index, 'title', e.target.value)}
                  helperText="The title will be displayed as the support card title and as the button label in the service type homepage header. e.g Chat with specialists"
                />
                <Input
                  label="Description"
                  id={`validateLabel-${index}`}
                  value={entry.description}
                  minRows={3}
                  multiline
                  onChange={e =>
                    handleUpdate(index, 'description', e.target.value)
                  }
                  helperText="A short summary of the support. This will appear as the description in the support card on the Help & Support page."
                />
                <Input
                  label="Url"
                  id={`validateLabel-${index}`}
                  value={entry.link}
                  onChange={e => handleUpdate(index, 'link', e.target.value)}
                  helperText="URL or email address for the support channel. This will be used as the contact link in the support card."
                />
                <Grid
                  item
                  xs={12}
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Grid item xs={5}>
                    <Dropdown
                      label="Support type"
                      id={`validateColor-${index}`}
                      defaultValue="Chat"
                      options={SupportTypes}
                      value={entry.supportType || 'Chat'}
                      helperText="Select a type to choose the icon displayed before the title in the support card."
                      onChange={e =>
                        handleUpdate(
                          index,
                          'supportType',
                          e.target.value as string,
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Input
                      label="Action label"
                      id={`validateLabel-${index}`}
                      value={entry.actionLabel}
                      onChange={e =>
                        handleUpdate(index, 'actionLabel', e.target.value)
                      }
                      helperText="The text that will be displayed on the action button in the support card, such as 'Chat with our team' or 'Contact Us'."
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={1} className={styles.supportLinkBtnContainer}>
                <IconButton onClick={() => handleRemove(index)}>
                  <RemoveCircleOutlineIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        ))}
      <Button
        onClick={handleAdd}
        startIcon={<AddCircleOutlineIcon />}
        fullWidth
        variant="secondary"
      >
        Add a support link
      </Button>
    </FormControl>
  );
};
