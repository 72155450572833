import { primitive } from './primitive';
import { semantic } from './semantic';

const page = {
  horizontalPadding: primitive.dimensions[800],
};

export const component = {
  light: {
    page,
    button: {
      primary: {
        text: primitive.color.grey[50],
        background: {
          default: primitive.color.blue[1000],
          hover: primitive.color.blue[1100],
        },
      },
      outlined: {
        text: semantic.light.text.primary,
        background: {
          default: semantic.light.background.neutral[1],
          hover: semantic.light.background.neutral[2],
        },
        border: {
          default: primitive.color.grey[100],
          hover: primitive.color.grey[200],
        },
      },
      text: {
        text: semantic.light.text.primary,
        background: {
          default: semantic.light.background.neutral[1],
          hover: semantic.light.background.neutral[2],
        },
      },
      destructive: {
        text: primitive.color.red[50],
        background: {
          default: primitive.color.red[1000],
          hover: primitive.color.red[1100],
        },
      },
    },
    tag: {
      neutral: {
        background: primitive.color.grey[100],
        text: semantic.light.text.primary,
      },
      purple: {
        background: primitive.color.purple['75'],
        text: primitive.color.purple['1100'],
      },
      blue: {
        background: semantic.light.background.info,
        text: semantic.light.text.info,
      },
      green: {
        background: semantic.light.background.success,
        text: semantic.light.text.success,
      },
      yellow: {
        background: semantic.light.background.warning,
        text: semantic.light.text.warning,
      },
      red: {
        background: semantic.light.background.error,
        text: semantic.light.text.error,
      },
    },
    entity: {
      highlight: {
        shadow: `0 0 0 2px ${primitive.color.green[500]}`,
      },
      icon: {
        api: {
          layer: {
            1: primitive.color.green[1300],
            2: primitive.color.green[900],
            3: primitive.color.green[200],
          },
        },
        documentation: {
          layer: {
            1: primitive.color.pink[1300],
            2: primitive.color.pink[800],
            3: primitive.color.pink[300],
          },
        },
        product: {
          layer: {
            1: primitive.color.blue[1300],
            2: primitive.color.blue[900],
            3: primitive.color.blue[200],
          },
        },
        community: {
          layer: {
            1: primitive.color.azure[1100],
            2: primitive.color.azure[700],
            3: primitive.color.azure[75],
          },
        },
        tool: {
          layer: {
            1: primitive.color.yellow[1300],
            2: primitive.color.yellow[600],
            3: primitive.color.yellow[75],
          },
        },
        application: {
          layer: {
            1: primitive.color.purple[1300],
            2: primitive.color.purple[700],
            3: primitive.color.purple[200],
          },
        },
        group: {
          layer: {
            1: primitive.color.teal[1300],
            2: primitive.color.teal[700],
            3: primitive.color.teal[200],
          },
        },
        'it-component': {
          layer: {
            1: primitive.color.orange[1200],
            2: primitive.color.orange[700],
            3: primitive.color.orange[200],
          },
        },
      },
    },
  },
  dark: {
    page,
    button: {
      primary: {
        text: primitive.color.grey[50],
        background: {
          default: primitive.color.blue[1100],
          hover: primitive.color.blue[1000],
        },
      },
      outlined: {
        text: semantic.dark.text.primary,
        background: {
          default: semantic.dark.background.neutral[1],
          hover: semantic.dark.background.neutral[3],
        },
        border: {
          default: primitive.color.grey[1000],
          hover: primitive.color.grey[1100],
        },
      },
      text: {
        text: semantic.dark.text.primary,
        background: {
          default: semantic.dark.background.neutral[1],
          hover: semantic.dark.background.neutral[3],
        },
      },
      destructive: {
        text: primitive.color.red[100],
        background: {
          default: primitive.color.red[1200],
          hover: primitive.color.red[1300],
        },
      },
    },
    tag: {
      neutral: {
        background: primitive.color.grey[1200],
        text: semantic.dark.text.primary,
      },
      purple: {
        background: primitive.color.purple['1300'],
        text: primitive.color.purple['300'],
      },
      blue: {
        background: semantic.dark.background.info,
        text: semantic.dark.text.info,
      },
      green: {
        background: semantic.dark.background.success,
        text: semantic.dark.text.success,
      },
      yellow: {
        background: semantic.dark.background.warning,
        text: semantic.dark.text.warning,
      },
      red: {
        background: semantic.dark.background.error,
        text: semantic.dark.text.error,
      },
    },
    entity: {
      highlight: {
        shadow: `0 0 0 2px ${primitive.color.green[1000]}`,
      },
      icon: {
        api: {
          layer: {
            1: primitive.color.green[100],
            2: primitive.color.green[600],
            3: primitive.color.green[1200],
          },
        },
        documentation: {
          layer: {
            1: primitive.color.pink[100],
            2: primitive.color.pink[800],
            3: primitive.color.pink[1200],
          },
        },
        product: {
          layer: {
            1: primitive.color.blue[100],
            2: primitive.color.blue[700],
            3: primitive.color.blue[1200],
          },
        },
        community: {
          layer: {
            1: primitive.color.azure[200],
            2: primitive.color.azure[700],
            3: primitive.color.azure[1200],
          },
        },
        tool: {
          layer: {
            1: primitive.color.yellow[50],
            2: primitive.color.yellow[800],
            3: primitive.color.yellow[1200],
          },
        },
        application: {
          layer: {
            1: primitive.color.purple[100],
            2: primitive.color.purple[800],
            3: primitive.color.purple[1200],
          },
        },
        group: {
          layer: {
            1: primitive.color.teal[100],
            2: primitive.color.teal[600],
            3: primitive.color.teal[1100],
          },
        },
        'it-component': {
          layer: {
            1: primitive.color.orange[50],
            2: primitive.color.orange[700],
            3: primitive.color.orange[1100],
          },
        },
      },
    },
  },
};
