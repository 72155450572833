import React from 'react';
import { SearchModalResultItemTooltip } from './SearchModalResultItemTooltip';
import { baseplateStyles } from '@lego/plugin-baseplate-core-components';

export type SearchModalResultItemProps = {
  onClick: () => void;
  setCursor: (cursor: number) => void;
  idx: number;
  cursor: number;
  filterable?: boolean;
};

const useStyles = baseplateStyles(theme => ({
  root: {
    textAlign: 'left',
    background: 'none',
    border: 0,
    width: '100%',
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    padding: theme.spacing(1, 2),
    borderRadius: theme.primitive.borderRadius.default,
    color: theme.semantic.text.primary,
    '&$selected': {
      backgroundColor: theme.semantic.background.neutral[3],
      color: 'inherit',
      '& $tooltip': {
        visibility: 'visible',
      },
    },
  },
  children: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    // EntityLink style overrides
    '& > a, & > a:hover': {
      border: 0,
      background: 'none',
      padding: '0 !important',
    },
  },
  tooltip: { visibility: 'hidden' },
  selected: {},
}));

export const SearchModalResultItemWrapper = ({
  onClick,
  filterable,
  setCursor,
  children,
  idx,
  cursor,
}: SearchModalResultItemProps & { children: React.ReactNode }) => {
  const classes = useStyles();
  const selected = cursor === idx;
  return (
    <button
      onMouseEnter={() => setCursor(idx)}
      onMouseLeave={() => setCursor(-1)}
      onClick={onClick}
      className={[classes.root, selected && classes.selected].join(' ')}
    >
      <div className={classes.children}>{children}</div>
      <div className={classes.tooltip}>
        <SearchModalResultItemTooltip filterable={filterable} />
      </div>
    </button>
  );
};
