import { featureFlagsApiRef, useApi } from '@backstage/core-plugin-api';
import { useEntity } from '@backstage/plugin-catalog-react';
import { AMMA_ENVIRONMENT } from '@lego/plugin-baseplate-amma-common';
import { LinterPage } from '@lego/plugin-baseplate-api-linter';
import { EntityLayout } from '@lego/plugin-baseplate-catalog';
import { Button } from '@lego/plugin-baseplate-core-components';
import React from 'react';
import { AccessTab } from '../AccessTab';
import { DefinitionTab } from '../DefinitionTab';
import { MetricsTab } from '../MetricsTab';
import { OverviewTab } from '../OverviewTab';
import { ApiType } from '../../types';

export const ApiDetailPage = () => {
  const { entity } = useEntity();
  const featureFlagsApi = useApi(featureFlagsApiRef);
  const isNewDetailPage = featureFlagsApi.isActive('api-detail');

  const workspaceToConnect =
    entity?.metadata?.annotations !== undefined
      ? entity?.metadata?.annotations['baseplate.legogroup.io/raw-namespace']
      : undefined;

  const apiToConnect =
    entity?.metadata?.annotations !== undefined
      ? entity?.metadata?.annotations['baseplate.legogroup.io/raw-name']
      : undefined;

  const productId = entity.spec?.owner;

  const environment = entity.spec?.environment as AMMA_ENVIRONMENT;

  const type = entity.spec?.type as ApiType;

  const workspace = entity.metadata.annotations && {
    label: entity.metadata.annotations['baseplate.legogroup.io/raw-namespace'],
    href: `/catalog/default/workspace/${entity.metadata.annotations['baseplate.legogroup.io/raw-namespace']}`,
  };

  const switchToOldVersion = () => {
    featureFlagsApi.save({ states: { 'api-detail': 0 }, merge: true });
    location.reload();
  };

  const hasLinter =
    type === 'openapi' && featureFlagsApi.isActive('api-linter');

  return (
    <EntityLayout
      breadcrumbLinks={[
        { label: 'API', href: '/api-docs' },
        ...(workspace ? [workspace] : []),
      ]}
      actions={[
        <Button variant="secondary" onClick={switchToOldVersion}>
          Switch to {isNewDetailPage ? 'old' : 'new'} version
        </Button>,
        <Button
          variant="primary"
          href={`/request-access/workspace/${workspaceToConnect}/api/${apiToConnect}/environment/${environment}${
            productId ? `/owner/${productId as string}` : ''
          }`}
        >
          Request access
        </Button>,
      ]}
      labels={e => {
        const isProduction = e.spec?.environment === 'production';
        return [
          {
            title: environment,
            color: isProduction ? 'green' : 'yellow',
          },
          {
            title: type,
            color: {
              openapi: 'green',
              asyncapi: 'blue',
              graphql: 'purple',
            }[type],
          },
        ];
      }}
    >
      <EntityLayout.Route path="/" title="Overview">
        <OverviewTab entity={entity} />
      </EntityLayout.Route>
      <EntityLayout.Route path="/access" title="Access">
        <AccessTab entity={entity} />
      </EntityLayout.Route>
      <EntityLayout.Route path="/documentation" title="Documentation">
        <>Coming soon</>
      </EntityLayout.Route>

      <EntityLayout.Route path="/definition" title="Definition">
        <DefinitionTab entity={entity} />
      </EntityLayout.Route>

      <EntityLayout.Route
        path="/api-linter"
        title="Linter"
        if={() => hasLinter}
      >
        <LinterPage />
      </EntityLayout.Route>
      <EntityLayout.Route path="/api-metrics" title="Metrics">
        <MetricsTab type={entity.spec?.type as string} />
      </EntityLayout.Route>
    </EntityLayout>
  );
};
