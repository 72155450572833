import { getIconObject } from '@lego/plugin-baseplate-core-components';

export const systemIcons = {
  computerBug: getIconObject('bug'),
  messageBubble: getIconObject('message'),
  programming: getIconObject('code'),
  bug: getIconObject('bug'),
  issues: getIconObject('bug'),
  teams: getIconObject('message'),
  discussions: getIconObject('lightbulb'),
  feedback: getIconObject('file-pen'),
  'kind:api': getIconObject('cloud-api'),
  'kind:workspace': getIconObject('cloud-folder-open'),
  'kind:identity': getIconObject('credit-card-front'),
};
