import { useApi } from '@backstage/core-plugin-api';
import {
  Banner,
  BannerCreateParams,
} from '@lego/plugin-baseplate-admin-common';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { AdminApiRef } from '../../api';

type ContextType = {
  banners: Banner[];
  handleCreateBanner: (banner: BannerCreateParams) => Promise<void>;
  handleUpdatingBanner: (
    id: string,
    banner: BannerCreateParams,
  ) => Promise<void>;
  handleDeletingBanner: (id: string) => Promise<void>;
  loading: boolean;
  error?: Error;
};

interface ProviderProps {
  children?: React.ReactNode;
}

const BannerContext = createContext<ContextType>({
  banners: [],
  handleCreateBanner: async () => Promise.resolve(),
  handleUpdatingBanner: async () => Promise.resolve(),
  handleDeletingBanner: async () => Promise.resolve(),
  loading: false,
  error: undefined,
});

export const useBanner = () => useContext(BannerContext);

export const BannerProvider: React.FC = ({ children }: ProviderProps) => {
  const api = useApi(AdminApiRef);
  const [banners, setBanners] = useState<Banner[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | undefined>(undefined);

  useEffect(() => {
    const fetchBanners = async () => {
      setLoading(true);
      try {
        const currentBanners = await api.getBanners();
        setBanners(currentBanners);
      } catch (e: any) {
        setError(e);
      } finally {
        setLoading(false);
      }
    };
    void fetchBanners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCreateBanner = async (banner: BannerCreateParams) => {
    const newBanner = await api.createBanner(banner);
    setBanners(prevBanners => [...prevBanners, newBanner]);
  };

  const handleDeletingBanner = async (id: string) => {
    await api.deleteBannerById(id);
    setBanners(prevBanners => {
      const newBanners = prevBanners?.filter(
        prevBanner => prevBanner.id !== id,
      );
      return newBanners;
    });
  };

  const handleUpdatingBanner = async (
    id: string,
    banner: BannerCreateParams,
  ) => {
    const updatedBanner = await api.updateBannerById(id, banner);
    setBanners(prevBanners => {
      const newBanners = prevBanners?.map(prevBanner => {
        if (prevBanner.id === updatedBanner.id) {
          return updatedBanner;
        }
        return prevBanner;
      });
      return newBanners;
    });
  };

  return (
    <BannerContext.Provider
      value={{
        banners: banners || [],
        handleCreateBanner,
        handleUpdatingBanner,
        handleDeletingBanner,
        loading,
        error,
      }}
    >
      {children}
    </BannerContext.Provider>
  );
};
