import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function WifiSlash(props) {
  return (
    <SvgIcon
      viewBox="0 0 41 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="m2.417 4.344 5.5 4.25c3.687-1.688 7.75-2.625 12.125-2.625 7.437 0 14.25 2.812 19.437 7.375.625.562.688 1.5.188 2.125-.563.625-1.5.687-2.125.125-4.688-4.125-10.813-6.625-17.5-6.625-3.313 0-6.5.625-9.438 1.75l8.063 6.312c.437 0 .875-.062 1.375-.062 4.812 0 9.187 1.875 12.5 4.875.562.562.625 1.5.062 2.125-.562.625-1.5.687-2.125.125-2.125-2-4.75-3.313-7.75-3.875l16.688 13.125c.687.5.812 1.437.25 2.062-.5.688-1.438.813-2.063.25l-37-29c-.687-.5-.812-1.437-.25-2.062.5-.688 1.438-.813 2.063-.25Zm2.375 9.437a58.502 58.502 0 0 0-2.313 1.813c-.625.562-1.562.5-2.062-.125a1.493 1.493 0 0 1 .125-2.125c.562-.5 1.187-1 1.812-1.5l2.438 1.937Zm9.375 7.375a14.237 14.237 0 0 0-4.625 2.938c-.625.562-1.563.5-2.125-.125a1.549 1.549 0 0 1 .062-2.125c1.25-1.063 2.563-2 4.063-2.813l2.625 2.125Zm9.375 9.313a3.518 3.518 0 0 1-1.75 3.062c-1.125.625-2.438.625-3.5 0-1.125-.625-1.75-1.75-1.75-3.062 0-1.25.625-2.375 1.75-3 1.062-.625 2.375-.625 3.5 0 1.062.625 1.75 1.75 1.75 3Z" />
    </SvgIcon>
  );
}
