import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ArrowUpToLine(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M9.5 6h21c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5h-21C8.625 9 8 8.375 8 7.5 8 6.687 8.625 6 9.5 6Zm11.563 8.5 8 8.5c.562.625.562 1.563-.063 2.125-.625.563-1.563.563-2.125-.063l-5.375-5.75V32.5c0 .875-.688 1.5-1.5 1.5-.875 0-1.5-.625-1.5-1.5V19.312l-5.438 5.75c-.562.625-1.5.625-2.124.063-.626-.563-.626-1.5-.063-2.125l8-8.5c.313-.313.688-.5 1.125-.5.375 0 .813.188 1.063.5Z" />
    </SvgIcon>
  );
}
