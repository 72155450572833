import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Tv(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M4 7c-.563 0-1 .5-1 1v18c0 .563.438 1 1 1h32c.5 0 1-.438 1-1V8c0-.5-.5-1-1-1H4ZM0 8c0-2.188 1.75-4 4-4h32c2.188 0 4 1.813 4 4v18c0 2.25-1.813 4-4 4H4c-2.25 0-4-1.75-4-4V8Zm7.5 25h25c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5h-25c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5Z" />
    </SvgIcon>
  );
}
