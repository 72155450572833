import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Whatsapp(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M31.143 8.695c3 3 4.857 6.928 4.857 11.214 0 8.714-7.286 15.857-16.071 15.857-2.643 0-5.215-.714-7.572-1.928L4 35.98l2.214-8.214a15.876 15.876 0 0 1-2.143-7.928c0-8.715 7.143-15.858 15.858-15.858 4.285 0 8.285 1.715 11.214 4.715ZM19.929 33.052c7.285 0 13.357-5.929 13.357-13.143 0-3.571-1.5-6.857-4-9.357-2.5-2.5-5.786-3.857-9.286-3.857-7.286 0-13.214 5.928-13.214 13.143 0 2.5.714 4.928 2 7.071l.357.5-1.357 4.857 5-1.357.428.286c2.072 1.214 4.357 1.857 6.715 1.857Zm7.285-9.857c.357.214.643.285.715.5.142.143.142.928-.215 1.857-.357.928-1.928 1.786-2.643 1.857-1.285.214-2.285.143-4.785-1-4-1.714-6.572-5.714-6.786-5.928-.214-.286-1.571-2.143-1.571-4.143 0-1.929 1-2.858 1.357-3.286a1.34 1.34 0 0 1 1.071-.5h.714c.286 0 .572-.072.929.714.286.786 1.143 2.714 1.214 2.929.072.214.143.428 0 .714-.714 1.5-1.571 1.429-1.143 2.143 1.572 2.643 3.072 3.571 5.429 4.714.357.215.571.143.857-.071.214-.286 1-1.215 1.214-1.572.286-.428.572-.357.929-.214.357.143 2.286 1.072 2.714 1.286Z" />
    </SvgIcon>
  );
}
