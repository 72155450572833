import { createApiRef } from '@backstage/core-plugin-api';
import {
  MsSQLDatabaseResponse,
  CreateMsSQLRequestBody,
} from '@lego/plugin-baseplate-edge-platform-common';

export const MsSQLApiRef = createApiRef<MsSQLApi>({
  id: 'plugin.edge.platform.mssql',
});

export interface MsSQLApi {
  getAllMsSQLDatabase(productId: string): Promise<MsSQLDatabaseResponse>;
  createMsSQL(requestBody: CreateMsSQLRequestBody): Promise<unknown>;
}
