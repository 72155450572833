import { Typography } from '@material-ui/core';
import React from 'react';
import {
  SearchModalResultItemProps,
  SearchModalResultItemWrapper,
} from '../SearchModalResultItemWrapper';
import { HighlightedSearchResultText } from '@backstage/plugin-search-react';
import { Icon, baseplateStyles } from '@lego/plugin-baseplate-core-components';

type Props = {
  result: any;
} & SearchModalResultItemProps;

export const useStyles = baseplateStyles(_ => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    flexGrow: 1,
  },
  highlightedText: {
    overflow: 'hidden',
    'white-space': 'nowrap',
    'text-overflow': 'ellipsis',
    maxWidth: '300px',
  },
}));

export const SearchModalTechdocsResultItem = ({ result, ...props }: Props) => {
  const classes = useStyles();
  return (
    <SearchModalResultItemWrapper {...props}>
      <div className={classes.wrapper}>
        <Icon icon="book-open" />
        <div>
          <Typography variant="body1">
            {/* Adds prefix to documentation page title. For example: Baseplate: Create a plugin */}
            {result.document.entityTitle && `${result.document.entityTitle}: `}
            {result.document.title}
          </Typography>
          <Typography
            variant="body2"
            color="secondary"
            className={classes.highlightedText}
          >
            {Object.keys(result.highlight.fields).length > 0 && (
              <HighlightedSearchResultText
                text={result.highlight.fields.text}
                preTag={result.highlight.preTag}
                postTag={result.highlight.postTag}
              />
            )}
          </Typography>
        </div>
      </div>
    </SearchModalResultItemWrapper>
  );
};
