/* eslint-disable @typescript-eslint/no-misused-promises */
import {
  AnalyticsApi,
  AnalyticsEvent,
  FetchApi,
  IdentityApi,
} from '@backstage/core-plugin-api';
import { DiscoveryApi } from '@backstage/plugin-permission-common';

export class BaseplateAnalytics implements AnalyticsApi {
  private sessionToken: string | undefined;
  private fetchApi: FetchApi;
  private discoveryApi: DiscoveryApi;

  private constructor(options: {
    identityApi: IdentityApi;
    fetchApi: FetchApi;
    discoveryApi: DiscoveryApi;
  }) {
    const { fetchApi, identityApi, discoveryApi } = options;
    if (identityApi) {
      identityApi
        .getProfileInfo()
        .then(identity => {
          this.sessionToken = `${identity.email ?? 'session'}-${Date.now()}`;
          window.sessionStorage.setItem('sessionToken', this.sessionToken);
        })
        .catch(_ => {
          this.sessionToken = undefined;
        });
    }
    this.fetchApi = fetchApi;
    this.discoveryApi = discoveryApi;
  }

  captureEvent(event: AnalyticsEvent): void {
    let normalisedEvent = event;
    if (event.attributes?.email && event.attributes?.displayName) {
      normalisedEvent = { ...event, attributes: { ...event.attributes } };
      delete normalisedEvent.attributes?.email;
      delete normalisedEvent.attributes?.displayName;
    }

    setTimeout(async () => {
      try {
        const body = {
          location: window.location,
          event: normalisedEvent,
          sessionToken: this.sessionToken,
        };

        const baseUrl = await this.discoveryApi.getBaseUrl('analytics');
        await this.fetchApi.fetch(baseUrl, {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        });
      } catch (error) {
        // fetch() promise rejects if a request cannot be made or a response cannot be retrieved.
        // eslint-disable-next-line no-console
        console.log(
          'Analytics event connection failed, e.g. networking or no application/json',
          error,
        );
      }
    }, 0);
  }

  static fromConfig(options: {
    identityApi: IdentityApi;
    fetchApi: FetchApi;
    discoveryApi: DiscoveryApi;
  }) {
    return new BaseplateAnalytics(options);
  }
}
