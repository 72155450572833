import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ListCheck(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M13.578 9.54v-.063l-4.5 5c-.25.313-.625.5-1.062.5-.375.063-.813-.125-1.063-.437l-2.5-2.5c-.625-.563-.625-1.5 0-2.125.563-.563 1.5-.563 2.125 0l1.375 1.437 3.438-3.875a1.549 1.549 0 0 1 2.125-.062c.562.562.625 1.5.062 2.125Zm0 10v-.063l-4.5 5c-.25.313-.625.5-1.062.5-.375.063-.813-.125-1.063-.437l-2.5-2.5c-.625-.563-.625-1.5 0-2.125.563-.563 1.5-.563 2.125 0l1.375 1.437 3.438-3.875a1.549 1.549 0 0 1 2.125-.062c.562.562.625 1.5.062 2.125Zm3.938-7.063c-.875 0-1.5-.625-1.5-1.5 0-.812.625-1.5 1.5-1.5h17c.812 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5h-17Zm-1.5 8.5c0-.812.625-1.5 1.5-1.5h17c.812 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5h-17c-.875 0-1.5-.625-1.5-1.5Zm-2 10c0-.812.625-1.5 1.5-1.5h19c.812 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5h-19c-.875 0-1.5-.625-1.5-1.5Zm-4 0c0 1.125-.938 2-2 2-1.125 0-2-.875-2-2 0-1.062.875-2 2-2 1.062 0 2 .938 2 2Z" />
    </SvgIcon>
  );
}
