import { ComponentProps } from 'react';
import { Status } from './Status';
import { LeanIxPhase } from '@lego/plugin-baseplate-common';

export const getLeanixLifecycleLabel = (lifecycle: LeanIxPhase) =>
  ({
    plan: 'Plan',
    phaseIn: 'Phase in',
    active: 'Active',
    phaseOut: 'Phase out',
    endOfLife: 'End of life',
  }[lifecycle] ?? null);

export const leanIxStatusToColor = (
  status: LeanIxPhase,
): ComponentProps<typeof Status>['status'] => {
  switch (status) {
    case 'active':
      return 'green';

    case 'phaseIn':
      return 'yellow';

    case 'phaseOut':
      return 'yellow';

    case 'endOfLife':
      return 'red';

    default:
      return 'neutral';
  }
};
