import React from 'react';
import {
  FormStepTitle,
  SelectableCard,
} from '@lego/plugin-baseplate-core-components';
import { Box, Grid, Typography } from '@material-ui/core';
import { StepProps } from '../types';
import { Link } from '@backstage/core-components';
import WidgetsIcon from '@material-ui/icons/Widgets';

export const StepChooseVendor = ({ values, updateValue }: StepProps) => {
  return (
    <Grid>
      <FormStepTitle
        formTitle="Create Cloud Account"
        stepTitle="Choose vendor"
        step={1}
      />
      <Box mt={1} mb={1}>
        <Typography variant="body1">
          Consult the <Link to="/docs">cloud vendor guide</Link> to determine
          which provider best suits your us case.
        </Typography>
      </Box>
      <SelectableCard
        name="type"
        value={values?.vendor as string}
        onChange={value => {
          updateValue('vendor', value);
          updateValue('currency', value === 'azure' ? 'DKK' : '$');
        }}
        options={[
          {
            label: 'Azure',
            description:
              'Microsoft Azure is a cloud platform offering over 200 services, including AI, machine learning, and IoT, with a global presence.',
            value: 'azure',
            icon: <WidgetsIcon />,
          },
          {
            label: 'AWS',
            description:
              'Amazon Web Services offers a comprehensive suite of cloud computing services globally.',
            value: 'aws',
            icon: <WidgetsIcon />,
          },
          {
            label: 'AWS China',
            description:
              'Amazon Web Services China provides localized cloud computing solutions within China, operated by local providers.',
            value: 'aws-china',
            icon: <WidgetsIcon />,
          },
        ]}
      ></SelectableCard>
    </Grid>
  );
};
