import { baseplateStyles } from '../../utils';

export const useDialogStyles = baseplateStyles(theme => ({
  root: {
    '& .v5-MuiDialogContent-root': {
      backgroundColor: theme.semantic.background.neutral[1],
      border: `1px solid ${theme.semantic.border.default}`,
      padding: '1.5rem',
      borderRadius: theme.primitive.borderRadius.default,
    },
    '& .v5-MuiDialogActions-root': {
      display: 'flex',
      gap: '1rem',
      justifyContent: 'flex-end',
    },
  },
}));
