import { Typography, makeStyles } from '@material-ui/core';
import Box from '@mui/material/Box';
import React from 'react';
import { Entity } from '@backstage/catalog-model';
import { baseBot2 } from '../../images/index';

const useStyles = makeStyles({
  subText: {
    paddingTop: '12px',
    fontSize: '1.3rem',
  },
  intro: {
    position: 'relative',
    left: '50%',
    transform: 'translateX(-50%)',
    maxWidth: '75%',
    paddingTop: '20vh',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  basebotImg: {
    height: '3rem',
    marginLeft: '10px',
  },
});

interface EmptyStateProps {
  entity?: Entity;
}

export const EmptyState: React.FC<EmptyStateProps> = ({ entity }) => {
  const classes = useStyles();
  return (
    <div className={classes.intro}>
      <div className={classes.container}>
        <div className={classes.textContainer}>
          <Typography className="" variant="h3">
            Welcome to Basebot
          </Typography>
          <Typography className={classes.subText}>
            Your GenAI-powered Baseplate Assistant{' '}
          </Typography>
        </div>
        <Box
          component="img"
          alt="Baseplate-bot"
          src={baseBot2}
          className={classes.basebotImg}
        />
      </div>
    </div>
  );
};
