import { BackstageOverrides } from '@backstage/core-components';
import { DesignTokens } from '../types';

export const button = (
  tokens: DesignTokens,
): BackstageOverrides & {
  MuiToggleButton: BackstageOverrides['MuiButton'];
} => ({
  MuiIconButton: {
    root: { color: tokens.semantic.icon.primary },
    label: { color: tokens.semantic.icon.secondary },
    disabled: { color: tokens.semantic.icon.secondary },
  },
  MuiButtonBase: {
    root: {
      boxShadow: 'none',
    },
  },

  MuiButton: {
    root: {
      borderRadius: tokens.primitive.borderRadius.default,
      textTransform: 'none',
      fontSize: '1rem',
    },
    containedPrimary: {
      backgroundColor: tokens.component.button.primary.background.default,
      color: tokens.component.button.primary.text,
      boxShadow: 'none',
      '& span': {
        color: tokens.component.button.primary.text,
      },
      '&:hover': {
        backgroundColor: tokens.component.button.primary.background.hover,
        boxShadow: 'none',
      },
    },
    outlinedPrimary: {
      color: tokens.component.button.outlined.text,
      borderColor: tokens.component.button.outlined.border.default,
      backgroundColor: tokens.component.button.outlined.background.default,
      '&:hover': {
        backgroundColor: tokens.component.button.outlined.background.hover,
        borderColor: tokens.component.button.outlined.border.hover,
      },
    },
    textPrimary: {
      color: tokens.component.button.text.text,
      '&:hover': {
        backgroundColor: tokens.component.button.text.background.hover,
      },
    },
  },
  MuiToggleButton: {
    label: {
      color: tokens.semantic.text.primary,
    },
  },
});
