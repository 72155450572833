import { RELATION_HAS_PART } from '@backstage/catalog-model';
import { EntityLayout } from '@lego/plugin-baseplate-catalog';
import {
  EntityCatalogGraphCard,
  EntityNodeData,
} from '@backstage/plugin-catalog-graph';
import { Grid, makeStyles } from '@material-ui/core';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { entityWarningContent } from '../ContentSections';
import { DependencyGraphTypes } from '@backstage/core-components';
import classNames from 'classnames';
import { EntityKindIcon, getKindIcon } from '../EntityKindIcon';
import { IdentityAboutCard } from '../CustomComponents/IdentityAboutCard';
import { useApp } from '@backstage/core-plugin-api';

const useStyles = makeStyles(theme => ({
  node: {
    fill: theme.palette.grey[300],
    stroke: theme.palette.grey[300],

    '&.primary': {
      fill: theme.palette.primary.light,
      stroke: theme.palette.primary.light,
    },
    '&.secondary': {
      fill: theme.palette.secondary.light,
      stroke: theme.palette.secondary.light,
    },
  },
  text: {
    fill: theme.palette.getContrastText(theme.palette.grey[300]),

    '&.primary': {
      fill: theme.palette.primary.contrastText,
    },
    '&.secondary': {
      fill: theme.palette.secondary.contrastText,
    },
    '&.focused': {
      fontWeight: 'bold',
    },
  },
  clickable: {
    cursor: 'pointer',
  },
}));

function RenderIdentityNodes({
  node: { color = 'default', focused, onClick, entity },
}: DependencyGraphTypes.RenderNodeProps<EntityNodeData>) {
  const app = useApp();
  const classes = useStyles();
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const idRef = useRef<SVGTextElement | null>(null);

  useLayoutEffect(() => {
    // set the width to the length of the ID
    if (idRef.current) {
      let { height: renderedHeight, width: renderedWidth } =
        idRef.current.getBBox();
      renderedHeight = Math.round(renderedHeight);
      renderedWidth = Math.round(renderedWidth);

      if (renderedHeight !== height || renderedWidth !== width) {
        setWidth(renderedWidth);
        setHeight(renderedHeight);
      }
    }
  }, [width, height]);

  const kindIcon = getKindIcon(app, entity.kind);

  const padding = 10;
  const iconSize = height;
  const paddedIconWidth = kindIcon ? iconSize + padding : 0;
  const paddedWidth = paddedIconWidth + width + padding * 2;
  const paddedHeight = height + padding * 2;

  const displayTitle =
    entity.kind.toLowerCase() === 'api'
      ? entity.spec?.type?.toString()
      : entity.metadata.title ?? '';

  return (
    <g onClick={onClick} className={classNames(onClick && classes.clickable)}>
      <rect
        className={classNames(
          classes.node,
          color === 'primary' && 'primary',
          color === 'secondary' && 'secondary',
        )}
        width={paddedWidth}
        height={paddedHeight}
        rx={10}
      />
      {kindIcon && (
        <EntityKindIcon
          kind={entity.kind}
          y={padding}
          x={padding}
          width={iconSize}
          height={iconSize}
          className={classNames(
            classes.text,
            focused && 'focused',
            color === 'primary' && 'primary',
            color === 'secondary' && 'secondary',
          )}
        />
      )}
      <text
        ref={idRef}
        className={classNames(
          classes.text,
          focused && 'focused',
          color === 'primary' && 'primary',
          color === 'secondary' && 'secondary',
        )}
        y={paddedHeight / 2}
        x={paddedIconWidth + (width + padding * 2) / 2}
        textAnchor="middle"
        alignmentBaseline="middle"
      >
        {displayTitle}
      </text>
    </g>
  );
}

const IdentityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={4} alignItems="stretch">
        {entityWarningContent}
        <Grid item md={6}>
          <IdentityAboutCard variant="gridItem" />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard
            variant="gridItem"
            height={400}
            title="Specifications"
            relations={[RELATION_HAS_PART]}
            renderNode={RenderIdentityNodes}
          />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);

export default IdentityPage;
