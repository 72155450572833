import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function TableRows(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M32 31c.5 0 1-.438 1-1v-8.5H12V31h20Zm1-12.5V10c0-.5-.5-1-1-1H12v9.5h21ZM4 30V10c0-2.188 1.75-4 4-4h24c2.188 0 4 1.813 4 4v20c0 2.25-1.813 4-4 4H8c-2.25 0-4-1.75-4-4Z" />
    </SvgIcon>
  );
}
