import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function BoxMagnifying(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <g fillRule="evenodd" clipRule="evenodd">
        <path d="M3.487 27.476v-13h19.375c1.375-.625 2.937-1 4.625-1 .149 0 .298.014.454.029.17.016.35.033.546.033v-.187c0-.563-.125-1.125-.375-1.625L25.487 5.85a3.97 3.97 0 0 0-3.625-2.375H7.049c-1.562 0-3 .937-3.625 2.375L.8 11.726c-.25.5-.312 1.062-.312 1.625v14.125c0 2.25 1.75 4 4 4h14.5c-.75-.875-1.375-1.875-1.75-3H4.487c-.563 0-1-.438-1-1Zm12.5-21h5.875c.375 0 .75.25.937.625l1.938 4.375h-8.75v-5Zm-3 5H4.174l2-4.375a.94.94 0 0 1 .875-.625h5.938v5Z" />
        <path d="M19.528 24.54c0 4.375 3.563 8 8 8 1.625 0 3.188-.563 4.438-1.438l5 5c.562.563 1.5.563 2.125 0 .562-.625.562-1.562 0-2.125l-5-4.937c.875-1.313 1.437-2.875 1.437-4.5 0-4.438-3.625-8-8-8-4.437 0-8 3.562-8 8Zm12.313 2.5c-.938 1.5-2.563 2.5-4.313 2.5-1.812 0-3.437-1-4.375-2.5-.875-1.563-.875-3.5 0-5 .938-1.563 2.563-2.5 4.375-2.5 1.75 0 3.375.937 4.313 2.5.875 1.5.875 3.437 0 5Z" />
      </g>
    </SvgIcon>
  );
}
