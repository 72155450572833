export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** The `Long` scalar type represents non-fractional signed whole 64-bit numeric values. Long can represent values between -(2^63) and 2^63 - 1. */
  Long: { input: any; output: any };
  /** The `Date` scalar represents an ISO-8601 compliant date type. */
  Date: { input: any; output: any };
  /** The built-in `Decimal` scalar type. */
  Decimal: { input: any; output: any };
};

export type Query = {
  __typename?: 'Query';
  factSheet?: Maybe<BaseFactSheet>;
  /**
   * This query provides the FactSheets for requested FactSheet-Ids or
   * External-Ids. It has the same functionality as the factSheet() query but can
   * handle multiple ids or external ids within one call. This query is intendet
   * for integration systems to reduce the number of API calls when getting
   * FactSheets by its ids or external ids.
   */
  factSheets?: Maybe<SimpleBaseFactSheetConnection>;
  /** Provides all fact sheets based on an optional filter. */
  allFactSheets?: Maybe<BaseFactSheetConnection>;
  allFacetItems?: Maybe<FacetItemConnection>;
  /** Retrieves all log events */
  allLogEvents?: Maybe<LogEventConnection>;
  /** A subscription role. */
  subscriptionRole?: Maybe<SubscriptionRole>;
  /** Provides all subscription roles. */
  allSubscriptionRoles?: Maybe<SubscriptionRoleConnection>;
  /** A tag to put on Fact Sheets. */
  tag?: Maybe<Tag>;
  /** Provides all tags. */
  allTags?: Maybe<TagConnection>;
  /**
   * Retrieves the milestones matching the passed in filter. NOTE: This field
   * definition is not stable yet and may be subject to change at any time.
   */
  allMilestones?: Maybe<MilestoneDataConnection>;
  /**
   * Runs a query to get all or a set of fact sheets based on given filter. For
   * each fact sheet in the result set a mapping is created to a field and all
   * possible values of this field type.
   */
  view?: Maybe<ViewResult>;
  /** Runs a query to get the last x viewed items, based on the user id. */
  recentlyViewed?: Maybe<Array<Maybe<ViewedItem>>>;
  /** A tag group consisting of some tags. */
  tagGroup?: Maybe<TagGroup>;
  /** Provides all tag groups. */
  allTagGroups?: Maybe<TagGroupConnection>;
  /** A document associated with a Fact Sheet. */
  document?: Maybe<Document>;
  /**
   * Retrieves the single relation of a specific relation type between two Fact
   * Sheets. In case of a to-many relation (many-to-many, one-to-many), a relation
   * is uniquely identified by two fact sheet identifiers ('from' and 'to') and the
   * relation type (relToParent, relToRequiredBy, ...). In case of a to-one
   * relation (many-to-one, one-to-one), a relation is already uniquely identified
   * by the 'from' identifier and the relation type.
   */
  relation?: Maybe<BaseRelation>;
  /** Using external geo coding services like google maps or OpenStreetMap to resolve an address string. */
  geoCoding?: Maybe<LocationConnection>;
  apis?: Maybe<ApisConnection>;
  /** @deprecated To follow the GraphQL conventions, please use the GetApiById query. */
  apiByName: ApiType;
  apiById: ApiType;
  applications?: Maybe<ApplicationsConnection>;
  applicationById?: Maybe<ApplicationType>;
  domains?: Maybe<DomainsConnection>;
  domainById: DomainType;
  employees?: Maybe<EmployeesConnection>;
  employeeById?: Maybe<EmployeeType>;
  generalOverheadProducts?: Maybe<GeneralOverheadProductsConnection>;
  generalOverheadProductById: GeneralOverheadProductType;
  openPositions?: Maybe<OpenPositionsConnection>;
  products?: Maybe<ProductsConnection>;
  productById?: Maybe<ProductType>;
  subDomains?: Maybe<SubDomainsConnection>;
  subDomainById: SubDomainType;
  virtualMachines?: Maybe<VirtualMachinesConnection>;
  virtualMachineById: VirtualMachineType;
};

export type QueryFactSheetArgs = {
  id: Scalars['ID']['input'];
  markAsViewed?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryFactSheetsArgs = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  externalIds?: InputMaybe<Array<Scalars['String']['input']>>;
  omitArchivedFactSheets?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryAllFactSheetsArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  factSheetType?: InputMaybe<FactSheetType>;
  filter?: InputMaybe<FilterInput>;
  sort?: InputMaybe<Array<InputMaybe<Sorting>>>;
  hierarchy?: InputMaybe<HierarchyInput>;
};

export type QueryAllFacetItemsArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  factSheetType?: InputMaybe<Scalars['String']['input']>;
  facetKey: Scalars['String']['input'];
  filterText?: InputMaybe<Scalars['String']['input']>;
};

export type QueryAllLogEventsArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  factSheetId: Scalars['ID']['input'];
  eventTypes?: InputMaybe<Array<InputMaybe<EventTypes>>>;
  sinceRev?: InputMaybe<Scalars['Long']['input']>;
  mode?: InputMaybe<EventViewMode>;
};

export type QuerySubscriptionRoleArgs = {
  id: Scalars['ID']['input'];
};

export type QueryAllSubscriptionRolesArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Sorting>>>;
  filter?: InputMaybe<SubscriptionRoleFilter>;
};

export type QueryTagArgs = {
  id: Scalars['ID']['input'];
};

export type QueryAllTagsArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Sorting>>>;
  filter?: InputMaybe<TagQueryFilters>;
};

export type QueryAllMilestonesArgs = {
  filter?: InputMaybe<MilestoneQueryFilter>;
};

export type QueryViewArgs = {
  factSheetType?: InputMaybe<FactSheetType>;
  key?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<FilterInput>;
  viewOption?: InputMaybe<ViewOptionInput>;
};

export type QueryRecentlyViewedArgs = {
  type?: InputMaybe<ViewedItemType>;
  count?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryTagGroupArgs = {
  id: Scalars['ID']['input'];
};

export type QueryAllTagGroupsArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Sorting>>>;
};

export type QueryDocumentArgs = {
  documentId?: InputMaybe<Scalars['ID']['input']>;
  factSheetIdentifier?: InputMaybe<FactSheetIdentifierType>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type QueryRelationArgs = {
  from: FactSheetIdentifierType;
  to: FactSheetIdentifierType;
  type: RelationName;
};

export type QueryGeoCodingArgs = {
  rawAddress: Scalars['String']['input'];
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryApisArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<ApiTypeFilterInput>;
  order?: InputMaybe<Array<ApiTypeSortInput>>;
};

export type QueryApiByNameArgs = {
  input: ApiInput;
};

export type QueryApiByIdArgs = {
  input: ApiInput;
};

export type QueryApplicationsArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<ApplicationTypeFilterInput>;
  order?: InputMaybe<Array<ApplicationTypeSortInput>>;
};

export type QueryApplicationByIdArgs = {
  input: ApplicationInput;
};

export type QueryDomainsArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<DomainTypeFilterInput>;
};

export type QueryDomainByIdArgs = {
  input: DomainInput;
};

export type QueryEmployeesArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<EmployeeTypeFilterInput>;
  order?: InputMaybe<Array<EmployeeTypeSortInput>>;
};

export type QueryEmployeeByIdArgs = {
  input: EmployeeInput;
};

export type QueryGeneralOverheadProductsArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<GeneralOverheadProductTypeFilterInput>;
};

export type QueryGeneralOverheadProductByIdArgs = {
  input: GeneralOverheadProductInput;
};

export type QueryOpenPositionsArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<OpenPositionTypeFilterInput>;
  order?: InputMaybe<Array<OpenPositionTypeSortInput>>;
};

export type QueryProductsArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<ProductTypeFilterInput>;
  order?: InputMaybe<Array<ProductTypeSortInput>>;
};

export type QueryProductByIdArgs = {
  input: ProductInput;
  order?: InputMaybe<Array<ProductTypeSortInput>>;
};

export type QuerySubDomainsArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<SubDomainTypeFilterInput>;
};

export type QuerySubDomainByIdArgs = {
  input: SubDomainInput;
};

export type QueryVirtualMachinesArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<VirtualMachineTypeFilterInput>;
  order?: InputMaybe<Array<VirtualMachineTypeSortInput>>;
};

export type QueryVirtualMachineByIdArgs = {
  input: VirtualMachineInput;
};

/** The basic Fact Sheet object */
export type BaseFactSheet = {
  /** The id of the Fact Sheet */
  id: Scalars['ID']['output'];
  /** The revision of the Fact Sheet, incremented automatically on each Fact Sheet update. */
  rev?: Maybe<Scalars['Long']['output']>;
  /** The type of the fact sheet, eg: Application, ITComponent, etc. */
  type: FactSheetType;
  /** This can be used to categorize the Fact Sheet type into subtypes. */
  category?: Maybe<Scalars['String']['output']>;
  /** The name of the Fact Sheet. */
  name: Scalars['String']['output'];
  /** The calculated display name of the Fact Sheet. */
  displayName: Scalars['String']['output'];
  /** The calculated full name of the Fact Sheet. */
  fullName: Scalars['String']['output'];
  /** An optional description of the Fact Sheet. */
  description?: Maybe<Scalars['String']['output']>;
  /**
   * An array of attribute names that indicates which fields or relations of this
   * Fact Sheet have intentionally not been set. These attributes are thus not used
   * for completeness calculation.
   */
  naFields?: Maybe<Array<Scalars['String']['output']>>;
  /** The tags that are associated with the Fact Sheet. */
  tags?: Maybe<Array<Tag>>;
  /** All documents that are assigned to the Fact Sheet. */
  documents?: Maybe<DocumentConnection>;
  /** Comments of a Fact Sheet */
  comments?: Maybe<CommentConnection>;
  /** All subscriptions that are assigned to the Fact Sheet. */
  subscriptions?: Maybe<SubscriptionConnection>;
  /** A list of Access Control Entities defining which user in the workspace has read access to this Fact Sheet. */
  permittedReadACL?: Maybe<Array<AccessControlEntityReference>>;
  /** A list of Access Control Entities defining which user in the workspace has write access to this Fact Sheet. */
  permittedWriteACL?: Maybe<Array<AccessControlEntityReference>>;
  /** Status of the Fact Sheet. */
  status?: Maybe<FactSheetStatus>;
  /** The hierarchy level of the Fact Sheet.  (0 means level 1, 1 means level 2, etc.) */
  level?: Maybe<Scalars['Int']['output']>;
  /**
   * The timestamp in ISO 8601 format when the Fact Sheet was created. This field
   * is read-only and will never change during the lifecycle of the FactSheet.
   */
  createdAt: Scalars['String']['output'];
  /** The timestamp in ISO 8601 format when the Fact Sheet was last updated. */
  updatedAt: Scalars['String']['output'];
  /** Indicates how much data is already provided in the Fact Sheet, its sections and subsections. */
  completion?: Maybe<FactSheetCompletion>;
  /** Contains a list for the permissions for this instance and a list of fields for each CRUD operation. */
  permissions: Permissions;
  /**
   * The status of the quality seal for this Fact Sheet
   * @deprecated Use the field 'lxState' instead. The 'qualitySeal' maps the values 'DRAFT' and 'REJECTED' that exist on 'lxState' to 'BROKEN.
   */
  qualitySeal?: Maybe<QualitySealStatus>;
  /** State of the Fact Sheet. */
  lxState?: Maybe<FactSheetState>;
  /** Milestones on the Fact Sheet. */
  lxMilestones?: Maybe<Array<Milestone>>;
  /** Whether the fact sheet should be excluded from the quota check or not. */
  lxExcludeFromQuota: Scalars['Boolean']['output'];
};

/** The basic Fact Sheet object */
export type BaseFactSheetDocumentsArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  filter?: InputMaybe<DocumentFilterInput>;
};

/** The basic Fact Sheet object */
export type BaseFactSheetCommentsArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  filter?: InputMaybe<CommentFilterInput>;
};

/** The basic Fact Sheet object */
export type BaseFactSheetSubscriptionsArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  filter?: InputMaybe<SubscriptionFilterInput>;
};

/** All Fact Sheet types that are defined in the workspace data model definition. */
export enum FactSheetType {
  BusinessCapability = 'BusinessCapability',
  Process = 'Process',
  UserGroup = 'UserGroup',
  Project = 'Project',
  Application = 'Application',
  Interface = 'Interface',
  DataObject = 'DataObject',
  ItComponent = 'ITComponent',
  Provider = 'Provider',
  TechnicalStack = 'TechnicalStack',
  Product = 'Product',
  FactSheetOfUnknownType = 'FactSheetOfUnknownType',
}

/** Information that can be tagged to a Fact Sheet. */
export type Tag = {
  __typename?: 'Tag';
  /** The ID of the tag. */
  id: Scalars['ID']['output'];
  /** The name of the tag. */
  name: Scalars['String']['output'];
  /** A description for the tag. */
  description?: Maybe<Scalars['String']['output']>;
  /** The hash of color used to display the tag in the form "#000000". */
  color: Scalars['String']['output'];
  /** The tag group this tag is a member of */
  tagGroup?: Maybe<TagGroup>;
  /** Indicates if the tag is active or archived. */
  status?: Maybe<TagStatusEnum>;
  /**
   * The number of Fact Sheets that are tagged with this tag (takes virtual
   * workspaces into account, in case they are activated).
   */
  factSheetCount: Scalars['Int']['output'];
  /** Returns true if it's possible to delete the flag: no fact sheets in the whole workspace are tagged with this tag. */
  deletable: Scalars['Boolean']['output'];
};

/** Collects tags that are related semantically. */
export type TagGroup = {
  __typename?: 'TagGroup';
  /** The ID of the tag group. */
  id: Scalars['ID']['output'];
  /** The name of the tag group. */
  name: Scalars['String']['output'];
  /** A short designation or acronym naming the tag group. */
  shortName?: Maybe<Scalars['String']['output']>;
  /** A description for the tag group. */
  description?: Maybe<Scalars['String']['output']>;
  /** Determines if a Fact Sheet can have more than one tag from this tag group. */
  mode: TagGroupModeEnum;
  /** All Fact Sheets applicable to this tag group must be tagged with at least one tag from the group. */
  mandatory?: Maybe<Scalars['Boolean']['output']>;
  /** If nonempty, tags in this tag group may be associated to Fact Sheets of the listed types only. */
  restrictToFactSheetTypes: Array<FactSheetType>;
  /** A list of Fact Sheet types. The tag group is mandatory for the defined types. */
  mandatoryForFactSheetTypes?: Maybe<Array<FactSheetType>>;
  /** The tags that this tag group consists of. */
  tags: TagConnection;
  /** The number of tags that this tag group consists of. */
  tagCount: Scalars['Int']['output'];
};

/** Collects tags that are related semantically. */
export type TagGroupTagsArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export enum TagGroupModeEnum {
  /** A Fact Sheet can have at most one tag from this tag group. */
  Single = 'SINGLE',
  /** A Fact Sheet can have several tags from this tag group. */
  Multiple = 'MULTIPLE',
}

/** A connection to a list of items. */
export type TagConnection = {
  __typename?: 'TagConnection';
  edges?: Maybe<Array<Maybe<TagEdge>>>;
  pageInfo: PageInfo;
  /**
   * A count of the total number of objects in this collection, ignoring
   * pagination. This allows a client to fetch the first five objects by passing
   * "5" as the argument to "limit", then fetch the total count so it could display
   * "5 of 83", for example.
   */
  totalCount: Scalars['Int']['output'];
  /** The requested tags */
  asList: Array<Maybe<Tag>>;
};

/** An edge in a connection. */
export type TagEdge = {
  __typename?: 'TagEdge';
  /** The item at the end of the edge */
  node?: Maybe<Tag>;
  cursor: Scalars['String']['output'];
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** Indicates whether more edges exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean']['output'];
  /** Indicates whether more edges exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
};

export enum TagStatusEnum {
  /** The tag can be fully used. */
  Active = 'ACTIVE',
  /** Fact Sheets remain tagged with this tag, but the tag can not be added to a Fact Sheet. */
  Archived = 'ARCHIVED',
}

/** A connection to a list of items. */
export type DocumentConnection = {
  __typename?: 'DocumentConnection';
  edges?: Maybe<Array<Maybe<DocumentEdge>>>;
  pageInfo: PageInfo;
  /**
   * A count of the total number of objects in this collection, ignoring
   * pagination. This allows a client to fetch the first five objects by passing
   * "5" as the argument to "limit", then fetch the total count so it could display
   * "5 of 83", for example.
   */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type DocumentEdge = {
  __typename?: 'DocumentEdge';
  /** The item at the end of the edge */
  node?: Maybe<Document>;
  cursor: Scalars['String']['output'];
};

/** A document that can be attached to a Fact Sheet. */
export type Document = {
  __typename?: 'Document';
  /** The id of the document */
  id: Scalars['ID']['output'];
  /** The name of the document */
  name: Scalars['String']['output'];
  /** The name of the document */
  description?: Maybe<Scalars['String']['output']>;
  /** The url to the document */
  url?: Maybe<Scalars['String']['output']>;
  /** The origin of the document, e.g. a certain application, database or integration. */
  origin?: Maybe<Scalars['String']['output']>;
  /** The time stamp in ISO 8601 format when the document was uploaded. */
  createdAt: Scalars['String']['output'];
  /** The type of the documents. */
  documentType?: Maybe<Scalars['String']['output']>;
  /**
   * A field used to store the ID of the referenced object. E.g. in cases where the
   * document links to an object in another system.
   */
  refId?: Maybe<Scalars['String']['output']>;
  /** Metadata for Fact Sheet links. */
  metadata?: Maybe<Scalars['String']['output']>;
  /**
   * A type containing information to the uploaded file. If a document is only an
   * url and not based of a file upload, this field will be null.
   */
  fileInformation?: Maybe<FileInformation>;
  /** In case that the document is assigned to a Fact Sheet this is the assigned Fact Sheet. */
  factSheetId?: Maybe<Scalars['ID']['output']>;
};

/**
 * Information that describes the uploaded document file. This information is only
 * present when a file was uploaded before and it is only readable and not present
 * in document mutations.
 */
export type FileInformation = {
  __typename?: 'FileInformation';
  /** The origin file name */
  fileName: Scalars['String']['output'];
  /** A key used to pick the relevant legend item */
  size: Scalars['Int']['output'];
  /** The media type of the document */
  mediaType?: Maybe<Scalars['String']['output']>;
  /** The binary stream in base 64 format. */
  content?: Maybe<Scalars['String']['output']>;
  /**
   * The binary stream in base 64 format of an preview image. This is only
   * available for document types that can generate a preview image, eg. images or videos.
   */
  previewImage?: Maybe<Scalars['String']['output']>;
};

/** Input filter for documents. */
export type DocumentFilterInput = {
  /** Filter documents by their origin. */
  origin?: InputMaybe<Scalars['String']['input']>;
  /** when filtering for origin, also include documents where origin is null */
  includeNullOrigin?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filter documents by their document type. */
  documentType?: InputMaybe<Scalars['String']['input']>;
  /** when filtering for documentType, also include documents where documentType is null */
  includeNullDocumentType?: InputMaybe<Scalars['Boolean']['input']>;
};

/** A connection to a list of items. */
export type CommentConnection = {
  __typename?: 'CommentConnection';
  edges?: Maybe<Array<Maybe<CommentEdge>>>;
  pageInfo: PageInfo;
  /**
   * A count of the total number of objects in this collection, ignoring
   * pagination. This allows a client to fetch the first five objects by passing
   * "5" as the argument to "limit", then fetch the total count so it could display
   * "5 of 83", for example.
   */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type CommentEdge = {
  __typename?: 'CommentEdge';
  /** The item at the end of the edge */
  node?: Maybe<Comment>;
  cursor: Scalars['String']['output'];
};

/** A comment to a Fact Sheet */
export type Comment = Node & {
  __typename?: 'Comment';
  /** The ID of the comment. */
  id: Scalars['ID']['output'];
  /** The user who submitted the comment. */
  user: User;
  /** The ID of the Fact Sheet that this comment references. */
  factSheetId: Scalars['ID']['output'];
  /** The text of the comment. */
  message: Scalars['String']['output'];
  /** The status of the comment. */
  status: CommentStatus;
  /** The timestamp in ISO 8601 format of the creation of the comment. */
  createdAt: Scalars['String']['output'];
  /** The replies to this comment */
  replies?: Maybe<Array<Maybe<Reply>>>;
};

/** An object with an ID */
export type Node = {
  /** The ID of an object */
  id: Scalars['ID']['output'];
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: Application */
export type Application = Node &
  BaseFactSheet & {
    __typename?: 'Application';
    /** The graphQL id used to retrieve each node (object of arbitrary type) */
    id: Scalars['ID']['output'];
    /** The revision of the Fact Sheet, incremented automatically on each Fact Sheet update. */
    rev?: Maybe<Scalars['Long']['output']>;
    /** The name of the Application. */
    name: Scalars['String']['output'];
    /** The calculated display name of the Fact Sheet. */
    displayName: Scalars['String']['output'];
    /** The calculated full name of the Fact Sheet. */
    fullName: Scalars['String']['output'];
    /** The type of the Fact Sheet. (Same as parameter __typeName) */
    type: FactSheetType;
    /** This can be used to categorize the Fact Sheet type into subtypes. */
    category?: Maybe<Scalars['String']['output']>;
    /** An optional description of the Application. */
    description?: Maybe<Scalars['String']['output']>;
    /** Indicates how much data is already provided in the Fact Sheet, its sections and subsections. */
    completion?: Maybe<FactSheetCompletion>;
    /**
     * An array of attribute names that indicates which fields or relations of this
     * Fact Sheet have intentionally not been set. These attributes are thus not used
     * for completeness calculation.
     */
    naFields?: Maybe<Array<Scalars['String']['output']>>;
    /** The tags that are associated with the Fact Sheet. */
    tags?: Maybe<Array<Tag>>;
    /** All documents that are assigned to the Fact Sheet. */
    documents?: Maybe<DocumentConnection>;
    /** Comments on a Fact Sheet */
    comments?: Maybe<CommentConnection>;
    /** All subscriptions that are assigned to the Fact Sheet. */
    subscriptions?: Maybe<SubscriptionConnection>;
    /** A list of Access Control Entities defining which user in the workspace has read access to this Fact Sheet. */
    permittedReadACL?: Maybe<Array<AccessControlEntityReference>>;
    /** A list of Access Control Entities defining which user in the workspace has write access to this Fact Sheet. */
    permittedWriteACL?: Maybe<Array<AccessControlEntityReference>>;
    /** Status of the Fact Sheet. */
    status?: Maybe<FactSheetStatus>;
    /** The hierarchy level of the Fact Sheet. (0 means level 1, 1 means level 2, etc.) */
    level?: Maybe<Scalars['Int']['output']>;
    /**
     * The timestamp in ISO 8601 format when the Fact Sheet was created. This field
     * is read-only and will never change during the lifecycle of the FactSheet.
     */
    createdAt: Scalars['String']['output'];
    /** The timestamp in ISO 8601 format when the Fact Sheet was last updated. */
    updatedAt: Scalars['String']['output'];
    /** Contains a list for the permissions for this instance and a list of fields for each CRUD operation. */
    permissions: Permissions;
    /** The status of the quality seal for this Fact Sheet */
    qualitySeal?: Maybe<QualitySealStatus>;
    /** State of the Fact Sheet. */
    lxState?: Maybe<FactSheetState>;
    /** Milestones on the Fact Sheet. */
    lxMilestones?: Maybe<Array<Milestone>>;
    /** Whether the fact sheet should be excluded from the quota check or not. */
    lxExcludeFromQuota: Scalars['Boolean']['output'];
    /** lifecycle */
    lifecycle?: Maybe<ApplicationLifecycle>;
    functionalSuitabilityDescription?: Maybe<Scalars['String']['output']>;
    technicalSuitabilityDescription?: Maybe<Scalars['String']['output']>;
    /** functionalSuitability */
    functionalSuitability?: Maybe<ApplicationFunctionalSuitability>;
    /** technicalSuitability */
    technicalSuitability?: Maybe<ApplicationTechnicalSuitability>;
    /** businessCriticality */
    businessCriticality?: Maybe<ApplicationBusinessCriticality>;
    release?: Maybe<Scalars['String']['output']>;
    businessCriticalityDescription?: Maybe<Scalars['String']['output']>;
    alias?: Maybe<Scalars['String']['output']>;
    /** processingPurpose */
    processingPurpose?: Maybe<Array<Maybe<ApplicationProcessingPurpose>>>;
    /** legalBasis */
    legalBasis?: Maybe<ApplicationLegalBasis>;
    legalBasisDescription?: Maybe<Scalars['String']['output']>;
    TOMDescription?: Maybe<Scalars['String']['output']>;
    /** orderingState */
    orderingState?: Maybe<ApplicationOrderingState>;
    /** aggregatedObsolescenceRisk */
    aggregatedObsolescenceRisk?: Maybe<ApplicationAggregatedObsolescenceRisk>;
    InformationSystemContingencyPlan?: Maybe<Scalars['String']['output']>;
    IscpUpdated?: Maybe<Scalars['String']['output']>;
    DPMDId?: Maybe<Scalars['Int']['output']>;
    /** Question1 */
    Question1?: Maybe<ApplicationQuestion1>;
    /** Question2 */
    Question2?: Maybe<ApplicationQuestion2>;
    /** Question3 */
    Question3?: Maybe<ApplicationQuestion3>;
    /** lxCatalogStatus */
    lxCatalogStatus?: Maybe<ApplicationLxCatalogStatus>;
    lxProductCategory?: Maybe<Scalars['String']['output']>;
    /** lxHostingType */
    lxHostingType?: Maybe<ApplicationLxHostingType>;
    lxHostingDescription?: Maybe<Scalars['String']['output']>;
    lxSsoProvider?: Maybe<Scalars['String']['output']>;
    /** lxStatusSSO */
    lxStatusSSO?: Maybe<ApplicationLxStatusSso>;
    lxSsoProviderSMP?: Maybe<Scalars['String']['output']>;
    /** lxStatusSSOSMP */
    lxStatusSSOSMP?: Maybe<ApplicationLxStatusSsosmp>;
    lxActiveUsers?: Maybe<Scalars['Int']['output']>;
    lxYearToDate?: Maybe<Scalars['Float']['output']>;
    lxSpendMonth?: Maybe<Scalars['Float']['output']>;
    /** Recoverytimeobjectivetimeunit */
    Recoverytimeobjectivetimeunit?: Maybe<ApplicationRecoverytimeobjectivetimeunit>;
    /** Recoverypointobjectivetimeunit */
    Recoverypointobjectivetimeunit?: Maybe<ApplicationRecoverypointobjectivetimeunit>;
    TimeqtyRTO?: Maybe<Scalars['Int']['output']>;
    TimeqtyRPO?: Maybe<Scalars['Int']['output']>;
    externalId?: Maybe<ExternalId>;
    applicationId?: Maybe<ExternalId>;
    signavioGlossaryItemId?: Maybe<ExternalId>;
    serviceNowExternalId?: Maybe<ExternalId>;
    lxSiId?: Maybe<ExternalId>;
    lxCatalogId?: Maybe<ExternalId>;
    relToParent?: Maybe<RelToParentConnection>;
    relToChild?: Maybe<RelToChildConnection>;
    relToRequires?: Maybe<RelToRequiresConnection>;
    relToRequiredBy?: Maybe<RelToRequiredByConnection>;
    relToSuccessor?: Maybe<RelToSuccessorConnection>;
    relToPredecessor?: Maybe<RelToPredecessorConnection>;
    relApplicationToUserGroup?: Maybe<RelApplicationToUserGroupConnection>;
    relApplicationToDataObject?: Maybe<RelApplicationToDataObjectConnection>;
    relApplicationToITComponent?: Maybe<RelApplicationToItComponentConnection>;
    relApplicationToProject?: Maybe<RelApplicationToProjectConnection>;
    relProviderApplicationToInterface?: Maybe<RelProviderApplicationToInterfaceConnection>;
    relConsumerApplicationToInterface?: Maybe<RelConsumerApplicationToInterfaceConnection>;
    relApplicationToProcess?: Maybe<RelApplicationToProcessConnection>;
    relApplicationToBusinessCapability?: Maybe<RelApplicationToBusinessCapabilityConnection>;
    relApplicationToProduct?: Maybe<RelApplicationToProductConnection>;
  };

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: Application */
export type ApplicationDocumentsArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  filter?: InputMaybe<DocumentFilterInput>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: Application */
export type ApplicationCommentsArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  filter?: InputMaybe<CommentFilterInput>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: Application */
export type ApplicationSubscriptionsArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  filter?: InputMaybe<SubscriptionFilterInput>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: Application */
export type ApplicationRelToParentArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: Application */
export type ApplicationRelToChildArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: Application */
export type ApplicationRelToRequiresArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: Application */
export type ApplicationRelToRequiredByArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: Application */
export type ApplicationRelToSuccessorArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: Application */
export type ApplicationRelToPredecessorArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: Application */
export type ApplicationRelApplicationToUserGroupArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: Application */
export type ApplicationRelApplicationToDataObjectArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: Application */
export type ApplicationRelApplicationToItComponentArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: Application */
export type ApplicationRelApplicationToProjectArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: Application */
export type ApplicationRelProviderApplicationToInterfaceArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: Application */
export type ApplicationRelConsumerApplicationToInterfaceArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: Application */
export type ApplicationRelApplicationToProcessArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: Application */
export type ApplicationRelApplicationToBusinessCapabilityArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: Application */
export type ApplicationRelApplicationToProductArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** Information about how much data is provided in a fact sheet, its sections and subsections */
export type FactSheetCompletion = {
  __typename?: 'FactSheetCompletion';
  completion?: Maybe<Scalars['Float']['output']>;
  percentage?: Maybe<Scalars['Int']['output']>;
  sectionCompletions: Array<Maybe<FactSheetSectionCompletion>>;
};

/** Information about how much data is provided in a fact sheet's sections and their subsections */
export type FactSheetSectionCompletion = {
  __typename?: 'FactSheetSectionCompletion';
  /** The name of the section */
  name?: Maybe<Scalars['String']['output']>;
  completion?: Maybe<Scalars['Float']['output']>;
  percentage?: Maybe<Scalars['Int']['output']>;
  subSectionCompletions: Array<Maybe<FactSheetSubSectionCompletion>>;
};

/** Information about how much data is provided in a subsection of a fact sheet's section */
export type FactSheetSubSectionCompletion = {
  __typename?: 'FactSheetSubSectionCompletion';
  /** The name of the subsection */
  name?: Maybe<Scalars['String']['output']>;
  completion?: Maybe<Scalars['Float']['output']>;
  percentage?: Maybe<Scalars['Int']['output']>;
};

/** Input filter for comments. */
export type CommentFilterInput = {
  /** Filter comments by an id. */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Filter comments by a status. */
  status?: InputMaybe<CommentStatus>;
};

/** The different statuses a comment can have. */
export enum CommentStatus {
  /** The comment is open to discussion and never was CLOSED. */
  Active = 'ACTIVE',
  /** The comment is closed but may be re-opened. */
  Closed = 'CLOSED',
  /** The comment is open to discussion. It once was CLOSED but has been re-opened. */
  Reopened = 'REOPENED',
}

/** A connection to a list of items. */
export type SubscriptionConnection = {
  __typename?: 'SubscriptionConnection';
  edges?: Maybe<Array<Maybe<SubscriptionEdge>>>;
  pageInfo: PageInfo;
  /** A count of the total number of objects in this collection, ignoring pagination. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type SubscriptionEdge = {
  __typename?: 'SubscriptionEdge';
  /** The item at the end of the edge */
  node?: Maybe<Subscription>;
  cursor: Scalars['String']['output'];
};

/** A subscription that is assigned to a Fact Sheet. */
export type Subscription = Node & {
  __typename?: 'Subscription';
  /** The id of the subscription */
  id: Scalars['ID']['output'];
  /**
   * The user that is assigned to the subscription. Set either the id or email. If
   * the user cannot be found via the given email, it is created automatically with
   * the given firstName and lastName.
   */
  user: User;
  /** The type of the subscription. */
  type: FactSheetSubscriptionType;
  /** The role the user has for this subscription. */
  roles?: Maybe<Array<SubscriptionToSubscriptionRoleLink>>;
  /** The timestamp in ISO 8601 format this subscription was created. */
  createdAt: Scalars['String']['output'];
  /** The Fact Sheet that is assigned to the subscription */
  factSheet: BaseFactSheet;
};

/** Information about a user. */
export type User = Node & {
  __typename?: 'User';
  /** The id of the user. */
  id: Scalars['ID']['output'];
  /** The user's username. */
  userName?: Maybe<Scalars['String']['output']>;
  /** The user's email address. */
  email?: Maybe<Scalars['String']['output']>;
  /** The user's first name. */
  firstName?: Maybe<Scalars['String']['output']>;
  /** The user's last name. */
  lastName?: Maybe<Scalars['String']['output']>;
  /** The user's full name (if first or lastname not set, email is used). */
  displayName?: Maybe<Scalars['String']['output']>;
  /** True if the user is a technical user. */
  technicalUser?: Maybe<Scalars['Boolean']['output']>;
  /**
   * A permission object that provides a set of permission information for this
   * user in the current workspace. Only the active Permission will be shown here.
   */
  permission?: Maybe<Permission>;
  status: UserStatus;
  /**
   * User's role which comes from the Service Providers. This role is NOT relevant
   * to check user's permission within this workspace! If you want to check the
   * user's role in pathfinder's workspace use the role attribute in the Permission
   * object instead.
   */
  role: UserRole;
};

/** The permission of a user of the current workspace */
export type Permission = {
  __typename?: 'Permission';
  role?: Maybe<PermissionRole>;
  status?: Maybe<PermissionStatus>;
};

/** The roles that can be assigned to a permission */
export enum PermissionRole {
  Admin = 'ADMIN',
  Member = 'MEMBER',
  Viewer = 'VIEWER',
  Contact = 'CONTACT',
}

/** The roles that can be assigned to a permission */
export enum PermissionStatus {
  Notinvited = 'NOTINVITED',
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Invited = 'INVITED',
  Requested = 'REQUESTED',
  Anonymized = 'ANONYMIZED',
}

/** The actual status of the user */
export enum UserStatus {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
}

/** The permission of a user of the current workspace */
export enum UserRole {
  Superadmin = 'SUPERADMIN',
  System = 'SYSTEM',
  Apiclient = 'APICLIENT',
  Accountadmin = 'ACCOUNTADMIN',
  Accountuser = 'ACCOUNTUSER',
}

/** The type of the subscription that is assigned to the fact sheet. */
export enum FactSheetSubscriptionType {
  Accountable = 'ACCOUNTABLE',
  Responsible = 'RESPONSIBLE',
  Observer = 'OBSERVER',
}

/**
 * The role the user has when subscribing to a Fact Sheet including additional role
 * details in context of the current subscription
 */
export type SubscriptionToSubscriptionRoleLink = {
  __typename?: 'SubscriptionToSubscriptionRoleLink';
  /** The id of the subscription role. */
  id: Scalars['String']['output'];
  /** The name of the subscription role. */
  name: Scalars['String']['output'];
  /**
   * The description of the subscription role.
   * @deprecated Use the queries 'subscriptionRole' or 'allSubscriptionRoles' instead.
   */
  description?: Maybe<Scalars['String']['output']>;
  /** A comment with details about the subscription role in context of the current subscription. */
  comment?: Maybe<Scalars['String']['output']>;
  /**
   * Determines to which subscriptions this role can be assigned.
   * @deprecated Use the queries 'subscriptionRole' or 'allSubscriptionRoles' instead.
   */
  subscriptionType?: Maybe<FactSheetSubscriptionType>;
  /**
   * The Fact Sheet Types this subscription role can be assigned to.
   * @deprecated Use the queries 'subscriptionRole' or 'allSubscriptionRoles' instead.
   */
  restrictToFactSheetTypes: Array<Scalars['String']['output']>;
};

/** Input filter for subscriptions. */
export type SubscriptionFilterInput = {
  /** Filter subscriptions by an id. */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Filter subscriptions by a user id. */
  userId?: InputMaybe<Scalars['ID']['input']>;
  /** Filter subscriptions by the type of the subscription. */
  type?: InputMaybe<FactSheetSubscriptionType>;
};

/** References an Access Control Entity by name or by id. */
export type AccessControlEntityReference = {
  __typename?: 'AccessControlEntityReference';
  /** The id of the Access Control Entity. */
  id: Scalars['String']['output'];
  /** The name of the Access Control Entity. */
  name: Scalars['String']['output'];
};

/** The status of the Fact Sheet */
export enum FactSheetStatus {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
}

/** Lists create, read, update and delete permission for fields. */
export type Permissions = {
  __typename?: 'Permissions';
  /**
   * Contains the CRUD permissions for this object. 'null' in case that is not
   * possible to reveal information about this action at this point.
   */
  self?: Maybe<Array<Maybe<PermissibleAction>>>;
  /**
   * Contains a list of all creatable fields. 'null' in case that is not possible
   * to reveal information about this action at this point.
   */
  create?: Maybe<Array<Scalars['String']['output']>>;
  /**
   * Contains a list of all readable fields. 'null' in case that is not possible to
   * reveal information about this action at this point.
   */
  read?: Maybe<Array<Scalars['String']['output']>>;
  /**
   * Contains a list of all updatable fields. 'null' in case that is not possible
   * to reveal information about this action at this point.
   */
  update?: Maybe<Array<Scalars['String']['output']>>;
  /**
   * Contains a list of all deletable fields. 'null' in case that is not possible
   * to reveal information about this action at this point.
   */
  delete?: Maybe<Array<Scalars['String']['output']>>;
};

/** Permission rights describing the access of an field or object. */
export enum PermissibleAction {
  Read = 'READ',
  Update = 'UPDATE',
  Create = 'CREATE',
  Delete = 'DELETE',
  Archive = 'ARCHIVE',
  Import = 'IMPORT',
  Export = 'EXPORT',
  InlineEdit = 'INLINE_EDIT',
  Unlock = 'UNLOCK',
  ChangeOwner = 'CHANGE_OWNER',
  ManagePredefined = 'MANAGE_PREDEFINED',
  ManageContent = 'MANAGE_CONTENT',
  Administrate = 'ADMINISTRATE',
}

/** The status of the quality seal of current Fact Sheet */
export enum QualitySealStatus {
  Disabled = 'DISABLED',
  Approved = 'APPROVED',
  Broken = 'BROKEN',
}

/** The state of the Fact Sheet */
export enum FactSheetState {
  BrokenQualitySeal = 'BROKEN_QUALITY_SEAL',
  Draft = 'DRAFT',
  Rejected = 'REJECTED',
  Approved = 'APPROVED',
}

/** This is a milestone */
export type Milestone = {
  __typename?: 'Milestone';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['String']['output']>;
};

export type ApplicationLifecycle = DynamicDataType & {
  __typename?: 'ApplicationLifecycle';
  asString?: Maybe<Scalars['String']['output']>;
  /** All phases in order of this lifecycle */
  phases?: Maybe<Array<Maybe<ApplicationLifecyclePhase>>>;
};

export type DynamicDataType = {
  /** This fields provides a string used to simplify rendering the content of dynamic data objects. */
  asString?: Maybe<Scalars['String']['output']>;
};

export type BusinessCapabilityLifecycle = DynamicDataType & {
  __typename?: 'BusinessCapabilityLifecycle';
  asString?: Maybe<Scalars['String']['output']>;
  /** All phases in order of this lifecycle */
  phases?: Maybe<Array<Maybe<BusinessCapabilityLifecyclePhase>>>;
};

export type BusinessCapabilityLifecyclePhase = {
  __typename?: 'BusinessCapabilityLifecyclePhase';
  /** The phase of the lifecycle. */
  phase: BusinessCapabilityLifecycleName;
  /** The ISO 8601 date format of a lifecycle start */
  startDate: Scalars['String']['output'];
  /** The id of a referenced milestone defining the startDate of the phase. */
  milestoneId?: Maybe<Scalars['String']['output']>;
};

/** The allowed lifecycle phases */
export enum BusinessCapabilityLifecycleName {
  Plan = 'plan',
  PhaseIn = 'phaseIn',
  Active = 'active',
  PhaseOut = 'phaseOut',
  EndOfLife = 'endOfLife',
}

export type DataObjectLifecycle = DynamicDataType & {
  __typename?: 'DataObjectLifecycle';
  asString?: Maybe<Scalars['String']['output']>;
  /** All phases in order of this lifecycle */
  phases?: Maybe<Array<Maybe<DataObjectLifecyclePhase>>>;
};

export type DataObjectLifecyclePhase = {
  __typename?: 'DataObjectLifecyclePhase';
  /** The phase of the lifecycle. */
  phase: DataObjectLifecycleName;
  /** The ISO 8601 date format of a lifecycle start */
  startDate: Scalars['String']['output'];
  /** The id of a referenced milestone defining the startDate of the phase. */
  milestoneId?: Maybe<Scalars['String']['output']>;
};

/** The allowed lifecycle phases */
export enum DataObjectLifecycleName {
  Plan = 'plan',
  PhaseIn = 'phaseIn',
  Active = 'active',
  PhaseOut = 'phaseOut',
  EndOfLife = 'endOfLife',
}

export type ItComponentLifecycle = DynamicDataType & {
  __typename?: 'ITComponentLifecycle';
  asString?: Maybe<Scalars['String']['output']>;
  /** All phases in order of this lifecycle */
  phases?: Maybe<Array<Maybe<ItComponentLifecyclePhase>>>;
};

export type ItComponentLifecyclePhase = {
  __typename?: 'ITComponentLifecyclePhase';
  /** The phase of the lifecycle. */
  phase: ItComponentLifecycleName;
  /** The ISO 8601 date format of a lifecycle start */
  startDate: Scalars['String']['output'];
  /** The id of a referenced milestone defining the startDate of the phase. */
  milestoneId?: Maybe<Scalars['String']['output']>;
};

/** The allowed lifecycle phases */
export enum ItComponentLifecycleName {
  Plan = 'plan',
  PhaseIn = 'phaseIn',
  Active = 'active',
  PhaseOut = 'phaseOut',
  EndOfLife = 'endOfLife',
}

export type InterfaceLifecycle = DynamicDataType & {
  __typename?: 'InterfaceLifecycle';
  asString?: Maybe<Scalars['String']['output']>;
  /** All phases in order of this lifecycle */
  phases?: Maybe<Array<Maybe<InterfaceLifecyclePhase>>>;
};

export type InterfaceLifecyclePhase = {
  __typename?: 'InterfaceLifecyclePhase';
  /** The phase of the lifecycle. */
  phase: InterfaceLifecycleName;
  /** The ISO 8601 date format of a lifecycle start */
  startDate: Scalars['String']['output'];
  /** The id of a referenced milestone defining the startDate of the phase. */
  milestoneId?: Maybe<Scalars['String']['output']>;
};

/** The allowed lifecycle phases */
export enum InterfaceLifecycleName {
  Plan = 'plan',
  PhaseIn = 'phaseIn',
  Active = 'active',
  PhaseOut = 'phaseOut',
  EndOfLife = 'endOfLife',
}

/** The location of a Fact Sheet. */
export type Location = DynamicDataType & {
  __typename?: 'Location';
  /** A place id that was given by external geo coding systems */
  placeId?: Maybe<Scalars['String']['output']>;
  asString?: Maybe<Scalars['String']['output']>;
  /** A raw address for the location which was given by the user. */
  rawAddress?: Maybe<Scalars['String']['output']>;
  /** The geographic latitude of the location. */
  latitude?: Maybe<Scalars['Float']['output']>;
  /** The geographic longitude of the location. */
  longitude?: Maybe<Scalars['Float']['output']>;
  /** The city of the location. */
  geoCity?: Maybe<Scalars['String']['output']>;
  /** The ISO 8601 country code of the location. */
  geoCountryCode?: Maybe<Scalars['String']['output']>;
  /** The country of the location. */
  geoCountry?: Maybe<Scalars['String']['output']>;
  /**
   * The address of the location as resolved by external geo coding system. For
   * example, this field is often called 'display_name' (in OpenStreetMap) and can
   * be very long, like 'Ochtruper Straße, Gronau, Kreis Borken, Regierungsbezirk
   * Münster, Nordrhein-Westfalen, 48599, Deutschland'.
   */
  geoAddress?: Maybe<Scalars['String']['output']>;
  /** The street of the location. */
  geoStreet?: Maybe<Scalars['String']['output']>;
  /** The street of the location. */
  geoHouseNumber?: Maybe<Scalars['String']['output']>;
  /** The postal code of the location. */
  geoPostalCode?: Maybe<Scalars['String']['output']>;
};

export type ProcessLifecycle = DynamicDataType & {
  __typename?: 'ProcessLifecycle';
  asString?: Maybe<Scalars['String']['output']>;
  /** All phases in order of this lifecycle */
  phases?: Maybe<Array<Maybe<ProcessLifecyclePhase>>>;
};

export type ProcessLifecyclePhase = {
  __typename?: 'ProcessLifecyclePhase';
  /** The phase of the lifecycle. */
  phase: ProcessLifecycleName;
  /** The ISO 8601 date format of a lifecycle start */
  startDate: Scalars['String']['output'];
  /** The id of a referenced milestone defining the startDate of the phase. */
  milestoneId?: Maybe<Scalars['String']['output']>;
};

/** The allowed lifecycle phases */
export enum ProcessLifecycleName {
  Plan = 'plan',
  PhaseIn = 'phaseIn',
  Active = 'active',
  PhaseOut = 'phaseOut',
  EndOfLife = 'endOfLife',
}

export type ProductLifecycle = DynamicDataType & {
  __typename?: 'ProductLifecycle';
  asString?: Maybe<Scalars['String']['output']>;
  /** All phases in order of this lifecycle */
  phases?: Maybe<Array<Maybe<ProductLifecyclePhase>>>;
};

export type ProductLifecyclePhase = {
  __typename?: 'ProductLifecyclePhase';
  /** The phase of the lifecycle. */
  phase: ProductLifecycleName;
  /** The ISO 8601 date format of a lifecycle start */
  startDate: Scalars['String']['output'];
  /** The id of a referenced milestone defining the startDate of the phase. */
  milestoneId?: Maybe<Scalars['String']['output']>;
};

/** The allowed lifecycle phases */
export enum ProductLifecycleName {
  Plan = 'plan',
  PhaseIn = 'phaseIn',
  Active = 'active',
  PhaseOut = 'phaseOut',
  EndOfLife = 'endOfLife',
}

export type ProjectLifecycle = DynamicDataType & {
  __typename?: 'ProjectLifecycle';
  asString?: Maybe<Scalars['String']['output']>;
  /** All phases in order of this lifecycle */
  phases?: Maybe<Array<Maybe<ProjectLifecyclePhase>>>;
};

export type ProjectLifecyclePhase = {
  __typename?: 'ProjectLifecyclePhase';
  /** The phase of the lifecycle. */
  phase: ProjectLifecycleName;
  /** The ISO 8601 date format of a lifecycle start */
  startDate: Scalars['String']['output'];
  /** The id of a referenced milestone defining the startDate of the phase. */
  milestoneId?: Maybe<Scalars['String']['output']>;
};

/** The allowed lifecycle phases */
export enum ProjectLifecycleName {
  Plan = 'plan',
  PhaseIn = 'phaseIn',
  Active = 'active',
  PhaseOut = 'phaseOut',
  EndOfLife = 'endOfLife',
}

/** One element in the project status progress describing the project status at a defined point of time. */
export type ProjectProjectStatus = DynamicDataType & {
  __typename?: 'ProjectProjectStatus';
  /** A unique id for this element within is parent project status progress. */
  id?: Maybe<Scalars['String']['output']>;
  asString?: Maybe<Scalars['String']['output']>;
  /** A timestamp in ISO 8601 format that describes the date when the project status is valid. */
  date?: Maybe<Scalars['String']['output']>;
  /** The status at the given date. */
  status?: Maybe<ProjectProjectStatusStatus>;
  /** The progress of the project status at given date. */
  progress?: Maybe<Scalars['Int']['output']>;
  /** A description to the project status */
  description?: Maybe<Scalars['String']['output']>;
};

/** All enum value(s) which are available for this ProjectProjectStatusStatus type */
export enum ProjectProjectStatusStatus {
  Red = 'red',
  Yellow = 'yellow',
  Green = 'green',
}

export type ProviderLifecycle = DynamicDataType & {
  __typename?: 'ProviderLifecycle';
  asString?: Maybe<Scalars['String']['output']>;
  /** All phases in order of this lifecycle */
  phases?: Maybe<Array<Maybe<ProviderLifecyclePhase>>>;
};

export type ProviderLifecyclePhase = {
  __typename?: 'ProviderLifecyclePhase';
  /** The phase of the lifecycle. */
  phase: ProviderLifecycleName;
  /** The ISO 8601 date format of a lifecycle start */
  startDate: Scalars['String']['output'];
  /** The id of a referenced milestone defining the startDate of the phase. */
  milestoneId?: Maybe<Scalars['String']['output']>;
};

/** The allowed lifecycle phases */
export enum ProviderLifecycleName {
  Plan = 'plan',
  PhaseIn = 'phaseIn',
  Active = 'active',
  PhaseOut = 'phaseOut',
  EndOfLife = 'endOfLife',
}

export type TechnicalStackLifecycle = DynamicDataType & {
  __typename?: 'TechnicalStackLifecycle';
  asString?: Maybe<Scalars['String']['output']>;
  /** All phases in order of this lifecycle */
  phases?: Maybe<Array<Maybe<TechnicalStackLifecyclePhase>>>;
};

export type TechnicalStackLifecyclePhase = {
  __typename?: 'TechnicalStackLifecyclePhase';
  /** The phase of the lifecycle. */
  phase: TechnicalStackLifecycleName;
  /** The ISO 8601 date format of a lifecycle start */
  startDate: Scalars['String']['output'];
  /** The id of a referenced milestone defining the startDate of the phase. */
  milestoneId?: Maybe<Scalars['String']['output']>;
};

/** The allowed lifecycle phases */
export enum TechnicalStackLifecycleName {
  Plan = 'plan',
  PhaseIn = 'phaseIn',
  Active = 'active',
  PhaseOut = 'phaseOut',
  EndOfLife = 'endOfLife',
}

export type UserGroupLifecycle = DynamicDataType & {
  __typename?: 'UserGroupLifecycle';
  asString?: Maybe<Scalars['String']['output']>;
  /** All phases in order of this lifecycle */
  phases?: Maybe<Array<Maybe<UserGroupLifecyclePhase>>>;
};

export type UserGroupLifecyclePhase = {
  __typename?: 'UserGroupLifecyclePhase';
  /** The phase of the lifecycle. */
  phase: UserGroupLifecycleName;
  /** The ISO 8601 date format of a lifecycle start */
  startDate: Scalars['String']['output'];
  /** The id of a referenced milestone defining the startDate of the phase. */
  milestoneId?: Maybe<Scalars['String']['output']>;
};

/** The allowed lifecycle phases */
export enum UserGroupLifecycleName {
  Plan = 'plan',
  PhaseIn = 'phaseIn',
  Active = 'active',
  PhaseOut = 'phaseOut',
  EndOfLife = 'endOfLife',
}

export type ApplicationLifecyclePhase = {
  __typename?: 'ApplicationLifecyclePhase';
  /** The phase of the lifecycle. */
  phase: ApplicationLifecycleName;
  /** The ISO 8601 date format of a lifecycle start */
  startDate: Scalars['String']['output'];
  /** The id of a referenced milestone defining the startDate of the phase. */
  milestoneId?: Maybe<Scalars['String']['output']>;
};

/** The allowed lifecycle phases */
export enum ApplicationLifecycleName {
  Plan = 'plan',
  PhaseIn = 'phaseIn',
  Active = 'active',
  PhaseOut = 'phaseOut',
  EndOfLife = 'endOfLife',
}

/** All enum value(s) which are available for this ApplicationFunctionalSuitability type */
export enum ApplicationFunctionalSuitability {
  Unreasonable = 'unreasonable',
  Insufficient = 'insufficient',
  Appropriate = 'appropriate',
  Perfect = 'perfect',
}

/** All enum value(s) which are available for this ApplicationTechnicalSuitability type */
export enum ApplicationTechnicalSuitability {
  Inappropriate = 'inappropriate',
  Unreasonable = 'unreasonable',
  Adequate = 'adequate',
  FullyAppropriate = 'fullyAppropriate',
}

/** All enum value(s) which are available for this ApplicationBusinessCriticality type */
export enum ApplicationBusinessCriticality {
  AdministrativeService = 'administrativeService',
  BusinessOperational = 'businessOperational',
  BusinessCritical = 'businessCritical',
  MissionCritical = 'missionCritical',
}

/** All enum value(s) which are available for this ApplicationProcessingPurpose type */
export enum ApplicationProcessingPurpose {
  CustomerContract = 'customerContract',
  Marketing = 'marketing',
  EmployeeTask = 'employeeTask',
  Recruiting = 'recruiting',
  HrAdmin = 'HRAdmin',
  Ga = 'GA',
  Financial = 'financial',
}

/** All enum value(s) which are available for this ApplicationLegalBasis type */
export enum ApplicationLegalBasis {
  EmployeeData = 'employeeData',
  CustomerData = 'customerData',
  MarketingConsent = 'marketingConsent',
  MarketingNoConsent = 'marketingNoConsent',
}

/** All enum value(s) which are available for this ApplicationOrderingState type */
export enum ApplicationOrderingState {
  ApprovalDenied = 'approvalDenied',
  ApprovalRequested = 'approvalRequested',
  LimitedApproval = 'limitedApproval',
  Approved = 'approved',
}

/** All enum value(s) which are available for this ApplicationAggregatedObsolescenceRisk type */
export enum ApplicationAggregatedObsolescenceRisk {
  UnaddressedEndOfLife = 'unaddressedEndOfLife',
  UnaddressedPhaseOut = 'unaddressedPhaseOut',
  MissingLifecycle = 'missingLifecycle',
  MissingItComponent = 'missingItComponent',
  RiskAccepted = 'riskAccepted',
  RiskAddressed = 'riskAddressed',
  NoRisk = 'noRisk',
}

/** All enum value(s) which are available for this ApplicationQuestion1 type */
export enum ApplicationQuestion1 {
  Yes = 'Yes',
  No = 'No',
}

/** All enum value(s) which are available for this ApplicationQuestion2 type */
export enum ApplicationQuestion2 {
  Yes = 'Yes',
  No = 'No',
}

/** All enum value(s) which are available for this ApplicationQuestion3 type */
export enum ApplicationQuestion3 {
  Yes = 'Yes',
  No = 'No',
}

/** All enum value(s) which are available for this ApplicationLxCatalogStatus type */
export enum ApplicationLxCatalogStatus {
  Linked = 'linked',
  Ignored = 'ignored',
}

/** All enum value(s) which are available for this ApplicationLxHostingType type */
export enum ApplicationLxHostingType {
  DesktopOrLaptop = 'desktopOrLaptop',
  Mobile = 'mobile',
  OnPremise = 'onPremise',
  Hybrid = 'hybrid',
  Saas = 'saas',
  Paas = 'paas',
  Iaas = 'iaas',
}

/** All enum value(s) which are available for this ApplicationLxStatusSSO type */
export enum ApplicationLxStatusSso {
  Supported = 'supported',
  NotSupported = 'notSupported',
}

/** All enum value(s) which are available for this ApplicationLxStatusSSOSMP type */
export enum ApplicationLxStatusSsosmp {
  Implemented = 'implemented',
  NotImplemented = 'notImplemented',
}

/** All enum value(s) which are available for this ApplicationRecoverytimeobjectivetimeunit type */
export enum ApplicationRecoverytimeobjectivetimeunit {
  Seconds = 'Seconds',
  Minutes = 'Minutes',
  Hours = 'Hours',
  Days = 'Days',
  Irrelevant = 'Irrelevant',
}

/** All enum value(s) which are available for this ApplicationRecoverypointobjectivetimeunit type */
export enum ApplicationRecoverypointobjectivetimeunit {
  Seconds = 'Seconds',
  Minutes = 'Minutes',
  Hours = 'Hours',
  Days = 'Days',
  Irrelevant = 'Irrelevant',
}

/** ID to an external system. */
export type ExternalId = {
  __typename?: 'ExternalId';
  /** The ID of the item representing this Fact Sheet in the external system. */
  externalId?: Maybe<Scalars['String']['output']>;
  /** The URL to the external item. */
  externalUrl?: Maybe<Scalars['String']['output']>;
  /** The URL of the LeanIX GUI for the Fact Sheet, employing the external ID. The URL is relative to the base URL of pathfinder */
  leanixUrl: Scalars['String']['output'];
  /** A comment for the external ID. */
  comment?: Maybe<Scalars['String']['output']>;
  /** The status of the external ID */
  status?: Maybe<Scalars['String']['output']>;
  /** An external version or revision id used to synchronize external documents against this system. */
  externalVersion?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type RelToParentConnection = {
  __typename?: 'RelToParentConnection';
  edges?: Maybe<Array<Maybe<RelToParentEdge>>>;
  pageInfo: PageInfo;
  /** A count of the total number of objects in this collection, ignoring pagination. */
  totalCount: Scalars['Int']['output'];
  /** Contains a list of the permissions for this type of relation and a list of fields for each CRUD operation. */
  permissions: Permissions;
  /**
   * A count of the skipped number of objects in this collection and for this
   * pagignation. The reason to skip/ hide an object can be a missing READ
   * permission of the relation of the target Fact Sheet for the requesting user.
   */
  skippedCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type RelToParentEdge = {
  __typename?: 'RelToParentEdge';
  /** The item at the end of the edge */
  node?: Maybe<RelToParent>;
  cursor: Scalars['String']['output'];
};

/** Relation with name relToParent, which is a FORWARD relation. */
export type RelToParent = Node &
  BaseRelation & {
    __typename?: 'RelToParent';
    /** The global id of the relation. */
    id: Scalars['ID']['output'];
    /** The relation type. */
    type?: Maybe<RelationType>;
    /** The Fact Sheet this relation points to. */
    factSheet?: Maybe<BaseFactSheet>;
    /**
     * An array of field names that indicates which fields of this relation have
     * intentionally not been set. These fields are thus not used for completeness calculation.
     */
    naFields?: Maybe<Array<Scalars['String']['output']>>;
    /** The date when this relation gets active. */
    activeFrom?: Maybe<Scalars['String']['output']>;
    /** The date until this relation is active. */
    activeUntil?: Maybe<Scalars['String']['output']>;
    /** Contains a list for the permissions for this instance and a list of fields for each CRUD operation. */
    permissions: Permissions;
    description?: Maybe<Scalars['String']['output']>;
  };

/** The basic relation type. */
export type BaseRelation = {
  /** The id of the relation */
  id: Scalars['ID']['output'];
  /** The relation type. */
  type?: Maybe<RelationType>;
  /** The Fact Sheet this relation points to. */
  factSheet?: Maybe<BaseFactSheet>;
  /**
   * An array of attribute names that indicates which fields of this relation have
   * intentionally not been set. These attributes are thus not used for
   * completeness calculation.
   */
  naFields?: Maybe<Array<Scalars['String']['output']>>;
  /** The date when this relation gets active. */
  activeFrom?: Maybe<Scalars['String']['output']>;
  /** The date until this relation is active. */
  activeUntil?: Maybe<Scalars['String']['output']>;
  /** Contains a list for the permissions for this instance and a list of fields for each CRUD operation. */
  permissions: Permissions;
};

/** The type of a relation with an uppercase first letter (e.g. 'RelToParent') */
export enum RelationType {
  RelToParent = 'RelToParent',
  RelToChild = 'RelToChild',
  RelToRequires = 'RelToRequires',
  RelToRequiredBy = 'RelToRequiredBy',
  RelToSuccessor = 'RelToSuccessor',
  RelToPredecessor = 'RelToPredecessor',
  RelProductToDataObject = 'RelProductToDataObject',
  RelDataObjectToProduct = 'RelDataObjectToProduct',
  RelApplicationToUserGroup = 'RelApplicationToUserGroup',
  RelUserGroupToApplication = 'RelUserGroupToApplication',
  RelApplicationToDataObject = 'RelApplicationToDataObject',
  RelDataObjectToApplication = 'RelDataObjectToApplication',
  RelApplicationToItComponent = 'RelApplicationToITComponent',
  RelItComponentToApplication = 'RelITComponentToApplication',
  RelApplicationToProject = 'RelApplicationToProject',
  RelProjectToApplication = 'RelProjectToApplication',
  RelProviderApplicationToInterface = 'RelProviderApplicationToInterface',
  RelInterfaceToProviderApplication = 'RelInterfaceToProviderApplication',
  RelConsumerApplicationToInterface = 'RelConsumerApplicationToInterface',
  RelInterfaceToConsumerApplication = 'RelInterfaceToConsumerApplication',
  RelApplicationToProcess = 'RelApplicationToProcess',
  RelProcessToApplication = 'RelProcessToApplication',
  RelApplicationToBusinessCapability = 'RelApplicationToBusinessCapability',
  RelBusinessCapabilityToApplication = 'RelBusinessCapabilityToApplication',
  RelItComponentToTechnologyStack = 'RelITComponentToTechnologyStack',
  RelTechnologyStackToItComponent = 'RelTechnologyStackToITComponent',
  RelItComponentToUserGroup = 'RelITComponentToUserGroup',
  RelUserGroupToItComponent = 'RelUserGroupToITComponent',
  RelItComponentToProvider = 'RelITComponentToProvider',
  RelProviderToItComponent = 'RelProviderToITComponent',
  RelInterfaceToDataObject = 'RelInterfaceToDataObject',
  RelDataObjectToInterface = 'RelDataObjectToInterface',
  RelInterfaceToItComponent = 'RelInterfaceToITComponent',
  RelItComponentToInterface = 'RelITComponentToInterface',
  RelProcessToBusinessCapability = 'RelProcessToBusinessCapability',
  RelBusinessCapabilityToProcess = 'RelBusinessCapabilityToProcess',
  RelProjectToUserGroup = 'RelProjectToUserGroup',
  RelUserGroupToProject = 'RelUserGroupToProject',
  RelProjectToBusinessCapability = 'RelProjectToBusinessCapability',
  RelBusinessCapabilityToProject = 'RelBusinessCapabilityToProject',
  RelProjectToItComponent = 'RelProjectToITComponent',
  RelItComponentToProject = 'RelITComponentToProject',
  RelProjectToProcess = 'RelProjectToProcess',
  RelProcessToProject = 'RelProcessToProject',
  RelProjectToProvider = 'RelProjectToProvider',
  RelProviderToProject = 'RelProviderToProject',
  RelProductToApplication = 'RelProductToApplication',
  RelApplicationToProduct = 'RelApplicationToProduct',
  RelProductToItComponent = 'RelProductToITComponent',
  RelItComponentToProduct = 'RelITComponentToProduct',
  RelProductToProject = 'RelProductToProject',
  RelProjectToProduct = 'RelProjectToProduct',
  RelProductToBusinessCapability = 'RelProductToBusinessCapability',
  RelBusinessCapabilityToProduct = 'RelBusinessCapabilityToProduct',
  RelProductToUserGroup = 'RelProductToUserGroup',
  RelUserGroupToProduct = 'RelUserGroupToProduct',
  RelProductToProcess = 'RelProductToProcess',
  RelProcessToProduct = 'RelProcessToProduct',
  RelProjectToBlockedProject = 'RelProjectToBlockedProject',
  RelProjectToBlockingProject = 'RelProjectToBlockingProject',
}

/** Relation with name relApplicationToBusinessCapability, which is a FORWARD relation. */
export type RelApplicationToBusinessCapability = Node &
  BaseRelation & {
    __typename?: 'RelApplicationToBusinessCapability';
    /** The global id of the relation. */
    id: Scalars['ID']['output'];
    /** The relation type. */
    type?: Maybe<RelationType>;
    /** The Fact Sheet this relation points to. */
    factSheet?: Maybe<BaseFactSheet>;
    /**
     * An array of field names that indicates which fields of this relation have
     * intentionally not been set. These fields are thus not used for completeness calculation.
     */
    naFields?: Maybe<Array<Scalars['String']['output']>>;
    /** The date when this relation gets active. */
    activeFrom?: Maybe<Scalars['String']['output']>;
    /** The date until this relation is active. */
    activeUntil?: Maybe<Scalars['String']['output']>;
    /** Contains a list for the permissions for this instance and a list of fields for each CRUD operation. */
    permissions: Permissions;
    /** Info about relations on the Fact Sheet that constrain this relation */
    constrainingRelations: ConstrainingRelation;
    /** supportType */
    supportType?: Maybe<RelApplicationToBusinessCapabilitySupportType>;
    /** functionalSuitability */
    functionalSuitability?: Maybe<RelApplicationToBusinessCapabilityFunctionalSuitability>;
    description?: Maybe<Scalars['String']['output']>;
  };

/** A restriction on the relation */
export type ConstrainingRelation = {
  __typename?: 'ConstrainingRelation';
  /** The list of total counts of constraining relations of each type */
  totalCounts: Array<ConstrainingRelationTotalCount>;
  /** The list of constraining relations */
  relations: Array<BaseRelation>;
};

/** Type of constraining relation and count of constraining relations with that type */
export type ConstrainingRelationTotalCount = {
  __typename?: 'ConstrainingRelationTotalCount';
  /** the type of a constraining relation */
  type: Scalars['String']['output'];
  /** the number of constraining relations of a single type */
  totalCount: Scalars['Long']['output'];
};

/** All enum value(s) which are available for this RelApplicationToBusinessCapabilitySupportType type */
export enum RelApplicationToBusinessCapabilitySupportType {
  Supports = 'supports',
  Leading = 'leading',
}

/** All enum value(s) which are available for this RelApplicationToBusinessCapabilityFunctionalSuitability type */
export enum RelApplicationToBusinessCapabilityFunctionalSuitability {
  Unreasonable = 'unreasonable',
  Insufficient = 'insufficient',
  Appropriate = 'appropriate',
  Perfect = 'perfect',
}

/** Relation with name relApplicationToDataObject, which is a FORWARD relation. */
export type RelApplicationToDataObject = Node &
  BaseRelation & {
    __typename?: 'RelApplicationToDataObject';
    /** The global id of the relation. */
    id: Scalars['ID']['output'];
    /** The relation type. */
    type?: Maybe<RelationType>;
    /** The Fact Sheet this relation points to. */
    factSheet?: Maybe<BaseFactSheet>;
    /**
     * An array of field names that indicates which fields of this relation have
     * intentionally not been set. These fields are thus not used for completeness calculation.
     */
    naFields?: Maybe<Array<Scalars['String']['output']>>;
    /** The date when this relation gets active. */
    activeFrom?: Maybe<Scalars['String']['output']>;
    /** The date until this relation is active. */
    activeUntil?: Maybe<Scalars['String']['output']>;
    /** Contains a list for the permissions for this instance and a list of fields for each CRUD operation. */
    permissions: Permissions;
    /** usage */
    usage?: Maybe<Array<Maybe<RelApplicationToDataObjectUsage>>>;
    description?: Maybe<Scalars['String']['output']>;
    /** dataOrigin3 */
    dataOrigin3?: Maybe<Array<Maybe<RelApplicationToDataObjectDataOrigin3>>>;
    deletionDeadline?: Maybe<Scalars['String']['output']>;
  };

/** All enum value(s) which are available for this RelApplicationToDataObjectUsage type */
export enum RelApplicationToDataObjectUsage {
  Create = 'create',
  Read = 'read',
  Update = 'update',
  Delete = 'delete',
}

/** All enum value(s) which are available for this RelApplicationToDataObjectDataOrigin3 type */
export enum RelApplicationToDataObjectDataOrigin3 {
  ByDataObject = 'byDataObject',
  ByLeanix = 'byLeanix',
}

/** Relation with name relApplicationToITComponent, which is a FORWARD relation. */
export type RelApplicationToItComponent = Node &
  BaseRelation & {
    __typename?: 'RelApplicationToITComponent';
    /** The global id of the relation. */
    id: Scalars['ID']['output'];
    /** The relation type. */
    type?: Maybe<RelationType>;
    /** The Fact Sheet this relation points to. */
    factSheet?: Maybe<BaseFactSheet>;
    /**
     * An array of field names that indicates which fields of this relation have
     * intentionally not been set. These fields are thus not used for completeness calculation.
     */
    naFields?: Maybe<Array<Scalars['String']['output']>>;
    /** The date when this relation gets active. */
    activeFrom?: Maybe<Scalars['String']['output']>;
    /** The date until this relation is active. */
    activeUntil?: Maybe<Scalars['String']['output']>;
    /** Contains a list for the permissions for this instance and a list of fields for each CRUD operation. */
    permissions: Permissions;
    costTotalAnnual?: Maybe<Scalars['Float']['output']>;
    /** technicalSuitability */
    technicalSuitability?: Maybe<RelApplicationToItComponentTechnicalSuitability>;
    description?: Maybe<Scalars['String']['output']>;
    serviceLevel?: Maybe<Scalars['String']['output']>;
    /** obsolescenceRiskStatus */
    obsolescenceRiskStatus?: Maybe<RelApplicationToItComponentObsolescenceRiskStatus>;
    obsolescenceRiskComment?: Maybe<Scalars['String']['output']>;
  };

/** All enum value(s) which are available for this RelApplicationToITComponentTechnicalSuitability type */
export enum RelApplicationToItComponentTechnicalSuitability {
  Inappropriate = 'inappropriate',
  Unreasonable = 'unreasonable',
  Adequate = 'adequate',
  FullyAppropriate = 'fullyAppropriate',
}

/** All enum value(s) which are available for this RelApplicationToITComponentObsolescenceRiskStatus type */
export enum RelApplicationToItComponentObsolescenceRiskStatus {
  RiskAccepted = 'riskAccepted',
  RiskAddressed = 'riskAddressed',
}

/** Relation with name relApplicationToProcess, which is a FORWARD relation. */
export type RelApplicationToProcess = Node &
  BaseRelation & {
    __typename?: 'RelApplicationToProcess';
    /** The global id of the relation. */
    id: Scalars['ID']['output'];
    /** The relation type. */
    type?: Maybe<RelationType>;
    /** The Fact Sheet this relation points to. */
    factSheet?: Maybe<BaseFactSheet>;
    /**
     * An array of field names that indicates which fields of this relation have
     * intentionally not been set. These fields are thus not used for completeness calculation.
     */
    naFields?: Maybe<Array<Scalars['String']['output']>>;
    /** The date when this relation gets active. */
    activeFrom?: Maybe<Scalars['String']['output']>;
    /** The date until this relation is active. */
    activeUntil?: Maybe<Scalars['String']['output']>;
    /** Contains a list for the permissions for this instance and a list of fields for each CRUD operation. */
    permissions: Permissions;
    /** Info about relations on the Fact Sheet that constrain this relation */
    constrainingRelations: ConstrainingRelation;
    description?: Maybe<Scalars['String']['output']>;
  };

/** Relation with name relApplicationToProduct, which is a REVERSE relation. */
export type RelApplicationToProduct = Node &
  BaseRelation & {
    __typename?: 'RelApplicationToProduct';
    /** The global id of the relation. */
    id: Scalars['ID']['output'];
    /** The relation type. */
    type?: Maybe<RelationType>;
    /** The Fact Sheet this relation points to. */
    factSheet?: Maybe<BaseFactSheet>;
    /**
     * An array of field names that indicates which fields of this relation have
     * intentionally not been set. These fields are thus not used for completeness calculation.
     */
    naFields?: Maybe<Array<Scalars['String']['output']>>;
    /** The date when this relation gets active. */
    activeFrom?: Maybe<Scalars['String']['output']>;
    /** The date until this relation is active. */
    activeUntil?: Maybe<Scalars['String']['output']>;
    /** Contains a list for the permissions for this instance and a list of fields for each CRUD operation. */
    permissions: Permissions;
    description?: Maybe<Scalars['String']['output']>;
    /** UserType */
    UserType?: Maybe<RelApplicationToProductUserType>;
  };

/** All enum value(s) which are available for this RelApplicationToProductUserType type */
export enum RelApplicationToProductUserType {
  Owner = 'Owner',
  User = 'User',
}

/** Relation with name relApplicationToProject, which is a FORWARD relation. */
export type RelApplicationToProject = Node &
  BaseRelation & {
    __typename?: 'RelApplicationToProject';
    /** The global id of the relation. */
    id: Scalars['ID']['output'];
    /** The relation type. */
    type?: Maybe<RelationType>;
    /** The Fact Sheet this relation points to. */
    factSheet?: Maybe<BaseFactSheet>;
    /**
     * An array of field names that indicates which fields of this relation have
     * intentionally not been set. These fields are thus not used for completeness calculation.
     */
    naFields?: Maybe<Array<Scalars['String']['output']>>;
    /** The date when this relation gets active. */
    activeFrom?: Maybe<Scalars['String']['output']>;
    /** The date until this relation is active. */
    activeUntil?: Maybe<Scalars['String']['output']>;
    /** Contains a list for the permissions for this instance and a list of fields for each CRUD operation. */
    permissions: Permissions;
    description?: Maybe<Scalars['String']['output']>;
  };

/** Relation with name relApplicationToUserGroup, which is a FORWARD relation. */
export type RelApplicationToUserGroup = Node &
  BaseRelation & {
    __typename?: 'RelApplicationToUserGroup';
    /** The global id of the relation. */
    id: Scalars['ID']['output'];
    /** The relation type. */
    type?: Maybe<RelationType>;
    /** The Fact Sheet this relation points to. */
    factSheet?: Maybe<BaseFactSheet>;
    /**
     * An array of field names that indicates which fields of this relation have
     * intentionally not been set. These fields are thus not used for completeness calculation.
     */
    naFields?: Maybe<Array<Scalars['String']['output']>>;
    /** The date when this relation gets active. */
    activeFrom?: Maybe<Scalars['String']['output']>;
    /** The date until this relation is active. */
    activeUntil?: Maybe<Scalars['String']['output']>;
    /** Contains a list for the permissions for this instance and a list of fields for each CRUD operation. */
    permissions: Permissions;
    /** Info about relations on the Fact Sheet that constrain this relation */
    constrainingRelations: ConstrainingRelation;
    /** functionalSuitability */
    functionalSuitability?: Maybe<RelApplicationToUserGroupFunctionalSuitability>;
    numberOfUsers?: Maybe<Scalars['Int']['output']>;
    description?: Maybe<Scalars['String']['output']>;
    /** usageType */
    usageType?: Maybe<RelApplicationToUserGroupUsageType>;
  };

/** All enum value(s) which are available for this RelApplicationToUserGroupFunctionalSuitability type */
export enum RelApplicationToUserGroupFunctionalSuitability {
  Unreasonable = 'unreasonable',
  Insufficient = 'insufficient',
  Appropriate = 'appropriate',
  Perfect = 'perfect',
}

/** All enum value(s) which are available for this RelApplicationToUserGroupUsageType type */
export enum RelApplicationToUserGroupUsageType {
  User = 'user',
  Owner = 'owner',
}

/** Relation with name relBusinessCapabilityToApplication, which is a REVERSE relation. */
export type RelBusinessCapabilityToApplication = Node &
  BaseRelation & {
    __typename?: 'RelBusinessCapabilityToApplication';
    /** The global id of the relation. */
    id: Scalars['ID']['output'];
    /** The relation type. */
    type?: Maybe<RelationType>;
    /** The Fact Sheet this relation points to. */
    factSheet?: Maybe<BaseFactSheet>;
    /**
     * An array of field names that indicates which fields of this relation have
     * intentionally not been set. These fields are thus not used for completeness calculation.
     */
    naFields?: Maybe<Array<Scalars['String']['output']>>;
    /** The date when this relation gets active. */
    activeFrom?: Maybe<Scalars['String']['output']>;
    /** The date until this relation is active. */
    activeUntil?: Maybe<Scalars['String']['output']>;
    /** Contains a list for the permissions for this instance and a list of fields for each CRUD operation. */
    permissions: Permissions;
    /** Info about relations on the Fact Sheet that constrain this relation */
    constrainingRelations: ConstrainingRelation;
    /** supportType */
    supportType?: Maybe<RelBusinessCapabilityToApplicationSupportType>;
    /** functionalSuitability */
    functionalSuitability?: Maybe<RelBusinessCapabilityToApplicationFunctionalSuitability>;
    description?: Maybe<Scalars['String']['output']>;
  };

/** All enum value(s) which are available for this RelBusinessCapabilityToApplicationSupportType type */
export enum RelBusinessCapabilityToApplicationSupportType {
  Supports = 'supports',
  Leading = 'leading',
}

/** All enum value(s) which are available for this RelBusinessCapabilityToApplicationFunctionalSuitability type */
export enum RelBusinessCapabilityToApplicationFunctionalSuitability {
  Unreasonable = 'unreasonable',
  Insufficient = 'insufficient',
  Appropriate = 'appropriate',
  Perfect = 'perfect',
}

/** Relation with name relBusinessCapabilityToProcess, which is a REVERSE relation. */
export type RelBusinessCapabilityToProcess = Node &
  BaseRelation & {
    __typename?: 'RelBusinessCapabilityToProcess';
    /** The global id of the relation. */
    id: Scalars['ID']['output'];
    /** The relation type. */
    type?: Maybe<RelationType>;
    /** The Fact Sheet this relation points to. */
    factSheet?: Maybe<BaseFactSheet>;
    /**
     * An array of field names that indicates which fields of this relation have
     * intentionally not been set. These fields are thus not used for completeness calculation.
     */
    naFields?: Maybe<Array<Scalars['String']['output']>>;
    /** The date when this relation gets active. */
    activeFrom?: Maybe<Scalars['String']['output']>;
    /** The date until this relation is active. */
    activeUntil?: Maybe<Scalars['String']['output']>;
    /** Contains a list for the permissions for this instance and a list of fields for each CRUD operation. */
    permissions: Permissions;
    description?: Maybe<Scalars['String']['output']>;
  };

/** Relation with name relBusinessCapabilityToProduct, which is a REVERSE relation. */
export type RelBusinessCapabilityToProduct = Node &
  BaseRelation & {
    __typename?: 'RelBusinessCapabilityToProduct';
    /** The global id of the relation. */
    id: Scalars['ID']['output'];
    /** The relation type. */
    type?: Maybe<RelationType>;
    /** The Fact Sheet this relation points to. */
    factSheet?: Maybe<BaseFactSheet>;
    /**
     * An array of field names that indicates which fields of this relation have
     * intentionally not been set. These fields are thus not used for completeness calculation.
     */
    naFields?: Maybe<Array<Scalars['String']['output']>>;
    /** The date when this relation gets active. */
    activeFrom?: Maybe<Scalars['String']['output']>;
    /** The date until this relation is active. */
    activeUntil?: Maybe<Scalars['String']['output']>;
    /** Contains a list for the permissions for this instance and a list of fields for each CRUD operation. */
    permissions: Permissions;
    description?: Maybe<Scalars['String']['output']>;
  };

/** Relation with name relBusinessCapabilityToProject, which is a REVERSE relation. */
export type RelBusinessCapabilityToProject = Node &
  BaseRelation & {
    __typename?: 'RelBusinessCapabilityToProject';
    /** The global id of the relation. */
    id: Scalars['ID']['output'];
    /** The relation type. */
    type?: Maybe<RelationType>;
    /** The Fact Sheet this relation points to. */
    factSheet?: Maybe<BaseFactSheet>;
    /**
     * An array of field names that indicates which fields of this relation have
     * intentionally not been set. These fields are thus not used for completeness calculation.
     */
    naFields?: Maybe<Array<Scalars['String']['output']>>;
    /** The date when this relation gets active. */
    activeFrom?: Maybe<Scalars['String']['output']>;
    /** The date until this relation is active. */
    activeUntil?: Maybe<Scalars['String']['output']>;
    /** Contains a list for the permissions for this instance and a list of fields for each CRUD operation. */
    permissions: Permissions;
    /** Info about relations on the Fact Sheet that constrain this relation */
    constrainingRelations: ConstrainingRelation;
    description?: Maybe<Scalars['String']['output']>;
  };

/** Relation with name relConsumerApplicationToInterface, which is a FORWARD relation. */
export type RelConsumerApplicationToInterface = Node &
  BaseRelation & {
    __typename?: 'RelConsumerApplicationToInterface';
    /** The global id of the relation. */
    id: Scalars['ID']['output'];
    /** The relation type. */
    type?: Maybe<RelationType>;
    /** The Fact Sheet this relation points to. */
    factSheet?: Maybe<BaseFactSheet>;
    /**
     * An array of field names that indicates which fields of this relation have
     * intentionally not been set. These fields are thus not used for completeness calculation.
     */
    naFields?: Maybe<Array<Scalars['String']['output']>>;
    /** The date when this relation gets active. */
    activeFrom?: Maybe<Scalars['String']['output']>;
    /** The date until this relation is active. */
    activeUntil?: Maybe<Scalars['String']['output']>;
    /** Contains a list for the permissions for this instance and a list of fields for each CRUD operation. */
    permissions: Permissions;
    description?: Maybe<Scalars['String']['output']>;
    /** Criticality */
    Criticality?: Maybe<RelConsumerApplicationToInterfaceCriticality>;
  };

/** All enum value(s) which are available for this RelConsumerApplicationToInterfaceCriticality type */
export enum RelConsumerApplicationToInterfaceCriticality {
  Yes = 'Yes',
  No = 'No',
}

/** Relation with name relDataObjectToApplication, which is a REVERSE relation. */
export type RelDataObjectToApplication = Node &
  BaseRelation & {
    __typename?: 'RelDataObjectToApplication';
    /** The global id of the relation. */
    id: Scalars['ID']['output'];
    /** The relation type. */
    type?: Maybe<RelationType>;
    /** The Fact Sheet this relation points to. */
    factSheet?: Maybe<BaseFactSheet>;
    /**
     * An array of field names that indicates which fields of this relation have
     * intentionally not been set. These fields are thus not used for completeness calculation.
     */
    naFields?: Maybe<Array<Scalars['String']['output']>>;
    /** The date when this relation gets active. */
    activeFrom?: Maybe<Scalars['String']['output']>;
    /** The date until this relation is active. */
    activeUntil?: Maybe<Scalars['String']['output']>;
    /** Contains a list for the permissions for this instance and a list of fields for each CRUD operation. */
    permissions: Permissions;
    /** usage */
    usage?: Maybe<Array<Maybe<RelDataObjectToApplicationUsage>>>;
    description?: Maybe<Scalars['String']['output']>;
    /** dataOrigin3 */
    dataOrigin3?: Maybe<Array<Maybe<RelDataObjectToApplicationDataOrigin3>>>;
    deletionDeadline?: Maybe<Scalars['String']['output']>;
  };

/** All enum value(s) which are available for this RelDataObjectToApplicationUsage type */
export enum RelDataObjectToApplicationUsage {
  Create = 'create',
  Read = 'read',
  Update = 'update',
  Delete = 'delete',
}

/** All enum value(s) which are available for this RelDataObjectToApplicationDataOrigin3 type */
export enum RelDataObjectToApplicationDataOrigin3 {
  ByDataObject = 'byDataObject',
  ByLeanix = 'byLeanix',
}

/** Relation with name relDataObjectToInterface, which is a REVERSE relation. */
export type RelDataObjectToInterface = Node &
  BaseRelation & {
    __typename?: 'RelDataObjectToInterface';
    /** The global id of the relation. */
    id: Scalars['ID']['output'];
    /** The relation type. */
    type?: Maybe<RelationType>;
    /** The Fact Sheet this relation points to. */
    factSheet?: Maybe<BaseFactSheet>;
    /**
     * An array of field names that indicates which fields of this relation have
     * intentionally not been set. These fields are thus not used for completeness calculation.
     */
    naFields?: Maybe<Array<Scalars['String']['output']>>;
    /** The date when this relation gets active. */
    activeFrom?: Maybe<Scalars['String']['output']>;
    /** The date until this relation is active. */
    activeUntil?: Maybe<Scalars['String']['output']>;
    /** Contains a list for the permissions for this instance and a list of fields for each CRUD operation. */
    permissions: Permissions;
    description?: Maybe<Scalars['String']['output']>;
  };

/** Relation with name relDataObjectToProduct, which is a REVERSE relation. */
export type RelDataObjectToProduct = Node &
  BaseRelation & {
    __typename?: 'RelDataObjectToProduct';
    /** The global id of the relation. */
    id: Scalars['ID']['output'];
    /** The relation type. */
    type?: Maybe<RelationType>;
    /** The Fact Sheet this relation points to. */
    factSheet?: Maybe<BaseFactSheet>;
    /**
     * An array of field names that indicates which fields of this relation have
     * intentionally not been set. These fields are thus not used for completeness calculation.
     */
    naFields?: Maybe<Array<Scalars['String']['output']>>;
    /** The date when this relation gets active. */
    activeFrom?: Maybe<Scalars['String']['output']>;
    /** The date until this relation is active. */
    activeUntil?: Maybe<Scalars['String']['output']>;
    /** Contains a list for the permissions for this instance and a list of fields for each CRUD operation. */
    permissions: Permissions;
    description?: Maybe<Scalars['String']['output']>;
    /** UserType */
    UserType?: Maybe<RelDataObjectToProductUserType>;
  };

/** All enum value(s) which are available for this RelDataObjectToProductUserType type */
export enum RelDataObjectToProductUserType {
  Owner = 'Owner',
  User = 'User',
}

/** Relation with name relITComponentToApplication, which is a REVERSE relation. */
export type RelItComponentToApplication = Node &
  BaseRelation & {
    __typename?: 'RelITComponentToApplication';
    /** The global id of the relation. */
    id: Scalars['ID']['output'];
    /** The relation type. */
    type?: Maybe<RelationType>;
    /** The Fact Sheet this relation points to. */
    factSheet?: Maybe<BaseFactSheet>;
    /**
     * An array of field names that indicates which fields of this relation have
     * intentionally not been set. These fields are thus not used for completeness calculation.
     */
    naFields?: Maybe<Array<Scalars['String']['output']>>;
    /** The date when this relation gets active. */
    activeFrom?: Maybe<Scalars['String']['output']>;
    /** The date until this relation is active. */
    activeUntil?: Maybe<Scalars['String']['output']>;
    /** Contains a list for the permissions for this instance and a list of fields for each CRUD operation. */
    permissions: Permissions;
    costTotalAnnual?: Maybe<Scalars['Float']['output']>;
    /** technicalSuitability */
    technicalSuitability?: Maybe<RelItComponentToApplicationTechnicalSuitability>;
    description?: Maybe<Scalars['String']['output']>;
    serviceLevel?: Maybe<Scalars['String']['output']>;
    /** obsolescenceRiskStatus */
    obsolescenceRiskStatus?: Maybe<RelItComponentToApplicationObsolescenceRiskStatus>;
    obsolescenceRiskComment?: Maybe<Scalars['String']['output']>;
  };

/** All enum value(s) which are available for this RelITComponentToApplicationTechnicalSuitability type */
export enum RelItComponentToApplicationTechnicalSuitability {
  Inappropriate = 'inappropriate',
  Unreasonable = 'unreasonable',
  Adequate = 'adequate',
  FullyAppropriate = 'fullyAppropriate',
}

/** All enum value(s) which are available for this RelITComponentToApplicationObsolescenceRiskStatus type */
export enum RelItComponentToApplicationObsolescenceRiskStatus {
  RiskAccepted = 'riskAccepted',
  RiskAddressed = 'riskAddressed',
}

/** Relation with name relITComponentToInterface, which is a REVERSE relation. */
export type RelItComponentToInterface = Node &
  BaseRelation & {
    __typename?: 'RelITComponentToInterface';
    /** The global id of the relation. */
    id: Scalars['ID']['output'];
    /** The relation type. */
    type?: Maybe<RelationType>;
    /** The Fact Sheet this relation points to. */
    factSheet?: Maybe<BaseFactSheet>;
    /**
     * An array of field names that indicates which fields of this relation have
     * intentionally not been set. These fields are thus not used for completeness calculation.
     */
    naFields?: Maybe<Array<Scalars['String']['output']>>;
    /** The date when this relation gets active. */
    activeFrom?: Maybe<Scalars['String']['output']>;
    /** The date until this relation is active. */
    activeUntil?: Maybe<Scalars['String']['output']>;
    /** Contains a list for the permissions for this instance and a list of fields for each CRUD operation. */
    permissions: Permissions;
    description?: Maybe<Scalars['String']['output']>;
  };

/** Relation with name relITComponentToProduct, which is a REVERSE relation. */
export type RelItComponentToProduct = Node &
  BaseRelation & {
    __typename?: 'RelITComponentToProduct';
    /** The global id of the relation. */
    id: Scalars['ID']['output'];
    /** The relation type. */
    type?: Maybe<RelationType>;
    /** The Fact Sheet this relation points to. */
    factSheet?: Maybe<BaseFactSheet>;
    /**
     * An array of field names that indicates which fields of this relation have
     * intentionally not been set. These fields are thus not used for completeness calculation.
     */
    naFields?: Maybe<Array<Scalars['String']['output']>>;
    /** The date when this relation gets active. */
    activeFrom?: Maybe<Scalars['String']['output']>;
    /** The date until this relation is active. */
    activeUntil?: Maybe<Scalars['String']['output']>;
    /** Contains a list for the permissions for this instance and a list of fields for each CRUD operation. */
    permissions: Permissions;
    description?: Maybe<Scalars['String']['output']>;
  };

/** Relation with name relITComponentToProject, which is a REVERSE relation. */
export type RelItComponentToProject = Node &
  BaseRelation & {
    __typename?: 'RelITComponentToProject';
    /** The global id of the relation. */
    id: Scalars['ID']['output'];
    /** The relation type. */
    type?: Maybe<RelationType>;
    /** The Fact Sheet this relation points to. */
    factSheet?: Maybe<BaseFactSheet>;
    /**
     * An array of field names that indicates which fields of this relation have
     * intentionally not been set. These fields are thus not used for completeness calculation.
     */
    naFields?: Maybe<Array<Scalars['String']['output']>>;
    /** The date when this relation gets active. */
    activeFrom?: Maybe<Scalars['String']['output']>;
    /** The date until this relation is active. */
    activeUntil?: Maybe<Scalars['String']['output']>;
    /** Contains a list for the permissions for this instance and a list of fields for each CRUD operation. */
    permissions: Permissions;
    description?: Maybe<Scalars['String']['output']>;
  };

/** Relation with name relITComponentToProvider, which is a FORWARD relation. */
export type RelItComponentToProvider = Node &
  BaseRelation & {
    __typename?: 'RelITComponentToProvider';
    /** The global id of the relation. */
    id: Scalars['ID']['output'];
    /** The relation type. */
    type?: Maybe<RelationType>;
    /** The Fact Sheet this relation points to. */
    factSheet?: Maybe<BaseFactSheet>;
    /**
     * An array of field names that indicates which fields of this relation have
     * intentionally not been set. These fields are thus not used for completeness calculation.
     */
    naFields?: Maybe<Array<Scalars['String']['output']>>;
    /** The date when this relation gets active. */
    activeFrom?: Maybe<Scalars['String']['output']>;
    /** The date until this relation is active. */
    activeUntil?: Maybe<Scalars['String']['output']>;
    /** Contains a list for the permissions for this instance and a list of fields for each CRUD operation. */
    permissions: Permissions;
    description?: Maybe<Scalars['String']['output']>;
  };

/** Relation with name relITComponentToTechnologyStack, which is a FORWARD relation. */
export type RelItComponentToTechnologyStack = Node &
  BaseRelation & {
    __typename?: 'RelITComponentToTechnologyStack';
    /** The global id of the relation. */
    id: Scalars['ID']['output'];
    /** The relation type. */
    type?: Maybe<RelationType>;
    /** The Fact Sheet this relation points to. */
    factSheet?: Maybe<BaseFactSheet>;
    /**
     * An array of field names that indicates which fields of this relation have
     * intentionally not been set. These fields are thus not used for completeness calculation.
     */
    naFields?: Maybe<Array<Scalars['String']['output']>>;
    /** The date when this relation gets active. */
    activeFrom?: Maybe<Scalars['String']['output']>;
    /** The date until this relation is active. */
    activeUntil?: Maybe<Scalars['String']['output']>;
    /** Contains a list for the permissions for this instance and a list of fields for each CRUD operation. */
    permissions: Permissions;
    /** Info about relations on the Fact Sheet that constrain this relation */
    constrainingRelations: ConstrainingRelation;
    /** supportType */
    supportType?: Maybe<RelItComponentToTechnologyStackSupportType>;
    /** resourceClassification */
    resourceClassification?: Maybe<RelItComponentToTechnologyStackResourceClassification>;
    description?: Maybe<Scalars['String']['output']>;
  };

/** All enum value(s) which are available for this RelITComponentToTechnologyStackSupportType type */
export enum RelItComponentToTechnologyStackSupportType {
  Supports = 'supports',
  Leading = 'leading',
}

/** All enum value(s) which are available for this RelITComponentToTechnologyStackResourceClassification type */
export enum RelItComponentToTechnologyStackResourceClassification {
  Investigating = 'investigating',
  Approved = 'approved',
  Conditional = 'conditional',
  Retiring = 'retiring',
  Unapproved = 'unapproved',
}

/** Relation with name relITComponentToUserGroup, which is a FORWARD relation. */
export type RelItComponentToUserGroup = Node &
  BaseRelation & {
    __typename?: 'RelITComponentToUserGroup';
    /** The global id of the relation. */
    id: Scalars['ID']['output'];
    /** The relation type. */
    type?: Maybe<RelationType>;
    /** The Fact Sheet this relation points to. */
    factSheet?: Maybe<BaseFactSheet>;
    /**
     * An array of field names that indicates which fields of this relation have
     * intentionally not been set. These fields are thus not used for completeness calculation.
     */
    naFields?: Maybe<Array<Scalars['String']['output']>>;
    /** The date when this relation gets active. */
    activeFrom?: Maybe<Scalars['String']['output']>;
    /** The date until this relation is active. */
    activeUntil?: Maybe<Scalars['String']['output']>;
    /** Contains a list for the permissions for this instance and a list of fields for each CRUD operation. */
    permissions: Permissions;
    /** technicalSuitability */
    technicalSuitability?: Maybe<RelItComponentToUserGroupTechnicalSuitability>;
    numberOfUsers?: Maybe<Scalars['Int']['output']>;
    description?: Maybe<Scalars['String']['output']>;
    /** usageType */
    usageType?: Maybe<RelItComponentToUserGroupUsageType>;
  };

/** All enum value(s) which are available for this RelITComponentToUserGroupTechnicalSuitability type */
export enum RelItComponentToUserGroupTechnicalSuitability {
  Inappropriate = 'inappropriate',
  Unreasonable = 'unreasonable',
  Adequate = 'adequate',
  FullyAppropriate = 'fullyAppropriate',
}

/** All enum value(s) which are available for this RelITComponentToUserGroupUsageType type */
export enum RelItComponentToUserGroupUsageType {
  User = 'user',
  Owner = 'owner',
}

/** Relation with name relInterfaceToConsumerApplication, which is a REVERSE relation. */
export type RelInterfaceToConsumerApplication = Node &
  BaseRelation & {
    __typename?: 'RelInterfaceToConsumerApplication';
    /** The global id of the relation. */
    id: Scalars['ID']['output'];
    /** The relation type. */
    type?: Maybe<RelationType>;
    /** The Fact Sheet this relation points to. */
    factSheet?: Maybe<BaseFactSheet>;
    /**
     * An array of field names that indicates which fields of this relation have
     * intentionally not been set. These fields are thus not used for completeness calculation.
     */
    naFields?: Maybe<Array<Scalars['String']['output']>>;
    /** The date when this relation gets active. */
    activeFrom?: Maybe<Scalars['String']['output']>;
    /** The date until this relation is active. */
    activeUntil?: Maybe<Scalars['String']['output']>;
    /** Contains a list for the permissions for this instance and a list of fields for each CRUD operation. */
    permissions: Permissions;
    description?: Maybe<Scalars['String']['output']>;
    /** Criticality */
    Criticality?: Maybe<RelInterfaceToConsumerApplicationCriticality>;
  };

/** All enum value(s) which are available for this RelInterfaceToConsumerApplicationCriticality type */
export enum RelInterfaceToConsumerApplicationCriticality {
  Yes = 'Yes',
  No = 'No',
}

/** Relation with name relInterfaceToDataObject, which is a FORWARD relation. */
export type RelInterfaceToDataObject = Node &
  BaseRelation & {
    __typename?: 'RelInterfaceToDataObject';
    /** The global id of the relation. */
    id: Scalars['ID']['output'];
    /** The relation type. */
    type?: Maybe<RelationType>;
    /** The Fact Sheet this relation points to. */
    factSheet?: Maybe<BaseFactSheet>;
    /**
     * An array of field names that indicates which fields of this relation have
     * intentionally not been set. These fields are thus not used for completeness calculation.
     */
    naFields?: Maybe<Array<Scalars['String']['output']>>;
    /** The date when this relation gets active. */
    activeFrom?: Maybe<Scalars['String']['output']>;
    /** The date until this relation is active. */
    activeUntil?: Maybe<Scalars['String']['output']>;
    /** Contains a list for the permissions for this instance and a list of fields for each CRUD operation. */
    permissions: Permissions;
    description?: Maybe<Scalars['String']['output']>;
  };

/** Relation with name relInterfaceToITComponent, which is a FORWARD relation. */
export type RelInterfaceToItComponent = Node &
  BaseRelation & {
    __typename?: 'RelInterfaceToITComponent';
    /** The global id of the relation. */
    id: Scalars['ID']['output'];
    /** The relation type. */
    type?: Maybe<RelationType>;
    /** The Fact Sheet this relation points to. */
    factSheet?: Maybe<BaseFactSheet>;
    /**
     * An array of field names that indicates which fields of this relation have
     * intentionally not been set. These fields are thus not used for completeness calculation.
     */
    naFields?: Maybe<Array<Scalars['String']['output']>>;
    /** The date when this relation gets active. */
    activeFrom?: Maybe<Scalars['String']['output']>;
    /** The date until this relation is active. */
    activeUntil?: Maybe<Scalars['String']['output']>;
    /** Contains a list for the permissions for this instance and a list of fields for each CRUD operation. */
    permissions: Permissions;
    description?: Maybe<Scalars['String']['output']>;
  };

/** Relation with name relInterfaceToProviderApplication, which is a REVERSE relation. */
export type RelInterfaceToProviderApplication = Node &
  BaseRelation & {
    __typename?: 'RelInterfaceToProviderApplication';
    /** The global id of the relation. */
    id: Scalars['ID']['output'];
    /** The relation type. */
    type?: Maybe<RelationType>;
    /** The Fact Sheet this relation points to. */
    factSheet?: Maybe<BaseFactSheet>;
    /**
     * An array of field names that indicates which fields of this relation have
     * intentionally not been set. These fields are thus not used for completeness calculation.
     */
    naFields?: Maybe<Array<Scalars['String']['output']>>;
    /** The date when this relation gets active. */
    activeFrom?: Maybe<Scalars['String']['output']>;
    /** The date until this relation is active. */
    activeUntil?: Maybe<Scalars['String']['output']>;
    /** Contains a list for the permissions for this instance and a list of fields for each CRUD operation. */
    permissions: Permissions;
    description?: Maybe<Scalars['String']['output']>;
  };

/** Relation with name relProcessToApplication, which is a REVERSE relation. */
export type RelProcessToApplication = Node &
  BaseRelation & {
    __typename?: 'RelProcessToApplication';
    /** The global id of the relation. */
    id: Scalars['ID']['output'];
    /** The relation type. */
    type?: Maybe<RelationType>;
    /** The Fact Sheet this relation points to. */
    factSheet?: Maybe<BaseFactSheet>;
    /**
     * An array of field names that indicates which fields of this relation have
     * intentionally not been set. These fields are thus not used for completeness calculation.
     */
    naFields?: Maybe<Array<Scalars['String']['output']>>;
    /** The date when this relation gets active. */
    activeFrom?: Maybe<Scalars['String']['output']>;
    /** The date until this relation is active. */
    activeUntil?: Maybe<Scalars['String']['output']>;
    /** Contains a list for the permissions for this instance and a list of fields for each CRUD operation. */
    permissions: Permissions;
    /** Info about relations on the Fact Sheet that constrain this relation */
    constrainingRelations: ConstrainingRelation;
    description?: Maybe<Scalars['String']['output']>;
  };

/** Relation with name relProcessToBusinessCapability, which is a FORWARD relation. */
export type RelProcessToBusinessCapability = Node &
  BaseRelation & {
    __typename?: 'RelProcessToBusinessCapability';
    /** The global id of the relation. */
    id: Scalars['ID']['output'];
    /** The relation type. */
    type?: Maybe<RelationType>;
    /** The Fact Sheet this relation points to. */
    factSheet?: Maybe<BaseFactSheet>;
    /**
     * An array of field names that indicates which fields of this relation have
     * intentionally not been set. These fields are thus not used for completeness calculation.
     */
    naFields?: Maybe<Array<Scalars['String']['output']>>;
    /** The date when this relation gets active. */
    activeFrom?: Maybe<Scalars['String']['output']>;
    /** The date until this relation is active. */
    activeUntil?: Maybe<Scalars['String']['output']>;
    /** Contains a list for the permissions for this instance and a list of fields for each CRUD operation. */
    permissions: Permissions;
    description?: Maybe<Scalars['String']['output']>;
  };

/** Relation with name relProcessToProduct, which is a REVERSE relation. */
export type RelProcessToProduct = Node &
  BaseRelation & {
    __typename?: 'RelProcessToProduct';
    /** The global id of the relation. */
    id: Scalars['ID']['output'];
    /** The relation type. */
    type?: Maybe<RelationType>;
    /** The Fact Sheet this relation points to. */
    factSheet?: Maybe<BaseFactSheet>;
    /**
     * An array of field names that indicates which fields of this relation have
     * intentionally not been set. These fields are thus not used for completeness calculation.
     */
    naFields?: Maybe<Array<Scalars['String']['output']>>;
    /** The date when this relation gets active. */
    activeFrom?: Maybe<Scalars['String']['output']>;
    /** The date until this relation is active. */
    activeUntil?: Maybe<Scalars['String']['output']>;
    /** Contains a list for the permissions for this instance and a list of fields for each CRUD operation. */
    permissions: Permissions;
    description?: Maybe<Scalars['String']['output']>;
  };

/** Relation with name relProcessToProject, which is a REVERSE relation. */
export type RelProcessToProject = Node &
  BaseRelation & {
    __typename?: 'RelProcessToProject';
    /** The global id of the relation. */
    id: Scalars['ID']['output'];
    /** The relation type. */
    type?: Maybe<RelationType>;
    /** The Fact Sheet this relation points to. */
    factSheet?: Maybe<BaseFactSheet>;
    /**
     * An array of field names that indicates which fields of this relation have
     * intentionally not been set. These fields are thus not used for completeness calculation.
     */
    naFields?: Maybe<Array<Scalars['String']['output']>>;
    /** The date when this relation gets active. */
    activeFrom?: Maybe<Scalars['String']['output']>;
    /** The date until this relation is active. */
    activeUntil?: Maybe<Scalars['String']['output']>;
    /** Contains a list for the permissions for this instance and a list of fields for each CRUD operation. */
    permissions: Permissions;
    description?: Maybe<Scalars['String']['output']>;
  };

/** Relation with name relProductToApplication, which is a FORWARD relation. */
export type RelProductToApplication = Node &
  BaseRelation & {
    __typename?: 'RelProductToApplication';
    /** The global id of the relation. */
    id: Scalars['ID']['output'];
    /** The relation type. */
    type?: Maybe<RelationType>;
    /** The Fact Sheet this relation points to. */
    factSheet?: Maybe<BaseFactSheet>;
    /**
     * An array of field names that indicates which fields of this relation have
     * intentionally not been set. These fields are thus not used for completeness calculation.
     */
    naFields?: Maybe<Array<Scalars['String']['output']>>;
    /** The date when this relation gets active. */
    activeFrom?: Maybe<Scalars['String']['output']>;
    /** The date until this relation is active. */
    activeUntil?: Maybe<Scalars['String']['output']>;
    /** Contains a list for the permissions for this instance and a list of fields for each CRUD operation. */
    permissions: Permissions;
    description?: Maybe<Scalars['String']['output']>;
    /** UserType */
    UserType?: Maybe<RelProductToApplicationUserType>;
  };

/** All enum value(s) which are available for this RelProductToApplicationUserType type */
export enum RelProductToApplicationUserType {
  Owner = 'Owner',
  User = 'User',
}

/** Relation with name relProductToBusinessCapability, which is a FORWARD relation. */
export type RelProductToBusinessCapability = Node &
  BaseRelation & {
    __typename?: 'RelProductToBusinessCapability';
    /** The global id of the relation. */
    id: Scalars['ID']['output'];
    /** The relation type. */
    type?: Maybe<RelationType>;
    /** The Fact Sheet this relation points to. */
    factSheet?: Maybe<BaseFactSheet>;
    /**
     * An array of field names that indicates which fields of this relation have
     * intentionally not been set. These fields are thus not used for completeness calculation.
     */
    naFields?: Maybe<Array<Scalars['String']['output']>>;
    /** The date when this relation gets active. */
    activeFrom?: Maybe<Scalars['String']['output']>;
    /** The date until this relation is active. */
    activeUntil?: Maybe<Scalars['String']['output']>;
    /** Contains a list for the permissions for this instance and a list of fields for each CRUD operation. */
    permissions: Permissions;
    description?: Maybe<Scalars['String']['output']>;
  };

/** Relation with name relProductToDataObject, which is a FORWARD relation. */
export type RelProductToDataObject = Node &
  BaseRelation & {
    __typename?: 'RelProductToDataObject';
    /** The global id of the relation. */
    id: Scalars['ID']['output'];
    /** The relation type. */
    type?: Maybe<RelationType>;
    /** The Fact Sheet this relation points to. */
    factSheet?: Maybe<BaseFactSheet>;
    /**
     * An array of field names that indicates which fields of this relation have
     * intentionally not been set. These fields are thus not used for completeness calculation.
     */
    naFields?: Maybe<Array<Scalars['String']['output']>>;
    /** The date when this relation gets active. */
    activeFrom?: Maybe<Scalars['String']['output']>;
    /** The date until this relation is active. */
    activeUntil?: Maybe<Scalars['String']['output']>;
    /** Contains a list for the permissions for this instance and a list of fields for each CRUD operation. */
    permissions: Permissions;
    description?: Maybe<Scalars['String']['output']>;
    /** UserType */
    UserType?: Maybe<RelProductToDataObjectUserType>;
  };

/** All enum value(s) which are available for this RelProductToDataObjectUserType type */
export enum RelProductToDataObjectUserType {
  Owner = 'Owner',
  User = 'User',
}

/** Relation with name relProductToITComponent, which is a FORWARD relation. */
export type RelProductToItComponent = Node &
  BaseRelation & {
    __typename?: 'RelProductToITComponent';
    /** The global id of the relation. */
    id: Scalars['ID']['output'];
    /** The relation type. */
    type?: Maybe<RelationType>;
    /** The Fact Sheet this relation points to. */
    factSheet?: Maybe<BaseFactSheet>;
    /**
     * An array of field names that indicates which fields of this relation have
     * intentionally not been set. These fields are thus not used for completeness calculation.
     */
    naFields?: Maybe<Array<Scalars['String']['output']>>;
    /** The date when this relation gets active. */
    activeFrom?: Maybe<Scalars['String']['output']>;
    /** The date until this relation is active. */
    activeUntil?: Maybe<Scalars['String']['output']>;
    /** Contains a list for the permissions for this instance and a list of fields for each CRUD operation. */
    permissions: Permissions;
    description?: Maybe<Scalars['String']['output']>;
  };

/** Relation with name relProductToProcess, which is a FORWARD relation. */
export type RelProductToProcess = Node &
  BaseRelation & {
    __typename?: 'RelProductToProcess';
    /** The global id of the relation. */
    id: Scalars['ID']['output'];
    /** The relation type. */
    type?: Maybe<RelationType>;
    /** The Fact Sheet this relation points to. */
    factSheet?: Maybe<BaseFactSheet>;
    /**
     * An array of field names that indicates which fields of this relation have
     * intentionally not been set. These fields are thus not used for completeness calculation.
     */
    naFields?: Maybe<Array<Scalars['String']['output']>>;
    /** The date when this relation gets active. */
    activeFrom?: Maybe<Scalars['String']['output']>;
    /** The date until this relation is active. */
    activeUntil?: Maybe<Scalars['String']['output']>;
    /** Contains a list for the permissions for this instance and a list of fields for each CRUD operation. */
    permissions: Permissions;
    description?: Maybe<Scalars['String']['output']>;
  };

/** Relation with name relProductToProject, which is a FORWARD relation. */
export type RelProductToProject = Node &
  BaseRelation & {
    __typename?: 'RelProductToProject';
    /** The global id of the relation. */
    id: Scalars['ID']['output'];
    /** The relation type. */
    type?: Maybe<RelationType>;
    /** The Fact Sheet this relation points to. */
    factSheet?: Maybe<BaseFactSheet>;
    /**
     * An array of field names that indicates which fields of this relation have
     * intentionally not been set. These fields are thus not used for completeness calculation.
     */
    naFields?: Maybe<Array<Scalars['String']['output']>>;
    /** The date when this relation gets active. */
    activeFrom?: Maybe<Scalars['String']['output']>;
    /** The date until this relation is active. */
    activeUntil?: Maybe<Scalars['String']['output']>;
    /** Contains a list for the permissions for this instance and a list of fields for each CRUD operation. */
    permissions: Permissions;
    description?: Maybe<Scalars['String']['output']>;
  };

/** Relation with name relProductToUserGroup, which is a FORWARD relation. */
export type RelProductToUserGroup = Node &
  BaseRelation & {
    __typename?: 'RelProductToUserGroup';
    /** The global id of the relation. */
    id: Scalars['ID']['output'];
    /** The relation type. */
    type?: Maybe<RelationType>;
    /** The Fact Sheet this relation points to. */
    factSheet?: Maybe<BaseFactSheet>;
    /**
     * An array of field names that indicates which fields of this relation have
     * intentionally not been set. These fields are thus not used for completeness calculation.
     */
    naFields?: Maybe<Array<Scalars['String']['output']>>;
    /** The date when this relation gets active. */
    activeFrom?: Maybe<Scalars['String']['output']>;
    /** The date until this relation is active. */
    activeUntil?: Maybe<Scalars['String']['output']>;
    /** Contains a list for the permissions for this instance and a list of fields for each CRUD operation. */
    permissions: Permissions;
    description?: Maybe<Scalars['String']['output']>;
  };

/** Relation with name relProjectToApplication, which is a REVERSE relation. */
export type RelProjectToApplication = Node &
  BaseRelation & {
    __typename?: 'RelProjectToApplication';
    /** The global id of the relation. */
    id: Scalars['ID']['output'];
    /** The relation type. */
    type?: Maybe<RelationType>;
    /** The Fact Sheet this relation points to. */
    factSheet?: Maybe<BaseFactSheet>;
    /**
     * An array of field names that indicates which fields of this relation have
     * intentionally not been set. These fields are thus not used for completeness calculation.
     */
    naFields?: Maybe<Array<Scalars['String']['output']>>;
    /** The date when this relation gets active. */
    activeFrom?: Maybe<Scalars['String']['output']>;
    /** The date until this relation is active. */
    activeUntil?: Maybe<Scalars['String']['output']>;
    /** Contains a list for the permissions for this instance and a list of fields for each CRUD operation. */
    permissions: Permissions;
    description?: Maybe<Scalars['String']['output']>;
  };

/** Relation with name relProjectToBlockedProject, which is a FORWARD relation. */
export type RelProjectToBlockedProject = Node &
  BaseRelation & {
    __typename?: 'RelProjectToBlockedProject';
    /** The global id of the relation. */
    id: Scalars['ID']['output'];
    /** The relation type. */
    type?: Maybe<RelationType>;
    /** The Fact Sheet this relation points to. */
    factSheet?: Maybe<BaseFactSheet>;
    /**
     * An array of field names that indicates which fields of this relation have
     * intentionally not been set. These fields are thus not used for completeness calculation.
     */
    naFields?: Maybe<Array<Scalars['String']['output']>>;
    /** The date when this relation gets active. */
    activeFrom?: Maybe<Scalars['String']['output']>;
    /** The date until this relation is active. */
    activeUntil?: Maybe<Scalars['String']['output']>;
    /** Contains a list for the permissions for this instance and a list of fields for each CRUD operation. */
    permissions: Permissions;
    /** Info about relations on the Fact Sheet that constrain this relation */
    constrainingRelations: ConstrainingRelation;
    description?: Maybe<Scalars['String']['output']>;
  };

/** Relation with name relProjectToBlockingProject, which is a REVERSE relation. */
export type RelProjectToBlockingProject = Node &
  BaseRelation & {
    __typename?: 'RelProjectToBlockingProject';
    /** The global id of the relation. */
    id: Scalars['ID']['output'];
    /** The relation type. */
    type?: Maybe<RelationType>;
    /** The Fact Sheet this relation points to. */
    factSheet?: Maybe<BaseFactSheet>;
    /**
     * An array of field names that indicates which fields of this relation have
     * intentionally not been set. These fields are thus not used for completeness calculation.
     */
    naFields?: Maybe<Array<Scalars['String']['output']>>;
    /** The date when this relation gets active. */
    activeFrom?: Maybe<Scalars['String']['output']>;
    /** The date until this relation is active. */
    activeUntil?: Maybe<Scalars['String']['output']>;
    /** Contains a list for the permissions for this instance and a list of fields for each CRUD operation. */
    permissions: Permissions;
    /** Info about relations on the Fact Sheet that constrain this relation */
    constrainingRelations: ConstrainingRelation;
    description?: Maybe<Scalars['String']['output']>;
  };

/** Relation with name relProjectToBusinessCapability, which is a FORWARD relation. */
export type RelProjectToBusinessCapability = Node &
  BaseRelation & {
    __typename?: 'RelProjectToBusinessCapability';
    /** The global id of the relation. */
    id: Scalars['ID']['output'];
    /** The relation type. */
    type?: Maybe<RelationType>;
    /** The Fact Sheet this relation points to. */
    factSheet?: Maybe<BaseFactSheet>;
    /**
     * An array of field names that indicates which fields of this relation have
     * intentionally not been set. These fields are thus not used for completeness calculation.
     */
    naFields?: Maybe<Array<Scalars['String']['output']>>;
    /** The date when this relation gets active. */
    activeFrom?: Maybe<Scalars['String']['output']>;
    /** The date until this relation is active. */
    activeUntil?: Maybe<Scalars['String']['output']>;
    /** Contains a list for the permissions for this instance and a list of fields for each CRUD operation. */
    permissions: Permissions;
    /** Info about relations on the Fact Sheet that constrain this relation */
    constrainingRelations: ConstrainingRelation;
    description?: Maybe<Scalars['String']['output']>;
  };

/** Relation with name relProjectToITComponent, which is a FORWARD relation. */
export type RelProjectToItComponent = Node &
  BaseRelation & {
    __typename?: 'RelProjectToITComponent';
    /** The global id of the relation. */
    id: Scalars['ID']['output'];
    /** The relation type. */
    type?: Maybe<RelationType>;
    /** The Fact Sheet this relation points to. */
    factSheet?: Maybe<BaseFactSheet>;
    /**
     * An array of field names that indicates which fields of this relation have
     * intentionally not been set. These fields are thus not used for completeness calculation.
     */
    naFields?: Maybe<Array<Scalars['String']['output']>>;
    /** The date when this relation gets active. */
    activeFrom?: Maybe<Scalars['String']['output']>;
    /** The date until this relation is active. */
    activeUntil?: Maybe<Scalars['String']['output']>;
    /** Contains a list for the permissions for this instance and a list of fields for each CRUD operation. */
    permissions: Permissions;
    description?: Maybe<Scalars['String']['output']>;
  };

/** Relation with name relProjectToProcess, which is a FORWARD relation. */
export type RelProjectToProcess = Node &
  BaseRelation & {
    __typename?: 'RelProjectToProcess';
    /** The global id of the relation. */
    id: Scalars['ID']['output'];
    /** The relation type. */
    type?: Maybe<RelationType>;
    /** The Fact Sheet this relation points to. */
    factSheet?: Maybe<BaseFactSheet>;
    /**
     * An array of field names that indicates which fields of this relation have
     * intentionally not been set. These fields are thus not used for completeness calculation.
     */
    naFields?: Maybe<Array<Scalars['String']['output']>>;
    /** The date when this relation gets active. */
    activeFrom?: Maybe<Scalars['String']['output']>;
    /** The date until this relation is active. */
    activeUntil?: Maybe<Scalars['String']['output']>;
    /** Contains a list for the permissions for this instance and a list of fields for each CRUD operation. */
    permissions: Permissions;
    description?: Maybe<Scalars['String']['output']>;
  };

/** Relation with name relProjectToProduct, which is a REVERSE relation. */
export type RelProjectToProduct = Node &
  BaseRelation & {
    __typename?: 'RelProjectToProduct';
    /** The global id of the relation. */
    id: Scalars['ID']['output'];
    /** The relation type. */
    type?: Maybe<RelationType>;
    /** The Fact Sheet this relation points to. */
    factSheet?: Maybe<BaseFactSheet>;
    /**
     * An array of field names that indicates which fields of this relation have
     * intentionally not been set. These fields are thus not used for completeness calculation.
     */
    naFields?: Maybe<Array<Scalars['String']['output']>>;
    /** The date when this relation gets active. */
    activeFrom?: Maybe<Scalars['String']['output']>;
    /** The date until this relation is active. */
    activeUntil?: Maybe<Scalars['String']['output']>;
    /** Contains a list for the permissions for this instance and a list of fields for each CRUD operation. */
    permissions: Permissions;
    description?: Maybe<Scalars['String']['output']>;
  };

/** Relation with name relProjectToProvider, which is a FORWARD relation. */
export type RelProjectToProvider = Node &
  BaseRelation & {
    __typename?: 'RelProjectToProvider';
    /** The global id of the relation. */
    id: Scalars['ID']['output'];
    /** The relation type. */
    type?: Maybe<RelationType>;
    /** The Fact Sheet this relation points to. */
    factSheet?: Maybe<BaseFactSheet>;
    /**
     * An array of field names that indicates which fields of this relation have
     * intentionally not been set. These fields are thus not used for completeness calculation.
     */
    naFields?: Maybe<Array<Scalars['String']['output']>>;
    /** The date when this relation gets active. */
    activeFrom?: Maybe<Scalars['String']['output']>;
    /** The date until this relation is active. */
    activeUntil?: Maybe<Scalars['String']['output']>;
    /** Contains a list for the permissions for this instance and a list of fields for each CRUD operation. */
    permissions: Permissions;
    orderNo?: Maybe<Scalars['String']['output']>;
    orderedCapex?: Maybe<Scalars['Int']['output']>;
    orderedOpex?: Maybe<Scalars['Int']['output']>;
    description?: Maybe<Scalars['String']['output']>;
  };

/** Relation with name relProjectToUserGroup, which is a FORWARD relation. */
export type RelProjectToUserGroup = Node &
  BaseRelation & {
    __typename?: 'RelProjectToUserGroup';
    /** The global id of the relation. */
    id: Scalars['ID']['output'];
    /** The relation type. */
    type?: Maybe<RelationType>;
    /** The Fact Sheet this relation points to. */
    factSheet?: Maybe<BaseFactSheet>;
    /**
     * An array of field names that indicates which fields of this relation have
     * intentionally not been set. These fields are thus not used for completeness calculation.
     */
    naFields?: Maybe<Array<Scalars['String']['output']>>;
    /** The date when this relation gets active. */
    activeFrom?: Maybe<Scalars['String']['output']>;
    /** The date until this relation is active. */
    activeUntil?: Maybe<Scalars['String']['output']>;
    /** Contains a list for the permissions for this instance and a list of fields for each CRUD operation. */
    permissions: Permissions;
    description?: Maybe<Scalars['String']['output']>;
  };

/** Relation with name relProviderApplicationToInterface, which is a FORWARD relation. */
export type RelProviderApplicationToInterface = Node &
  BaseRelation & {
    __typename?: 'RelProviderApplicationToInterface';
    /** The global id of the relation. */
    id: Scalars['ID']['output'];
    /** The relation type. */
    type?: Maybe<RelationType>;
    /** The Fact Sheet this relation points to. */
    factSheet?: Maybe<BaseFactSheet>;
    /**
     * An array of field names that indicates which fields of this relation have
     * intentionally not been set. These fields are thus not used for completeness calculation.
     */
    naFields?: Maybe<Array<Scalars['String']['output']>>;
    /** The date when this relation gets active. */
    activeFrom?: Maybe<Scalars['String']['output']>;
    /** The date until this relation is active. */
    activeUntil?: Maybe<Scalars['String']['output']>;
    /** Contains a list for the permissions for this instance and a list of fields for each CRUD operation. */
    permissions: Permissions;
    description?: Maybe<Scalars['String']['output']>;
  };

/** Relation with name relProviderToITComponent, which is a REVERSE relation. */
export type RelProviderToItComponent = Node &
  BaseRelation & {
    __typename?: 'RelProviderToITComponent';
    /** The global id of the relation. */
    id: Scalars['ID']['output'];
    /** The relation type. */
    type?: Maybe<RelationType>;
    /** The Fact Sheet this relation points to. */
    factSheet?: Maybe<BaseFactSheet>;
    /**
     * An array of field names that indicates which fields of this relation have
     * intentionally not been set. These fields are thus not used for completeness calculation.
     */
    naFields?: Maybe<Array<Scalars['String']['output']>>;
    /** The date when this relation gets active. */
    activeFrom?: Maybe<Scalars['String']['output']>;
    /** The date until this relation is active. */
    activeUntil?: Maybe<Scalars['String']['output']>;
    /** Contains a list for the permissions for this instance and a list of fields for each CRUD operation. */
    permissions: Permissions;
    description?: Maybe<Scalars['String']['output']>;
  };

/** Relation with name relProviderToProject, which is a REVERSE relation. */
export type RelProviderToProject = Node &
  BaseRelation & {
    __typename?: 'RelProviderToProject';
    /** The global id of the relation. */
    id: Scalars['ID']['output'];
    /** The relation type. */
    type?: Maybe<RelationType>;
    /** The Fact Sheet this relation points to. */
    factSheet?: Maybe<BaseFactSheet>;
    /**
     * An array of field names that indicates which fields of this relation have
     * intentionally not been set. These fields are thus not used for completeness calculation.
     */
    naFields?: Maybe<Array<Scalars['String']['output']>>;
    /** The date when this relation gets active. */
    activeFrom?: Maybe<Scalars['String']['output']>;
    /** The date until this relation is active. */
    activeUntil?: Maybe<Scalars['String']['output']>;
    /** Contains a list for the permissions for this instance and a list of fields for each CRUD operation. */
    permissions: Permissions;
    orderNo?: Maybe<Scalars['String']['output']>;
    orderedCapex?: Maybe<Scalars['Int']['output']>;
    orderedOpex?: Maybe<Scalars['Int']['output']>;
    description?: Maybe<Scalars['String']['output']>;
  };

/** Relation with name relTechnologyStackToITComponent, which is a REVERSE relation. */
export type RelTechnologyStackToItComponent = Node &
  BaseRelation & {
    __typename?: 'RelTechnologyStackToITComponent';
    /** The global id of the relation. */
    id: Scalars['ID']['output'];
    /** The relation type. */
    type?: Maybe<RelationType>;
    /** The Fact Sheet this relation points to. */
    factSheet?: Maybe<BaseFactSheet>;
    /**
     * An array of field names that indicates which fields of this relation have
     * intentionally not been set. These fields are thus not used for completeness calculation.
     */
    naFields?: Maybe<Array<Scalars['String']['output']>>;
    /** The date when this relation gets active. */
    activeFrom?: Maybe<Scalars['String']['output']>;
    /** The date until this relation is active. */
    activeUntil?: Maybe<Scalars['String']['output']>;
    /** Contains a list for the permissions for this instance and a list of fields for each CRUD operation. */
    permissions: Permissions;
    /** Info about relations on the Fact Sheet that constrain this relation */
    constrainingRelations: ConstrainingRelation;
    /** supportType */
    supportType?: Maybe<RelTechnologyStackToItComponentSupportType>;
    /** resourceClassification */
    resourceClassification?: Maybe<RelTechnologyStackToItComponentResourceClassification>;
    description?: Maybe<Scalars['String']['output']>;
  };

/** All enum value(s) which are available for this RelTechnologyStackToITComponentSupportType type */
export enum RelTechnologyStackToItComponentSupportType {
  Supports = 'supports',
  Leading = 'leading',
}

/** All enum value(s) which are available for this RelTechnologyStackToITComponentResourceClassification type */
export enum RelTechnologyStackToItComponentResourceClassification {
  Investigating = 'investigating',
  Approved = 'approved',
  Conditional = 'conditional',
  Retiring = 'retiring',
  Unapproved = 'unapproved',
}

/** Relation with name relToChild, which is a REVERSE relation. */
export type RelToChild = Node &
  BaseRelation & {
    __typename?: 'RelToChild';
    /** The global id of the relation. */
    id: Scalars['ID']['output'];
    /** The relation type. */
    type?: Maybe<RelationType>;
    /** The Fact Sheet this relation points to. */
    factSheet?: Maybe<BaseFactSheet>;
    /**
     * An array of field names that indicates which fields of this relation have
     * intentionally not been set. These fields are thus not used for completeness calculation.
     */
    naFields?: Maybe<Array<Scalars['String']['output']>>;
    /** The date when this relation gets active. */
    activeFrom?: Maybe<Scalars['String']['output']>;
    /** The date until this relation is active. */
    activeUntil?: Maybe<Scalars['String']['output']>;
    /** Contains a list for the permissions for this instance and a list of fields for each CRUD operation. */
    permissions: Permissions;
    description?: Maybe<Scalars['String']['output']>;
  };

/** Relation with name relToPredecessor, which is a REVERSE relation. */
export type RelToPredecessor = Node &
  BaseRelation & {
    __typename?: 'RelToPredecessor';
    /** The global id of the relation. */
    id: Scalars['ID']['output'];
    /** The relation type. */
    type?: Maybe<RelationType>;
    /** The Fact Sheet this relation points to. */
    factSheet?: Maybe<BaseFactSheet>;
    /**
     * An array of field names that indicates which fields of this relation have
     * intentionally not been set. These fields are thus not used for completeness calculation.
     */
    naFields?: Maybe<Array<Scalars['String']['output']>>;
    /** The date when this relation gets active. */
    activeFrom?: Maybe<Scalars['String']['output']>;
    /** The date until this relation is active. */
    activeUntil?: Maybe<Scalars['String']['output']>;
    /** Contains a list for the permissions for this instance and a list of fields for each CRUD operation. */
    permissions: Permissions;
    description?: Maybe<Scalars['String']['output']>;
  };

/** Relation with name relToRequiredBy, which is a REVERSE relation. */
export type RelToRequiredBy = Node &
  BaseRelation & {
    __typename?: 'RelToRequiredBy';
    /** The global id of the relation. */
    id: Scalars['ID']['output'];
    /** The relation type. */
    type?: Maybe<RelationType>;
    /** The Fact Sheet this relation points to. */
    factSheet?: Maybe<BaseFactSheet>;
    /**
     * An array of field names that indicates which fields of this relation have
     * intentionally not been set. These fields are thus not used for completeness calculation.
     */
    naFields?: Maybe<Array<Scalars['String']['output']>>;
    /** The date when this relation gets active. */
    activeFrom?: Maybe<Scalars['String']['output']>;
    /** The date until this relation is active. */
    activeUntil?: Maybe<Scalars['String']['output']>;
    /** Contains a list for the permissions for this instance and a list of fields for each CRUD operation. */
    permissions: Permissions;
    description?: Maybe<Scalars['String']['output']>;
  };

/** Relation with name relToRequires, which is a FORWARD relation. */
export type RelToRequires = Node &
  BaseRelation & {
    __typename?: 'RelToRequires';
    /** The global id of the relation. */
    id: Scalars['ID']['output'];
    /** The relation type. */
    type?: Maybe<RelationType>;
    /** The Fact Sheet this relation points to. */
    factSheet?: Maybe<BaseFactSheet>;
    /**
     * An array of field names that indicates which fields of this relation have
     * intentionally not been set. These fields are thus not used for completeness calculation.
     */
    naFields?: Maybe<Array<Scalars['String']['output']>>;
    /** The date when this relation gets active. */
    activeFrom?: Maybe<Scalars['String']['output']>;
    /** The date until this relation is active. */
    activeUntil?: Maybe<Scalars['String']['output']>;
    /** Contains a list for the permissions for this instance and a list of fields for each CRUD operation. */
    permissions: Permissions;
    description?: Maybe<Scalars['String']['output']>;
  };

/** Relation with name relToSuccessor, which is a FORWARD relation. */
export type RelToSuccessor = Node &
  BaseRelation & {
    __typename?: 'RelToSuccessor';
    /** The global id of the relation. */
    id: Scalars['ID']['output'];
    /** The relation type. */
    type?: Maybe<RelationType>;
    /** The Fact Sheet this relation points to. */
    factSheet?: Maybe<BaseFactSheet>;
    /**
     * An array of field names that indicates which fields of this relation have
     * intentionally not been set. These fields are thus not used for completeness calculation.
     */
    naFields?: Maybe<Array<Scalars['String']['output']>>;
    /** The date when this relation gets active. */
    activeFrom?: Maybe<Scalars['String']['output']>;
    /** The date until this relation is active. */
    activeUntil?: Maybe<Scalars['String']['output']>;
    /** Contains a list for the permissions for this instance and a list of fields for each CRUD operation. */
    permissions: Permissions;
    description?: Maybe<Scalars['String']['output']>;
  };

/** Relation with name relUserGroupToApplication, which is a REVERSE relation. */
export type RelUserGroupToApplication = Node &
  BaseRelation & {
    __typename?: 'RelUserGroupToApplication';
    /** The global id of the relation. */
    id: Scalars['ID']['output'];
    /** The relation type. */
    type?: Maybe<RelationType>;
    /** The Fact Sheet this relation points to. */
    factSheet?: Maybe<BaseFactSheet>;
    /**
     * An array of field names that indicates which fields of this relation have
     * intentionally not been set. These fields are thus not used for completeness calculation.
     */
    naFields?: Maybe<Array<Scalars['String']['output']>>;
    /** The date when this relation gets active. */
    activeFrom?: Maybe<Scalars['String']['output']>;
    /** The date until this relation is active. */
    activeUntil?: Maybe<Scalars['String']['output']>;
    /** Contains a list for the permissions for this instance and a list of fields for each CRUD operation. */
    permissions: Permissions;
    /** Info about relations on the Fact Sheet that constrain this relation */
    constrainingRelations: ConstrainingRelation;
    /** functionalSuitability */
    functionalSuitability?: Maybe<RelUserGroupToApplicationFunctionalSuitability>;
    numberOfUsers?: Maybe<Scalars['Int']['output']>;
    description?: Maybe<Scalars['String']['output']>;
    /** usageType */
    usageType?: Maybe<RelUserGroupToApplicationUsageType>;
  };

/** All enum value(s) which are available for this RelUserGroupToApplicationFunctionalSuitability type */
export enum RelUserGroupToApplicationFunctionalSuitability {
  Unreasonable = 'unreasonable',
  Insufficient = 'insufficient',
  Appropriate = 'appropriate',
  Perfect = 'perfect',
}

/** All enum value(s) which are available for this RelUserGroupToApplicationUsageType type */
export enum RelUserGroupToApplicationUsageType {
  User = 'user',
  Owner = 'owner',
}

/** Relation with name relUserGroupToITComponent, which is a REVERSE relation. */
export type RelUserGroupToItComponent = Node &
  BaseRelation & {
    __typename?: 'RelUserGroupToITComponent';
    /** The global id of the relation. */
    id: Scalars['ID']['output'];
    /** The relation type. */
    type?: Maybe<RelationType>;
    /** The Fact Sheet this relation points to. */
    factSheet?: Maybe<BaseFactSheet>;
    /**
     * An array of field names that indicates which fields of this relation have
     * intentionally not been set. These fields are thus not used for completeness calculation.
     */
    naFields?: Maybe<Array<Scalars['String']['output']>>;
    /** The date when this relation gets active. */
    activeFrom?: Maybe<Scalars['String']['output']>;
    /** The date until this relation is active. */
    activeUntil?: Maybe<Scalars['String']['output']>;
    /** Contains a list for the permissions for this instance and a list of fields for each CRUD operation. */
    permissions: Permissions;
    /** technicalSuitability */
    technicalSuitability?: Maybe<RelUserGroupToItComponentTechnicalSuitability>;
    numberOfUsers?: Maybe<Scalars['Int']['output']>;
    description?: Maybe<Scalars['String']['output']>;
    /** usageType */
    usageType?: Maybe<RelUserGroupToItComponentUsageType>;
  };

/** All enum value(s) which are available for this RelUserGroupToITComponentTechnicalSuitability type */
export enum RelUserGroupToItComponentTechnicalSuitability {
  Inappropriate = 'inappropriate',
  Unreasonable = 'unreasonable',
  Adequate = 'adequate',
  FullyAppropriate = 'fullyAppropriate',
}

/** All enum value(s) which are available for this RelUserGroupToITComponentUsageType type */
export enum RelUserGroupToItComponentUsageType {
  User = 'user',
  Owner = 'owner',
}

/** Relation with name relUserGroupToProduct, which is a REVERSE relation. */
export type RelUserGroupToProduct = Node &
  BaseRelation & {
    __typename?: 'RelUserGroupToProduct';
    /** The global id of the relation. */
    id: Scalars['ID']['output'];
    /** The relation type. */
    type?: Maybe<RelationType>;
    /** The Fact Sheet this relation points to. */
    factSheet?: Maybe<BaseFactSheet>;
    /**
     * An array of field names that indicates which fields of this relation have
     * intentionally not been set. These fields are thus not used for completeness calculation.
     */
    naFields?: Maybe<Array<Scalars['String']['output']>>;
    /** The date when this relation gets active. */
    activeFrom?: Maybe<Scalars['String']['output']>;
    /** The date until this relation is active. */
    activeUntil?: Maybe<Scalars['String']['output']>;
    /** Contains a list for the permissions for this instance and a list of fields for each CRUD operation. */
    permissions: Permissions;
    description?: Maybe<Scalars['String']['output']>;
  };

/** Relation with name relUserGroupToProject, which is a REVERSE relation. */
export type RelUserGroupToProject = Node &
  BaseRelation & {
    __typename?: 'RelUserGroupToProject';
    /** The global id of the relation. */
    id: Scalars['ID']['output'];
    /** The relation type. */
    type?: Maybe<RelationType>;
    /** The Fact Sheet this relation points to. */
    factSheet?: Maybe<BaseFactSheet>;
    /**
     * An array of field names that indicates which fields of this relation have
     * intentionally not been set. These fields are thus not used for completeness calculation.
     */
    naFields?: Maybe<Array<Scalars['String']['output']>>;
    /** The date when this relation gets active. */
    activeFrom?: Maybe<Scalars['String']['output']>;
    /** The date until this relation is active. */
    activeUntil?: Maybe<Scalars['String']['output']>;
    /** Contains a list for the permissions for this instance and a list of fields for each CRUD operation. */
    permissions: Permissions;
    description?: Maybe<Scalars['String']['output']>;
  };

/** Filter for the validity of relations. */
export type RelationValidityFilter = {
  /** The start of the validity of the relation, if not set all relations valid until activeUntil date are found. */
  activeFrom?: InputMaybe<Scalars['Date']['input']>;
  /** The end of the validity of the relation, if not set all relations valid from activeFrom date are found. */
  activeUntil?: InputMaybe<Scalars['Date']['input']>;
};

/** Describes the applied filtering of one facet. This contains the name of the facet, an operator, keys or Ids etc. */
export type FacetFilterInput = {
  /** The name of the facet */
  facetKey: Scalars['String']['input'];
  /** The operator that is applyed for each key within this facet filter. */
  operator?: InputMaybe<FacetKeyOperator>;
  /** The filter value that is used. This can be a string, a id, an constant etc. depending of context of facet filter. */
  keys: Array<InputMaybe<Scalars['String']['input']>>;
  /** Additional filter keys that can be used to filter relations with a 'groupBy' configuration in their data model definition. */
  groupByKeys?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /**
   * This type is intended when filtering for dates and time ranges and the the
   * simple key list is not suitable.It is normally used in the lifecycle type
   * facets and all times are interpreted as UTC time.
   */
  dateFilter?: InputMaybe<DateFilterInput>;
  /**
   * The normal subscription facet provides keys for filtering by subscribed
   * persons.When you need to filter additionally by subscription types and
   * subscription roles this filter have to be used to set these filter arguments
   */
  subscriptionFilter?: InputMaybe<SubscriptionFacetFilterInput>;
  /**
   * A sub-filter used to specify further filter settings for this facet.
   * sub-filter can only be used in facets of relation type.
   */
  subFilter?: InputMaybe<SubFacetFilterInput>;
  /** The relation fields filter operator applied to the relation fields of this relation facet. */
  relationFieldsFilterOperator?: InputMaybe<RelationFieldsFilterOperator>;
  /**
   * Relevant for relation facets only: List of relation fields and their values to
   * restrict the facet filtering. Needs active feature toggle
   * 'inventory.relationfieldfiltering' and is experimental and subject to change.
   * Do not use it for productive integrations.
   */
  relationFieldsFilter?: InputMaybe<
    Array<InputMaybe<RelationFieldsFilterFieldInput>>
  >;
  /** Excludes transitive relations from the retrieval. Applicable only to relation facet filters */
  excludeTransitiveRelations?: InputMaybe<Scalars['Boolean']['input']>;
};

/**
 * In case that multiple filter items should be used for filtering within one
 * facet, this is the operator to combine each item. (NOR means that none of
 * selected items must match.)
 */
export enum FacetKeyOperator {
  Or = 'OR',
  And = 'AND',
  Nor = 'NOR',
}

/** A filter object used when filtering over timestamps and time ranges. */
export type DateFilterInput = {
  /** The start UTC time of a range query or timestamp in ISO 8601 date format. */
  from?: InputMaybe<Scalars['String']['input']>;
  /** The end UTC time of a range query in ISO 8601 date format. */
  to?: InputMaybe<Scalars['String']['input']>;
  /** The type this date filter required to say the backend how to interprete the from and to field. The default is RANGE */
  type?: InputMaybe<DateFilterType>;
};

/** Use a date filter for the facet item. Currently used only for the lifecycle filter type. */
export enum DateFilterType {
  Point = 'POINT',
  Today = 'TODAY',
  EndOfMonth = 'END_OF_MONTH',
  EndOfYear = 'END_OF_YEAR',
  Range = 'RANGE',
  RangeStarts = 'RANGE_STARTS',
  RangeEnds = 'RANGE_ENDS',
  Timeline = 'TIMELINE',
}

/**
 * This object is only used when a subscription facet has to apply the selected
 * person by a subscription type and a subscription role.
 */
export type SubscriptionFacetFilterInput = {
  /** The subscription type that has to be used as additionally filter criteria. */
  type: FactSheetSubscriptionType;
  /** The subscription role Id that has to be used as additionally filter criteria. */
  roleId?: InputMaybe<Scalars['ID']['input']>;
};

/**
 * A sub-filter which is embetted within a facet filter. This filter can be used to
 * specify a query where the resulting Fact Sheets are filter of the embedded facet
 * filter. A sub-filter can only be used within relation type facets.
 */
export type SubFacetFilterInput = {
  /**
   * Stores a list of FacetFilterInput objects used as a sub-filter within the
   * embedded facet. These sub-filters are used for transitive queries.
   */
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
  /** A text used to query for display names and fullTextSearch enabled fields of Fact Sheets. */
  fullTextSearch?: InputMaybe<Scalars['String']['input']>;
  /** A list of Fact Sheet IDs. Useful to retrieve only Fact Sheet for a list of given IDs. */
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export enum RelationFieldsFilterOperator {
  /** Only the matches of the applied relation field filters are kept in the response. */
  Inclusive = 'INCLUSIVE',
  /** The matches of the applied relation field filters are removed from the response. */
  Exclusive = 'EXCLUSIVE',
}

/** Describes the filtering for a relation field of the facet's relation to be applied. */
export type RelationFieldsFilterFieldInput = {
  /** The relation field name */
  fieldName: Scalars['String']['input'];
  /** The field values to match */
  values: Array<Scalars['String']['input']>;
};

/** A connection to a list of items. */
export type RelToChildConnection = {
  __typename?: 'RelToChildConnection';
  edges?: Maybe<Array<Maybe<RelToChildEdge>>>;
  pageInfo: PageInfo;
  /** A count of the total number of objects in this collection, ignoring pagination. */
  totalCount: Scalars['Int']['output'];
  /** Contains a list of the permissions for this type of relation and a list of fields for each CRUD operation. */
  permissions: Permissions;
  /**
   * A count of the skipped number of objects in this collection and for this
   * pagignation. The reason to skip/ hide an object can be a missing READ
   * permission of the relation of the target Fact Sheet for the requesting user.
   */
  skippedCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type RelToChildEdge = {
  __typename?: 'RelToChildEdge';
  /** The item at the end of the edge */
  node?: Maybe<RelToChild>;
  cursor: Scalars['String']['output'];
};

/** A connection to a list of items. */
export type RelToRequiresConnection = {
  __typename?: 'RelToRequiresConnection';
  edges?: Maybe<Array<Maybe<RelToRequiresEdge>>>;
  pageInfo: PageInfo;
  /** A count of the total number of objects in this collection, ignoring pagination. */
  totalCount: Scalars['Int']['output'];
  /** Contains a list of the permissions for this type of relation and a list of fields for each CRUD operation. */
  permissions: Permissions;
  /**
   * A count of the skipped number of objects in this collection and for this
   * pagignation. The reason to skip/ hide an object can be a missing READ
   * permission of the relation of the target Fact Sheet for the requesting user.
   */
  skippedCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type RelToRequiresEdge = {
  __typename?: 'RelToRequiresEdge';
  /** The item at the end of the edge */
  node?: Maybe<RelToRequires>;
  cursor: Scalars['String']['output'];
};

/** A connection to a list of items. */
export type RelToRequiredByConnection = {
  __typename?: 'RelToRequiredByConnection';
  edges?: Maybe<Array<Maybe<RelToRequiredByEdge>>>;
  pageInfo: PageInfo;
  /** A count of the total number of objects in this collection, ignoring pagination. */
  totalCount: Scalars['Int']['output'];
  /** Contains a list of the permissions for this type of relation and a list of fields for each CRUD operation. */
  permissions: Permissions;
  /**
   * A count of the skipped number of objects in this collection and for this
   * pagignation. The reason to skip/ hide an object can be a missing READ
   * permission of the relation of the target Fact Sheet for the requesting user.
   */
  skippedCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type RelToRequiredByEdge = {
  __typename?: 'RelToRequiredByEdge';
  /** The item at the end of the edge */
  node?: Maybe<RelToRequiredBy>;
  cursor: Scalars['String']['output'];
};

/** A connection to a list of items. */
export type RelToSuccessorConnection = {
  __typename?: 'RelToSuccessorConnection';
  edges?: Maybe<Array<Maybe<RelToSuccessorEdge>>>;
  pageInfo: PageInfo;
  /** A count of the total number of objects in this collection, ignoring pagination. */
  totalCount: Scalars['Int']['output'];
  /** Contains a list of the permissions for this type of relation and a list of fields for each CRUD operation. */
  permissions: Permissions;
  /**
   * A count of the skipped number of objects in this collection and for this
   * pagignation. The reason to skip/ hide an object can be a missing READ
   * permission of the relation of the target Fact Sheet for the requesting user.
   */
  skippedCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type RelToSuccessorEdge = {
  __typename?: 'RelToSuccessorEdge';
  /** The item at the end of the edge */
  node?: Maybe<RelToSuccessor>;
  cursor: Scalars['String']['output'];
};

/** A connection to a list of items. */
export type RelToPredecessorConnection = {
  __typename?: 'RelToPredecessorConnection';
  edges?: Maybe<Array<Maybe<RelToPredecessorEdge>>>;
  pageInfo: PageInfo;
  /** A count of the total number of objects in this collection, ignoring pagination. */
  totalCount: Scalars['Int']['output'];
  /** Contains a list of the permissions for this type of relation and a list of fields for each CRUD operation. */
  permissions: Permissions;
  /**
   * A count of the skipped number of objects in this collection and for this
   * pagignation. The reason to skip/ hide an object can be a missing READ
   * permission of the relation of the target Fact Sheet for the requesting user.
   */
  skippedCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type RelToPredecessorEdge = {
  __typename?: 'RelToPredecessorEdge';
  /** The item at the end of the edge */
  node?: Maybe<RelToPredecessor>;
  cursor: Scalars['String']['output'];
};

/** A connection to a list of items. */
export type RelApplicationToUserGroupConnection = {
  __typename?: 'RelApplicationToUserGroupConnection';
  edges?: Maybe<Array<Maybe<RelApplicationToUserGroupEdge>>>;
  pageInfo: PageInfo;
  /** A count of the total number of objects in this collection, ignoring pagination. */
  totalCount: Scalars['Int']['output'];
  /** Contains a list of the permissions for this type of relation and a list of fields for each CRUD operation. */
  permissions: Permissions;
  /**
   * A count of the skipped number of objects in this collection and for this
   * pagignation. The reason to skip/ hide an object can be a missing READ
   * permission of the relation of the target Fact Sheet for the requesting user.
   */
  skippedCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type RelApplicationToUserGroupEdge = {
  __typename?: 'RelApplicationToUserGroupEdge';
  /** The item at the end of the edge */
  node?: Maybe<RelApplicationToUserGroup>;
  cursor: Scalars['String']['output'];
};

/** A connection to a list of items. */
export type RelApplicationToDataObjectConnection = {
  __typename?: 'RelApplicationToDataObjectConnection';
  edges?: Maybe<Array<Maybe<RelApplicationToDataObjectEdge>>>;
  pageInfo: PageInfo;
  /** A count of the total number of objects in this collection, ignoring pagination. */
  totalCount: Scalars['Int']['output'];
  /** Contains a list of the permissions for this type of relation and a list of fields for each CRUD operation. */
  permissions: Permissions;
  /**
   * A count of the skipped number of objects in this collection and for this
   * pagignation. The reason to skip/ hide an object can be a missing READ
   * permission of the relation of the target Fact Sheet for the requesting user.
   */
  skippedCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type RelApplicationToDataObjectEdge = {
  __typename?: 'RelApplicationToDataObjectEdge';
  /** The item at the end of the edge */
  node?: Maybe<RelApplicationToDataObject>;
  cursor: Scalars['String']['output'];
};

/** A connection to a list of items. */
export type RelApplicationToItComponentConnection = {
  __typename?: 'RelApplicationToITComponentConnection';
  edges?: Maybe<Array<Maybe<RelApplicationToItComponentEdge>>>;
  pageInfo: PageInfo;
  /** A count of the total number of objects in this collection, ignoring pagination. */
  totalCount: Scalars['Int']['output'];
  /** Contains a list of the permissions for this type of relation and a list of fields for each CRUD operation. */
  permissions: Permissions;
  /**
   * A count of the skipped number of objects in this collection and for this
   * pagignation. The reason to skip/ hide an object can be a missing READ
   * permission of the relation of the target Fact Sheet for the requesting user.
   */
  skippedCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type RelApplicationToItComponentEdge = {
  __typename?: 'RelApplicationToITComponentEdge';
  /** The item at the end of the edge */
  node?: Maybe<RelApplicationToItComponent>;
  cursor: Scalars['String']['output'];
};

/** A connection to a list of items. */
export type RelApplicationToProjectConnection = {
  __typename?: 'RelApplicationToProjectConnection';
  edges?: Maybe<Array<Maybe<RelApplicationToProjectEdge>>>;
  pageInfo: PageInfo;
  /** A count of the total number of objects in this collection, ignoring pagination. */
  totalCount: Scalars['Int']['output'];
  /** Contains a list of the permissions for this type of relation and a list of fields for each CRUD operation. */
  permissions: Permissions;
  /**
   * A count of the skipped number of objects in this collection and for this
   * pagignation. The reason to skip/ hide an object can be a missing READ
   * permission of the relation of the target Fact Sheet for the requesting user.
   */
  skippedCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type RelApplicationToProjectEdge = {
  __typename?: 'RelApplicationToProjectEdge';
  /** The item at the end of the edge */
  node?: Maybe<RelApplicationToProject>;
  cursor: Scalars['String']['output'];
};

/** A connection to a list of items. */
export type RelProviderApplicationToInterfaceConnection = {
  __typename?: 'RelProviderApplicationToInterfaceConnection';
  edges?: Maybe<Array<Maybe<RelProviderApplicationToInterfaceEdge>>>;
  pageInfo: PageInfo;
  /** A count of the total number of objects in this collection, ignoring pagination. */
  totalCount: Scalars['Int']['output'];
  /** Contains a list of the permissions for this type of relation and a list of fields for each CRUD operation. */
  permissions: Permissions;
  /**
   * A count of the skipped number of objects in this collection and for this
   * pagignation. The reason to skip/ hide an object can be a missing READ
   * permission of the relation of the target Fact Sheet for the requesting user.
   */
  skippedCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type RelProviderApplicationToInterfaceEdge = {
  __typename?: 'RelProviderApplicationToInterfaceEdge';
  /** The item at the end of the edge */
  node?: Maybe<RelProviderApplicationToInterface>;
  cursor: Scalars['String']['output'];
};

/** A connection to a list of items. */
export type RelConsumerApplicationToInterfaceConnection = {
  __typename?: 'RelConsumerApplicationToInterfaceConnection';
  edges?: Maybe<Array<Maybe<RelConsumerApplicationToInterfaceEdge>>>;
  pageInfo: PageInfo;
  /** A count of the total number of objects in this collection, ignoring pagination. */
  totalCount: Scalars['Int']['output'];
  /** Contains a list of the permissions for this type of relation and a list of fields for each CRUD operation. */
  permissions: Permissions;
  /**
   * A count of the skipped number of objects in this collection and for this
   * pagignation. The reason to skip/ hide an object can be a missing READ
   * permission of the relation of the target Fact Sheet for the requesting user.
   */
  skippedCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type RelConsumerApplicationToInterfaceEdge = {
  __typename?: 'RelConsumerApplicationToInterfaceEdge';
  /** The item at the end of the edge */
  node?: Maybe<RelConsumerApplicationToInterface>;
  cursor: Scalars['String']['output'];
};

/** A connection to a list of items. */
export type RelApplicationToProcessConnection = {
  __typename?: 'RelApplicationToProcessConnection';
  edges?: Maybe<Array<Maybe<RelApplicationToProcessEdge>>>;
  pageInfo: PageInfo;
  /** A count of the total number of objects in this collection, ignoring pagination. */
  totalCount: Scalars['Int']['output'];
  /** Contains a list of the permissions for this type of relation and a list of fields for each CRUD operation. */
  permissions: Permissions;
  /**
   * A count of the skipped number of objects in this collection and for this
   * pagignation. The reason to skip/ hide an object can be a missing READ
   * permission of the relation of the target Fact Sheet for the requesting user.
   */
  skippedCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type RelApplicationToProcessEdge = {
  __typename?: 'RelApplicationToProcessEdge';
  /** The item at the end of the edge */
  node?: Maybe<RelApplicationToProcess>;
  cursor: Scalars['String']['output'];
};

/** A connection to a list of items. */
export type RelApplicationToBusinessCapabilityConnection = {
  __typename?: 'RelApplicationToBusinessCapabilityConnection';
  edges?: Maybe<Array<Maybe<RelApplicationToBusinessCapabilityEdge>>>;
  pageInfo: PageInfo;
  /** A count of the total number of objects in this collection, ignoring pagination. */
  totalCount: Scalars['Int']['output'];
  /** Contains a list of the permissions for this type of relation and a list of fields for each CRUD operation. */
  permissions: Permissions;
  /**
   * A count of the skipped number of objects in this collection and for this
   * pagignation. The reason to skip/ hide an object can be a missing READ
   * permission of the relation of the target Fact Sheet for the requesting user.
   */
  skippedCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type RelApplicationToBusinessCapabilityEdge = {
  __typename?: 'RelApplicationToBusinessCapabilityEdge';
  /** The item at the end of the edge */
  node?: Maybe<RelApplicationToBusinessCapability>;
  cursor: Scalars['String']['output'];
};

/** A connection to a list of items. */
export type RelApplicationToProductConnection = {
  __typename?: 'RelApplicationToProductConnection';
  edges?: Maybe<Array<Maybe<RelApplicationToProductEdge>>>;
  pageInfo: PageInfo;
  /** A count of the total number of objects in this collection, ignoring pagination. */
  totalCount: Scalars['Int']['output'];
  /** Contains a list of the permissions for this type of relation and a list of fields for each CRUD operation. */
  permissions: Permissions;
  /**
   * A count of the skipped number of objects in this collection and for this
   * pagignation. The reason to skip/ hide an object can be a missing READ
   * permission of the relation of the target Fact Sheet for the requesting user.
   */
  skippedCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type RelApplicationToProductEdge = {
  __typename?: 'RelApplicationToProductEdge';
  /** The item at the end of the edge */
  node?: Maybe<RelApplicationToProduct>;
  cursor: Scalars['String']['output'];
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: DataObject */
export type DataObject = Node &
  BaseFactSheet & {
    __typename?: 'DataObject';
    /** The graphQL id used to retrieve each node (object of arbitrary type) */
    id: Scalars['ID']['output'];
    /** The revision of the Fact Sheet, incremented automatically on each Fact Sheet update. */
    rev?: Maybe<Scalars['Long']['output']>;
    /** The name of the DataObject. */
    name: Scalars['String']['output'];
    /** The calculated display name of the Fact Sheet. */
    displayName: Scalars['String']['output'];
    /** The calculated full name of the Fact Sheet. */
    fullName: Scalars['String']['output'];
    /** The type of the Fact Sheet. (Same as parameter __typeName) */
    type: FactSheetType;
    /** This can be used to categorize the Fact Sheet type into subtypes. */
    category?: Maybe<Scalars['String']['output']>;
    /** An optional description of the DataObject. */
    description?: Maybe<Scalars['String']['output']>;
    /** Indicates how much data is already provided in the Fact Sheet, its sections and subsections. */
    completion?: Maybe<FactSheetCompletion>;
    /**
     * An array of attribute names that indicates which fields or relations of this
     * Fact Sheet have intentionally not been set. These attributes are thus not used
     * for completeness calculation.
     */
    naFields?: Maybe<Array<Scalars['String']['output']>>;
    /** The tags that are associated with the Fact Sheet. */
    tags?: Maybe<Array<Tag>>;
    /** All documents that are assigned to the Fact Sheet. */
    documents?: Maybe<DocumentConnection>;
    /** Comments on a Fact Sheet */
    comments?: Maybe<CommentConnection>;
    /** All subscriptions that are assigned to the Fact Sheet. */
    subscriptions?: Maybe<SubscriptionConnection>;
    /** A list of Access Control Entities defining which user in the workspace has read access to this Fact Sheet. */
    permittedReadACL?: Maybe<Array<AccessControlEntityReference>>;
    /** A list of Access Control Entities defining which user in the workspace has write access to this Fact Sheet. */
    permittedWriteACL?: Maybe<Array<AccessControlEntityReference>>;
    /** Status of the Fact Sheet. */
    status?: Maybe<FactSheetStatus>;
    /** The hierarchy level of the Fact Sheet. (0 means level 1, 1 means level 2, etc.) */
    level?: Maybe<Scalars['Int']['output']>;
    /**
     * The timestamp in ISO 8601 format when the Fact Sheet was created. This field
     * is read-only and will never change during the lifecycle of the FactSheet.
     */
    createdAt: Scalars['String']['output'];
    /** The timestamp in ISO 8601 format when the Fact Sheet was last updated. */
    updatedAt: Scalars['String']['output'];
    /** Contains a list for the permissions for this instance and a list of fields for each CRUD operation. */
    permissions: Permissions;
    /** The status of the quality seal for this Fact Sheet */
    qualitySeal?: Maybe<QualitySealStatus>;
    /** State of the Fact Sheet. */
    lxState?: Maybe<FactSheetState>;
    /** Milestones on the Fact Sheet. */
    lxMilestones?: Maybe<Array<Milestone>>;
    /** Whether the fact sheet should be excluded from the quota check or not. */
    lxExcludeFromQuota: Scalars['Boolean']['output'];
    /** lifecycle */
    lifecycle?: Maybe<DataObjectLifecycle>;
    /** dataClassification */
    dataClassification?: Maybe<DataObjectDataClassification>;
    alias?: Maybe<Scalars['String']['output']>;
    dataClassificationDescription?: Maybe<Scalars['String']['output']>;
    externalId?: Maybe<ExternalId>;
    dataObjectId?: Maybe<ExternalId>;
    serviceNowExternalId?: Maybe<ExternalId>;
    relToParent?: Maybe<RelToParentConnection>;
    relToChild?: Maybe<RelToChildConnection>;
    relToRequires?: Maybe<RelToRequiresConnection>;
    relToRequiredBy?: Maybe<RelToRequiredByConnection>;
    relToSuccessor?: Maybe<RelToSuccessorConnection>;
    relToPredecessor?: Maybe<RelToPredecessorConnection>;
    relDataObjectToProduct?: Maybe<RelDataObjectToProductConnection>;
    relDataObjectToApplication?: Maybe<RelDataObjectToApplicationConnection>;
    relDataObjectToInterface?: Maybe<RelDataObjectToInterfaceConnection>;
  };

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: DataObject */
export type DataObjectDocumentsArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  filter?: InputMaybe<DocumentFilterInput>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: DataObject */
export type DataObjectCommentsArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  filter?: InputMaybe<CommentFilterInput>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: DataObject */
export type DataObjectSubscriptionsArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  filter?: InputMaybe<SubscriptionFilterInput>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: DataObject */
export type DataObjectRelToParentArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: DataObject */
export type DataObjectRelToChildArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: DataObject */
export type DataObjectRelToRequiresArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: DataObject */
export type DataObjectRelToRequiredByArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: DataObject */
export type DataObjectRelToSuccessorArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: DataObject */
export type DataObjectRelToPredecessorArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: DataObject */
export type DataObjectRelDataObjectToProductArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: DataObject */
export type DataObjectRelDataObjectToApplicationArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: DataObject */
export type DataObjectRelDataObjectToInterfaceArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** All enum value(s) which are available for this DataObjectDataClassification type */
export enum DataObjectDataClassification {
  Public = 'public',
  Sensitive = 'sensitive',
  Restricted = 'restricted',
  Confidential = 'confidential',
}

/** A connection to a list of items. */
export type RelDataObjectToProductConnection = {
  __typename?: 'RelDataObjectToProductConnection';
  edges?: Maybe<Array<Maybe<RelDataObjectToProductEdge>>>;
  pageInfo: PageInfo;
  /** A count of the total number of objects in this collection, ignoring pagination. */
  totalCount: Scalars['Int']['output'];
  /** Contains a list of the permissions for this type of relation and a list of fields for each CRUD operation. */
  permissions: Permissions;
  /**
   * A count of the skipped number of objects in this collection and for this
   * pagignation. The reason to skip/ hide an object can be a missing READ
   * permission of the relation of the target Fact Sheet for the requesting user.
   */
  skippedCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type RelDataObjectToProductEdge = {
  __typename?: 'RelDataObjectToProductEdge';
  /** The item at the end of the edge */
  node?: Maybe<RelDataObjectToProduct>;
  cursor: Scalars['String']['output'];
};

/** A connection to a list of items. */
export type RelDataObjectToApplicationConnection = {
  __typename?: 'RelDataObjectToApplicationConnection';
  edges?: Maybe<Array<Maybe<RelDataObjectToApplicationEdge>>>;
  pageInfo: PageInfo;
  /** A count of the total number of objects in this collection, ignoring pagination. */
  totalCount: Scalars['Int']['output'];
  /** Contains a list of the permissions for this type of relation and a list of fields for each CRUD operation. */
  permissions: Permissions;
  /**
   * A count of the skipped number of objects in this collection and for this
   * pagignation. The reason to skip/ hide an object can be a missing READ
   * permission of the relation of the target Fact Sheet for the requesting user.
   */
  skippedCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type RelDataObjectToApplicationEdge = {
  __typename?: 'RelDataObjectToApplicationEdge';
  /** The item at the end of the edge */
  node?: Maybe<RelDataObjectToApplication>;
  cursor: Scalars['String']['output'];
};

/** A connection to a list of items. */
export type RelDataObjectToInterfaceConnection = {
  __typename?: 'RelDataObjectToInterfaceConnection';
  edges?: Maybe<Array<Maybe<RelDataObjectToInterfaceEdge>>>;
  pageInfo: PageInfo;
  /** A count of the total number of objects in this collection, ignoring pagination. */
  totalCount: Scalars['Int']['output'];
  /** Contains a list of the permissions for this type of relation and a list of fields for each CRUD operation. */
  permissions: Permissions;
  /**
   * A count of the skipped number of objects in this collection and for this
   * pagignation. The reason to skip/ hide an object can be a missing READ
   * permission of the relation of the target Fact Sheet for the requesting user.
   */
  skippedCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type RelDataObjectToInterfaceEdge = {
  __typename?: 'RelDataObjectToInterfaceEdge';
  /** The item at the end of the edge */
  node?: Maybe<RelDataObjectToInterface>;
  cursor: Scalars['String']['output'];
};

/**
 * A Fact Sheet with a type that is not defined in the data model. Possibly the
 * deletion of fact sheets of a deleted type is not yet finished.
 */
export type FactSheetOfUnknownType = Node &
  BaseFactSheet & {
    __typename?: 'FactSheetOfUnknownType';
    /** The graphQL id used to retrieve each node (object of arbitrary type) */
    id: Scalars['ID']['output'];
    /** The revision of the Fact Sheet, incremented automatically on each Fact Sheet update. */
    rev?: Maybe<Scalars['Long']['output']>;
    /** The name of the Fact Sheet. */
    name: Scalars['String']['output'];
    /** The calculated display name of the Fact Sheet. */
    displayName: Scalars['String']['output'];
    /** The calculated full name of the Fact Sheet. */
    fullName: Scalars['String']['output'];
    /** The type of the Fact Sheet. (Same as parameter __typeName) */
    type: FactSheetType;
    /** This can be used to categorize the Fact Sheet type into subtypes. */
    category?: Maybe<Scalars['String']['output']>;
    /** An optional description of the Fact Sheet. */
    description?: Maybe<Scalars['String']['output']>;
    /** Indicates how much data is already provided in the Fact Sheet, its sections and subsections. */
    completion?: Maybe<FactSheetCompletion>;
    /**
     * An array of attribute names that indicates which fields or relations of this
     * Fact Sheet have intentionally not been set. These attributes are thus not used
     * for completeness calculation.
     */
    naFields?: Maybe<Array<Scalars['String']['output']>>;
    /** The tags that are associated with the Fact Sheet. */
    tags?: Maybe<Array<Tag>>;
    /** All documents that are assigned to the Fact Sheet. */
    documents?: Maybe<DocumentConnection>;
    /** Comments on a Fact Sheet */
    comments?: Maybe<CommentConnection>;
    /** All subscriptions that are assigned to the Fact Sheet. */
    subscriptions?: Maybe<SubscriptionConnection>;
    /** A list of Access Control Entities defining which user in the workspace has read access to this Fact Sheet. */
    permittedReadACL?: Maybe<Array<AccessControlEntityReference>>;
    /** A list of Access Control Entities defining which user in the workspace has write access to this Fact Sheet. */
    permittedWriteACL?: Maybe<Array<AccessControlEntityReference>>;
    /** Status of the Fact Sheet. */
    status?: Maybe<FactSheetStatus>;
    /** The hierarchy level of the Fact Sheet.  (0 means level 1, 1 means level 2, etc.) */
    level?: Maybe<Scalars['Int']['output']>;
    /**
     * The timestamp in ISO 8601 format when the Fact Sheet was created. This field
     * is read-only and will never change during the lifecycle of the FactSheet.
     */
    createdAt: Scalars['String']['output'];
    /** The timestamp in ISO 8601 format when the Fact Sheet was last updated. */
    updatedAt: Scalars['String']['output'];
    /** Contains a list for the permissions for this instance and a list of fields for each CRUD operation. */
    permissions: Permissions;
    /** The status of the quality seal for this Fact Sheet */
    qualitySeal?: Maybe<QualitySealStatus>;
    /** State of the Fact Sheet. */
    lxState?: Maybe<FactSheetState>;
    /** Milestones on the Fact Sheet. */
    lxMilestones?: Maybe<Array<Milestone>>;
    /** Whether the fact sheet should be excluded from the quota check or not. */
    lxExcludeFromQuota: Scalars['Boolean']['output'];
  };

/**
 * A Fact Sheet with a type that is not defined in the data model. Possibly the
 * deletion of fact sheets of a deleted type is not yet finished.
 */
export type FactSheetOfUnknownTypeDocumentsArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  filter?: InputMaybe<DocumentFilterInput>;
};

/**
 * A Fact Sheet with a type that is not defined in the data model. Possibly the
 * deletion of fact sheets of a deleted type is not yet finished.
 */
export type FactSheetOfUnknownTypeCommentsArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  filter?: InputMaybe<CommentFilterInput>;
};

/**
 * A Fact Sheet with a type that is not defined in the data model. Possibly the
 * deletion of fact sheets of a deleted type is not yet finished.
 */
export type FactSheetOfUnknownTypeSubscriptionsArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  filter?: InputMaybe<SubscriptionFilterInput>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: ITComponent */
export type ItComponent = Node &
  BaseFactSheet & {
    __typename?: 'ITComponent';
    /** The graphQL id used to retrieve each node (object of arbitrary type) */
    id: Scalars['ID']['output'];
    /** The revision of the Fact Sheet, incremented automatically on each Fact Sheet update. */
    rev?: Maybe<Scalars['Long']['output']>;
    /** The name of the ITComponent. */
    name: Scalars['String']['output'];
    /** The calculated display name of the Fact Sheet. */
    displayName: Scalars['String']['output'];
    /** The calculated full name of the Fact Sheet. */
    fullName: Scalars['String']['output'];
    /** The type of the Fact Sheet. (Same as parameter __typeName) */
    type: FactSheetType;
    /** This can be used to categorize the Fact Sheet type into subtypes. */
    category?: Maybe<Scalars['String']['output']>;
    /** An optional description of the ITComponent. */
    description?: Maybe<Scalars['String']['output']>;
    /** Indicates how much data is already provided in the Fact Sheet, its sections and subsections. */
    completion?: Maybe<FactSheetCompletion>;
    /**
     * An array of attribute names that indicates which fields or relations of this
     * Fact Sheet have intentionally not been set. These attributes are thus not used
     * for completeness calculation.
     */
    naFields?: Maybe<Array<Scalars['String']['output']>>;
    /** The tags that are associated with the Fact Sheet. */
    tags?: Maybe<Array<Tag>>;
    /** All documents that are assigned to the Fact Sheet. */
    documents?: Maybe<DocumentConnection>;
    /** Comments on a Fact Sheet */
    comments?: Maybe<CommentConnection>;
    /** All subscriptions that are assigned to the Fact Sheet. */
    subscriptions?: Maybe<SubscriptionConnection>;
    /** A list of Access Control Entities defining which user in the workspace has read access to this Fact Sheet. */
    permittedReadACL?: Maybe<Array<AccessControlEntityReference>>;
    /** A list of Access Control Entities defining which user in the workspace has write access to this Fact Sheet. */
    permittedWriteACL?: Maybe<Array<AccessControlEntityReference>>;
    /** Status of the Fact Sheet. */
    status?: Maybe<FactSheetStatus>;
    /** The hierarchy level of the Fact Sheet. (0 means level 1, 1 means level 2, etc.) */
    level?: Maybe<Scalars['Int']['output']>;
    /**
     * The timestamp in ISO 8601 format when the Fact Sheet was created. This field
     * is read-only and will never change during the lifecycle of the FactSheet.
     */
    createdAt: Scalars['String']['output'];
    /** The timestamp in ISO 8601 format when the Fact Sheet was last updated. */
    updatedAt: Scalars['String']['output'];
    /** Contains a list for the permissions for this instance and a list of fields for each CRUD operation. */
    permissions: Permissions;
    /** The status of the quality seal for this Fact Sheet */
    qualitySeal?: Maybe<QualitySealStatus>;
    /** State of the Fact Sheet. */
    lxState?: Maybe<FactSheetState>;
    /** Milestones on the Fact Sheet. */
    lxMilestones?: Maybe<Array<Milestone>>;
    /** Whether the fact sheet should be excluded from the quota check or not. */
    lxExcludeFromQuota: Scalars['Boolean']['output'];
    /** lifecycle */
    lifecycle?: Maybe<ItComponentLifecycle>;
    technicalSuitabilityDescription?: Maybe<Scalars['String']['output']>;
    /** technicalSuitability */
    technicalSuitability?: Maybe<ItComponentTechnicalSuitability>;
    release?: Maybe<Scalars['String']['output']>;
    alias?: Maybe<Scalars['String']['output']>;
    /** location */
    location?: Maybe<Location>;
    /** ltlsStatus */
    ltlsStatus?: Maybe<ItComponentLtlsStatus>;
    DPMDId?: Maybe<Scalars['Int']['output']>;
    externalId?: Maybe<ExternalId>;
    technopediaId?: Maybe<ExternalId>;
    serviceNowExternalId?: Maybe<ExternalId>;
    relToParent?: Maybe<RelToParentConnection>;
    relToChild?: Maybe<RelToChildConnection>;
    relToRequires?: Maybe<RelToRequiresConnection>;
    relToRequiredBy?: Maybe<RelToRequiredByConnection>;
    relToSuccessor?: Maybe<RelToSuccessorConnection>;
    relToPredecessor?: Maybe<RelToPredecessorConnection>;
    relITComponentToApplication?: Maybe<RelItComponentToApplicationConnection>;
    relITComponentToTechnologyStack?: Maybe<RelItComponentToTechnologyStackConnection>;
    relITComponentToUserGroup?: Maybe<RelItComponentToUserGroupConnection>;
    relITComponentToProvider?: Maybe<RelItComponentToProviderConnection>;
    relITComponentToInterface?: Maybe<RelItComponentToInterfaceConnection>;
    relITComponentToProject?: Maybe<RelItComponentToProjectConnection>;
    relITComponentToProduct?: Maybe<RelItComponentToProductConnection>;
  };

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: ITComponent */
export type ItComponentDocumentsArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  filter?: InputMaybe<DocumentFilterInput>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: ITComponent */
export type ItComponentCommentsArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  filter?: InputMaybe<CommentFilterInput>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: ITComponent */
export type ItComponentSubscriptionsArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  filter?: InputMaybe<SubscriptionFilterInput>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: ITComponent */
export type ItComponentRelToParentArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: ITComponent */
export type ItComponentRelToChildArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: ITComponent */
export type ItComponentRelToRequiresArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: ITComponent */
export type ItComponentRelToRequiredByArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: ITComponent */
export type ItComponentRelToSuccessorArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: ITComponent */
export type ItComponentRelToPredecessorArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: ITComponent */
export type ItComponentRelItComponentToApplicationArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: ITComponent */
export type ItComponentRelItComponentToTechnologyStackArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: ITComponent */
export type ItComponentRelItComponentToUserGroupArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: ITComponent */
export type ItComponentRelItComponentToProviderArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: ITComponent */
export type ItComponentRelItComponentToInterfaceArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: ITComponent */
export type ItComponentRelItComponentToProjectArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: ITComponent */
export type ItComponentRelItComponentToProductArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

export enum ApplyPolicy {
  BeforeResolver = 'BEFORE_RESOLVER',
  AfterResolver = 'AFTER_RESOLVER',
  Validation = 'VALIDATION',
}

/** All enum value(s) which are available for this ITComponentTechnicalSuitability type */
export enum ItComponentTechnicalSuitability {
  Inappropriate = 'inappropriate',
  Unreasonable = 'unreasonable',
  Adequate = 'adequate',
  FullyAppropriate = 'fullyAppropriate',
}

/** All enum value(s) which are available for this ITComponentLtlsStatus type */
export enum ItComponentLtlsStatus {
  Linked = 'linked',
  Requested = 'requested',
  Ignored = 'ignored',
}

/** A connection to a list of items. */
export type RelItComponentToApplicationConnection = {
  __typename?: 'RelITComponentToApplicationConnection';
  edges?: Maybe<Array<Maybe<RelItComponentToApplicationEdge>>>;
  pageInfo: PageInfo;
  /** A count of the total number of objects in this collection, ignoring pagination. */
  totalCount: Scalars['Int']['output'];
  /** Contains a list of the permissions for this type of relation and a list of fields for each CRUD operation. */
  permissions: Permissions;
  /**
   * A count of the skipped number of objects in this collection and for this
   * pagignation. The reason to skip/ hide an object can be a missing READ
   * permission of the relation of the target Fact Sheet for the requesting user.
   */
  skippedCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type RelItComponentToApplicationEdge = {
  __typename?: 'RelITComponentToApplicationEdge';
  /** The item at the end of the edge */
  node?: Maybe<RelItComponentToApplication>;
  cursor: Scalars['String']['output'];
};

/** A connection to a list of items. */
export type RelItComponentToTechnologyStackConnection = {
  __typename?: 'RelITComponentToTechnologyStackConnection';
  edges?: Maybe<Array<Maybe<RelItComponentToTechnologyStackEdge>>>;
  pageInfo: PageInfo;
  /** A count of the total number of objects in this collection, ignoring pagination. */
  totalCount: Scalars['Int']['output'];
  /** Contains a list of the permissions for this type of relation and a list of fields for each CRUD operation. */
  permissions: Permissions;
  /**
   * A count of the skipped number of objects in this collection and for this
   * pagignation. The reason to skip/ hide an object can be a missing READ
   * permission of the relation of the target Fact Sheet for the requesting user.
   */
  skippedCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type RelItComponentToTechnologyStackEdge = {
  __typename?: 'RelITComponentToTechnologyStackEdge';
  /** The item at the end of the edge */
  node?: Maybe<RelItComponentToTechnologyStack>;
  cursor: Scalars['String']['output'];
};

/** A connection to a list of items. */
export type RelItComponentToUserGroupConnection = {
  __typename?: 'RelITComponentToUserGroupConnection';
  edges?: Maybe<Array<Maybe<RelItComponentToUserGroupEdge>>>;
  pageInfo: PageInfo;
  /** A count of the total number of objects in this collection, ignoring pagination. */
  totalCount: Scalars['Int']['output'];
  /** Contains a list of the permissions for this type of relation and a list of fields for each CRUD operation. */
  permissions: Permissions;
  /**
   * A count of the skipped number of objects in this collection and for this
   * pagignation. The reason to skip/ hide an object can be a missing READ
   * permission of the relation of the target Fact Sheet for the requesting user.
   */
  skippedCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type RelItComponentToUserGroupEdge = {
  __typename?: 'RelITComponentToUserGroupEdge';
  /** The item at the end of the edge */
  node?: Maybe<RelItComponentToUserGroup>;
  cursor: Scalars['String']['output'];
};

/** A connection to a list of items. */
export type RelItComponentToProviderConnection = {
  __typename?: 'RelITComponentToProviderConnection';
  edges?: Maybe<Array<Maybe<RelItComponentToProviderEdge>>>;
  pageInfo: PageInfo;
  /** A count of the total number of objects in this collection, ignoring pagination. */
  totalCount: Scalars['Int']['output'];
  /** Contains a list of the permissions for this type of relation and a list of fields for each CRUD operation. */
  permissions: Permissions;
  /**
   * A count of the skipped number of objects in this collection and for this
   * pagignation. The reason to skip/ hide an object can be a missing READ
   * permission of the relation of the target Fact Sheet for the requesting user.
   */
  skippedCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type RelItComponentToProviderEdge = {
  __typename?: 'RelITComponentToProviderEdge';
  /** The item at the end of the edge */
  node?: Maybe<RelItComponentToProvider>;
  cursor: Scalars['String']['output'];
};

/** A connection to a list of items. */
export type RelItComponentToInterfaceConnection = {
  __typename?: 'RelITComponentToInterfaceConnection';
  edges?: Maybe<Array<Maybe<RelItComponentToInterfaceEdge>>>;
  pageInfo: PageInfo;
  /** A count of the total number of objects in this collection, ignoring pagination. */
  totalCount: Scalars['Int']['output'];
  /** Contains a list of the permissions for this type of relation and a list of fields for each CRUD operation. */
  permissions: Permissions;
  /**
   * A count of the skipped number of objects in this collection and for this
   * pagignation. The reason to skip/ hide an object can be a missing READ
   * permission of the relation of the target Fact Sheet for the requesting user.
   */
  skippedCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type RelItComponentToInterfaceEdge = {
  __typename?: 'RelITComponentToInterfaceEdge';
  /** The item at the end of the edge */
  node?: Maybe<RelItComponentToInterface>;
  cursor: Scalars['String']['output'];
};

/** A connection to a list of items. */
export type RelItComponentToProjectConnection = {
  __typename?: 'RelITComponentToProjectConnection';
  edges?: Maybe<Array<Maybe<RelItComponentToProjectEdge>>>;
  pageInfo: PageInfo;
  /** A count of the total number of objects in this collection, ignoring pagination. */
  totalCount: Scalars['Int']['output'];
  /** Contains a list of the permissions for this type of relation and a list of fields for each CRUD operation. */
  permissions: Permissions;
  /**
   * A count of the skipped number of objects in this collection and for this
   * pagignation. The reason to skip/ hide an object can be a missing READ
   * permission of the relation of the target Fact Sheet for the requesting user.
   */
  skippedCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type RelItComponentToProjectEdge = {
  __typename?: 'RelITComponentToProjectEdge';
  /** The item at the end of the edge */
  node?: Maybe<RelItComponentToProject>;
  cursor: Scalars['String']['output'];
};

/** A connection to a list of items. */
export type RelItComponentToProductConnection = {
  __typename?: 'RelITComponentToProductConnection';
  edges?: Maybe<Array<Maybe<RelItComponentToProductEdge>>>;
  pageInfo: PageInfo;
  /** A count of the total number of objects in this collection, ignoring pagination. */
  totalCount: Scalars['Int']['output'];
  /** Contains a list of the permissions for this type of relation and a list of fields for each CRUD operation. */
  permissions: Permissions;
  /**
   * A count of the skipped number of objects in this collection and for this
   * pagignation. The reason to skip/ hide an object can be a missing READ
   * permission of the relation of the target Fact Sheet for the requesting user.
   */
  skippedCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type RelItComponentToProductEdge = {
  __typename?: 'RelITComponentToProductEdge';
  /** The item at the end of the edge */
  node?: Maybe<RelItComponentToProduct>;
  cursor: Scalars['String']['output'];
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: Interface */
export type Interface = Node &
  BaseFactSheet & {
    __typename?: 'Interface';
    /** The graphQL id used to retrieve each node (object of arbitrary type) */
    id: Scalars['ID']['output'];
    /** The revision of the Fact Sheet, incremented automatically on each Fact Sheet update. */
    rev?: Maybe<Scalars['Long']['output']>;
    /** The name of the Interface. */
    name: Scalars['String']['output'];
    /** The calculated display name of the Fact Sheet. */
    displayName: Scalars['String']['output'];
    /** The calculated full name of the Fact Sheet. */
    fullName: Scalars['String']['output'];
    /** The type of the Fact Sheet. (Same as parameter __typeName) */
    type: FactSheetType;
    /** This can be used to categorize the Fact Sheet type into subtypes. */
    category?: Maybe<Scalars['String']['output']>;
    /** An optional description of the Interface. */
    description?: Maybe<Scalars['String']['output']>;
    /** Indicates how much data is already provided in the Fact Sheet, its sections and subsections. */
    completion?: Maybe<FactSheetCompletion>;
    /**
     * An array of attribute names that indicates which fields or relations of this
     * Fact Sheet have intentionally not been set. These attributes are thus not used
     * for completeness calculation.
     */
    naFields?: Maybe<Array<Scalars['String']['output']>>;
    /** The tags that are associated with the Fact Sheet. */
    tags?: Maybe<Array<Tag>>;
    /** All documents that are assigned to the Fact Sheet. */
    documents?: Maybe<DocumentConnection>;
    /** Comments on a Fact Sheet */
    comments?: Maybe<CommentConnection>;
    /** All subscriptions that are assigned to the Fact Sheet. */
    subscriptions?: Maybe<SubscriptionConnection>;
    /** A list of Access Control Entities defining which user in the workspace has read access to this Fact Sheet. */
    permittedReadACL?: Maybe<Array<AccessControlEntityReference>>;
    /** A list of Access Control Entities defining which user in the workspace has write access to this Fact Sheet. */
    permittedWriteACL?: Maybe<Array<AccessControlEntityReference>>;
    /** Status of the Fact Sheet. */
    status?: Maybe<FactSheetStatus>;
    /** The hierarchy level of the Fact Sheet. (0 means level 1, 1 means level 2, etc.) */
    level?: Maybe<Scalars['Int']['output']>;
    /**
     * The timestamp in ISO 8601 format when the Fact Sheet was created. This field
     * is read-only and will never change during the lifecycle of the FactSheet.
     */
    createdAt: Scalars['String']['output'];
    /** The timestamp in ISO 8601 format when the Fact Sheet was last updated. */
    updatedAt: Scalars['String']['output'];
    /** Contains a list for the permissions for this instance and a list of fields for each CRUD operation. */
    permissions: Permissions;
    /** The status of the quality seal for this Fact Sheet */
    qualitySeal?: Maybe<QualitySealStatus>;
    /** State of the Fact Sheet. */
    lxState?: Maybe<FactSheetState>;
    /** Milestones on the Fact Sheet. */
    lxMilestones?: Maybe<Array<Milestone>>;
    /** Whether the fact sheet should be excluded from the quota check or not. */
    lxExcludeFromQuota: Scalars['Boolean']['output'];
    /** lifecycle */
    lifecycle?: Maybe<InterfaceLifecycle>;
    /** interfaceType */
    interfaceType?: Maybe<InterfaceInterfaceType>;
    release?: Maybe<Scalars['String']['output']>;
    alias?: Maybe<Scalars['String']['output']>;
    /** dataFlowDirection */
    dataFlowDirection?: Maybe<InterfaceDataFlowDirection>;
    /** frequency */
    frequency?: Maybe<InterfaceFrequency>;
    externalId?: Maybe<ExternalId>;
    serviceNowExternalId?: Maybe<ExternalId>;
    interfaceIdSequence?: Maybe<ExternalId>;
    relToParent?: Maybe<RelToParentConnection>;
    relToChild?: Maybe<RelToChildConnection>;
    relToRequires?: Maybe<RelToRequiresConnection>;
    relToRequiredBy?: Maybe<RelToRequiredByConnection>;
    relToSuccessor?: Maybe<RelToSuccessorConnection>;
    relToPredecessor?: Maybe<RelToPredecessorConnection>;
    relInterfaceToProviderApplication?: Maybe<RelInterfaceToProviderApplicationConnection>;
    relInterfaceToConsumerApplication?: Maybe<RelInterfaceToConsumerApplicationConnection>;
    relInterfaceToDataObject?: Maybe<RelInterfaceToDataObjectConnection>;
    relInterfaceToITComponent?: Maybe<RelInterfaceToItComponentConnection>;
  };

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: Interface */
export type InterfaceDocumentsArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  filter?: InputMaybe<DocumentFilterInput>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: Interface */
export type InterfaceCommentsArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  filter?: InputMaybe<CommentFilterInput>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: Interface */
export type InterfaceSubscriptionsArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  filter?: InputMaybe<SubscriptionFilterInput>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: Interface */
export type InterfaceRelToParentArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: Interface */
export type InterfaceRelToChildArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: Interface */
export type InterfaceRelToRequiresArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: Interface */
export type InterfaceRelToRequiredByArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: Interface */
export type InterfaceRelToSuccessorArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: Interface */
export type InterfaceRelToPredecessorArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: Interface */
export type InterfaceRelInterfaceToProviderApplicationArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: Interface */
export type InterfaceRelInterfaceToConsumerApplicationArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: Interface */
export type InterfaceRelInterfaceToDataObjectArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: Interface */
export type InterfaceRelInterfaceToItComponentArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** All enum value(s) which are available for this InterfaceInterfaceType type */
export enum InterfaceInterfaceType {
  Synchronous = 'synchronous',
  Asynchronous = 'asynchronous',
  Batch = 'batch',
}

/** All enum value(s) which are available for this InterfaceDataFlowDirection type */
export enum InterfaceDataFlowDirection {
  Incoming = 'incoming',
  Outgoing = 'outgoing',
  BiDirectional = 'biDirectional',
}

/** All enum value(s) which are available for this InterfaceFrequency type */
export enum InterfaceFrequency {
  Hourly = 'hourly',
  Daily = 'daily',
  Weekly = 'weekly',
  Monthly = 'monthly',
  Yearly = 'yearly',
  Realtime = 'realtime',
  OnDemand = 'onDemand',
}

/** A connection to a list of items. */
export type RelInterfaceToProviderApplicationConnection = {
  __typename?: 'RelInterfaceToProviderApplicationConnection';
  edges?: Maybe<Array<Maybe<RelInterfaceToProviderApplicationEdge>>>;
  pageInfo: PageInfo;
  /** A count of the total number of objects in this collection, ignoring pagination. */
  totalCount: Scalars['Int']['output'];
  /** Contains a list of the permissions for this type of relation and a list of fields for each CRUD operation. */
  permissions: Permissions;
  /**
   * A count of the skipped number of objects in this collection and for this
   * pagignation. The reason to skip/ hide an object can be a missing READ
   * permission of the relation of the target Fact Sheet for the requesting user.
   */
  skippedCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type RelInterfaceToProviderApplicationEdge = {
  __typename?: 'RelInterfaceToProviderApplicationEdge';
  /** The item at the end of the edge */
  node?: Maybe<RelInterfaceToProviderApplication>;
  cursor: Scalars['String']['output'];
};

/** A connection to a list of items. */
export type RelInterfaceToConsumerApplicationConnection = {
  __typename?: 'RelInterfaceToConsumerApplicationConnection';
  edges?: Maybe<Array<Maybe<RelInterfaceToConsumerApplicationEdge>>>;
  pageInfo: PageInfo;
  /** A count of the total number of objects in this collection, ignoring pagination. */
  totalCount: Scalars['Int']['output'];
  /** Contains a list of the permissions for this type of relation and a list of fields for each CRUD operation. */
  permissions: Permissions;
  /**
   * A count of the skipped number of objects in this collection and for this
   * pagignation. The reason to skip/ hide an object can be a missing READ
   * permission of the relation of the target Fact Sheet for the requesting user.
   */
  skippedCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type RelInterfaceToConsumerApplicationEdge = {
  __typename?: 'RelInterfaceToConsumerApplicationEdge';
  /** The item at the end of the edge */
  node?: Maybe<RelInterfaceToConsumerApplication>;
  cursor: Scalars['String']['output'];
};

/** A connection to a list of items. */
export type RelInterfaceToDataObjectConnection = {
  __typename?: 'RelInterfaceToDataObjectConnection';
  edges?: Maybe<Array<Maybe<RelInterfaceToDataObjectEdge>>>;
  pageInfo: PageInfo;
  /** A count of the total number of objects in this collection, ignoring pagination. */
  totalCount: Scalars['Int']['output'];
  /** Contains a list of the permissions for this type of relation and a list of fields for each CRUD operation. */
  permissions: Permissions;
  /**
   * A count of the skipped number of objects in this collection and for this
   * pagignation. The reason to skip/ hide an object can be a missing READ
   * permission of the relation of the target Fact Sheet for the requesting user.
   */
  skippedCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type RelInterfaceToDataObjectEdge = {
  __typename?: 'RelInterfaceToDataObjectEdge';
  /** The item at the end of the edge */
  node?: Maybe<RelInterfaceToDataObject>;
  cursor: Scalars['String']['output'];
};

/** A connection to a list of items. */
export type RelInterfaceToItComponentConnection = {
  __typename?: 'RelInterfaceToITComponentConnection';
  edges?: Maybe<Array<Maybe<RelInterfaceToItComponentEdge>>>;
  pageInfo: PageInfo;
  /** A count of the total number of objects in this collection, ignoring pagination. */
  totalCount: Scalars['Int']['output'];
  /** Contains a list of the permissions for this type of relation and a list of fields for each CRUD operation. */
  permissions: Permissions;
  /**
   * A count of the skipped number of objects in this collection and for this
   * pagignation. The reason to skip/ hide an object can be a missing READ
   * permission of the relation of the target Fact Sheet for the requesting user.
   */
  skippedCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type RelInterfaceToItComponentEdge = {
  __typename?: 'RelInterfaceToITComponentEdge';
  /** The item at the end of the edge */
  node?: Maybe<RelInterfaceToItComponent>;
  cursor: Scalars['String']['output'];
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: Product */
export type Product = Node &
  BaseFactSheet & {
    __typename?: 'Product';
    /** The graphQL id used to retrieve each node (object of arbitrary type) */
    id: Scalars['ID']['output'];
    /** The revision of the Fact Sheet, incremented automatically on each Fact Sheet update. */
    rev?: Maybe<Scalars['Long']['output']>;
    /** The name of the Product. */
    name: Scalars['String']['output'];
    /** The calculated display name of the Fact Sheet. */
    displayName: Scalars['String']['output'];
    /** The calculated full name of the Fact Sheet. */
    fullName: Scalars['String']['output'];
    /** The type of the Fact Sheet. (Same as parameter __typeName) */
    type: FactSheetType;
    /** This can be used to categorize the Fact Sheet type into subtypes. */
    category?: Maybe<Scalars['String']['output']>;
    /** An optional description of the Product. */
    description?: Maybe<Scalars['String']['output']>;
    /** Indicates how much data is already provided in the Fact Sheet, its sections and subsections. */
    completion?: Maybe<FactSheetCompletion>;
    /**
     * An array of attribute names that indicates which fields or relations of this
     * Fact Sheet have intentionally not been set. These attributes are thus not used
     * for completeness calculation.
     */
    naFields?: Maybe<Array<Scalars['String']['output']>>;
    /** The tags that are associated with the Fact Sheet. */
    tags?: Maybe<Array<Tag>>;
    /** All documents that are assigned to the Fact Sheet. */
    documents?: Maybe<DocumentConnection>;
    /** Comments on a Fact Sheet */
    comments?: Maybe<CommentConnection>;
    /** All subscriptions that are assigned to the Fact Sheet. */
    subscriptions?: Maybe<SubscriptionConnection>;
    /** A list of Access Control Entities defining which user in the workspace has read access to this Fact Sheet. */
    permittedReadACL?: Maybe<Array<AccessControlEntityReference>>;
    /** A list of Access Control Entities defining which user in the workspace has write access to this Fact Sheet. */
    permittedWriteACL?: Maybe<Array<AccessControlEntityReference>>;
    /** Status of the Fact Sheet. */
    status?: Maybe<FactSheetStatus>;
    /** The hierarchy level of the Fact Sheet. (0 means level 1, 1 means level 2, etc.) */
    level?: Maybe<Scalars['Int']['output']>;
    /**
     * The timestamp in ISO 8601 format when the Fact Sheet was created. This field
     * is read-only and will never change during the lifecycle of the FactSheet.
     */
    createdAt: Scalars['String']['output'];
    /** The timestamp in ISO 8601 format when the Fact Sheet was last updated. */
    updatedAt: Scalars['String']['output'];
    /** Contains a list for the permissions for this instance and a list of fields for each CRUD operation. */
    permissions: Permissions;
    /** The status of the quality seal for this Fact Sheet */
    qualitySeal?: Maybe<QualitySealStatus>;
    /** State of the Fact Sheet. */
    lxState?: Maybe<FactSheetState>;
    /** Milestones on the Fact Sheet. */
    lxMilestones?: Maybe<Array<Milestone>>;
    /** Whether the fact sheet should be excluded from the quota check or not. */
    lxExcludeFromQuota: Scalars['Boolean']['output'];
    /** lifecycle */
    lifecycle?: Maybe<ProductLifecycle>;
    alias?: Maybe<Scalars['String']['output']>;
    WBSno?: Maybe<Scalars['String']['output']>;
    externalId?: Maybe<ExternalId>;
    externalIdProduct?: Maybe<ExternalId>;
    serviceNowExternalId?: Maybe<ExternalId>;
    relToParent?: Maybe<RelToParentConnection>;
    relToChild?: Maybe<RelToChildConnection>;
    relToRequires?: Maybe<RelToRequiresConnection>;
    relToRequiredBy?: Maybe<RelToRequiredByConnection>;
    relToSuccessor?: Maybe<RelToSuccessorConnection>;
    relToPredecessor?: Maybe<RelToPredecessorConnection>;
    relProductToDataObject?: Maybe<RelProductToDataObjectConnection>;
    relProductToApplication?: Maybe<RelProductToApplicationConnection>;
    relProductToITComponent?: Maybe<RelProductToItComponentConnection>;
    relProductToProject?: Maybe<RelProductToProjectConnection>;
    relProductToBusinessCapability?: Maybe<RelProductToBusinessCapabilityConnection>;
    relProductToUserGroup?: Maybe<RelProductToUserGroupConnection>;
    relProductToProcess?: Maybe<RelProductToProcessConnection>;
  };

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: Product */
export type ProductDocumentsArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  filter?: InputMaybe<DocumentFilterInput>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: Product */
export type ProductCommentsArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  filter?: InputMaybe<CommentFilterInput>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: Product */
export type ProductSubscriptionsArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  filter?: InputMaybe<SubscriptionFilterInput>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: Product */
export type ProductRelToParentArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: Product */
export type ProductRelToChildArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: Product */
export type ProductRelToRequiresArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: Product */
export type ProductRelToRequiredByArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: Product */
export type ProductRelToSuccessorArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: Product */
export type ProductRelToPredecessorArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: Product */
export type ProductRelProductToDataObjectArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: Product */
export type ProductRelProductToApplicationArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: Product */
export type ProductRelProductToItComponentArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: Product */
export type ProductRelProductToProjectArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: Product */
export type ProductRelProductToBusinessCapabilityArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: Product */
export type ProductRelProductToUserGroupArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: Product */
export type ProductRelProductToProcessArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A connection to a list of items. */
export type RelProductToDataObjectConnection = {
  __typename?: 'RelProductToDataObjectConnection';
  edges?: Maybe<Array<Maybe<RelProductToDataObjectEdge>>>;
  pageInfo: PageInfo;
  /** A count of the total number of objects in this collection, ignoring pagination. */
  totalCount: Scalars['Int']['output'];
  /** Contains a list of the permissions for this type of relation and a list of fields for each CRUD operation. */
  permissions: Permissions;
  /**
   * A count of the skipped number of objects in this collection and for this
   * pagignation. The reason to skip/ hide an object can be a missing READ
   * permission of the relation of the target Fact Sheet for the requesting user.
   */
  skippedCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type RelProductToDataObjectEdge = {
  __typename?: 'RelProductToDataObjectEdge';
  /** The item at the end of the edge */
  node?: Maybe<RelProductToDataObject>;
  cursor: Scalars['String']['output'];
};

/** A connection to a list of items. */
export type RelProductToApplicationConnection = {
  __typename?: 'RelProductToApplicationConnection';
  edges?: Maybe<Array<Maybe<RelProductToApplicationEdge>>>;
  pageInfo: PageInfo;
  /** A count of the total number of objects in this collection, ignoring pagination. */
  totalCount: Scalars['Int']['output'];
  /** Contains a list of the permissions for this type of relation and a list of fields for each CRUD operation. */
  permissions: Permissions;
  /**
   * A count of the skipped number of objects in this collection and for this
   * pagignation. The reason to skip/ hide an object can be a missing READ
   * permission of the relation of the target Fact Sheet for the requesting user.
   */
  skippedCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type RelProductToApplicationEdge = {
  __typename?: 'RelProductToApplicationEdge';
  /** The item at the end of the edge */
  node?: Maybe<RelProductToApplication>;
  cursor: Scalars['String']['output'];
};

/** A connection to a list of items. */
export type RelProductToItComponentConnection = {
  __typename?: 'RelProductToITComponentConnection';
  edges?: Maybe<Array<Maybe<RelProductToItComponentEdge>>>;
  pageInfo: PageInfo;
  /** A count of the total number of objects in this collection, ignoring pagination. */
  totalCount: Scalars['Int']['output'];
  /** Contains a list of the permissions for this type of relation and a list of fields for each CRUD operation. */
  permissions: Permissions;
  /**
   * A count of the skipped number of objects in this collection and for this
   * pagignation. The reason to skip/ hide an object can be a missing READ
   * permission of the relation of the target Fact Sheet for the requesting user.
   */
  skippedCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type RelProductToItComponentEdge = {
  __typename?: 'RelProductToITComponentEdge';
  /** The item at the end of the edge */
  node?: Maybe<RelProductToItComponent>;
  cursor: Scalars['String']['output'];
};

/** A connection to a list of items. */
export type RelProductToProjectConnection = {
  __typename?: 'RelProductToProjectConnection';
  edges?: Maybe<Array<Maybe<RelProductToProjectEdge>>>;
  pageInfo: PageInfo;
  /** A count of the total number of objects in this collection, ignoring pagination. */
  totalCount: Scalars['Int']['output'];
  /** Contains a list of the permissions for this type of relation and a list of fields for each CRUD operation. */
  permissions: Permissions;
  /**
   * A count of the skipped number of objects in this collection and for this
   * pagignation. The reason to skip/ hide an object can be a missing READ
   * permission of the relation of the target Fact Sheet for the requesting user.
   */
  skippedCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type RelProductToProjectEdge = {
  __typename?: 'RelProductToProjectEdge';
  /** The item at the end of the edge */
  node?: Maybe<RelProductToProject>;
  cursor: Scalars['String']['output'];
};

/** A connection to a list of items. */
export type RelProductToBusinessCapabilityConnection = {
  __typename?: 'RelProductToBusinessCapabilityConnection';
  edges?: Maybe<Array<Maybe<RelProductToBusinessCapabilityEdge>>>;
  pageInfo: PageInfo;
  /** A count of the total number of objects in this collection, ignoring pagination. */
  totalCount: Scalars['Int']['output'];
  /** Contains a list of the permissions for this type of relation and a list of fields for each CRUD operation. */
  permissions: Permissions;
  /**
   * A count of the skipped number of objects in this collection and for this
   * pagignation. The reason to skip/ hide an object can be a missing READ
   * permission of the relation of the target Fact Sheet for the requesting user.
   */
  skippedCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type RelProductToBusinessCapabilityEdge = {
  __typename?: 'RelProductToBusinessCapabilityEdge';
  /** The item at the end of the edge */
  node?: Maybe<RelProductToBusinessCapability>;
  cursor: Scalars['String']['output'];
};

/** A connection to a list of items. */
export type RelProductToUserGroupConnection = {
  __typename?: 'RelProductToUserGroupConnection';
  edges?: Maybe<Array<Maybe<RelProductToUserGroupEdge>>>;
  pageInfo: PageInfo;
  /** A count of the total number of objects in this collection, ignoring pagination. */
  totalCount: Scalars['Int']['output'];
  /** Contains a list of the permissions for this type of relation and a list of fields for each CRUD operation. */
  permissions: Permissions;
  /**
   * A count of the skipped number of objects in this collection and for this
   * pagignation. The reason to skip/ hide an object can be a missing READ
   * permission of the relation of the target Fact Sheet for the requesting user.
   */
  skippedCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type RelProductToUserGroupEdge = {
  __typename?: 'RelProductToUserGroupEdge';
  /** The item at the end of the edge */
  node?: Maybe<RelProductToUserGroup>;
  cursor: Scalars['String']['output'];
};

/** A connection to a list of items. */
export type RelProductToProcessConnection = {
  __typename?: 'RelProductToProcessConnection';
  edges?: Maybe<Array<Maybe<RelProductToProcessEdge>>>;
  pageInfo: PageInfo;
  /** A count of the total number of objects in this collection, ignoring pagination. */
  totalCount: Scalars['Int']['output'];
  /** Contains a list of the permissions for this type of relation and a list of fields for each CRUD operation. */
  permissions: Permissions;
  /**
   * A count of the skipped number of objects in this collection and for this
   * pagignation. The reason to skip/ hide an object can be a missing READ
   * permission of the relation of the target Fact Sheet for the requesting user.
   */
  skippedCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type RelProductToProcessEdge = {
  __typename?: 'RelProductToProcessEdge';
  /** The item at the end of the edge */
  node?: Maybe<RelProductToProcess>;
  cursor: Scalars['String']['output'];
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: Provider */
export type Provider = Node &
  BaseFactSheet & {
    __typename?: 'Provider';
    /** The graphQL id used to retrieve each node (object of arbitrary type) */
    id: Scalars['ID']['output'];
    /** The revision of the Fact Sheet, incremented automatically on each Fact Sheet update. */
    rev?: Maybe<Scalars['Long']['output']>;
    /** The name of the Provider. */
    name: Scalars['String']['output'];
    /** The calculated display name of the Fact Sheet. */
    displayName: Scalars['String']['output'];
    /** The calculated full name of the Fact Sheet. */
    fullName: Scalars['String']['output'];
    /** The type of the Fact Sheet. (Same as parameter __typeName) */
    type: FactSheetType;
    /** This can be used to categorize the Fact Sheet type into subtypes. */
    category?: Maybe<Scalars['String']['output']>;
    /** An optional description of the Provider. */
    description?: Maybe<Scalars['String']['output']>;
    /** Indicates how much data is already provided in the Fact Sheet, its sections and subsections. */
    completion?: Maybe<FactSheetCompletion>;
    /**
     * An array of attribute names that indicates which fields or relations of this
     * Fact Sheet have intentionally not been set. These attributes are thus not used
     * for completeness calculation.
     */
    naFields?: Maybe<Array<Scalars['String']['output']>>;
    /** The tags that are associated with the Fact Sheet. */
    tags?: Maybe<Array<Tag>>;
    /** All documents that are assigned to the Fact Sheet. */
    documents?: Maybe<DocumentConnection>;
    /** Comments on a Fact Sheet */
    comments?: Maybe<CommentConnection>;
    /** All subscriptions that are assigned to the Fact Sheet. */
    subscriptions?: Maybe<SubscriptionConnection>;
    /** A list of Access Control Entities defining which user in the workspace has read access to this Fact Sheet. */
    permittedReadACL?: Maybe<Array<AccessControlEntityReference>>;
    /** A list of Access Control Entities defining which user in the workspace has write access to this Fact Sheet. */
    permittedWriteACL?: Maybe<Array<AccessControlEntityReference>>;
    /** Status of the Fact Sheet. */
    status?: Maybe<FactSheetStatus>;
    /** The hierarchy level of the Fact Sheet. (0 means level 1, 1 means level 2, etc.) */
    level?: Maybe<Scalars['Int']['output']>;
    /**
     * The timestamp in ISO 8601 format when the Fact Sheet was created. This field
     * is read-only and will never change during the lifecycle of the FactSheet.
     */
    createdAt: Scalars['String']['output'];
    /** The timestamp in ISO 8601 format when the Fact Sheet was last updated. */
    updatedAt: Scalars['String']['output'];
    /** Contains a list for the permissions for this instance and a list of fields for each CRUD operation. */
    permissions: Permissions;
    /** The status of the quality seal for this Fact Sheet */
    qualitySeal?: Maybe<QualitySealStatus>;
    /** State of the Fact Sheet. */
    lxState?: Maybe<FactSheetState>;
    /** Milestones on the Fact Sheet. */
    lxMilestones?: Maybe<Array<Milestone>>;
    /** Whether the fact sheet should be excluded from the quota check or not. */
    lxExcludeFromQuota: Scalars['Boolean']['output'];
    /** lifecycle */
    lifecycle?: Maybe<ProviderLifecycle>;
    providerCriticalityDescription?: Maybe<Scalars['String']['output']>;
    alias?: Maybe<Scalars['String']['output']>;
    /** providerCriticality */
    providerCriticality?: Maybe<ProviderProviderCriticality>;
    /** providerQuality */
    providerQuality?: Maybe<ProviderProviderQuality>;
    providerQualityDescription?: Maybe<Scalars['String']['output']>;
    externalId?: Maybe<ExternalId>;
    technopediaId?: Maybe<ExternalId>;
    serviceNowExternalId?: Maybe<ExternalId>;
    relToParent?: Maybe<RelToParentConnection>;
    relToChild?: Maybe<RelToChildConnection>;
    relToRequires?: Maybe<RelToRequiresConnection>;
    relToRequiredBy?: Maybe<RelToRequiredByConnection>;
    relToSuccessor?: Maybe<RelToSuccessorConnection>;
    relToPredecessor?: Maybe<RelToPredecessorConnection>;
    relProviderToITComponent?: Maybe<RelProviderToItComponentConnection>;
    relProviderToProject?: Maybe<RelProviderToProjectConnection>;
  };

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: Provider */
export type ProviderDocumentsArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  filter?: InputMaybe<DocumentFilterInput>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: Provider */
export type ProviderCommentsArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  filter?: InputMaybe<CommentFilterInput>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: Provider */
export type ProviderSubscriptionsArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  filter?: InputMaybe<SubscriptionFilterInput>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: Provider */
export type ProviderRelToParentArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: Provider */
export type ProviderRelToChildArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: Provider */
export type ProviderRelToRequiresArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: Provider */
export type ProviderRelToRequiredByArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: Provider */
export type ProviderRelToSuccessorArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: Provider */
export type ProviderRelToPredecessorArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: Provider */
export type ProviderRelProviderToItComponentArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: Provider */
export type ProviderRelProviderToProjectArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** All enum value(s) which are available for this ProviderProviderCriticality type */
export enum ProviderProviderCriticality {
  Commodity = 'commodity',
  Operational = 'operational',
  Tactical = 'tactical',
  Strategic = 'strategic',
}

/** All enum value(s) which are available for this ProviderProviderQuality type */
export enum ProviderProviderQuality {
  Unreasonable = 'unreasonable',
  Insufficient = 'insufficient',
  Appropriate = 'appropriate',
  Perfect = 'perfect',
}

/** A connection to a list of items. */
export type RelProviderToItComponentConnection = {
  __typename?: 'RelProviderToITComponentConnection';
  edges?: Maybe<Array<Maybe<RelProviderToItComponentEdge>>>;
  pageInfo: PageInfo;
  /** A count of the total number of objects in this collection, ignoring pagination. */
  totalCount: Scalars['Int']['output'];
  /** Contains a list of the permissions for this type of relation and a list of fields for each CRUD operation. */
  permissions: Permissions;
  /**
   * A count of the skipped number of objects in this collection and for this
   * pagignation. The reason to skip/ hide an object can be a missing READ
   * permission of the relation of the target Fact Sheet for the requesting user.
   */
  skippedCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type RelProviderToItComponentEdge = {
  __typename?: 'RelProviderToITComponentEdge';
  /** The item at the end of the edge */
  node?: Maybe<RelProviderToItComponent>;
  cursor: Scalars['String']['output'];
};

/** A connection to a list of items. */
export type RelProviderToProjectConnection = {
  __typename?: 'RelProviderToProjectConnection';
  edges?: Maybe<Array<Maybe<RelProviderToProjectEdge>>>;
  pageInfo: PageInfo;
  /** A count of the total number of objects in this collection, ignoring pagination. */
  totalCount: Scalars['Int']['output'];
  /** Contains a list of the permissions for this type of relation and a list of fields for each CRUD operation. */
  permissions: Permissions;
  /**
   * A count of the skipped number of objects in this collection and for this
   * pagignation. The reason to skip/ hide an object can be a missing READ
   * permission of the relation of the target Fact Sheet for the requesting user.
   */
  skippedCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type RelProviderToProjectEdge = {
  __typename?: 'RelProviderToProjectEdge';
  /** The item at the end of the edge */
  node?: Maybe<RelProviderToProject>;
  cursor: Scalars['String']['output'];
};

/** A reply to a comment */
export type Reply = Node & {
  __typename?: 'Reply';
  /** The ID of the reply. */
  id: Scalars['ID']['output'];
  /** The user who submitted the reply. */
  user: User;
  /** The reply itself. */
  message: Scalars['String']['output'];
  /** The timestamp of the creation of the reply. */
  createdAt: Scalars['String']['output'];
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: TechnicalStack */
export type TechnicalStack = Node &
  BaseFactSheet & {
    __typename?: 'TechnicalStack';
    /** The graphQL id used to retrieve each node (object of arbitrary type) */
    id: Scalars['ID']['output'];
    /** The revision of the Fact Sheet, incremented automatically on each Fact Sheet update. */
    rev?: Maybe<Scalars['Long']['output']>;
    /** The name of the TechnicalStack. */
    name: Scalars['String']['output'];
    /** The calculated display name of the Fact Sheet. */
    displayName: Scalars['String']['output'];
    /** The calculated full name of the Fact Sheet. */
    fullName: Scalars['String']['output'];
    /** The type of the Fact Sheet. (Same as parameter __typeName) */
    type: FactSheetType;
    /** This can be used to categorize the Fact Sheet type into subtypes. */
    category?: Maybe<Scalars['String']['output']>;
    /** An optional description of the TechnicalStack. */
    description?: Maybe<Scalars['String']['output']>;
    /** Indicates how much data is already provided in the Fact Sheet, its sections and subsections. */
    completion?: Maybe<FactSheetCompletion>;
    /**
     * An array of attribute names that indicates which fields or relations of this
     * Fact Sheet have intentionally not been set. These attributes are thus not used
     * for completeness calculation.
     */
    naFields?: Maybe<Array<Scalars['String']['output']>>;
    /** The tags that are associated with the Fact Sheet. */
    tags?: Maybe<Array<Tag>>;
    /** All documents that are assigned to the Fact Sheet. */
    documents?: Maybe<DocumentConnection>;
    /** Comments on a Fact Sheet */
    comments?: Maybe<CommentConnection>;
    /** All subscriptions that are assigned to the Fact Sheet. */
    subscriptions?: Maybe<SubscriptionConnection>;
    /** A list of Access Control Entities defining which user in the workspace has read access to this Fact Sheet. */
    permittedReadACL?: Maybe<Array<AccessControlEntityReference>>;
    /** A list of Access Control Entities defining which user in the workspace has write access to this Fact Sheet. */
    permittedWriteACL?: Maybe<Array<AccessControlEntityReference>>;
    /** Status of the Fact Sheet. */
    status?: Maybe<FactSheetStatus>;
    /** The hierarchy level of the Fact Sheet. (0 means level 1, 1 means level 2, etc.) */
    level?: Maybe<Scalars['Int']['output']>;
    /**
     * The timestamp in ISO 8601 format when the Fact Sheet was created. This field
     * is read-only and will never change during the lifecycle of the FactSheet.
     */
    createdAt: Scalars['String']['output'];
    /** The timestamp in ISO 8601 format when the Fact Sheet was last updated. */
    updatedAt: Scalars['String']['output'];
    /** Contains a list for the permissions for this instance and a list of fields for each CRUD operation. */
    permissions: Permissions;
    /** The status of the quality seal for this Fact Sheet */
    qualitySeal?: Maybe<QualitySealStatus>;
    /** State of the Fact Sheet. */
    lxState?: Maybe<FactSheetState>;
    /** Milestones on the Fact Sheet. */
    lxMilestones?: Maybe<Array<Milestone>>;
    /** Whether the fact sheet should be excluded from the quota check or not. */
    lxExcludeFromQuota: Scalars['Boolean']['output'];
    /** lifecycle */
    lifecycle?: Maybe<TechnicalStackLifecycle>;
    alias?: Maybe<Scalars['String']['output']>;
    /** lxCatalogStatus */
    lxCatalogStatus?: Maybe<TechnicalStackLxCatalogStatus>;
    externalId?: Maybe<ExternalId>;
    technopediaId?: Maybe<ExternalId>;
    serviceNowExternalId?: Maybe<ExternalId>;
    relToParent?: Maybe<RelToParentConnection>;
    relToChild?: Maybe<RelToChildConnection>;
    relToRequires?: Maybe<RelToRequiresConnection>;
    relToRequiredBy?: Maybe<RelToRequiredByConnection>;
    relToSuccessor?: Maybe<RelToSuccessorConnection>;
    relToPredecessor?: Maybe<RelToPredecessorConnection>;
    relTechnologyStackToITComponent?: Maybe<RelTechnologyStackToItComponentConnection>;
  };

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: TechnicalStack */
export type TechnicalStackDocumentsArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  filter?: InputMaybe<DocumentFilterInput>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: TechnicalStack */
export type TechnicalStackCommentsArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  filter?: InputMaybe<CommentFilterInput>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: TechnicalStack */
export type TechnicalStackSubscriptionsArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  filter?: InputMaybe<SubscriptionFilterInput>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: TechnicalStack */
export type TechnicalStackRelToParentArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: TechnicalStack */
export type TechnicalStackRelToChildArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: TechnicalStack */
export type TechnicalStackRelToRequiresArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: TechnicalStack */
export type TechnicalStackRelToRequiredByArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: TechnicalStack */
export type TechnicalStackRelToSuccessorArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: TechnicalStack */
export type TechnicalStackRelToPredecessorArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: TechnicalStack */
export type TechnicalStackRelTechnologyStackToItComponentArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** All enum value(s) which are available for this TechnicalStackLxCatalogStatus type */
export enum TechnicalStackLxCatalogStatus {
  Linked = 'linked',
  Ignored = 'ignored',
}

/** A connection to a list of items. */
export type RelTechnologyStackToItComponentConnection = {
  __typename?: 'RelTechnologyStackToITComponentConnection';
  edges?: Maybe<Array<Maybe<RelTechnologyStackToItComponentEdge>>>;
  pageInfo: PageInfo;
  /** A count of the total number of objects in this collection, ignoring pagination. */
  totalCount: Scalars['Int']['output'];
  /** Contains a list of the permissions for this type of relation and a list of fields for each CRUD operation. */
  permissions: Permissions;
  /**
   * A count of the skipped number of objects in this collection and for this
   * pagignation. The reason to skip/ hide an object can be a missing READ
   * permission of the relation of the target Fact Sheet for the requesting user.
   */
  skippedCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type RelTechnologyStackToItComponentEdge = {
  __typename?: 'RelTechnologyStackToITComponentEdge';
  /** The item at the end of the edge */
  node?: Maybe<RelTechnologyStackToItComponent>;
  cursor: Scalars['String']['output'];
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: UserGroup */
export type UserGroup = Node &
  BaseFactSheet & {
    __typename?: 'UserGroup';
    /** The graphQL id used to retrieve each node (object of arbitrary type) */
    id: Scalars['ID']['output'];
    /** The revision of the Fact Sheet, incremented automatically on each Fact Sheet update. */
    rev?: Maybe<Scalars['Long']['output']>;
    /** The name of the UserGroup. */
    name: Scalars['String']['output'];
    /** The calculated display name of the Fact Sheet. */
    displayName: Scalars['String']['output'];
    /** The calculated full name of the Fact Sheet. */
    fullName: Scalars['String']['output'];
    /** The type of the Fact Sheet. (Same as parameter __typeName) */
    type: FactSheetType;
    /** This can be used to categorize the Fact Sheet type into subtypes. */
    category?: Maybe<Scalars['String']['output']>;
    /** An optional description of the UserGroup. */
    description?: Maybe<Scalars['String']['output']>;
    /** Indicates how much data is already provided in the Fact Sheet, its sections and subsections. */
    completion?: Maybe<FactSheetCompletion>;
    /**
     * An array of attribute names that indicates which fields or relations of this
     * Fact Sheet have intentionally not been set. These attributes are thus not used
     * for completeness calculation.
     */
    naFields?: Maybe<Array<Scalars['String']['output']>>;
    /** The tags that are associated with the Fact Sheet. */
    tags?: Maybe<Array<Tag>>;
    /** All documents that are assigned to the Fact Sheet. */
    documents?: Maybe<DocumentConnection>;
    /** Comments on a Fact Sheet */
    comments?: Maybe<CommentConnection>;
    /** All subscriptions that are assigned to the Fact Sheet. */
    subscriptions?: Maybe<SubscriptionConnection>;
    /** A list of Access Control Entities defining which user in the workspace has read access to this Fact Sheet. */
    permittedReadACL?: Maybe<Array<AccessControlEntityReference>>;
    /** A list of Access Control Entities defining which user in the workspace has write access to this Fact Sheet. */
    permittedWriteACL?: Maybe<Array<AccessControlEntityReference>>;
    /** Status of the Fact Sheet. */
    status?: Maybe<FactSheetStatus>;
    /** The hierarchy level of the Fact Sheet. (0 means level 1, 1 means level 2, etc.) */
    level?: Maybe<Scalars['Int']['output']>;
    /**
     * The timestamp in ISO 8601 format when the Fact Sheet was created. This field
     * is read-only and will never change during the lifecycle of the FactSheet.
     */
    createdAt: Scalars['String']['output'];
    /** The timestamp in ISO 8601 format when the Fact Sheet was last updated. */
    updatedAt: Scalars['String']['output'];
    /** Contains a list for the permissions for this instance and a list of fields for each CRUD operation. */
    permissions: Permissions;
    /** The status of the quality seal for this Fact Sheet */
    qualitySeal?: Maybe<QualitySealStatus>;
    /** State of the Fact Sheet. */
    lxState?: Maybe<FactSheetState>;
    /** Milestones on the Fact Sheet. */
    lxMilestones?: Maybe<Array<Milestone>>;
    /** Whether the fact sheet should be excluded from the quota check or not. */
    lxExcludeFromQuota: Scalars['Boolean']['output'];
    /** lifecycle */
    lifecycle?: Maybe<UserGroupLifecycle>;
    alias?: Maybe<Scalars['String']['output']>;
    /** location */
    location?: Maybe<Location>;
    externalId?: Maybe<ExternalId>;
    signavioGlossaryItemId?: Maybe<ExternalId>;
    serviceNowExternalId?: Maybe<ExternalId>;
    relToParent?: Maybe<RelToParentConnection>;
    relToChild?: Maybe<RelToChildConnection>;
    relToRequires?: Maybe<RelToRequiresConnection>;
    relToRequiredBy?: Maybe<RelToRequiredByConnection>;
    relToSuccessor?: Maybe<RelToSuccessorConnection>;
    relToPredecessor?: Maybe<RelToPredecessorConnection>;
    relUserGroupToApplication?: Maybe<RelUserGroupToApplicationConnection>;
    relUserGroupToITComponent?: Maybe<RelUserGroupToItComponentConnection>;
    relUserGroupToProject?: Maybe<RelUserGroupToProjectConnection>;
    relUserGroupToProduct?: Maybe<RelUserGroupToProductConnection>;
  };

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: UserGroup */
export type UserGroupDocumentsArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  filter?: InputMaybe<DocumentFilterInput>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: UserGroup */
export type UserGroupCommentsArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  filter?: InputMaybe<CommentFilterInput>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: UserGroup */
export type UserGroupSubscriptionsArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  filter?: InputMaybe<SubscriptionFilterInput>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: UserGroup */
export type UserGroupRelToParentArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: UserGroup */
export type UserGroupRelToChildArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: UserGroup */
export type UserGroupRelToRequiresArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: UserGroup */
export type UserGroupRelToRequiredByArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: UserGroup */
export type UserGroupRelToSuccessorArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: UserGroup */
export type UserGroupRelToPredecessorArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: UserGroup */
export type UserGroupRelUserGroupToApplicationArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: UserGroup */
export type UserGroupRelUserGroupToItComponentArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: UserGroup */
export type UserGroupRelUserGroupToProjectArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A Fact Sheet that represents all data that is available for Fact Sheets of type: UserGroup */
export type UserGroupRelUserGroupToProductArgs = {
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  validityFilter?: InputMaybe<RelationValidityFilter>;
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
};

/** A connection to a list of items. */
export type RelUserGroupToApplicationConnection = {
  __typename?: 'RelUserGroupToApplicationConnection';
  edges?: Maybe<Array<Maybe<RelUserGroupToApplicationEdge>>>;
  pageInfo: PageInfo;
  /** A count of the total number of objects in this collection, ignoring pagination. */
  totalCount: Scalars['Int']['output'];
  /** Contains a list of the permissions for this type of relation and a list of fields for each CRUD operation. */
  permissions: Permissions;
  /**
   * A count of the skipped number of objects in this collection and for this
   * pagignation. The reason to skip/ hide an object can be a missing READ
   * permission of the relation of the target Fact Sheet for the requesting user.
   */
  skippedCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type RelUserGroupToApplicationEdge = {
  __typename?: 'RelUserGroupToApplicationEdge';
  /** The item at the end of the edge */
  node?: Maybe<RelUserGroupToApplication>;
  cursor: Scalars['String']['output'];
};

/** A connection to a list of items. */
export type RelUserGroupToItComponentConnection = {
  __typename?: 'RelUserGroupToITComponentConnection';
  edges?: Maybe<Array<Maybe<RelUserGroupToItComponentEdge>>>;
  pageInfo: PageInfo;
  /** A count of the total number of objects in this collection, ignoring pagination. */
  totalCount: Scalars['Int']['output'];
  /** Contains a list of the permissions for this type of relation and a list of fields for each CRUD operation. */
  permissions: Permissions;
  /**
   * A count of the skipped number of objects in this collection and for this
   * pagignation. The reason to skip/ hide an object can be a missing READ
   * permission of the relation of the target Fact Sheet for the requesting user.
   */
  skippedCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type RelUserGroupToItComponentEdge = {
  __typename?: 'RelUserGroupToITComponentEdge';
  /** The item at the end of the edge */
  node?: Maybe<RelUserGroupToItComponent>;
  cursor: Scalars['String']['output'];
};

/** A connection to a list of items. */
export type RelUserGroupToProjectConnection = {
  __typename?: 'RelUserGroupToProjectConnection';
  edges?: Maybe<Array<Maybe<RelUserGroupToProjectEdge>>>;
  pageInfo: PageInfo;
  /** A count of the total number of objects in this collection, ignoring pagination. */
  totalCount: Scalars['Int']['output'];
  /** Contains a list of the permissions for this type of relation and a list of fields for each CRUD operation. */
  permissions: Permissions;
  /**
   * A count of the skipped number of objects in this collection and for this
   * pagignation. The reason to skip/ hide an object can be a missing READ
   * permission of the relation of the target Fact Sheet for the requesting user.
   */
  skippedCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type RelUserGroupToProjectEdge = {
  __typename?: 'RelUserGroupToProjectEdge';
  /** The item at the end of the edge */
  node?: Maybe<RelUserGroupToProject>;
  cursor: Scalars['String']['output'];
};

/** A connection to a list of items. */
export type RelUserGroupToProductConnection = {
  __typename?: 'RelUserGroupToProductConnection';
  edges?: Maybe<Array<Maybe<RelUserGroupToProductEdge>>>;
  pageInfo: PageInfo;
  /** A count of the total number of objects in this collection, ignoring pagination. */
  totalCount: Scalars['Int']['output'];
  /** Contains a list of the permissions for this type of relation and a list of fields for each CRUD operation. */
  permissions: Permissions;
  /**
   * A count of the skipped number of objects in this collection and for this
   * pagignation. The reason to skip/ hide an object can be a missing READ
   * permission of the relation of the target Fact Sheet for the requesting user.
   */
  skippedCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type RelUserGroupToProductEdge = {
  __typename?: 'RelUserGroupToProductEdge';
  /** The item at the end of the edge */
  node?: Maybe<RelUserGroupToProduct>;
  cursor: Scalars['String']['output'];
};

/** A connection to a list of items. */
export type SimpleBaseFactSheetConnection = {
  __typename?: 'SimpleBaseFactSheetConnection';
  edges?: Maybe<Array<Maybe<SimpleBaseFactSheetEdge>>>;
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type SimpleBaseFactSheetEdge = {
  __typename?: 'SimpleBaseFactSheetEdge';
  /** The item at the end of the edge */
  node?: Maybe<BaseFactSheet>;
  cursor: Scalars['String']['output'];
};

/** A connection to a list of items. */
export type BaseFactSheetConnection = {
  __typename?: 'BaseFactSheetConnection';
  edges?: Maybe<Array<Maybe<BaseFactSheetEdge>>>;
  pageInfo: PageInfo;
  /**
   * The total number of objects in this collection, ignoring pagination. This
   * allows a client to fetch the first five objects by passing "5" as the argument
   * to "limit", then fetch the total count so it could display "5 of 83", for example.
   */
  totalCount: Scalars['Int']['output'];
  /** Provides information about applied filters for the given result. */
  filterOptions?: Maybe<FilterOptions>;
  /** Lists for each Fact Sheet type its CRUD permissions. */
  permissions: FactSheetPermissionsConnection;
  /** Provides information about possible views for reports, the mapping and the legend if a report view is selected. */
  view: ViewResult;
};

/** A connection to a list of items. */
export type BaseFactSheetConnectionViewArgs = {
  key?: InputMaybe<Scalars['String']['input']>;
  viewOption?: InputMaybe<ViewOptionInput>;
};

/** An edge in a connection. */
export type BaseFactSheetEdge = {
  __typename?: 'BaseFactSheetEdge';
  /** The item at the end of the edge */
  node?: Maybe<BaseFactSheet>;
  cursor: Scalars['String']['output'];
  score?: Maybe<Scalars['Float']['output']>;
};

/**
 * Shows all possible facets based on a given input filter. The information within
 * this object can be shown in the frontend and is the base when creating a new
 * filter object for the next query.
 */
export type FilterOptions = {
  __typename?: 'FilterOptions';
  /** A list of FacetOption objects which contain facet information about all possible facets for the current query result. */
  facets?: Maybe<Array<Maybe<FacetOption>>>;
};

/** Shows all possible facets that can be used for a next query including the applyed facet selection in actual query. */
export type FacetOption = {
  __typename?: 'FacetOption';
  /** The name of the facet. Eg. the name of a relation of of a selected Fact Sheet type. */
  facetKey: Scalars['String']['output'];
  /** The type of this facet. */
  facetType: FacetType;
  /**
   * An additional information field that holds further information based on
   * current facet type. Currently, this Field is used for all relational facet
   * types and provides the name of the target Fact Sheet type this facet points
   * to. EG: 'Application' or 'UserGroup'
   */
  facetSubType?: Maybe<Scalars['String']['output']>;
  /** All operators that can be used for this facet. */
  possibleOperators: Array<FacetKeyOperator>;
  /** That operator that was used for the current result. */
  operator: FacetKeyOperator;
  /** The filter items of this facet that can be used for filtering. */
  results: Array<Maybe<FacetItem>>;
  /** This facet is available with or without a Fact Sheet type filter. */
  globalFacet?: Maybe<Scalars['Boolean']['output']>;
  /**
   * Facets that represents a relation with a 'groupBy' configuration in the data
   * model definition will provide here an additional filter.
   */
  possibleGroupByItems?: Maybe<Array<Maybe<FacetGroupBy>>>;
  /**
   * Only available in lifecycle facets used to show the current selection of time
   * relevant values. All time information comes as a UTC time and has to be
   * transformed into the local time.
   */
  dateFilter?: Maybe<DateFilterOption>;
  /** Only available in subscription facets used to show the additional applied subscription type and subscription role. */
  subscriptionFilter?: Maybe<SubscriptionFacetFilter>;
  /**
   * Contains a sub-filter for this facet. When the sub-filter is applied the
   * resulting Fact Sheets are filter keys for this facet.
   */
  subFilter?: Maybe<SubFacetFilter>;
  /** The operator that is applied to the relation field filters in this relation facet. */
  relationFieldsFilterOperator?: Maybe<RelationFieldsFilterOperator>;
  /** The possible and selected relation field filters in this relation facet. */
  relationFieldsFilter?: Maybe<Array<Maybe<RelationFieldsFilterFieldOption>>>;
  /** The total count of items within this facet */
  total: Scalars['Int']['output'];
};

export enum FacetType {
  Simple = 'SIMPLE',
  Tag = 'TAG',
  Factsheettype = 'FACTSHEETTYPE',
  Factsheetsubtype = 'FACTSHEETSUBTYPE',
  Relation = 'RELATION',
  Lifecycle = 'LIFECYCLE',
  Subscriptions = 'SUBSCRIPTIONS',
  ExternalId = 'EXTERNAL_ID',
  Hierarchy = 'HIERARCHY',
  Factsheetreference = 'FACTSHEETREFERENCE',
}

export type FacetItem = {
  __typename?: 'FacetItem';
  /** The name of the facet */
  name: Scalars['String']['output'];
  /** The key for this facet. This key is used in backend for further queries. */
  key: Scalars['String']['output'];
  /** The number of items that matches when this facet is selected. */
  count: Scalars['Int']['output'];
  /** True, when this facet was applyed in current result set. */
  selected: Scalars['Boolean']['output'];
};

/** An additional filter for relations that are maked with a 'groupBy' in their data model definition. */
export type FacetGroupBy = {
  __typename?: 'FacetGroupBy';
  /** The value of the groupBy field which can be used as filtering key. */
  key: Scalars['String']['output'];
  /** True, when this groupBy key was selected for current query. */
  selected: Scalars['Boolean']['output'];
};

/** A Filter used to define time related queries. */
export type DateFilterOption = {
  __typename?: 'DateFilterOption';
  /** The start UTC date in ISO 8601 date format of current selected time range or timestamp. */
  from?: Maybe<Scalars['String']['output']>;
  /** The end UTC date in ISO 8601 date format of current selected time range. */
  to?: Maybe<Scalars['String']['output']>;
  /** The current selected type the from and to dates are applied in current fact sheet result. */
  type?: Maybe<DateFilterType>;
  /** The minimum date that can be used for time range queries in the 'from' parameter (ISO 8601 date format) */
  minDate?: Maybe<Scalars['String']['output']>;
  /** The maximum date that can be used for time range queries in the 'to' parameter (ISO 8601 date format) */
  maxDate?: Maybe<Scalars['String']['output']>;
};

/** Contains the subscription type and role that was used in the actual query. */
export type SubscriptionFacetFilter = {
  __typename?: 'SubscriptionFacetFilter';
  /** The subscription type that was applyed in the result. Can be 'null', if no subscription type filter was used. */
  type: FactSheetSubscriptionType;
  /** The subscription role that was used in the actual query. Can be 'null', if no subscription role filter was used. */
  role?: Maybe<SubscriptionToSubscriptionRoleLink>;
};

/**
 * A structure used to hold sub-filters that are applied for a facet. When a
 * sub-filter is used within a facet, the results of the sub-filter query is used
 * as new filter keys for the embedded facet.
 */
export type SubFacetFilter = {
  __typename?: 'SubFacetFilter';
  /** A list of facet filters that describe the sub-filter. */
  facetFilters?: Maybe<Array<Maybe<FacetFilter>>>;
  /** A text used to query for display names and fullTextSearch enabled fields of Fact Sheets. */
  fullTextSearch?: Maybe<Scalars['String']['output']>;
  /** A list of Fact Sheet IDs. Useful to retrieve only Fact Sheet for a list of given IDs. */
  ids?: Maybe<Array<Scalars['ID']['output']>>;
};

/** Same as the input type FacetFilterInput but only an output field type. */
export type FacetFilter = {
  __typename?: 'FacetFilter';
  /** The name of the facet */
  facetKey: Scalars['String']['output'];
  /** The operator that is applyed for each key within this facet filter. */
  operator?: Maybe<FacetKeyOperator>;
  /** The filter value that is used. This can be a string, a id, an constant etc. depending of context of facet filter. */
  keys: Array<Maybe<Scalars['String']['output']>>;
  /**
   * This type is intended when filtering for dates and time ranges and the the
   * simple key list is not suitable.It is normally used in the lifecycle type
   * facets and all times are interpreted as UTC time.
   */
  dateFilter?: Maybe<DateFilter>;
  /**
   * The normal subscription facet provides keys for filtering by subscribed
   * persons.When you need to filter additionally by subscription types and
   * subscription roles this filter have to be used to set these filter arguments
   */
  subscriptionFilter?: Maybe<SubscriptionFacetFilter>;
  /**
   * A sub-filter used to specify further filter settings for this facet.
   * Sub-filter can only be used in facets of relation type.
   */
  subFilter?: Maybe<SubFacetFilter>;
};

/** A Filter used to define time related queries. */
export type DateFilter = {
  __typename?: 'DateFilter';
  /** The start UTC date in ISO 8601 date format of current selected time range or timestamp. */
  from?: Maybe<Scalars['String']['output']>;
  /** The end UTC date in ISO 8601 date format of current selected time range. */
  to?: Maybe<Scalars['String']['output']>;
  /** The current selected type the from and to dates are applied in current fact sheet result. */
  type?: Maybe<DateFilterType>;
};

/** The field name with possible and selected values in the relation field. */
export type RelationFieldsFilterFieldOption = {
  __typename?: 'RelationFieldsFilterFieldOption';
  /** The name of the relation field used in the filter. */
  fieldName: Scalars['String']['output'];
  /** The possible field values of the relation field and their selection status. */
  values: Array<Maybe<RelationFieldsFilterFieldValueOption>>;
};

/** Possible field values of the relation field and their selection status. */
export type RelationFieldsFilterFieldValueOption = {
  __typename?: 'RelationFieldsFilterFieldValueOption';
  /** A possible relation field value(!). */
  key: Scalars['String']['output'];
  /** The value is selected for filtering. */
  selected: Scalars['Boolean']['output'];
};

/** A connection to a list of items. */
export type FactSheetPermissionsConnection = {
  __typename?: 'FactSheetPermissionsConnection';
  edges?: Maybe<Array<Maybe<FactSheetTypePermissionEdge>>>;
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type FactSheetTypePermissionEdge = {
  __typename?: 'FactSheetTypePermissionEdge';
  /** The item at the end of the edge */
  node?: Maybe<FactSheetTypePermission>;
  cursor: Scalars['String']['output'];
};

/** Lists CRUD permissions for a Fact Sheet type. */
export type FactSheetTypePermission = {
  __typename?: 'FactSheetTypePermission';
  /** The Fact Sheet type. */
  type: FactSheetType;
  /** The CRUD permission for a given Fact Sheet type. */
  permissions: Permissions;
};

/**
 * A type that holds two kind of informations: A) All possible view types of a
 * specified Fact Sheet Type. And B) the legend and mappings of a selected view
 * based on resulting Fact Sheets set.
 */
export type ViewResult = {
  __typename?: 'ViewResult';
  /** The list of all possible views for the specified Fact Sheet type. */
  viewInfos?: Maybe<Array<Maybe<ViewInfo>>>;
  /** All items of the view's legend */
  legendItems?: Maybe<Array<Maybe<LegendItem>>>;
  /** Contains the complete mapping of each Fact Sheet's fields to a legend item. */
  mapping?: Maybe<Array<Maybe<ViewMapping>>>;
};

/** A type used to hold the key of a view and some meta informations to show this in the frontend. */
export type ViewInfo = {
  __typename?: 'ViewInfo';
  /** A key used to select a specified view. */
  key: Scalars['String']['output'];
  /** A label that is used to show this view in the frontend, eg. as a menu item. */
  label: Scalars['String']['output'];
  /** A type that can be used to group multiple keys for the frontend. */
  type: ViewInfoType;
  /** Provides information ablout possible viewOption input parameters when using this view. */
  viewOptionSupport?: Maybe<ViewOptionSupport>;
};

export enum ViewInfoType {
  Tag = 'TAG',
  Field = 'FIELD',
  FieldRelation = 'FIELD_RELATION',
  FieldTargetFs = 'FIELD_TARGET_FS',
  BuiltIn = 'BUILT_IN',
}

/** Object that provides information about the supported ViewOptionInput parameter for this view */
export type ViewOptionSupport = {
  __typename?: 'ViewOptionSupport';
  /** Shows the constraint that is optionally supported by this view. */
  optionalConstraint?: Maybe<ViewConstraintType>;
  /**
   * Signals, if this view shows a range legend or not. In case that this is
   * 'true', the parameter 'ViewOptionInput.rangeLegendItemCount' can be used to
   * specifiy the number of range legend items.
   */
  usesRangeLegend: Scalars['Boolean']['output'];
};

export enum ViewConstraintType {
  /** When used, for each year whithin the given startDate and endDate a mapping with a 'constraints' field will be computed. */
  MappingPerYear = 'MAPPING_PER_YEAR',
}

/** One item in the legend of the view */
export type LegendItem = {
  __typename?: 'LegendItem';
  /** The legend key used for the mapping of items in ViewMapping and LegendItems. */
  id: Scalars['Int']['output'];
  /** The background color */
  bgColor: Scalars['String']['output'];
  /** The foreground color */
  color: Scalars['String']['output'];
  /**
   * The name or value as defined in data modell for one legend item. (This string
   * can be optionally translated into another language or used directly.)
   */
  value: Scalars['String']['output'];
  /**
   * Set to 'true' when this item has to be shown in the legend. When it is set to
   * true, this object is used to describe items in the report that have a
   * different background color than specified in legend.
   */
  inLegend: Scalars['Boolean']['output'];
  /**
   * Describes the css-opacity attribute that is used for the rendering. This field
   * is the same the the alpha value in RGBA colors and is a number between 0.0
   * (fully transparent) and 1.0 (fully opaque). (Only used in combination with
   * inLegend = true.)
   */
  transparency?: Maybe<Scalars['Decimal']['output']>;
};

/** The mapping of a Fact Sheet's field to an item in the legend */
export type ViewMapping = {
  __typename?: 'ViewMapping';
  /** The Fact Sheet Id */
  fsId: Scalars['String']['output'];
  /** A key used to pick the relevant legend item. This legendId referes to the LegendItem.id field. */
  legendId: Scalars['Int']['output'];
  /** Constraints for this mapping */
  constraints?: Maybe<Array<Maybe<ViewConstraint>>>;
  /** Additional information in form of a json string based on the selected view type. */
  infos?: Maybe<Scalars['String']['output']>;
};

/** One constraint used for a View Mapping */
export type ViewConstraint = {
  __typename?: 'ViewConstraint';
  /** The key of the constraint, like 'year' */
  key: Scalars['String']['output'];
  /** The value of the constraint, like '2015' */
  value: Scalars['String']['output'];
};

/** Optional configurations which are required for some views. */
export type ViewOptionInput = {
  /**
   * When given, the mapping result will contain additionally constraint
   * information. For example when showing a matrix report of lifecycles in a year
   * axis, each mapping will get an additionally constraints field, which tells the
   * report for which year a lifecycle phase is applied.
   */
  constraint?: InputMaybe<ViewConstraintType>;
  /**
   * Only used for views which shows values that are grouped into a range legend
   * like the 'Total Costs of IT-Components'. For these views, this parameter
   * specified the number of range items in the legend between the min and max
   * values. The 'n/a' field is not included.
   */
  rangeLegendItemCount?: InputMaybe<Scalars['Int']['input']>;
  /**
   * The start date of a time range or a timestamp in ISO 8601 date format:
   * yyyy-mm-dd. (This can possibly differ from date information given in input
   * filter and is only used for some special classifications.)
   */
  startDate?: InputMaybe<Scalars['Date']['input']>;
  /**
   * The end date of a time range in ISO 8601 date format: yyyy-mm-dd. (This can
   * possibly differ from date information given in input filter and is only used
   * for some special classifications.)
   */
  endDate?: InputMaybe<Scalars['Date']['input']>;
  /** Filter the results based on the activeFrom/activeTo fields of the given relation type. */
  constrainingRelation?: InputMaybe<Array<InputMaybe<RelationType>>>;
};

/** A complex Fact Sheet filter used to transport the user's facet filter settings to the backend that performs the query. */
export type FilterInput = {
  /** Used to control the FilterOptions in response. */
  responseOptions?: InputMaybe<ResponseOptionsInput>;
  /** A list of filter objects which describes the filtering of one facet. */
  facetFilters?: InputMaybe<Array<InputMaybe<FacetFilterInput>>>;
  /** A text used to query for display names of Fact Sheets. */
  displayName?: InputMaybe<Scalars['String']['input']>;
  /** A text used to query for display names and quickSearch enabled fields of Fact Sheets. */
  quickSearch?: InputMaybe<Scalars['String']['input']>;
  /** A text used to query for display names and fullTextSearch enabled fields of Fact Sheets. */
  fullTextSearch?: InputMaybe<Scalars['String']['input']>;
  /**
   * A list of external ID paths that have to be applied for the query. The format
   * is '<external-id name>/<external-id id>[?type=FactSheetType]' like
   * 'externalId/123'. In case an external Id needs to be restricted to a FactSheet
   * type, a query field 'type' needs to be added, eg:
   * 'externalId/123?type=Application'
   */
  externalIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /**
   * A list of Fact Sheet IDs. Useful to retrieve only Fact Sheet for a list of
   * given IDs. (DEPRECATED, use the new query 'factSheets()' instead')
   */
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Settings used to control the response of facets and facet entries. */
export type ResponseOptionsInput = {
  /** The number of facet items within a facet that should be send back in the response. */
  maxFacetDepth?: InputMaybe<Scalars['Int']['input']>;
};

/** A sorting criteria that will be applied for the result of a search. */
export type Sorting = {
  /**
   * A modue for the sorting. In most cases you will sort by fields (BY_FIELD), but
   * for reports special complex types are also available. If no mode is specified,
   * the default mode is BY_FIELD.
   */
  mode?: InputMaybe<SortMode>;
  /**
   * The field to order the result by. Only used when you search on fields using
   * the mode BY_FIELD. When using the mode BY_LIFECYCLE_LAST_PHASE and this field
   * is not set, the FactSheets lifecycle field 'lifecycle' is used as default.
   */
  key?: InputMaybe<Scalars['String']['input']>;
  /** The sort direction to order the results by. Only used when you search on fields and using the mode BY_FIELD. */
  order?: InputMaybe<SortOrder>;
};

/** The mode this sorting argument will be applied. */
export enum SortMode {
  /** Sort by a given FactSheet's field. */
  ByField = 'BY_FIELD',
  /**
   * Sort by a the FactSheet's lifecycle field, as used for the Roadmap reports for
   * example. In case that you need to use another lifecycle field than
   * 'lifecycle', the relevant lifecycle field name has to be specified in 'key'.
   */
  ByLifecycleLastPhase = 'BY_LIFECYCLE_LAST_PHASE',
}

/** The possible sort orders. */
export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc',
}

/** Options to query a whole hierarchy of Fact Sheets at once. */
export type HierarchyInput = {
  /** Determines how to query the Fact Sheet hierarchy. */
  mode: HierarchyMode;
};

/** Different modes to query a Fact Sheet hierarchy. */
export enum HierarchyMode {
  /** Query the whole hierarchy upwards and downwards. */
  FullTree = 'FULL_TREE',
}

/** A connection to a list of items. */
export type FacetItemConnection = {
  __typename?: 'FacetItemConnection';
  edges?: Maybe<Array<Maybe<FacetItemEdge>>>;
  pageInfo: PageInfo;
  /** The count of all possible facet items for requested facet group. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type FacetItemEdge = {
  __typename?: 'FacetItemEdge';
  /** The item at the end of the edge */
  node?: Maybe<FacetItem>;
  cursor: Scalars['String']['output'];
};

/** A connection to a list of items. */
export type LogEventConnection = {
  __typename?: 'LogEventConnection';
  edges?: Maybe<Array<Maybe<LogEventEdge>>>;
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type LogEventEdge = {
  __typename?: 'LogEventEdge';
  /** The item at the end of the edge */
  node?: Maybe<LogEvent>;
  cursor: Scalars['String']['output'];
};

/** Information concerning one change within a fact sheet or relation. */
export type LogEvent = {
  __typename?: 'LogEvent';
  /** The graphQL id used to retrieve each node (object of arbitrary type) */
  id: Scalars['ID']['output'];
  /** The name of the event that is relevant for the audit tail. */
  eventType: EventTypes;
  /** The attribute that was changed within the modified object. */
  path?: Maybe<Scalars['String']['output']>;
  /** The old value */
  oldValue?: Maybe<Scalars['String']['output']>;
  /** The new value */
  newValue?: Maybe<Scalars['String']['output']>;
  /** Additional context specific information which is need for special event types. */
  context?: Maybe<LogEventContext>;
  /** A message that describes the change. */
  message: Scalars['String']['output'];
  /** The seconds that are elapsed after this change. */
  secondsPast: Scalars['Long']['output'];
  /** The ISO 8601 date format of change. The date represents the UTC time. */
  createdAt: Scalars['String']['output'];
  /** The user that has caused this change. */
  user?: Maybe<User>;
  /** Contains a list for the permissions for the referenced Fact Sheet field. */
  permissions: Permissions;
};

/** All events that can be used to build an audit trail. */
export enum EventTypes {
  RelationTypeFieldCreated = 'RELATION_TYPE_FIELD_CREATED',
  FactSheetTypeFieldCreated = 'FACT_SHEET_TYPE_FIELD_CREATED',
  WorkspaceStatistics = 'WORKSPACE_STATISTICS',
  RelationCreated = 'RELATION_CREATED',
  FactSheetACERemoved = 'FACT_SHEET_A_C_E_REMOVED',
  WorkspaceDeleted = 'WORKSPACE_DELETED',
  LanguageCreated = 'LANGUAGE_CREATED',
  BookmarkShareCreated = 'BOOKMARK_SHARE_CREATED',
  FactSheetFieldTranslationDeleted = 'FACT_SHEET_FIELD_TRANSLATION_DELETED',
  RelationDeleted = 'RELATION_DELETED',
  FactSheetArchived = 'FACT_SHEET_ARCHIVED',
  FactSheetFieldUpdated = 'FACT_SHEET_FIELD_UPDATED',
  ReportViewed = 'REPORT_VIEWED',
  SubsectionViewModelUpdated = 'SUBSECTION_VIEW_MODEL_UPDATED',
  SubscriptionRoleDeleted = 'SUBSCRIPTION_ROLE_DELETED',
  FactSheetTypeCreated = 'FACT_SHEET_TYPE_CREATED',
  WorkspaceRefresh = 'WORKSPACE_REFRESH',
  RelationValidityFromChanged = 'RELATION_VALIDITY_FROM_CHANGED',
  MilestoneDeleted = 'MILESTONE_DELETED',
  RelationIntentionallyNotSetAdded = 'RELATION_INTENTIONALLY_NOT_SET_ADDED',
  WorkspaceSnapshotCreationFailed = 'WORKSPACE_SNAPSHOT_CREATION_FAILED',
  MilestoneCreated = 'MILESTONE_CREATED',
  BaseModelTranslationUpdated = 'BASE_MODEL_TRANSLATION_UPDATED',
  ExternalIdFieldTranslationDeleted = 'EXTERNAL_ID_FIELD_TRANSLATION_DELETED',
  FactSheetViewed = 'FACT_SHEET_VIEWED',
  ViewModelFieldCreated = 'VIEW_MODEL_FIELD_CREATED',
  BaseModelFieldTranslationUpdated = 'BASE_MODEL_FIELD_TRANSLATION_UPDATED',
  RelationTypeConstrainingRelationDeleted = 'RELATION_TYPE_CONSTRAINING_RELATION_DELETED',
  RelationTranslationUpdated = 'RELATION_TRANSLATION_UPDATED',
  ImpactGroupCreatedProxy = 'IMPACT_GROUP_CREATED_PROXY',
  SubscriptionCreated = 'SUBSCRIPTION_CREATED',
  TagGroupTagAdded = 'TAG_GROUP_TAG_ADDED',
  MetaModelJobEnded = 'META_MODEL_JOB_ENDED',
  ReportDeleted = 'REPORT_DELETED',
  BookmarkCreated = 'BOOKMARK_CREATED',
  RelationIntentionallyNotSetRemoved = 'RELATION_INTENTIONALLY_NOT_SET_REMOVED',
  TodoDeleted = 'TODO_DELETED',
  DocumentCreated = 'DOCUMENT_CREATED',
  FactSheetTranslationUpdated = 'FACT_SHEET_TRANSLATION_UPDATED',
  SectionViewModelUpdated = 'SECTION_VIEW_MODEL_UPDATED',
  BookmarkShareDeleted = 'BOOKMARK_SHARE_DELETED',
  FactSheetViewModelCreated = 'FACT_SHEET_VIEW_MODEL_CREATED',
  TagCreated = 'TAG_CREATED',
  Fat = 'FAT',
  QualitySealApproved = 'QUALITY_SEAL_APPROVED',
  DocumentDeleted = 'DOCUMENT_DELETED',
  ViewModelFieldUpdated = 'VIEW_MODEL_FIELD_UPDATED',
  WorkspaceSnapshotRestored = 'WORKSPACE_SNAPSHOT_RESTORED',
  MilestoneUpdated = 'MILESTONE_UPDATED',
  CommentDeleted = 'COMMENT_DELETED',
  SubscriptionRoleCreated = 'SUBSCRIPTION_ROLE_CREATED',
  RelationUpdated = 'RELATION_UPDATED',
  IntentionallyNotSetAdded = 'INTENTIONALLY_NOT_SET_ADDED',
  AuthorizationRoleCreated = 'AUTHORIZATION_ROLE_CREATED',
  TranslationModelChangedBase = 'TRANSLATION_MODEL_CHANGED_BASE',
  CommentUpdated = 'COMMENT_UPDATED',
  CommentCreated = 'COMMENT_CREATED',
  SubscriptionDeleted = 'SUBSCRIPTION_DELETED',
  RelationSwitch = 'RELATION_SWITCH',
  AccessControlEntityDeleted = 'ACCESS_CONTROL_ENTITY_DELETED',
  ExternalIdFieldDefinitionUpdated = 'EXTERNAL_ID_FIELD_DEFINITION_UPDATED',
  TagGroupUpdated = 'TAG_GROUP_UPDATED',
  AccessControlEntityBase = 'ACCESS_CONTROL_ENTITY_BASE',
  NamingRuleCreated = 'NAMING_RULE_CREATED',
  TagGroupDeleted = 'TAG_GROUP_DELETED',
  NamingRuleUpdated = 'NAMING_RULE_UPDATED',
  SubsectionViewModelDeleted = 'SUBSECTION_VIEW_MODEL_DELETED',
  TagDeleted = 'TAG_DELETED',
  ExternalIdFieldDefinitionDeleted = 'EXTERNAL_ID_FIELD_DEFINITION_DELETED',
  RelationFieldTranslationCreated = 'RELATION_FIELD_TRANSLATION_CREATED',
  FactSheetRecovered = 'FACT_SHEET_RECOVERED',
  WorkspaceSnapshotCreated = 'WORKSPACE_SNAPSHOT_CREATED',
  BookmarkUpdated = 'BOOKMARK_UPDATED',
  FactSheetCreated = 'FACT_SHEET_CREATED',
  LifecyclePhaseChangingSoon = 'LIFECYCLE_PHASE_CHANGING_SOON',
  WorkspaceInitialized = 'WORKSPACE_INITIALIZED',
  FullExportFinished = 'FULL_EXPORT_FINISHED',
  ExternalIdFieldTranslationUpdated = 'EXTERNAL_ID_FIELD_TRANSLATION_UPDATED',
  RelationTypeFieldDeleted = 'RELATION_TYPE_FIELD_DELETED',
  SectionViewModelDeleted = 'SECTION_VIEW_MODEL_DELETED',
  ImpactGroupUpdatedProxy = 'IMPACT_GROUP_UPDATED_PROXY',
  TagGroupCreated = 'TAG_GROUP_CREATED',
  FactSheetUpdated = 'FACT_SHEET_UPDATED',
  FactSheetTypeUpdated = 'FACT_SHEET_TYPE_UPDATED',
  ViewModelFieldDeleted = 'VIEW_MODEL_FIELD_DELETED',
  TagGroupTagRemoved = 'TAG_GROUP_TAG_REMOVED',
  SubscriptionRoleUpdated = 'SUBSCRIPTION_ROLE_UPDATED',
  FactSheetFieldTranslationUpdated = 'FACT_SHEET_FIELD_TRANSLATION_UPDATED',
  FactSheetTypeDeleted = 'FACT_SHEET_TYPE_DELETED',
  RelationRecovered = 'RELATION_RECOVERED',
  RelationTypeConstrainingRelationAdded = 'RELATION_TYPE_CONSTRAINING_RELATION_ADDED',
  RelationArchived = 'RELATION_ARCHIVED',
  FactSheetTypeFieldDeleted = 'FACT_SHEET_TYPE_FIELD_DELETED',
  TagUpdated = 'TAG_UPDATED',
  FactSheetAccessControlListUpdated = 'FACT_SHEET_ACCESS_CONTROL_LIST_UPDATED',
  IntentionallyNotSetRemoved = 'INTENTIONALLY_NOT_SET_REMOVED',
  TodoUpdated = 'TODO_UPDATED',
  SectionViewModelCreated = 'SECTION_VIEW_MODEL_CREATED',
  TodoCreated = 'TODO_CREATED',
  ViewModelChangedBase = 'VIEW_MODEL_CHANGED_BASE',
  FactSheetACEAdded = 'FACT_SHEET_A_C_E_ADDED',
  ReportInstalled = 'REPORT_INSTALLED',
  RelationTypeCreated = 'RELATION_TYPE_CREATED',
  BaseModelFieldTranslationCreated = 'BASE_MODEL_FIELD_TRANSLATION_CREATED',
  AuthorizationRoleDeleted = 'AUTHORIZATION_ROLE_DELETED',
  AccessControlEntityCreated = 'ACCESS_CONTROL_ENTITY_CREATED',
  BaseModelFieldTranslationDeleted = 'BASE_MODEL_FIELD_TRANSLATION_DELETED',
  RelationFieldTranslationUpdated = 'RELATION_FIELD_TRANSLATION_UPDATED',
  ExternalIdFieldDefinitionCreated = 'EXTERNAL_ID_FIELD_DEFINITION_CREATED',
  ConstrainingRelationsUpdated = 'CONSTRAINING_RELATIONS_UPDATED',
  BookmarkDeleted = 'BOOKMARK_DELETED',
  ImpactGroupDeletedProxy = 'IMPACT_GROUP_DELETED_PROXY',
  DocumentUpdated = 'DOCUMENT_UPDATED',
  FactSheetTagRemoved = 'FACT_SHEET_TAG_REMOVED',
  FactSheetFieldTranslationCreated = 'FACT_SHEET_FIELD_TRANSLATION_CREATED',
  SubsectionViewModelCreated = 'SUBSECTION_VIEW_MODEL_CREATED',
  LanguageUpdated = 'LANGUAGE_UPDATED',
  RelationTypeUpdated = 'RELATION_TYPE_UPDATED',
  NamingRuleDeleted = 'NAMING_RULE_DELETED',
  RelationFieldTranslationDeleted = 'RELATION_FIELD_TRANSLATION_DELETED',
  SubscriptionUpdated = 'SUBSCRIPTION_UPDATED',
  AccessControlEntityUpdated = 'ACCESS_CONTROL_ENTITY_UPDATED',
  WorkspaceSnapshotRestorationFailed = 'WORKSPACE_SNAPSHOT_RESTORATION_FAILED',
  FactSheetDeleted = 'FACT_SHEET_DELETED',
  AuthorizationRoleUpdated = 'AUTHORIZATION_ROLE_UPDATED',
  FactSheetTranslationCreated = 'FACT_SHEET_TRANSLATION_CREATED',
  WorkspaceUpdated = 'WORKSPACE_UPDATED',
  RelationValidityUntilChanged = 'RELATION_VALIDITY_UNTIL_CHANGED',
  CommentReplyAdded = 'COMMENT_REPLY_ADDED',
  RelationTypeDeleted = 'RELATION_TYPE_DELETED',
  TransactionCommitted = 'TRANSACTION_COMMITTED',
  WorkspaceCreated = 'WORKSPACE_CREATED',
  FactSheetTagAdded = 'FACT_SHEET_TAG_ADDED',
  RelationTranslationCreated = 'RELATION_TRANSLATION_CREATED',
  RelationTypeFieldUpdated = 'RELATION_TYPE_FIELD_UPDATED',
  LifecyclePhaseChanged = 'LIFECYCLE_PHASE_CHANGED',
  RelationTranslationDeleted = 'RELATION_TRANSLATION_DELETED',
  BookmarkViewed = 'BOOKMARK_VIEWED',
  ExternalIdFieldTranslationCreated = 'EXTERNAL_ID_FIELD_TRANSLATION_CREATED',
  FactSheetViewModelDeleted = 'FACT_SHEET_VIEW_MODEL_DELETED',
  FactSheetTranslationDeleted = 'FACT_SHEET_TRANSLATION_DELETED',
  FactSheetTypeFieldUpdated = 'FACT_SHEET_TYPE_FIELD_UPDATED',
  QualitySealBroken = 'QUALITY_SEAL_BROKEN',
}

/** Union type used to transport context specific information */
export type LogEventContext = LogEventRelationContext;

/** Context specific information for relation events */
export type LogEventRelationContext = {
  __typename?: 'LogEventRelationContext';
  /** The display name of the relation */
  displayName?: Maybe<Scalars['String']['output']>;
  /** The changed attribute within the relation */
  path?: Maybe<Scalars['String']['output']>;
};

export enum EventViewMode {
  AuditTrail = 'AUDIT_TRAIL',
  All = 'ALL',
}

/** The role the user has when subscribing to a Fact Sheet. */
export type SubscriptionRole = {
  __typename?: 'SubscriptionRole';
  /** The id of the subscription role. */
  id: Scalars['ID']['output'];
  /** The name of the subscription role. */
  name: Scalars['String']['output'];
  /** The description of the subscription role. */
  description?: Maybe<Scalars['String']['output']>;
  /** The type of the subscriptions that this role can be attached to. */
  subscriptionType?: Maybe<FactSheetSubscriptionType>;
  /** The type of Fact Sheets that this subscription role can be assigned to. */
  restrictToFactSheetTypes: Array<Scalars['String']['output']>;
  /** A list of Fact Sheet types. The subscription role will be mandatory for the defined types */
  mandatoryForFactSheetTypes: Array<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type SubscriptionRoleConnection = {
  __typename?: 'SubscriptionRoleConnection';
  edges?: Maybe<Array<Maybe<SubscriptionRoleEdge>>>;
  pageInfo: PageInfo;
  /**
   * A count of the total number of objects in this collection, ignoring
   * pagination. This allows a client to fetch the first five objects by passing
   * "5" as the argument to "limit", then fetch the total count so it could display
   * "5 of 83", for example.
   */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type SubscriptionRoleEdge = {
  __typename?: 'SubscriptionRoleEdge';
  /** The item at the end of the edge */
  node?: Maybe<SubscriptionRole>;
  cursor: Scalars['String']['output'];
};

/** Filters for the allSubscriptionRoles query. */
export type SubscriptionRoleFilter = {
  /** Selects only the subscription roles which are assigned to the given subscription type. */
  subscriptionType?: InputMaybe<FactSheetSubscriptionType>;
  /** Selects only the subscription roles which are assigned to specified fact sheet type. */
  factSheetType?: InputMaybe<FactSheetType>;
  /** Selects only the subscription roles that are mandatory for the specific fact sheet type or not fact sheet type restricted */
  mandatoryForFactSheetType?: InputMaybe<FactSheetType>;
};

/** Filters for the allTags query. */
export type TagQueryFilters = {
  /** Selects only the tags that are not part of a tag group */
  noTagGroup?: InputMaybe<Scalars['Boolean']['input']>;
  /** Selects only the tags which are assigned to specified tag group. */
  tagGroupName?: InputMaybe<Scalars['String']['input']>;
  /** Selects only the tags containing the substring in their name */
  nameSubstring?: InputMaybe<Scalars['String']['input']>;
  tagGroupNameSubstring?: InputMaybe<Scalars['String']['input']>;
  operator?: InputMaybe<TagQueryFiltersOperator>;
};

/** In case that multiple fields should be used for filtering, this is the operator to combine fields by */
export enum TagQueryFiltersOperator {
  And = 'AND',
  Or = 'OR',
}

/** A connection to a list of items. */
export type MilestoneDataConnection = {
  __typename?: 'MilestoneDataConnection';
  edges?: Maybe<Array<Maybe<MilestoneDataEdge>>>;
  pageInfo: PageInfo;
  /** The total number of objects in this collection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type MilestoneDataEdge = {
  __typename?: 'MilestoneDataEdge';
  /** The item at the end of the edge */
  node?: Maybe<MilestoneData>;
  cursor: Scalars['String']['output'];
};

/** A Milestone is mainly a referencable date with a name. */
export type MilestoneData = {
  __typename?: 'MilestoneData';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  /** Formatted YYYY-MM-DD. */
  date: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
};

/** Filters for the allMilestones query. */
export type MilestoneQueryFilter = {
  /** Matches all milestones whose id is contained in the list. */
  ids: Array<Scalars['ID']['input']>;
};

/** A type used to hold information about items viewed by the user. */
export type ViewedItem = {
  __typename?: 'ViewedItem';
  /** Time the item was last viewed by the given user. */
  lastViewedAt: Scalars['String']['output'];
  viewedItem?: Maybe<BaseFactSheet>;
};

export enum ViewedItemType {
  FactSheet = 'FACT_SHEET',
}

/** A connection to a list of items. */
export type TagGroupConnection = {
  __typename?: 'TagGroupConnection';
  edges?: Maybe<Array<Maybe<TagGroupEdge>>>;
  pageInfo: PageInfo;
  /**
   * A count of the total number of objects in this collection, ignoring
   * pagination. This allows a client to fetch the first five objects by passing
   * "5" as the argument to "limit", then fetch the total count so it could display
   * "5 of 83", for example.
   */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type TagGroupEdge = {
  __typename?: 'TagGroupEdge';
  /** The item at the end of the edge */
  node?: Maybe<TagGroup>;
  cursor: Scalars['String']['output'];
};

/**
 * Either an external id or an internal id, uniquely identifying a Fact Sheet. One
 * of external id and internal id, but not both, must be set.
 */
export type FactSheetIdentifierType = {
  /** The UUID of a Fact Sheet that was assigned by LeanIX */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** The type and id of an external id, uniquely identifying a Fact Sheet */
  externalId?: InputMaybe<FactSheetExternalIdentifierType>;
};

/** The type and id of an external id, uniquely identifying a Fact Sheet */
export type FactSheetExternalIdentifierType = {
  /** The type of the external id as defined in the data model in the section 'externalIdFields' */
  type: ExternalIdentifierType;
  /** The external id of the Fact Sheet registered with the type */
  id: Scalars['String']['input'];
};

/** The type of the external as defined in the data model in the section 'externalIdFields' */
export enum ExternalIdentifierType {
  ExternalId = 'externalId',
  ApplicationId = 'applicationId',
  DataObjectId = 'dataObjectId',
  ExternalIdProduct = 'externalIdProduct',
  SignavioProcessId = 'signavioProcessId',
  SignavioGlossaryItemId = 'signavioGlossaryItemId',
  TechnopediaId = 'technopediaId',
  ServiceNowExternalId = 'serviceNowExternalId',
  InterfaceIdSequence = 'interfaceIdSequence',
  LxSiId = 'lxSiId',
  LxCatalogId = 'lxCatalogId',
}

/** The type of a relation as defined in the data model (e.g. 'relToParent') */
export enum RelationName {
  RelToParent = 'relToParent',
  RelToChild = 'relToChild',
  RelToRequires = 'relToRequires',
  RelToRequiredBy = 'relToRequiredBy',
  RelToSuccessor = 'relToSuccessor',
  RelToPredecessor = 'relToPredecessor',
  RelProductToDataObject = 'relProductToDataObject',
  RelDataObjectToProduct = 'relDataObjectToProduct',
  RelApplicationToUserGroup = 'relApplicationToUserGroup',
  RelUserGroupToApplication = 'relUserGroupToApplication',
  RelApplicationToDataObject = 'relApplicationToDataObject',
  RelDataObjectToApplication = 'relDataObjectToApplication',
  RelApplicationToItComponent = 'relApplicationToITComponent',
  RelItComponentToApplication = 'relITComponentToApplication',
  RelApplicationToProject = 'relApplicationToProject',
  RelProjectToApplication = 'relProjectToApplication',
  RelProviderApplicationToInterface = 'relProviderApplicationToInterface',
  RelInterfaceToProviderApplication = 'relInterfaceToProviderApplication',
  RelConsumerApplicationToInterface = 'relConsumerApplicationToInterface',
  RelInterfaceToConsumerApplication = 'relInterfaceToConsumerApplication',
  RelApplicationToProcess = 'relApplicationToProcess',
  RelProcessToApplication = 'relProcessToApplication',
  RelApplicationToBusinessCapability = 'relApplicationToBusinessCapability',
  RelBusinessCapabilityToApplication = 'relBusinessCapabilityToApplication',
  RelItComponentToTechnologyStack = 'relITComponentToTechnologyStack',
  RelTechnologyStackToItComponent = 'relTechnologyStackToITComponent',
  RelItComponentToUserGroup = 'relITComponentToUserGroup',
  RelUserGroupToItComponent = 'relUserGroupToITComponent',
  RelItComponentToProvider = 'relITComponentToProvider',
  RelProviderToItComponent = 'relProviderToITComponent',
  RelInterfaceToDataObject = 'relInterfaceToDataObject',
  RelDataObjectToInterface = 'relDataObjectToInterface',
  RelInterfaceToItComponent = 'relInterfaceToITComponent',
  RelItComponentToInterface = 'relITComponentToInterface',
  RelProcessToBusinessCapability = 'relProcessToBusinessCapability',
  RelBusinessCapabilityToProcess = 'relBusinessCapabilityToProcess',
  RelProjectToUserGroup = 'relProjectToUserGroup',
  RelUserGroupToProject = 'relUserGroupToProject',
  RelProjectToBusinessCapability = 'relProjectToBusinessCapability',
  RelBusinessCapabilityToProject = 'relBusinessCapabilityToProject',
  RelProjectToItComponent = 'relProjectToITComponent',
  RelItComponentToProject = 'relITComponentToProject',
  RelProjectToProcess = 'relProjectToProcess',
  RelProcessToProject = 'relProcessToProject',
  RelProjectToProvider = 'relProjectToProvider',
  RelProviderToProject = 'relProviderToProject',
  RelProductToApplication = 'relProductToApplication',
  RelApplicationToProduct = 'relApplicationToProduct',
  RelProductToItComponent = 'relProductToITComponent',
  RelItComponentToProduct = 'relITComponentToProduct',
  RelProductToProject = 'relProductToProject',
  RelProjectToProduct = 'relProjectToProduct',
  RelProductToBusinessCapability = 'relProductToBusinessCapability',
  RelBusinessCapabilityToProduct = 'relBusinessCapabilityToProduct',
  RelProductToUserGroup = 'relProductToUserGroup',
  RelUserGroupToProduct = 'relUserGroupToProduct',
  RelProductToProcess = 'relProductToProcess',
  RelProcessToProduct = 'relProcessToProduct',
  RelProjectToBlockedProject = 'relProjectToBlockedProject',
  RelProjectToBlockingProject = 'relProjectToBlockingProject',
}

/** A connection to a list of items. */
export type LocationConnection = {
  __typename?: 'LocationConnection';
  edges?: Maybe<Array<Maybe<LocationEdge>>>;
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type LocationEdge = {
  __typename?: 'LocationEdge';
  /** The item at the end of the edge */
  node?: Maybe<Location>;
  cursor: Scalars['String']['output'];
};

/** A connection to a list of items. */
export type ApisConnection = {
  __typename?: 'ApisConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<ApisEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<ApiType>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ApisEdge = {
  __typename?: 'ApisEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: ApiType;
};

export type ApiType = {
  __typename?: 'ApiType';
  name: Scalars['String']['output'];
  workspaceName: Scalars['String']['output'];
  identity: Scalars['String']['output'];
  application?: Maybe<ApplicationType>;
  consumedApis?: Maybe<ApiConnection>;
  consumerApis?: Maybe<ApiConnection>;
  scopes: Array<Scalars['String']['output']>;
  specifications?: Maybe<SpecificationsConnection>;
};

export type ApiTypeConsumedApisArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
};

export type ApiTypeConsumerApisArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
};

export type ApiTypeSpecificationsArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
};

export type ApplicationType = {
  __typename?: 'ApplicationType';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  tags?: Maybe<Array<TagType>>;
  lifeCycle?: Maybe<Scalars['String']['output']>;
  virtualMachines?: Maybe<VirtualMachinesConnection>;
  products?: Maybe<ApplicationProductsConnection>;
  apis?: Maybe<ApisConnection>;
};

export type ApplicationTypeVirtualMachinesArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
};

export type ApplicationTypeProductsArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
};

export type ApplicationTypeApisArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
};

export type TagType = {
  __typename?: 'TagType';
  name: Scalars['String']['output'];
  tagGroup?: Maybe<Scalars['String']['output']>;
  product: ProductType;
};

export type ProductType = {
  __typename?: 'ProductType';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  wbsNumber?: Maybe<Scalars['String']['output']>;
  lifeCycle?: Maybe<Scalars['String']['output']>;
  subDomain: SubDomainType;
  tags: Array<TagType>;
  employees?: Maybe<ProductEmployeesConnection>;
  applications?: Maybe<ProductApplicationsConnection>;
};

export type ProductTypeEmployeesArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
};

export type ProductTypeApplicationsArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
};

export type SubDomainType = {
  __typename?: 'SubDomainType';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  products?: Maybe<ProductsConnection>;
  domain: DomainType;
};

export type SubDomainTypeProductsArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
};

/** A connection to a list of items. */
export type ProductsConnection = {
  __typename?: 'ProductsConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<ProductsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<ProductType>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ProductsEdge = {
  __typename?: 'ProductsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: ProductType;
};

export type DomainType = {
  __typename?: 'DomainType';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  subDomains?: Maybe<SubDomainsConnection>;
};

export type DomainTypeSubDomainsArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
};

/** A connection to a list of items. */
export type SubDomainsConnection = {
  __typename?: 'SubDomainsConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<SubDomainsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<SubDomainType>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type SubDomainsEdge = {
  __typename?: 'SubDomainsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: SubDomainType;
};

/** A connection to a list of items. */
export type ProductEmployeesConnection = {
  __typename?: 'ProductEmployeesConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<ProductEmployeesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<EmployeeType>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ProductEmployeesEdge = {
  __typename?: 'ProductEmployeesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: EmployeeType;
  tco: Scalars['Float']['output'];
  roles: Array<Role>;
};

export type EmployeeType = {
  __typename?: 'EmployeeType';
  id: Scalars['Int']['output'];
  fullName: Scalars['String']['output'];
  email: Scalars['String']['output'];
  position: Scalars['String']['output'];
  positionId: Scalars['Int']['output'];
  organizationalUnitId: Scalars['Int']['output'];
  type: Scalars['String']['output'];
  jobCode: Scalars['String']['output'];
  peopleLeader: EmployeeType;
  directReports?: Maybe<DirectReportsConnection>;
  products?: Maybe<EmployeeProductsConnection>;
  openPositions?: Maybe<OpenPositionsConnection>;
  generalOverheadProducts?: Maybe<EmployeeGeneralOverheadProductsConnection>;
};

export type EmployeeTypeDirectReportsArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<EmployeeTypeFilterInput>;
};

export type EmployeeTypeProductsArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
};

export type EmployeeTypeOpenPositionsArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  where?: InputMaybe<OpenPositionTypeFilterInput>;
};

export type EmployeeTypeGeneralOverheadProductsArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
};

/** A connection to a list of items. */
export type DirectReportsConnection = {
  __typename?: 'DirectReportsConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<DirectReportsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<EmployeeType>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type DirectReportsEdge = {
  __typename?: 'DirectReportsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: EmployeeType;
};

export type EmployeeTypeFilterInput = {
  and?: InputMaybe<Array<EmployeeTypeFilterInput>>;
  or?: InputMaybe<Array<EmployeeTypeFilterInput>>;
  id?: InputMaybe<IntOperationFilterInput>;
  fullName?: InputMaybe<StringOperationFilterInput>;
  email?: InputMaybe<StringOperationFilterInput>;
  position?: InputMaybe<StringOperationFilterInput>;
  positionId?: InputMaybe<IntOperationFilterInput>;
  organizationalUnitId?: InputMaybe<IntOperationFilterInput>;
  peopleLeader?: InputMaybe<EmployeeTypeFilterInput>;
  directReports?: InputMaybe<ListFilterInputTypeOfEmployeeTypeFilterInput>;
  products?: InputMaybe<ListFilterInputTypeOfProductTypeFilterInput>;
  generalOverheadProducts?: InputMaybe<ListFilterInputTypeOfGeneralOverheadProductTypeFilterInput>;
  type?: InputMaybe<StringOperationFilterInput>;
  openPositions?: InputMaybe<ListFilterInputTypeOfOpenPositionTypeFilterInput>;
  jobCode?: InputMaybe<StringOperationFilterInput>;
};

export type IntOperationFilterInput = {
  eq?: InputMaybe<Scalars['Int']['input']>;
  neq?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  ngt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  ngte?: InputMaybe<Scalars['Int']['input']>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  nlt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  nlte?: InputMaybe<Scalars['Int']['input']>;
};

export type StringOperationFilterInput = {
  and?: InputMaybe<Array<StringOperationFilterInput>>;
  or?: InputMaybe<Array<StringOperationFilterInput>>;
  eq?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  contains?: InputMaybe<Scalars['String']['input']>;
  ncontains?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
  nstartsWith?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  nendsWith?: InputMaybe<Scalars['String']['input']>;
};

export type ListFilterInputTypeOfEmployeeTypeFilterInput = {
  all?: InputMaybe<EmployeeTypeFilterInput>;
  none?: InputMaybe<EmployeeTypeFilterInput>;
  some?: InputMaybe<EmployeeTypeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ListFilterInputTypeOfProductTypeFilterInput = {
  all?: InputMaybe<ProductTypeFilterInput>;
  none?: InputMaybe<ProductTypeFilterInput>;
  some?: InputMaybe<ProductTypeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ProductTypeFilterInput = {
  and?: InputMaybe<Array<ProductTypeFilterInput>>;
  or?: InputMaybe<Array<ProductTypeFilterInput>>;
  id?: InputMaybe<StringOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  wbsNumber?: InputMaybe<StringOperationFilterInput>;
  lifeCycle?: InputMaybe<StringOperationFilterInput>;
  subDomain?: InputMaybe<SubDomainTypeFilterInput>;
  employees?: InputMaybe<ListFilterInputTypeOfEmployeeTypeFilterInput>;
  applications?: InputMaybe<ListFilterInputTypeOfApplicationTypeFilterInput>;
  tags?: InputMaybe<ListFilterInputTypeOfTagTypeFilterInput>;
};

export type SubDomainTypeFilterInput = {
  and?: InputMaybe<Array<SubDomainTypeFilterInput>>;
  or?: InputMaybe<Array<SubDomainTypeFilterInput>>;
  id?: InputMaybe<StringOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  domain?: InputMaybe<DomainTypeFilterInput>;
  products?: InputMaybe<ListFilterInputTypeOfProductTypeFilterInput>;
};

export type DomainTypeFilterInput = {
  and?: InputMaybe<Array<DomainTypeFilterInput>>;
  or?: InputMaybe<Array<DomainTypeFilterInput>>;
  id?: InputMaybe<StringOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  subDomains?: InputMaybe<ListFilterInputTypeOfSubDomainTypeFilterInput>;
};

export type ListFilterInputTypeOfSubDomainTypeFilterInput = {
  all?: InputMaybe<SubDomainTypeFilterInput>;
  none?: InputMaybe<SubDomainTypeFilterInput>;
  some?: InputMaybe<SubDomainTypeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ListFilterInputTypeOfApplicationTypeFilterInput = {
  all?: InputMaybe<ApplicationTypeFilterInput>;
  none?: InputMaybe<ApplicationTypeFilterInput>;
  some?: InputMaybe<ApplicationTypeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ApplicationTypeFilterInput = {
  and?: InputMaybe<Array<ApplicationTypeFilterInput>>;
  or?: InputMaybe<Array<ApplicationTypeFilterInput>>;
  id?: InputMaybe<StringOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  tags?: InputMaybe<ListFilterInputTypeOfTagTypeFilterInput>;
  lifeCycle?: InputMaybe<StringOperationFilterInput>;
  products?: InputMaybe<ListFilterInputTypeOfProductTypeFilterInput>;
  virtualMachines?: InputMaybe<ListFilterInputTypeOfVirtualMachineTypeFilterInput>;
  apis?: InputMaybe<ListFilterInputTypeOfApiTypeFilterInput>;
};

export type ListFilterInputTypeOfTagTypeFilterInput = {
  all?: InputMaybe<TagTypeFilterInput>;
  none?: InputMaybe<TagTypeFilterInput>;
  some?: InputMaybe<TagTypeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TagTypeFilterInput = {
  and?: InputMaybe<Array<TagTypeFilterInput>>;
  or?: InputMaybe<Array<TagTypeFilterInput>>;
  name?: InputMaybe<StringOperationFilterInput>;
  tagGroup?: InputMaybe<StringOperationFilterInput>;
  product?: InputMaybe<ProductTypeFilterInput>;
};

export type ListFilterInputTypeOfVirtualMachineTypeFilterInput = {
  all?: InputMaybe<VirtualMachineTypeFilterInput>;
  none?: InputMaybe<VirtualMachineTypeFilterInput>;
  some?: InputMaybe<VirtualMachineTypeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
};

export type VirtualMachineTypeFilterInput = {
  and?: InputMaybe<Array<VirtualMachineTypeFilterInput>>;
  or?: InputMaybe<Array<VirtualMachineTypeFilterInput>>;
  id?: InputMaybe<IntOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  location?: InputMaybe<StringOperationFilterInput>;
  operatingSystem?: InputMaybe<StringOperationFilterInput>;
  application?: InputMaybe<ApplicationTypeFilterInput>;
};

export type ListFilterInputTypeOfApiTypeFilterInput = {
  all?: InputMaybe<ApiTypeFilterInput>;
  none?: InputMaybe<ApiTypeFilterInput>;
  some?: InputMaybe<ApiTypeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ApiTypeFilterInput = {
  and?: InputMaybe<Array<ApiTypeFilterInput>>;
  or?: InputMaybe<Array<ApiTypeFilterInput>>;
  name?: InputMaybe<StringOperationFilterInput>;
  workspaceName?: InputMaybe<StringOperationFilterInput>;
  identity?: InputMaybe<StringOperationFilterInput>;
  application?: InputMaybe<ApplicationTypeFilterInput>;
  consumedApis?: InputMaybe<ListFilterInputTypeOfApiTypeFilterInput>;
  consumerApis?: InputMaybe<ListFilterInputTypeOfApiTypeFilterInput>;
  specifications?: InputMaybe<ListFilterInputTypeOfApiSpecificationTypeFilterInput>;
};

export type ListFilterInputTypeOfApiSpecificationTypeFilterInput = {
  all?: InputMaybe<ApiSpecificationTypeFilterInput>;
  none?: InputMaybe<ApiSpecificationTypeFilterInput>;
  some?: InputMaybe<ApiSpecificationTypeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ApiSpecificationTypeFilterInput = {
  and?: InputMaybe<Array<ApiSpecificationTypeFilterInput>>;
  or?: InputMaybe<Array<ApiSpecificationTypeFilterInput>>;
  workspaceName?: InputMaybe<StringOperationFilterInput>;
  apiName?: InputMaybe<StringOperationFilterInput>;
  type?: InputMaybe<StringOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  version?: InputMaybe<StringOperationFilterInput>;
  api?: InputMaybe<ApiTypeFilterInput>;
  audience?: InputMaybe<StringOperationFilterInput>;
};

export type ListFilterInputTypeOfGeneralOverheadProductTypeFilterInput = {
  all?: InputMaybe<GeneralOverheadProductTypeFilterInput>;
  none?: InputMaybe<GeneralOverheadProductTypeFilterInput>;
  some?: InputMaybe<GeneralOverheadProductTypeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GeneralOverheadProductTypeFilterInput = {
  and?: InputMaybe<Array<GeneralOverheadProductTypeFilterInput>>;
  or?: InputMaybe<Array<GeneralOverheadProductTypeFilterInput>>;
  id?: InputMaybe<StringOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  wbsNumber?: InputMaybe<StringOperationFilterInput>;
  employees?: InputMaybe<ListFilterInputTypeOfEmployeeTypeFilterInput>;
};

export type ListFilterInputTypeOfOpenPositionTypeFilterInput = {
  all?: InputMaybe<OpenPositionTypeFilterInput>;
  none?: InputMaybe<OpenPositionTypeFilterInput>;
  some?: InputMaybe<OpenPositionTypeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OpenPositionTypeFilterInput = {
  and?: InputMaybe<Array<OpenPositionTypeFilterInput>>;
  or?: InputMaybe<Array<OpenPositionTypeFilterInput>>;
  positionId?: InputMaybe<IntOperationFilterInput>;
  position?: InputMaybe<StringOperationFilterInput>;
  organizationalUnitId?: InputMaybe<IntOperationFilterInput>;
  peopleLeader?: InputMaybe<EmployeeTypeFilterInput>;
  status?: InputMaybe<StringOperationFilterInput>;
  newEmployeeName?: InputMaybe<StringOperationFilterInput>;
  startDate?: InputMaybe<StringOperationFilterInput>;
};

/** A connection to a list of items. */
export type EmployeeProductsConnection = {
  __typename?: 'EmployeeProductsConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<EmployeeProductsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<ProductType>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type EmployeeProductsEdge = {
  __typename?: 'EmployeeProductsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: ProductType;
  tco: Scalars['Float']['output'];
  roles: Array<Role>;
};

export enum Role {
  ProductMember = 'PRODUCT_MEMBER',
  LeadEngineer = 'LEAD_ENGINEER',
  ProductManager = 'PRODUCT_MANAGER',
  ProductDesigner = 'PRODUCT_DESIGNER',
  CompetencyLead = 'COMPETENCY_LEAD',
}

/** A connection to a list of items. */
export type OpenPositionsConnection = {
  __typename?: 'OpenPositionsConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<OpenPositionsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<OpenPositionType>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type OpenPositionsEdge = {
  __typename?: 'OpenPositionsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: OpenPositionType;
};

export type OpenPositionType = {
  __typename?: 'OpenPositionType';
  positionId: Scalars['Int']['output'];
  position: Scalars['String']['output'];
  organizationalUnitId?: Maybe<Scalars['Int']['output']>;
  status: Scalars['String']['output'];
  newEmployeeName?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['String']['output']>;
  jobCode: Scalars['String']['output'];
  peopleLeader: EmployeeType;
  product?: Maybe<ProductType>;
  generalOverheadProduct?: Maybe<GeneralOverheadProductType>;
};

export type GeneralOverheadProductType = {
  __typename?: 'GeneralOverheadProductType';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  wbsNumber?: Maybe<Scalars['String']['output']>;
  employees?: Maybe<GeneralOverheadProductEmployeesConnection>;
};

export type GeneralOverheadProductTypeEmployeesArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
};

/** A connection to a list of items. */
export type GeneralOverheadProductEmployeesConnection = {
  __typename?: 'GeneralOverheadProductEmployeesConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<GeneralOverheadProductEmployeesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<EmployeeType>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type GeneralOverheadProductEmployeesEdge = {
  __typename?: 'GeneralOverheadProductEmployeesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: EmployeeType;
  tco: Scalars['Float']['output'];
};

/** A connection to a list of items. */
export type EmployeeGeneralOverheadProductsConnection = {
  __typename?: 'EmployeeGeneralOverheadProductsConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<EmployeeGeneralOverheadProductsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<GeneralOverheadProductType>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type EmployeeGeneralOverheadProductsEdge = {
  __typename?: 'EmployeeGeneralOverheadProductsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: GeneralOverheadProductType;
  tco: Scalars['Float']['output'];
};

/** A connection to a list of items. */
export type ProductApplicationsConnection = {
  __typename?: 'ProductApplicationsConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<ProductApplicationsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<ApplicationType>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ProductApplicationsEdge = {
  __typename?: 'ProductApplicationsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: ApplicationType;
  usageType: Scalars['String']['output'];
};

/** A connection to a list of items. */
export type VirtualMachinesConnection = {
  __typename?: 'VirtualMachinesConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<VirtualMachinesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<VirtualMachineType>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type VirtualMachinesEdge = {
  __typename?: 'VirtualMachinesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: VirtualMachineType;
};

export type VirtualMachineType = {
  __typename?: 'VirtualMachineType';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  location?: Maybe<Scalars['String']['output']>;
  operatingSystem?: Maybe<Scalars['String']['output']>;
  application?: Maybe<ApplicationType>;
};

/** A connection to a list of items. */
export type ApplicationProductsConnection = {
  __typename?: 'ApplicationProductsConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<ApplicationProductsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<ProductType>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ApplicationProductsEdge = {
  __typename?: 'ApplicationProductsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: ProductType;
  usageType: Scalars['String']['output'];
};

/** A connection to a list of items. */
export type ApiConnection = {
  __typename?: 'ApiConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<ApiEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<ApiType>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ApiEdge = {
  __typename?: 'ApiEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: ApiType;
  scopes: Array<Scalars['String']['output']>;
};

/** A connection to a list of items. */
export type SpecificationsConnection = {
  __typename?: 'SpecificationsConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<SpecificationsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<ApiSpecificationType>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type SpecificationsEdge = {
  __typename?: 'SpecificationsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: ApiSpecificationType;
};

export type ApiSpecificationType = {
  __typename?: 'ApiSpecificationType';
  type: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['String']['output']>;
  audience?: Maybe<Scalars['String']['output']>;
};

export type ApiTypeSortInput = {
  name?: InputMaybe<SortEnumType>;
  workspaceName?: InputMaybe<SortEnumType>;
  identity?: InputMaybe<SortEnumType>;
  application?: InputMaybe<ApplicationTypeSortInput>;
};

export enum SortEnumType {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type ApplicationTypeSortInput = {
  id?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  lifeCycle?: InputMaybe<SortEnumType>;
};

export type ApiInput = {
  workspaceName: Scalars['String']['input'];
  apiName: Scalars['String']['input'];
};

/** A connection to a list of items. */
export type ApplicationsConnection = {
  __typename?: 'ApplicationsConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<ApplicationsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<ApplicationType>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type ApplicationsEdge = {
  __typename?: 'ApplicationsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: ApplicationType;
};

export type ApplicationInput = {
  id: Scalars['String']['input'];
};

/** A connection to a list of items. */
export type DomainsConnection = {
  __typename?: 'DomainsConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<DomainsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<DomainType>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type DomainsEdge = {
  __typename?: 'DomainsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: DomainType;
};

export type DomainInput = {
  id: Scalars['String']['input'];
};

/** A connection to a list of items. */
export type EmployeesConnection = {
  __typename?: 'EmployeesConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<EmployeesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<EmployeeType>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type EmployeesEdge = {
  __typename?: 'EmployeesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: EmployeeType;
};

export type EmployeeTypeSortInput = {
  id?: InputMaybe<SortEnumType>;
  fullName?: InputMaybe<SortEnumType>;
  email?: InputMaybe<SortEnumType>;
  position?: InputMaybe<SortEnumType>;
  positionId?: InputMaybe<SortEnumType>;
  organizationalUnitId?: InputMaybe<SortEnumType>;
  peopleLeader?: InputMaybe<EmployeeTypeSortInput>;
  type?: InputMaybe<SortEnumType>;
  jobCode?: InputMaybe<SortEnumType>;
};

export type EmployeeInput = {
  id: Scalars['Int']['input'];
};

/** A connection to a list of items. */
export type GeneralOverheadProductsConnection = {
  __typename?: 'GeneralOverheadProductsConnection';
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of edges. */
  edges?: Maybe<Array<GeneralOverheadProductsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<GeneralOverheadProductType>>;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int']['output'];
};

/** An edge in a connection. */
export type GeneralOverheadProductsEdge = {
  __typename?: 'GeneralOverheadProductsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: GeneralOverheadProductType;
};

export type GeneralOverheadProductInput = {
  id: Scalars['String']['input'];
};

export type OpenPositionTypeSortInput = {
  positionId?: InputMaybe<SortEnumType>;
  position?: InputMaybe<SortEnumType>;
  organizationalUnitId?: InputMaybe<SortEnumType>;
  peopleLeader?: InputMaybe<EmployeeTypeSortInput>;
  status?: InputMaybe<SortEnumType>;
  newEmployeeName?: InputMaybe<SortEnumType>;
  startDate?: InputMaybe<SortEnumType>;
};

export type ProductTypeSortInput = {
  id?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  wbsNumber?: InputMaybe<SortEnumType>;
  lifeCycle?: InputMaybe<SortEnumType>;
  subDomain?: InputMaybe<SubDomainTypeSortInput>;
};

export type SubDomainTypeSortInput = {
  id?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  domain?: InputMaybe<DomainTypeSortInput>;
};

export type DomainTypeSortInput = {
  id?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
};

export type ProductInput = {
  id: Scalars['String']['input'];
};

export type SubDomainInput = {
  id: Scalars['String']['input'];
};

export type VirtualMachineTypeSortInput = {
  id?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  location?: InputMaybe<SortEnumType>;
  operatingSystem?: InputMaybe<SortEnumType>;
  application?: InputMaybe<ApplicationTypeSortInput>;
};

export type VirtualMachineInput = {
  id: Scalars['Int']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Create a Fact Sheet */
  createFactSheet: CreateFactSheetPayload;
  /** Clone a Fact Sheet */
  cloneFactSheet: CloneFactSheetPayload;
  /** Allows to update Fact Sheet data */
  updateFactSheet: UpdateFactSheetPayload;
  /** Allows to update Fact Sheet data using an external ID */
  updateFactSheetExternal: UpdateFactSheetExternalPayload;
  /** add a relation constraint, creating the constrained and constraining relations if missing */
  createRelationConstraint: CreateRelationConstraintPayload;
  /** remove a relation constraint, deleting the necessary relations */
  deleteRelationConstraint: DeleteRelationConstraintPayload;
  /** move all relations of a given name from one Fact Sheet to another */
  moveRelations: MoveRelationsPayload;
  /** copy all relations of a given name from one Fact Sheet to another */
  copyRelations: CopyRelationsPayload;
  /** Creates a tag group in the storage. */
  createTagGroup: CreateTagGroupPayload;
  /** Updates a tag group. */
  updateTagGroup: UpdateTagGroupPayload;
  /** Upserts a tag group */
  upsertTagGroup: UpsertTagGroupPayload;
  /** Deletes a tag group from the storage if it does not contain any tag. */
  deleteTagGroup: DeleteTagGroupPayload;
  /** Creates a tag in the storage. */
  createTag: CreateTagPayload;
  /** Upserts a tag */
  upsertTag: UpsertTagPayload;
  /** Updates a tag */
  updateTag: UpdateTagPayload;
  /** Deletes a tag from the storage if it is not assigned to any Fact Sheet. */
  deleteTag: DeleteTagPayload;
  /**
   * Adds a document to the storage. To upload a file to the graphQL endpoint,
   * please use the multipart endpoint to be able to send the binary file data
   * including with graphQL's query and variables.
   */
  createDocument: CreateDocumentPayload;
  /**
   * Updates fields or content of a document. When the binary content should be
   * updated, one Patch object with path 'file' needs to be applied to specify a
   * file name and triggering the replacement of binary content.
   */
  updateDocument: UpdateDocumentPayload;
  /** Deletes a document */
  deleteDocument: DeleteDocumentPayload;
  /** Adds a comment to a Fact Sheet */
  createComment: CreateCommentPayload;
  /** Deletes a comment from a fact sheet */
  deleteComment: DeleteCommentPayload;
  /** Adds a reply to a comment */
  createReply: CreateReplyPayload;
  /** Adds a subscription to a Fact Sheet. */
  createSubscription: CreateSubscriptionPayload;
  /** Updates fields of a subscription */
  updateSubscription: UpdateSubscriptionPayload;
  /** Deletes a subscription */
  deleteSubscription: DeleteSubscriptionPayload;
  /** Deletes all subscriptions of current user from a fact sheet */
  deleteSubscriptions: DeleteSubscriptionsPayload;
  /** Adds a subscription role. */
  createSubscriptionRole: CreateSubscriptionRolePayload;
  /** Updates a subscription role. */
  updateSubscriptionRole: UpdateSubscriptionRolePayload;
  /** Deletes a subscription role. */
  deleteSubscriptionRole: DeleteSubscriptionRolePayload;
  /**
   * Creates or updates a relation between two Fact Sheets. In case of a to-many
   * relation (many-to-many, one-to-many), a relation is uniquely identified by two
   * fact sheet identifiers ('from' and 'to') and the relation type (relToParent,
   * relToRequiredBy, ...). In case of a to-one relation (many-to-one, one-to-one),
   * a relation is already uniquely identified by the 'from' identifier and the
   * relation type. In that case, the relation is seen as a property of the 'from'
   * fact sheet that can be updated. This would result in a switch of the relation target.
   */
  upsertRelation: UpsertRelationPayload;
  /**
   * Deletes a relation between two Fact Sheets. If the type of the relation is
   * "-to-one", the mutation will also delete a relation that targets a different to Fact Sheet.
   */
  deleteRelation: DeleteRelationPayload;
  /**
   * Assigns an employee to a list of regular products, while also specifying the TCO for said relationships.
   * This mutation overwrites any existing relationships, but will fail if materialisation will remove any existing relationships.
   * Use the `UnassignEmployeeFromProduct` mutation to explicitly remove relationships.
   * The sum of the TCO of the relationships must be exactly 1.
   */
  assignEmployeeToProducts: AssignEmployeeToProductsPayload;
  /**
   * Assigns an employee to a General Overhead Product.
   * This mutation overwrites any existing relationships.
   * Any regular product relationships will be removed.
   * An employee can and must be be assigned either to one or more Regular Products, either to one and only one General Overhead Product.
   * The TCO of a relationship with a General Overhead Product is 1.0.
   */
  assignEmployeeToGeneralOverheadProduct: AssignEmployeeToGeneralOverheadProductPayload;
  /** Assigns an employee to a product. The relationship has no TCO. */
  assignEmployeeToProductWithoutTco: AssignEmployeeToProductWithoutTcoPayload;
  /**
   * Unassigns an employee from a list of products.
   * This mutation succeeds only if the TCO of the relationships is zero.
   * Use the `AssignEmployeeToProduct` mutation to fully reallocate TCO of relationships before they are removed.
   */
  unassignEmployeeFromProducts: UnassignEmployeeFromProductsPayload;
  /**
   * Unassigns an employee from a product.
   * This mutation succeeds only if the relationship has no TCO.
   * Use the `AssignEmployeeToProduct` mutation to fully reallocate TCO of a relationship before it is removed.
   */
  unassignEmployeeFromProductWithoutTco: UnassignEmployeeFromProductWithoutTcoPayload;
  /**
   * Unassign role from employee product relation
   * Use the `AssignEmployeeToProduct` mutation to reallocate a role to an employee.
   */
  unassignRolesFromEmployeeProduct: UnassignRolesFromEmployeeProductPayload;
  /**
   * Assigns roles to an employee product relationship. The relationship must already exist and it is not possible
   * to assign multiple roles to a product besides the "Product Member" role.
   */
  assignRolesToEmployeeProduct: AssignRolesToEmployeeProductPayload;
  modifyRolesForEmployeeProduct: ModifyRolesForEmployeeProductPayload;
  /**
   * Assigns a product to an open position.
   * This mutation overwrites any existing assignment.
   * Use the `UnassignProductFromOpenPosition` mutation to explicitly remove the relationship.
   * The TCO associated with the relationship is considered to be 1.
   */
  assignProductToOpenPosition: AssignProductToOpenPositionPayload;
  /**
   * Unassigns an employee from a list of products.
   * This mutation succeeds only if the TCO of the relationships is zero.
   * Use the `AssignEmployeeToProduct` mutation to fully reallocate TCO of relationships before they are removed.
   */
  unassignProductFromOpenPosition: UnassignProductFromOpenPositionPayload;
  /**
   * Assigns a general overhead product to an open position.
   * This mutation overwrites any existing assignment.
   * Use the `UnassignGeneralOverheadProductFromOpenPosition` mutation to explicitly remove the relationship.
   */
  assignGeneralOverheadProductToOpenPosition: AssignGeneralOverheadProductToOpenPositionPayload;
  /**
   * Unassigns a general overhead product from an open position.
   * This mutation succeeds only if the TCO of the relationships is zero.
   * Use the `AssignGeneralOverheadProductToOpenPosition` mutation to fully reallocate TCO of relationships before they are removed.
   */
  unassignGeneralOverheadProductFromOpenPosition: UnassignGeneralOverheadProductFromOpenPositionPayload;
};

export type MutationCreateFactSheetArgs = {
  input: CreateFactSheetInput;
};

export type MutationCloneFactSheetArgs = {
  input: CloneFactSheetInput;
};

export type MutationUpdateFactSheetArgs = {
  input: UpdateFactSheetInput;
};

export type MutationUpdateFactSheetExternalArgs = {
  input: UpdateFactSheetExternalInput;
};

export type MutationCreateRelationConstraintArgs = {
  input: CreateRelationConstraintInput;
};

export type MutationDeleteRelationConstraintArgs = {
  input: DeleteRelationConstraintInput;
};

export type MutationMoveRelationsArgs = {
  input: MoveRelationsInput;
};

export type MutationCopyRelationsArgs = {
  input: CopyRelationsInput;
};

export type MutationCreateTagGroupArgs = {
  input: CreateTagGroupInput;
};

export type MutationUpdateTagGroupArgs = {
  input: UpdateTagGroupInput;
};

export type MutationUpsertTagGroupArgs = {
  input: UpsertTagGroupInput;
};

export type MutationDeleteTagGroupArgs = {
  input: DeleteTagGroupInput;
};

export type MutationCreateTagArgs = {
  input: CreateTagInput;
};

export type MutationUpsertTagArgs = {
  input: UpsertTagInput;
};

export type MutationUpdateTagArgs = {
  input: UpdateTagInput;
};

export type MutationDeleteTagArgs = {
  input: DeleteTagInput;
};

export type MutationCreateDocumentArgs = {
  input: CreateDocumentInput;
};

export type MutationUpdateDocumentArgs = {
  input: UpdateDocumentInput;
};

export type MutationDeleteDocumentArgs = {
  input: DeleteDocumentInput;
};

export type MutationCreateCommentArgs = {
  input: CreateCommentInput;
};

export type MutationDeleteCommentArgs = {
  input: DeleteCommentInput;
};

export type MutationCreateReplyArgs = {
  input: CreateReplyInput;
};

export type MutationCreateSubscriptionArgs = {
  input: CreateSubscriptionInput;
};

export type MutationUpdateSubscriptionArgs = {
  input: UpdateSubscriptionInput;
};

export type MutationDeleteSubscriptionArgs = {
  input: DeleteSubscriptionInput;
};

export type MutationDeleteSubscriptionsArgs = {
  input: DeleteSubscriptionsInput;
};

export type MutationCreateSubscriptionRoleArgs = {
  input: CreateSubscriptionRoleInput;
};

export type MutationUpdateSubscriptionRoleArgs = {
  input: UpdateSubscriptionRoleInput;
};

export type MutationDeleteSubscriptionRoleArgs = {
  input: DeleteSubscriptionRoleInput;
};

export type MutationUpsertRelationArgs = {
  input: UpsertRelationInput;
};

export type MutationDeleteRelationArgs = {
  input: DeleteRelationInput;
};

export type MutationAssignEmployeeToProductsArgs = {
  input: AssignEmployeeToProductsInput;
};

export type MutationAssignEmployeeToGeneralOverheadProductArgs = {
  input: AssignEmployeeToGeneralOverheadProductInput;
};

export type MutationAssignEmployeeToProductWithoutTcoArgs = {
  input: AssignEmployeeToProductWithoutTcoInput;
};

export type MutationUnassignEmployeeFromProductsArgs = {
  input: UnassignEmployeeFromProductsInput;
};

export type MutationUnassignEmployeeFromProductWithoutTcoArgs = {
  input: UnassignEmployeeFromProductWithoutTcoInput;
};

export type MutationUnassignRolesFromEmployeeProductArgs = {
  input: UnassignRolesFromEmployeeProductInput;
};

export type MutationAssignRolesToEmployeeProductArgs = {
  input: AssignRolesToEmployeeProductInput;
};

export type MutationModifyRolesForEmployeeProductArgs = {
  input: ModifyRolesForEmployeeProductInput;
};

export type MutationAssignProductToOpenPositionArgs = {
  input: AssignProductToOpenPositionInput;
};

export type MutationUnassignProductFromOpenPositionArgs = {
  input: UnassignProductFromOpenPositionInput;
};

export type MutationAssignGeneralOverheadProductToOpenPositionArgs = {
  input: AssignGeneralOverheadProductToOpenPositionInput;
};

export type MutationUnassignGeneralOverheadProductFromOpenPositionArgs = {
  input: UnassignGeneralOverheadProductFromOpenPositionInput;
};

export type CreateFactSheetPayload = {
  __typename?: 'CreateFactSheetPayload';
  factSheetMutationResult?: Maybe<FactSheetMutationResult>;
};

/** Result of a updateFactSheet mutation containing the updated Fact Sheet and possible new relations. */
export type FactSheetMutationResult = {
  __typename?: 'FactSheetMutationResult';
  /** The Fact Sheet after the mutation has finished. */
  factSheet: BaseFactSheet;
  /** A list of new created relations plus the path of initiating patch operation. */
  newRelations?: Maybe<Array<Maybe<CreatedRelation>>>;
};

/** Describes a validation error which occures during a mutation. */
export type CreatedRelation = {
  __typename?: 'CreatedRelation';
  /** The path that was used to create the new relation. */
  path: Scalars['String']['output'];
  /** The new created relation */
  relation: BaseRelation;
};

export type CreateFactSheetInput = {
  /** Data for the created Fact Sheet */
  input: BaseFactSheetInput;
  /** The patches you want to perform on the created Fact Sheet */
  patches?: InputMaybe<Array<InputMaybe<Patch>>>;
  /**
   * Don't persist the mutation, only check if validation passes. Must be the
   * same for all mutation fields in the GraphQL request!
   */
  validateOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Data sent when creating a Fact Sheet */
export type BaseFactSheetInput = {
  /** The name of the new Fact Sheet */
  name: Scalars['String']['input'];
  /** The type of the new Fact Sheet */
  type: FactSheetType;
  /** Whether the fact sheet should be excluded from the quota check or not. */
  lxExcludeFromQuota?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * The Access Control List determining permitted read access on the created Fact
   * Sheet. If null or empty, read access will be permitted to all users.
   * (Authorization Model permissions will still be taken into account.)
   */
  permittedReadACL?: InputMaybe<Array<AccessControlEntityReferenceInput>>;
  /**
   * The Access Control List determining permitted read access on the created Fact
   * Sheet. If null or empty, read access will be permitted to all users.
   * (Authorization Model permissions will still be taken into account.)
   */
  permittedWriteACL?: InputMaybe<Array<AccessControlEntityReferenceInput>>;
};

/** References an Access Control Entity by name or by id. */
export type AccessControlEntityReferenceInput = {
  /** The id of the Access Control Entity. If this field is null, then 'name' must not be null. */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** The name of the Access Control Entity. Only used if 'id' is null. */
  name?: InputMaybe<Scalars['String']['input']>;
};

/** A patch of a concrete object's fields */
export type Patch = {
  /** The operation you want to perform */
  op: PatchOperation;
  /** Path to the field which should be modified, eg: '/name' */
  path: Scalars['String']['input'];
  /** The value to store. This can be a simple string or a json object for complex types. */
  value?: InputMaybe<Scalars['String']['input']>;
};

/** The operations which can be performed in a patch. */
export enum PatchOperation {
  Add = 'add',
  Replace = 'replace',
  Remove = 'remove',
}

export type CloneFactSheetPayload = {
  __typename?: 'CloneFactSheetPayload';
  factSheetCloneResult?: Maybe<FactSheetCloneResult>;
};

/**
 * Result of a cloneFactSheet mutation containing the cloned Fact Sheet, a list of
 * fields that were removed and possible new relations.
 */
export type FactSheetCloneResult = {
  __typename?: 'FactSheetCloneResult';
  /** The cloned Fact Sheet with all patches applied. */
  factSheet: BaseFactSheet;
  /** A list of fields which were deleted while cloning the Fact Sheet. */
  removedFields: Array<Maybe<Scalars['String']['output']>>;
  /** A list of new created relations plus the path of initiating patch operation. */
  newRelations?: Maybe<Array<Maybe<CreatedRelation>>>;
};

export type CloneFactSheetInput = {
  /** The id of the fact sheet to clone */
  id: Scalars['ID']['input'];
  /** The patches you want to perform on the cloned Fact Sheet */
  patches?: InputMaybe<Array<InputMaybe<Patch>>>;
  /** The options to apply when cloning */
  cloningOptions?: InputMaybe<CloningOptionsType>;
  /** Revision of the Fact Sheet to update. Nulling this value will result in a forced update. */
  rev?: InputMaybe<Scalars['Long']['input']>;
  /**
   * Don't persist the mutation, only check if validation passes. Must be the
   * same for all mutation fields in the GraphQL request!
   */
  validateOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CloningOptionsType = {
  cloneRelations?: InputMaybe<Scalars['Boolean']['input']>;
  cloneExternalIds?: InputMaybe<Scalars['Boolean']['input']>;
  cloneCreationDate?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateFactSheetPayload = {
  __typename?: 'UpdateFactSheetPayload';
  factSheetMutationResult?: Maybe<FactSheetMutationResult>;
};

export type UpdateFactSheetInput = {
  /** The id of the fact sheet to update */
  id: Scalars['ID']['input'];
  /** revision of the fact sheet to update. Nulling this value will result in a force update. */
  rev?: InputMaybe<Scalars['Long']['input']>;
  /** The patches you want to perform */
  patches: Array<InputMaybe<Patch>>;
  /** A comment to the mutation which is used for archiving and recovering of Fact Sheets */
  comment?: InputMaybe<Scalars['String']['input']>;
  /**
   * Don't persist the mutation, only check if validation passes. Must be the
   * same for all mutation fields in the GraphQL request!
   */
  validateOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateFactSheetExternalPayload = {
  __typename?: 'UpdateFactSheetExternalPayload';
  factSheetMutationResult?: Maybe<FactSheetMutationResult>;
};

export type UpdateFactSheetExternalInput = {
  /**
   * The external ID path of a Fact Sheet to update. The format is '<external-id
   * name>/<external-id id>[?type=FactSheetType]' like 'externalId/123'. In case
   * an external Id needs to be restricted to a FactSheet type, a query field
   * 'type' needs to be added, eg: 'externalId/123?type=Application'
   */
  externalIdPath: Scalars['ID']['input'];
  /** The patches you want to perform */
  patches: Array<InputMaybe<Patch>>;
  /** A comment to the mutation which is used for archiving and recovering of Fact Sheets */
  comment?: InputMaybe<Scalars['String']['input']>;
  /**
   * Don't persist the mutation, only check if validation passes. Must be the
   * same for all mutation fields in the GraphQL request!
   */
  validateOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateRelationConstraintPayload = {
  __typename?: 'CreateRelationConstraintPayload';
  baseFactSheet?: Maybe<BaseFactSheet>;
};

export type CreateRelationConstraintInput = {
  /** The id of the fact sheet that shall have a constrained relation */
  factSheetId: Scalars['ID']['input'];
  /** The type of the constrained relation */
  constrainedRelationType: Scalars['String']['input'];
  /** The id of the fact sheet the constrained relation points to */
  constrainedRelationTargetFactSheetId: Scalars['ID']['input'];
  /** The type of the constraining relation */
  constrainingRelationType: Scalars['String']['input'];
  /** The id of the fact sheet the constraining relation points to */
  constrainingRelationTargetFactSheetId: Scalars['ID']['input'];
  /**
   * Don't persist the mutation, only check if validation passes. Must be the
   * same for all mutation fields in the GraphQL request!
   */
  validateOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DeleteRelationConstraintPayload = {
  __typename?: 'DeleteRelationConstraintPayload';
  baseFactSheet?: Maybe<BaseFactSheet>;
};

export type DeleteRelationConstraintInput = {
  /** The id of the fact sheet that shall have a constrained relation */
  factSheetId: Scalars['ID']['input'];
  /** The type of the constrained relation */
  constrainedRelationType: Scalars['String']['input'];
  /** The id of the fact sheet the constrained relation points to */
  constrainedRelationTargetFactSheetId: Scalars['ID']['input'];
  /** The type of the constraininc relation */
  constrainingRelationType: Scalars['String']['input'];
  /** The id of the fact sheet the constraining relation points to */
  constrainingRelationTargetFactSheetId: Scalars['ID']['input'];
  /**
   * Don't persist the mutation, only check if validation passes. Must be the
   * same for all mutation fields in the GraphQL request!
   */
  validateOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MoveRelationsPayload = {
  __typename?: 'MoveRelationsPayload';
  baseFactSheet?: Maybe<BaseFactSheet>;
};

export type MoveRelationsInput = {
  /** The id of the fact sheet that shall have the relations added */
  factSheetId: Scalars['ID']['input'];
  /** The id of the fact sheet that shall have the relations removed */
  sourceFactSheetId: Scalars['ID']['input'];
  /** The type of the relations which shall be moved */
  relationType: Scalars['String']['input'];
  /**
   * Don't persist the mutation, only check if validation passes. Must be the
   * same for all mutation fields in the GraphQL request!
   */
  validateOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CopyRelationsPayload = {
  __typename?: 'CopyRelationsPayload';
  baseFactSheet?: Maybe<BaseFactSheet>;
};

export type CopyRelationsInput = {
  /** The id of the fact sheet that shall have the relations added */
  factSheetId: Scalars['ID']['input'];
  /** The id of the fact sheet to copy the relations from */
  sourceFactSheetId: Scalars['ID']['input'];
  /** The type of the relations which shall be copied */
  relationType: Scalars['String']['input'];
  /**
   * Don't persist the mutation, only check if validation passes. Must be the
   * same for all mutation fields in the GraphQL request!
   */
  validateOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateTagGroupPayload = {
  __typename?: 'CreateTagGroupPayload';
  tagGroup?: Maybe<TagGroup>;
};

export type CreateTagGroupInput = {
  /** The name of the new tag group. */
  name: Scalars['String']['input'];
  /** The short name (acronym) of the new tag group. */
  shortName?: InputMaybe<Scalars['String']['input']>;
  /** The description of the new tag group. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The mode of the new tag group. */
  mode: TagGroupModeEnum;
  /** All Fact Sheets applicable to this tag group must be tagged with at least one tag from the group. */
  mandatory?: InputMaybe<Scalars['Boolean']['input']>;
  /** If nonempty, tags in this tag group may be associated to fact sheets of the listed types only. */
  restrictToFactSheetTypes?: InputMaybe<Array<FactSheetType>>;
  /** A list of Fact Sheet types. The tag group will be mandatory for the defined types. */
  mandatoryForFactSheetTypes?: InputMaybe<Array<FactSheetType>>;
  /**
   * Don't persist the mutation, only check if validation passes. Must be the
   * same for all mutation fields in the GraphQL request!
   */
  validateOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateTagGroupPayload = {
  __typename?: 'UpdateTagGroupPayload';
  tagGroup?: Maybe<TagGroup>;
};

export type UpdateTagGroupInput = {
  /** ID of the tag group to modify */
  id: Scalars['ID']['input'];
  /** The patches to perform */
  patches: Array<InputMaybe<Patch>>;
  /**
   * Don't persist the mutation, only check if validation passes. Must be the
   * same for all mutation fields in the GraphQL request!
   */
  validateOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpsertTagGroupPayload = {
  __typename?: 'UpsertTagGroupPayload';
  tagGroup?: Maybe<TagGroup>;
};

export type UpsertTagGroupInput = {
  /** The name of the tag group */
  name: Scalars['String']['input'];
  /** The short name (acronym) of the tag group */
  shortName?: InputMaybe<Scalars['String']['input']>;
  /** The description of the tag group */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The mode of the tag group */
  mode?: InputMaybe<TagGroupModeEnum>;
  /** If true, then all Fact Sheets applicable to this tag group must be tagged with at least one tag from the tag group */
  mandatory?: InputMaybe<Scalars['Boolean']['input']>;
  /** If nonempty, tags in this tag group may be associated to fact sheets of the listed types only */
  restrictToFactSheetTypes?: InputMaybe<Array<FactSheetType>>;
  /** A list of Fact Sheet types. The tag group will be mandatory for the defined types. */
  mandatoryForFactSheetTypes?: InputMaybe<Array<FactSheetType>>;
  /**
   * Don't persist the mutation, only check if validation passes. Must be the
   * same for all mutation fields in the GraphQL request!
   */
  validateOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DeleteTagGroupPayload = {
  __typename?: 'DeleteTagGroupPayload';
  tagGroup?: Maybe<TagGroup>;
};

export type DeleteTagGroupInput = {
  /** ID of the tag group to delete. */
  id: Scalars['ID']['input'];
  /**
   * Don't persist the mutation, only check if validation passes. Must be the
   * same for all mutation fields in the GraphQL request!
   */
  validateOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateTagPayload = {
  __typename?: 'CreateTagPayload';
  tag?: Maybe<Tag>;
};

export type CreateTagInput = {
  /** The name of the new tag. */
  name: Scalars['String']['input'];
  /** The description of the new tag. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The color of the new tag. */
  color?: InputMaybe<Scalars['String']['input']>;
  /** Put the tag into this tag group. */
  tagGroupId?: InputMaybe<Scalars['ID']['input']>;
  /**
   * Don't persist the mutation, only check if validation passes. Must be the
   * same for all mutation fields in the GraphQL request!
   */
  validateOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpsertTagPayload = {
  __typename?: 'UpsertTagPayload';
  tag?: Maybe<Tag>;
};

export type UpsertTagInput = {
  /** The name of the tag */
  name: Scalars['String']['input'];
  /** The name of the tag group of the tag */
  tagGroupName?: InputMaybe<Scalars['String']['input']>;
  /** A description for the tag */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The hex code of the color of the tag */
  color?: InputMaybe<Scalars['String']['input']>;
  /**
   * Don't persist the mutation, only check if validation passes. Must be the
   * same for all mutation fields in the GraphQL request!
   */
  validateOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateTagPayload = {
  __typename?: 'UpdateTagPayload';
  tag?: Maybe<Tag>;
};

export type UpdateTagInput = {
  /** ID of the tag to update */
  id: Scalars['ID']['input'];
  /** The patches to perform */
  patches: Array<InputMaybe<Patch>>;
  /**
   * Don't persist the mutation, only check if validation passes. Must be the
   * same for all mutation fields in the GraphQL request!
   */
  validateOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DeleteTagPayload = {
  __typename?: 'DeleteTagPayload';
  tag?: Maybe<Tag>;
};

export type DeleteTagInput = {
  /** ID of the tag to delete */
  id: Scalars['ID']['input'];
  /**
   * Don't persist the mutation, only check if validation passes. Must be the
   * same for all mutation fields in the GraphQL request!
   */
  validateOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateDocumentPayload = {
  __typename?: 'CreateDocumentPayload';
  document?: Maybe<Document>;
};

export type CreateDocumentInput = {
  /** The ID of the Fact Sheet that the new document should be assigned to. */
  factSheetId: Scalars['ID']['input'];
  /** The revision of the Fact Sheet that the new document should be assigned to. */
  factSheetRev?: InputMaybe<Scalars['Long']['input']>;
  /** The name of the new document */
  name: Scalars['String']['input'];
  /** The description of the new document. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** The type of the new document. */
  documentType?: InputMaybe<Scalars['String']['input']>;
  /** Metadata in case the document is a Fact Sheet. */
  metadata?: InputMaybe<Scalars['String']['input']>;
  /**
   * The url in case that the document is not a file but a simple URL where the
   * document can be reached over the internet or intranet.
   */
  url?: InputMaybe<Scalars['String']['input']>;
  /** The origin of the document, e.g. a certain application, database or integration. */
  origin?: InputMaybe<Scalars['String']['input']>;
  /**
   * A field used to store the ID of the referenced object. E.g. in cases where
   * the document links to an object in another system.
   */
  refId?: InputMaybe<Scalars['String']['input']>;
  /**
   * Don't persist the mutation, only check if validation passes. Must be the
   * same for all mutation fields in the GraphQL request!
   */
  validateOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateDocumentPayload = {
  __typename?: 'UpdateDocumentPayload';
  document?: Maybe<Document>;
};

export type UpdateDocumentInput = {
  /** ID of the document to modify */
  id: Scalars['ID']['input'];
  /** The revision of the Fact Sheet in case that a Fact Sheet is assigned to the document. */
  factSheetRev?: InputMaybe<Scalars['Long']['input']>;
  /** The patches to perform */
  patches: Array<InputMaybe<Patch>>;
  /**
   * Don't persist the mutation, only check if validation passes. Must be the
   * same for all mutation fields in the GraphQL request!
   */
  validateOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DeleteDocumentPayload = {
  __typename?: 'DeleteDocumentPayload';
  document?: Maybe<Document>;
};

export type DeleteDocumentInput = {
  /** ID of the document that has to be deleted */
  id: Scalars['ID']['input'];
  /** The revision of the Fact Sheet in case that a Fact Sheet is assigned to the document. */
  factSheetRev?: InputMaybe<Scalars['Long']['input']>;
  /**
   * Don't persist the mutation, only check if validation passes. Must be the
   * same for all mutation fields in the GraphQL request!
   */
  validateOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateCommentPayload = {
  __typename?: 'CreateCommentPayload';
  comment?: Maybe<Comment>;
};

export type CreateCommentInput = {
  /** The Fact Sheet the comment belongs to. */
  factSheetId: Scalars['ID']['input'];
  /** The message of the comment. */
  message: Scalars['String']['input'];
  /** The status of the comment. */
  status: CommentStatus;
  /**
   * Don't persist the mutation, only check if validation passes. Must be the
   * same for all mutation fields in the GraphQL request!
   */
  validateOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DeleteCommentPayload = {
  __typename?: 'DeleteCommentPayload';
  boolean?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteCommentInput = {
  /** The ID of the comment to delete. */
  commentId?: InputMaybe<Scalars['ID']['input']>;
  /**
   * Don't persist the mutation, only check if validation passes. Must be the
   * same for all mutation fields in the GraphQL request!
   */
  validateOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateReplyPayload = {
  __typename?: 'CreateReplyPayload';
  reply?: Maybe<Reply>;
};

export type CreateReplyInput = {
  /** The ID of the comment to delete. */
  commentId?: InputMaybe<Scalars['ID']['input']>;
  /** The message. */
  message?: InputMaybe<Scalars['String']['input']>;
  /** New status for the comment */
  newCommentStatus?: InputMaybe<CommentStatus>;
  /**
   * Don't persist the mutation, only check if validation passes. Must be the
   * same for all mutation fields in the GraphQL request!
   */
  validateOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateSubscriptionPayload = {
  __typename?: 'CreateSubscriptionPayload';
  subscription?: Maybe<Subscription>;
};

export type CreateSubscriptionInput = {
  /** ID of the Fact Sheet the subscription should be assigned to. */
  factSheetId: Scalars['ID']['input'];
  /** Revision of the Fact Sheet the subscription should be assigned to. */
  factSheetRev?: InputMaybe<Scalars['Long']['input']>;
  /** The user to be subscribed. */
  user: UserInput;
  /** The subscription type. */
  type: FactSheetSubscriptionType;
  /** A list of subscription role ids that have to be assigned to the new subscription. */
  roleIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** A list of active subscription roles attached to the subscription. */
  roles?: InputMaybe<
    Array<InputMaybe<SubscriptionToSubscriptionRoleLinkInput>>
  >;
  /**
   * Don't persist the mutation, only check if validation passes. Must be the
   * same for all mutation fields in the GraphQL request!
   */
  validateOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Input type for users. */
export type UserInput = {
  /** The user's id. */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** The user's username. */
  userName?: InputMaybe<Scalars['String']['input']>;
  /** The user's email address. */
  email?: InputMaybe<Scalars['String']['input']>;
  /** The user's first name. */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** The user's last name. */
  lastName?: InputMaybe<Scalars['String']['input']>;
};

/** Input type for subscription to subscription role links. */
export type SubscriptionToSubscriptionRoleLinkInput = {
  /** The subscription role's id. */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** The comment on the link between subscription and subscription role. */
  comment?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateSubscriptionPayload = {
  __typename?: 'UpdateSubscriptionPayload';
  subscription?: Maybe<Subscription>;
};

export type UpdateSubscriptionInput = {
  /** ID of the subscription to modify. */
  id: Scalars['ID']['input'];
  /** The revision of the Fact Sheet the subscription is assigned on. */
  factSheetRev?: InputMaybe<Scalars['Long']['input']>;
  /** The user to be subscribed. */
  user: UserInput;
  /** The subscription type. */
  type: FactSheetSubscriptionType;
  /** A list of subscription role ids to be assigned to the new subscription (deprecated: use field 'roles' instead). */
  roleIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** A list of active subscription roles attached to the subscription. */
  roles?: InputMaybe<
    Array<InputMaybe<SubscriptionToSubscriptionRoleLinkInput>>
  >;
  /**
   * Don't persist the mutation, only check if validation passes. Must be the
   * same for all mutation fields in the GraphQL request!
   */
  validateOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DeleteSubscriptionPayload = {
  __typename?: 'DeleteSubscriptionPayload';
  baseFactSheet?: Maybe<BaseFactSheet>;
};

export type DeleteSubscriptionInput = {
  /** ID of the subscription that should be deleted */
  id: Scalars['ID']['input'];
  /** The revision of the Fact Sheet the subscription is assigned to. */
  factSheetRev?: InputMaybe<Scalars['Long']['input']>;
  /**
   * Don't persist the mutation, only check if validation passes. Must be the
   * same for all mutation fields in the GraphQL request!
   */
  validateOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DeleteSubscriptionsPayload = {
  __typename?: 'DeleteSubscriptionsPayload';
  baseFactSheet?: Maybe<BaseFactSheet>;
};

export type DeleteSubscriptionsInput = {
  /** The id of the fact sheet were all user subscriptions should be removed */
  factSheetId: Scalars['ID']['input'];
  /** The revision of the Fact Sheet the subscription is assigned to. */
  factSheetRev?: InputMaybe<Scalars['Long']['input']>;
  /**
   * Don't persist the mutation, only check if validation passes. Must be the
   * same for all mutation fields in the GraphQL request!
   */
  validateOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateSubscriptionRolePayload = {
  __typename?: 'CreateSubscriptionRolePayload';
  subscriptionRole?: Maybe<SubscriptionRole>;
};

export type CreateSubscriptionRoleInput = {
  /** Name of the subscription role. */
  name: Scalars['String']['input'];
  /** The description for the role. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Type of subscriptions the role should be used for. Null means usable on any subscription type. */
  type?: InputMaybe<FactSheetSubscriptionType>;
  /** A list of Fact Sheet types. The subscription role will only be available for the defined types */
  allowedFactSheetTypes?: InputMaybe<Array<FactSheetType>>;
  /** A list of Fact Sheet types. The subscription role will be mandatory for the defined types. */
  mandatoryForFactSheetTypes?: InputMaybe<Array<FactSheetType>>;
  /**
   * Don't persist the mutation, only check if validation passes. Must be the
   * same for all mutation fields in the GraphQL request!
   */
  validateOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateSubscriptionRolePayload = {
  __typename?: 'UpdateSubscriptionRolePayload';
  subscriptionRole?: Maybe<SubscriptionRole>;
};

export type UpdateSubscriptionRoleInput = {
  /** ID of the subscription role. */
  id: Scalars['ID']['input'];
  /** Name of the subscription role. */
  name: Scalars['String']['input'];
  /** The description for the role. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Type of subscriptions the role should be used for. Null means usable on any subscription type. */
  type?: InputMaybe<FactSheetSubscriptionType>;
  /** A list of Fact Sheet types. The subscription type will only be available for the defined types */
  allowedFactSheetTypes?: InputMaybe<Array<FactSheetType>>;
  /** A list of Fact Sheet types. The subscription role will be mandatory for the defined types. */
  mandatoryForFactSheetTypes?: InputMaybe<Array<FactSheetType>>;
  /**
   * Don't persist the mutation, only check if validation passes. Must be the
   * same for all mutation fields in the GraphQL request!
   */
  validateOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DeleteSubscriptionRolePayload = {
  __typename?: 'DeleteSubscriptionRolePayload';
  subscriptionRole?: Maybe<SubscriptionRole>;
};

export type DeleteSubscriptionRoleInput = {
  /** ID of the subscription role. */
  id: Scalars['ID']['input'];
  /**
   * Don't persist the mutation, only check if validation passes. Must be the
   * same for all mutation fields in the GraphQL request!
   */
  validateOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpsertRelationPayload = {
  __typename?: 'UpsertRelationPayload';
  upsertRelationResponse?: Maybe<UpsertRelationResponse>;
};

export type UpsertRelationResponse = {
  __typename?: 'UpsertRelationResponse';
  relation?: Maybe<BaseRelation>;
  /** The internal id of the 'from' Fact Sheet, no matter if that was selected via the same id or an external id value. */
  fromFactSheetId?: Maybe<Scalars['ID']['output']>;
};

export type UpsertRelationInput = {
  /** An identifier for the source Fact Sheet of the relation */
  from: FactSheetIdentifierType;
  /** An identifier for the target Fact Sheet of the relation */
  to: FactSheetIdentifierType;
  /** The type of the relation (e.g. 'relToParent') */
  type: RelationName;
  /** The patches to perform on the existing or newly created Fact Sheet directly after its creation */
  patches?: InputMaybe<Array<Patch>>;
  /**
   * Don't persist the mutation, only check if validation passes. Must be the
   * same for all mutation fields in the GraphQL request!
   */
  validateOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DeleteRelationPayload = {
  __typename?: 'DeleteRelationPayload';
  deleteRelationResponse?: Maybe<DeleteRelationResponse>;
};

export type DeleteRelationResponse = {
  __typename?: 'DeleteRelationResponse';
  relation?: Maybe<BaseRelation>;
};

export type DeleteRelationInput = {
  /** An identifier for the source Fact Sheet of the relation */
  from: FactSheetIdentifierType;
  /** An identifier for the target Fact Sheet of the relation */
  to: FactSheetIdentifierType;
  /** The type of the relation (e.g. 'relToParent') */
  type: RelationName;
  /**
   * Don't persist the mutation, only check if validation passes. Must be the
   * same for all mutation fields in the GraphQL request!
   */
  validateOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AssignEmployeeToProductsPayload = {
  __typename?: 'AssignEmployeeToProductsPayload';
  employeeType?: Maybe<EmployeeType>;
  errors?: Maybe<Array<AssignEmployeeToProductsError>>;
};

export type AssignEmployeeToProductsError =
  | EntityNotFoundError
  | OperationNotAllowedError
  | DataModelConsistencyError;

export type EntityNotFoundError = Error & {
  __typename?: 'EntityNotFoundError';
  message: Scalars['String']['output'];
};

export type Error = {
  message: Scalars['String']['output'];
};

export type OperationNotAllowedError = Error & {
  __typename?: 'OperationNotAllowedError';
  message: Scalars['String']['output'];
};

export type DataModelConsistencyError = Error & {
  __typename?: 'DataModelConsistencyError';
  message: Scalars['String']['output'];
};

export type AssignEmployeeToProductsInput = {
  employeeId: Scalars['Int']['input'];
  productsTcoInputs: Array<ProductTcoRolesInput>;
};

export type ProductTcoRolesInput = {
  productId: Scalars['String']['input'];
  tco: Scalars['Float']['input'];
  roles: Array<Role>;
};

export type AssignEmployeeToGeneralOverheadProductPayload = {
  __typename?: 'AssignEmployeeToGeneralOverheadProductPayload';
  employeeType?: Maybe<EmployeeType>;
  errors?: Maybe<Array<AssignEmployeeToGeneralOverheadProductError>>;
};

export type AssignEmployeeToGeneralOverheadProductError = EntityNotFoundError;

export type AssignEmployeeToGeneralOverheadProductInput = {
  employeeId: Scalars['Int']['input'];
  generalOverheadProductId: Scalars['String']['input'];
};

export type AssignEmployeeToProductWithoutTcoPayload = {
  __typename?: 'AssignEmployeeToProductWithoutTcoPayload';
  employeeType?: Maybe<EmployeeType>;
  errors?: Maybe<Array<AssignEmployeeToProductWithoutTcoError>>;
};

export type AssignEmployeeToProductWithoutTcoError =
  | EntityNotFoundError
  | OperationNotAllowedError;

export type AssignEmployeeToProductWithoutTcoInput = {
  employeeId: Scalars['Int']['input'];
  productId: Scalars['String']['input'];
  roles: Array<Role>;
};

export type UnassignEmployeeFromProductsPayload = {
  __typename?: 'UnassignEmployeeFromProductsPayload';
  employeeType?: Maybe<EmployeeType>;
  errors?: Maybe<Array<UnassignEmployeeFromProductsError>>;
};

export type UnassignEmployeeFromProductsError =
  | EntityNotFoundError
  | DataModelConsistencyError;

export type UnassignEmployeeFromProductsInput = {
  employeeId: Scalars['Int']['input'];
  productsIds: Array<Scalars['String']['input']>;
};

export type UnassignEmployeeFromProductWithoutTcoPayload = {
  __typename?: 'UnassignEmployeeFromProductWithoutTcoPayload';
  employeeType?: Maybe<EmployeeType>;
  errors?: Maybe<Array<UnassignEmployeeFromProductWithoutTcoError>>;
};

export type UnassignEmployeeFromProductWithoutTcoError =
  | EntityNotFoundError
  | DataModelConsistencyError;

export type UnassignEmployeeFromProductWithoutTcoInput = {
  employeeId: Scalars['Int']['input'];
  productId: Scalars['String']['input'];
};

export type UnassignRolesFromEmployeeProductPayload = {
  __typename?: 'UnassignRolesFromEmployeeProductPayload';
  employeeType?: Maybe<EmployeeType>;
  errors?: Maybe<Array<UnassignRolesFromEmployeeProductError>>;
};

export type UnassignRolesFromEmployeeProductError =
  | EntityNotFoundError
  | DataModelConsistencyError
  | OperationNotAllowedError;

export type UnassignRolesFromEmployeeProductInput = {
  employeeId: Scalars['Int']['input'];
  productId: Scalars['String']['input'];
  roles: Array<Role>;
};

export type AssignRolesToEmployeeProductPayload = {
  __typename?: 'AssignRolesToEmployeeProductPayload';
  employeeType?: Maybe<EmployeeType>;
  errors?: Maybe<Array<AssignRolesToEmployeeProductError>>;
};

export type AssignRolesToEmployeeProductError =
  | EntityNotFoundError
  | OperationNotAllowedError;

export type AssignRolesToEmployeeProductInput = {
  employeeId: Scalars['Int']['input'];
  productId: Scalars['String']['input'];
  roles: Array<Role>;
};

export type ModifyRolesForEmployeeProductPayload = {
  __typename?: 'ModifyRolesForEmployeeProductPayload';
  employeeType?: Maybe<EmployeeType>;
  errors?: Maybe<Array<ModifyRolesForEmployeeProductError>>;
};

export type ModifyRolesForEmployeeProductError =
  | EntityNotFoundError
  | OperationNotAllowedError;

export type ModifyRolesForEmployeeProductInput = {
  employeeId: Scalars['Int']['input'];
  productId: Scalars['String']['input'];
  rolesToRemove: Array<Role>;
  rolesToAdd: Array<Role>;
};

export type AssignProductToOpenPositionPayload = {
  __typename?: 'AssignProductToOpenPositionPayload';
  openPositionType?: Maybe<OpenPositionType>;
  errors?: Maybe<Array<AssignProductToOpenPositionError>>;
};

export type AssignProductToOpenPositionError = EntityNotFoundError;

export type AssignProductToOpenPositionInput = {
  positionId: Scalars['Int']['input'];
  productId: Scalars['String']['input'];
};

export type UnassignProductFromOpenPositionPayload = {
  __typename?: 'UnassignProductFromOpenPositionPayload';
  openPositionType?: Maybe<OpenPositionType>;
  errors?: Maybe<Array<UnassignProductFromOpenPositionError>>;
};

export type UnassignProductFromOpenPositionError =
  | EntityNotFoundError
  | DataModelConsistencyError;

export type UnassignProductFromOpenPositionInput = {
  positionId: Scalars['Int']['input'];
};

export type AssignGeneralOverheadProductToOpenPositionPayload = {
  __typename?: 'AssignGeneralOverheadProductToOpenPositionPayload';
  openPositionType?: Maybe<OpenPositionType>;
  errors?: Maybe<Array<AssignGeneralOverheadProductToOpenPositionError>>;
};

export type AssignGeneralOverheadProductToOpenPositionError =
  | EntityNotFoundError
  | DataModelConsistencyError;

export type AssignGeneralOverheadProductToOpenPositionInput = {
  positionId: Scalars['Int']['input'];
  generalOverheadProductId: Scalars['String']['input'];
};

export type UnassignGeneralOverheadProductFromOpenPositionPayload = {
  __typename?: 'UnassignGeneralOverheadProductFromOpenPositionPayload';
  openPositionType?: Maybe<OpenPositionType>;
  errors?: Maybe<Array<UnassignGeneralOverheadProductFromOpenPositionError>>;
};

export type UnassignGeneralOverheadProductFromOpenPositionError =
  | EntityNotFoundError
  | DataModelConsistencyError;

export type UnassignGeneralOverheadProductFromOpenPositionInput = {
  positionId: Scalars['Int']['input'];
};
