import {
  AutocompleteDropdown,
  Spinner,
} from '@lego/plugin-baseplate-core-components';
import React, { useEffect, useState } from 'react';
// fetch
import {
  discoveryApiRef,
  fetchApiRef,
  useApi,
} from '@backstage/core-plugin-api';
// to make it valid field extension
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';

export const CustomExtensionForServersDropdown = ({
  onChange,
  rawErrors,
  required,
  formData,
}: FieldExtensionComponentProps<string>) => {
  // fetch
  const discoveryApi = useApi(discoveryApiRef);
  const fetchAPI = useApi(fetchApiRef);
  // loading
  const [loading, setLoading] = useState<boolean>(false);
  // virtual machine response
  const [virtualMachines, setVirtualMachines] = useState([]);

  // fetching all virtual machines
  const getAllVms = async () => {
    setLoading(true);

    const baseUrl = await discoveryApi.getBaseUrl('server-access-form');
    const endpoint = `${baseUrl}/all-virtual-machines`;
    const resp = await fetchAPI.fetch(endpoint);

    if (!resp.ok) {
      setLoading(false);
      return resp.status;
    }

    const result = await resp.json();
    setVirtualMachines(result.data);

    setLoading(false);
    return result;
  };

  // when component loads, call getAllVms() function
  useEffect(() => {
    void getAllVms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <AutocompleteDropdown
          required={required}
          options={virtualMachines}
          label="Virtual Machines"
          onChange={(_, value) => {
            onChange(value);
          }}
          error={rawErrors?.length > 0 && !formData}
        />
      )}
    </>
  );
};
