import {
  ErrorApi,
  ErrorApiError,
  ErrorApiErrorContext,
} from '@backstage/core-plugin-api';
import { Observable } from '@backstage/types';
import { toast } from 'react-toastify';

/**
 * Api overriding the error reporting system.
 *
 * @public
 */
export class BaseplateErrorApi implements ErrorApi {
  error$(): Observable<{
    error: ErrorApiError;
    context?: ErrorApiErrorContext | undefined;
  }> {
    throw new Error('Method not implemented.');
  }

  post(
    error: ErrorApiError,
    _context?: ErrorApiErrorContext | undefined,
  ): void {
    toast(error.message, { autoClose: 15000, type: 'error' });
  }
}
