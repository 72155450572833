/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  EntityTextFilter,
  useEntityList,
} from '@backstage/plugin-catalog-react';
import { Icon, Input } from '@lego/plugin-baseplate-core-components';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useMemo, useState } from 'react';
import useDebounce from 'react-use/lib/useDebounce';

/** @public */

const useStyles = makeStyles(_theme => ({
  searchToolbar: {
    paddingLeft: 0,
    paddingRight: 0,
    width: '100%',
  },
  input: {},
}));

interface Props {
  placeholder: string;
}

/**
 * Renders search bar for filtering the entity list.
 * @public
 */
export const EntitySearchBar = ({ placeholder }: Props) => {
  const classes = useStyles();

  const {
    updateFilters,
    queryParameters: { text: textParameter },
  } = useEntityList();

  const queryParamTextFilter = useMemo(
    () => [textParameter].flat()[0],
    [textParameter],
  );

  const [search, setSearch] = useState(queryParamTextFilter ?? '');

  useDebounce(
    () => {
      updateFilters({
        text: search.length ? new EntityTextFilter(search) : undefined,
      });
    },
    150,
    [search, updateFilters],
  );

  useEffect(() => {
    if (queryParamTextFilter) {
      setSearch(queryParamTextFilter);
    }
  }, [queryParamTextFilter]);

  return (
    <FormControl className={classes.searchToolbar}>
      <Input
        required
        InputProps={{
          startAdornment: <Icon icon="magnifying-glass" />,
        }}
        label="Search"
        id="input-with-icon-adornment"
        className={classes.input}
        placeholder={placeholder}
        autoComplete="off"
        onChange={event => setSearch(event.target.value)}
        value={search}
      />
    </FormControl>
  );
};
