/* eslint-disable */ // TODO remove this
import {
  BaseplateMarkdownContent,
  Spinner,
} from '@lego/plugin-baseplate-core-components';
import {
  LangChainMessage,
  Role,
  UserMessage,
} from '@lego/plugin-baseplate-ai-common';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useEffect } from 'react';
import { BackstageTheme } from '@backstage/theme';
import { BaseplateAnalyticsEventAttributes } from '@lego/plugin-baseplate-common';
import { useAnalytics } from '@backstage/core-plugin-api';

export type MessageProps = {
  currentResponse: string;
  messages: (LangChainMessage | UserMessage)[];
  statusCode?: number;
  loading: boolean;
};

const useStyles = makeStyles<BackstageTheme>(theme => ({
  message: {
    padding: '1rem',
    fontSize: '1rem',
    maxWidth: '80%',
    width: 'fit-content',
    margin: '12px 5% 12px auto',
    gap: '8px',
    '& *': {
      margin: 0,
    },
  },
  Human: {
    backgroundColor: theme.palette.banner.info,
    color: theme.palette.getContrastText(theme.palette.banner.info),
    borderRadius: '8px 8px 0 8px',
  },
  AI: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.getContrastText(theme.palette.background.default),
    borderRadius: '8px 8px 8px 0',
    marginRight: 'auto',
    marginLeft: '5%',
  },
  error: {
    padding: '8px',
    gap: '8px',
    marginLeft: 'auto',
    marginRight: '5%',
    marginBottom: '24px',
    backgroundColor: '#FFBABA',
    borderRadius: '8px',
    width: 'fit-content',
    maxWidth: '80%',
  },
}));

const getStatusCodeDescription = (statusCode: number): string => {
  let statusCodeDescription =
    'Something went wrong! Please refresh and try again. If this continues, please reach out to us.';

  switch (statusCode) {
    case 200:
      statusCodeDescription = 'ok';
      break;
    case 400:
      statusCodeDescription =
        "Your request and the AI's response is too large for the selected model. Try again with a smaller request or select another model";
      break;
    case 401:
      statusCodeDescription = 'Your request is unauthorized.';
      break;
    case 413:
      statusCodeDescription =
        'Your request is too large. Try again with a smaller request or select another model';
      break;
    case 429:
      statusCodeDescription =
        'You have exceeded the request limit. Please try again later, or select another model.';
      break;
  }

  return statusCodeDescription;
};

export const ChatLine = ({
  currentResponse,
  messages,
  statusCode,
  loading,
}: MessageProps) => {
  const classes = useStyles();
  const messagesEndRef = React.useRef<HTMLInputElement>(null);
  const analytics = useAnalytics();

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    if (messages.length > 0) {
      if (statusCode && messages[messages.length - 1].role === Role.AI) {
        analytics.captureEvent('status', 'http_status_code', {
          attributes: {
            key: 'http_status_code',
            'overwrites.derived.plugin_identifier': 'ai', // because if used as modal window the path is not /ai
            'overwrites.derived.plugin_subidentifier': 'undetermined',
            value: statusCode,
            valueDesc: getStatusCodeDescription(statusCode),
          } as BaseplateAnalyticsEventAttributes,
        });
      }
    }
  }, [statusCode, messages]);

  useEffect(() => {
    if (statusCode) {
      if (statusCode !== 200) {
        messages.push({
          content: getStatusCodeDescription(statusCode),
          role: Role.AI,
          error: true,
        });
      }
    }
  }, [statusCode]);

  return (
    <>
      {messages &&
        messages.length > 0 &&
        messages.map((message, index) => (
          <div
            key={index}
            className={[
              message.error ? classes.error : classes[messages[index].role],
              classes.message,
            ].join(' ')}
          >
            <BaseplateMarkdownContent
              content={message.content}
              linkTarget={'_blank'}
            />
          </div>
        ))}
      {loading && (
        <div className={[classes.AI, classes.message].join(' ')}>
          <BaseplateMarkdownContent content={currentResponse} />
          {loading && <Spinner size="small" width={30} align="center" />}
        </div>
      )}
      <div ref={messagesEndRef} />
    </>
  );
};
