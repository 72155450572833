import { EntitySwitch, isComponentType } from '@backstage/plugin-catalog';
import React from 'react';
import {
  serviceEntityPage,
  websiteEntityPage,
  defaultEntityPage,
} from '../ContentSections';

const ComponentPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isComponentType(['service', 'plugin', 'repo'])}>
      {serviceEntityPage}
    </EntitySwitch.Case>

    <EntitySwitch.Case if={isComponentType('website')}>
      {websiteEntityPage}
    </EntitySwitch.Case>

    <EntitySwitch.Case>{defaultEntityPage}</EntitySwitch.Case>
  </EntitySwitch>
);
export default ComponentPage;
