import { Icon, baseplateStyles } from '@lego/plugin-baseplate-core-components';
import { IconButton, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { type ReactElement } from 'react';
import { Parameter } from './Parameter';
import { UiParameterType } from '../../../types';

const useStyles = baseplateStyles(theme => ({
  wrapper: {
    borderRadius: theme.primitive.borderRadius.default,
    border: `1px solid ${theme.semantic.border.default}`,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.5rem 1rem',
  },
  headerText: {
    display: 'flex',
    alignItems: 'baseline',
    gap: '0.5rem',
    flexGrow: 1,
    '& span': {
      textTransform: 'capitalize',
    },
  },
  parametersWrapper: {
    padding: '1rem',
    borderTop: `1px solid ${theme.semantic.border.default}`,
  },
  table: {
    borderSpacing: 0,
  },
  requiredHelper: {
    '& span': {
      color: theme.semantic.shape.error,
    },
  },
  open: {
    transform: 'rotate(180deg)',
  },
}));

export function ParameterGroup({
  name,
  parameters,
  initialState,
}: {
  name: string;
  parameters: UiParameterType[];
  initialState?: boolean;
}): ReactElement {
  const classes = useStyles();
  const [open, setOpen] = useState(initialState);
  const toggleOpen = () => {
    setOpen(prev => !prev);
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <div className={classes.headerText}>
          <Typography variant="subtitle2">{name}</Typography>
          <Typography variant="caption" color="secondary">
            ({parameters.length})
          </Typography>
        </div>
        <IconButton onClick={toggleOpen} className={open ? classes.open : ''}>
          <Icon icon="chevron-down" />
        </IconButton>
      </div>
      {open && (
        <div className={classes.parametersWrapper}>
          <table className={classes.table}>
            <tbody>
              {parameters.map(parameter => (
                <Parameter {...parameter} />
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}
