import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Underline(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M7 7.5C7 6.687 7.625 6 8.5 6h6c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5H13v9c0 3.875 3.125 7 7 7 3.813 0 7-3.125 7-7V9h-1.5c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5h6c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5H30v9c0 5.563-4.5 10-10 10a9.95 9.95 0 0 1-10-10V9H8.5C7.625 9 7 8.375 7 7.5Zm-1 25c0-.813.625-1.5 1.5-1.5h25c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5h-25c-.875 0-1.5-.625-1.5-1.5Z" />
    </SvgIcon>
  );
}
