import React from 'react';
import {
  SearchModalResultItemProps,
  SearchModalResultItemWrapper,
} from '../SearchModalResultItemWrapper';
import { Entity } from '@backstage/catalog-model';
import { EntityLink } from '@lego/plugin-baseplate-core-components';

type Props = {
  entity: Entity;
} & SearchModalResultItemProps;

export const SearchModalEntityResultItem = ({ entity, ...props }: Props) => {
  return (
    <SearchModalResultItemWrapper {...props}>
      <EntityLink entity={entity} variant="link" />
    </SearchModalResultItemWrapper>
  );
};
