import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function FileQuery(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M17.346 18.7h.666c.51 0 .966.426.966.943 0 .543-.463.943-.966.943h-.666a.36.36 0 0 0-.366.343v.763c0 .591-.268 1.17-.695 1.582l-.24.23.242.244a2.2 2.2 0 0 1 .693 1.58v.743c0 .186.147.343.366.343h.666c.51 0 .966.426.966.943 0 .543-.463.943-.966.943h-.666c-1.259 0-2.298-.988-2.298-2.229v-.743a.384.384 0 0 0-.117-.246l-.932-.9a.905.905 0 0 1 0-1.344l.932-.9a.385.385 0 0 0 .117-.246v-.764c0-1.24 1.04-2.228 2.298-2.228Zm7.725 3.22.932.92a.905.905 0 0 1-.001 1.342l-.933.9a.325.325 0 0 0-.096.246v.743c0 1.24-1.04 2.229-2.298 2.229h-.666c-.53 0-.966-.406-.966-.943a.96.96 0 0 1 .966-.943h.666c.204 0 .366-.163.366-.343v-.743c0-.604.241-1.162.672-1.58l.242-.243-.24-.231a2.214 2.214 0 0 1-.674-1.582v-.764c0-.165-.168-.342-.366-.342h-.666c-.53 0-.966-.406-.966-.943a.96.96 0 0 1 .966-.943h.666c1.259 0 2.298.988 2.298 2.229v.763a.31.31 0 0 0 .096.226l.002.002Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 4C9.75 4 8 5.813 8 8v24c0 2.25 1.75 4 4 4h16c2.188 0 4-1.75 4-4V13.687a3.976 3.976 0 0 0-1.188-2.812l-5.687-5.688A3.976 3.976 0 0 0 22.312 4H12Zm17 28c0 .563-.5 1-1 1H12c-.563 0-1-.438-1-1V8c0-.5.438-1 1-1h10v5c0 1.125.875 2 2 2h5v18Z"
      />
    </SvgIcon>
  );
}
