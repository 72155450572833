import { DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';
import { ResponseError } from '@backstage/errors';
import {
  Banner,
  BannerCreateParams,
  BaseplateVersion,
} from '@lego/plugin-baseplate-admin-common';
import { AdminApi } from './AdminApi';

export class AdminClient implements AdminApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi: FetchApi;

  public constructor(options: {
    discoveryApi: DiscoveryApi;
    fetchApi: FetchApi;
  }) {
    this.discoveryApi = options.discoveryApi;
    this.fetchApi = options.fetchApi;
  }
  async getCurrentBaseplateVersion(): Promise<BaseplateVersion> {
    const url = await this.getUrl('baseplate-version');
    const response = await this.fetchApi.fetch(url.toString());
    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }
    return response.json();
  }

  async getBanners(): Promise<Banner[]> {
    const url = await this.getUrl('banners');
    const response = await this.fetchApi.fetch(url.toString());

    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }
    const banners = await response.json();
    return banners;
  }

  async createBanner(banner: BannerCreateParams): Promise<Banner> {
    const url = await this.getUrl('banners');
    const response = await this.fetchApi.fetch(url.toString(), {
      method: 'POST',
      body: JSON.stringify({ ...banner }),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }

    return response.json();
  }

  async getBannerById(id: string): Promise<Banner | undefined> {
    const url = await this.getUrl(`banners/${id}`);
    const response = await this.fetchApi.fetch(url.toString());

    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }

    const banner = await response.json();
    // Fetch and populate the associated page paths
    banner.pages = await this.getBannerPages(id);

    return banner;
  }

  async deleteBannerById(id: string): Promise<void> {
    const url = await this.getUrl(`banners/${id}`);
    const response = await this.fetchApi.fetch(url.toString(), {
      method: 'DELETE',
    });

    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }
  }

  async updateBannerById(
    id: string,
    banner: BannerCreateParams,
  ): Promise<Banner> {
    const url = await this.getUrl(`banners/${id}`);
    const response = await this.fetchApi.fetch(url.toString(), {
      method: 'PUT',
      body: JSON.stringify({ ...banner }),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }

    return response.json();
  }

  async getBannerPages(id: string): Promise<string[]> {
    const url = await this.getUrl(`banners/${id}/pages`);
    const response = await this.fetchApi.fetch(url.toString());

    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }

    return response.json();
  }

  getUrl = async (path: string): Promise<URL> => {
    const baseUrl = `${await this.discoveryApi.getBaseUrl('admin')}/`;
    return new URL(path, baseUrl);
  };
}
