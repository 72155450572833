import React from 'react';
import { type ReactElement } from 'react';
import { DevModePanelFieldType } from './types';
import {
  Icon,
  Spinner,
  baseplateStyles,
  semantic,
} from '@lego/plugin-baseplate-core-components';
import { Link } from '@backstage/core-components';

const useStyles = baseplateStyles(theme => ({
  field: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    fontSize: '1rem',
  },
  label: {
    flexGrow: 1,
    textDecoration: 'none',
    color: semantic.dark.text.primary,
    '&:hover': {
      textDecoration: 'none',
      color: semantic.dark.text.secondary,
    },
  },
}));

export function DevModePanelField({
  label,
  value,
  status,
  href,
}: DevModePanelFieldType): ReactElement {
  const classes = useStyles();
  return (
    <div className={classes.field}>
      {status === 'error' && <Icon icon="status-triangle-exclamation" />}
      {status === 'warning' && <Icon icon="status-triangle-exclamation" />}
      {href ? (
        <Link to={href} className={classes.label} underline="none">
          {label}
        </Link>
      ) : (
        <p className={classes.label}>{label}</p>
      )}
      <p>
        {status === 'loading' && <Spinner type="circle" size="small" />}
        {status !== 'loading' && href && (
          <Link to={href} className={classes.label} underline="none">
            {value}
          </Link>
        )}
        {status !== 'loading' && !href && (
          <span className={classes.label}>{value}</span>
        )}
      </p>
    </div>
  );
}
