import { baseplateStyles } from '../../utils';

export const useInputStyles = baseplateStyles(theme => ({
  root: {
    '& > label': {
      display: 'block',
    },
    '& .v5-MuiInputBase-root': {
      fontFamily: theme.typography.fontFamily,
      borderRadius: theme.primitive.borderRadius.default,
      backgroundColor: theme.semantic.background.neutral[1],
    },
    '& .v5-MuiInputBase-multiline': {
      padding: 0,
    },
    '& .v5-MuiInputBase-input': {
      color: theme.semantic.text.primary,
      display: 'flex',
      alignItems: 'center',
      minHeight: '2.5rem',
      borderColor: theme.semantic.border.default,
      padding: '0 1rem',
      '&::placeholder': {
        color: theme.semantic.text.tertiary,
        opacity: 1,
      },
    },
    // Special styling for textarea inputs
    '& textarea.v5-MuiInputBase-input': {
      padding: '0.5rem 1rem',
    },
    '& .v5-MuiOutlinedInput-notchedOutline': {
      borderColor: theme.semantic.border.default,
      transitionProperty: 'border-color, box-shadow',
      transitionDuration: theme.transitions.duration.standard,
      transitionTimingFunction: theme.transitions.easing.easeInOut,
    },
    '& .v5-MuiInputBase-input:hover ~ .v5-MuiOutlinedInput-notchedOutline': {
      borderColor: theme.semantic.border.hover,
    },

    // Border colors:
    // Focus
    '& .v5-MuiInputBase-input:focus ~ .v5-MuiOutlinedInput-notchedOutline': {
      borderColor: theme.semantic.border.info,
      boxShadow: `0 0 0 2px ${theme.semantic.border.info}80`,
    },
    // Error
    '&$error .v5-MuiInputBase-root .v5-MuiOutlinedInput-notchedOutline': {
      borderColor: theme.semantic.border.error,
      boxShadow: `0 0 0 2px ${theme.semantic.border.error}80`,
    },
    // Success
    '&$success .v5-MuiInputBase-root .v5-MuiOutlinedInput-notchedOutline': {
      borderColor: theme.semantic.border.success,
      boxShadow: `0 0 0 2px ${theme.semantic.border.success}80`,
    },
    '& .v5-MuiSvgIcon-root': {
      color: theme.semantic.icon.secondary,
    },
    // Icon styling
    '& .v5-MuiInputBase-root span': {
      color: theme.semantic.icon.secondary,
      display: 'inline-flex',
      alignItems: 'center',
    },
  },
  descriptiveTextContainer: {
    padding: '0 1rem',
  },
  helperText: {
    color: theme.semantic.text.secondary,
  },
  errorText: {
    color: theme.semantic.text.error,
  },
  successText: {
    color: theme.semantic.text.success,
  },

  // Small styling
  small: {
    '& .v5-MuiInputBase-root': {
      height: '1.5rem',
    },
    '& .v5-MuiInputBase-input': {
      ...theme.typography.body2,
      padding: '0 0.5rem',
    },
  },

  // Declaring classes, supposed to be empty
  error: {},
  success: {},
  loadingIcon: {},
}));
