import {
  getCompoundEntityRef,
  stringifyEntityRef,
} from '@backstage/catalog-model';
import { ChatIcon, Link } from '@backstage/core-components';
import { useRouteRef } from '@backstage/core-plugin-api';
import {
  entityRouteRef,
  useEntity,
  useRelatedEntities,
} from '@backstage/plugin-catalog-react';
import {
  EmployeeEntity,
  RELATION_COMPETENCY_LEAD_OF,
} from '@lego/plugin-baseplate-common';
import {
  Card,
  Chip,
  Spinner,
  baseplateStyles,
} from '@lego/plugin-baseplate-core-components';
import {
  RELATION_LEAD_ENGINEER_OF,
  RELATION_PRODUCT_DESIGNER_OF,
  RELATION_PRODUCT_MANAGER_OF,
} from '@lego/plugin-baseplate-common';
import { Tooltip, Typography } from '@material-ui/core';
import React from 'react';
import { LegoFigureHead } from '@lego/plugin-baseplate-core-components';

import { Role } from '@lego/plugin-baseplate-eagraph-common';

const useStyles = baseplateStyles(theme => ({
  list: {
    padding: 0,
  },
  item: {
    listStyleType: 'none',
    marginBottom: '0.25rem',
  },
  name: {
    marginBottom: '-4px',
  },
  picture: {
    width: 32,
    height: 32,
    backgroundColor: theme.primitive.color.warning[200],
    borderRadius: theme.primitive.borderRadius.full,
  },
  text: {
    flexGrow: 1,
  },
  linkWrapper: {
    borderRadius: theme.primitive.borderRadius.default,
    display: 'flex',
    gap: '1rem',
  },
  link: {
    borderRadius: theme.primitive.borderRadius.default,
    display: 'flex',
    gap: '1rem',
    flexGrow: 1,
    alignItems: 'center',
    margin: 0,
    padding: '0.25rem',
    '&:hover': {
      backgroundColor: theme.semantic.background.neutral[2],
      borderColor: theme.semantic.border.hover,
      textDecoration: 'none',
    },
  },
  teamsLink: {
    width: 48,
    height: 48,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.primitive.borderRadius.full,
    color: theme.semantic.icon.primary,
    '&:hover': {
      backgroundColor: theme.semantic.background.neutral[1],
      color: theme.semantic.icon.primary,
    },
  },
}));

const PeopleItem = (user: EmployeeEntity) => {
  const classes = useStyles();
  const { entity } = useEntity();
  const entityRef = stringifyEntityRef(entity);

  const { position, profile } = user.spec;

  const relations =
    user.relations?.filter(r => r.targetRef === entityRef) ?? [];

  const hasRole = (roleType: string) => {
    return relations.some(
      e => e.type === roleType && e.targetRef === entityRef,
    );
  };

  const roles = [
    ...(hasRole(RELATION_PRODUCT_DESIGNER_OF) ? ['Designer'] : []),
    ...(hasRole(RELATION_LEAD_ENGINEER_OF) ? ['Lead Engineer'] : []),
    ...(hasRole(RELATION_PRODUCT_MANAGER_OF) ? ['Product Manager'] : []),
    ...(hasRole(RELATION_COMPETENCY_LEAD_OF) ? ['Competency Lead'] : []),
  ];

  const entityRoute = useRouteRef(entityRouteRef);
  const ref = getCompoundEntityRef(user);

  const teamsLink = profile?.email
    ? `https://teams.microsoft.com/l/chat/0/0?users=${profile.email}`
    : 'https://teams.microsoft.com';

  return (
    <li className={classes.item}>
      <div className={classes.linkWrapper}>
        <Link
          className={classes.link}
          to={entityRoute({ ...ref })}
          style={{ display: 'flex' }}
        >
          <img
            src={user.spec?.profile?.picture ?? LegoFigureHead}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = LegoFigureHead;
            }}
            className={classes.picture}
            alt="profile"
          />
          <div className={classes.text}>
            <Typography variant="subtitle2" className={classes.name}>
              {user.spec?.profile?.displayName}{' '}
              {roles &&
                roles.map(r => (
                  <Chip key={r} label={r} style={{ marginLeft: 4 }} />
                ))}
            </Typography>
            {position && (
              <Typography variant="overline" color="secondary">
                {position}
              </Typography>
            )}
          </div>
        </Link>
        <Tooltip title="Open Teams chat">
          <Link
            className={classes.teamsLink}
            to={teamsLink}
            onClick={e => e.stopPropagation()}
          >
            <ChatIcon />
          </Link>
        </Tooltip>
      </div>
    </li>
  );
};

export const ProductEmployeesList = () => {
  const classes = useStyles();

  const { entity } = useEntity();
  const entityRef = stringifyEntityRef(entity);

  const { entities, error, loading } = useRelatedEntities(entity, {
    type: 'hasMember',
  });

  const users = (entities as EmployeeEntity[]) ?? [];

  const sortedUsers = users?.sort((a, b) => {
    const aProduct = a.spec.products?.find(p => p.entityRef === entityRef);
    const bProduct = b.spec.products?.find(p => p.entityRef === entityRef);

    const aHasRolesOtherThanProductMember =
      aProduct && aProduct.roles.some(r => r !== Role.ProductMember);
    const bHasRolesOtherThanProductMember =
      bProduct && bProduct.roles.some(r => r !== Role.ProductMember);

    if (aHasRolesOtherThanProductMember && !bHasRolesOtherThanProductMember) {
      return -1;
    }

    if (!aHasRolesOtherThanProductMember && bHasRolesOtherThanProductMember) {
      return 1;
    }

    const aDisplayName = a.spec?.profile?.displayName ?? '';
    const bDisplayName = b.spec?.profile?.displayName ?? '';

    return aDisplayName.localeCompare(bDisplayName);
  });

  if (loading) {
    return <Spinner />;
  }

  if (error || !users) {
    return <></>;
  }

  const countOfUsers =
    entity.relations?.filter(r => r.type === 'hasMember').length ?? 0;

  return (
    <Card title={`People (${countOfUsers})`}>
      <ul className={classes.list}>
        {sortedUsers.map((user, index) => (
          <PeopleItem key={index} {...user} />
        ))}
      </ul>
    </Card>
  );
};
