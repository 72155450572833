import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function StackedImages(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M12 9c-.563 0-1 .5-1 1v14c0 .563.438 1 1 1h.938l4.312-5.875c.313-.375.75-.625 1.188-.625.5 0 .937.25 1.25.625l.75 1.063 3.25-5c.312-.376.75-.688 1.312-.688.5 0 .938.313 1.25.688L32.625 25H34c.5 0 1-.438 1-1V10c0-.5-.5-1-1-1H12Zm-4 1c0-2.188 1.75-4 4-4h22c2.188 0 4 1.813 4 4v14c0 2.25-1.813 4-4 4H12c-2.25 0-4-1.75-4-4V10Zm-3 1.5v14c0 3.063 2.438 5.5 5.5 5.5h20c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5h-20C5.75 34 2 30.25 2 25.5v-14c0-.813.625-1.5 1.5-1.5.813 0 1.5.688 1.5 1.5ZM18 13c0 1.125-.938 2-2 2-1.125 0-2-.875-2-2 0-1.063.875-2 2-2 1.063 0 2 .938 2 2Z" />
    </SvgIcon>
  );
}
