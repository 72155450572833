import {
  EntityLink,
  OverviewPageCardGrid,
  Spinner,
} from '@lego/plugin-baseplate-core-components';
import { Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import { Entity } from '@backstage/catalog-model';
import { useEntityList } from '@backstage/plugin-catalog-react';

type EntityWithKind = Entity & { relations: [{ target: { kind: string } }] };

const useStyles = makeStyles(() => ({
  categoryHeader: {
    margin: '1rem 0',
    textTransform: 'capitalize',
  },
}));

export const DocumentationEntitiesList = () => {
  const { entities: allEntities, loading: allEntitiesLoading } =
    useEntityList();
  const allEntitiesWithKinds = allEntities as EntityWithKind[];

  const classes = useStyles();

  if (allEntitiesLoading) {
    return <Spinner />;
  }

  const kinds = new Set<string>();
  allEntitiesWithKinds.forEach(entity => {
    if (
      entity.relations &&
      Array.isArray(entity.relations) &&
      entity.relations.length > 0
    ) {
      kinds.add(entity.relations[0].target.kind);
    }
  });

  const entitiesByKind = Array.from(kinds).map(kind => {
    const categorizedEntities = allEntitiesWithKinds.filter(
      entity =>
        entity.relations &&
        Array.isArray(entity.relations) &&
        entity.relations.length > 0 &&
        entity.relations[0].target.kind === kind,
    );
    return { kind, categorizedEntities };
  });

  const sortCategories = (a: { kind: string }) => {
    if (a.kind === 'group') {
      return -1;
    }
    return 0;
  };

  const prioritizeDocumentation = (a: Entity) => {
    const prioritizedDocumentation = ['engineering-matters'];
    if (prioritizedDocumentation.includes(a.metadata.name)) {
      return -1;
    }
    return 0;
  };

  return (
    <>
      {entitiesByKind
        .sort(sortCategories)
        .map(({ kind, categorizedEntities }) => (
          <React.Fragment key={kind}>
            <Typography variant="subtitle2" className={classes.categoryHeader}>
              {kind}
            </Typography>
            <OverviewPageCardGrid>
              {categorizedEntities.sort(prioritizeDocumentation).map(entity => (
                <EntityLink
                  entity={entity}
                  key={entity.metadata.name}
                  showFavoriteButton
                  variant="thumbnail"
                  isTechdocs
                />
              ))}
            </OverviewPageCardGrid>
          </React.Fragment>
        ))}
    </>
  );
};
