import React from 'react';
import { useFormStepStyles } from './FormStep.styles';

export type StepType = {
  route: string;
  component: React.ReactNode;
};

interface Props {
  children: React.ReactNode;
}

export const FormStepWrapper = ({ children }: Props) => {
  const classes = useFormStepStyles();

  return <div className={classes.formStepContainer}>{children}</div>;
};
