import { MessageExampleObject } from '@asyncapi/parser/esm/spec-types/v2';
import { UiExampleType, UiParameterType } from '../../../types';
import { stringifyJSON } from '../../../utils/stringifyJSON';
import { formatParameterArray } from '../../utils/formatParameterArray';

export const createMessageExamples = (
  payload: UiParameterType[],
  example?: MessageExampleObject[],
): UiExampleType[] => {
  const examples: UiExampleType[] = [];

  example?.forEach((e, index) => {
    examples.push({
      label: e.name || `Example ${index + 1}`,
      language: 'json',
      value: stringifyJSON(e.payload),
    });
  });

  examples.push({
    label: 'Generated example',
    language: 'json',
    value: stringifyJSON(formatParameterArray(payload)),
  });

  return examples;
};
