import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { AppContext, useApp } from '@backstage/core-plugin-api';

export function getKindIcon(app: AppContext, kind: string | undefined) {
  return (
    app.getSystemIcon(`kind:${kind?.toLocaleLowerCase('en-US')}`) ?? SvgIcon
  );
}

export function EntityKindIcon({
  kind,
  ...props
}: {
  kind: string;
  x?: number;
  y?: number;
  width?: number;
  height?: number;
  className?: string;
}) {
  const app = useApp();
  const Icon = getKindIcon(app, kind);
  return <Icon {...props} />;
}
