import { primitive } from './primitive';

type SemanticTokens = {
  background: {
    neutral: {
      1: string;
      2: string;
      3: string;
      4: string;
    };
    success: string;
    error: string;
    warning: string;
    info: string;
    code: string;
  };
  text: {
    primary: string;
    secondary: string;
    tertiary: string;
    success: string;
    error: string;
    warning: string;
    info: string;
    code: {
      generic: string;
      function: string;
      string: string;
      keyword: string;
      number: string;
      punctuation: string;
      comment: string;
    };
  };
  border: {
    default: string;
    hover: string;
    success: string;
    error: string;
    warning: string;
    info: string;
  };
  icon: {
    primary: string;
    secondary: string;
    duotone: {
      layer: {
        1: string;
        2: string;
        3: string;
      };
    };
    negative: string;
    positive: string;
    warning: string;
    informative: string;
    default: 24;
    sm: 16;
    xs: 12;
  };
  shape: {
    success: string;
    info: string;
    error: string;
    warning: string;
    neutral: string;
  };
  interactive: {
    default: string;
    hover: string;
  };
};

export const semantic: { light: SemanticTokens; dark: SemanticTokens } = {
  light: {
    background: {
      neutral: {
        1: primitive.color.grey[0],
        2: primitive.color.grey[10],
        3: primitive.color.grey[50],
        4: primitive.color.grey[75],
      },
      info: primitive.color.blue[75],
      warning: primitive.color.yellow[50],
      error: primitive.color.red[75],
      success: primitive.color.green[75],
      code: primitive.color.grey[10], // neutral[2],
    },
    text: {
      primary: primitive.color.grey[1300],
      secondary: primitive.color.grey[1000],
      tertiary: primitive.color.grey[700],
      warning: primitive.color.yellow[1100],
      error: primitive.color.red[1100],
      success: primitive.color.green[1100],
      info: primitive.color.blue[1100],
      code: {
        generic: primitive.color.blue[1100],
        function: primitive.color.purple[1000],
        string: primitive.color.success[1000],
        keyword: primitive.color.purple[1000],
        number: primitive.color.blue[1000],
        punctuation: primitive.color.blue[1000],
        comment: primitive.color.grey[700], // Same as tertiary,
      },
    },
    border: {
      default: primitive.color.grey[300],
      hover: primitive.color.grey[700],
      success: primitive.color.green[400],
      error: primitive.color.red[500],
      warning: primitive.color.yellow[300],
      info: primitive.color.blue[400],
    },
    icon: {
      primary: primitive.color.grey[1200],
      secondary: primitive.color.grey[1000],
      duotone: {
        layer: {
          1: primitive.color.grey[1300],
          2: primitive.color.grey[600],
          3: primitive.color.grey[100],
        },
      },
      positive: primitive.color.green[800],
      negative: primitive.color.red[800],
      warning: primitive.color.yellow[500],
      informative: primitive.color.blue[800],
      default: 24,
      sm: 16,
      xs: 12,
    },
    shape: {
      success: primitive.color.green[500],
      info: primitive.color.blue[500],
      error: primitive.color.red[500],
      warning: primitive.color.yellow[500],
      neutral: primitive.color.grey[500],
    },
    interactive: {
      default: primitive.color.blue[1000],
      hover: primitive.color.blue[1100],
    },
  },
  dark: {
    background: {
      neutral: {
        1: '#24242E',
        2: '#1B1B22',
        3: '#121217',
        4: '#09090B',
      },
      info: primitive.color.blue[1300],
      warning: primitive.color.yellow[1200],
      error: primitive.color.red[1200],
      success: primitive.color.green[1300],
      code: '#1B1B22', // neutral[2],
    },
    text: {
      primary: primitive.color.grey[100],
      secondary: primitive.color.grey[400],
      tertiary: primitive.color.grey[500],
      warning: primitive.color.yellow[50],
      error: primitive.color.red[75],
      success: primitive.color.green[75],
      info: primitive.color.blue[50],
      code: {
        generic: primitive.color.purple[500],
        function: primitive.color.warning[300],
        string: primitive.color.success[400],
        keyword: primitive.color.error[600],
        number: primitive.color.blue[400],
        punctuation: primitive.color.blue[700],
        comment: primitive.color.grey[500],
      },
    },
    border: {
      default: primitive.color.grey[1200],
      hover: primitive.color.grey[1000],
      success: primitive.color.green[1000],
      error: primitive.color.red[1100],
      warning: primitive.color.yellow[1000],
      info: primitive.color.blue[1000],
    },
    icon: {
      primary: primitive.color.grey[75],
      secondary: primitive.color.grey[100],
      duotone: {
        layer: {
          1: primitive.color.grey[300],
          2: primitive.color.grey[1000],
          3: '#131418',
        },
      },
      positive: primitive.color.green[600],
      negative: primitive.color.red[600],
      warning: primitive.color.yellow[400],
      informative: primitive.color.blue[600],
      default: 24,
      sm: 16,
      xs: 12,
    },
    shape: {
      success: primitive.color.green[800],
      info: primitive.color.blue[800],
      error: primitive.color.red[800],
      warning: primitive.color.yellow[800],
      neutral: primitive.color.grey[800],
    },
    interactive: {
      default: primitive.color.blue[700],
      hover: primitive.color.blue[800],
    },
  },
};
