import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

export default function Hybrid(props: any) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <g>
        <path d="M8.75 8.125C9.5788 8.125 10.3737 7.79576 10.9597 7.20971C11.5458 6.62366 11.875 5.8288 11.875 5C11.875 4.1712 11.5458 3.37634 10.9597 2.79029C10.3737 2.20424 9.5788 1.875 8.75 1.875C7.9212 1.875 7.12634 2.20424 6.54029 2.79029C5.95424 3.37634 5.625 4.1712 5.625 5C5.625 5.8288 5.95424 6.62366 6.54029 7.20971C7.12634 7.79576 7.9212 8.125 8.75 8.125ZM8.75 0C10.0761 0 11.3479 0.526784 12.2855 1.46447C13.2232 2.40215 13.75 3.67392 13.75 5C13.75 6.32608 13.2232 7.59785 12.2855 8.53553C11.3479 9.47322 10.0761 10 8.75 10C7.42392 10 6.15215 9.47322 5.21447 8.53553C4.27678 7.59785 3.75 6.32608 3.75 5C3.75 3.67392 4.27678 2.40215 5.21447 1.46447C6.15215 0.526784 7.42392 0 8.75 0ZM1.92578 18.125H13.6133C14.0937 18.8594 14.707 19.4961 15.4219 20H1.16016C0.519531 20 0 19.4805 0 18.8398C0 14.9922 3.11719 11.875 6.96484 11.875H8.75H10.5352C11.3398 11.875 12.1133 12.0117 12.832 12.2617C12.6406 12.8594 12.5273 13.4883 12.5039 14.1445C11.8984 13.8906 11.2344 13.75 10.5352 13.75H6.96484C4.39844 13.75 2.27344 15.6523 1.92578 18.125Z" />
        <path
          d="M20.4 18.95C20.5619 18.95 20.9907 18.7925 21.4413 17.8913C21.6338 17.5041 21.8 17.0338 21.9225 16.5H18.8775C19 17.0338 19.1663 17.5041 19.3588 17.8913C19.8094 18.7925 20.2382 18.95 20.4 18.95ZM18.7047 15.45H22.0954C22.1304 15.1154 22.15 14.7632 22.15 14.4C22.15 14.0369 22.1304 13.6847 22.0954 13.35H18.7047C18.6697 13.6847 18.65 14.0369 18.65 14.4C18.65 14.7632 18.6697 15.1154 18.7047 15.45ZM18.8775 12.3H21.9225C21.8 11.7663 21.6338 11.296 21.4413 10.9088C20.9907 10.0075 20.5619 9.85005 20.4 9.85005C20.2382 9.85005 19.8094 10.0075 19.3588 10.9088C19.1663 11.296 19 11.7663 18.8775 12.3ZM23.1497 13.35C23.1825 13.6891 23.1979 14.0413 23.1979 14.4C23.1979 14.7588 23.1804 15.111 23.1497 15.45H24.8275C24.9063 15.1132 24.95 14.761 24.95 14.4C24.95 14.0391 24.9085 13.6869 24.8275 13.35H23.1519H23.1497ZM24.436 12.3C23.9679 11.401 23.2088 10.6791 22.2835 10.2569C22.5919 10.8169 22.8369 11.5147 22.9966 12.3H24.4382H24.436ZM17.8013 12.3C17.961 11.5147 18.206 10.8191 18.5144 10.2569C17.5891 10.6791 16.83 11.401 16.3619 12.3H17.8035H17.8013ZM15.9725 13.35C15.8938 13.6869 15.85 14.0391 15.85 14.4C15.85 14.761 15.8916 15.1132 15.9725 15.45H17.6504C17.6175 15.111 17.6022 14.7588 17.6022 14.4C17.6022 14.0413 17.6197 13.6891 17.6504 13.35H15.9725ZM22.2835 18.5432C23.2088 18.121 23.9679 17.3991 24.436 16.5H22.9966C22.8369 17.2854 22.5919 17.981 22.2835 18.5432ZM18.5166 18.5432C18.2082 17.9832 17.9632 17.2854 17.8035 16.5H16.3619C16.83 17.3991 17.5891 18.121 18.5144 18.5432H18.5166ZM20.4 20C18.9148 20 17.4905 19.4101 16.4403 18.3598C15.39 17.3096 14.8 15.8853 14.8 14.4C14.8 12.9148 15.39 11.4905 16.4403 10.4403C17.4905 9.39005 18.9148 8.80005 20.4 8.80005C21.8853 8.80005 23.3096 9.39005 24.3598 10.4403C25.4101 11.4905 26 12.9148 26 14.4C26 15.8853 25.4101 17.3096 24.3598 18.3598C23.3096 19.4101 21.8853 20 20.4 20Z"
          strokeWidth="0.2"
        />
      </g>
    </SvgIcon>
  );
}
