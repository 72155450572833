import { DesignTokens } from '../types';

export const userListPicker = (tokens: DesignTokens) => ({
  CatalogReactUserListPicker: {
    root: {
      backgroundColor: tokens.semantic.background.neutral[2],
    },
    menuItem: {
      backgroundColor: tokens.semantic.background.neutral[1],
    },
    title: {
      textTransform: 'capitalize',
    },
  },
  CatalogReactEntityOwnerPicker: {
    root: {
      display: 'none',
    },
  },
});
