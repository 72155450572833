import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Instagram(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M20.031 12.781c3.938 0 7.188 3.25 7.188 7.188 0 4-3.25 7.187-7.188 7.187-4 0-7.187-3.187-7.187-7.187 0-3.938 3.187-7.188 7.187-7.188Zm0 11.875c2.563 0 4.625-2.062 4.625-4.687a4.614 4.614 0 0 0-4.625-4.625c-2.625 0-4.687 2.062-4.687 4.625a4.681 4.681 0 0 0 4.687 4.687Zm9.125-12.125a1.68 1.68 0 0 0-1.687-1.687 1.68 1.68 0 0 0-1.688 1.687c0 .938.75 1.688 1.688 1.688a1.68 1.68 0 0 0 1.687-1.688Zm4.75 1.688c.125 2.312.125 9.25 0 11.562-.125 2.25-.625 4.188-2.25 5.875-1.625 1.625-3.625 2.125-5.875 2.25-2.312.125-9.25.125-11.562 0-2.25-.125-4.188-.625-5.875-2.25-1.625-1.687-2.125-3.625-2.25-5.875-.125-2.312-.125-9.25 0-11.562.125-2.25.625-4.25 2.25-5.875 1.687-1.625 3.625-2.125 5.875-2.25 2.312-.125 9.25-.125 11.562 0 2.25.125 4.25.625 5.875 2.25s2.125 3.625 2.25 5.875Zm-3 14c.75-1.813.563-6.188.563-8.25 0-2 .187-6.375-.563-8.25-.5-1.188-1.437-2.188-2.625-2.625-1.875-.75-6.25-.563-8.25-.563-2.062 0-6.437-.187-8.25.563a4.82 4.82 0 0 0-2.687 2.625c-.75 1.875-.563 6.25-.563 8.25 0 2.062-.187 6.437.563 8.25a4.738 4.738 0 0 0 2.687 2.687c1.813.75 6.188.563 8.25.563 2 0 6.375.187 8.25-.563 1.188-.5 2.188-1.437 2.625-2.687Z" />
    </SvgIcon>
  );
}
