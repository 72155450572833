import {
  configApiRef,
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  fetchApiRef,
} from '@backstage/core-plugin-api';
import { identityApiRef } from './api/IdentityApi';
import { IdentityClient } from './api/IdentityClient';
import { rootRouteRef } from './routes';

export const identityPlugin = createPlugin({
  id: 'identity',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: identityApiRef,
      deps: {
        fetchApi: fetchApiRef,
        discoveryApi: discoveryApiRef,
        configApi: configApiRef,
      },
      factory: ({ fetchApi, discoveryApi, configApi }) =>
        new IdentityClient({
          discoveryApi,
          fetchApi,
          configApi,
        }),
    }),
  ],
});

export const IdentityPage = identityPlugin.provide(
  createRoutableExtension({
    name: 'IdentityPage',
    component: () =>
      import('./components/IdentityPage').then(m => m.IdentityPage),
    mountPoint: rootRouteRef,
  }),
);
