import { TableRowType } from './types';

// Format number with spaces between thousands and max 2 decimals
export const formatNumber = (number: number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

export const getColumnType = (headerKey: string, data: TableRowType[]) => {
  const columnType = data.find(row => row.columns[headerKey])?.columns[
    headerKey
  ].type;

  return columnType;
};

export const MENU_CELL_WIDTH = 24;
