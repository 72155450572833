import { ConfigApi, OAuthApi } from '@backstage/core-plugin-api';
import { readGithubIntegrationConfigs } from '@backstage/integration';
import { Octokit } from '@octokit/rest';
import { MBCPluginGithubApi } from './MBCPluginGithubApi';

export class MBCPluginGithubClient implements MBCPluginGithubApi {
  private readonly configApi: ConfigApi;
  private readonly githubAuthApi: OAuthApi;

  base = 'main';
  ref = 'main';
  owner = 'lego';
  repo = 'mac-build-cloud-images';

  constructor(options: { configApi: ConfigApi; githubAuthApi: OAuthApi }) {
    this.configApi = options.configApi;
    this.githubAuthApi = options.githubAuthApi;
  }

  private async getOctokit(hostname?: string): Promise<Octokit> {
    const token = await this.githubAuthApi.getAccessToken(['repo']);

    const configs = readGithubIntegrationConfigs(
      this.configApi.getOptionalConfigArray('integrations.github') ?? []
    );

    const githubIntegrationConfig = configs.find(
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      (v) => v.host === hostname ?? 'github.com'
    );

    const baseUrl = githubIntegrationConfig?.apiBaseUrl;

    return new Octokit({ auth: token, baseUrl });
  }

  async submitIssue(
    leanixId: string,
    imageName: string,
    gitHubTeamName: string,
    macOSVersion: string,
    chipArchitecture: string,
    xCodeVersion: string
  ): Promise<string> {
    const octokit = await this.getOctokit();

    const body = `
    ### Image name
    ${imageName}
    ### GitHub Team Name
    ${gitHubTeamName}
    ### LeanIX App Id
    ${leanixId}
    ### macOS Version
    ${macOSVersion} 
    ### Xcode Version
    ${xCodeVersion}`
      .split('\n')
      .map((line) => {
        return line.trim();
      })
      .join('\n');

    const { data } = await octokit.issues.create({
      owner: this.owner,
      repo: this.repo,
      title: 'I would like a custom image, please',
      labels: [`image-request-${chipArchitecture}`],
      body,
    });

    return apiUrlToGithubUrl(data.url);
  }
}

const apiUrlToGithubUrl = (url: string) => {
  return url.replace('https://api.github.com/repos/', 'https://github.com/');
};
