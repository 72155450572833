import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ArrowRightFromBracket(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="m35.57 21.063-8 8c-.625.625-1.562.625-2.125 0-.625-.563-.625-1.5 0-2.125l5.438-5.438H15.508c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5h15.375l-5.438-5.438c-.625-.562-.625-1.5 0-2.124.563-.563 1.5-.563 2.125 0l8 8.062c.563.563.563 1.5 0 2.063ZM14.508 9h-5c-1.438 0-2.5 1.125-2.5 2.5v17c0 1.438 1.062 2.5 2.5 2.5h5c.812 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5h-5a5.468 5.468 0 0 1-5.5-5.5v-17c0-3 2.437-5.5 5.5-5.5h5c.812 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5Z" />
    </SvgIcon>
  );
}
