import { parseEntityRef } from '@backstage/catalog-model';
import { useApi } from '@backstage/core-plugin-api';
import {
  CATALOG_FILTER_EXISTS,
  catalogApiRef,
  useStarredEntities,
} from '@backstage/plugin-catalog-react';
import { Typography } from '@material-ui/core';
import React from 'react';
import useAsync from 'react-use/lib/useAsync';
import { baseplateStyles } from '../../utils';
import { EntityLink } from '../entity-link/EntityLink';
import { OverviewPageCardGrid } from '../overview-page';

interface Props {
  kind?: string;
  isTechdocs?: boolean;
}

const useStyles = baseplateStyles(theme => ({
  container: {
    display: 'grid',
    width: '100%',
    minHeight: '200px',
  },
  favoriteEntitiesList: {
    borderRadius: theme.primitive.borderRadius.default,
    backgroundColor: theme.semantic.background.neutral[2],
    padding: '24px',
    width: '100vw%',
  },
}));

export const FavoriteEntitiesCardsList = ({ kind, isTechdocs }: Props) => {
  const classes = useStyles();
  const catalogApi = useApi(catalogApiRef);
  const { starredEntities } = useStarredEntities();
  const entities = useAsync(async () => {
    if (!starredEntities.size) {
      return [];
    }

    const filter = [...starredEntities]
      .map(ent => parseEntityRef(ent))
      .map(ref => ({
        ...(kind && {
          kind: kind,
        }),
        'metadata.namespace': ref.namespace,
        'metadata.name': ref.name,
        ...(isTechdocs && {
          'metadata.annotations.backstage.io/techdocs-ref':
            CATALOG_FILTER_EXISTS,
        }),
      }));

    return (await catalogApi.getEntities({ filter })).items;
  }, [catalogApi, starredEntities, kind]);

  return (
    <div className={classes.favoriteEntitiesList}>
      <Typography variant="subtitle2" style={{ marginBottom: '8px' }}>
        Favorites
      </Typography>
      {!!entities.value && entities.value.length === 0 ? (
        <Typography variant="body1">
          Click the star on the card to add it to this list
        </Typography>
      ) : (
        <OverviewPageCardGrid>
          {entities.value?.map(entity => (
            <EntityLink
              key={entity.metadata.uid}
              entity={entity}
              variant="thumbnail"
              showFavoriteButton
            />
          ))}
        </OverviewPageCardGrid>
      )}
    </div>
  );
};
