import React from 'react';
import { type ReactElement } from 'react';
import { UiChannelType } from '../../../types';
import { Typography } from '@material-ui/core';
import { Message } from './Message';
import {
  CodeChip,
  baseplateStyles,
} from '@lego/plugin-baseplate-core-components';
import { getNavId } from '../sidenav/getNavId';
import { ScopePreview } from '../lego-metadata/ScopePreview';
import { MetadataContainer } from '../lego-metadata/MetadataContainer';

const useStyles = baseplateStyles(theme => ({
  root: {
    borderTop: `1px solid ${theme.semantic.border.default}`,
    padding: `2rem ${theme.primitive.dimensions.page.horizontalPadding}`,
  },
}));

export function Channel({
  name,
  summary,
  messages,
  classification,
  topicAddress,
}: UiChannelType): ReactElement {
  const classes = useStyles();
  return (
    <div className={classes.root} id={getNavId(name)}>
      <Typography variant="h3">{name}</Typography>
      <Typography variant="body1">{summary}</Typography>
      <MetadataContainer>
        <ScopePreview
          label="Required scopes"
          scopes={['read', 'write'].map(type => ({
            name: `async://${name}/${type}`,
            access: false,
          }))}
        />
        <div>
          <Typography variant="overline" color="secondary">
            Topic address
          </Typography>
          <div>
            <CodeChip
              children={topicAddress}
              showCopyToClipboard
              maxWidth={400}
            />
          </div>
        </div>
        {classification && (
          <div>
            <Typography variant="overline" color="secondary">
              Classification
            </Typography>
            <Typography variant="body1">{classification}</Typography>
          </div>
        )}
      </MetadataContainer>
      {messages.map((message, index) => (
        <Message {...message} key={`message-${message.name}-${index}`} />
      ))}
    </div>
  );
}
