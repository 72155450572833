import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Image(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M32 9H8c-.563 0-1 .5-1 1v20.063l.25-.375 5-7C12.563 22.25 13 22 13.5 22c.438 0 .938.25 1.188.625l1.937 2.688 5.188-6.688C22.063 18.25 22.5 18 23 18c.438 0 .875.25 1.188.625l8.5 11L33 30V10c0-.5-.5-1-1-1ZM8 6h24c2.188 0 4 1.813 4 4v20c0 2.25-1.813 4-4 4H8c-2.25 0-4-1.75-4-4V10c0-2.188 1.75-4 4-4Zm5 12c-1.125 0-2.063-.563-2.625-1.5-.563-.875-.563-2.063 0-3 .563-.875 1.5-1.5 2.625-1.5 1.063 0 2 .625 2.563 1.5.562.938.562 2.125 0 3C15 17.438 14.063 18 13 18Z" />
    </SvgIcon>
  );
}
