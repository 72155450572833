import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useDocsAnalytics } from '../hooks/useDocsAnalytics';
import { AnalyticsPageCharts } from './AnalyticsPageCharts';
import { AnalyticsPageCards } from './AnalyticsPageCards';
import { Spinner } from '@lego/plugin-baseplate-core-components';

export const AnalyticsPageContent = () => {
  const { analyticsValue, selected, loading } = useDocsAnalytics();

  if (loading) {
    return (
      <Grid item xs={12}>
        <Spinner />
      </Grid>
    );
  }

  if (!selected) {
    return (
      <Grid item xs={12}>
        <Typography>Please select an entity to view analytics</Typography>
      </Grid>
    );
  }

  if (!analyticsValue) {
    return (
      <Grid item xs={12}>
        <Typography>
          No analytics data found for the chosen documentation
        </Typography>
      </Grid>
    );
  }

  return (
    <>
      <Grid item xs={12}>
        <AnalyticsPageCards data={analyticsValue} />
      </Grid>
      <Grid item xs={12} md={12}>
        <AnalyticsPageCharts data={analyticsValue} entityRef={selected} />
      </Grid>
    </>
  );
};
