import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ControlClose(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="m31.586 10.54-9.5 9.5 9.437 9.437c.625.562.625 1.5 0 2.062-.562.625-1.5.625-2.062 0l-9.5-9.437-9.438 9.437c-.562.625-1.5.625-2.062 0-.625-.562-.625-1.5 0-2.125l9.437-9.437-9.437-9.438c-.625-.562-.625-1.5 0-2.125.562-.562 1.5-.562 2.125 0l9.437 9.5 9.438-9.437c.562-.625 1.5-.625 2.125 0 .562.562.562 1.5 0 2.062Z" />
    </SvgIcon>
  );
}
