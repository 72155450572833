import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ArrowUpRightFromSquare(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M23.031 5.5c0-.813.625-1.5 1.5-1.5h9.938c.875 0 1.5.688 1.5 1.5v10c0 .875-.625 1.5-1.5 1.5a1.48 1.48 0 0 1-1.5-1.5V9.125L19.03 23.063c-.562.625-1.5.625-2.062 0-.625-.563-.625-1.5 0-2.125L30.906 7h-6.375c-.875 0-1.5-.625-1.5-1.5Zm-14.5.5h8c.813 0 1.5.688 1.5 1.5 0 .875-.687 1.5-1.5 1.5h-8c-.875 0-1.5.688-1.5 1.5v21c0 .875.625 1.5 1.5 1.5h21c.813 0 1.5-.625 1.5-1.5v-8c0-.813.625-1.5 1.5-1.5.813 0 1.5.688 1.5 1.5v8c0 2.5-2.062 4.5-4.5 4.5h-21c-2.5 0-4.5-2-4.5-4.5v-21c0-2.438 2-4.5 4.5-4.5Z" />
    </SvgIcon>
  );
}
