import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';
import { rootRouteRef } from './routes';

export const templatePlugin = createPlugin({
  id: 'api-linter',
  featureFlags: [{ name: 'api-linter' }],
  routes: {
    root: rootRouteRef,
  },
});

export const LinterPage: () => JSX.Element = templatePlugin.provide(
  createRoutableExtension({
    name: 'LinterPage',
    component: () =>
      import('./components/LinterComponent').then(m => m.LinterComponent),
    mountPoint: rootRouteRef,
  }),
);
