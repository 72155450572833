import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function StopwatchExclamation(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M15 5.5c0-.813.625-1.5 1.5-1.5h7c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5h-2v3.125a12.666 12.666 0 0 1 7.063 3.188l1.875-1.876a1.471 1.471 0 0 1 2.062 0c.625.626.625 1.563 0 2.126L30.562 15.5C32.063 17.625 33 20.25 33 23c0 7.188-5.875 13-13 13-7.188 0-13-5.813-13-13 0-6.625 5-12.125 11.5-12.875V7h-2c-.875 0-1.5-.625-1.5-1.5ZM20 33c3.563 0 6.813-1.875 8.625-5 1.813-3.063 1.813-6.875 0-10-1.813-3.063-5.063-5-8.625-5-3.625 0-6.875 1.938-8.688 5-1.812 3.125-1.812 6.938 0 10 1.813 3.125 5.063 5 8.688 5Zm1.5-15.5V24c0 .875-.688 1.5-1.5 1.5-.875 0-1.5-.625-1.5-1.5v-6.5c0-.813.625-1.5 1.5-1.5.813 0 1.5.688 1.5 1.5Z" />
      <path d="M21.5 28.5c0 .875-.688 1.5-1.5 1.5-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5.813 0 1.5.688 1.5 1.5Z" />
    </SvgIcon>
  );
}
