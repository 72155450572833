import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function BookOpenReader(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M20 13c1.063 0 2-.563 2.563-1.5.562-.875.562-2.063 0-3C22 7.625 21.063 7 20 7c-1.125 0-2.063.625-2.625 1.5-.563.938-.563 2.125 0 3 .563.938 1.5 1.5 2.625 1.5Zm0-9c2.125 0 4.063 1.188 5.188 3 1.062 1.875 1.062 4.188 0 6-1.125 1.875-3.063 3-5.188 3-2.188 0-4.125-1.125-5.25-3-1.063-1.813-1.063-4.125 0-6 1.125-1.813 3.063-3 5.25-3Zm-1.75 17.438h-.063s-.125-.063-.312-.188c-.313-.125-.938-.438-1.875-.75-1.75-.563-4.688-1.188-9-1.375v11c5.25.313 9.188 1.375 11.5 2.25v-10.75l-.25-.188Zm3.25.187v10.75c2.25-.875 6.188-1.938 11.5-2.25v-11c-4.375.188-7.25.813-9 1.375-1 .313-1.563.625-1.938.75-.187.125-.312.188-.312.188l-.25.187ZM6 16.062C16.438 16.375 20 19 20 19s3.5-2.625 14-2.938c1.063-.062 2 .875 2 1.938v13c0 1.125-.938 2-2 2.063-7.188.312-11.688 2.124-13.063 2.75A2.508 2.508 0 0 1 20 36c-.375 0-.688-.063-.938-.188-1.375-.624-5.875-2.437-13.062-2.75C4.875 33 4 32.126 4 31V18c0-1.063.875-2 2-1.938Z" />
    </SvgIcon>
  );
}
