import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function DollarSign(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M21.526 5.5v3.125c1.688.188 3.25.5 4.438.75a1.507 1.507 0 1 1-.688 2.938c-1.938-.438-4.75-1-7.125-.75-1.188.062-2.125.312-2.813.75-.624.437-1.062 1-1.25 1.937-.187.688-.062 1.188.126 1.5.124.375.5.688 1.062 1.063 1.188.75 3 1.187 5.063 1.75l.187.062c1.938.5 4.125 1.063 5.813 2.063.875.5 1.625 1.25 2.125 2.25.562 1 .625 2.187.437 3.437-.438 2.25-2.063 3.688-4.063 4.438-1 .375-2.125.625-3.312.687v3c0 .875-.688 1.5-1.5 1.5-.875 0-1.5-.625-1.5-1.5v-3.063c-.25-.062-.438-.062-.625-.125-1.563-.187-4.313-.812-6-1.562-.75-.375-1.125-1.25-.75-2 .313-.75 1.188-1.063 1.938-.75 1.374.563 3.812 1.188 5.187 1.375 2.25.313 4.125.125 5.5-.375 1.313-.5 2-1.25 2.125-2.188.188-.687.063-1.187-.125-1.5-.125-.375-.5-.75-1.063-1.062-1.187-.75-2.937-1.25-5.062-1.75l-.188-.063c-1.937-.5-4.125-1.062-5.812-2.062-.813-.563-1.625-1.25-2.125-2.25-.5-1.063-.625-2.188-.375-3.438.313-1.812 1.25-3.062 2.563-3.874 1.25-.813 2.75-1.126 4.187-1.25.188-.063.375-.063.625-.063v-3c0-.813.625-1.5 1.5-1.5.813 0 1.5.688 1.5 1.5Z" />
    </SvgIcon>
  );
}
