import React from 'react';
import { Typography } from '@material-ui/core';
import { useSwitchStyles } from './Switch.styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import { SwitchProps } from '@mui/material/Switch';

export type SwitchToggleProps = {
  label?: string;
  helperText?: string;
  errorText?: string;
  successText?: string;
};

export type BaseplateSwitchToggleProps = Omit<SwitchProps, 'color'> & {
  color?: 'primary' | 'success';
} & SwitchToggleProps;
/**
 * A custom form control switch component that extends the MUI Switch component.
 * @docs https://baseplate.legogroup.io/docs/default/component/baseplate
 */
export const SwitchToggle = ({
  label,
  color = 'primary',
  helperText,
  errorText,
  successText,
  ...props
}: BaseplateSwitchToggleProps): JSX.Element => {
  const classes = useSwitchStyles();

  return (
    <div className={[classes.root, classes[color]].join(' ')}>
      <FormControlLabel
        control={
          <Switch
            {...props}
            focusVisibleClassName=".Mui-focusVisible"
            disableRipple
            required={false}
          />
        }
        label={
          label && (
            <Typography variant="body2" component="label">
              {label}
            </Typography>
          )
        }
      />
      <div className={classes.descriptiveTextContainer}>
        {helperText && (
          <Typography variant="body2" className={classes.helperText}>
            {helperText}
          </Typography>
        )}
        {errorText && (
          <Typography variant="body2" className={classes.errorText}>
            {errorText}
          </Typography>
        )}
        {successText && (
          <Typography variant="body2" className={classes.successText}>
            {successText}
          </Typography>
        )}
      </div>
    </div>
  );
};
