import { MessageObject } from '@asyncapi/parser/esm/spec-types/v2';
import { parsePayload } from './payload';
import { UiMessageType } from '../../../types';
import { createMessageExamples } from '../transform/createMessageExamples';

export const parseMessage = (
  message: MessageObject,
  index: number,
  totalMessages: number,
): UiMessageType => {
  const payload = parsePayload(
    message.payload.properties,
    message.payload.required,
  );

  return {
    name: message['x-parser-message-name'],
    summary: message.summary,
    payload,
    examples: createMessageExamples(payload, message.examples),
    messageNumber: index + 1,
    totalMessageTypes: totalMessages,
  };
};
