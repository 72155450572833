import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ListMusic(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M34.063 4C35.124 4 36 4.875 36 5.938V12.5c0 .688-.438 1.25-1.063 1.438L28 16.188V31c0 2.813-3.188 5-7 5-3.875 0-7-2.188-7-5 0-2.75 3.125-5 7-5 1.438 0 2.813.375 4 .938V8c0-.625.375-1.188 1-1.375l7.438-2.5c.187-.063.374-.125.624-.125Zm-9.876 25.938C23.5 29.438 22.375 29 21 29c-1.438 0-2.563.438-3.25.938-.688.5-.75.937-.75 1.062 0 .188.063.625.75 1.125.688.5 1.813.875 3.25.875 1.375 0 2.5-.375 3.188-.875.687-.5.812-.938.812-1.125 0-.125-.125-.563-.813-1.063ZM28 13.063l5-1.626v-4l-5 1.688v3.938ZM5.5 7h15c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5h-15C4.625 10 4 9.375 4 8.5 4 7.687 4.625 7 5.5 7Zm0 8h15c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5h-15c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5Zm0 8h7c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5h-7c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5Z" />
    </SvgIcon>
  );
}
