import { baseplateStyles } from '../../utils';

export const useDatePickerStyles = baseplateStyles(theme => ({
  '@global': {
    '.rs-picker-date, .rs-picker-daterange, .rs-picker-popup': {
      '--rs-input-focus-border': theme.semantic.border.info,
      '--rs-input-bg': theme.semantic.background.neutral[1],

      '--rs-text-primary': theme.semantic.text.primary,
      '--rs-text-secondary': theme.semantic.text.secondary,
      '--rs-text-disabled': theme.semantic.text.tertiary,
      '--rs-text-active': theme.semantic.interactive.default,
      '--rs-text-error': theme.semantic.text.error,

      '--rs-border-primary': theme.semantic.border.default,
      '--rs-border-secondary': theme.semantic.border.default,
      // --rs-border-secondary: var(--rs-gray-100);

      '--rs-state-focus-shadow': `0 0 0 2px ${theme.semantic.border.info}80`,
      '--rs-color-focus-ring': theme.semantic.border.info,

      '--rs-bg-overlay': theme.semantic.background.neutral[1],
      '--rs-bg-well': theme.semantic.background.neutral[2],
      '--rs-bg-active': theme.component.button.primary.background.default,
      '--rs-calendar-range-bg': theme.semantic.background.info,
      '--rs-calendar-cell-selected-hover-bg':
        theme.component.button.primary.background.hover,
      '--rs-listbox-option-hover-text': theme.component.button.primary.text,
      '--rs-listbox-option-hover-bg':
        theme.component.button.primary.background.hover,

      '--rs-btn-primary-bg': theme.component.button.primary.background.default,
      '--rs-btn-primary-hover-bg':
        theme.component.button.primary.background.hover,
      '--rs-btn-subtle-hover-bg': theme.component.button.text.background.hover,
      '--rs-btn-subtle-hover-text': theme.component.button.text.text,
      '--rs-btn-subtle-active-bg':
        theme.component.button.text.background.default,
      '--rs-btn-subtle-active-text': theme.component.button.text.text,
      '--rs-btn-subtle-text': theme.semantic.text.secondary,
    },

    '.rs-input-group': {
      borderRadius: theme.primitive.borderRadius.default,
    },

    '.rs-input-group:not(.rs-input-group-disabled):hover': {
      '--rs-input-focus-border': theme.semantic.border.hover,
    },

    '.rs-calendar-table-cell-disabled': {
      backgroundColor: theme.semantic.background.neutral[2],
      color: theme.semantic.text.tertiary,
      '&:hover > div': {
        backgroundColor: 'inherit !important',
        color: 'inherit !important',
      },
    },

    '.rs-btn': {
      fontFamily: 'Cera Pro !important',
      fontWeight: '500',
      fontSize: theme.typography.body2.fontSize,
      padding: '0.25rem 0.5rem',
      borderRadius: theme.primitive.borderRadius.default,
    },

    '.rs-picker input': {
      fontFamily: 'Cera Pro !important',
    },
  },
  error: {
    '--rs-border-primary': theme.semantic.border.error,
    '--rs-border-secondary': theme.semantic.border.error,
  },
  menu: {},
}));
