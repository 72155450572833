import React from 'react';
import { baseplateStyles } from '../../utils';

export type StatusProps = {
  status: 'green' | 'yellow' | 'red' | 'neutral';
  label: string;
};

const useStyles = baseplateStyles(theme => ({
  statusWrapper: {
    color: 'inherit',
    display: 'flex',
    gap: '0.25rem',
    alignItems: 'center',
    fontSize: '0.875rem',
  },
  statusIcon: {
    borderRadius: 2,
    width: 8,
    height: 8,
    '&$green': {
      backgroundColor: theme.semantic.shape.success,
    },
    '&$yellow': {
      backgroundColor: theme.semantic.shape.warning,
    },
    '&$red': {
      backgroundColor: theme.semantic.shape.error,
    },
    '&$neutral': {
      backgroundColor: theme.semantic.shape.neutral,
    },
  },
  green: {},
  yellow: {},
  red: {},
  neutral: {},
}));

export const Status = ({ label, status }: StatusProps) => {
  const classes = useStyles();
  return (
    <div className={classes.statusWrapper}>
      <div className={[classes.statusIcon, classes[status]].join(' ')} />
      {label}
    </div>
  );
};
