import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ShareNodes(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M34 12c0 3.313-2.688 6-6 6-1.813 0-3.438-.75-4.563-2.063l-5.625 2.813c.125.438.188.875.188 1.25 0 .438-.063.875-.188 1.313l5.625 2.75C24.563 22.813 26.188 22 28 22c3.313 0 6 2.688 6 6 0 3.313-2.688 6-6 6-3.313 0-6-2.688-6-6 0-.375 0-.813.125-1.25L16.5 23.937A5.892 5.892 0 0 1 12 26c-3.313 0-6-2.688-6-6 0-3.313 2.688-6 6-6 1.75 0 3.375.813 4.5 2.063l5.625-2.75C22 12.874 22 12.437 22 12c0-3.313 2.688-6 6-6 3.313 0 6 2.688 6 6ZM12 23c1.063 0 2-.563 2.563-1.5.562-.875.562-2.063 0-3C14 17.625 13.063 17 12 17c-1.125 0-2.063.625-2.625 1.5-.563.938-.563 2.125 0 3 .563.938 1.5 1.5 2.625 1.5Zm19-11c0-1.063-.625-2-1.5-2.563-.938-.562-2.125-.562-3 0C25.562 10 25 10.938 25 12c0 1.125.563 2.063 1.5 2.625.875.563 2.063.563 3 0 .875-.563 1.5-1.5 1.5-2.625Zm-3 19c1.063 0 2-.563 2.563-1.5.562-.875.562-2.063 0-3C30 25.625 29.063 25 28 25c-1.125 0-2.063.625-2.625 1.5-.563.938-.563 2.125 0 3 .563.938 1.5 1.5 2.625 1.5Z" />
    </SvgIcon>
  );
}
