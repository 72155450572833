import { useEnvironmentStage } from '@lego/plugin-baseplate-core-components';
import { MenuItem, makeStyles } from '@material-ui/core';
import React from 'react';
import { BaseplateTheme } from '../../style/types';

const useStyles = makeStyles<BaseplateTheme>({
  wrapper: {
    marginTop: '1rem',
  },
});

export const DevModeSwitch = () => {
  const { isDevEnvironment, isLocalEnvironment, isTestEnvironment } =
    useEnvironmentStage();

  let envAlignment: 'dev' | 'test' | 'prod' = 'dev';

  if (isLocalEnvironment || isDevEnvironment) {
    envAlignment = 'dev';
  } else if (isTestEnvironment) {
    envAlignment = 'test';
  } else {
    envAlignment = 'prod';
  }

  return (
    <div>
      {envAlignment === 'dev' && <MenuItem disabled>Dev Cockpit</MenuItem>}
    </div>
  );
};
