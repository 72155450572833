import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function BackwardStep(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M14.5 9.5v6.625l11-7.75A1.7 1.7 0 0 1 26.563 8c1.062 0 1.937.875 1.937 1.938v20.187A1.88 1.88 0 0 1 26.625 32c-.438 0-.813-.063-1.125-.313l-11-7.75V30.5c0 .875-.688 1.5-1.5 1.5-.875 0-1.5-.625-1.5-1.5v-21c0-.813.625-1.5 1.5-1.5.813 0 1.5.688 1.5 1.5Zm0 10.813L25.5 28V12.062l-11 7.688v.563Z" />
    </SvgIcon>
  );
}
