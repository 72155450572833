import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function TagEuro(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M15 13c0-1.063-.938-2-2-2-1.125 0-2 .938-2 2 0 1.125.875 2 2 2 1.063 0 2-.875 2-2Zm2.907 9.803.284-.284.568.568-.284.284a.809.809 0 0 0 0 1.135c.32.32.816.32 1.136 0l.497-.497c2.005.976 4.507.604 6.175-1.064l.426-.426a.809.809 0 0 0 0-1.136.826.826 0 0 0-1.136 0l-.426.426a3.775 3.775 0 0 1-3.797.958l2.236-2.235a.809.809 0 0 0 0-1.136.826.826 0 0 0-1.136 0l-2.555 2.555-.568-.568 2.555-2.555a.809.809 0 0 0 0-1.136.826.826 0 0 0-1.136 0l-2.235 2.236a3.826 3.826 0 0 1 .958-3.797l.426-.426a.809.809 0 0 0 0-1.136.826.826 0 0 0-1.136 0l-.426.426a5.448 5.448 0 0 0-1.064 6.175l-.497.497a.808.808 0 0 0 0 1.136c.319.32.816.32 1.135 0Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.125 7.188A3.976 3.976 0 0 0 18.312 6H9a3 3 0 0 0-3 3v9.375c0 1.063.375 2.063 1.125 2.813l11 11a4.036 4.036 0 0 0 5.688 0l8.312-8.313a4.036 4.036 0 0 0 0-5.688l-11-11ZM9.25 19.063c-.188-.188-.25-.438-.25-.688V9h9.313c.25 0 .5.125.687.313l11 11a.951.951 0 0 1 0 1.437l-8.313 8.313c-.375.375-1.062.375-1.437 0l-11-11Z"
      />
    </SvgIcon>
  );
}
