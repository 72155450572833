import { showToast } from '@lego/plugin-baseplate-core-components';
import {
  OpenPosition,
  Product,
} from '@lego/plugin-baseplate-people-to-product-common';
import { TableCell, TableRow } from '@material-ui/core';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useOpenPosition } from '../../../hooks';
import DeleteProductButton from '../../DeleteProductButton/DeleteProductButton';

interface OpenPositionProductTableRowProps {
  openPosition: OpenPosition;
  product: Product;
}

function OpenPositionProductTableRow({
  openPosition,
  product,
}: OpenPositionProductTableRowProps) {
  const { removeProductFromOpenPosition } = useOpenPosition();

  const handleOnDeleteButtonClick = async () => {
    try {
      if (openPosition) {
        await removeProductFromOpenPosition(openPosition, product);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        showToast({
          type: 'success',
          message: (
            <>
              <Typography>
                Successfully removed <strong>{openPosition.positionId}</strong>{' '}
                from the following product:
              </Typography>
              <Typography sx={{ fontWeight: 'bold' }}>
                {product.name}
              </Typography>
            </>
          ),
        });
      }
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      showToast({
        type: 'error',
        message: 'Error happened while removing product.',
      });
    }
  };
  return (
    <TableRow>
      <TableCell>{product.name}</TableCell>
      <TableCell>
        <DeleteProductButton
          product={product}
          isDisabled={false}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={handleOnDeleteButtonClick}
        />
      </TableCell>
    </TableRow>
  );
}

export default OpenPositionProductTableRow;
