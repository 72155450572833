import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';
import {
  Button,
  Input,
  Dropdown,
} from '@lego/plugin-baseplate-core-components';
import {
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import React, { useEffect, useState } from 'react';

export enum SupportType {
  Chat = 'Chat',
  Docs = 'Docs',
  Email = 'Email',
  Call = 'Call',
  Ideas = 'Ideas',
  Survey = 'Survey',
  Link = 'Link',
}

type SupportLinks = {
  url: string;
  title: string | undefined;
  description: string | undefined;
  supportType: SupportType;
  actionLabel: string;
};

export const CustomSupportLink = ({
  onChange,
  rawErrors,
  required,
  formData,
  uiSchema,
}: FieldExtensionComponentProps<SupportLinks[]>) => {
  const [entries, setEntries] = useState<SupportLinks[] | undefined>(
    formData || [],
  );

  useEffect(() => {
    if (entries && entries.length > 0) {
      const newEntries = entries;
      onChange(newEntries);
    } else {
      onChange(undefined);
    }
  }, [entries, onChange]);

  const handleUpdate = (
    index: number,
    key: keyof SupportLinks,
    newValue: string,
  ) => {
    const newEntries = (entries ?? []).map((entry, i) =>
      i === index ? { ...entry, [key]: newValue } : entry,
    );
    setEntries(newEntries);
  };

  const handleAdd = () => {
    setEntries([
      ...(entries ?? []),
      {
        title: '',
        url: '',
        description: '',
        supportType: SupportType.Chat,
        actionLabel: '',
      },
    ]);
  };

  const handleRemove = (index: number) => {
    const newEntries = (entries ?? []).filter((_, i) => i !== index);
    setEntries(newEntries);
  };

  const supportTypes = [
    'Chat',
    'Docs',
    'Email',
    'Call',
    'Ideas',
    'Survey',
    'Link',
  ];

  return (
    <FormControl required={required} error={rawErrors?.length > 0 && !formData}>
      {entries &&
        !!entries.length &&
        entries.map((entry, index) => (
          <Grid container key={index}>
            <Input
              label="Title"
              id={`validateLabel-${index}`}
              value={entry.title}
              onChange={e => handleUpdate(index, 'title', e.target.value)}
              helperText="The title will be displayed as the support card title and as the button label in the service type homepage header e.g. Chat with Specialists"
              required
            />
            <Input
              label="Description"
              id={`validateDescription-${index}`}
              value={entry.description}
              onChange={e => handleUpdate(index, 'description', e.target.value)}
              helperText="A short summary of the support. This will appear as the description in the support card on the Help & Support page."
              required
            />
            <Grid container key={index}>
              <Input
                label="URL"
                id={`validateUrl-${index}`}
                value={entry.url}
                required
                onChange={e => handleUpdate(index, 'url', e.target.value)}
                helperText="URL or email address for the support channel. This will be used as the contact link in the support card."
              />
            </Grid>
            <Grid container key={index}>
              <Grid item xs={6}>
                <Dropdown
                  label="Support Type"
                  id={`validateSupportType-${index}`}
                  defaultValue={SupportType.Chat}
                  value={entry.supportType}
                  required
                  options={supportTypes}
                  onChange={e =>
                    handleUpdate(index, 'supportType', e.target.value as string)
                  }
                  helperText="Select a type to choose the icon displayed before the title in the support card."
                />
              </Grid>
              <Grid item xs={6}>
                <Input
                  label="Action Label"
                  id={`validateActionLabel-${index}`}
                  value={entry.actionLabel}
                  required
                  onChange={e =>
                    handleUpdate(index, 'actionLabel', e.target.value)
                  }
                  helperText="The text that will be displayed on the action button in the support card, such as 'Chat with our team' or 'Contact Us'"
                />
              </Grid>
            </Grid>
            <Grid
              item
              style={{
                width: '100%',
                padding: 0,
                textAlign: 'center',
              }}
            >
              <IconButton onClick={() => handleRemove(index)}>
                <RemoveCircleOutlineIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}
      <Button
        onClick={handleAdd}
        startIcon={<AddCircleOutlineIcon />}
        fullWidth
        variant="secondary"
      >
        Add a Support Link
      </Button>
      <FormHelperText style={{ padding: '0 1rem' }}>
        <Typography variant="body2" color="secondary">
          You can add Custom Support Links to your Hosting Platform Plugin e.g.
          "Azure Specialist"
        </Typography>
      </FormHelperText>
    </FormControl>
  );
};
