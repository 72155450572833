import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function MusicNote(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M32 6v6.5c0 .688-.5 1.313-1.188 1.5L22 16.25V31c0 2.813-3.188 5-7 5-3.875 0-7-2.188-7-5 0-2.75 3.125-5 7-5 1.438 0 2.813.375 4 .938V8c0-.625.438-1.25 1.063-1.438l9.5-2.5c.125 0 .312-.062.437-.062 1.125 0 2 .875 2 2ZM19 31c0-.125-.125-.563-.813-1.063C17.5 29.438 16.375 29 15 29c-1.438 0-2.563.438-3.25.938-.688.5-.75.937-.75 1.062 0 .188.063.625.75 1.125.688.5 1.813.875 3.25.875 1.375 0 2.5-.375 3.188-.875.687-.5.812-.938.812-1.125Zm10-19.625V7.312l-7 1.875v3.938l7-1.75Z" />
    </SvgIcon>
  );
}
