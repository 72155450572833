/* eslint-disable @typescript-eslint/no-misused-promises */
import {
  configApiRef,
  discoveryApiRef,
  fetchApiRef,
  useAnalytics,
  useApi,
} from '@backstage/core-plugin-api';
import {
  AvailableKinds,
  BaseplateAnalyticsEventAttributes,
} from '@lego/plugin-baseplate-common';
import React, { useState } from 'react';
import useAsync from 'react-use/lib/useAsync';
import { Button } from '../button';
import { Icon } from '../icon';
import { IngestionInfo } from './types';
import Tooltip from '@mui/material/Tooltip';

export type ReingestionProps = {
  provider:
    | 'AmmaEntityProvider-production:refresh'
    | 'AmmaEntityProvider-integration:refresh'
    | 'LeanIxProvider:refresh';
  kind: AvailableKinds;
};

export const Reingestion = ({ provider, kind }: ReingestionProps) => {
  const discoveryApi = useApi(discoveryApiRef);
  const fetchApi = useApi(fetchApiRef);
  const analytics = useAnalytics();

  const config = useApi(configApiRef);
  const dbType = config.getOptionalString('backend.database.client');

  const isPersistentDatabase = dbType === 'pg';

  const [isRunning, setIsRunning] = useState<boolean>();

  const { loading, error } = useAsync(async () => {
    if (!isPersistentDatabase) return;
    const url = `${await discoveryApi.getBaseUrl('catalog')}/tasks/${provider}`;
    const response = await fetchApi.fetch(url);

    if (response.status !== 200) {
      throw new Error('Failed to fetch ingestion info');
    }
    const { isRunning: isIngestionRunnnig } =
      (await response.json()) as IngestionInfo;
    setIsRunning(isIngestionRunnnig);
  }, [isPersistentDatabase, provider]);

  const handleReingestion = async () => {
    analytics.captureEvent('click', 'Reingestion', {
      attributes: {
        key: 'reingestion-entity',
        reingestion_kind: kind,
      } as BaseplateAnalyticsEventAttributes,
    });

    const url = `${await discoveryApi.getBaseUrl('catalog')}/tasks/${provider}`;
    await fetchApi.fetch(url, {
      method: 'PUT',
    });
    setIsRunning(true);
  };

  let tooltipTitle = '';
  if (isRunning) {
    tooltipTitle = 'Reingestion is currently running.';
  } else if (loading) {
    tooltipTitle = 'Loading ingestion status...';
  } else if (error) {
    tooltipTitle = 'Failed to fetch ingestion info.';
  } else if (!isPersistentDatabase) {
    tooltipTitle = 'Reingestion is only available for persistent databases.';
  }

  return (
    <>
      <Tooltip title={tooltipTitle}>
        <span>
          <Button
            variant="text"
            disabled={
              isRunning ||
              loading ||
              error !== undefined ||
              !isPersistentDatabase
            }
            onClick={handleReingestion}
            startIcon={<Icon icon="rotate-left" />}
          >
            Reingest
          </Button>
        </span>
      </Tooltip>
    </>
  );
};
