import React from 'react';
import {
  SearchModalResultItemProps,
  SearchModalResultItemWrapper,
} from '../SearchModalResultItemWrapper';
import { Typography } from '@material-ui/core';
import { baseplateStyles } from '@lego/plugin-baseplate-core-components';

type Props = {
  // Add your prop types here
  icon?: React.ReactNode;
  label: string;
} & SearchModalResultItemProps;

const useStyles = baseplateStyles(_ => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.25rem',
  },
}));

export const SearchModalActionItem = ({ icon, label, ...props }: Props) => {
  const classes = useStyles();
  return (
    <SearchModalResultItemWrapper {...props}>
      <div className={classes.root}>
        {icon && icon}
        <Typography variant="body1">{label}</Typography>
      </div>
    </SearchModalResultItemWrapper>
  );
};
