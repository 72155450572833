import {
  CopyTextButton,
  InfoCard,
  InfoCardVariants,
  MarkdownContent,
} from '@backstage/core-components';
import { AboutField } from '@backstage/plugin-catalog';
import { useEntity } from '@backstage/plugin-catalog-react';
import { ANNOTATION_LAST_INGESTION } from '@lego/plugin-baseplate-common';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { DateTime } from 'luxon';
import React, { useMemo } from 'react';

type AboutCardProps = {
  variant?: InfoCardVariants;
};

const useStyles = makeStyles(theme => ({
  container: {
    display: 'grid',
    width: '100%',
    height: '50%',
    margin: `${theme.spacing(2)}px 0`,
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
    gap: '1rem',
    '& a': {
      display: 'block',
    },
  },
  description: {
    wordBreak: 'break-word',
  },
  smallContainer: {
    width: '50%',
  },
}));

export function IdentityAboutCard({ variant }: AboutCardProps) {
  const classes = useStyles();
  const { entity } = useEntity();

  const timeSinceIngestion = useMemo(() => {
    if (entity.metadata.annotations?.[ANNOTATION_LAST_INGESTION]) {
      return DateTime.fromISO(
        entity.metadata.annotations?.[ANNOTATION_LAST_INGESTION],
      ).toRelative({ locale: 'en' });
    }
    return '';
  }, [entity]);

  return (
    <InfoCard
      title="Overview"
      variant={variant}
      className="bp-about-card"
      action={
        <>
          <Typography variant="inherit" style={{ marginRight: 10 }}>
            Last updated {timeSinceIngestion}
          </Typography>
        </>
      }
    >
      <>
        <Grid container>
          <>
            <AboutField label="Description" gridSizes={{ xs: 12 }}>
              <MarkdownContent
                className={classes.description}
                content="An Identity is an AMMA API that exposes different API specifications. When the Identity has no specifications, it is referred to as a _ClientAPI_."
              />
            </AboutField>
            <>
              <AboutField
                label="Identity (Entra ID)"
                value={entity?.spec?.identity as string}
                gridSizes={{ xs: 6 }}
              />
              <CopyTextButton
                text={entity?.spec?.identity as string}
                tooltipText="Copied Identity to Clipboard"
                arial-label="Copy the Identity to the clipboard"
              />
            </>
          </>
        </Grid>
      </>
    </InfoCard>
  );
}
