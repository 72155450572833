import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function MagnifyingGlass(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M27.008 16.984c0-3.562-1.938-6.812-5-8.625-3.125-1.812-6.938-1.812-10 0-3.125 1.813-5 5.063-5 8.625 0 3.625 1.875 6.875 5 8.688 3.062 1.812 6.875 1.812 10 0 3.062-1.813 5-5.063 5-8.688Zm-1.938 10.25a13.096 13.096 0 0 1-8.062 2.75c-7.188 0-13-5.812-13-13 0-7.125 5.812-13 13-13 7.125 0 13 5.875 13 13 0 3.063-1.063 5.875-2.813 8.125l8.375 8.313c.563.625.563 1.562 0 2.125-.625.625-1.562.625-2.125 0l-8.375-8.313Z" />
    </SvgIcon>
  );
}
