import React from 'react';
import { useFilterChipStyles } from './FilterChip.styles';
import CheckIcon from '@material-ui/icons/Check';
import { Typography } from '@material-ui/core';

interface Props {
  label: string;
  count?: number;
  selected: boolean;
  onClick: (label: string, selectedState: boolean) => void;
}

export const FilterChip = ({ label, count, selected, onClick }: Props) => {
  const classes = useFilterChipStyles();
  return (
    <button
      onClick={() => onClick(label, selected)}
      className={[classes.root, selected ? classes.selected : ''].join(' ')}
    >
      {selected && <CheckIcon fontSize="small" />}
      <Typography variant="body2">{label}</Typography>
      {count !== undefined && (
        <Typography variant="overline" color="secondary">
          {count}
        </Typography>
      )}
    </button>
  );
};
