import { baseplateStyles } from '@lego/plugin-baseplate-core-components';
import React from 'react';

type Props = {
  size?: 'sm' | 'md' | 'lg';
} & (
  | {
      icon: React.ReactNode;
      text?: never;
    }
  | {
      icon?: never;
      text: string;
    }
);

const useStyles = baseplateStyles(theme => ({
  container: {
    backgroundColor: theme.component.tag.neutral.background,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0, 0.5),
    borderRadius: theme.primitive.borderRadius.sm,
    color: theme.component.tag.neutral.text,
  },
  md: {
    width: 24,
    height: 24,
  },
  sm: {
    height: 18,
    width: 18,
  },
  text: {
    width: 'fit-content',
  },
}));

// TODO: Move to @lego/plugin-baseplate-core-components
export const ShortcutSymbol = ({ size = 'md', icon, text }: Props) => {
  const classes = useStyles();
  return (
    <div
      className={[
        classes.container,
        !icon && classes.text,
        size && classes[size],
      ].join(' ')}
    >
      {icon || text}
    </div>
  );
};
