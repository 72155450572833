import {
  analyticsApiRef,
  AnyApiFactory,
  configApiRef,
  createApiFactory,
  discoveryApiRef,
  errorApiRef,
  fetchApiRef,
  githubAuthApiRef,
  IconComponent,
  identityApiRef,
  microsoftAuthApiRef,
  storageApiRef,
} from '@backstage/core-plugin-api';
import {
  ScmAuth,
  scmAuthApiRef,
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
} from '@backstage/integration-react';
import { DefaultEntityPresentationApi } from '@backstage/plugin-catalog';
import {
  catalogApiRef,
  entityPresentationApiRef,
} from '@backstage/plugin-catalog-react';
import { visitsApiRef, VisitsStorageApi } from '@backstage/plugin-home';
import { ammaApiRef, AmmaClient } from '@lego/plugin-baseplate-amma';
import { BaseplateAnalytics } from '@lego/plugin-baseplate-analytics';
import {
  apiMetricsApiRef,
  ApiMetricsClient,
} from '@lego/plugin-baseplate-api-metrics';
import {
  ApplicationIcon,
  ProductIcon,
} from '@lego/plugin-baseplate-core-components';
import { BaseplateErrorApi } from './BaseplateErrorApi';

export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  createApiFactory({
    api: analyticsApiRef,
    deps: {
      identityApi: identityApiRef,
      fetchApi: fetchApiRef,
      discoveryApi: discoveryApiRef,
    },
    factory: ({ identityApi, fetchApi, discoveryApi }) =>
      BaseplateAnalytics.fromConfig({ identityApi, fetchApi, discoveryApi }),
  }),

  createApiFactory({
    api: scmAuthApiRef,
    deps: {
      githubAuthApi: githubAuthApiRef,
    },
    factory: ({ githubAuthApi }) =>
      ScmAuth.merge(ScmAuth.forGithub(githubAuthApi)),
  }),
  createApiFactory({
    api: errorApiRef,
    deps: {},
    factory: () => new BaseplateErrorApi(),
  }),

  createApiFactory({
    api: entityPresentationApiRef,
    deps: {
      catalogApi: catalogApiRef,
    },
    factory({ catalogApi }) {
      return DefaultEntityPresentationApi.create({
        catalogApi,
        cacheTtl: { minutes: 1 },
        batchDelay: { seconds: 0.25 },
        kindIcons: {
          product: ProductIcon as IconComponent,
          application: ApplicationIcon as IconComponent,
        },
      });
    },
  }),
  createApiFactory({
    api: ammaApiRef,
    deps: {
      configApi: configApiRef,
      fetchApi: fetchApiRef,
      discoveryApi: discoveryApiRef,
      microsoftAuth: microsoftAuthApiRef,
    },
    factory: ({ configApi, discoveryApi, fetchApi, microsoftAuth }) =>
      new AmmaClient({
        configApi,
        fetchApi,
        discoveryApi,
        microsoftAuth,
      }),
  }),
  createApiFactory({
    api: apiMetricsApiRef,
    deps: {
      fetchApi: fetchApiRef,
      discoveryApi: discoveryApiRef,
    },
    factory: ({ discoveryApi, fetchApi }) =>
      new ApiMetricsClient({
        fetchApi,
        discoveryApi,
      }),
  }),
  createApiFactory({
    api: visitsApiRef,
    deps: {
      storageApi: storageApiRef,
      identityApi: identityApiRef,
    },
    factory: ({ storageApi, identityApi }) =>
      VisitsStorageApi.create({ storageApi, identityApi }),
  }),
];
