import React, { ReactNode } from 'react';
import { type ReactElement } from 'react';
import { useCodeChipStyles } from './CodeChip.styles';
import { Icon } from '../icon';
import { showToast } from '../toast';

export interface CodeChipProps {
  children: ReactNode;
  showCopyToClipboard?: boolean;
  maxWidth?: number;
}

export function CodeChip({
  children,
  showCopyToClipboard,
  maxWidth,
}: CodeChipProps): ReactElement {
  const classes = useCodeChipStyles();

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(children as string)
      .then(() => {
        showToast({ message: 'Copied to clipboard', success: 'true' }).catch(
          () => {},
        );
      })
      .catch(() => {});
  };

  return (
    <div className={classes.root}>
      <div style={{ maxWidth }} className={classes.content}>
        {children}
      </div>
      {showCopyToClipboard && (
        <button className={classes.copyButton} onClick={copyToClipboard}>
          <Icon icon="copy" size={16} color="secondary" />
        </button>
      )}
    </div>
  );
}
