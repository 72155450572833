import {
  Button,
  baseplateStyles,
} from '@lego/plugin-baseplate-core-components';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Box from '@mui/material/Box';
import React, { useState } from 'react';
import { useReport } from '../../hooks';
import { gopColumns } from '../EmployeeProductTable/columns';

import GeneralOverheadProductTableRow from './GeneralOverheadProductTableRow';
import NewGeneralOverheadProductTableRow from './NewGeneralOverheadProductTableRow';

const useStyles = baseplateStyles(theme => ({
  tableHeader: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    borderTop: `1px solid ${theme.semantic.border.default}`,
    borderBottom: `1px solid ${theme.semantic.border.default}`,
  },
}));

type Props = {
  employeeId: number;
};

function GeneralOverheadProductsTable({ employeeId }: Props) {
  const { reports } = useReport();
  const [isAdding, setIsAdding] = useState(false);

  const classes = useStyles();

  const employee = reports.find(e => e.id === employeeId);

  const gops = employee?.generalOverheadProducts || [];

  const handleClick = () => {
    setIsAdding(true);
  };

  if (!employee) {
    return null;
  }

  return (
    <Paper style={{ marginTop: 32 }}>
      <Toolbar>
        <Typography variant="h5">General Overhead Products</Typography>
        <Box flexGrow={1} />
        <Button
          onClick={handleClick}
          disabled={isAdding || gops.length > 0}
          variant="primary"
        >
          <AddIcon />
          Add
        </Button>
      </Toolbar>
      <Table component={Paper}>
        <TableHead>
          <TableRow>
            {gopColumns.map((column, index) => (
              <TableCell key={index} className={classes.tableHeader}>
                {column.title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {gops.map(({ node }) => (
            <GeneralOverheadProductTableRow
              employee={employee}
              rowData={node}
            />
          ))}
          {isAdding && (
            <NewGeneralOverheadProductTableRow
              employee={employee}
              setIsAddingProduct={setIsAdding}
            />
          )}
        </TableBody>
      </Table>
    </Paper>
  );
}

export default GeneralOverheadProductsTable;
