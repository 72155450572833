import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ArrowSplitThree(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M8.469 27.594c.562.562 1.5.562 2.062 0v-.063c.625-.562.625-1.5 0-2.062L8.094 23.03V23H12.5c.816 0 1.417.538 1.94 1.06l4.06 4.061V35a1.5 1.5 0 0 0 3 0v-6.879l4.06-4.06C26.084 23.538 26.684 23 27.5 23h4.406v.031L29.47 25.47a1.369 1.369 0 0 0 0 2.062v.063c.562.562 1.5.562 2.062 0l5-5c.625-.625.625-1.563 0-2.125l-5-5a1.369 1.369 0 0 0-2.062 0c-.625.562-.625 1.5 0 2.125L31.875 20H27.5c-2.128 0-3.51 1.387-4.022 1.9l-.039.04-1.94 1.939V9.109l2.423 2.422c.625.625 1.562.625 2.125 0 .625-.562.625-1.5 0-2.062l-5-5c-.563-.625-1.5-.625-2.125 0l-5 5a1.471 1.471 0 0 0 0 2.062h.062a1.37 1.37 0 0 0 2.063 0l2.437-2.437h.016v14.785l-1.94-1.94-.038-.038C16.01 21.387 14.627 20 12.5 20H8.125l2.406-2.406c.625-.625.625-1.563 0-2.125a1.369 1.369 0 0 0-2.062 0l-5 5c-.625.562-.625 1.5 0 2.125l5 5Z" />
    </SvgIcon>
  );
}
