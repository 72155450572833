import { BackstageOverrides } from '@backstage/core-components';
import { DesignTokens } from '../types';

export const table = (
  tokens: DesignTokens,
): Pick<
  BackstageOverrides,
  | 'BackstageTable'
  | 'BackstageTableToolbar'
  | 'BackstageTableHeader'
  | 'MuiTable'
  | 'MuiTableCell'
  | 'MuiTablePagination'
> => ({
  BackstageTable: {
    root: {
      // this is in order to have tables (that look like cards) have the same height as the cards
      // for example in the overview tab of an api
      height: '100%',
      // padding: theme.spacing(4),
    },
  },
  BackstageTableHeader: {
    header: {
      textTransform: 'capitalize',
    },
  },
  BackstageTableToolbar: {
    root: {
      textTransform: 'capitalize',
      margin: tokens.primitive.dimensions[300],
      padding: 0,
    },
  },
  MuiTable: {
    root: {
      color: tokens.semantic.text.primary,
    },
  },
  MuiTableCell: {
    root: {
      borderBottomColor: tokens.semantic.border.default,
      '& a': {
        color: tokens.semantic.interactive.default,
      },
    },
    paddingCheckbox: {
      '&:last-child': {
        paddingRight: 'none',
      },
    },
  },
  MuiTablePagination: {
    root: {
      color: tokens.semantic.icon.secondary,
    },
    selectIcon: {},
  },
});
