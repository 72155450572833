import React from 'react';
import {
  FilterChip,
  baseplateStyles,
} from '@lego/plugin-baseplate-core-components';
import { type ReactElement } from 'react';
import { Typography } from '@material-ui/core';
import {
  useSearchModalContext,
  allSearchFilters,
  GlobalSearchField,
} from '../../../search';

const useStyles = baseplateStyles(theme => ({
  searchContent: {
    gridArea: 'pageHeader',
    padding: `4rem ${theme.primitive.dimensions.page.horizontalPadding}`,
    backgroundColor: theme.semantic.background.neutral[3],
    borderBottom: `1px solid ${theme.semantic.border.default}`,
    [theme.breakpoints.up('md')]: {
      '& > div': {
        width: `66.66%`,
        margin: '0 auto',
      },
    },
  },
  searchFilters: {
    display: 'flex',
    gap: '0.5rem',
    marginTop: '0.5rem',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  shortcut: {
    display: 'flex',
    gap: '4px ',
  },
}));

export function HomePageSearch(): ReactElement {
  const classes = useStyles();
  const { openModal } = useSearchModalContext();

  return (
    <div className={classes.searchContent}>
      <div>
        <GlobalSearchField scope="Global" size="large" color="default" />
        <div className={classes.searchFilters}>
          <Typography variant="body1">Scope the search to:</Typography>
          {allSearchFilters.map(filter => (
            <FilterChip
              selected={false}
              label={filter.title}
              onClick={() => openModal(filter)}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
