import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Film(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M26 31v-9.5H14V31h12Zm0-12.5V9H14v9.5h12ZM8 9c-.563 0-1 .5-1 1v4.5h4V9H8Zm-1 8.5v5h4v-5H7ZM11 31v-5.5H7V30c0 .563.438 1 1 1h3Zm18-16.5h4V10c0-.5-.5-1-1-1h-3v5.5Zm0 3v5h4v-5h-4Zm0 8V31h3c.5 0 1-.438 1-1v-4.5h-4ZM32 6c2.188 0 4 1.813 4 4v20c0 2.25-1.813 4-4 4H8c-2.25 0-4-1.75-4-4V10c0-2.188 1.75-4 4-4h24Z" />
    </SvgIcon>
  );
}
