import {
  Entity,
  entityKindSchemaValidator,
  KindValidator,
} from '@backstage/catalog-model';
import schema from './Application.v1alpha.schema.json';

export interface ApplicationEntityV1alpha1 extends Entity {
  apiVersion: 'scaffolder.legogroup.io/v1alpha1';
  kind: 'Application';
}

const validator = entityKindSchemaValidator(schema);

export const ApplicationEntityV1alpha1Validator: KindValidator = {
  // eslint-disable-next-line @typescript-eslint/require-await
  async check(data: Entity) {
    return validator(data) === data;
  },
};
