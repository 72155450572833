import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function VrCardboard(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M35 11H5c-1.125 0-2 .938-2 2v14c0 1.125.875 2 2 2h6.875c.75 0 1.438-.375 1.813-1.063l2.124-4.375C16.625 22 18.25 21 20 21c1.75 0 3.313 1 4.125 2.563l2.125 4.375A2.026 2.026 0 0 0 28.063 29H35c1.063 0 2-.875 2-2V13c0-1.063-.938-2-2-2ZM5 8h30c2.75 0 5 2.25 5 5v14c0 2.813-2.25 5-5 5h-6.938a5.094 5.094 0 0 1-4.5-2.75l-2.125-4.313c-.312-.562-.875-.937-1.5-.937-.562 0-1.125.375-1.437.938l-2.125 4.312a5.094 5.094 0 0 1-4.5 2.75H5c-2.813 0-5-2.188-5-5V13c0-2.75 2.188-5 5-5Zm1 11c0-1.375.75-2.688 2-3.438 1.188-.687 2.75-.687 4 0 1.188.75 2 2.063 2 3.438 0 1.438-.813 2.75-2 3.5-1.25.688-2.813.688-4 0-1.25-.75-2-2.063-2-3.5Zm24-4c1.375 0 2.688.813 3.438 2 .687 1.25.687 2.813 0 4-.75 1.25-2.063 2-3.438 2-1.438 0-2.75-.75-3.5-2-.688-1.188-.688-2.75 0-4 .75-1.188 2.063-2 3.5-2Z" />
    </SvgIcon>
  );
}
