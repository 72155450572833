import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Desktop(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M34 7H6c-.563 0-1 .5-1 1v12h30V8c0-.5-.5-1-1-1Zm4 13v6c0 2.25-1.813 4-4 4h-9.25l.5 3h3.25c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5h-17c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5h3.188l.5-3H6c-2.25 0-4-1.75-4-4V8c0-2.188 1.75-4 4-4h28c2.188 0 4 1.813 4 4v12ZM5 23v3c0 .563.438 1 1 1h28c.5 0 1-.438 1-1v-3H5Zm12.75 10h4.438l-.5-3H18.25l-.5 3Z" />
    </SvgIcon>
  );
}
