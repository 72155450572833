import { ConfigApi, DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';
import { ScmAuthApi } from '@backstage/integration-react';
import { GithubaApi } from './GithubApi';
import { Octokit } from '@octokit/rest';
import { readGithubIntegrationConfigs } from '@backstage/integration';
import { GitHubUser } from './github.types';
import { ApiUtils } from '../APIUtils';

const getParams = (endpoint: string, data?: any) => {
  const customData = data
    ? { ...data, headers: { 'X-GitHub-Api-Version': '2022-11-28' } }
    : { headers: { 'X-GitHub-Api-Version': '2022-11-28' } };
  return { url: endpoint, data: customData };
};
export class GithubClient implements GithubaApi {
  private readonly configApi: ConfigApi;
  private readonly scmAuthApi: ScmAuthApi;
  private readonly apiUtils: ApiUtils;

  constructor(options: {
    configApi: ConfigApi;
    scmAuthApi: ScmAuthApi;
    discoveryApi: DiscoveryApi;
    fetchApi: FetchApi;
  }) {
    this.configApi = options.configApi;
    this.scmAuthApi = options.scmAuthApi;
    this.apiUtils = new ApiUtils(options.discoveryApi, options.fetchApi);
  }

  private async getOctokit(hostname: string = 'github.com'): Promise<Octokit> {
    const { token } = await this.scmAuthApi.getCredentials({
      url: `https://${hostname}`,
    });
    // const token = getCookie('token');
    const configs = readGithubIntegrationConfigs(
      this.configApi.getOptionalConfigArray('integrations.github') ?? [],
    );
    const githubIntegrationConfig = configs.find(v => v.host === hostname);
    const baseUrl = githubIntegrationConfig?.apiBaseUrl;
    return new Octokit({ auth: token, baseUrl });
  }

  async getUserProfile(username: string): Promise<GitHubUser | null> {
    const octokit = await this.getOctokit();
    const result = await octokit.users.getByUsername({ username });
    let userProfile = null;

    if (result.status === 200 || result.status === 201) {
      userProfile = {
        avatar_url: result.data.avatar_url,
        name: result.data.name ?? result.data.login,
        username: `@${result.data.login}`,
        url: result.data.html_url,
      };
    }
    return userProfile;
  }

  async getRepo(repoName: string, token?: string): Promise<any> {
    return await this.apiUtils.get<unknown>(
      `repository/${repoName}`,
      'githubApi',
      token ? { 'X-GitHub-Token': token } : null,
    );
  }

  async getRepContent(
    repoName: string,
    path: string,
    token: string,
  ): Promise<any> {
    return await this.apiUtils.get<unknown>(
      `repo-file-contents/${repoName}/${path}`,
      'githubApi',
      { 'X-GitHub-Token': token },
    );
  }

  async getAuthenticatedUser(token: string): Promise<any> {
    return await this.apiUtils.post<any>(
      `call-api`,
      getParams('GET /user'),
      'githubApi',
      { 'X-GitHub-Token': token },
      true,
    );
  }

  async getCollaboratorPermissionLevel(
    repoName: string,
    token: string,
  ): Promise<any> {
    const userName = await this.getAuthenticatedUser(token);
    const collaborators = await this.apiUtils.post<unknown>(
      `call-api`,
      getParams(
        'GET /repos/{owner}/{repo}/collaborators/{username}/permission',
        {
          owner: 'LEGO',
          repo: repoName,
          username: userName.login,
        },
      ),
      'githubApi',
      { 'X-GitHub-Token': token },
      true,
    );

    return collaborators;
  }
}
