import { useApi } from '@backstage/core-plugin-api';
import { Role } from '@lego/plugin-baseplate-eagraph-common';
import {
  Employee,
  GeneralOverheadProduct,
  Product,
} from '@lego/plugin-baseplate-people-to-product-common';
import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { peopleToProductApiRef } from '../api/PeopleToProductApi';
import {
  filterEmployeesWithProducts,
  filterEmployeesWithSpecificProducts,
  filterEmployeesWithoutProducts,
  filterExternalEmployees,
  filterInternalEmployees,
} from './filters/filters';
import { useReportSearchParams } from './useReportSearchParams';
import { getExternalToggle } from '@lego/plugin-baseplate-people-to-product-common';

type ReportContextType = {
  reports: Employee[];
  filteredReports: Employee[];
  error?: Error;
  loading: boolean;
  removeProductFromEmployee: (
    employee: Employee,
    product: Product,
  ) => Promise<void>;
  addProductToEmployee: (
    employee: Employee,
    product: Product,
    roles: Role[],
  ) => Promise<void>;
  updateTcoProduct: (employee: Employee, product: Product) => Promise<void>;
  updateRolesOnProductForEmployee: (
    employee: Employee,
    product: Product,
    rolesToRemove: Role[],
    rolesToAdd: Role[],
  ) => Promise<void>;
  addGeneralOverheadProductToEmployee: (
    employee: Employee,
    generalOverheadProduct: GeneralOverheadProduct,
  ) => Promise<void>;
};

type Props = {
  children?: ReactNode;
};

const ReportContext = createContext<ReportContextType>({
  reports: [],
  filteredReports: [],
  loading: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  removeProductFromEmployee: async () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  addProductToEmployee: async () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updateTcoProduct: async () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updateRolesOnProductForEmployee: async () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  addGeneralOverheadProductToEmployee: async () => {},
});

export const useReport = () => useContext(ReportContext);

export const ReportProvider = ({ children }: Props) => {
  const peopleToProductApi = useApi(peopleToProductApiRef);
  const [reports, setReports] = useState<Employee[]>([]);
  const [filteredReports, setFilteredReports] = useState<Employee[]>([]);
  const { products, searchParams, external } = useReportSearchParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error>();

  // First useEffect for fetching data
  useEffect(() => {
    async function fetchReports() {
      setLoading(true);
      try {
        const data = await peopleToProductApi.getReports();
        if (getExternalToggle()) {
          console.log('toggle true');
          setReports(data);
        } else {
          const dataFiltered = data.filter(filterInternalEmployees);
          console.log('toggle false ' + dataFiltered.length);
          setReports(dataFiltered);
        }
      } catch (e) {
        setError(e as Error);
      } finally {
        setLoading(false);
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Second useEffect for filtering data
  useEffect(() => {
    function filterData() {
      if (products === false) {
        setFilteredReports(reports.filter(filterEmployeesWithoutProducts));
      } else if (products === true) {
        setFilteredReports(reports.filter(filterEmployeesWithProducts));
      } else if (Array.isArray(products) && products.length > 0) {
        setFilteredReports(
          reports.filter(e => filterEmployeesWithSpecificProducts(e, products)),
        );
      } else if (external) {
        setFilteredReports(reports.filter(filterExternalEmployees));
      } else {
        setFilteredReports(reports);
      }
    }

    filterData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reports, searchParams]);

  const removeProductFromEmployee = async (
    employee: Employee,
    product: Product,
  ) => {
    await peopleToProductApi.deleteProductFromEmployee(employee.id, product.id);
    const updatedEmployees = reports.map(e => {
      if (e.id === employee.id) {
        return {
          ...e,
          products: e.products.filter(
            productConnection => productConnection.node.id !== product.id,
          ),
        };
      }
      return e;
    });
    setReports(updatedEmployees);
  };

  const addProductToEmployee = async (
    employee: Employee,
    product: Product,
    roles: Role[],
  ) => {
    const isFirstProduct =
      reports.find(e => e.id === employee.id)?.products.length === 0;
    await peopleToProductApi.addProductToEmployee(
      employee.id,
      product.id,
      roles,
      isFirstProduct,
    );
    const updatedEmployees = reports.map(e => {
      if (e.id === employee.id) {
        return {
          ...e,
          generalOverheadProducts: [],
          products:
            e.products.length > 0
              ? [
                  ...e.products,
                  {
                    tco: 0,
                    node: product,
                    roles: roles,
                  },
                ]
              : [
                  {
                    tco: 1,
                    node: product,
                    roles: roles,
                  },
                ],
        };
      }
      return e;
    });
    setReports(updatedEmployees);
  };

  const updateTcoProduct = async (employee: Employee, product: Product) => {
    const updatedProducts = reports.filter(e => e.id === employee.id)[0]
      .products;

    await peopleToProductApi.updateTcoProductForEmployee(
      employee.id,
      product.id,
      updatedProducts.map(p => {
        return {
          productId: p.node.id,
          tco: p.node.id === product.id ? 1 : 0,
          roles: p.roles,
        };
      }),
    );
    const updatedEmployees = reports.map(e => {
      if (e.id === employee.id) {
        return {
          ...e,
          products: e.products.map(productConnection => {
            if (productConnection.node.id === product.id) {
              return {
                ...productConnection,
                tco: 1,
              };
            }
            return {
              ...productConnection,
              tco: 0,
            };
          }),
        };
      }
      return e;
    });
    setReports(updatedEmployees);
  };

  const updateRolesOnProductForEmployee = async (
    employee: Employee,
    product: Product,
    rolesToRemove: Role[],
    rolesToAdd: Role[],
  ) => {
    await peopleToProductApi.updateRolesOnProductForEmployee(
      employee.id,
      product.id,
      rolesToRemove,
      rolesToAdd,
    );
    const updatedEmployees = reports.map(e => {
      if (e.id === employee.id) {
        return {
          ...e,
          products: e.products.map(productConnection => {
            if (productConnection.node.id === product.id) {
              return {
                ...productConnection,
                roles: [
                  ...rolesToAdd,
                  ...productConnection.roles.filter(
                    role => !rolesToRemove.includes(role),
                  ),
                ],
              };
            }
            return productConnection;
          }),
        };
      }
      return e;
    });
    setReports(updatedEmployees);
  };

  const addGeneralOverheadProductToEmployee = async (
    employee: Employee,
    generalOverheadProduct: GeneralOverheadProduct,
  ) => {
    await peopleToProductApi.addGeneralOverheadProductToEmployee(
      employee.id,
      generalOverheadProduct.id,
    );
    const updatedEmployees = reports.map(e => {
      if (e.id === employee.id) {
        return {
          ...e,
          products: [],
          generalOverheadProducts: [
            {
              tco: 1,
              node: generalOverheadProduct,
            },
          ],
        };
      }
      return e;
    });
    setReports(updatedEmployees);
  };

  return (
    <ReportContext.Provider
      value={{
        filteredReports: filteredReports || [],
        reports: reports || [],
        loading,
        error,
        removeProductFromEmployee,
        addProductToEmployee,
        updateTcoProduct,
        updateRolesOnProductForEmployee,
        addGeneralOverheadProductToEmployee,
      }}
    >
      {children}
    </ReportContext.Provider>
  );
};
