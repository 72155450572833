import { Icon, baseplateStyles } from '@lego/plugin-baseplate-core-components';
import { Typography } from '@material-ui/core';
import React from 'react';
import { type ReactElement } from 'react';

export type UiScopeType = {
  name: string;
  access: boolean;
};

export interface ScopePreviewProps {
  label: string;
  scopes: UiScopeType[];
}

const useStyles = baseplateStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.25rem',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
  },
  access: {
    '& svg': {
      fill: theme.semantic.icon.positive,
    },
  },
  noAccess: {
    '& svg': {
      fill: theme.semantic.icon.negative,
    },
  },
}));

export function ScopePreview({
  label,
  scopes,
}: ScopePreviewProps): ReactElement {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant="overline" color="secondary">
        {label}
      </Typography>
      {scopes.map(({ name, access }) => (
        <div
          key={name}
          className={[
            classes.item,
            classes[access ? 'access' : 'noAccess'],
          ].join(' ')}
        >
          <Icon icon={access ? 'lock-open' : 'lock'} size={16} />
          <span>{name}</span>
        </div>
      ))}
    </div>
  );
}
