import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';
import { FC } from 'react';
import { rootRouteRef } from './routes';

export const templatePlugin = createPlugin({
  id: 'api-metrics',
  routes: {
    root: rootRouteRef,
  },
});

export const ApiMetricsPage: FC = templatePlugin.provide(
  createRoutableExtension({
    name: 'ApiMetricsPage',
    component: () =>
      import('./components/ApiMetricsComponent').then(
        m => m.ApiMetricsComponent,
      ),
    mountPoint: rootRouteRef,
  }),
);
