import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Scissors(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M35.646 31.5c-.563.625-1.5.688-2.125.188l-10-8.375 2.312-2 9.625 8.062c.625.563.688 1.5.188 2.125ZM11.02 7c-1.438 0-2.75.813-3.5 2-.688 1.25-.688 2.813 0 4 .75 1.25 2.062 2 3.5 2 1.375 0 2.687-.75 3.437-2 .688-1.188.688-2.75 0-4-.75-1.188-2.062-2-3.437-2Zm0-3c3.812 0 7 3.188 7 7 0 1.125-.313 2.188-.75 3.125l4.687 3.938L33.52 8.375a1.558 1.558 0 0 1 2.125.188c.5.624.437 1.562-.188 2.124L17.27 25.937c.437.938.75 2 .75 3.063 0 3.875-3.188 7-7 7-3.875 0-7-3.125-7-7 0-3.813 3.125-7 7-7 1.625 0 3.187.625 4.375 1.563L19.646 20l-4.25-3.5A7.122 7.122 0 0 1 11.02 18c-3.875 0-7-3.125-7-7 0-3.813 3.125-7 7-7Zm4 25c0-1.375-.813-2.688-2-3.438-1.25-.687-2.813-.687-4 0-1.25.75-2 2.063-2 3.438 0 1.438.75 2.75 2 3.5 1.187.688 2.75.688 4 0 1.187-.75 2-2.063 2-3.5Z" />
    </SvgIcon>
  );
}
