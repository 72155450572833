import React from 'react';
import { Backdrop } from '@material-ui/core';
import { Spinner } from '@lego/plugin-baseplate-core-components';
import { useLoaderStyles } from './Loader.styles';

type LoaderProps = {
  displayed: boolean;
  withBackdrop?: boolean;
  size?: 'small' | 'large';
  type?: 'lego' | 'circle';
  width?: number;
};
export const Loader = ({
  displayed,
  withBackdrop = true,
  size = 'large',
  type = 'lego',
}: LoaderProps) => {
  const styles = useLoaderStyles();

  const renderSpinner = () => {
    return (
      <div className={styles.spinner}>
        <Spinner align="center" size={size} type={type} />
      </div>
    );
  };
  return (
    <>
      {withBackdrop ? (
        <Backdrop className={styles.backdrop} open={displayed}>
          {renderSpinner()}
        </Backdrop>
      ) : (
        <>{renderSpinner()}</>
      )}
    </>
  );
};
