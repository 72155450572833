/** Skill a user associated with. */
export interface Skill {
  /** Skill unique identifier */
  id: number;
  /** Display Name of the skill */
  displayName?: string;
  active?: boolean;
}

export interface ErrorMessage {
  /** A short message */
  title?: string;
  /** A detailed error description, if any */
  detail?: string;
  /** An identifier used by the development team to look up more details in the logs */
  instance?: string;
  /** Http status code */
  status?: number;
}

export interface CreateSkillRequest {
  skills: string[];
}

export interface LearningProfile {
  /** The learning profile identifier */
  id: number;
  /** Username of the mentor */
  username: string;
  /** Name of the mentor */
  name?: string;
  /** Email address of the mentor */
  email?: string;
  location?: string;
  /** Mentor's brief description. */
  bio: string;
  skills: SkillProfile[];
  /** Identifier to check if the current profile is tagged as mentor or not. If set to trie, mentorAttributes is not empty */
  isMentor: boolean;
  role?: string;
  mentorAttributes?: MentorAttributes;
}

/** Skills that you associate in your learning profile */
export interface SkillProfile {
  /** Skill a user associated with. */
  skill: Skill;
  /** Skill rating */
  level?: number;
  description?: string;
  /** Role you want for a skill (Mentor/Mentee). */
  role: SkillRole;
}

export interface MentorAttributes {
  /** Times you're available to mentor. */
  availabilities?: MentorAvailability[];
  /** Number of mentees the mentor can handle */
  preferredMenteesCount: number;
  /** The user's preferred contact channel */
  modeOfConnection: ModeOfConnection;
  /** How you approach mentoring. */
  teachingStyle?: string;
  /** Key value pair of mentorship connections state and its current count. Key as the mentorship connection status. Value as the count. */
  mentorshipConnectionsCount?: { [propertyName: string]: string };
}

export interface MentorAvailability {
  /** Day of the week you're available. */
  day?: string;
  /**
   * When your availability starts.
   * This value should be an ISO-8601 formatted string representing time. E.g. "HH:MM:SS" or "HH:MM:SS.mm".
   */
  startTime?: string;
  /**
   * When your availability ends.
   * This value should be an ISO-8601 formatted string representing time. E.g. "HH:MM:SS" or "HH:MM:SS.mm".
   */
  endTime?: string;
}

export interface UpsertLearningProfileRequest {
  /** A few words about yourself. */
  bio: string;
  skills: UpsertSkillProfileRequest[];
  /** Flag to determine whether to tag or untag a profile as mentor. If set to true, mentorAttributes cannot be empty */
  tagAsMentor: boolean;
  /** User's country location */
  location?: string;
  mentorAttributes?: MentorAttributes;
}

export interface UpsertSkillProfileRequest {
  learningProfileId?: number;
  skill: string;
  level?: number;
  description?: string;
  /** Role you want for a skill (Mentor/Mentee). */
  role: SkillRole;
}

export interface MsGraphGetPhotoResponse {
  data?: string;
}

export interface ConnectWithMentorRequest {
  mentorUsername: string;
}

export interface UpsertMentorConnectionRequest {
  /** The action intended to initate or update a mentor connection */
  action: MentorConnectionAction;
  /** Required if action = 'Request', otherwise can be null/undefined. Value should be the mentor's username */
  username?: string;
  /** Required if action != 'Request', otherwise can be null/undefined. */
  connectionId?: number;
}

/** The response object when creating/updating mentorship connection */
export interface UpsertMentorConnectionResponse {
  /** The updated connection id */
  connectionId?: number;
}

/** The object that denotes the mentorship connection */
export interface MentorshipConnection {
  /** The connection id */
  id?: number;
  /** Display name of the profile */
  displayName?: string;
  /** User name of the profile in baseplate */
  username?: string;
  /** Email of the profile in baseplate */
  email?: string;
  /** The role of the profile in a connection. 'mentee' if the profile is your mentee, and 'mentor' if the profile is mentoring you */
  role?: MentorshipConnectionRole;
  /** The status of the mentorship connection */
  status?: MentorshipConnectionStatus;
}

export interface PaginatedEventResponse {
  data?: Event[];
  pagination: Pagination;
}

export interface EventParticipantsCount {
  /** Total count of attending participants (inlcudes host, co-host and speakers) in a specific location of the event. Key as the location address. */
  attending?: { [propertyName: string]: number };
  /** Total count of spots left in a specific location of the event. Key as the location address. */
  spotsLeft?: { [propertyName: string]: number };
  /** Total count of interested */
  interested?: number;
  /** Total count of waitlisted */
  waitlisted?: number;
}

/** The object containing the base attribute of an Event */
export interface EventBase {
  /** Title of the event */
  title: string;
  /** Description of the event */
  description: string;
  /** Base64-encoded image OR url of uploaded photo */
  bannerPhotoUrl?: string;
  /** Session types of the event. Some examples can be `[hackathon, seminar, workshop, etc.]` */
  sessionFormat: string[];
  /** The object for the important people of an event */
  contributors: EventContributors;
  /** Max number of attendees for the event in physical location */
  physicalAttendeeLimit?: number;
  /** Max number of attendees for the event in online location */
  onlineAttendeeLimit?: number;
  /** Specify whether to allow the event to have waitlist */
  allowWaitlist: boolean;
  /** Physical location name */
  physicalLocation?: string;
  /** Online location name of the event */
  onlineLocation?: string;
  /** Specify if the event is an rsvp-required type. Defaults to `true` */
  requireRsvp: boolean;
  /** The deadline in datetime format for when will the rsvp ends */
  rsvpDeadline?: string;
  /** Questionnaire for the event for participants */
  questionToAttendees?: string;
  locations: EventLocation[];
  schedules: EventSchedule[];
  /** An array of the `skill` related to the event */
  skills?: string[];
  /** The identifiers of the products that are associated to the event */
  products?: string[];
  /** Set to true if the event is exclusive to product members only, otherwise false */
  exclusiveToProducts?: boolean;
  /** The identifiers of the communities that are associated to the event */
  communities?: string[];
  /** Default is false. Set to true to enable MS Teams online linking */
  enableTeamsMeeting?: boolean;
  recurrence: boolean;
  recurrenceFeatureFlag: boolean;
}

/** The object for the important people of an event */
export interface EventContributors {
  /** The host's identifier (i.e. username) */
  hostId: string;
  /** The co-hosts identifiers (i.e. username) */
  coHostIds?: string[];
  /** The speakers identifiers (i.e. username) */
  speakers?: string[];
}

/** The object for the event's location(s) */
export interface EventLocation {
  /** The location's identifier when in-use by an event */
  id?: number;
  /** The location's specific address */
  address: string;
  /** The location's user-friendly name */
  displayName: string;
  /** Whether the location address is a building, conference room or url */
  addressType: EventLocationAddressType;
  /** Whether this is an online or physical location */
  locationType: EventLocationType;
  /** The parent address of a location (i.e. building address when searching for conference rooms) */
  parentLocationAddress?: string;
  /** The location's capacity */
  attendeeLimit?: number;
}

/** Specifies an event's schedules */
export interface EventSchedule {
  /** Identifier of the created schedule. This is required when updating the schedule via PATCH /events/{id}. */
  id?: number;
  /** Datetime string of the event's start date and time */
  startDateTime: string;
  /** Datetime string of the event's end date and time */
  endDateTime?: string;
  recurrenceRange?: RecurrenceRange;
  recurrencePattern?: RecurrencePattern;
}

export interface Pagination {
  /** Total items of the result regardless of the page number. Only shows up on first page of results */
  total?: number;
  /** The number of the last page of the results i.e. the end page. Only shows up on first page of results */
  lastPage?: number;
  /** The page number of the previous page. Returns null if there is no previous page. Only shows up on first page of results */
  prevPage?: number;
  /** The page number of the next page. Returns null if there is no next page. Only shows up on first page of results */
  nextPage?: number;
  /** The maximum number of items per page */
  perPage: number;
  /** The current page number */
  currentPage: number;
  /** Counting ID of the first item of the current page */
  from: number;
  /** Counting ID of the last item of the current page */
  to: number;
}

/** The object containing the base attribute of an Event */
export interface UpdateEventRequest {
  /** Title of the event */
  title?: string;
  /** Description of the event */
  description?: string;
  /** Base64-encoded image OR url of uploaded photo */
  bannerPhotoUrl?: string;
  /** Session types of the event. Some examples can be `[hackathon, seminar, workshop, etc.]` */
  sessionFormat?: string[];
  /** The object for the important people of an event */
  contributors?: EventContributors;
  /** Max number of attendees for the event in physical location */
  physicalAttendeeLimit?: number;
  /** Max number of attendees for the event in physical location */
  onlineAttendeeLimit?: number;
  /** Specify whether to allow the event to have waitlist */
  allowWaitlist?: boolean;
  /** Physical location name */
  physicalLocation?: string;
  /** Online location name of the event */
  onlineLocation?: string;
  /** Specify if the event is an rsvp-required type. Defaults to `true` */
  requireRsvp?: boolean;
  /** The deadline in datetime format for when will the rsvp ends */
  rsvpDeadline?: string;
  locations?: EventLocation[];
  schedules?: EventSchedule[];
  /** An array of the `skill` related to the event */
  skills?: string[];
  /** The updated status of the event. Published event's status cannot be changed. */
  status?: string;
  /** A teaser question to the attendees of the event */
  questionToAttendees?: string;
  /** The products associated to the event */
  products?: string[];
  /** Set to true if the event is exclusive to product members only, otherwise false */
  exclusiveToProducts?: boolean;
  /** The identifiers of the communities that are associated to the event */
  communities?: string[];
  /** Flag for whether to overwrite online location url to the link graph API will return for online meeting. Defaults to `True`, if property is not given, automatically acquire URL from graph response and overwrite the event's online location */
  generateOnlineUrl?: boolean;
  /** Default is false. Set to true to enable MS Teams online linking */
  enableTeamsMeeting?: boolean;
  recurrence?: boolean;
}

/** A participant for an event. Contains their role and username. */
export interface EventParticipant {
  /** ID of the participant object. Note that this is NOT the ID of the profile. */
  id: number;
  /** Username of the profile as a participant for the event */
  username?: string;
  /** ID of the event from which the profile is a participant for */
  eventId: number;
  /** Email of the event participant */
  email: string;
  /** Role of the participant. One of these `[host, co-host, attending, waitlisted, interested]` */
  role: string;
  /** Response of the participant to the event's questionnaire. Optional only, depends on the event. */
  eventQuestionResponse?: string;
  /** Type of the attendance of the participant. Whether they will be attending the online or physical location, either `online` or `physical`. If role is `interested`, allowed attendance only is `tbd` */
  attendanceType: string;
  /** The object for the event's location(s) */
  location?: EventLocation;
}

/** The payload for updating a participant for an event. ID is not required if first time creation. */
export interface UpsertEventParticipantRequest {
  /** Username of the profile as a participant for the event. Pass the username if the user is in baseplate. */
  username?: string;
  /** Email of the event participant. Regardless of whether the profile is in baseplate or not, always pass the email */
  email: string;
  /** Role of the participant. One of these `[host, co-host, speaker, attendee, waitlisted, interested]` */
  role: string;
  /** Response of the participant to the event's questionnaire. Optional only, depends on the event. */
  eventQuestionResponse?: string;
  /** Specify whether the participant is participating in `online` or `physical` location. */
  attendanceType: UpsertEventParticipantRequestAttendanceType;
  /** If attendanceType is physical, specify the location's address. Otherwise leave blank */
  eventLocationAddress?: string;
}

/** Indicates whether a location is free or occupied at a given time */
export interface EventLocationAvailability {
  /** The location's specific address */
  address?: string;
  /** Flag that indicates availability of the given address at a given time */
  free?: boolean;
}

/** Object that denotes the event object */
export interface Event extends EventBase {
  /** The event identifier */
  id: number;
  /** Participation of the current logged in user of the event (e.g. host, co-host, speaker, attending, interested, waitlisted) */
  currentUserParticipation: string;
  /** Attendance type of the current logged in user of the event (e.g. physical, online) */
  currentUserAttendanceType?: string;
  /** Status of the event */
  status?: string;
  counts: EventParticipantsCount;
}

/** The object for creating an event */
export interface CreateEventRequest extends EventBase {
  /** Indicator whether to save the event as draft or immediate publish */
  publish: boolean;
  /** Flag for whether to overwrite online location url to the link graph API will return for online meeting. Defaults to `True`, if property is not given, automatically acquire URL from graph response and overwrite the event's online location */
  generateOnlineUrl?: boolean;
}

/** Known values of {@link SkillRole} that the service accepts. */
export enum KnownSkillRole {
  /** None */
  None = 'None',
  /** Mentor */
  Mentor = 'Mentor',
  /** Mentee */
  Mentee = 'Mentee',
}

/**
 * Defines values for SkillRole. \
 * {@link KnownSkillRole} can be used interchangeably with SkillRole,
 *  this enum contains the known values that the service supports.
 * ### Known values supported by the service
 * **None** \
 * **Mentor** \
 * **Mentee**
 */
export type SkillRole = string;

/** Known values of {@link ModeOfConnection} that the service accepts. */
export enum KnownModeOfConnection {
  /** Mail */
  Mail = 'Mail',
  /** MsTeams */
  MsTeams = 'MsTeams',
}

/**
 * Defines values for ModeOfConnection. \
 * {@link KnownModeOfConnection} can be used interchangeably with ModeOfConnection,
 *  this enum contains the known values that the service supports.
 * ### Known values supported by the service
 * **Mail** \
 * **MsTeams**
 */
export type ModeOfConnection = string;

/** Known values of {@link MentorConnectionAction} that the service accepts. */
export enum KnownMentorConnectionAction {
  /** Request */
  Request = 'Request',
  /** Accept */
  Accept = 'Accept',
  /** Cancel */
  Cancel = 'Cancel',
  /** Pause */
  Pause = 'Pause',
  /** Complete */
  Complete = 'Complete',
  /** Resume */
  Resume = 'Resume',
}

/**
 * Defines values for MentorConnectionAction. \
 * {@link KnownMentorConnectionAction} can be used interchangeably with MentorConnectionAction,
 *  this enum contains the known values that the service supports.
 * ### Known values supported by the service
 * **Request** \
 * **Accept** \
 * **Cancel** \
 * **Pause** \
 * **Complete** \
 * **Resume**
 */
export type MentorConnectionAction = string;

/** Known values of {@link MentorshipConnectionRole} that the service accepts. */
export enum KnownMentorshipConnectionRole {
  /** Mentee */
  Mentee = 'Mentee',
  /** Mentor */
  Mentor = 'Mentor',
}

/**
 * Defines values for MentorshipConnectionRole. \
 * {@link KnownMentorshipConnectionRole} can be used interchangeably with MentorshipConnectionRole,
 *  this enum contains the known values that the service supports.
 * ### Known values supported by the service
 * **Mentee** \
 * **Mentor**
 */
export type MentorshipConnectionRole = string;

/** Known values of {@link MentorshipConnectionStatus} that the service accepts. */
export enum KnownMentorshipConnectionStatus {
  /** Pending */
  Pending = 'Pending',
  /** Active */
  Active = 'Active',
  /** Cancelled */
  Cancelled = 'Cancelled',
  /** Completed */
  Completed = 'Completed',
  /** Paused */
  Paused = 'Paused',
}

/**
 * Defines values for MentorshipConnectionStatus. \
 * {@link KnownMentorshipConnectionStatus} can be used interchangeably with MentorshipConnectionStatus,
 *  this enum contains the known values that the service supports.
 * ### Known values supported by the service
 * **Pending** \
 * **Active** \
 * **Cancelled** \
 * **Completed** \
 * **Paused**
 */
export type MentorshipConnectionStatus = string;

/** Known values of {@link EventStartDateTimeFilterOperation} that the service accepts. */
export enum KnownEventStartDateTimeFilterOperation {
  /** Default */
  Default = 'default',
  /** Exact */
  Exact = 'exact',
}

/**
 * Defines values for EventStartDateTimeFilterOperation. \
 * {@link KnownEventStartDateTimeFilterOperation} can be used interchangeably with EventStartDateTimeFilterOperation,
 *  this enum contains the known values that the service supports.
 * ### Known values supported by the service
 * **default** \
 * **exact**
 */
export type EventStartDateTimeFilterOperation = string;

/** Known values of {@link EventLocationAddressType} that the service accepts. */
export enum KnownEventLocationAddressType {
  /** Building */
  Building = 'building',
  /** ConferenceRoom */
  ConferenceRoom = 'conferenceRoom',
  /** Url */
  Url = 'url',
}

/**
 * Defines values for EventLocationAddressType. \
 * {@link KnownEventLocationAddressType} can be used interchangeably with EventLocationAddressType,
 *  this enum contains the known values that the service supports.
 * ### Known values supported by the service
 * **building** \
 * **conferenceRoom** \
 * **url**
 */
export type EventLocationAddressType = string;

/** Known values of {@link EventLocationType} that the service accepts. */
export enum KnownEventLocationType {
  /** Physical */
  Physical = 'physical',
  /** Online */
  Online = 'online',
}

/**
 * Defines values for EventLocationType. \
 * {@link KnownEventLocationType} can be used interchangeably with EventLocationType,
 *  this enum contains the known values that the service supports.
 * ### Known values supported by the service
 * **physical** \
 * **online**
 */
export type EventLocationType = string;

/** Known values of {@link UpsertEventParticipantRequestAttendanceType} that the service accepts. */
export enum KnownUpsertEventParticipantRequestAttendanceType {
  /** Physical */
  Physical = 'physical',
  /** Online */
  Online = 'online',
}

/**
 * Defines values for UpsertEventParticipantRequestAttendanceType. \
 * {@link KnownUpsertEventParticipantRequestAttendanceType} can be used interchangeably with UpsertEventParticipantRequestAttendanceType,
 *  this enum contains the known values that the service supports.
 * ### Known values supported by the service
 * **physical** \
 * **online**
 */
export type UpsertEventParticipantRequestAttendanceType = string;

/** Known values of {@link ParticipantRole} that the service accepts. */
export enum KnownParticipantRole {
  /** Host */
  Host = 'host',
  /** CoHost */
  CoHost = 'co-host',
  /** Speaker */
  Speaker = 'speaker',
  /** Attendee */
  Attendee = 'attendee',
  /** Waitlisted */
  Waitlisted = 'waitlisted',
  /** Interested */
  Interested = 'interested',
}

/**
 * Defines values for ParticipantRole. \
 * {@link KnownParticipantRole} can be used interchangeably with ParticipantRole,
 *  this enum contains the known values that the service supports.
 * ### Known values supported by the service
 * **host** \
 * **co-host** \
 * **speaker** \
 * **attendee** \
 * **waitlisted** \
 * **interested**
 */
export type ParticipantRole = string;

/** Known values of {@link EventStatus} that the service accepts. */
export enum KnownEventStatus {
  /** Draft */
  Draft = 'draft',
  /** Published */
  Published = 'published',
  /** Cancelled */
  Cancelled = 'cancelled',
}

/**
 * Defines values for EventStatus. \
 * {@link KnownEventStatus} can be used interchangeably with EventStatus,
 *  this enum contains the known values that the service supports.
 * ### Known values supported by the service
 * **draft** \
 * **published** \
 * **cancelled**
 */
export type EventStatus = string;

// source: https://learn.microsoft.com/en-us/graph/api/resources/patternedrecurrence?view=graph-rest-1.0
export type PatternedRecurrence = {
  pattern: RecurrencePattern;
  range: RecurrenceRange;
};

export type RecurrencePattern = {
  dayOfMonth?: number;
  daysOfWeek?: DayOfWeek[];
  firstDayOfWeek?: DayOfWeek;
  index?: WeekIndex;
  interval: number;
  month?: number;
  type: RecurrencePatternType;
};

export enum RecurrencePatternType {
  Daily = 'daily',
  Weekly = 'weekly',
  AbsoluteMonthly = 'absoluteMonthly',
  RelativeMonthly = 'relativeMonthly',
  AbsoluteYearly = 'absoluteYearly',
  RelativeYearly = 'relativeYearly',
}

export type RecurrenceRange = {
  endDate: string;
  numberOfOccurrences?: number;
  recurrenceTimeZone: string;
  startDate: string;
  type: RecurrenceRangeType;
};

export enum RecurrenceRangeType {
  EndDate = 'endDate',
  // NoEnd = 'noEnd', // not supported until we see the need for it from users
  Numbered = 'numbered',
}

export enum WeekIndex {
  First = 'first',
  Second = 'second',
  Third = 'third',
  Fourth = 'fourth',
  Last = 'last',
}

export enum DayOfWeek {
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
}

export enum RecurrencePatternTypeToDisplay {
  Weekly = 'weekly',
  Monthly = 'monthly',
}
export const dayOfWeekMapping = {
  [DayOfWeek.Sunday]: 0,
  [DayOfWeek.Monday]: 1,
  [DayOfWeek.Tuesday]: 2,
  [DayOfWeek.Wednesday]: 3,
  [DayOfWeek.Thursday]: 4,
  [DayOfWeek.Friday]: 5,
  [DayOfWeek.Saturday]: 6,
};
