import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useCallback, useState } from 'react';
import { GitHubRepository } from '../types';
import { LogLevel } from '../types/Logging';
import { logToElastic } from '../util/logs';
import { useConfig } from './useConfig';
import { useLoggingData } from './useLoggingData';

export const useGithubRepositories = () => {
  const [query, setQuery] = useState<string>('');
  const { BACKEND_BASE_URL, azureAccessToken } = useConfig();
  const { email, environment } = useLoggingData();

  const fetchGithubRepositories = async (): Promise<GitHubRepository[]> => {
    const response = await axios.get<GitHubRepository[]>(
      `${BACKEND_BASE_URL}/v1/github/repositories?q=${query}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: '*/*',
          Authorization: `Bearer ${azureAccessToken}`,
        },
      },
    );
    return response.data;
  };

  const {
    data: repositories = [],
    error,
    isLoading,
  } = useQuery<GitHubRepository[], Error>(
    ['repositories', query, azureAccessToken, BACKEND_BASE_URL],
    fetchGithubRepositories,
    {
      enabled: !!query && !!azureAccessToken && !!BACKEND_BASE_URL,
      onError: catchError => {
        (async () => {
          await logToElastic(
            `Error fetching repositories: ${catchError.message}`,
            LogLevel.ERROR,
            'useGithubRepositories.tsx',
            email,
            environment,
            BACKEND_BASE_URL,
            azureAccessToken,
          );
        })();
      },
    },
  );

  const searchRepositories = useCallback((nextQuery: string) => {
    setQuery(nextQuery);
  }, []);

  return { repositories, error, isLoading, searchRepositories };
};
