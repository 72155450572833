import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function BadgePercent(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M16.5 9.125c-.375.625-1.063.938-1.75.75-1.375-.438-2.875-.125-4 .938a3.883 3.883 0 0 0-.938 3.937c.188.688-.124 1.438-.75 1.813C7.813 17.188 7 18.5 7 20c0 1.563.813 2.875 2.063 3.5.624.375.937 1.063.75 1.75-.438 1.375-.063 2.875 1 3.938 1.062 1.062 2.562 1.437 3.937 1 .688-.188 1.438.125 1.75.75.688 1.25 2 2.062 3.5 2.062s2.813-.813 3.438-2.063c.375-.625 1.125-.937 1.812-.75 1.313.438 2.875.063 3.938-1 1.062-1.062 1.375-2.562.937-3.937-.188-.688.125-1.438.75-1.75 1.25-.688 2.125-2 2.125-3.5s-.875-2.813-2.125-3.438c-.625-.375-.938-1.125-.75-1.812a3.883 3.883 0 0 0-.938-3.938 3.883 3.883 0 0 0-3.937-.937c-.688.188-1.438-.125-1.813-.75A3.859 3.859 0 0 0 20 7c-1.563 0-2.875.875-3.5 2.125ZM20 4c2.188 0 4.188 1.063 5.5 2.75 2.063-.313 4.188.375 5.813 1.938A7.081 7.081 0 0 1 33.25 14.5C34.938 15.813 36 17.813 36 20c0 2.25-1.063 4.25-2.75 5.5.313 2.063-.375 4.25-1.938 5.813-1.625 1.625-3.75 2.25-5.812 2C24.187 34.937 22.187 36 20 36a6.93 6.93 0 0 1-5.5-2.688c-2.063.25-4.25-.374-5.813-1.937-1.624-1.625-2.25-3.75-2-5.813C5.063 24.25 4 22.25 4 20c0-2.188 1.063-4.188 2.688-5.5a7.081 7.081 0 0 1 1.937-5.813 7.081 7.081 0 0 1 5.813-1.937C15.75 5.062 17.75 4 20 4Zm-4 14c-1.125 0-2-.875-2-2 0-1.063.875-2 2-2 1.063 0 2 .938 2 2 0 1.125-.938 2-2 2Zm10 6c0 1.125-.938 2-2 2-1.125 0-2-.875-2-2 0-1.063.875-2 2-2 1.063 0 2 .938 2 2Zm-.938-6.938-8.062 8a1.37 1.37 0 0 1-2.063 0c-.624-.562-.624-1.5 0-2.125l8-7.937c.563-.625 1.5-.625 2.125 0 .563.563.563 1.5 0 2.063Z" />
    </SvgIcon>
  );
}
