import { OpenAPIV3_1 } from 'openapi-types';
import { resolveRef } from './utils/refResolver';

/**
 * Get the first [media type](https://swagger.io/specification/#media-type-object) object from the content object
 */
export const getFirstMediaObject = (
  content: {
    [mediaType: string]: OpenAPIV3_1.MediaTypeObject;
  },
  components: OpenAPIV3_1.ComponentsObject | undefined,
): {
  schema: OpenAPIV3_1.SchemaObject | OpenAPIV3_1.ReferenceObject | undefined;
  examples: Record<string, OpenAPIV3_1.ExampleObject>;
} => {
  const firstMedia = Object.values(content)[0];

  const examples = resolveRef<Record<string, OpenAPIV3_1.ExampleObject>>(
    'examples',
    firstMedia.examples,
    components,
  );

  return {
    schema: firstMedia.schema,
    examples,
  };
};
