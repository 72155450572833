import { OpenAPIV3_1 } from 'openapi-types';
import { UiEndpointType, UiParameterGroupType } from '../../../types';
import { resolveParameters } from './parameters';
import { resolveRequestBody } from './requestBody';
import { resolveResponses } from './responses';
import { createRequestExamples } from '../transform/createRequestExamples';
import { createResponseExamples } from '../transform/createResponseExamples';

/*
 * Resolve the [operation object](https://swagger.io/specification/#operation-object)
 * */
export const resolveOperation = ({
  path,
  method,
  operation,
  components,
  servers,
}: {
  path: string;
  method: OpenAPIV3_1.HttpMethods;
  operation: OpenAPIV3_1.OperationObject;
  components?: OpenAPIV3_1.ComponentsObject;
  servers?: OpenAPIV3_1.ServerObject[];
}): UiEndpointType => {
  const { bodyParameterGroup, requestExamples } = resolveRequestBody(
    operation.requestBody,
    components,
  );
  const { responseGroups, responseExamples } = resolveResponses(
    operation.responses,
    components,
  );

  const parameters: UiParameterGroupType = {
    ...resolveParameters(operation.parameters, components),
    ...bodyParameterGroup,
  };

  const formattedRequestExamples = createRequestExamples({
    examples: requestExamples,
    method,
    parameters,
    servers,
    path,
  });

  const formattedResponseExamples = createResponseExamples({
    examples: responseExamples,
    parameters: responseGroups,
  });

  return {
    path,
    method,
    summary: operation.summary,
    tags: operation.tags,
    parameters,
    responses: responseGroups,
    requestExamples: formattedRequestExamples,
    responseExamples: formattedResponseExamples,
  };
};
