import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Headset(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M7 20v2.5c0 .875-.688 1.5-1.5 1.5-.875 0-1.5-.625-1.5-1.5V20c0-8.813 7.125-16 16-16 8.813 0 16 7.188 16 16v9.063c0 3-2.5 5.5-5.5 5.5l-6.938-.063c-.5.938-1.5 1.5-2.562 1.5h-2c-1.688 0-3-1.313-3-3a3 3 0 0 1 3-3h2c1.063 0 2.063.625 2.563 1.5l6.937.063c1.375 0 2.5-1.125 2.5-2.5V20c0-7.125-5.875-13-13-13-7.188 0-13 5.875-13 13Zm7-2c-1.125 0-2 .938-2 2v3c0 1.125.875 2 2 2v-7Zm-5 2c0-2.75 2.188-5 5-5h1c1.063 0 2 .938 2 2v9c0 1.125-.938 2-2 2h-1c-2.813 0-5-2.188-5-5v-3Zm17-2v7c1.063 0 2-.875 2-2v-3c0-1.063-.938-2-2-2Zm5 2v3c0 2.813-2.25 5-5 5h-1c-1.125 0-2-.875-2-2v-9c0-1.063.875-2 2-2h1c2.75 0 5 2.25 5 5Z" />
    </SvgIcon>
  );
}
