import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

export default function MultipleUsers(props: any) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <g clipPath="url(#clip0_5711_8272)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.75 3.75C3.05964 3.75 2.5 4.30964 2.5 5C2.5 5.69036 3.05964 6.25 3.75 6.25C4.44036 6.25 5 5.69036 5 5C5 4.30964 4.44036 3.75 3.75 3.75ZM1.25 5C1.25 3.61929 2.36929 2.5 3.75 2.5C5.13071 2.5 6.25 3.61929 6.25 5C6.25 6.38071 5.13071 7.5 3.75 7.5C2.36929 7.5 1.25 6.38071 1.25 5Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.09835 8.59835C1.80161 7.89509 2.75544 7.5 3.75 7.5C4.09518 7.5 4.375 7.77982 4.375 8.125C4.375 8.47018 4.09518 8.75 3.75 8.75C3.08696 8.75 2.45107 9.01339 1.98223 9.48223C1.51339 9.95107 1.25 10.587 1.25 11.25V12.5H1.875C2.1902 12.5 2.45608 12.7347 2.49517 13.0475L3.05174 17.5H5C5.34518 17.5 5.625 17.7798 5.625 18.125C5.625 18.4702 5.34518 18.75 5 18.75H2.5C2.1848 18.75 1.91892 18.5153 1.87983 18.2025L1.32326 13.75H0.625C0.279822 13.75 0 13.4702 0 13.125V11.25C0 10.2554 0.395088 9.30161 1.09835 8.59835Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.25 3.75C15.5596 3.75 15 4.30964 15 5C15 5.69036 15.5596 6.25 16.25 6.25C16.9404 6.25 17.5 5.69036 17.5 5C17.5 4.30964 16.9404 3.75 16.25 3.75ZM13.75 5C13.75 3.61929 14.8693 2.5 16.25 2.5C17.6307 2.5 18.75 3.61929 18.75 5C18.75 6.38071 17.6307 7.5 16.25 7.5C14.8693 7.5 13.75 6.38071 13.75 5Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.625 8.125C15.625 7.77982 15.9048 7.5 16.25 7.5C17.2446 7.5 18.1984 7.89509 18.9017 8.59835C19.6049 9.30161 20 10.2554 20 11.25V13.125C20 13.4702 19.7202 13.75 19.375 13.75H18.6767L18.1202 18.2025C18.0811 18.5153 17.8152 18.75 17.5 18.75H15C14.6548 18.75 14.375 18.4702 14.375 18.125C14.375 17.7798 14.6548 17.5 15 17.5H16.9483L17.5048 13.0475C17.5439 12.7347 17.8098 12.5 18.125 12.5H18.75V11.25C18.75 10.587 18.4866 9.95107 18.0178 9.48223C17.5489 9.01339 16.913 8.75 16.25 8.75C15.9048 8.75 15.625 8.47018 15.625 8.125Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 1.25C8.96447 1.25 8.125 2.08947 8.125 3.125C8.125 4.16053 8.96447 5 10 5C11.0355 5 11.875 4.16053 11.875 3.125C11.875 2.08947 11.0355 1.25 10 1.25ZM6.875 3.125C6.875 1.39911 8.27411 0 10 0C11.7259 0 13.125 1.39911 13.125 3.125C13.125 4.85089 11.7259 6.25 10 6.25C8.27411 6.25 6.875 4.85089 6.875 3.125Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.46447 7.71447C7.40215 6.77678 8.67392 6.25 10 6.25C11.3261 6.25 12.5979 6.77678 13.5355 7.71447C14.4732 8.65215 15 9.92392 15 11.25V13.125C15 13.4702 14.7202 13.75 14.375 13.75H13.0656L12.4969 19.4372C12.4649 19.7567 12.1961 20 11.875 20H8.125C7.80391 20 7.53505 19.7567 7.5031 19.4372L6.93438 13.75H5.625C5.27982 13.75 5 13.4702 5 13.125V11.25C5 9.92392 5.52678 8.65215 6.46447 7.71447ZM10 7.5C9.00544 7.5 8.05161 7.89509 7.34835 8.59835C6.64509 9.30161 6.25 10.2554 6.25 11.25V12.5H7.5C7.82109 12.5 8.08995 12.7433 8.1219 13.0628L8.69062 18.75H11.3094L11.8781 13.0628C11.9101 12.7433 12.1789 12.5 12.5 12.5H13.75V11.25C13.75 10.2554 13.3549 9.30161 12.6517 8.59835C11.9484 7.89509 10.9946 7.5 10 7.5Z"
          fill="currentColor"
        />
      </g>
    </SvgIcon>
  );
}
