import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Grid2(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M9 9v6h6V9H9ZM6 9a3 3 0 0 1 3-3h6c1.625 0 3 1.375 3 3v6a3 3 0 0 1-3 3H9c-1.688 0-3-1.313-3-3V9Zm3 16v6h6v-6H9Zm-3 0a3 3 0 0 1 3-3h6c1.625 0 3 1.375 3 3v6a3 3 0 0 1-3 3H9c-1.688 0-3-1.313-3-3v-6ZM31 9h-6v6h6V9Zm-6-3h6c1.625 0 3 1.375 3 3v6a3 3 0 0 1-3 3h-6c-1.688 0-3-1.313-3-3V9a3 3 0 0 1 3-3Zm0 19v6h6v-6h-6Zm-3 0a3 3 0 0 1 3-3h6c1.625 0 3 1.375 3 3v6a3 3 0 0 1-3 3h-6c-1.688 0-3-1.313-3-3v-6Z" />
    </SvgIcon>
  );
}
