import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function WatchApple(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M15 4h9.938A3.067 3.067 0 0 1 28 7.063v1.062c2.25.438 4 2.5 4 4.875v14c0 2.438-1.75 4.438-4 4.938V33a3 3 0 0 1-3 3H15c-1.688 0-3-1.313-3-3v-1.063c-2.313-.5-4-2.5-4-4.937V13c0-2.375 1.688-4.438 4-4.875V7.062C12 5.375 13.313 4 15 4Zm12 7H13c-1.125 0-2 .938-2 2v14c0 1.125.875 2 2 2h14c1.063 0 2-.875 2-2V13c0-1.063-.938-2-2-2Zm-7 11c-1.125 0-2-.875-2-2 0-1.063.875-2 2-2 1.063 0 2 .938 2 2 0 1.125-.938 2-2 2Zm4 2.5c0 .875-.688 1.5-1.5 1.5-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5.813 0 1.5.688 1.5 1.5ZM17.5 26c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5ZM24 15.5c0 .875-.688 1.5-1.5 1.5-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5.813 0 1.5.688 1.5 1.5ZM17.5 17c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5Zm-.5 3c0 .875-.688 1.5-1.5 1.5-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5.813 0 1.5.688 1.5 1.5Zm7.5 1.5c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5Z" />
    </SvgIcon>
  );
}
