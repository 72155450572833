import {
  GeneralOverheadProduct,
  Product,
} from '@lego/plugin-baseplate-people-to-product-common';
import React from 'react';
import TooltipStatusWarning from '../TooltipStatusWarning/TooltipStatusWarning';
import Box from '@mui/material/Box';
import TooltipChip from '../TooltipChip/TooltipChip';
import { Chip } from '@lego/plugin-baseplate-core-components';

type CombinedProduct = Product | GeneralOverheadProduct;

interface ProductTagsProps {
  products: CombinedProduct[];
  employeeName?: string;
}

function ProductTags({ products, employeeName }: ProductTagsProps) {
  return (
    <>
      {products.length === 0 && (
        <TooltipStatusWarning
          title={
            employeeName ? `${employeeName} has no products` : 'No products'
          }
        />
      )}
      {products.length === 1 && <Chip label={products[0].name} color="blue" />}
      {products.length > 1 && (
        <Box sx={{ display: 'flex', alignItems: 'row' }}>
          <Chip label={products[0].name} color="blue" />
          <TooltipChip products={products.slice(1)} />
        </Box>
      )}
    </>
  );
}

export default ProductTags;
