import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function HandshakeSimple(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="m17 7.984-3.188 3.187c-1 .25-1.874.75-2.624 1.438l-1.626 1.437c-.687.563-1.5.875-2.374.875H3.5c-.875 0-1.5-.625-1.5-1.5 0-.812.625-1.5 1.5-1.5h3.688c.125 0 .25 0 .375-.125l1.625-1.437c1.75-1.563 4-2.438 6.312-2.438h.688c.25 0 .5.063.812.063Zm6.563-.063c2.25 0 4.437.875 6.125 2.375l1.875 1.688H36.5c.813 0 1.5.625 1.5 1.5 0 .812-.688 1.5-1.5 1.5H31c-.375 0-.75-.188-1-.438l-2.313-2c-1.125-1.062-2.625-1.562-4.125-1.562-1.625 0-3.125.562-4.312 1.687l-4.375 4.125a1.533 1.533 0 0 0 .063 2.25c.562.5 1.437.5 2-.062l4.5-4.125a1.549 1.549 0 0 1 2.125.062c.562.625.5 1.563-.063 2.125l-.063.063 4.625 4.625a4.866 4.866 0 0 1 1.25 2.187H36.5c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5h-7.563a3.26 3.26 0 0 1-.875 1.188 4.425 4.425 0 0 1-3.937 1.25c-.188.5-.5.875-.875 1.25-1.75 1.75-4.375 1.937-6.313.562a4.03 4.03 0 0 1-5.375-.312L9.938 29.17l-.438-.437-2.25-2.188c-.375-.375-.875-.562-1.438-.562H3.5a1.48 1.48 0 0 1-1.5-1.5c0-.875.625-1.5 1.5-1.5h2.313c1.375 0 2.625.5 3.562 1.437l2.25 2.25.438.438 1.687 1.625a.884.884 0 0 0 1.375 0c.125-.063.188-.188.25-.313.188-.5.563-.875 1.063-1 .5-.062 1.062.063 1.375.438l.687.625c.75.75 1.875.75 2.625 0 .313-.313.5-.75.563-1.188 0-.562.312-1.062.812-1.25.5-.25 1.125-.187 1.563.125A1.527 1.527 0 0 0 26 25.984c.563-.563.563-1.5 0-2.125l-4.75-4.75L19 21.17c-1.688 1.625-4.313 1.625-6.063.063-2-1.75-2-4.813-.124-6.625l4.374-4.125c1.75-1.625 4-2.563 6.375-2.563Z" />
    </SvgIcon>
  );
}
