import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Hand(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M21.023 4c1.563 0 2.938.938 3.563 2.25.437-.125.937-.25 1.437-.25 2.188 0 4 1.813 4 4v.125c.313-.063.625-.125 1-.125 2.188 0 4 1.813 4 4v11c0 .125-.062.188-.062.313A11.443 11.443 0 0 1 24.273 36h-1.312a15.99 15.99 0 0 1-11.313-4.688l-5.5-5.437a4.036 4.036 0 0 1 0-5.688 4.036 4.036 0 0 1 5.688 0l.187.188V10c0-2.188 1.75-4 4-4 .438 0 .938.125 1.375.25.625-1.313 2-2.25 3.625-2.25Zm-1 6.063V18.5c0 .875-.687 1.5-1.5 1.5-.875 0-1.5-.625-1.5-1.5V10c0-.5-.5-1-1-1-.562 0-1 .5-1 1v14c0 .625-.375 1.125-.937 1.375-.563.25-1.25.125-1.625-.313l-2.75-2.75c-.375-.375-1.063-.375-1.438 0s-.375 1.063 0 1.438l5.5 5.5c2.438 2.438 5.75 3.75 9.188 3.75h.937a8.428 8.428 0 0 0 8.063-8.063s0-.062.062-.125V14c0-.5-.5-1-1-1-.562 0-1 .5-1 1v4.5c0 .875-.687 1.5-1.5 1.5-.875 0-1.5-.625-1.5-1.5V10c0-.5-.5-1-1-1-.562 0-1 .5-1 1v8.5c0 .875-.687 1.5-1.5 1.5-.875 0-1.5-.625-1.5-1.5V8c0-.5-.5-1-1-1-.562 0-1 .5-1 1v2.063Z" />
    </SvgIcon>
  );
}
