import { useAzureTokenProvider } from '@lego/plugin-baseplate-auth';
import { useEffect, useState } from 'react';

export interface IUseAuthFlowProps {
  azureAccessToken: string;
  azureTokenError: boolean;
}

export const useAuthFlow = (scope: string): IUseAuthFlowProps => {
  const { getAccessToken } = useAzureTokenProvider();
  const [azureAccessToken, setAzureAccessToken] = useState('');
  const [azureTokenError, setAzureTokenError] = useState(false);

  const getAzureAccessToken = async () => {
    const token = await getAccessToken(scope);

    if (!token) {
      setAzureTokenError(true);
      throw new Error('Issue generating token');
    }
    setAzureAccessToken(token);
  };

  useEffect(() => {
    void getAzureAccessToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scope]);

  return {
    azureAccessToken,
    azureTokenError,
  };
};
