import {
  Button,
  baseplateStyles,
} from '@lego/plugin-baseplate-core-components';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@material-ui/core';
import Box from '@mui/material/Box';
import React from 'react';
import { useOpenPosition } from '../../../hooks';
import NewOpenPositionProductRow from './NewOpenPositionProductRow';
import OpenPositionProductTableRow from './OpenPositionProductTableRow';
import OpenPositionGeneralOverheadProductTable from '../OpenPostionGeneralOverheadProductTable/OpenPositionGeneralOverheadProductTable';
import AddIcon from '@material-ui/icons/Add';

const useStyles = baseplateStyles(theme => ({
  tableHeader: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    borderTop: `1px solid ${theme.semantic.border.default}`,
    borderBottom: `1px solid ${theme.semantic.border.default}`,
  },
}));

interface OpenPositionProductTableProps {
  openPositionId: number;
}

function OpenPositionProductTable({
  openPositionId,
}: OpenPositionProductTableProps) {
  const { openPositions } = useOpenPosition();
  const [isAddingProduct, setIsAddingProduct] = React.useState<boolean>(false);

  const classes = useStyles();

  const selectedOpenPosition = openPositions.find(
    openPosition => openPosition.positionId === openPositionId,
  );

  const product = selectedOpenPosition?.product;

  const tableColumns = [{ title: 'Name' }, { title: 'Actions' }];

  const handleOnAddProductButtonClick = () => {
    setIsAddingProduct(true);
  };

  return (
    <>
      {selectedOpenPosition && (
        <>
          <Paper>
            <Toolbar>
              <Typography variant="h5">Products</Typography>
              <Box flexGrow={1} />
              <Button
                variant="primary"
                onClick={handleOnAddProductButtonClick}
                disabled={
                  isAddingProduct || (product !== undefined && product !== null)
                }
              >
                <AddIcon />
                Add
              </Button>
            </Toolbar>
            <Table component={Paper}>
              <TableHead>
                <TableRow>
                  {tableColumns.map((column, index) => (
                    <TableCell key={index} className={classes.tableHeader}>
                      {column.title}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {product && (
                  <OpenPositionProductTableRow
                    openPosition={selectedOpenPosition}
                    product={product}
                  />
                )}
                {isAddingProduct && (
                  <NewOpenPositionProductRow
                    openPosition={selectedOpenPosition}
                    setIsAddingProduct={setIsAddingProduct}
                  />
                )}
              </TableBody>
            </Table>
          </Paper>
          <OpenPositionGeneralOverheadProductTable
            positionId={selectedOpenPosition.positionId}
          />
        </>
      )}
    </>
  );
}

export default OpenPositionProductTable;
