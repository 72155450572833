import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';
import { rootRouteRef } from './routes';

export const approvalFlowPlugin = createPlugin({
  id: 'approval-flow',
  featureFlags: [{ name: 'approval-flow' }],
  routes: {
    root: rootRouteRef,
  },
});

export const ApprovalFlowPage = approvalFlowPlugin.provide(
  createRoutableExtension({
    name: 'ApprovalFlowPage',
    component: () =>
      import('./components/ApprovalFlowComponent').then(
        m => m.ApprovalFlowComponent,
      ),
    mountPoint: rootRouteRef,
  }),
);
