import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Recycle(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M15.313 6.807A5.648 5.648 0 0 1 20 4.245c1.875 0 3.625 1 4.625 2.562l3.875 6.125.438-1.937c.188-.813.938-1.375 1.75-1.188.813.188 1.375.938 1.188 1.75l-1.188 5.938c-.125.375-.375.75-.75 1a1.72 1.72 0 0 1-1.25.125L23 16.682c-.812-.25-1.187-1.125-.937-1.875.25-.812 1.125-1.25 1.875-.937l2 .625-3.813-6.063c-.5-.687-1.312-1.187-2.125-1.187-.875 0-1.687.5-2.125 1.187L16.5 10.557c-.437.688-1.312.875-2.062.5A1.51 1.51 0 0 1 14 8.932l1.313-2.125Zm16.125 13.438c.688-.438 1.563-.188 2 .5l1.688 2.625c.562.875.812 1.875.875 2.875 0 3.062-2.438 5.5-5.5 5.5h-8.563l1.563 1.375c.562.562.624 1.5.124 2.125-.562.625-1.5.687-2.125.125l-4.5-4c-.312-.25-.5-.688-.5-1.125 0-.375.125-.813.5-1.063l4.5-4a1.493 1.493 0 0 1 2.125.125c.5.563.438 1.563-.125 2.063l-1.562 1.375h8.563c1.374 0 2.5-1.063 2.5-2.438 0-.5-.188-.937-.438-1.312l-1.625-2.688c-.5-.687-.25-1.625.438-2.062h.062Zm-19.5.25-.438-2.063-4.124 6.563c-.25.375-.375.812-.375 1.312-.063 1.375 1.062 2.438 2.437 2.438h3.063c.812 0 1.5.687 1.5 1.5 0 .875-.688 1.5-1.5 1.5H9.438c-3 0-5.5-2.438-5.437-5.5 0-1 .25-2 .812-2.875l4.063-6.438-1.938.688c-.75.25-1.625-.188-1.875-1-.25-.75.125-1.625.938-1.875l5.687-1.875a1.274 1.274 0 0 1 1.25.125c.375.187.625.562.75 1l1.188 5.875a1.456 1.456 0 0 1-1.188 1.75c-.813.187-1.563-.313-1.75-1.125Z" />
    </SvgIcon>
  );
}
