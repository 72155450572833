import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const ApiOverviewPlugin = createPlugin({
  id: 'plugin-baseplate-api-overview',
  routes: {
    root: rootRouteRef,
  },
});

export const ApiExplorerPage = ApiOverviewPlugin.provide(
  createRoutableExtension({
    name: 'ApiExplorerPage',
    component: () => import('./components/Apis/App').then(m => m.App),
    mountPoint: rootRouteRef,
  }),
);

export const AllApisPage = ApiOverviewPlugin.provide(
  createRoutableExtension({
    name: 'AllApisPage',
    component: () =>
      import('./components/ApiExplorerPage/ApiExplorerPage').then(
        m => m.ApiExplorerPage,
      ),
    mountPoint: rootRouteRef,
  }),
);

export const ApiGatewayStatusPage = ApiOverviewPlugin.provide(
  createRoutableExtension({
    name: 'ApiGatewayStatusPage',
    component: () =>
      import('./components/GatewayStatus/GatewayStatusPage').then(
        m => m.GatewayStatusPage,
      ),
    mountPoint: rootRouteRef,
  }),
);

export const EspStatusPage = ApiOverviewPlugin.provide(
  createRoutableExtension({
    name: 'EspStatusPage',
    component: () =>
      import('@lego/backstage-plugin-baseplate-esp-status').then(
        m => m.EspStatusPage,
      ),
    mountPoint: rootRouteRef,
  }),
);
