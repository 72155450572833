import {
  Entity,
  entityKindSchemaValidator,
  EntityMeta,
  KindValidator,
} from '@backstage/catalog-model';
import schema from './Tool.v1alpha.schema.json';
import { LeanIxPhase, LicenceType } from '../../types';

export interface ToolMetadata extends EntityMeta {
  categories?: string[];
  actions: {
    url?: string;
    title?: string;
    icon?: string;
    color?: 'primary' | 'secondary' | 'text' | 'destructive';
  }[];
  licence?: LicenceType;
}

export interface ToolEntity extends Entity {
  apiVersion: 'baseplate.legogroup.io/v1alpha1';
  kind: 'Tool';
  metadata: ToolMetadata;
  spec: {
    owner?: string;
    application?: string;
    image?: string;
    banner?: string;
    lifecycle: LeanIxPhase;
  };
}

const validator = entityKindSchemaValidator(schema);

export const toolEntityV1alpha1Validator: KindValidator = {
  // eslint-disable-next-line @typescript-eslint/require-await
  async check(data: Entity) {
    return validator(data) === data;
  },
};
