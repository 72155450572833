import { ChannelsObject } from '@asyncapi/parser/esm/spec-types/v2';
import { parseOperation } from './operation';
import { UiChannelType } from '../../../types';

export const parseChannels = (channels: ChannelsObject): UiChannelType[] => {
  return Object.entries(channels).map(([name, channel]): UiChannelType => {
    return {
      name: name,
      summary: channel.description,
      messages: parseOperation(channel.subscribe, channel.publish),
      classification: channel['x-classification'],
      topicAddress: channel['x-topic-address'],
    };
  });
};
