import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function TableTennisPaddleBall(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M29.75 10.203a10.977 10.977 0 0 0-15.563 0l-2.937 2.938 12.188 12.187a8.316 8.316 0 0 0-1.313 2.938l-12.75-12.75a5.414 5.414 0 0 0-.375 2v.125c0 1.375.5 2.625 1.438 3.562 2.124 2.125 1.874 5.625-.5 7.438l-2.876 2.187 2 2 2.25-2.812c1.813-2.375 5.313-2.625 7.438-.5l-2.125 2.125 2.125-2.125A5.004 5.004 0 0 0 22 30.953c.188 1.063.5 2.125 1 3h-.75c-2.063 0-4.125-.812-5.625-2.312-.813-.875-2.25-.75-2.938.187l-2.374 3.063a2.844 2.844 0 0 1-2.188 1.062c-.75 0-1.438-.25-1.938-.812L4.75 32.766c-.5-.5-.75-1.188-.75-1.938 0-.875.375-1.625 1.063-2.187l3.062-2.375c.938-.688 1-2.125.188-2.938C6.813 21.828 6 19.766 6 17.641v-.125c0-2.125.813-4.188 2.313-5.688l3.75-3.75a14.07 14.07 0 0 1 19.812 0 13.98 13.98 0 0 1 2.938 15.5c-.813-.562-1.75-1.062-2.688-1.312 1.688-4 .875-8.813-2.375-12.063ZM27 29.953c0 1.125.563 2.063 1.5 2.625.875.563 2.063.563 3 0 .875-.562 1.5-1.5 1.5-2.625 0-1.062-.625-2-1.5-2.562-.938-.563-2.125-.563-3 0-.938.562-1.5 1.5-1.5 2.562Zm9 0c0 2.188-1.188 4.125-3 5.25-1.875 1.063-4.188 1.063-6 0-1.875-1.125-3-3.062-3-5.25 0-2.125 1.125-4.062 3-5.187 1.813-1.063 4.125-1.063 6 0 1.813 1.125 3 3.062 3 5.187Z" />
    </SvgIcon>
  );
}
