import React from 'react';
import { useEntityLinkStyles } from './EntityLink.styles';
import { Entity } from '@backstage/catalog-model';
import { Typography } from '@material-ui/core';
import { truncate } from 'lodash';
import { EntityLinkVariant } from './types';

interface Props {
  entity: Entity;
  variant: EntityLinkVariant;
}

export const EntityLinkDescription = ({ entity, variant }: Props) => {
  const classes = useEntityLinkStyles();

  if (variant !== 'thumbnail') return null;

  const description = entity.metadata.description;
  if (!description) return null;
  return (
    <div className={classes.body}>
      <Typography color="textPrimary">
        {truncate(description, {
          length: 75,
        })}
      </Typography>
    </div>
  );
};
