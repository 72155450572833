import { useEntity } from '@backstage/plugin-catalog-react';
import { ToolEntity } from '@lego/plugin-baseplate-common';
import React from 'react';
import { MarkdownContent } from '@backstage/core-components';
import { makeStyles } from '@material-ui/core';
import {
  EntityTechdocsContent,
  isTechDocsAvailable,
} from '@backstage/plugin-techdocs';
import { FeatureFlagged } from '@backstage/core-app-api';
import {
  ReportIssue,
  LightBox,
} from '@backstage/plugin-techdocs-module-addons-contrib';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { Mermaid } from 'backstage-plugin-techdocs-addon-mermaid';
import {
  ColorLinks,
  MagicLinks,
  Codeblock,
  TableOfContentActions,
  GlobalStyles,
} from '../../../documentation';

const useStyles = makeStyles({
  description: {
    wordBreak: 'break-word',
  },
  documentationContainer: {
    maxWidth: '700px',
    margin: '0 auto',
    fontSize: '1rem',
  },
});

export const DocumentationContent = () => {
  const classes = useStyles();
  const { entity } = useEntity<ToolEntity>();

  return isTechDocsAvailable(entity) ? (
    <EntityTechdocsContent>
      <TechDocsAddons>
        <Mermaid
          config={{ theme: 'forest', themeVariables: { lineColor: '#000000' } }}
        />
        <ReportIssue />
        <ColorLinks />
        <MagicLinks />
        <LightBox />
        <Codeblock />
        <TableOfContentActions />
        <GlobalStyles />
      </TechDocsAddons>
    </EntityTechdocsContent>
  ) : (
    <div className={classes.documentationContainer}>
      {entity?.metadata?.documentation ? (
        <MarkdownContent
          className={classes.description}
          content={
            typeof entity?.metadata?.documentation === 'string'
              ? entity?.metadata?.documentation
              : ''
          }
        />
      ) : (
        <div>Documentation is not available for this entity.</div>
      )}
    </div>
  );
};
