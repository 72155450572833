import React from 'react';
import { ShortcutSymbol } from '../ShortcutSymbol';
import { baseplateStyles } from '@lego/plugin-baseplate-core-components';

const useStyles = baseplateStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1, 2),
    borderTop: `1px solid ${theme.semantic.border.default}`,
    gap: '1rem',
    color: theme.semantic.text.secondary,
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
  },
}));

export const SearchModalInstructions = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.item}>
        <ShortcutSymbol
          size="md"
          icon={
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.76244 16.1376L6.36244 19.7376C6.71494 20.0901 7.28494 20.0901 7.63369 19.7376L11.2337 16.1376C11.5862 15.7851 11.5862 15.2151 11.2337 14.8664C10.8812 14.5176 10.3112 14.5139 9.96244 14.8664L7.89994 16.9289V4.10012C7.89994 3.60137 7.49869 3.20012 6.99994 3.20012C6.50119 3.20012 6.09994 3.60137 6.09994 4.10012V16.9289L4.03744 14.8626C3.68494 14.5101 3.11494 14.5101 2.76619 14.8626C2.41744 15.2151 2.41369 15.7851 2.76619 16.1339L2.76244 16.1376ZM15.9624 3.46262L12.3624 7.06262C12.0099 7.41512 12.0099 7.98512 12.3624 8.33387C12.7149 8.68262 13.2849 8.68637 13.6337 8.33387L15.6962 6.27137V19.1001C15.6962 19.5989 16.0974 20.0001 16.5962 20.0001C17.0949 20.0001 17.4962 19.5989 17.4962 19.1001V6.27137L19.5587 8.33387C19.9112 8.68637 20.4812 8.68637 20.8299 8.33387C21.1787 7.98137 21.1824 7.41137 20.8299 7.06262L17.2374 3.46262C16.8849 3.11012 16.3149 3.11012 15.9662 3.46262H15.9624Z"
                fill="currentColor"
              />
            </svg>
          }
        />{' '}
        to navigate
      </div>
      <div className={classes.item}>
        <ShortcutSymbol size="md" text="Enter" /> to select
      </div>
      <div className={classes.item}>
        <ShortcutSymbol size="md" text="Esc" /> to close
      </div>
      <div className={classes.item}>
        <ShortcutSymbol
          size="md"
          icon={
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.69875 4.40002C8.06125 4.40002 7.45 4.65127 7 5.10127L1.3525 10.7525C1.1275 10.9775 1 11.2813 1 11.6C1 11.9188 1.1275 12.2225 1.3525 12.4475L7 18.0988C7.45 18.5488 8.06125 18.8 8.69875 18.8H20.2C21.5237 18.8 22.6 17.7238 22.6 16.4V6.80002C22.6 5.47627 21.5237 4.40002 20.2 4.40002H8.69875ZM20.8 6.80002V16.4C20.8 16.73 20.53 17 20.2 17H8.69875C8.54125 17 8.3875 16.9363 8.275 16.8238L3.0475 11.6L8.27125 6.37627C8.38375 6.26377 8.5375 6.20002 8.695 6.20002H20.2C20.53 6.20002 20.8 6.47002 20.8 6.80002ZM17.2375 8.56252C16.885 8.21002 16.315 8.21002 15.9662 8.56252L14.2037 10.325L12.4412 8.56252C12.0887 8.21002 11.5187 8.21002 11.17 8.56252C10.8212 8.91502 10.8175 9.48502 11.17 9.83377L12.9325 11.5963L11.17 13.3588C10.8175 13.7113 10.8175 14.2813 11.17 14.63C11.5225 14.9788 12.0925 14.9825 12.4412 14.63L14.2037 12.8675L15.9662 14.63C16.3187 14.9825 16.8887 14.9825 17.2375 14.63C17.5862 14.2775 17.59 13.7075 17.2375 13.3588L15.475 11.5963L17.2375 9.83377C17.59 9.48127 17.59 8.91127 17.2375 8.56252Z"
                fill="currentColor"
              />
            </svg>
          }
        />
        to go back
      </div>
    </div>
  );
};
