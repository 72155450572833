import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function WindowStopwatch(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <g fillRule="evenodd" clipRule="evenodd">
        <path d="M6.5 2c-2.25 0-4 1.813-4 4v20c0 2.25 1.75 4 4 4h10.04a9.137 9.137 0 0 1 .219-3H6.5c-.563 0-1-.438-1-1V14h29V6c0-2.188-1.813-4-4-4h-24Zm4 6c0 1.125-.938 2-2 2-1.125 0-2-.875-2-2 0-1.063.875-2 2-2 1.063 0 2 .938 2 2Zm4-2c1.063 0 2 .938 2 2 0 1.125-.938 2-2 2-1.125 0-2-.875-2-2 0-1.063.875-2 2-2Zm8 2c0 1.125-.938 2-2 2-1.125 0-2-.875-2-2 0-1.063.875-2 2-2 1.063 0 2 .938 2 2Z" />
        <path d="M26.423 16a1.39 1.39 0 0 0-1.384 1.375c0 .773.605 1.375 1.384 1.375h.692v1.504c-4.326.644-7.615 4.34-7.615 8.808 0 4.942 4.024 8.938 9 8.938 4.933 0 9-3.996 9-8.938 0-1.761-.563-3.437-1.471-4.855l1.038-1.031a1.319 1.319 0 0 0 0-1.934 1.34 1.34 0 0 0-1.947 0l-.952.902c-1.211-.988-2.682-1.632-4.283-1.89V18.75h.692c.735 0 1.384-.602 1.384-1.375 0-.73-.648-1.375-1.384-1.375h-4.154Zm2.077 7.219a1.05 1.05 0 0 1 1.038 1.031v5.5c0 .602-.476 1.031-1.038 1.031-.606 0-1.038-.43-1.038-1.031v-5.5a1.02 1.02 0 0 1 1.038-1.031Z" />
      </g>
    </SvgIcon>
  );
}
