import {
  Icon,
  SideMenu,
  baseplateStyles,
} from '@lego/plugin-baseplate-core-components';
import React from 'react';
import { type ReactElement } from 'react';
import { Route, Routes, useLocation } from 'react-router';
import { DocumentationExplorerPage } from './DocumentationExplorerPage';
import { GlobalSearchField } from '../search/GlobalSearchField';
import { ManageDocumentation } from './ManageDocumentation';
import { DocsAnalyticsPage } from '@lego/plugin-baseplate-analytics';
import { EntityListProvider } from '@backstage/plugin-catalog-react';
import { ManageLinkDocumentation } from './ManageLinkDocumentation';

const sidebarWidth = 300;

const useStyles = baseplateStyles(theme => ({
  sideNavigation: {
    position: 'fixed',
    top: theme.primitive.dimensions.nav.height,
    left: 0,
    height: `calc(100vh - ${theme.primitive.dimensions.nav.height})`,
    width: sidebarWidth,
    paddingLeft: theme.primitive.dimensions.page.horizontalPadding,
    backgroundColor: theme.semantic.background.neutral[1],
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      // '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.semantic.background.neutral[4],
    },
  },
  pageWrapper: {
    marginLeft: sidebarWidth,
  },
}));

export function DocumentationPage(): ReactElement {
  const classes = useStyles();
  const { pathname } = useLocation();

  return (
    <>
      <div className={classes.pageWrapper}>
        <nav className={classes.sideNavigation}>
          <SideMenu.Wrapper>
            <GlobalSearchField scope="Documentation" />
            <br />
            <SideMenu.Item
              label="All documentation"
              icon={<Icon icon="list" />}
              href="/docs"
              active={pathname === '/docs'}
            />
            <SideMenu.Section label="Manage documentation" />
            <SideMenu.Item
              label="Manage your documentation"
              href="/docs/manage"
              icon={<Icon icon="gear" />}
              active={pathname.includes('docs/manage')}
            />
            <SideMenu.Item
              label="Documentation metrics"
              icon={<Icon icon="chart-line" />}
              href="/docs/analytics"
              active={pathname.includes('docs/analytics')}
            />
          </SideMenu.Wrapper>
        </nav>
        <EntityListProvider>
          <Routes>
            <Route path="/" element={<DocumentationExplorerPage />} />
            <Route path="/manage" element={<ManageDocumentation />} />
            <Route
              path="/manage/:linkId"
              element={<ManageLinkDocumentation />}
            />
            <Route path="/analytics" element={<DocsAnalyticsPage />} />
          </Routes>
        </EntityListProvider>
      </div>
    </>
  );
}
