import { EntityLayout } from '@lego/plugin-baseplate-catalog';
import { Grid } from '@material-ui/core';
import React from 'react';

import { featureFlagsApiRef, useApi } from '@backstage/core-plugin-api';
import { NovusPage } from '@lego/plugin-baseplate-novus';
import { entityWarningContent } from '../ContentSections';
import { BaseplateAboutContent } from '../CustomComponents';
import { useEntity } from '@backstage/plugin-catalog-react';
import { useExternalLink } from '../hooks';
import { RouteTitleWithIcon } from './ExternalLinkUtils/RouteTitleWithIcon';
import { RenavigateContent } from './ExternalLinkUtils';

const ApplicationsPage = () => {
  const { entity } = useEntity();
  const featureFlagsApi = useApi(featureFlagsApiRef);
  const { externalLink } = useExternalLink(entity);

  return (
    <EntityLayout>
      <EntityLayout.Route path="/" title="Overview">
        <Grid container spacing={4} alignItems="stretch">
          {entityWarningContent}
          <Grid item xs={12}>
            <BaseplateAboutContent entity={entity} />
          </Grid>
        </Grid>
      </EntityLayout.Route>
      {featureFlagsApi.isActive('novus-plugin') && (
        <EntityLayout.Route path="/novus" title="Novus">
          <NovusPage />
        </EntityLayout.Route>
      )}
      {!!externalLink && (
        <EntityLayout.Route
          path={externalLink}
          title={(<RouteTitleWithIcon title="Docs" />) as unknown as string}
        >
          <RenavigateContent />
        </EntityLayout.Route>
      )}
    </EntityLayout>
  );
};

export default ApplicationsPage;
