import { IconComponent } from '@backstage/core-plugin-api';
import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';
import { useStyles } from './EntityIcon.styles';

export const ApplicationIcon: React.FC<IconComponent> = props => {
  const classes = useStyles();
  const iconClassName = `svg-icon-32`;

  return (
    <SvgIcon {...props}>
      <svg
        width={32}
        height={32}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={classes[iconClassName]}
      >
        <rect
          width="24"
          height="24"
          rx="8"
          className={classes['application-layer-3']}
        />
        <path
          d="M9.3 4.8C9.67407 4.8 9.975 5.10094 9.975 5.475V6.6H8.625V5.475C8.625 5.10094 8.92594 4.8 9.3 4.8Z"
          className={classes['application-layer-2']}
        />
        <path
          d="M5.475 8.625H6.6V9.975H5.475C5.10094 9.975 4.8 9.67407 4.8 9.3C4.8 8.92594 5.10094 8.625 5.475 8.625Z"
          className={classes['application-layer-2']}
        />
        <path
          d="M5.475 11.325H6.6V12.675H5.475C5.10094 12.675 4.8 12.3741 4.8 12C4.8 11.6259 5.10094 11.325 5.475 11.325Z"
          className={classes['application-layer-2']}
        />
        <path
          d="M5.475 14.025H6.6V15.375H5.475C5.10094 15.375 4.8 15.0741 4.8 14.7C4.8 14.3259 5.10094 14.025 5.475 14.025Z"
          className={classes['application-layer-2']}
        />
        <path
          d="M8.625 18.525V17.4H9.975V18.525C9.975 18.8991 9.67407 19.2 9.3 19.2C8.92594 19.2 8.625 18.8991 8.625 18.525Z"
          className={classes['application-layer-2']}
        />
        <path
          d="M11.325 18.525V17.4H12.675V18.525C12.675 18.8991 12.3741 19.2 12 19.2C11.6259 19.2 11.325 18.8991 11.325 18.525Z"
          className={classes['application-layer-2']}
        />
        <path
          d="M14.025 18.525V17.4H15.375V18.525C15.375 18.8991 15.0741 19.2 14.7 19.2C14.3259 19.2 14.025 18.8991 14.025 18.525Z"
          className={classes['application-layer-2']}
        />
        <path
          d="M18.525 15.375H17.4V14.025H18.525C18.8991 14.025 19.2 14.3259 19.2 14.7C19.2 15.0741 18.8991 15.375 18.525 15.375Z"
          className={classes['application-layer-2']}
        />
        <path
          d="M18.525 12.675H17.4V11.325H18.525C18.8991 11.325 19.2 11.6259 19.2 12C19.2 12.3741 18.8991 12.675 18.525 12.675Z"
          className={classes['application-layer-2']}
        />
        <path
          d="M18.525 9.975H17.4V8.625H18.525C18.8991 8.625 19.2 8.92594 19.2 9.3C19.2 9.67407 18.8991 9.975 18.525 9.975Z"
          className={classes['application-layer-2']}
        />
        <path
          d="M15.375 5.475V6.6H14.025V5.475C14.025 5.10094 14.3259 4.8 14.7 4.8C15.0741 4.8 15.375 5.10094 15.375 5.475Z"
          className={classes['application-layer-2']}
        />
        <path
          d="M12.675 5.475V6.6H11.325V5.475C11.325 5.10094 11.6259 4.8 12 4.8C12.3741 4.8 12.675 5.10094 12.675 5.475Z"
          className={classes['application-layer-2']}
        />
        <path
          d="M6.60001 8.4C6.60001 7.40719 7.40719 6.6 8.40001 6.6H15.6C16.5928 6.6 17.4 7.40719 17.4 8.4V15.6C17.4 16.5928 16.5928 17.4 15.6 17.4H8.40001C7.40719 17.4 6.60001 16.5928 6.60001 15.6V8.4Z"
          className={classes['application-layer-1']}
        />
        <path
          d="M10.2 9.3C9.70219 9.3 9.3 9.70219 9.3 10.2V13.8C9.3 14.2978 9.70219 14.7 10.2 14.7H13.8C14.2978 14.7 14.7 14.2978 14.7 13.8V10.2C14.7 9.70219 14.2978 9.3 13.8 9.3H10.2Z"
          className={classes['application-layer-2']}
        />
      </svg>
    </SvgIcon>
  );
};
