import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function WeightScale(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M12 15c0 2.875 1.5 5.5 4 6.938 2.438 1.437 5.5 1.437 8 0 2.438-1.438 4-4.063 4-6.938 0-2.813-1.563-5.438-4-6.875-2.5-1.438-5.563-1.438-8 0A7.928 7.928 0 0 0 12 15Zm19 0c0 6.125-4.938 11-11 11-6.125 0-11-4.875-11-11 0-1.375.25-2.75.75-4H8c-.563 0-1 .5-1 1v20c0 .563.438 1 1 1h24c.5 0 1-.438 1-1V12c0-.5-.5-1-1-1h-1.75c.438 1.25.75 2.625.75 4Zm-2.563-7H32c2.188 0 4 1.813 4 4v20c0 2.25-1.813 4-4 4H8c-2.25 0-4-1.75-4-4V12c0-2.188 1.75-4 4-4h3.5c2-2.438 5.063-4 8.5-4 3.375 0 6.438 1.563 8.438 4ZM22.5 18c0 1.438-1.125 2.5-2.5 2.5-1.438 0-2.5-1.063-2.5-2.5 0-1.375 1.063-2.438 2.438-2.5l2.125-4.875c.187-.5.812-.75 1.312-.5.5.188.75.813.5 1.313l-2.063 4.874c.375.438.688 1.063.688 1.688Z" />
    </SvgIcon>
  );
}
