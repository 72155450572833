import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ArrowUpArrowDown(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="m35.086 27.547-6 6c-.625.625-1.563.625-2.125 0l-6-6a1.369 1.369 0 0 1 0-2.063c.562-.625 1.5-.625 2.125 0l3.437 3.438V7.484c0-.812.625-1.5 1.5-1.5.813 0 1.5.688 1.5 1.5v21.438l3.438-3.438c.562-.625 1.5-.625 2.125 0 .562.563.562 1.5 0 2.063Zm-22-21.125 5.937 6c.625.625.625 1.562 0 2.125-.562.625-1.5.625-2.125 0l-3.437-3.438v21.375c0 .875-.625 1.5-1.5 1.5a1.48 1.48 0 0 1-1.5-1.5V11.11l-3.438 3.438c-.562.625-1.5.625-2.062 0-.625-.563-.625-1.5 0-2.125l6-6c.562-.563 1.5-.563 2.125 0Z" />
    </SvgIcon>
  );
}
