import { Entity } from '@backstage/catalog-model';
import { Card } from '@lego/plugin-baseplate-core-components';
import { Grid } from '@material-ui/core';
import React, { type ReactElement } from 'react';
import { AboutCard } from './AboutCard';
import { UsageCard } from './UsageCard';

export interface OverviewTabProps {
  entity: Entity;
}

export function OverviewTab({ entity }: OverviewTabProps): ReactElement {
  return (
    <div>
      <Grid container>
        <Grid item xs={12} sm={6} lg={4}>
          <AboutCard
            description={entity.metadata.description}
            entity={entity}
          />
        </Grid>
        <Grid container item xs={12} sm={6} lg={8}>
          <Grid item xs={12}>
            <Card title="Service level">
              <div></div>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <UsageCard entity={entity} />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
