import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ChartSimple(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M21 9h-2v22h2V9Zm-2-3h2c1.625 0 3 1.375 3 3v22a3 3 0 0 1-3 3h-2c-1.688 0-3-1.313-3-3V9a3 3 0 0 1 3-3Zm-8 15H9v10h2V21Zm-2-3h2c1.625 0 3 1.375 3 3v10a3 3 0 0 1-3 3H9c-1.688 0-3-1.313-3-3V21a3 3 0 0 1 3-3Zm20-5v18h2V13h-2Zm-3 0a3 3 0 0 1 3-3h2c1.625 0 3 1.375 3 3v18a3 3 0 0 1-3 3h-2c-1.688 0-3-1.313-3-3V13Z" />
    </SvgIcon>
  );
}
