import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function TrophyStar(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M13 7c.438 10.063 2.813 14.5 4.5 16.375.875.938 1.625 1.375 2.063 1.5.187.063.312.125.375.125.125 0 .25-.063.437-.125.438-.125 1.188-.563 2.063-1.5C24.125 21.5 26.5 17.062 26.875 7H13Zm0-3h14c1.625 0 3 1.375 2.938 3.063-.063.312-.063.625-.063.937H36.5c.813 0 1.5.688 1.5 1.5 0 6.813-2.875 11.125-6.375 13.813-3.375 2.625-7.25 3.625-9.313 4-.312.125-.562.25-.812.375V33h5c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5h-13c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5h5v-5.313c-.25-.125-.563-.25-.875-.375-2.063-.375-5.938-1.375-9.313-4C4.813 20.625 2 16.313 2 9.5 2 8.687 2.625 8 3.5 8h6.563c0-.313 0-.625-.063-.938C9.937 5.375 11.313 4 13 4Zm13.375 18.938c1.125-.5 2.313-1.125 3.438-2 2.5-1.938 4.75-5 5.125-9.938h-5.313c-.563 5.688-1.813 9.438-3.25 11.938Zm-16.25-2c1.125.875 2.313 1.5 3.438 2-1.438-2.5-2.688-6.25-3.25-11.938H5c.375 4.938 2.625 8 5.125 9.938Zm10.313-10.5.875 1.812c.062.188.25.25.375.313l2.062.25c.375.062.563.562.25.874l-1.438 1.438a.567.567 0 0 0-.187.438l.375 2c.063.437-.375.75-.75.5l-1.813-.938a.534.534 0 0 0-.437 0l-1.813.938c-.375.25-.812-.063-.75-.5l.375-2a.567.567 0 0 0-.187-.438l-1.438-1.438c-.312-.312-.124-.812.25-.874l2.063-.25c.125-.063.313-.126.375-.313l.875-1.813c.188-.374.75-.374.938 0Z" />
    </SvgIcon>
  );
}
