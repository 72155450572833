import React, { useState } from 'react';
import {
  AutocompleteDropdown,
  ProductRelationsPicker,
  useUserContext,
} from '@lego/plugin-baseplate-core-components';
import { FormControl, FormHelperText, Typography } from '@material-ui/core';
import useAsync from 'react-use/esm/useAsync';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { ProductRelationPickerExtensionProps } from './schema';
import { AvailableKinds } from '@lego/plugin-baseplate-common';
import { Entity, stringifyEntityRef } from '@backstage/catalog-model';
import { useParams } from 'react-router-dom';
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';

export const ProductRelationPickerExtension = ({
  onChange,
  required,
  formData,
  uiSchema,
  schema: { title, description },
  formContext,
}: ProductRelationPickerExtensionProps) => {
  const { product } = useUserContext();
  const catalogApi = useApi(catalogApiRef);
  const params = useParams();
  const fieldSchema = uiSchema['ui:options'];
  const [kind, setKind] = useState(fieldSchema?.kind ?? 'User');
  const chooseWhichKind = fieldSchema?.kindPicker ?? false;
  const options = fieldSchema?.kindPicker?.options as string[] | [];
  const kindPickerLabel = fieldSchema?.kindPicker?.label;
  const kindPickerHelperText = fieldSchema?.kindPicker?.helperText;
  const productSource = fieldSchema?.productSource ?? 'user';
  const valueProp = fieldSchema?.valueProp;

  const { value: productEntity } = useAsync(async () => {
    if (!fieldSchema?.kind) {
      throw new Error('Kind is required for EntityDisplayExtension');
    }

    let prodFilter;

    if (productSource === 'user' && product) {
      return product;
    }

    if (fieldSchema?.productSource === 'url' && params?.name) {
      prodFilter = { kind: kind, namespace: 'default', name: params.name };
    } else if (
      fieldSchema?.productSource === 'property' &&
      fieldSchema?.productProperty
    ) {
      const propertyName = fieldSchema.productProperty;
      const prodEntity = formContext?.formData[propertyName ?? ''];

      if (!prodEntity) {
        throw new Error(`${propertyName} is required for options`);
      }
      prodFilter = prodEntity;
    }

    if (!prodFilter) {
      throw new Error('Product filter is not defined. Unable to fetch entity.');
    }

    const entityResult = await catalogApi.getEntityByRef(prodFilter);

    return entityResult;
  }, [formContext?.formData]);

  const handleEntityChange = (selectedEntity: any) => {
    onChange(selectedEntity ? stringifyEntityRef(selectedEntity) : undefined);
  };

  const handleKindChange = (newKind: string) => {
    setKind(newKind);
  };

  return (
    <div
      style={{
        width: '100%',
        padding: 0,
        display: 'flex',
        justifyContent: 'space-between',
        gap: '1rem',
      }}
    >
      {chooseWhichKind && (
        <div style={{ flex: 1 }}>
          <FormControl style={{ width: '100%' }}>
            <AutocompleteDropdown
              options={options}
              required
              disableClearable
              value={kind}
              label={kindPickerLabel ?? 'Choose kind'}
              onChange={(_, value) => handleKindChange(value)}
            />
            {kindPickerHelperText && (
              <FormHelperText>
                <Typography variant="body2" color="secondary">
                  {kindPickerHelperText}
                </Typography>
              </FormHelperText>
            )}
          </FormControl>
        </div>
      )}
      <div style={{ flex: 1 }}>
        <FormControl required={required} style={{ width: '100%' }}>
          <ProductRelationsPicker
            label={chooseWhichKind ? title : title ?? 'Choose entity'}
            helperText={description}
            handleChange={handleEntityChange}
            placeholder={title}
            kind={kind as AvailableKinds}
            required
            product={productEntity as Entity}
            value={formData}
          />
        </FormControl>
      </div>
    </div>
  );
};
