import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function CartFlatbed(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M1.5 4h3C6.375 4 8 5.625 8 7.5v22c0 .313.188.5.5.5h30c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5H34a3 3 0 0 1-3 3c-1.688 0-3-1.313-3-3H16a3 3 0 0 1-3 3c-1.688 0-3-1.313-3-3H8.5A3.494 3.494 0 0 1 5 29.5v-22c0-.25-.25-.5-.5-.5h-3C.625 7 0 6.375 0 5.5 0 4.687.625 4 1.5 4ZM15 9v14h20V9h-6v6c0 .375-.25.75-.563.938a1.131 1.131 0 0 1-1-.063L25 14.25l-2.5 1.625c-.25.188-.688.188-1 .063-.313-.188-.5-.563-.5-.938V9h-6Zm-3 0a3 3 0 0 1 3-3h20c1.625 0 3 1.375 3 3v14a3 3 0 0 1-3 3H15c-1.688 0-3-1.313-3-3V9Z" />
    </SvgIcon>
  );
}
