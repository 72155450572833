/*
 * Copyright 2022 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';
import { AbstractClient } from '../AbstractClient';
import {
  StorageCredentialUpdateRequest,
  StorageCredentialsApi,
  DataProductStorageCredential,
  StorageCredentialCreateRequest,
  UpdatePermissionsRequest,
  PermissionsResponse,
  DataProductExternalLocation,
} from '@lego/plugin-baseplate-nexus-databricks-storage-credentials-and-external-locations-common';

export class StorageCredentialsClient
  extends AbstractClient
  implements StorageCredentialsApi
{
  public constructor(options: {
    discoveryApi: DiscoveryApi;
    fetchApi: FetchApi;
  }) {
    super(
      'nexus-databricks-storage-credentials-and-external-locations',
      options.discoveryApi,
      options.fetchApi,
    );
  }

  public async list(
    dataProduct?: string,
  ): Promise<DataProductStorageCredential[]> {
    let url = 'storage-credentials';
    if (dataProduct) {
      url = url.concat(`?dataproduct=${dataProduct}`);
    }

    return this.fetch<DataProductStorageCredential[]>({
      path: url,
      method: 'GET',
      showProgress: {
        error: 'Failed to list Storage Credentials',
        pending: dataProduct
          ? `Fetching Storage Credentials for Data Product '${dataProduct}'`
          : false,
        success: false,
      },
    });
  }

  public async create(
    dataProduct: string,
    storageCredentialsCreateRequest: StorageCredentialCreateRequest,
  ): Promise<DataProductStorageCredential> {
    return this.fetch<DataProductStorageCredential>({
      path: `storage-credentials/${dataProduct}`,
      method: 'POST',
      body: storageCredentialsCreateRequest,
      showProgress: {
        pending: 'Creating Storage Credential',
        success: 'Successfully created Storage Credential',
        error: 'Failed to create Storage Credential',
      },
    });
  }

  public async update(
    dataProduct: string,
    name: string,
    storageCredentials: Partial<StorageCredentialUpdateRequest>,
  ): Promise<DataProductStorageCredential> {
    return this.fetch<DataProductStorageCredential>({
      path: `storage-credentials/${dataProduct}/${name}`,
      method: 'PATCH',
      body: storageCredentials,
      showProgress: {
        pending: 'Updating Storage Credential',
        success: 'Successfully updated Storage Credential',
        error: 'Failed to update Storage Credential',
      },
    });
  }

  public getPermissions(
    dataProduct: string,
    storageCredentialName: string,
  ): Promise<PermissionsResponse> {
    return this.fetch({
      path: `storage-credentials/${dataProduct}/${storageCredentialName}/permissions`,
      method: 'GET',
      showProgress: {
        error: 'Failed to list Storage Credential Permissions',
        pending: false,
        success: false,
      },
    });
  }

  public updatePermissions(
    dataProduct: string,
    name: string,
    updatePermissionsRequest: Partial<UpdatePermissionsRequest>,
  ): Promise<PermissionsResponse> {
    throw new Error('Not Implemented');
  }

  public async delete(dataProduct: string, name: string): Promise<object> {
    return this.fetch({
      path: `storage-credentials/${dataProduct}/${name}`,
      method: 'DELETE',
      showProgress: {
        pending: 'Deleting Storage Credential',
        success: 'Successfully deleted Storage Credential',
        error: 'Failed to delete Storage Credential',
      },
    });
  }

  public async getUsages(name: string): Promise<DataProductExternalLocation[]> {
    return this.fetch({
      path: `storage-credentials/${name}/usage`,
      method: 'GET',
      showProgress: {
        error: 'Failed to list Storage Credential Usages',
        pending: false,
        success: false,
      },
    });
  }
}
