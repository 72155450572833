export const dateFormats = {
  default: 'DD-MM-YYYY',
  default_v3: 'YYYY-MM-DD',
  defaultlong: 'dddd MMMM DD, YYYY HH:mm',
  default_v2: 'dd-MM-yyyy',
  default_v2_slashes: 'dd/MM/yyyy',
  day: 'dd',
  month: 'MMM',
  year: 'yyyy',
  day_of_week: 'EEEEE',
  month_day: 'd',
  long_date: 'MMMM dd, yyyy EEEE',
  long_date_V2: 'MMMM DD, yyyy',
  week: 'w',
  short_date: 'MMMM d',
  short_date_dayOfWeek: 'MMMM dd, EEE',
  time: 'HH:mm',
  date_slashes: 'MM/dd/yyyy',
  datetime: 'dd/MM/yyyy hh:mm',
  yyyymmdd: 'yyyy-MM-dd',
  date_slashes_dayOfWeek: 'MM/dd/yyyy, EEE',
  date_slashes_ddmmyyyy: 'dd/MM/yyyy',
  date_slashes_ddmmyyyy_EEE: 'dd/MM/yyyy, EEE',
  datepicker: 'DD/MM/YYYY',
  date_short_date_dayOfWeek: 'DD/MM/YYYY, ddd',
  dateTimeHH: 'DD/MM/YYYY HH:mm',
  day_of_week_half: 'EEEEE',
  mmddyyyyhhmmssampm: 'MM/DD/YYYY, hh:mm:ss A',
  short_date_month: 'MMM DD, YYYY',
  short_date_month_w_day: 'ddd, MMM DD, YYYY',
  short_day_date_month_wo_y: 'ddd, MMM DD, YYYY',
  long_day_date_month_w_y: 'dddd, MMMM DD, YYYY',
  short_month_wo_year: 'MMM DD',
  iso_date_tring: 'mm-dd-yyyyTHH:mm:ss.sssZ',
};
