import React from 'react';
import ConfirmationPopover from '../../components/ConfirmationPopover/ConfirmationPopover';
import TooltipDeleteIconButton from '../../components/TooltipDeleteIconButton/TooltipDeleteIconButton';
import {
  GeneralOverheadProduct,
  Product,
} from '@lego/plugin-baseplate-people-to-product-common';

interface DeleteProductButtonProps {
  isDisabled: boolean;
  onClick: () => void;
  product: Product | GeneralOverheadProduct;
}

function DeleteProductButton({
  isDisabled,
  onClick,
  product,
}: DeleteProductButtonProps) {
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleOnDeleteButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleOnPopoverConfirm = () => {
    onClick();
    setOpen(false);
  };

  const handleOnPopoverCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <TooltipDeleteIconButton
        isDisabled={isDisabled}
        onClick={handleOnDeleteButtonClick}
      />
      <ConfirmationPopover
        open={open}
        anchorEl={anchorEl}
        message={`Are you sure you want to unassign ${product.name} (${product.id})?`}
        onConfirm={handleOnPopoverConfirm}
        onCancel={handleOnPopoverCancel}
      />
    </>
  );
}

export default DeleteProductButton;
