import { Content, Link, Page } from '@backstage/core-components';
import {
  Button,
  Card,
  EntityLink,
  baseplateStyles,
} from '@lego/plugin-baseplate-core-components';
import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import copilotImage from './home-page-assets/copilot.png';
import { HighlightedToolCard } from '../components/HighlightedToolCard';
import { ToolEntity } from '@lego/plugin-baseplate-common';
import { pavedPathTools } from '../utils/pavedPathTools';

const useStyles = baseplateStyles(theme => ({
  pageWrapper: {
    maxWidth: 1200,
  },
  vertical: {
    '& img': {
      width: '100%',
      aspectRatio: 4 / 2,
      objectFit: 'cover',
    },
    '& $cardContent': {
      padding: '1rem',
    },
  },
  horizontal: {
    '& > div': {
      display: 'grid',
      gridTemplateColumns: 'repeat(2,1fr)',
      alignItems: 'center',
    },
    '& img': {
      width: '100%',
      height: '100%',
      aspectRatio: 4 / 3,
      objectFit: 'cover',
      order: 2,
    },
    '& $cardContent': {
      padding: '1.5rem',
    },
  },
  cardContent: {
    '& > * + *': {
      marginTop: '1em !important',
    },
  },
  popularList: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
    gap: '1rem',
  },
  betaCard: {
    backgroundColor: theme.semantic.background.info,
    padding: '2rem',
    borderRadius: theme.primitive.borderRadius.default,
    '& > *': {
      color: theme.semantic.text.info,
    },
    '& p + p': {
      marginTop: '1em',
    },
    '& > div': {
      display: 'flex',
      gap: '1rem',
      marginTop: '1rem',
    },
  },
  section: {
    marginTop: '2rem',
  },
}));

const ToolsHomePageCard = ({
  children,
  image,
}: {
  children: React.ReactNode;
  image: string;
}) => {
  const classes = useStyles();

  return (
    <Card padding="none" className={classes.horizontal}>
      <img alt="" src={image} />
      <div className={classes.cardContent}>{children}</div>
    </Card>
  );
};

export const ToolsHomePage = ({
  popularTools,
  dataPlatformTools,
}: {
  popularTools: ToolEntity[];
  dataPlatformTools: ToolEntity[];
}) => {
  const classes = useStyles();
  return (
    <Page themeId="home">
      <Content className={classes.pageWrapper}>
        <Grid container>
          <Grid item xs={12}>
            <div className={classes.betaCard}>
              <Typography variant="overline">beta release</Typography>
              <Typography variant="h2">
                A new overview of developer tools
              </Typography>
              <Typography variant="body1">
                As developers at the LEGO Group, it can be challenging to stay
                up to date on what tools are available. To address this, we've
                created a new overview specifically for developers.
              </Typography>
              <Typography variant="body1">
                Currently, this catalog of tools is stored in a GitHub
                repository called{' '}
                <a href="https://github.com/LEGO/lego-tools" target="_blank">
                  "lego-tools"
                </a>
                . This is a temporary, manually maintained solution. Our aim is
                to together with other product teams in the LEGO Group,
                establish a centralized catalog with up to date licence
                information, security compliance and user documentation.
              </Typography>
              <div>
                <Button
                  href="https://github.com/LEGO/lego-tools/blob/main/CONTRIBUTING.md"
                  variant="secondary"
                >
                  Contribute to the catalog
                </Button>
                <Button
                  href="/catalog/default/user/tobias.wulvik"
                  variant="secondary"
                >
                  Send feedback
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <ToolsHomePageCard image={copilotImage}>
              <Typography variant="h2">GitHub Copilot</Typography>
              <Typography variant="body1">
                GitHub Copilot is an AI pair programmer that offers
                autocomplete-style suggestions as you code. You can receive
                suggestions from GitHub Copilot either by starting to write the
                code you want to use, or by writing a natural language comment
                describing what you want the code to do.
              </Typography>
              <Button
                href="/catalog/default/tool/github-copilot"
                variant="primary"
              >
                Get access
              </Button>
            </ToolsHomePageCard>
          </Grid>
          <Grid item xs={12} className={classes.section}>
            <Typography variant="subtitle1">Nexus tools </Typography>
            <Typography variant="body1">
              Use these tools to work with LEGO Nexus the internal data
              platform. You can find their documentation{' '}
              <Link to="/docs/default/Component/lego-nexus">here</Link>.
            </Typography>
            <br />
            <div className={classes.popularList}>
              {dataPlatformTools.map(tool => (
                <EntityLink entity={tool} variant="thumbnail" />
              ))}
            </div>
          </Grid>
          <Grid item xs={12} className={classes.section}>
            <Typography variant="subtitle1">
              Essential Developer Tools
            </Typography>
            <Typography variant="body1">
              The essential list of tools for any developer in the LEGO Group.
            </Typography>
            <br />
            <div className={classes.popularList}>
              {popularTools.map(tool => (
                <EntityLink entity={tool} variant="thumbnail" />
              ))}
            </div>
          </Grid>
        </Grid>
      </Content>
    </Page>
  );
};
