import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Gear(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M20.008 4c1.062 0 2.062.125 3.062.313.5.125 1.375.375 1.875 1.25.125.25.188.5.25.75l.625 2.437c.063.313.688.688 1.063.563l2.375-.688c.25-.063.5-.125.75-.125 1 0 1.687.625 2 1a15.977 15.977 0 0 1 3.125 5.375c.187.5.375 1.375-.188 2.188-.125.25-.312.437-.5.625l-1.75 1.75c-.312.25-.312.937 0 1.187l1.75 1.75c.188.188.375.375.5.625.5.813.313 1.688.188 2.188a15.99 15.99 0 0 1-3.125 5.375c-.313.375-1 1-2 1-.25 0-.5-.063-.75-.125l-2.375-.75c-.375-.063-1 .25-1.063.625l-.625 2.437c-.062.25-.125.5-.25.75-.5.875-1.375 1.125-1.875 1.25-1 .188-2 .25-3.062.25-1.063 0-2.125-.063-3.125-.25-.5-.125-1.375-.375-1.875-1.25-.125-.25-.188-.5-.25-.75l-.625-2.438c-.063-.375-.688-.687-1-.625l-2.375.75c-.25.063-.563.063-.813.125-1 0-1.687-.625-2-1a15.977 15.977 0 0 1-3.125-5.375c-.125-.5-.312-1.375.188-2.25a4.4 4.4 0 0 1 .5-.625l1.812-1.687c.25-.25.25-.938 0-1.188l-1.812-1.75c-.188-.187-.375-.375-.5-.625-.5-.812-.313-1.687-.188-2.187.688-2 1.75-3.813 3.125-5.375.313-.375 1-1 2-1 .25 0 .563.063.813.125l2.375.688c.312.124.937-.25 1-.563l.625-2.438c.062-.25.125-.5.25-.75.5-.875 1.375-1.125 1.875-1.25 1-.187 2.062-.312 3.125-.312Zm-2.375 3.25-.563 2.188c-.5 2-2.812 3.374-4.812 2.812l-2.188-.688c-1 1.25-1.812 2.626-2.375 4.126l1.688 1.562c1.5 1.438 1.5 4.125 0 5.563l-1.688 1.562c.563 1.5 1.375 2.875 2.375 4.125l2.188-.688c2-.562 4.312.813 4.812 2.813l.563 2.188c1.5.312 3.187.312 4.75 0l.5-2.188c.5-2 2.812-3.375 4.812-2.813l2.188.688c1-1.25 1.812-2.625 2.375-4.125l-1.625-1.563c-1.5-1.437-1.5-4.125 0-5.562l1.625-1.563c-.563-1.5-1.375-2.874-2.375-4.124l-2.188.687c-1.937.563-4.312-.813-4.812-2.813l-.5-2.187c-1.563-.313-3.25-.313-4.75 0ZM17.008 20c0 1.125.562 2.063 1.5 2.625.875.563 2.062.563 3 0 .875-.563 1.5-1.5 1.5-2.625 0-1.063-.625-2-1.5-2.563-.938-.562-2.125-.562-3 0-.938.563-1.5 1.5-1.5 2.563Zm3 6c-2.188 0-4.125-1.125-5.25-3-1.063-1.813-1.063-4.125 0-6 1.125-1.813 3.062-3 5.25-3 2.125 0 4.062 1.188 5.187 3 1.063 1.875 1.063 4.188 0 6-1.125 1.875-3.062 3-5.187 3Z" />
    </SvgIcon>
  );
}
