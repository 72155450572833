/* eslint-disable @typescript-eslint/restrict-template-expressions */
/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  EnvironmentStages,
  useEnvironmentStage,
} from '@lego/plugin-baseplate-core-components';

import { SearchContextProvider } from '@backstage/plugin-search-react';
import { useClientSideAnalytics } from '@lego/plugin-baseplate-analytics';
import { useTheme } from '@material-ui/core';
import React, { PropsWithChildren, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { DevModePanel } from '../dev-mode/DevModePanel';
import { Navigation } from '../navigation/Navigation';
import { SearchModal } from '../search/modal/SearchModal';
import { SupportButton } from '../support/SupportButton';
import { AiModal } from './AiModal';

const getAnalyticsScript = ({
  isDevEnvironment,
  isLocalEnvironment,
  isTestEnvironment,
}: EnvironmentStages): string => {
  const analyticsScriptUrls = {
    dev: 'https://assets.adobedtm.com/113bc1fcf650/518e2ad5f195/launch-c9758209c7fd-development.min.js',
    test: 'https://assets.adobedtm.com/113bc1fcf650/518e2ad5f195/launch-ea1d3c5948d1-staging.min.js',
    prod: 'https://assets.adobedtm.com/113bc1fcf650/518e2ad5f195/launch-8bc8c146de5c.min.js',
  };
  if (isDevEnvironment || isLocalEnvironment) {
    return analyticsScriptUrls.dev;
  } else if (isTestEnvironment) {
    return analyticsScriptUrls.test;
  }
  return analyticsScriptUrls.prod;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const Root = ({ children }: PropsWithChildren<{}>) => {
  const environmentStages = useEnvironmentStage();
  const analyticsScript = getAnalyticsScript(environmentStages);

  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const _paq = (window._paq = window._paq || []);
    _paq.push(['trackPageView']);
    _paq.push(['enableLinkTracking']);
    const matomoSiteId = environmentStages.isProdEnvironment ? 44 : 45;
    (function matomo() {
      const u = '//matomo.apps.lego.com/';
      _paq.push(['setDoNotTrack', false]);
      _paq.push(['setTrackerUrl', `${u}matomo.php`]);
      _paq.push(['setSiteId', matomoSiteId]);
      const d = document;
      const g = d.createElement('script');
      const s = d.getElementsByTagName('script')[0];
      g.type = 'text/javascript';
      g.async = true;
      g.src = `${u}matomo.js`;
      s.parentNode!.insertBefore(g, s);
    })();
  }, [environmentStages.isProdEnvironment]);

  useClientSideAnalytics();

  // Ensure browsers use their light dark modes for default components. For example scrollbars
  const {
    palette: { type: mode },
  } = useTheme();

  useEffect(() => {
    document.documentElement.style.setProperty('color-scheme', mode);
  }, [mode]);

  return (
    <>
      <Helmet>
        <meta
          httpEquiv="Content-Security-Policy"
          // Default Helmet Backend Backstage's CSP:
          // content="default-src 'self';base-uri 'self';block-all-mixed-content;font-src 'self' https: data:;frame-ancestors 'self';img-src 'self' data:;object-src 'none';script-src 'self' 'unsafe-eval';script-src-attr 'none';style-src 'self' https: 'unsafe-inline';upgrade-insecure-requests;connect-src 'self' http: https:"
          // For the frontend needs the CSP got adjusted:1/ frame-ancestors 'self' is not supported as meta tag.  2/ img-src allows any origin as techdocs images + adobe analytics seem to be easily changing, thus breaking. 3/ upgrade-insecure-requests is omitted as http used for local development 4/ style-src http: for techdocs locally
          // This CSP is medium security (according to https://csp-evaluator.withgoogle.com/ ) as it allows 'unsafe-eval' (removing it breaks upstream's codebase). require-trusted-types-for [missing] is breaking as well.
          // Note: any adjustments should also be performed on Nginx, which can have a more hardened CSP (e.g allows for reporting and can have effective frame-ancestors 'self' ).
          // Setting it to the meta tag as well is still valuable as a CSP cannot be set on upstream's WebpackDevServer so local development is more close to the hosted environments.
          content="default-src 'self';base-uri 'self';block-all-mixed-content;font-src 'self' https: data:;img-src 'self' data: https:;object-src 'none';script-src 'self' 'unsafe-eval';script-src-elem 'self' ws://localhost:8000 ws://baseplate-dev.legogroup.io:8000 ws://baseplate-test.legogroup.io:8000 ws://baseplate.legogroup.io:8000 https://assets.adobedtm.com https://matomo.apps.lego.com http://matomo.apps.lego.com 'unsafe-inline';script-src-attr 'none';style-src 'self' http: https: 'unsafe-inline';;connect-src 'self' ws: 'self' http: https:;frame-src 'self' https://lego.demdex.net https://app.powerbi.com/ https://baseplate-dev.legogroup.io https://baseplate-test.legogroup.io https://baseplate.legogroup.io"
        />
        <script src={analyticsScript} />
      </Helmet>
      <div id="modal-container">
        <AiModal />
      </div>
      <Navigation />
      <SearchContextProvider>
        <SearchModal />
      </SearchContextProvider>
      <SupportButton />
      {environmentStages.isLocalEnvironment && <DevModePanel />}
      {children}
    </>
  );
};
