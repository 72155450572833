import React, { useState } from 'react';
import { type ReactElement } from 'react';
import {
  CodeChip,
  Icon,
  baseplateStyles,
} from '@lego/plugin-baseplate-core-components';
import { IconButton, Typography } from '@material-ui/core';
import { UiParameterType } from '../../../types';

const indent = 1.5;

const useStyles = baseplateStyles(theme => ({
  wrapper: {
    '& > td': {
      verticalAlign: 'baseline',
    },
  },
  parameterName: {
    position: 'relative',
    minWidth: 150,
  },
  description: {
    paddingLeft: '1rem',
    paddingBottom: '1rem',
    width: '100%',
  },
  type: {
    fontFamily: theme.primitive.font.family.mono,
    color: theme.semantic.text.code.string,
  },
  indentMarker: {
    display: 'block',
    position: 'absolute',
    height: '100%',
    width: `${indent / 2}rem`,
    borderLeft: `1px solid ${theme.semantic.border.default}`,
    top: '50%',
    transform: 'translateY(-50%)',
    '&:last-of-type:after': {
      content: '""',
      width: `${indent / 2}rem`,
      left: 0,
      top: '1rem',
      height: 1,
      position: 'absolute',
      backgroundColor: theme.semantic.border.default,
    },
  },
  openToggleIcon: {
    transition: 'transform 200ms ease-in-out',
  },
  open: {
    transform: 'rotate(180deg)',
  },
  enum: {
    color: theme.semantic.text.code.string,
  },
  required: {
    color: theme.semantic.shape.error,
    marginLeft: 4,
  },
}));

export function Parameter({
  name,
  required,
  enums,
  type,
  description,
  additionalDescription,
  items,
  nestedLevel = 0,
}: UiParameterType & { nestedLevel?: number }): ReactElement {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const toggleAccordion = () => {
    setOpen(prev => !prev);
  };

  return (
    <>
      <tr
        className={[classes.wrapper, nestedLevel ? classes.indent : ''].join(
          ' ',
        )}
      >
        <td
          className={classes.parameterName}
          style={{
            paddingLeft: (indent / 2) * nestedLevel + 'rem',
          }}
        >
          {nestedLevel > 0 &&
            [...new Array(nestedLevel)].map((_, level) => (
              <span
                className={classes.indentMarker}
                style={{ left: `${(indent / 2) * level}rem` }}
              />
            ))}
          <div style={{ zIndex: 1, position: 'relative' }}>
            <CodeChip>
              {name}
              {required && <sup className={classes.required}>*</sup>}
            </CodeChip>
          </div>
        </td>
        <td className={classes.description}>
          <Typography className={classes.type}>{type}</Typography>
          {enums && (
            <Typography variant="body2" className={classes.type}>
              {enums.map((e, i) => (
                <>
                  <span>{i !== 0 ? ', ' : ''}</span>
                  <span className={classes.enum}>"{e}"</span>
                </>
              ))}
            </Typography>
          )}
          <Typography variant="body2">{description}</Typography>
          <Typography variant="caption" color="secondary">
            {additionalDescription}
          </Typography>
        </td>
        <td>
          {items && items.length > 0 && (
            <IconButton onClick={toggleAccordion}>
              <Icon
                icon="chevron-down"
                className={[
                  classes.openToggleIcon,
                  open ? classes.open : undefined,
                ].join(' ')}
              />
            </IconButton>
          )}
        </td>
      </tr>
      {open &&
        items?.map((parameter: UiParameterType) => (
          <Parameter nestedLevel={nestedLevel + 1} {...parameter} />
        ))}
    </>
  );
}
