/* eslint-disable new-cap */

/**
 * Regular expression for matching date pattern tokens.
 */
const PATTERN_REGEX = /(M|Y|d|D|h|H|m|s|S|G|Z|P|a)+/g;

/**
 * Regular expression for escaping quotes and capturing quoted strings or '+' signs.
 */
const ESCAPE_REGEX = /\\"|"((?:\\"|[^"])*)"|(\+)/g;

/**
 * Object mapping date pattern tokens to their corresponding options.
 */
const optionNames: Record<string, string> = {
  Y: 'year',
  M: 'month',
  D: 'day',
  d: 'weekday',
  S: 'fractionalSecondDigits',
  G: 'era',
  Z: 'timeZoneName',
  P: 'dayPeriod',
  a: 'hour12',
  h: 'hour',
  H: 'hour',
  m: 'minute',
  s: 'second',
};

/**
 * Object mapping date pattern tokens to possible values.
 */
const values: Record<string, any[]> = {
  Y: ['numeric', '2-digit', undefined, 'numeric'],
  M: ['narrow', '2-digit', 'short', 'long'],
  D: [undefined, '2-digit'],
  d: ['narrow', 'narrow', 'short', 'long'],
  S: [1, 2, 3],
  G: ['narrow', 'short', 'long'],
  Z: ['short', 'long'],
  P: ['narrow', 'short', 'long'],
  a: [true],
  h: ['numeric', '2-digit'],
  H: ['numeric', '2-digit'],
  m: ['numeric', '2-digit'],
  s: ['numeric', '2-digit'],
};

/**
 * Pads a number with leading zero if the condition is met.
 *
 * @param {boolean} condition - The condition to determine if padding is required.
 * @param {number | string} value - The value to pad.
 * @param {number} length - The desired length of the padded string.
 * @returns {string} - The padded string.
 */
function padIf(condition: boolean, value: any, length: number): string {
  return condition && length === 2 && +value / 10 < 1
    ? `0${String(value)}`
    : String(value);
}

/**
 * Formats a specific date pattern token based on its type, length, and configuration.
 *
 * @param {Date} date - The date to format.
 * @param {string} type - The type of date pattern token.
 * @param {number} length - The length of the date pattern token.
 * @param {{ locale?: string, timeZone?: string }} config - Additional configuration options.
 * @returns {string | undefined} - The formatted date pattern token.
 */
function formatType(
  date: Date,
  type: string,
  length: number,
  { locale, timeZone }: { locale?: string; timeZone?: string } = {},
): string | undefined {
  const option = optionNames[type];
  const value = values[type][length - 1];

  if (!value) {
    return undefined;
  }

  const options = {
    [option]: value,
    timeZone,
  };

  if (type === 'a') {
    return (
      Intl.DateTimeFormat(locale, {
        ...options,
        hour: 'numeric',
      })
        .formatToParts(date)
        .pop()?.value || ''
    );
  }

  if (type === 'G' || type === 'Z') {
    return (
      Intl.DateTimeFormat(locale, options).formatToParts(date).pop()?.value ||
      ''
    );
  }

  if (type === 'H' || type === 'h') {
    return (
      Intl.DateTimeFormat('en-GB', {
        ...options,
        hourCycle: type === 'H' ? 'h23' : 'h11',
      })
        .format(date)
        .toLocaleLowerCase()
        .replace(' am', '')
        .replace(' pm', '') || ''
    );
  }

  return padIf(
    ['m', 's'].includes(type) && value === '2-digit',
    Intl.DateTimeFormat(locale, options).format(date),
    2,
  ).toString();
}

/**
 * Formats a date based on the specified pattern and configuration.
 *
 * @param {Date} date - The date to format.
 * @param {string} pattern - The pattern to use for formatting.
 * @param {{ locale?: string, timeZone?: string }} config - Additional configuration options.
 * @returns {string} - The formatted date string.
 */
export const formatDate = (
  date: Date,
  pattern: string,
  config?: { locale?: string; timeZone?: string },
): string => {
  return pattern
    .split(ESCAPE_REGEX)
    .filter(sub => sub !== undefined)
    .map((sub, index) => {
      // keep escaped strings as is
      if (index % 2 !== 0) {
        return sub;
      }

      return sub.replace(PATTERN_REGEX, match => {
        const type = match.charAt(0);
        return formatType(date, type, match.length, config) || match;
      });
    })
    .join('');
};
