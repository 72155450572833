import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Grid(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M11.5 8.5h-3v3h3v-3ZM8.5 6h3C12.875 6 14 7.125 14 8.5v3c0 1.438-1.125 2.5-2.5 2.5h-3C7.062 14 6 12.937 6 11.5v-3C6 7.125 7.063 6 8.5 6Zm3 12.5h-3v3h3v-3Zm-3-2.5h3c1.375 0 2.5 1.125 2.5 2.5v3c0 1.438-1.125 2.5-2.5 2.5h-3C7.062 24 6 22.937 6 21.5v-3C6 17.125 7.063 16 8.5 16Zm0 12.5v3h3v-3h-3Zm-2.5 0C6 27.125 7.063 26 8.5 26h3c1.375 0 2.5 1.125 2.5 2.5v3c0 1.438-1.125 2.5-2.5 2.5h-3C7.062 34 6 32.937 6 31.5v-3Zm15.5-20h-3v3h3v-3Zm-3-2.5h3C22.875 6 24 7.125 24 8.5v3c0 1.438-1.125 2.5-2.5 2.5h-3c-1.438 0-2.5-1.063-2.5-2.5v-3C16 7.125 17.063 6 18.5 6Zm0 12.5v3h3v-3h-3Zm-2.5 0c0-1.375 1.063-2.5 2.5-2.5h3c1.375 0 2.5 1.125 2.5 2.5v3c0 1.438-1.125 2.5-2.5 2.5h-3c-1.438 0-2.5-1.063-2.5-2.5v-3Zm5.5 10h-3v3h3v-3Zm-3-2.5h3c1.375 0 2.5 1.125 2.5 2.5v3c0 1.438-1.125 2.5-2.5 2.5h-3c-1.438 0-2.5-1.063-2.5-2.5v-3c0-1.375 1.063-2.5 2.5-2.5Zm10-17.5v3h3v-3h-3Zm-2.5 0C26 7.125 27.063 6 28.5 6h3C32.875 6 34 7.125 34 8.5v3c0 1.438-1.125 2.5-2.5 2.5h-3c-1.438 0-2.5-1.063-2.5-2.5v-3Zm5.5 10h-3v3h3v-3Zm-3-2.5h3c1.375 0 2.5 1.125 2.5 2.5v3c0 1.438-1.125 2.5-2.5 2.5h-3c-1.438 0-2.5-1.063-2.5-2.5v-3c0-1.375 1.063-2.5 2.5-2.5Zm0 12.5v3h3v-3h-3Zm-2.5 0c0-1.375 1.063-2.5 2.5-2.5h3c1.375 0 2.5 1.125 2.5 2.5v3c0 1.438-1.125 2.5-2.5 2.5h-3c-1.438 0-2.5-1.063-2.5-2.5v-3Z" />
    </SvgIcon>
  );
}
