import { makeStyles } from '@material-ui/core';

export const useEventScheduleStyles = makeStyles<any>(theme => ({
  schedule: {
    textTransform: 'uppercase',
  },
  happeningNow: {
    color: theme.semantic.text.error,
  },
}));
