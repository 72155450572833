import React from 'react';
import { Icons, toast, ToastOptions } from 'react-toastify';
import { Spinner as spinner } from '../spinner';

type ShowToast =
  | (ToastOptions & {
      message: string | React.ReactNode;
      promise?: Promise<unknown> | (() => Promise<unknown>);
      pending?: string;
      success?: string;
      error?: string;
    })
  | (ToastOptions & {
      message?: string | React.ReactNode;
      promise: Promise<unknown> | (() => Promise<unknown>);
      pending: string;
      success?: string;
      error?: string;
    });

export const showToast = async ({ ...args }: ShowToast) => {
  const { pending, promise, message, success, error, ...restProps } = args;
  if (promise) {
    return await toast.promise(promise, {
      pending: {
        render() {
          return pending;
        },
        icon: spinner({ width: 30, size: 'small' }),
      },
      ...(success && {
        success: {
          render() {
            return success;
          },
          icon: Icons.success,
        },
      }),
      ...(error && {
        error: {
          render() {
            return error;
          },
          icon: Icons.error,
        },
      }),
    });
  }

  return toast(message, {
    containerId: 'in-front-of-side-panel',
    ...restProps,
    ...(restProps.type === 'error' && { autoClose: false }),
  });
};
