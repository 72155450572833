import {
  Callout,
  Dropdown,
  Input,
  Spinner,
  useUserContext,
} from '@lego/plugin-baseplate-core-components';
import React, { useEffect, useState } from 'react';
// fetch
import {
  discoveryApiRef,
  fetchApiRef,
  useApi,
  configApiRef,
} from '@backstage/core-plugin-api';
// to make it valid field extension
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';

export const CustomExtensionForAdminAccount = ({
  onChange,
  rawErrors,
  required,
  formData,
}: FieldExtensionComponentProps<string>) => {
  const { userEntity } = useUserContext();
  // fetch
  const discoveryApi = useApi(discoveryApiRef);
  const fetchAPI = useApi(fetchApiRef);
  // for holding username
  const [username, setUsername] = useState<string>('');
  // for holding admin account
  const [adminAcc, setAdminAcc] = useState<string[]>([]);
  const [adminAccPayload, setAdminAccPayload] = useState<string>('');
  // error message for user if doesn't hold proper account for requesting access
  const [error, setError] = useState<boolean>(false);
  // for loading
  const [adminAccLoading, setAdminAccLoading] = useState<boolean>(false);

  // fetches username using the current user's email
  const getUsername = async (email: string) => {
    setAdminAccLoading(true);

    const baseURL = await discoveryApi.getBaseUrl('server-access-form');
    const endpoint = `${baseURL}/user-account/${email}`;
    const resp = await fetchAPI.fetch(endpoint);

    if (!resp.ok) {
      return resp.status;
    }

    const result = await resp.json();
    setUsername(result.user_account);

    return result;
  };

  // result is a list, so we have to map through them
  // even if user has only 1 admin account
  const setMultipleAdminAccounts = (result: []) => {
    result.map((user: any) => {
      // only users with ISO 1-10-11 accounts can request access to servers
      // && !adminAcc.includes(user.name)
      if (!user.name.includes('12') && !adminAcc.includes(user.name)) {
        setAdminAcc(prevAdminAcc => [...prevAdminAcc, user.name]);
      }
    });
  };

  // fetches the admin account using the current user's username (from response)
  const getAdminAcc = async (usernameParam: string) => {
    const baseURL = await discoveryApi.getBaseUrl('server-access-form');
    const endpoint = `${baseURL}/admin-account/${usernameParam}`;
    const resp = await fetchAPI.fetch(endpoint);

    if (!resp.ok) {
      setAdminAccLoading(false);
      return resp.status;
    }

    const result = await resp.json();
    setMultipleAdminAccounts(result.access_admin_rights);

    setAdminAccLoading(false);
    return result;
  };

  // when admin acc list gets updated, useEffect checks if dropdown or input field needed in JSX
  useEffect(() => {
    if (adminAcc.length === 1) {
      setError(false);
      setAdminAccPayload(adminAcc[0]);
      onChange(adminAcc[0]);
    } else if (adminAcc.length === 0) {
      setError(true);
    } else {
      setError(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminAcc]);

  // when current user's email arrives, fetches for username
  useEffect(() => {
    if (userEntity) {
      void getUsername(userEntity.spec.profile.email);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userEntity]);

  // when username comes back in response, fetches for admin account
  useEffect(() => {
    if (username) {
      void getAdminAcc(username);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username]);

  const renderAdminAccContent = () => {
    if (adminAccLoading) {
      return <Spinner />;
    }

    if (adminAcc.length > 1) {
      return (
        <Dropdown
          required={required}
          label="Admin Account"
          options={adminAcc}
          onChange={value => onChange((value.target as HTMLInputElement).value)}
          error={rawErrors?.length > 0 && !formData}
        />
      );
    }

    return (
      <Input
        required={required}
        disabled
        label="Admin Account"
        value={adminAccPayload}
        error={rawErrors?.length > 0 && !formData}
      />
    );
  };

  return (
    <>
      {renderAdminAccContent()}
      {error && !adminAccLoading && (
        <Callout
          type="error"
          title="Account error"
          body={
            <>
              Only users with accounts ISO 10 or 11 can request access. You
              don't hold the proper account.
            </>
          }
        />
      )}
    </>
  );
};
