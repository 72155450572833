import { KeyboardEvent } from 'react';
import { EntityKindFilter, ResetObject } from '../../types';
import { SearchResult } from '@backstage/plugin-search-common';

// Handles all the Enter, ArrowDown, ArrowUp, Backspace, and Tab keydown events
export const useSearchModalKeyboardNavigation = (
  reset: ResetObject,
  navigate: () => void,
  displayedKindFilters: EntityKindFilter[],
  searchTerm: string,
  searchResults: SearchResult[],
  cursor: number,
  setCursor: React.Dispatch<React.SetStateAction<number>>,
  setSelectedFilter: React.Dispatch<
    React.SetStateAction<EntityKindFilter | null>
  >,
) => {
  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    const inputFieldValue = (event.target as HTMLInputElement).value;
    if (event.key === 'Enter') {
      navigate();
    }
    if (event.key === 'Backspace' && inputFieldValue === '') {
      reset.toDefaultSearchResults();
      reset.selectedFilter();
    }
    if (event.key === 'ArrowDown') {
      let maxCursor = displayedKindFilters.length - 1;
      if (searchTerm) maxCursor += searchResults.length + 1; // +1 for the "Search all of Baseplate" button
      setCursor(prev => Math.min(prev + 1, maxCursor));
    }
    if (event.key === 'ArrowUp') {
      setCursor(prev => Math.max(0, prev - 1));
    }
    if (event.key === 'Tab') {
      event.preventDefault();
      if (cursor > -1 && cursor < displayedKindFilters.length) {
        setSelectedFilter(displayedKindFilters[cursor]);
        reset.toEmptySearchResults();
        reset.searchTerm();
      }
    }
  };

  return handleKeyDown;
};
