import React from 'react';
import {
  Callout,
  EntityDropdown,
  FormStepTitle,
  Input,
} from '@lego/plugin-baseplate-core-components';
import { Box, Grid, Typography } from '@material-ui/core';
import { StepProps } from '../types';
import { InputAdornment } from '@mui/material';
import { stringifyEntityRef } from '@backstage/catalog-model';

export const StepBudget = ({ values, updateValue }: StepProps) => {
  return (
    <Grid>
      <FormStepTitle
        formTitle="Create Cloud Account"
        stepTitle="Budget"
        step={3}
      />
      <Box mt={1} mb={1}>
        <Typography variant="body1">Budget goes here</Typography>
        <Callout
          title="Actual vs. forecast"
          body={
            <p>
              <strong>Actual</strong> means the minute your cost has exceeded
              your set budget alert threshold.
              <br />
              <strong>Forecast</strong> is the calculated total spend on this
              cloud account for the current month.
            </p>
          }
        />
        <Input
          label="Monthly budget"
          type="number"
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                {values.currency}
              </InputAdornment>
            ),
          }}
          value={values.monthlyBudget}
          onChange={e => updateValue('monthlyBudget', e.target.value)}
          required
        />
        <EntityDropdown
          label="Budget approver"
          kind="User"
          value={values.budgetApprover as string}
          handleChange={entity =>
            updateValue('budgetApprover', stringifyEntityRef(entity))
          }
          required
        />
      </Box>
    </Grid>
  );
};
