import { InfoCard } from '@backstage/core-components';
import { Banner } from '@lego/plugin-baseplate-admin-common';
import { SidePanel } from '@lego/plugin-baseplate-core-components';
import { Grid, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { CreateBanner, EditBanner } from './side-panel-forms';
import { BannerTable } from './table';

export const BannerPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [banner, setBanner] = useState<Banner>();
  const [sidePanelType, setSidePanelType] = useState<'create' | 'edit'>(
    'create',
  );

  const handleOpenEdit = (chosenBanner: Banner) => {
    setBanner(chosenBanner);
    setSidePanelType('edit');
    setIsOpen(true);
  };

  const handleOpenCreate = () => {
    setSidePanelType('create');
    setIsOpen(true);
  };

  const isCreate = sidePanelType === 'create' || !banner;

  return (
    <>
      <Grid container justifyContent="space-between">
        <Grid item xs={12}>
          <InfoCard title="Baseplate Banners 📰">
            <Typography>
              On this page, you can dive into the world of crafting and tweaking
              banners that will catch eyes on Baseplate. These banners can hold
              messages, links, or even both, offering a versatile way to
              announce new features, downtimes, or any other important stuff.
              So, get creative and make your mark on how we share essential
              information!
            </Typography>
          </InfoCard>
        </Grid>
        <Grid item xs={12}>
          <BannerTable
            handleOpenEdit={handleOpenEdit}
            handleOpenCreate={handleOpenCreate}
          />
        </Grid>
      </Grid>
      <SidePanel
        title={sidePanelType === 'create' ? 'Create Banner' : 'Edit Banner'}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      >
        {isCreate ? (
          <CreateBanner setIsOpen={setIsOpen} />
        ) : (
          <EditBanner banner={banner} setIsOpen={setIsOpen} />
        )}
      </SidePanel>
    </>
  );
};
