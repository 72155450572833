import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function CloudApi(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 28c4.438-.25 8-3.938 8-8.5 0-3.438-2.125-6.438-5.125-7.75l.021-.146c.055-.377.104-.716.104-1.104C35 6.375 31.625 3 27.5 3a7.1 7.1 0 0 0-3.063.688C22.5 1.438 19.625 0 16.5 0 10.687 0 6 4.75 6 10.5v.063C2.5 11.75 0 15.125 0 19c0 5 4 9 9 9h23ZM22.125 5.625a3.096 3.096 0 0 0 3.5.813C26.188 6.188 26.813 6 27.5 6c2.438 0 4.5 2.063 4.5 4.5 0 .125-.016.25-.031.375a3.02 3.02 0 0 0-.032.375c-.25 1.375.5 2.688 1.75 3.25a5.428 5.428 0 0 1 3.313 5c0 2.938-2.313 5.375-5.188 5.5H9c-3.313 0-6-2.688-6-6a5.937 5.937 0 0 1 4-5.625c1.188-.438 2-1.563 2-2.813V10.5C9 6.375 12.313 3 16.5 3c2.25 0 4.25 1.063 5.625 2.625ZM11.893 38.17l.504 1.212.003.007c.216.495.806.755 1.322.529.494-.216.754-.804.53-1.32l-3.03-7.273a1.015 1.015 0 0 0-.92-.625c-.407 0-.78.248-.937.625l-3.03 7.274a1.013 1.013 0 0 0 .529 1.319l.008.003a1.006 1.006 0 0 0 1.315-.532l.507-1.22h3.199Zm-2.354-2.013.764-1.823.763 1.823H9.54Zm9.253-5.457c-.907 0-1.612.732-1.612 1.612v6.67a.999.999 0 0 0 1.006 1.007 1.01 1.01 0 0 0 1.006-1.006v-1.42h1.42a3.429 3.429 0 0 0 3.432-3.431 3.44 3.44 0 0 0-3.432-3.432h-1.82Zm1.82 4.85h-1.42v-2.838h1.42a1.42 1.42 0 0 1 0 2.839Z"
      />
      <path d="M27.888 30.7h4.852c.543 0 1.006.463 1.006 1.006a1.01 1.01 0 0 1-1.006 1.006h-1.42v5.264h1.42c.543 0 1.006.464 1.006 1.007a1.01 1.01 0 0 1-1.006 1.006h-4.852a.999.999 0 0 1-1.006-1.006 1.01 1.01 0 0 1 1.006-1.007h1.42v-5.264h-1.42a.999.999 0 0 1-1.006-1.006 1.01 1.01 0 0 1 1.006-1.006Z" />
    </SvgIcon>
  );
}
