import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function CreditCardCvc(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M23.5 11a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Zm4.5 0a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Zm3 1.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28 0c-6.78 0-12.25 5.63-11.99 12.47.23 6.18 5.35 11.29 11.52 11.52C34.37 24.25 40 18.78 40 12c0-6.63-5.37-12-12-12Zm0 3c5.05 0 9.14 4.18 9 9.26-.13 4.69-4.04 8.6-8.74 8.74-5.08.14-9.26-3.95-9.26-9 0-4.96 4.04-9 9-9Z"
      />
      <path d="M38 23.178V30c0 2.25-1.813 4-4 4H6c-2.25 0-4-1.75-4-4V10c0-2.188 1.75-4 4-4h8.25a14.883 14.883 0 0 0-.949 3H6c-.563 0-1 .5-1 1v2h8.001c0 .193.004.388.011.583v.001A14.689 14.689 0 0 0 14.27 18H5v12c0 .563.438 1 1 1h28c.5 0 1-.438 1-1v-4.732a15.037 15.037 0 0 0 3-2.09Z" />
      <path d="M19.5 24c2 1.5 4 2.5 6.46 2.86-.46.14-.764.14-1.46.14h-7c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5h2Zm-10 0h3c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5h-3c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5Z" />
    </SvgIcon>
  );
}
