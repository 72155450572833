import { Page, Content } from '@backstage/core-components';
import { HomepageUpcomingEvents } from '@lego/plugin-baseplate-learning-and-development';
import { Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import { ArticleFeed, ProductRelationsTreeCard } from './Cards';
import { Renderer } from './Renderer';
import { HomePageSearch } from './Cards/HomePageSearch';
import { useUserContext } from '@lego/plugin-baseplate-core-components';
import { ProductHealthBetaCard } from '@lego/backstage-plugin-finops-scorecard';

export const HomePage = () => {
  const { userEntity } = useUserContext();
  const [hideProduct, setHideProduct] = React.useState(false);

  useEffect(() => {
    if (userEntity) {
      if (
        userEntity.spec.generalOverheadProducts &&
        userEntity.spec.generalOverheadProducts.length > 0
      ) {
        setHideProduct(true);
      }
    }
  }, [userEntity]);

  return (
    <Page themeId="home">
      <HomePageSearch />
      <Content>
        <Grid container>
          {!hideProduct && (
            <Grid item xs={12} md={4}>
              <ProductRelationsTreeCard Renderer={Renderer} />
            </Grid>
          )}
          <Grid item xs={12} md={hideProduct ? 6 : 4}>
            <ArticleFeed Renderer={Renderer} />
          </Grid>
          <Grid item xs={12} md={hideProduct ? 6 : 4}>
            <HomepageUpcomingEvents Renderer={Renderer} />
            <ProductHealthBetaCard />
          </Grid>
        </Grid>
      </Content>
    </Page>
  );
};
