import { util } from './utilFunc';

/**
 * Encodes an object as a URL-encoded query string.
 *
 * @param {Record<string, any>} params - The object to encode.
 * @returns {string} The URL-encoded query string.
 */
export const processParamsAsRequestBody = (
  params: Record<string, any>,
): string => {
  const newParams = new URLSearchParams();
  for (const key in params) {
    if (!util().isNullOrUndefined(params[key])) {
      if (Array.isArray(params[key])) {
        params[key].forEach((value: unknown) =>
          newParams.append(key, String(value)),
        );
      } else {
        newParams.append(key, String(params[key]));
      }
    }
  }
  return newParams.toString();
};

/**
 * Modifies the URL query parameters of the current page based on the input object.
 *
 * @param {Record<string, unknown>} params - The object containing the query parameters to set.
 * @returns {void} Nothing is returned.
 */
export const setURLParams = (params: Record<string, unknown>): void => {
  const newUrl = new URL(window.location.href);
  for (const [key, value] of Object.entries(params)) {
    if (value === undefined) {
      newUrl.searchParams.delete(key);
    } else {
      newUrl.searchParams.set(key, String(value));
    }
  }
  window.history.pushState(null, '', newUrl);
};
