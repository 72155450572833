import React from 'react';
import { useSearchModalContext } from './context/SearchModalContext';
import { Typography } from '@material-ui/core';
import { Icon, baseplateStyles } from '@lego/plugin-baseplate-core-components';
import { ShortcutSymbol } from './ShortcutSymbol';
import { SearchScope, getSearchScope } from './utils/allSearchFilters';
import { getShortcutKey } from './utils/getShortcutKey';

type Props = {
  scope?: SearchScope;
  color?: 'default' | 'darker';
  size?: 'default' | 'large';
};

const useStyles = baseplateStyles(theme => ({
  globalSearchBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    border: `1px solid ${theme.semantic.border.default}`,
    borderRadius: theme.primitive.borderRadius.default,
    transition: 'border-color 200ms ease-in-out',
    textAlign: 'left',
    gap: '1.5rem',
    width: '100%',
    '&:hover': {
      borderColor: theme.semantic.border.hover,
    },
  },
  'color-default': {
    background: theme.semantic.background.neutral[1],
  },
  'color-darker': {
    background: theme.semantic.background.neutral[3],
  },
  'size-default': {
    height: '2.5rem', // 40px
  },
  'size-large': {
    background: theme.semantic.background.neutral[1],
    height: '3rem', // 48px
  },
  shortcut: {
    display: 'flex',
    gap: '4px ',
  },
  placeholder: {
    display: 'flex',
    gap: 4,
    color: theme.semantic.text.tertiary,
    fontSize: '1rem',
    fontFamily: theme.typography.fontFamily,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '& span': {
      display: 'flex',
      alignItems: 'center',
    },
  },
}));

export const GlobalSearchField = ({
  scope,
  size = 'default',
  color = 'darker',
}: Props) => {
  const classes = useStyles();
  const { openModal } = useSearchModalContext();

  const filter = React.useMemo(() => getSearchScope(scope), [scope]);

  const placeholder = React.useMemo(
    () => (filter ? `Search ${filter.title.toLowerCase()}...` : 'Search...'),
    [filter],
  );

  const shortcutKey = getShortcutKey(); // Get the correct shortcut key

  return (
    <button
      className={[
        classes.globalSearchBar,
        classes[`size-${size}`],
        classes[`color-${color}`],
      ].join(' ')}
      onClick={e => {
        // Make sure that the button isn't triggered when hitting enter in the search modal
        e.currentTarget.blur();
        openModal(filter);
      }}
    >
      <Typography
        variant="body2"
        component="span"
        className={classes.placeholder}
      >
        <Icon icon="magnifying-glass" />
        <span>{placeholder}</span>
      </Typography>

      <div className={classes.shortcut}>
        <ShortcutSymbol text={shortcutKey} />
        <ShortcutSymbol text="K" />
      </div>
    </button>
  );
};
