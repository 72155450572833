import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';
import { Input } from '@lego/plugin-baseplate-core-components';
import React, { useEffect } from 'react';
import { useDependencyDisplayExtensionStyles } from './DependencyDisplayExtension.styles';
import { isNull } from 'lodash';

const findMatchingObject = (
  list: any,
  dependencyProperty: string[],
  objectValue: any,
) => {
  const foundObject = list.find((item: any) => {
    return dependencyProperty.every(prop => item[prop] === objectValue[prop]);
  });
  return foundObject || null;
};

export const DependencyDisplayExtension = (
  props: FieldExtensionComponentProps<string | undefined>,
) => {
  const { onChange, formContext, uiSchema, schema, name } = props;
  const styles = useDependencyDisplayExtensionStyles();
  const fieldSchema = uiSchema['ui:options'] as Record<string, any>;
  const label = fieldSchema?.label?.toString() ?? schema?.title?.toString();
  const helperText = (fieldSchema?.helperText as string) || undefined;
  const dependencyProps = fieldSchema?.dependencyProps ?? [];
  const valueProp = fieldSchema?.valueProp ?? name;
  const value = formContext.formData?.[name] ?? '';

  useEffect(() => {
    if (!isNull(formContext.formData)) {
      const dependencyValue = findMatchingObject(
        fieldSchema.options ?? [],
        dependencyProps,
        formContext.formData,
      );
      const depvalue = dependencyValue?.[valueProp];
      let formattedValue;

      if (typeof depvalue === 'object' && !Array.isArray(depvalue)) {
        formattedValue = Object.values(depvalue).join(', ');
      } else if (Array.isArray(depvalue)) {
        formattedValue = depvalue.join(', ');
      } else {
        formattedValue = depvalue?.toString() ?? '';
      }

      onChange(formattedValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formContext]);

  return (
    <div className={styles.dependencyInput}>
      <Input
        required
        label={label ?? ''}
        disabled
        value={value}
        helperText={helperText}
      />
    </div>
  );
};
