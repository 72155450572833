import React from 'react';
import { techdocsContent } from '../catalog/ContentSections';
import { useTechdocsPageStyles } from './useTechdocsPageStyles';

export function EntityTechdocs() {
  const classes = useTechdocsPageStyles();
  return (
    <div className={[classes.techdocsPage, classes.entityPage].join(' ')}>
      {techdocsContent}
    </div>
  );
}
