import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function CalendarClock(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M10 3.984c.813 0 1.5.688 1.5 1.5v2.5h9v-2.5c0-.812.625-1.5 1.5-1.5.813 0 1.5.688 1.5 1.5v2.5H26c2.188 0 4 1.813 4 4v4H5v16c0 .563.438 1 1 1h13.75c.75 1.188 1.75 2.25 2.875 3H6c-2.25 0-4-1.75-4-4v-20c0-2.187 1.75-4 4-4h2.5v-2.5c0-.812.625-1.5 1.5-1.5Zm10 23c0-3.187 1.688-6.125 4.5-7.75 2.75-1.625 6.188-1.625 9 0 2.75 1.625 4.5 4.563 4.5 7.75 0 3.25-1.75 6.188-4.5 7.813-2.813 1.625-6.25 1.625-9 0-2.813-1.625-4.5-4.563-4.5-7.813Zm9-5c-.563 0-1 .5-1 1v4c0 .563.438 1 1 1h3c.5 0 1-.437 1-1 0-.5-.5-1-1-1h-2v-3c0-.5-.5-1-1-1Z" />
    </SvgIcon>
  );
}
