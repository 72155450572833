import { EntityLayout } from '@lego/plugin-baseplate-catalog';
import { EntityOwnershipCard } from '@backstage/plugin-org';
import { Grid } from '@material-ui/core';
import React from 'react';

import { BaseplateEntityUserProfileCard } from '../CustomComponents/BaseplateEntityUserProfileCard';
import { entityWarningContent } from '../ContentSections';

const UserPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={4}>
        {entityWarningContent}
        <Grid item xs={12} md={6}>
          <BaseplateEntityUserProfileCard variant="gridItem" />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityOwnershipCard variant="gridItem" hideRelationsToggle />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);

export default UserPage;
