import { Entity, RELATION_OWNED_BY } from '@backstage/catalog-model';
import {
  EntityRefLinks,
  getEntityRelations,
} from '@backstage/plugin-catalog-react';
import { Typography, Link } from '@material-ui/core';
import React from 'react';
import { CodeSnippet, useSupportConfig } from '@backstage/core-components';
import { isMissingOwner } from '@lego/plugin-baseplate-common';

type Props = {
  entity: Entity;
};

const externalDocsJson = `{
  ...,
  "externalDocs": {
    "description": "Find more info here",
    "url": "https://example.com"
  }
}`;

export const EntityLinksEmptyState = ({ entity }: Props) => {
  const ownedByRelations = getEntityRelations(entity, RELATION_OWNED_BY);
  const hasOwner = !isMissingOwner(entity);

  const support = useSupportConfig();
  return (
    <>
      {ownedByRelations.length > 0 ? (
        <>
          <Typography>
            The owners of this API have not provided any links to documentation.
            {hasOwner && (
              <>
                We encourage you to ask the owners to do so. See contact
                information here:{' '}
                <EntityRefLinks
                  entityRefs={ownedByRelations}
                  defaultKind="group"
                />{' '}
              </>
            )}
          </Typography>
          <br />
          <Typography>
            If you are the owner of this API you can add links to external
            API-documentation and other relevant resources by filling the
            externalDocs property:
          </Typography>
          <CodeSnippet
            text={externalDocsJson}
            language="json"
            showLineNumbers
            highlightedNumbers={[3, 4, 5, 6]}
            customStyle={{ background: 'inherit', fontSize: '115%' }}
          />
        </>
      ) : (
        <Typography>
          There are no Links and no owner for this entity. For more information
          please{' '}
          <Link href={support.url} rel="noopener noreferrer">
            contact support.
          </Link>
        </Typography>
      )}
    </>
  );
};
