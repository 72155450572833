import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  fetchApiRef,
} from '@backstage/core-plugin-api';
import { AdminApiRef, AdminClient } from './api';
import { rootRouteRef } from './routes';

export const adminPlugin = createPlugin({
  id: 'admin',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: AdminApiRef,
      deps: {
        fetchApi: fetchApiRef,
        discoveryApi: discoveryApiRef,
      },
      factory: ({ fetchApi, discoveryApi }) =>
        new AdminClient({
          discoveryApi,
          fetchApi,
        }),
    }),
  ],
});

export const AdminPage: () => JSX.Element = adminPlugin.provide(
  createRoutableExtension({
    name: 'AdminPage',
    component: () => import('./components/AdminPage').then(m => m.AdminPage),
    mountPoint: rootRouteRef,
  }),
);
