import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Mobile(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M13 7c-.563 0-1 .5-1 1v24c0 .563.438 1 1 1h14c.5 0 1-.438 1-1V8c0-.5-.5-1-1-1H13ZM9 8c0-2.188 1.75-4 4-4h14c2.188 0 4 1.813 4 4v24c0 2.25-1.813 4-4 4H13c-2.25 0-4-1.75-4-4V8Zm9 21h4c.5 0 1 .5 1 1 0 .563-.5 1-1 1h-4c-.563 0-1-.438-1-1 0-.5.438-1 1-1Z" />
    </SvgIcon>
  );
}
