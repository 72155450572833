import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { useUserProfile } from '@backstage/plugin-user-settings';
import { ProductEntityV1alpha1 } from '@lego/plugin-baseplate-common';
import { useMemo } from 'react';
import useAsync from 'react-use/lib/useAsync';

/** @deprecated Please use `useUserContext` instead, to grab the states from the context. */
export const useCurrentUser = () => {
  const {
    profile,
    backstageIdentity,
    loading: loadingProfile,
  } = useUserProfile();
  const catalogApi = useApi(catalogApiRef);

  const { value: product, loading } = useAsync(async () => {
    if (!backstageIdentity) return undefined;
    const { ownershipEntityRefs } = backstageIdentity;
    const ref = ownershipEntityRefs.find(o => o.startsWith('product:'));
    if (!ref) return undefined;
    return (await catalogApi.getEntityByRef(ref)) as ProductEntityV1alpha1;
  }, [backstageIdentity, catalogApi]);

  const values = useMemo(
    () => ({
      user: profile,
      backstageIdentity,
      product,
      loading: loadingProfile || loading,
    }),
    [profile, backstageIdentity, product, loadingProfile, loading],
  );

  return { ...values };
};
