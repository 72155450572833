import { GetEntityFacetsRequest } from '@backstage/catalog-client';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { AutocompleteDropdown } from '@lego/plugin-baseplate-core-components';
import { FormControl, FormHelperText, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import useAsync from 'react-use/lib/useAsync';
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';

export const ToolCategoryPicker = (
  props: FieldExtensionComponentProps<string[]>,
) => {
  const { formData, onChange, uiSchema, required } = props;

  const catalogApi = useApi(catalogApiRef);
  const [categoryOptions, setCategoryOptions] = useState<string[]>([]);
  const label = uiSchema['ui:options']?.label?.toString();
  const showCounts = uiSchema['ui:options']?.showCounts;

  const { loading } = useAsync(async () => {
    const facet = 'metadata.categories';
    const categoriesRequest: GetEntityFacetsRequest = { facets: [facet] };
    categoriesRequest.filter = { kind: 'tool' };

    const { facets } = await catalogApi.getEntityFacets(categoriesRequest);

    const categoryFacets = Object.fromEntries(
      facets[facet].map(({ value, count }) => [value, count]),
    );

    setCategoryOptions(
      Object.keys(categoryFacets).sort((a, b) =>
        showCounts ? categoryFacets[b] - categoryFacets[a] : a.localeCompare(b),
      ),
    );

    return categoryFacets;
  });

  const setValues = (_: React.ChangeEvent<object>, values: string[] | null) => {
    onChange(values || []);
  };

  return (
    <FormControl margin="normal" required={required}>
      <AutocompleteDropdown
        multiple
        filterSelectedOptions
        value={formData || []}
        id="category"
        loading={loading}
        options={categoryOptions}
        onChange={setValues}
        label={label ?? ''}
      />
      <FormHelperText style={{ padding: '0 1rem' }}>
        <Typography variant="body2" color="secondary">
          The category is used to group tools together. Please select the
          catagory that best fits the tool.
        </Typography>
      </FormHelperText>
    </FormControl>
  );
};
