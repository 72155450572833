import { createPermission } from '@backstage/plugin-permission-common';

export const adminPageReadPermission = createPermission({
  name: 'admin.page.read',
  attributes: { action: 'read' },
});

export const adminPageCreatePermission = createPermission({
  name: 'admin.page.create',
  attributes: { action: 'create' },
});

export const adminPagePermissions = [
  adminPageCreatePermission,
  adminPageReadPermission,
];
