import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ArrowRightArrowLeft(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="m12.453 35.04-6-6c-.625-.563-.625-1.5 0-2.126l6-5.937a1.369 1.369 0 0 1 2.063 0c.625.562.625 1.5 0 2.062l-3.438 3.438h21.438c.812 0 1.5.687 1.5 1.5 0 .875-.688 1.5-1.5 1.5l-21.438.062 3.438 3.438c.625.562.625 1.5 0 2.062a1.37 1.37 0 0 1-2.063 0Zm21.125-22-6 6c-.625.624-1.562.624-2.125 0-.625-.563-.625-1.5 0-2.126l3.438-3.437H7.516c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5H28.89l-3.438-3.438c-.625-.562-.625-1.5 0-2.125.563-.562 1.5-.562 2.125 0l6 6.063a1.47 1.47 0 0 1 0 2.062Z" />
    </SvgIcon>
  );
}
