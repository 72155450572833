import { DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';
import { AMMA_ENVIRONMENT } from '@lego/plugin-baseplate-amma-common';
import { IUpstreamStatusResponse } from '../types';
import { IApiMetricsClient } from './IApiMetricsClient';

export class ApiMetricsClient implements IApiMetricsClient {
  private readonly fetchApi: FetchApi;
  private readonly discoveryApi: DiscoveryApi;

  constructor({
    fetchApi,
    discoveryApi,
  }: {
    fetchApi: FetchApi;
    discoveryApi: DiscoveryApi;
  }) {
    this.fetchApi = fetchApi;
    this.discoveryApi = discoveryApi;
  }
  async getUpstreamStatus(
    environment: AMMA_ENVIRONMENT,
  ): Promise<IUpstreamStatusResponse> {
    const baseUrl = `${await this.discoveryApi.getBaseUrl(
      'proxy',
    )}/api-metrics/${environment}`;
    const response = await this.fetchApi.fetch(
      `${baseUrl}/v1/upstream-status`,
      {
        method: 'POST',
        body: JSON.stringify(['*']),
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }

    return await response.json();
  }
}
