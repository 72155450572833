import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function UsbDrive(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M4 13c-.563 0-1 .5-1 1v12c0 .563.438 1 1 1h21V13H4Zm-4 1c0-2.188 1.75-4 4-4h22c1.063 0 2 .938 2 2v16c0 1.125-.938 2-2 2H4c-2.25 0-4-1.75-4-4V14Zm38-2c1.063 0 2 .938 2 2v12c0 1.125-.938 2-2 2h-8V12h8Zm-3 3.5c-.875 0-1.5.688-1.5 1.5 0 .875.625 1.5 1.5 1.5.813 0 1.5-.625 1.5-1.5 0-.813-.688-1.5-1.5-1.5ZM33.5 23c0 .875.625 1.5 1.5 1.5.813 0 1.5-.625 1.5-1.5 0-.813-.688-1.5-1.5-1.5-.875 0-1.5.688-1.5 1.5Z" />
    </SvgIcon>
  );
}
