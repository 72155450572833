import React, { useState, useEffect } from 'react';
import { useApi } from '@backstage/core-plugin-api';
import { storageApiRef } from '@backstage/core-plugin-api';
import { PopupCard, PopUpCardProps } from './PopupCard';

const STORAGE_KEY = 'PopupDismissed';

type PopupCardShowOnceProps = PopUpCardProps & {
  showOnceIdentifier: string;
  show: boolean;
};

export const PopupCardShowOnce = ({
  showOnceIdentifier,
  show = false,
  ...props
}: PopupCardShowOnceProps) => {
  const storageApi = useApi(storageApiRef).forBucket('PopupCard');
  const storageKey = `${STORAGE_KEY}_${showOnceIdentifier}`;
  const [isDismissed, setIsDismissed] = useState(false);

  useEffect(() => {
    // Check storage to see if the modal has been dismissed
    const checkDismissed = () => {
      const dismissed = storageApi.snapshot<boolean>(storageKey);
      setIsDismissed(dismissed.value || false);
    };
    checkDismissed();
  }, [storageApi, storageKey]);

  const handleConfirm = async () => {
    // Call the original confirm handler
    props.confirmButton.onClick();
    // Set the dismissed state and update storage
    setIsDismissed(true);
    await storageApi.set(storageKey, true);
  };

  const handleCancel = async () => {
    // Call the original cancel handler
    props.cancelButton.onClick();
    // Set the dismissed state and update storage
    setIsDismissed(true);
    await storageApi.set(storageKey, true);
  };

  if (isDismissed || !show) {
    // Do not render the component if it has been dismissed
    return null;
  }

  return (
    <PopupCard
      {...props}
      confirmButton={{
        ...props.confirmButton,
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onClick: async () => await handleConfirm(),
      }}
      cancelButton={{
        ...props.cancelButton,
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onClick: async () => await handleCancel(),
      }}
    />
  );
};
