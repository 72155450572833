import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Gift(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M18.438 6.813 20 9.374l1.5-2.563C22.5 5.063 24.313 4 26.313 4h.187c3 0 5.5 2.5 5.5 5.5 0 .938-.25 1.75-.625 2.5H33c1.625 0 3 1.375 3 3v4c0 1.313-.875 2.438-2 2.875V32c0 2.25-1.813 4-4 4H10c-2.25 0-4-1.75-4-4V21.875A3.05 3.05 0 0 1 4 19v-4a3 3 0 0 1 3-3h1.563C8.187 11.25 8 10.437 8 9.5c0-3 2.438-5.5 5.5-5.5h.125c2 0 3.813 1.063 4.813 2.813ZM33 15H22v4h11v-4Zm-15 0H7v4h11v-4Zm0 7H9v10c0 .563.438 1 1 1h8V22Zm4 11h8c.5 0 1-.438 1-1V22h-9v11Zm4.5-21c1.375 0 2.5-1.063 2.5-2.5C29 8.125 27.875 7 26.5 7h-.188c-.937 0-1.75.5-2.25 1.313L21.875 12H26.5ZM18 12h.063l-2.188-3.688C15.437 7.5 14.562 7 13.625 7H13.5C12.062 7 11 8.125 11 9.5c0 1.438 1.063 2.5 2.5 2.5H18Z" />
    </SvgIcon>
  );
}
