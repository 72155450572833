import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Ban(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M28.063 30.219 9.75 11.906A13.096 13.096 0 0 0 7 19.97c0 7.187 5.813 13 13 13 3 0 5.813-1 8.063-2.75Zm2.125-2.125C31.938 25.844 33 23.03 33 19.969c0-7.125-5.875-13-13-13-3.063 0-5.875 1.062-8.125 2.812l18.313 18.313ZM4 19.969C4 14.28 7 9.03 12 6.156c4.938-2.875 11-2.875 16 0 4.938 2.875 8 8.125 8 13.813 0 5.75-3.063 11-8 13.875-5 2.875-11.063 2.875-16 0-5-2.875-8-8.125-8-13.875Z" />
    </SvgIcon>
  );
}
