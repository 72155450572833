import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function FileXmark(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M12 33h16c.5 0 1-.438 1-1V14h-5c-1.125 0-2-.875-2-2V7H12c-.563 0-1 .5-1 1v24c0 .563.438 1 1 1Zm0-29h10.313c1.062 0 2.062.438 2.812 1.188l5.688 5.687c.75.75 1.187 1.75 1.187 2.813V32c0 2.25-1.813 4-4 4H12c-2.25 0-4-1.75-4-4V8c0-2.188 1.75-4 4-4Zm2.938 14.938a1.471 1.471 0 0 1 2.062 0l2.938 2.937 2.937-2.938c.625-.562 1.563-.562 2.125 0 .625.625.625 1.563 0 2.125L22.062 24 25 26.938c.625.625.625 1.562 0 2.125-.563.625-1.5.625-2.125 0l-2.938-2.938L17 29.063a1.37 1.37 0 0 1-2.063 0c-.624-.563-.624-1.5 0-2.125L17.875 24l-2.938-2.938c-.624-.562-.624-1.5 0-2.125Z" />
    </SvgIcon>
  );
}
