import { Typography } from '@material-ui/core';
import React from 'react';
import {
  SearchModalResultItemProps,
  SearchModalResultItemWrapper,
} from '../SearchModalResultItemWrapper';

type Props = {
  label: string;
} & SearchModalResultItemProps;

export const SearchModalKindFilterItem = ({ label, ...props }: Props) => {
  return (
    <SearchModalResultItemWrapper {...props}>
      <Typography variant="body1">{label}</Typography>
    </SearchModalResultItemWrapper>
  );
};
