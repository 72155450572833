import { baseplateStyles } from '../../utils';

export const useAutocompleteDropdownStyles = baseplateStyles(theme => ({
  root: {
    '& + .v5-MuiAutocomplete-popper': {
      '& .v5-MuiPaper-root': {
        backgroundColor: theme.semantic.background.neutral[1],
        color: theme.semantic.text.primary,
      },
      '& .v5-MuiAutocomplete-option': {
        padding: '0.5rem 1rem',
        '&:hover, &.Mui-focusVisible': {
          backgroundColor: theme.semantic.background.neutral[2],
        },
        '&[aria-selected="true"], &[aria-selected="true"]:hover': {
          backgroundColor: theme.semantic.background.info,
        },
      },
    },
    '& div.v5-MuiInputBase-root, input.v5-MuiInputBase-input.v5-MuiAutocomplete-input':
      {
        paddingTop: 0,
        paddingBottom: 0,
      },
  },
  multiple: {
    '& .v5-MuiChip-root': {
      marginRight: 4,
    },
    '& .v5-MuiInputBase-root': {
      paddingLeft: '0.5rem',
      '& .v5-MuiInputBase-input': {
        paddingLeft: '0.25rem',
      },
    },
  },
}));
