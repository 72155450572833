import { Entity } from '@backstage/catalog-model';
import { AvailableKinds } from '@lego/plugin-baseplate-common';
import React from 'react';
import { ConstructionSvg } from './ConstructionSvg';
import { useStyles } from './EntityIcon.styles';
import { EntityIconSvg } from './EntityIconSvg';

interface Props {
  kind: AvailableKinds;
  size: 'sm' | 'md';
  entity?: Entity;
}

export const EntityIcon = ({ kind, size, entity }: Props) => {
  const classes = useStyles();

  const iconClassName = `svg-icon-${size}`;

  const isConstruction = (() => {
    if (entity?.spec?.environment === 'integration') return true;
    // Some other random reason to show construction
    // else if () return true;
    return false;
  })();

  return (
    <div className={[classes.iconWrapper, classes[iconClassName]].join(' ')}>
      {isConstruction && <ConstructionSvg />}
      <EntityIconSvg
        entity={entity}
        size={size}
        kind={kind ?? (entity?.kind as AvailableKinds)}
      />
    </div>
  );
};
