import {
  DismissableBanner as BackstageDismissableBanner,
  Link,
} from '@backstage/core-components';
import { useAnalytics } from '@backstage/core-plugin-api';
import { BannerCreateParams } from '@lego/plugin-baseplate-admin-common';
import { Typography, makeStyles } from '@material-ui/core';
import { StatusInformationBold, StatusErrorBold } from '@lego/klik-ui-icons';
import React from 'react';

interface Props {
  banner: BannerCreateParams;
  id: string;
}

const useStyles = makeStyles(_ => ({
  banner: {
    '& svg': { width: 16, height: 16 },
  },
  title: {
    display: 'flex',
    gap: '0.5rem',
    alignItems: 'center',
  },
  link: {
    color: 'inherit',
  },
}));

export const DismissableBanner = ({ banner, id }: Props) => {
  const classes = useStyles();
  const analytics = useAnalytics();

  const getIcon = (severity: string) => {
    switch (severity) {
      case 'info':
        return <StatusInformationBold />;
      case 'warning':
        return <StatusErrorBold />;
      case 'error':
        return <StatusErrorBold />;
      default:
        return <StatusInformationBold />;
    }
  };

  const track = () => {
    analytics.captureEvent('click', banner.link_text ?? 'banner_link_text', {
      attributes: {
        'bp.banner_id': id,
        'bp.banner_title': banner.title,
        'bp.banner_severity': banner.severity,
      },
    });
  };

  return (
    <BackstageDismissableBanner
      message={
        <div className={classes.banner}>
          <div className={classes.title}>
            {getIcon(banner.severity)}
            <Typography variant="subtitle2" color="inherit">
              {banner.title}
            </Typography>
          </div>
          <Typography variant="body1" color="inherit">
            {banner.text_body}{' '}
            {banner.link_text && banner.link_url && (
              <Link
                to={banner.link_url}
                target="_blank"
                noTrack
                underline="always"
                onClick={track}
                color="inherit"
                className={classes.link}
              >
                {banner.link_text}
              </Link>
            )}
          </Typography>
        </div>
      }
      variant={banner.severity}
      id={id}
    />
  );
};
