import React, { useEffect, useState } from 'react';
import { useShadowRootElements } from '@backstage/plugin-techdocs-react';
import { TextField, Typography, makeStyles } from '@material-ui/core';
import { Button, Icon } from '@lego/plugin-baseplate-core-components';
import { BaseplateTheme } from '../../../../style/types';
import { useTechDocsReaderPage } from '@backstage/plugin-techdocs-react';
import { useAnalytics } from '@backstage/core-plugin-api';

const useStyles = makeStyles<BaseplateTheme>(theme => ({
  secondarySidebar: {
    display: 'flex !important',
    flexDirection: 'column-reverse',
    justifyContent: 'flex-end',
    '@media (max-width: 1220px)': {
      display: 'none !important',
    },
  },
  border: {
    margin: '1.5rem 0',
    borderTop: `1px solid ${theme.palette.border}`,
  },
  feedbackButton: {
    marginTop: 10,
    marginRight: 10,
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 5,
    paddingBottom: 5,
  },
  feedbackStep: {
    marginTop: 10,
    marginBottom: 5,
  },
  finalMessage: {
    marginTop: 5,
  },
}));

enum FeedbackOptions {
  LIKE = 'LIKE',
  DISLIKE = 'DISLIKE',
}

type FeedbackResult = {
  feedback: FeedbackOptions | null;
  comment?: string;
};

const QUESTION =
  'Did this document help you complete the task you needed help with?';

export const TableOfContentActionsAddon = () => {
  const classes = useStyles();
  const analytics = useAnalytics();
  const [feedbackDone, setFeedbackDone] = useState<boolean>(false);
  const [feedbackResult, setFeedbackResult] = useState<FeedbackResult>({
    feedback: null,
  });
  const sidebar = useShadowRootElements(['div.md-sidebar--secondary']);
  const {
    entityMetadata: { value: entityMetadata },
  } = useTechDocsReaderPage();

  useEffect(() => {
    sidebar.forEach(link => {
      link.classList.add(classes.secondarySidebar);
    });
  }, [classes.secondarySidebar, sidebar]);

  const handleFeedbackEvent = (eventType: string) => {
    analytics.captureEvent(
      eventType,
      entityMetadata?.metadata.name || 'unknown_entity_name',
      {
        attributes: {
          feedback: feedbackResult.feedback || 'empty',
          comment: feedbackResult.comment || 'empty',
          url: window.location.href,
        },
      },
    );
  };

  // callback function for reaction button press
  useEffect(() => {
    if (
      feedbackResult.feedback ===
      (FeedbackOptions.LIKE || FeedbackOptions.DISLIKE)
    ) {
      handleFeedbackEvent('feedbackReactionButtonPress');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedbackResult.feedback]);

  return (
    <div>
      <hr className={classes.border} />
      <Typography className={classes.feedbackQuestion}>{QUESTION}</Typography>
      <Button
        className={classes.feedbackButton}
        variant={
          feedbackResult.feedback === FeedbackOptions.DISLIKE
            ? 'primary'
            : 'secondary'
        }
        onClick={() => {
          setFeedbackResult(prev => ({
            ...prev,
            feedback: FeedbackOptions.DISLIKE,
          }));
          setFeedbackDone(false);
        }}
      >
        <Typography>
          <Icon icon="thumbs-down" color="primary" />
        </Typography>
      </Button>
      <Button
        className={classes.feedbackButton}
        variant={
          feedbackResult.feedback === FeedbackOptions.LIKE
            ? 'primary'
            : 'secondary'
        }
        onClick={() => {
          setFeedbackResult(prev => ({
            ...prev,
            feedback: FeedbackOptions.LIKE,
          }));
          setFeedbackDone(true);
        }}
      >
        <Typography>
          <Icon icon="thumbs-up" color="primary" />
        </Typography>
      </Button>
      {feedbackDone ? (
        <Typography className={classes.finalMessage}>
          Thank you for your feedback!
        </Typography>
      ) : (
        <div>
          {!!feedbackResult.feedback && (
            <div>
              <TextField
                style={{ marginTop: 10 }}
                label="Feedback (optional)"
                placeholder="Tell us what could be improved on this documentation"
                onChange={event => {
                  setFeedbackResult(prev => ({
                    ...prev,
                    comment: event.target.value,
                  }));
                }}
                variant="outlined"
                size="small"
                fullWidth
                multiline
                minRows={4}
              />
              <Button
                variant="secondary"
                className={classes.feedbackButton}
                onClick={() => {
                  handleFeedbackEvent('feedbackSubmitButtonPress');
                  setFeedbackDone(true);
                }}
              >
                Submit feedback
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
