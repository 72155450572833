import React from 'react';
import { Typography } from '@material-ui/core';
import { SupportLink } from '../SupportLink';
import { useApp } from '@backstage/core-plugin-api';
import { SupportPageTitle } from '../SupportPageTitle';
import { useSupportStyles } from '../support.styles';

export const BaseplateFeedback = () => {
  const classes = useSupportStyles();
  const app = useApp();
  const BugIconComponent = app.getSystemIcon('bug');
  const IdeaIconComponent = app.getSystemIcon('discussions');
  const FeedbackIconComponent = app.getSystemIcon('feedback');

  return (
    <div>
      <SupportPageTitle title="Feedback for Baseplate" subtitle="Baseplate" />
      <div className={classes.linkGrid}>
        <SupportLink
          title="Share Your Ideas"
          href="https://github.com/LEGO/baseplate/discussions"
          icon={IdeaIconComponent && <IdeaIconComponent />}
          description="Contribute to the platform's ongoing improvement by submitting your innovative ideas, feature requests, or suggestions for enhancements."
        />

        <SupportLink
          title="Report a Bug"
          href="https://github.com/LEGO/baseplate/issues"
          icon={BugIconComponent && <BugIconComponent />}
          description="Help us maintain the platform's stability by reporting any bugs, glitches, or unexpected behaviors you encounter during use."
        />
      </div>
      <Typography variant="subtitle2" className={classes.sectionTitle}>
        How well is Baseplate working for you?
      </Typography>
      <Typography variant="body2" color="secondary">
        4 mins
      </Typography>
      <br />
      <SupportLink
        title="Take our quick survey"
        href="https://forms.office.com/e/pwmHPQsWwk"
        icon={FeedbackIconComponent && <FeedbackIconComponent />}
        description="Help shape the future of our developer portal by taking this quick survey, empowering us to create an exceptional platform tailored to your needs!"
      />
    </div>
  );
};
