import {
  Entity,
  RELATION_OWNER_OF,
  stringifyEntityRef,
} from '@backstage/catalog-model';
import { useApi } from '@backstage/core-plugin-api';
import {
  CatalogApi,
  catalogApiRef,
  getEntityRelations,
} from '@backstage/plugin-catalog-react';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

const getExternalLinkIfExists = async (
  entity: Entity,
  catalogApi: CatalogApi,
) => {
  const ownedComponents = getEntityRelations(entity, RELATION_OWNER_OF, {
    kind: 'Component',
  });

  const componentPromises = ownedComponents.map(async component => {
    try {
      const componentEntity = await catalogApi.getEntityByRef(
        stringifyEntityRef(component),
      );
      if (
        componentEntity?.spec?.type === 'external-link' &&
        componentEntity?.metadata?.links?.length
      ) {
        return componentEntity?.metadata?.links[0].url;
      }
    } catch (error) {
      toast.error(
        `Failed to fetch owned component ${stringifyEntityRef(component)}: ${
          error as string
        }`,
      );
    }
    return null;
  });

  const results = await Promise.all(componentPromises);
  const link = results.find(result => result !== null);
  return link || null;
};

export const useExternalLink = (entity: Entity) => {
  const catalogApi = useApi(catalogApiRef);
  const [externalLink, setExternalLink] = useState<string | null>('');

  useEffect(() => {
    const fetchExternalLinkRelation = async () => {
      const link = await getExternalLinkIfExists(entity, catalogApi);
      setExternalLink(link);
    };
    void fetchExternalLinkRelation();
  }, [entity, catalogApi]);

  return { externalLink };
};
