import { Entity } from '@backstage/catalog-model';
import React from 'react';
import { EntityLinkVariant } from '../types';
import { useEntityLinkStyles } from '../EntityLink.styles';
import { Typography } from '@material-ui/core';
import { Status } from '../../status/Status';
import { EntityLinkFooterTags } from './EntityLinkFooterTags';

interface Props {
  entity: Entity;
  variant: EntityLinkVariant;
}

export const EntityLinkProductFooter = ({ entity, variant }: Props) => {
  const classes = useEntityLinkStyles();

  const peopleCount =
    entity.relations?.filter(r => r.type === 'hasMember').length ?? 0;
  const lifecycle = entity.metadata.labels?.lifecycle;

  switch (variant) {
    case 'thumbnail':
      return (
        <div className={classes.footer}>
          <EntityLinkFooterTags
            tags={[entity.metadata.name, `${peopleCount} people`]}
            entity={entity}
          />
          {lifecycle && (
            <Status
              status={
                {
                  active: 'green',
                  plan: 'neutral',
                  phaseIn: 'yellow',
                  phaseOut: 'yellow',
                  endOfLife: 'neutral',
                }[lifecycle ?? ''] as 'green' | 'yellow' | 'neutral'
              }
              label={lifecycle}
            />
          )}
        </div>
      );

    case 'link':
      return (
        <div>
          <Typography variant="overline" color="secondary">
            {entity.kind} • {peopleCount} people
          </Typography>
        </div>
      );

    default:
      return null;
  }
};
