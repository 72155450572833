import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function TagDollar(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M15 13c0-1.063-.938-2-2-2-1.125 0-2 .938-2 2 0 1.125.875 2 2 2 1.063 0 2-.875 2-2Zm2.844 3.315.863-.598a1.046 1.046 0 0 1 1.477.243c.328.474.194 1.148-.274 1.48l-.003.001-.967.67c-.569.415-.946.917-1.134 1.31-.16.388-.1.596-.054.674.092.154.259.236.495.193.5-.1.952-.383 1.392-.658.148-.093.295-.185.442-.27.572-.325 1.294-.737 2.005-.896.42-.098.935-.13 1.423.038.533.172.927.539 1.259.979l.004.006.004.006c.584.858.518 1.847.16 2.674a3.99 3.99 0 0 1-.451.788l.363.364c.426.426.38 1.104-.016 1.501-.419.419-1.08.439-1.502.017l-.392-.391a10.07 10.07 0 0 1-1.882 1.035 1.03 1.03 0 0 1-1.382-.576l-.003-.008-.002-.007c-.172-.537.06-1.15.648-1.372.46-.18 1.182-.614 1.558-.894l.002-.001c.597-.44.953-.896 1.12-1.262.139-.325.107-.471.008-.649-.14-.165-.29-.24-.501-.188-.428.105-.798.325-1.169.545a9.4 9.4 0 0 1-.52.295c-.592.36-1.39.814-2.165.988-.422.098-.938.13-1.427-.04l-.006-.002a2.532 2.532 0 0 1-1.224-.988c-.606-.883-.478-1.895-.125-2.707.12-.29.271-.543.45-.79l-.413-.412c-.4-.401-.406-1.08.017-1.502.402-.402 1.097-.422 1.502-.017l.42.42Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.125 7.188A3.976 3.976 0 0 0 18.312 6H9a3 3 0 0 0-3 3v9.375c0 1.063.375 2.063 1.125 2.813l11 11a4.036 4.036 0 0 0 5.688 0l8.312-8.313a4.036 4.036 0 0 0 0-5.688l-11-11ZM9.25 19.063c-.188-.188-.25-.438-.25-.688V9h9.313c.25 0 .5.125.687.313l11 11a.951.951 0 0 1 0 1.437l-8.313 8.313c-.375.375-1.062.375-1.437 0l-11-11Z"
      />
    </SvgIcon>
  );
}
