import React from 'react';
import { type ReactElement } from 'react';
import { Endpoint } from './Endpoint';
import { EndpointGroupHeader } from './EndpointGroupHeader';
import { UiGroupedEndpointType } from '../../../types';
import { useDefinitionTabStyles } from '../DefinitionTab.styles';

export interface OpenApiRendererProps {
  groupedEndpoints: UiGroupedEndpointType[];
}

export function OpenApiDefinition({
  groupedEndpoints,
}: OpenApiRendererProps): ReactElement {
  const classes = useDefinitionTabStyles();
  return (
    <>
      <div className={classes.content}>
        {groupedEndpoints.map(({ name, endpoints }) => (
          <>
            <EndpointGroupHeader name={name} />
            {endpoints.map(endpoint => (
              <Endpoint {...endpoint} />
            ))}
          </>
        ))}
      </div>
    </>
  );
}
