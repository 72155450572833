import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function LaptopMobile(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M8 7c-.563 0-1 .5-1 1v16h15v4H3c-1.688 0-3-1.313-3-3 0-.5.438-1 1-1h3V8c0-2.188 1.75-4 4-4h20c2.188 0 4 1.813 4 4v2h-3V8c0-.5-.5-1-1-1H8Zm16 8a3 3 0 0 1 3-3h10c1.625 0 3 1.375 3 3v18a3 3 0 0 1-3 3H27c-1.688 0-3-1.313-3-3V15Zm3 0v18h10V15H27Z" />
    </SvgIcon>
  );
}
