import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function FilePen(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M6.023 32.989h12.375l-.312 1.312c-.125.563-.125 1.188.062 1.688H6.023c-2.25 0-4-1.75-4-4v-24c0-2.188 1.75-4 4-4h10.313c1.062 0 2.062.437 2.812 1.187l5.688 5.688c.75.75 1.187 1.75 1.187 2.812v8.188l-3 3V13.989h-5c-1.125 0-2-.875-2-2v-5h-10c-.562 0-1 .5-1 1v24c0 .562.438 1 1 1Zm30.313-14.25.937.937c.938.938.938 2.5 0 3.5l-1.875 1.875-4.437-4.437 1.875-1.875c.937-.938 2.562-.938 3.5 0ZM21.46 30.05l8.125-8.062 4.437 4.437-8.125 8.063c-.25.25-.562.437-.875.562l-3.812.938c-.313.062-.688 0-.938-.25s-.312-.625-.25-1l.938-3.75c.062-.313.25-.688.5-.938Z" />
    </SvgIcon>
  );
}
