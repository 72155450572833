import { Entity } from '@backstage/catalog-model';
import { startCase } from 'lodash';
import { util } from './utilFunc';

export const createCatalogEntity = (
  name: string,
  email: string,
  userId?: string,
): Entity => {
  const displayName = getDisplayName(name, userId ?? '');

  return {
    apiVersion: '',
    kind: 'User',
    metadata: {
      etag: '',
      name: userId ?? '',
      namespace: 'default',
      title: displayName,
      uid: '',
    },
    spec: {
      profile: {
        displayName: displayName,
        email: email,
      },
    },
  };
};

/**
 * Handle cases where a user's display name might be empty (User is not an entity in catalog).
 * In such scenarios, it uses the userName as the display name. This ensures that there is always
 * a display name for the user, providing a better user experience.
 * @export
 * @param {string} name
 * @param {string} userName
 * @return {*}  {string}
 */
export function getDisplayName(name: string, userName: string): string {
  return !util().isNullOrUndefined(name)
    ? name
    : startCase(userName.replaceAll('.', ' '));
}
