import React, { ReactNode } from 'react';
import { type ReactElement } from 'react';

export interface ChartWrapperProps {
  height: number;
  children: ReactNode;
}

export function ChartWrapper({
  height,
  children,
}: ChartWrapperProps): ReactElement {
  return <div style={{ height }}>{children}</div>;
}
