import { Avatar, TableColumn } from '@backstage/core-components';
import { Employee } from '@lego/plugin-baseplate-people-to-product-common';
import React from 'react';
import ProductTags from '../../../components/ProductTags/ProductTags';

export const columns: TableColumn<Employee>[] = [
  { title: 'Id', field: 'id', hidden: true },
  {
    title: 'Avatar',
    field: 'avatar',
    render: employee => {
      return (
        <Avatar picture={employee.image} displayName={employee.fullName} />
      );
    },
    sorting: false,
  },
  { title: 'Name', field: 'fullName' },
  { title: 'Position', field: 'position' },
  { title: 'Email', field: 'email' },
  { title: 'People Leader Id', field: 'peopleLeaderId', hidden: true },
  {
    title: 'Products',
    field: 'products',
    render(employee) {
      const products = employee.products?.map(e => e.node) ?? [];
      const gops = employee.generalOverheadProducts?.map(e => e.node) ?? [];
      return (
        <ProductTags
          products={[...products, ...gops]}
          employeeName={employee.fullName}
        />
      );
    },
    align: 'center',
    customSort: (data1, data2) => {
      const product1 = data1.products[0]?.node.name ?? '';
      const product2 = data2.products[0]?.node.name ?? '';
      return product1.localeCompare(product2);
    },
  },
];
