import { Role } from '@lego/plugin-baseplate-eagraph-common';
import {
  ConfigApi,
  DiscoveryApi,
  FetchApi,
  IdentityApi,
} from '@backstage/core-plugin-api';
import { ResponseError } from '@backstage/errors';
import {
  Employee,
  EmployeeProductRoleRecord,
  GeneralOverheadProduct,
  OpenPosition,
  OpenPositionProductRecord,
  OverviewStatistics,
  ProductTcoInput,
} from '@lego/plugin-baseplate-people-to-product-common';
import { PeopleToProductApi } from './PeopleToProductApi';

export class PeopleToProductClient implements PeopleToProductApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi: FetchApi;
  private readonly identityApi: IdentityApi;

  constructor(options: {
    discoveryApi: DiscoveryApi;
    fetchApi: FetchApi;
    identityApi: IdentityApi;
    configApi: ConfigApi;
  }) {
    this.discoveryApi = options.discoveryApi;
    this.fetchApi = options.fetchApi;
    this.identityApi = options.identityApi;
  }

  // Methods for fetching data for the overview cards

  async getReportsMappingStatistics(
    externalJobCodes: string,
  ): Promise<OverviewStatistics> {
    const { email } = await this.identityApi.getProfileInfo();
    if (!email) throw new Error('No email found');
    return (await this.fetch(
      `/statistics/reports?peopleLeaderEmail=${email}&externalJobCodes=${externalJobCodes}`,
      'GET',
    )) as OverviewStatistics;
  }

  async getOpenPositionsMappingStatistics(
    externalJobCodes: string,
  ): Promise<OverviewStatistics> {
    const { email } = await this.identityApi.getProfileInfo();
    if (!email) throw new Error('No email found');
    return (await this.fetch(
      `/statistics/open-positions?peopleLeaderEmail=${email}&externalJobCodes=${externalJobCodes}`,
      'GET',
    )) as OverviewStatistics;
  }

  async getNonDigitalProductManagersStatistics(
    externalJobCodes: string,
  ): Promise<OverviewStatistics> {
    return (await this.fetch(
      `/statistics/non-digital-product-managers?externalJobCodes=${externalJobCodes}`,
      'GET',
    )) as OverviewStatistics;
  }

  // Methods for exporting CSV data

  async getReportsMappingForCsv(): Promise<EmployeeProductRoleRecord[]> {
    return (await this.fetch(
      '/export/reports',
      'GET',
    )) as EmployeeProductRoleRecord[];
  }

  async getOpenPositionsMappingForCsv(): Promise<OpenPositionProductRecord[]> {
    return (await this.fetch(
      '/export/open-positions',
      'GET',
    )) as OpenPositionProductRecord[];
  }

  // Methods for fetching data for the tables

  async getReports(): Promise<Employee[]> {
    const { email } = await this.identityApi.getProfileInfo();
    if (!email) throw new Error('No email found');
    const result = await this.fetch(
      `/reports?peopleLeaderEmail=${email}`,
      'GET',
    );
    return result as Employee[];
  }

  async getOpenPositions(): Promise<OpenPosition[]> {
    const { email } = await this.identityApi.getProfileInfo();
    if (!email) throw new Error('No email found');
    const result = await this.fetch(
      `/open-positions?peopleLeaderEmail=${email}`,
      'GET',
    );
    return result as OpenPosition[];
  }

  async getNonDigitalProductManagers(): Promise<Employee[]> {
    return (await this.fetch(
      '/non-digital-product-managers',
      'GET',
    )) as Employee[];
  }

  async addGeneralOverheadProductToEmployee(
    employeeId: number,
    productId: string,
  ): Promise<void> {
    return await this.fetch(
      `/employees/${employeeId}/general-overhead-products`,
      'POST',
      { productId },
    );
  }

  async fetchGeneralOverheadProducts(): Promise<GeneralOverheadProduct[]> {
    const result = await this.fetch('/general-overhead-products', 'GET');
    return result as GeneralOverheadProduct[];
  }

  async deleteProductFromEmployee(
    employeeId: number,
    productId: string,
  ): Promise<void> {
    return this.fetch(
      `/employees/${employeeId}/products/${productId}`,
      'DELETE',
    );
  }

  async addProductToEmployee(
    employeeId: number,
    productId: string,
    roles: Role[],
    isFirstProduct: boolean,
  ): Promise<void> {
    return this.fetch(`/employees/${employeeId}/products`, 'POST', {
      productId,
      roles,
      isFirstProduct,
    });
  }

  async updateTcoProductForEmployee(
    employeeId: number,
    productId: string,
    productTcoMapping: ProductTcoInput[],
  ): Promise<void> {
    return this.fetch(`/employees/${employeeId}/products/${productId}`, 'PUT', {
      productTcoMapping,
    });
  }

  async addProductToOpenPosition(
    openPositionId: number,
    productId: string,
  ): Promise<void> {
    return this.fetch(`/open-positions/${openPositionId}/product`, 'POST', {
      productId,
    });
  }

  async deleteProductFromOpenPosition(
    openPositionId: number,
    productId: string,
  ): Promise<void> {
    return this.fetch(
      `/open-positions/${openPositionId}/product/${productId}`,
      'DELETE',
    );
  }

  async updateRolesOnProductForEmployee(
    employeeId: number,
    productId: string,
    rolesToRemove: Role[],
    rolesToAdd: Role[],
  ): Promise<void> {
    return this.fetch(
      `/employees/${employeeId}/products/${productId}/roles`,
      'PUT',
      { rolesToRemove, rolesToAdd },
    );
  }

  async addGeneralOverheadProductToOpenPosition(
    openPositionId: number,
    generalOverheadProductId: string,
  ): Promise<void> {
    return this.fetch(
      `/open-positions/${openPositionId}/generalOverheadProduct`,
      'POST',
      { generalOverheadProductId },
    );
  }

  async deleteGeneralOverheadProductFromOpenPosition(
    openPositionId: number,
  ): Promise<void> {
    return this.fetch(
      `/open-positions/${openPositionId}/generalOverheadProduct`,
      'DELETE',
    );
  }

  async fetch(path: string, method: string, body?: object): Promise<any> {
    const url = await this.discoveryApi.getBaseUrl('people-to-product');

    const response = await this.fetchApi.fetch(`${url}${path}`, {
      method,
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }

    if (response.status === 204) {
      return null;
    }

    const result = await response.json();

    return result;
  }
}
