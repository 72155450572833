import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Facebook(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M36 19.98c0 8-5.871 14.646-13.548 15.807V24.626h3.741l.71-4.646h-4.451v-2.967c0-1.29.645-2.516 2.645-2.516h2V10.56s-1.807-.322-3.613-.322c-3.613 0-6 2.258-6 6.258v3.483h-4.065v4.646h4.065v11.16C9.806 34.627 4 27.98 4 19.98c0-8.838 7.161-16 16-16s16 7.162 16 16Z" />
    </SvgIcon>
  );
}
