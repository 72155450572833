import { createApiRef } from '@backstage/core-plugin-api';
import {
  CommunityMembersResponse,
  CommunityV2,
} from '@lego/plugin-baseplate-communities-common';

export const communitiesV2ApiRef = createApiRef<CommunitiesV2Api>({
  id: 'plugin.communities-v2.apis',
});

export interface CommunitiesV2Api {
  createCommunity(community: CommunityV2): Promise<CommunityV2>;
  updateCommunity(community: CommunityV2): Promise<CommunityV2>;
  joinCommunity(
    communityId: string,
    userEntityRef: string,
  ): Promise<CommunityV2>;
  leaveCommunity(
    communityId: string,
    userEntityRef: string,
  ): Promise<CommunityV2>;
  getCommunityMemberEntityRefs(
    communityId: string,
  ): Promise<CommunityMembersResponse>;
}
