import { homePlugin } from '@backstage/plugin-home';
import { createCardExtension } from '@backstage/plugin-home-react';

/**
 * A component for displaying the relations of a product
 *
 * @public
 */
export const ProductRelationsTreeCard = homePlugin.provide(
  createCardExtension({
    name: 'ProductRelationsTree',
    title: 'Ownership',
    description: 'Shows the ownership tree of a product',
    components: () => import('./Cards/ProductRelationsTree'),
  }),
);

/**
 * A component for displaying the relations of a product
 *
 * @public
 */
export const FeaturedDocsCard = homePlugin.provide(
  createCardExtension({
    name: 'FeaturedDocsCard',
    title: 'Recommended Reading',
    description: 'Shows a card with recommended reading.',
    components: () => import('./Cards/FeaturedDocsCard'),
  }),
);

/**
 * A component for displaying favorite entities
 *
 * @public
 */
export const FavoriteEntitiesCard = homePlugin.provide(
  createCardExtension({
    name: 'FavoriteEntitiesCard',
    title: 'Favorite Entities',
    description: 'Shows a card with your favorite entities',
    components: () => import('./Cards/FavoriteEntities'),
  }),
);

/**
 * A component for displaying the latest posts from the DevRel SharePoint blog and the LEGO Engineering Medium blog
 *
 * @public
 */
export const ArticleFeed = homePlugin.provide(
  createCardExtension({
    name: 'ArticleFeed',
    title: 'Feed',
    description:
      'Shows a card with the latest posts from the DevRel SharePoint blog and the LEGO Engineering Medium blog',
    components: () => import('./Cards/ArticleFeedCard'),
  }),
);
