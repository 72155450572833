import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function BrickOneByOneSideBlue(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path
        d="M1 16.26h7.126V9.954C8.126 8.848 9.015 8 10.144 8h19.698c1.143 0 2.018.861 2.018 1.955v6.304H39V31H1V16.26Z"
        fill="#0DADE4"
      />
    </SvgIcon>
  );
}
