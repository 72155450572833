import { useApi } from '@backstage/core-plugin-api';
import { Spinner, showToast } from '@lego/plugin-baseplate-core-components';
import {
  Employee,
  GeneralOverheadProduct,
} from '@lego/plugin-baseplate-people-to-product-common';
import {
  Checkbox,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useState } from 'react';
import useAsync from 'react-use/lib/useAsync';
import { peopleToProductApiRef } from '../../api/PeopleToProductApi';
import ConfirmationActions from '../../components/ConfirmationActions/ConfirmationActions';
import TooltipEditIconButton from '../../components/TooltipEditIconButton/TooltipEditIconButton';
import { useReport } from '../../hooks';

type Props = {
  rowData: GeneralOverheadProduct;
  employee: Employee;
};

function GeneralOverheadProductTableRow({ rowData, employee }: Props) {
  const peopleToProductApi = useApi(peopleToProductApiRef);

  const { value: generalOverheadProducts, loading } = useAsync(async () => {
    return await peopleToProductApi.fetchGeneralOverheadProducts();
  });

  const { addGeneralOverheadProductToEmployee } = useReport();

  const [selectedGop, setSelectedGop] = useState<GeneralOverheadProduct>();

  const [isEditing, setIsEditing] = useState(false);

  const handleOnEditButtonClick = () => {
    setIsEditing(true);
  };

  const handleOnCancelButtonClick = () => {
    setIsEditing(false);
  };

  const handleOnSaveButtonClick = async () => {
    try {
      if (!isEditing) {
        return;
      }
      if (!selectedGop) {
        void showToast({
          type: 'error',
          message:
            'Please select a product that you want to assign to this employee',
        });
        return;
      }
      await addGeneralOverheadProductToEmployee(employee, selectedGop);

      void showToast({
        type: 'success',
        message: (
          <Typography>
            Successfully updated the General Overhead Product for{' '}
            {employee.fullName}
          </Typography>
        ),
      });
      setIsEditing(false);
    } catch (error: any) {
      try {
        const messages = JSON.parse(error.body.error.message);

        void showToast({
          type: 'error',
          message: (
            <>
              <Typography>
                Failed to update General Overhead Product for{' '}
                {employee.fullName}. Reasons:
              </Typography>
              <ul>
                {messages.map((e: Error) => (
                  <li>
                    <Typography>{e.message}</Typography>
                  </li>
                ))}
              </ul>
            </>
          ),
        });
      } catch (_) {
        void showToast({
          type: 'error',
          message:
            'Something went wrong. Please refresh and try again. If the issue persists, please reach out to Enterprise Transparency.',
        });
      }
    }
  };

  return (
    <TableRow>
      <TableCell>
        {isEditing ? (
          <Autocomplete
            id="gop-dropdown"
            options={generalOverheadProducts ?? []}
            autoSelect
            value={rowData}
            getOptionLabel={option => option.name}
            onChange={(_, value) => value && setSelectedGop(value)}
            loading={loading}
            style={{ minWidth: 200 }}
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? <Spinner size="large" width={30} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        ) : (
          rowData.name
        )}
      </TableCell>
      <TableCell>
        <Checkbox checked />
      </TableCell>
      <TableCell>
        {isEditing ? (
          <ConfirmationActions
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            handleOnSaveButtonClick={handleOnSaveButtonClick}
            handleOnCancelButtonClick={handleOnCancelButtonClick}
          />
        ) : (
          <TooltipEditIconButton
            onClick={handleOnEditButtonClick}
            disabled={isEditing}
          />
        )}
      </TableCell>
    </TableRow>
  );
}

export default GeneralOverheadProductTableRow;
