import { ComponentRenderer } from '@backstage/plugin-home-react';
import { Card } from '@lego/plugin-baseplate-core-components';
import React from 'react';

export const Renderer: ComponentRenderer['Renderer'] = ({
  title,
  Content,
  Actions,
  Settings,
  ContextProvider,
}) => {
  const content = (
    <div>
      <Card title={title}>
        <Content />
      </Card>
      {Actions && <Actions />}
      {Settings && <Settings />}
    </div>
  );

  return ContextProvider ? (
    <ContextProvider>{content}</ContextProvider>
  ) : (
    content
  );
};
