/*
    Sanitize the skill name according to the following rules: 
    * No more than one consecutive space: The text cannot have more than one space in a row.
    * No consecutive identical symbols: The text cannot have two identical symbols (like "&", "#", etc.) right next to each other, but it allows numbers and letters to be consecutive.
    * No two consecutive special symbols: It doesn't allow two consecutive special symbols (like "&&" or "##"), but it permits letters, numbers, spaces, and specific special symbols to occur consecutively.
    * No leading or trailing spaces: The text cannot start or end with a space.
    * Allowed characters: The text can contain letters, numbers, spaces, and specific special symbols like "_", "&", "#".
*/

export const sanitizeSkill = (text: string): string => {
  // Remove any characters that don't match the regex pattern
  let sanitizedInput = text.replace(/[^a-zA-Z0-9&_#. ]/g, '');

  // Replace consecutive identical symbols with one symbol
  sanitizedInput = sanitizedInput.replace(/([^\w\s])\1+/g, '$1');

  return sanitizedInput;
};
