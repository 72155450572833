import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function MagnifyingGlassMinus(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M17.016 6.984c-3.625 0-6.875 1.938-8.688 5-1.812 3.125-1.812 6.938 0 10 1.813 3.125 5.063 5 8.688 5 3.562 0 6.812-1.875 8.625-5 1.812-3.062 1.812-6.875 0-10-1.813-3.062-5.063-5-8.625-5Zm0 23c-7.188 0-13-5.812-13-13 0-7.125 5.812-13 13-13 7.125 0 13 5.875 13 13 0 3.063-1.063 5.875-2.813 8.125l8.313 8.375a1.37 1.37 0 0 1 0 2.063 1.37 1.37 0 0 1-2.063 0l-8.375-8.313a13.096 13.096 0 0 1-8.062 2.75Zm-4.5-14.5h9c.812 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5h-9c-.875 0-1.5-.625-1.5-1.5 0-.812.625-1.5 1.5-1.5Z" />
    </SvgIcon>
  );
}
