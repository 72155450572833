import { DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';
import { ResponseError } from '@backstage/errors';
import { JsonObject } from '@backstage/types';
import { IEspClient } from './IEspClient';
import { IEspStatus } from '../types';

export class EspClient implements IEspClient {
  private readonly fetchApi: FetchApi;
  private readonly discoveryApi: DiscoveryApi;

  constructor({
    fetchApi,
    discoveryApi,
  }: {
    fetchApi: FetchApi;
    discoveryApi: DiscoveryApi;
  }) {
    this.fetchApi = fetchApi;
    this.discoveryApi = discoveryApi;
  }

  getUrl = async (path: string, environment: string): Promise<URL> => {
    const url = await this.discoveryApi.getBaseUrl('esp-status');
    const baseUrl = `${url}/v1/${environment}/${path}`;
    return new URL(baseUrl);
  };

  getStatus = async (environment: string): Promise<IEspStatus> => {
    const url = await this.getUrl('status', environment);
    const response = await this.fetchApi.fetch(url);
    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }
    return await response.json();
  };

  getMetrics = async (
    environment: string,
    metricType: string,
    queryParams: JsonObject,
  ): Promise<any> => {
    const url = await this.getUrl(`metrics/${metricType}`, environment);
    const response = await this.fetchApi.fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(queryParams),
    });
    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }
    return await response.json();
  };
}
