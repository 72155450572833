import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ChevronLeft(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="m11.96 18.906 12-11.937c.563-.625 1.5-.625 2.126 0 .562.562.562 1.5 0 2.062l-11 10.938 10.937 11c.625.562.625 1.5 0 2.062-.562.625-1.5.625-2.062 0l-12-12c-.625-.562-.625-1.5 0-2.125Z" />
    </SvgIcon>
  );
}
