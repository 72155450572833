import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function CubeArrows3d(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M10 38.547C10 39.339 9.34 40 8.547 40H1.5c-.881 0-1.542-.66-1.498-1.498v-7.047a1.364 1.364 0 0 1 1.454-1.453c.836-.044 1.497.617 1.497 1.498v3.426l4.986-4.987a1.5 1.5 0 1 1 2.122 2.122l-4.989 4.988h3.43a1.364 1.364 0 0 1 1.454 1.453l.044.045Zm8.5-33.453h-.016L16.047 7.53a1.37 1.37 0 0 1-2.063 0h-.062a1.471 1.471 0 0 1 0-2.062l5-5c.625-.625 1.562-.625 2.125 0l5 5c.625.562.625 1.5 0 2.062-.563.625-1.5.625-2.125 0L21.5 5.11v6.39a1.5 1.5 0 1 1-3 0V5.094ZM32.06 29.94a1.5 1.5 0 0 0-2.12 2.12l4.988 4.99h-3.43a1.364 1.364 0 0 0-1.454 1.453l-.044.044c0 .792.66 1.453 1.453 1.453H38.5c.881 0 1.542-.66 1.498-1.498v-7.047a1.364 1.364 0 0 0-1.454-1.453c-.836-.044-1.497.617-1.497 1.498v3.426l-4.986-4.987Z" />
      <path d="M6.25 12.687 12 10.5v3.21l-4.063 1.54L20 20.004l12-4.752-4-1.517v-3.21l5.687 2.162A3.521 3.521 0 0 1 36 16.001v11.71h-3v-9.584L21.5 22.63v13.32l6.5-2.484v3.21l-6.813 2.59c-.75.312-1.625.312-2.437 0L12 36.699V33.48l6.5 2.47V22.63L7 18.127v9.584H4v-11.71c0-1.5.875-2.814 2.25-3.315Z" />
    </SvgIcon>
  );
}
