import { Role } from '@lego/plugin-baseplate-eagraph-common';

export function getReadableRole(enumRole: string): string {
  const readableRoles: { [key in Role]: string } = {
    [Role.ProductMember]: 'Product Member',
    [Role.LeadEngineer]: 'Lead Engineer',
    [Role.ProductManager]: 'Product Manager',
    [Role.ProductDesigner]: 'Product Designer',
    [Role.CompetencyLead]: 'Competency Lead',
  };

  return readableRoles[enumRole as Role] || 'Unknown Role';
}
