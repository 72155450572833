import { Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import { useEntityLinkStyles } from '../entity-link/EntityLink.styles';

const useStyles = makeStyles(_ => ({
  anchor: {
    textDecoration: 'none',
    color: 'inherit',
  },
  linkContainerBorderless: {
    border: 'none',
    padding: '0 !important',
  },
  linkContainer: {
    width: '100%',
    padding: '0.5rem 1rem',
    overflow: 'hidden',
  },
  linkText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  body: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: '4px',
  },
}));

type Props = {
  title?: string;
  url: string;
  className?: string;
  isBorderless?: boolean;
};

export const LinkCard = ({ title, url, className, isBorderless }: Props) => {
  const linkClasses = useEntityLinkStyles();
  const classes = useStyles();

  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className={[classes.anchor, className].join(' ')}
    >
      <div
        className={[
          isBorderless ? classes.linkContainerBorderless : '',
          linkClasses.wrapper,
          classes.linkContainer,
        ].join(' ')}
      >
        {title ? <Typography variant="body1">{title}</Typography> : null}
        <div className={classes.body}>
          <img
            src={`https://www.google.com/s2/favicons?domain=${url}`}
            alt={title ?? ''}
            className={classes.icon}
          />
          <Typography
            variant="caption"
            color="secondary"
            className={classes.linkText}
          >
            {url}
          </Typography>
        </div>
      </div>
    </a>
  );
};
