import React from 'react';
import { EntityLinkVariant } from '../types';
import { useEntityLinkStyles } from '../EntityLink.styles';
import { Typography } from '@material-ui/core';
import { LicenceChip } from './LicenceChip/LicenceChip';
import { ToolEntity } from '@lego/plugin-baseplate-common';
import { Status, leanIxStatusToColor } from '../../status';

interface Props {
  entity: ToolEntity;
  variant: EntityLinkVariant;
}

export const EntityLinkToolFooter = ({ entity, variant }: Props) => {
  const classes = useEntityLinkStyles();
  const licence = entity.metadata?.licence;
  const lifecycle = entity.spec.lifecycle;

  switch (variant) {
    case 'thumbnail':
      return (
        <div className={classes.footer}>
          <div>
            <LicenceChip licence={licence} />
          </div>
          <Status status={leanIxStatusToColor(lifecycle)} label={lifecycle} />
        </div>
      );

    case 'link':
      return (
        <Typography
          variant="overline"
          color="secondary"
          className={classes.linkFooterWrapper}
        >
          <div>Tool</div>
          <div>•</div>
          <Status status={leanIxStatusToColor(lifecycle)} label={lifecycle} />
        </Typography>
      );
    default:
      return null;
  }
};
