import { stringifyEntityRef } from '@backstage/catalog-model';
import {
  AutocompleteDropdown,
  EntityDropdown,
} from '@lego/plugin-baseplate-core-components';
import { FormControl, FormHelperText, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { EntityPickerExtensionProps } from './schema';
import { AvailableKinds } from '@lego/plugin-baseplate-common';

export const EntityPickerExtension = ({
  onChange,
  required,
  formData,
  uiSchema,
  schema: { title, description },
}: EntityPickerExtensionProps) => {
  const [kind, setKind] = useState(uiSchema['ui:options']?.kind ?? 'Product');
  const chooseWhichKind = uiSchema['ui:options']?.kindPicker ?? false;
  const helperText = uiSchema['ui:options']?.helperText as string;
  const options = uiSchema['ui:options']?.kindPicker?.options as string[] | [];
  const kindPickerLabel = uiSchema['ui:options']?.kindPicker?.label;
  const kindPickerHelperText = uiSchema['ui:options']?.kindPicker?.helperText;

  const handleKindChange = (newKind: string) => {
    setKind(newKind);
  };

  return (
    <div
      style={{
        width: '100%',
        padding: 0,
        display: 'flex',
        justifyContent: 'space-between',
        gap: '1rem',
      }}
    >
      {chooseWhichKind && (
        <div style={{ flex: 1 }}>
          <FormControl style={{ width: '100%' }}>
            <AutocompleteDropdown
              options={options}
              required
              disableClearable
              label={kindPickerLabel ?? 'Choose kind'}
              onChange={(_, value) => handleKindChange(value)}
            />
            {kindPickerHelperText && (
              <FormHelperText>
                <Typography variant="body2" color="secondary">
                  {kindPickerHelperText}
                </Typography>
              </FormHelperText>
            )}
          </FormControl>
        </div>
      )}
      <div style={{ flex: 1 }}>
        <FormControl required={required} style={{ width: '100%' }}>
          <EntityDropdown
            required={required}
            value={formData}
            helperText={description}
            handleChange={value =>
              value ? onChange(stringifyEntityRef(value)) : onChange(undefined)
            }
            kind={kind as AvailableKinds}
            label={chooseWhichKind ? title : title ?? 'Choose entity'}
          />
          {helperText && (
            <FormHelperText style={{ padding: '0 1rem' }}>
              <Typography variant="body2" color="secondary">
                {helperText}
              </Typography>
            </FormHelperText>
          )}
        </FormControl>
      </div>
    </div>
  );
};
