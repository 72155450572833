import { format } from 'date-fns';
import moment from 'moment';
import { isEqual } from 'lodash';
import {
  dateFormats,
  extractDateTimeProps,
  sameDateCheck,
} from '@lego/plugin-baseplate-learning-and-development-common';

export const dateCompare = (startDate: Date, endDate: Date) => {
  const date1 = new Date(startDate);
  const date2 = new Date(endDate);
  let isTrue;

  if (date1 > date2 || date1 < date2) {
    isTrue = false;
  } else {
    isTrue = true;
  }

  return isTrue;
};

export const timeCompare = (startDate: Date, endDate: Date) => {
  const date1 = new Date(startDate);
  const date2 = new Date(endDate);

  const startTime = format(date1, dateFormats.time);
  const endTime = format(date2, dateFormats.time);
  let isTrue;

  if (startTime > endTime || startTime < endTime) {
    isTrue = false;
  } else {
    isTrue = true;
  }

  return isTrue;
};

export const startDateIsEqualToCurrent = (startDate: Date) => {
  const current = new Date();
  const start = new Date(startDate);
  let isTrue;

  if (current > start || current < start) {
    isTrue = false;
  } else {
    isTrue = true;
  }

  return isTrue;
};

export const handleDateFormatRangeMonth = (startDate: Date, endDate: Date) => {
  const startDT = moment(startDate);
  const newStart = new Date(startDate);
  const newEnd = new Date(endDate);
  const sameDate = sameDateCheck(newStart, newEnd, dateFormats.default);
  const sameTime = sameDateCheck(newStart, newEnd, dateFormats.time);

  const start = extractDateTimeProps(newStart, true);
  const end = extractDateTimeProps(newEnd, true);

  let date = '';

  const timeSlot = `${start.hour}:${start.min}${
    !sameTime ? `-${end.hour}:${end.min}` : ''
  }`;

  if (sameDate) {
    date = `${moment(startDT).format(
      dateFormats.short_day_date_month_wo_y,
    )} AT ${timeSlot}`;
  } else {
    if (isEqual(start.month, end.month) && isEqual(start.year, end.year)) {
      date =
        `${start.day}, ${start.month} ${start.date}-${end.date}` +
        ` AT ${timeSlot}`;
    } else if (
      !isEqual(start.month, end.month) &&
      isEqual(start.year, end.year)
    ) {
      date =
        `${start.day}, ${start.month} ${start.date}-${end.month} ${end.date}, ${start.year}` +
        ` AT ${timeSlot}`;
    } else if (
      !isEqual(start.month, end.month) &&
      !isEqual(start.year, end.year)
    ) {
      date = `${start.day}, ${moment(
        `${start.month} ${start.date} ${start.year}`,
      ).format(dateFormats.short_date_month)}-${moment(
        `${end.month} ${end.date} ${end.year}`,
      ).format(dateFormats.short_date_month)} AT ${timeSlot}`;
    }
  }

  return date;
};

export function yesterday() {
  const date = new Date();
  date.setDate(date.getDate() - 1);
  return moment(date).format(dateFormats.default);
}

export function firstDayOfTheYear() {
  const now = new Date(); // Get the current date
  const year = now.getFullYear(); // Get the current year
  const firstDayOfYear = new Date(year, 0, 1);
  const formattedDate = firstDayOfYear.toISOString().slice(0, 10);
  return formattedDate;
}

/**
 * Calculate a date from the current date.
 * @export
 * @param {number} count : The number of units (years/months/days) to add or subtract from the current date.
 * @param {('year' | 'month' | 'date')} part : Specifies the unit of time to be added or subtracted ('year', 'month', or 'date').
 * @param {boolean} future : If true, the function will return a future date from now; if false, it will return a past date.
 * @param {string} getDateFromNow: If defined, the function will return a formatted date based on the specified format; if not, it will return the calculated date
 * @return {Date | string} : The calculated date or formatted date string.
 */
export function getDateFromNow(
  count: number,
  part: 'year' | 'month' | 'date',
  future: boolean,
  dateFormat?: string,
): Date | string {
  const now = new Date();

  switch (part) {
    case 'year':
      now.setFullYear(
        future ? now.getFullYear() + count : now.getFullYear() - count,
      );
      break;
    case 'month':
      now.setMonth(future ? now.getMonth() + count : now.getMonth() - count);
      break;
    default:
      now.setDate(future ? now.getDate() + count : now.getDate() - count);
  }

  return dateFormat ? moment(now).format(dateFormat) : now;
}
