import { useMemo, useState } from 'react';
import { TableRowType } from '../types';

export const filterDataByQuery = <T>(
  data: TableRowType<T>[],
  query: string,
): TableRowType<T>[] => {
  return data.filter(row => {
    return Object.values(row.columns).some(column => {
      if (!column.value) return false;
      if (column.type === 'custom') return false;
      return column.value
        .toString()
        .toLowerCase()
        .includes(query.toLowerCase());
    });
  });
};

export const useTableSearch = <T>(data: TableRowType<T>[]) => {
  const [query, setQuery] = useState('');

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };

  const searchFilteredData = useMemo(
    () => filterDataByQuery<T>(data, query),
    [data, query],
  );

  return { query, handleSearchChange, searchFilteredData };
};
