import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { BackstageTheme } from '@backstage/theme';
import { BaseplateButton } from '@lego/plugin-baseplate-core-components';
import { Entity } from '@backstage/catalog-model';
import { EntityLink } from '@lego/plugin-baseplate-core-components';
import {
  Paper,
  Typography,
  makeStyles,
  TextareaAutosize,
  useTheme,
  Theme,
} from '@material-ui/core';
import SparklesIcon from './SparklesIcon';
import {
  LangChainMessage,
  UserMessage,
} from '@lego/plugin-baseplate-ai-common';

const useStyles = makeStyles<BackstageTheme>(theme => ({
  formContainer: {
    left: '0',
    right: '0',
    width: '100%',
    paddingTop: '24px',
  },
  inputContainer: {
    padding: '0px',
    // position: 'fixed', //TODO: interfers with the dismissablebanners of Baseplate
    zIndex: 999,
    maxWidth: '900px',
    width: '100%',
    margin: '0 auto',
    display: 'flex',
    alignItems: 'flex-end',
    marginBottom: '1rem',
    gap: '1rem',
  },
  input: {
    width: '100%',
    overflow: 'auto',
    resize: 'none',
    flexGrow: 1,
    color: theme.palette.text.primary,
    background: theme.palette.background.paper,
    fontFamily: theme.typography.fontFamily,
    fontSize: '1rem',
    borderRadius: '4px',
    padding: '12px',
    height: '100%',
  },
  contextContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: '1rem',
    padding: '0px 24px',
    marginBottom: '1rem',
  },
  buttonContainer: {
    padding: '5px 5px',
    display: 'flex',
    justifyContent: 'space-around',
    borderRadius: '8px',
    maxWidth: '110px',
  },
  contextText: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  buttonText: {
    color: 'white',
    paddingLeft: '8px',
  },
  sampleContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: '1rem',
    padding: '0px 24px',
  },
  loadingIcon: {
    color: 'white',
  },
  tokenInfo: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '1rem',
    color: theme.palette.text.secondary,
  },
  guidelineText: {
    marginBottom: '1rem',
    marginTop: '1rem',
    textAlign: 'center',
  },
}));

type inputProps = {
  handleChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  handleGenerateButtonClick: () => void;
  loading: boolean;
  prompt: string;
  samples: React.ReactNode;
  placeholder: string;
  entity?: Entity;
  textAreaRef: React.RefObject<HTMLTextAreaElement>;
  pastMessages: (LangChainMessage | UserMessage)[];
  context?: string;
};

export const TextInput: React.FC<inputProps> = ({
  handleChange,
  handleGenerateButtonClick,
  loading,
  prompt,
  samples,
  placeholder,
  entity,
  textAreaRef,
  pastMessages,
  context,
}) => {
  const classes = useStyles();
  const [usedTokens, setUsedTokens] = useState<number>(0);
  const theme = useTheme();
  const getTokenColor = (_usedTokens: number, _theme: Theme): string => {
    const percentageUsed = (_usedTokens / 120000) * 100;
    if (percentageUsed >= 98) {
      return 'red';
    } else if (percentageUsed > 90) {
      return 'orange';
    }
    return _theme.palette.text.secondary;
  };

  const getTokenCost = (_usedTokens: number): string => {
    const cost = (_usedTokens / 1000) * 0.005 + (400 / 1000) * 0.015;
    return `~${cost.toFixed(3)}`;
  };
  useEffect(() => {
    const calculateTokens = () => {
      const totalPrompt = `${context} ${JSON.stringify(
        { pastMessages },
        null,
        2,
      )} ${prompt}`;
      const tokens = Math.ceil((totalPrompt.length + 840) / 4);
      setUsedTokens(tokens);
    };
    calculateTokens();
  }, [prompt, pastMessages, context]);

  useEffect(() => {
    if (!loading) {
      textAreaRef.current?.focus();
    }
  }, [textAreaRef, loading]);

  return (
    <div className={classes.formContainer}>
      <div className={classes.contextContainer}>
        {entity ? (
          <div className={classes.contextText}>
            <span>The Baseplate Assistant is using</span>
            <EntityLink entity={entity} variant="inline-link" />
            <span>to answer your questions.</span>
          </div>
        ) : (
          <div className={classes.contextText}>
            <Typography variant="body1">
              NOT YET IMPLEMENTED ! The Baseplate Assistant is using the
              software catalog(Products, Applications, Tools, APIs etc)
            </Typography>
          </div>
        )}
      </div>
      <div className={classes.inputContainer}>
        <TextareaAutosize
          ref={textAreaRef}
          id="chat-input"
          placeholder={placeholder}
          className={classes.input}
          onChange={handleChange}
          value={prompt}
          onKeyUp={event => {
            if (
              event.key === 'Enter' &&
              !event.shiftKey &&
              prompt.trim().length > 0
            ) {
              void handleGenerateButtonClick();
            }
          }}
        />
        <Paper
          className={classes.buttonContainer}
          style={{ background: loading ? '#989898' : '#2A2B37' }}
        >
          <BaseplateButton
            aria-label="send"
            onClick={() => handleGenerateButtonClick()}
            disabled={loading}
          >
            <>
              <SparklesIcon />
              <Typography className={classes.buttonText} align="center">
                Ask
              </Typography>
            </>
          </BaseplateButton>
        </Paper>
        <div className={classes.tokenInfo}>
          <div style={{ textAlign: 'center' }}>
            <Typography
              variant="body2"
              style={{ color: getTokenColor(usedTokens, theme) }}
            >
              {usedTokens} tokens will be used (
              {((usedTokens / 120000) * 100).toFixed(2)}% of query limit,
              {getTokenCost(usedTokens)} $/€)
            </Typography>
          </div>
        </div>
      </div>
      {samples && <div className={classes.sampleContainer}>{samples}</div>}
      <div className={classes.guidelineText}>
        <Typography variant="body2">
          Responses are generated by Generative AI, so please verify accuracy
          and don't forget the{' '}
          <a
            href="https://legogroup.sharepoint.com/sites/DTHub/SitePages/Our-Guidelines-for-using-Generative-AI.aspx?csf=1&web=1&e=f7xbR4&CID=e57bf7a3-4454-4020-98f6-50ad2da017b6"
            target="_blank"
            rel="noopener noreferrer"
          >
            LEGO Group’s guidelines
          </a>
          .
        </Typography>
      </div>
    </div>
  );
};
