import { baseplateStyles } from '../../utils';

export const useSwitchStyles = baseplateStyles(theme => ({
  root: {
    '& > .v5-MuiFormControlLabel-root': {
      marginLeft: 0,
      padding: '0.65rem 0',
      '& .v5-MuiSwitch-root': {
        width: 42,
        height: 26,
        padding: 0,
        borderRadius: theme.primitive.borderRadius.default,
        '& .v5-MuiSwitch-switchBase': {
          padding: 0,
          margin: 2,
          transitionDuration: '300ms',
          backgroundColor:
            theme.palette.type === 'light'
              ? theme.semantic.background.neutral[1]
              : theme.primitive.color.grey[100],
          height: 22,
          width: 22,
          '&.Mui-checked': {
            transform: 'translateX(16px)',
            backgroundColor:
              theme.palette.type === 'light'
                ? theme.semantic.background.neutral[1]
                : theme.primitive.color.grey[100],
            height: 22,
            width: 22,
            margin: 2,
            '& + .v5-MuiSwitch-track': {
              backgroundColor:
                theme.component.button.primary.background.default,
              opacity: 1,
              border: 0,
            },
            '&.v5-MuiSwitch-thumb': {
              backgroundColor: theme.primitive.color.grey[0],
            },
            '&.Mui-disabled + .v5-MuiSwitch-track': {
              opacity: 0.3,
            },
          },
          '&.Mui-disabled + .v5-MuiSwitch-track': {
            opacity: 0.4,
          },
        },
        '& .v5-MuiSwitch-thumb': {
          backgroundColor: theme.primitive.color.grey[0],
          height: 22,
          width: 22,
        },
        '& .v5-MuiSwitch-track': {
          borderRadius: 26 / 2,
          backgroundColor: theme.semantic.border.default,
          opacity: 1,
          transition: theme.transitions.create(['background-color'], {
            duration: 500,
          }),
        },
      },
      '& .v5-MuiTypography-root': {
        marginLeft: 10,
      },
    },
  },
  success: {
    '& > .v5-MuiFormControlLabel-root > .v5-MuiSwitch-root > .v5-MuiSwitch-switchBase':
      {
        '&.Mui-checked + .v5-MuiSwitch-track': {
          backgroundColor: theme.semantic.shape.success,
        },
      },
  },
  disabledOption: {
    color: theme.semantic.text.tertiary,
  },
  descriptiveTextContainer: {
    padding: '0 1rem',
  },
  helperText: {
    color: theme.semantic.text.secondary,
  },
  errorText: {
    color: theme.semantic.text.error,
  },
  successText: {
    color: theme.semantic.text.success,
  },
}));
