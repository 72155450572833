import { baseplateStyles } from '../../utils';

export const useTableStyles = baseplateStyles(theme => ({
  container: {
    border: `1px solid ${theme.semantic.border.default}`,
    borderRadius: theme.primitive.borderRadius.default,
    fontFamily: theme.typography.fontFamily,
    background: theme.semantic.background.neutral[1],
    overflow: 'hidden',

    '& .v5-MuiTable-root': {
      position: 'relative',
    },
    '& .v5-MuiTableRow-head': {
      '& span': {
        color: theme.semantic.text.primary,
      },
      '& .v5-MuiTableCell-root': {
        backgroundColor: theme.semantic.background.neutral[2],
        borderBottom: `1px solid ${theme.semantic.border.default}`,
      },
      '& .v5-MuiTableSortLabel-root:hover': {
        color: theme.semantic.text.secondary,
      },
    },
    '& .v5-MuiTableRow-root': {
      backgrounds: `red`,
      '& + .v5-MuiTableRow-root > .v5-MuiTableCell-root': {
        borderTop: `1px solid ${theme.semantic.border.default}`,
        borderBottom: 0,
      },
    },
    '& .v5-MuiTableCell-root': {
      fontFamily: theme.typography.fontFamily,
      fontSize: '1rem',
      border: 0,
      borderColor: theme.semantic.border.default,
      color: theme.semantic.text.primary,

      '& .Mui-disabled': {
        color: theme.semantic.icon.secondary,
        opacity: 0.5,
      },
    },
    '& :not($activeRow, $tableSummary).v5-MuiTableRow-root:hover': {
      backgroundColor: theme.semantic.background.neutral[2],
    },
    '& .v5-MuiCheckbox-root svg': {
      fill: theme.semantic.icon.primary,
    },
    '& .v5-MuiSvgIcon-root': {
      // fill: theme.semantic.icon.primary,
    },
    '&$stickyHeader': {
      '& .v5-MuiTableContainer-root': {
        // Screen height - nav height - padding (24px) - table header height - table summary height
        maxHeight: `calc(90vh - ${theme.primitive.dimensions.nav.height} - 1rem - 9.375rem - 2.5rem)`,
      },
    },
  },
  activeRow: {
    backgroundColor: theme.semantic.background.info,
  },
  tableHeader: {
    padding: '1rem',
    borderBottom: `1px solid ${theme.semantic.border.default}`,
  },
  tableHeaderRow: {
    '& th': {
      background: theme.semantic.background.neutral[2],
      zIndex: 2,
    },
  },
  tableSummary: {
    // padding: '1rem',
    borderTop: `1px solid ${theme.semantic.border.default}`,
    background: theme.semantic.background.neutral[1],
    width: '100%',

    '& td': {
      paddingTop: '0.5rem',
      paddingBottom: '0.5rem',
    },
    '&$selected': {
      backgroundColor: theme.semantic.background.info,
    },
  },
  tableActions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    flexWrap: 'wrap',
    gap: '1rem',
    marginTop: '1rem',
  },
  tableFilters: {
    display: 'flex',
    alignItems: 'flex-end',
    gap: '1rem',
    // The individual dropdown elements
    '& > div': {
      minWidth: '150px',
    },
  },
  tableActionButtons: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
  },
  stickyHeader: {},
  selected: {},
  emptyStateContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%', // Adjust based on your table's height
    padding: '1rem',
    textAlign: 'center',
  },
}));
