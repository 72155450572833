import { baseplateStyles } from '../../utils';

export const useCodeBlockStyles = baseplateStyles(theme => ({
  wrapper: {
    borderRadius: theme.primitive.borderRadius.default,
    backgroundColor: theme.semantic.background.code,
    // border: `1px solid ${theme.semantic.border.default}`,
    overflow: 'hidden',
    width: '100%',
  },
  title: {
    backgroundColor: theme.semantic.background.neutral[3],
    padding: '0.5rem 1rem',
  },
  content: {
    padding: '1rem',
    overflowX: 'auto',
    overflowY: 'auto',
    fontFamily: theme.primitive.font.family.mono,
    '& > pre': {
      margin: 0,
      padding: `0 !important`,
    },
  },
}));
