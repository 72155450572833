import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function BirthdayCake(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M11.375 4.375C11.5 4.187 11.75 4 12 4s.438.188.563.375L14.124 7c.25.438.375.875.375 1.375V8.5c0 1.438-1.125 2.5-2.5 2.5-1.438 0-2.5-1.063-2.5-2.5v-.125c0-.5.125-.938.313-1.375l1.562-2.625Zm8 0C19.5 4.187 19.75 4 20 4s.438.188.563.375L22.125 7c.25.438.375.875.375 1.375V8.5c0 1.438-1.125 2.5-2.5 2.5-1.438 0-2.5-1.063-2.5-2.5v-.125c0-.5.125-.938.313-1.375l1.562-2.625ZM25.813 7l1.562-2.625C27.5 4.187 27.75 4 28 4s.438.188.563.375L30.125 7c.25.438.375.875.375 1.375V8.5c0 1.438-1.125 2.5-2.5 2.5-1.438 0-2.5-1.063-2.5-2.5v-.125c0-.5.125-.938.313-1.375ZM13.5 14.5V18h5v-3.5c0-.813.625-1.5 1.5-1.5.813 0 1.5.688 1.5 1.5V18h5v-3.5c0-.813.625-1.5 1.5-1.5.813 0 1.5.688 1.5 1.5V18h.5c2.188 0 4 1.813 4 4v12c0 1.125-.938 2-2 2H8c-1.125 0-2-.875-2-2V22c0-2.188 1.75-4 4-4h.5v-3.5c0-.813.625-1.5 1.5-1.5.813 0 1.5.688 1.5 1.5ZM10 21h-.063c-.5 0-1 .5-1 1v3.813c.376-.125.688-.313 1-.5.188-.125.376-.313.563-.5.438-.375.875-.813 1.5-.813.563 0 1 .438 1.438.813.187.187.374.375.562.5.563.375 1.313.687 2 .687.688 0 1.375-.313 1.938-.688.187-.125.375-.312.562-.5.438-.375.875-.812 1.438-.812.625 0 1.062.438 1.5.813.187.187.375.375.562.5.563.375 1.313.687 2 .687.688 0 1.375-.313 1.938-.688.187-.125.375-.312.562-.5.438-.375.875-.812 1.438-.812.625 0 1.062.438 1.5.813.187.187.375.375.562.5.313.187.625.375 1 .5V22c0-.5-.5-1-1-1H10Zm21 7.938c-1.125-.188-2.125-.75-3-1.375-1.188.875-2.563 1.437-4 1.437-1.5 0-2.875-.563-4-1.438-1.188.875-2.563 1.438-4 1.438-1.5 0-2.875-.563-4-1.438-.938.625-1.938 1.188-3 1.375V33h22v-4.063Z" />
    </SvgIcon>
  );
}
