import { BaseplateTheme } from '../../../../style/types';

export const calloutStyles = (theme: BaseplateTheme) => ({
  '& .admonition': {
    borderRadius: theme.primitive.borderRadius.default,
    boxShadow: 'none',
    backgroundColor: 'var(--callout-background-color)',
    borderWidth: 1,
    borderColor: 'var(--callout-border-color) !important',
    color: 'var(--callout-text-color)',
    fontSize: '1rem',

    '& .admonition-title:before': {
      backgroundColor: 'var(--callout-text-color)',
      left: 0,
    },
  },

  '& .admonition-title': {
    background: 'none !important',
    fontSize: '1rem',
    fontWeight: 'bold',
    paddingLeft: `calc(20px + 0.5rem) !important`,
    border: 0,
    margin: 0,
  },

  // Body text
  '& p+p': {
    marginTop: 0,
  },

  '& .admonition.info, .admonition.note, .admonition.abstract, .admonition.tip':
    {
      '--callout-background-color': theme.semantic.background.info,
      '--callout-border-color': theme.semantic.border.info,
      '--callout-text-color': theme.semantic.text.info,
    },

  '& .admonition.success': {
    '--callout-background-color': theme.semantic.background.success,
    '--callout-border-color': theme.semantic.border.success,
    '--callout-text-color': theme.semantic.text.success,
  },

  '& .admonition.error, .admonition.failure, .admonition.danger, .admonition.bug':
    {
      '--callout-background-color': theme.semantic.background.error,
      '--callout-border-color': theme.semantic.border.error,
      '--callout-text-color': theme.semantic.text.error,
    },

  '& .admonition.warning': {
    '--callout-background-color': theme.semantic.background.warning,
    '--callout-border-color': theme.semantic.border.warning,
    '--callout-text-color': theme.semantic.text.warning,
  },

  '& .admonition.example, .admonition.question, .admonition.quote': {
    '--callout-background-color': theme.semantic.background.neutral[2],
    '--callout-border-color': theme.semantic.border.default,
    '--callout-text-color': theme.semantic.text.primary,
  },
});
