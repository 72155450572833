import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ArrowDown(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="m18.906 33.563-10.5-11c-.562-.625-.562-1.563.063-2.125.625-.563 1.562-.563 2.125.062l7.937 8.313V7.5c0-.813.625-1.5 1.5-1.5.813 0 1.5.688 1.5 1.5v21.313l7.875-8.313c.563-.625 1.563-.625 2.125-.063.625.563.625 1.5.063 2.125l-10.5 11A1.464 1.464 0 0 1 20.03 34c-.437 0-.812-.125-1.125-.438Z" />
    </SvgIcon>
  );
}
