import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ConveyorBeltArm(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M9.5 4h11c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5h-4.313l-.312 1.688c.375.312.688.75.875 1.187l12.438 1.688c.812.062 1.375.812 1.25 1.687 0 .375-.188.688-.438.875V16c0 .563-.5 1-1 1-.563 0-1-.438-1-1v-1.563l-11.625-1.562A3.09 3.09 0 0 1 14 14c-1.688 0-3-1.313-3-3 0-1.25.813-2.375 1.938-2.813L13.124 7H9.5C8.625 7 8 6.375 8 5.5 8 4.687 8.625 4 9.5 4Zm5.5 7c0-.5-.5-1-1-1-.563 0-1 .5-1 1 0 .563.438 1 1 1 .5 0 1-.438 1-1Zm11 9.5c0-.813.625-1.5 1.5-1.5h3c.813 0 1.5.688 1.5 1.5V22h1c3.813 0 7 3.188 7 7 0 3.875-3.188 7-7 7H7c-3.875 0-7-3.125-7-7 0-3.5 2.563-6.438 6-6.875V20.5c0-.813.625-1.5 1.5-1.5h3c.813 0 1.5.688 1.5 1.5V22h4v-1.5c0-.813.625-1.5 1.5-1.5h3c.813 0 1.5.688 1.5 1.5V22h4v-1.5ZM7 25c-2.25 0-4 1.813-4 4 0 2.25 1.75 4 4 4h26c2.188 0 4-1.75 4-4 0-2.188-1.813-4-4-4H7Zm1 2c1.063 0 2 .938 2 2 0 1.125-.938 2-2 2-1.125 0-2-.875-2-2 0-1.063.875-2 2-2Zm10 2c0-1.063.875-2 2-2 1.063 0 2 .938 2 2 0 1.125-.938 2-2 2-1.125 0-2-.875-2-2Zm14-2c1.063 0 2 .938 2 2 0 1.125-.938 2-2 2-1.125 0-2-.875-2-2 0-1.063.875-2 2-2Z" />
    </SvgIcon>
  );
}
