import { useTemplateSecrets } from '@backstage/plugin-scaffolder-react';
import useAsync from 'react-use/esm/useAsync';
import { useEffect } from 'react';
import { useAzureTokenProvider } from '@lego/plugin-baseplate-auth';

import { AzureTokenExtensionProps } from './schema';

export const AzureTokenExtension = ({
  onChange,
  required,
  formData,
  schema: { title, description },
  formContext,
  uiSchema,
}: AzureTokenExtensionProps) => {
  const scope = uiSchema['ui:options']?.scope as string;
  const { setSecrets } = useTemplateSecrets();
  const { getAccessToken } = useAzureTokenProvider();

  useAsync(async () => {
    const token = await getAccessToken(scope);
    setSecrets({ AZURE_TOKEN: token });
  }, []);

  return null;
};
