import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Bold(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M8.5 7.5c0-.813.625-1.5 1.5-1.5h11.75C26 6 29.5 9.5 29.5 13.75a7.735 7.735 0 0 1-2.375 5.563c2.563 1.25 4.375 3.937 4.375 6.937 0 4.313-3.5 7.75-7.75 7.75H10c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5h1.5V9H10c-.875 0-1.5-.625-1.5-1.5Zm13.25 11a4.748 4.748 0 0 0 4.75-4.75A4.748 4.748 0 0 0 21.75 9H14.5v9.5h7.25Zm-7.25 3V31h9.25a4.748 4.748 0 0 0 4.75-4.75 4.748 4.748 0 0 0-4.75-4.75H14.5Z" />
    </SvgIcon>
  );
}
