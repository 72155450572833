import { DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';

const pluginBaseUrl = 'mssql';

export class ApiUtils {
  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi: FetchApi;

  public constructor(discoveryApi: DiscoveryApi, fetchApi: FetchApi) {
    this.discoveryApi = discoveryApi;
    this.fetchApi = fetchApi;
  }

  public async get<T>(path: string): Promise<T> {
    const baseUrl = `${await this.discoveryApi.getBaseUrl(pluginBaseUrl)}/`;
    const url = `${baseUrl}${path}`;

    const response = await this.fetchApi.fetch(url.toString());

    if (!response.ok) {
      throw await response.json();
    }

    return response.json() as Promise<T>;
  }

  public async post<T>(path: string, requestBody?: any): Promise<T> {
    const baseUrl = `${await this.discoveryApi.getBaseUrl(pluginBaseUrl)}/`;
    const url = `${baseUrl}${path}`;

    const response = await this.fetchApi.fetch(url.toString(), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });
    if (!response.ok) {
      throw await response.json();
    }

    return (this.hasBody(response) ? response.json() : response) as Promise<T>;
  }

  public async put<T>(path: string, requestBody?: any): Promise<T> {
    const baseUrl = `${await this.discoveryApi.getBaseUrl(pluginBaseUrl)}/`;
    const url = `${baseUrl}${path}`;

    const response = await this.fetchApi.fetch(url.toString(), {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(requestBody),
    });
    if (!response.ok) {
      throw await response.json();
    }

    return (this.hasBody(response) ? response.json() : response) as Promise<T>;
  }

  public async patch<T>(path: string, requestBody?: any): Promise<T> {
    const baseUrl = `${await this.discoveryApi.getBaseUrl(pluginBaseUrl)}/`;
    const url = `${baseUrl}${path}`;

    const response = await this.fetchApi.fetch(url.toString(), {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });
    if (!response.ok) {
      throw await response.json();
    }

    return (this.hasBody(response) ? response.json() : response) as Promise<T>;
  }

  public async delete<T>(path: string): Promise<T> {
    const baseUrl = `${await this.discoveryApi.getBaseUrl(pluginBaseUrl)}/`;
    const url = `${baseUrl}${path}`;

    const response = await this.fetchApi.fetch(url.toString(), {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      throw await response.json();
    }

    return (this.hasBody(response) ? response.json() : response) as Promise<T>;
  }

  private hasBody(response: Response): boolean {
    const contentLength = response.headers.get('Content-Length');
    return contentLength !== null && contentLength !== '0';
  }
}
