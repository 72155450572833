import { parseEntityRef } from '@backstage/catalog-model';
import { useRouteRef } from '@backstage/core-plugin-api';
import { entityRouteRef } from '@backstage/plugin-catalog-react';
import {
  showToast,
  useUserContext,
} from '@lego/plugin-baseplate-core-components';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const RedirectMyProfile = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const catalogEntityRoute = useRouteRef(entityRouteRef);

  const { backstageIdentity } = useUserContext();

  useEffect(() => {
    if (!backstageIdentity) return;
    const userId = backstageIdentity?.userEntityRef;
    if (!userId) {
      void showToast({
        message:
          'User not found: Please log in or Make sure you are a registered user in the catalog.',
        type: 'warning',
      });
      navigate('https://baseplate.legogroup.io', { replace: true });
    } else {
      const link = catalogEntityRoute(parseEntityRef(userId));
      const segment = pathname.substring(pathname.lastIndexOf('/') + 1);
      navigate(`${link}/${segment}`, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backstageIdentity?.userEntityRef]);
  return null;
};
