import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Cubes(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M20.125 7.035c-.063-.063-.188-.063-.313 0L14.75 8.847l5.25 2 5.188-2-5.063-1.812ZM13 16.972l5.5 1.938v-5.438L13 11.347v5.625Zm8.5 1.938 5.5-1.938v-5.625l-5.5 2.125v5.438ZM30 9.785v7.187l5.688 2.188c1.374.5 2.312 1.812 2.312 3.25v7.437c0 1.438-.875 2.688-2.125 3.25l-6 2.625c-.875.375-1.875.375-2.75.063l-7.125-3-7.188 2.937a3.502 3.502 0 0 1-2.75 0l-6-2.625C2.813 32.535 2 31.285 2 29.847V22.41c0-1.438.875-2.75 2.25-3.25L10 16.972V9.785c0-1.438.875-2.75 2.313-3.25l6.5-2.313a2.97 2.97 0 0 1 2.312 0l6.5 2.313c1.438.5 2.375 1.812 2.375 3.25Zm-11.5 14.25L13 26.097v6.375l5.5-2.312v-6.125ZM5.25 30.347 10 32.41v-6.313l-5-1.875v5.625c0 .25.063.375.25.5ZM27 32.472v-6.375l-5.5-2.062v6.125l5.5 2.312Zm3-.062 4.688-2.063c.187-.125.312-.312.312-.5v-5.625l-5 1.875v6.313ZM11.687 19.66h-.374l-4.938 1.875 5.125 1.937 5.188-2-5-1.812Zm16.626 0-5.063 1.812 5.25 2 5.063-1.937-4.938-1.875h-.313Z" />
    </SvgIcon>
  );
}
