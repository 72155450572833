import { Entity } from '@backstage/catalog-model';

export const mapSearchResultToEntity = (result: any): Entity => {
  const resultEntity: Entity = {
    apiVersion: 'scaffolder.legogroup.io/v1alpha1',
    kind: result.document.kind,
    metadata: {
      name: result.document.name,
      title: result.document.title,
      description: result.document.text,
      namespace: result.document.namespace,
      labels: {
        lifecycle: result.document.lifecycle,
      },
    },
    spec: {
      type: result.document.type,
      lifecycle: result.document.lifecycle,
      profile: {
        picture: result.document.picture,
      },
      environment: result.document.environment,
      position: result.document.position,
    },
    relations: result.document.members?.map((_: any) => ({
      type: 'hasMember',
    })),
  };

  return resultEntity;
};
