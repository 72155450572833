import { Typography } from '@material-ui/core';
import React from 'react';
import { baseplateStyles } from '../../utils';

type Props = {
  values: { label: string; value: unknown }[];
};

const useStyles = baseplateStyles(theme => ({
  table: {
    border: `1px solid ${theme.semantic.border.default}`,
    borderRadius: theme.primitive.borderRadius.default,
    overflow: 'hidden',
  },
  row: {
    display: 'grid',
    gridTemplateColumns: '200px 1fr',
    '&+&': {
      borderTop: `1px solid ${theme.semantic.border.default}`,
    },
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    padding: '1rem',
    borderRight: `1px solid ${theme.semantic.border.default}`,
    backgroundColor: theme.semantic.background.neutral[2],
  },
  value: {
    display: 'flex',
    alignItems: 'center',
    padding: '1rem',
  },
}));

export const SummaryTable = ({ values }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.table}>
      {values.map(value => (
        <div className={classes.row}>
          <Typography className={classes.label} variant="overline">
            {value.label}
          </Typography>
          <Typography className={classes.value} variant="body1">
            {String(value.value)}
          </Typography>
        </div>
      ))}
    </div>
  );
};
