import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Family(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M15.684 7.977c0 1.437-.813 2.75-2 3.5-1.25.687-2.813.687-4 0-1.25-.75-2-2.063-2-3.5 0-1.375.75-2.688 2-3.438 1.187-.687 2.75-.687 4 0 1.187.75 2 2.063 2 3.438Zm-5.438 9c-1 0-1.875.812-2 1.875l-.25 3.062c-.062.563.438 1.063 1 1.063h3.938a7.313 7.313 0 0 0-.25 2v1h-2v8.5c0 .875-.688 1.5-1.5 1.5-.875 0-1.5-.625-1.5-1.5v-8.688c-1.688-.625-2.813-2.25-2.688-4.125l.25-3.062c.25-2.625 2.438-4.625 5-4.625h2.813c.562 0 1.125.125 1.625.312v.688c0 .75.125 1.437.375 2h-4.813Zm14.438-2c0-.125-.063-.25-.063-.438.625-.312 1.313-.562 2.063-.562h1.937a4.013 4.013 0 0 1 3.875 2.937l2.438 8.563c.375 1.25-.625 2.5-1.938 2.5h-1.312v6.5c0 .875-.688 1.5-1.5 1.5-.875 0-1.5-.625-1.5-1.5v-6.5h-2.375c.25-.625.375-1.25.375-2v-1h5l-2.063-7.25a1.07 1.07 0 0 0-1-.75h-4.375c.25-.563.438-1.25.438-2Zm7-7c0 1.437-.813 2.75-2 3.5-1.25.687-2.813.687-4 0-1.25-.75-2-2.063-2-3.5 0-1.375.75-2.688 2-3.438 1.187-.687 2.75-.687 4 0 1.187.75 2 2.063 2 3.438Zm-12 10c-1.125 0-2.063-.563-2.625-1.5-.563-.875-.563-2.063 0-3 .562-.875 1.5-1.5 2.625-1.5 1.062 0 2 .625 2.562 1.5.563.937.563 2.125 0 3-.562.937-1.5 1.5-2.562 1.5Zm2 7c0-1.063-.938-2-2-2-1.125 0-2 .937-2 2v1c0 .562.437 1 1 1h2c.5 0 1-.438 1-1v-1Zm3 1c0 1.5-.875 2.812-2.063 3.5 0 .187.063.375.063.5v4c0 1.125-.938 2-2 2h-2c-1.125 0-2-.875-2-2v-4.5c-1.188-.688-2-2-2-3.5v-1c0-2.75 2.187-5 5-5 2.75 0 5 2.25 5 5v1Z" />
    </SvgIcon>
  );
}
