import { useAnalytics } from '@backstage/core-plugin-api';

/**
 * Provides a hook for logging custom events with flexible parameters,
 * including key, action, subject, and attributes. Can handle arrays within attributes.
 */

export function useMultiEventLogger() {
  const analytics = useAnalytics();

  /**
   * Logs custom events with specified parameters, including a key, action, subject, and attributes.
   * For attributes that are arrays, separate events are captured for each element.
   *
   * @param {string} key - A unique identifier or category for the event.
   * @param {string} action - The event being tracked by the type of action the event represents. Examples : view | click | filter | search | hover | scroll | track
   * @param {string} subject - The primary subject or target of the event. Examples : Path/url of the page viewed | Url of the link clicked| Value that was filtered by | Text that was searched for.
   * @param {Record<string, any>} attributes - Additional data associated with the event.
   */

  const logMultipleCustomEvents = (
    key: string,
    action: string,
    subject: string,
    trackingAttributes: Record<string, any> | null,
  ) => {
    const eventProps = {
      action,
      subject,
      attributes: Object(),
    };
    if (trackingAttributes !== null) {
      Object.entries(trackingAttributes).forEach(([_key, value]) => {
        if (Array.isArray(value)) {
          // If the attribute is an array, capture separate events for each element
          value.forEach(attribute => {
            eventProps.attributes = {};
            eventProps.attributes[_key] = attribute;
            analytics.captureEvent(action, subject, {
              ...eventProps,
              attributes: { ...eventProps.attributes, key },
            });
          });
        } else {
          // If the attribute is not an array, set the value directly in eventProps.attributes
          analytics.captureEvent(action, subject, {
            ...eventProps,
            attributes: { [_key]: value, key },
          });
        }
      });
    } else {
      analytics.captureEvent(action, subject, {
        ...eventProps,
        attributes: { ...eventProps.attributes, key },
      });
    }
  };

  return {
    logMultipleCustomEvents,
  };
}
