import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function BrickTwoByTwoInstructions(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M28.711 32.222H11.29a.539.539 0 0 1-.385-.163.561.561 0 0 1-.16-.392v-1.111a.56.56 0 0 1 .16-.393.539.539 0 0 1 .385-.163h17.422c.145 0 .283.058.385.163.102.104.16.245.16.393v1.11a.561.561 0 0 1-.16.393.539.539 0 0 1-.385.163Zm.545-6.11V25a.561.561 0 0 0-.16-.393.539.539 0 0 0-.385-.163H11.29a.539.539 0 0 0-.385.163.561.561 0 0 0-.16.393v1.111c0 .148.058.289.16.393a.54.54 0 0 0 .385.163h17.422a.54.54 0 0 0 .385-.163.561.561 0 0 0 .16-.393ZM33.5 3h-27v34h27V3Zm-.433-3c.866 0 1.697.351 2.31.976a3.37 3.37 0 0 1 .957 2.357v33.334a3.37 3.37 0 0 1-.957 2.357 3.234 3.234 0 0 1-2.31.976H6.934a3.234 3.234 0 0 1-2.31-.976 3.368 3.368 0 0 1-.957-2.357V3.333c0-.884.344-1.732.957-2.357A3.234 3.234 0 0 1 6.934 0h26.133Z" />
      <path d="M27.84 10.078V8.333c0-.659-.653-1.11-1.306-1.11h-4.9c-.658 0-1.072.456-1.072 1.11v1.745h-1.106V8.333c0-.65-.44-1.11-1.089-1.11h-4.9c-.649 0-1.282.46-1.282 1.11v1.745h-1.351V21.11h18.303V10.078H27.84Zm-5.537-.99h3.804v.99h-3.804v-.99Zm-8.44 0h3.831v.99h-3.83v-.99Zm13.215 9.8H12.923v-6.666h14.155v6.667Z" />
    </SvgIcon>
  );
}
