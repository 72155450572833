import { useEffect } from 'react';
import { useShadowRootElements } from '@backstage/plugin-techdocs-react';
import { useCodeBlockStyles } from './codeblock.styles';

export const CodeblockAddon = () => {
  const codeBlockClasses = useCodeBlockStyles();

  // This hook can be used to get references to specific elements. If you need
  // access to the whole shadow DOM, use the the underlying useShadowRoot()
  // hook instead.
  const codeblocks = useShadowRootElements(['.highlighttable']);

  useEffect(() => {
    codeblocks.forEach(codeblock => {
      codeblock.classList.add(codeBlockClasses.codeblock);
    });
  }, [codeblocks, codeBlockClasses.codeblock]);

  // Nothing to render directly, so we can just return null.
  return null;
};
