import { makeStyles } from '@material-ui/core';

export const useActionMenuButtonStyles = makeStyles<any>(_theme => ({
  button: {
    width: '100%',
  },
  menu: {
    width: '100%',
    '& > .MuiPaper-root ': {
      width: 'inherit',
    },
  },
  itemLabel: {
    fontFamily: 'Cera Pro !important',
    marginLeft: '-5px',
    color: 'black',
  },
}));
