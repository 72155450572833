import { baseplateStyles } from '@lego/plugin-baseplate-core-components';
import React from 'react';
import { type ReactElement } from 'react';

export interface MetadataContainerProps {
  children: React.ReactNode;
}

const useStyles = baseplateStyles(() => ({
  root: {
    marginTop: '2rem',
    display: 'flex',
    gap: '1.5rem',
  },
}));

export function MetadataContainer({
  children,
}: MetadataContainerProps): ReactElement {
  const classes = useStyles();
  return <div className={classes.root}>{children}</div>;
}
