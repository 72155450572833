import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  fetchApiRef,
} from '@backstage/core-plugin-api';

import { EspClient } from './api/EspClient';
import { espStatusApiRef } from './api/IEspClient';
import { rootRouteRef } from './routes';

export const espStatusPlugin = createPlugin({
  id: 'baseplate-esp-status',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: espStatusApiRef,
      deps: {
        fetchApi: fetchApiRef,
        discoveryApi: discoveryApiRef,
      },
      factory: ({ fetchApi, discoveryApi }) =>
        new EspClient({
          discoveryApi,
          fetchApi,
        }),
    }),
  ],
});

export const EspStatusPage: () => JSX.Element = espStatusPlugin.provide(
  createRoutableExtension({
    name: 'EspStatusPage',
    component: () =>
      import('./components/status/EspStatus').then(m => {
        return m.EspStatus;
      }),
    mountPoint: rootRouteRef,
  }),
);

export const EspMetricsPage: () => JSX.Element = espStatusPlugin.provide(
  createRoutableExtension({
    name: 'EspMetricsPage',
    component: () =>
      import('./components/metrics/EspMetricsComponent').then(m => {
        return m.EspMetricsComponent;
      }),
    mountPoint: rootRouteRef,
  }),
);
