import { baseplateStyles } from '../../utils';

export const useFilterChipStyles = baseplateStyles(theme => ({
  root: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
    background: 'none',
    border: `1px solid ${theme.semantic.border.default}`,
    color: theme.component.tag.neutral.text,
    borderRadius: theme.primitive.borderRadius.default,
    padding: theme.spacing(0.5, 1),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.component.tag.neutral.background,
    },
  },
  selected: {
    backgroundColor: theme.component.tag.neutral.background,
    border: 0,
  },
}));
