import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Hashtag(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M17.75 6.031c.813.188 1.375.938 1.188 1.75L18 12.47h6.938L26 7.219c.188-.813.938-1.375 1.75-1.188.813.188 1.375.938 1.188 1.75L28 12.47h4.5c.813 0 1.5.687 1.5 1.5 0 .875-.688 1.5-1.5 1.5h-5.125l-1.75 9H30.5c.813 0 1.5.687 1.5 1.5 0 .875-.688 1.5-1.5 1.5H25l-1.063 5.312a1.456 1.456 0 0 1-1.75 1.188A1.534 1.534 0 0 1 21 32.219l.938-4.75H15l-1.063 5.312a1.456 1.456 0 0 1-1.75 1.188A1.534 1.534 0 0 1 11 32.219l.938-4.75H7.5c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5h5.063l1.75-9H9.5c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5h5.438L16 7.219c.188-.813.938-1.375 1.75-1.188Zm-.375 9.438-1.75 9h6.938l1.75-9h-6.938Z" />
    </SvgIcon>
  );
}
