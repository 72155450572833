import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function BrickTwoByTwoTop(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M35.575 40H4.53A4.548 4.548 0 0 1 0 35.47V4.53A4.548 4.548 0 0 1 4.53 0h30.94A4.548 4.548 0 0 1 40 4.53v31.045A4.425 4.425 0 0 1 35.575 40ZM4.53 2.634A1.914 1.914 0 0 0 2.634 4.53v30.94a1.914 1.914 0 0 0 1.896 1.896h31.045a1.756 1.756 0 0 0 1.756-1.756V4.53a1.914 1.914 0 0 0-1.897-1.896H4.53Z" />
      <path d="M11.572 17.471a5.636 5.636 0 1 1 0-11.272 5.636 5.636 0 0 1 0 11.272Zm0-8.639a3.003 3.003 0 1 0 3.002 3.003 3.02 3.02 0 0 0-3.002-3.003Zm16.558 8.639a5.636 5.636 0 1 1 0-11.272 5.636 5.636 0 0 1 0 11.272Zm0-8.639a3.003 3.003 0 1 0 3.003 3.003 3.02 3.02 0 0 0-3.003-3.003ZM11.87 33.801a5.637 5.637 0 1 1 0-11.273 5.637 5.637 0 0 1 0 11.273Zm0-8.639a3.003 3.003 0 1 0 0 6.006 3.003 3.003 0 0 0 0-6.006Zm16.558 8.639a5.636 5.636 0 1 1-.035-11.272 5.636 5.636 0 0 1 .035 11.272Zm0-8.639a3.001 3.001 0 1 0 2.985 3.003 3.02 3.02 0 0 0-2.985-3.003Z" />
    </SvgIcon>
  );
}
