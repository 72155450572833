import { createApiRef } from '@backstage/core-plugin-api';
import {
  Banner,
  BannerCreateParams,
  BaseplateVersion,
} from '@lego/plugin-baseplate-admin-common';

export const AdminApiRef = createApiRef<AdminApi>({
  id: 'plugin.admin.service',
});

export interface AdminApi {
  getBanners(): Promise<Banner[]>;
  createBanner(banner: BannerCreateParams): Promise<Banner>;
  getBannerById(id: string): Promise<Banner | undefined>;
  deleteBannerById(id: string): Promise<void>;
  updateBannerById(id: string, banner: BannerCreateParams): Promise<Banner>;
  getCurrentBaseplateVersion(): Promise<BaseplateVersion>;
}
