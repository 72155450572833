import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  configApiRef,
  fetchApiRef,
  discoveryApiRef,
} from '@backstage/core-plugin-api';
import { rootRouteRef } from './routes';
import { GitHubClient, gitHubApiRef } from './github';

export const baseplateTemplatesPlugin = createPlugin({
  id: 'templates',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: gitHubApiRef,
      deps: {
        configApi: configApiRef,
        fetchApi: fetchApiRef,
        discoveryApi: discoveryApiRef,
      },
      factory: ({ discoveryApi, fetchApi }) =>
        new GitHubClient({ discoveryApi, fetchApi }),
    }),
  ],
});

export const BaseplateTemplatesPage = baseplateTemplatesPlugin.provide(
  createRoutableExtension({
    name: 'BaseplateTemplatesPage',
    component: () =>
      import('./components/DocumentationTemplates').then(
        m => m.CreateDocumentationForm,
      ),
    mountPoint: rootRouteRef,
  }),
);
