import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  spinnerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1300,
  },
}));
