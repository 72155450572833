import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import { StepProps } from './types';
import { FormStep, Button } from '@lego/plugin-baseplate-core-components';
import { Box, Container } from '@material-ui/core';
import { baseplateStyles } from '@lego/plugin-baseplate-core-components';
import { StepChooseVendor } from './steps/StepChooseVendor';
import { StepInformation } from './steps/StepInformation';
import { StepBudget } from './steps/StepBudget';
import { StepSummary } from './steps/StepSummary';
import { useSearchParams } from 'react-router-dom';

const useBaseplateStyles = baseplateStyles(theme => ({
  backgroundColor: {
    backgroundColor: theme.semantic.background.neutral[1],
  },
}));

export const CloudForm = () => {
  const classes = useBaseplateStyles();
  const [searchParams, setSearchParams] = useSearchParams();
  const [completedSteps, setCompletedSteps] = useState<string[]>([]);
  const [values, setValues] = useState<StepProps['values']>({
    vendor: 'azure',
    currency: 'DKK',
    name: '',
    environment: '',
    purpose: '',
    monthlyBudget: 0,
    budgetAlertThreshold1: 100,
    budgetAlertThreshold2: 80,
    budgetAlertThreshold3: 60,
    budgetAlertThreshold1Type: 'actual',
    budgetAlertThreshold2Type: 'actual',
    budgetAlertThreshold3Type: 'actual',
  });

  const activeStep = searchParams.get('step') ?? '1';
  const activeStepInt = parseInt(activeStep);
  const totalSteps = 4;

  const changeStep = (step: string) => {
    setSearchParams(prev => {
      prev.set('step', step);
      return prev;
    });
  };

  const updateValue = (key: string, value: string | number) => {
    setValues(values => ({
      ...values,
      [key]: value,
    }));
  };

  const stepProps: StepProps = {
    values,
    updateValue,
    changeStep,
  };

  const handleNext = () => {
    const nextStep = activeStepInt + 1;
    if (nextStep <= totalSteps) {
      changeStep(nextStep.toString());
      setCompletedSteps(prev => [
        ...new Set([...prev, activeStepInt.toString()]),
      ]);
      window.scrollTo(0, 0);
    }
  };

  const handleBack = () => {
    const prevStep = activeStepInt - 1;
    if (prevStep >= 1) {
      changeStep(prevStep.toString());
      window.scrollTo(0, 0);
    }
  };

  return (
    <Grid
      mt={8}
      display="flex"
      flexDirection="column"
      alignItems="center"
      className={classes.backgroundColor}
      sx={{
        minHeight: 'calc(100vh - 64px)',
      }}
    >
      <Box sx={{ width: '100%' }} mb={2}>
        <FormStep.Wrapper>
          <FormStep.Item
            label="Choose vendor"
            stepNumber={1}
            active={activeStepInt === 1}
            completed={completedSteps.includes('1')}
            onClick={() => changeStep('1')}
          />
          <FormStep.Item
            label="Account information"
            stepNumber={2}
            active={activeStepInt === 2}
            completed={completedSteps.includes('2')}
            onClick={() => changeStep('2')}
          />
          <FormStep.Item
            label="Budget"
            stepNumber={3}
            active={activeStepInt === 3}
            completed={completedSteps.includes('3')}
            onClick={() => changeStep('3')}
          />
          <FormStep.Item
            label={'Summary'}
            stepNumber={4}
            active={activeStepInt === 4}
            completed={completedSteps.includes('4')}
            onClick={() => changeStep('4')}
          />
        </FormStep.Wrapper>
      </Box>
      <Container maxWidth="sm" className={classes.backgroundColor}>
        {activeStepInt === 1 && <StepChooseVendor {...stepProps} />}
        {activeStepInt === 2 && <StepInformation {...stepProps} />}
        {activeStepInt === 3 && <StepBudget {...stepProps} />}
        {activeStepInt === 4 && <StepSummary {...stepProps} />}
        <Box
          mt={3}
          mb={3}
          display="flex"
          justifyContent="space-between"
          width="100%"
        >
          {activeStepInt > 1 && (
            <Button variant="secondary" onClick={handleBack}>
              Back
            </Button>
          )}
          {activeStepInt < totalSteps ? (
            <Button variant="primary" onClick={handleNext}>
              Next
            </Button>
          ) : (
            <Button variant="primary">Create Cloud Account</Button>
          )}
        </Box>
      </Container>
    </Grid>
  );
};
