import React, { useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Content, Page } from '@backstage/core-components';
import { SearchBar, SearchResult } from '@backstage/plugin-search-react';
import { SearchPageResultGroup } from './SearchPageResultGroup';
import { useSearch } from '@backstage/plugin-search-react';
import { JsonObject } from '@backstage/types';
import {
  FilterChip,
  baseplateStyles,
  useMultiEventLogger,
} from '@lego/plugin-baseplate-core-components';

const PAGE_MAX_WIDTH = 1000;

const useStyles = baseplateStyles(theme => ({
  page: {
    maxWidth: PAGE_MAX_WIDTH,
    margin: '0 auto',
  },
  searchBarContainer: {
    padding: '2rem 2rem 0',
    backgroundColor: theme.semantic.background.neutral[1],
    width: '100%',
    borderRadius: theme.primitive.borderRadius.default,
    '& > div': {
      position: 'relative',
      display: 'block',
      maxWidth: PAGE_MAX_WIDTH,
      margin: '0 auto',
    },
    '& div + div': {
      transform: 'translateY(30%)',
    },
  },
  filter: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '0.5rem',
    margin: '3rem 0 1rem',
  },
}));

const showEntities = (filters: JsonObject): boolean => {
  return (
    Object.keys(filters).length === 0 ||
    (Array.isArray(filters.kind) &&
      filters.kind.some(element => element !== 'Documentation'))
  );
};

const showDocs = (filters: JsonObject): boolean => {
  return (
    Object.keys(filters).length === 0 ||
    (Array.isArray(filters.kind) && filters.kind.includes('Documentation'))
  );
};

const SearchPage = () => {
  const classes = useStyles();
  const { result, term, filters, setFilters } = useSearch();
  const { logMultipleCustomEvents } = useMultiEventLogger();
  const [filterButtons, setFilterButtons] = React.useState<
    { label: string; count: number }[]
  >([]);
  const sanitizedTerm = term.replace('<', ' ');

  const updateFilter = (kind: string) => {
    // Accepts a string to add to the filter, or no argument to reset the filter
    const newFilters = { ...filters };

    // If the filter already exists, remove it
    if (Array.isArray(newFilters.kind)) {
      const index = newFilters.kind.indexOf(kind);
      if (index > -1) {
        newFilters.kind.splice(index, 1);
      } else {
        newFilters.kind.push(kind);
      }
    } else {
      newFilters.kind = [kind];
    }

    if (!kind || newFilters.kind.length === 0) {
      delete newFilters.kind;
    }

    setFilters(newFilters);
  };

  useEffect(() => {
    logMultipleCustomEvents(
      'search_page_event',
      'search',
      `Search for term: ${term}`,
      {
        searchTerm: term,
      },
    );
  }, [term, logMultipleCustomEvents]);

  useEffect(() => {
    const kinds = [
      'Product',
      'API',
      'Documentation',
      'Tool',
      'User',
      'Application',
    ];
    const kindCounts = kinds.map(kind => {
      if (term === '' || term === undefined || term === null) {
        return { label: kind, count: 0 };
      }
      return {
        label: kind,
        count: result.value?.results?.filter(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore SearchDocument type is not expanded to include `kind`
          res => res.document.kind === kind,
        ).length as number,
      };
    });
    setFilterButtons(kindCounts);
  }, [term, result.value]);

  return (
    <Page themeId="home">
      <Content>
        <div className={classes.searchBarContainer}>
          <div>
            <Typography variant="h4">Search results for "{term}"</Typography>
            {result?.value?.results &&
              `${result?.value?.results?.length} results`}
          </div>
          <SearchBar />
        </div>
        <div className={classes.page}>
          <div className={classes.filter}>
            {filterButtons.map(({ label }) => (
              <FilterChip
                label={label}
                onClick={() => updateFilter(label)}
                selected={
                  Array.isArray(filters.kind) && filters.kind.includes(label)
                }
              />
            ))}
          </div>
          {!!term ? (
            <Grid item>
              {showEntities(filters) && (
                <>
                  <Typography variant="subtitle1">Results</Typography>
                  <SearchResult
                    noResultsComponent={<div>0 results for {term}...</div>}
                    query={{
                      types: ['software-catalog'],
                      term: sanitizedTerm,
                      filters: filters,
                    }}
                  >
                    {({ results }) => {
                      return <SearchPageResultGroup results={results} />;
                    }}
                  </SearchResult>
                </>
              )}
              {showDocs(filters) && (
                <>
                  <Typography variant="subtitle2">
                    Results from documentation
                  </Typography>
                  <SearchResult
                    noResultsComponent={
                      <div>0 documentation results for {term}...</div>
                    }
                    query={{ types: ['techdocs'], term: sanitizedTerm }}
                  >
                    {({ results }) => {
                      return <SearchPageResultGroup results={results} />;
                    }}
                  </SearchResult>
                </>
              )}
            </Grid>
          ) : (
            <Grid item xs={9}>
              <Typography style={{ marginTop: 20 }}>
                Please enter a search term above to see results 🔎
              </Typography>
            </Grid>
          )}
        </div>
      </Content>
    </Page>
  );
};

export const searchPage = <SearchPage />;
