import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Gauge(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M20.031 7c-4.687 0-8.937 2.5-11.312 6.5-2.313 4.063-2.313 9 0 13 2.375 4.063 6.625 6.5 11.312 6.5 4.625 0 8.875-2.438 11.25-6.5 2.313-4 2.313-8.938 0-13-2.375-4-6.625-6.5-11.25-6.5Zm0 29c-5.75 0-11-3-13.875-8-2.875-4.938-2.875-11 0-16 2.875-4.938 8.125-8 13.875-8 5.688 0 10.938 3.063 13.813 8 2.875 5 2.875 11.063 0 16-2.875 5-8.125 8-13.813 8Zm3.5-10c0 1.938-1.625 3.5-3.5 3.5a3.494 3.494 0 0 1-3.5-3.5c0-1.375.813-2.563 2-3.125V11.5c0-.813.625-1.5 1.5-1.5.813 0 1.5.688 1.5 1.5v11.375c1.125.563 2 1.75 2 3.125Zm-9.5-10c-1.125 0-2-.875-2-2 0-1.063.875-2 2-2 1.063 0 2 .938 2 2 0 1.125-.937 2-2 2Zm-1 4c0 1.125-.937 2-2 2-1.125 0-2-.875-2-2 0-1.063.875-2 2-2 1.063 0 2 .938 2 2Zm16 2c-1.125 0-2-.875-2-2 0-1.063.875-2 2-2 1.063 0 2 .938 2 2 0 1.125-.937 2-2 2Zm-1-8c0 1.125-.937 2-2 2-1.125 0-2-.875-2-2 0-1.063.875-2 2-2 1.063 0 2 .938 2 2Z" />
    </SvgIcon>
  );
}
