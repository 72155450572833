import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Percent(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="m31.086 11.031-20.063 20c-.562.625-1.5.625-2.062 0-.625-.562-.625-1.5 0-2.125l20-19.937c.562-.625 1.5-.625 2.125 0 .562.562.562 1.5 0 2.062Zm-16.063.938c0 1.125-.625 2.062-1.5 2.625-.937.562-2.125.562-3 0-.937-.563-1.5-1.5-1.5-2.625 0-1.063.563-2 1.5-2.563.875-.562 2.063-.562 3 0 .875.563 1.5 1.5 1.5 2.563Zm16 16c0 1.125-.625 2.062-1.5 2.625-.937.562-2.125.562-3 0-.937-.563-1.5-1.5-1.5-2.625 0-1.063.563-2 1.5-2.563.875-.562 2.063-.562 3 0 .875.563 1.5 1.5 1.5 2.563Z" />
    </SvgIcon>
  );
}
