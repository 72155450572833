import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function VolumeOff(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M9.938 16.625A1.38 1.38 0 0 1 9 17H3.5c-.313 0-.5.25-.5.5v5c0 .313.188.5.5.5H9c.313 0 .688.188.938.438L17 29.625V10.437l-7.063 6.188ZM18.124 6c1 0 1.875.875 1.875 1.875v24.313c0 1-.875 1.812-1.875 1.812-.5 0-.938-.125-1.25-.438L8.375 26H3.5A3.494 3.494 0 0 1 0 22.5v-5C0 15.625 1.563 14 3.5 14h4.875l8.5-7.5c.313-.313.75-.5 1.25-.5Zm8.438 8.438L30 17.875l3.438-3.438a1.471 1.471 0 0 1 2.062 0c.625.626.625 1.563 0 2.126L32.062 20l3.438 3.438c.625.625.625 1.562 0 2.125a1.37 1.37 0 0 1-2.063 0L30 22.125l-3.438 3.438c-.625.625-1.562.625-2.125 0-.625-.563-.625-1.5 0-2.125L27.875 20l-3.438-3.438c-.625-.562-.625-1.5 0-2.125.563-.562 1.5-.562 2.125 0Z" />
    </SvgIcon>
  );
}
