import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Envelope(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M8 11c-.563 0-1 .5-1 1v1.438l10.75 8.812a3.487 3.487 0 0 0 4.438 0L33 13.437V12c0-.5-.5-1-1-1H8Zm-1 6.313V28c0 .563.438 1 1 1h24c.5 0 1-.438 1-1V17.312l-8.875 7.25c-2.438 2-5.875 2-8.25 0L7 17.313ZM4 12c0-2.188 1.75-4 4-4h24c2.188 0 4 1.813 4 4v16c0 2.25-1.813 4-4 4H8c-2.25 0-4-1.75-4-4V12Z" />
    </SvgIcon>
  );
}
