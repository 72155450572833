import { ApiDetailPage } from '@lego/plugin-baseplate-api-detail';

import React from 'react';
import { type ReactElement } from 'react';
import { OldApiDetailPage } from './OldApiDetailPage';
import { featureFlagsApiRef, useApi } from '@backstage/core-plugin-api';

function ApiPage(): ReactElement {
  const featureFlagsApi = useApi(featureFlagsApiRef);

  if (featureFlagsApi.isActive('api-detail')) {
    return <ApiDetailPage />;
  }
  return <OldApiDetailPage />;
}

export default ApiPage;
