import { Content, PageWithHeader } from '@backstage/core-components';
import { TechDocsPicker } from '@backstage/plugin-techdocs';
import {
  BaseplateOwnerPicker,
  Button,
  FavoriteEntitiesCardsList,
  OverviewPageHeader,
} from '@lego/plugin-baseplate-core-components';
import React from 'react';
import { DocumentationEntitiesList } from './DocumentationEntitiesList';
import AddIcon from '@material-ui/icons/Add';
import { FilterRowLayout } from '@lego/plugin-baseplate-catalog';

export const DocumentationExplorerPage = () => {
  return (
    <PageWithHeader
      pageTitleOverride="Documentation"
      title={
        <>
          <OverviewPageHeader
            title="Documentation"
            actions={[
              <Button
                variant="primary"
                startIcon={<AddIcon />}
                href="/templates"
                key="add_documentation"
              >
                Add documentation
              </Button>,
            ]}
            description="Documentation in the LEGO group"
          />
          <FavoriteEntitiesCardsList isTechdocs />
        </>
      }
      themeId="documentation"
    >
      <Content>
        <TechDocsPicker />
        <FilterRowLayout pickers={[<BaseplateOwnerPicker />]} />
        <DocumentationEntitiesList />
      </Content>
    </PageWithHeader>
  );
};
