import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Mouse(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M20 18h-9v8c0 3.875 3.125 7 7 7h4c3.813 0 7-3.125 7-7v-8h-9Zm12-3v11c0 5.563-4.5 10-10 10h-4A9.95 9.95 0 0 1 8 26V14C8 8.5 12.438 4 18 4h4c5.5 0 10 4.5 10 10v1ZM21.5 7v8H29v-1c0-3.813-3.188-7-7-7h-.5Zm-3 0H18c-3.875 0-7 3.188-7 7v1h7.5V7Z" />
    </SvgIcon>
  );
}
