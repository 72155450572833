import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Filter(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M4 8.625C4 7.187 5.125 6 6.563 6h26.812A2.64 2.64 0 0 1 36 8.625c0 .625-.25 1.188-.625 1.625L25 23.063V32c0 1.125-.938 2-2.063 2a1.91 1.91 0 0 1-1.25-.438L15.938 29A2.425 2.425 0 0 1 15 27.062v-4L4.562 10.25C4.188 9.812 4 9.25 4 8.625ZM7.438 9l10.187 12.563c.25.312.375.625.375.937v4.313L22 30v-7.5c0-.313.063-.625.313-.938L32.563 9H7.436Z" />
    </SvgIcon>
  );
}
