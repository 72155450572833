import React from 'react';
import { primitive } from '../../../design-tokens';

export const ConstructionSvg = () => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ position: 'absolute', top: 0, left: 0 }}
    >
      <g clipPath="url(#a)">
        <mask
          id="b"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="24"
          height="24"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24 0H0v24h24V0ZM8.5 1.5a7 7 0 0 0-7 7v7a7 7 0 0 0 7 7h7a7 7 0 0 0 7-7v-7a7 7 0 0 0-7-7h-7Z"
            fill="#D9D9D9"
          />
        </mask>
        <g mask="url(#b)">
          <path
            fill={primitive.color.grey[1300]}
            d="m5.7-20.712 3 3-26.799 26.8-3-3z"
          />
          <path
            fill={primitive.color.yellow[500]}
            d="m8.701-17.711 3.001 3-26.8 26.8-3-3z"
          />
          <path
            fill={primitive.color.grey[1300]}
            d="m11.702-14.71 3.001 3-26.8 26.8-3-3z"
          />
          <path
            fill={primitive.color.yellow[500]}
            d="m14.703-11.71 3.001 3.002-26.8 26.8-3-3.002z"
          />
          <path
            fill={primitive.color.grey[1300]}
            d="m18.51-9.513 3 3.001-27.604 27.605-3.001-3.001z"
          />
          <path
            fill={primitive.color.yellow[500]}
            d="m20.706-5.707 3.001 3.001-26.8 26.8-3-3.001z"
          />
          <path
            fill={primitive.color.grey[1300]}
            d="M23.707-2.706 26.708.295l-26.8 26.8-3-3.001z"
          />
          <path
            fill={primitive.color.yellow[500]}
            d="m26.708.296 3 3.001-26.799 26.8-3-3.002z"
          />
          <path
            fill={primitive.color.grey[1300]}
            d="m29.709 3.297 3 3-26.799 26.8-3-3zM38.412 6.58l3 3.002-26.799 26.8-3-3.002z"
          />
          <path
            fill={primitive.color.yellow[500]}
            d="m34.54 4.452 3.002 3-26.8 26.8-3-3z"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path
            d="M0 8a8 8 0 0 1 8-8h8a8 8 0 0 1 8 8v8a8 8 0 0 1-8 8H8a8 8 0 0 1-8-8V8Z"
            fill="#fff"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
