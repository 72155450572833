import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Play(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="m11 30.972 18-11-18-11v22ZM9.5 6.41a2.99 2.99 0 0 1 3.063 0l18 11c.875.562 1.437 1.562 1.437 2.562 0 1.063-.563 2.063-1.438 2.563l-18 11a2.857 2.857 0 0 1-3.062.062c-.938-.5-1.5-1.5-1.5-2.625v-22C8 7.91 8.563 6.91 9.5 6.41Z" />
    </SvgIcon>
  );
}
