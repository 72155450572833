import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function HandTap(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.705 18.196A9.976 9.976 0 0 0 27 9.99C27 4.473 22.523 0 17 0S7 4.473 7 9.99c0 4.1 2.473 7.623 6.01 9.163v5.222a4.022 4.022 0 0 0-5.25-.688c-1.813 1.25-2.313 3.75-1.126 5.563l4.25 6.313C12.76 38.374 15.822 40 19.197 40h7.312c4.125 0 7.5-3.313 7.5-7.5V25c0-2.188-1.812-4-4-4-.312 0-.562.063-.812.125-.75-1-1.938-1.625-3.188-1.625-.437 0-.875.125-1.25.25a4.147 4.147 0 0 0-2.054-1.554Zm-9.696-2.464a6.98 6.98 0 0 1-3.006-5.742C10.003 6.13 13.136 3 17 3a6.994 6.994 0 0 1 6.997 6.99 6.98 6.98 0 0 1-2.988 5.73V12c0-2.188-1.812-4-4-4-2.25 0-4 1.813-4 4v3.732Zm2.938 13.518L16.009 12c0-.5.438-1 1-1 .5 0 1 .5 1 1v8.5c0 .688.375 1.25 1 1.438.625.25 1.313 0 1.688-.5.187-.25.437-.375.812-.375.5 0 1 .437 1 1 0 .625.375 1.187 1 1.375.625.25 1.313 0 1.688-.5.187-.25.437-.375.812-.375.438 0 .875.312.938.812.125.5.5.938.937 1.125.5.188 1.063.063 1.5-.25.125-.125.375-.188.625-.188.5 0 1 .438 1 1V32.5c0 2.5-2.062 4.5-4.5 4.5h-7.312a7.008 7.008 0 0 1-5.813-3.063l-4.25-6.374c-.312-.438-.187-1.063.313-1.375.437-.313 1.062-.188 1.375.312l2.437 3.625c.313.5 1 .75 1.625.563.688-.188 1.063-.75 1.063-1.438Z"
      />
    </SvgIcon>
  );
}
