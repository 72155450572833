import React from 'react';
import { Content, Page } from '@backstage/core-components';
import { LangChainChat } from './LangChainChat';

export const LangChainPageComponent = () => {
  return (
    <Page themeId="dashboard">
      <Content>
        <LangChainChat verbose userHint="Ask me anything..." />
      </Content>
    </Page>
  );
};
