import { AutocompleteDropdown } from '@lego/plugin-baseplate-core-components';
import { FormControl, FormHelperText, Typography } from '@material-ui/core';
import React from 'react';
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';
import { isArray, uniq } from 'lodash';

export const AutoCompleteExtension = (
  props: FieldExtensionComponentProps<string[]>,
) => {
  const { formData, onChange, required, schema, uiSchema } = props;
  const uiOptions = uiSchema['ui:options'] as Record<string, any>;
  const helperText = uiOptions?.helperText as string;
  const options = uiOptions?.options as string[] | [];
  const multiple = (uiOptions?.multiple as boolean) ?? false;
  const label = uiOptions.label as string;
  const valueKey = (uiOptions.valueKey as string) ?? 'value';
  const labelKey = (uiOptions.labelKey as string) ?? 'label';

  const setValues = (_: React.ChangeEvent<object>, values: any) => {
    let _values: any;
    if (isArray(values)) {
      _values =
        uniq(values?.map((v: any) => (v[valueKey] as string) ?? v)) || [];
    } else {
      _values = (values?.[valueKey] as string) ?? '';
    }
    onChange(_values);
  };

  return (
    <FormControl margin="normal" required={required}>
      <AutocompleteDropdown
        multiple={multiple}
        filterSelectedOptions
        value={formData || (multiple ? [] : '')}
        id={label}
        options={options}
        onChange={setValues}
        getOptionLabel={option => {
          return option[labelKey] ?? option;
        }}
        label={label ?? schema.title}
      />
      {helperText && (
        <FormHelperText style={{ padding: '0 1rem' }}>
          <Typography variant="body2" color="secondary">
            {helperText}
          </Typography>
        </FormHelperText>
      )}
    </FormControl>
  );
};
