import { OpenAPIV3_1 } from 'openapi-types';
import { UiParameterGroupType } from '../../../types';
import { resolveRef } from './utils/refResolver';

export const resolveParameters = (
  parameters:
    | (OpenAPIV3_1.ParameterObject | OpenAPIV3_1.ReferenceObject)[]
    | undefined,
  components: OpenAPIV3_1.ComponentsObject | undefined,
): UiParameterGroupType => {
  if (!parameters) return {};

  const parameterGroups: UiParameterGroupType = {};

  parameters.forEach(parameter => {
    const resolvedParameter = resolveRef<OpenAPIV3_1.ParameterObject>(
      'parameters',
      parameter,
      components,
    );

    const {
      name,
      in: group,
      description,
      schema,
      required,
    } = resolvedParameter;

    // Create group if none exist. Can be "query", "header", "path" or "cookie"
    // Source: https://swagger.io/specification#path-item-object
    if (!parameterGroups[group]) {
      parameterGroups[group] = [];
    }

    parameterGroups[group].push({
      name,
      description: description,
      type: (schema as OpenAPIV3_1.SchemaObject)?.type as string,
      required,
      example: description,
    });
  });

  return parameterGroups;
};
