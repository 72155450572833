import {
  Button,
  baseplateStyles,
} from '@lego/plugin-baseplate-core-components';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Box from '@mui/material/Box';
import React, { useState } from 'react';
import { useOpenPosition } from '../../../hooks';
import { gopColumns } from '../../EmployeeProductTable/columns';

import NewOpenPositionGeneralOverheadProductTableRow from './NewOpenPositionGeneralOverheadProductTableRow';
import OpenPositionGeneralOverheadProductTableRow from './OpenPositionGeneralOverheadProductTableRow';

const useStyles = baseplateStyles(theme => ({
  tableHeader: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    borderTop: `1px solid ${theme.semantic.border.default}`,
    borderBottom: `1px solid ${theme.semantic.border.default}`,
  },
}));

type Props = {
  positionId: number;
};

function OpenPositionGeneralOverheadProductTable({ positionId }: Props) {
  const { openPositions } = useOpenPosition();
  const [isAdding, setIsAdding] = useState(false);

  const classes = useStyles();

  const openPosition = openPositions.find(e => e.positionId === positionId);

  const handleClick = () => {
    setIsAdding(true);
  };

  if (!openPosition) {
    return null;
  }

  return (
    <Paper style={{ marginTop: 32 }}>
      <Toolbar>
        <Typography variant="h5">General Overhead Products</Typography>
        <Box flexGrow={1} />
        <Button
          onClick={handleClick}
          disabled={isAdding || openPosition.generalOverheadProduct !== null}
          variant="primary"
        >
          <AddIcon />
          Add
        </Button>
      </Toolbar>
      <Table component={Paper}>
        <TableHead>
          <TableRow>
            {gopColumns.map((column, index) => (
              <TableCell key={index} className={classes.tableHeader}>
                {column.title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {openPosition.generalOverheadProduct && (
            <OpenPositionGeneralOverheadProductTableRow
              openPosition={openPosition}
              rowData={openPosition.generalOverheadProduct}
            />
          )}
          {isAdding && (
            <NewOpenPositionGeneralOverheadProductTableRow
              openPosition={openPosition}
              setIsAddingProduct={setIsAdding}
            />
          )}
        </TableBody>
      </Table>
    </Paper>
  );
}

export default OpenPositionGeneralOverheadProductTable;
