import { BackstageOverrides } from '@backstage/core-components';
import { DesignTokens } from '../types';

export const input = (
  tokens: DesignTokens,
): Pick<
  BackstageOverrides,
  | 'MuiTextField'
  | 'MuiInputBase'
  | 'MuiSwitch'
  | 'BackstageSelect'
  | 'MuiFormLabel'
  | 'MuiInputAdornment'
  | 'MuiSelect'
  | 'MuiOutlinedInput'
  | 'MuiInput'
> => ({
  MuiTextField: {
    root: {
      borderRadius: tokens.primitive.borderRadius.default,
    },
  },
  MuiInputBase: {
    root: {
      backgroundColor: tokens.semantic.background.neutral[1],
      borderRadius: tokens.primitive.borderRadius.default,
      color: tokens.semantic.text.tertiary,
      borderColor: tokens.semantic.border.default,
    },
  },
  MuiOutlinedInput: {
    root: {
      borderColor: tokens.semantic.border.default,
    },
    notchedOutline: {
      borderColor: tokens.semantic.border.default,
    },
  },
  MuiInputAdornment: {
    root: {
      color: tokens.semantic.icon.secondary,
    },
  },
  MuiSwitch: {
    colorPrimary: {
      '&$checked': {
        color: tokens.semantic.interactive.default,
      },
      thumb: {
        color: tokens.semantic.interactive.default,
      },
      track: {
        backgroundColor: tokens.semantic.interactive.default,
      },
    },
    track: {
      background: 'red',
      '$checked$checked + &': {
        backgroundColor: tokens.semantic.background.neutral[3],
      },
    },
  },
  BackstageSelect: {
    formControl: {
      maxWidth: '100%',
    },
    root: {
      backgroundColor: 'unset',
    },
  },
  MuiSelect: {
    icon: {
      color: tokens.semantic.icon.secondary,
    },
  },
  MuiFormLabel: {
    root: {
      '&$focused': {
        color: tokens.semantic.text.primary,
      },
    },
  },
  MuiInput: {
    root: {
      padding: '9px',
    },
  },
});
