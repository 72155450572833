// NOTE: These are collections of validators used in endpoints
// It will be `key`: field to validate using regex: `value`: regex pattern
export const regexPatterns = {
  /*
  `skill` regex pattern
  * No more than one consecutive space: The text cannot have more than one space in a row. 
  * No three consecutive special symbols: It doesn't allow three consecutive special symbols (like "&&&" or "###"), but it permits letters, numbers to occur consecutively.
  * No leading or trailing spaces: The text cannot start or end with a space.
  * Allowed characters: The text can contain letters, numbers, spaces, and specific special symbols like "_", "&", "#", "/", "-", "!".
  */
  skill:
    /^(?!.*\s{3,})(?!.*[#_&.+-/!]{3})[a-zA-Z0-9&_#.+-/!]+( ?[a-zA-Z0-9&_#.+-/!]+)*$/,
};
