import { baseplateStyles } from '../../utils';

export const useCardStyles = baseplateStyles(theme => ({
  card: {
    display: 'block',
    color: theme.semantic.text.primary,
    borderRadius: theme.primitive.borderRadius.default,
    overflow: 'hidden',
    backgroundColor: theme.semantic.background.neutral[1],

    '&$clickable': {
      border: `1px solid ${theme.semantic.border.default}`,
      transition: 'border 200ms ease-in-out',

      '&:hover': {
        color: 'inherit',
        border: `1px solid ${theme.semantic.border.hover}`,
        textDecoration: 'none',
      },
    },

    '& div$titleContainer': {
      display: 'flex',
      alignItems: 'center',
      borderBottom: `1px solid ${theme.semantic.border.default}`,
      paddingTop: '0.5rem',
      paddingBottom: '0.5rem',
      '& p': {
        flexGrow: 1,
      },
    },

    '&$xsPadding': {
      '& > $titleContainer': {
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
      '& > $contentContainer': {
        padding: '1rem',
      },
    },
    '&$mdPadding': {
      '& > $titleContainer': {
        paddingLeft: '1.5rem',
        paddingRight: '1.5rem',
      },
      '& > $contentContainer': {
        padding: '1.5rem',
      },
    },
    '&$nonePadding': {
      '& > $titleContainer': {
        paddingLeft: '1rem',
        paddingRight: '1rem',
      },
      '& > $contentContainer': {
        padding: '0',
      },
    },
  },
  tooltip: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.primitive.borderRadius.full,
    cursor: 'help',
    width: 16,
    height: 16,
    backgroundColor: theme.semantic.background.neutral[3],
    color: theme.semantic.text.secondary,
  },
  tooltipBox: {
    backgroundColor: theme.semantic.background.neutral[3],
    color: theme.semantic.text.primary,
    fontWeight: 'normal',
    fontSize: '0.875rem',
  },

  // Instantiating classes (supposed to be empty):
  clickable: {},
  titleContainer: {},
  contentContainer: {},
  nonePadding: {},
  xsPadding: {},
  mdPadding: {},
}));
