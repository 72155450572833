import { BaseplateTheme } from '../../../../style/types';

export const tableStyles = (theme: BaseplateTheme) => ({
  // Set background color and add border + border radius around the table
  '& .md-typeset table:not([class])': {
    backgroundColor: theme.semantic.background.neutral[1],
    borderRadius: theme.primitive.borderRadius.default,
    border: `1px solid ${theme.semantic.border.default}`,
    overflowX: 'scroll',
    overflowY: 'hidden',
    color: theme.semantic.text.primary,

    // Set darker color on header in table
    '& thead tr': {
      backgroundColor: theme.semantic.background.neutral[2],
      borderBottom: `1px solid ${theme.semantic.border.default}`,
      '& th': {
        borderBottom: 0,
      },
    },
    // Reset table cell borders
    '& td': {
      border: 0,
    },
    // Add border to top of every row, except the first
    '& tr + tr': {
      border: 0,
      borderTop: `1px solid ${theme.semantic.border.default}`,
    },
    // Add border to the left on every cell, except the first
    '& td+td, & th+th': {
      borderLeft: `1px solid ${theme.semantic.border.default}`,
    },
    // Redefine padding in cells, both header and normal cells
    '& td, & th': {
      padding: '0.5rem 1rem',
    },
  },
});
