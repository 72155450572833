import { createApiRef } from '@backstage/core-plugin-api';
import { GithubPR } from '../../../templates-common/src';

export interface IGitHubApi {
  createPullRequest(
    prData: GithubPR,
    // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  ): Promise<any>;
}

export const gitHubApiRef = createApiRef<IGitHubApi>({
  id: 'baseplate.templates.github',
});
