import { TaskStep } from '@backstage/plugin-scaffolder-common';
import { Typography } from '@material-ui/core';
import { useMountEffect } from '@react-hookz/web';
import humanizeDuration from 'humanize-duration';
import { DateTime, Interval } from 'luxon';
import { default as React, useCallback, useEffect, useState } from 'react';
import useInterval from 'react-use/esm/useInterval';

import {
  ScaffolderStep,
  ScaffolderTaskStatus,
} from '@backstage/plugin-scaffolder-react';
import { Icon, Spinner } from '@lego/plugin-baseplate-core-components';
import { LogMessage } from './LogMessage';
import { useTemplateStepStyles } from './TemplateStep.styles';
import { getStatusText } from '../../utils';

export interface WorkflowStepProps {
  step: TaskStep & ScaffolderStep;
  logs: string[];
}

export function TemplateStep({ step, logs }: WorkflowStepProps) {
  const [time, setTime] = useState('');

  const getDelay = () => {
    if (step.startedAt && step.endedAt && time) {
      return null;
    }
    if (step.startedAt && step.endedAt) {
      return 1;
    }
    return 1000;
  };

  const calculate = useCallback(() => {
    if (!step.startedAt) {
      setTime('');
      return;
    }

    const end = step.endedAt
      ? DateTime.fromISO(step.endedAt)
      : DateTime.local();

    const startedAt = DateTime.fromISO(step.startedAt);
    const formatted = Interval.fromDateTimes(startedAt, end)
      .toDuration()
      .valueOf();

    setTime(humanizeDuration(formatted, { round: true }));
  }, [step.endedAt, step.startedAt]);

  useMountEffect(calculate);
  useInterval(calculate, getDelay());

  const [open, setOpen] = useState(false);
  const classes = useTemplateStepStyles();

  useEffect(() => {
    if (step.status === 'processing') {
      setOpen(true);
    } else if (step.status === 'completed') {
      setOpen(false);
    }
  }, [step.status]);

  function getStatusIcon(stepStatus: ScaffolderTaskStatus) {
    if (stepStatus === 'completed') {
      return (
        <div className={[classes.circle, classes.completed].join(' ')}>
          <Icon icon="control-check" size={16} />
        </div>
      );
    } else if (stepStatus === 'failed') {
      return (
        <div className={[classes.circle, classes.failed].join(' ')}>
          <Icon icon="control-close" size={16} />
        </div>
      );
    } else if (stepStatus === 'processing') {
      return <Spinner type="circle" />;
    }
    return <div className={classes.circle} />;
  }

  return (
    <div className={classes.workflowStepContainer}>
      <button className={classes.titleContainer} onClick={() => setOpen(!open)}>
        {getStatusIcon(step.status)}
        <div className={classes.title}>
          <Typography variant="body1">{step.name}</Typography>
          <Typography variant="body2" color="secondary">
            {getStatusText(step.status)} – {time}
          </Typography>
        </div>
        <div className={[classes.chevron, open ? classes.open : ''].join(' ')}>
          <Icon icon="chevron-down" />
        </div>
      </button>
      {open && (
        <div className={classes.logsContainer}>
          {(logs || ['No logs...']).map(message => (
            <LogMessage>{message}</LogMessage>
          ))}
        </div>
      )}
    </div>
  );
}
