import React from 'react';
import { makeStyles } from '@material-ui/core';

interface Props {
  children: React.ReactNode;
}

const useStyles = makeStyles(_ => ({
  grid: {
    display: 'grid',
    gridTemplateColumns: `repeat(auto-fill, minmax(300px, 1fr))`,
    gap: '1rem',
  },
}));

export const OverviewPageCardGrid = ({ children }: Props) => {
  const classes = useStyles();
  return <div className={classes.grid}>{children}</div>;
};
