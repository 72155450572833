import { UiEndpointType } from '../../types';

/**
 * Used for React map functions
 */
export const getGroupedEndpoints = (endpoints: UiEndpointType[]) => {
  // Find the array of unique tags
  const groups: string[] = [
    ...new Set(endpoints.flatMap(({ tags }) => tags ?? [])),
  ].filter(group => group);

  // Loop through the unique tags and attach the endpoints with that tag
  const groupedEndpoints = groups.map(group => ({
    name: group,
    endpoints: endpoints.filter(({ tags }) => tags?.includes(group)),
  }));

  if (endpoints.some(({ tags }) => !tags)) {
    groupedEndpoints.push({
      name: 'Endpoints',
      endpoints: endpoints.filter(({ tags }) => !tags),
    });
  }

  return groupedEndpoints;
};
