import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function UsersLightbulbOn(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="m11.313 2.25-3-2C7.625-.188 6.687 0 6.25.687c-.5.688-.313 1.625.375 2.063l3 2c.688.5 1.625.313 2.125-.375.438-.688.25-1.625-.438-2.125Zm19 2.5 3-2c.687-.438.874-1.375.437-2.063-.5-.687-1.438-.875-2.125-.437l-3 2c-.688.5-.875 1.438-.375 2.125a1.435 1.435 0 0 0 2.063.375ZM9.5 10h-4c-.875 0-1.5.688-1.5 1.5 0 .875.625 1.5 1.5 1.5h4c.813 0 1.5-.625 1.5-1.5 0-.813-.688-1.5-1.5-1.5Zm25 0h-4c-.875 0-1.5.688-1.5 1.5 0 .875.625 1.5 1.5 1.5h4c.813 0 1.5-.625 1.5-1.5 0-.813-.688-1.5-1.5-1.5Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.568 12.16c.682.92 1.364 1.874 1.705 2.93h5.454c.307-1.056.989-2.01 1.67-2.93l.512-.717C25.591 10.454 26 9.295 26 8.034 26 4.694 23.307 2 20 2c-3.34 0-6 2.693-6 6 0 1.295.375 2.454 1.057 3.443l.511.716ZM20 5.272A2.714 2.714 0 0 0 17.273 8a.56.56 0 0 1-.546.545.539.539 0 0 1-.545-.545c0-2.08 1.704-3.818 3.818-3.818.273 0 .546.273.546.545a.56.56 0 0 1-.546.546Z"
      />
      <path d="M17.273 16.727A2.692 2.692 0 0 0 20 19.454c1.5 0 2.727-1.193 2.727-2.727v-.545h-5.454v.545Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.162 23.5c-.883-1.51-.883-3.438 0-5 .935-1.51 2.546-2.5 4.364-2.5 1.766 0 3.377.99 4.312 2.5.883 1.563.883 3.49 0 5-.935 1.563-2.546 2.5-4.312 2.5-1.818 0-3.429-.938-4.364-2.5Zm2.583-1.527C8.154 22.647 8.797 23 9.526 23c.66 0 1.311-.338 1.729-1.027.312-.544.341-1.3-.001-1.946C10.805 19.338 10.13 19 9.526 19c-.67 0-1.34.353-1.78 1.027-.342.647-.313 1.402-.001 1.946ZM8.313 27h2.312A8.375 8.375 0 0 1 19 35.375c0 .938-.75 1.625-1.688 1.625H1.626C.687 37 0 36.25 0 35.375 0 30.75 3.688 27 8.313 27Zm0 3c-2.5 0-4.563 1.75-5.188 4h12.688c-.626-2.25-2.688-4-5.188-4H8.312Zm17.849-11.5c-.883 1.563-.883 3.49 0 5 .935 1.563 2.546 2.5 4.364 2.5 1.766 0 3.377-.938 4.312-2.5.883-1.51.883-3.438 0-5-.935-1.51-2.546-2.5-4.312-2.5-1.818 0-3.429.99-4.364 2.5Zm4.364 4.5c-.729 0-1.372-.353-1.781-1.027-.312-.544-.341-1.3.001-1.946.44-.674 1.11-1.027 1.78-1.027.604 0 1.28.338 1.728 1.027.342.647.313 1.402.001 1.946-.418.689-1.069 1.027-1.729 1.027Zm1.099 4h-2.313C24.688 27 21 30.75 21 35.375c0 .875.688 1.625 1.625 1.625h15.688C39.25 37 40 36.312 40 35.375A8.375 8.375 0 0 0 31.625 27Zm-7.5 7c.625-2.25 2.688-4 5.188-4h2.312c2.5 0 4.563 1.75 5.188 4H24.124Z"
      />
    </SvgIcon>
  );
}
