import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function CloudArrowUpArrowDown(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M25.625 12.438a3.096 3.096 0 0 1-3.5-.813C20.75 10.062 18.75 9 16.5 9A7.482 7.482 0 0 0 9 16.5v.063c0 1.25-.813 2.375-2 2.812A5.937 5.937 0 0 0 3 25a6.002 6.002 0 0 0 5.632 5.989l-.002.017a1.5 1.5 0 0 1 .014 2.987A8.96 8.96 0 0 1 0 25c0-3.875 2.5-7.25 6-8.438V16.5C6 10.75 10.688 6 16.5 6c3.125 0 6 1.438 7.938 3.688A7.102 7.102 0 0 1 27.5 9c4.125 0 7.5 3.375 7.5 7.5 0 .388-.05.727-.104 1.105l-.021.145c3 1.313 5.125 4.313 5.125 7.75a8.511 8.511 0 0 1-5.495 7.97l-.004-.01c-.177.073-.37.113-.574.113-.713 0-1.115-.367-1.349-.862-.344-.727 0-1.753.667-1.974C35.418 30.017 37 27.929 37 25.5a5.428 5.428 0 0 0-3.313-5c-1.25-.563-2-1.875-1.75-3.25 0-.125.016-.25.032-.375.015-.125.031-.25.031-.375 0-2.438-2.063-4.5-4.5-4.5-.688 0-1.313.188-1.875.438Z" />
      <path d="m19.61 26.14-3.75-3.75a1.205 1.205 0 0 0-1.758 0l-3.75 3.75a1.204 1.204 0 0 0 0 1.758 1.205 1.205 0 0 0 1.757 0l1.641-1.601V38.25c0 .703.547 1.25 1.25 1.25.664 0 1.25-.547 1.25-1.25V26.297l1.602 1.601a1.205 1.205 0 0 0 1.757 0 1.205 1.205 0 0 0 0-1.757Zm6.25 13.008 3.75-3.75a1.205 1.205 0 0 0 0-1.757 1.205 1.205 0 0 0-1.758 0l-1.602 1.601V23.25c0-.664-.586-1.25-1.25-1.25-.703 0-1.25.586-1.25 1.25v11.992l-1.64-1.601a1.205 1.205 0 0 0-1.758 0 1.205 1.205 0 0 0 0 1.757l3.75 3.75a1.205 1.205 0 0 0 1.757 0Z" />
    </SvgIcon>
  );
}
