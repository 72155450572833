import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function LayerArrowDown(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M20 1c.5 0 1.063.125 1.5.375l13.125 5.563C35.438 7.313 36 8.124 36 9c0 .938-.563 1.75-1.375 2.125L21.5 16.688c-.438.25-1 .312-1.5.312-.563 0-1.063-.063-1.563-.313L5.313 11.125C4.5 10.75 4 9.937 4 9c0-.875.5-1.688 1.313-2.063l13.125-5.562c.5-.25 1-.375 1.562-.375Zm-.375 3.125L8.125 9l11.5 4.938c.125.062.25.062.375.062s.25 0 .313-.063L31.813 9l-11.5-4.875A.477.477 0 0 0 20 4c-.125 0-.25.063-.375.125Zm-12.313 10 3.813 1.625-3 1.25 11.5 4.938c.125.062.25.062.375.062s.25 0 .313-.063L31.813 17l-2.938-1.25 3.75-1.625 2 .813C35.438 15.312 36 16.125 36 17c0 .938-.563 1.75-1.375 2.125L21.5 24.688c-.438.25-1 .312-1.5.312-.563 0-1.063-.063-1.563-.313L5.313 19.125C4.5 18.75 4 17.937 4 17c0-.875.5-1.688 1.313-2.063l2-.812ZM27.994 33.9h-.016l-2.434-2.442c-.496-.51-1.59-.533-2.123 0a1.476 1.476 0 0 0 0 2.066l4.994 5.007c.625.626 1.561.626 2.123 0l4.994-5.007a1.373 1.373 0 0 0 0-2.066c-.562-.626-1.498-.626-2.123 0l-2.419 2.425c0-3.724.008-2.66.008-6.385a1.498 1.498 0 0 0-2.996 0l-.008 6.401Z" />
    </SvgIcon>
  );
}
