import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function List(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M6 7h3c.813 0 1.5.688 1.5 1.5v3c0 .875-.688 1.5-1.5 1.5H6c-.875 0-1.5-.625-1.5-1.5v-3C4.5 7.687 5.125 7 6 7Zm9 1.5h19c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5H15c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5Zm0 10h19c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5H15c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5Zm0 10h19c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5H15c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5Zm-10.5-10c0-.813.625-1.5 1.5-1.5h3c.813 0 1.5.688 1.5 1.5v3c0 .875-.688 1.5-1.5 1.5H6c-.875 0-1.5-.625-1.5-1.5v-3ZM6 27h3c.813 0 1.5.688 1.5 1.5v3c0 .875-.688 1.5-1.5 1.5H6c-.875 0-1.5-.625-1.5-1.5v-3c0-.813.625-1.5 1.5-1.5Z" />
    </SvgIcon>
  );
}
