import { useMemo, useState } from 'react';
import { StatusProps } from '../../status';
import {
  Order,
  TableColumnType,
  TableColumnTypes,
  TableRowType,
} from '../types';

export const getSortFunction = <T>(
  order: Order,
  orderBy: string | undefined,
  sortType: TableColumnTypes,
) => {
  if (!orderBy) {
    return (_a: TableRowType<T>, _b: TableRowType<T>) => {
      return 0;
    };
  }
  const getValues = <Type extends string | number>(
    a: TableColumnType,
    b: TableColumnType,
  ) => {
    const { value: aValue } = a;
    const { value: bValue } = b;

    return [aValue, bValue] as [Type, Type];
  };

  switch (sortType) {
    case 'number':
      return (a: TableRowType<T>, b: TableRowType<T>) => {
        const aCell = a.columns[orderBy];
        const bCell = b.columns[orderBy];
        const [aValue, bValue] = getValues<number>(aCell, bCell);
        return order === 'asc' ? aValue - bValue : bValue - aValue;
      };
    case 'status':
      return (a: TableRowType<T>, b: TableRowType<T>) => {
        const aCell = a.columns[orderBy] as { status: StatusProps['status'] };
        const bCell = b.columns[orderBy] as { status: StatusProps['status'] };
        const statusOrder: { [key in StatusProps['status']]: number } = {
          green: 0,
          yellow: 1,
          red: 2,
          neutral: 3,
        };
        return order === 'asc'
          ? statusOrder[aCell.status] - statusOrder[bCell.status]
          : statusOrder[bCell.status] - statusOrder[aCell.status];
      };
    case 'custom':
      return (_: TableRowType<T>, __: TableRowType<T>) => 0;
    default:
      return (a: TableRowType<T>, b: TableRowType<T>) => {
        const aCell = a.columns[orderBy];
        const bCell = b.columns[orderBy];
        const [aValue, bValue] = getValues<string>(aCell, bCell);
        return order === 'asc'
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      };
  }
};

export const useTableSorting = <T>(data: TableRowType<T>[]) => {
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<string>();

  const handleSortClick = (id: string) => {
    setOrderBy(id);
    setOrder(prev => (prev === 'asc' ? 'desc' : 'asc'));
  };

  const sortedData = useMemo(() => {
    return orderBy
      ? [...data].sort(
          getSortFunction<T>(
            order,
            orderBy,
            data[0].columns[orderBy || ''].type,
          ),
        )
      : data;
  }, [data, order, orderBy]);

  return {
    sortedData,
    order,
    orderBy,
    handleSortClick,
  };
};
