import * as React from 'react';
import { TableFilterType } from './types';
import { useTableStyles } from './Table.styles';
import { SearchField } from '../search-field';
import { Dropdown } from '..';

type Props = {
  selectedIds: string[];
  updateFilter: (key: string, values: string[]) => void;
  filters: TableFilterType[];
  query: string;
  handleSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  actions?: React.ReactNode;
  selectedActions?: React.ReactNode;
};

/*
 * Renders the dropdowns used to filter the table.
 */
export const TableActions = ({
  selectedIds,
  updateFilter,
  filters,
  query,
  actions,
  selectedActions,
  handleSearchChange,
}: Props) => {
  const classes = useTableStyles();
  return (
    <div className={classes.tableActions}>
      <div className={classes.tableFilters}>
        <SearchField value={query} onChange={handleSearchChange} />
        {filters.map(filter => (
          <Dropdown
            value={filter.active}
            label={filter.label ?? filter.key}
            key={filter.key}
            required
            placeholder={filter.label ?? filter.key}
            options={filter.options}
            onChange={event =>
              updateFilter(filter.key, event.target.value as string[])
            }
            multiple
          />
        ))}
      </div>
      <div className={classes.tableActionButtons}>
        {selectedIds.length > 0 ? selectedActions : actions}
      </div>
    </div>
  );
};
