import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Compass(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M33 19.969c0-4.625-2.5-8.875-6.5-11.25-4.063-2.313-9-2.313-13 0C9.437 11.094 7 15.344 7 19.969c0 4.687 2.438 8.937 6.5 11.312 4 2.313 8.938 2.313 13 0 4-2.375 6.5-6.625 6.5-11.312Zm-29 0C4 14.28 7 9.03 12 6.156c4.938-2.875 11-2.875 16 0 4.938 2.875 8 8.125 8 13.813 0 5.75-3.063 11-8 13.875-5 2.875-11.063 2.875-16 0-5-2.875-8-8.125-8-13.875Zm19.125 4.375-9 3.437c-1.188.5-2.438-.75-1.938-1.937l3.438-9a2.21 2.21 0 0 1 1.188-1.188l9-3.437c1.25-.5 2.437.687 1.937 1.937l-3.438 9a2.21 2.21 0 0 1-1.187 1.188ZM22 19.969c0-1.063-.938-2-2-2-1.125 0-2 .937-2 2 0 1.125.875 2 2 2 1.063 0 2-.875 2-2Z" />
    </SvgIcon>
  );
}
