import { Modal, makeStyles } from '@material-ui/core';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { BaseplateTheme } from '../../../style/types';
import { SearchModalInput } from './SearchModalInput';
import { EntityKindFilter, ResetObject } from '../types';
import { SearchModalInstructions } from './SearchModalInstructions';
import { Result, SearchDocument } from '@backstage/plugin-search-common';
import { Icon } from '@lego/plugin-baseplate-core-components';
import { useNavigate } from 'react-router-dom';
import { mapSearchResultToEntity } from '../utils/mapSearchResultToEntity';
import { SearchModalKindFilterItem } from './resultItems/SearchModalKindFilterItem';
import { SearchModalEntityResultItem } from './resultItems/SearchModalEntityResultItem';
import { SearchModalTechdocsResultItem } from './resultItems/SearchModalTechdocsResultItem';
import { SearchModalActionItem } from './resultItems/SearchModalActionItem';
import { useSearchModalKeyboardNavigation } from './hooks/useSearchModalKeyboardNavigation';
import { useBackstageSearch } from './hooks/useBackstageSearch';
import { useSearchModalContext } from '../context/SearchModalContext';
import { allSearchFilters } from '../utils/allSearchFilters';

const useStyles = makeStyles<BaseplateTheme>(theme => ({
  wrapper: {
    position: 'absolute',
    width: '60%',
    maxWidth: '60%',
    maxHeight: '100%',
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.primitive.borderRadius.default,
    boxShadow: theme.shadows[5],
    top: '20%',
    left: '50%',
    transform: 'translateX(-50%)',
    transformOrigin: 'top center',
    overflow: 'auto',
  },
  searchGroup: {
    padding: '0.5rem',
    '& + &': {
      borderTop: `1px solid ${theme.semantic.border.default}`,
    },
  },
}));

export const SearchModal = () => {
  const classes = useStyles();

  const _navigate = useNavigate();
  const {
    modalOpen,
    closeModal,
    selectedFilter,
    setSelectedFilter,
    initialFilter,
  } = useSearchModalContext();

  // The entityKinds that match the search term. E.g, Documentation, Application, etc.
  const [availableFilters, setAvailableFilters] =
    useState<EntityKindFilter[]>(allSearchFilters);

  const [cursor, setCursor] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState<string>('');

  // The url of the selected search result
  const [targetUrl, setTargetUrl] = useState<string>('');
  const { result, setFilters, setTypes } = useBackstageSearch(
    searchTerm,
    setCursor,
  );
  const reset: ResetObject = {
    cursor: () => setCursor(0),
    toDefaultSearchResults: () => setAvailableFilters(allSearchFilters),
    toEmptySearchResults: () => setAvailableFilters([]),
    searchTerm: () => setSearchTerm(''),
    selectedFilter: () => setSelectedFilter(null),
  };

  const navigate = () => {
    closeModal();
    _navigate(targetUrl);
  };

  // The top 5 search results
  const searchResults: Result<SearchDocument>[] =
    searchTerm === '' ? [] : result?.value?.results?.slice(0, 5) ?? [];

  const handleKeyDown = useSearchModalKeyboardNavigation(
    reset,
    navigate,
    availableFilters,
    searchTerm,
    searchResults,
    cursor,
    setCursor,
    setSelectedFilter,
  );

  // Handle onChange in the search input
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    if (selectedFilter) reset.toEmptySearchResults();
    else if (event.target.value === '') reset.toDefaultSearchResults();
    else {
      setAvailableFilters(
        allSearchFilters.filter(({ name }) =>
          name.toLowerCase().includes(event.target.value.toLowerCase()),
        ),
      );
    }
  };

  // Reset search when modal is opened
  useEffect(() => {
    if (modalOpen) {
      reset.cursor();
      reset.searchTerm();
      if (!selectedFilter) {
        setSelectedFilter(initialFilter);
      }
      if (selectedFilter || initialFilter) {
        reset.toEmptySearchResults();
      } else {
        reset.toDefaultSearchResults();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalOpen]);

  // When the cursor changes, update the target url
  useEffect(() => {
    const currentTargets: string[] = availableFilters.map(({ href }) => href);
    if (searchTerm) {
      const resultTargets = searchResults.map(
        ({ document }) => document.location,
      );
      currentTargets.push(...resultTargets);
      currentTargets.push(`/search?query=${searchTerm}`);
    }
    if (cursor >= availableFilters.length + searchResults.length) {
      setTargetUrl(
        currentTargets[availableFilters.length + searchResults.length],
      );
    } else {
      setTargetUrl(currentTargets[cursor]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cursor, searchResults]);

  useEffect(() => {
    if (selectedFilter) {
      if (selectedFilter.name === 'documentation') {
        setTypes(['techdocs']);
      } else if (selectedFilter.name === 'global') {
        reset.toDefaultSearchResults();
        reset.selectedFilter();
      } else {
        setFilters({ kind: [selectedFilter.name] });
      }
    } else {
      setFilters({ kind: [] });
      setTypes([]);
    }
    reset.cursor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilter]);

  return (
    <Modal open={modalOpen} onClose={closeModal} closeAfterTransition>
      <div className={classes.wrapper}>
        <SearchModalInput
          value={searchTerm}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          placeholder={
            !selectedFilter
              ? 'Search everything...'
              : `Search ${selectedFilter.title.toLowerCase()}...`
          }
          searchScope={[selectedFilter?.title || '']}
        />
        {/* Kind filters */}
        {availableFilters.length > 0 && (
          <div className={classes.searchGroup}>
            {availableFilters.map((entityKind, idx) => (
              <SearchModalKindFilterItem
                idx={idx}
                setCursor={setCursor}
                onClick={navigate}
                cursor={cursor}
                filterable
                label={entityKind.title}
                key={idx}
              />
            ))}
          </div>
        )}
        {/* If the user has started typing in the search field */}
        {searchTerm !== '' && (
          <>
            {/* Search results */}
            {searchResults.length > 0 && (
              <div className={classes.searchGroup}>
                {searchResults.map((searchResult, i) => {
                  // Add the number of kind filters to the index to get the correct cursor
                  const idx = availableFilters.length + i;

                  return searchResult.type === 'techdocs' ? (
                    <SearchModalTechdocsResultItem
                      result={searchResult}
                      onClick={navigate}
                      setCursor={setCursor}
                      idx={idx}
                      cursor={cursor}
                      key={idx}
                    />
                  ) : (
                    <SearchModalEntityResultItem
                      onClick={navigate}
                      setCursor={setCursor}
                      idx={idx}
                      cursor={cursor}
                      entity={mapSearchResultToEntity(searchResult)}
                      key={idx}
                    />
                  );
                })}
              </div>
            )}
            <div className={classes.searchGroup}>
              {/* "Search all of Baseplate" button */}
              <SearchModalActionItem
                label={`Search all of Baseplate for: "${searchTerm}"`}
                onClick={navigate}
                icon={<Icon icon="magnifying-glass" />}
                setCursor={setCursor}
                idx={availableFilters.length + searchResults.length}
                cursor={cursor}
              />
            </div>
          </>
        )}
        <SearchModalInstructions />
      </div>
    </Modal>
  );
};
