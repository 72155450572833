import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function PresentationScreen(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M3.5 3.984h33c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5h-33c-.875 0-1.5-.625-1.5-1.5 0-.812.625-1.5 1.5-1.5Zm.5 5h3v13.5c0 .313.188.5.5.5h25c.25 0 .5-.187.5-.5v-13.5h3v13.5c0 1.938-1.625 3.5-3.5 3.5H21.437v2.938L26 33.484a1.37 1.37 0 0 1 0 2.063c-.563.625-1.5.625-2.125 0l-3.938-3.938L16 35.547a1.37 1.37 0 0 1-2.063 0 1.369 1.369 0 0 1 0-2.063l4.563-4.562v-2.938h-11a3.494 3.494 0 0 1-3.5-3.5v-13.5Z" />
    </SvgIcon>
  );
}
