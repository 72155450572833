import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Forward(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M18 9.875c0-1 .813-1.875 1.875-1.875a1.7 1.7 0 0 1 1.063.375l14.437 10.438c.375.312.625.75.625 1.187 0 .5-.25.938-.625 1.25L20.937 31.688a2.107 2.107 0 0 1-1.062.312A1.842 1.842 0 0 1 18 30.125v-6.188l-11.063 7.75c-.312.25-.687.313-1.062.313A1.842 1.842 0 0 1 4 30.125V9.937C4 8.876 4.813 8 5.875 8a1.7 1.7 0 0 1 1.063.375L18 16.125v-6.25Zm0 9.875L7 12.062V28l11-7.688v-.562Zm13.938.25L21 12.125v15.813L31.938 20Z" />
    </SvgIcon>
  );
}
