export const reportsCsvHeaders: string[] = [
  'id',
  'fullName',
  'email',
  'positionId',
  'position',
  'peopleLeaderId',
  'peopleLeaderName',
  'organizationalUnitId',
  'productId',
  'productName',
  'tco',
  'role',
];

export const openPositionsCsvHeaders: string[] = [
  'positionId',
  'position',
  'status',
  'employeeName',
  'startDate',
  'peopleLeaderId',
  'peopleLeaderName',
  'productId',
  'productName',
];
