import { UiGroupedEndpointType, UiNavItem } from '../../../../types';
import { getEndpointId } from './getEndpointId';

export const getOpenApiSideNavItems = (
  groupedEndpoints: UiGroupedEndpointType[],
): UiNavItem[] => {
  return groupedEndpoints.map(({ name, endpoints }) => ({
    key: name,
    label: name,
    children: endpoints.map(({ path, method }): UiNavItem => {
      const id = getEndpointId({ path, method });
      return {
        key: id,
        label: path,
        href: `#${id}`,
        // Don't include chip if method starts with "x"
        ...(!method.startsWith('x') && {
          chip: { label: method.toUpperCase(), color: 'transparent' },
        }),
      };
    }),
  }));
};
