import { useShadowRootElements } from '@backstage/plugin-techdocs-react';
import { baseplateStyles } from '@lego/plugin-baseplate-core-components';
import { useEffect } from 'react';
import { articleWidth, breakpoint } from '../../techdocsStyleVariables';
import { calloutStyles } from './CalloutStyles';
import { navigationStyles } from './NavigationStyles';
import { tableStyles } from './TableStyles';

const useStyles = baseplateStyles(theme => ({
  global: {
    '--md-code-bg-color': theme.semantic.background.neutral[3],
    '--md-code-fg-color': theme.semantic.text.primary,
    '& article': {
      width: '100%',
      margin: '0 auto !important',
      fontSize: '1rem',
      [`@media screen and (min-width: ${breakpoint})`]: {
        // maxWidth: articleWidth,
      },
    },
    '& .md-content': {
      maxWidth: 'none',
      marginLeft: 0,
      // Fix for the techdocs permalinks, to avoid being hidden behind the top nav
      [`@media screen and (min-width: ${breakpoint})`]: {
        maxWidth: articleWidth,
        margin: '0 auto',
      },
    },
    '& .md-footer': {
      display: 'none',
    },
    ...tableStyles(theme),
    ...calloutStyles(theme),
    ...navigationStyles(theme),

    '& [data-md-toggle=drawer]:checked~.md-container .md-sidebar--primary': {
      transform: 'translateX(-30px)',
      top: `0 !important`,
    },
    '& .md-typeset code': {
      wordBreak: 'break-all',
    },
  },
}));

export const GlobalStylesAddon = () => {
  const classes = useStyles();
  const articles = useShadowRootElements(['body']);

  useEffect(() => {
    articles.forEach(article => {
      article.classList.add(classes.global);
    });
  }, [classes.article, classes.global, articles]);

  return null;
};
