import { createApiRef } from '@backstage/core-plugin-api';
import {
  Community,
  ConnectUserToGithubRequest,
  CreateCommunityRequest,
  UpdateCommunityRequest,
  UpsertMembership,
} from '@lego/plugin-baseplate-communities-common';

export const communitiesApiRef = createApiRef<CommunitiesApi>({
  id: 'plugin.communities.apis',
});

export interface CommunitiesApi {
  getAllCommunities(): Promise<Community[]>;
  getCommunityById(communityId: string): Promise<Community>;
  manageCommunity(
    community: CreateCommunityRequest | UpdateCommunityRequest,
    communityId: string | number,
  ): Promise<Response>;
  updateMembership(
    communityId: string,
    role: UpsertMembership,
  ): Promise<Response>;
  leaveCommunity(communityId: string): Promise<Response>;
  linkAccount(accounts: ConnectUserToGithubRequest): Promise<Response>;
  getLinkedAccount(userId: string): Promise<Response>;
  genericAdminRoute(endpoint: string): Promise<Response>;
}
