import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ChartLine(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M7 7.5v22c0 .875.625 1.5 1.5 1.5h26c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5h-26C6 34 4 32 4 29.5v-22C4 6.687 4.625 6 5.5 6 6.313 6 7 6.688 7 7.5Zm26.563 7.063L25 23.063a1.37 1.37 0 0 1-2.063 0L18 18.125l-4.938 4.938c-.624.625-1.562.625-2.124 0a1.369 1.369 0 0 1 0-2.063l6-6c.25-.313.625-.438 1.062-.438.375 0 .75.126 1.063.438L24 19.938l7.438-7.438c.562-.625 1.5-.625 2.125 0 .562.563.562 1.5 0 2.063Z" />
    </SvgIcon>
  );
}
