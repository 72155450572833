import { configApiRef, useApi } from '@backstage/core-plugin-api';
import {
  isDevelopmentKindEnv,
  isLocal,
  isProd,
  isTest,
} from '@lego/plugin-baseplate-common';

export type EnvironmentStages = {
  environment: string;
  isLocalEnvironment: boolean;
  isDevEnvironment: boolean;
  isTestEnvironment: boolean;
  isProdEnvironment: boolean;
};

export const useEnvironmentStage = (): EnvironmentStages => {
  const configApi = useApi(configApiRef);
  const environmentStage = configApi
    .getOptionalString('environmentStage')
    ?.toUpperCase();
  return {
    environment: environmentStage ?? '',
    isLocalEnvironment: isLocal(environmentStage),
    isDevEnvironment: isDevelopmentKindEnv(environmentStage),
    isTestEnvironment: isTest(environmentStage),
    isProdEnvironment: isProd(environmentStage),
  };
};
