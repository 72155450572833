import {
  Chip,
  baseplateStyles,
  showToast,
} from '@lego/plugin-baseplate-core-components';
import { Role } from '@lego/plugin-baseplate-eagraph-common';
import {
  Employee,
  Product,
  ProductConnection,
  getReadableRole,
} from '@lego/plugin-baseplate-people-to-product-common';
import { TableCell, TableRow, Typography } from '@material-ui/core';
import Box from '@mui/material/Box';
import React, { useState } from 'react';
import ConfirmationActions from '../../components/ConfirmationActions/ConfirmationActions';
import RoleDropdown from '../../components/RoleDropdown/RoleDropdown';
import TooltipEditIconButton from '../../components/TooltipEditIconButton/TooltipEditIconButton';
import { useReport } from '../../hooks';
import ChangeTcoCheckbox from '../ChangeTcoCheckbox/ChangeTcoCheckbox';
import DeleteProductButton from '../DeleteProductButton/DeleteProductButton';

const useStyles = baseplateStyles(theme => ({
  disabled: {
    backgroundColor: theme.semantic.background.info,
  },
}));

interface EmployeeProductTableRowProps {
  rowData: ProductConnection;
  employee: Employee;
  disabled: boolean;
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
  setEditingProductId: (productId: string | null) => void;
}

function EmployeeProductTableRow({
  rowData,
  employee,
  disabled,
  isEditing,
  setIsEditing,
  setEditingProductId,
}: EmployeeProductTableRowProps) {
  const [selectedRoles, setSelectedRoles] = useState<Role[]>([]);
  const {
    updateRolesOnProductForEmployee,
    removeProductFromEmployee,
    updateTcoProduct,
  } = useReport();

  const classes = useStyles();

  const handleOnEditButtonClick = () => {
    setIsEditing(true);
    setEditingProductId(rowData.node.id);
  };

  const handleOnCancelButtonClick = () => {
    setIsEditing(false);
  };

  const handleOnSaveButtonClick = async () => {
    try {
      if (isEditing) {
        const rolesToRemove = rowData.roles.filter(
          role => !selectedRoles.includes(role),
        );

        const rolesToAdd = selectedRoles.filter(
          role => !rowData.roles.includes(role),
        );

        await updateRolesOnProductForEmployee(
          employee,
          rowData.node,
          rolesToRemove,
          rolesToAdd,
        );

        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        showToast({
          type: 'success',
          message: (
            <>
              <Typography>Successfully updated the roles</Typography>
            </>
          ),
        });
        setIsEditing(false);
      }
    } catch (error: any) {
      try {
        const messages = JSON.parse(error.body.error.message);

        void showToast({
          type: 'error',
          message: (
            <>
              <Typography>Failed to update roles. Reasons:</Typography>
              <ul>
                {messages.map((e: Error) => (
                  <li>
                    <Typography>{e.message}</Typography>
                  </li>
                ))}
              </ul>
            </>
          ),
        });
      } catch (_) {
        void showToast({
          type: 'error',
          message:
            'Something went wrong. Please refresh and try again. If the issue persists, please reach out to Enterprise Transparency.',
        });
      }
    }
  };

  const handleOnDeleteButtonClick = async (product: Product) => {
    try {
      if (employee) {
        await removeProductFromEmployee(employee, product);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        showToast({
          type: 'success',

          message: (
            <>
              <Typography>
                Successfully removed <strong>{employee.fullName}</strong> from
                the following product:
              </Typography>
              <Typography style={{ fontWeight: 'bold' }}>
                {product.name} ({product.id})
              </Typography>
            </>
          ),
        });
      }
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      showToast({
        type: 'error',
        message: 'Error happened while removing product.',
      });
    }
  };

  const handleCheckboxChange = async (productConnection: ProductConnection) => {
    if (productConnection.tco !== 1) {
      try {
        if (employee) {
          await updateTcoProduct(employee, productConnection.node);
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          showToast({
            type: 'success',
            message: (
              <>
                <Typography>
                  Successfully changed the TCO for{' '}
                  <strong>{employee.fullName}</strong> to the following product:
                </Typography>
                <Typography style={{ fontWeight: 'bold' }}>
                  {productConnection.node.name} ({productConnection.node.id})
                </Typography>
              </>
            ),
          });
        }
      } catch (error) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        showToast({
          type: 'error',
          message: 'Error happened while updating TCO product.',
          autoClose: 5000,
        });
      }
    }
  };

  return (
    <TableRow className={disabled ? classes.disabled : ''}>
      <TableCell>{rowData.node.name}</TableCell>
      <TableCell>
        {isEditing ? (
          <RoleDropdown
            setSelectedRoles={setSelectedRoles}
            defaultRoles={rowData.roles}
          />
        ) : (
          rowData.roles.map((role: string) => (
            <Box padding={0.5}>
              <Chip label={getReadableRole(role)} />
            </Box>
          ))
        )}
      </TableCell>
      <TableCell>
        {isEditing ? (
          <></>
        ) : (
          <ChangeTcoCheckbox
            checked={rowData.tco === 1}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onChange={() => handleCheckboxChange(rowData)}
            product={rowData.node}
            disabled={isEditing || rowData.tco === 1 || disabled}
          />
        )}
      </TableCell>
      <TableCell>
        {isEditing ? (
          <ConfirmationActions
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            handleOnSaveButtonClick={handleOnSaveButtonClick}
            handleOnCancelButtonClick={handleOnCancelButtonClick}
          />
        ) : (
          <>
            <TooltipEditIconButton
              onClick={handleOnEditButtonClick}
              disabled={disabled}
            />
            {rowData.tco !== 1 && (
              <DeleteProductButton
                isDisabled={rowData.tco === 1 || disabled}
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onClick={() => handleOnDeleteButtonClick(rowData.node)}
                product={rowData.node}
              />
            )}
          </>
        )}
      </TableCell>
    </TableRow>
  );
}

export default EmployeeProductTableRow;
