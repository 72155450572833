import { stringifyEntityRef } from '@backstage/catalog-model';
import {
  Button,
  DatePicker,
  Dropdown,
} from '@lego/plugin-baseplate-core-components';
import { Grid } from '@material-ui/core';
import React, { SyntheticEvent } from 'react';
import { useDocsAnalytics } from '../hooks/useDocsAnalytics';

export const AnalyticsPageHeader = () => {
  const {
    selected,
    entities,
    searchParams,
    setSearchParams,
    endDate,
    startDate,
  } = useDocsAnalytics();

  const handleDateRangeChange = (
    value: [Date, Date] | null,
    _: SyntheticEvent,
  ) => {
    if (value) {
      const [newStartDate, newEndDate] = value;
      const updatedSearchParams = new URLSearchParams(searchParams);
      updatedSearchParams.set('startDate', newStartDate.toISOString());
      updatedSearchParams.set('endDate', newEndDate.toISOString());
      setSearchParams(updatedSearchParams);
    }
  };

  const datePickerValue: [Date, Date] | null =
    startDate && endDate ? [startDate, endDate] : null;

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item xs>
        <Grid container spacing={2} alignItems="center" style={{ padding: 0 }}>
          <Grid item>
            <Dropdown
              label="Choose documentation"
              required
              value={selected ?? 'Select an entity'}
              onChange={selectedOption => {
                const value = selectedOption.target.value as string;
                setSearchParams({
                  selected: value,
                });
              }}
              options={entities.map(e => {
                return {
                  label: e.metadata.title ?? e.metadata.name,
                  value: stringifyEntityRef(e),
                };
              })}
            />
          </Grid>
          <Grid item>
            <DatePicker
              id="date-range-picker"
              label="Select Period"
              range
              value={datePickerValue}
              onChange={handleDateRangeChange}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Button
          variant="primary"
          onClick={() =>
            window.open(
              'https://baseplate.kb.ece.legogroup.io/app/dashboards#/view/57f3a7e0-c8e7-11ed-b3ee-2f75c2b44905?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-5d,to:now))',
              '_blank',
            )
          }
        >
          View more analytics
        </Button>
      </Grid>
    </Grid>
  );
};
