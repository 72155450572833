import { SymbolProps } from '@nivo/legends';
import React from 'react';

export const CustomSymbolShape: React.FC<SymbolProps> = ({
  x,
  y,
  size,
  fill,
  borderWidth,
  borderColor,
}) => (
  <rect
    x={x}
    y={y}
    fill={fill}
    strokeWidth={borderWidth}
    stroke={borderColor}
    width={size}
    height={size}
    rx={size / 4}
    style={{ pointerEvents: 'none' }}
  />
);
