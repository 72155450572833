import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Stop(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M28 11H12c-.563 0-1 .5-1 1v16c0 .563.438 1 1 1h16c.5 0 1-.438 1-1V12c0-.5-.5-1-1-1ZM12 8h16c2.188 0 4 1.813 4 4v16c0 2.25-1.813 4-4 4H12c-2.25 0-4-1.75-4-4V12c0-2.188 1.75-4 4-4Z" />
    </SvgIcon>
  );
}
