import { OpenAPIV3_1 } from 'openapi-types';
import { UiParameterType } from '../../../types';
import { resolveRef } from './utils/refResolver';
import { transformSchemaPropertiesToUiProperties } from '../transform/schemaPropertiesToUiProperties';

/**
 * Resolve the [schema object](https://swagger.io/specification/#schema-object)
 */
export const resolveSchema = (
  schema: OpenAPIV3_1.SchemaObject | OpenAPIV3_1.ReferenceObject | undefined,
  components: OpenAPIV3_1.ComponentsObject | undefined,
): UiParameterType[] => {
  let bodyParameters: UiParameterType[] = [];
  const resolvedSchema = resolveRef<OpenAPIV3_1.SchemaObject>(
    'schemas',
    schema,
    components,
  );

  if (resolvedSchema.type === 'array') {
    const resolvedItems = resolveRef<OpenAPIV3_1.SchemaObject>(
      'schemas',
      resolvedSchema.items,
      components,
    );
    return resolveSchema(resolvedItems, components);
  }

  if (resolvedSchema.properties) {
    bodyParameters = transformSchemaPropertiesToUiProperties(
      resolvedSchema.properties,
      resolvedSchema.required,
    );
  }
  return bodyParameters;
};
