import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function UserGroupSimple(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M6.5 12.5c0 1.313.625 2.438 1.75 3.063 1.063.624 2.375.624 3.5 0A3.518 3.518 0 0 0 13.5 12.5c0-1.25-.688-2.375-1.75-3-1.125-.625-2.438-.625-3.5 0-1.125.625-1.75 1.75-1.75 3Zm10 0c0 2.375-1.25 4.5-3.25 5.688-2.063 1.125-4.5 1.125-6.5 0C4.687 17 3.5 14.874 3.5 12.5a6.43 6.43 0 0 1 3.25-5.625c2-1.125 4.438-1.125 6.5 0 2 1.188 3.25 3.313 3.25 5.625ZM30 18c-2.188 0-4.125-1.125-5.25-3-1.063-1.813-1.063-4.125 0-6 1.125-1.813 3.063-3 5.25-3 2.125 0 4.063 1.188 5.188 3 1.062 1.875 1.062 4.188 0 6-1.126 1.875-3.063 3-5.188 3ZM8 25c-2.813 0-5 2.25-5 5v1h14v-1c0-2.75-2.25-5-5-5H8Zm0-3h4c4.375 0 8 3.625 8 8v1a3 3 0 0 1-3 3H3c-1.688 0-3-1.313-3-3v-1c0-4.375 3.563-8 8-8Zm20 12c-2.438 0-4.563-1-6.063-2.688C22 31.188 22 31.125 22 31v-1c0-2.125-.75-4.188-1.938-5.813A3.095 3.095 0 0 1 23 22h14c1.625 0 3 1.375 3 3v1c0 4.438-3.625 8-8 8h-4Z" />
    </SvgIcon>
  );
}
