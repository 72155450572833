export enum LogLevel {
  INFO = 'INFO',
  WARN = 'WARN',
  ERROR = 'ERROR',
}

export type LogData = {
  '@timestamp': Date;
  log_level: LogLevel;
  message: string;
  service: string;
  component: string;
  user: {
    email: string;
  };
  environment: string;
  http?: {
    method: string;
    status_code: number;
    url: string;
  };
};
