import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function SustrainableDelivery(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M12.935 17.032c1.84 0 2.896 1.264 2.896 3.072V25h-1.232v-4.752c0-1.232-.736-2.08-1.936-2.08s-2.048.864-2.048 2.08V25H9.383v-7.792h1.152v.832c.56-.624 1.392-1.008 2.4-1.008Zm3.302 9.904 8.368-14.416h1.456l-8.368 14.416h-1.456Zm11.448-9.904c1.776 0 3.04 1.072 3.04 3.168V25h-1.152v-1.008c-.56.752-1.472 1.184-2.624 1.184-1.568 0-2.72-.832-2.72-2.192 0-1.312 1.104-2.176 2.8-2.176h2.464v-.672c0-1.312-.736-1.968-1.888-1.968-.976 0-1.664.464-2.272 1.248l-.816-.672c.736-1.184 1.92-1.712 3.168-1.712Zm-.576 7.12c1.28 0 2.384-.928 2.384-2.144v-.192h-2.32c-1.088 0-1.68.368-1.68 1.104 0 .832.752 1.232 1.616 1.232Z" />
    </SvgIcon>
  );
}
