import { Content, PageWithHeader } from '@backstage/core-components';
import { Grid } from '@material-ui/core';
import React from 'react';
import { DocsAnalyticsProvider } from '../hooks/useDocsAnalytics';
import { AnalyticsPageContent } from './AnalyticsPageContent';
import { AnalyticsPageHeader } from './AnalyticsPageHeader';

export function DocsAnalyticsPage() {
  return (
    <DocsAnalyticsProvider>
      <PageWithHeader
        title="Analytics"
        themeId=""
        subtitle="Analytics for your documentation pages."
      >
        <Content>
          <Grid container spacing={3}>
            <AnalyticsPageHeader />
            <AnalyticsPageContent />
          </Grid>
        </Content>
      </PageWithHeader>
    </DocsAnalyticsProvider>
  );
}
