import React from 'react';
import { type ReactElement, type ReactNode } from 'react';
import { UiAsyncApiDocumentType } from '../../../types';
import { Channel } from './Channel';
import { useDefinitionTabStyles } from '../DefinitionTab.styles';

export interface AsyncApiDefinitionProps {
  children: ReactNode;
}

export function AsyncApiDefinition({
  channels,
}: UiAsyncApiDocumentType): ReactElement {
  const classes = useDefinitionTabStyles();
  return (
    <div className={classes.content}>
      {channels.map(channel => (
        <Channel {...channel} key={channel.name} />
      ))}
    </div>
  );
}
