import { AnalyticsData } from '@lego/plugin-baseplate-analytics-common';
import { Card } from '@lego/plugin-baseplate-core-components';
import { Grid, Typography } from '@material-ui/core';
import React from 'react';

interface Props {
  data: AnalyticsData;
}

export const AnalyticsPageCards = ({ data }: Props) => {
  return (
    <Grid container style={{ padding: 0 }}>
      <Grid item xs={12} sm={6} md={3}>
        <Card
          title="Total visits"
          tooltip="The total number of times the documentation has been visited by all visitors."
        >
          <Typography variant="body1">{data.uniqueVisits} visits</Typography>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Card
          title="Total unique visitors"
          tooltip="The total number of unique visitors who have visited the documentation."
        >
          <Typography variant="body1">
            {data.totalUniqueVisits} visitors
          </Typography>
        </Card>
      </Grid>
    </Grid>
  );
};
