/***/
/**
 * Common functionalities for the people-to-product plugin.
 *
 * @packageDocumentation
 */

/**
 * In this package you might for example declare types that are common
 * between the frontend and backend plugin packages.
 */

let toggleExternals: boolean = false;

export const getExternalToggle = () => toggleExternals;
export const setExternalToggle = (value: boolean) => {
  toggleExternals = value;
};

export * from './types';
export { getReadableRole } from './enums/Role';

export { peopleLeaderAccess } from './access';
/**
 * Or you might declare some common constants.
 */
export const ADMIN_EMAIL = 'atul.bhardwaj@lego.com';
export const ADMIN_GROUP_ID = 'a3b77669-22dd-4545-a946-804a6d689c64';
export const TEAMS_CHANNEL_LINK =
  'https://teams.microsoft.com/l/channel/19%3ada2bb72f8cbb4f889b1f88cbef93bfeb%40thread.tacv2/People2Product%2520mapping%2520tool%2520and%2520process?groupId=b6e21856-52b4-47c7-9070-054eabbebada&tenantId=1d063515-6cad-4195-9486-ea65df456faa';

export const REPORT_ISSUE_LINK =
  'https://github.com/LEGO/plugin-baseplate-people-to-product/issues/new?assignees=&labels=%F0%9F%90%9B+Bug&projects=&template=bug_report.md&title=';

export const FEATURE_REQUEST_LINK =
  'https://github.com/LEGO/plugin-baseplate-people-to-product/issues/new?assignees=&labels=%F0%9F%8E%89+Enhancement&projects=&template=feature_request.md&title=';

export const TEAMS_CHANNEL_WEBHOOK_LINK =
  'https://legogroup.webhook.office.com/webhookb2/b6e21856-52b4-47c7-9070-054eabbebada@1d063515-6cad-4195-9486-ea65df456faa/IncomingWebhook/1476a19830884ee191641d7d056bf744/5be6fd27-7017-488c-9963-870ce212037e';

export const CONFLUENCE_PAGE_LINK =
  'https://legogroup.atlassian.net/wiki/spaces/1ET/pages/37545247060/User+Guide+for+the+People2Product+application';

export const PRODUCT_MANAGER_JOB_CODES = [
  'J0993',
  'J0428',
  'J0427',
  'J0426',
  'J0994',
  'J1040',
  'J1041',
  'J1059',
  'J1034',
  'J1033',
  'J1032',
];

export const EXTERNAL_JOB_CODE = 'D0003';
