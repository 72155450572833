import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ClipboardQuestion(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M28 8c2.188 0 4 1.813 4 4v20c0 2.25-1.813 4-4 4H12c-2.25 0-4-1.75-4-4V12c0-2.188 1.75-4 4-4h3.063c.5-2.25 2.5-4 4.937-4 2.375 0 4.438 1.75 4.875 4H28Zm-15 3h-1c-.563 0-1 .5-1 1v20c0 .563.438 1 1 1h16c.5 0 1-.438 1-1V12c0-.5-.5-1-1-1h-1v1.5c0 .875-.688 1.5-1.5 1.5h-11c-.875 0-1.5-.625-1.5-1.5V11Zm5.5-2c0 .875.625 1.5 1.5 1.5.813 0 1.5-.625 1.5-1.5 0-.813-.688-1.5-1.5-1.5-.875 0-1.5.688-1.5 1.5Zm-3.938 9.375c.5-1.438 1.813-2.375 3.313-2.375H21.5c2.188 0 3.938 1.813 3.938 4 0 1.375-.75 2.688-1.938 3.375l-2.063 1.188c0 .812-.625 1.437-1.5 1.437a1.48 1.48 0 0 1-1.5-1.5v-.813c0-.562.313-1.062.813-1.312L22 20.812c.313-.187.438-.5.438-.812 0-.563-.375-1-.938-1h-3.625c-.188 0-.375.188-.438.375l-.062.063c-.25.75-1.125 1.187-1.875.937a1.56 1.56 0 0 1-.938-1.938v-.062ZM22 30c0 1.125-.938 2-2 2-1.125 0-2-.875-2-2 0-1.063.875-2 2-2 1.063 0 2 .938 2 2Z" />
    </SvgIcon>
  );
}
