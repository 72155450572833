import React from 'react';
import TableHead from '@mui/material/TableHead';
import TableSortLabel from '@mui/material/TableSortLabel';
import { getColumnType } from './utils';
import TableCell from '@mui/material/TableCell';
import Checkbox from '@mui/material/Checkbox';
import TableRow from '@mui/material/TableRow';
import { Tooltip } from '@material-ui/core';
import { useTableStyles } from './Table.styles';

type Props = {
  selectable?: boolean;
  multiple?: boolean;
  onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectedIds: string[];
  data: any[];
  headers: Array<{
    key: string;
    tooltip?: string;
    disableSorting?: boolean;
    label?: string;
  }>;
  hasMenu: boolean;
  order: any;
  orderBy: any;
  areCheckboxesDisabled?: boolean;
  handleSortClick: (key: string) => void;
};

/*
 * Renders the table header row with sorting and select all UI.
 */
export const TableHeaderRow = ({
  selectable,
  multiple,
  onSelectAllClick,
  selectedIds,
  data,
  headers,
  order,
  orderBy,
  hasMenu,
  handleSortClick,
  areCheckboxesDisabled = false,
}: Props) => {
  const classes = useTableStyles();
  return (
    <TableHead className={classes.tableHeaderRow}>
      <TableRow>
        {/* A select all checkbox in the leftmost column */}
        {selectable && (
          <TableCell padding="checkbox">
            {multiple && data.length > 0 && (
              <Tooltip title="Select all">
                <Checkbox
                  onChange={onSelectAllClick}
                  checked={selectedIds.length === data.length}
                  disabled={areCheckboxesDisabled}
                  inputProps={{ 'aria-label': 'select all' }}
                />
              </Tooltip>
            )}
          </TableCell>
        )}
        {/* All the headers with click to sort logic */}
        {headers.map((header, index) => (
          <TableCell
            sortDirection={order}
            key={index}
            align={
              getColumnType(header.key, data) === 'number' ? 'right' : 'left'
            }
          >
            <Tooltip title={header.tooltip ?? ''}>
              {header.disableSorting ? (
                <span>{header.label}</span>
              ) : (
                <TableSortLabel
                  active={orderBy === header.key}
                  direction={orderBy === header.key ? order : 'asc'}
                  onClick={() => handleSortClick(header.key)}
                >
                  {header.label}
                </TableSortLabel>
              )}
            </Tooltip>
          </TableCell>
        ))}
        {hasMenu && <TableCell padding="checkbox" />}
      </TableRow>
    </TableHead>
  );
};
