import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import { useTechDocsReaderPage } from '@backstage/plugin-techdocs-react';

export const CustomTechDocsReaderPageHeader = () => {
  const {
    title,
    setTitle,
    metadata: { value: metadata },
  } = useTechDocsReaderPage();

  useEffect(() => {
    if (!metadata) return;
    setTitle(metadata.site_name);
  }, [metadata, setTitle]);

  const tabTitle = [title, 'Baseplate'].filter(Boolean).join(' | ');

  return (
    <Helmet titleTemplate="%s">
      <title>{tabTitle}</title>
    </Helmet>
  );
};
