import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ScaleBalanced(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M32.563 7c.812 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5h-7.626a5.11 5.11 0 0 1-3.375 3.813V33h11c.813 0 1.5.688 1.5 1.5 0 .875-.687 1.5-1.5 1.5h-25c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5h11V13.812A5.075 5.075 0 0 1 15.126 10H7.562c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5h7.875C16.25 5.25 18 4 20.064 4c2 0 3.812 1.25 4.562 3h7.938Zm-.5 9.25L27.5 24h9.063l-4.5-7.75Zm-7.876 8.875c-.187-.688.063-1.438.375-2l5.938-10.25c.313-.5.938-.875 1.563-.875.562 0 1.187.375 1.5.875L39.5 23.063c.375.625.563 1.375.438 2.062-.688 2.75-4 4.875-7.876 4.875-3.937 0-7.25-2.125-7.874-4.875ZM3.438 24H12.5l-4.563-7.75-4.5 7.75Zm4.5 6C4.062 30 .75 27.875.062 25.125c-.125-.688.063-1.438.438-2l5.937-10.25c.313-.5.875-.875 1.5-.875s1.188.375 1.5.875l6 10.188c.313.625.563 1.375.376 2.062C15.187 27.875 11.874 30 7.936 30ZM20.062 7c-1.125 0-2 .938-2 2 0 1.125.875 2 2 2 1.063 0 2-.875 2-2 0-1.063-.937-2-2-2Z" />
    </SvgIcon>
  );
}
