import { DbtCapabilityPage } from '@lego/plugin-baseplate-data-processing';
import {
  GitHubConfigContextProvider,
  GithubCopilotPage,
} from '@lego/plugin-baseplate-github';
import { NexusIngestionPage } from '@lego/plugin-baseplate-nexus-ingestion';
import { OnexPage } from '@lego/plugin-baseplate-onex';
import React from 'react';
import { isName } from '../tools';
import { EntitySwitch } from '@backstage/plugin-catalog';
import { PluginTabs } from './TabsMounter';
import { NexusDatabricksStorageCredentialsExternalLocationsPage } from '@lego/plugin-baseplate-nexus-databricks-storage-credentials-and-external-locations';

const ToolsPage = (
  <EntitySwitch>
    <EntitySwitch.Case
      if={isName('data-build-tool')}
      children={<DbtCapabilityPage />}
    />
    <EntitySwitch.Case
      if={isName('github-copilot')}
      children={
        <GitHubConfigContextProvider>
          <GithubCopilotPage />
        </GitHubConfigContextProvider>
      }
    />
    <EntitySwitch.Case if={isName('onex')} children={<OnexPage />} />
    <EntitySwitch.Case if={isName('nexus')} children={<NexusIngestionPage />} />
    <EntitySwitch.Case
      if={isName('nexus-databricks-storage-credentials-and-external-locations')}
      children={<NexusDatabricksStorageCredentialsExternalLocationsPage />}
    />
    <EntitySwitch.Case children={<PluginTabs toolTabs={[]} />} />
  </EntitySwitch>
);

export default ToolsPage;
