import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ChevronDown(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="m18.96 28.04-12-12c-.624-.563-.624-1.5 0-2.126.563-.562 1.5-.562 2.126 0l10.937 11 10.938-10.937c.562-.625 1.5-.625 2.125 0 .562.562.562 1.5 0 2.062l-12.063 12c-.562.625-1.5.625-2.062 0Z" />
    </SvgIcon>
  );
}
