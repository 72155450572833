import {
  configApiRef,
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  fetchApiRef,
} from '@backstage/core-plugin-api';
import { OnexApiRef } from './api';
import { OnexApi } from './api';
import { rootRouteRef } from './routes';
import { useAzureTokenProvider } from '@lego/plugin-baseplate-auth';

export const ONEXPlugin = createPlugin({
  id: 'onex',
  routes: {
    root: rootRouteRef,
  },
  featureFlags: [{ name: 'onex-plugin' }],
  apis: [
    createApiFactory({
      api: OnexApiRef,
      deps: {
        fetchApi: fetchApiRef,
        discoveryApi: discoveryApiRef,
        configApi: configApiRef,
      },
      factory: ({ fetchApi, discoveryApi, configApi }) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const azureAuth = useAzureTokenProvider();
        return new OnexApi({
          discoveryApi,
          fetchApi,
          configApi,
          azureAuth,
        });
      },
    }),
  ],
});

export const OnexPage = ONEXPlugin.provide(
  createRoutableExtension({
    name: 'OnexPage',
    component: () =>
      import('./components/indexpage').then(m => m.IndexPageComponent),
    mountPoint: rootRouteRef,
  }),
);

// export const OnexResourcesOverviewPage = ONEXPlugin.provide(
//   createRoutableExtension({
//     name: 'OnexResourcesOverviewPage',
//     component: () =>
//       import(
//         './components/DataProductComponents/DataProductPageComponent'
//       ).then(m => m.DataProductPageComponent),
//     mountPoint: rootRouteRef,
//   }),
// );

// export const OnexRegistrationPage = ONEXPlugin.provide(
//   createRoutableExtension({
//     name: 'OnexRegistrationPage',
//     component: () =>
//       import(
//         './components/RegistrationComponents/RegistrationPageComponent'
//       ).then(m => m.RegistrationPageComponent),
//     mountPoint: rootRouteRef,
//   }),
// );

// Add the following to app.tsx
// REMEMBER: Change wherever it says "Template" to the name of your liking, but still matching the name of the plugin.
// import { TemplatePage } from './@lego/plugin-baseplate-onex';
// [...]
// <Route path="/onex" element={<TemplatePage />} />
// Remember to run yarn install in the root of the repo to install the new plugin :)
