import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function BrickTwoByTwoStudiofile(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.886 18.866 21.134 5.114a1.603 1.603 0 0 0-2.268 0L5.114 18.866a1.603 1.603 0 0 0 0 2.268l13.752 13.752a1.603 1.603 0 0 0 2.268 0l13.752-13.752a1.603 1.603 0 0 0 0-2.268ZM23.24 3.008a4.58 4.58 0 0 0-6.478 0L3.008 16.761a4.58 4.58 0 0 0 0 6.478l13.753 13.753a4.58 4.58 0 0 0 6.478 0l13.753-13.753a4.58 4.58 0 0 0 0-6.478L23.239 3.008Z"
      />
      <path d="M23.817 11.984a3.817 3.817 0 1 1-7.634 0 3.817 3.817 0 0 1 7.634 0Zm0 16.796a3.817 3.817 0 1 1-7.634 0 3.817 3.817 0 0 1 7.634 0Zm-8.397-8.398a3.817 3.817 0 1 1-7.635 0 3.817 3.817 0 0 1 7.635 0Zm16.031 0a3.817 3.817 0 1 1-7.634 0 3.817 3.817 0 0 1 7.634 0Z" />
    </SvgIcon>
  );
}
