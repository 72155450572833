import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function WindowMagnifyingGllass(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <g fillRule="evenodd" clipRule="evenodd">
        <path d="M1 5c0-2.188 1.75-4 4-4h24c2.188 0 4 1.813 4 4v8H4v12c0 .563.438 1 1 1h10.043c.088 1.015.305 2.023.652 3H5c-2.25 0-4-1.75-4-4V5Zm6 4c1.063 0 2-.875 2-2 0-1.063-.938-2-2-2-1.125 0-2 .938-2 2 0 1.125.875 2 2 2Zm8-2c0-1.063-.938-2-2-2-1.125 0-2 .938-2 2 0 1.125.875 2 2 2 1.063 0 2-.875 2-2Zm4 2c1.063 0 2-.875 2-2 0-1.063-.938-2-2-2-1.125 0-2 .938-2 2 0 1.125.875 2 2 2Z" />
        <path d="M19.213 29.5c-1.617-2.813-1.617-6.223 0-9 1.617-2.813 4.57-4.5 7.805-4.5a8.936 8.936 0 0 1 7.77 4.5c1.616 2.777 1.616 6.188 0 9a9.17 9.17 0 0 1-.59.888l4.623 4.622a1.5 1.5 0 1 1-2.122 2.122l-4.673-4.673A8.927 8.927 0 0 1 27.018 34a9.02 9.02 0 0 1-7.805-4.5Zm2.596-7.497-.004.007c-1.069 1.836-1.08 4.096.005 5.987A6.02 6.02 0 0 0 27.018 31c2.11 0 4.082-1.136 5.172-3.003 1.085-1.89 1.074-4.151.005-5.987l-.005-.008-.004-.007A5.933 5.933 0 0 0 27.018 19c-2.173 0-4.128 1.124-5.204 2.995l-.005.008Z" />
      </g>
    </SvgIcon>
  );
}
