import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

export default function Sparkle(props: any) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M14.2813 5.195C14.1125 5.25875 14 5.42 14 5.6C14 5.78 14.1125 5.94125 14.2813 6.005L16.4 6.8L17.195 8.91875C17.2588 9.0875 17.42 9.2 17.6 9.2C17.78 9.2 17.9412 9.0875 18.005 8.91875L18.8 6.8L20.9188 6.005C21.0875 5.94125 21.2 5.78 21.2 5.6C21.2 5.42 21.0875 5.25875 20.9188 5.195L18.8 4.4L18.005 2.28125C17.9412 2.1125 17.78 2 17.6 2C17.42 2 17.2588 2.1125 17.195 2.28125L16.4 4.4L14.2813 5.195ZM2.34875 11C2.135 11.0975 2 11.3113 2 11.5475C2 11.7838 2.135 11.9938 2.34875 12.0913L2.98625 12.3875L3.29 12.5263L3.3125 12.5375L6.62375 14.0675L8.15375 17.375L8.165 17.3975L8.30375 17.7013L8.6 18.3425C8.6975 18.5562 8.91125 18.6913 9.14375 18.6913C9.37625 18.6913 9.59 18.5562 9.6875 18.3425L9.98375 17.7013L10.1225 17.3975L10.1337 17.375L11.6638 14.0638L14.975 12.5375L14.9975 12.5263L15.3013 12.3875L15.9425 12.0913C16.1562 11.9938 16.2913 11.78 16.2913 11.5475C16.2913 11.315 16.1562 11.1013 15.9425 11.0038L15.3013 10.7075L14.9975 10.5688L14.975 10.5575L11.6638 9.0275L10.1375 5.71625L10.1263 5.69375L9.9875 5.38625L9.69125 4.745C9.59375 4.53125 9.38 4.39625 9.1475 4.39625C8.915 4.39625 8.70125 4.53125 8.60375 4.745L8.3075 5.38625L8.16875 5.69L8.1575 5.7125L6.6275 9.02375L3.31625 10.5538L3.29375 10.565L2.99 10.7038L2.34875 11ZM5.46125 11.5438L7.38125 10.6588C7.77125 10.4788 8.0825 10.1675 8.2625 9.7775L9.1475 7.8575L10.0325 9.7775C10.2125 10.1675 10.5238 10.4788 10.9138 10.6588L12.8337 11.5438L10.9138 12.4288C10.5238 12.6088 10.2125 12.92 10.0325 13.31L9.1475 15.23L8.2625 13.31C8.0825 12.92 7.77125 12.6088 7.38125 12.4288L5.46125 11.5475V11.5438ZM16.4 16.4L14.2813 17.195C14.1125 17.2588 14 17.42 14 17.6C14 17.78 14.1125 17.9412 14.2813 18.005L16.4 18.8L17.195 20.9188C17.2588 21.0875 17.42 21.2 17.6 21.2C17.78 21.2 17.9412 21.0875 18.005 20.9188L18.8 18.8L20.9188 18.005C21.0875 17.9412 21.2 17.78 21.2 17.6C21.2 17.42 21.0875 17.2588 20.9188 17.195L18.8 16.4L18.005 14.2813C17.9412 14.1125 17.78 14 17.6 14C17.42 14 17.2588 14.1125 17.195 14.2813L16.4 16.4Z" />
    </SvgIcon>
  );
}
