import React from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { useTableStyles } from './Table.styles';
import { TableHeaderType, TableRowType } from './types';
import { Typography } from '@material-ui/core';
import { formatNumber, getColumnType } from './utils';

type Props = {
  headers: TableHeaderType[];
  data: TableRowType[];
  selectedIds: string[];
  selectable?: boolean;
};

/*
 * Renders the table summary row at the bottom of the table if the user has inpiutwith summary functions.
 */
export const TableSummaryRow = ({
  data,
  headers,
  selectedIds,
  selectable,
}: Props) => {
  const classes = useTableStyles();
  if (!headers.some(header => header.summary)) {
    return null;
  }
  if (data.length === 0) return null;
  return (
    <TableRow
      className={[
        classes.tableSummary,
        selectedIds.length > 0 ? classes.selected : '',
      ].join(' ')}
    >
      {selectable && <TableCell padding="checkbox" />}
      {headers.map((header, index) => {
        const firstCellInColumn = data[0].columns[header.key];
        const unit =
          firstCellInColumn.type === 'number' ? firstCellInColumn.unit : '';

        return (
          <TableCell
            key={index}
            align={
              getColumnType(header.key, data) === 'number' ? 'right' : 'left'
            }
          >
            <Typography variant="overline" color="secondary">
              {header.summary && (
                <>
                  <Typography
                    component="span"
                    variant="overline"
                    color="secondary"
                  >
                    {header.summary.label}{' '}
                  </Typography>
                  <Typography component="span" variant="body2" color="primary">
                    {formatNumber(
                      header.summary.value(
                        selectedIds.length > 0
                          ? // Calculate the summary function only on the user selected rows
                            data.filter(row => selectedIds.includes(row.id))
                          : // If none are selected, calculate the summary function with all data
                            data,
                      ),
                    )}{' '}
                    {unit}
                  </Typography>
                </>
              )}
            </Typography>
          </TableCell>
        );
      })}
    </TableRow>
  );
};
