export interface IBaseplateError extends Error {
  error: any;
  request: { method: string; url: string };
  response: { statusCode: number };
  errorId?: string;
  plugin?: string;
}

export class BaseplateError extends Error {
  error: any;
  request: { method: string; url: string };
  response: { statusCode: number };
  errorId?: string;
  plugin?: string;

  constructor(options: {
    message: string;
    error: any;
    request: { method: string; url: string };
    response: { statusCode: number };
    errorId?: string;
    plugin?: string;
  }) {
    const { message, error, request, response, errorId, plugin } = options;
    super(message);
    this.name = 'BaseplateError';
    this.error = error;
    this.request = request;
    this.response = response;
    this.errorId = errorId;
    this.plugin = plugin;
  }
}
