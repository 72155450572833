import { SideMenuWrapper } from './SideMenuWrapper';
import { SideMenuItem } from './SideMenuItem';
import { SideMenuSection } from './SideMenuSection';

type Props = {
  Wrapper: typeof SideMenuWrapper;
  Item: typeof SideMenuItem;
  Section: typeof SideMenuSection;
};

const SideMenu: Props = {
  Wrapper: SideMenuWrapper,
  Item: SideMenuItem,
  Section: SideMenuSection,
};

export { SideMenu };
