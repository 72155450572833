import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function HelmetSafety(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M18.5 9c-.313 0-.5.25-.5.5v13c0 .875-.688 1.5-1.5 1.5-.875 0-1.5-.625-1.5-1.5v-13C15 7.625 16.563 6 18.5 6h3C23.375 6 25 7.625 25 9.5v13c0 .875-.688 1.5-1.5 1.5-.875 0-1.5-.625-1.5-1.5v-13c0-.25-.25-.5-.5-.5h-3Zm18.438 19C37.5 28 38 28.5 38 29.063a.89.89 0 0 1-.375.75C36.25 30.813 31 34 20 34 8.937 34 3.687 30.812 2.312 29.812a1.035 1.035 0 0 1-.312-.75C2 28.5 2.438 28 3 28h33.938ZM4 22a13.93 13.93 0 0 1 9-13.063v3.313c-3.563 1.813-6 5.5-6 9.75v4H4v-4Zm29-.125c-.063-4.188-2.5-7.875-6-9.625V8.937c5.188 2 8.938 7.063 9 12.938V26h-3v-4.125Z" />
    </SvgIcon>
  );
}
