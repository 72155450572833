import { Entity } from '@backstage/catalog-model';
import { Tab, Tabs, Typography } from '@material-ui/core';
import React, { useEffect, useState, type ReactElement } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { getAsyncApiDefinition } from '../../parser/asyncapi/getAsyncApiDefinition';
import { getOpenApiDefinition } from '../../parser/openapi/getOpenApiDefinition';
import {
  ApiType,
  UiAsyncApiDocumentType,
  UiOpenApiDocumentType,
} from '../../types';
import { AsyncApiDefinition } from './asyncapi/AsyncApiDefinition';
import { AsyncApiHeader } from './asyncapi/AsyncApiHeader';
import { useDefinitionTabStyles } from './DefinitionTab.styles';
import { OpenApiDefinition } from './openapi/OpenApiDefinition';
import { OpenApiHeader } from './openapi/OpenApiHeader';
import { RawDefinition } from './raw-specification/RawDefinition';
import { DefinitionSideNavigation } from './sidenav/DefinitionSideNavigation';
import { Link } from '@backstage/core-components';
import { UiGraphQlDocumentType } from '../../types/graphql';

export interface DefinitionTabProps {
  entity: Entity;
}

export function DefinitionTab({
  entity,
}: DefinitionTabProps): ReactElement | string {
  const classes = useDefinitionTabStyles();
  const [currentTab, setCurrentTab] = useState('');
  const type = entity.spec?.type as ApiType;
  const specification = entity.spec as any;
  const [document, setDocument] = useState<
    UiOpenApiDocumentType | UiAsyncApiDocumentType | UiGraphQlDocumentType
  >();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      let document;
      if (type === 'openapi') {
        document = getOpenApiDefinition(specification.definition);
      } else if (type === 'asyncapi') {
        document = await getAsyncApiDefinition(specification.definition);
      } else if (type === 'graphql') {
        document = {
          navItems: [],
          servers: [],
        } as UiGraphQlDocumentType;
        setCurrentTab('raw');
      }
      setDocument(document);
    };

    fetchData()
      .then(() => setLoading(false))
      .catch(err => {
        console.error(err);
        setLoading(false);
      });
  }, [specification]); // Add type if it's relevant for the fetch

  if (loading) return 'Loading...';

  if (!document) return 'Error fetching API specification';

  return (
    <div className={classes.pageWrapper}>
      {
        {
          openapi: <OpenApiHeader entity={entity} servers={document.servers} />,
          asyncapi: (
            <AsyncApiHeader entity={entity} servers={document.servers} />
          ),
          graphql: 'graphql',
        }[type]
      }

      <Tabs
        value={currentTab}
        onChange={(_, newValue) => {
          navigate(`./${newValue}`);
          setCurrentTab(newValue);
        }}
        className={classes.tabs}
      >
        <Tab label="Default" value="" />
        <Tab label="Raw" value="raw" />
      </Tabs>

      <DefinitionSideNavigation navItems={document.navItems} />
      <Routes>
        <Route
          path=""
          element={
            <>
              {
                {
                  openapi: (
                    <OpenApiDefinition
                      {...(document as UiOpenApiDocumentType)}
                    />
                  ),
                  asyncapi: (
                    <AsyncApiDefinition
                      {...(document as UiAsyncApiDocumentType)}
                    />
                  ),
                  graphql: (
                    <div>
                      <Typography variant="subtitle1">
                        Graphql definition coming soon...
                      </Typography>
                      <Typography variant="body1">
                        Use <Link to="raw">raw</Link> definition for now.
                      </Typography>
                    </div>
                  ),
                }[type]
              }
            </>
          }
        />
        <Route
          path="raw"
          element={
            <RawDefinition rawSpecification={specification.definition} />
          }
        />
      </Routes>
    </div>
  );
}
