import React from 'react';
import { useEntityDisplayExtensionStyles } from './CurrentEntityDisplayExtension.styles';
import { Input, useUserContext } from '@lego/plugin-baseplate-core-components';
import useAsync from 'react-use/esm/useAsync';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { useParams } from 'react-router-dom';
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';
import { stringifyEntityRef } from '@backstage/catalog-model';

export const CurrentEntityDisplayExtension = (
  props: FieldExtensionComponentProps<string | undefined>,
) => {
  const { uiSchema, onChange } = props;
  const styles = useEntityDisplayExtensionStyles();
  const fieldSchema = uiSchema['ui:options'];
  const label =
    fieldSchema?.label?.toString() ?? props?.schema?.title?.toString();
  const helperText = (fieldSchema?.helperText as string) || undefined;
  const kind = fieldSchema?.kind?.toString() ?? ('Product' as string);
  const productSource = fieldSchema?.productSource ?? 'user';
  const catalogApi = useApi(catalogApiRef);
  const { userEntity, product } = useUserContext();
  const valueProp = fieldSchema?.valueProp;
  const params = useParams();

  const { loading, value: entity } = useAsync(async () => {
    if (!fieldSchema?.kind) {
      throw new Error('Kind is required for EntityDisplayExtension');
    }

    let entityResult;

    if (fieldSchema?.kind === 'User') {
      entityResult = userEntity;
    } else if (
      fieldSchema?.kind === 'Product' &&
      productSource === 'user' &&
      product
    ) {
      entityResult = product;
    } else {
      entityResult = await catalogApi.getEntityByRef({
        kind: kind,
        namespace: 'default',
        name: params?.name ?? '',
      });
    }

    let value = '';
    if (valueProp && entityResult) {
      value = (entityResult.metadata as Record<string, any>)[
        valueProp as string
      ];
    } else {
      value = stringifyEntityRef(entityResult ?? { kind: '', name: '' });
    }
    onChange(value);

    return entityResult;
  });

  return (
    <div className={styles.entityInput}>
      <Input
        loading={loading}
        required
        label={label ?? ''}
        disabled
        value={entity?.metadata?.title ?? ''}
        helperText={helperText}
      />
    </div>
  );
};
