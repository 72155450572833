import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function MinifigureFaceless4(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M11.338 28.81c-2.41 0-4.36 1.55-5.28 3.28-.91 1.71-1.47 4.35-1.47 5.72h3c.12-2.03 1.202-6.05 3.75-6.05h16.5c2.549 0 3.63 4.02 3.75 6.05h3c0-1.37-.56-4.01-1.47-5.72-.92-1.74-2.78-3.28-5.28-3.28h-16.5Zm23.673-5.89a5.171 5.171 0 0 1-1.48-5.59c.87-3.04 1.67-9.13-.91-12.63a6.565 6.565 0 0 0-2.46-1.95c-.71-.32-1.46-.51-2.26-.56h-.79c-1.14.03-2.23.3-3.25.81-.97.49-1.83 1.16-2.53 2-2.27-.42-6.54-.94-9.61 2.27-2.38 2.4-2.93 6.32-1.65 11.6v3.44c0 2.19 1.92 4.23 4.23 4.28h10.56c.79-.03 1.52-.3 2.15-.71.66.27 1.96.71 3.47.71 1.51 0 3.09-.4 4.55-1.74.25-.26.38-.59.38-.95s-.14-.7-.4-.97v-.01Zm-20.45.66c-.81 0-1.5-.71-1.5-1.51v-6.98c3.02-.24 8.39-1.06 11.19-3.9.46.12.87.36 1.18.7.59.8.58 1.69.63 2.68v7.5c0 .81-.69 1.5-1.5 1.5h-10v.01Zm14.26.04c.34-.56.32-1.16.39-1.85 0-.05.02-.84.04-1.66.02-.91.05-1.87.05-1.92v-2.51c0-1 0-2.24-.4-3.33a7.197 7.197 0 0 0-1.87-2.78 4.735 4.735 0 0 0-3.48-1.29c-.19.02-.38.08-.55.18-.17.1-.31.23-.43.39-1.6 2.29-6.76 3.26-10.37 3.49.14-1.15.62-2.23 1.38-3.08 2.8-2.87 7.66-1.39 7.91-1.33.29.08.6.06.88-.06s.51-.33.66-.59l.15-.24c.47-.62 1.07-1.13 1.75-1.49.68-.35 1.44-.55 2.22-.57.62-.05 1.24.05 1.8.3.57.25 1.07.64 1.46 1.12 1.79 2.44 1.24 7.45.43 10.21-.06.18-1.4 4.34 1.16 7.08-1.11.41-2.1.36-3.19-.07h.01Z" />
    </SvgIcon>
  );
}
