import { makeStyles } from '@material-ui/core';

export const useJoinCommunityStyles = makeStyles<any>(theme => ({
  joinModalContent: {
    width: 650,
    background: theme.semantic.background.neutral[1],
    padding: '20px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    margin: 'auto',
    transform: 'translate(-50%, -50%)',
    '-ms-transform': 'translateX(-50%) translateY(-50%)',
    '-webkit-transform': 'translate(-50%, -50%)',

    [theme.breakpoints.down('800')]: {
      width: '80%',
      left: '45%',
    },
  },
  joinTitleGrp: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  joinBody: {
    marginBottom: 20,
    '& > .MuiTypography-root': {
      marginBottom: 10,
    },
  },

  joinFooter: {
    marginTop: 40,
    display: 'flex',
    justifyContent: 'flex-end',

    '& > button:last-of-type': {
      marginLeft: 10,
    },
  },
  joinDisabledBtn: {
    opacity: 0.5,
  },
  joinCloseBtn: {
    color: theme.semantic.text.secondary,
    '& :hover': {
      color: theme.semantic.text.primary,
      cursor: 'pointer',
    },
  },
  joinSpinnerBg: {
    position: 'absolute',
    margin: 'auto',
    background: 'rgba(255, 255, 255, 0.8)',
    width: '95%',
    height: 450,
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '20%',
    '& > .MuiGrid-root >.v5-v5-MuiBox-root': {
      top: '34%',
      margin: 'auto',
    },
  },
  joinRole: {
    '& .MuiMenu-paper': {
      width: 260,
      top: '158px !important',

      '& > .MuiList-root > .MuiListItem-root.Mui-selected': {
        backgroundColor: theme.semantic.background.info,
      },
      '& > .MuiList-root > .MuiButtonBase-root': {
        '& > .MuiListItemText-root > .MuiTypography-root': {
          color: theme.semantic.text.primary,
        },

        '& > svg': {
          fontSize: 18,
        },
      },
    },
  },
}));
