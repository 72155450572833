import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function SlidersUp(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M10 4c.813 0 1.5.688 1.5 1.5v3.75c2 .625 3.5 2.563 3.5 4.75 0 2.25-1.5 4.188-3.5 4.813V34.5c0 .875-.688 1.5-1.5 1.5-.875 0-1.5-.625-1.5-1.5V18.812C6.437 18.188 5 16.25 5 14c0-2.188 1.438-4.125 3.5-4.75V5.5c0-.813.625-1.5 1.5-1.5Zm0 12c1.063 0 2-.875 2-2 0-1.063-.938-2-2-2-1.125 0-2 .938-2 2 0 1.125.875 2 2 2Zm10 12c1.063 0 2-.875 2-2 0-1.063-.938-2-2-2-1.125 0-2 .938-2 2 0 1.125.875 2 2 2Zm5-2c0 2.25-1.5 4.188-3.5 4.813V34.5c0 .875-.688 1.5-1.5 1.5-.875 0-1.5-.625-1.5-1.5v-3.688C16.437 30.188 15 28.25 15 26c0-2.188 1.438-4.125 3.5-4.75V5.5c0-.813.625-1.5 1.5-1.5.813 0 1.5.688 1.5 1.5v15.75c2 .625 3.5 2.563 3.5 4.75Zm7-10c0-1.063-.938-2-2-2-1.125 0-2 .938-2 2 0 1.125.875 2 2 2 1.063 0 2-.875 2-2Zm-.5 4.813V34.5c0 .875-.688 1.5-1.5 1.5-.875 0-1.5-.625-1.5-1.5V20.812C26.437 20.188 25 18.25 25 16c0-2.188 1.438-4.125 3.5-4.75V5.5c0-.813.625-1.5 1.5-1.5.813 0 1.5.688 1.5 1.5v5.75c2 .625 3.5 2.563 3.5 4.75 0 2.25-1.5 4.188-3.5 4.813Z" />
    </SvgIcon>
  );
}
