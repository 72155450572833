import { getDateFromNow } from '../../../utils';

export const dateRange = (startDate: any, endDate: any) => {
  const defaultFrom = {
    year: startDate?.getFullYear() || 0,
    month: ((startDate?.getMonth() as number) || 0) + 1,
    day: startDate?.getDate() || 0,
  };
  const defaultTo = {
    year: endDate?.getFullYear() || 0,
    month: ((endDate?.getMonth() as number) || 0) + 1,
    day: endDate?.getDate() || 0,
  };

  const defaultRangeValue = {
    from: defaultFrom,
    to: defaultTo,
  };

  return defaultRangeValue;
};

export const getWeekDate = (day: number) => {
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  const currentDay = currentDate.getDay();

  let difference = currentDay - 1;
  if (difference < 0) {
    difference = 6;
  }

  const startOfWeek = new Date(currentDate);
  startOfWeek.setDate(currentDate.getDate() - difference + day);

  const endOfWeek = new Date(startOfWeek);
  endOfWeek.setDate(startOfWeek.getDate() + 6);

  return dateRange(startOfWeek, endOfWeek);
};

export const defaultDateRange = () => {
  const dateToday = new Date();
  return dateRange(dateToday, getDateFromNow(1, 'year', true));
};

export const getNextWeekend = () => {
  const currentDate = new Date();

  const currentDay = currentDate.getDay();

  let daysUntilSaturday = 6 - currentDay;

  if (currentDay >= 6) {
    daysUntilSaturday = 6 + (7 - currentDay);
  }

  const nextSaturday = new Date(currentDate);
  nextSaturday.setDate(currentDate.getDate() + daysUntilSaturday);

  const nextSunday = new Date(nextSaturday);
  nextSunday.setDate(nextSaturday.getDate() + 1);

  return dateRange(nextSaturday, nextSunday);
};

export const formatDate = (dateObject: any, type: any) => {
  const { year, month, day } = dateObject;
  const paddedMonth = month < 10 ? `0${month}` : `${month}`;
  const paddedDay = day < 10 ? `0${day}` : `${day}`;

  const date = `${year}-${paddedMonth}-${paddedDay}T00:00:00Z`;

  const newDate = new Date(date);

  if (type === 'start') {
    newDate.setUTCHours(0, 0, 0);
  } else {
    newDate.setUTCHours(23, 59, 0);
  }

  return newDate.toISOString();
};
