import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Heart(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="m18.063 32.933-.125-.187L7 22.62a9.458 9.458 0 0 1-3-6.938v-.187c0-4.375 3.125-8.188 7.438-9 2.437-.5 4.937.062 7 1.5C19 8.37 19.5 8.87 20 9.37c.25-.25.5-.563.813-.813l.75-.562c2-1.438 4.5-2 6.937-1.5a9.167 9.167 0 0 1 7.5 9v.187c0 2.625-1.125 5.125-3.063 6.938L22 32.746l-.125.187c-.5.438-1.188.75-1.875.75-.75 0-1.375-.312-1.938-.75Zm.875-20.25c-.063 0-.063 0-.063-.062l-1.125-1.25C16.312 9.746 14.125 9.058 12 9.433a6.158 6.158 0 0 0-5 6.063v.187c0 1.813.688 3.5 2 4.688l11 10.187 10.938-10.187A6.289 6.289 0 0 0 33 15.683v-.187c0-2.938-2.125-5.5-5.063-6.063-2.125-.375-4.312.313-5.75 1.938l-1.125 1.25c0 .062 0 .062-.062.125-.25.25-.625.437-1 .437-.438 0-.813-.187-1.063-.437v-.063Z" />
    </SvgIcon>
  );
}
