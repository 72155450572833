import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function CircleQuestion(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M33 19.969c0-4.625-2.5-8.875-6.5-11.25-4.063-2.313-9-2.313-13 0C9.437 11.094 7 15.344 7 19.969c0 4.687 2.438 8.937 6.5 11.312 4 2.313 8.938 2.313 13 0 4-2.375 6.5-6.625 6.5-11.312Zm-29 0C4 14.28 7 9.03 12 6.156c4.938-2.875 11-2.875 16 0 4.938 2.875 8 8.125 8 13.813 0 5.75-3.063 11-8 13.875-5 2.875-11.063 2.875-16 0-5-2.875-8-8.125-8-13.875Zm10.563-5.625c.5-1.438 1.812-2.375 3.312-2.375H21.5c2.188 0 4 1.812 4 4 0 1.375-.813 2.687-2 3.375l-2 1.187c-.063.813-.688 1.438-1.5 1.438-.875 0-1.5-.625-1.5-1.5v-.813c0-.562.25-1.062.75-1.312L22 16.78c.313-.187.5-.5.5-.812 0-.563-.438-.938-1-.938h-3.625a.47.47 0 0 0-.438.313l-.062.062c-.25.813-1.125 1.188-1.875.938a1.561 1.561 0 0 1-.938-1.938v-.062ZM18 25.969c0-1.063.875-2 2-2 1.063 0 2 .937 2 2 0 1.125-.938 2-2 2-1.125 0-2-.875-2-2Z" />
    </SvgIcon>
  );
}
