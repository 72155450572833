import React from 'react';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { baseplateStyles } from '../../utils';

type Props = {
  content: React.ReactNode;
};

const useStyles = baseplateStyles(theme => ({
  tooltip: {
    [`& .v5-${tooltipClasses.tooltip}`]: {
      color: theme.semantic.text.primary,
      backgroundColor: theme.semantic.background.neutral[1],
      borderRadius: theme.primitive.borderRadius.default,
      padding: '0.5rem 1rem',
      boxShadow: '0 2px 3px rgba(0,0,0,0.2)',
      ...theme.typography.body2,
    },
  },
  circle: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    userSelect: 'none',
    cursor: 'help',
    width: 24,
    height: 24,
    borderRadius: theme.primitive.borderRadius.full,
    backgroundColor: theme.semantic.background.neutral[3],
  },
}));

export const TooltipIcon = ({ content }: Props) => {
  const classes = useStyles();
  return (
    <Tooltip
      title={<>{content}</>}
      classes={{ popper: classes.tooltip }}
      leaveDelay={300}
    >
      <div className={classes.circle}>?</div>
    </Tooltip>
  );
};
