import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function BarcodeRead(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M5.5 7c-.313 0-.5.25-.5.5v5c0 .875-.688 1.5-1.5 1.5-.875 0-1.5-.625-1.5-1.5v-5C2 5.625 3.563 4 5.5 4h5c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5h-5Zm4 5c.813 0 1.5.688 1.5 1.5v13c0 .875-.688 1.5-1.5 1.5-.875 0-1.5-.625-1.5-1.5v-13c0-.813.625-1.5 1.5-1.5Zm3.5 1c0-.5.438-1 1-1 .5 0 1 .5 1 1v14c0 .563-.5 1-1 1-.563 0-1-.438-1-1V13Zm17 0c0-.5.438-1 1-1 .5 0 1 .5 1 1v14c0 .563-.5 1-1 1-.563 0-1-.438-1-1V13Zm-13 .5c0-.813.625-1.5 1.5-1.5.813 0 1.5.688 1.5 1.5v13c0 .875-.688 1.5-1.5 1.5-.875 0-1.5-.625-1.5-1.5v-13Zm9.5-1.5c.813 0 1.5.688 1.5 1.5v13c0 .875-.688 1.5-1.5 1.5-.875 0-1.5-.625-1.5-1.5v-13c0-.813.625-1.5 1.5-1.5ZM35 7.5c0-.25-.25-.5-.5-.5h-5c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5h5C36.375 4 38 5.625 38 7.5v5c0 .875-.688 1.5-1.5 1.5-.875 0-1.5-.625-1.5-1.5v-5ZM5.5 33h5c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5h-5A3.494 3.494 0 0 1 2 32.5v-5c0-.813.625-1.5 1.5-1.5.813 0 1.5.688 1.5 1.5v5c0 .313.188.5.5.5Zm29.5-.5v-5c0-.813.625-1.5 1.5-1.5.813 0 1.5.688 1.5 1.5v5c0 1.938-1.625 3.5-3.5 3.5h-5c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5h5c.25 0 .5-.188.5-.5Z" />
    </SvgIcon>
  );
}
