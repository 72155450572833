import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Bucket(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M12 13.5v.5H9v-.5C9 8.312 13.25 4 18.5 4h3c5.188 0 9.5 4.313 9.5 9.5v.5h-3v-.5C28 9.937 25.062 7 21.5 7h-3a6.495 6.495 0 0 0-6.5 6.5Zm-6 4c0-.813.625-1.5 1.5-1.5h25c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5h-.375l-1.813 14.375c-.187 1.5-1.5 2.625-3 2.625H12.626c-1.5 0-2.813-1.125-3-2.625L7.875 19H7.5c-.875 0-1.5-.625-1.5-1.5Zm4.875 1.5 1.75 14h14.688l1.75-14H10.875Z" />
    </SvgIcon>
  );
}
