import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Line(props) {
  return (
    <SvgIcon
      viewBox="0 0 41 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <g clipPath="url(#clip0_3592_7067)">
        <path d="M24.298 15.39v6.406c0 .157-.156.235-.312.235H22.97c-.078 0-.234 0-.234-.078l-2.89-3.985v3.828c0 .157-.157.235-.313.235h-1.016c-.156 0-.313-.078-.313-.235V15.39c0-.156.157-.234.313-.234h1.016c.078 0 .156 0 .234.078l2.89 3.984V15.39c0-.156.157-.234.313-.234h1.016c.156 0 .312.078.312.234Zm-7.343-.234h-1.016c-.156 0-.313.078-.313.234v6.406c0 .157.157.235.313.235h1.015c.157 0 .313-.078.313-.235V15.39c0-.156-.156-.234-.313-.234Zm-2.5 5.312H11.72V15.39c0-.156-.156-.234-.312-.234h-1.016c-.156 0-.313.078-.313.234v6.406c0 .078.079.078.079.157.078.078.156.078.234.078h4.063a.336.336 0 0 0 .312-.313v-1.015c0-.157-.156-.235-.313-.235Zm15.156-5.312h-4.063c-.156 0-.312.078-.312.234v6.406c0 .078.156.235.312.235h4.063c.156 0 .312-.078.312-.235v-1.093c0-.157-.156-.235-.312-.235h-2.735v-1.094h2.735c.156 0 .312-.078.312-.312v-1.016c0-.156-.156-.234-.312-.234h-2.735v-1.094h2.735a.336.336 0 0 0 .312-.312V15.39c0-.156-.156-.234-.312-.234ZM40 7.343v25.469c-.078 3.984-3.359 7.266-7.343 7.187H7.188C3.205 40-.076 36.72.002 32.734V7.264C.001 3.282 3.283 0 7.267 0h25.469C36.72.077 40 3.36 40 7.343Zm-5.546 10.938c0-6.485-6.563-11.797-14.532-11.797-8.047 0-14.61 5.312-14.61 11.797 0 5.859 5.235 10.703 12.188 11.64 1.72.39 1.563 1.016 1.172 3.282-.078.39-.312 1.484 1.25.78 1.563-.624 8.36-4.921 11.407-8.437 2.109-2.265 3.125-4.687 3.125-7.265Z" />
      </g>
      <defs>
        <clipPath id="clip0_3592_7067">
          <path fill="#fff" d="M0 0h40.001v40H0z" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
