import React from 'react';
import { Link } from '@material-ui/core';
import { ANNOTATION_VIEW_URL } from '@backstage/catalog-model';
import { useEntity } from '@backstage/plugin-catalog-react';
import { BaseplateWarningPanel } from './CustomComponents/BaseplateWarningPanel';

export const EntityLifecycleWarning = () => {
  const { entity } = useEntity();

  return (
    <BaseplateWarningPanel
      severity="info"
      title="Lifecycle information is missing in LeanIX"
    >
      <>
        <p>This entity is missing lifecycle information in LeanIX</p>
        <p>
          The {entity.kind}: {entity.metadata.title ?? entity.metadata.name} has
          incorrect or missing data in LeanIX.
        </p>
        <p>Please fill out the lifecycle information.</p>
        <Link
          href={entity.metadata.annotations?.[ANNOTATION_VIEW_URL]}
          target="_blank"
        >
          {entity.metadata.title ?? entity.metadata.name} in LeanIX.
        </Link>
      </>
    </BaseplateWarningPanel>
  );
};
