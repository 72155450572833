import { useEntity } from '@backstage/plugin-catalog-react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useMemo } from 'react';
import { LogLevel } from '../types/Logging';
import { RepositorySuggestion } from '../types/Repository';
import { logToElastic } from '../util/logs';
import { useConfig } from './useConfig';
import { useLoggingData } from './useLoggingData';

export const useRepositorySuggestions = () => {
  const { entity } = useEntity();
  const { BACKEND_BASE_URL, azureAccessToken } = useConfig();
  const { email, environment } = useLoggingData();
  const productId = entity.metadata.name;

  const fetchRepositorySuggestions = async (): Promise<
    RepositorySuggestion[]
  > => {
    const response = await axios.get<RepositorySuggestion[]>(
      `${BACKEND_BASE_URL}/v1/product/${productId}/repositories/suggestions`,
      {
        headers: {
          authorization: `Bearer ${azureAccessToken}`,
        },
      },
    );
    return response.data;
  };

  const {
    data = [],
    error,
    isLoading: loading,
    refetch,
  } = useQuery<RepositorySuggestion[], Error>(
    ['repositorySuggestions', productId],
    fetchRepositorySuggestions,
    {
      enabled: !!azureAccessToken && !!BACKEND_BASE_URL,
      onError: catchError => {
        (async () => {
          await logToElastic(
            `Error fetching repository suggestions: ${catchError.message}.`,
            LogLevel.ERROR,
            'useRepositorySuggestions.tsx',
            email,
            environment,
            BACKEND_BASE_URL,
            azureAccessToken,
          );
        })();
      },
    },
  );

  const repositorySuggestions = useMemo(
    () => data.sort((a, b) => (a.repoName > b.repoName ? 1 : -1)),
    [data],
  );

  return { repositorySuggestions, error, loading, refetch };
};
