/*
 * Copyright 2022 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';
import {
  DataProductExternalLocation,
  ExternalLocationCreateRequest,
  ExternalLocationUpdateRequest,
  ExternalLocationsApi,
  PermissionsResponse,
  UpdatePermissionsRequest,
} from '@lego/plugin-baseplate-nexus-databricks-storage-credentials-and-external-locations-common';
import { AbstractClient } from '../AbstractClient';

export class ExternalLocationsClient
  extends AbstractClient
  implements ExternalLocationsApi
{
  public constructor(options: {
    discoveryApi: DiscoveryApi;
    fetchApi: FetchApi;
  }) {
    super(
      'nexus-databricks-storage-credentials-and-external-locations',
      options.discoveryApi,
      options.fetchApi,
    );
  }

  public async list(): Promise<DataProductExternalLocation[]> {
    return this.fetch({
      path: 'external-locations',
      method: 'GET',
      showProgress: {
        error: 'Failed to list External Locations',
        pending: false,
        success: false,
      },
    });
  }

  public async create(
    dataProduct: string,
    externalLocationCreateRequest: ExternalLocationCreateRequest,
  ): Promise<DataProductExternalLocation> {
    return this.fetch({
      path: `external-locations/${dataProduct}`,
      method: 'POST',
      body: externalLocationCreateRequest,
      showProgress: {
        pending: 'Creating External Location',
        success: 'Successfully created External Location',
        error: 'Failed to create External Location',
      },
    });
  }

  public async update(
    dataProduct: string,
    name: string,
    externalLocationUpdateRequest: Partial<ExternalLocationUpdateRequest>,
  ): Promise<DataProductExternalLocation> {
    return this.fetch({
      path: `external-locations/${dataProduct}/${name}`,
      method: 'PATCH',
      body: externalLocationUpdateRequest,
      showProgress: {
        pending: 'Updating External Location',
        success: 'Successfully updated External Location',
        error: 'Failed to update External Location',
      },
    });
  }

  public async delete(dataProduct: string, name: string): Promise<object> {
    return this.fetch({
      path: `external-locations/${dataProduct}/${name}`,
      method: 'DELETE',
      showProgress: {
        pending: 'Deleting External Location',
        success: 'Successfully deleted External Location',
        error: 'Failed to delete External Location',
      },
    });
  }

  public getPermissions(
    dataProduct: string,
    externalLocationName: string,
  ): Promise<PermissionsResponse> {
    return this.fetch({
      path: `external-locations/${dataProduct}/${externalLocationName}/permissions`,
      method: 'GET',
      showProgress: {
        error: 'Failed to list External Location Permissions',
        pending: false,
        success: false,
      },
    });
  }

  public updatePermissions(
    dataProduct: string,
    externalLocationName: string,
    updatePermissionsRequest: Partial<UpdatePermissionsRequest>,
  ): Promise<PermissionsResponse> {
    return this.fetch({
      path: `external-locations/${dataProduct}/${externalLocationName}/permissions`,
      method: 'PATCH',
      body: updatePermissionsRequest,
      showProgress: {
        pending: 'Updating External Location Permissions',
        success: 'Successfully updated External Location Permissions',
        error: 'Failed to update External Location Permissions',
      },
    });
  }
}
