import axios from 'axios';
import { LogData, LogLevel } from '../types/Logging';

export async function logToElastic(
  message: string,
  level: LogLevel,
  component: string,
  email: string,
  environment: string,
  BACKEND_BASE_URL: string,
  azureAccessToken: string,
  http?: {
    method: string;
    status_code: number;
    url: string;
  },
) {
  const logData: LogData = {
    '@timestamp': new Date(),
    log_level: level,
    message: message,
    service: 'github-frontend',
    environment: environment,
    component: component,
    user: {
      email,
    },
    http: http,
  };

  try {
    await axios.post(`${BACKEND_BASE_URL}/v1/elastic/send-logs`, logData, {
      headers: {
        'Content-Type': 'application/json',
        Accept: '*/*',
        Authorization: `Bearer ${azureAccessToken}`,
      },
    });
  } catch (error: any) {
    throw new Error(`Error sending logs to backend: ${error.message || error}`);
  }
}
