import { Entity, stringifyEntityRef } from '@backstage/catalog-model';
import { useApi } from '@backstage/core-plugin-api';
import {
  CATALOG_FILTER_EXISTS,
  catalogApiRef,
} from '@backstage/plugin-catalog-react';
import { AnalyticsData } from '@lego/plugin-baseplate-analytics-common';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import {
  SetURLSearchParams,
  useLocation,
  useSearchParams,
} from 'react-router-dom';
import useAsync from 'react-use/lib/useAsync';
import { AnalyticsApiApiRef } from '../api';

const filter = [
  {
    'metadata.annotations.backstage.io/techdocs-ref': CATALOG_FILTER_EXISTS,
  },
];

type ContextType = {
  startDate?: Date;
  endDate?: Date;
  selected?: string;
  loading: boolean;
  error?: Error;
  errorAnalytics?: Error;
  analyticsValue?: AnalyticsData;
  entities: Entity[];
  searchParams: URLSearchParams;
  setSearchParams: SetURLSearchParams;
};

interface ProviderProps {
  children?: React.ReactNode;
}

const DocsAnalyticsContext = createContext<ContextType>({
  loading: false,
  entities: [],
  searchParams: new URLSearchParams(),
  setSearchParams: () => {},
});

export const DocsAnalyticsProvider = ({ children }: ProviderProps) => {
  const catalogApi = useApi(catalogApiRef);
  const analyticsApi = useApi(AnalyticsApiApiRef);
  const [searchParams, setSearchParams] = useSearchParams();
  const { search } = useLocation();

  const selected = searchParams.get('selected') ?? undefined;

  const startDate = useMemo(() => {
    const startDateStr = searchParams.get('startDate');
    return startDateStr ? new Date(startDateStr) : undefined;
  }, [searchParams]);

  const endDate = useMemo(() => {
    const endDateStr = searchParams.get('endDate');
    return endDateStr ? new Date(endDateStr) : undefined;
  }, [searchParams]);

  const {
    loading: loadingDocs,
    error: docsError,
    value: entities,
  } = useAsync(async () => {
    const { items } = await catalogApi.getEntities({ filter });
    return items;
  }, []);

  const fetchAnalytics = useCallback(async () => {
    if (!selected) return undefined;
    return await analyticsApi.fetchDocsAnalytics({
      entityRef: selected,
      endTime: endDate ? endDate.toISOString() : undefined,
      startTime: startDate ? startDate.toISOString() : undefined,
    });
  }, [analyticsApi, selected, startDate, endDate]);

  const {
    loading: loadingAnalyticsData,
    error: errorAnalyticsData,
    value: analyticsValueData,
  } = useAsync(fetchAnalytics, [search]);

  useEffect(() => {
    if (!loadingDocs && entities && entities.length > 0 && !selected) {
      const updatedSearchParams = new URLSearchParams(searchParams);
      const initialSelected = stringifyEntityRef(entities[0]);
      updatedSearchParams.set('selected', initialSelected);
      setSearchParams(updatedSearchParams);
    }
  }, [loadingDocs, entities, selected, searchParams, setSearchParams]);

  return (
    <DocsAnalyticsContext.Provider
      value={{
        startDate,
        endDate,
        selected,
        loading: loadingDocs || loadingAnalyticsData,
        error: docsError,
        errorAnalytics: errorAnalyticsData,
        analyticsValue: analyticsValueData,
        entities: entities ?? [],
        searchParams,
        setSearchParams,
      }}
    >
      {children}
    </DocsAnalyticsContext.Provider>
  );
};

export const useDocsAnalytics = () => useContext(DocsAnalyticsContext);
