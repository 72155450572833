import { semantic as s } from '../../design-tokens';

export const getTheme = (theme: 'light' | 'dark') => {
  const semantic = s[theme];
  const codeBlockTheme: { [key: string]: React.CSSProperties } = {
    hljs: {
      display: 'block',
      overflowX: 'auto',
      padding: '0.5em',
      color: semantic.text.primary,
      background: 'none',
    },
    'hljs-comment': {
      color: semantic.text.code.comment,
    },
    'hljs-quote': {
      color: semantic.text.code.comment,
      fontStyle: 'italic',
    },
    'hljs-doctag': {
      color: semantic.text.code.generic,
    },
    'hljs-keyword': {
      color: semantic.text.code.keyword,
    },
    'hljs-formula': {
      color: semantic.text.code.keyword,
    },
    'hljs-section': {
      color: 'pink',
    },
    'hljs-name': {
      color: 'pink',
    },
    'hljs-selector-tag': {
      color: 'pink',
    },
    'hljs-deletion': {
      color: 'pink',
    },
    'hljs-subst': {
      color: 'pink',
    },
    'hljs-literal': {
      color: semantic.text.code.number,
    },
    'hljs-string': {
      color: semantic.text.code.string,
    },
    'hljs-regexp': {
      color: semantic.text.code.string,
    },
    'hljs-addition': {
      color: semantic.text.code.string,
    },
    'hljs-attribute': {
      color: semantic.text.code.string,
    },
    'hljs-meta-string': {
      color: semantic.text.code.string,
    },
    'hljs-built_in': {
      color: semantic.text.code.function,
    },
    'hljs-class .hljs-title': {
      color: semantic.text.code.function,
    },
    'hljs-attr': {
      color: semantic.text.code.string,
    },
    'hljs-variable': {
      color: semantic.text.code.string,
    },
    'hljs-template-variable': {
      color: semantic.text.code.string,
    },
    'hljs-type': {
      color: semantic.text.code.string,
    },
    'hljs-selector-class': {
      color: semantic.text.code.string,
    },
    'hljs-selector-attr': {
      color: semantic.text.code.string,
    },
    'hljs-selector-pseudo': {
      color: semantic.text.code.string,
    },
    'hljs-number': {
      color: semantic.text.code.number,
    },
    'hljs-symbol': {
      color: semantic.text.code.punctuation,
    },
    'hljs-bullet': {
      color: semantic.text.code.punctuation,
    },
    'hljs-link': {
      color: semantic.text.code.punctuation,
      textDecoration: 'underline',
    },
    'hljs-meta': {
      color: semantic.text.code.punctuation,
    },
    'hljs-selector-id': {
      color: semantic.text.code.punctuation,
    },
    'hljs-title': {
      color: semantic.text.code.punctuation,
    },
    'hljs-emphasis': {
      fontStyle: 'italic',
    },
    'hljs-strong': {
      fontWeight: 'bold',
    },
  };
  return { codeBlockTheme };
};
