import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

interface ConfirmationPopoverProps {
  open: boolean;
  anchorEl: HTMLElement | null;
  message: string;
  onConfirm: () => void;
  onCancel: () => void;
}

function ConfirmationPopover({
  anchorEl,
  open,
  message,
  onConfirm,
  onCancel,
}: ConfirmationPopoverProps) {
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onCancel}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      <Typography
        sx={{
          p: 2,
          maxWidth: 400,
        }}
      >
        {message}
      </Typography>
      <Box display="flex" justifyContent="space-between" p={2}>
        <Button variant="contained" color="primary" onClick={onConfirm}>
          Confirm
        </Button>
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
      </Box>
    </Popover>
  );
}

export default ConfirmationPopover;
