import React, { useState, SyntheticEvent } from 'react';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import {
  Button,
  useMultiEventLogger,
} from '@lego/plugin-baseplate-core-components';
import { Share } from '../../assets/svg';

type ShareButtonProps = {
  url: any;
  name: string;
  btnStyle: any;
  iconStyle: any;
  height: string;
  icon: string;
  trackingAttributes?: {
    key: string;
    subject: string;
    action?: string;
    otherAttributes: Record<string, any> | null;
  };
};

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#00af4d',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#00af4d',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#00af4d',
    color: 'theme.palette.common.white',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

function ShareButton({
  url,
  name,
  btnStyle,
  iconStyle,
  trackingAttributes,
}: ShareButtonProps) {
  const [copiedButtonId, setCopiedButtonId] = useState('');
  const { logMultipleCustomEvents } = useMultiEventLogger();

  const handleShareButton = (e: SyntheticEvent) => {
    e.stopPropagation();
    void navigator.clipboard.writeText(url);
    setCopiedButtonId(name);
    setTimeout(() => setCopiedButtonId(''), 2000);
    logMultipleCustomEvents(
      trackingAttributes?.key ?? 'Share',
      trackingAttributes?.action ?? 'click',
      trackingAttributes?.subject ?? 'Share',
      trackingAttributes?.otherAttributes ?? null,
    );
  };

  return (
    <>
      <CustomTooltip
        open={copiedButtonId === name}
        title="Link copied"
        placement="top"
        arrow
      >
        <Button
          key={name}
          id={name}
          variant="secondary"
          style={btnStyle}
          onClick={e => handleShareButton(e)}
        >
          <div className={iconStyle}>
            <Share />
          </div>
        </Button>
      </CustomTooltip>
    </>
  );
}

export default ShareButton;
