import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function LocationDot(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M29 16.023c0-4.937-4.063-9-9-9-5 0-9 4.063-9 9 0 .813.25 2 .938 3.625.624 1.5 1.562 3.25 2.624 5 1.813 2.875 3.876 5.625 5.438 7.625 1.5-2 3.563-4.75 5.375-7.625 1.063-1.75 2-3.5 2.625-5 .688-1.625 1-2.812 1-3.625Zm3 0c0 5.5-7.313 15.188-10.563 19.25a1.886 1.886 0 0 1-2.937 0C15.312 31.211 8 21.523 8 16.023c0-6.625 5.375-12 12-12s12 5.375 12 12Zm-10 0c0-1.062-.938-2-2-2-1.125 0-2 .938-2 2 0 1.125.875 2 2 2 1.063 0 2-.875 2-2Zm-7 0c0-1.75.938-3.375 2.5-4.312 1.5-.875 3.438-.875 5 0 1.5.937 2.5 2.562 2.5 4.312 0 1.813-1 3.438-2.5 4.375-1.563.875-3.5.875-5 0-1.563-.937-2.5-2.562-2.5-4.375Z" />
    </SvgIcon>
  );
}
