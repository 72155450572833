import { Typography } from '@material-ui/core';
import React from 'react';
import { baseplateStyles } from '../../utils';

const useStyles = baseplateStyles(theme => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '1rem',
    marginBottom: '2rem',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  gridItem: {
    width: '100%', // Default width for small screens
    [theme.breakpoints.up('md')]: {
      width: '40%', // 40% width for screens larger than medium
    },
  },
  description: {
    maxWidth: '60ch',
  },
  button: {
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(2),
    },
  },
  actions: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '1rem',
  },
}));

interface Props {
  title: string;
  description: string;
  actions?: React.ReactNode[];
}

export const OverviewPageHeader = ({ title, actions, description }: Props) => {
  const classes = useStyles();

  return (
    <header className={classes.header}>
      <div className={classes.gridItem}>
        <Typography variant="h2">{title}</Typography>
        <Typography variant="body1" className={classes.description}>
          {description}
        </Typography>
      </div>
      <div className={classes.actions}>{actions}</div>
    </header>
  );
};
