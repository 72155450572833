import { BaseplateTheme } from '../../../../style/types';
import {
  breakpoint,
  sidebarWidth,
  tableOfContentsWidth,
} from '../../techdocsStyleVariables';

export const navigationStyles = (theme: BaseplateTheme) => ({
  '& .md-sidebar': {
    width: '100%',
    overflowY: 'auto',
    height: `calc(100vh - ${theme.primitive.dimensions.nav.height}) !important`,
    '& .md-sidebar__scrollwrap': {
      width: '100%',
      overflowY: 'visible',
    },
    '& .md-sidebar__inner': {
      width: '100%',
      padding: 0,
    },
  },
  '& .md-nav__title': {
    background: 'none',
    boxShadow: 'none',
    position: 'static',
  },
  '& .md-sidebar--primary': {
    width: sidebarWidth,
    left: '0',
    zIndex: '1000',
    paddingLeft: `calc(${theme.primitive.dimensions.page.horizontalPadding} - (.6rem * 2))`,
    [`@media screen and (max-width: ${breakpoint})`]: {
      transform: 'translateX(-300px)',
    },
  },
  '& .md-nav--primary .md-nav__title': {
    display: 'none',
    [`@media screen and (max-width: 76em)`]: {
      display: 'flex',
    },
  },
  // The ul element containing the li elements in each level

  '& .md-nav__list': {
    borderLeft: `1px solid ${theme.semantic.border.default}`,
    marginLeft: '0.75rem',
  },
  '& .md-nav--primary > .md-nav__list': {
    border: 0,
    margin: 0,
  },
  '& .md-nav__item': {
    paddingLeft: '0.5rem',
    // borderLeft: `1px solid ${theme.semantic.border.default}`,
    '& .md-nav__link': {
      fontSize: '1rem',
      padding: '0.25rem 0.75rem',
      color: theme.semantic.text.primary,
      borderRadius: theme.primitive.borderRadius.default,
      textDecoration: 'none',
      '&:hover': {
        backgroundColor: theme.semantic.background.neutral[3],
      },
    },
    '& .md-nav__link--active': {
      backgroundColor: theme.semantic.background.neutral[3],
      fontWeight: 'bold',
    },
  },
  '& .md-sidebar--secondary': {
    paddingTop: '2rem',
    paddingRight: theme.primitive.dimensions.page.horizontalPadding,
    width: tableOfContentsWidth,
    right: '0',
  },
});
