import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ListMinusCircle(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M6 7h3c.813 0 1.5.688 1.5 1.5v3c0 .875-.688 1.5-1.5 1.5H6c-.875 0-1.5-.625-1.5-1.5v-3C4.5 7.687 5.125 7 6 7Zm9 1.5h19c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5H15c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5Zm11.565 10H15c-.875 0-1.5.688-1.5 1.5 0 .875.625 1.5 1.5 1.5h6.527a11.909 11.909 0 0 1 5.038-3Zm-8.469 13a11.933 11.933 0 0 1-.003-3H15c-.875 0-1.5.688-1.5 1.5 0 .875.625 1.5 1.5 1.5h3.096ZM6 17c-.875 0-1.5.688-1.5 1.5v3c0 .875.625 1.5 1.5 1.5h3c.813 0 1.5-.625 1.5-1.5v-3c0-.813-.688-1.5-1.5-1.5H6Zm0 10h3c.813 0 1.5.688 1.5 1.5v3c0 .875-.688 1.5-1.5 1.5H6c-.875 0-1.5-.625-1.5-1.5v-3c0-.813.625-1.5 1.5-1.5Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.018 21c-3.235 0-6.188 1.688-7.805 4.5-1.617 2.777-1.617 6.188 0 9a9.02 9.02 0 0 0 7.805 4.5c3.199 0 6.152-1.723 7.77-4.5 1.616-2.813 1.616-6.223 0-9a8.933 8.933 0 0 0-7.77-4.5Zm5.125 9c0-.71-.576-1.286-1.286-1.286h-7.714a1.286 1.286 0 1 0 0 2.572h7.714c.71 0 1.286-.576 1.286-1.286Z"
      />
    </SvgIcon>
  );
}
