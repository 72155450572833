import React from 'react';
import { toolCategorySlugify } from './tool-category-slugify';
import { Icon } from '@lego/plugin-baseplate-core-components';

export type ToolCategoryType = {
  title: string;
  description: string;
  name: string;
  icon: React.ReactNode;
  category: 'lego' | 'plan' | 'code' | 'build' | 'test' | 'operate' | 'monitor';
};

const list: Omit<ToolCategoryType, 'name'>[] = [
  // LEGO
  {
    title: 'Internal tools',
    description: 'Tools developed by the LEGO Group.',
    category: 'lego',
    icon: <Icon icon="lego-gift" />,
  },
  // Plan
  {
    title: 'Planning / project management',
    description:
      'Planning and project management tools assist in organizing and tracking projects, tasks, and resources, enhancing collaboration and productivity.',
    category: 'plan',
    icon: <Icon icon="chart-gantt" />,
  },
  {
    title: 'Collaboration',
    description:
      'Collaboration tools enable teams to communicate, share files, and work together effectively, fostering collaboration and knowledge sharing.',
    category: 'plan',
    icon: <Icon icon="messages" />,
  },
  // Code
  {
    title: 'Source control management',
    description:
      'Source control management tools provide version control and collaboration capabilities for software development projects, ensuring code integrity and team coordination.',
    category: 'code',
    icon: <Icon icon="code-branch" />,
  },
  {
    title: 'AI',
    description:
      'AI tools leverage machine learning and artificial intelligence techniques to automate tasks, make predictions, and gain insights from data.',
    category: 'code',
    icon: <Icon icon="sparkles" />,
  },
  {
    title: 'Infrastructure-as-code',
    description:
      'In the realm of modern IT operations, infrastructure-as-code (IaC) tools provide the means to manage, orchestrate, and provision IT infrastructures programmatically, ensuring consistent and reliable environments. These tools primarily utilize code scripts, configuration files, and templates to create a replicable and scalable infrastructure, effectively minimizing human errors and enhancing operational efficiency.',
    category: 'code',
    icon: <Icon icon="gears" />,
  },
  // Build
  {
    title: 'Build tools',
    description:
      'Build tools automate the process of compiling source code into executable programs or libraries, facilitating software development and release processes.',
    category: 'build',
    icon: <Icon icon="gear-code" />,
  },
  {
    title: 'Design',
    description:
      'These tools help designers develop new solutions or for developers to implement designs that align with the LEGO brand.',
    category: 'build',
    icon: <Icon icon="paintbrush-fine" />,
  },
  {
    title: 'Artifacts',
    description:
      'Artifacts tools manage and store software artifacts, such as binaries, libraries, and documentation, ensuring their availability and accessibility.',
    category: 'build',
    icon: <Icon icon="square-code" />,
  },
  {
    title: 'Run',
    description:
      'Run tools facilitate the execution and management of applications and services, ensuring smooth operation and optimal performance.',
    category: 'build',
    icon: <Icon icon="play" />,
  },
  {
    title: 'Build, test and deploy',
    description:
      'Build, test, and deploy tools streamline the software development lifecycle, enabling efficient code compilation, testing, and deployment processes.',
    category: 'build',
    icon: <Icon icon="infinity" />,
  },
  // Test
  {
    title: 'Testing tools',
    description:
      'Testing tools automate the testing process, enabling organizations to validate software functionality, performance, and reliability.',
    category: 'test',
    icon: <Icon icon="ban-bug" />,
  },
  {
    title: 'Cyber security posture',
    description:
      "Cyber security posture tools assess and improve an organization's security posture, identifying vulnerabilities and implementing security measures.",
    category: 'test',
    icon: <Icon icon="shield-check" />,
  },
  {
    title: 'Legal compliance',
    description:
      'Legal compliance tools help organizations adhere to regulatory requirements and industry standards, ensuring data privacy and security.',
    category: 'test',
    icon: <Icon icon="scale-balanced" />,
  },
  // Operate
  {
    title: 'Cost optimization',
    description:
      'Cost optimization tools help analyze and optimize resource usage, enabling organizations to reduce expenses and maximize efficiency.',
    category: 'operate',
    icon: <Icon icon="money-bill-transfer" />,
  },
  {
    title: 'Service management',
    description:
      'Service management tools facilitate the management and delivery of IT services, ensuring efficient service delivery and customer satisfaction.',
    category: 'operate',
    icon: <Icon icon="handshake-simple" />,
  },
  {
    title: 'Data platform',
    description:
      'Data platform tools provide the infrastructure and services to store, process, and analyze data, enabling organizations to derive insights and make data-driven decisions.',
    category: 'operate',
    icon: <Icon icon="file-database" />,
  },
  // Monitor
  {
    title: 'Observability',
    description:
      'Observability tools provide insights into the behavior and performance of systems, allowing for effective monitoring and troubleshooting.',
    category: 'monitor',
    icon: <Icon icon="eye" />,
  },
  {
    title: 'Performance',
    description:
      'Performance tools measure and optimize the speed and efficiency of software applications, ensuring optimal user experience.',
    category: 'monitor',
    icon: <Icon icon="gauge" />,
  },
  {
    title: 'Alerting and incident management',
    description:
      'Alerting and incident management tools enable organizations to detect and respond to incidents and outages, minimizing downtime and ensuring business continuity.',
    category: 'monitor',
    icon: <Icon icon="light-emergency" />,
  },
  {
    title: 'Data cyber security posture and analytics',
    description:
      'Data cyber security posture and analytics tools help organizations assess and improve their data security posture, detect and respond to cyber threats, and gain insights from security data.',
    category: 'monitor',
    icon: <Icon icon="lock" />,
  },
];

export const toolCategories: ToolCategoryType[] = list.map(category => ({
  ...category,
  name: toolCategorySlugify(category.title),
}));

export const groupedToolCategories = Object.values(
  toolCategories.reduce((acc: { [key: string]: any }, item) => {
    if (acc[item.category]) {
      acc[item.category].categories.push(item);
    } else {
      acc[item.category] = {
        title: item.category,
        categories: [item],
      };
    }
    return acc;
  }, {}),
);
