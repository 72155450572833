import { createRouteRef, createSubRouteRef } from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
  id: 'mssql',
});

export const mssqlRouteRef = createSubRouteRef({
  id: 'mssql1',
  path: '/mssql1',
  parent: rootRouteRef,
});

export const rootCreateMSSQLPageRef = createRouteRef({
  id: 'Create MsSQL',
});

export const rootMSSQLDetailPageRef = createRouteRef({
  id: 'MSSQL Detail',
});
