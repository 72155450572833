import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Key(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M17 15c0 .688.063 1.313.188 1.938.125.5 0 1-.375 1.375L7 28.124V33h4v-2.5c0-.813.625-1.5 1.5-1.5H15v-2.5c0-.813.625-1.5 1.5-1.5h3.375l1.813-1.813a1.41 1.41 0 0 1 1.375-.375c.625.125 1.25.188 1.937.188 4.375 0 8-3.563 8-8 0-4.375-3.625-8-8-8-4.438 0-8 3.625-8 8Zm8-11c6.063 0 11 4.938 11 11 0 6.125-4.938 11-11 11-.625 0-1.188 0-1.75-.125l-1.688 1.688A1.464 1.464 0 0 1 20.5 28H18v2.5c0 .875-.688 1.5-1.5 1.5H14v2.5c0 .875-.688 1.5-1.5 1.5h-7c-.875 0-1.5-.625-1.5-1.5v-7c0-.375.125-.75.438-1.063l9.687-9.687C14 16.187 14 15.625 14 15c0-6.063 4.875-11 11-11Zm2 11c-1.125 0-2-.875-2-2 0-1.063.875-2 2-2 1.063 0 2 .938 2 2 0 1.125-.938 2-2 2Z" />
    </SvgIcon>
  );
}
