import {
  ConfigApi,
  DiscoveryApi,
  FetchApi,
  OAuthApi,
} from '@backstage/core-plugin-api';

const lndBaseUrl = 'learning-and-development';
export class ApiUtils {
  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi: FetchApi;
  private readonly oAuthApi: OAuthApi;
  private readonly configApi: ConfigApi;

  public constructor(
    discoveryApi: DiscoveryApi,
    fetchApi: FetchApi,
    oAuthApi: OAuthApi,
    configApi: ConfigApi,
  ) {
    this.discoveryApi = discoveryApi;
    this.fetchApi = fetchApi;
    this.oAuthApi = oAuthApi;
    this.configApi = configApi;
  }

  isLocalEnvironment = () => {
    return (
      this.configApi.getString('environmentStage').toLowerCase() === 'local'
    );
  };

  getMsAccessTokenHeader = async () => {
    const token = await this.getAzureAccessToken();
    return { 'x-ms-token': token };
  };

  private getAzureAccessToken = async (scope?: string) => {
    try {
      const token = await this.oAuthApi.getAccessToken(scope ?? 'User.Read');
      return token;
    } catch (error) {
      return '';
    }
  };

  public async get<T>(
    path: string,
    customHeaders?: { [key: string]: any },
  ): Promise<T> {
    const baseUrl = `${await this.discoveryApi.getBaseUrl(lndBaseUrl)}/`;
    const url = `${baseUrl}${path}`;

    const response = await this.fetchApi.fetch(url.toString(), {
      headers: customHeaders,
    });

    if (!response.ok) {
      throw await response.json();
    }

    return response.json() as Promise<T>;
  }

  public async post<T>(
    path: string,
    requestBody?: any,
    customHeaders?: { [key: string]: any },
  ): Promise<T> {
    const baseUrl = `${await this.discoveryApi.getBaseUrl(lndBaseUrl)}/`;
    const url = new URL(path, baseUrl);

    const response = await this.fetchApi.fetch(url.toString(), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...customHeaders,
      },
      body: JSON.stringify(requestBody),
    });
    if (!response.ok) {
      throw await response.json();
    }

    return (this.hasBody(response) ? response.json() : response) as Promise<T>;
  }

  public async put<T>(
    path: string,
    requestBody?: any,
    customHeaders?: { [key: string]: any },
  ): Promise<T> {
    const baseUrl = `${await this.discoveryApi.getBaseUrl(lndBaseUrl)}/`;
    const url = new URL(path, baseUrl);

    const response = await this.fetchApi.fetch(url.toString(), {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', ...customHeaders },
      body: JSON.stringify(requestBody),
    });
    if (!response.ok) {
      throw await response.json();
    }

    return (this.hasBody(response) ? response.json() : response) as Promise<T>;
  }

  public async patch<T>(
    path: string,
    requestBody?: any,
    customHeaders?: { [key: string]: any },
  ): Promise<T> {
    const baseUrl = `${await this.discoveryApi.getBaseUrl(
      'learning-and-development',
    )}/`;
    const url = new URL(path, baseUrl);

    const response = await this.fetchApi.fetch(url.toString(), {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        ...customHeaders,
      },
      body: JSON.stringify(requestBody),
    });
    if (!response.ok) {
      throw await response.json();
    }

    return (this.hasBody(response) ? response.json() : response) as Promise<T>;
  }

  public async delete<T>(
    path: string,
    customHeaders?: { [key: string]: any },
  ): Promise<T> {
    const baseUrl = `${await this.discoveryApi.getBaseUrl(
      'learning-and-development',
    )}/`;
    const url = new URL(path, baseUrl);

    const response = await this.fetchApi.fetch(url.toString(), {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        ...customHeaders,
      },
    });
    if (!response.ok) {
      throw await response.json();
    }

    return (this.hasBody(response) ? response.json() : response) as Promise<T>;
  }

  public async uploadFormData<T>(
    path: string,
    method: string,
    requestBody?: any,
    customHeaders?: { [key: string]: any },
  ): Promise<T> {
    const baseUrl = `${await this.discoveryApi.getBaseUrl(
      'learning-and-development',
    )}/`;
    const url = new URL(path, baseUrl);

    const response = await this.fetchApi.fetch(url.toString(), {
      method: method,
      body: requestBody,
      headers: customHeaders,
    });
    if (!response.ok) {
      throw await response.json();
    }

    return (this.hasBody(response) ? response.json() : response) as Promise<T>;
  }

  private hasBody(response: Response): boolean {
    const contentLength = response.headers.get('Content-Length');
    return contentLength !== null && contentLength !== '0';
  }
}
