import { Modal, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { BaseplateTheme } from '../../style/types';
import { LangChainChat } from '@lego/plugin-baseplate-ai';

const useStyles = makeStyles<BaseplateTheme>(theme => ({
  wrapper: {
    position: 'absolute',
    width: '80%',
    maxHeight: '100%',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: '50%',
    left: '50%',
    transform: `translate(-${50}%, -${50}%)`,
    overflow: 'auto',
  },
}));

export const AiModal = () => {
  const classes = useStyles();
  const [aiOpened, setAiOpened] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event: any) => {
      if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 'j') {
        setAiOpened(prev => !prev);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <Modal
      open={aiOpened}
      onClose={() => {
        setAiOpened(false);
      }}
    >
      <div className={classes.wrapper}>
        <LangChainChat verbose={false} userHint="Ask me anything..." />
      </div>
    </Modal>
  );
};
