import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ArrowsUpDown(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="m21.086 4.414 6 6c.562.625.562 1.563 0 2.125-.625.625-1.563.625-2.125 0l-3.438-3.437v21.75l3.438-3.438c.562-.562 1.5-.562 2.125 0 .562.625.562 1.563 0 2.125l-6 6c-.625.625-1.563.625-2.125 0l-6-6c-.625-.562-.625-1.5 0-2.125.562-.562 1.5-.562 2.125 0l3.375 3.5V9.102l-3.438 3.437c-.562.625-1.5.625-2.062 0-.625-.562-.625-1.5 0-2.125l6-6c.562-.562 1.5-.562 2.125 0Z" />
    </SvgIcon>
  );
}
