import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function PaperPlane(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="m5.023 20.293 28-16c.625-.375 1.5-.375 2.125.063.625.437.938 1.187.813 1.937l-4 26c-.188 1.25-1.563 2.063-2.75 1.563l-7.75-3.25-3.875 4.687c-.375.438-.938.688-1.563.688-1.125 0-2-.875-2-2v-6.5l-8.812-3.625c-1.5-.625-1.625-2.75-.188-3.563Zm3.25 1.563 7.688 3.187-.063-.062L29.586 9.668 8.273 21.856Zm20.938 8.75 2.937-19.313-13.375 14.938 10.438 4.375Z" />
    </SvgIcon>
  );
}
