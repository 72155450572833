import { EntityLayout, BreadcrumbLink } from '@lego/plugin-baseplate-catalog';
import { Grid } from '@material-ui/core';
import React, { useMemo } from 'react';

import { entityWarningContent } from '../ContentSections';
import {
  entityRouteRef,
  useEntity,
  useRelatedEntities,
} from '@backstage/plugin-catalog-react';
import { BaseplateAboutContent } from '../CustomComponents';
import {
  RELATION_CHILD_OF,
  getCompoundEntityRef,
} from '@backstage/catalog-model';
import { useRouteRef } from '@backstage/core-plugin-api';

const GroupPage = () => {
  const { entity } = useEntity();
  const getRouteForEntity = useRouteRef(entityRouteRef);

  const isSubdomain = entity.spec?.type === 'sub-domain';
  const domain = (useRelatedEntities(entity, {
    type: RELATION_CHILD_OF,
  }).entities ?? [])[0];

  const breadcrumbLinks: BreadcrumbLink[] | undefined = useMemo(() => {
    if (!isSubdomain) return [{ label: 'Domain' }];
    if (isSubdomain && !domain) return undefined;
    return [
      {
        label: domain.metadata.title || domain.metadata.name,
        href: getRouteForEntity(getCompoundEntityRef(domain)),
      },
    ];
  }, [isSubdomain, domain, getRouteForEntity]);

  return (
    <EntityLayout breadcrumbLinks={breadcrumbLinks}>
      <EntityLayout.Route path="/" title="Overview">
        <Grid container spacing={4}>
          {entityWarningContent}
          <Grid item xs={12} md={12}>
            <BaseplateAboutContent entity={entity} />
          </Grid>
        </Grid>
      </EntityLayout.Route>
    </EntityLayout>
  );
};

export default GroupPage;
