import React from 'react';
import { LicenceType } from '@lego/plugin-baseplate-common';
import { Chip } from '../../../chip';

export const LicenceChip = ({ licence }: { licence?: LicenceType }) => {
  if (!licence) {
    return <Chip label="Missing licence" color="red" />;
  }
  switch (licence.type) {
    case 'paid':
      return <Chip label="Paid" color="green" />;
    case 'open-source':
      return <Chip label="Open source" color="blue" />;
    case 'internal':
      return <Chip label="Internal" color="purple" />;
    default:
      return null;
  }
};
