/*
 * Copyright 2022 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { toast } from 'react-toastify';
import {
  ConfigApi,
  DiscoveryApi,
  FetchApi,
  OAuthApi,
} from '@backstage/core-plugin-api';
import { EventsApi } from './EventsApi';
import {
  CreateEventRequest,
  Event,
  EventLocation,
  EventParticipant,
  PaginatedEventResponse,
  UpdateEventRequest,
} from '@lego/plugin-baseplate-learning-and-development-common';
import { ApiUtils } from './APIUtils';
import { encodeQueryStrings } from '../utils/utilFunc';

export class EventsClient implements EventsApi {
  private readonly apiUtils: ApiUtils;

  public constructor(options: {
    discoveryApi: DiscoveryApi;
    fetchApi: FetchApi;
    oAuthApi: OAuthApi;
    configApi: ConfigApi;
  }) {
    this.apiUtils = new ApiUtils(
      options.discoveryApi,
      options.fetchApi,
      options.oAuthApi,
      options.configApi,
    );
  }

  async getLocations(queryParams?: any): Promise<EventLocation[]> {
    const customHeaders = await this.apiUtils.getMsAccessTokenHeader();
    try {
      const queryString = encodeQueryStrings(queryParams);
      const events = await this.apiUtils.get<EventLocation[]>(
        `event-locations${queryString}`,
        customHeaders,
      );
      return events;
    } catch (error: any) {
      toast.error(
        `Failed to fetch locations ${error.message}: ${error as string}`,
      );
    }
    return [];
  }

  public async getEventById(eventId: string | number): Promise<Event> {
    return await this.apiUtils.get<Event>(`events/${eventId}`);
  }

  public async updateParticipation(payload: any): Promise<any> {
    const customHeaders = await this.apiUtils.getMsAccessTokenHeader();

    return await this.apiUtils.put<any>(
      `events/${payload.eventId}/participants`,
      payload,
      customHeaders,
    );
  }

  public async manageEvent(
    event: CreateEventRequest | UpdateEventRequest,
    isNewEvent: boolean,
    id: string,
  ): Promise<any> {
    const customHeaders = await this.apiUtils.getMsAccessTokenHeader();

    return isNewEvent
      ? await this.apiUtils.post<any>('events', event, customHeaders)
      : await this.apiUtils.patch<any>(`events/${id}`, event, customHeaders);
  }

  public async uploadBanner(eventId: string | number, img: any): Promise<any> {
    const form = new FormData();
    if (img !== null) {
      form.append('event-banner', img, img.name);
    }
    return await this.apiUtils.uploadFormData<any>(
      `events/${eventId}/banner`,
      'PUT',
      form,
    );
  }

  public async removeBanner(eventId: string): Promise<any> {
    return await this.apiUtils.delete<any>(`events/${eventId}/banner`);
  }

  public async getEvents(
    queryParams?: Record<string, string>,
  ): Promise<PaginatedEventResponse> {
    const queryString = encodeQueryStrings(queryParams);
    const events = await this.apiUtils.get<PaginatedEventResponse>(
      `events${queryString}`,
    );
    return events;
  }

  public async getEventParticipants(
    eventId: string,
  ): Promise<EventParticipant[]> {
    return await this.apiUtils.get<EventParticipant[]>(
      `events/${eventId}/participants`,
    );
  }

  async getLocationsAvailabilities(
    queryParams?: any,
  ): Promise<EventLocation[]> {
    const customHeaders = await this.apiUtils.getMsAccessTokenHeader();

    const queryString = encodeQueryStrings(queryParams);
    const events = await this.apiUtils.get<EventLocation[]>(
      `event-locations-availabilities${queryString}`,
      customHeaders,
    );
    return events;
  }
}
