import { useState, useEffect, useCallback } from 'react';
import { useApi, errorApiRef } from '@backstage/core-plugin-api';
import { discoveryApiRef, fetchApiRef } from '@backstage/core-plugin-api';
import { DevModePanelFieldType } from '../types';

export const useEntityStatus = () => {
  const [pendingCount, setPendingCount] = useState<number | null>(null);
  const [failingCount, setFailingCount] = useState<number | null>(null);
  const [existingCount, setExistingCount] = useState<number | null>(null);
  const [status, setStatus] = useState<{
    pending: 'loading' | 'error' | undefined;
    failing: 'loading' | 'error' | undefined;
    existing: 'loading' | 'error' | undefined;
  }>({
    pending: undefined,
    failing: undefined,
    existing: undefined,
  });
  const [isTimerActive, setIsTimerActive] = useState(false);

  const errorApi = useApi(errorApiRef);
  const discoveryApi = useApi(discoveryApiRef);
  const fetchApi = useApi(fetchApiRef);

  const fetchCount = useCallback(
    async (
      endpointSuffix: string,
      setter: (count: number | null) => void,
      statusKey: keyof typeof status,
    ) => {
      setStatus(prev => ({ ...prev, [statusKey]: 'loading' }));
      try {
        const baseUrl = await discoveryApi.getBaseUrl('catalog');
        const url = `${baseUrl}${endpointSuffix}`;
        const response = await fetchApi.fetch(url);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        if (data.entities && Array.isArray(data.entities)) {
          setter(data.entities.length);
        } else if (Array.isArray(data)) {
          setter(data.length);
        } else {
          setStatus(prev => ({ ...prev, [statusKey]: 'error' }));
          setter(null); // Error indicator
        }
      } catch (e) {
        setStatus(prev => ({ ...prev, [statusKey]: 'error' }));
        setter(null); // Error indicator
        if (e instanceof Error) {
          errorApi.post(e);
        }
      } finally {
        setStatus(prev => ({ ...prev, [statusKey]: undefined })); // Disable loading status
      }
    },
    [discoveryApi, fetchApi, setStatus, errorApi],
  );

  const performAllFetches = useCallback(async () => {
    await Promise.all([
      fetchCount('/entities/unprocessed/failed', setFailingCount, 'failing'),
      fetchCount('/entities/unprocessed/pending', setPendingCount, 'pending'),
      fetchCount('/entities', setExistingCount, 'existing'),
    ]);
  }, [fetchCount]);

  const toggleTimer = useCallback(() => {
    setIsTimerActive(prev => {
      if (!prev) {
        performAllFetches().catch(e => {
          if (e instanceof Error) {
            errorApi.post(e);
          }
        });
      }
      return !prev;
    });
  }, [performAllFetches, errorApi]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;
    if (isTimerActive) {
      intervalId = setInterval(() => {
        performAllFetches().catch(e => {
          if (e instanceof Error) {
            errorApi.post(e);
          }
        });
      }, 10000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isTimerActive, performAllFetches, errorApi]);

  const pendingEntitiesPath = '/admin/Unprocessed-entities';
  const failingEntitiesPath = '/admin/Unprocessed-entities';
  const catalogPath = '/catalog';

  const entitiesFields: DevModePanelFieldType[] = [
    {
      label: 'Pending entities',
      value: pendingCount ?? 'N/A', // Handle null value
      status: status.pending,
      href: pendingEntitiesPath,
    },
    {
      label: 'Failing entities',
      value: failingCount ?? 'N/A', // Handle null value
      status: status.failing,
      href: failingEntitiesPath,
    },
    {
      label: 'Existing entities',
      value: existingCount ?? 'N/A', // Handle null value
      status: status.existing,
      href: catalogPath,
    },
  ];

  return { entitiesFields, isTimerActive, toggleTimer };
};
