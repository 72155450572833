import React from 'react';
import { useFormStepStyles } from './FormStep.styles';
import { Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@mui/icons-material/Error';

interface Props {
  label: string;
  completed?: boolean;
  error?: boolean;
  active?: boolean;
  skipped?: boolean;
  disabled?: boolean;
  stepNumber: number;
  onClick?: () => void;
}

export const FormStepItem = ({
  label,
  completed,
  active,
  disabled,
  stepNumber,
  skipped,
  onClick,
  error,
}: Props) => {
  const classes = useFormStepStyles();
  const statusLabel = (() => {
    if (active) return 'In progress';
    if (completed) return 'Completed';
    if (skipped) return 'Skipped';
    if (error) return 'Failed';
    return 'Pending';
  })();
  return (
    <button
      type="button"
      onClick={onClick}
      disabled={disabled}
      className={[
        classes.formStep,
        active ? classes.active : '',
        completed ? classes.completed : '',
        disabled ? classes.disabled : '',
      ].join(' ')}
    >
      <div className={classes.shapes}>
        <div className={classes.indicator}>
          {completed && !active && <CheckIcon fontSize="small" />}
          {error && !active && <ErrorIcon fontSize="small" color="error" />}
        </div>
        <div className={classes.line} />
      </div>
      <div className={classes.textContainer}>
        {stepNumber && (
          <Typography variant="overline" color="secondary">
            Step {stepNumber}
          </Typography>
        )}
        <Typography variant="body1">{label}</Typography>
        <Typography variant="caption" className={classes.statusLabel}>
          {statusLabel}
        </Typography>
      </div>
    </button>
  );
};
