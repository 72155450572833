import { SidePanel } from '@lego/plugin-baseplate-core-components';
import { Employee } from '@lego/plugin-baseplate-people-to-product-common';
import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import EmployeeProductTable from '../EmployeeProductTable/EmployeeProductTable';

interface EmployeeProductSidePanelProps {
  employee: Employee;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

function EmployeeProductSidePanel({
  employee,
  isOpen,
  setIsOpen,
}: EmployeeProductSidePanelProps) {
  return (
    <SidePanel title={employee.fullName} isOpen={isOpen} setIsOpen={setIsOpen}>
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <Typography variant="h6">User actions</Typography>
          <ul>
            <Typography component="li">
              Click on the add button to add a product.
            </Typography>
            <Typography component="li">
              Click on the checkbox in the TCO column to change the total cost
              of ownership.
            </Typography>
            <Typography component="li">
              Click on the remove button to remove a product.
            </Typography>
          </ul>
        </Grid>
        <Grid item>
          <EmployeeProductTable employeeId={employee.id} />
        </Grid>
      </Grid>
    </SidePanel>
  );
}

export default EmployeeProductSidePanel;
