import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

export default function LocationLanguage(props: any) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <g transform="translate(8 8)">
        <path d="M6.54088 16.9244C7.17112 16.0889 7.95807 14.996 8.72117 13.8185V16.7517C8.19995 17.4779 7.72301 18.1125 7.34827 18.5955C6.92924 19.1348 6.15252 19.1348 5.73349 18.5955C3.98585 16.3357 0 10.8501 0 7.76884C0 4.03188 2.92977 1 6.54088 1C9.8011 1 12.5026 3.46781 13 6.69358C12.4243 6.79229 11.8894 7.02497 11.4295 7.35989C11.2285 4.74754 9.11635 2.69221 6.54088 2.69221C3.83255 2.69221 1.63522 4.96612 1.63522 7.76884C1.63522 8.20599 1.78852 8.88288 2.15645 9.78539C2.51415 10.6597 3.02175 11.6257 3.6043 12.6022C4.57521 14.231 5.69942 15.8033 6.54088 16.9244ZM7.63103 7.76884C7.63103 7.46964 7.51617 7.18269 7.31173 6.97112C7.10729 6.75956 6.83001 6.6407 6.54088 6.6407C6.25176 6.6407 5.97447 6.75956 5.77003 6.97112C5.56559 7.18269 5.45073 7.46964 5.45073 7.76884C5.45073 8.06804 5.56559 8.35499 5.77003 8.56655C5.97447 8.77812 6.25176 8.89698 6.54088 8.89698C6.83001 8.89698 7.10729 8.77812 7.31173 8.56655C7.51617 8.35499 7.63103 8.06804 7.63103 7.76884ZM3.81551 7.76884C3.81551 7.02084 4.10265 6.30347 4.61376 5.77455C5.12486 5.24563 5.81807 4.94849 6.54088 4.94849C7.26369 4.94849 7.9569 5.24563 8.46801 5.77455C8.97911 6.30347 9.26625 7.02084 9.26625 7.76884C9.26625 8.51684 8.97911 9.23421 8.46801 9.76313C7.9569 10.292 7.26369 10.5892 6.54088 10.5892C5.81807 10.5892 5.12486 10.292 4.61376 9.76313C4.10265 9.23421 3.81551 8.51684 3.81551 7.76884Z" />
        <path d="M17 19.6875C17.2023 19.6875 17.7383 19.4906 18.3016 18.3641C18.5422 17.8801 18.75 17.2922 18.9031 16.625H15.0969C15.25 17.2922 15.4578 17.8801 15.6984 18.3641C16.2617 19.4906 16.7977 19.6875 17 19.6875ZM14.8809 15.3125H19.1191C19.1629 14.8941 19.1875 14.4539 19.1875 14C19.1875 13.5461 19.1629 13.1059 19.1191 12.6875H14.8809C14.8371 13.1059 14.8125 13.5461 14.8125 14C14.8125 14.4539 14.8371 14.8941 14.8809 15.3125ZM15.0969 11.375H18.9031C18.75 10.7078 18.5422 10.1199 18.3016 9.63594C17.7383 8.50937 17.2023 8.3125 17 8.3125C16.7977 8.3125 16.2617 8.50937 15.6984 9.63594C15.4578 10.1199 15.25 10.7078 15.0969 11.375ZM20.4371 12.6875C20.4781 13.1113 20.4973 13.5516 20.4973 14C20.4973 14.4484 20.4754 14.8887 20.4371 15.3125H22.5344C22.6328 14.8914 22.6875 14.4512 22.6875 14C22.6875 13.5488 22.6355 13.1086 22.5344 12.6875H20.4398H20.4371ZM22.0449 11.375C21.4598 10.2512 20.5109 9.34883 19.3543 8.82109C19.7398 9.52109 20.0461 10.3934 20.2457 11.375H22.0477H22.0449ZM13.7516 11.375C13.9512 10.3934 14.2574 9.52383 14.643 8.82109C13.4863 9.34883 12.5375 10.2512 11.9523 11.375H13.7543H13.7516ZM11.4656 12.6875C11.3672 13.1086 11.3125 13.5488 11.3125 14C11.3125 14.4512 11.3645 14.8914 11.4656 15.3125H13.5629C13.5219 14.8887 13.5027 14.4484 13.5027 14C13.5027 13.5516 13.5246 13.1113 13.5629 12.6875H11.4656ZM19.3543 19.1789C20.5109 18.6512 21.4598 17.7488 22.0449 16.625H20.2457C20.0461 17.6066 19.7398 18.4762 19.3543 19.1789ZM14.6457 19.1789C14.2602 18.4789 13.9539 17.6066 13.7543 16.625H11.9523C12.5375 17.7488 13.4863 18.6512 14.643 19.1789H14.6457ZM17 21C15.1435 21 13.363 20.2625 12.0503 18.9497C10.7375 17.637 10 15.8565 10 14C10 12.1435 10.7375 10.363 12.0503 9.05025C13.363 7.7375 15.1435 7 17 7C18.8565 7 20.637 7.7375 21.9497 9.05025C23.2625 10.363 24 12.1435 24 14C24 15.8565 23.2625 17.637 21.9497 18.9497C20.637 20.2625 18.8565 21 17 21Z" />
      </g>
    </SvgIcon>
  );
}
