import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Bug(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M20 3.984c3.313 0 6 2.688 6 6v.25c0 1-.813 1.75-1.813 1.75H15.75c-1 0-1.75-.75-1.75-1.75v-.25c0-3.312 2.688-6 6-6ZM6.437 10.422a1.471 1.471 0 0 1 2.063 0l4.563 4.562a6.5 6.5 0 0 1 3.437-1h7c1.25 0 2.375.375 3.375 1l4.563-4.562a1.471 1.471 0 0 1 2.062 0c.625.625.625 1.562 0 2.125L29 17.109c.625 1 1 2.125 1 3.375h4.5c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5H30v.5c0 1.75-.438 3.313-1.188 4.75l4.75 4.688c.563.625.563 1.562 0 2.125-.624.625-1.562.625-2.124 0L27 31.109a9.877 9.877 0 0 1-7 2.875c-2.75 0-5.25-1.062-7.063-2.812l-4.374 4.375c-.626.625-1.563.625-2.126 0a1.369 1.369 0 0 1 0-2.063l4.688-4.75c-.75-1.437-1.125-3-1.125-4.75v-.5H5.5c-.875 0-1.5-.625-1.5-1.5 0-.812.625-1.5 1.5-1.5H10c0-1.25.313-2.375.938-3.375l-4.5-4.562a1.369 1.369 0 0 1 0-2.063v-.062ZM13 20.484v3.5c0 3.375 2.313 6.188 5.5 6.875v-9.375c0-.812.625-1.5 1.5-1.5.813 0 1.5.688 1.5 1.5v9.375a7.044 7.044 0 0 0 5.5-6.875v-3.5c0-1.875-1.625-3.5-3.5-3.5h-7c-1.938 0-3.5 1.625-3.5 3.5Z" />
    </SvgIcon>
  );
}
