export function util() {
  const isNullOrUndefined = (value: any) => {
    return (
      value === undefined || value === null || value.length <= 0 || value === ''
    );
  };

  return { isNullOrUndefined };
}

export function encodeQueryStrings(
  data?: Record<string, string | string>,
): string {
  if (data && typeof data !== 'string') {
    return Object.keys(data).reduce((queryString: string, key: string) => {
      if (data[key]) {
        const queryItem = `${key}=${data[key]}`;
        const query = !queryString
          ? `?${queryItem}`
          : `${queryString}&${queryItem}`;

        return query;
      }
      return queryString;
    }, '');
  }
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  return `?${data}` ?? '';
}

export function getLastPartUrl(url: string) {
  const parts = url.split('/');
  return parts.at(-1);
}
export function isBooked(active: any, pref: any): any {
  if (pref === 0) {
    return false;
  } else if (pref > active || active.length === 0) {
    return true;
  }
  return false;
}
export function getEventLocationName(name: any) {
  const locations: any = [];
  name.map((event: any) => {
    event.locations.map((item: any) => {
      if (item.locationType === 'physical') {
        locations.push(item);
      }
    });
  });
  return locations;
}

export const isValidUrl = (value: string) => {
  const urlPattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '([\\w.-]+)' + // host
      '(\\.\\w{2,})+' + // top-level domain and any sub-domains
      '(:\\d+)?' + // port
      '(\\/\\S*)?' + // path
      '(\\?\\S*)?' + // query string
      '(#\\S*)?' + // fragment
      '$',
    'i',
  );
  return !!urlPattern.test(value);
};

export const checkOption = (option: string) => {
  const pattern = /^Use "(.*)" as location$/;

  if (pattern.test(option)) {
    return true;
  }
  return false;
};

export const isValidEmail = (email: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};
