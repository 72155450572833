import React from 'react';
import { baseplateStyles } from '@lego/plugin-baseplate-core-components';

type Props = {
  sample: string;
  setPrompt: (sample: string) => void;
};

const useStyles = baseplateStyles(theme => ({
  button: {
    color: theme.component.button.outlined.text,
    backgroundColor: theme.component.button.outlined.background.default,
    padding: '0.5rem 1rem',
    borderRadius: theme.primitive.borderRadius.default,
    cursor: 'pointer',
    border: `1px solid ${theme.component.button.outlined.border.default}`,
    '&:hover': {
      backgroundColor: theme.component.button.outlined.background.hover,
      borderColor: theme.component.button.outlined.border.hover,
    },
  },
}));

export const SamplePrompt = ({ sample, setPrompt }: Props) => {
  const classes = useStyles();

  return (
    <button className={classes.button} onClick={() => setPrompt(sample)}>
      {sample}
    </button>
  );
};
