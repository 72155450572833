import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function AccountProfileColorFlat(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M10 33h20v6H10v-6Z" fill="#FDBF0F" />
      <path
        d="M11 2.6c0-.884.733-1.6 1.636-1.6h14.728C28.267 1 29 1.716 29 2.6v4.8c0 .884-.733 1.6-1.636 1.6H12.636C11.733 9 11 8.284 11 7.4V2.6Z"
        fill="#FDE351"
      />
      <path
        d="M5 13.353C5 9.844 7.828 7 11.316 7h17.368C32.172 7 35 9.844 35 13.353v14.294C35 31.156 32.172 34 28.684 34H11.316C7.828 34 5 31.156 5 27.647V13.353Z"
        fill="#FFD400"
      />
      <path d="M17.429 19.6c0 .884-.768 1.6-1.715 1.6-.947 0-1.714-.716-1.714-1.6 0-.884.768-1.6 1.714-1.6.947 0 1.715.716 1.715 1.6Zm8.571 0c0 .884-.767 1.6-1.714 1.6s-1.715-.716-1.715-1.6c0-.884.768-1.6 1.715-1.6.947 0 1.714.716 1.714 1.6Zm-9.81 5.734c-.394-.245-.926-.145-1.189.222-.262.368-.156.865.238 1.11 2.86 1.779 6.663 1.779 9.522 0 .394-.245.5-.742.238-1.11-.263-.367-.795-.467-1.189-.222-2.283 1.421-5.337 1.421-7.62 0Z" />
    </SvgIcon>
  );
}
