import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function FlagGo(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M4.048 2.505v.5h33.22c.812 0 1.5.688 1.5 1.5 0 .375-.12.621-.25.977L34.653 17l3.932 11.482c.125.26.183.554.183.866 0 .875-.688 1.5-1.5 1.5H4.048v6.5c0 .875-.688 1.5-1.5 1.5-.875 0-1.5-.625-1.5-1.5V2.505c0-.813.625-1.5 1.5-1.5.812 0 1.5.688 1.5 1.5Zm0 3.5v21.843h30.698l-3.125-9.035c-.474-1.134-.4-2.478 0-3.563l3.35-9.245H4.048Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.77 21.247a4.634 4.634 0 0 0 4.61-4.238v-.007a1.306 1.306 0 0 0-1.286-1.391h-2.72c-.57 0-1.005.47-1.005 1.004a.997.997 0 0 0 1.005 1.005h1.808a2.608 2.608 0 0 1-2.413 1.618 2.607 2.607 0 0 1-2.622-2.623 2.619 2.619 0 0 1 2.622-2.622c.667 0 1.272.26 1.734.671.404.37 1.06.352 1.423-.075a1.02 1.02 0 0 0-.07-1.422l-.009-.007a4.67 4.67 0 0 0-3.078-1.176 4.636 4.636 0 0 0-4.63 4.631 4.624 4.624 0 0 0 4.63 4.632Zm8.143-.627a4.543 4.543 0 0 0 4.634 0 4.613 4.613 0 0 0 0-8.001 4.543 4.543 0 0 0-4.634 0 4.574 4.574 0 0 0-2.314 3.992c0 1.67.867 3.182 2.314 4.01Zm.037-2.699-.002-.003a2.585 2.585 0 0 1 .001-2.616 2.646 2.646 0 0 1 2.28-1.313c.929 0 1.783.504 2.262 1.313a2.585 2.585 0 0 1 .001 2.616l-.002.003a2.599 2.599 0 0 1-2.26 1.312c-.953 0-1.804-.49-2.28-1.312Z"
      />
    </SvgIcon>
  );
}
