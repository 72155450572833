import { useEffect } from 'react';
import {
  CATALOG_FILTER_EXISTS,
  DefaultEntityFilters,
  EntityFilter,
  useEntityList,
} from '@backstage/plugin-catalog-react';
import { Entity } from '@backstage/catalog-model';

class RelatedDocumentationFilter implements EntityFilter {
  constructor(readonly userRelatedEntityRefs: string[]) {}
  getCatalogFilters(): Record<string, string | symbol | (string | symbol)[]> {
    return {
      'metadata.annotations.backstage.io/techdocs-ref': CATALOG_FILTER_EXISTS,
    };
  }
  filterEntity(entity: Entity): boolean {
    return this.userRelatedEntityRefs.includes(entity.spec?.owner as string);
  }
}

type CustomFilters = DefaultEntityFilters & {
  techdocs?: RelatedDocumentationFilter;
};

export interface RelatedDocumentationPickerProps {
  userRelatedEntityRefs: string[];
}

export const RelatedDocumentationPicker = (
  props: RelatedDocumentationPickerProps,
) => {
  const { updateFilters } = useEntityList<CustomFilters>();

  useEffect(() => {
    updateFilters({
      techdocs: new RelatedDocumentationFilter(props.userRelatedEntityRefs),
    });
  }, [updateFilters, props.userRelatedEntityRefs]);

  return null;
};
