import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Warehouse(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M20.188 7.148c-.125-.062-.313-.062-.375 0L3.938 13.586c-.626.187-.938.75-.938 1.375v19.437c0 .875-.688 1.5-1.5 1.5-.875 0-1.5-.625-1.5-1.5V14.961c0-1.875 1.063-3.5 2.75-4.188l15.938-6.437c.812-.313 1.75-.313 2.625 0l15.875 6.437A4.498 4.498 0 0 1 40 14.961v19.437c0 .875-.688 1.5-1.5 1.5-.875 0-1.5-.625-1.5-1.5V14.961c0-.625-.375-1.188-.938-1.375L20.188 7.148ZM9 24.398v3h22v-3H9Zm0 8.5h22v-2.5H9v2.5Zm-1.5 3c-.875 0-1.5-.625-1.5-1.5v-16c0-1.375 1.063-2.5 2.5-2.5h23c1.375 0 2.5 1.125 2.5 2.5v16c0 .875-.688 1.5-1.5 1.5h-25Zm1.5-14.5h22v-2.5H9v2.5Z" />
    </SvgIcon>
  );
}
