import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Gem(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="m14.531 8.75 5.5 5.813L25.47 8.75H14.53ZM27.97 10.5l-4.5 4.75h8.062L27.97 10.5Zm3.125 7.75H8.906l11.125 12.313L31.094 18.25Zm-22.625-3h8.062l-4.5-4.75-3.562 4.75Zm27.125 2.563-14.5 16c-.25.312-.688.437-1.063.437-.437 0-.875-.125-1.125-.438l-14.5-16c-.5-.562-.562-1.375-.125-1.937l7-9.5c.313-.375.75-.625 1.25-.625h15c.438 0 .875.25 1.188.625l7 9.5c.437.563.375 1.375-.125 1.938Z" />
    </SvgIcon>
  );
}
