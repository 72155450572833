import React from 'react';
import { baseplateStyles } from '@lego/plugin-baseplate-core-components';
import { Typography } from '@material-ui/core';
import { type ReactElement } from 'react';

export interface EndpointGroupHeaderProps {
  name: string;
}
const useStyles = baseplateStyles(theme => ({
  wrapper: {
    borderTop: `1px solid ${theme.semantic.border.default}`,
    padding: theme.primitive.dimensions.page.horizontalPadding,
  },
}));

export function EndpointGroupHeader({
  name,
}: EndpointGroupHeaderProps): ReactElement {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <Typography variant="h2">{name}</Typography>
    </div>
  );
}
