import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function TagBarcode(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M15 13c0-1.063-.938-2-2-2-1.125 0-2 .938-2 2 0 1.125.875 2 2 2 1.063 0 2-.875 2-2Zm3.73 2.746a1.52 1.52 0 0 0 0-2.122 1.48 1.48 0 0 0-2.121 0l-3.536 3.536c-.619.619-.619 1.503 0 2.121a1.48 1.48 0 0 0 2.122 0l3.535-3.535Zm3.182 1.06c-.398-.397-1.06-.353-1.414 0l-4.243 4.243a.987.987 0 0 0 0 1.414c.354.354 1.017.398 1.414 0l4.243-4.242c.354-.354.354-1.061 0-1.415Zm4.95 4.95c-.398-.398-1.06-.353-1.414 0L21.205 26a.987.987 0 0 0 0 1.414c.354.354 1.016.398 1.414 0l4.243-4.243c.353-.353.353-1.06 0-1.414Zm-2.475-2.475a1.48 1.48 0 0 0-2.121 0l-3.536 3.536c-.619.619-.619 1.502 0 2.121a1.48 1.48 0 0 0 2.121 0l3.536-3.535a1.52 1.52 0 0 0 0-2.122Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.125 7.188A3.976 3.976 0 0 0 18.312 6H9a3 3 0 0 0-3 3v9.375c0 1.063.375 2.063 1.125 2.813l11 11a4.036 4.036 0 0 0 5.688 0l8.312-8.313a4.036 4.036 0 0 0 0-5.688l-11-11ZM9.25 19.063c-.188-.188-.25-.438-.25-.688V9h9.313c.25 0 .5.125.687.313l11 11a.951.951 0 0 1 0 1.437l-8.313 8.313c-.375.375-1.062.375-1.437 0l-11-11Z"
      />
    </SvgIcon>
  );
}
