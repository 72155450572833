import { Typography } from '@material-ui/core';
import React from 'react';

type Props = {
  formTitle: string;
  stepTitle: string;
  step?: number;
};

export const FormStepTitle = ({ formTitle, stepTitle, step }: Props) => {
  return (
    <div>
      <Typography variant="overline" color="secondary">
        {formTitle}
        {step && ` — step ${step}`}
      </Typography>
      <Typography variant="h4" color="primary">
        {stepTitle}
      </Typography>
    </div>
  );
};
