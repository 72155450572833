import { Entity } from '@backstage/catalog-model';
import {
  BaseplateMarkdownContent,
  CodeChip,
} from '@lego/plugin-baseplate-core-components';
import { Typography } from '@material-ui/core';
import React, { type ReactElement } from 'react';
import { UiOpenApiDocumentType } from '../../../types';
import { useDefinitionTabStyles } from '../DefinitionTab.styles';

export interface OpenApiHeaderProps {
  entity: Entity;
  servers: UiOpenApiDocumentType['servers'];
}

export function OpenApiHeader({
  entity,
  servers,
}: OpenApiHeaderProps): ReactElement {
  const classes = useDefinitionTabStyles();

  return (
    <header className={classes.header}>
      <Typography variant="h2">{entity.metadata.title} Definition</Typography>
      <BaseplateMarkdownContent content={entity.metadata.description || ''} />
      <br />
      <Typography variant="subtitle2">Server</Typography>
      {servers.map(url => (
        <CodeChip children={url} showCopyToClipboard key={url} />
      ))}
    </header>
  );
}
