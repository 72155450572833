export const lifecycleDescriptions = {
  experimental:
    "This software isn't registered in Architecture Gateway and thus unofficial. Be extra cautious when using this software.",
  plan: 'This tool is still in the planning state and it is not clear whether it will be implemented.',
  phaseIn: 'This tool is in the phase of being built or acquired.',
  active: 'This tool is productive and in use.',
  phaseOut:
    'This tool will be retired at the end of this phase. Decommissioning actions starts at the indicated Phase out date.',
  endOfLife: 'This tool is out-of-life and cannot be used anymore.',
};
