import { makeStyles } from '@material-ui/core';
import { primitive, semantic, component } from '../design-tokens';
import { BackstageTheme } from '@backstage/theme';

type ThemeVariables = {
  primitive: typeof primitive;
  semantic: typeof semantic.light;
  component: typeof component.light;
};

export const baseplateStyles = (
  styles: (theme: ThemeVariables & BackstageTheme) => any,
) => {
  return makeStyles<ThemeVariables & BackstageTheme>(theme => styles(theme));
};
