import { createRouteRef } from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
  id: 'onex',
});

// export const dataProductResourceOverviewRouteRef = createRouteRef({
//   id: ' onexresourceoverview',
// });

// export const dataProductRegistrationRouteRef = createRouteRef({
//   id: 'onexregistration',
// });
