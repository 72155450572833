import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function NotesPlus(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M14 9v16c0 .563.438 1 1 1h12.313c.25 0 .5-.063.687-.25l3.688-3.688c.187-.187.312-.437.312-.687V9c0-.5-.5-1-1-1H15c-.563 0-1 .5-1 1Zm-3 0c0-2.188 1.75-4 4-4h16c2.188 0 4 1.813 4 4v12.375a3.976 3.976 0 0 1-1.188 2.813l-3.687 3.687c-.75.75-1.75 1.125-2.813 1.125H15c-2.25 0-4-1.75-4-4V9Zm11 2h2c.5 0 1 .5 1 1v3h3c.5 0 1 .5 1 1v2c0 .563-.5 1-1 1h-3v3c0 .563-.5 1-1 1h-2c-.563 0-1-.438-1-1v-3h-3c-.563 0-1-.438-1-1v-2c0-.5.438-1 1-1h3v-3c0-.5.438-1 1-1Zm1.5 21c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5h-10C8.75 35 5 31.25 5 26.5v-14c0-.813.625-1.5 1.5-1.5.813 0 1.5.688 1.5 1.5v14c0 3.063 2.438 5.5 5.5 5.5h10Z" />
    </SvgIcon>
  );
}
