import React from 'react';
import { RadioGroup, Typography } from '@material-ui/core';
import { useRadioStyles } from './Radio.styles';
import Radio, { RadioProps } from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import { BaseplateOption } from '..';
import { BaseplateInputProps } from '../input/InputProps.type';

export type BaseplateRadioButtonProps = BaseplateInputProps & {
  options: string[] | BaseplateOption[];
  value: string | BaseplateOption;
  required?: boolean;
  radioProps?: RadioProps;
  onChange: (value: string) => void;
  row?: boolean;
};

/**
 * A custom form control radio component that extends the MUI Radio component.
 * @docs https://baseplate.legogroup.io/docs/default/component/baseplate
 */
export const RadioButton = ({
  label,
  required,
  helperText,
  errorText,
  success,
  successText,
  options,
  value,
  radioProps,
  onChange,
  row = false,
}: BaseplateRadioButtonProps): JSX.Element => {
  const classes = useRadioStyles();

  const _options = options.map(option =>
    typeof option === 'string'
      ? { option, label: option, value: option, disabled: false }
      : { ...option, disabled: option.disabled ?? false },
  );

  const handleChange = (option: BaseplateOption, checked: boolean) => {
    if (checked) {
      onChange(option.value);
    }
  };
  return (
    <>
      <Typography variant="body2" component="label">
        {label}{' '}
        {!required && (
          <Typography variant="caption" color="secondary">
            (optional)
          </Typography>
        )}
      </Typography>
      <RadioGroup row={row}>
        {_options.map(option => (
          <FormControlLabel
            key={option.label}
            className={classes.radioControlLabel}
            control={
              <Radio
                {...radioProps}
                disabled={option.disabled}
                role="checkbox"
                value={option.value}
                checked={option.value === value}
                className={classes.radio}
                onChange={e => handleChange(option, e.target.checked)}
                tabIndex={-1}
                aria-checked={option.value === value}
              />
            }
            label={
              <Typography
                variant="body1"
                component="label"
                className={option.disabled ? classes.disabledOption : ''}
              >
                {option.label}
              </Typography>
            }
          />
        ))}
      </RadioGroup>
      <div className={classes.descriptiveTextContainer}>
        {helperText && (
          <Typography variant="body2" className={classes.helperText}>
            {helperText}
          </Typography>
        )}
        {errorText && (
          <Typography variant="body2" className={classes.errorText}>
            {errorText}
          </Typography>
        )}
        {success && successText && (
          <Typography variant="body2" className={classes.successText}>
            {successText}
          </Typography>
        )}
      </div>
    </>
  );
};
