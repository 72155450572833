import { Link } from '@backstage/core-components';
import { Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useSideMenuStyles } from './SideMenu.styles';
import ExternalIcon from '@material-ui/icons/LaunchOutlined';
import { Icon } from '../icon';
import { Chip, ChipProps } from '../chip';

/**
 * An item in the side menu
 */
interface BaseProps {
  /**
   * The title of the item
   */
  label: string;
  /**
   * The icon of the item
   */
  icon?: React.ReactNode;
  chip?: ChipProps;
  /**
   * Whether the item should open in a new tab
   */
  external?: boolean;
  /**
   * Whether the item is active
   */
  active?: boolean;
  /**
   * Nested side menu items. Type of array is `SideMenuItem`
   */
  children?: React.ReactNode;
  className?: string;
}

type Props = BaseProps &
  (
    | {
        onClick: () => void;
        href?: never;
      }
    | {
        onClick?: never;
        href: string;
      }
    | {
        children: React.ReactNode;
        onClick?: never;
        href?: never;
      }
  );

/**
 * A component that represents an item in the side menu
 */
export const SideMenuItem = ({
  label,
  icon,
  chip,
  onClick,
  href,
  external,
  children,
  active,
  className,
}: Props) => {
  const classes = useSideMenuStyles();
  const [open, setOpen] = useState(false);

  const props = React.useMemo(
    () => ({
      className: [
        classes.item,
        active ? classes.active : '',
        className ? className : '',
      ].join(' '),
    }),
    [classes, active, className],
  );

  const externalLinkProps = React.useMemo(
    () => (external ? { target: '_blank', rel: 'noopener' } : {}),
    [external],
  );

  const Component = React.useMemo(
    () =>
      ({
        children: itemChildren,
        onClick: handleOpen,
      }: {
        children: React.ReactNode;
        onClick: () => void;
      }) => {
        if (onClick) {
          return (
            <button
              onClick={() => {
                handleOpen();
                onClick();
              }}
              {...props}
            >
              {itemChildren}
            </button>
          );
        } else if (href) {
          return (
            <Link
              to={href}
              onClick={handleOpen}
              underline="none"
              {...externalLinkProps}
              {...props}
            >
              {itemChildren}
            </Link>
          );
        }
        return (
          <button onClick={handleOpen} {...props}>
            {itemChildren}
          </button>
        );
      },
    [onClick, props, href, externalLinkProps],
  );

  return (
    <li>
      <Component onClick={() => setOpen(!open)}>
        {icon}
        <Typography variant="body1">{label}</Typography>
        {children && (
          <Icon
            icon="chevron-right"
            size={16}
            className={[classes.chevron, open ? classes.open : ''].join(' ')}
          />
        )}
        {chip && <Chip {...chip} />}
        {external && (
          <div className={classes.external}>
            <ExternalIcon fontSize="small" />
          </div>
        )}
      </Component>
      {children && (
        <ul
          className={[classes.childContainer, open ? classes.open : ''].join(
            ' ',
          )}
        >
          {children}
        </ul>
      )}
    </li>
  );
};
