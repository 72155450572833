import { BackstageOverrides } from '@backstage/core-components';
import { DesignTokens } from '../types';

export const base = (
  tokens: DesignTokens,
): Pick<
  BackstageOverrides,
  | 'BackstageContent'
  | 'BackstagePage'
  | 'MuiDivider'
  | 'MuiContainer'
  | 'BackstageContentHeader'
  | 'MuiCssBaseline'
  | 'MuiGrid'
  | 'MuiLink'
  | 'MuiSvgIcon'
  | 'MuiStepIcon'
  | 'BackstageEmptyState'
  | 'MuiAccordionDetails'
> => ({
  BackstagePage: {
    root: {
      height: 'auto',
      minHeight: `calc(100vh - ${tokens.primitive.dimensions.nav.height})`,
      marginTop: tokens.primitive.dimensions.nav.height,
      overflowY: 'initial',
    },
  },
  BackstageContent: {
    root: {
      width: '100%',
      height: 'auto',
      backgroundColor: tokens.semantic.background.neutral[2],
      paddingRight: `${tokens.primitive.dimensions.page.horizontalPadding} !important`,
      paddingLeft: `${tokens.primitive.dimensions.page.horizontalPadding} !important`,
    },
  },
  MuiDivider: {
    root: {
      backgroundColor: tokens.semantic.border.default,
    },
  },
  MuiContainer: {
    root: {
      backgroundColor: tokens.semantic.background.neutral[2],
    },
  },
  BackstageContentHeader: {
    container: {
      padding: tokens.primitive.dimensions[200],
      marginBottom: 0,
    },
  },
  MuiGrid: {
    container: {
      padding: tokens.primitive.dimensions[200],
    },
  },
  MuiLink: {
    root: {
      color: tokens.semantic.interactive.default,
      '&:hover': { color: tokens.semantic.interactive.hover },
    },
  },
  MuiSvgIcon: {
    colorPrimary: {
      color: tokens.semantic.icon.primary,
    },
  },
  MuiStepIcon: {
    root: {
      color: tokens.semantic.background.neutral[4],
      '&$active': {
        color: tokens.semantic.interactive.default,
      },
      '&$completed': {
        color: 'green',
      },
      '&$error': {
        color: 'red',
      },
    },
  },
  BackstageEmptyState: {
    root: {
      backgroundColor: tokens.semantic.background.neutral[2],
    },
  },

  MuiAccordionDetails: {
    root: {
      makeStylesdetails: {
        backgroundColor: tokens.semantic.background.neutral[1],
      },
    },
  },
});
