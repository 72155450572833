import { Entity } from '@backstage/catalog-model';
import { Link } from '@backstage/core-components';
import { FavoriteEntity } from '@backstage/plugin-catalog-react';
import { Chip, EntityIcon } from '@lego/plugin-baseplate-core-components';
import { Breadcrumbs, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import React, { useMemo } from 'react';
import { BreadcrumbLink } from './EntityLayout';
import { AiEntityHelper } from '@lego/plugin-baseplate-ai';

type EntityPageLabel = {
  title: string;
  color: string;
  style?: React.CSSProperties;
};

export type EntityPageLabels = (entity: Entity) => EntityPageLabel[];

export function EntityLayoutTitle(props: {
  entity: Entity | undefined;
  breadcrumbLinks?: BreadcrumbLink[];
  actions?: React.ReactNode[];
  labels?: EntityPageLabels;
}) {
  const { entity, breadcrumbLinks, labels } = props;
  const title = useMemo(() => {
    if (!entity) return 'Missing title';
    return entity.metadata.title || entity.metadata.name;
  }, [entity]);

  const labelList = useMemo(() => {
    return entity && labels ? labels(entity) : [];
  }, [entity, labels]);

  return (
    <Box>
      <Box marginBottom="0.5rem">
        <Breadcrumbs separator="›">
          {breadcrumbLinks?.map(breadcrumb =>
            breadcrumb.href ? (
              <Link key={breadcrumb.label} to={breadcrumb.href}>
                {breadcrumb.label}
              </Link>
            ) : (
              <Typography key={breadcrumb.label} color="secondary">
                {breadcrumb.label}
              </Typography>
            ),
          )}
        </Breadcrumbs>
      </Box>

      <Box
        display="inline-flex"
        alignItems="center"
        gridGap="0.5rem"
        height="1em"
        maxWidth="100%"
      >
        {entity && (
          <EntityIcon kind={entity.kind as any} entity={entity} size="md" />
        )}
        <Box
          component="span"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          overflow="hidden"
        >
          {title}
        </Box>
        {labelList.map(label => (
          <Chip
            label={label.title}
            variant="filled"
            key={label.title}
            color={label.color as any}
            style={{
              ...label.style,
            }}
          />
        ))}
        {entity && <FavoriteEntity entity={entity} />}
        <AiEntityHelper />
      </Box>
    </Box>
  );
}
