import { getCompoundEntityRef } from '@backstage/catalog-model';
import { InfoCard, InfoCardVariants, Link } from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import {
  CATALOG_FILTER_EXISTS,
  catalogApiRef,
  useEntity,
} from '@backstage/plugin-catalog-react';
import React from 'react';
import { FilesDocument } from '@lego/klik-ui-icons';
import useAsync from 'react-use/lib/useAsync';
import { Typography, makeStyles } from '@material-ui/core';

type DocumentationCardProps = {
  variant?: InfoCardVariants;
};

const useStyles = makeStyles({
  description: {
    wordBreak: 'break-word',
  },
});

const ViewDocumentation = () => {
  const catalogApi = useApi(catalogApiRef);
  const classes = useStyles();
  const { entity } = useEntity();
  const { value: components } = useAsync(
    () =>
      catalogApi.getEntities({
        filter: {
          'spec.owner': `product:default/${entity.metadata.name.toLowerCase()}`,
          'metadata.annotations.backstage.io/techdocs-ref':
            CATALOG_FILTER_EXISTS,
        },
      }),
    [entity],
  );
  const filteredComponents = components?.items?.filter(
    item =>
      item?.spec?.type === 'service' || item?.spec?.type === 'documentation',
  );
  const entitiesRef = filteredComponents?.map(item => ({
    ref: getCompoundEntityRef(item),
    title: item?.metadata?.title,
  }));

  return (
    <>
      {entitiesRef && entitiesRef.length ? (
        entitiesRef.map(entityRef => (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
            }}
          >
            <Link
              key={entityRef.ref.name}
              title="Documentation"
              to={`/docs/default/component/${entityRef.ref.name}`}
            >
              <FilesDocument style={{ marginRight: '0.5rem' }} />
              {entityRef.title || entityRef.ref.name}
            </Link>
          </div>
        ))
      ) : (
        <Typography variant="body1" paragraph className={classes.description}>
          No documentation available.
        </Typography>
      )}
    </>
  );
};

export const BaseplateDocumentationCard = ({
  variant,
}: DocumentationCardProps) => {
  return (
    <InfoCard title="Documentation" variant={variant}>
      <ViewDocumentation />
    </InfoCard>
  );
};
