import { baseplateStyles } from '../../utils';

export const useDropdownStyles = baseplateStyles(() => ({
  root: {
    '& .v5-MuiInputBase-input.v5-MuiSelect-multiple': {
      padding: '0 0.5rem',
    },
    '& .v5-MuiSelect-select': {
      height: '40px !important',
    },
  },
}));
