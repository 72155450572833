/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Entity, RELATION_OWNER_OF } from '@backstage/catalog-model';
import { ConfigApi, DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';
import {
  Account,
  AzureApplication,
  ClientSecret,
  CreateClientSecret,
  Group,
  ImportAccountInterface,
  ImportApplicationInterface,
  ImportGroupInterface,
} from '@lego/plugin-baseplate-identity-common';
import { IdentityApi } from './IdentityApi';
import { getEntityRelations } from '@backstage/plugin-catalog-react';
import { useUserContext } from '@lego/plugin-baseplate-core-components';

/**
 * A client for interacting with the Identity Plugin on Baseplate.
 *
 * @public
 */
export class IdentityClient implements IdentityApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi: FetchApi;

  constructor(options: {
    discoveryApi: DiscoveryApi;
    fetchApi: FetchApi;
    configApi: ConfigApi;
  }) {
    this.discoveryApi = options.discoveryApi;
    this.fetchApi = options.fetchApi;
  }

  async importApplication(
    payload: ImportApplicationInterface[],
    options: { token: string },
  ): Promise<void> {
    const { token } = options;

    const url = await this.discoveryApi.getBaseUrl('identity');
    const response = await this.fetchApi.fetch(`${url}/applications`, {
      headers: {
        azureaccesstoken: token,
        'Content-Type': 'application/json',
      },
      method: 'PATCH',
      body: JSON.stringify({ payload }),
    });
    if (!response.ok) {
      const json = await response.json();
      throw new Error(json.error);
    }
  }

  async fetchApplications(product: Entity): Promise<AzureApplication[]> {
    const {
      metadata: { name },
    } = product;
    const applicationsRefs = getEntityRelations(product, RELATION_OWNER_OF, {
      kind: 'application',
    });
    const url = await this.discoveryApi.getBaseUrl('identity');
    const response = await this.fetchApi.fetch(
      `${url}/product/${name}/applications`,
    );

    if (!response.ok) {
      const json = await response.json();
      throw new Error(json.error);
    }
    const ownedApplications = (await response.json()).filter(
      (res: AzureApplication) =>
        applicationsRefs
          .map(ref => ref.name.toLowerCase())
          .includes(res.leanIxApplicationId.toLowerCase()),
    );

    return ownedApplications;
  }

  async fetchMyApplications(token: string): Promise<AzureApplication[]> {
    const url = await this.discoveryApi.getBaseUrl('identity');
    const response = await this.fetchApi.fetch(`${url}/applications/my`, {
      headers: {
        azureaccesstoken: token,
      },
    });
    if (!response.ok) {
      const json = await response.json();
      throw new Error(json.error);
    }
    return (await response.json()) as AzureApplication[];
  }

  async fetchClientSecrets(
    type: string,
    clientId: string,
    token: string,
  ): Promise<ClientSecret[]> {
    const url = await this.discoveryApi.getBaseUrl('identity');
    const response = await this.fetchApi.fetch(
      `${url}/applications/${this.applicationTypeToUrlPart(
        type,
      )}/${clientId}/secrets`,
      {
        headers: {
          azureaccesstoken: token,
        },
      },
    );
    if (!response.ok) {
      const json = await response.json();
      throw new Error(json.error);
    }
    return (await response.json()) as ClientSecret[];
  }

  async createClientSecret(
    payload: CreateClientSecret,
    type: string,
    clientId: string,
    token: string,
  ): Promise<ClientSecret> {
    const url = await this.discoveryApi.getBaseUrl('identity');
    const response = await this.fetchApi.fetch(
      `${url}/applications/${this.applicationTypeToUrlPart(
        type,
      )}/${clientId}/secrets`,
      {
        headers: {
          azureaccesstoken: token,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(payload),
      },
    );
    if (!response.ok) {
      const json = await response.json();
      throw new Error(json.error);
    }
    return (await response.json()) as ClientSecret;
  }

  async deleteClientSecret(
    type: string,
    clientId: string,
    keyId: string,
    token: string,
  ): Promise<void> {
    const url = await this.discoveryApi.getBaseUrl('identity');
    const response = await this.fetchApi.fetch(
      `${url}/applications/${this.applicationTypeToUrlPart(
        type,
      )}/${clientId}/secrets/${keyId}`,
      {
        headers: {
          azureaccesstoken: token,
          'Content-Type': 'application/json',
        },
        method: 'DELETE',
      },
    );
    if (!response.ok) {
      const json = await response.json();
      throw new Error(json.error);
    }
  }

  applicationTypeToUrlPart(type: string): string {
    switch (type) {
      case 'Client':
        return 'clients';
      case 'Resource':
        return 'resources';
      case 'SingleSignOn':
        return 'singlesignonclients';
      default:
        return 'clients';
    }
  }

  async fetchGroups(product: Entity): Promise<Group[]> {
    const {
      metadata: { name },
    } = product;
    const url = await this.discoveryApi.getBaseUrl('identity');
    const response = await this.fetchApi.fetch(`${url}/product/${name}/groups`);
    if (!response.ok) {
      const json = await response.json();
      throw new Error(json.error);
    }
    return (await response.json()) as Group[];
  }

  async fetchMyGroups(token: string): Promise<Group[]> {
    const url = await this.discoveryApi.getBaseUrl('identity');
    const response = await this.fetchApi.fetch(`${url}/groups`, {
      headers: {
        azureaccesstoken: token,
      },
    });
    if (!response.ok) {
      const json = await response.json();
      throw new Error(json.error);
    }
    return (await response.json()) as Group[];
  }

  async importGroup(
    payload: ImportGroupInterface[],
    options: { token: string },
  ): Promise<void> {
    const { token } = options;
    const url = await this.discoveryApi.getBaseUrl('identity');

    await Promise.all(
      payload.map(async group => {
        const response = await this.fetchApi.fetch(
          `${url}/groups/${group.keyId}/directoryextensions/extension_7bb6cee95f7746b294a57cb3a3517802_LeanIXProductID`,
          {
            headers: {
              azureaccesstoken: token,
              'Content-Type': 'application/json',
            },
            method: 'PUT',
            body: JSON.stringify({ payload: [group] }),
          },
        );
        if (!response.ok) {
          const json = await response.json();
          throw new Error(json.error);
        }
      }),
    );
  }

  async fetchAccounts(product: Entity): Promise<Account[]> {
    const {
      metadata: { name },
    } = product;
    const url = await this.discoveryApi.getBaseUrl('identity');
    const response = await this.fetchApi.fetch(`${url}/product/${name}/users`);
    if (!response.ok) {
      const json = await response.json();
      throw new Error(json.error);
    }
    return (await response.json()) as Account[];
  }

  async fetchMyAccounts(token: string): Promise<Account[]> {
    const url = await this.discoveryApi.getBaseUrl('identity');
    const response = await this.fetchApi.fetch(`${url}/users`, {
      headers: {
        azureaccesstoken: token,
      },
    });
    if (!response.ok) {
      const json = await response.json();
      throw new Error(json.error);
    }
    return (await response.json()) as Account[];
  }

  async importAccount(
    payload: ImportAccountInterface[],
    options: { token: string },
  ): Promise<void> {
    const { token } = options;
    const url = await this.discoveryApi.getBaseUrl('identity');
    await Promise.all(
      payload.map(async account => {
        const response = await this.fetchApi.fetch(
          `${url}/users/${account.keyId}/`,
          {
            headers: {
              azureaccesstoken: token,
              'Content-Type': 'application/json',
            },
            method: 'PATCH',
            body: JSON.stringify({ payload: [account] }),
          },
        );
        if (!response.ok) {
          const json = await response.json();
          throw new Error(json.error);
        }
      }),
    );
  }
}
