import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function AccountProfile(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M17.632 20a1.58 1.58 0 0 1-1.58 1.583A1.581 1.581 0 0 1 14.475 20c0-.875.707-1.583 1.579-1.583s1.579.708 1.579 1.583Zm7.894 0a1.58 1.58 0 0 1-1.579 1.583A1.581 1.581 0 0 1 22.368 20a1.58 1.58 0 0 1 1.58-1.583A1.58 1.58 0 0 1 25.526 20Zm-10.13 5.894a.788.788 0 0 1 1.095-.22 6.367 6.367 0 0 0 7.018 0 .788.788 0 0 1 1.095.22.793.793 0 0 1-.219 1.098 7.942 7.942 0 0 1-8.77 0 .793.793 0 0 1-.22-1.098Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.684 2.583v4.75c3.488 0 6.316 2.836 6.316 6.334v14.25a6.333 6.333 0 0 1-4.737 6.133V39H9.737v-4.95A6.333 6.333 0 0 1 5 27.918v-14.25c0-3.498 2.828-6.334 6.316-6.334v-4.75c0-.874.707-1.583 1.579-1.583h14.21c.872 0 1.58.709 1.58 1.583Zm-15 4.75V3.375h12.632v3.958H13.684Zm-6.316 6.334a3.953 3.953 0 0 1 3.948-3.959h17.368a3.953 3.953 0 0 1 3.948 3.959v14.25a3.953 3.953 0 0 1-3.948 3.958H11.316a3.953 3.953 0 0 1-3.948-3.958v-14.25Zm4.737 20.583h15.79v2.375h-15.79V34.25Z"
      />
    </SvgIcon>
  );
}
