import {
  Icon,
  SelectableCard as SelectableCardCore,
} from '@lego/plugin-baseplate-core-components';
import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';

export const SelectableCardExtension = ({
  onChange,
  uiSchema,
}: FieldExtensionComponentProps<string>) => {
  const defaultValue = uiSchema['ui:properties']?.defaultValue ?? '';

  const [changedValue, setChangedValue] = useState<string>('');

  useEffect(() => {
    if (changedValue === '' && defaultValue !== '') {
      onChange(defaultValue);
      setChangedValue(defaultValue);
    }
  }, [changedValue, defaultValue, onChange, setChangedValue]);

  const label = uiSchema['ui:properties']?.label ?? 'empty';

  const yamlOptions = uiSchema['ui:select'];
  if (!yamlOptions) {
    return <div>You should add `ui:options` to your yaml file.</div>;
  }

  const options = Object.keys(yamlOptions).map(key => {
    const option = yamlOptions[key];
    return {
      label: option.label,
      icon: <Icon icon={option.icon} />,
      value: option.value,
      description: option.description,
    };
  });

  return (
    <div>
      {label === 'empty' ? null : <Typography variant="h5">{label}</Typography>}
      <SelectableCardCore
        value={changedValue}
        name="location"
        type="radio"
        onChange={value => {
          setChangedValue(value);
          onChange(value);
        }}
        options={options}
      />
    </div>
  );
};
