import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Shapes(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="m23.695 5 6 10a1.918 1.918 0 0 1 0 2c-.312.625-1 1-1.687 1h-12c-.75 0-1.438-.375-1.75-1a1.918 1.918 0 0 1 0-2l6-10c.375-.625 1-1 1.75-1 .687 0 1.312.375 1.687 1Zm-1.687 18.5c0-1.375 1.062-2.5 2.5-2.5h9c1.375 0 2.5 1.125 2.5 2.5v9c0 1.438-1.125 2.5-2.5 2.5h-9c-1.438 0-2.5-1.063-2.5-2.5v-9Zm-10 12.5c-2.875 0-5.5-1.5-6.938-4-1.437-2.438-1.437-5.5 0-8 1.438-2.438 4.063-4 6.938-4 2.812 0 5.437 1.563 6.875 4 1.437 2.5 1.437 5.563 0 8a7.928 7.928 0 0 1-6.875 4Z" />
    </SvgIcon>
  );
}
