import { Button, showToast } from '@lego/plugin-baseplate-core-components';
import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';

interface ExportToCSVButtonProps {
  headers: string[];
  filename: string;
  buttonText: string;
  fetchCSVData: () => Promise<string[][]>;
}

export function ExportToCSVButton({
  headers,
  filename,
  buttonText,
  fetchCSVData,
}: ExportToCSVButtonProps) {
  const [isDownloading, setIsDownloading] = React.useState<boolean>(false);

  const showErrorPopup = () => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    showToast({
      type: 'error',
      message: 'Failed to export to CSV',
    });
  };

  const convertToCSV = (data: string[][]) => {
    const formattedHeaders = headers.join('|').concat('\n');

    const rows = data.map(row => row.join('|')).join('\n');

    return formattedHeaders + rows;
  };

  const handleOnClick = async () => {
    try {
      setIsDownloading(true);
      const data = await fetchCSVData();
      const csv = convertToCSV(data);

      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const element = document.createElement('a');
      const url = URL.createObjectURL(blob);
      element.href = url;
      element.download = filename;
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();
      document.body.removeChild(element);
      setIsDownloading(false);
    } catch (e) {
      showErrorPopup();
      setIsDownloading(false);
    }
  };
  return (
    <Button
      variant="primary"
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onClick={handleOnClick}
      disabled={isDownloading}
      endIcon={isDownloading ? <CircularProgress size={20} /> : null}
    >
      {buttonText}
    </Button>
  );
}
