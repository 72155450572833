import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function HandHoldingDollar(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M21.5 5.469v.687c.375.063.75.188 1.125.25.813.25 1.25 1.063 1.063 1.875-.25.75-1.063 1.25-1.875 1.063-.688-.188-1.313-.313-1.875-.313-.438-.062-.938.063-1.188.25a.856.856 0 0 0-.25.188v.187c0 .063 0 .125.188.188.375.187.875.375 1.687.625h.063c.687.25 1.625.5 2.375 1 .875.5 1.625 1.437 1.625 2.812.062 1.375-.688 2.375-1.625 3-.438.25-.875.438-1.375.563v.625c0 .875-.625 1.5-1.5 1.5a1.48 1.48 0 0 1-1.5-1.5v-.688a9.18 9.18 0 0 1-1.563-.5c-.125 0-.25-.062-.375-.125-.813-.25-1.25-1.125-.938-1.875.25-.812 1.063-1.25 1.876-.937.187.062.312.062.437.125.875.312 1.5.5 2.125.5.5.062.938-.063 1.188-.25a.863.863 0 0 0 .25-.188s.062-.062 0-.25c0-.062 0-.125-.25-.25-.313-.25-.875-.437-1.688-.687h-.125c-.625-.25-1.563-.5-2.25-.938-.813-.5-1.625-1.375-1.688-2.75 0-1.437.813-2.375 1.75-2.937.438-.25.813-.438 1.25-.5v-.75c0-.813.688-1.5 1.5-1.5.875 0 1.5.687 1.5 1.5h.063Zm-7.813 20.5c-.5 0-1 .187-1.374.437l-5 3.313a1.38 1.38 0 0 1-.813.25h-3c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5H6l4.625-3.063c.938-.562 2-.937 3.063-.937H23.5c2.188 0 4 1.812 4 4v.125l4-2.5c.688-.375 1.438-.625 2.25-.625h.438A3.788 3.788 0 0 1 38 27.78c0 1.25-.688 2.438-1.75 3.125l-5.938 3.875c-1.25.813-2.687 1.25-4.125 1.25H3.5a1.48 1.48 0 0 1-1.5-1.5c0-.875.625-1.5 1.5-1.5h22.688c.875 0 1.75-.25 2.437-.75l6-3.875c.188-.125.375-.375.375-.625a.827.827 0 0 0-.813-.812h-.437c-.25 0-.438.062-.625.187L27.25 30.78c-.25.125-.5.188-.813.188H18c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5h5.5c.5 0 1-.438 1-1 0-.5-.5-1-1-1h-9.813Z" />
    </SvgIcon>
  );
}
