import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function DoubleChevron(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="m10.594 24.469 9.375 9.875 9.437-9.875c.563-.625 1.5-.625 2.125-.063.625.563.625 1.5.063 2.125l-10.5 11c-.313.313-.688.438-1.125.438-.375 0-.75-.125-1.063-.438l-10.5-11c-.562-.625-.562-1.562.063-2.125.625-.562 1.562-.562 2.125.063Zm0-8.938c-.563.625-1.563.625-2.125.063-.625-.563-.625-1.5-.063-2.125l10.5-11a1.464 1.464 0 0 1 1.063-.438c.437 0 .812.125 1.125.438l10.5 11c.562.625.562 1.562-.063 2.125-.625.562-1.562.562-2.125-.063L19.97 5.656l-9.375 9.875Z" />
    </SvgIcon>
  );
}
