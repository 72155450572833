import { stringifyEntityRef } from '@backstage/catalog-model';
import { useApi } from '@backstage/core-plugin-api';
import {
  useShadowRootElements,
  useTechDocsReaderPage,
} from '@backstage/plugin-techdocs-react';
import { AnalyticsApiApiRef } from '@lego/plugin-baseplate-analytics';
import { EntityLink, Icon } from '@lego/plugin-baseplate-core-components';
import { Typography, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import useAsync from 'react-use/lib/useAsync';
import { BaseplateTheme } from '../../../../style/types';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles<BaseplateTheme>({
  header: {
    fontSize: '1rem',
    fontWeight: 'normal',
    paddingTop: '0.5rem',
  },
  maintained: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.25rem',
    '& a': {
      fontSize: '0.875rem',
      padding: '0 4px !important',
    },
    '& svg': {
      width: '16px',
      height: '16px',
    },
  },
  metadata: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
  },
});

function findFirstMarkdownElement(article: HTMLElement) {
  const markdownTags = [
    'H1',
    'H2',
    'H3',
    'H4',
    'H5',
    'H6',
    'P',
    'UL',
    'OL',
    'LI',
    'BLOCKQUOTE',
    'CODE',
    'PRE',
  ];

  return (
    Array.from(article.children).find(child =>
      markdownTags.includes(child.tagName),
    ) || null
  );
}

const DocsContentHeader = () => {
  const {
    setTitle,
    entityRef,
    metadata: { value: metadata },
    entityMetadata: { value: entityMetadata },
  } = useTechDocsReaderPage();
  const api = useApi(AnalyticsApiApiRef);
  const classes = useStyles();
  const { pathname } = useLocation();

  const { loading, error, value } = useAsync(async () => {
    const data = await api.fetchDocsAnalytics({
      entityRef: stringifyEntityRef(entityRef),
    });
    return { ...data };
  });

  useEffect(() => {
    if (!metadata) return;
    setTitle(metadata.site_name);
  }, [metadata, setTitle]);

  const date = useShadowRootElements([
    '.git-revision-date-localized-plugin-date',
  ])[0]?.innerText;

  const views = React.useMemo(() => {
    if (loading) return 'Loading...';
    if (error) return null;

    const normalizedPathname = pathname.toLowerCase().replace(/\/$/, '');
    const pageVisits = value?.pageVisits?.find(
      r => r.key.replace(/\/$/, '') === normalizedPathname,
    )?.doc_count;

    return pageVisits;
  }, [loading, error, value, pathname]);

  return (
    <div className={classes.header}>
      {entityMetadata?.spec?.owner &&
        typeof entityMetadata.spec.owner === 'string' && (
          <div className={classes.maintained}>
            <Typography variant="body2" component="span">
              Maintained by
            </Typography>
            <EntityLink
              entityRef={entityMetadata.spec.owner}
              variant="inline-link"
            />
          </div>
        )}
      <div className={classes.metadata}>
        {date && (
          <>
            <Typography variant="body2" component="span">
              {date}
              {' • '}
            </Typography>
          </>
        )}
        {views && (
          <>
            <Icon icon="chart-line-up" size={16} />
            <Typography variant="body2" component="span">
              {views} page views
            </Typography>
          </>
        )}
      </div>
    </div>
  );
};

export const ContentComponentsAddon = () => {
  const article = useShadowRootElements(['article'])[0];
  const toparticle = useShadowRootElements(['div.md-content'])[0];
  const firstContentElement = findFirstMarkdownElement(article);

  if (firstContentElement) {
    return createPortal(<DocsContentHeader />, firstContentElement);
  } else if (toparticle.firstElementChild) {
    return createPortal(<DocsContentHeader />, toparticle.firstElementChild);
  }
  return null;
};
