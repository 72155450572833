/*
 * Copyright 2021 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  parseEntityRef,
  RELATION_OWNED_BY,
  stringifyEntityRef,
} from '@backstage/catalog-model';
import { useApi } from '@backstage/core-plugin-api';
import {
  catalogApiRef,
  EntityOwnerFilter,
  getEntityRelations,
  humanizeEntityRef,
  useEntityList,
} from '@backstage/plugin-catalog-react';
import {
  Checkbox,
  FormControlLabel,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import Box from '@mui/material/Box';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Autocomplete } from '@material-ui/lab';
import React, { useEffect, useMemo, useState } from 'react';
import useAsync from 'react-use/lib/useAsync';
import { humanizeEntity } from './utils';

/** @public */
export type CatalogReactEntityOwnerPickerClassKey = 'input';

const useStyles = makeStyles(
  {
    input: {},
  },
  {
    name: 'CatalogReactEntityOwnerPicker',
  },
);

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

/** @public */
/**

A React component that allows selecting entities by their owners.
It fetches the list of owners from the catalog API and renders an Autocomplete component
for selecting multiple owners. It also updates the entity list filters according to the selected owners.
@returns {React.ReactElement} - The React element representing the component.
@example
<BaseplateOwnerPicker />
*/

type OwnerOption = {
  label: string;
  entityRef: string;
};

export const BaseplateOwnerPicker = () => {
  const classes = useStyles();
  const {
    updateFilters,
    backendEntities,
    filters,
    queryParameters: { owners: ownersParameter },
  } = useEntityList();
  const catalogApi = useApi(catalogApiRef);

  const queryParamOwners = useMemo(
    () => [ownersParameter].flat().filter(Boolean) as string[],
    [ownersParameter],
  );

  const [selectedOwners, setSelectedOwners] = useState(
    queryParamOwners.length ? queryParamOwners : filters.owners?.values ?? [],
  );

  const { loading, value: ownerEntities } = useAsync(async () => {
    const ownerEntityRefs = [
      ...new Set(
        backendEntities
          .flatMap(e =>
            getEntityRelations(e, RELATION_OWNED_BY).map(o =>
              stringifyEntityRef(o),
            ),
          )
          .filter(Boolean),
      ),
    ];

    const { items: ownerEntitiesOrNull } = await catalogApi.getEntitiesByRefs({
      entityRefs: ownerEntityRefs,
    });
    const owners = ownerEntitiesOrNull.map((entity, index) => {
      if (entity) {
        return {
          label: humanizeEntity(entity, { defaultKind: entity.kind }),
          entityRef: humanizeEntityRef(entity),
        };
      }
      return {
        label: humanizeEntityRef(
          parseEntityRef(ownerEntityRefs[index], { defaultKind: 'Product' }),
          { defaultKind: 'product' },
        ),
        entityRef: ownerEntityRefs[index],
      };
    });

    return owners.sort((a, b) =>
      a.label.localeCompare(b.label, 'en-US', {
        ignorePunctuation: true,
        caseFirst: 'upper',
      }),
    );
  }, [backendEntities]);

  // Set selected owners on query parameter updates; this happens at initial page load and from
  // external updates to the page location.
  useEffect(() => {
    if (queryParamOwners.length) {
      setSelectedOwners(queryParamOwners);
    }
  }, [queryParamOwners]);

  useEffect(() => {
    if (!loading && ownerEntities) {
      updateFilters({
        owners:
          selectedOwners.length !== 0 && ownerEntities.length !== 0
            ? new EntityOwnerFilter(
                selectedOwners.map(s => s.toLocaleLowerCase()),
              )
            : undefined,
      });
    }
  }, [selectedOwners, updateFilters, ownerEntities, loading]);

  if (!loading && !ownerEntities?.length) return null;

  return (
    <Autocomplete
      multiple
      loading={loading}
      options={ownerEntities || []}
      value={
        ownerEntities?.filter((e: OwnerOption) =>
          selectedOwners.some((f: string) => f === e.entityRef),
        ) ?? []
      }
      onChange={(_: object, value: Array<OwnerOption>) =>
        setSelectedOwners(value.map(e => e.entityRef))
      }
      getOptionLabel={(option: OwnerOption) => option.label}
      renderOption={(option: OwnerOption, { selected }) => (
        <FormControlLabel
          control={
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              checked={selected}
            />
          }
          label={option.label}
        />
      )}
      size="small"
      popupIcon={<ExpandMoreIcon data-testid="owner-picker-expand" />}
      renderInput={params => (
        <TextField
          {...params}
          className={classes.input}
          variant="outlined"
          placeholder="Owner"
        />
      )}
    />
  );
};
