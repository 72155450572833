import React from 'react';
import { Link } from '@backstage/core-components';
import { useEntity } from '@backstage/plugin-catalog-react';
import { ENTITY_IS_DUPLICATED } from '@lego/plugin-baseplate-common';
import { BaseplateWarningPanel } from './CustomComponents/BaseplateWarningPanel';

export const DuplicatedEntityWarning = () => {
  const { entity } = useEntity();

  const viewUrls =
    entity.metadata.annotations?.[ENTITY_IS_DUPLICATED].split(',');

  return (
    <BaseplateWarningPanel severity="error" title="Duplicate entities found">
      <p>
        Detected duplicates with the name "{entity.metadata.name}" in the
        namespace "{entity.metadata.namespace}", which conflicts with the
        ingestion of entities. Please see the links below, that links to the
        system-of-record objects in question.
      </p>
      {viewUrls?.map(url => (
        <p>
          <Link target="_blank" to={url}>
            {url}
          </Link>
        </p>
      ))}
    </BaseplateWarningPanel>
  );
};
