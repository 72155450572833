import { Entity, EntityMeta } from '@backstage/catalog-model';

export type azureEnvConfig = {
  clientId: string;
  tenantId: string;
};

export type oboFlowEnvConfig = {
  clientId: string;
  tenantId: string;
  secret: string;
  oboFlowScope: string;
  middleTierApiScope: string;
};

/** Available kinds of Baseplate */
export type AvailableKinds =
  | 'API'
  | 'Application'
  | 'Community'
  | 'Component'
  | 'Group'
  | 'Identity'
  | 'Location'
  | 'Product'
  | 'Template'
  | 'Tool'
  | 'User'
  | 'Workspace';

// todo: remove the type and only use the enum
export enum AvailableKindsEnum {
  Product = 'Product',
  Application = 'Application',
  Component = 'Component',
  API = 'API',
  System = 'System',
  User = 'User',
  Group = 'Group',
  Resource = 'Resource',
  Location = 'Location',
}

/** Available types of Baseplate */
export type AvailableTypes =
  | 'documentation'
  | 'plugin'
  | 'external-link'
  | 'service'
  | 'repository'
  | 'application';

export declare type LeanixEntity = Entity & {
  metadata: EntityMeta & {
    leanixId: string;
  };
};

/**
 * A structure allowing other arbitrary metadata to be provided by analytics
 * event emitters.
 *
 */

export type BaseplateAnalyticsEventAttributes = {
  key: string;
} & {
  [attribute in string]: string | boolean | number;
};
