import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  fetchApiRef,
} from '@backstage/core-plugin-api';
import { MsSQLApiClient } from './api';
import { rootEdgeRef } from './routes';
import { MsSQLApiRef } from './api/MsSQLApi';

export const edgePlugin = createPlugin({
  id: 'edge-platform',
  routes: {
    root: rootEdgeRef,
  },
  apis: [
    createApiFactory({
      api: MsSQLApiRef,
      deps: {
        fetchApi: fetchApiRef,
        discoveryApi: discoveryApiRef,
      },
      factory: ({ fetchApi, discoveryApi }) =>
        new MsSQLApiClient({
          discoveryApi,
          fetchApi,
        }),
    }),
  ],
});

export const EdgePage: () => JSX.Element = edgePlugin.provide(
  createRoutableExtension({
    name: 'EdgePage',
    component: () => import('../src/components/Router').then(m => m.Router),
    mountPoint: rootEdgeRef,
  }),
);
