import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function MicrosoftWindowsos(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path
        d="M37.75 4a1.5 1.5 0 0 0-1.739-1.481l-15.5 2.5A1.5 1.5 0 0 0 19.25 6.5V17a1.5 1.5 0 0 0 1.5 1.5h15.5a1.5 1.5 0 0 0 1.5-1.5V4Zm-15.5 11.5V7.777l12.5-2.016V15.5h-12.5ZM16.75 7a1.5 1.5 0 0 0-1.694-1.487l-11.5 1.5A1.5 1.5 0 0 0 2.25 8.5V17a1.5 1.5 0 0 0 1.5 1.5h11.5a1.5 1.5 0 0 0 1.5-1.5V7Zm-11.5 8.5V9.817l8.5-1.109V15.5h-8.5Zm-3 7a1.5 1.5 0 0 1 1.5-1.5h11.5a1.5 1.5 0 0 1 1.5 1.5v10a1.5 1.5 0 0 1-1.694 1.487l-11.5-1.5A1.5 1.5 0 0 1 2.25 31v-8.5Zm3 1.5v5.683l8.5 1.109V24h-8.5Zm31-3h-15.5a1.5 1.5 0 0 0-1.5 1.5v11a1.5 1.5 0 0 0 1.261 1.48l15.5 2.5A1.5 1.5 0 0 0 37.75 36V22.5a1.5 1.5 0 0 0-1.5-1.5Zm-14 11.222V24h12.5v10.239l-12.5-2.017Z"
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </SvgIcon>
  );
}
