import { createApiRef } from '@backstage/core-plugin-api';
import { AMMA_ENVIRONMENT } from '@lego/plugin-baseplate-amma-common';
import { IUpstreamStatusResponse } from '../types';

export const apiMetricsApiRef = createApiRef<IApiMetricsClient>({
  id: 'plugin.amma-metrics.service',
});

export interface IApiMetricsClient {
  getUpstreamStatus(
    environment: AMMA_ENVIRONMENT,
  ): Promise<IUpstreamStatusResponse>;
}
