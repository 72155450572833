import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ArrowRight(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="m33.5 21.094-11 10.5c-.625.562-1.563.562-2.125-.063-.563-.625-.563-1.562.063-2.125l8.312-7.937H7.5c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5h21.25l-8.313-7.875c-.625-.563-.625-1.563-.062-2.125.563-.625 1.563-.625 2.125-.063l11 10.5c.313.313.5.688.5 1.063 0 .437-.188.812-.5 1.125Z" />
    </SvgIcon>
  );
}
