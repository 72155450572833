import { DashboardIcon, Page } from '@backstage/core-components';
import { useRouteRef } from '@backstage/core-plugin-api';
import { useAADGroupMembership } from '@lego/plugin-baseplate-access-control';
import { SideMenu } from '@lego/plugin-baseplate-core-components';
import { ADMIN_GROUP_ID } from '@lego/plugin-baseplate-people-to-product-common';
import { Grid } from '@material-ui/core';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import React from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import EmployeePageHeader from './features/EmployeePageHeader/EmployeePageHeader';
import { SwitchToggle } from '@lego/plugin-baseplate-core-components';
import { setExternalToggle } from '@lego/plugin-baseplate-people-to-product-common';

import {
  nonDigitalProductManagersRouteRef,
  openPositionsRouteRef,
  overviewRouteRef,
  reportsRouteRef,
} from './routes';
import {
  NonDigitalProductManagersPage,
  OpenPositionsPage,
  OverviewPage,
  ReportsPage,
} from './pages';

export function Router() {
  const navigate = useNavigate();

  const { isUserMember: isUserAdmin } = useAADGroupMembership([ADMIN_GROUP_ID]);

  const [toggleExternal, setToggleState] = React.useState<boolean>(false);

  const [activePage, setActivePage] = React.useState<string>(
    window.location.pathname,
  );

  const [reloadKey, setReloadKey] = React.useState<number>(0);

  const overviewLink = useRouteRef(overviewRouteRef);
  const reportsLink = useRouteRef(reportsRouteRef);
  const openPositionsLink = useRouteRef(openPositionsRouteRef);
  const nonDigitalProductManagersLink = useRouteRef(
    nonDigitalProductManagersRouteRef,
  );

  const handleOnSideMenuItemClick = (label: string) => {
    setActivePage(label);
    navigate(label);
    setReloadKey(prevKey => prevKey + 1);
  };

  const handleExternalTgl = (currentState: boolean) => {
    setToggleState(!currentState);
    setExternalToggle(!currentState);

    handleOnSideMenuItemClick(activePage);
  };

  return (
    <Page themeId="tool">
      <EmployeePageHeader />
      <SideMenu.Wrapper>
        <SideMenu.Section label="General" />
        <SideMenu.Item
          label="Overview"
          icon={<DashboardIcon />}
          onClick={() => handleOnSideMenuItemClick(overviewLink())}
          active={activePage === overviewLink()}
        />
        <SideMenu.Section label="Manage" />
        <SideMenu.Item
          label="Reports"
          icon={<PeopleAltIcon />}
          onClick={() => handleOnSideMenuItemClick(reportsLink())}
          active={activePage === reportsLink()}
        />
        <SideMenu.Item
          label="Open positions"
          icon={<EmojiPeopleIcon />}
          onClick={() => handleOnSideMenuItemClick(openPositionsLink())}
          active={activePage === openPositionsLink()}
        />
        <SideMenu.Item
          label="Non-digital product managers"
          icon={<SupervisedUserCircleIcon />}
          onClick={() =>
            handleOnSideMenuItemClick(nonDigitalProductManagersLink())
          }
          active={activePage === nonDigitalProductManagersLink()}
        />
        <SideMenu.Section label="Options" />
        <SwitchToggle
          label="Include externals"
          id="tglIncludeExternals"
          onChange={() => handleExternalTgl(toggleExternal)}
        />
      </SideMenu.Wrapper>
      <Grid container key={reloadKey}>
        <Grid item xs>
          <Routes>
            <Route path="/" element={<Navigate to={overviewLink()} />} />
            <Route
              path={`/${overviewRouteRef.path}`}
              element={<OverviewPage />}
            />
            <Route
              path={`/${reportsRouteRef.path}`}
              element={<ReportsPage />}
            />
            <Route
              path={`/${openPositionsRouteRef.path}`}
              element={<OpenPositionsPage />}
            />
            {isUserAdmin && (
              <Route
                path={`/${nonDigitalProductManagersRouteRef.path}`}
                element={<NonDigitalProductManagersPage />}
              />
            )}
          </Routes>
        </Grid>
      </Grid>
    </Page>
  );
}
