import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function RocketLaunch(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M16.356 19.831c.813.438 1.563.938 2.188 1.625.687.625 1.187 1.375 1.625 2.188 6.562-1.875 9.75-5 11.312-7.875 1.563-2.938 1.688-6.125 1.375-8.625-2.5-.313-5.687-.188-8.625 1.375-2.875 1.562-6 4.75-7.875 11.312Zm11.688 3.688v5.125c0 1.562-.875 3.062-2.25 3.875l-5.5 3.25c-.5.312-1.063.312-1.5 0-.5-.25-.75-.75-.75-1.313v-7.125a5.39 5.39 0 0 0-1.563-3.812 5.39 5.39 0 0 0-3.812-1.563H5.544c-.563 0-1.063-.25-1.313-.75-.312-.437-.312-1 0-1.5l3.25-5.5c.813-1.375 2.313-2.25 3.875-2.25h5.125c5.125-8.187 13.063-8.5 17.75-7.625.75.125 1.313.688 1.438 1.438.875 4.687.562 12.625-7.625 17.75ZM5.794 35.956a1.64 1.64 0 0 1-1.75-1.75c.062-2.375.437-6.5 2.625-8.625 2.125-2.125 5.625-2.125 7.75 0s2.125 5.625 0 7.75c-2.125 2.188-6.25 2.563-8.625 2.625Zm3.125-4c.812-.062 1.937-.25 2.562-.875a1.74 1.74 0 0 0 0-2.562 1.74 1.74 0 0 0-2.562 0c-.625.625-.813 1.75-.875 2.562 0 .5.375.875.875.875Zm15.625-19c0-.875.437-1.687 1.25-2.125.75-.437 1.687-.437 2.5 0 .75.438 1.25 1.25 1.25 2.125 0 .938-.5 1.75-1.25 2.188-.813.437-1.75.437-2.5 0-.813-.438-1.25-1.25-1.25-2.188Z" />
    </SvgIcon>
  );
}
