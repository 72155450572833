import { Modal, TextField, makeStyles } from '@material-ui/core';
import React from 'react';
import { LangChainChat } from '../LangChainComponent';

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: '60%',
    maxHeight: '100%',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: '50%',
    left: '50%',
    transform: `translate(-${50}%, -${50}%)`,
    overflow: 'auto',
  },
}));

export interface AiHelperProps {
  userHint: string;
  userHintInModal: string;
  context: string;
  model?: 'gpt-4o';
}

export const AiHelper = ({
  userHint = 'Ask LEGO ChatAI anything about this page...',
  userHintInModal = 'Ask me anything about the page...',
  context,
  model = 'gpt-4o',
}: AiHelperProps) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <TextField
        variant="outlined"
        placeholder={userHint}
        fullWidth
        onClick={handleOpen}
        style={{ paddingLeft: 16, paddingRight: 16 }}
      />
      <Modal open={open} onClose={handleClose}>
        <div className={classes.paper}>
          <LangChainChat
            verbose
            userHint={userHintInModal}
            context={context}
            model={model}
          />
        </div>
      </Modal>
    </React.Fragment>
  );
};
