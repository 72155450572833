import React from 'react';
import { Product } from '@lego/plugin-baseplate-people-to-product-common';
import { Spinner } from '@lego/plugin-baseplate-core-components';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { useApi } from '@backstage/core-plugin-api';
import useAsync from 'react-use/lib/useAsync';

interface ProductDropdownProps {
  productsToExclude: Product[];
  setSelectedProduct: React.Dispatch<React.SetStateAction<Product | null>>;
}

function ProductDropdown({
  productsToExclude,
  setSelectedProduct,
}: ProductDropdownProps) {
  const catalogApi = useApi(catalogApiRef);

  const { loading, value: productOptions } = useAsync(async () => {
    const { items } = await catalogApi.getEntities({
      filter: { kind: 'Product' },
    });

    return items
      .filter(
        productEntity =>
          !productsToExclude.find(
            product => product.id === productEntity.metadata.name,
          ),
      )
      .map(productEntity => ({
        label: productEntity.metadata.title
          ? `${productEntity.metadata.title} (${productEntity.metadata.name})`
          : '',
        product: {
          id: productEntity.metadata.name || '',
          name: productEntity.metadata.title || '',
        },
      }));
  });

  const handleOnChange = (_event: any, option: any) => {
    if (!option) {
      setSelectedProduct(null);
      return;
    }
    setSelectedProduct(option.product);
  };

  return (
    <Autocomplete
      id="product-dropdown"
      options={productOptions || []}
      autoSelect
      onChange={handleOnChange}
      getOptionSelected={(option, value) => option.label === value.label}
      disabled={loading}
      style={{ minWidth: 200 }}
      getOptionLabel={option => option.label}
      renderInput={params => (
        <TextField
          {...params}
          label={loading ? 'Loading...' : 'Select a product'}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <Spinner size="large" width={30} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}

export default ProductDropdown;
