import { Icon, baseplateStyles } from '@lego/plugin-baseplate-core-components';
import React, { ChangeEvent, KeyboardEvent } from 'react';

interface Props {
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown: (event: KeyboardEvent<HTMLInputElement>) => void;
  placeholder: string;
  searchScope: string[];
}

const useStyles = baseplateStyles(theme => ({
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 3),
    gap: 8,
    borderBottom: `1px solid ${theme.semantic.border.default}`,
  },
  input: {
    width: '100%',
    fontSize: '1rem',
    fontFamily: theme.typography.fontFamily,
    padding: theme.spacing(2, 0),
    border: 0,
    background: 'none',
    color: theme.semantic.text.primary,
    '&:focus': {
      outline: 'none',
    },
    '&::placeholder': {
      color: theme.semantic.text.tertiary,
    },
  },
  scopeItem: {
    display: 'inline-flex',
    padding: '0.25rem 0.5rem',
    width: 'fit-content',
    borderRadius: theme.primitive.borderRadius.default,
    backgroundColor: theme.component.tag.neutral.background,
    color: theme.component.tag.neutral.text,
  },
}));

export const SearchModalInput = ({
  value,
  onChange,
  onKeyDown,
  placeholder,
  searchScope,
}: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.inputContainer}>
      <Icon icon="magnifying-glass" />
      {searchScope.map(
        scope =>
          scope.length > 0 && <div className={classes.scopeItem}>{scope}</div>,
      )}
      <input
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        className={classes.input}
      />
    </div>
  );
};
