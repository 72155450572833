import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import {
  Drawer,
  Grid,
  IconButton,
  Typography,
  makeStyles,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info';
import DeleteIcon from '@material-ui/icons/Delete';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import { BackstageTheme } from '@backstage/theme';
import {
  BaseplateButton,
  FiveStarFeedbackButton,
  PREFIX_FEEDBACK_SUBJECT,
} from '@lego/plugin-baseplate-core-components';
import {
  RequestParams,
  ResponseParams,
  History,
  HistoryItem,
} from '@lego/plugin-baseplate-ai-common';
import { DeploymentSelectionBox, TemperatureSlider } from '../FineTuning';

interface Props {
  open: boolean;
  setAiRequest: (value: React.SetStateAction<RequestParams>) => void;
  aiRequest: RequestParams;
  setOpen: (value: boolean) => void;
  verbose: boolean;
  selectedSession: number | null;
  setSelectedSession: (value: React.SetStateAction<number | null>) => void;
  histories: History;
  setAiResponse: (value: React.SetStateAction<ResponseParams>) => void;
}

const useStyles = makeStyles<BackstageTheme>(theme => ({
  historyButton: {
    border: `1px solid ${theme.palette.border}`,
    borderRadius: '8px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  menuText: {
    paddingBottom: 10,
    paddingTop: 20,
  },
  menuElement: {
    paddingBottom: 15,
  },
  drawer: {
    width: '100%',
    flexShrink: 0,
  },
  drawerPaper: {
    overflow: 'auto',
    overflowX: 'hidden',
    width: '450px',
    borderRadius: '8px 0px 0px 8px',
    position: 'absolute',
    height: '100vh',
  },
  drawerRoot: {
    position: 'absolute',
    right: 0,
  },
  drawerContainer: {},
  deleteButton: {
    marginLeft: 'auto',
  },
  sessionContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  messageButton: {
    paddingLeft: 10,
    paddingRight: 10,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

export const LangChainDrawer: React.FC<Props> = ({
  open,
  aiRequest,
  setAiRequest,
  setOpen,
  verbose,
  selectedSession,
  setSelectedSession,
  histories,
}) => {
  const classes = useStyles();
  return (
    <Drawer
      classes={{
        root: classes.drawerRoot,
        paper: classes.drawerPaper,
      }}
      className={classes.drawer}
      variant="temporary"
      anchor="right"
      onClose={() => setOpen(false)}
      open={open}
    >
      <Grid container>
        <Grid item lg />
        <Grid item>
          <BaseplateButton onClick={() => setOpen(!open)}>
            <CloseIcon />
          </BaseplateButton>
        </Grid>
      </Grid>

      <Grid id="history-chat" container spacing={1}>
        <Grid item xs={12}>
          <BaseplateButton
            onClick={() => {
              setAiRequest({
                prompt: '',
                pastMessages: [],
                azureOpenAIApiDeploymentName: 'gpt-4o',
                temperature: 0.2,
                verbose,
              });
              setSelectedSession(null);
              setOpen(!open);
            }}
            className={classes.historyButton}
          >
            <AddIcon />
            <Typography className={classes.messageButton}>New Chat</Typography>
          </BaseplateButton>
          <Typography variant="body1" className={classes.menuText}>
            Chat history:
          </Typography>
          {histories.items
            ? histories.items.map((historyItem: HistoryItem, index: number) => (
                <div key={index} className={classes.sessionContainer}>
                  <BaseplateButton
                    onClick={() => {
                      setSelectedSession(index);
                      setAiRequest(historyItem.data);
                      setOpen(!open);
                    }}
                    className={classes.historyButton}
                  >
                    <ChatBubbleOutlineIcon />
                    <Typography className={classes.messageButton}>
                      {historyItem.summary}
                    </Typography>
                  </BaseplateButton>
                  <IconButton
                    className={classes.deleteButton}
                    onClick={() => {
                      if (selectedSession === index) {
                        setSelectedSession(null);
                        setAiRequest({
                          prompt: '',
                          pastMessages: [],
                          azureOpenAIApiDeploymentName: 'gpt-4o',
                          temperature: 0.2,
                          verbose,
                        });
                      }
                      histories.delete(index);
                      setOpen(!open);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
              ))
            : null}
        </Grid>
      </Grid>
      {/* <hr className={classes.hr} /> */}
      <Grid id="deployment-selection" container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="body1" className={classes.menuText}>
            Fine-tune your LEGO ChatAI:
          </Typography>
          <Grid className={classes.menuElement}>
            <Typography variant="subtitle1">
              Select model{' '}
              <Tooltip
                title={
                  <div style={{ whiteSpace: 'pre-line' }}>
                    {`
                    Model gpt-4o
                      pro: smartest GenAI model in Azure OpenAI
                      cons: nothing relevant to mention in the use case Baseplate currently provides with the GenAI models
                    `}
                  </div>
                }
                arrow
              >
                <InfoIcon fontSize="inherit" />
              </Tooltip>
            </Typography>
            <DeploymentSelectionBox
              onChange={newModel => {
                setAiRequest({
                  ...aiRequest,
                  azureOpenAIApiDeploymentName: newModel,
                });
              }}
              model={aiRequest.azureOpenAIApiDeploymentName}
            />
          </Grid>
          <Grid>
            <Typography variant="subtitle1">
              Select temperature{' '}
              <Tooltip
                title="Controls randomness. Lowering the temperature means that the model will produce more repetitive and deterministic responses. Increasing the temperature will result in more unexpected or creative responses."
                arrow
              >
                <InfoIcon fontSize="inherit" />
              </Tooltip>
            </Typography>
            <TemperatureSlider
              onChange={newTemp => {
                setAiRequest({
                  ...aiRequest,
                  temperature: newTemp,
                });
              }}
              value={aiRequest.temperature}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid id="feedback" container spacing={1}>
        <Grid item xs={12}>
          <FiveStarFeedbackButton
            position="top-right"
            feedbackSubject={`${PREFIX_FEEDBACK_SUBJECT} LEGO ChatAI?`}
            trackingIdentifier="/ai"
            onClick={() => setOpen(!open)}
          >
            Give your valuable feedback
          </FiveStarFeedbackButton>
        </Grid>
      </Grid>
    </Drawer>
  );
};
