import { makeFieldSchemaFromZod } from '@backstage/plugin-scaffolder';
import { z } from 'zod';

// Define the schema for the kind property
const typeSchema = z
  .union([
    z.literal('info'),
    z.literal('warning'),
    z.literal('error'),
    z.literal('success'),
  ])
  .optional()
  .describe('Type of callout');

// Define the schema for the callout options
export const calloutExtensionWithOptionsSchema = makeFieldSchemaFromZod(
  z.string(),
  z.object({
    type: typeSchema,
  }),
);

// Define the types for the UI options and the props
export type CalloutExtensionUiOptions =
  typeof calloutExtensionWithOptionsSchema.uiOptionsType;

export type CalloutExtensionProps =
  typeof calloutExtensionWithOptionsSchema.type;

// Export the schema for the callout
export const CalloutExtensionSchema = calloutExtensionWithOptionsSchema.schema;
