import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';
import { rootRouteRef } from './routes';

export const githubPlugin = createPlugin({
  id: 'github',
  routes: {
    root: rootRouteRef,
  },
  featureFlags: [
    { name: 'github-plugin' },
    { name: 'github-repository-suggestions' },
  ],
});

export const GithubPage: () => JSX.Element = githubPlugin.provide(
  createRoutableExtension({
    name: 'GithubPage',
    component: () =>
      import('./components/GithubPage/GithubPageLandingPage').then(
        m => m.GithubPageLandingPage,
      ),
    mountPoint: rootRouteRef,
  }),
);

export const GithubCopilotPage: () => JSX.Element = githubPlugin.provide(
  createRoutableExtension({
    name: 'GithubCopilotPage',
    component: () =>
      import(
        './components/GithubCopilotToolPage/GithubCopilotLandingPage'
      ).then(m => m.GithubCopilotLandingPage),
    mountPoint: rootRouteRef,
  }),
);

export { GithubCopilotPopupCard } from './components/GithubCopilotPopupCard';
