import { baseplateStyles } from '@lego/plugin-baseplate-core-components';

export const useDefinitionTabStyles = baseplateStyles(theme => ({
  pageWrapper: {
    background: theme.semantic.background.neutral[1],
    position: 'relative',
    margin: `-24px calc(-1 * ${theme.primitive.dimensions.page.horizontalPadding})`,
    display: 'grid',
    gridTemplateColumns: '300px 1fr',
    gridTemplateAreas: `
      'sidebar header'
      'sidebar tabs'
      'sidebar content'
    `,
  },
  header: {
    padding: `2rem ${theme.primitive.dimensions.page.horizontalPadding}`,
    gridArea: 'header',
  },
  tabs: {
    gridArea: 'tabs',
    borderBottom: `1px solid ${theme.semantic.border.default}`,
  },
  content: {
    gridArea: 'content',
    minHeight: 670, // Fixes bug on tab height
  },
}));
