import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function BatteryThreeQuarters(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M31 13H7c-1.125 0-2 .938-2 2v10c0 1.125.875 2 2 2h24c1.063 0 2-.875 2-2V15c0-1.063-.938-2-2-2ZM7 10h24c2.75 0 5 2.25 5 5v1c1.063 0 2 .938 2 2v4c0 1.125-.938 2-2 2v1c0 2.813-2.25 5-5 5H7c-2.813 0-5-2.188-5-5V15c0-2.75 2.188-5 5-5Zm17 6v8H8v-8h16Z" />
    </SvgIcon>
  );
}
