/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Popover from '@material-ui/core/Popover';
import Tooltip from '@material-ui/core/Tooltip';
import { Theme, makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useApi, alertApiRef } from '@backstage/core-plugin-api';
import useCopyToClipboard from 'react-use/esm/useCopyToClipboard';
import { Icon } from '@lego/plugin-baseplate-core-components';
import { Entity, stringifyEntityRef } from '@backstage/catalog-model';

/** @public */
export type EntityContextMenuClassKey = 'button';

const useStyles = makeStyles(
  (theme: Theme) => {
    return {
      button: {
        color: theme.page.fontColor,
      },
    };
  },
  { name: 'PluginCatalogEntityContextMenu' },
);

export interface ExtraContextMenuItem {
  title: string;
  icon: React.ReactNode;
  onClick: () => void;
}

interface EntityContextMenuProps {
  extraContextMenuItems?: ExtraContextMenuItem[];
  onInspectEntity: () => void;
  entity: Entity | undefined;
}

export function EntityContextMenu(props: EntityContextMenuProps) {
  const { extraContextMenuItems, onInspectEntity, entity } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();
  const classes = useStyles();

  const onOpen = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(undefined);
  };

  const alertApi = useApi(alertApiRef);
  const [copyState, copyToClipboard] = useCopyToClipboard();
  useEffect(() => {
    if (!copyState.error && copyState.value) {
      alertApi.post({
        message: 'Copied!',
        severity: 'info',
        display: 'transient',
      });
    }
  }, [copyState, alertApi]);

  const extraItems = extraContextMenuItems && [
    ...extraContextMenuItems.map(item => (
      <MenuItem
        key={item.title}
        onClick={() => {
          onClose();
          item.onClick();
        }}
      >
        <ListItemIcon>{item.icon}</ListItemIcon>
        <ListItemText primary={item.title} />
      </MenuItem>
    )),
    <Divider key="the divider is here!" />,
  ];

  return (
    <>
      <Tooltip title="More" arrow>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          aria-expanded={!!anchorEl}
          role="button"
          onClick={onOpen}
          data-testid="menu-button"
          className={classes.button}
          id="long-menu"
        >
          <Icon icon="ellipsis-vertical" />
        </IconButton>
      </Tooltip>
      <Popover
        open={Boolean(anchorEl)}
        onClose={onClose}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        aria-labelledby="long-menu"
      >
        <MenuList autoFocusItem={Boolean(anchorEl)}>
          {extraItems}
          <MenuItem
            onClick={() => {
              onClose();
              onInspectEntity();
            }}
          >
            <ListItemIcon>
              <Icon icon="code" />
            </ListItemIcon>
            <ListItemText primary="Inspect entity" />
          </MenuItem>
          {entity && (
            <MenuItem
              onClick={() => {
                onClose();
                copyToClipboard(stringifyEntityRef(entity));
              }}
            >
              <ListItemIcon>
                <Icon icon="clipboard-plus" />
              </ListItemIcon>
              <ListItemText primary="Copy entity ref" />
            </MenuItem>
          )}
        </MenuList>
      </Popover>
    </>
  );
}
