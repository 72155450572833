import { useEntity } from '@backstage/plugin-catalog-react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useMemo } from 'react';
import { OwnedRepository } from '../types';
import { LogLevel } from '../types/Logging';
import { logToElastic } from '../util/logs';
import { useConfig } from './useConfig';
import { useLoggingData } from './useLoggingData';

export const useOwnedRepositories = () => {
  const { entity } = useEntity();
  const { BACKEND_BASE_URL, azureAccessToken } = useConfig();
  const { email, environment } = useLoggingData();
  const productId = entity.metadata.name;

  const fetchOwnedRepositories = async (): Promise<OwnedRepository[]> => {
    const response = await axios.get<OwnedRepository[]>(
      `${BACKEND_BASE_URL}/v1/product-repositories?productId=${productId}`,
      {
        headers: {
          authorization: `Bearer ${azureAccessToken}`,
        },
      },
    );
    return response.data;
  };

  const {
    data = [],
    error,
    isLoading: loading,
    refetch,
  } = useQuery<OwnedRepository[], Error>(
    ['repositories', productId],
    fetchOwnedRepositories,
    {
      enabled: !!azureAccessToken && !!BACKEND_BASE_URL,
      onError: catchError => {
        (async () => {
          await logToElastic(
            `Error fetching product repositories: ${catchError.message}.`,
            LogLevel.ERROR,
            'useOwnedRepositories.tsx',
            email,
            environment,
            BACKEND_BASE_URL,
            azureAccessToken,
          );
        })();
      },
    },
  );

  const repositories = useMemo(
    () => data.sort((a, b) => (a.name > b.name ? 1 : -1)),
    [data],
  );

  return { repositories, error, loading, refetch };
};
