import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function AlignRight(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M32.5 6.5c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5h-15c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5h15Zm0 8c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5h-25c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5h25ZM34 24c0 .875-.688 1.5-1.5 1.5h-15c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5h15c.813 0 1.5.688 1.5 1.5Zm-1.5 6.5c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5h-25c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5h25Z" />
    </SvgIcon>
  );
}
