import { ToolEntity } from '@lego/plugin-baseplate-common';

export const sortTools = (tools: ToolEntity[]): ToolEntity[] => {
  return tools.sort((a, b) => {
    const order = {
      internal: 1,
      paid: 2,
      'open-source': 3,
      'missing-licence': 4,
    };
    let aValue = order['missing-licence'];
    let bValue = order['missing-licence'];

    // Assign aValue and bValue a sorting order
    if (a.metadata.licence) {
      aValue = order[a.metadata.licence.type];
    }
    if (b.metadata.licence) {
      bValue = order[b.metadata.licence.type];
    }

    // Example:
    // aValue = internal = 1
    // bValue = paid = 2
    // return = 1 - 2 = (-1)
    // Since the result is negative, "paid" (a) will be sorted before "internal" (b)
    return aValue - bValue;
  });
};
