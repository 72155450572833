import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function AppleIos(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="m26.249 18.333.001-.002c-.005-.006-.001.002-.001.002Zm4.168 4.169.001-.002.001.004-.002-.002ZM17.5 17.083c-1.501 0-2.917 1.378-2.917 3.334 0 1.955 1.416 3.333 2.917 3.333s2.917-1.378 2.917-3.333c0-1.956-1.416-3.334-2.917-3.334Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.25 10.833a9.583 9.583 0 0 1 9.583-9.583h18.334a9.583 9.583 0 0 1 9.583 9.583v18.334a9.583 9.583 0 0 1-9.583 9.583H10.833a9.583 9.583 0 0 1-9.583-9.583V10.833Zm7.917 2.644a1.667 1.667 0 1 0 0 3.334 1.667 1.667 0 0 0 0-3.334Zm19.166 1.106c-3.19 0-4.583 2.227-4.583 3.75 0 1.334.947 2.08 1.733 2.472.75.375 1.695.612 2.472.806l.075.018c.877.22 1.56.397 2.036.635.226.113.319.2.35.236v.002c-.002.15-.281 1.248-2.083 1.248-1.81 0-2.083-1.107-2.083-1.25h-2.5c0 1.524 1.393 3.75 4.583 3.75 3.19 0 4.584-2.226 4.584-3.75 0-1.334-.947-2.08-1.733-2.472-.75-.375-1.696-.611-2.473-.805l-.074-.02c-.877-.218-1.56-.396-2.036-.634-.226-.113-.32-.2-.35-.236l-.001-.002c.003-.15.282-1.248 2.083-1.248.442 0 1 .239 1.492.648.234.195.409.394.515.553a.852.852 0 0 1 .087.156.446.446 0 0 1-.01-.107h2.5c0-.572-.262-1.083-.497-1.435a5.213 5.213 0 0 0-.995-1.088c-.759-.632-1.866-1.227-3.092-1.227Zm-16.25 5.834c0-3.107 2.316-5.834 5.417-5.834s5.417 2.727 5.417 5.834c0 3.106-2.316 5.833-5.417 5.833s-5.417-2.727-5.417-5.833ZM7.917 17.5v8.75h2.5V17.5h-2.5Z"
      />
    </SvgIcon>
  );
}
