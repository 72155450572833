import { ToolEntity } from '@lego/plugin-baseplate-common';
import {
  Card,
  Chip,
  LicenceChip,
  baseplateStyles,
} from '@lego/plugin-baseplate-core-components';
import { Typography } from '@material-ui/core';
import React from 'react';
import { truncate } from 'lodash';

type Props = {
  tool: ToolEntity;
  pavedPath?: boolean;
};

const useStyles = baseplateStyles(theme => ({
  wrapper: {
    borderRadius: theme.primitive.borderRadius.default,
    background: theme.semantic.background.neutral[1],
    overflow: 'hidden',
    border: `1px solid ${theme.semantic.border.default}`,
    '&:hover': {
      border: `1px solid ${theme.semantic.border.hover}`,
      textDecoration: 'none',
    },
  },
  content: {
    padding: '1.5rem',
  },
  title: {
    display: 'flex',
  },
  image: {
    width: '100%',
    display: 'block',
    aspectRatio: 16 / 9,
    backgroundColor: theme.semantic.background.neutral[3],
  },
  labels: {
    margin: '4px 0 8px',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '4px',
  },
}));

export const HighlightedToolCard = ({ tool, pavedPath }: Props) => {
  const classes = useStyles();
  return (
    <Card href={`/catalog/default/tool/${tool.metadata.name}`} padding="none">
      <img
        alt="tool card"
        className={classes.image}
        src={tool.spec.banner ?? tool.spec.image}
      />
      <div className={classes.content}>
        <div className={classes.title}>
          <Typography variant="subtitle2">{tool.metadata.title}</Typography>
        </div>
        <div className={classes.labels}>
          {pavedPath && <Chip label="Paved path" color="yellow" />}
          <LicenceChip licence={tool.metadata.licence} />
          {/* <Cis18Chip variant="evaluated" /> */}
        </div>
        <Typography variant="body1">
          {truncate(tool.metadata.description, {
            length: 75,
          })}
        </Typography>
      </div>
    </Card>
  );
};
