import {
  discoveryApiRef,
  fetchApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { useCallback, useEffect, useState } from 'react';

export const useAADGroupMembership = (groupIds: string[]) => {
  const [isUserMember, setIsUserMember] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const fetchApi = useApi(fetchApiRef);
  const discoveryApi = useApi(discoveryApiRef);

  const checkAccess = useCallback(async () => {
    try {
      const requestParams: string = groupIds
        .map(id => `groupIds[]=${encodeURIComponent(id)}`)
        .join('&');
      const url = await discoveryApi.getBaseUrl('access-control');
      const target = `${url}/groups?${requestParams}`;
      const res = await fetchApi.fetch(target);
      const { result } = await res.json();
      setIsUserMember(result);
    } catch (error) {
      setIsUserMember(false);
    } finally {
      setIsLoading(false);
    }
  }, [fetchApi, discoveryApi, groupIds]);

  useEffect(() => {
    void checkAccess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isUserMember, isLoading };
};
