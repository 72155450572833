import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Laptop(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M32 9H8c-.563 0-1 .5-1 1v14H4V10c0-2.188 1.75-4 4-4h24c2.188 0 4 1.813 4 4v14h-3V10c0-.5-.5-1-1-1ZM6 31h28c1.25 0 2.375-.813 2.813-2H3.124A3.05 3.05 0 0 0 6 31Zm-6-3c0-1.063.875-2 2-2h36c1.063 0 2 .938 2 2 0 3.313-2.688 6-6 6H6c-3.313 0-6-2.688-6-6Z" />
    </SvgIcon>
  );
}
