import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function BrickTwoByTwoSide(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M34.467 11.485V9.323A2.325 2.325 0 0 0 32.147 7h-8.73a2.325 2.325 0 0 0-2.32 2.323v2.162h-2.27V9.298A2.298 2.298 0 0 0 16.532 7H7.743a2.298 2.298 0 0 0-2.295 2.298v2.187H3V33h34V11.485h-2.533Zm-10.82-1.932h8.27v1.932h-8.27V9.553Zm-15.649 0h8.27v1.932h-8.27V9.553ZM34.45 30.447H5.55V14.038h28.9v16.409Z" />
    </SvgIcon>
  );
}
