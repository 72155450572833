import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function RotateRight(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M26.688 11.688C24.75 10.062 22.25 9 19.5 9c-6.125 0-11 4.938-11 11 0 6.125 4.875 11 11 11a10.9 10.9 0 0 0 6.563-2.188c.687-.5 1.625-.375 2.125.313s.375 1.625-.313 2.125C25.562 33 22.625 34 19.5 34c-7.75 0-14-6.25-14-14 0-7.688 6.25-14 14-14 3.563 0 6.813 1.375 9.313 3.563l1.875-1.876c.437-.437 1-.625 1.562-.625C33.5 7 34.5 8 34.5 9.25v7.25c0 .875-.688 1.5-1.5 1.5h-7.313c-1.25 0-2.187-.938-2.187-2.188 0-.562.188-1.124.625-1.562l2.563-2.563Zm4.812-.563L27.562 15H31.5v-3.875Z" />
    </SvgIcon>
  );
}
