import { Edit } from '@lego/klik-ui-icons';
import { icons } from '@lego/plugin-baseplate-core-components';
import { IconButton, Tooltip, Typography } from '@material-ui/core';
import React from 'react';

interface TooltipEditIconButtonProps {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
}

function TooltipEditIconButton({
  onClick,
  disabled,
}: TooltipEditIconButtonProps) {
  return (
    <Tooltip title={disabled ? '' : <Typography>Edit roles</Typography>}>
      <IconButton
        aria-label="edit"
        style={{ color: icons.default }}
        onClick={onClick}
        disabled={disabled}
      >
        <Edit />
      </IconButton>
    </Tooltip>
  );
}

export default TooltipEditIconButton;
