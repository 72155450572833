import { Entity, parseEntityRef } from '@backstage/catalog-model';
import { useRelatedEntities } from '@backstage/plugin-catalog-react';
import { Typography } from '@material-ui/core';
import React from 'react';
import { Spinner } from '../spinner';
import { AutocompleteDropdown } from '../autocomplete-dropdown';
import {
  BaseplateEntityDropdownProps,
  getName,
  getTitle,
} from '../entity-dropdown';

export type ProductRelationsPickerProps = BaseplateEntityDropdownProps & {
  // The product to find relations for.
  product: Entity;
};

/**
 * The `ProductRelationsPicker` component is a form control component that displays a dropdown menu of entities that are owned by the product you provide.
 * The list of entities is filtered by the `kind` parameter, which defaults to `Product`.
 *
 * @param {Object} props - The component's props.
 */
export const ProductRelationsPicker = (props: ProductRelationsPickerProps) => {
  const {
    helperText,
    handleChange,
    placeholder,
    kind = 'Product',
    required,
    value,
    errorMessage,
    product,
  } = props;

  const { entities, loading, error } = useRelatedEntities(product, {
    kind,
    type: kind === 'User' ? 'hasMember' : 'ownerOf',
  });

  const label =
    props.label ?? `Related Entity of Product ${product.metadata.name}`;

  if (!entities || error) {
    return <Typography>Error loading product relations.</Typography>;
  }

  return (
    <AutocompleteDropdown
      label={label}
      onInputChange={(_event, _newInputValue, reason) => {
        if (reason === 'clear') {
          handleChange(undefined as any);
        }
      }}
      placeholder={placeholder}
      disabled={loading || !entities || entities.length === 0}
      loading={loading}
      helperText={helperText}
      error={Boolean(error) || !!errorMessage}
      errorText={errorMessage ?? `Error loading ${kind}s`}
      required={required}
      onChange={(_, v) => v && handleChange(v)}
      getOptionLabel={option => getTitle(option)}
      value={
        (value &&
          (entities || []).find(
            entity =>
              getName(entity).toLocaleLowerCase() ===
              parseEntityRef(value).name.toLocaleLowerCase(),
          )) ??
        null
      }
      options={entities || []}
      autoSelect
    />
  );
};
