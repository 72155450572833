import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Font(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M21.375 6.938 31.188 31H32.5c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5h-7c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5h2.438l-2.063-5H14.062L12 31h2.5c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5h-7c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5h1.25l9.813-24.063A1.58 1.58 0 0 1 20 6c.563 0 1.125.375 1.375.938ZM24.688 23 20 11.5 15.25 23h9.438Z" />
    </SvgIcon>
  );
}
