import React from 'react';
import { StatusWarning } from '@lego/klik-ui-icons';
import { Tooltip, Typography } from '@material-ui/core';

interface TooltipStatusWarningProps {
  title: string;
}

function TooltipStatusWarning({ title }: TooltipStatusWarningProps) {
  return (
    <Tooltip title={<Typography>{title}</Typography>}>
      <StatusWarning
        color="red"
        fontSize="x-large"
        aria-label="status-warning"
      />
    </Tooltip>
  );
}

export default TooltipStatusWarning;
