import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Newspaper(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M14.5 9c-.875 0-1.5.688-1.5 1.5v19c0 .563-.125 1.063-.313 1.5H31.5c.813 0 1.5-.625 1.5-1.5v-19c0-.813-.688-1.5-1.5-1.5h-17Zm-6 25C6 34 4 32 4 29.5V11c0-.813.625-1.5 1.5-1.5.813 0 1.5.688 1.5 1.5v18.5c0 .875.625 1.5 1.5 1.5.813 0 1.5-.625 1.5-1.5v-19C10 8.062 12 6 14.5 6h17c2.438 0 4.5 2.063 4.5 4.5v19c0 2.5-2.063 4.5-4.5 4.5h-23ZM15 12.5c0-.813.625-1.5 1.5-1.5h6c.813 0 1.5.688 1.5 1.5v5c0 .875-.688 1.5-1.5 1.5h-6c-.875 0-1.5-.625-1.5-1.5v-5ZM27.5 11h2c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5h-2c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5Zm0 5h2c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5h-2c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5Zm-11 5h13c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5h-13c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5Zm0 5h13c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5h-13c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5Z" />
    </SvgIcon>
  );
}
