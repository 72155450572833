import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Bell(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M20.031 4c1.063 0 2 .938 2 2v1.125c4.5.75 8 4.688 8 9.375v2.125c0 2.813.938 5.563 2.688 7.813l.937 1.125c.375.5.438 1.125.188 1.625s-.75.812-1.313.812h-25c-.625 0-1.125-.313-1.375-.813-.25-.5-.187-1.125.188-1.625l.937-1.125c1.75-2.25 2.75-5 2.75-7.812V16.5c0-4.688 3.438-8.625 8-9.375V6c0-1.063.875-2 2-2Zm0 6h-.5a6.495 6.495 0 0 0-6.5 6.5v2.125c0 3-.875 5.875-2.5 8.375H29.47c-1.625-2.5-2.438-5.375-2.438-8.375V16.5c0-3.563-2.937-6.5-6.5-6.5h-.5Zm4 22a4.122 4.122 0 0 1-1.187 2.875c-.75.75-1.75 1.125-2.813 1.125-1.062 0-2.125-.375-2.875-1.125S16.031 33.062 16.031 32h8Z" />
    </SvgIcon>
  );
}
