import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';
import { rootRouteRef } from './routes';

export const registerApiPlugin = createPlugin({
  id: 'register-api',
  featureFlags: [{ name: 'register-api' }],
  routes: {
    root: rootRouteRef,
  },
});

export const RegisterApiPage: () => JSX.Element = registerApiPlugin.provide(
  createRoutableExtension({
    name: 'RegisterApiPage',
    component: () =>
      import('./components/RegisterApiComponent').then(
        m => m.RegisterApiComponent,
      ),
    mountPoint: rootRouteRef,
  }),
);
