import { makeFieldSchemaFromZod } from '@backstage/plugin-scaffolder';
import { z } from 'zod';

const azureTokenExtensionSchema = makeFieldSchemaFromZod(
  z.string().describe('Azure Token Extension'),
  z.object({
    scope: z
      .string()
      .describe('Scope to use with the acquisition of the token'),
  }),
);

export type AzureTokenExtensionProps = typeof azureTokenExtensionSchema.type;

/**
 * The input props that can be specified under `ui:options` for the
 * `AzureTokenExtension` field extension.
 *
 * @public
 */
export type AzureTokenExtensionUiOptions =
  typeof azureTokenExtensionSchema.uiOptionsType;

export const AzureTokenExtensionSchema = azureTokenExtensionSchema.schema;
