import React from 'react';
import { useCalloutStyles } from './Callout.styles';
import InfoRounded from '@material-ui/icons/InfoRounded';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
import SuccessIcon from '@material-ui/icons/CheckCircle';
import { Typography } from '@material-ui/core';

interface Props {
  title: string;
  type?: 'info' | 'warning' | 'error' | 'success';
  body?: string | React.ReactNode;
}

export const Callout = ({ title, body, type = 'info' }: Props) => {
  const classes = useCalloutStyles();
  const Icon = {
    info: () => <InfoRounded />,
    warning: () => <WarningIcon />,
    error: () => <ErrorIcon />,
    success: () => <SuccessIcon />,
  }[type];

  return (
    <div className={[classes.root, classes[type]].join(' ')}>
      <Typography component="p" variant="subtitle2" className={classes.title}>
        <Icon />
        {title}
      </Typography>
      {body && <div className={classes.content}>{body}</div>}
    </div>
  );
};
