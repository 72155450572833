import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ClipboardCheck(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M28 8c2.188 0 4 1.813 4 4v20c0 2.25-1.813 4-4 4H12c-2.25 0-4-1.75-4-4V12c0-2.188 1.75-4 4-4h3.063c.5-2.25 2.5-4 4.937-4 2.375 0 4.438 1.75 4.875 4H28Zm-15 3h-1c-.563 0-1 .5-1 1v20c0 .563.438 1 1 1h16c.5 0 1-.438 1-1V12c0-.5-.5-1-1-1h-1v1.5c0 .875-.688 1.5-1.5 1.5h-11c-.875 0-1.5-.625-1.5-1.5V11Zm5.5-2c0 .875.625 1.5 1.5 1.5.813 0 1.5-.625 1.5-1.5 0-.813-.688-1.5-1.5-1.5-.875 0-1.5.688-1.5 1.5Zm7.563 11.75-6.688 6.688c-.313.25-.688.437-1.063.437a1.63 1.63 0 0 1-1.062-.438l-3.313-3.375c-.624-.562-.624-1.5 0-2.125.563-.562 1.5-.562 2.126 0l2.25 2.313 5.625-5.625c.562-.563 1.5-.563 2.125 0 .562.563.562 1.563 0 2.125Z" />
    </SvgIcon>
  );
}
