import React, { useState } from 'react';
import {
  RELATION_MEMBER_OF,
  UserEntity,
  stringifyEntityRef,
} from '@backstage/catalog-model';
import {
  Avatar,
  InfoCard,
  InfoCardVariants,
  Link,
  OverflowTooltip,
} from '@backstage/core-components';
import { getEntityRelations, useEntity } from '@backstage/plugin-catalog-react';
import { Alert } from '@lego/klik-ui-icons';
import { EntityLink } from '@lego/plugin-baseplate-core-components';
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import EmailIcon from '@material-ui/icons/Email';
import PersonIcon from '@material-ui/icons/Person';
import CopyIcon from '@material-ui/icons/FileCopyOutlined';
import CheckIcon from '@material-ui/icons/Check';

const CardTitle = ({ title }: { title?: string }) =>
  title ? (
    <Box display="flex" alignItems="center">
      <PersonIcon fontSize="inherit" />
      <Box ml={1}>{title}</Box>
    </Box>
  ) : null;

export const BaseplateEntityUserProfileCard = ({
  variant,
}: {
  variant?: InfoCardVariants;
}) => {
  const { entity: user } = useEntity<UserEntity>();
  const theme = useTheme();
  const [copied, setCopied] = useState(false);

  if (!user) {
    return <Alert>User not found</Alert>;
  }

  const {
    metadata: { name: metaName },
    spec: { profile, position },
  } = user as unknown as {
    metadata: { name: string };
    spec: {
      position: string;
      profile?: { displayName?: string; picture?: string; email?: string };
    };
  };

  function copyEmail() {
    navigator.clipboard
      .writeText(profile?.email ?? '')
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
      })
      .catch(() => {
        window.console.log('Could not copy email to clipboard');
      });
  }

  const displayName = profile?.displayName ?? metaName;
  const memberOfRelations = getEntityRelations(user, RELATION_MEMBER_OF, {
    kind: 'Product',
  });
  const productManagerRelations = getEntityRelations(user, 'productManagerOf');
  const designerRelations = getEntityRelations(user, 'designerOf');
  const leadEngineerRelations = getEntityRelations(user, 'leadEngineerOf');

  const hasRole =
    productManagerRelations.length > 0 ||
    designerRelations.length > 0 ||
    leadEngineerRelations.length > 0;

  return (
    <InfoCard title={<CardTitle title={displayName} />} variant={variant}>
      <Grid container spacing={5}>
        <Grid
          item
          xs={12}
          sm={2}
          xl={1}
          style={{
            display: 'flex',
            justifyContent: 'center',
            paddingTop: theme.spacing(5),
          }}
        >
          <Avatar displayName={displayName} picture={profile?.picture} />
        </Grid>

        <Grid item md={10} xl={11}>
          <List>
            {position && (
              <ListItem>
                <ListItemText>
                  <Typography variant="h6">{position}</Typography>
                </ListItemText>
              </ListItem>
            )}
            <>
              <ListItem>
                <Grid container direction="row">
                  <Grid item>
                    <Tooltip title="Email">
                      <EmailIcon />
                    </Tooltip>
                  </Grid>
                  {profile?.email && (
                    <Grid item>
                      <Typography> Contact </Typography>
                      <Link to={`mailto:${profile.email}`}>
                        {profile.email}
                      </Link>
                      &nbsp;
                      <Tooltip title={copied ? 'Copied!' : 'Copy to clipboard'}>
                        <CopyIcon
                          fontSize="inherit"
                          onClick={copyEmail}
                          style={{
                            cursor: 'pointer',
                            color: copied ? 'green' : 'inherit',
                          }}
                        />
                      </Tooltip>
                      <Link
                        to={`https://teams.microsoft.com/l/chat/0/0?users=${profile.email}`}
                      >
                        <OverflowTooltip text="Teams Chat" />
                      </Link>
                    </Grid>
                  )}
                </Grid>
              </ListItem>
            </>

            {leadEngineerRelations.length > 0 && (
              <ListItem>
                <Grid container direction="row">
                  <Grid item>
                    <Typography> Lead Engineer</Typography>
                    {leadEngineerRelations.map(r => (
                      <EntityLink
                        key={stringifyEntityRef(r)}
                        entityRef={stringifyEntityRef(r)}
                        variant="inline-link"
                      />
                    ))}
                  </Grid>
                </Grid>
              </ListItem>
            )}
            {designerRelations.length > 0 && (
              <ListItem>
                <Grid container direction="row">
                  <Grid item>
                    <Typography> Designer</Typography>
                    {designerRelations.map(r => (
                      <EntityLink
                        key={stringifyEntityRef(r)}
                        entityRef={stringifyEntityRef(r)}
                        variant="inline-link"
                      />
                    ))}
                  </Grid>
                </Grid>
              </ListItem>
            )}
            {!hasRole && memberOfRelations.length > 0 && (
              <ListItem>
                <Grid container direction="row">
                  <Grid item>
                    <Typography> Product member</Typography>
                    {memberOfRelations.map(r => (
                      <EntityLink
                        key={stringifyEntityRef(r)}
                        entityRef={stringifyEntityRef(r)}
                        variant="inline-link"
                      />
                    ))}
                  </Grid>
                </Grid>
              </ListItem>
            )}
            {productManagerRelations.length > 0 && (
              <ListItem>
                <Grid container direction="row">
                  <Grid item>
                    <Typography> Product manager</Typography>
                    {productManagerRelations.map(r => (
                      <EntityLink
                        key={stringifyEntityRef(r)}
                        entityRef={stringifyEntityRef(r)}
                        variant="inline-link"
                      />
                    ))}
                  </Grid>
                </Grid>
              </ListItem>
            )}
          </List>
        </Grid>
      </Grid>
    </InfoCard>
  );
};
