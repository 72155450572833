import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ArrowsMinimize(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M5.46 5.414a1.471 1.471 0 0 1 2.063 0l7.438 7.438V7.477c0-.813.687-1.5 1.5-1.5.875 0 1.5.687 1.5 1.5v9c0 .875-.625 1.5-1.5 1.5H7.523c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5h5.375L5.461 7.539a1.369 1.369 0 0 1 0-2.062v-.063Zm29.126 0c.562.625.562 1.563 0 2.125l-7.438 7.438h5.375c.813 0 1.5.687 1.5 1.5 0 .875-.687 1.5-1.5 1.5h-9c-.875 0-1.5-.625-1.5-1.5v-9c0-.813.625-1.5 1.5-1.5.813 0 1.5.687 1.5 1.5v5.437l7.438-7.5a1.471 1.471 0 0 1 2.062 0h.063ZM7.523 24.977c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5h9c.813 0 1.5.687 1.5 1.5v9c0 .875-.687 1.5-1.5 1.5-.875 0-1.5-.625-1.5-1.5v-5.375l-7.437 7.437c-.625.625-1.563.625-2.125 0a1.369 1.369 0 0 1 0-2.062l7.437-7.438H7.523v-.062Zm14.5-1.5c0-.813.625-1.5 1.5-1.5h9c.813 0 1.5.687 1.5 1.5 0 .875-.687 1.5-1.5 1.5h-5.437l7.5 7.437c.562.625.562 1.563 0 2.125-.625.625-1.563.625-2.125 0l-7.438-7.437v5.375c0 .875-.687 1.5-1.5 1.5-.875 0-1.5-.625-1.5-1.5v-9Z" />
    </SvgIcon>
  );
}
