import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function MinifigureFaceless3(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M11.75 28.25c-2.41 0-4.36 1.55-5.28 3.28C5.56 33.24 5 35.88 5 37.25h3c.12-2.03 1.202-6.05 3.75-6.05h16.5c2.548 0 3.63 4.02 3.75 6.05h3c0-1.37-.56-4.01-1.47-5.72-.92-1.74-2.78-3.28-5.28-3.28h-16.5ZM29.293 6.49c-2.67-3.22-7.46-3.73-10.08-3.74h-.37c-.75 0-1.28.06-1.4.08-2.74.46-4.29 1.45-4.82 3.1-.58-.08-1.16 0-1.71.21-.43.17-.81.43-1.14.76-.32.34-.57.73-.74 1.17-.98 2.6-.49 6.22 1.46 10.76v2.92c0 2.19 1.92 4.23 4.23 4.28h10.56c2.3-.09 4.21-2.12 4.21-4.31v-2.97a11.47 11.47 0 0 0 1.28-3.51c.84-3.63.34-6.57-1.47-8.76l-.01.01Zm-17.82 2.58c.04-.09.1-.17.17-.24a.58.58 0 0 1 .28-.15c.22-.1.5-.08.64-.02.25.16.53.26.82.28.29.01.59-.05.86-.2.28-.17.52-.42.68-.72.16-.3.24-.64.24-.97 0-.33 0-1.21 2.8-1.6.07 0 6.72-.57 9.49 2.73.55.72.94 1.58 1.13 2.48.18.82.19 1.69.04 2.52l-.56-.05c-3.86-.52-7.64-1.54-11.27-3.02l-.64-.23c-.53-.2-1.08-.3-1.64-.31h-.11c-.52 0-1.03.12-1.5.35-.5.25-.94.62-1.28 1.08-.15.21-.27.44-.38.67-.08-.87 0-1.75.23-2.58v-.02Zm1.86 4.82c0-.1.02-.19.03-.28-.01.16-.02.28 0 .28 0 0 0 .01-.03.04v-.04Zm11.65 9.14h-9.99c-.81 0-1.5-.71-1.5-1.51v-8.43c.06-.14.11-.29.19-.42.04-.06.43-.54 1.52-.14l.63.23c3.44 1.37 7.02 2.39 10.65 3.01v5.76c0 .81-.69 1.5-1.5 1.5Z" />
    </SvgIcon>
  );
}
