import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function WindowRestore(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M31 7H17c-1.125 0-2 .938-2 2v1h-3V9c0-2.75 2.188-5 5-5h14c2.75 0 5 2.25 5 5v14c0 2.813-2.25 5-5 5h-1v-3h1c1.063 0 2-.875 2-2V9c0-1.063-.938-2-2-2ZM7 32c0 .563.438 1 1 1h16c.5 0 1-.438 1-1V20H7v12Zm1-20h16c2.188 0 4 1.813 4 4v16c0 2.25-1.813 4-4 4H8c-2.25 0-4-1.75-4-4V16c0-2.188 1.75-4 4-4Z" />
    </SvgIcon>
  );
}
