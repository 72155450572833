import { createApiRef } from '@backstage/core-plugin-api';
import { GitHubUser } from './github.types';

export const githubApiRef = createApiRef<GithubaApi>({
  id: 'plugin.communities.github',
});

export interface GithubaApi {
  getUserProfile(username: string): Promise<GitHubUser | null>;
  getRepo(repoUrl: string, token: string): Promise<any>;
  getRepContent(repoUrl: string, path: string, token: string): Promise<any>;
  getCollaboratorPermissionLevel(repoUrl: string, token: string): Promise<any>;
  getAuthenticatedUser(token: string): Promise<any>;
}
