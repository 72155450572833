import { ToolEntity } from '@lego/plugin-baseplate-common';
import { Button } from '@lego/plugin-baseplate-core-components';
import React from 'react';

const getEntityActions = (tool: ToolEntity) => {
  const actionsArray = Array(tool?.metadata?.actions)[0];
  if (
    typeof actionsArray !== 'object' ||
    actionsArray === null ||
    !Array.isArray(actionsArray)
  )
    return [];
  const actions = actionsArray?.map(action => {
    if (typeof action !== 'object' || action === null)
      return { url: null, title: null, icon: null };
    const actionTyped = action;
    return {
      url: actionTyped?.url,
      title: actionTyped?.title,
      icon: actionTyped?.icon,
      color: actionTyped?.color ?? 'primary',
    };
  });
  return actions;
};

interface props {
  tool: ToolEntity;
}

export const ToolsActionsBar = ({ tool }: props) => {
  const actions = getEntityActions(tool);

  if (!actions || actions.length === 0) {
    return null;
  }

  return (
    <div>
      {actions.map(({ url, title, color }, index) => {
        const action = {
          text: title ?? url,
          href: url,
          color: color,
        };
        return (
          <Button
            key={index}
            onClick={() => {
              window.open(action.href ?? '#', '_blank', 'noreferrer');
            }}
            variant={action.color ?? 'primary'}
            style={{ marginRight: 10 }}
          >
            {action.text ?? 'Action Without Title'}
          </Button>
        );
      })}
    </div>
  );
};
