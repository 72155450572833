import { Entity } from '@backstage/catalog-model';

const highlightedNames = ['engineering-handbook'];

export const isHighlighted = (entity: Entity) => {
  if (highlightedNames.includes(entity.metadata.name)) {
    return true;
  }
  return false;
};
