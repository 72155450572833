import React from 'react';
import {
  FiveStarFeedbackToastify,
  ONE_MONTH_IN_MILLISECONDS,
  doNotAskAgainForFeedback,
  wasFeedbackGiven,
} from '@lego/plugin-baseplate-core-components';

const BASEPLATE_GENERAL_FEEDBACK = 'baseplate_general_feedback';
const TOAST_DELAY = 3000;

export function FiveStarFeedbackToastifyUseBPTrigger() {
  if (wasFeedbackGiven(BASEPLATE_GENERAL_FEEDBACK)[1]) {
    if (!wasFeedbackGiven(BASEPLATE_GENERAL_FEEDBACK)[0]) {
      onlyShowOnceTheFeedbackFor1Week();
    }

    return (
      <FiveStarFeedbackToastify
        feedbackSubject="How likely are you to recommend Baseplate to a colleague?"
        trackingIdentifier={BASEPLATE_GENERAL_FEEDBACK}
        doNotAskAgainForFeedbackWithSameTrackingIdentifierInMilliSeconds={
          ONE_MONTH_IN_MILLISECONDS * 3 // cancel or answering is suppressing it for 3 months
        }
        showToastDelay={TOAST_DELAY}
        autoCloseToastAfter={20000}
      />
    );
  }

  onlyShowOnceTheUserAccessedBPAfterFirstDay();
  return <></>;
}
function onlyShowOnceTheUserAccessedBPAfterFirstDay() {
  doNotAskAgainForFeedback(BASEPLATE_GENERAL_FEEDBACK, 24 * 60 * 60 * 1000);
}

function onlyShowOnceTheFeedbackFor1Week() {
  setTimeout(() => {
    doNotAskAgainForFeedback(
      BASEPLATE_GENERAL_FEEDBACK,
      7 * 24 * 60 * 60 * 1000,
    );
  }, TOAST_DELAY + 1000);
}
