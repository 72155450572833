import { DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';
import {
  CommunityMembersResponse,
  CommunityV2,
} from '@lego/plugin-baseplate-communities-common';
import { CommunitiesV2Api } from './CommunitiesV2Api';

export class CommunitiesV2Client implements CommunitiesV2Api {
  public constructor(
    private readonly discoveryApi: DiscoveryApi,
    private readonly fetchApi: FetchApi,
  ) {}

  public async createCommunity(community: CommunityV2): Promise<CommunityV2> {
    // todo: utilise APIUtils
    const baseUrl = `${await this.discoveryApi.getBaseUrl('communities-v2')}/`;
    const response = await this.fetchApi.fetch(baseUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(community),
    });
    if (!response.ok) {
      throw new Error(
        `Failed to create community with status: ${response.status}`,
      );
    }
    const result = await response.json();
    return result;
  }

  public async updateCommunity(community: CommunityV2): Promise<CommunityV2> {
    // todo: utilise APIUtils
    const baseUrl = `${await this.discoveryApi.getBaseUrl('communities-v2')}/${
      community.id
    }`;
    const response = await this.fetchApi.fetch(baseUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(community),
    });
    if (!response.ok) {
      throw new Error(
        `Failed to update community with status: ${response.status}`,
      );
    }
    const result = await response.json();
    return result;
  }

  public async getCommunityMemberEntityRefs(
    communityId: string,
  ): Promise<CommunityMembersResponse> {
    // todo: utilise APIUtils
    const baseUrl = `${await this.discoveryApi.getBaseUrl(
      'communities-v2',
    )}/${communityId}/members`;
    const response = await this.fetchApi.fetch(baseUrl, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    });
    if (!response.ok) {
      throw new Error(
        `Failed to get community members with with status: ${response.status}`,
      );
    }
    const result = await response.json();
    return result;
  }

  public async joinCommunity(
    communityId: string,
    userEntityRef: string,
  ): Promise<CommunityV2> {
    // todo: utilise APIUtils
    const baseUrl = `${await this.discoveryApi.getBaseUrl(
      'communities-v2',
    )}/${communityId}/members`;
    const response = await this.fetchApi.fetch(baseUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({ userEntityRef }),
    });
    if (!response.ok) {
      throw new Error(
        `Failed to add member to community with status: ${response.status}`,
      );
    }
    const result = await response.json();
    return result;
  }

  public async leaveCommunity(
    communityId: string,
    userEntityRef: string,
  ): Promise<CommunityV2> {
    // todo: utilise APIUtils
    const baseUrl = `${await this.discoveryApi.getBaseUrl(
      'communities-v2',
    )}/${communityId}/members`;
    const response = await this.fetchApi.fetch(baseUrl, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({ userEntityRef }),
    });
    if (!response.ok) {
      throw new Error(
        `Failed to remove member from community with status: ${response.status}`,
      );
    }
    const result = await response.json();
    return result;
  }
}
