import { OpenAPIV3_1 } from 'openapi-types';
import { resolveRef } from './utils/refResolver';
import { UiEndpointType } from '../../../types';
import { resolveOperation } from './operation';

/*
 * Resolve the [path item object](https://swagger.io/specification/#path-item-object)
 * */
export const resolvePathItemObject = (
  path: string,
  pathItemObject: OpenAPIV3_1.PathItemObject,
  components?: OpenAPIV3_1.ComponentsObject,
  servers?: OpenAPIV3_1.ServerObject[],
): UiEndpointType[] => {
  const resolvedPathItemObject = resolveRef<OpenAPIV3_1.PathItemObject>(
    'pathItems',
    pathItemObject,
    components,
  );

  const endpoints: UiEndpointType[] = [];

  Object.entries(resolvedPathItemObject).forEach(([method, operation]) => {
    endpoints.push(
      resolveOperation({
        path,
        method: method as OpenAPIV3_1.HttpMethods,
        operation: operation as OpenAPIV3_1.OperationObject,
        components,
        servers,
      }),
    );
  });

  return endpoints;
};
