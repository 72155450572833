import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Paperclip(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M29.383 8.617c-1.625-1.625-4.313-1.625-5.938 0l-11.937 12c-2.75 2.75-2.75 7.188 0 9.875 2.687 2.75 7.125 2.75 9.875 0l9.5-9.5a1.471 1.471 0 0 1 2.062 0c.625.625.625 1.563 0 2.125l-9.437 9.5c-3.938 3.938-10.25 3.938-14.125 0-3.938-3.875-3.938-10.187 0-14.125l12-12c2.75-2.75 7.312-2.75 10.125 0 2.75 2.813 2.75 7.375 0 10.125l-11.5 11.5c-2 2-5.25 1.813-7-.375-1.5-1.875-1.313-4.562.375-6.25l9.5-9.5a1.471 1.471 0 0 1 2.062 0c.625.625.625 1.563 0 2.125l-9.437 9.5c-.625.625-.688 1.563-.125 2.25.625.75 1.75.875 2.5.125l11.5-11.5a4.15 4.15 0 0 0 0-5.875Z" />
    </SvgIcon>
  );
}
