import {
  createComponentExtension,
  createPlugin,
} from '@backstage/core-plugin-api';
import { rootRouteRef } from './routes';

export const templatePlugin = createPlugin({
  id: 'openapi-to-postmancollection',
  routes: {
    root: rootRouteRef,
  },
});

export const PostmanCollectionComponent: () => JSX.Element =
  templatePlugin.provide(
    createComponentExtension({
      name: 'PostmanCollectionComponent',
      component: {
        lazy: () =>
          import('./components/PostmanCollectionComponent').then(
            m => m.PostmanCollectionComponent,
          ),
      },
    }),
  );
