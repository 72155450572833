import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Ruler(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="m7.969 27.719 4.312 4.312c.375.375 1.063.375 1.438 0L32.03 13.72c.375-.375.375-1.063 0-1.438L27.72 7.97c-.375-.375-1.063-.375-1.438 0l-2.437 2.437 1.875 1.875c.375.375.375 1.063 0 1.438s-1.063.375-1.438 0l-1.875-1.875-2.562 2.562 1.875 1.875c.375.375.375 1.063 0 1.438s-1.063.375-1.438 0l-1.875-1.875-2.562 2.562 1.875 1.875c.375.375.375 1.063 0 1.438s-1.063.375-1.438 0l-1.875-1.875-2.562 2.562 1.875 1.875c.375.375.375 1.063 0 1.438s-1.063.375-1.438 0l-1.875-1.875L7.97 26.28c-.375.375-.375 1.063 0 1.438Zm2.187 6.437-4.312-4.312a4.036 4.036 0 0 1 0-5.688L24.156 5.844a4.036 4.036 0 0 1 5.688 0l4.312 4.312a4.036 4.036 0 0 1 0 5.688L15.844 34.156a4.036 4.036 0 0 1-5.688 0Z" />
    </SvgIcon>
  );
}
