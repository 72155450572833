/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { createApiRef } from '@backstage/core-plugin-api';
import {
  Account,
  AzureApplication,
  ClientSecret,
  CreateClientSecret,
  Group,
  ImportAccountInterface,
  ImportApplicationInterface,
  ImportGroupInterface,
} from '@lego/plugin-baseplate-identity-common';
import { Entity } from '@backstage/catalog-model';

/** @public */
export const identityApiRef = createApiRef<IdentityApi>({
  id: 'plugin.identity.core',
});

/**
 * A client for interacting with the identity plugin API.
 *
 * @public
 */
export type IdentityApi = {
  fetchApplications(product: Entity): Promise<AzureApplication[]>;
  fetchMyApplications(token: string): Promise<AzureApplication[]>;
  fetchClientSecrets(
    type: string,
    clientId: string,
    token: string,
  ): Promise<ClientSecret[]>;
  createClientSecret(
    payload: CreateClientSecret,
    type: string,
    clientId: string,
    token: string,
  ): Promise<ClientSecret>;
  deleteClientSecret(
    type: string,
    clientId: string,
    keyId: string,
    token: string,
  ): Promise<void>;
  importApplication(
    payload: ImportApplicationInterface[],
    options: { token: string },
  ): Promise<void>;
  fetchGroups(product: Entity): Promise<Group[]>;
  fetchMyGroups(token: string): Promise<Group[]>;
  importGroup(
    payload: ImportGroupInterface[],
    options: { token: string },
  ): Promise<void>;
  fetchAccounts(product: Entity): Promise<Account[]>;
  fetchMyAccounts(token: string): Promise<Account[]>;
  importAccount(
    payload: ImportAccountInterface[],
    options: { token: string },
  ): Promise<void>;
};
