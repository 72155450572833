import { FEEDBACK_SUBJECT_DEFAULT_OBJECT } from '@lego/plugin-baseplate-core-components';

function getLatestPathSegment(path: string) {
  const pathSegments = path.split('/');
  const latestSegment =
    pathSegments.length > 1 ? pathSegments[pathSegments.length - 1] : '';
  return latestSegment;
}

export interface FeedbackDetails {
  feedbackSubject: string;
  trackingIdentifier: string;
  webhook?: string;
}

export function deduceFeedbackDetails(pathname: string): FeedbackDetails {
  const feedbackDetails = [
    {
      regex: /^\/docs\//i,
      feedbackSubject: (identifier: string) =>
        ` specific documentation ${getLatestPathSegment(identifier)}?`,
      trackingIndexes: [0, 5],
    },
    {
      regex: /^\/docs/i,
      feedbackSubject: 'documentation overview page?',
      trackingIndexes: [0, 5],
    },
    {
      regex: /^\/catalog\/default\/product\/PRO-\d+/i,
      feedbackSubject: (identifier: string) =>
        ` specific ${getLatestPathSegment(identifier)} product page?`,
      trackingIndexes: [0, 5],
    },
    {
      regex: /^\/products/i,
      feedbackSubject: 'products overview page?',
      trackingIndexes: [0, 4],
    },
    {
      regex: /^\/catalog\/.*\/api\/.*/i,
      feedbackSubject: (identifier: string) =>
        ` specific ${getLatestPathSegment(identifier)} API page?`,
      trackingIndexes: [0, 5],
    },
    {
      regex: /^\/api-docs/i,
      feedbackSubject: 'API overview page?',
      trackingIndexes: [0, 4],
    },
    {
      regex: /^\/search/i,
      feedbackSubject: 'search page?',
      trackingIndexes: [0, 4],
    },
    {
      regex: /^\/$/i,
      feedbackSubject: 'dashboard page?',
      trackingIndexes: [0, 0],
    },
    {
      regex: /^\/catalog\/default\/tool\/.+/i,
      feedbackSubject: (identifier: string) =>
        ` specific ${getLatestPathSegment(identifier)} tool page?`,
      trackingIndexes: [0, 5],
    },
    {
      regex: /^\/tools/i,
      feedbackSubject: 'tools overview page?',
      trackingIndexes: [0, 5],
    },
    {
      regex: /^\/create\/templates\/default\/.+/i,
      feedbackSubject: (identifier: string) =>
        ` specific ${getLatestPathSegment(identifier)} accelerator page?`,
      trackingIndexes: [0, 5],
    },
    {
      regex: /^\/create/i,
      feedbackSubject: 'accelerators overview page?',
      trackingIndexes: [0, 4],
    },
    {
      regex: /^\/ai/i,
      feedbackSubject: 'LEGO ChatAI?',
      trackingIndexes: [0, 4],
      webhook:
        'https://legogroup.webhook.office.com/webhookb2/65a88209-7eaf-410a-8df8-0941a71b4f96@1d063515-6cad-4195-9486-ea65df456faa/IncomingWebhook/43ba146168a642c390690a8ae7269920/071c03bc-ab1f-4aeb-977c-cacae3abdb9e',
    },
  ];

  for (const detail of feedbackDetails) {
    if (detail.regex.test(pathname)) {
      const trackingIdentifier = pathname
        .split('/')
        .slice(...detail.trackingIndexes)
        .join('/');
      const feedbackSubject =
        typeof detail.feedbackSubject === 'string'
          ? detail.feedbackSubject
          : detail.feedbackSubject(trackingIdentifier);
      return {
        trackingIdentifier,
        feedbackSubject,
        webhook: detail.webhook,
      };
    }
  }

  return {
    trackingIdentifier: pathname,
    feedbackSubject: FEEDBACK_SUBJECT_DEFAULT_OBJECT,
  };
}
