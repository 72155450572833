import { baseplateStyles } from '../../utils';

export const useSelectableCardStyles = baseplateStyles(token => ({
  outerContainer: {
    '&+&': {
      marginTop: '1rem',
    },
  },
  container: {
    display: 'flex',
    width: '100%',
    textAlign: 'left',
    alignItems: 'center',
    gap: '1rem',
    backgroundColor: token.semantic.background.neutral[1],
    borderRadius: token.primitive.borderRadius.default,
    border: `1px solid ${token.semantic.border.default}`,
    padding: '1rem',
    cursor: 'pointer',
    '&:hover': {
      borderColor: token.semantic.border.hover,
    },
    '&:focus-visible': {
      boxShadow: `0 0 0 2px ${token.semantic.shape.info}44`,
    },
  },
  indicator: {
    width: 24,
    height: 24,
    border: `1px solid ${token.semantic.border.default}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&$radio': {
      borderRadius: '50%',
    },
    '&$checkbox': {
      borderRadius: token.primitive.borderRadius.sm,
    },
  },
  radio: {}, // Only for instantiating the class
  checkbox: {}, // Only for instantiating the class
  textContainer: {
    width: 'min-content',
    flexGrow: 1,
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
  },
  input: {
    position: 'absolute',
    opacity: 0,
    cursor: 'pointer',
    height: 0,
    width: 0,
    '&:disabled + $container': {
      opacity: 0.5,
      pointerEvents: 'none',
    },
    '&:checked + $container': {
      borderColor: token.semantic.border.info,
      backgroundColor: token.semantic.background.info,

      '& $indicator$radio': {
        '&::after': {
          content: '""',
          display: 'block',
          width: 16,
          height: 16,
          backgroundColor: token.component.button.primary.background.default,
          borderRadius: '50%',
          margin: 'auto',
        },
      },
      '& $indicator$checkbox': {
        backgroundColor: token.component.button.primary.background.default,
        color: token.component.button.primary.text,
        border: 0,
      },
    },
  },
}));
