import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ObjectsColumn(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M9 9v10h6V9H9ZM6 9a3 3 0 0 1 3-3h6c1.625 0 3 1.375 3 3v10a3 3 0 0 1-3 3H9c-1.688 0-3-1.313-3-3V9Zm19 12v10h6V21h-6Zm-3 0a3 3 0 0 1 3-3h6c1.625 0 3 1.375 3 3v10a3 3 0 0 1-3 3h-6c-1.688 0-3-1.313-3-3V21Zm-7 6H9v4h6v-4Zm-6-3h6c1.625 0 3 1.375 3 3v4a3 3 0 0 1-3 3H9c-1.688 0-3-1.313-3-3v-4a3 3 0 0 1 3-3ZM25 9v4h6V9h-6Zm-3 0a3 3 0 0 1 3-3h6c1.625 0 3 1.375 3 3v4a3 3 0 0 1-3 3h-6c-1.688 0-3-1.313-3-3V9Z" />
    </SvgIcon>
  );
}
