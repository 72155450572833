import {
  AnalyticsData,
  SearchParams,
} from '@lego/plugin-baseplate-analytics-common';

import { createApiRef } from '@backstage/core-plugin-api';

export const AnalyticsApiApiRef = createApiRef<AnalyticsApi>({
  id: 'plugin.analytics.service',
});

export interface AnalyticsApi {
  fetchDocsAnalytics(params: SearchParams): Promise<AnalyticsData | undefined>;
}
