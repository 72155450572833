import { FeatureFlagged } from '@backstage/core-app-api';
import { useEntity } from '@backstage/plugin-catalog-react';
import { ProductHealthBetaCard } from '@lego/backstage-plugin-finops-scorecard';
import { useAADGroupMembership } from '@lego/plugin-baseplate-access-control';
import { AsmaPage } from '@lego/plugin-baseplate-asma';
import { EntityLayout } from '@lego/plugin-baseplate-catalog';
import { CloudPage } from '@lego/plugin-baseplate-cloud';
import {
  Card,
  ProductRelationsTree,
  useEnvironmentStage,
  useUserContext,
} from '@lego/plugin-baseplate-core-components';
import { ProductEntity } from '@lego/plugin-baseplate-eagraph-common';
import { EdgePage } from '@lego/plugin-baseplate-edge-platform';
import { ElasticPage } from '@lego/plugin-baseplate-elastic';
import { FossIdPage } from '@lego/plugin-baseplate-fossid';
import {
  GitHubConfigContextProvider,
  GithubPage,
} from '@lego/plugin-baseplate-github';
import { HostingPlatform } from '@lego/plugin-baseplate-hosting-platform';
import { IdentityPage } from '@lego/plugin-baseplate-identity';
import { JiraPage } from '@lego/plugin-baseplate-jira';
import { EntityEventsCard } from '@lego/plugin-baseplate-learning-and-development';
import { MacBuildCloudPage } from '@lego/plugin-baseplate-mac-build-cloud';
import { ANNOTATION_LEANIX_REQUIRES_MAC_BUILD_CLOUD_APPLICATION } from '@lego/plugin-baseplate-mac-build-cloud-common';
import { EntityComponent } from '@lego/plugin-baseplate-pager-duty-form';
import { SnykPage } from '@lego/plugin-baseplate-snyk';
import { VmSnapshotPage } from '@lego/plugin-baseplate-vm-snapshot';
import { VulnerabilityGamificationCard } from '@lego/plugin-baseplate-vulnerability-gamification';
import { Grid, makeStyles } from '@material-ui/core';
import { isPluginApplicableToEntity as isPagerDutyAvailable } from '@pagerduty/backstage-plugin';
import React from 'react';
import { entityWarningContent } from '../ContentSections';
import { BaseplateAboutCard } from '../CustomComponents/BaseplateAboutCard';
import { ProductEmployeesList } from '../products';

const useStyles = makeStyles(_theme => ({
  aboutCard: {
    '& > .bp-about-card ': {
      '& > div': {
        flexWrap: 'wrap',
      },
    },
    marginBottom: '16px',
  },
  productRelationsTreeWrapper: {
    marginTop: '-1rem',
  },
}));

const ProductPage = () => {
  const classes = useStyles();
  const { isTestEnvironment } = useEnvironmentStage();
  const { entity }: { entity: ProductEntity } = useEntity();
  const { product } = useUserContext();
  const { isUserMember } = useAADGroupMembership([
    'abc9a64b-fefc-4ee2-9329-40fb7b07d8f4',
  ]);

  const productId = entity.metadata.name;

  const requiresMacBuildCloudApplicationString =
    entity.metadata.annotations?.[
      ANNOTATION_LEANIX_REQUIRES_MAC_BUILD_CLOUD_APPLICATION
    ];
  const requiresMacBuildCloudApplication =
    requiresMacBuildCloudApplicationString &&
    requiresMacBuildCloudApplicationString.toLowerCase() === 'true';

  const hasCurrentUserPagerDutyTab = productId === product?.metadata.name;

  return (
    <EntityLayout
      breadcrumbLinks={[
        {
          label: entity.spec.domain.name,
          href: `/catalog/default/Group/${entity.spec.domain.id}`,
        },
        {
          label: entity.spec.domain.subDomain.name,
          href: `/catalog/default/Group/${entity.spec.domain.subDomain.id}`,
        },
      ]}
    >
      <EntityLayout.Route path="/" title="Overview">
        <Grid container spacing={1} style={{ padding: '8px' }}>
          {entityWarningContent}
          <Grid item xs={12} lg={4} style={{ padding: '8px' }}>
            <Grid item className={classes.aboutCard}>
              <BaseplateAboutCard />
              {/* <OverviewCard /> */}
            </Grid>
            <Grid className={classes.aboutCard}>
              <EntityEventsCard />
            </Grid>
            <FeatureFlagged with="vulnerability-gamification">
              <Grid item>
                <VulnerabilityGamificationCard />
              </Grid>
            </FeatureFlagged>
          </Grid>
          <Grid item xs={12} lg={4} style={{ padding: '8px' }}>
            <Card title="Ownership">
              <div className={classes.productRelationsTreeWrapper}>
                <ProductRelationsTree product={entity} showProduct={false} />
              </div>
            </Card>
          </Grid>
          <Grid item xs={12} lg={4} style={{ padding: '8px' }}>
            <ProductEmployeesList />
            <ProductHealthBetaCard />
          </Grid>
        </Grid>
      </EntityLayout.Route>
      {(isUserMember || !isTestEnvironment) && (
        <EntityLayout.Route path="/cloud" title="Cloud">
          <CloudPage />
        </EntityLayout.Route>
      )}
      <EntityLayout.Route path="/elastic" title="Elastic">
        <ElasticPage />
      </EntityLayout.Route>
      <EntityLayout.Route path="/identity" title="IAM">
        <IdentityPage />
      </EntityLayout.Route>
      {requiresMacBuildCloudApplication && (
        <EntityLayout.Route path="/mac-build-cloud" title="Mac Build Cloud">
          <MacBuildCloudPage />
        </EntityLayout.Route>
      )}
      <FeatureFlagged with="virtual-machines">
        <EntityLayout.Route path="/virtual-machines" title="Virtual Machines">
          <VmSnapshotPage />
        </EntityLayout.Route>
      </FeatureFlagged>
      <EntityLayout.Route path="/asma" title="Mobile Apps">
        <AsmaPage />
      </EntityLayout.Route>
      <FeatureFlagged with="snyk-plugin">
        <EntityLayout.Route path="/snyk" title="Snyk">
          <SnykPage />
        </EntityLayout.Route>
      </FeatureFlagged>
      <EntityLayout.Route path="/github" title="GitHub">
        <GitHubConfigContextProvider>
          <GithubPage />
        </GitHubConfigContextProvider>
      </EntityLayout.Route>
      <FeatureFlagged with="fossid-plugin">
        <EntityLayout.Route path="/fossid" title="FossID">
          <FossIdPage />
        </EntityLayout.Route>
      </FeatureFlagged>
      <FeatureFlagged with="jira-plugin">
        <EntityLayout.Route path="/jira" title="Jira">
          <JiraPage />
        </EntityLayout.Route>
      </FeatureFlagged>
      <FeatureFlagged with="hosting-platform">
        <EntityLayout.Route path="/edge-platform" title="Edge">
          <EdgePage />
        </EntityLayout.Route>
      </FeatureFlagged>
      <FeatureFlagged with="hosting-platform-core-self-service">
        <EntityLayout.Route path="/hosting-platform" title="Hosting Platform">
          <HostingPlatform />
        </EntityLayout.Route>
      </FeatureFlagged>
      {isPagerDutyAvailable(entity) && hasCurrentUserPagerDutyTab && (
        <EntityLayout.Route path="pagerduty-ticket" title="PagerDuty">
          <EntityComponent />
        </EntityLayout.Route>
      )}
    </EntityLayout>
  );
};

export default ProductPage;
