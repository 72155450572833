import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ChartLineUpDown(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M5.5 6C6.313 6 7 6.688 7 7.5v22c0 .875.625 1.5 1.5 1.5h26c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5h-26C6 34 4 32 4 29.5v-22C4 6.687 4.625 6 5.5 6Zm22 5c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5h5c.375 0 .75.188 1.063.438.25.312.437.687.437 1.062v5c0 .875-.688 1.5-1.5 1.5-.875 0-1.5-.625-1.5-1.5v-1.375l-7.938 7.938c-.625.625-1.562.625-2.125 0L17 17.125l-3.938 3.938c-.624.625-1.562.625-2.124 0a1.369 1.369 0 0 1 0-2.063l5-5c.562-.625 1.5-.625 2.124 0L22 17.938 28.875 11H27.5Zm0 17c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5h1.375l-2.938-2.938 2.125-2.125L31 22.875V21.5c0-.813.625-1.5 1.5-1.5.813 0 1.5.688 1.5 1.5v5c0 .438-.188.813-.438 1.063A1.464 1.464 0 0 1 32.5 28h-5Z" />
    </SvgIcon>
  );
}
