import {
  Entity,
  entityKindSchemaValidator,
  KindValidator,
} from '@backstage/catalog-model';
import schema from './Product.v1alpha.schema.json';

export interface ProductEntity extends Entity {
  apiVersion: 'scaffolder.legogroup.io/v1alpha1';
  kind: 'Product';
  spec: {
    domain: {
      id: string;
      name: string;
      subDomain: {
        id: string;
        name: string;
      };
    };
    productManager?: string[];
    applications: string[];
    ownerTitle: string;
    owner: string;
  };
}

const validator = entityKindSchemaValidator(schema);

export const productEntityV1alpha1Validator: KindValidator = {
  // eslint-disable-next-line @typescript-eslint/require-await
  async check(data: Entity) {
    return validator(data) === data;
  },
};
