import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { useUserProfile } from '@backstage/plugin-user-settings';

export function useLoggingData() {
  const { profile } = useUserProfile();
  const config = useApi(configApiRef);

  return {
    email: profile.email ?? '',
    environment: config.getString('environmentStage') || 'LOCAL',
  };
}
