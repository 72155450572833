import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ArrowTurnDownRight(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M7.008 8.734v10.5c0 1.375 1.062 2.5 2.5 2.5h21.375l-5.438-5.437c-.625-.563-.625-1.5 0-2.125.563-.563 1.5-.563 2.125 0l8 8.062c.563.563.563 1.5 0 2.063l-8 8c-.625.625-1.562.625-2.125 0-.625-.563-.625-1.5 0-2.125l5.438-5.438H9.508a5.468 5.468 0 0 1-5.5-5.5v-10.5c0-.812.625-1.5 1.5-1.5.812 0 1.5.688 1.5 1.5Z" />
    </SvgIcon>
  );
}
