import { Entity } from '@backstage/catalog-model';
import React from 'react';
import { EntityLinkVariant } from '../types';
import { Typography } from '@material-ui/core';
import { useEntityLinkStyles } from '../EntityLink.styles';
import { Icon } from '../../icon';

interface Props {
  entity: Entity;
  variant: EntityLinkVariant;
}

export const EntityLinkDocumentationFooter = ({ entity, variant }: Props) => {
  const classes = useEntityLinkStyles();

  switch (variant) {
    case 'thumbnail':
      if (entity.spec?.type === 'confluence-link') {
        return (
          <div className={classes.linkInFooter}>
            <Typography variant="overline" color="secondary">
              Confluence
            </Typography>
            <Icon
              icon="arrow-up-right-from-square"
              size={16}
              color="secondary"
            />
          </div>
        );
      }
      return null;
    case 'link':
      return (
        <div>
          <Typography variant="overline" color="secondary">
            {entity.spec?.type === 'confluence-link'
              ? 'confluence'
              : 'documentation'}
          </Typography>
        </div>
      );
    default:
      return <div />;
  }
};
