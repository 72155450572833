import { ResponsiveLine } from '@nivo/line';
import { CustomSymbolShape } from './CustomSymbolShape';
import { LegendProps } from '@nivo/legends';

type ScaleSpec = ResponsiveLine['props']['xScale'];
type AxisProps = ResponsiveLine['props']['axisBottom'];

export const xScale: ScaleSpec = { type: 'point' };

export const yScale: ScaleSpec = {
  type: 'linear',
  min: 0,
  max: 'auto',
};

export const axisBottom: AxisProps = {
  tickSize: 10,
  tickPadding: 5,
  truncateTickAt: 0,
};

export const axisLeft: AxisProps = {
  tickSize: 10,
  tickPadding: 5,
  tickRotation: 0,
  tickValues: 5,
};

export const legend: LegendProps = {
  anchor: 'bottom',
  direction: 'row',
  translateY: 55,
  itemWidth: 100,
  itemHeight: 20,
  itemOpacity: 0.75,
  symbolSize: 12,
  symbolSpacing: 8,
  symbolShape: CustomSymbolShape,
  toggleSerie: true,
};
