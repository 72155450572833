import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Truck(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M22 7H4c-.563 0-1 .5-1 1v18c0 .563.438 1 1 1h.75c1.063-1.75 3-3 5.25-3 2.188 0 4.125 1.25 5.188 3H22c.5 0 1-.438 1-1V8c0-.5-.5-1-1-1Zm2 23h-8c0 3.313-2.688 6-6 6-3.313 0-6-2.688-6-6-2.25 0-4-1.75-4-4V8c0-2.188 1.75-4 4-4h18c2.188 0 4 1.813 4 4v2h2.625c.938 0 1.813.375 2.5 1.063l5.813 5.812c.687.688 1.062 1.563 1.062 2.5V27h.5c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5H36c0 3.313-2.688 6-6 6-3.313 0-6-2.688-6-6Zm10.813-11L29 13.187c-.125-.124-.25-.187-.375-.187H26v6h8.875-.063ZM7 30c0 1.125.563 2.063 1.5 2.625.875.563 2.063.563 3 0 .875-.563 1.5-1.5 1.5-2.625 0-1.063-.625-2-1.5-2.563-.938-.562-2.125-.562-3 0C7.562 28 7 28.938 7 30Zm23-3c-1.125 0-2.063.625-2.625 1.5-.563.938-.563 2.125 0 3 .563.938 1.5 1.5 2.625 1.5 1.063 0 2-.563 2.563-1.5.562-.875.562-2.063 0-3C32 27.625 31.063 27 30 27Z" />
    </SvgIcon>
  );
}
