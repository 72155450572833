import React from 'react';
import { Chip } from '../../chip/Chip';
import { Tooltip } from '@material-ui/core';
import { Entity } from '@backstage/catalog-model';

interface Props {
  tags: string[];
  entity: Entity;
}

export const EntityLinkFooterTags = ({ tags: inputTags, entity }: Props) => {
  const tags = inputTags ?? entity.metadata.tags ?? [];
  return (
    <div>
      {tags.slice(0, 2).map(tag => (
        <Chip label={tag} key={tag} />
      ))}
      {tags.length > 2 && (
        <Tooltip title={tags.slice(2).join(', ')} placement="top">
          <Chip label={`+${tags.length - 2}`} />
        </Tooltip>
      )}
    </div>
  );
};
