import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function HandHoldingUser(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M18 4.532c-1.25.75-2 2.043-2 3.46 0 1.46.75 2.752 2 3.503 1.208.708 2.75.708 4 0 1.208-.751 2-2.044 2-3.503 0-1.417-.792-2.71-2-3.46-1.25-.71-2.792-.71-4 0Zm-.458 8.797c-3.084 0-5.542 2.502-5.542 5.587 0 .625.5 1.084 1.083 1.084h13.792C27.5 20 28 19.5 28 18.916c0-3.085-2.5-5.587-5.583-5.587h-4.875Zm-5.229 13.077c.374-.25.874-.437 1.374-.437H23.5c.5 0 1 .5 1 1 0 .562-.5 1-1 1H18c-.875 0-1.5.687-1.5 1.5 0 .875.625 1.5 1.5 1.5h8.438c.312 0 .562-.063.812-.188l5.875-3.625c.188-.125.375-.187.625-.187h.438c.437 0 .812.375.812.812 0 .25-.188.5-.375.625l-6 3.875c-.688.5-1.563.75-2.438.75H3.5c-.875 0-1.5.625-1.5 1.5 0 .813.625 1.5 1.5 1.5h22.688c1.437 0 2.875-.437 4.125-1.25l5.937-3.875c1.063-.687 1.75-1.875 1.75-3.125a3.788 3.788 0 0 0-3.813-3.812h-.437c-.813 0-1.563.25-2.25.625l-4 2.5v-.125c0-2.188-1.813-4-4-4h-9.813c-1.062 0-2.124.375-3.062.937L6 26.97H3.5c-.875 0-1.5.687-1.5 1.5 0 .875.625 1.5 1.5 1.5h3c.25 0 .563-.063.813-.25l5-3.313Z" />
    </SvgIcon>
  );
}
