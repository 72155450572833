import { Typography } from '@material-ui/core';
import React from 'react';
import { Chip } from '../chip/Chip';
import { useInputStyles } from '../input/Input.styles';
import { BaseplateInputProps } from '../input/InputProps.type';
import { useDropdownStyles } from './Dropdown.styles';
import Select, { SelectProps } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useDropdownMenuStyles } from './dropdownMenu.styles';
import { Spinner } from '../spinner';

export type BaseplateDropdownProps = SelectProps &
  BaseplateInputProps & {
    options: string[] | { label: string; value: unknown }[];
    placeholder?: string;
  };

/**
 * A Baseplate dropdown component that extends the MUI Autocomplete component.
 * @docs https://baseplate.legogroup.io/docs/default/component/baseplate
 */
export const Dropdown = ({
  fullWidth = true,
  placeholder = 'Select an option',
  ...props
}: BaseplateDropdownProps) => {
  const inputClasses = useInputStyles();
  const dropdownClasses = useDropdownStyles();
  const dropdownMenuClasses = useDropdownMenuStyles();

  const defaultRenderMultipleValue: SelectProps['renderValue'] = selected => {
    if (Array.isArray(selected) && selected.length > 0) {
      return (
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '0.5rem' }}>
          {selected.map((value: unknown) => {
            const options = props.options.map(option =>
              typeof option === 'string'
                ? { label: option, value: option }
                : option,
            );
            const label = options.find(option => option.value === value)?.label;
            return (
              <Chip
                key={label}
                label={label}
                onClick={e => e.stopPropagation()}
              />
            );
          })}
        </div>
      );
    }
    return (
      <Typography variant="body1" color="secondary">
        {placeholder}
      </Typography>
    );
  };

  const renderValue = (selected: any) => {
    if (props.multiple) {
      return defaultRenderMultipleValue(selected);
    }

    const value = selected;
    if (!value && value !== 0) {
      return (
        <Typography variant="body1" color="secondary">
          {placeholder}
        </Typography>
      );
    }

    const options = props.options.map(option =>
      typeof option === 'string' ? { label: option, value: option } : option,
    );
    const label = options.find(option => option.value === value)?.label;
    return label || placeholder;
  };

  return (
    <div
      className={[
        inputClasses.root,
        dropdownClasses.root,
        props.success && inputClasses.success,
        props.error && inputClasses.error,
        props.small && inputClasses.small,
      ].join(' ')}
    >
      <label htmlFor={props.id}>
        {props.label}{' '}
        {!props.required && (
          <Typography variant="caption" color="secondary">
            (optional)
          </Typography>
        )}
      </label>
      <Select
        {...props}
        multiple={props.multiple}
        renderValue={renderValue}
        label=""
        className={inputClasses.textField}
        endAdornment={props.loading ? <Spinner type="circle" /> : undefined}
        MenuProps={{
          MenuListProps: {
            className: dropdownMenuClasses.menu,
          },
        }}
        fullWidth={fullWidth}
        displayEmpty
      >
        {props.options.map(option => {
          const [value, label] =
            typeof option === 'string'
              ? [option, option]
              : [option.value, option.label];
          return (
            <MenuItem key={label} value={value as string}>
              {label}
            </MenuItem>
          );
        })}
      </Select>
      <div className={inputClasses.descriptiveTextContainer}>
        {props.helperText && (
          <Typography variant="body2" className={inputClasses.helperText}>
            {props.helperText}
          </Typography>
        )}
        {props.error && props.errorText && (
          <Typography variant="body2" className={inputClasses.errorText}>
            {props.errorText}
          </Typography>
        )}
        {props.success && props.successText && (
          <Typography variant="body2" className={inputClasses.successText}>
            {props.successText}
          </Typography>
        )}
      </div>
    </div>
  );
};
