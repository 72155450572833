import { Entity } from '@backstage/catalog-model';
import React, { ReactNode } from 'react';
import { EntityLinkVariant } from '../types';
import { EntityLinkProductFooter } from './EntityLinkProductFooter';
import { EntityLinkToolFooter } from './EntityLinkToolFooter';
import { EntityLinkDocumentationFooter } from './EntityLinkDocumentationFooter';
import { EntityLinkApiFooter } from './EntityLinkApiFooter';
import { EntityLinkApplicationFooter } from './EntityLinkApplicationFooter';
import { Typography } from '@material-ui/core';
import { EntityLinkUserFooter } from './EntityLinkUserFooter';
import { ToolEntity } from '@lego/plugin-baseplate-common';

interface Props {
  entity: Entity;
  variant: EntityLinkVariant;
  isExternalLink?: boolean;
  apiStatus?: ReactNode;
}

export const EntityLinkFooter = ({ entity, variant, apiStatus }: Props) => {
  const footerProps = { entity, variant };

  switch (entity.kind) {
    case 'Product':
      return <EntityLinkProductFooter {...footerProps} />;
    case 'Tool':
      return (
        <EntityLinkToolFooter
          {...(footerProps as {
            entity: ToolEntity;
            variant: Props['variant'];
          })}
        />
      );
    case 'Component':
      return <EntityLinkDocumentationFooter {...footerProps} />;
    case 'API':
      return <EntityLinkApiFooter {...footerProps} apiStatus={apiStatus} />;
    case 'Application':
      return <EntityLinkApplicationFooter {...footerProps} />;
    case 'User':
      return <EntityLinkUserFooter {...footerProps} />;
    default:
      switch (variant) {
        case 'link':
          return (
            <div>
              <Typography variant="overline" color="secondary">
                {entity.kind}
              </Typography>
            </div>
          );
        default:
          return <div />;
      }
  }
};
