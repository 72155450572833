import { useApi } from '@backstage/core-plugin-api';
import {
  AppConfigType,
  PluginAppConfigApi,
  pluginAppConfigApiRef,
  Providers,
} from '@lego/plugin-baseplate-plugin-app-config';
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  AZURE_SCOPE,
  BASE_URL_APP_CONFIG_KEY,
  CONFIG_STORE_NAME,
} from '../util/constants';
import { useAuthFlow } from './useAuthFlow';

interface IGitHubConfigProps {
  BACKEND_BASE_URL: string;
  azureAccessToken: string;
}

interface IGitHubConfigContextProviderProps {
  children?: ReactNode;
}

const getGitHubParameter = async (
  pluginAppConfigApi: PluginAppConfigApi,
  appConfigKey: string,
): Promise<string> => {
  const value = await pluginAppConfigApi.getAppConfig<string>({
    configStoreName: CONFIG_STORE_NAME,
    appConfigKey,
    provider: Providers.AZURE,
    appConfigType: AppConfigType.PARAMETER,
  });

  return value;
};

export const GitHubConfigContext = createContext<
  IGitHubConfigProps | undefined
>(undefined);

export const GitHubConfigContextProvider: React.FC<
  IGitHubConfigContextProviderProps
> = ({ children }) => {
  const pluginAppConfigApi = useApi(pluginAppConfigApiRef);
  const [baseUrl, setBaseUrl] = useState('');
  const [finishedLoading, setFinishedLoading] = useState(false);

  useEffect(() => {
    if (finishedLoading) return;

    const loadDependencies = async () => {
      try {
        const config_baseUrl = await getGitHubParameter(
          pluginAppConfigApi,
          BASE_URL_APP_CONFIG_KEY,
        );
        setBaseUrl(config_baseUrl);
      } catch (error) {
        throw new Error('Error loading GitHub plugin app config');
      } finally {
        setFinishedLoading(true);
      }
    };

    // Using the void operator to suppress lint warning
    void loadDependencies();
  }, [finishedLoading, pluginAppConfigApi]);

  const { azureAccessToken } = useAuthFlow(AZURE_SCOPE);

  const value: IGitHubConfigProps = {
    BACKEND_BASE_URL: baseUrl,
    azureAccessToken,
  };

  return (
    <GitHubConfigContext.Provider value={value}>
      {children}
    </GitHubConfigContext.Provider>
  );
};

export const useConfig = () => {
  const context = useContext(GitHubConfigContext);
  if (!context) {
    throw new Error(
      'useConfig must be used within a GitHubConfigContextProvider',
    );
  }
  return context;
};
