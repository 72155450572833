import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function HandPinch(props) {
  return (
    <SvgIcon
      viewBox="0 0 41 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M7.434 2.458C7.434 1.663 8.097 1 8.892 1h7.071c.884 0 1.547.663 1.503 1.503v7.07a1.369 1.369 0 0 1-1.459 1.46c-.84.043-1.502-.62-1.502-1.504V6.104l-8.443 8.442.022.023h3.448a1.369 1.369 0 0 1 1.458 1.458l.044.044a1.47 1.47 0 0 1-1.458 1.458H2.505c-.884 0-1.547-.662-1.503-1.502V8.956a1.369 1.369 0 0 1 1.459-1.459c.84-.044 1.502.62 1.502 1.503v3.403l8.431-8.431-.01-.011H8.935a1.369 1.369 0 0 1-1.458-1.458l-.044-.045Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.02 12c0-2.188-1.813-4-4-4-2.25 0-4 1.813-4 4v12.375a4.022 4.022 0 0 0-5.25-.688c-1.813 1.25-2.313 3.75-1.126 5.563l4.25 6.313C18.77 38.374 21.832 40 25.207 40h7.312c4.125 0 7.5-3.313 7.5-7.5V25c0-2.188-1.812-4-4-4-.312 0-.562.063-.812.125-.75-1-1.938-1.625-3.188-1.625-.437 0-.875.125-1.25.25-.75-1.063-1.937-1.75-3.25-1.75-.103 0-.206.019-.3.036a1.212 1.212 0 0 1-.2.026V12Zm-5.063 17.25L22.019 12c0-.5.438-1 1-1 .5 0 1 .5 1 1v8.5c0 .688.375 1.25 1 1.438.625.25 1.313 0 1.688-.5.187-.25.437-.375.812-.375.5 0 1 .437 1 1 0 .625.375 1.187 1 1.375.625.25 1.313 0 1.688-.5.187-.25.437-.375.812-.375.438 0 .875.312.938.812.125.5.5.938.937 1.125.5.188 1.063.063 1.5-.25.125-.125.375-.188.625-.188.5 0 1 .438 1 1V32.5c0 2.5-2.062 4.5-4.5 4.5h-7.312a7.008 7.008 0 0 1-5.813-3.063l-4.25-6.374c-.312-.438-.187-1.063.313-1.375.437-.313 1.062-.188 1.375.312l2.437 3.625c.313.5 1 .75 1.625.563.688-.188 1.063-.75 1.063-1.438Z"
      />
    </SvgIcon>
  );
}
