/*
 * Copyright 2022 Larder Software Limited
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { createScaffolderFieldExtension } from '@backstage/plugin-scaffolder-react';
import {
  DateReaderExtension,
  CalloutExtension,
  CalloutExtensionSchema,
  EntityPickerExtension,
  EntityPickerExtensionSchema,
  EntityTagsPicker,
  GithubTokenExtension,
  Links,
  MarkdownExtension,
  SelectableCardExtension,
  ToolCategoryPicker,
  ImageUploadField,
  MultiStringField,
  MultiStringFieldExtensionSchema,
  AutoCompleteExtension,
  RadioExtension,
  ProductRelationPickerExtension,
  ProductRelationPickerExtensionSchema,
  CustomSupportLink,
  AzureTokenExtension,
  AzureTokenExtensionSchema,
  CurrentEntityDisplayExtension,
  DependencyDisplayExtension,
} from '../src/components';

export const EntityPickerFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'EntityPicker',
    component: EntityPickerExtension,
    schema: EntityPickerExtensionSchema,
  }),
);

export const CalloutFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'CalloutExtension',
    component: CalloutExtension,
    schema: CalloutExtensionSchema,
  }),
);

export const MarkdownContentExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'MarkdownExtension',
    component: MarkdownExtension,
  }),
);

export const DateExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'DateReaderExtension',
    component: DateReaderExtension,
  }),
);

export const EntityTagsPickerExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'EntityTagsPicker',
    component: EntityTagsPicker,
  }),
);

export const ToolCategoryPickerExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'ToolCategoryPicker',
    component: ToolCategoryPicker,
  }),
);

export const LinksAndActionsExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'LinksAndActionsExtension',
    component: Links,
  }),
);

export const SelectableRadioCardExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'SelectableCardExtension',
    component: SelectableCardExtension,
  }),
);

export const ImageUploadExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'ImageUploadField',
    component: ImageUploadField,
  }),
);

export const GithubTokenFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'GithubTokenExtension',
    component: GithubTokenExtension,
  }),
);

export const MultiStringFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'MultiStringFieldExtension',
    component: MultiStringField,
    schema: MultiStringFieldExtensionSchema,
  }),
);

export const AutoCompleteFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'AutoCompleteExtension',
    component: AutoCompleteExtension,
  }),
);

export const RadioFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'RadioExtension',
    component: RadioExtension,
  }),
);

export const ProductRelationPickerFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'ProductRelationPicker',
    component: ProductRelationPickerExtension,
    schema: ProductRelationPickerExtensionSchema,
  }),
);

export const CustomSupportLinkExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'CustomSupportLinkExtension',
    component: CustomSupportLink,
  }),
);

export const AzureTokenFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'AzureTokenExtension',
    component: AzureTokenExtension,
    schema: AzureTokenExtensionSchema,
  }),
);

export const CurrentEntityFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'CurrentEntityDisplay',
    component: CurrentEntityDisplayExtension,
  }),
);

export const DependencyDisplayFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'DependencyDisplayFieldExtension',
    component: DependencyDisplayExtension,
  }),
);
