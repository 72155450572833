import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ControlCheck(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M35.586 9.664c.562.625.562 1.563 0 2.125l-18.5 18.5c-.625.625-1.563.625-2.125 0l-10.5-10.5a1.369 1.369 0 0 1 0-2.062 1.369 1.369 0 0 1 2.062 0l9.438 9.437 17.5-17.5a1.471 1.471 0 0 1 2.062 0h.063Z" />
    </SvgIcon>
  );
}
