import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function DollarArrowUpArrowDown(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M2.422 30.469a1.471 1.471 0 0 0 0 2.062l5 5c.625.625 1.562.625 2.125 0l5-5c.625-.562.625-1.5 0-2.062-.563-.625-1.5-.625-2.125 0L10 32.89v-14.1c.008-1.718.545-4.326 2.086-6.462 1.487-2.063 3.968-3.78 8.152-3.78a1.5 1.5 0 1 0 0-3c-5.166 0-8.545 2.196-10.586 5.025C7.667 13.326 7.01 16.584 7 18.78v14.126h-.016L4.547 30.47a1.369 1.369 0 0 0-2.063 0h-.062ZM38.054 9.531c.562-.562.562-1.5 0-2.062l-5-5c-.625-.625-1.563-.625-2.125 0l-5 5a1.369 1.369 0 0 0 0 2.062c.562.625 1.5.625 2.125 0l2.422-2.422V21.21c-.009 1.718-.545 4.326-2.086 6.462-1.487 2.063-3.969 3.78-8.152 3.78a1.5 1.5 0 1 0 0 3c5.166 0 8.545-2.196 10.585-5.025 1.986-2.753 2.643-6.011 2.652-8.207V7.094h.016L35.93 9.53c.562.625 1.5.625 2.062 0h.063Z" />
      <path d="m21.307 14.458 1.223.223c.71.118 1.154.779 1.033 1.449-.122.675-.798 1.129-1.469 1.013h-.003l-1.37-.25c-.825-.13-1.561-.025-2.047.148-.46.19-.584.416-.61.522-.052.206.019.416.253.579.503.336 1.118.478 1.717.617.202.047.402.093.595.146.752.207 1.701.468 2.43.932.434.272.892.679 1.16 1.23.302.594.325 1.235.234 1.884l-.001.009-.002.008c-.23 1.214-1.113 1.992-2.105 2.385a4.71 4.71 0 0 1-1.038.285v.612c0 .717-.606 1.25-1.272 1.25-.7 0-1.271-.54-1.271-1.25v-.66c-.722-.12-1.73-.404-2.444-.712a1.229 1.229 0 0 1-.674-1.649l.004-.008.004-.009c.306-.596 1.015-.917 1.691-.609.537.235 1.504.479 2.054.56h.002c.87.132 1.549.048 1.995-.12.389-.157.484-.307.55-.539.021-.257-.041-.446-.262-.58-.447-.272-.94-.398-1.435-.525a11.067 11.067 0 0 1-.682-.19c-.798-.195-1.846-.484-2.641-.99-.435-.273-.894-.68-1.161-1.235l-.004-.007c-.262-.576-.325-1.21-.198-1.862.232-1.254 1.187-1.998 2.163-2.385a4.55 4.55 0 0 1 1.038-.286v-.694c0-.675.563-1.25 1.271-1.25.673 0 1.272.568 1.272 1.25v.708Z" />
    </SvgIcon>
  );
}
