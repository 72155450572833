import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Language(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M4 8h32c2.188 0 4 1.813 4 4v16c0 2.25-1.813 4-4 4H4c-2.25 0-4-1.75-4-4V12c0-2.188 1.75-4 4-4Zm32 3H20v18h16c.5 0 1-.438 1-1V12c0-.5-.5-1-1-1Zm-24.875 4c-.188-.438-.688-.75-1.125-.75-.5 0-1 .313-1.188.75l-4 9c-.25.625 0 1.375.625 1.688.688.25 1.375 0 1.688-.625l.563-1.313h4.562l.563 1.313c.312.625 1.062.875 1.687.625.625-.313.875-1.063.625-1.688l-4-9Zm.063 6.25H8.812L10 18.625l1.188 2.625Zm17.312-7c.688 0 1.25.563 1.25 1.25v.25h3.75c.688 0 1.25.563 1.25 1.25 0 .688-.563 1.25-1.25 1.25h-.125l-.125.313c-.563 1.5-1.438 2.937-2.5 4.062.063.063.125.063.188.125l1.187.688c.563.375.75 1.125.438 1.75-.376.562-1.126.75-1.75.437l-1.188-.75c-.25-.125-.5-.313-.813-.5-.625.438-1.375.875-2.125 1.188l-.187.125c-.625.25-1.375 0-1.688-.625-.25-.688 0-1.375.625-1.688l.25-.063c.375-.187.813-.437 1.188-.625l-.813-.75c-.437-.5-.437-1.312 0-1.812.5-.438 1.313-.438 1.813 0l.875.938h.063c.75-.813 1.375-1.75 1.875-2.813H24c-.688 0-1.25-.563-1.25-1.25 0-.688.563-1.25 1.25-1.25h3.25v-.25c0-.688.563-1.25 1.25-1.25Z" />
    </SvgIcon>
  );
}
