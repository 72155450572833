import { makeStyles } from '@material-ui/core';

export const useSupportLinkExtensionStyles = makeStyles<any>(theme => ({
  supportLinkTitle: {
    paddingTop: 16,
    width: '100%',
    borderTop: `1px solid ${theme.semantic.border.default}`,
  },
  supportLinkContainer: {
    display: 'flex',
    padding: '0',
    margin: '0',
    marginBottom: '1rem',
    justifyContent: 'space-between',

    '& > div': {
      padding: '0 0 0 5px',
      display: 'flex',
      '& > div': {
        paddingBottom: 8,
        paddingTop: 8,
      },
      '& > div:last-child': {
        display: 'flex',
        justifyContent: 'flex-end',
        margin: 'auto',
        '& > button': {
          height: 50,
        },
      },
    },
  },
}));
