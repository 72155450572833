import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function FlagSwallowtail(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M9 5.5V6h23.5c.813 0 1.5.688 1.5 1.5 0 .375-.125.688-.313.938L28 16l5.688 7.625c.187.25.312.563.312.875 0 .875-.688 1.5-1.5 1.5H9v8.5c0 .875-.688 1.5-1.5 1.5-.875 0-1.5-.625-1.5-1.5v-29C6 4.687 6.625 4 7.5 4 8.313 4 9 4.688 9 5.5ZM9 9v14h20.5l-3.938-5.188a2.908 2.908 0 0 1 0-3.562L29.5 9H9Z" />
    </SvgIcon>
  );
}
