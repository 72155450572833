import { Typography } from '@material-ui/core';
import React from 'react';
import { ShortcutSymbol } from '../ShortcutSymbol';

type Props = {
  filterable?: boolean;
};

export const SearchModalResultItemTooltip = ({ filterable }: Props) => {
  return (
    <Typography variant="body1" component="div">
      {filterable && (
        <>
          <ShortcutSymbol text="Tab" size="md" /> to filter, click or{' '}
        </>
      )}
      <ShortcutSymbol text="Enter" size="md" /> to go to
    </Typography>
  );
};
