import { Entity } from '@backstage/catalog-model';
import React, { ReactNode } from 'react';
import { EntityLinkVariant } from '../types';
import { useEntityLinkStyles } from '../EntityLink.styles';
import { Chip } from '../../chip/Chip';
import { Tooltip, Typography } from '@material-ui/core';
import { Status } from '../../status';

interface Props {
  entity: Entity;
  variant: EntityLinkVariant;
  apiStatus?: ReactNode;
  // Add your prop types here
}

export const EntityLinkApiFooter = ({
  entity,
  variant,
  apiStatus = 'UNKNOWN',
}: Props) => {
  const classes = useEntityLinkStyles();

  if (typeof apiStatus !== 'string') {
    return null;
  }

  const getApiEntityStatusColor = (): 'green' | 'yellow' | 'neutral' => {
    switch (true) {
      case entity.spec?.type !== 'openapi' && entity.spec?.type !== 'graphql':
        return 'neutral';
      case apiStatus === 'HEALTHY':
        return 'green';
      case apiStatus === 'DEGRADED':
        return 'yellow';
      default:
        return 'neutral';
    }
  };

  switch (variant) {
    case 'thumbnail':
      return (
        <div className={classes.footer}>
          <Chip
            label={entity.spec?.type?.toString()}
            color={
              ({ asyncapi: 'blue', openapi: 'green', graphql: 'purple' }[
                entity.spec?.type as 'asyncapi' | 'openapi' | 'graphql'
              ] ?? 'neutral') as 'blue' | 'green' | 'purple' | 'neutral'
            }
          />
          {entity.spec?.type === 'openapi' &&
            entity.spec?.apiScore !== undefined &&
            entity.spec?.apiScore !== 0 && (
              <Tooltip title="Linter score">
                <Typography variant="body2" color="secondary">
                  {entity.spec?.apiScore?.toString()}/5.0
                </Typography>
              </Tooltip>
            )}
          {(entity.spec?.type === 'openapi' ||
            entity.spec?.type === 'graphql') && (
            <Status
              status={getApiEntityStatusColor()}
              label={
                apiStatus.charAt(0).toUpperCase() +
                apiStatus.slice(1).toLowerCase()
              }
            />
          )}
        </div>
      );

    case 'link':
      return (
        <div>
          <Typography variant="overline" color="secondary">
            {entity.kind} • {entity.spec?.type?.toString()}{' '}
            {entity.spec?.environment &&
              `• ${entity.spec.environment as string}`}
          </Typography>
        </div>
      );

    default:
      return null;
  }
};
