/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role*/
import React from 'react';
import { useSelectableCardStyles } from './RadiobuttonCard.styles';
import { Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';

interface Props {
  // Add your prop types here
  name: string;
  value: string;
  onChange: (value: string) => void;
  type?: 'radio' | 'checkbox';
  options: {
    label: string;
    icon?: React.ReactNode;
    description?: string;
    value: string;
    disabled?: boolean;
  }[];
}

export const RadiobuttonCard = ({
  name,
  options,
  type = 'radio',
  value: selectedValue,
  onChange,
}: Props) => {
  const classes = useSelectableCardStyles();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLLabelElement>,
    value: string,
  ) => {
    if (event.key === ' ') {
      event.preventDefault();
      onChange(value);
    }
  };

  return (
    <div>
      {options.map(({ label, icon, value, description, disabled }) => (
        <div className={classes.outerContainer}>
          <input
            id={value}
            role="radio"
            value={value}
            checked={selectedValue === value}
            type={type}
            name={name}
            className={classes.input}
            tabIndex={-1}
            onChange={handleChange}
            aria-checked={selectedValue === value}
            disabled={disabled}
          />
          <label
            htmlFor={value}
            className={classes.container}
            tabIndex={0}
            onKeyDown={event => handleKeyDown(event, value)}
            aria-labelledby={value}
            role="radio"
            aria-checked={selectedValue === value}
          >
            <div className={[classes.indicator, classes[type]].join(' ')}>
              {type === 'checkbox' && selectedValue === value && (
                <CheckIcon fontSize="small" />
              )}
            </div>
            <div className={classes.textContainer}>
              <Typography
                variant="subtitle1"
                className={classes.titleContainer}
              >
                {icon}
                {label}
              </Typography>
              <Typography>{description}</Typography>
            </div>
          </label>
        </div>
      ))}
    </div>
  );
};
