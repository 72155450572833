import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function GamepadModern(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M13.53 25h13c1.376 0 2.563.938 2.876 2.313l.25.875C30.03 29.875 31.53 31 33.28 31c2.062 0 3.75-1.625 3.75-3.688v-.187c0-.25-.063-.438-.063-.688L34.906 14.5c-.375-2.25-1.75-3.75-3.438-4.188C28.843 9.688 24.968 9 20.031 9c-5 0-8.875.688-11.5 1.313-1.688.437-3.063 1.937-3.438 4.187L3.031 26.438v.875A3.655 3.655 0 0 0 6.718 31c1.75 0 3.25-1.125 3.625-2.813l.25-.875C10.906 25.938 12.156 25 13.531 25Zm13.188 3.938L26.531 28h-13l-.25.938c-.75 3-3.438 5.062-6.563 5.062-3.687 0-6.687-3-6.687-6.688v-.187c0-.375 0-.813.062-1.188L2.156 14c.5-3.125 2.562-5.813 5.625-6.563C10.656 6.688 14.78 6 20.03 6c5.187 0 9.312.688 12.187 1.438 3.063.75 5.125 3.437 5.625 6.562l2.063 11.938c.062.375.062.812.062 1.187v.188C40.031 31 36.968 34 33.281 34c-3.125 0-5.813-2.063-6.563-5.063ZM14.531 14.5v2h2c.812 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5h-2v2c0 .875-.688 1.5-1.5 1.5-.875 0-1.5-.625-1.5-1.5v-2h-2c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5h2v-2c0-.813.625-1.5 1.5-1.5.812 0 1.5.688 1.5 1.5Zm10.5 4.5c1.062 0 2 .938 2 2 0 1.125-.938 2-2 2-1.125 0-2-.875-2-2 0-1.063.875-2 2-2Zm2-4c0-1.063.875-2 2-2 1.062 0 2 .938 2 2 0 1.125-.938 2-2 2-1.125 0-2-.875-2-2Z" />
    </SvgIcon>
  );
}
