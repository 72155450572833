import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Trophy(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M17.5 23.375c.875.938 1.625 1.375 2.125 1.5.125.063.25.125.375.125.063 0 .188-.063.375-.125.438-.125 1.188-.563 2.063-1.5C24.125 21.5 26.5 17.062 26.938 7H13.063c.374 10.063 2.75 14.5 4.437 16.375ZM27 4c1.625 0 3 1.375 2.938 3.063-.063.312-.063.625-.063.937H36.5c.813 0 1.5.688 1.5 1.5 0 6.813-2.875 11.125-6.375 13.813-3.375 2.625-7.25 3.625-9.313 4-.312.125-.562.25-.812.375V33h5c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5h-13c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5h5v-5.313c-.25-.125-.563-.25-.813-.375-2.125-.375-6-1.375-9.375-4C4.813 20.625 2 16.313 2 9.5 2 8.687 2.625 8 3.5 8h6.563c0-.313 0-.625-.063-.938C9.937 5.375 11.313 4 13 4h14Zm2.625 7c-.563 5.688-1.813 9.438-3.25 11.938 1.125-.5 2.313-1.125 3.438-2 2.5-1.938 4.75-5 5.125-9.938h-5.313ZM13.562 22.938c-1.437-2.5-2.687-6.25-3.25-11.938H5c.375 4.938 2.625 8 5.125 9.938 1.125.875 2.313 1.5 3.438 2Z" />
    </SvgIcon>
  );
}
