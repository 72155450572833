import React, { useEffect } from 'react';
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder';
import { Box } from '@material-ui/core';

export const DateReaderExtension = ({
  onChange,
}: FieldExtensionComponentProps<string>) => {
  const currentDate = new Date();
  useEffect(() => {
    onChange(currentDate.toISOString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onChange]);
  return (
    <Box component="div" sx={{ display: 'inline' }}>
      Created on date: {currentDate.toDateString()}
    </Box>
  );
};
