import { Modal } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import {
  Icon,
  useSupportContext,
} from '@lego/plugin-baseplate-core-components';
import { SideMenu } from '@lego/plugin-baseplate-core-components';
import { PluginSupport } from './supportPages/PluginSupport';
import { BaseplateSupport } from './supportPages/BaseplateSupport';
import { BaseplateFeedback } from './supportPages/BaseplateFeedback';
import { useSupportStyles } from './support.styles';

export const SupportModal = ({
  supportModalOpened,
  setSupportModalOpened,
}: {
  supportModalOpened: boolean;
  setSupportModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const classes = useSupportStyles();
  const { pluginEntity } = useSupportContext();
  const [currentPage, setCurrentPage] = useState<string>('help_baseplate');

  useEffect(() => {
    if (pluginEntity) {
      setCurrentPage('plugin');
    } else if (currentPage === 'plugin') {
      setSupportModalOpened(false);
      setCurrentPage('help_baseplate');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pluginEntity, setSupportModalOpened]);

  const SwitchComponent = () => {
    const renderSwitch = () => {
      switch (currentPage) {
        case 'plugin':
          return <PluginSupport pluginEntity={pluginEntity} />;
        case 'help_baseplate':
          return <BaseplateSupport />;
        case 'feedback_baseplate':
          return <BaseplateFeedback />;
        default:
          return <BaseplateSupport />;
      }
    };

    return <div>{renderSwitch()}</div>;
  };

  return (
    <Modal
      open={supportModalOpened}
      onClose={() => {
        setSupportModalOpened(false);
        setCurrentPage(pluginEntity ? 'plugin' : 'help_baseplate');
      }}
    >
      <div className={classes.root}>
        <SideMenu.Wrapper>
          {pluginEntity && (
            <React.Fragment>
              <SideMenu.Section label="Plugin support" />
              <SideMenu.Item
                label="Support & Feedback"
                icon={<Icon icon="circle-question" />}
                onClick={() => setCurrentPage('plugin')}
                active={currentPage === 'plugin'}
              />
            </React.Fragment>
          )}
          <SideMenu.Section label="Baseplate support" />
          <SideMenu.Item
            label="Get help with Baseplate"
            icon={<Icon icon="headset" />}
            onClick={() => setCurrentPage('help_baseplate')}
            active={currentPage === 'help_baseplate'}
          />
          <SideMenu.Item
            label="Feedback for Baseplate"
            icon={<Icon icon="lightbulb" />}
            onClick={() => setCurrentPage('feedback_baseplate')}
            active={currentPage === 'feedback_baseplate'}
          />
          <SideMenu.Item
            label="Baseplate docs"
            icon={<Icon icon="book-open" />}
            href="/docs/default/component/baseplate"
            external
          />
        </SideMenu.Wrapper>
        <div className={classes.content}>
          <SwitchComponent />
        </div>
      </div>
    </Modal>
  );
};
