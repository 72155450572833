import {
  createApiFactory,
  createComponentExtension,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  fetchApiRef,
} from '@backstage/core-plugin-api';
import { rootRouteRef } from './routes';
import { MsSQLApiClient, MsSQLApiRef } from './api';

export const MssqlPlugin = createPlugin({
  id: 'mssql',
  featureFlags: [{ name: 'hosting-platform-mssql' }],
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: MsSQLApiRef,
      deps: {
        fetchApi: fetchApiRef,
        discoveryApi: discoveryApiRef,
      },
      factory: ({ fetchApi, discoveryApi }) =>
        new MsSQLApiClient({
          discoveryApi,
          fetchApi,
        }),
    }),
  ],
});

export const MssqlPage = MssqlPlugin.provide(
  createRoutableExtension({
    name: 'MssqlPage',
    component: () => import('./components/Router').then(m => m.Router),
    mountPoint: rootRouteRef,
  }),
);

export const MssqlInstancePage = MssqlPlugin.provide(
  createComponentExtension({
    name: 'MssqlInstancePage',
    component: {
      lazy: () => import('./components/InstancePage').then(m => m.InstancePage),
    },
  }),
);
