import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function FloppyDisk(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M9.031 10v20c0 .563.438 1 1 1h20c.5 0 1-.438 1-1V14.687c0-.25-.125-.5-.312-.687l2.125-2.125c.75.75 1.125 1.75 1.125 2.813V30c0 2.25-1.75 4-4 4H10.03c-2.25 0-4-1.75-4-4V10c0-2.188 1.75-4 4-4h15.313c1.062 0 2.062.438 2.812 1.188l4.688 4.687L30.719 14 26.03 9.312V9.25v6.25c0 .875-.687 1.5-1.5 1.5h-12c-.875 0-1.5-.625-1.5-1.5V9h-1c-.562 0-1 .5-1 1Zm5-1v5h9V9h-9Zm2 15c0-1.375.75-2.688 2-3.438 1.188-.687 2.75-.687 4 0 1.188.75 2 2.063 2 3.438 0 1.438-.812 2.75-2 3.5-1.25.688-2.812.688-4 0-1.25-.75-2-2.063-2-3.5Z" />
    </SvgIcon>
  );
}
