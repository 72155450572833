import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ShoppingBag(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M16 7v3h8V7c0-2.188-1.813-4-4-4-2.25 0-4 1.813-4 4Zm-3 6H9v21c0 1.688 1.313 3 3 3h16a3 3 0 0 0 3-3V13h-4v3.5c0 .875-.688 1.5-1.5 1.5-.875 0-1.5-.625-1.5-1.5V13h-8v3.5c0 .875-.688 1.5-1.5 1.5-.875 0-1.5-.625-1.5-1.5V13Zm0-3V7c0-3.813 3.125-7 7-7 3.813 0 7 3.188 7 7v3h4c1.625 0 3 1.375 3 3v21c0 3.313-2.688 6-6 6H12c-3.313 0-6-2.688-6-6V13a3 3 0 0 1 3-3h4Z" />
    </SvgIcon>
  );
}
