import React from 'react';
import {
  Entity,
  RELATION_API_CONSUMED_BY,
  stringifyEntityRef,
} from '@backstage/catalog-model';
import { Table, TableRowType } from '@lego/plugin-baseplate-core-components';
import { type ReactElement } from 'react';
import { useRelatedEntities } from '@backstage/plugin-catalog-react';

export interface AccessTabProps {
  entity: Entity;
}

export function AccessTab({ entity }: AccessTabProps): ReactElement {
  const consumers =
    useRelatedEntities(entity, {
      type: RELATION_API_CONSUMED_BY,
    }).entities ?? [];

  return (
    <div>
      <Table
        title="Your identities connected to this API"
        headers={[
          {
            label: 'Name',
            key: 'name',
          },
          {
            label: 'Product',
            key: 'product',
          },
          {
            label: 'Requests last 30 days',
            key: 'usage',
          },
        ]}
        data={consumers.slice(0, 2).map(
          (consumer, i): TableRowType => ({
            id: consumer.metadata.name,
            columns: {
              name: {
                value: stringifyEntityRef(consumer),
                type: 'entity',
              },
              product: {
                value: 'PRO-227',
                type: 'entity',
              },
              usage: {
                value: i * Math.round(Math.sin(i) + 1.5) * 100,
                type: 'number',
              },
            },
          }),
        )}
      />
      <br />
      <Table
        title="All identities connected to this API"
        headers={[
          {
            label: 'Name',
            key: 'name',
          },
          {
            label: 'Product',
            key: 'product',
          },
          {
            label: 'Requests last 30 days',
            key: 'usage',
          },
        ]}
        data={consumers.slice(0, 2).map(
          (consumer, i): TableRowType => ({
            id: consumer.metadata.name,
            columns: {
              name: {
                value: stringifyEntityRef(consumer),
                type: 'entity',
              },
              product: {
                value: 'PRO-227',
                type: 'entity',
              },
              usage: {
                value: i * Math.round(Math.sin(i) + 1.5) * 100,
                type: 'number',
              },
            },
          }),
        )}
      />
    </div>
  );
}
