import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ScrewdriverWrench(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="m8.894 4.29 6.5 5c.375.312.625.75.625 1.187v3.375l6.812 6.812a4.88 4.88 0 0 1 5.563.938l7 7c.812.75.812 2.062 0 2.812l-4 4a1.927 1.927 0 0 1-2.813 0l-7-7c-1.5-1.5-1.812-3.812-.875-5.625l-6.812-6.812h-3.438a1.54 1.54 0 0 1-1.187-.563l-5-6.5c-.438-.625-.375-1.437.187-2l2.5-2.5c.5-.5 1.375-.562 1.938-.125ZM5.456 27.54l8.563-8.563 2.125 2.125-8.563 8.562a1.939 1.939 0 0 0-.562 1.375c0 1.063.875 1.938 1.937 1.938.5 0 1-.188 1.375-.563l7.813-7.812a6.957 6.957 0 0 0 .75 3.5l-6.438 6.437a4.916 4.916 0 0 1-3.5 1.438 4.908 4.908 0 0 1-4.937-4.938c0-1.312.5-2.562 1.437-3.5Zm27.5-13.313-1.562 1.625c-.75.75-1.75 1.125-2.813 1.125H27.02c-2.25 0-4-1.75-4-4v-1.563c0-1.062.375-2.062 1.125-2.812l1.625-1.563c-3.75.125-6.75 3.188-6.75 6.938v.062l-1-1v-2.562a3.36 3.36 0 0 0-.5-1.75c1.75-2.813 4.875-4.75 8.5-4.75 1.5 0 3 .375 4.312 1 .563.312.625 1.062.188 1.5l-4.25 4.25c-.188.187-.25.437-.25.687v1.625c0 .5.437 1 1 1h1.562c.25 0 .5-.125.688-.312l4.25-4.25c.437-.438 1.187-.375 1.5.187a10.22 10.22 0 0 1 1 4.375c0 3.313-1.688 6.313-4.25 8.125l-1.938-2-.187-.187a6.948 6.948 0 0 0 3.312-5.75Z" />
    </SvgIcon>
  );
}
