import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function XTwitter(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M30.406 3.75h5.516L23.875 17.516 38.047 36.25H26.953l-8.695-11.36L8.32 36.25H2.797L15.68 21.523 2.094 3.75h11.375l7.851 10.383L30.406 3.75ZM28.47 32.953h3.054L11.805 6.875H8.523L28.47 32.953Z" />
    </SvgIcon>
  );
}
