import { useApi } from '@backstage/core-plugin-api';
import { useUserContext } from '@lego/plugin-baseplate-core-components';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { throttle } from 'lodash';
import { updateUserDataLayer } from './updateUserDataLayer';

declare global {
  interface Window {
    _paq: any;
  }
}

// Throttling limits the number of execution to once in every specified time interval
const sendPageLoadEvent = throttle(
  ({ url, pageTitle }: { url: string; pageTitle: string }) => {
    const _paq = (window._paq = window._paq || []);
    _paq.push(['setDocumentTitle', pageTitle]);
    _paq.push(['setCustomUrl', url]);
    _paq.push(['trackPageView']);
  },
  500,
);

/**
 * Hook to send page load event and update the data layer with the signed in user
 */
export const useClientSideAnalytics = () => {
  const location = useLocation();
  const { user } = useUserContext();
  const catalogApi = useApi(catalogApiRef);

  // Send event on every page load
  useEffect(() => {
    sendPageLoadEvent({ url: location.pathname, pageTitle: document.title });
  }, [location]);

  // Update the data layer with the signed in user
  useEffect(() => {
    const abortController = new AbortController();

    if (user.email) {
      // Call updateUserDataLayer with the abort signal
      updateUserDataLayer(user, catalogApi, abortController.signal).catch(
        _error => {
          abortController.abort();
        },
      );
    }

    return () => {
      abortController.abort();
    };
  }, [user, catalogApi]);
};
