import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Eye(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M20.031 9c-4.125 0-7.437 1.875-10 4.25C7.594 15.5 5.97 18.125 5.094 20c.875 1.875 2.5 4.563 4.937 6.813 2.563 2.375 5.875 4.187 10 4.187 4.063 0 7.375-1.813 9.938-4.188 2.437-2.25 4.125-4.937 4.937-6.812-.812-1.875-2.5-4.5-4.875-6.75-2.625-2.375-5.937-4.25-10-4.25ZM7.97 11.063C10.906 8.313 14.969 6 20.03 6c5 0 9.063 2.313 12 5.063 2.938 2.75 4.875 5.937 5.813 8.187.187.5.187 1.063 0 1.563C36.906 23 34.969 26.25 32.03 29c-2.937 2.75-7 5-12 5-5.062 0-9.125-2.25-12.062-5-2.938-2.75-4.875-6-5.813-8.188a2.24 2.24 0 0 1 0-1.562c.938-2.25 2.875-5.5 5.813-8.188ZM20.03 25c2.75 0 5-2.188 5-5 0-2.75-2.25-5-5-5h-.125c.063.375.125.688.125 1 0 2.25-1.812 4-4 4-.375 0-.687 0-1-.125V20c0 2.813 2.188 5 5 5Zm0-13c2.813 0 5.438 1.563 6.875 4 1.438 2.5 1.438 5.563 0 8a7.928 7.928 0 0 1-6.875 4c-2.875 0-5.5-1.5-6.937-4-1.438-2.438-1.438-5.5 0-8 1.437-2.438 4.062-4 6.937-4Z" />
    </SvgIcon>
  );
}
