import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Pen(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M6.295 26.5c.25-.875.75-1.687 1.375-2.312l19-19a4.036 4.036 0 0 1 5.687 0l2.438 2.437c.187.188.375.438.5.625a3.988 3.988 0 0 1-.5 5.063l-19 19c-.063.063-.188.125-.25.25-.625.5-1.313.875-2.063 1.125l-4.875 1.438-2.687.812c-.5.125-1.063 0-1.438-.438a1.275 1.275 0 0 1-.375-1.437l.75-2.688L6.295 26.5Zm2.875.875-.438 1.438-1 3.438 3.438-1 1.437-.438a2.3 2.3 0 0 0 1.063-.625l14.312-14.312L24.107 12 9.795 26.313c-.063 0-.063.063-.125.125-.25.25-.375.563-.5.938Z" />
    </SvgIcon>
  );
}
