/*
 * Copyright 2022 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { createApiRef } from '@backstage/core-plugin-api';
import {
  DataProductExternalLocation,
  ExternalLocationCreateRequest,
  ExternalLocationUpdateRequest,
} from './types';
import { PermissionsResponse, UpdatePermissionsRequest } from '../permissions';

export const ExternalLocationsApiRef = createApiRef<ExternalLocationsApi>({
  id: 'api.plugin.nexus.databricks.external.locations',
});

export const ExternalLocationsApiMockRef = createApiRef<ExternalLocationsApi>({
  id: 'api.plugin.nexus.databricks.external.locations.mock',
});

export interface ExternalLocationsApi {
  list(): Promise<DataProductExternalLocation[]>;

  create(
    dataProduct: string,
    externalLocationCreateRequest: ExternalLocationCreateRequest,
  ): Promise<DataProductExternalLocation>;

  update(
    dataProduct: string,
    name: string,
    externalLocationUpdateRequest: Partial<ExternalLocationUpdateRequest>,
  ): Promise<DataProductExternalLocation>;

  delete(dataProduct: string, name: string): Promise<object>;

  getPermissions(
    dataProduct: string,
    externalLocationName: string,
  ): Promise<PermissionsResponse>;

  updatePermissions(
    dataProduct: string,
    name: string,
    updatePermissionsRequest: Partial<UpdatePermissionsRequest>,
  ): Promise<PermissionsResponse>;
}
