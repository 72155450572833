import { createApiRef } from '@backstage/core-plugin-api';
import {
  LearningProfile,
  MentorshipConnection,
  Skill,
  UpsertMentorConnectionRequest,
  UpsertMentorConnectionResponse,
} from '@lego/plugin-baseplate-learning-and-development-common';

export const mentorMatchingApiRef = createApiRef<MentorMatchingApi>({
  id: 'plugin.learning-and-development.mentormatching',
});

export interface MentorMatchingApi {
  getSkills(keyword: string): Promise<Skill[]>;
  getMentors(): Promise<LearningProfile[]>;
  updateProfile(mentorProfile: any): Promise<any>;
  getMentorById(userId: string | number): Promise<LearningProfile>;
  getMyProfile(): Promise<LearningProfile>;
  getMentorsByParams(queryParams: any): Promise<LearningProfile[]>;
  getMentorshipConnection(): Promise<MentorshipConnection[]>;
  logMentorshipConnection(
    request: UpsertMentorConnectionRequest,
  ): Promise<UpsertMentorConnectionResponse>;
}
