import { useEffect, useState } from 'react';
import { TableRowType } from '../types';

type Props<T> = {
  data: TableRowType<T>[];
  processedData: TableRowType<T>[];
  selectable?: boolean;
  multiple?: boolean;
  setSelectedRow?:
    | ((selected: TableRowType<T>[]) => void)
    | ((selected: TableRowType<T>) => void);
};

export const useTableSelection = <T>({
  data,
  processedData,
  setSelectedRow,
  selectable,
  multiple,
}: Props<T>) => {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const onSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = processedData.map(row => row.id);
      setSelectedIds(newSelected);
      return;
    }
    setSelectedIds([]);
  };

  const handleCheckboxClick = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string,
  ) => {
    if (!selectable) return;
    if (multiple) {
      setSelectedIds(
        !event.target.checked
          ? selectedIds.filter(selectedId => selectedId !== id)
          : [...selectedIds, id],
      );
      return;
    }
    setSelectedIds(prev => (prev.includes(id) ? [] : [id]));
  };

  // Updates the selectedIds when the processedData changes
  useEffect(() => {
    if (selectable) {
      setSelectedIds(prevSelectedIds =>
        processedData
          .map(row => row.id)
          .filter(id => prevSelectedIds.includes(id)),
      );
    }
  }, [processedData, selectable]);

  // Updates the data sent to the parent component when the selectedIds change
  useEffect(() => {
    if (!selectable || !setSelectedRow) return;
    if (multiple) {
      (setSelectedRow as (selected: TableRowType<T>[]) => void)(
        data.filter(row => selectedIds.includes(row.id)),
      );
    } else {
      (setSelectedRow as (selected: TableRowType<T>) => void)(
        data.filter(row => selectedIds.includes(row.id))[0],
      );
    }
  }, [data, selectable, selectedIds, setSelectedRow, multiple]);

  return { onSelectAllClick, handleCheckboxClick, selectedIds };
};
