import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ArrowLeft(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M6.438 21.094C6.125 20.78 6 20.406 6 19.969c0-.375.125-.75.438-1.063l11-10.5c.625-.562 1.562-.562 2.125.063.562.562.562 1.562-.063 2.125l-8.313 7.875H32.5c.813 0 1.5.687 1.5 1.5 0 .875-.688 1.5-1.5 1.5H11.187l8.313 7.937c.625.563.625 1.5.063 2.125-.563.625-1.5.625-2.125.063l-11-10.5Z" />
    </SvgIcon>
  );
}
