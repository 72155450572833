import { showToast } from '@lego/plugin-baseplate-core-components';
import {
  OpenPosition,
  Product,
} from '@lego/plugin-baseplate-people-to-product-common';
import { TableCell, TableRow, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import ConfirmationActions from '../../../components/ConfirmationActions/ConfirmationActions';
import ProductDropdown from '../../../components/ProductDropdown/ProductDropdown';
import { useOpenPosition } from '../../../hooks';

interface NewOpenPositionProductRowProps {
  openPosition: OpenPosition;
  setIsAddingProduct: (isAddingProduct: boolean) => void;
}

function NewOpenPositionProductRow({
  openPosition,
  setIsAddingProduct,
}: NewOpenPositionProductRowProps) {
  const { addProductToOpenPosition } = useOpenPosition();
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);

  const handleOnCancelButtonClick = () => {
    setIsAddingProduct(false);
  };

  const handleOnSaveButtonClick = async () => {
    if (selectedProduct === null) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      showToast({
        type: 'error',
        message:
          'Please select a product that you want to assign to this open position',
      });

      return;
    }
    try {
      await addProductToOpenPosition(openPosition, selectedProduct);

      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      showToast({
        type: 'success',
        message: (
          <>
            <Typography>
              Successfully assigned <strong>{openPosition.positionId}</strong>{' '}
              to the following product:
            </Typography>
            <Typography style={{ fontWeight: 'bold' }}>
              {selectedProduct.name}
            </Typography>
          </>
        ),
      });
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      showToast({
        type: 'error',
        message: 'Error happened while assigning product.',
      });
    }
    setIsAddingProduct(false);
  };

  return (
    <TableRow>
      <TableCell>
        <ProductDropdown
          productsToExclude={[]}
          setSelectedProduct={setSelectedProduct}
        />
      </TableCell>
      <TableCell>
        <ConfirmationActions
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          handleOnSaveButtonClick={handleOnSaveButtonClick}
          handleOnCancelButtonClick={handleOnCancelButtonClick}
        />
      </TableCell>
    </TableRow>
  );
}

export default NewOpenPositionProductRow;
