import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Arrow360Oval(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M8.5 20c0-1.117.864-2.469 3.019-3.629C13.613 15.243 16.609 14.5 20 14.5s6.387.743 8.481 1.871C30.636 17.531 31.5 18.883 31.5 20c0 1.3-1.198 2.921-4.082 4.136a1.5 1.5 0 0 0 1.164 2.765C31.785 25.552 34.5 23.183 34.5 20c0-2.75-2.047-4.897-4.596-6.27-2.611-1.406-6.116-2.23-9.904-2.23-3.788 0-7.293.824-9.903 2.23C7.547 15.102 5.5 17.25 5.5 20c0 2.75 2.047 4.897 4.597 6.27 1.966 1.06 4.438 1.788 7.157 2.083l-3.823 3.822a1.521 1.521 0 0 0 0 2.122 1.48 1.48 0 0 0 2.121 0l6.364-6.364c.619-.619.619-1.503 0-2.121l-6.364-6.364a1.48 1.48 0 0 0-2.121 0 1.52 1.52 0 0 0 0 2.12l3.755 3.756c-2.225-.283-4.175-.891-5.667-1.695C9.364 22.469 8.5 21.117 8.5 20Z" />
    </SvgIcon>
  );
}
