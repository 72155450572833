import React, { useMemo } from 'react';
import { useState } from 'react';
import { UiExampleType } from '../../../types';
import {
  CodeBlock,
  Dropdown,
  baseplateStyles,
} from '@lego/plugin-baseplate-core-components';
import _ from 'lodash';
import { Typography } from '@material-ui/core';

export interface ExampleCodeBlockProps {
  title: string;
  examples: UiExampleType[];
}

const useStyles = baseplateStyles(theme => ({
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: theme.semantic.background.neutral[3],
    padding: '0.5rem 1rem',
    borderRadius: `${theme.primitive.borderRadius.default}px ${theme.primitive.borderRadius.default}px 0 0`,
    '&+div': {
      borderRadius: `0 0 ${theme.primitive.borderRadius.default}px ${theme.primitive.borderRadius.default}px`,
    },
  },
}));

export function ExampleCodeBlock({ title, examples }: ExampleCodeBlockProps) {
  const classes = useStyles();
  const [currentExample, setCurrentExample] = useState(0);

  if (examples.length === 0) return null;

  const example = useMemo(() => {
    // If the value is a string, we assume it's already formatted
    const value =
      typeof examples[currentExample]?.value === 'string'
        ? examples[currentExample]?.value
        : // Otherwise, we format it as JSON
          JSON.stringify(examples[currentExample]?.value, null, 2);

    return { value, language: examples[currentExample]?.language };
  }, [currentExample, examples]);

  return (
    <div>
      <div className={classes.title}>
        <span>{title}</span>
        {examples.length > 1 ? (
          <Dropdown
            small
            label=""
            required
            value={currentExample}
            onChange={e => setCurrentExample(e.target.value as number)}
            options={examples.map(({ label }, index) => ({
              label: _.truncate(label, {
                length: 40,
              }),
              value: index,
            }))}
          />
        ) : (
          <Typography variant="body2" color="secondary">
            {examples[0]?.label}
          </Typography>
        )}
      </div>
      <CodeBlock
        language={example.language}
        children={example.value}
        maxHeight={400}
      />
    </div>
  );
}
