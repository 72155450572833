import { Entity } from '@backstage/catalog-model';

interface Domain {
  id: string;
  name: string;
  subDomains: SubDomain[];
}

interface SubDomain {
  id: string;
  name: string;
  products: Entity[];
}

interface Spec {
  domain: {
    id: string;
    name: string;
    subDomain: { name: string; id: string };
  };
}

export function createDomainArray(entities: Entity[]): Domain[] {
  const domains: Domain[] = [];

  for (const entity of entities) {
    const spec = entity.spec as unknown as Spec;
    if (!spec.domain) continue;
    let domain = domains.find(d => d.id === spec.domain.id);

    if (!domain) {
      domain = {
        id: spec.domain.id,
        name: spec.domain.name,
        subDomains: [],
      };
      domains.push(domain);
    }

    let subDomain = domain.subDomains.find(
      sd => sd.id === spec.domain.subDomain.id,
    );

    if (!subDomain) {
      subDomain = {
        id: spec.domain.subDomain.id,
        name: spec.domain.subDomain.name,
        products: [],
      };
      domain.subDomains.push(subDomain);
    }

    subDomain.products.push(entity);
  }

  domains.sort((a, b) => a.name.localeCompare(b.name));

  for (const domain of domains) {
    domain.subDomains
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(sd =>
        sd.products.sort((a, b) => {
          if (!a.metadata.title || !b.metadata.title) return 0;
          const aTitle = a.metadata.title;
          const bTitle = b.metadata.title;
          return aTitle.localeCompare(bTitle);
        }),
      );
  }

  return domains;
}
