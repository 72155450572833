import '@backstage/cli/asset-types';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

const rootEl = document.getElementById('root');

if (!rootEl) {
  throw new Error(
    `E4578: You forget to put an element with id='root' in the dom.`,
  );
}

ReactDOM.createRoot(rootEl).render(<App />);
