import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  fetchApiRef,
} from '@backstage/core-plugin-api';
import { TemplateApiRef, TemplateClient } from './api';
import { rootRouteRef } from './routes';

export const ZabbixPagePlugin = createPlugin({
  id: 'plugin-baseplate-zabbix-management',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: TemplateApiRef,
      deps: {
        fetchApi: fetchApiRef,
        discoveryApi: discoveryApiRef,
      },
      factory: ({ fetchApi, discoveryApi }) =>
        new TemplateClient({
          discoveryApi,
          fetchApi,
        }),
    }),
  ],
});

export const ZabbixPage = ZabbixPagePlugin.provide(
  createRoutableExtension({
    name: 'ZabbixPage',
    component: () =>
      import('./components/ZabbixPage/ZabbixPage').then(m => m.ZabbixPage),
    mountPoint: rootRouteRef,
  }),
);

// Add the following to app.tsx
// REMEMBER: Change wherever it says "Template" to the name of your liking, but still matching the name of the plugin.
// import { TemplatePage } from './@lego/plugin-baseplate-zabbix-management';
// [...]
// <Route path="/zabbix-management" element={<TemplatePage />} />
// Remember to run yarn install in the root of the repo to install the new plugin :)
