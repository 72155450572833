import { showToast } from '@lego/plugin-baseplate-core-components';
import { Role } from '@lego/plugin-baseplate-eagraph-common';
import {
  Employee,
  Product,
} from '@lego/plugin-baseplate-people-to-product-common';
import { TableCell, TableRow, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import ConfirmationActions from '../../../components/ConfirmationActions/ConfirmationActions';
import ProductDropdown from '../../../components/ProductDropdown/ProductDropdown';
import { useNonDigitalProductManager } from '../../../hooks';

interface NewEmployeeProductTableRowProps {
  employee: Employee;
  setIsAddingProduct: (isAddingProduct: boolean) => void;
}
function NewProductManagerProductTableRow({
  employee,
  setIsAddingProduct,
}: NewEmployeeProductTableRowProps) {
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);

  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { addProductToNonDigitalProductManager } =
    useNonDigitalProductManager();

  const productsToExclude = employee.products.map(product => product.node);

  const handleOnSaveButtonClick = async () => {
    if (!selectedProduct) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      showToast({
        type: 'error',
        message:
          'Please select a product that you want to assign to this employee',
      });

      return;
    }

    try {
      await addProductToNonDigitalProductManager(employee, selectedProduct, [
        Role.ProductManager,
      ]);

      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      showToast({
        type: 'success',
        message: (
          <>
            <Typography color="textPrimary">
              Successfully assigned <strong>{employee.fullName}</strong> to the
              following product:
            </Typography>
            <Typography style={{ fontWeight: 'bold' }}>
              {selectedProduct.name} ({selectedProduct.id})
            </Typography>
          </>
        ),
      });
      setIsAddingProduct(false);
    } catch (error: any) {
      try {
        const messages = JSON.parse(error.body.error.message);
        void showToast({
          type: 'error',
          message: (
            <>
              <Typography>Failed to update roles. Reasons:</Typography>
              <ul>
                {messages.map((e: Error) => (
                  <li>
                    <Typography>{e.message}</Typography>
                  </li>
                ))}
              </ul>
            </>
          ),
        });
      } catch (_) {
        void showToast({
          type: 'error',
          message:
            'Something went wrong. Please refresh and try again. If the issue persists, please reach out to Enterprise Transparency.',
        });
      }
    }
  };

  const handleOnCancelButtonClick = () => {
    setIsAddingProduct(false);
  };

  return (
    <TableRow>
      <TableCell>
        <ProductDropdown
          productsToExclude={productsToExclude}
          setSelectedProduct={setSelectedProduct}
        />
      </TableCell>
      <TableCell>
        <Typography>Product Manager</Typography>
      </TableCell>
      <TableCell>
        <ConfirmationActions
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          handleOnSaveButtonClick={handleOnSaveButtonClick}
          handleOnCancelButtonClick={handleOnCancelButtonClick}
        />
      </TableCell>
    </TableRow>
  );
}

export default NewProductManagerProductTableRow;
