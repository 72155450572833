import { Input } from '@lego/plugin-baseplate-core-components';
import { FormControl, Typography } from '@material-ui/core';
import Markdown from 'markdown-to-jsx';
import React from 'react';
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';

export const MarkdownExtension = ({
  onChange,
  formData,
  required,
  rawErrors,
  uiSchema,
}: FieldExtensionComponentProps<string>) => {
  const uiOptions = uiSchema['ui:options'];
  const helperText = uiOptions?.helperText?.toString();
  const label = uiOptions?.label;
  return (
    <>
      <FormControl
        required={required}
        error={rawErrors?.length > 0 && !formData}
      >
        <Input
          label={label ? `${label} (Markdown)` : 'Markdown'}
          key="markdown-editor"
          multiline
          minRows={8}
          required={required}
          value={formData}
          defaultValue={formData}
          onChange={e => onChange(e.target.value)}
          style={{ width: '100%' }}
          helperText={helperText ?? 'Markdown description'}
        />
        {formData && <Typography>Preview</Typography>}
        <Markdown>{formData || ''}</Markdown>
      </FormControl>
    </>
  );
};
