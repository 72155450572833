import { createTheme } from '@backstage/theme';
import { DesignTokens } from './types';

export const theme = (tokens: DesignTokens, themeType: 'light' | 'dark') => {
  // this is for the color of the ownership cards
  const pageTheme = {
    colors: ['', ''],
    shape: '',
    backgroundImage: tokens.semantic.background.neutral[1],
    background: '#ff0000',
    fontColor: tokens.semantic.text.primary,
  };

  return createTheme({
    palette: {
      // Colors
      type: themeType,
      primary: {
        main: tokens.primitive.color.blue[1000],
        light: tokens.primitive.color.blue[700],
        dark: tokens.primitive.color.blue[1300],
        contrastText: tokens.semantic.text.primary,
      },
      secondary: {
        main: tokens.primitive.color.grey[500],
        light: tokens.primitive.color.grey[300],
        dark: tokens.primitive.color.grey[700],
        contrastText: tokens.semantic.text.primary,
      },
      error: {
        dark: tokens.semantic.text.error,
        main: tokens.semantic.border.error,
        light: tokens.semantic.background.error,
        contrastText: tokens.semantic.text.error,
      },
      warning: {
        dark: tokens.semantic.text.warning,
        main: tokens.semantic.border.warning,
        light: tokens.semantic.background.warning,
        contrastText: tokens.semantic.text.warning,
      },
      success: {
        dark: tokens.semantic.text.success,
        main: tokens.semantic.border.success,
        light: tokens.semantic.background.success,
        contrastText: tokens.semantic.text.success,
      },
      status: {
        ok: '',
        warning: '',
        error: '',
        pending: '',
        running: '',
        aborted: '',
      },
      textContrast: tokens.semantic.text.primary,
      textSubtle: tokens.semantic.text.secondary,
      textVerySubtle: tokens.semantic.text.tertiary,
      errorBackground: tokens.semantic.background.error,
      warningBackground: tokens.semantic.background.warning,
      infoBackground: tokens.semantic.background.info,
      errorText: tokens.semantic.text.error,
      infoText: tokens.semantic.text.info,
      warningText: tokens.semantic.text.warning,
      gold: tokens.semantic.text.warning,
      tabbar: {
        indicator: tokens.semantic.interactive.default,
      },
      bursts: {
        fontColor: tokens.semantic.text.primary,
        slackChannelText: tokens.semantic.text.secondary,
        backgroundColor: {
          default: tokens.semantic.background.neutral[1],
        },
        gradient: {
          linear: 'none',
        },
      },
      pinSidebarButton: {
        icon: tokens.semantic.icon.primary,
        background: tokens.semantic.background.neutral[1],
      },
      // Components
      background: {
        default: tokens.semantic.background.neutral[2],
        paper: tokens.semantic.background.neutral[1],
      },
      text: {
        primary: tokens.semantic.text.primary,
        secondary: tokens.semantic.text.secondary,
        disabled: tokens.semantic.text.tertiary,
      },
      border: tokens.semantic.border.default,
      navigation: {
        background: tokens.semantic.background.neutral[1],
        indicator: tokens.semantic.text.primary,
        color: tokens.semantic.text.primary,
        selectedColor: tokens.semantic.text.primary,
        navItem: {
          hoverBackground: tokens.semantic.background.neutral[2],
        },
      },
      highlight: tokens.semantic.text.primary,
      link: tokens.semantic.interactive.default,
      linkHover: tokens.semantic.interactive.hover,

      banner: {
        info: tokens.semantic.background.info,
        error: tokens.semantic.background.error,
        text: tokens.semantic.text.primary,
        link: tokens.semantic.interactive.default,
        warning: tokens.semantic.background.warning,
      },
    },

    fontFamily: 'Cera Pro',
    defaultPageTheme: 'home',

    pageTheme: {
      home: pageTheme,
      documentation: pageTheme,
      tool: pageTheme,
      service: pageTheme,
      website: pageTheme,
      library: pageTheme,
      applications: pageTheme,
      other: pageTheme,
      app: pageTheme,
      apis: pageTheme,
      onboarding: pageTheme,
    },
  });
};
