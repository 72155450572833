import React from 'react';
import {
  Header,
  HeaderLabel,
  SupportButton,
  SupportItem,
} from '@backstage/core-components';
import { EntityRefLink } from '@backstage/plugin-catalog-react';
import {
  FiveStarFeedbackButton,
  useEnvironmentStage,
} from '@lego/plugin-baseplate-core-components';
import {
  CONFLUENCE_PAGE_LINK,
  FEATURE_REQUEST_LINK,
  REPORT_ISSUE_LINK,
  TEAMS_CHANNEL_LINK,
  TEAMS_CHANNEL_WEBHOOK_LINK,
} from '@lego/plugin-baseplate-people-to-product-common';
import StarRate from '@material-ui/icons/StarRate';

function EmployeePageHeader() {
  const { isLocalEnvironment } = useEnvironmentStage();

  const supportButtonItems: SupportItem[] = [
    {
      title: 'Plugin support',
      links: [
        {
          title: 'Teams channel',
          url: TEAMS_CHANNEL_LINK,
        },
        {
          title: 'Confluence page',
          url: CONFLUENCE_PAGE_LINK,
        },
        {
          title: 'Report a bug',
          url: REPORT_ISSUE_LINK,
        },
        {
          title: 'Request a feature',
          url: FEATURE_REQUEST_LINK,
        },
      ],
    },
  ];

  return (
    <Header title="People to Product">
      <HeaderLabel
        label="Owner"
        value={
          <EntityRefLink
            entityRef="product:default/pro-254"
            title="Enterprise Transparency"
          />
        }
      />
      {!isLocalEnvironment && (
        <FiveStarFeedbackButton
          noTrack={false}
          webhook={TEAMS_CHANNEL_WEBHOOK_LINK}
          icon={<StarRate />}
        >
          Give feedback
        </FiveStarFeedbackButton>
      )}
      <SupportButton items={supportButtonItems} />
    </Header>
  );
}

export default EmployeePageHeader;
