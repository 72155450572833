import { Entity } from '@backstage/catalog-model';
import { useApp } from '@backstage/core-plugin-api';
import { Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { SupportLink } from '../SupportLink';
import { EntityLink as EntityLinkComponent } from '@lego/plugin-baseplate-core-components';
import { descriptionDictionary } from '../utils/descriptionDictonary';
import { SupportPageTitle } from '../SupportPageTitle';
import { useSupportStyles } from '../support.styles';

export const PluginSupport = ({ pluginEntity }: { pluginEntity?: Entity }) => {
  const app = useApp();
  const classes = useSupportStyles();
  const [pluginName, setPluginName] = useState<string>('');

  useEffect(() => {
    const populateEntityDetails = () => {
      const pluginEntityName =
        pluginEntity?.metadata.name
          .replace('plugin-baseplate-', '')
          .replace(/-/g, ' ') || '';
      setPluginName(pluginEntityName);
    };
    void populateEntityDetails();
  }, [pluginEntity]);

  return (
    <div className={classes.wrapper}>
      {pluginEntity ? (
        <div>
          <SupportPageTitle
            subtitle={`${pluginName} plugin`}
            title="Support & Feedback"
          />
          {pluginEntity.spec && pluginEntity.spec.owner && (
            <>
              <Typography variant="overline" color="secondary">
                Owned by
              </Typography>
              <EntityLinkComponent
                variant="link"
                entityRef={pluginEntity.spec.owner as string}
              />
            </>
          )}
          {pluginEntity.metadata.links &&
            pluginEntity.metadata.links.length !== 0 && (
              <div style={{ marginTop: 10 }}>
                <Typography variant="overline" color="secondary">
                  Plugin support
                </Typography>
                <div className={classes.linkGrid}>
                  {pluginEntity.metadata.links.map((entityLink, idx) => {
                    const IconComponent = entityLink.type
                      ? app.getSystemIcons()[entityLink.type]
                      : undefined;
                    return (
                      <SupportLink
                        key={idx}
                        title={entityLink.title as string}
                        href={entityLink.url}
                        icon={IconComponent && <IconComponent />}
                        description={
                          descriptionDictionary[
                            entityLink.type as keyof typeof descriptionDictionary
                          ] ?? ''
                        }
                      />
                    );
                  })}
                </div>
              </div>
            )}
        </div>
      ) : (
        <div>No plugin context found!</div>
      )}
    </div>
  );
};
