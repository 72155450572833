import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';
import { rootRouteRef } from './routes';

export const RabbitmqPlugin = createPlugin({
  id: 'rabbitmq',
  featureFlags: [{ name: 'hosting-platform-rabbitmq' }],
  routes: {
    root: rootRouteRef,
  },
});

export const RabbitmqPage = RabbitmqPlugin.provide(
  createRoutableExtension({
    name: 'RabbitmqPage',
    component: () => import('./components/Router').then(m => m.Router),
    mountPoint: rootRouteRef,
  }),
);
