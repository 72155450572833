import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

export default function Brick(props: any) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M0.75 20.9883V3.08203L14.25 0.65625V23.3438L0.75 20.9883ZM4.26562 7.91016V9.51562L6.58594 9.42188V16.0312L8.32031 16.1367V9.36328L10.6406 9.25781V7.51172L4.26562 7.91016ZM21.375 10.5C21.1172 10.5 20.875 10.4531 20.6484 10.3594C20.4219 10.2578 20.2227 10.1211 20.0508 9.94922C19.8789 9.77734 19.7422 9.57812 19.6406 9.35156C19.5469 9.125 19.5 8.88281 19.5 8.625C19.5 8.36719 19.5469 8.125 19.6406 7.89844C19.7422 7.67188 19.8789 7.47266 20.0508 7.30078C20.2227 7.12891 20.4219 6.99609 20.6484 6.90234C20.875 6.80078 21.1172 6.75 21.375 6.75C21.6328 6.75 21.875 6.80078 22.1016 6.90234C22.3281 6.99609 22.5234 7.12891 22.6875 7.30078C22.8594 7.47266 22.9922 7.67188 23.0859 7.89844C23.1875 8.125 23.2383 8.36719 23.2383 8.625C23.2383 8.88281 23.1875 9.125 23.0859 9.35156C22.9922 9.57812 22.8594 9.77734 22.6875 9.94922C22.5234 10.1211 22.3281 10.2578 22.1016 10.3594C21.875 10.4531 21.6328 10.5 21.375 10.5ZM19.5 17.8125V12H23.2383V15.1875C23.2383 15.5547 23.168 15.8984 23.0273 16.2188C22.8945 16.5312 22.7109 16.8086 22.4766 17.0508C22.2422 17.2852 21.9648 17.4727 21.6445 17.6133C21.3242 17.7461 20.9844 17.8125 20.625 17.8125H19.5ZM16.5 9C16.2031 9 15.9219 8.93359 15.6562 8.80078C15.3984 8.66797 15.1797 8.48047 15 8.23828V6.01172C15.1797 5.76953 15.3984 5.58203 15.6562 5.44922C15.9219 5.31641 16.2031 5.25 16.5 5.25C16.7578 5.25 17 5.30078 17.2266 5.40234C17.4531 5.49609 17.6523 5.62891 17.8242 5.80078C17.9961 5.97266 18.1289 6.17188 18.2227 6.39844C18.3242 6.625 18.375 6.86719 18.375 7.125C18.375 7.38281 18.3242 7.625 18.2227 7.85156C18.1289 8.07812 17.9961 8.27734 17.8242 8.44922C17.6523 8.62109 17.4531 8.75781 17.2266 8.85938C17 8.95312 16.7578 9 16.5 9ZM15 19.3125V10.5H18.75V17.0625C18.75 17.375 18.6914 17.668 18.5742 17.9414C18.457 18.2148 18.2969 18.4531 18.0938 18.6562C17.8906 18.8594 17.6523 19.0195 17.3789 19.1367C17.1055 19.2539 16.8125 19.3125 16.5 19.3125H15Z" />
    </SvgIcon>
  );
}
