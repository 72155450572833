import { rootRouteRef } from './routes';
import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

export const NasuniFormPlugin = createPlugin({
  id: 'plugin-baseplate-nasuni-form',
  routes: {
    root: rootRouteRef,
  },
});

export const NasuniFormPage = NasuniFormPlugin.provide(
  createRoutableExtension({
    name: 'NasuniFormPage',
    component: () =>
      import('./components/NasuniPageContainer/NasuniPageContainer').then(
        m => m.NasuniPageContainer,
      ),
    mountPoint: rootRouteRef,
  }),
);
