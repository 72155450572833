import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ArrowUpFromSquare(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="m21.063 4.43 6.937 7c.625.625.625 1.562 0 2.125-.563.625-1.5.625-2.125 0l-4.438-4.438v15.375c0 .875-.625 1.5-1.5 1.5a1.48 1.48 0 0 1-1.5-1.5V9.117L14 13.555a1.37 1.37 0 0 1-2.063 0c-.624-.563-.624-1.5 0-2.125l7-7c.563-.563 1.5-.563 2.125 0ZM8.5 5.992H9c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5h-.5c-.875 0-1.5.688-1.5 1.5v21c0 .875.625 1.5 1.5 1.5h23c.813 0 1.5-.625 1.5-1.5v-21c0-.812-.688-1.5-1.5-1.5H31c-.875 0-1.5-.625-1.5-1.5 0-.812.625-1.5 1.5-1.5h.5c2.438 0 4.5 2.063 4.5 4.5v21c0 2.5-2.063 4.5-4.5 4.5h-23c-2.5 0-4.5-2-4.5-4.5v-21c0-2.437 2-4.5 4.5-4.5Z" />
    </SvgIcon>
  );
}
