import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ArrowUpRightAndArrowDownLeftFromCenter(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M22.469 15.438 30.906 7h-5.375c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5h8.938c.875 0 1.5.688 1.5 1.5v9c0 .875-.625 1.5-1.5 1.5a1.48 1.48 0 0 1-1.5-1.5V9.125l-8.438 8.438c-.562.625-1.5.625-2.062 0-.625-.563-.625-1.5 0-2.125Zm-4.875 9.124L9.094 33h5.437c.813 0 1.5.688 1.5 1.5 0 .875-.687 1.5-1.5 1.5h-9c-.875 0-1.5-.625-1.5-1.5v-9c0-.813.625-1.5 1.5-1.5.813 0 1.5.688 1.5 1.5v5.438L15.47 22.5c.562-.625 1.5-.625 2.125 0 .562.563.562 1.5 0 2.063Z" />
    </SvgIcon>
  );
}
