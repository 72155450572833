/*
 * Copyright 2022 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { createApiRef } from '@backstage/core-plugin-api';
import { DataProductRegistrationPayload } from '../types/DataProductRegistration';
import { GroupAddPayload, GroupData } from '../types/Groups';

export const OnexApiRef = createApiRef<IOnexApi>({
  id: 'plugin.template.onex',
});

export interface IOnexApi {
  getProduct(name: string): Promise<any>;
  getOboToken(): Promise<string>;
  getApprovalRequests(approverEmail: string): Promise<any>;
  approveRequest(
    approvalId: string,
    decision: string,
    approvalComment: string,
  ): Promise<any>;
  registerDataProduct(
    registrationPayload: DataProductRegistrationPayload,
  ): Promise<any>;
  generateServicePrincipalOAuthCredentials(
    requesterEmail: string,
    servicePrincipalName: string,
  ): Promise<any>;
  getUserGroups(): Promise<GroupData[]>;
  getGroup(groupId: string): Promise<GroupData>;
  addGroupToNexus(groupAddPayload: GroupAddPayload): Promise<any>;
  removeGroupFromNexus(groupId: string): Promise<any>;
}
