import { createRouteRef } from '@backstage/core-plugin-api';

export const mainRoute = '/learning-and-development/communities';

export const paths: { [key: string]: string } = {
  myCommunities: '/joined',
  overview: '/:communityId/overview',
  members: '/:communityId/members',
  docs: '/:communityId/docs/default/community/:communityId',
  discover: '',
  manageCommunity: '/:manageView/:communityId?',
};

export const rootRouteRef = createRouteRef({
  id: 'communities',
});
