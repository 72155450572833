import { BackstageTheme } from '@backstage/theme';
import {
  component,
  primitive,
  semantic,
} from '@lego/plugin-baseplate-core-components';
import { customComponentOverrides } from './overrides';
import { theme } from './theme';
import { BaseplateTheme } from './types';

function getTheme(themeType: 'dark' | 'light') {
  const tokens = {
    primitive: primitive,
    semantic: semantic[themeType],
    component: component[themeType],
  };

  const customColorTheme = theme(tokens, themeType);

  const customThemes: BackstageTheme & BaseplateTheme = {
    ...customColorTheme,
    semantic: semantic[themeType],
    component: component[themeType],
    primitive,
    overrides: {
      ...customComponentOverrides(tokens),
    },
  };

  return customThemes;
}

export { getTheme };
