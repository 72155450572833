import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function MessageExclamation(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M17 29.99v1l4.5-3.375a3.025 3.025 0 0 1 1.813-.625H32c.5 0 1-.437 1-1v-18c0-.5-.5-1-1-1H8c-.563 0-1 .5-1 1v18c0 .563.438 1 1 1h6c1.625 0 3 1.375 3 3Zm-.063 4.813-.312.187-1.063.813c-.312.25-.687.25-1.062.125-.313-.188-.5-.5-.5-.938v-5H8c-2.25 0-4-1.75-4-4v-18c0-2.187 1.75-4 4-4h24c2.188 0 4 1.813 4 4v18c0 2.25-1.813 4-4 4h-8.688L17 34.74l-.063.063ZM20 8.99c.813 0 1.5.688 1.5 1.5v7c0 .875-.688 1.5-1.5 1.5-.875 0-1.5-.625-1.5-1.5v-7c0-.812.625-1.5 1.5-1.5Zm2 14c0 1.125-.938 2-2 2-1.125 0-2-.875-2-2 0-1.062.875-2 2-2 1.063 0 2 .938 2 2Z" />
    </SvgIcon>
  );
}
