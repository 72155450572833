/*
 * Copyright 2022 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { createApiRef } from '@backstage/core-plugin-api';
import {
  DataProductPrincipal,
  DataProductsApiResponse,
} from '@lego/plugin-baseplate-nexus-databricks-storage-credentials-and-external-locations-common';

export const DataProductsApiRef = createApiRef<DataProductsApi>({
  id: 'api.plugin.nexus.data.products',
});

export interface DataProductsApi {
  getDataProducts(userEmail?: string): Promise<DataProductsApiResponse>;

  getDataProductPrincipals(
    dataProduct: string,
  ): Promise<DataProductPrincipal[]>;
}
