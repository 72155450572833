import { ANNOTATION_EDIT_URL, Entity } from '@backstage/catalog-model';
import { Link } from '@backstage/core-components';
import { Typography, makeStyles } from '@material-ui/core';
import React from 'react';

interface props {
  entity: Entity;
}

const useStyles = makeStyles(theme => ({
  container: {
    display: 'grid',
    width: '100%',
    height: '50%',
    margin: `${theme.spacing(2)}px 0`,
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
    gap: '1rem',
    '& a': {
      display: 'block',
    },
  },
  description: {
    wordBreak: 'break-word',
    fontSize: '1rem',
  },
  smallContainer: {
    width: '50%',
  },
}));

export function ToolsAboutContent({ entity }: props) {
  const classes = useStyles();

  const entityMetadataEditUrl =
    entity.metadata.annotations?.[ANNOTATION_EDIT_URL];

  if (!entity?.metadata?.description) {
    return (
      <Typography variant="body1" paragraph className={classes.description}>
        Description not found. Add a description for this {entity.kind}
        <Link target="_blank" to={entityMetadataEditUrl ?? ''}>
          {' '}
          here.
        </Link>
      </Typography>
    );
  }

  return (
    <span className={classes.description}>{entity?.metadata?.description}</span>
  );
}
