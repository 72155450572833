import { useEffect } from 'react';
import { useNavigate } from 'react-router';

export const RenavigateContent = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(-1);
  }, [navigate]);
  return null;
};
