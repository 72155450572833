import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Italic(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M16 7.5c0-.813.625-1.5 1.5-1.5h13c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5h-4.313L17 31h5.5c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5h-13c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5h4.25l9.188-22H17.5c-.875 0-1.5-.625-1.5-1.5Z" />
    </SvgIcon>
  );
}
