import {
  ANNOTATION_LOCATION,
  ANNOTATION_VIEW_URL,
  stringifyEntityRef,
} from '@backstage/catalog-model';
import { LinkButton } from '@backstage/core-components';
import { Card } from '@lego/plugin-baseplate-core-components';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef, useEntity } from '@backstage/plugin-catalog-react';
import {
  ANNOTATION_LAST_INGESTION,
  isAPI,
  isOrganization,
} from '@lego/plugin-baseplate-common';
import { Button, showToast } from '@lego/plugin-baseplate-core-components';
import { Typography } from '@material-ui/core';
import CachedIcon from '@material-ui/icons/Cached';
import { DateTime } from 'luxon';
import React, { useCallback, useMemo } from 'react';
import { BaseplateAboutContent } from './BaseplateAboutContent';

// A card
export const BaseplateAboutCard = (): JSX.Element => {
  const { entity } = useEntity();
  const catalogApi = useApi(catalogApiRef);

  const entityLocation = entity.metadata.annotations?.[ANNOTATION_LOCATION];
  const allowRefresh = entityLocation?.startsWith('url:');

  const refreshEntity = useCallback(async () => {
    try {
      await catalogApi.refreshEntity(stringifyEntityRef(entity));
      void showToast({
        type: 'info',
        message: 'Refresh scheduled',
      });
    } catch (e: any) {
      void showToast({
        type: 'error',
        message: 'Failed to schedule refresh, try again later',
      });
    }
  }, [catalogApi, entity]);

  const timeSinceIngestion = useMemo(() => {
    if (entity.metadata.annotations?.[ANNOTATION_LAST_INGESTION]) {
      return DateTime.fromISO(
        entity.metadata.annotations?.[ANNOTATION_LAST_INGESTION],
      ).toRelative({ locale: 'en' });
    }
    return '';
  }, [entity]);

  const accessUrl = entity.metadata?.links?.find(
    link => link.type === 'access',
  );

  return (
    <Card title="About">
      <>
        {accessUrl && (
          <LinkButton
            to={accessUrl.url}
            variant="contained"
            color="primary"
            style={{ marginRight: 10 }}
          >
            Get access
          </LinkButton>
        )}
      </>

      <BaseplateAboutContent entity={entity} />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {!isAPI(entity) &&
          !(entity.kind.toLowerCase() === 'identity') &&
          !(entity.kind.toLowerCase() === 'workspace') && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body2">Source:</Typography>
              <Button
                href={entity.metadata.annotations?.[ANNOTATION_VIEW_URL] ?? '#'}
                variant="text"
                size="small"
              >
                LeanIX
              </Button>
            </div>
          )}
        {!isOrganization(entity) && (
          <Typography variant="inherit" style={{ marginRight: 10 }}>
            Last updated: {timeSinceIngestion}
          </Typography>
        )}
        {allowRefresh && (
          <Button
            variant="primary"
            endIcon={<CachedIcon />}
            style={{ marginRight: 10 }}
            onClick={() => void refreshEntity()}
          >
            Refresh
          </Button>
        )}
      </div>
    </Card>
  );
};
