import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Industry(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M7.5 9c-.313 0-.5.25-.5.5v20c0 .875.625 1.5 1.5 1.5h23c.813 0 1.5-.625 1.5-1.5V13.75l-8.75 5.563c-.438.312-1 .312-1.5.062s-.813-.75-.813-1.313v-4.437L13.25 18.75c-.5.25-1.063.25-1.5 0-.5-.25-.75-.75-.75-1.313V9.5c0-.25-.25-.5-.5-.5h-3ZM4 9.5C4 7.625 5.563 6 7.5 6h3C12.375 6 14 7.625 14 9.5v5.313l6.438-3.75c2-1.188 4.562.25 4.562 2.562v1.688l6.375-4.063c2-1.313 4.625.125 4.625 2.5V29.5c0 2.5-2.063 4.5-4.5 4.5h-23C6 34 4 32 4 29.5v-20Z" />
    </SvgIcon>
  );
}
