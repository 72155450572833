import * as React from 'react';

const SvgComponent = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M12.7813 3.195C12.6125 3.25875 12.5 3.42 12.5 3.6C12.5 3.78 12.6125 3.94125 12.7813 4.005L14.9 4.8L15.695 6.91875C15.7588 7.0875 15.92 7.2 16.1 7.2C16.28 7.2 16.4412 7.0875 16.505 6.91875L17.3 4.8L19.4188 4.005C19.5875 3.94125 19.7 3.78 19.7 3.6C19.7 3.42 19.5875 3.25875 19.4188 3.195L17.3 2.4L16.505 0.28125C16.4412 0.1125 16.28 0 16.1 0C15.92 0 15.7588 0.1125 15.695 0.28125L14.9 2.4L12.7813 3.195ZM0.84875 9C0.635 9.0975 0.5 9.31125 0.5 9.5475C0.5 9.78375 0.635 9.99375 0.84875 10.0913L1.48625 10.3875L1.79 10.5263L1.8125 10.5375L5.12375 12.0675L6.65375 15.375L6.665 15.3975L6.80375 15.7013L7.1 16.3425C7.1975 16.5562 7.41125 16.6913 7.64375 16.6913C7.87625 16.6913 8.09 16.5562 8.1875 16.3425L8.48375 15.7013L8.6225 15.3975L8.63375 15.375L10.1638 12.0638L13.475 10.5375L13.4975 10.5263L13.8013 10.3875L14.4425 10.0913C14.6562 9.99375 14.7913 9.78 14.7913 9.5475C14.7913 9.315 14.6562 9.10125 14.4425 9.00375L13.8013 8.7075L13.4975 8.56875L13.475 8.5575L10.1638 7.0275L8.6375 3.71625L8.62625 3.69375L8.4875 3.38625L8.19125 2.745C8.09375 2.53125 7.88 2.39625 7.6475 2.39625C7.415 2.39625 7.20125 2.53125 7.10375 2.745L6.8075 3.38625L6.66875 3.69L6.6575 3.7125L5.1275 7.02375L1.81625 8.55375L1.79375 8.565L1.49 8.70375L0.84875 9ZM3.96125 9.54375L5.88125 8.65875C6.27125 8.47875 6.5825 8.1675 6.7625 7.7775L7.6475 5.8575L8.5325 7.7775C8.7125 8.1675 9.02375 8.47875 9.41375 8.65875L11.3337 9.54375L9.41375 10.4288C9.02375 10.6088 8.7125 10.92 8.5325 11.31L7.6475 13.23L6.7625 11.31C6.5825 10.92 6.27125 10.6088 5.88125 10.4288L3.96125 9.5475V9.54375ZM14.9 14.4L12.7813 15.195C12.6125 15.2588 12.5 15.42 12.5 15.6C12.5 15.78 12.6125 15.9412 12.7813 16.005L14.9 16.8L15.695 18.9188C15.7588 19.0875 15.92 19.2 16.1 19.2C16.28 19.2 16.4412 19.0875 16.505 18.9188L17.3 16.8L19.4188 16.005C19.5875 15.9412 19.7 15.78 19.7 15.6C19.7 15.42 19.5875 15.2588 19.4188 15.195L17.3 14.4L16.505 12.2813C16.4412 12.1125 16.28 12 16.1 12C15.92 12 15.7588 12.1125 15.695 12.2813L14.9 14.4Z"
      fill="#F1F1F4"
    />
  </svg>
);
export default SvgComponent;
