import { EntityListProvider } from '@backstage/plugin-catalog-react';
import React from 'react';
import { ToolsEntitiesList } from './ToolsEntitiesList';

export const ToolsExplorerPage = () => {
  return (
    <EntityListProvider>
      <ToolsEntitiesList />
    </EntityListProvider>
  );
};
