import { useAnalytics } from '@backstage/core-plugin-api';
import { BaseplateAnalyticsEventAttributes } from '@lego/plugin-baseplate-common';
import Button, { ButtonProps } from '@material-ui/core/Button';
import React from 'react';

const getNodeText = (node: React.ReactNode): string => {
  // If the node is an array of children, recurse and join.
  if (node instanceof Array) {
    return node.map(getNodeText).join(' ').trim();
  }

  // If the node is a react element, recurse on its children.
  if (typeof node === 'object' && node) {
    return getNodeText((node as React.ReactElement)?.props?.children);
  }

  // Base case: the node is just text. Return it.
  if (['string', 'number'].includes(typeof node)) {
    return String(node);
  }

  // Base case: just return an empty string.
  return '';
};

interface BaseplateButtonProps extends ButtonProps {
  noTrack?: boolean;
  trackingAttributes?: BaseplateAnalyticsEventAttributes;
  icon?: React.ReactNode;
}

/**
 * @deprecated Use the Button component from @baseplate/plugin-core-components instead.
 */
export const BaseplateButton: React.FC<BaseplateButtonProps> = ({
  noTrack,
  onClick,
  trackingAttributes,
  icon,
  ...buttonProps
}) => {
  const analytics = useAnalytics();

  const handleClick = (event: React.MouseEvent<any, MouseEvent>) => {
    onClick?.(event);
    if (!noTrack) {
      analytics.captureEvent('click', getNodeText(buttonProps.children), {
        attributes: trackingAttributes,
      });
    }
  };

  return (
    <Button
      {...buttonProps}
      onClick={handleClick}
      style={{
        ...buttonProps.style,
      }}
      startIcon={icon && icon}
    >
      {buttonProps.children}
    </Button>
  );
};
