import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function HourglassHalf(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M8 5.5C8 4.687 8.625 4 9.5 4h21c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5H30v1.188c0 2.562-1 4.937-2.813 6.75L22.063 20l5.125 5.125C29 26.875 30 29.313 30 31.813V33h.5c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5h-21c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5h.5v-1.188c0-2.5 1-4.937 2.75-6.687L17.875 20l-5.125-5.063C11 13.126 10 10.75 10 8.188V7h-.5C8.625 7 8 6.375 8 5.5Zm6.875 21.75c-.25.25-.438.5-.688.75H25.75c-.188-.25-.438-.5-.688-.75L20 22.125l-5.125 5.125ZM25.75 12C26.563 10.937 27 9.562 27 8.187V7H13v1.188c0 1.437.438 2.75 1.188 3.812H25.75Z" />
    </SvgIcon>
  );
}
