import { OpenAPIV3_1 } from 'openapi-types';
import { UiParameterGroupType } from '../../../types';
import { resolveRef } from './utils/refResolver';
import { resolveSchema } from './schema';
import { getFirstMediaObject } from './media';

/**
 * Resolve the [request body object](https://swagger.io/specification/#request-body-object)
 */
export const resolveRequestBody = (
  requestBody:
    | OpenAPIV3_1.RequestBodyObject
    | OpenAPIV3_1.ReferenceObject
    | undefined,
  components: OpenAPIV3_1.ComponentsObject | undefined,
): {
  bodyParameterGroup: UiParameterGroupType;
  requestExamples: Record<string, OpenAPIV3_1.ExampleObject> | undefined;
} => {
  if (!requestBody) return { bodyParameterGroup: {}, requestExamples: {} };

  const resolvedRequestBody = resolveRef<OpenAPIV3_1.RequestBodyObject>(
    'requestBodies',
    requestBody,
    components,
  );

  const { schema, examples } = getFirstMediaObject(
    resolvedRequestBody.content,
    components,
  );

  const bodyParameters = resolveSchema(schema, components);

  const resolvedExamples = resolveRef<
    Record<string, OpenAPIV3_1.ExampleObject>
  >('examples', examples, components);

  return {
    bodyParameterGroup: { body: bodyParameters },
    requestExamples: resolvedExamples,
  };
};
