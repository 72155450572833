import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Grid245(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="m20.017 4.444-4.243 4.242 4.243 4.243 4.242-4.243-4.242-4.242Zm-2.122-2.122a3 3 0 0 1 4.243 0l4.242 4.243c1.15 1.149 1.15 3.094 0 4.243l-4.242 4.242a3 3 0 0 1-4.243 0l-4.242-4.242a2.962 2.962 0 0 1 0-4.243l4.242-4.243ZM8.703 15.757 4.46 20l4.243 4.243L12.945 20l-4.242-4.243Zm-2.121-2.121a3 3 0 0 1 4.242 0l4.243 4.243c1.149 1.149 1.149 3.093 0 4.242l-4.243 4.243a3 3 0 0 1-4.242 0L2.339 22.12a2.962 2.962 0 0 1 0-4.242l4.243-4.243ZM35.572 20l-4.242-4.243L27.088 20l4.242 4.243L35.573 20Zm-2.12-6.364 4.242 4.243c1.15 1.149 1.15 3.093 0 4.242l-4.242 4.243a3 3 0 0 1-4.243 0l-4.243-4.243a2.962 2.962 0 0 1 0-4.242l4.243-4.243a3 3 0 0 1 4.243 0ZM20.017 27.071l-4.243 4.243 4.243 4.242 4.242-4.242-4.242-4.243Zm-2.122-2.121a3 3 0 0 1 4.243 0l4.242 4.242c1.15 1.15 1.15 3.094 0 4.243l-4.242 4.243a3 3 0 0 1-4.243 0l-4.242-4.243a2.962 2.962 0 0 1 0-4.243l4.242-4.242Z" />
    </SvgIcon>
  );
}
