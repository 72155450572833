import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function BellOn(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M20 4c1.063 0 2 .938 2 2v1.125c4.5.75 8 4.688 8 9.375v2.125c0 2.813.938 5.563 2.688 7.813l.937 1.125c.375.5.438 1.125.188 1.625S33.063 30 32.5 30h-25c-.625 0-1.125-.313-1.375-.813-.25-.5-.188-1.125.188-1.625l.937-1.125c1.75-2.25 2.75-5 2.75-7.812V16.5c0-4.688 3.438-8.625 8-9.375V6c0-1.063.875-2 2-2Zm0 6h-.5a6.495 6.495 0 0 0-6.5 6.5v2.125c0 3-.875 5.875-2.5 8.375h18.938C27.813 24.5 27 21.625 27 18.625V16.5c0-3.563-2.938-6.5-6.5-6.5H20Zm4 22a4.122 4.122 0 0 1-1.188 2.875C22.063 35.625 21.063 36 20 36c-1.063 0-2.125-.375-2.875-1.125S16 33.062 16 32h8ZM0 16.5c0-.813.625-1.5 1.5-1.5h5c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5h-5C.625 18 0 17.375 0 16.5ZM33.5 15h5c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5h-5c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5Zm3.813-9.625c.374.75.062 1.625-.688 2l-4 2c-.75.375-1.625.063-2-.688-.375-.75-.063-1.624.688-2l4-2c.75-.375 1.624-.062 2 .688Zm-34 2c-.75-.375-1.063-1.25-.688-2s1.25-1.063 2-.688l4 2c.75.375 1.063 1.25.688 2-.376.75-1.25 1.063-2 .688l-4-2Z" />
    </SvgIcon>
  );
}
