import { parseEntityRef } from '@backstage/catalog-model';
import { useRouteRef } from '@backstage/core-plugin-api';
import { entityRouteRef } from '@backstage/plugin-catalog-react';
import {
  showToast,
  useUserContext,
} from '@lego/plugin-baseplate-core-components';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const RedirectMyProduct = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const catalogEntityRoute = useRouteRef(entityRouteRef);

  const { backstageIdentity } = useUserContext();

  useEffect(() => {
    if (!backstageIdentity) return;
    const productRef = backstageIdentity?.ownershipEntityRefs.find(
      o => parseEntityRef(o).kind.toLocaleLowerCase() === 'product',
    );
    if (!productRef) {
      void showToast({
        message:
          "You're not associated with any product. Contact your people leader.",
        type: 'warning',
      });
      navigate('/products', { replace: true });
    } else {
      const link = catalogEntityRoute(parseEntityRef(productRef));
      const segment = pathname.substring(pathname.lastIndexOf('/') + 1);
      navigate(`${link}/${segment}`, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backstageIdentity?.ownershipEntityRefs]);

  return null;
};
