import React from 'react';
import ConfirmationPopover from '../../components/ConfirmationPopover/ConfirmationPopover';
import { Checkbox } from '@material-ui/core';
import { Product } from '@lego/plugin-baseplate-people-to-product-common';

interface ChangeTcoCheckboxProps {
  checked: boolean;
  onChange: () => void;
  product: Product;
  disabled: boolean;
}

function ChangeTcoCheckbox({
  checked,
  onChange,
  product,
  disabled,
}: ChangeTcoCheckboxProps) {
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleOnCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.checked) {
      setAnchorEl(event.currentTarget);
      setOpen(true);
    }
  };

  const handleOnPopoverConfirm = () => {
    onChange();
    setOpen(false);
  };

  const handleOnPopoverCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <Checkbox
        checked={checked}
        onChange={handleOnCheckboxChange}
        disabled={disabled}
      />
      <ConfirmationPopover
        open={open}
        anchorEl={anchorEl}
        message={`Are you sure you want to change the TCO (Total Cost of Ownership) to ${product.name} (${product.id})?`}
        onConfirm={handleOnPopoverConfirm}
        onCancel={handleOnPopoverCancel}
      />
    </>
  );
}

export default ChangeTcoCheckbox;
