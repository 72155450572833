/*
 * Copyright 2022 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

export interface DataProduct {
  name: string;
  status: string;
}

import { DiscoveryApi, FetchApi, ConfigApi } from '@backstage/core-plugin-api';
import { ResponseError } from '@backstage/errors';
import { IOnexApi } from './ONEXApi';
import { jwtDecode } from 'jwt-decode';
import { DataProductRegistrationPayload } from '../types/DataProductRegistration';
import { GroupAddPayload, GroupData } from '../types/Groups';

export class OnexApi implements IOnexApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi: FetchApi;
  private readonly configApi: ConfigApi;
  private readonly azureAuth: {
    getAccessToken: (scope: string) => Promise<string>;
  };
  private oboToken: string | undefined;

  public constructor(options: {
    discoveryApi: DiscoveryApi;
    fetchApi: FetchApi;
    configApi: ConfigApi;
    azureAuth: {
      getAccessToken: (scope: string) => Promise<string>;
    };
  }) {
    this.discoveryApi = options.discoveryApi;
    this.fetchApi = options.fetchApi;
    this.configApi = options.configApi;
    this.azureAuth = options.azureAuth;
  }

  async getProduct(productName: string): Promise<DataProduct[]> {
    const baseUrl = await this.discoveryApi.getBaseUrl('onex');
    const response = await this.fetchApi.fetch(
      `${baseUrl}/v3/data_products/${productName}`,
    );

    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }
    return (await response.json()) as DataProduct[];
  }

  async getOboToken(): Promise<string> {
    if (this.oboToken) {
      const decodedToken = jwtDecode(this.oboToken);
      const now = Math.floor(Date.now() / 1000);
      const twoMinutes = 120;

      if (decodedToken.exp && decodedToken.exp > now + twoMinutes) {
        // Token is still valid for more than 2 minutes return it instead
        return this.oboToken;
      }
    }

    const impersonationScope = this.configApi.getString(
      'integrations.pluginONEX.onexObo.impersonationScope',
    );

    const accessToken = await this.azureAuth.getAccessToken(impersonationScope);

    const baseUrl = await this.discoveryApi.getBaseUrl('onex');
    const response = await this.fetchApi.fetch(`${baseUrl}/oboToken`, {
      method: 'GET',
      headers: {
        azureaccesstoken: accessToken,
      },
    });

    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }
    const token = (await response.json()).token as string;
    this.oboToken = token;
    return token;
  }

  async getApprovalRequests(approverEmail: string): Promise<any> {
    const baseUrl = await this.discoveryApi.getBaseUrl('onex');
    const response = await this.fetchApi.fetch(
      `${baseUrl}/v3/approval_requests/?approver=${approverEmail}`,
    );

    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }
    return await response.json();
  }

  async approveRequest(
    approvalId: string,
    decision: string,
    approvalComment: string,
  ): Promise<void> {
    const onBehalfOfToken = await this.getOboToken();

    const baseUrl = await this.discoveryApi.getBaseUrl('onex');
    const response = await this.fetchApi.fetch(
      `${baseUrl}/v3/approval_requests/${approvalId}`,
      {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          azureaccesstoken: onBehalfOfToken,
        },
        body: JSON.stringify({
          status: decision,
          approver_decision_reason: approvalComment,
        }),
      },
    );

    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }

    return await response.json();
  }

  async registerDataProduct(
    registrationPayload: DataProductRegistrationPayload,
  ): Promise<any> {
    const baseUrl = await this.discoveryApi.getBaseUrl('onex');
    const response = await this.fetchApi.fetch(
      `${baseUrl}/v3/data_products/register`,
      {
        method: 'POST',
        body: JSON.stringify(registrationPayload),
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }

    return await response.json();
  }

  async generateServicePrincipalOAuthCredentials(
    requesterEmail: string,
    servicePrincipalName: string,
  ): Promise<any> {
    const baseUrl = await this.discoveryApi.getBaseUrl('onex');
    const response = await this.fetchApi.fetch(
      `${baseUrl}/v2/service_principal/oauth_credentials`,
      {
        method: 'POST',
        body: JSON.stringify({
          requester: requesterEmail,
          service_principal_name: servicePrincipalName,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }

    return await response.json();
  }

  async getUserGroups(): Promise<GroupData[]> {
    const onBehalfOfToken = await this.getOboToken();

    const baseUrl = await this.discoveryApi.getBaseUrl('onex');
    const response = await this.fetchApi.fetch(
      `${baseUrl}/v4/users/me/groups`,
      {
        headers: {
          azureaccesstoken: onBehalfOfToken,
        },
      },
    );

    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }

    const responseBody = await response.json();
    return responseBody.data as GroupData[];
  }

  async getGroup(groupId: string): Promise<GroupData> {
    const onBehalfOfToken = await this.getOboToken();

    const baseUrl = await this.discoveryApi.getBaseUrl('onex');
    const response = await this.fetchApi.fetch(
      `${baseUrl}/v4/groups/${groupId}`,
      {
        headers: {
          azureaccesstoken: onBehalfOfToken,
        },
      },
    );

    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }

    const responseBody = await response.json();
    return responseBody.data[0] as GroupData;
  }

  async addGroupToNexus(groupAddPayload: GroupAddPayload): Promise<any> {
    const onBehalfOfToken = await this.getOboToken();

    const baseUrl = await this.discoveryApi.getBaseUrl('onex');
    const response = await this.fetchApi.fetch(`${baseUrl}/v4/groups`, {
      method: 'POST',
      body: JSON.stringify(groupAddPayload),
      headers: {
        'Content-Type': 'application/json',
        azureaccesstoken: onBehalfOfToken,
      },
    });

    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }

    return await response.json();
  }

  async removeGroupFromNexus(groupId: string): Promise<any> {
    const onBehalfOfToken = await this.getOboToken();

    const baseUrl = await this.discoveryApi.getBaseUrl('onex');
    const response = await this.fetchApi.fetch(
      `${baseUrl}/v4/groups/${groupId}`,
      {
        method: 'DELETE',
        headers: {
          azureaccesstoken: onBehalfOfToken,
        },
      },
    );

    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }

    return await response.json();
  }
}
