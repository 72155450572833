import React from 'react';
import {
  SideMenu,
  baseplateStyles,
} from '@lego/plugin-baseplate-core-components';
import { type ReactElement } from 'react';
import { UiNavItem } from '../../../types';

export interface DefinitionSideNavigationProps {
  navItems: UiNavItem[];
}

const useStyles = baseplateStyles(theme => ({
  sideNavigation: {
    left: 0,
    position: 'sticky',
    width: 300,
    zIndex: 5,
    top: theme.primitive.dimensions.nav.height,
    height: `calc(100vh - ${theme.primitive.dimensions.nav.height})`,
    overflowY: 'auto',
    borderRight: `1px solid ${theme.semantic.border.default}`,
    backgroundColor: theme.semantic.background.neutral[2],
    gridArea: 'sidebar',
  },
}));

const scrollIntoView = (id: string) => {
  const offset = 48;
  const targetElement = document.getElementById(id);
  if (targetElement) {
    const elementPosition =
      targetElement.getBoundingClientRect().top + window.scrollY;
    const offsetPosition = elementPosition - offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  }
};

export function DefinitionSideNavigation({
  navItems,
}: DefinitionSideNavigationProps): ReactElement {
  const classes = useStyles();

  return (
    <nav className={classes.sideNavigation}>
      <div className={classes.innerWrapper}>
        <SideMenu.Wrapper>
          {navItems.map(({ key, label, children }) => (
            <SideMenu.Item
              key={key}
              label={label}
              onClick={() =>
                (!children || children.length < 1) && scrollIntoView(key)
              }
              children={children?.map(({ key, label, chip }) => (
                <SideMenu.Item
                  key={key}
                  chip={chip}
                  label={label}
                  onClick={() => scrollIntoView(key)}
                />
              ))}
            />
          ))}
        </SideMenu.Wrapper>
      </div>
    </nav>
  );
}
