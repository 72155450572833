import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Users(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M9 14c-1.813 0-3.438-.938-4.375-2.5-.875-1.5-.875-3.438 0-5C5.563 5 7.188 4 9 4c1.75 0 3.375 1 4.313 2.5.874 1.563.874 3.5 0 5C12.374 13.063 10.75 14 9 14Zm23 0c-1.813 0-3.438-.938-4.375-2.5-.875-1.5-.875-3.438 0-5C28.563 5 30.188 4 32 4c1.75 0 3.375 1 4.313 2.5.874 1.563.874 3.5 0 5C35.374 13.063 33.75 14 32 14ZM0 22.688C0 19 2.938 16 6.625 16h2.688c1 0 1.937.25 2.812.625C12 17.063 12 17.563 12 18c0 2.438 1 4.563 2.688 6H1.311A1.282 1.282 0 0 1 0 22.687ZM25.313 24h-.063c1.688-1.438 2.75-3.563 2.75-6 0-.438-.063-.938-.125-1.375a6.582 6.582 0 0 1 2.75-.625h2.688C37 16 40 19 40 22.688c0 .75-.625 1.312-1.375 1.312H25.312ZM20 15c-1.125 0-2.063.625-2.625 1.5-.563.938-.563 2.125 0 3 .563.938 1.5 1.5 2.625 1.5 1.063 0 2-.563 2.563-1.5.562-.875.562-2.063 0-3C22 15.625 21.063 15 20 15Zm0 9c-2.188 0-4.125-1.125-5.25-3-1.063-1.813-1.063-4.125 0-6 1.125-1.813 3.063-3 5.25-3 2.125 0 4.063 1.188 5.188 3 1.062 1.875 1.062 4.188 0 6-1.125 1.875-3.063 3-5.188 3Zm-3.688 5c-2.5 0-4.562 1.75-5.187 4h17.688c-.625-2.25-2.688-4-5.188-4h-7.313Zm0-3h7.313A8.375 8.375 0 0 1 32 34.375c0 .938-.75 1.625-1.688 1.625H9.625C8.687 36 8 35.25 8 34.375 8 29.75 11.688 26 16.313 26Z" />
    </SvgIcon>
  );
}
