import { createRouteRef, createSubRouteRef } from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
  id: 'people-to-product',
});

export const reportsRouteRef = createSubRouteRef({
  id: 'people-to-product/reports',
  path: '/reports',
  parent: rootRouteRef,
});

export const openPositionsRouteRef = createSubRouteRef({
  id: 'people-to-product/open-positions',
  path: '/open-positions',
  parent: rootRouteRef,
});

export const nonDigitalProductManagersRouteRef = createSubRouteRef({
  id: 'people-to-product/product-managers',
  path: '/product-managers',
  parent: rootRouteRef,
});

export const overviewRouteRef = createSubRouteRef({
  id: 'people-to-product/overview',
  path: '/overview',
  parent: rootRouteRef,
});
