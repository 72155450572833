import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import React from 'react';
import { Input } from '../input/Input';
import { BaseplateInputProps } from '../input/InputProps.type';
import { useAutocompleteDropdownStyles } from './AutocompleteDropdown.styles';
import { useDropdownMenuStyles } from '../dropdown/dropdownMenu.styles';
import { Chip } from '../chip/Chip';

export type Props = Omit<
  AutocompleteProps<any, boolean, boolean, true>,
  'renderInput'
> &
  BaseplateInputProps & {
    error?: boolean;
    required?: boolean;
    placeholder?: string;
  };

/**
 * A Baseplate autocomplete dropdown component that extends the MUI Autocomplete component.
 * @docs https://baseplate.legogroup.io/docs/default/component/baseplate
 */
export const AutocompleteDropdown = ({
  label,
  helperText,
  errorText,
  error,
  success,
  successText,
  placeholder,
  options,
  required,
  loading,
  value,
  setInputValue,
  ...props
}: Props) => {
  const classes = useAutocompleteDropdownStyles();
  const dropdownMenuClasses = useDropdownMenuStyles();

  return (
    <Autocomplete
      {...props}
      value={value}
      className={[
        classes.root,
        dropdownMenuClasses.menu,
        props.multiple ? classes.multiple : '',
      ].join(' ')}
      renderInput={params => (
        <Input
          {...params}
          required={required}
          value={value}
          label={label}
          placeholder={placeholder || label}
          helperText={helperText}
          errorText={errorText}
          error={error}
          loading={loading}
          success={success}
          successText={successText}
          onChange={e => setInputValue?.(e.target.value)}
          InputProps={{
            ...params.InputProps,
          }}
        />
      )}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => {
          const chipLabel = typeof option === 'string' ? option : option.label;
          return <Chip label={chipLabel} {...getTagProps({ index })} />;
        })
      }
      disablePortal
      options={options}
    />
  );
};
