import { FilterChip } from '@lego/plugin-baseplate-core-components';
import { Employee } from '@lego/plugin-baseplate-people-to-product-common';
import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { NavigateOptions, URLSearchParamsInit } from 'react-router-dom';
import {
  filterEmployeesWithProducts,
  filterEmployeesWithoutProducts,
} from '../../../hooks/filters/filters';

interface HeaderProps {
  loading: boolean;
  nonDigitalProductManagers: Employee[];
  productsFilter: boolean | string[] | null;
  setSearchParams: (
    nextInit?:
      | URLSearchParamsInit
      | ((prev: URLSearchParams) => URLSearchParamsInit)
      | undefined,
    navigateOpts?: NavigateOptions | undefined,
  ) => void;
}

export function Header({
  loading,
  nonDigitalProductManagers,
  productsFilter,
  setSearchParams,
}: HeaderProps) {
  return (
    <>
      {loading ? (
        <>Loading...</>
      ) : (
        <>
          <Typography variant="h5" style={{ padding: 2 }}>
            Non Digital Product Managers
          </Typography>
          <Grid container spacing={1} style={{ paddingLeft: 0 }}>
            <Grid item>
              <FilterChip
                label="All"
                count={nonDigitalProductManagers.length}
                selected={productsFilter === null}
                onClick={() =>
                  setSearchParams(prevParams => {
                    if (productsFilter !== null) {
                      prevParams.delete('products');
                    }
                    return prevParams;
                  })
                }
              />
            </Grid>
            <Grid item>
              <FilterChip
                label="Without products"
                count={
                  nonDigitalProductManagers.filter(
                    filterEmployeesWithoutProducts,
                  ).length
                }
                selected={productsFilter === false}
                onClick={(_, selectedState: boolean) =>
                  setSearchParams(prevParams => {
                    if (productsFilter !== false) {
                      prevParams.set('products', String(selectedState));
                    }
                    return prevParams;
                  })
                }
              />
            </Grid>
            <Grid item>
              <FilterChip
                label="With products"
                count={
                  nonDigitalProductManagers.filter(filterEmployeesWithProducts)
                    .length
                }
                selected={productsFilter === true}
                onClick={() =>
                  setSearchParams(prevParams => {
                    if (productsFilter !== true) {
                      prevParams.set('products', 'true');
                    }
                    return prevParams;
                  })
                }
              />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}
