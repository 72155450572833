import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function BoxArchive(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M7 9v3h26V9H7ZM6 6h28c1.063 0 2 .938 2 2v5c0 1.125-.938 2-2 2H6c-1.125 0-2-.875-2-2V8c0-1.063.875-2 2-2Zm8 13.5c0-.813.625-1.5 1.5-1.5h9c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5h-9c-.875 0-1.5-.625-1.5-1.5ZM6 30V17h3v13c0 .563.438 1 1 1h20c.5 0 1-.438 1-1V17h3v13c0 2.25-1.813 4-4 4H10c-2.25 0-4-1.75-4-4Z" />
    </SvgIcon>
  );
}
