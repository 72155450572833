import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ForwardStep(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M25.5 9.5c0-.813.625-1.5 1.5-1.5.813 0 1.5.688 1.5 1.5v21c0 .875-.688 1.5-1.5 1.5-.875 0-1.5-.625-1.5-1.5v-6.563l-11.063 7.75c-.312.25-.687.313-1.062.313a1.842 1.842 0 0 1-1.875-1.875V9.937C11.5 8.876 12.313 8 13.375 8a1.7 1.7 0 0 1 1.063.375l11.062 7.75V9.5Zm0 10.813v-.563l-11-7.688V28l11-7.688Z" />
    </SvgIcon>
  );
}
