import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ShieldCheck(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M8.564 12c-.375.188-.563.5-.563.813 0 5.75 2.375 15.562 11.625 20 .188.124.5.124.688 0 9.25-4.376 11.625-14.25 11.687-20 0-.313-.25-.626-.562-.813L20 7.187 8.564 12Zm24-2.75C33.939 9.875 35 11.188 35 12.813c-.062 6.25-2.625 17.562-13.375 22.75-1.062.5-2.25.5-3.312 0C7.564 30.375 5 19.063 5 12.813c-.062-1.626 1-2.938 2.375-3.563l11.75-5a1.996 1.996 0 0 1 1.688 0l11.75 5Zm-5.5 7.875-8 8c-.625.625-1.563.625-2.125 0l-4-4a1.369 1.369 0 0 1 0-2.063c.562-.625 1.5-.625 2.125 0L18 22l6.938-6.938c.562-.624 1.5-.624 2.125 0 .562.563.562 1.5 0 2.063Z" />
    </SvgIcon>
  );
}
