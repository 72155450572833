/***/
/**
 * Common functionalities for the ai plugin
 *
 * @packageDocumentation
 */

export interface UserMessage {
  role: 'Human';
  content: string;
  error?: boolean;
}

export interface LangChainMessage {
  role: 'AI';
  content: string;
  error?: boolean;
}

export interface RequestParams {
  prompt: string;
  context?: string;
  pastMessages: (UserMessage | LangChainMessage)[];
  azureOpenAIApiDeploymentName: string;
  temperature: number;
  verbose: boolean;
}

export interface ResponseParams {
  aiMessage: LangChainMessage;
  verboseLog: string;
}

export enum Role {
  Human = 'Human',
  AI = 'AI',
}

export interface HistoryItem {
  data: RequestParams;
  lastChange: number;
  summary: string;
}

export class History {
  items!: HistoryItem[];

  public History() {
    this.load();
  }

  private add(item: RequestParams) {
    const historyItem: HistoryItem = {
      data: item,
      lastChange: new Date().getTime(),
      summary: '',
    };
    this.items.push(historyItem);
    this.save();
  }

  public save() {
    localStorage.setItem('history', JSON.stringify(this.items));
  }

  public order() {
    this.items.sort((a, b) => {
      const dateA = new Date(a.lastChange);
      const dateB = new Date(b.lastChange);
      return dateA.getTime() - dateB.getTime();
    });
    this.save();
  }

  public load() {
    const history = localStorage.getItem('history');
    if (history !== 'undefined' && history !== null) {
      const items = JSON.parse(history);
      this.items = items;
    } else {
      this.items = [];
    }
  }

  public update(index: number | null, item: RequestParams) {
    if (item.prompt === '') {
      return;
    }
    if (index === null || index >= this.items.length) {
      this.add(item);
    } else {
      this.items[index].data = item;
      this.items[index].lastChange = new Date().getTime();
    }
    this.save();
  }

  public delete(item: number) {
    this.items.splice(item, 1);
    this.save();
  }

  public newSummary(index: number, summary: string) {
    this.items[index].summary = summary;
    this.save();
  }
}
