import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const cloudPlugin = createPlugin({
  id: 'cloud',
  routes: {
    root: rootRouteRef,
  },
});

export const CloudPage = cloudPlugin.provide(
  createRoutableExtension({
    name: 'CloudPage',
    component: () => import('./components/CloudPage').then(m => m.CloudPage),
    mountPoint: rootRouteRef,
  }),
);
