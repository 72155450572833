import { makeFieldSchemaFromZod } from '@backstage/plugin-scaffolder';
import { z } from 'zod';

const multiStringFieldSchema = makeFieldSchemaFromZod(
  z.string().array(),
  z.object({
    title: z.string().describe('Title of the field'),
    fieldName: z.string().describe('Label of the field'),
    sampleValue: z.string().describe('Sample value of the field'),
    maxItems: z.number().optional().describe('Maximum number of items'),
  }),
);

export type MultiStringFieldExtensionProps = typeof multiStringFieldSchema.type;

/**
 * The input props that can be specified under `ui:options` for the
 * `MultiStringField` field extension.
 *
 * @public
 */
export type MultiStringFieldExtensionUiOptions =
  typeof multiStringFieldSchema.uiOptionsType;

export const MultiStringFieldExtensionSchema = multiStringFieldSchema.schema;
