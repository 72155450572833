import { createApiRef } from '@backstage/core-plugin-api';
import { JsonObject } from '@backstage/types';
import { IEspStatus } from '../types';

export const espStatusApiRef = createApiRef<IEspClient>({
  id: 'plugin.esp-status.service',
});

export interface IEspClient {
  getStatus(environment: string): Promise<IEspStatus>;
  getMetrics(
    environment: string,
    metricType: string,
    queryParams: JsonObject,
  ): Promise<any>;
}
