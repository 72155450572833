import { ResponseErrorPanel, Table } from '@backstage/core-components';
import React, { MouseEvent, useState } from 'react';
import { useNonDigitalProductManager, useReport } from '../../../hooks';
import ProductManagerSidePanel from './ProductManagerSidePanel';
import { columns } from './columns';
import { Employee } from '@lego/plugin-baseplate-people-to-product-common';
import { Header } from './Header';
import { useNonDigitalProductManagerSearchParams } from '../../../hooks/useNonDigitalProductManagerSearchParams';

export function NonDigitalProductManagerTable() {
  const [selected, setSelected] = useState<Employee | undefined>();
  const [isOpen, setIsOpen] = useState(false);

  const {
    nonDigitalProductManagers,
    loading: loadingNonDigitalProductManagers,
    error,
    filteredNonDigitalProductManagers,
  } = useNonDigitalProductManager();
  const { loading: loadingReports } = useReport();
  const { setSearchParams, products } =
    useNonDigitalProductManagerSearchParams();

  if (error) {
    return <ResponseErrorPanel error={error} />;
  }

  const handleRowClick = (_event?: MouseEvent, rowData?: any) => {
    const clickedEmployee = nonDigitalProductManagers?.find(
      employee => employee.fullName === rowData.fullName,
    );
    setSelected(clickedEmployee);
    setIsOpen(true);
  };

  return (
    <>
      <Table
        title={
          <Header
            loading={loadingNonDigitalProductManagers}
            nonDigitalProductManagers={nonDigitalProductManagers}
            productsFilter={products}
            setSearchParams={setSearchParams}
          />
        }
        options={{
          search: true,
          paging: false,
          sorting: true,
        }}
        columns={columns}
        data={filteredNonDigitalProductManagers}
        onRowClick={handleRowClick}
        isLoading={loadingNonDigitalProductManagers || loadingReports}
        parentChildData={(row: any, rows: any) =>
          rows.find((a: any) => a.id === row.peopleLeaderId)
        }
      />
      {selected && (
        <ProductManagerSidePanel
          employee={selected}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      )}
    </>
  );
}
