import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function LayerGroup(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M20 4c.5 0 1.063.125 1.5.375l13.125 5.563C35.438 10.312 36 11.124 36 12c0 .938-.563 1.75-1.375 2.125L21.5 19.688c-.438.25-1 .312-1.5.312-.563 0-1.063-.063-1.563-.313L5.313 14.125C4.5 13.75 4 12.937 4 12c0-.875.5-1.688 1.313-2.063l13.125-5.562c.5-.25 1-.375 1.562-.375Zm-.375 3.125L8.125 12l11.5 4.938c.125.062.25.062.375.062s.25 0 .313-.063L31.813 12l-11.5-4.875A.477.477 0 0 0 20 7c-.125 0-.25.063-.375.125ZM5.312 17.938l2-.813 3.813 1.625-3 1.25 11.5 4.938c.125.062.25.062.375.062s.25 0 .313-.063L31.813 20l-2.938-1.25 3.75-1.625 2 .813C35.438 18.313 36 19.125 36 20c0 .938-.563 1.75-1.375 2.125L21.5 27.688c-.438.25-1 .312-1.5.312-.563 0-1.063-.063-1.563-.313L5.313 22.125C4.5 21.75 4 20.937 4 20c0-.875.5-1.688 1.313-2.063Zm0 8 2-.813 3.813 1.625-3 1.25 11.5 4.938c.125.062.25.062.375.062s.25 0 .313-.063L31.813 28l-2.938-1.25 3.75-1.625 2 .813C35.438 26.313 36 27.125 36 28c0 .938-.563 1.75-1.375 2.125L21.5 35.688c-.438.25-1 .312-1.5.312-.563 0-1.063-.063-1.563-.313L5.313 30.125C4.5 29.75 4 28.937 4 28c0-.875.5-1.688 1.313-2.063Z" />
    </SvgIcon>
  );
}
