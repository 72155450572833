import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function StatusTriangleExclamation(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M19.5 9.313 7.125 29.625c-.125.188-.125.313-.125.5 0 .5.375.875.875.875h24.188c.5 0 .937-.375.937-.875 0-.188-.063-.313-.188-.5L20.438 9.312A.47.47 0 0 0 20 9c-.25 0-.375.125-.5.313ZM16.937 7.75A3.596 3.596 0 0 1 20 6c1.188 0 2.375.688 3 1.75l12.375 20.313c.375.625.625 1.312.625 2.062C36 32.25 34.25 34 32.062 34H7.875C5.75 34 4 32.25 4 30.125c0-.75.188-1.438.563-2.063L16.938 7.75ZM22 27c0 1.125-.938 2-2 2-1.125 0-2-.875-2-2 0-1.063.875-2 2-2 1.063 0 2 .938 2 2Zm-.5-11.5v6c0 .875-.688 1.5-1.5 1.5-.875 0-1.5-.625-1.5-1.5v-6c0-.813.625-1.5 1.5-1.5.813 0 1.5.688 1.5 1.5Z" />
    </SvgIcon>
  );
}
