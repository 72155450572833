import React from 'react';
import MuiChip, { ChipProps as MuiChipProps } from '@mui/material/Chip';
import { useChipStyles } from './Chip.styles';
import CloseIcon from '@mui/icons-material/Close';

export type ChipProps = Omit<MuiChipProps, 'size' | 'color'> & {
  color?:
    | 'blue'
    | 'green'
    | 'red'
    | 'yellow'
    | 'purple'
    | 'neutral'
    | 'success'
    | 'error'
    | 'warning'
    | 'info'
    | 'transparent';
};

export const Chip = ({ color = 'neutral', ...props }: ChipProps) => {
  const classes = useChipStyles();
  return (
    <MuiChip
      {...props}
      size="small"
      className={[classes.root, classes[color]].join(' ')}
      deleteIcon={<CloseIcon />}
    />
  );
};
