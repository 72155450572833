import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Phone(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="m27.441 21.215 7 3a2.456 2.456 0 0 1 1.5 2.812l-1.5 7c-.25 1.125-1.312 2-2.437 2-.438 0-.813-.062-1.188-.062-.625 0-1.25-.063-1.812-.125-14.063-1.5-25-13.375-25-27.875 0-1.125.812-2.188 1.937-2.438l7-1.5a2.456 2.456 0 0 1 2.813 1.5l3 7c.437 1 .187 2.188-.688 2.875l-2.562 2.125a19.42 19.42 0 0 0 6.937 6.938l2.125-2.563c.688-.875 1.875-1.125 2.875-.687Zm4.125 11.75 1.313-6.188-6.25-2.687-1.813 2.25a3.059 3.059 0 0 1-3.875.687 22.306 22.306 0 0 1-8-8 3.059 3.059 0 0 1 .688-3.875l2.25-1.812-2.688-6.25-6.187 1.312c.187 13.5 11.062 24.375 24.562 24.563Z" />
    </SvgIcon>
  );
}
