import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function SlidersUp(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M31.8958 26.7832C32.6145 27.1957 33.5395 26.952 33.8833 26.202C34.8145 24.1707 35.3333 21.9145 35.3333 19.5332C35.3333 11.202 28.9645 4.35823 20.8333 3.60198C20.0083 3.52698 19.3333 4.20198 19.3333 5.03323C19.3333 5.86448 20.0083 6.52698 20.8333 6.62073C27.302 7.35823 32.3333 12.8582 32.3333 19.5332C32.3333 21.3645 31.952 23.1145 31.2708 24.6957C30.9395 25.4582 31.177 26.3707 31.8958 26.7895V26.7832Z" />
    </SvgIcon>
  );
}
