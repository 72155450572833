import { makeStyles } from '@material-ui/core';
import { cssVariables } from '../../../styles/variable.styles';

export const useStyles = makeStyles<any>(theme => ({
  countStepperTxtField: {
    marginRight: 10,
    height: 38,
    '& > .MuiInputBase-root': {
      height: 38,
      '& > input': {
        textAlign: 'center',
        color: `${theme.semantic.text.primary}`,
      },
    },

    '& > .Mui-error': {
      border: `${cssVariables.borderDefault} ${theme.primitive.color.error[500]}`,
    },
  },

  countStepperBtn: {
    width: 20,
    marginRight: 10,
    height: 38,
  },

  countStepperGrp: {
    width: 250,
    paddingTop: 10,
    flexWrap: 'nowrap',
    display: 'flex',
    flexDirection: 'row',
  },
}));
