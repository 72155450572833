import {
  Banner,
  BannerCreateParams,
} from '@lego/plugin-baseplate-admin-common';
import React, { useState } from 'react';
import { useBanner } from '../../hooks';
import { BannerForm } from './BannerForm';

interface Props {
  banner: Banner;
  setIsOpen: (isOpen: boolean) => void;
}

export function EditBanner({ banner, setIsOpen }: Props) {
  const { handleUpdatingBanner } = useBanner();

  const [formData, setFormData] = useState<BannerCreateParams>({
    ...banner,
  });

  const onSubmit = async () => {
    await handleUpdatingBanner(banner.id, formData);
    setIsOpen(false);
  };

  return (
    <>
      <BannerForm
        setFormData={setFormData}
        formData={formData}
        onSubmit={onSubmit}
        type="Edit"
      />
    </>
  );
}
