import React from 'react';
import { type ReactElement } from 'react';
import { TableMenuItemType, TableRowType } from './types';
import { ListItemIcon, Menu, MenuItem, Typography } from '@material-ui/core';
import { Icon } from '../icon';

export interface TableRowMenuProps<T> {
  isRowMenuOpen: boolean;
  anchorEl: HTMLElement | null;
  handleClose: () => void;
  openedRow: TableRowType<T> | undefined;
  menuItems: TableMenuItemType<T>[];
}

export function TableRowMenu<T>({
  isRowMenuOpen,
  openedRow,
  anchorEl,
  handleClose,
  menuItems,
}: TableRowMenuProps<T>): ReactElement {
  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={isRowMenuOpen}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      {menuItems.map(({ label, icon, onClick, disabled }) => (
        <MenuItem
          onClick={() => {
            onClick(openedRow as TableRowType<T>);
            handleClose();
          }}
          disabled={disabled}
        >
          <ListItemIcon>
            <Icon icon={icon} />
          </ListItemIcon>
          <Typography color="primary">{label}</Typography>
        </MenuItem>
      ))}
    </Menu>
  );
}
