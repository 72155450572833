import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function PowerOff(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M22 5.813v15c0 .875-.688 1.5-1.5 1.5-.875 0-1.5-.625-1.5-1.5v-15c0-.813.625-1.5 1.5-1.5.813 0 1.5.687 1.5 1.5Zm-9.125 5.25A11.84 11.84 0 0 0 8 20.687c0 6.625 5.313 12.063 11.938 12.063 6.625 0 12-5.375 12-12 0-4-1.875-7.5-4.875-9.688-.625-.5-.813-1.437-.313-2.124.5-.626 1.438-.813 2.125-.313C32.563 11.375 35 15.75 35 20.688c0 8.312-6.75 15-15 15-8.313 0-15-6.688-15-15 0-4.938 2.375-9.313 6.063-12.063.687-.5 1.624-.375 2.124.313.5.687.313 1.624-.312 2.124Z" />
    </SvgIcon>
  );
}
