import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function BrickTwoByTwoSideScan(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M12 2.51v1c0 .28-.22.5-.5.5H2v9.49c0 .28-.22.5-.5.5h-1c-.28 0-.5-.22-.5-.5v-11c0-.28.22-.5.5-.5h1c.01 0 .03.01.04.01h9.96c.28 0 .5.22.5.5Zm28-.01c0-.28-.22-.5-.5-.5h-11c-.28 0-.5.22-.5.5v1c0 .28.22.5.5.5h9.49v9.5c0 .28.22.5.5.5h1c.28 0 .5-.22.5-.5V3.54c0-.01.01-.03.01-.04v-1ZM39.5 26h-1c-.28 0-.5.22-.5.5v9.49h-9.5c-.28 0-.5.22-.5.5v1c0 .28.22.5.5.5h9.96c.01 0 .03.01.04.01h1c.28 0 .5-.22.5-.5v-11c0-.28-.22-.5-.5-.5ZM0 37.5c0 .28.22.5.5.5h11c.28 0 .5-.22.5-.5v-1c0-.28-.22-.5-.5-.5H2.01v-9.5c0-.28-.22-.5-.5-.5h-1c-.28 0-.5.22-.5.5L0 37.5Zm32.5-23.79v15.85h-25V13.71h1.79v-1.53c0-.96.78-1.75 1.75-1.75h6.42c.96 0 1.75.78 1.75 1.75v1.53h1.52V12.2c0-.97.79-1.77 1.77-1.77h6.38c.97 0 1.77.79 1.77 1.77v1.51h1.85Zm-9.77 0v1h5.91v-2.28h-5.91v1.28Zm-11.44 0v1h5.91v-2.28h-5.91v1.28Zm19.21 2h-21v11.85h21V15.71Z" />
    </SvgIcon>
  );
}
