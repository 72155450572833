import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import { featureFlags } from '../featureFlags';

export const cloudAdminPlugin = createPlugin({
  id: 'cloudAdmin',
  featureFlags: Object.values(featureFlags).map(name => ({ name })),
  routes: {
    root: rootRouteRef,
  },
});

export const CloudAdminPage = cloudAdminPlugin.provide(
  createRoutableExtension({
    name: 'CloudAdminPage',
    component: () => import('./components/AdminPage').then(m => m.AdminPage),
    mountPoint: rootRouteRef,
  }),
);
