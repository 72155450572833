/*
 * Copyright 2022 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';
import { AbstractClient } from '../AbstractClient';
import {
  DataProductExternalLocation,
  DataProductStorageCredential,
  StorageCredentialsApi,
} from '@lego/plugin-baseplate-nexus-databricks-storage-credentials-and-external-locations-common';

export class StorageCredentialsMockClient
  extends AbstractClient
  implements StorageCredentialsApi
{
  public constructor(options: {
    discoveryApi: DiscoveryApi;
    fetchApi: FetchApi;
  }) {
    super(
      'nexus-databricks-storage-credentials-and-external-locations',
      options.discoveryApi,
      options.fetchApi,
    );
  }

  public list(dataProduct?: string): Promise<DataProductStorageCredential[]> {
    return Promise.resolve([]);
  }

  public create(): Promise<any> {
    return Promise.resolve({});
  }

  public update(): Promise<any> {
    return Promise.resolve({});
  }

  public getPermissions(): Promise<any> {
    return Promise.resolve({});
  }

  public updatePermissions(): Promise<any> {
    return Promise.resolve({});
  }

  public delete(): Promise<any> {
    return Promise.resolve({});
  }

  public getUsages(name: string): Promise<DataProductExternalLocation[]> {
    return Promise.resolve([]);
  }
}
