import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function CreditCardFront(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M6 9c-.563 0-1 .5-1 1v20c0 .563.438 1 1 1h28c.5 0 1-.438 1-1V10c0-.5-.5-1-1-1H6Zm-4 1c0-2.188 1.75-4 4-4h28c2.188 0 4 1.813 4 4v20c0 2.25-1.813 4-4 4H6c-2.25 0-4-1.75-4-4V10Zm6 16.5c0-.813.625-1.5 1.5-1.5h3c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5h-3c-.875 0-1.5-.625-1.5-1.5Zm8 0c0-.813.625-1.5 1.5-1.5h7c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5h-7c-.875 0-1.5-.625-1.5-1.5Zm-8-5c0-.813.625-1.5 1.5-1.5h21c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5h-21c-.875 0-1.5-.625-1.5-1.5ZM25.5 12h5c.813 0 1.5.688 1.5 1.5v3c0 .875-.688 1.5-1.5 1.5h-5c-.875 0-1.5-.625-1.5-1.5v-3c0-.813.625-1.5 1.5-1.5Z" />
    </SvgIcon>
  );
}
