/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { EntitySwitch, isKind } from '@backstage/plugin-catalog';
import React from 'react';
import { defaultEntityPage } from './ContentSections';
import ApplicationsPage from './EntityPages/ApplicationsPage';
import ComponentPage from './EntityPages/ComponentPage';
import DomainPage from './EntityPages/DomainPage';
import GroupPage from './EntityPages/GroupPage';
import ProductPage from './EntityPages/ProductPage';
import ToolsPage from './EntityPages/ToolsPage';
import UserPage from './EntityPages/UserPage';
import IdentityPage from './EntityPages/IdentityPage';
import CommunityPage from './EntityPages/CommunityPage';
import {
  isPluginApplicableToEntity as isPagerDutyAvailable,
  EntityPagerDutyCard,
} from '@pagerduty/backstage-plugin';
import ApiPage from './EntityPages/ApiPage';

export const entityPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isKind('api')} children={<ApiPage />} />
    <EntitySwitch.Case
      if={isKind('application')}
      children={<ApplicationsPage />}
    />
    <EntitySwitch.Case if={isKind('identity')} children={IdentityPage} />
    <EntitySwitch.Case if={isKind('component')} children={ComponentPage} />
    <EntitySwitch.Case if={isKind('domain')} children={DomainPage} />
    <EntitySwitch.Case if={isKind('group')} children={<GroupPage />} />
    <EntitySwitch.Case if={isKind('product')} children={<ProductPage />} />
    <EntitySwitch.Case if={isKind('user')} children={UserPage} />
    <EntitySwitch.Case if={isKind('tool')} children={ToolsPage} />
    <EntitySwitch.Case if={isKind('community')} children={<CommunityPage />} />
    <EntitySwitch.Case
      if={isPagerDutyAvailable}
      children={<EntityPagerDutyCard />}
    />
    <EntitySwitch.Case>{defaultEntityPage}</EntitySwitch.Case>
  </EntitySwitch>
);
