import { BackstageOverrides } from '@backstage/core-components';
import { DesignTokens } from '../types';
import { alert } from './alert';
import { banner } from './banner';
import { base } from './base';
import { button } from './button';
import { card } from './card';
import { input } from './input';
import { list } from './list';
import { navigation } from './navigation';
import { table } from './table';
import { typography } from './typography';
import { userListPicker } from './userListPicker';
import { search } from './search';
import { entity } from './entity';

export const customComponentOverrides = (
  tokens: DesignTokens,
): BackstageOverrides => ({
  ...alert(tokens),
  ...banner(tokens),
  ...base(tokens),
  ...button(tokens),
  ...card(tokens),
  ...input(tokens),
  ...list(tokens),
  ...navigation(tokens),
  ...table(tokens),
  ...typography(tokens),
  ...userListPicker(tokens),
  ...search(tokens),
  ...entity(tokens),
});
