import { Config } from '@backstage/config';
import { AZURE_PROVIDER_CONFIG_KEY } from '../constants';
import { azureEnvConfig, oboFlowEnvConfig } from '../types';

/**
 * Gets Azure Configuration from the env
 * @param config backstage Configuration object
 *
 */
export const getAzureConfig = (config: Config): azureEnvConfig => {
  const azureConfig = config.getOptionalConfig(AZURE_PROVIDER_CONFIG_KEY);
  if (!azureConfig) {
    throw new Error('Azure configuration is missing in the env.');
  }

  return {
    clientId: azureConfig.getString('clientId'),
    tenantId: azureConfig.getString('tenantId'),
  };
};

export const getOnBehalfOfFlowConfig = (
  config: Config,
  key: string,
): oboFlowEnvConfig => {
  const oboConfig = config.getOptionalConfig(key);
  if (!oboConfig) {
    throw new Error(`Obo flow is missing for the given key: ${key}`);
  }

  return {
    clientId: oboConfig.getString('clientId'),
    tenantId: oboConfig.getString('tenantId'),
    oboFlowScope: oboConfig.getString('oboFlowScope'),
    secret: oboConfig.getString('secret'),
    middleTierApiScope: oboConfig.getString('middleTierApiScope'),
  };
};
