import {
  Employee,
  Product,
  getExternalToggle,
} from '@lego/plugin-baseplate-people-to-product-common';
import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { peopleToProductApiRef } from '../api/PeopleToProductApi';
import { useApi } from '@backstage/core-plugin-api';
import { Role } from '@lego/plugin-baseplate-eagraph-common';
import { useNonDigitalProductManagerSearchParams } from './useNonDigitalProductManagerSearchParams';
import {
  filterEmployeesWithProducts,
  filterEmployeesWithoutProducts,
  filterInternalEmployees,
} from './filters/filters';

type NonDigitalProductManagerContextType = {
  nonDigitalProductManagers: Employee[];
  filteredNonDigitalProductManagers: Employee[];
  loading: boolean;
  error?: Error;
  addProductToNonDigitalProductManager(
    employee: Employee,
    product: Product,
    roles: Role[],
  ): Promise<void>;
  removeProductFromNonDigitalProductManager(
    employee: Employee,
    product: Product,
  ): Promise<void>;
};

type Props = {
  children: ReactNode;
};

const NonDigitalProductManagerContext =
  createContext<NonDigitalProductManagerContextType>({
    nonDigitalProductManagers: [],
    filteredNonDigitalProductManagers: [],
    loading: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    addProductToNonDigitalProductManager: async () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    removeProductFromNonDigitalProductManager: async () => {},
  });

export const useNonDigitalProductManager = () =>
  useContext(NonDigitalProductManagerContext);

export const NonDigitalProductManagerProvider = ({ children }: Props) => {
  const peopleToProductApi = useApi(peopleToProductApiRef);

  const [nonDigitalProductManagers, setNonDigitalProductManagers] = useState<
    Employee[]
  >([]);
  const [
    filteredNonDigitalProductManagers,
    setFilteredNonDigitalProductManagers,
  ] = useState<Employee[]>([]);
  const { products: productFilter, searchParams } =
    useNonDigitalProductManagerSearchParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error>();

  useEffect(() => {
    async function fetch() {
      setLoading(true);
      try {
        const fetchedNonDigitalProductManagers =
          await peopleToProductApi.getNonDigitalProductManagers();
        if (getExternalToggle()) {
          setNonDigitalProductManagers(fetchedNonDigitalProductManagers);
        } else {
          const filteredNonDigitalProductManagers =
            fetchedNonDigitalProductManagers.filter(filterInternalEmployees);
          setNonDigitalProductManagers(filteredNonDigitalProductManagers);
        }
      } catch (fetchError) {
        setError(fetchError as Error);
      } finally {
        setLoading(false);
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetch();
  }, [peopleToProductApi]);

  useEffect(() => {
    function filterData() {
      if (productFilter === false) {
        setFilteredNonDigitalProductManagers(
          nonDigitalProductManagers.filter(filterEmployeesWithoutProducts),
        );
      } else if (productFilter === true) {
        setFilteredNonDigitalProductManagers(
          nonDigitalProductManagers.filter(filterEmployeesWithProducts),
        );
      } else {
        setFilteredNonDigitalProductManagers(nonDigitalProductManagers);
      }
    }

    filterData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nonDigitalProductManagers, searchParams]);

  const addProductToNonDigitalProductManager = async (
    employee: Employee,
    product: Product,
    roles: Role[],
  ) => {
    await peopleToProductApi.addProductToEmployee(
      employee.id,
      product.id,
      roles,
      false,
    );

    const updatedNonDigitalProductManagers = nonDigitalProductManagers.map(
      e => {
        if (e.id === employee.id) {
          return {
            ...e,
            products: [
              ...e.products,
              {
                node: product,
                roles: roles,
                tco: 0,
              },
            ],
          };
        }
        return e;
      },
    );

    setNonDigitalProductManagers(updatedNonDigitalProductManagers);
  };

  const removeProductFromNonDigitalProductManager = async (
    employee: Employee,
    product: Product,
  ) => {
    await peopleToProductApi.deleteProductFromEmployee(employee.id, product.id);

    const updatedNonDigitalProductManagers = nonDigitalProductManagers.map(
      pm => {
        if (pm.id === employee.id) {
          return {
            ...pm,
            products: pm.products.filter(p => p.node.id !== product.id),
          };
        }
        return pm;
      },
    );

    setNonDigitalProductManagers(updatedNonDigitalProductManagers);
  };

  return (
    <NonDigitalProductManagerContext.Provider
      value={{
        nonDigitalProductManagers: nonDigitalProductManagers || [],
        filteredNonDigitalProductManagers:
          filteredNonDigitalProductManagers || [],
        loading: loading,
        error: error,
        addProductToNonDigitalProductManager,
        removeProductFromNonDigitalProductManager,
      }}
    >
      {children}
    </NonDigitalProductManagerContext.Provider>
  );
};
