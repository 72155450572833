import { OpenAPIV3_1 } from 'openapi-types';
import { UiParameterGroupType } from '../../../types';
import { resolveRef } from './utils/refResolver';
import { resolveSchema } from './schema';
import { getFirstMediaObject } from './media';

export type ExampleResponseType = {
  [statusCode: string]: Record<string, OpenAPIV3_1.ExampleObject>;
};

/**
 * Resolve the [responses object](https://swagger.io/specification/#responses-object)
 */
export const resolveResponses = (
  responses: OpenAPIV3_1.ResponsesObject | undefined,
  components?: OpenAPIV3_1.ComponentsObject,
): {
  responseGroups?: UiParameterGroupType;
  responseExamples?: ExampleResponseType;
} => {
  if (!responses)
    return { responseGroups: undefined, responseExamples: undefined };
  const parsedResponses: UiParameterGroupType = {};
  const responseExamples: ExampleResponseType = {};

  Object.entries(responses).forEach(([statusCode, response]) => {
    const resolvedResponse = resolveRef<OpenAPIV3_1.ResponseObject>(
      'responses',
      response,
      components,
    );

    const { schema, examples } = resolvedResponse.content
      ? getFirstMediaObject(resolvedResponse.content, components)
      : { schema: undefined, examples: undefined };

    if (examples) {
      responseExamples[statusCode] = examples;
    }

    // Resolve schema returns an array of UiParameterType. If there's no schema, return empty array
    const parameters = (schema && resolveSchema(schema, components)) || [];

    Object.assign(parsedResponses, {
      [`${statusCode}: ${resolvedResponse.description}`]: parameters,
    });
  });

  return { responseGroups: parsedResponses, responseExamples };
};
