import { TablePagination, Typography } from '@material-ui/core';
import { default as MuiTable, TableProps } from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import React from 'react';
import { useTableStyles } from './Table.styles';
import { TableActions } from './TableActions';
import { TableContent } from './TableContent';
import { TableHeaderRow } from './TableHeaderRow';
import { TableRowMenu } from './TableRowMenu';
import { TableSummaryRow } from './TableSummaryRow';
import { usePagination } from './hooks/usePagination';
import { useTableFilter } from './hooks/useTableFilter';
import { useTableRowMenu } from './hooks/useTableRowMenu';
import { useTableSearch } from './hooks/useTableSearch';
import { useTableSelection } from './hooks/useTableSelection';
import { useTableSorting } from './hooks/useTableSorting';
import { TableHeaderType, TableMenuItemType, TableRowType } from './types';
import { validateTableData } from './validateTableData';
import { Spinner } from '../spinner';

type Props<T = unknown> = {
  headers: TableHeaderType[];
  data: TableRowType<T>[];
  className?: string;
  loading?: boolean;
  title: string;
  actions?: React.ReactNode;
  selectedActions?: React.ReactNode;
  enablePagination?: boolean;
  areCheckboxesDisabled?: boolean;
  emptyState?: React.ReactNode;
  emptyStateContent?: React.ReactNode;
  rowMenuActions?:
    | TableMenuItemType<T>[]
    | ((t: TableRowType<T> | undefined) => TableMenuItemType<T>[]);
} & (
  | {
      selectable: true;
      multiple: true;
      setSelected: (selected: TableRowType<T>[]) => void;
      selected?: TableRowType<T>[];
    }
  | {
      selectable: true;
      multiple?: false;
      setSelected: (selected: TableRowType<T>) => void;
      selected?: TableRowType<T>;
    }
  | {
      selectable?: false;
      setSelected?: never;
      selected?: never;
      multiple?: never;
    }
) &
  TableProps;

/**
 * A Baseplate table component that extends the MUI Table components.
 * @beta This component is in beta and is subject to change.
 * @docs https://baseplate.legogroup.io/docs/default/component/baseplate/core-components/components/table/
 */
export function Table<T>({
  title,
  headers,
  data,
  selectable,
  className,
  multiple,
  actions,
  selectedActions,
  stickyHeader,
  enablePagination,
  setSelected: setSelectedRow,
  areCheckboxesDisabled = false,
  emptyState = 'No data available.',
  emptyStateContent,
  rowMenuActions,
  loading,
  ...props
}: Props<T>) {
  // Throws errors if some prop data is invalid
  validateTableData<T>(data, headers);

  const classes = useTableStyles();

  const { openedRow, openRowMenu, isRowMenuOpen, anchorEl, handleClose } =
    useTableRowMenu<T>();

  // Handles table sorting logic
  const { sortedData, order, orderBy, handleSortClick } =
    useTableSorting<T>(data);

  // Handles table filtering logic
  const { filteredData, updateFilter, filters } = useTableFilter<T>(
    sortedData,
    headers,
  );

  // Handles table selection logic
  const { selectedIds, handleCheckboxClick, onSelectAllClick } =
    useTableSelection<T>({
      data,
      processedData: filteredData,
      multiple,
      selectable,
      setSelectedRow: setSelectedRow,
    });

  // Handles table search logic
  const { query, handleSearchChange, searchFilteredData } =
    useTableSearch<T>(filteredData);

  const finalMenu =
    typeof rowMenuActions === 'function'
      ? rowMenuActions(openedRow)
      : rowMenuActions;
  const hasMenu = Boolean(finalMenu);

  const {
    paginatedData,
    page,
    emptyRows,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  } = usePagination<T>(enablePagination, searchFilteredData);

  return (
    <div
      className={[
        classes.container,
        stickyHeader ? classes.stickyHeader : '',
        className,
      ].join(' ')}
    >
      <div className={classes.tableHeader}>
        <Typography variant="subtitle1">{title}</Typography>
        {data.length > 0 && (
          <TableActions
            selectedIds={selectedIds}
            updateFilter={updateFilter}
            filters={filters}
            query={query}
            handleSearchChange={handleSearchChange}
            actions={actions}
            selectedActions={selectedActions}
          />
        )}
      </div>
      <TableContainer>
        {loading ? (
          <Spinner />
        ) : (
          <>
            {data.length > 0 ? (
              <MuiTable stickyHeader={stickyHeader} {...props}>
                <TableHeaderRow
                  selectable={selectable}
                  multiple={multiple}
                  selectedIds={selectedIds}
                  data={searchFilteredData}
                  headers={headers}
                  order={order}
                  orderBy={orderBy}
                  handleSortClick={handleSortClick}
                  onSelectAllClick={onSelectAllClick}
                  areCheckboxesDisabled={areCheckboxesDisabled}
                  hasMenu={hasMenu}
                />
                <TableContent
                  data={paginatedData}
                  emptyRows={emptyRows}
                  selectedIds={selectedIds}
                  headers={headers}
                  selectable={selectable}
                  handleCheckboxClick={handleCheckboxClick}
                  stickyHeader={stickyHeader}
                  areCheckboxesDisabled={areCheckboxesDisabled}
                  hasMenu={hasMenu}
                  openRowMenu={openRowMenu}
                />
                <TableSummaryRow
                  data={paginatedData}
                  headers={headers}
                  selectedIds={selectedIds}
                />
                {enablePagination && (
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    page={page}
                    count={searchFilteredData.length}
                    rowsPerPage={rowsPerPage}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                )}
                {hasMenu && (
                  <TableRowMenu<T>
                    openedRow={openedRow}
                    isRowMenuOpen={isRowMenuOpen}
                    anchorEl={anchorEl}
                    handleClose={handleClose}
                    menuItems={finalMenu ?? []}
                  />
                )}
              </MuiTable>
            ) : (
              <div className={classes.emptyStateContainer}>
                {emptyStateContent ? emptyStateContent : emptyState}
              </div>
            )}
          </>
        )}
      </TableContainer>
    </div>
  );
}
