import { baseplateStyles } from '@lego/plugin-baseplate-core-components';

export const useCodeBlockStyles = baseplateStyles(theme => {
  return {
    codeblock: {
      // Background colors
      '--md-code-hl-color': theme.semantic.background.info,
      '--md-code-hl-color--light': theme.semantic.background.info,

      '--md-code-hl-number-color': theme.semantic.text.code.number,
      '--md-code-hl-special-color': 'pink',
      '--md-code-hl-function-color': theme.semantic.text.code.function,
      '--md-code-hl-constant-color': theme.semantic.text.code.function,
      '--md-code-hl-keyword-color': theme.semantic.text.code.keyword,
      '--md-code-hl-string-color': theme.semantic.text.code.string,
      '--md-code-hl-name-color': 'pink',
      '--md-code-hl-operator-color': theme.semantic.text.primary,
      '--md-code-hl-punctuation-color': theme.semantic.text.code.punctuation,
      '--md-code-hl-comment-color': theme.semantic.text.tertiary,
      '--md-code-hl-generic-color': theme.semantic.text.code.generic,
      '--md-code-hl-variable-color': theme.semantic.text.code.string,
      '--md-typeset-a-color': theme.semantic.text.primary,

      backgroundColor: theme.semantic.background.code,
      borderRadius: theme.primitive.borderRadius.default,
      overflow: 'hidden',

      // Removing line to the right of line numbers
      '& .linenodiv': {
        boxShadow: 'none',
      },

      // File name header
      '& th.filename span.filename': {
        backgroundColor: theme.semantic.background.neutral[3],
        border: 0,
      },

      '& .md-clipboard': {
        position: 'absolute',
        top: '0.5rem',
        right: '1rem',
      },

      '& code': {
        '& .nx, & .err': {
          color: theme.semantic.text.code.generic,
        },
      },
    },
  };
});
