import React from 'react';
import { SupportModal } from './SupportModal';
import {
  Icon,
  baseplateStyles,
  useSupportContext,
} from '@lego/plugin-baseplate-core-components';
import useAsync from 'react-use/lib/useAsync';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { useApi } from '@backstage/core-plugin-api';
import { Typography } from '@material-ui/core';

const useStyles = baseplateStyles(theme => ({
  supportButtonContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    position: 'fixed',
    bottom: '20%',
    right: 0,
    zIndex: 1000,
    transform: 'translateX(100%) rotate(-90deg)',
    transformOrigin: 'bottom left',
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    color: 'white',
    backgroundColor: theme.component.button.primary.background.default,
    cursor: 'pointer',
    border: 0,
    fontSize: '1rem',
    transform: 'translateY(0.5rem)',
    padding: '0.5rem 1rem 1rem',
    borderRadius: `${theme.primitive.borderRadius.default}px ${theme.primitive.borderRadius.default}px 0 0`,
    boxShadow: theme.shadows[4],
    transition: 'background-color 200ms ease-in-out, transform 200ms ease-out',
    '&:hover': {
      backgroundColor: theme.component.button.primary.background.hover,
      transform: 'translateY(0)',
    },
    '&:active, &:focus-visible': {
      transform: 'translateY(0.25rem)',
    },
  },
  supportOwner: {
    pointerEvents: 'none',
    background: theme.semantic.background.neutral[3],
    borderRadius: `0 ${theme.primitive.borderRadius.default}px 0 0`,
    transform: 'translateY(100%)',
    height: '80%',
    padding: '0.5rem 1rem',
  },
  hasSupportOwner: {
    transform: 'translateY(0)',
    transition: 'transform 200ms ease-out',
  },
}));

export const SupportButton = () => {
  const classes = useStyles();
  const [supportModalOpened, setSupportModalOpened] = React.useState(false);
  const { pluginEntity } = useSupportContext();

  const api = useApi(catalogApiRef);

  const { value: fetchedEntity } = useAsync(async () => {
    const entityRef = pluginEntity?.relations?.[0]?.targetRef;
    if (!entityRef) return undefined;
    return await api.getEntityByRef(entityRef);
  }, [pluginEntity]);

  return (
    <React.Fragment>
      <SupportModal
        supportModalOpened={supportModalOpened}
        setSupportModalOpened={setSupportModalOpened}
      />
      <div className={classes.supportButtonContainer}>
        <button
          className={classes.button}
          tabIndex={0}
          color="primary"
          aria-label="add"
          onClick={() => setSupportModalOpened(prev => !prev)}
        >
          <Icon icon="circle-question" />
          <span>Support & Feedback</span>
        </button>
        <div
          className={[
            classes.supportOwner,
            pluginEntity ? classes.hasSupportOwner : '',
          ].join(' ')}
        >
          <Typography variant="body1">
            {/* Removing the numbers from product name */}
            {fetchedEntity?.metadata.title?.split(' ').slice(1).join(' ')}
          </Typography>
        </div>
      </div>
    </React.Fragment>
  );
};
