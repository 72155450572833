import React from 'react';
import { Link } from '@material-ui/core';
import { ANNOTATION_VIEW_URL } from '@backstage/catalog-model';
import { BaseplateWarningPanel } from './CustomComponents/BaseplateWarningPanel';
import { useEntity } from '@backstage/plugin-catalog-react';
import { isAPI, isApplication } from '@lego/plugin-baseplate-common';

export const EntityMissingOwnerWarning = () => {
  const { entity } = useEntity();

  return (
    <BaseplateWarningPanel
      severity="info"
      title="Owner information is missing in LeanIX"
    >
      <>
        <p>This entity is missing owner information.</p>

        {isAPI(entity) && (
          <p>
            You can provide this information by adding <b>x-application-id</b>{' '}
            property to your API specification. More information{' '}
            <Link
              href="/docs/default/component/amma/produce/openapi/open-api-spec/#x-application-id"
              target="_blank"
            >
              here
            </Link>
          </p>
        )}
        {isApplication(entity) && (
          <>
            <p>
              {entity.kind}: {entity.metadata.title ?? entity.metadata.name} has
              incorrect or missing data in LeanIX.
            </p>
            <p>
              Please contact the owner of this application. No UserType, or
              UserType of value 'User' is not qualifiying for owning this
              application.
            </p>
            <Link
              href={entity.metadata.annotations?.[ANNOTATION_VIEW_URL]}
              target="_blank"
            >
              {entity.metadata.title ?? entity.metadata.name} in LeanIX.
            </Link>
          </>
        )}
      </>
    </BaseplateWarningPanel>
  );
};
