import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function MinifigureFaceless2(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M11.75 29.006c-2.41 0-4.36 1.55-5.28 3.28-.91 1.71-1.47 4.35-1.47 5.72h3c.12-2.03 1.202-6.05 3.75-6.05h16.5c2.548 0 3.63 4.02 3.75 6.05h3c0-1.37-.56-4.01-1.47-5.72-.92-1.74-2.78-3.28-5.28-3.28h-16.5Zm19.952-19.94-1-.34.92-1.83a1.567 1.567 0 0 0 0-1.7 1.453 1.453 0 0 0-1.63-.62l-.11.03c.15-.18.27-.38.34-.61.08-.23.11-.47.09-.76-.07-.31-.22-.58-.44-.8-.22-.21-.5-.35-.78-.39-4.31-.47-15.41 2.39-16.18 2.72-.75.34-1.37.9-1.8 1.64l-.63 1.13c-.28-.07-.56-.09-.85-.06-.35.04-.7.17-1.02.36a1.7 1.7 0 0 0-.54.59c-.13.24-.21.5-.23.77-.44 5.1.11 8.6 1.62 10.41.21.26.46.5.79.73.08.04.16.07.24.11v1.91c0 2.24 1.92 4.32 4.23 4.37h10.56c2.3-.09 4.21-2.16 4.21-4.4v-1.16c1.86-1.66 2.84-5.45 3.6-9.35l.05-.26h-.01c.11-1.06-.2-2.21-1.43-2.5v.01Zm-6.71 14.6h-10c-.81 0-1.5-.73-1.5-1.54v-6.71c3.45-.06 6.8-.82 9.97-2.27.9.7 1.94 1.18 3.03 1.41v7.58c0 .83-.69 1.53-1.5 1.53Zm4.3-7.5v-2.4c0-.45-.15-.88-.44-1.21-.29-.33-.68-.54-1.11-.59a4.662 4.662 0 0 1-2.73-1.12c-.22-.29-.54-.49-.88-.58a1.59 1.59 0 0 0-1.03.1c-2.38 1.05-6.93 2.74-10.65 2.21-.09-.01-.86-.12-1.33.28-.2.17-.32.41-.34.72v2.76c-.34-1.15-.56-2.99-.38-5.95l.1.04c.33.07.67.04.99-.1.31-.14.57-.38.75-.68l1.07-1.9c.12-.18.29-.33.48-.43.05-.01.15-.04.33-.1 6.27-1.92 9.98-2.47 12.09-2.56l-.91 1.07c-.18.24-.29.53-.32.84-.03.31.03.61.16.89.14.27.35.49.62.64.26.15.56.21.88.17l1.41-.34-.25.5c-.14.22-.24.48-.28.74-.04.26-.02.54.06.8.08.25.23.48.41.65.19.18.41.31.64.37l1.73.58a34.44 34.44 0 0 1-1.1 4.59l.03.01Z" />
    </SvgIcon>
  );
}
