import React from 'react';
import { Chip, ChipProps } from '@lego/plugin-baseplate-core-components';
import { type ReactElement } from 'react';
import { OpenAPIV3_1 } from 'openapi-types';

export interface OpenApiChipProps {
  method: OpenAPIV3_1.HttpMethods;
}

export function OpenApiChip({ method }: OpenApiChipProps): ReactElement {
  return (
    <Chip
      label={method.toUpperCase()}
      color={
        ({
          get: 'blue',
          post: 'green',
          put: 'yellow',
          patch: 'yellow',
          delete: 'error',
          trace: 'yellow',
          options: 'neutral',
          head: 'neutral',
          undefined: 'neutral',
        }[method] || 'undefined') as ChipProps['color']
      }
    />
  );
}
