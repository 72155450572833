import { Parser } from '@asyncapi/parser';
import { OpenAPISchemaParser } from '@asyncapi/openapi-schema-parser';
import { UiAsyncApiDocumentType } from '../../types';
import { parseChannels } from './v2.6.0/channel';
import { AsyncAPIObject } from '@asyncapi/parser/esm/spec-types/v2';
import { getAsyncApiSideNavItems } from './getAsyncApiSideNavItems';
import { parseServers } from './v2.6.0/servers';

export const getAsyncApiDefinition = async (
  specification: string,
): Promise<UiAsyncApiDocumentType> => {
  const parser = new Parser();
  parser.registerSchemaParser(OpenAPISchemaParser());

  const parsedSpec = await parser.parse(specification);
  if (!parsedSpec?.document) {
    return {
      channels: [],
      servers: [],
      navItems: [],
    };
  }

  const asyncObject = parsedSpec.document.json();

  let document: UiAsyncApiDocumentType;

  if (asyncObject.asyncapi.startsWith('2')) {
    const v2AsyncObject = asyncObject as AsyncAPIObject;

    document = {
      channels: parseChannels(v2AsyncObject.channels),
      servers: parseServers(v2AsyncObject.servers),
      navItems: [],
    };
  } else {
    document = {
      channels: [
        { name: 'unsupported version', messages: [], topicAddress: '' },
      ],
      servers: [],
      navItems: [],
    };
  }

  document.navItems = getAsyncApiSideNavItems(document.channels);

  return document;
};
