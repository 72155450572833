import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ControlPlus(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M21.5 6.5v12h12c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5h-12v12c0 .875-.688 1.5-1.5 1.5-.875 0-1.5-.625-1.5-1.5v-12h-12c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5h12v-12c0-.813.625-1.5 1.5-1.5.813 0 1.5.688 1.5 1.5Z" />
    </SvgIcon>
  );
}
