import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ArrowUpRight(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M28.516 9.984c.812 0 1.5.688 1.5 1.5v15c0 .875-.688 1.5-1.5 1.5-.875 0-1.5-.625-1.5-1.5V15.11L12.578 29.547c-.625.625-1.562.625-2.125 0a1.369 1.369 0 0 1 0-2.063l14.438-14.437H13.516a1.48 1.48 0 0 1-1.5-1.5c0-.875.625-1.5 1.5-1.5h15v-.063Z" />
    </SvgIcon>
  );
}
