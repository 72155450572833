import { makeStyles } from '@material-ui/core';

export const useFormLabelStyles = makeStyles<any>(theme => ({
  tooltipInfoIcon: {
    color: theme.semantic.text.info,
    marginLeft: '10px !important',
    fontSize: '16px !important',
  },
  label: {
    '& > div': {
      margin: 'auto 0 ',
      '& > div': {
        '& > svg': {
          margin: 'auto 0',
        },
        '& > label': {
          color: `${theme.semantic.text.primary} !important`,
          fontWeight: 500,
          lineHeight: '150%',
        },
      },
    },
  },

  optional: {
    margin: 'auto',
    marginLeft: 5,
  },
}));
