import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Tag(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M18.664 6.32c1.063 0 2.063.438 2.813 1.188l11 11a4.036 4.036 0 0 1 0 5.687l-8.313 8.313a4.036 4.036 0 0 1-5.687 0l-11-11c-.75-.75-1.125-1.75-1.125-2.813V9.32a3 3 0 0 1 3-3h9.312ZM9.352 18.695c0 .25.062.5.25.688l11 11c.375.375 1.062.375 1.437 0l8.313-8.313a.951.951 0 0 0 0-1.437l-11-11c-.188-.188-.438-.313-.688-.313H9.352v9.375Zm4-7.375c1.062 0 2 .938 2 2 0 1.125-.938 2-2 2-1.125 0-2-.875-2-2 0-1.062.875-2 2-2Z" />
    </SvgIcon>
  );
}
