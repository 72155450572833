import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function TicketSimple(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M36.5 15.813H38c0 .624-.438 1.187-1 1.374l-.5-1.375Zm0 8.437.5-1.375c.563.188 1 .75 1 1.375h-1.5Zm-33 0H2c0-.625.375-1.188 1-1.375l.5 1.375Zm0-8.438L3 17.189c-.625-.188-1-.75-1-1.375h1.5ZM6 8v3c-.563 0-1 .5-1 1H2c0-2.188 1.75-4 4-4Zm28 0v3H6V8h28Zm4 4h-3c0-.5-.5-1-1-1V8c2.188 0 4 1.813 4 4Zm0 3.813h-3V12h3v3.813ZM35 20h-3a5.937 5.937 0 0 1 4-5.625l1 2.813c-1.188.437-2 1.562-2 2.812Zm2 2.875-1 2.813c-2.375-.813-4-3.063-4-5.688h3a3.05 3.05 0 0 0 2 2.875ZM38 28h-3v-3.75h3V28Zm-4 4v-3c.5 0 1-.438 1-1h3c0 2.25-1.813 4-4 4ZM6 32v-3h28v3H6Zm-4-4h3c0 .563.438 1 1 1v3c-2.25 0-4-1.75-4-4Zm0-3.75h3V28H2v-3.75ZM5 20v.063h3c0 2.562-1.688 4.812-4 5.625l-1-2.813c1.125-.438 2-1.563 2-2.875Zm-2-2.813 1-2.812c2.313.813 4 3.063 4 5.625H5c0-1.25-.875-2.375-2-2.813ZM2 12h3v3.813H2V12Z" />
    </SvgIcon>
  );
}
