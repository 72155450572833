import { EntityLayout } from '@lego/plugin-baseplate-catalog';
import React from 'react';
import { ToolsContent } from '../tools';
import { DocumentationContent } from '../tools/ToolsPage/DocumentationContent';
import { ToolTab } from '@lego/plugin-baseplate-core-components';
import { useEntity } from '@backstage/plugin-catalog-react';
import { ToolEntity } from '@lego/plugin-baseplate-common';
import { toolCategorySlugify } from '../tools/utils/tool-category-slugify';
import { useExternalLink } from '../hooks';
import { RouteTitleWithIcon, RenavigateContent } from './ExternalLinkUtils';

export const PluginTabs = ({ toolTabs }: { toolTabs: ToolTab[] }) => {
  const { entity }: { entity: ToolEntity } = useEntity();
  const { externalLink } = useExternalLink(entity);

  return (
    <EntityLayout
      breadcrumbLinks={[
        { label: 'Tool', href: '/tools' },
        ...(entity.metadata.categories
          ? [
              {
                label: entity.metadata.categories[0],
                href: `/tools/${toolCategorySlugify(
                  entity.metadata.categories[0],
                )}`,
              },
            ]
          : []),
      ]}
    >
      <EntityLayout.Route path="/" title="Overview">
        <ToolsContent />
      </EntityLayout.Route>
      <EntityLayout.Route path="/documentation" title="Documentation">
        <DocumentationContent />
      </EntityLayout.Route>
      {!!externalLink && (
        <EntityLayout.Route
          path={externalLink}
          title={(<RouteTitleWithIcon title="Docs" />) as unknown as string}
        >
          <RenavigateContent />
        </EntityLayout.Route>
      )}
      {toolTabs.map(route => {
        return (
          <EntityLayout.Route
            path={route.path}
            title={route.title}
            children={route.component}
            key={`entity-layout-${route.title}`}
          />
        );
      })}
    </EntityLayout>
  );
};
