import { BackstageOverrides } from '@backstage/core-components';
import { DesignTokens } from '../types';

export const typography = (
  tokens: DesignTokens,
): Pick<BackstageOverrides, 'MuiTypography'> => ({
  MuiTypography: {
    root: {
      color: tokens.semantic.text.primary,
    },
    h1: {
      fontSize: '2.812rem',
      fontWeight: 500,
      lineHeight: '115%',
      letterSpacing: '-0.02em',
      fontFamily: 'inherit',
    },
    h2: {
      fontSize: '2.375rem',
      fontWeight: 500,
      lineHeight: '115%',
      letterSpacing: '-0.02em',
      fontFamily: 'inherit',
    },
    h3: {
      fontSize: '1.9375rem',
      fontWeight: 500,
      lineHeight: '115%',
      letterSpacing: '-0.02em',
      fontFamily: 'inherit',
    },
    h4: {
      fontSize: '1.6875rem',
      fontWeight: 500,
      lineHeight: '135%',
      letterSpacing: '-0.02em',
      fontFamily: 'inherit',
    },
    subtitle1: {
      fontSize: '1.375rem',
      letterSpacing: '-0.02em',
      lineHeight: '135%',
      fontWeight: 500,
      fontFamily: 'inherit',
    },
    subtitle2: {
      fontSize: '1.1875rem',
      fontWeight: 500,
      lineHeight: '150%',
      fontFamily: 'inherit',
    },
    body1: {
      fontSize: '1rem',
      lineHeight: '150%',
      fontFamily: 'inherit',
    },
    body2: {
      fontSize: '0.875rem',
      lineHeight: '150%',
    },
    caption: {
      fontSize: '0.75rem',
      lineHeight: '162%',
      fontFamily: 'inherit',
    },
    overline: {
      fontSize: '0.75rem',
      lineHeight: '162%',
      textTransform: 'uppercase',
      fontFamily: 'inherit',
    },
    button: {
      textTransform: 'none',
      fontSize: '1rem',
      fontFamily: 'inherit',
    },
    colorPrimary: { color: tokens.semantic.text.primary },
    colorTextPrimary: { color: tokens.semantic.text.primary },
    colorSecondary: { color: tokens.semantic.text.secondary },
    colorTextSecondary: { color: tokens.semantic.text.secondary },
    colorError: { color: tokens.semantic.text.error },
  },
});
