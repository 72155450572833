import React from 'react';
import { ReportProvider } from '../../hooks';
import { EmployeeTable } from '../../features/EmployeeTable/EmployeeTable';

export function ReportsPage() {
  return (
    <ReportProvider>
      <EmployeeTable />
    </ReportProvider>
  );
}
