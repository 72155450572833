import { OpenAPIV3_1 } from 'openapi-types';

const getReferenceUrl = (
  key: keyof OpenAPIV3_1.ComponentsObject,
  ref: string,
) => {
  const refUrl = ref.replace(`#/components/${key}/`, '');
  return refUrl;
};

/**
 * Takes any object that can be a ReferenceObject and looks for the referenced value in the components object.
 */
export const resolveRef = <T>(
  key: keyof OpenAPIV3_1.ComponentsObject,
  object: any,
  components: OpenAPIV3_1.ComponentsObject | undefined,
  resolvedRefs: Set<string> = new Set(), // Track resolved references
): T => {
  if (typeof object !== 'object' || !object) {
    // Neither a ReferenceObject nor an object. Return value
    return object as T;
  }

  let resolvedObject = object;

  // Check if object is a ReferenceObject
  if ('$ref' in object) {
    const refKey = object.$ref;
    if (resolvedRefs.has(refKey)) {
      console.warn(`Circular reference detected: ${refKey}`);
      return object as T; // Return initial object to avoid infinite recursion
    }
    resolvedRefs.add(refKey);
    resolvedObject =
      components?.[key]?.[getReferenceUrl(key, object.$ref)] ?? object;
  }

  // Resolve all nested references
  for (const objectKey in resolvedObject) {
    resolvedObject[objectKey] = resolveRef<object>(
      key,
      resolvedObject[objectKey],
      components,
      resolvedRefs,
    );
  }

  return resolvedObject as T;
};
