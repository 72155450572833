import { Delete } from '@lego/klik-ui-icons';
import { icons } from '@lego/plugin-baseplate-core-components';
import { IconButton, Tooltip, Typography } from '@material-ui/core';
import React from 'react';

interface TooltipDeleteIconButtonProps {
  isDisabled: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

function TooltipDeleteIconButton({
  isDisabled,
  onClick,
}: TooltipDeleteIconButtonProps) {
  return (
    <Tooltip title={isDisabled ? '' : <Typography>Delete product</Typography>}>
      <IconButton
        aria-label="delete"
        style={{ color: icons.default }}
        disabled={isDisabled}
        onClick={onClick}
      >
        <Delete />
      </IconButton>
    </Tooltip>
  );
}

export default TooltipDeleteIconButton;
