import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ArrowDownLeftAndArrowUpRightToCenter(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="m34.586 7.531-8.5 8.438h5.437c.813 0 1.5.687 1.5 1.5 0 .875-.687 1.5-1.5 1.5h-9c-.875 0-1.5-.625-1.5-1.5v-9c0-.813.625-1.5 1.5-1.5.813 0 1.5.687 1.5 1.5v5.437l8.438-8.437c.562-.625 1.5-.625 2.125 0 .562.562.562 1.5 0 2.062ZM5.46 32.406l8.437-8.437H8.523c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5h8.938c.875 0 1.5.687 1.5 1.5v9c0 .875-.625 1.5-1.5 1.5a1.48 1.48 0 0 1-1.5-1.5v-5.375L7.523 34.53c-.562.625-1.5.625-2.062 0-.625-.562-.625-1.5 0-2.125Z" />
    </SvgIcon>
  );
}
