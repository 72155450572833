const CONFIG_STORE_NAME = 'appcs-github-backend';
const BASE_URL_APP_CONFIG_KEY = 'GitHub:BackendBaseUrl';
const TENANT_ID_APP_CONFIG_KEY = 'GitHub:TenantId';
const CLIENT_ID_APP_CONFIG_KEY = 'GitHub:ClientId';
const AZURE_SCOPE =
  'api://c9ee06d3-7b58-49fa-bb6f-efd43a84ca6b/user_impersonation';
const TOAST_AUTO_CLOSE_TIME = 2000;
const TOAST_ERROR_AUTO_CLOSE_TIME = 5000;

export {
  AZURE_SCOPE,
  BASE_URL_APP_CONFIG_KEY,
  CLIENT_ID_APP_CONFIG_KEY,
  CONFIG_STORE_NAME,
  TENANT_ID_APP_CONFIG_KEY,
  TOAST_AUTO_CLOSE_TIME,
  TOAST_ERROR_AUTO_CLOSE_TIME,
};

export const DELAY_ON_RETRY = 250;
export const MAX_RETRY_ATTEMPTS = 3;
