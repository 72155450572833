import { OpenAPIV3_1 } from 'openapi-types';
import { UiExampleType, UiParameterGroupType } from '../../../types';
import { formatParameterArray } from '../../utils/formatParameterArray';

export const createRequestExamples = ({
  examples,
  parameters,
}: {
  examples?: OpenAPIV3_1.ExampleObject;
  method: string;
  path: string;
  parameters: UiParameterGroupType;
  servers?: OpenAPIV3_1.ServerObject[];
}): UiExampleType[] => {
  const language = 'json';
  const bodyParams = formatParameterArray(parameters.body);

  const requestExamples = [];

  if (examples) {
    Object.entries(examples).forEach(([key, { value }]) => {
      requestExamples.push({ label: key, language: 'json', value });
    });
  }

  if (bodyParams) {
    requestExamples.push({
      label: 'Generated from types',
      language,
      value: JSON.stringify(bodyParams, null, 2),
    });
  }

  return requestExamples;
};
