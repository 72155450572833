import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Icon, baseplateStyles } from '@lego/plugin-baseplate-core-components';
import { HighlightedSearchResultText } from '@backstage/plugin-search-react';
import { useNavigate } from 'react-router-dom';

// TODO: export and use styling from core-components
export const useEntityLinkStyles = baseplateStyles(token => ({
  wrapper: {
    border: `1px solid ${token.semantic.border.default}`,
    borderRadius: token.primitive.borderRadius.default,
    backgroundColor: token.semantic.background.neutral[1],
    color: token.semantic.text.primary,
    cursor: 'pointer',
    '&:hover': {
      borderColor: token.semantic.border.hover,
      color: token.semantic.text.primary,
      textDecoration: 'none',
    },
    display: 'flex !important',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: token.spacing(1, 0, 1, 2),
  },
  bookIcon: {
    width: 20,
    height: 20,
    color: token.semantic.text.primary,
  },
  titleFooter: { display: 'flex', alignItems: 'center', marginTop: 5 },
}));

export const TechDocsSearchResultListItem = ({ result }: { result: any }) => {
  const classes = useEntityLinkStyles();
  const navigate = useNavigate();

  return (
    <Grid
      className={classes.wrapper}
      onClick={() => navigate(result.document.location)}
    >
      <Typography variant="body1" style={{ marginBottom: 3 }}>
        {result.document.title}
      </Typography>
      <Typography variant="body2" color="secondary">
        <HighlightedSearchResultText
          text={result.highlight.fields.text}
          preTag={result.highlight.preTag}
          postTag={result.highlight.postTag}
        />
      </Typography>
      {!!result.document.entityTitle && (
        <Grid className={classes.titleFooter}>
          <Icon icon="book-open" />
          <Typography style={{ marginLeft: 5 }}>
            {result.document.entityTitle}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};
