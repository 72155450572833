import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Pinterest(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M36 19.98c0 8.84-7.161 16-16 16-1.677 0-3.29-.193-4.774-.71.645-1.032 1.613-2.773 2-4.193.193-.71.968-3.806.968-3.806.516 1.032 2.064 1.87 3.677 1.87 4.839 0 8.323-4.45 8.323-9.935 0-5.29-4.323-9.29-9.871-9.29-6.904 0-10.581 4.645-10.581 9.677 0 2.387 1.226 5.29 3.226 6.258.322.13.516.065.58-.258 0-.193.323-1.29.452-1.806 0-.13 0-.323-.129-.452-.645-.774-1.161-2.258-1.161-3.677 0-3.484 2.645-6.903 7.226-6.903 3.87 0 6.645 2.645 6.645 6.516 0 4.322-2.194 7.29-5.033 7.29-1.548 0-2.71-1.29-2.387-2.839.452-1.935 1.355-4 1.355-5.354 0-1.226-.645-2.258-2-2.258-1.613 0-2.903 1.677-2.903 3.87 0 1.42.451 2.388.451 2.388s-1.548 6.71-1.87 7.935c-.323 1.42-.194 3.355-.065 4.58A15.926 15.926 0 0 1 4 19.98c0-8.838 7.161-16 16-16s16 7.162 16 16Z" />
    </SvgIcon>
  );
}
