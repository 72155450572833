import { Typography } from '@material-ui/core';
import React from 'react';
import { Card } from '../card';
import { useModalStyles } from './PopupCard.styles';
import { Button } from '../button';

export type PopUpCardProps = {
  title: string;
  image: string;
  description: string;
  confirmButton: {
    text: string;
    onClick: () => void;
  };
  cancelButton: {
    text: string;
    onClick: () => void;
  };
};

export const PopupCard = ({
  title,
  image,
  description,
  confirmButton,
  cancelButton,
}: PopUpCardProps) => {
  const classes = useModalStyles();

  return (
    <div className={classes.popupContainer}>
      <Card>
        <img
          src={image}
          alt="specific for popup card"
          className={classes.image}
        />
        <Typography variant="h4">{title}</Typography>
        <Typography variant="body1">{description}</Typography>
        <div className={classes.buttonWrapper}>
          <Button
            className={classes.button}
            variant="primary"
            onClick={confirmButton.onClick}
          >
            {confirmButton.text}
          </Button>
        </div>
        <div className={classes.buttonWrapper}>
          <Button
            className={classes.button}
            variant="secondary"
            onClick={cancelButton.onClick}
          >
            {cancelButton.text}
          </Button>
        </div>
      </Card>
    </div>
  );
};
