import { makeFieldSchemaFromZod } from '@backstage/plugin-scaffolder';
import { z } from 'zod';

// Define the schema for the kind property
const kindSchema = z.string().optional().describe('The kind of entity to pick');

// Define the schema for the entity picker extension options
const entityPickerExtensionWithOptionsSchema = makeFieldSchemaFromZod(
  z.string(),
  z
    .object({
      kind: kindSchema,
      kindPicker: z
        .object({
          label: z.string().optional(),
          helperText: z.string().optional(),
          options: z.array(z.string()),
        })
        .optional(),
    })
    .refine(data => !(data.kind && data.kindPicker), {
      message: 'Only either "kind" or "chooseWhichKind" is allowed, not both.',
    }),
);

// Define the types for the UI options and the props
export type EntityPickerExtensionUiOptions =
  typeof entityPickerExtensionWithOptionsSchema.uiOptionsType;

export type EntityPickerExtensionProps =
  typeof entityPickerExtensionWithOptionsSchema.type;

// Export the schema for the entity picker extension
export const EntityPickerExtensionSchema =
  entityPickerExtensionWithOptionsSchema.schema;
