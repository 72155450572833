import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function MessagesDollar(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M4.5 6.985c-.875 0-1.5.688-1.5 1.5v11c0 .875.625 1.5 1.5 1.5h3c.813 0 1.5.688 1.5 1.5v1.25l3.625-2.5c.25-.125.563-.25.875-.25h8c.813 0 1.5-.625 1.5-1.5v-11c0-.812-.688-1.5-1.5-1.5h-17ZM0 8.485c0-2.437 2-4.5 4.5-4.5h17c2.438 0 4.5 2.063 4.5 4.5v11c0 2.5-2.063 4.5-4.5 4.5h-7.563l-5.624 3.75c-.5.313-1.063.375-1.563.125-.5-.312-.75-.812-.75-1.375v-2.5H4.5c-2.5 0-4.5-2-4.5-4.5v-11Zm16 17.5h3v1.5c0 .875.625 1.5 1.5 1.5h6c.25 0 .563.125.813.25l3.687 2.5v-1.25c0-.812.625-1.5 1.5-1.5h3c.813 0 1.5-.625 1.5-1.5v-11c0-.812-.688-1.5-1.5-1.5H28v-3h7.5c2.438 0 4.5 2.063 4.5 4.5v11c0 2.5-2.063 4.5-4.5 4.5H34v2.5c0 .563-.313 1.063-.813 1.375-.5.25-1.062.188-1.562-.125L26 31.985h-5.5c-2.5 0-4.5-2-4.5-4.5v-1.5Zm-2-17v.625c.313.063.688.125 1 .25.563.125.875.625.75 1.188-.125.562-.688.875-1.25.75-.5-.188-1.063-.25-1.563-.25-.374-.063-.812.062-1.062.187-.25.188-.313.313-.313.438 0 .062 0 .187.25.312.313.188.813.313 1.438.5.563.188 1.313.375 1.875.75.625.375 1.25 1.063 1.25 2.063 0 1-.563 1.75-1.25 2.187a2.74 2.74 0 0 1-1.188.438v.687c0 .5-.437 1-1 1-.5 0-1-.5-1-1v-.75c-.5-.062-.937-.25-1.374-.375-.126-.062-.188-.062-.313-.125-.5-.125-.813-.687-.625-1.25.125-.5.688-.812 1.25-.625.125 0 .25.063.375.125.688.188 1.188.375 1.75.375.438.063.813-.062 1.063-.187a.515.515 0 0 0 .312-.5c0-.125-.063-.188-.313-.375-.312-.188-.75-.313-1.374-.5l-.126-.063a7.779 7.779 0 0 1-1.75-.687c-.624-.375-1.25-1-1.25-2 0-1.063.626-1.75 1.313-2.188A2.987 2.987 0 0 1 12 9.61v-.625c0-.562.438-1 1-1 .5 0 1 .438 1 1Z" />
    </SvgIcon>
  );
}
