import { BackstageOverrides } from '@backstage/core-components';
import { DesignTokens } from '../types';

export const alert = (tokens: DesignTokens): BackstageOverrides => ({
  BackstageErrorPanel: {
    text: {
      background: tokens.semantic.background.error,
      color: tokens.semantic.text.error,
      border: tokens.semantic.border.error,
    },
  },
});
