import React from 'react';
import {
  FormStepTitle,
  SummaryTable,
} from '@lego/plugin-baseplate-core-components';
import { Box, Grid, Typography } from '@material-ui/core';
import { StepProps } from '../types';
import { getCloudAccountName } from '../utils/getCloudAccountName';

export const StepSummary = ({ values }: StepProps) => {
  return (
    <Grid>
      <FormStepTitle
        formTitle="Create Cloud Account"
        stepTitle="Summary"
        step={4}
      />
      <Box mt={1} mb={1}>
        <Typography variant="body1">Summary goes here</Typography>
        <SummaryTable
          values={[
            {
              label: 'Vendor',
              value: values.vendor,
            },
            {
              label: 'Account name',
              value: getCloudAccountName(
                String(values.name),
                String(values.environment),
              ),
            },
            {
              label: 'application',
              value: values.application,
            },
            {
              label: 'purpose',
              value: values.purpose,
            },
            {
              label: 'owner',
              value: values.owner,
            },
            {
              label: 'Backup owner',
              value: values.backupOwner,
            },
            {
              label: 'Monthly budget',
              value: `${values.monthlyBudget} ${values.currency}`,
            },
            {
              label: 'Budget alerts',
              value: `At ${values.budgetAlertThreshold1}% (${values.budgetAlertThreshold1Type}), ${values.budgetAlertThreshold2}% (${values.budgetAlertThreshold2Type}) and ${values.budgetAlertThreshold3}% (${values.budgetAlertThreshold3Type})`,
            },
            {
              label: 'Budget approver',
              value: values.budgetApprover,
            },
          ]}
        />
      </Box>
    </Grid>
  );
};
