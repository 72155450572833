import React from 'react';
import { iconLib } from './iconLib';
import { baseplateStyles } from '../../utils';

export type AvailableIcons = keyof typeof iconLib;

type Props = {
  icon: AvailableIcons;
  color?: 'primary' | 'secondary';
  size?: 16 | 24 | 32 | 40;
  className?: string;
};

export const getIconObject = (icon: keyof typeof iconLib) => {
  return iconLib[icon];
};

const useStyles = baseplateStyles(theme => ({
  icon: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  primary: {
    color: theme.semantic.icon.primary,
  },
  secondary: {
    color: theme.semantic.icon.secondary,
  },
  16: {
    '& svg': {
      width: 16,
      height: 16,
    },
  },
  24: {
    '& svg': {
      width: 24,
      height: 24,
    },
  },
  32: {
    '& svg': {
      width: 32,
      height: 32,
    },
  },
  40: {
    '& svg': {
      width: 40,
      height: 40,
    },
  },
}));

export const Icon = ({ icon, color, size = 24, className }: Props) => {
  const classes = useStyles();
  const IconComponent = iconLib[icon];
  if (!IconComponent) return null;
  return (
    <span
      className={[
        classes.icon,
        color ? classes[color] : '',
        size ? classes[size] : '',
        className,
      ].join(' ')}
    >
      <IconComponent />
    </span>
  );
};
