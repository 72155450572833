import React from 'react';
import { NonDigitalProductManagerProvider } from '../../hooks';
import { NonDigitalProductManagerTable } from '../../features/NonDigitalProductManagers/NonDigitalProductManagerTable/NonDIgitalProductManagerTable';

export function NonDigitalProductManagersPage() {
  return (
    <NonDigitalProductManagerProvider>
      <NonDigitalProductManagerTable />
    </NonDigitalProductManagerProvider>
  );
}
