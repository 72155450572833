import React from 'react';
import { Box, Link } from '@material-ui/core';
import { EntityStatusItem } from '@backstage/catalog-model/alpha';
import { useEntity } from '@backstage/plugin-catalog-react';
import {
  ENTITY_MISSING_X_AUDIENCE,
  ENTITY_MULTIPLE_OWNERS,
  getAmmaProcessingErrors,
} from '@lego/plugin-baseplate-common';
import { BaseplateWarningPanel } from './CustomComponents/BaseplateWarningPanel';

const MissingXAudience = (): JSX.Element => {
  return (
    <p>
      This API is missing x-audience property. As per{' '}
      <Link
        href="https://github.com/LEGO/api-matters/tree/main/docs/practices/sync-apis/restful#55-must-provide-api-audience"
        target="_blank"
      >
        API-matters guidelines
      </Link>{' '}
      each API must provide intended target audience.
    </p>
  );
};

const MultipleOwners = (): JSX.Element => {
  return (
    <>
      <p>This entity has multiple owners.</p>

      <p>
        This API is using an Application Identifier that is linked to multiple
        Products in Architecture Gateway. Consider updating Architecture Gateway
        application ownership to only have a single owner. As per{' '}
        <Link
          href="https://legogroup.atlassian.net/wiki/spaces/ARC/pages/1669237617/Product+team+application+ownership+-+what+is+expected"
          target="_blank"
        >
          product team application ownership guidelines
        </Link>{' '}
        you should ensure clear differentiation between owning and consuming
        applications. If you have questions about how you can mitigate this,
        reach out in the{' '}
        <Link
          href="https://teams.microsoft.com/l/channel/19%3ab2cfe10792f24b3fabe80265d7287169%40thread.skype/Architecture%2520Gateway?groupId=ecf9587b-7f13-48da-85fa-d6e62472a771&tenantId=1d063515-6cad-4195-9486-ea65df456faa"
          target="_blank"
        >
          Architecture Gateway Teams Channel
        </Link>
        .
      </p>
    </>
  );
};

const ErrorItem = ({ item }: { item: EntityStatusItem }): JSX.Element => {
  switch (item.message) {
    case ENTITY_MISSING_X_AUDIENCE:
      return <MissingXAudience />;
    case ENTITY_MULTIPLE_OWNERS:
      return <MultipleOwners />;
    default:
      return <></>;
  }
};

export const EntityAmmaProcessingErrors = () => {
  const { entity } = useEntity();
  const errors = getAmmaProcessingErrors(entity);

  return (
    <Box mb={1}>
      {errors.map((error, index) => {
        const title = `${error.message}`;
        return (
          <BaseplateWarningPanel
            key={index}
            severity={error.level}
            title={title}
          >
            <ErrorItem item={error} />
          </BaseplateWarningPanel>
        );
      })}
    </Box>
  );
};
