import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ArrowsToDot(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M21.5 5.469v5.375l1.938-1.938c.562-.562 1.5-.562 2.125 0 .562.625.562 1.563 0 2.125l-4.5 4.5c-.625.625-1.563.625-2.125 0l-4.5-4.5c-.626-.562-.626-1.5 0-2.125.562-.562 1.5-.562 2.124 0l1.938 2V5.47c0-.813.625-1.5 1.5-1.5.813 0 1.5.687 1.5 1.5Zm9.563 20.062-.063.063c-.563.562-1.5.562-2.063 0l-4.5-4.5c-.625-.625-.625-1.563 0-2.125l4.5-4.5a1.369 1.369 0 0 1 2.063 0c.625.562.625 1.5 0 2.125l-1.938 1.937H34.5c.813 0 1.5.625 1.5 1.5 0 .813-.688 1.5-1.5 1.5h-5.375l1.938 1.938c.562.562.562 1.5 0 2.062ZM22 19.97c0 1.125-.938 2-2 2-1.125 0-2-.875-2-2 0-1.063.875-2 2-2 1.063 0 2 .937 2 2Zm-10.938-5.563H11l4.5 4.5c.625.625.625 1.563 0 2.125l-4.5 4.5a1.37 1.37 0 0 1-2.063 0c-.624-.562-.624-1.5 0-2.125l1.938-1.937H5.5c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5h5.375l-1.938-1.94c-.624-.562-.624-1.5 0-2.125.563-.562 1.5-.562 2.126 0Zm3.376 16.625h-.063c-.563-.562-.563-1.5 0-2.125l4.5-4.5c.625-.562 1.563-.562 2.125 0l4.5 4.5c.625.625.625 1.563 0 2.125-.563.625-1.5.625-2.125 0l-1.938-1.937v5.437c0 .813-.625 1.5-1.5 1.5a1.52 1.52 0 0 1-1.5-1.5v-5.437L16.5 31.03a1.37 1.37 0 0 1-2.063 0Z" />
    </SvgIcon>
  );
}
