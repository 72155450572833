import { makeStyles } from '@material-ui/core';
import React from 'react';
import { useUserProfile } from '@backstage/plugin-user-settings';
import { BaseplateTheme } from '../../style/types';
import { LegoFigureHead } from '@lego/plugin-baseplate-core-components';

const useStyles = makeStyles<BaseplateTheme>(_ => ({
  container: {
    display: 'flex',
    gap: '4px',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  avatarWrapper: {
    width: '32px',
    height: '32px',
    overflow: 'hidden',
    borderRadius: '50%',
    alignItems: 'center',
    display: 'flex',
  },
  avatar: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    overflow: 'hidden',
    borderRadius: '50%',
  },
}));

export const AccountMenuButton = () => {
  const classes = useStyles();
  const { profile } = useUserProfile();

  return (
    <div className={classes.container}>
      <div className={classes.avatarWrapper}>
        {profile.picture ? (
          <img
            src={profile.picture}
            alt={profile.displayName}
            className={classes.avatar}
          />
        ) : (
          <img
            src={LegoFigureHead}
            alt="Lego Figure Head"
            className={classes.avatar}
          />
        )}
      </div>
      <svg
        width="8"
        height="7"
        viewBox="0 0 8 7"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.13397 6.5C3.51887 7.16667 4.48113 7.16667 4.86603 6.5L7.4641 2C7.849 1.33333 7.36788 0.5 6.59808 0.5H1.40192C0.632124 0.5 0.150998 1.33333 0.535898 2L3.13397 6.5Z"
          fill="#BDBEC9"
        />
      </svg>
    </div>
  );
};
