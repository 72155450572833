import { dateFormats } from './dateFormats';
import { formatDate } from './formatDate';

/**
 * Checks if two dates have the same value based on the specified format.
 *
 * @param {Date} startDate - The start date.
 * @param {Date} endDate - The end date.
 * @param {string} dateformat - The format string specifying how the dates should be compared.
 * @returns {boolean} True if the dates are equal based on the format false otherwise.
 */
export const sameDateCheck = (
  startDate: Date,
  endDate: Date,
  dateformat: string,
): boolean => {
  const formattedStartDate = formatDate(startDate, dateformat);
  const formattedEndDate = formatDate(endDate, dateformat);

  return formattedStartDate === formattedEndDate;
};

/**
 * Extracts date and time properties from a Date object.
 *
 * @param {Date} date - The Date object from which to extract properties.
 * @param {boolean} [isShortMonth=false] - A flag indicating whether to use short month names.
 * @returns {Object} An object containing extracted date and time properties.
 * @property {string} day - The day of the week.
 * @property {string} date - The day of the month.
 * @property {string} month - The month.
 * @property {string} year - The year.
 * @property {string} hour - The hour.
 * @property {string} min - The minutes.
 */
export const extractDateTimeProps = (date: Date, isShortMonth?: boolean) => {
  return {
    day: formatDate(date, isShortMonth ? 'ddd' : 'dddd'),
    date: formatDate(date, 'DD'),
    month: formatDate(date, isShortMonth ? 'MMM' : 'MMMM'),
    year: formatDate(date, 'YYYY'),
    hour: formatDate(date, 'HH'),
    min: formatDate(date, 'mm'),
  };
};

function isEqual(a: any, b: any): boolean {
  return a === b;
}

/**
 * Formats a date range into a human-readable string based on the specified format.
 *
 * @param {string | Date} startDate - The start date of the range.
 * @param {string | Date} endDate - The end date of the range.
 * @param {string | undefined} dateformat - The format string specifying how the date should be displayed.
 * @param {boolean} isLong - A flag indicating whether the date range should be displayed in a long formatDate.
 * @returns {string} The formatted date range string.
 */
export const formatDateRange = (
  startDate: string | Date,
  endDate: string | Date,
  dateformat: string | undefined = dateFormats.long_day_date_month_w_y,
  isLong = false,
): string => {
  // Convert string inputs to Date objects
  const startDateTime =
    startDate instanceof Date ? startDate : new Date(startDate);
  const endDateTime = endDate instanceof Date ? endDate : new Date(endDate);

  const start = extractDateTimeProps(startDateTime);
  const end = extractDateTimeProps(endDateTime);

  let date = '';
  const isSameDay = sameDateCheck(
    startDateTime,
    endDateTime,
    dateFormats.default,
  );
  const sameTime = sameDateCheck(startDateTime, endDateTime, dateFormats.time);

  const timeSlot = `${start.hour}:${start.min}${
    !sameTime ? `-${end.hour}:${end.min}` : ''
  }`;

  if (isSameDay) {
    date = `${formatDate(startDateTime, dateformat)} at ${timeSlot}`;
  } else {
    if (
      isEqual(start.month, end.month) &&
      isEqual(start.year, end.year) &&
      !isLong
    ) {
      date = `${start.month} ${start.date}-${end.date}, ${start.year} at ${timeSlot}`;
    } else if (
      !isEqual(start.month, end.month) &&
      isEqual(start.year, end.year) &&
      !isLong
    ) {
      date = `${formatDate(startDateTime, dateformat)} - ${formatDate(
        endDateTime,
        dateformat,
      )} at ${timeSlot}`;
    } else if (
      !isEqual(start.month, end.month) &&
      !isEqual(start.year, end.year) &&
      !isLong
    ) {
      date = `${formatDate(startDateTime, dateformat)} ${
        isLong ? 'to' : '-'
      } ${formatDate(endDateTime, dateformat)} at ${timeSlot}`;
    } else {
      date = `${formatDate(startDateTime, dateformat)} to ${formatDate(
        endDateTime,
        dateformat,
      )} at ${timeSlot}`;
    }
  }

  return date;
};

export const createDateTime = (
  recurringEventStartDate: string,
  dateTime: Date,
): Date => {
  const hours = dateTime.getHours();
  const minutes = dateTime.getMinutes();
  const secs = dateTime.getSeconds();

  const combinedDateTime = new Date(recurringEventStartDate);
  combinedDateTime.setHours(hours);
  combinedDateTime.setMinutes(minutes);
  combinedDateTime.setSeconds(secs);
  return combinedDateTime;
};
