import React, { useEffect } from 'react';
import {
  FormHelperText,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Button, EntityDropdown } from '@lego/plugin-baseplate-core-components';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { CommunityV2 } from '@lego/plugin-baseplate-communities-common';
import { stringifyEntityRef } from '@backstage/catalog-model';
import { FormikErrors } from 'formik';

export const CreateCommunityMaintainers = ({
  formValues,
  setFormValues,
  setValues,
}: {
  formValues: CommunityV2;
  setFormValues: React.Dispatch<React.SetStateAction<CommunityV2>>;
  setValues: (
    values: React.SetStateAction<CommunityV2>,
    shouldValidate?: boolean,
  ) => Promise<void | FormikErrors<CommunityV2>>;
}) => {
  const handleAdd = () => {
    const updatedMaintainerEntityRefs = [
      ...formValues.maintainerEntityRefs,
      '',
    ];
    setFormValues({
      ...formValues,
      maintainerEntityRefs: updatedMaintainerEntityRefs,
    });
  };

  const handleRemove = (index: number) => {
    const updatedMaintainerEntityRefs = formValues.maintainerEntityRefs.filter(
      (_, i) => i !== index,
    );
    setFormValues({
      ...formValues,
      maintainerEntityRefs: updatedMaintainerEntityRefs,
    });
  };

  const handleUpdate = (index: number, value: string) => {
    const updatedMaintainerEntityRefs = formValues.maintainerEntityRefs.map(
      (maintainerEntityRef, i) => (i === index ? value : maintainerEntityRef),
    );
    setFormValues({
      ...formValues,
      maintainerEntityRefs: updatedMaintainerEntityRefs,
    });
  };

  return (
    <div>
      <Typography component="label" variant="body2">
        Community Maintainers
      </Typography>
      {formValues.maintainerEntityRefs &&
        !!formValues.maintainerEntityRefs.length &&
        formValues.maintainerEntityRefs.map((entry, index) => (
          <Grid container key={index} style={{ padding: 0 }}>
            <Grid item xs={11}>
              <EntityDropdown
                label={`Maintainer ${index + 1}`}
                kind="User"
                value={entry.length > 0 ? entry : undefined}
                helperText="Add a maintainer to the community."
                required
                handleChange={entity => {
                  const entityRef = stringifyEntityRef(entity);
                  handleUpdate(index, entityRef);
                }}
              />
            </Grid>
            <Grid
              item
              xs={1}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <IconButton
                onClick={() => handleRemove(index)}
                style={{ height: 'fit-content' }}
              >
                <RemoveCircleOutlineIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}
      <Button
        onClick={handleAdd}
        startIcon={<AddCircleOutlineIcon />}
        fullWidth
        variant="secondary"
      >
        Add maintainer
      </Button>
      <FormHelperText style={{ padding: '0 1rem' }}>
        <Typography variant="body2" color="secondary">
          You can add maintainers to your community. These people are the
          drivers of the community.
        </Typography>
      </FormHelperText>
    </div>
  );
};
