import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function BrickOneByOneSideOutline(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.126 16.26H1V31h38V16.26h-7.14V9.954C31.86 8.861 30.985 8 29.842 8H10.144c-1.13 0-2.018.848-2.018 1.955v6.304Zm27.171 3.538h-7.14v-8.26H11.828v8.26H4.703v7.664h30.594v-7.664Z"
      />
    </SvgIcon>
  );
}
