import { createPlugin } from '@backstage/core-plugin-api';
import { createScaffolderFieldExtension } from '@backstage/plugin-scaffolder-react';
import { rootRouteRef } from './routes';
import { CustomExtensionForAdminAccount } from './components/CustomExtensionForAdminAccount/CustomExtensionForAdminAccount';
import { CustomExtensionForServersDropdown } from './components/CustomExtensionForServersDropdown/CustomExtensionForServersDropdown';

export const serverAccessFormPlugin = createPlugin({
  id: 'server-access-form',
  routes: {
    root: rootRouteRef,
  },
});

export const AdminAccountExtension = serverAccessFormPlugin.provide(
  createScaffolderFieldExtension({
    name: 'AdminAccountInput',
    component: CustomExtensionForAdminAccount,
  }),
);

export const ServersDropdownExtension = serverAccessFormPlugin.provide(
  createScaffolderFieldExtension({
    name: 'ServersDropdown',
    component: CustomExtensionForServersDropdown,
  }),
);
