import { CSSProperties } from 'react';
import { baseplateStyles } from '../../../utils';

export const useStyles = baseplateStyles(theme => {
  const iconColors = Object.entries(theme.component.entity.icon);
  const classes: { [key: string]: CSSProperties } = {};

  classes['svg-icon-sm'] = { width: 24, height: 24 };
  classes['svg-icon-md'] = { width: 32, height: 32 };

  iconColors.forEach(([key, value]) => {
    Object.values(value.layer).map((color, index) => {
      classes[`${key}-layer-${index + 1}`] = { fill: color };
    });
  });

  return {
    ...classes,
    iconWrapper: {
      position: 'relative',
      borderRadius: theme.primitive.borderRadius.default,
      overflow: 'hidden',
    },
    img: {
      borderRadius: theme.primitive.borderRadius.default,
    },
  };
});
