import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Trash(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M17.063 7c-.188 0-.313.125-.438.25L15.437 9H24.5l-1.188-1.75a.526.526 0 0 0-.437-.25h-5.813Zm11.062 2H32.5c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5h-.75l-1.5 20.313c-.188 2.124-1.875 3.687-4 3.687H13.687c-2.124 0-3.812-1.563-4-3.688L8.188 12H7.5c-.875 0-1.5-.625-1.5-1.5C6 9.687 6.625 9 7.5 9h4.313l2.312-3.438A3.584 3.584 0 0 1 17.063 4h5.812c1.188 0 2.313.625 2.938 1.563L28.125 9Zm.625 3H11.187l1.5 20.125c.063.5.5.875 1 .875H26.25c.5 0 .938-.375 1-.875L28.75 12Z" />
    </SvgIcon>
  );
}
