import { ResponseError } from '@backstage/errors';
import { DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';
import { toast } from 'react-toastify';

export abstract class AbstractClient {
  protected constructor(
    protected readonly baseUrlRef: string,
    protected readonly discoveryApi: DiscoveryApi,
    protected readonly fetchApi: FetchApi,
  ) {}

  protected async fetch<T>(props: {
    path: string;
    method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
    body?: any;
    showProgress?:
      | false
      | {
          pending?: false | string;
          success?: false | string;
          error?: false | string;
        };
  }): Promise<T> {
    const { path, method, body, showProgress } = props;
    const toastMessages = this.getToastMessages(showProgress);

    const baseUrl = `${await this.discoveryApi.getBaseUrl(this.baseUrlRef)}/`;
    const url = new URL(path, baseUrl);

    const response = await toast.promise(
      this.fetchApi.fetch(url.toString(), {
        method,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify(body),
      }),
      {
        pending: toastMessages.pending,
      },
      { theme: 'colored', toastId: `${path}_pending` },
    );

    if (!response.ok) {
      const error = await ResponseError.fromResponse(response);

      if (toastMessages.error) {
        toast.error(
          `${toastMessages.error}. ${error.body.error.name} ${error.statusCode} ${error.statusText}: '${error.body.error.message}'`,
          {
            theme: 'colored',
            toastId: `${path}_error`,
            autoClose: false,
            delay: 100,
          },
        );
      }

      throw error;
    }

    if (toastMessages.success) {
      toast.success(toastMessages.success, {
        theme: 'colored',
        toastId: `${path}_success`,
        delay: 100,
      });
    }

    return response.json() as Promise<T>;
  }

  private getToastMessages(
    showProgress?:
      | false
      | {
          pending?: false | string;
          success?: false | string;
          error?: false | string;
        },
  ): {
    pending?: string;
    success?: string;
    error?: string;
  } {
    if (showProgress === false) {
      return {};
    }

    let pending: string | undefined = 'Loading';
    let success: string | undefined;
    let error: string | undefined = 'Something went wrong';

    if (showProgress) {
      pending = showProgress.pending || undefined;
      success = showProgress.success || undefined;
      error = showProgress.error || undefined;
    }

    return { pending, success, error };
  }
}
