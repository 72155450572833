import { useState } from 'react';
import { TableRowType } from '../types';

export type OpenRowMenuFunctionProps<T> = (
  event: React.MouseEvent<HTMLButtonElement>,
  row: TableRowType<T>,
) => void;

export const useTableRowMenu = <T>() => {
  const [openedRow, setOpenedRow] = useState<TableRowType<T> | undefined>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const isRowMenuOpen = Boolean(anchorEl);

  const openRowMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
    row: TableRowType<T>,
  ) => {
    setAnchorEl(event.currentTarget);
    setOpenedRow(row);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return { handleClose, openRowMenu, openedRow, isRowMenuOpen, anchorEl };
};
