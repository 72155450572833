import {
  Entity,
  entityKindSchemaValidator,
  KindValidator,
} from '@backstage/catalog-model';
import schema from './Community.v1alpha1.schema.json';

export interface CommunityEntityV1alpha1 extends Entity {
  apiVersion: 'scaffolder.legogroup.io/v1alpha1';
  kind: 'Community';
  spec: {
    type: string;
    lifecycle: string;
    owner: string;
    subcomponentOf?: string;
    providesApis?: string[];
    consumesApis?: string[];
    dependsOn?: string[];
    system?: string;
  };
}

const validator = entityKindSchemaValidator(schema);

export const communityEntityV1alpha1Validator: KindValidator = {
  // eslint-disable-next-line @typescript-eslint/require-await
  async check(data: Entity) {
    return validator(data) === data;
  },
};
