import { Entity, getCompoundEntityRef } from '@backstage/catalog-model';
import { useApi, useRouteRef } from '@backstage/core-plugin-api';
import { catalogApiRef, entityRouteRef } from '@backstage/plugin-catalog-react';
import {
  isTechDocsAvailable,
  plugin as techdocsPlugin,
} from '@backstage/plugin-techdocs';
import React, { ReactNode } from 'react';
import useAsync from 'react-use/lib/useAsync';
import { EntityLinkDescription } from './EntityLinkDescription';
import { EntityLinkWrapper } from './EntityLinkWrapper';
import { EntityLinkFooter } from './entity-link-footer/EntityLinkFooter';
import { EntityLinkVariant } from './types';

type BaseProps = {
  variant: EntityLinkVariant;
  isTechdocs?: boolean;
  showFavoriteButton?: boolean;
  apiStatus?: ReactNode;
};

type EntityProps = BaseProps & {
  entity: Entity;
  entityRef?: never;
};

export type EntityRefProps = BaseProps & {
  entity?: never;
  entityRef: string;
};

type Props = EntityProps | EntityRefProps;

export const EntityLink = ({
  entity: entityProp,
  variant = 'link',
  showFavoriteButton,
  entityRef,
  apiStatus,
}: Props) => {
  const api = useApi(catalogApiRef);
  const getRouteForDocs = useRouteRef(techdocsPlugin.routes.docRoot);
  const getRouteForEntity = useRouteRef(entityRouteRef);

  const {
    loading,
    error,
    value: fetchedEntity,
  } = useAsync(async () => {
    if (!entityRef) return undefined;
    return await api.getEntityByRef(entityRef);
  }, [entityRef]);

  if (loading) return null;

  const entity = entityProp || fetchedEntity;

  if (error || !entity) return <div>—</div>;
  const isTemplate = entity.kind.toLocaleLowerCase('en-US') === 'template';
  const docsLink = getRouteForDocs(getCompoundEntityRef(entity));
  const entityLink = getRouteForEntity(getCompoundEntityRef(entity));
  const templateLink = `/create/templates/${entity.metadata.namespace}/${entity.metadata.name}`;
  const isExternalLink = entity.spec?.type === 'external-link';

  let url;
  // Order is important! a isExternalLink is also triggered with isTechDocsAvailable
  if (entity.kind === 'Community') {
    url = entityLink;
  } else if (isTemplate && !isExternalLink) {
    url = templateLink;
  } else if (isExternalLink || (isTemplate && isExternalLink)) {
    url =
      entity.metadata.links && entity.metadata.links.length > 0
        ? entity.metadata.links[0].url
        : entityLink;
  } else if (isTechDocsAvailable(entity)) {
    url = docsLink;
  } else {
    url = entityLink;
  }

  return (
    <EntityLinkWrapper
      entity={entity}
      variant={variant}
      url={url}
      showFavoriteButton={showFavoriteButton}
    >
      <EntityLinkDescription entity={entity} variant={variant} />
      <EntityLinkFooter
        entity={entity}
        variant={variant}
        isExternalLink={isExternalLink}
        apiStatus={apiStatus}
      />
    </EntityLinkWrapper>
  );
};
