/*
 * Copyright 2022 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  ConfigApi,
  DiscoveryApi,
  FetchApi,
  OAuthApi,
} from '@backstage/core-plugin-api';
import { ServicesApi } from './ServicesApi';
import { ApiUtils } from './APIUtils';

export class ServicesClient implements ServicesApi {
  private readonly apiUtils: ApiUtils;

  public constructor(options: {
    discoveryApi: DiscoveryApi;
    fetchApi: FetchApi;
    oAuthApi: OAuthApi;
    configApi: ConfigApi;
  }) {
    this.apiUtils = new ApiUtils(
      options.discoveryApi,
      options.fetchApi,
      options.oAuthApi,
      options.configApi,
    );
  }

  public async getProfilePictureById(id: string, size: string): Promise<any> {
    const customHeaders = await this.apiUtils.getMsAccessTokenHeader();

    return await this.apiUtils.get<any>(
      `services/msgraph/photo/${id}/${size}`,
      customHeaders,
    );
  }
}
