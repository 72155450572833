import { OpenAPIV3_1 } from 'openapi-types';
import {
  UiDefinitionType,
  UiEndpointType,
  UiGroupedEndpointType,
  UiOpenApiDocumentType,
} from '../../types';
import { resolvePathItemObject } from './v3.1/pathItem';
import { getGroupedEndpoints } from './getGroupedEndpoints';
import { getOpenApiSideNavItems } from '../../components/DefinitionTab/openapi/utils/getOpenApiSideNavItems';

/**
 * Parse the openapi spec and return endpoints in UI friendly format
 */
export const getOpenApiDefinition = (
  specification: string,
): UiOpenApiDocumentType => {
  const document: OpenAPIV3_1.Document = (() => {
    try {
      return JSON.parse(specification);
    } catch (e) {
      return specification;
    }
  })();

  // General metadata about the API specification
  const definition: UiDefinitionType = {
    title: document.info?.title,
    description: document.info?.description,
    servers: undefined,
    navigation: [],
  };

  if (document.servers) {
    definition.servers = [] || document.servers.map(server => server.url);
  }

  // Hierarchy: Pathitem -> Operation -> Parameter + Request Body
  let endpoints: UiEndpointType[] = [];
  if (document.paths) {
    endpoints = Object.entries(document.paths).flatMap(([path, pathItem]) =>
      resolvePathItemObject(
        path,
        pathItem as OpenAPIV3_1.PathItemObject,
        document.components,
        document.servers,
      ),
    );
  }

  const groupedEndpoints: UiGroupedEndpointType[] =
    getGroupedEndpoints(endpoints);

  const navItems = getOpenApiSideNavItems(groupedEndpoints);

  const servers = document.servers?.map(server => server.url) || [];

  return { definition, endpoints, groupedEndpoints, navItems, servers };
};
