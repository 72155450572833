import { TableColumn } from '@backstage/core-components';
import { Banner } from '@lego/plugin-baseplate-admin-common';

export const columns: TableColumn<Banner>[] = [
  {
    title: 'Title',
    id: 'title',
    field: 'title',
  },
  {
    title: 'Created by',
    id: 'createdBy',
    field: 'created_by',
  },
  {
    title: 'Severity',
    id: 'severity',
    field: 'severity',
  },
  {
    title: 'Theme',
    id: 'theme',
    field: 'theme',
    emptyValue: '-',
  },
  {
    title: 'Link Text',
    id: 'link_text',
    field: 'link_text',
    emptyValue: '-',
  },
  {
    title: 'Link URL',
    id: 'link_url',
    field: 'link_url',
    emptyValue: '-',
  },
  {
    title: 'Pages',
    id: 'pages',
    field: 'pages',
    render: (banner: Banner) =>
      banner.pages?.length ? banner.pages.join(', ') : '-',
    emptyValue: '-',
  },
];
