import { CheckTick, Cross } from '@lego/klik-ui-icons';
import { icons } from '@lego/plugin-baseplate-core-components';
import { IconButton, Tooltip, Typography } from '@material-ui/core';
import Box from '@mui/material/Box';
import React from 'react';

interface ConfirmationActionsProps {
  handleOnSaveButtonClick: () => void;
  handleOnCancelButtonClick: () => void;
  disabled?: boolean;
}
function ConfirmationActions({
  handleOnSaveButtonClick,
  handleOnCancelButtonClick,
  disabled,
}: ConfirmationActionsProps) {
  return (
    <Box display="flex" flexDirection="row">
      <Tooltip title={disabled ? '' : <Typography>Save changes</Typography>}>
        <IconButton
          aria-label="save"
          style={{ color: icons.default }}
          onClick={handleOnSaveButtonClick}
          disabled={disabled}
        >
          <CheckTick />
        </IconButton>
      </Tooltip>
      <Tooltip title={disabled ? '' : <Typography>Cancel changes</Typography>}>
        <IconButton
          aria-label="cancel"
          style={{ color: icons.default }}
          onClick={handleOnCancelButtonClick}
          disabled={disabled}
        >
          <Cross />
        </IconButton>
      </Tooltip>
    </Box>
  );
}

export default ConfirmationActions;
