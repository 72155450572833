import { Entity, stringifyEntityRef } from '@backstage/catalog-model';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import {
  createVersionedContext,
  createVersionedValueMap,
  useVersionedContext,
} from '@backstage/version-bridge';
import React, { ReactNode, useEffect, useState } from 'react';
import useAsync from 'react-use/lib/useAsync';

const DEFAULT_PLUGIN_NAME = 'default-baseplate';

type SupportContextProps = {
  pluginEntity: Entity | undefined;
  setPluginName: React.Dispatch<React.SetStateAction<string>>;
  setPluginNameToDefault: () => void;
};

export const SupportContext = createVersionedContext<{
  1: SupportContextProps;
}>('support-context');

export const useSupportContext = (): SupportContextProps => {
  const context = useVersionedContext<{ 1: SupportContextProps }>(
    'support-context',
  );

  if (!context) {
    throw new Error('Entity context is not available.');
  }

  const contextValues = context.atVersion(1);
  if (!contextValues) {
    throw new Error('No context found for version 1.');
  }

  return contextValues;
};

export const SupportProvider = ({ children }: { children?: ReactNode }) => {
  const [pluginName, setPluginName] = useState(DEFAULT_PLUGIN_NAME);
  const [pluginEntity, setPluginEntity] = useState<Entity | undefined>(
    undefined,
  );
  const catalogApi = useApi(catalogApiRef);

  const setPluginNameToDefault = () => {
    setPluginName(DEFAULT_PLUGIN_NAME);
  };

  useAsync(async () => {
    if (!pluginName) return;
    const plugin = await catalogApi.getEntityByRef(
      stringifyEntityRef({
        kind: 'Component',
        namespace: 'default',
        name: pluginName,
      }),
    );
    setPluginEntity(plugin);
  }, [pluginName]);

  useEffect(() => {
    if (pluginName !== DEFAULT_PLUGIN_NAME) {
      setPluginEntity(undefined);
    }
  }, [pluginName]);

  const versionedValue = createVersionedValueMap({
    1: {
      pluginEntity,
      setPluginName,
      setPluginNameToDefault,
    },
  });
  return (
    <SupportContext.Provider value={versionedValue}>
      {children}
    </SupportContext.Provider>
  );
};
