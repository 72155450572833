export const toolCategorySlugify = (s: string) => {
  if (typeof s === 'string') {
    // Strip leading and trailing spaces before creating the slug
    return s
      .trim()
      .toLowerCase()
      .replace(/\./g, '')
      .replace(/ /g, '-')
      .replace(/\//g, '-')
      .replace(/\\/g, '-')
      .replace(/;/g, '-')
      .replace(/,/g, '-')
      .replace(/\(/g, '')
      .replace(/\)/g, '');
  }
  return 'invalid-name';
};
