import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function FileDatabase(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M25.25 19v-1.125C25.25 16.844 22.883 16 20 16c-2.906 0-5.25.844-5.25 1.875V19c0 1.055 2.344 1.875 5.25 1.875 2.883 0 5.25-.82 5.25-1.875Zm0 2.113a4.754 4.754 0 0 1-1.29.656c-1.054.375-2.46.586-3.96.586-1.523 0-2.93-.211-3.984-.586-.47-.164-.938-.398-1.266-.656v2.367c0 1.055 2.344 1.875 5.25 1.875 2.883 0 5.25-.82 5.25-1.875v-2.367Zm-9.234 5.142c-.47-.164-.938-.399-1.266-.656v1.992c0 1.054 2.344 1.875 5.25 1.875 2.883 0 5.25-.82 5.25-1.875v-1.992a4.752 4.752 0 0 1-1.29.656c-1.054.375-2.46.586-3.96.586-1.523 0-2.93-.211-3.984-.586Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 4C9.75 4 8 5.813 8 8v24c0 2.25 1.75 4 4 4h16c2.188 0 4-1.75 4-4V13.687a3.976 3.976 0 0 0-1.188-2.812l-5.687-5.688A3.976 3.976 0 0 0 22.312 4H12Zm17 28c0 .563-.5 1-1 1H12c-.563 0-1-.438-1-1V8c0-.5.438-1 1-1h10v5c0 1.125.875 2 2 2h5v18Z"
      />
    </SvgIcon>
  );
}
