import { BaseplateAnalyticsEventAttributes } from '@lego/plugin-baseplate-common';
import { ButtonProps } from '@material-ui/core/Button';
import React from 'react';
import { BaseplateButton } from '../Button';
import {
  toastFiveStarFeedback,
  FiveStarFeedbackToastifyProps,
} from './FiveStarFeedbackToastify';
import { FiveStarFeedbackProps } from './FiveStarFeedback';
import { useTheme } from '@material-ui/core';
import { BackstageTheme } from '@backstage/theme';
import { toast } from 'react-toastify';
import { ONE_MONTH_IN_MILLISECONDS } from './utils';

export interface FiveStarFeedbackButtonProps
  extends ButtonProps,
    FiveStarFeedbackProps,
    FiveStarFeedbackToastifyProps {
  noTrack?: boolean;
  trackingAttributes?: BaseplateAnalyticsEventAttributes;
  icon?: React.ReactNode;
}

export const FiveStarFeedbackButton: React.FC<FiveStarFeedbackButtonProps> = ({
  noTrack,
  onClick,
  onClicked,
  trackingAttributes,
  icon,
  feedbackSubject,
  onFeedbackSubmitted,
  onClosed,
  trackingIdentifier,
  doNotAskAgainForFeedbackWithSameTrackingIdentifierInMilliSeconds = ONE_MONTH_IN_MILLISECONDS,
  webhook,
  position,
  showToastDelay,
  autoCloseToastAfter = 20000,
  ...buttonProps
}) => {
  const toastId = React.useRef<number | string | null>(null);
  const theme = useTheme<BackstageTheme>();

  const toastOnClicked = () => {
    if (toastId.current) {
      toast.update(toastId.current, {
        autoClose: false,
        containerId: 'behind-side-panel',
      });
    }
    if (onClicked) {
      onClicked();
    }
  };

  const toastOnClosed = () => {
    if (toastId.current) {
      toast.dismiss(toastId.current);
    }
    if (onClosed) {
      onClosed();
    }
  };

  return (
    <BaseplateButton
      {...buttonProps}
      onClick={e => {
        toastId.current = toastFiveStarFeedback({
          onClicked: toastOnClicked,
          onClosed: toastOnClosed,
          feedbackSubject,
          trackingIdentifier,
          trackingAttributes,
          noTrack,
          webhook,
          autoCloseToastAfter,
          position,
          themePalette: theme.palette.type,
        })();
        if (onClick) {
          onClick(e);
        }
      }}
      trackingAttributes={trackingAttributes}
      icon={icon}
      noTrack={noTrack}
    />
  );
};
