import {
  CommunityLink,
  CommunityV2,
} from '@lego/plugin-baseplate-communities-common';
import { Button, Chip, Input } from '@lego/plugin-baseplate-core-components';
import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  Typography,
} from '@material-ui/core';
import { Form, useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { useManageCommunityStyles } from '../ManageCommunity/ManageCommunity.styles';
import { Loader } from '../Shared/Loader';
import { CreateCommunityLinks } from './CreateCommunityLinks';
import { CreateCommunityMaintainers } from './CreateCommunityMaintainers';
import { FormQuill } from './FormQuill';
import { EntityDropdown } from '@lego/plugin-baseplate-core-components';
import { stringifyEntityRef } from '@backstage/catalog-model';

export const CreateCommunityForm = ({
  formValues,
  setFormValues,
  hideWelcomeMessage = false,
  buttonTitle,
}: {
  formValues: CommunityV2;
  setFormValues: React.Dispatch<React.SetStateAction<CommunityV2>>;
  hideWelcomeMessage?: boolean;
  buttonTitle?: string;
}) => {
  const { setValues, errors, touched, handleBlur, handleChange } =
    useFormikContext<CommunityV2>();

  const styles = useManageCommunityStyles();
  const errorTextColor = '#fce8e9';
  const handleInputChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string } },
  ) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  useEffect(() => {
    const setValuesAsync = async () => {
      await setValues({
        ...formValues,
        ...(!formValues.githubRepoLink && { aboutSource: 'form' }),
      });
    };
    void setValuesAsync();
  }, [formValues, setValues, setFormValues]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    const aboutSource = isChecked ? 'github' : 'form';

    setFormValues({
      ...formValues,
      aboutSource,
    });
  };

  const handleDocsUpdate = (value: string) => {
    setFormValues({
      ...formValues,
      techdocsEntityRef: value,
    });
  };

  return (
    <div>
      <Loader displayed={false} />
      <Form>
        <div className={styles.mngCommFormContent}>
          <div className={styles.mngCommFormStepsContainer}>
            {!hideWelcomeMessage && (
              <div>
                <div className={styles.mngCommStepHead}>
                  <Typography variant="h4">
                    Create, Connect, Collaborate
                  </Typography>
                </div>
                <div className={styles.mngCommCaption}>
                  <Typography variant="subtitle2">
                    Welcome to communities!
                  </Typography>
                  <Typography variant="body1">
                    Here, you can establish a dedicated space for like-minded
                    individuals to collaborate, share, and grow together.
                    Whether you're looking to build a technical project,
                    organize discussions, or connect with peers, Baseplate
                    Communities provides the perfect starting point. Select the
                    platform that aligns with your community's goals and start
                    building a vibrant, interactive community today.
                  </Typography>
                </div>
              </div>
            )}
            <div className={styles.mngCommFormSection}>
              <Input
                label="Name"
                name="name"
                value={formValues.name}
                onChange={e => {
                  handleInputChange(e);
                  handleChange(e);
                }}
                onBlur={handleBlur}
                required
                placeholder="Community name"
                helperText="The name for your community that appears in the Baseplate UI."
                error={!!(errors.name && touched.name)}
                errorText={
                  errors.name && touched.name ? `* ${errors.name}` : undefined
                }
              />
              <Input
                required
                label="Summary"
                name="description"
                value={formValues.description}
                onChange={e => {
                  handleInputChange(e);
                  handleChange(e);
                }}
                onBlur={handleBlur}
                rows={3}
                placeholder="Short summary of the community"
                helperText="This short summary appears on your community card."
                error={!!(errors.description && touched.description)}
                errorText={
                  errors.description && touched.description
                    ? `* ${errors.description}`
                    : undefined
                }
              />
              <Input
                label="Tags"
                name="tags"
                value={formValues.tags}
                onChange={e => {
                  handleInputChange(e);
                  handleChange(e);
                }}
                onBlur={handleBlur}
                rows={3}
                placeholder="Tags of your community"
                helperText="Tags help users to filter and find your community. Separate tags by semicolons (;)."
                error={!!(errors.tags && touched.tags)}
                errorText={
                  errors.tags && touched.tags ? `* ${errors.tags}` : undefined
                }
              />
              <div style={{ display: 'flex', gap: 6 }}>
                {formValues.tags &&
                  formValues.tags.length > 0 &&
                  formValues.tags.split(';').map(tag => <Chip label={tag} />)}
              </div>
              <Input
                label="GitHub Link"
                name="githubRepoLink"
                value={formValues.githubRepoLink}
                onChange={e => {
                  handleInputChange(e);
                  handleChange(e);
                }}
                onBlur={handleBlur}
                error={!!(errors.githubRepoLink && touched.githubRepoLink)}
                errorText={
                  errors.githubRepoLink && touched.githubRepoLink
                    ? `* ${errors.githubRepoLink}`
                    : undefined
                }
                placeholder="GitHub Link"
                helperText="If your community is present on GitHub, provide the link here. Format: https://github.com/LEGO/my-community-matters"
              />
              {formValues.githubRepoLink &&
                formValues.githubRepoLink.length > 0 && (
                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={event => handleCheckboxChange(event)}
                          checked={formValues.aboutSource === 'github'}
                        />
                      }
                      label={`Use README.md file from linked repository as "About" content`}
                    />
                    <FormHelperText style={{ padding: '0 1rem' }}>
                      <Typography variant="body2" color="secondary">
                        The README file has to be located at the root of the
                        repository ({formValues.githubRepoLink}
                        /blob/main/README.md)
                      </Typography>
                    </FormHelperText>
                  </div>
                )}
              <Input
                label="Teams Channel Link"
                name="teamsChannelLink"
                value={formValues.teamsChannelLink}
                onChange={e => {
                  handleInputChange(e);
                  handleChange(e);
                }}
                onBlur={handleBlur}
                error={!!(errors.teamsChannelLink && touched.teamsChannelLink)}
                errorText={
                  errors.teamsChannelLink && touched.teamsChannelLink
                    ? `* ${errors.teamsChannelLink}`
                    : undefined
                }
                placeholder="Teams Channel Link"
                helperText="If your community is present on Teams, provide the link here. Format: https://teams.microsoft.com/l/channel/..."
              />
              <CreateCommunityLinks
                title="Additional Links"
                formValues={formValues}
                setFormValues={setFormValues}
                setValues={setValues}
              />
              <div style={{ color: errorTextColor }}>
                {errors.links && touched.links
                  ? `${(errors.links as CommunityLink[])
                      .map((link, index) => {
                        return link ? `* Link ${index + 1}: ${link.url}` : ``;
                      })
                      .join('\n')}`
                  : undefined}
              </div>
              {(formValues.aboutSource === 'form' ||
                formValues.githubRepoLink?.length === 0) && (
                <div>
                  <Typography component="label" variant="body2">
                    About
                  </Typography>
                  <FormQuill
                    formValues={formValues}
                    handleInputChange={e => {
                      handleInputChange(e);
                      handleChange(e);
                    }}
                  />
                  <FormHelperText style={{ padding: '0 1rem' }}>
                    <Typography variant="body2" color="secondary">
                      Describe what your community is about.
                    </Typography>
                  </FormHelperText>
                </div>
              )}
              <Grid container style={{ padding: 0 }}>
                <Grid item xs={12}>
                  <EntityDropdown
                    label="Community Documentation"
                    kind="Component"
                    type="documentation"
                    helperText="Add documentation to the community."
                    required={false}
                    handleChange={entity => {
                      const entityRef = stringifyEntityRef(entity);
                      handleDocsUpdate(entityRef);
                    }}
                    value={formValues.techdocsEntityRef}
                  />
                </Grid>
              </Grid>
              <CreateCommunityMaintainers
                formValues={formValues}
                setFormValues={setFormValues}
                setValues={setValues}
              />
              <div style={{ color: errorTextColor }}>
                {Array.isArray(errors.maintainerEntityRefs) &&
                touched.maintainerEntityRefs
                  ? errors.maintainerEntityRefs
                      .map(
                        (error, index) => `* Maintainer ${index + 1}: ${error}`,
                      )
                      .join('\n')
                  : `${
                      errors.maintainerEntityRefs === undefined ||
                      errors.maintainerEntityRefs === 'This is Required'
                        ? ''
                        : `* ${errors.maintainerEntityRefs as string}`
                    }`}
              </div>
            </div>
          </div>
          <div className={styles.mngCommFormBtnGrp}>
            <Button variant="primary" type="submit">
              {buttonTitle || 'Create'}
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};
