import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

export default function PersonLanguage(props: any) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <g>
        <path
          d="M22.3334 12.5117C22.3334 10.6436 20.8217 9.12792 18.9584 9.12792C17.0951 9.12792 15.5834 10.6436 15.5834 12.5117C15.5834 12.8031 15.6888 13.2542 15.942 13.8558C16.1881 14.4385 16.5373 15.0824 16.9381 15.7333C17.606 16.8189 18.3795 17.8669 18.9584 18.6142C19.5396 17.8669 20.3131 16.8189 20.9787 15.7333C21.3795 15.0824 21.7287 14.4385 21.9748 13.8558C22.2279 13.2542 22.3334 12.8031 22.3334 12.5117ZM23.4584 12.5117C23.4584 14.5654 20.7162 18.2218 19.5138 19.7304C19.2256 20.0899 18.6912 20.0899 18.4029 19.7304C17.2006 18.2218 14.4584 14.5654 14.4584 12.5117C14.4584 10.0209 16.474 8 18.9584 8C21.4427 8 23.4584 10.0209 23.4584 12.5117ZM19.7084 12.5117C19.7084 12.3122 19.6294 12.121 19.4887 11.98C19.3481 11.839 19.1573 11.7597 18.9584 11.7597C18.7595 11.7597 18.5687 11.839 18.428 11.98C18.2874 12.121 18.2084 12.3122 18.2084 12.5117C18.2084 12.7111 18.2874 12.9024 18.428 13.0434C18.5687 13.1844 18.7595 13.2636 18.9584 13.2636C19.1573 13.2636 19.3481 13.1844 19.4887 13.0434C19.6294 12.9024 19.7084 12.7111 19.7084 12.5117ZM17.0834 12.5117C17.0834 12.0131 17.2809 11.535 17.6325 11.1824C17.9842 10.8299 18.4611 10.6318 18.9584 10.6318C19.4557 10.6318 19.9326 10.8299 20.2842 11.1824C20.6358 11.535 20.8334 12.0131 20.8334 12.5117C20.8334 13.0102 20.6358 13.4884 20.2842 13.8409C19.9326 14.1935 19.4557 14.3915 18.9584 14.3915C18.4611 14.3915 17.9842 14.1935 17.6325 13.8409C17.2809 13.4884 17.0834 13.0102 17.0834 12.5117Z"
          strokeWidth="0.2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.4166 5C12.4166 4.1712 12.0874 3.37634 11.5013 2.79029C10.9153 2.20424 10.1204 1.875 9.29163 1.875C8.46282 1.875 7.66797 2.20424 7.08192 2.79029C6.49587 3.37634 6.16663 4.1712 6.16663 5C6.16663 5.8288 6.49587 6.62366 7.08192 7.20971C7.66797 7.79576 8.46282 8.125 9.29163 8.125C10.1204 8.125 10.9153 7.79576 11.5013 7.20971C12.0874 6.62366 12.4166 5.8288 12.4166 5ZM4.29163 5C4.29163 3.67392 4.81841 2.40215 5.75609 1.46447C6.69377 0.526784 7.96554 0 9.29163 0C10.6177 0 11.8895 0.526784 12.8272 1.46447C13.7648 2.40215 14.2916 3.67392 14.2916 5C14.2916 6.32608 13.7648 7.59785 12.8272 8.53553C11.8895 9.47322 10.6177 10 9.29163 10C7.96554 10 6.69377 9.47322 5.75609 8.53553C4.81841 7.59785 4.29163 6.32608 4.29163 5ZM14.7909 18.125H2.46741C2.81506 15.6523 4.94006 13.75 7.50647 13.75H11.0768C11.6421 13.75 12.186 13.8423 12.6942 14.0127C12.4943 13.3748 12.3832 12.6977 12.3754 11.9958C11.9547 11.9165 11.5206 11.875 11.0768 11.875H7.50647C3.65881 11.875 0.541626 14.9922 0.541626 18.8398C0.541626 19.4805 1.06116 20 1.70178 20H16.0645L14.7909 18.125ZM18.0416 18.8501C18.0416 18.8467 18.0416 18.8433 18.0416 18.8398L18.0415 18.7898C18.0257 18.7868 18.01 18.7837 17.9943 18.7805L18.0416 18.8501Z"
        />
      </g>
    </SvgIcon>
  );
}
