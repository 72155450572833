import { Link } from '@backstage/core-components';
import { baseplateStyles } from '@lego/plugin-baseplate-core-components';
import React from 'react';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { Typography } from '@material-ui/core';

type Props = {
  title: string;
  href: string;
  icon: React.ReactNode;
  external?: boolean;
  description: string;
};

const useStyles = baseplateStyles(token => ({
  container: {
    backgroundColor: token.semantic.background.neutral[1],
    borderRadius: token.primitive.borderRadius.default,
    border: `1px solid ${token.semantic.border.default}`,
    padding: '1rem',
    display: 'block',
    '&:hover': {
      borderColor: token.semantic.border.hover,
    },
    '& svg': {
      color: token.semantic.icon.primary,
    },
    minHeight: '10rem',
  },
  titleContainer: {
    display: 'flex',
    gap: '0.5rem',
    alignItems: 'center',
    marginBottom: '0.5rem',
    '& span': {
      flexGrow: 1,
    },
  },
}));

export const SupportLink = ({
  title,
  icon,
  href,
  external = true,
  description,
}: Props) => {
  const classes = useStyles();
  return (
    <Link className={classes.container} to={href} underline="none">
      <Typography className={classes.titleContainer} variant="subtitle2">
        {icon}
        <span>{title}</span>
        {external && <OpenInNewIcon />}
      </Typography>
      <Typography variant="body2" color="secondary">
        {description}
      </Typography>
    </Link>
  );
};
