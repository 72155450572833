import { DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';
import { CommunitiesApi } from './CommunitiesApi';
import { ApiUtils } from './APIUtils';
import {
  Community,
  ConnectUserToGithubRequest,
  CreateCommunityRequest,
  UpdateCommunityRequest,
  UpsertMembership,
} from '@lego/plugin-baseplate-communities-common';

export class CommunitiesClient implements CommunitiesApi {
  private readonly apiUtils: ApiUtils;

  public constructor(options: {
    discoveryApi: DiscoveryApi;
    fetchApi: FetchApi;
  }) {
    this.apiUtils = new ApiUtils(options.discoveryApi, options.fetchApi);
  }

  public async getCommunityById(
    communityId: string | number,
  ): Promise<Community> {
    return await this.apiUtils.get<Community>(`${communityId}`);
  }

  public async getAllCommunities(): Promise<Community[]> {
    return await this.apiUtils.get<Community[]>('');
  }

  public async manageCommunity(
    community: CreateCommunityRequest | UpdateCommunityRequest,
    communityId: string | number,
  ): Promise<Response> {
    if (communityId === 'new') {
      return await this.apiUtils.post<Response>('', community);
    }

    return await this.apiUtils.patch<Response>(`${communityId}`, community);
  }

  public async updateMembership(
    communityId: string | number,
    role: UpsertMembership,
  ): Promise<Response> {
    return await this.apiUtils.put<Response>(`${communityId}/membership`, role);
  }

  public async linkAccount(
    accounts: ConnectUserToGithubRequest,
  ): Promise<Response> {
    return await this.apiUtils.post<Response>(`user/connect`, accounts);
  }

  public async getLinkedAccount(user: string): Promise<Response> {
    return await this.apiUtils.get<Response>(`user/connect/${user}`);
  }

  public async leaveCommunity(communityId: string): Promise<Response> {
    return await this.apiUtils.delete<Response>(`${communityId}/membership`);
  }

  public async genericAdminRoute(endpoint: string): Promise<Response> {
    return await this.apiUtils.post<Response>(`admin/${endpoint}`);
  }
}
