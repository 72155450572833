import React from 'react';
import { Typography } from '@material-ui/core';
import { type ReactElement } from 'react';
import {
  CodeChip,
  baseplateStyles,
} from '@lego/plugin-baseplate-core-components';
import { getEndpointId } from './utils/getEndpointId';
import { OpenApiChip } from '../../OpenApiChip';
import { ParameterGroup } from '../parameters/ParameterGroup';
import { UiEndpointType } from '../../../types';
import { ExampleCodeBlock } from '../example/ExampleCodeBlock';

const useStyles = baseplateStyles(theme => ({
  wrapper: {
    padding: `2rem ${theme.primitive.dimensions.page.horizontalPadding}`,
    borderTop: `1px solid ${theme.semantic.border.default}`,
  },
  title: {
    wordBreak: 'break-word',
  },
  content: {
    position: 'relative',
    marginTop: '2rem',
  },
  twoColumns: {
    marginTop: '1rem',
    display: 'grid',
    gap: '1rem',
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: '1fr 1fr',
    },
  },
  parameters: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  code: {
    position: 'sticky',
    top: `calc(${theme.primitive.dimensions.nav.height} + 1rem)`,
    display: 'flex',
    flexDirection: 'column',
    height: 'fit-content',
    gap: '1rem',
  },
  requiredDescriptionText: {
    '& span': {
      color: theme.semantic.shape.error,
    },
  },
}));

const Path = ({ path }: { path: string }) => {
  const pathSections = path.split('/');

  return (
    <Typography variant="h3">
      {pathSections.map((section, index) =>
        section.includes('{') ? (
          <span key={section}>
            {index > 0 && '/'}
            <CodeChip children={section} />
          </span>
        ) : (
          <span key={section}>
            {index > 0 && '/'}
            {section}
          </span>
        ),
      )}
    </Typography>
  );
};

export function Endpoint({
  method,
  path,
  summary,
  parameters,
  responses,
  requestExamples,
  responseExamples,
}: UiEndpointType): ReactElement {
  const classes = useStyles();

  return (
    <div className={classes.wrapper} id={getEndpointId({ path, method })}>
      <header>
        <OpenApiChip method={method} />
        <Typography variant="h3" className={classes.title}>
          <Path path={path} />
        </Typography>
        <Typography variant="body1">{summary}</Typography>
      </header>
      <div className={classes.content}>
        {Object.keys(parameters).length > 0 && (
          <>
            <Typography variant="subtitle1">Parameters</Typography>
            <Typography
              variant="caption"
              color="secondary"
              className={classes.requiredDescriptionText}
            >
              <span>*</span> = required parameter
            </Typography>
          </>
        )}
        <div className={classes.twoColumns}>
          <div className={classes.parameters}>
            {parameters &&
              Object.entries(parameters).map(([groupName, params]) => (
                <ParameterGroup
                  name={`${groupName} parameters`}
                  parameters={params}
                  initialState={groupName !== 'header'}
                />
              ))}
            <Typography variant="subtitle1">Responses</Typography>
            {responses &&
              Object.entries(responses).map(([groupName, params]) => (
                <ParameterGroup name={groupName} parameters={params} />
              ))}
          </div>
          <div className={classes.code}>
            <ExampleCodeBlock
              examples={requestExamples}
              title="Example request body"
            />
            <ExampleCodeBlock
              title="Response examples"
              examples={responseExamples}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
