/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';
import {
  LangChainMessage,
  RequestParams,
  ResponseParams,
} from '@lego/plugin-baseplate-ai-common';
import { AiApi } from './AiApi';

/**
 * A frontend client for interacting with the AI Plugin on Baseplate.
 *
 * @public
 */
export class AiClient implements AiApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi: FetchApi;

  constructor(options: { discoveryApi: DiscoveryApi; fetchApi: FetchApi }) {
    this.discoveryApi = options.discoveryApi;
    this.fetchApi = options.fetchApi;
  }

  async getResponse(
    req: RequestParams,
    setLatestResponse: React.Dispatch<React.SetStateAction<string>>,
  ): Promise<ResponseParams> {
    return await this.post<ResponseParams>(
      'lang-chain',
      req,
      setLatestResponse,
    );
  }

  async getSummary(req: RequestParams): Promise<ResponseParams> {
    req.prompt = `Summarize in a maximum of 20 chars: ${req.prompt}`;
    return await this.post<ResponseParams>('lang-chain', req);
  }

  private async post<T>(
    path: string,
    body: any,
    setLatestResponse?: React.Dispatch<React.SetStateAction<string>>,
  ) {
    const baseUrl = `${await this.discoveryApi.getBaseUrl('ai')}/`;
    const url = new URL(path, baseUrl);

    const response = await this.fetchApi.fetch(url.toString(), {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.body) throw new Error('No response body');

    const reader = response.body.getReader();
    const decoder = new TextDecoder();
    let aiResponse = '';
    let langChainMessageResponse = {};

    // TODO: replace while true
    // eslint-disable-next-line no-constant-condition
    while (true) {
      const { done, value } = await reader.read();
      if (done) {
        langChainMessageResponse = {
          aiMessage: {
            role: 'AI',
            content: aiResponse,
          } as LangChainMessage,
        };
        break;
      }
      const token = decoder.decode(value);
      if (setLatestResponse)
        setLatestResponse((prev: string) => prev.concat(token));
      aiResponse = aiResponse.concat(token);
    }

    // TODO: fix & use this recursive method instead of the while true solution
    // const readData = async ({
    //   done,
    //   value,
    // }: ReadableStreamReadResult<Uint8Array>) => {
    //   if (done) {
    //     langChainMessageResponse = {
    //       aiMessage: {
    //         role: 'AI',
    //         content: aiResponse,
    //       } as LangChainMessage,
    //     };
    //     return;
    //   }

    //   const token = decoder.decode(value, { stream: true });
    //   setCurrentResponse((prev: string) => prev.concat(token));
    //   const nextChunk = await reader.read();
    //   readData(nextChunk);
    // };

    // const firstChunk = await reader.read();
    // readData(firstChunk);

    return langChainMessageResponse as Promise<T>;
  }
}
