import {
  Entity,
  entityKindSchemaValidator,
  KindValidator,
} from '@backstage/catalog-model';
import { JsonObject } from '@backstage/types';
import { Role } from '@lego/plugin-baseplate-eagraph-common';
import schema from './Employee.v1alpha.schema.json';

export interface Profile extends JsonObject {
  displayName: string;
  email: string;
  picture?: string;
}

export interface Connection extends JsonObject {
  entityRef: string;
  id: string;
  title: string;
}

export interface ProductConnection extends Connection, JsonObject {
  roles: Role[];
  tco: number;
  domain: Connection;
  subdomain: Connection;
}

export interface GeneralOverheadProductConnection
  extends Connection,
    JsonObject {
  tco: number;
}

export interface PeopleLeader extends JsonObject {
  entityRef: string;
  name: string;
  email: string;
  id: number;
}

export interface EmployeeSpec extends JsonObject {
  employeeId: number;
  jobCode: string;
  profile: Profile;
  level?: number;
  position?: string;
  peopleLeader?: PeopleLeader;
  generalOverheadProducts?: Connection[];
  products?: ProductConnection[];
  directReportsRefs?: string[];
  inDirectReportsRefs?: string[];
}

export interface EmployeeEntity extends Entity {
  apiVersion: 'scaffolder.legogroup.io/v1alpha1';
  kind: 'User';
  spec: EmployeeSpec;
}

const validator = entityKindSchemaValidator(schema);

export const employeeEntityV1alpha1Validator: KindValidator = {
  // eslint-disable-next-line @typescript-eslint/require-await
  async check(data: Entity) {
    return validator(data) === data;
  },
};

export const isEmployee = (entity: Entity): entity is EmployeeEntity => {
  return entity.kind === 'User' && validator(entity) === entity;
};
