import { stringifyEntityRef } from '@backstage/catalog-model';

export const GROUP_UNDEFINED = 'undefined';
/**
 * Entity reference to non-existent group.
 * Owner is a required property for entities so this can be used to reference
 * non-existend / missing owner information.
 */
export const GROUP_UNDEFINED_REF = stringifyEntityRef({
  name: GROUP_UNDEFINED,
  kind: 'Group',
  namespace: 'default',
});

export const FIVE_MIN_IN_MS = 60000 * 5;

export const ENTITY_IS_DUPLICATED = 'baseplate.legogroup.io/duplicates';

export const ENTITY_STATUS_AMMA_API_PROCESSING =
  'baseplate.legogroup.io/amma-api-processing';

export const ENTITY_MISSING_X_AUDIENCE = 'Missing x-audience';
export const ENTITY_MULTIPLE_OWNERS = 'Multiple owners';
export const ANNOTATION_ATLASSIAN_JIRA_KEY =
  'legogroup.atlassian.net/project-key';

export const LABEL_LIFECYCLE = 'lifecycle';

export const ANNOTATION_LEANIX_APPLICATION_ID =
  'lego.leanix.net/application-id';
export const ANNOTATION_LEANIX_APPLICATION_WSBNO =
  'baseplate.legogroup.io/application-wsbno';

export const ANNOTATION_RAW_NAME = 'baseplate.legogroup.io/raw-name';
export const ANNOTATION_RAW_NAMESPACE = 'baseplate.legogroup.io/raw-namespace';

export const ANNOTATION_LAST_INGESTION =
  'baseplate.legogroup.io/last-ingestion-timestamp';

export const ENTITY_STATUS_MISSING_OWNER =
  'baseplate.legogroup.io/owner-missing';

export const ENTITY_STATUS_DUPLICATED_ENTITY =
  'baseplate.legogroup.io/entity-duplicated';

export const ENTITY_STATUS_LIFECYCLE_MISSING =
  'baseplate.legogroup.io/lifecycle-missing';
export const ENTITY_STATUS_PRODUCT_MANAGER_MISSING =
  'baseplate.legogroup.io/productmanager-missing';
export const ANNOTATION_NO_OWNER = 'baseplate.legogroup.io/owner-warning';

// App config constants

/**
 * Key for accessing Azure configuration from app-config.yaml
 */
export const AZURE_PROVIDER_CONFIG_KEY = 'auth.providers.microsoft.development';
export const ENABLEMENT_LAYER_CONFIG_KEY = 'integrations.enablementLayer';

/**
 * Catalog.ts tasks Ids
 */

export const LEANIX_TASK_SCHEDULER_ID = 'leanix:refresh';
export const LEANIX_PRODUCTS_TASK_SCHEDULER_ID = 'leanix-products:refresh';
export const AMMA_TASK_SCHEDULER_ID = 'amma:refresh';

export const LEAD_ENGINEER = 'leadEngineer';

export const PRODUCT_DESIGNER = 'productDesigner';

export const PRODUCT_MANAGER = 'productManager';

export const PEOPLE_LEADER = 'peopleLeader';

export const COMPETENCY_LEAD = 'competencyLead';

export const RELATION_LEAD_ENGINEER = 'leadEngineer';

export const RELATION_LEAD_ENGINEER_OF = 'leadEngineerOf';

export const RELATION_PEOPLE_LEADER = 'peopleLeader';

export const RELATION_PEOPLE_LEADER_OF = 'peopleLeaderOf';

export const RELATION_PRODUCT_DESIGNER = 'productDesigner';

export const RELATION_PRODUCT_DESIGNER_OF = 'productDesignerOf';

export const RELATION_PRODUCT_MANAGER = 'productManager';

export const RELATION_PRODUCT_MANAGER_OF = 'productManagerOf';

export const RELATION_COMPETENCY_LEAD = 'competencyLead';

export const RELATION_COMPETENCY_LEAD_OF = 'competencyLeadOf';
