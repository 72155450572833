import { baseplateStyles } from '../../utils';

export const useFormStepStyles = baseplateStyles(token => ({
  formStepContainer: {
    display: 'flex',
    gridArea: 'pageHeader',
    alignItems: 'center',
    justifyContent: 'center',
    padding: `1.5rem ${token.primitive.dimensions.page.horizontalPadding}`,
    background: token.semantic.background.neutral[2],
    borderBottom: `1px solid ${token.semantic.border.default}`,
  },

  // Form Step Item
  formStep: {
    background: 'none',
    border: 0,
    textAlign: 'left',
    '&:not($disabled)': {
      cursor: 'pointer',

      '&:hover $indicator': {
        transform: 'scale(1.1)',
      },
    },
    '&$completed $statusLabel': {
      color: token.semantic.text.success,
    },
    '&$completed $indicator': {
      background: token.semantic.shape.success,
    },
    '&$completed $line': {
      background: token.semantic.shape.success,
    },

    '&$active $statusLabel': {
      color: token.semantic.text.info,
    },
    '&$active $indicator': {
      background: token.semantic.shape.info,
      // Add 20% opacity to the box shadow
      boxShadow: `0 0 0 4px ${token.semantic.shape.info}44`,
    },
    '&$active $line': {
      background: token.semantic.shape.neutral,
    },
    '&$active $line::after': {
      transform: 'scaleX(0.5)',
      background: token.semantic.shape.info,
    },
  },
  textContainer: {
    paddingRight: '2rem',
    marginTop: '1rem',
  },
  shapes: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    gap: '1rem',
  },
  indicator: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: token.semantic.icon.primary,
    borderRadius: '50%',
    background: token.semantic.shape.neutral,
    transition: 'transform 150ms ease-in-out, box-shadow 150ms ease-in-out',
    boxShadow: `0 0 0 0px ${token.semantic.shape.info}44`,
  },
  line: {
    flexGrow: 1,
    height: 3,
    background: token.semantic.shape.neutral,
    borderRadius: 2,
    overflow: 'hidden',
    '&::after': {
      content: '""',
      display: 'block',
      width: '100%',
      transform: 'scaleX(0)',
      transformOrigin: 'left',
      height: '100%',
      transition: 'transform 600ms ease-out',
    },
  },
  active: {},
  completed: {},
  disabled: {},
  statusLabel: {},
}));
