import { FormStepWrapper } from './FormStepWrapper';
import { FormStepItem } from './FormStepItem';

type Props = {
  Wrapper: typeof FormStepWrapper;
  Item: typeof FormStepItem;
};

const FormStep: Props = {
  Wrapper: FormStepWrapper,
  Item: FormStepItem,
};

export { FormStep };
