import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Bookmark(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M8 6.985a3 3 0 0 1 3-3V31.61l8.125-5.812c.5-.375 1.188-.375 1.75 0L29 31.61V6.985H11v-3h18c1.625 0 3 1.375 3 3v27.5c0 .563-.313 1.125-.813 1.375-.5.25-1.125.188-1.562-.125L20 28.86l-9.688 6.875a1.44 1.44 0 0 1-1.5.125c-.5-.25-.812-.812-.812-1.375v-27.5Z" />
    </SvgIcon>
  );
}
