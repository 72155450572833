import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ChevronUp(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M18.96 11.914a1.471 1.471 0 0 1 2.063 0l12.063 12c.562.625.562 1.563 0 2.125-.625.625-1.563.625-2.125 0L20.023 15.102 9.086 26.039c-.625.625-1.563.625-2.125 0a1.369 1.369 0 0 1 0-2.062l12-12.063Z" />
    </SvgIcon>
  );
}
