import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function HardDrive(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M8 9c-.563 0-1 .5-1 1v10.125c.313-.063.625-.125 1-.125h24c.313 0 .625.063 1 .125V10c0-.5-.5-1-1-1H8ZM7 24v6c0 .563.438 1 1 1h24c.5 0 1-.438 1-1v-6c0-.5-.5-1-1-1H8c-.563 0-1 .5-1 1Zm-3 0V10c0-2.188 1.75-4 4-4h24c2.188 0 4 1.813 4 4v20c0 2.25-1.813 4-4 4H8c-2.25 0-4-1.75-4-4v-6Zm17.5 3c0-.813.625-1.5 1.5-1.5.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5-.875 0-1.5-.625-1.5-1.5Zm7.5-1.5c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5Z" />
    </SvgIcon>
  );
}
