import { useApi } from '@backstage/core-plugin-api';
import {
  GeneralOverheadProduct,
  OpenPosition,
  Product,
  getExternalToggle,
} from '@lego/plugin-baseplate-people-to-product-common';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { peopleToProductApiRef } from '../api/PeopleToProductApi';
import { useOpenPositionSearchParams } from './useOpenPositionsSearchParams';
import {
  filterOpenPositionsWithProducts,
  filterOpenPositionsWithoutProducts,
  filterInternalOpenPositions,
} from './filters/filters';

type OpenPositionContextType = {
  openPositions: OpenPosition[];
  filteredOpenPositions: OpenPosition[];
  loading: boolean;
  error?: Error;
  addProductToOpenPosition: (
    openPosition: OpenPosition,
    product: Product,
  ) => Promise<void>;
  addGeneralOverheadProductToOpenPosition: (
    openPosition: OpenPosition,
    generalOverheadProduct: GeneralOverheadProduct,
  ) => Promise<void>;
  removeProductFromOpenPosition: (
    openPosition: OpenPosition,
    product: Product,
  ) => Promise<void>;
  removeGeneralOverheadProductFromOpenPosition: (
    openPosition: OpenPosition,
  ) => Promise<void>;
};

type Props = {
  children: React.ReactNode;
};

const OpenPositionContext = createContext<OpenPositionContextType>({
  openPositions: [],
  filteredOpenPositions: [],
  loading: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  addProductToOpenPosition: async () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  addGeneralOverheadProductToOpenPosition: async () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  removeProductFromOpenPosition: async () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  removeGeneralOverheadProductFromOpenPosition: async () => {},
});

export const useOpenPosition = () => useContext(OpenPositionContext);

export const OpenPositionProvider = ({ children }: Props) => {
  const peopleToProductApi = useApi(peopleToProductApiRef);

  const [openPositions, setOpenPositions] = useState<OpenPosition[]>([]);
  const [filteredOpenPositions, setFilteredOpenPositions] = useState<
    OpenPosition[]
  >([]);
  const { products: productFilter, searchParams } =
    useOpenPositionSearchParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error>();

  useEffect(() => {
    async function fetch() {
      setLoading(true);
      try {
        const fetchedOpenPositions =
          await peopleToProductApi.getOpenPositions();

        if (getExternalToggle()) {
          setOpenPositions(fetchedOpenPositions);
        } else {
          const internalOpenPositions = fetchedOpenPositions.filter(
            filterInternalOpenPositions,
          );
          setOpenPositions(internalOpenPositions);
        }
      } catch (e) {
        setError(e as Error);
      } finally {
        setLoading(false);
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetch();
  }, [peopleToProductApi]);

  useEffect(() => {
    function filterData() {
      if (productFilter === false) {
        setFilteredOpenPositions(
          openPositions.filter(filterOpenPositionsWithoutProducts),
        );
      } else if (productFilter === true) {
        setFilteredOpenPositions(
          openPositions.filter(filterOpenPositionsWithProducts),
        );
      } else {
        setFilteredOpenPositions(openPositions);
      }
    }

    filterData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openPositions, searchParams]);

  const addProductToOpenPosition = async (
    openPosition: OpenPosition,
    product: Product,
  ) => {
    await peopleToProductApi.addProductToOpenPosition(
      openPosition.positionId,
      product.id,
    );

    const updatedOpenPositions = openPositions.map(op => {
      if (op.positionId === openPosition.positionId) {
        return {
          ...op,
          generalOverheadProduct: null,
          product: product,
        };
      }
      return op;
    });

    setOpenPositions(updatedOpenPositions);
  };

  const addGeneralOverheadProductToOpenPosition = async (
    openPosition: OpenPosition,
    generalOverheadProduct: GeneralOverheadProduct,
  ) => {
    await peopleToProductApi.addGeneralOverheadProductToOpenPosition(
      openPosition.positionId,
      generalOverheadProduct.id,
    );

    const updatedOpenPositions = openPositions.map(op => {
      if (op.positionId === openPosition.positionId) {
        return {
          ...op,
          generalOverheadProduct: generalOverheadProduct,
          product: null,
        };
      }
      return op;
    });

    setOpenPositions(updatedOpenPositions);
  };

  const removeProductFromOpenPosition = async (
    openPosition: OpenPosition,
    product: Product,
  ) => {
    await peopleToProductApi.deleteProductFromOpenPosition(
      openPosition.positionId,
      product.id,
    );

    const updatedOpenPositions = openPositions.map(op => {
      if (op.positionId === openPosition.positionId) {
        return {
          ...op,
          product: null,
        };
      }
      return op;
    });

    setOpenPositions(updatedOpenPositions);
  };

  const removeGeneralOverheadProductFromOpenPosition = async (
    openPosition: OpenPosition,
  ) => {
    await peopleToProductApi.deleteGeneralOverheadProductFromOpenPosition(
      openPosition.positionId,
    );

    const updatedOpenPositions = openPositions.map(op => {
      if (op.positionId === openPosition.positionId) {
        return {
          ...op,
          generalOverheadProduct: null,
        };
      }
      return op;
    });

    setOpenPositions(updatedOpenPositions);
  };

  return (
    <OpenPositionContext.Provider
      value={{
        filteredOpenPositions,
        openPositions,
        loading,
        error,
        addProductToOpenPosition,
        addGeneralOverheadProductToOpenPosition,
        removeProductFromOpenPosition,
        removeGeneralOverheadProductFromOpenPosition,
      }}
    >
      {children}
    </OpenPositionContext.Provider>
  );
};
