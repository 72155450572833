import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Shuffle(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="m30.57 5.906 5 5c.563.625.563 1.563 0 2.125l-5 5c-.625.625-1.562.625-2.125 0-.625-.562-.625-1.5 0-2.125l2.438-2.437h-4.875c-.813 0-1.563.375-2 1l-2.875 3.875-1.875-2.5 2.312-3.125a5.478 5.478 0 0 1 4.438-2.25h4.875L28.445 8.03a1.369 1.369 0 0 1 0-2.062 1.369 1.369 0 0 1 2.063 0l.062-.063Zm-13.812 18.25-2.375 3.125c-1.063 1.375-2.688 2.188-4.375 2.188h-4.5c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5h4.5c.75 0 1.5-.313 2-1l2.875-3.813 1.875 2.5Zm11.687-2.25a1.472 1.472 0 0 1 2.063 0l5 5c.625.625.625 1.563 0 2.125l-5 5a1.37 1.37 0 0 1-2.063 0c-.625-.562-.625-1.5 0-2.125l2.438-2.437h-4.875a5.596 5.596 0 0 1-4.438-2.188L12.008 14.47c-.5-.625-1.25-1-2-1h-4.5c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5h4.5c1.687 0 3.312.812 4.375 2.25l9.625 12.75c.437.687 1.187 1 2 1h4.875l-2.438-2.438a1.369 1.369 0 0 1 0-2.062v-.063Z" />
    </SvgIcon>
  );
}
