import { Link } from '@backstage/core-components';
import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import React, { useState } from 'react';
import { AccountMenu } from './AccountMenu';
import { NavigationItem } from './Navigation';
import { useNavigationStyles } from './Navigation.styles';
import { NavigationLogo } from './NavigationLogo';

type Props = {
  items: NavigationItem[];
  label: string;
};

export const MobileNavigation = ({ items, label }: Props) => {
  const [open, setOpen] = useState(false);
  const classes = useNavigationStyles();

  return (
    <nav className={classes.navigation}>
      <NavigationLogo label={label} />
      <div className={classes.settings}>
        <AccountMenu />
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={() => setOpen(true)}
        >
          <MenuIcon />
        </IconButton>
      </div>
      <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
        <div
          role="presentation"
          onClick={() => setOpen(false)}
          onKeyDown={() => setOpen(false)}
        >
          <List>
            {items.map((item, index) => (
              <ListItem button key={index} component={Link} to={item.href}>
                {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
                <ListItemText primary={item.label} />
              </ListItem>
            ))}
          </List>
        </div>
      </Drawer>
    </nav>
  );
};
