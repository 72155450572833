import { IGitHubApi } from './GitHubApi';
import { DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';
import { ResponseError } from '@backstage/errors';
import { GithubPR } from '../../../templates-common/src';
export class GitHubClient implements IGitHubApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi: FetchApi;

  static create(discoveryApi: DiscoveryApi, fetchApi: FetchApi): GitHubClient {
    return new GitHubClient({ discoveryApi, fetchApi });
  }

  constructor(options: { discoveryApi: DiscoveryApi; fetchApi: FetchApi }) {
    this.discoveryApi = options.discoveryApi;
    this.fetchApi = options.fetchApi;
  }

  async createPullRequest(prData: GithubPR): Promise<GithubPR> {
    const baseUrl = await this.discoveryApi.getBaseUrl('templates');
    const response = await this.fetchApi.fetch(
      `${baseUrl}/create-pull-request`,
      {
        method: 'POST',
        body: JSON.stringify({ ...prData }),
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }
    return await response.json();
  }
}
