import { UiExampleType, UiParameterGroupType } from '../../../types';
import { formatParameterArray } from '../../utils/formatParameterArray';
import { ExampleResponseType } from '../v3.1/responses';

export const createResponseExamples = ({
  examples,
  parameters,
}: {
  examples?: ExampleResponseType;
  parameters?: UiParameterGroupType;
}): UiExampleType[] => {
  const responseExamples: UiExampleType[] = [];

  // Process examples objects
  if (examples) {
    console.log('Examples', examples);

    Object.entries(examples).forEach(([statusCode, exampleObjects]) => {
      Object.entries(exampleObjects).forEach(([key, { value }]) => {
        responseExamples.push({
          language: 'json',
          label: `${statusCode}: ${key}`,
          value: JSON.stringify(value, null, 2),
        });
      });
    });
  }

  if (parameters) {
    Object.entries(parameters).forEach(([statusCode, parameters]) => {
      const formattedParams = formatParameterArray(parameters);
      if (!formattedParams) return;
      responseExamples.push({
        label: statusCode,
        language: 'json',
        value: JSON.stringify(formattedParams, null, 2),
      });
    });
  }

  return responseExamples;
};
