import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Thumbtack(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="m16.406 7.25-.687 8.813c-.063.937-.5 1.75-1.313 2.25-1.437 1-2.562 2.562-3.062 4.375L11.28 23h7.25v-5.5c0-.813.625-1.5 1.5-1.5.813 0 1.5.688 1.5 1.5V23h7.188l-.063-.313c-.5-1.812-1.562-3.375-3.062-4.375a2.93 2.93 0 0 1-1.313-2.25l-.687-8.812V7h-7.188v.25ZM18.531 26h-8.5c-.625 0-1.25-.25-1.625-.75s-.5-1.125-.312-1.688L8.469 22c.625-2.625 2.187-4.75 4.25-6.188l.5-5.812.187-3h-1.875a1.73 1.73 0 0 1-.812-.188c-.438-.25-.688-.75-.688-1.312 0-.813.625-1.5 1.5-1.5h17c.813 0 1.5.688 1.5 1.5 0 .563-.312 1.063-.75 1.313-.25.125-.5.187-.75.187h-1.937l.187 3 .5 5.813c2.063 1.437 3.625 3.562 4.25 6.187l.375 1.563c.188.562.063 1.187-.312 1.687s-1 .75-1.625.75H21.53v8.5c0 .875-.687 1.5-1.5 1.5-.875 0-1.5-.625-1.5-1.5V26Z" />
    </SvgIcon>
  );
}
