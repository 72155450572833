import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function CircleMinus(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M20.031 7c-4.687 0-8.937 2.5-11.312 6.5-2.313 4.063-2.313 9 0 13 2.375 4.063 6.625 6.5 11.312 6.5 4.625 0 8.875-2.438 11.25-6.5 2.313-4 2.313-8.938 0-13-2.375-4-6.625-6.5-11.25-6.5Zm0 29c-5.75 0-11-3-13.875-8-2.875-4.938-2.875-11 0-16 2.875-4.938 8.125-8 13.875-8 5.688 0 10.938 3.063 13.813 8 2.875 5 2.875 11.063 0 16-2.875 5-8.125 8-13.813 8Zm-4.5-17.5h9c.813 0 1.5.688 1.5 1.5 0 .875-.687 1.5-1.5 1.5h-9c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5Z" />
    </SvgIcon>
  );
}
