import React from 'react';
import { InfoCard } from '@backstage/core-components';
import LinearProgressWithLabel from '../../components/LinearProgressWithLabel/LinearProgressWithLabel';
import Typography from '@mui/material/Typography';
import { Spinner } from '@lego/plugin-baseplate-core-components';
import { useAADGroupMembership } from '@lego/plugin-baseplate-access-control';
import {
  ADMIN_GROUP_ID,
  getReadableRole,
  getExternalToggle,
  EXTERNAL_JOB_CODE,
} from '@lego/plugin-baseplate-people-to-product-common';
import { peopleToProductApiRef } from '../../api/PeopleToProductApi';
import { useApi } from '@backstage/core-plugin-api';
import useAsync from 'react-use/lib/useAsync';
import { ExportToCSVButton } from '../../components';
import { openPositionsCsvHeaders, reportsCsvHeaders } from './csvHeaders';
import {
  filterInternalEmployeeRecords,
  filterInternalOpenPositionRecords,
} from '../../hooks/filters/filters';

export function OverviewCard() {
  const { isUserMember: isAdminUser } = useAADGroupMembership([ADMIN_GROUP_ID]);
  const peopleToProductApi = useApi(peopleToProductApiRef);

  let externalJobCodes = EXTERNAL_JOB_CODE;

  if (getExternalToggle()) externalJobCodes = '';

  const { loading, error, value } = useAsync(async () => {
    const reportsStatisticsPromise =
      peopleToProductApi.getReportsMappingStatistics(externalJobCodes);
    const openPositionsStatisticsPromise =
      peopleToProductApi.getOpenPositionsMappingStatistics(externalJobCodes);
    const nonDigitalProductManagersStatisticsPromise =
      isAdminUser &&
      peopleToProductApi.getNonDigitalProductManagersStatistics(
        externalJobCodes,
      );

    const [
      reportsStatistics,
      openPositionsStatistics,
      nonDigitalProductManagersStatistics,
    ] = await Promise.all([
      reportsStatisticsPromise,
      openPositionsStatisticsPromise,
      isAdminUser
        ? nonDigitalProductManagersStatisticsPromise
        : Promise.resolve(null),
    ]);

    return {
      reportsStatistics,
      openPositionsStatistics,
      nonDigitalProductManagersStatistics,
    };
  }, [isAdminUser]);

  const handleOnExportReportsClick = async (): Promise<string[][]> => {
    let reportsMapping = await peopleToProductApi.getReportsMappingForCsv();

    if (!getExternalToggle())
      reportsMapping = reportsMapping.filter(filterInternalEmployeeRecords);

    return reportsMapping.map(report => [
      report.id.toString(),
      report.fullName,
      report.email,
      report.positionId.toString(),
      report.position,
      report.peopleLeaderId.toString(),
      report.peopleLeaderName,
      report.organizationalUnitId.toString(),
      report.productId ?? 'NOT_MAPPED',
      `"${report.productName ?? ''}"`,
      report.tco?.toString() ?? '',
      report.role ? getReadableRole(report.role.toString()) : '',
    ]);
  };

  const handleOnExportOpenPositionsClick = async (): Promise<string[][]> => {
    let openPositionsMapping =
      await peopleToProductApi.getOpenPositionsMappingForCsv();

    if (!getExternalToggle())
      openPositionsMapping = openPositionsMapping.filter(
        filterInternalOpenPositionRecords,
      );

    return openPositionsMapping.map(openPosition => [
      openPosition.positionId.toString(),
      `"${openPosition.position}"`,
      openPosition.status === 'FILLED' ? 'Filled' : 'Open',
      openPosition.employeeName ?? '',
      openPosition.startDate ?? '',
      openPosition.peopleLeaderId.toString(),
      openPosition.peopleLeaderName,
      openPosition.productId ?? 'NOT_MAPPED',
      `"${openPosition.productName ?? ''}"`,
    ]);
  };

  return (
    <InfoCard
      title="Overview"
      variant="fullHeight"
      actions={
        <>
          {!loading && !error && value && (
            <>
              <ExportToCSVButton
                headers={reportsCsvHeaders}
                filename={`reports_${new Date().toISOString()}.csv`}
                buttonText="Export reports"
                fetchCSVData={handleOnExportReportsClick}
              />
              {value?.openPositionsStatistics.total > 0 && (
                <ExportToCSVButton
                  headers={openPositionsCsvHeaders}
                  filename={`open_positions_${new Date().toISOString()}.csv`}
                  buttonText="Export open positions"
                  fetchCSVData={handleOnExportOpenPositionsClick}
                />
              )}
            </>
          )}
        </>
      }
    >
      {loading ? (
        <Spinner />
      ) : (
        <>
          {!error && value && (
            <>
              <Typography variant="h6">
                {value.reportsStatistics.mapped} of{' '}
                {value.reportsStatistics.total} reports mapped to at least one
                product
              </Typography>
              <LinearProgressWithLabel
                value={
                  (value.reportsStatistics.mapped /
                    value.reportsStatistics.total) *
                  100
                }
              />
              {value.openPositionsStatistics.total > 0 && (
                <>
                  <br />
                  <Typography variant="h6">
                    {value.openPositionsStatistics.mapped} of{' '}
                    {value.openPositionsStatistics.total} open positions mapped
                    to at least one product
                  </Typography>
                  <LinearProgressWithLabel
                    value={
                      (value.openPositionsStatistics.mapped /
                        value.openPositionsStatistics.total) *
                      100
                    }
                  />
                </>
              )}
              {isAdminUser && value.nonDigitalProductManagersStatistics && (
                <>
                  <br />
                  <Typography variant="h6">
                    {value.nonDigitalProductManagersStatistics.mapped} of{' '}
                    {value.nonDigitalProductManagersStatistics.total}{' '}
                    non-digital product managers mapped to at least one product
                  </Typography>
                  <LinearProgressWithLabel
                    value={
                      (value.nonDigitalProductManagersStatistics.mapped /
                        value.nonDigitalProductManagersStatistics.total) *
                      100
                    }
                  />
                </>
              )}
            </>
          )}
          {error && (
            <Typography>
              An error occurred when loading the statistics
            </Typography>
          )}
        </>
      )}
    </InfoCard>
  );
}
