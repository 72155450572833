import { baseplateStyles } from '@lego/plugin-baseplate-core-components';

export const useEntityLayoutStyles = baseplateStyles(theme => ({
  tabsContainer: {
    gridArea: 'pageSubheader',
    '& > div': {
      padding: `0 ${theme.primitive.dimensions.page.horizontalPadding}`,
      borderBottom: `1px solid ${theme.semantic.border.default}`,
    },
    '& [aria-label="tabs"]': {
      // Tab
      '& > a': {
        textTransform: 'initial',
        padding: '0.5rem 1rem',
        textDecoration: 'none',
        '&:active': {
          textDecoration: 'none',
        },
      },
      // Selected tab indicators
      '&+span': {
        backgroundColor: theme.semantic.text.secondary,
      },
    },
  },
}));
