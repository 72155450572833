import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function FileInvoiceDollar(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M29 32V14h-5c-1.125 0-2-.875-2-2V7H12c-.563 0-1 .5-1 1v24c0 .563.438 1 1 1h16c.5 0 1-.438 1-1ZM8 8c0-2.188 1.75-4 4-4h10.313c1.062 0 2.062.438 2.812 1.188l5.688 5.687c.75.75 1.187 1.75 1.187 2.813V32c0 2.25-1.813 4-4 4H12c-2.25 0-4-1.75-4-4V8Zm12 9.5c.688 0 1.25.563 1.25 1.25v.625c.438.063.875.188 1.25.25.688.188 1.125.875.938 1.5a1.198 1.198 0 0 1-1.5.938c-.688-.125-1.375-.25-2-.25-.5-.063-1 .062-1.375.25-.313.187-.313.312-.313.375 0 .062.063.125.25.25.375.187 1 .375 1.813.562.687.188 1.625.438 2.312.875.75.438 1.563 1.188 1.563 2.438.062 1.312-.688 2.187-1.563 2.625-.438.25-.938.437-1.438.5v.625c0 .687-.562 1.25-1.25 1.25-.687 0-1.25-.563-1.25-1.25v-.688c-.625-.125-1.187-.313-1.687-.438-.125-.062-.25-.062-.375-.125-.688-.187-1.063-.875-.875-1.562.188-.688.938-1.063 1.563-.813.187 0 .312.063.437.125.875.25 1.563.438 2.25.438a2.588 2.588 0 0 0 1.438-.25c.25-.125.312-.25.312-.438 0 0 0-.125-.313-.312-.437-.188-1-.375-1.812-.563l-.063-.062c-.687-.188-1.562-.438-2.25-.75-.75-.438-1.562-1.188-1.562-2.438-.063-1.312.813-2.125 1.625-2.562.438-.25.875-.375 1.375-.5v-.625c0-.688.563-1.25 1.25-1.25ZM14 10h5c.5 0 1 .5 1 1 0 .563-.5 1-1 1h-5c-.563 0-1-.438-1-1 0-.5.438-1 1-1Zm0 4h5c.5 0 1 .5 1 1 0 .563-.5 1-1 1h-5c-.563 0-1-.438-1-1 0-.5.438-1 1-1Z" />
    </SvgIcon>
  );
}
