import { createPlugin } from '@backstage/core-plugin-api';
import {
  createTechDocsAddonExtension,
  TechDocsAddonLocations,
} from '@backstage/plugin-techdocs-react';
import { ColorLinksAddon } from './ColorLinks';
import { CodeblockAddon } from './Codeblock';
import { MagicLinksAddons } from './MagicLinks';
import { GlobalStylesAddon } from './TechdocsBaseplateTheme';
import { TableOfContentActionsAddon } from './TableOfContentActions';
import { ContentComponentsAddon } from './ContentBaseplateComponents';

export const baseplatePluginsTechdocsAddonsPlugin = createPlugin({
  id: 'plugin-baseplate-techdocs-addons',
});

export const ColorLinks = baseplatePluginsTechdocsAddonsPlugin.provide(
  createTechDocsAddonExtension({
    name: 'ColorLinks',
    location: TechDocsAddonLocations.Content,
    component: ColorLinksAddon,
  }),
);
export const MagicLinks = baseplatePluginsTechdocsAddonsPlugin.provide(
  createTechDocsAddonExtension({
    name: 'MagicLinks',
    location: TechDocsAddonLocations.Content,
    component: MagicLinksAddons,
  }),
);

export const Codeblock = baseplatePluginsTechdocsAddonsPlugin.provide(
  createTechDocsAddonExtension({
    name: 'Codeblock',
    location: TechDocsAddonLocations.Content,
    component: CodeblockAddon,
  }),
);

export const GlobalStyles = baseplatePluginsTechdocsAddonsPlugin.provide(
  createTechDocsAddonExtension({
    name: 'GlobalStyles',
    location: TechDocsAddonLocations.Content,
    component: GlobalStylesAddon,
  }),
);

export const ContentComponents = baseplatePluginsTechdocsAddonsPlugin.provide(
  createTechDocsAddonExtension({
    name: 'ContentComponents',
    location: TechDocsAddonLocations.Content,
    component: ContentComponentsAddon,
  }),
);

export const TableOfContentActions =
  baseplatePluginsTechdocsAddonsPlugin.provide(
    createTechDocsAddonExtension({
      name: 'TableOfContentActions',
      location: TechDocsAddonLocations.SecondarySidebar,
      component: TableOfContentActionsAddon,
    }),
  );
