import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Lock(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M15 12v4h10v-4c0-2.75-2.25-5-5-5-2.813 0-5 2.25-5 5Zm-3 4v-4c0-4.375 3.563-8 8-8 4.375 0 8 3.625 8 8v4h2c2.188 0 4 1.813 4 4v12c0 2.25-1.813 4-4 4H10c-2.25 0-4-1.75-4-4V20c0-2.188 1.75-4 4-4h2Zm-3 4v12c0 .563.438 1 1 1h20c.5 0 1-.438 1-1V20c0-.5-.5-1-1-1H10c-.563 0-1 .5-1 1Z" />
    </SvgIcon>
  );
}
