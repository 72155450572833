import { Grid } from '@material-ui/core';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';
import React from 'react';
import FourStudSpinner from '../../assets/FourStudSpinner.svg';
import SingleStudSpinner from '../../assets/SingleStudSpinner.svg';
import { baseplateStyles } from '../../utils';
import { Icon } from '../icon';

type SpinnerProps = {
  /** Sizing of the spinner.
   * Small: Single Stud Spinner
   * Large: Four Stud Spinner
   */
  size?: 'small' | 'large';
  align?: 'left' | 'center' | 'right';
  type?: 'lego' | 'circle';
  width?: number;
};

const StyledBox = styled(Box, {
  shouldForwardProp: prop => prop !== 'align',
})<SpinnerProps>(({ align }) => ({
  display: 'flex',
  justifyContent: align,
}));

const useStyles = baseplateStyles(() => ({
  loadingIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    animation: '$spin 1s linear infinite',
  },
  // Animation
  '@keyframes spin': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(360deg)',
    },
  },
}));

export const Spinner = (props: SpinnerProps) => {
  const {
    type = 'lego',
    size = 'large',
    width = 150,
    align = 'center',
  } = props;
  const classes = useStyles();

  return type === 'lego' ? (
    <Grid>
      <StyledBox align={align}>
        <Box width={width}>
          <img
            alt="loading"
            src={size === 'large' ? FourStudSpinner : SingleStudSpinner}
          />
        </Box>
      </StyledBox>
    </Grid>
  ) : (
    <span className={classes.loadingIcon}>
      <Icon icon="spinnerThird" />
    </span>
  );
};
