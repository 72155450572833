import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { StatusMessage, UserSubscriptionDetails } from '../types';
import { LogLevel } from '../types/Logging';
import { logToElastic } from '../util/logs';
import { useLoggingData } from './useLoggingData';
import { useConfig } from './useConfig';

export const useUserSubscriptionDetails = () => {
  const { BACKEND_BASE_URL, azureAccessToken } = useConfig();
  const { email, environment } = useLoggingData();

  const {
    data = { githubHandle: '', statusMessage: '' },
    error,
    isLoading: loading,
    refetch,
  } = useQuery<UserSubscriptionDetails, Error>(
    ['user-subscription-details', email, azureAccessToken, BACKEND_BASE_URL],
    async () => {
      return axios
        .get<UserSubscriptionDetails>(
          `${BACKEND_BASE_URL}/v1/copilot/user-subscription-details?email=${encodeURIComponent(
            email,
          )}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${azureAccessToken}`,
            },
          },
        )
        .then(response => response.data)
        .catch(async catchError => {
          await logToElastic(
            `Error fetching user subscription details: ${catchError}`,
            LogLevel.ERROR,
            'useUserSubscriptionDetails.ts',
            email,
            environment,
            BACKEND_BASE_URL,
            azureAccessToken,
          );
          return {
            githubHandle: '',
            statusMessage: StatusMessage.ERROR,
          };
        });
    },
    { enabled: !!email && !!azureAccessToken && !!BACKEND_BASE_URL },
  );

  const { githubHandle, statusMessage } = data;

  return { email, githubHandle, statusMessage, error, loading, refetch };
};
