import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function LayerArrowUp(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M20 15c.5 0 1.063.125 1.5.375l13.125 5.563C35.438 21.313 36 22.125 36 23c0 .938-.563 1.75-1.375 2.125L21.5 30.688c-.438.25-1 .312-1.5.312-.563 0-1.063-.063-1.563-.313L5.313 25.125C4.5 24.75 4 23.937 4 23c0-.875.5-1.688 1.313-2.063l13.125-5.562c.5-.25 1-.375 1.562-.375Zm-.375 3.125L8.125 23l11.5 4.938c.125.062.25.062.375.062s.25 0 .313-.063L31.813 23l-11.5-4.875A.477.477 0 0 0 20 18c-.125 0-.25.063-.375.125Zm-12.313 10 3.813 1.625-3 1.25 11.5 4.938c.125.062.25.062.375.062s.25 0 .313-.063L31.813 31l-2.938-1.25 3.75-1.625 2 .813C35.438 29.313 36 30.125 36 31c0 .938-.563 1.75-1.375 2.125L21.5 38.688c-.438.25-1 .312-1.5.312-.563 0-1.063-.063-1.563-.313L5.313 33.126C4.5 32.75 4 31.937 4 31c0-.875.5-1.688 1.313-2.063l2-.812ZM27.994 6.1h-.016l-2.434 2.442c-.496.51-1.59.533-2.123 0a1.476 1.476 0 0 1 0-2.066l4.994-5.007c.625-.625 1.561-.625 2.123 0l4.994 5.007a1.373 1.373 0 0 1 0 2.066c-.562.626-1.498.626-2.123 0L30.99 6.116c0 3.725.008 2.662.008 6.386a1.498 1.498 0 0 1-2.996 0L27.994 6.1Z" />
    </SvgIcon>
  );
}
