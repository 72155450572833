import { Typography } from '@material-ui/core';
import React from 'react';
import { type ReactElement } from 'react';
import { baseplateStyles } from '../../../utils';

export interface ChartSummaryProps {
  value: number;
  unit: string;
}

const useStyles = baseplateStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'baseline',
    gap: '0.5rem',
  },
}));

export function ChartSummary({ value, unit }: ChartSummaryProps): ReactElement {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant="h3">{value}</Typography>
      <Typography variant="subtitle1" color="secondary">
        {unit}
      </Typography>
    </div>
  );
}
