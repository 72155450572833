import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function SackDollar(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="m17.625 13.5-.875.563C13.375 16.25 7 21.313 7 30c0 1.688 1.313 3 3 3h20a3 3 0 0 0 3-3c0-8.688-6.438-13.75-9.813-15.938l-.875-.562h-4.687Zm4.25-3 .438-.625L24.25 7h-8.563l1.938 2.875.5.625h3.75Zm-8.5 2.25c.688-.438 1.25-.875 1.813-1.188l-1.75-2.5-1.876-2.687c-.624-1 .063-2.375 1.25-2.375h14.313c1.188 0 1.938 1.375 1.25 2.375L26.5 9.063l-1.688 2.5c.5.312 1.063.75 1.75 1.187C30.375 15.625 36 21.188 36 30c0 3.313-2.688 6-6 6H10c-3.313 0-6-2.688-6-6 0-8.813 5.563-14.375 9.375-17.25Zm7.875 4.75v.875c.438.063.875.188 1.313.313.625.187 1.062.875.875 1.5-.188.687-.875 1.062-1.563.937-.688-.188-1.313-.375-1.938-.375-.5 0-1 .125-1.312.313-.313.187-.438.375-.438.625 0 .062.063.187.375.375.375.25.938.437 1.75.687h.063c.688.188 1.563.5 2.313.938.812.5 1.5 1.312 1.5 2.625.062 1.312-.625 2.25-1.5 2.812-.5.25-1 .438-1.5.563v.812c0 .688-.563 1.25-1.25 1.25-.688 0-1.25-.563-1.25-1.25v-.875a58.354 58.354 0 0 1-1.75-.563c-.125-.062-.25-.062-.375-.125-.625-.187-1-.937-.75-1.562.187-.688.874-1 1.562-.813.125.063.313.125.438.188.875.25 1.5.5 2.187.5.563 0 1.063-.063 1.375-.313.25-.125.375-.312.313-.625 0-.187 0-.312-.313-.5-.438-.25-1-.437-1.75-.687H19.5c-.688-.25-1.563-.5-2.25-.875-.75-.5-1.5-1.313-1.5-2.563-.063-1.312.688-2.25 1.563-2.75a5.72 5.72 0 0 1 1.437-.562V17.5c0-.688.563-1.25 1.25-1.25.688 0 1.25.563 1.25 1.25Z" />
    </SvgIcon>
  );
}
