import {
  MessageObject,
  OperationObject,
} from '@asyncapi/parser/esm/spec-types/v2';
import { UiMessageType } from '../../../types';
import { parseMessage } from './message';

const getMessagesFromOperation = (
  operation: OperationObject,
): MessageObject[] => {
  const message = operation?.message;
  if (!message) return [];

  if ('oneOf' in message) {
    return message.oneOf as MessageObject[];
  }
  return [message as MessageObject];
};

export const parseOperation = (
  subscribe: OperationObject | undefined,
  publish: OperationObject | undefined,
): UiMessageType[] => {
  const messages: MessageObject[] = [
    ...(subscribe ? getMessagesFromOperation(subscribe) : []),
    ...(publish ? getMessagesFromOperation(publish) : []),
  ];

  return messages.map((message, index) =>
    parseMessage(message, index, messages.length),
  );
};
