import { BackstageOverrides } from '@backstage/core-components';
import { DesignTokens } from '../types';

export const list = (
  tokens: DesignTokens,
): Pick<BackstageOverrides, 'MuiListItem' | 'MuiListItemIcon'> => ({
  MuiListItem: {
    root: {
      color: tokens.semantic.interactive.default,
    },
  },
  MuiListItemIcon: {
    root: {
      color: tokens.semantic.icon.primary,
    },
  },
});
