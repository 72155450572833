import { Entity } from '@backstage/catalog-model';
import { Link } from '@backstage/core-components';
import { FavoriteEntity } from '@backstage/plugin-catalog-react';
import { AvailableKinds } from '@lego/plugin-baseplate-common';
import { Typography } from '@material-ui/core';
import { truncate } from 'lodash';
import React from 'react';
import { useEntityLinkStyles } from './EntityLink.styles';
import { isHighlighted } from './isHighlighted';
import { EntityLinkVariant } from './types';
import { EntityIcon } from './entity-icon';

interface Props {
  entity: Entity;
  variant: EntityLinkVariant;
  url: string;
  showFavoriteButton?: boolean;
  children: React.ReactNode;
}

export const EntityLinkWrapper = ({
  entity,
  url,
  variant,
  showFavoriteButton,
  children,
}: Props) => {
  const classes = useEntityLinkStyles();

  switch (variant) {
    case 'thumbnail':
      return (
        <Link
          to={url}
          className={[
            classes.wrapper,
            classes[variant],
            isHighlighted(entity) ? classes.highlight : '',
          ].join(' ')}
        >
          <div className={classes.header}>
            <div className={classes.iconWrapper}>
              {entity.spec?.image ? (
                <img
                  alt={entity.metadata.title}
                  src={entity.spec.image as string}
                  className={classes.icon}
                />
              ) : (
                <EntityIcon
                  size="md"
                  kind={entity.kind as AvailableKinds}
                  entity={entity}
                />
              )}
            </div>
            <Typography
              color="textPrimary"
              variant="subtitle2"
              className={classes.title}
            >
              {truncate(entity.metadata.title ?? entity.metadata.name, {
                length: 80,
              })}
            </Typography>
            {showFavoriteButton && (
              // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
              <div onClick={e => e.preventDefault()}>
                <FavoriteEntity
                  entity={entity}
                  className={classes.starGridItem}
                />
              </div>
            )}
          </div>
          {children}
        </Link>
      );
    case 'inline-link':
      return (
        <Link
          to={url}
          className={[classes.wrapper, classes[variant]].join(' ')}
        >
          {showFavoriteButton && (
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
            <div onClick={e => e.preventDefault()}>
              <FavoriteEntity
                entity={entity}
                className={classes.starGridItem}
              />
            </div>
          )}
          <EntityIcon size="sm" kind={entity.kind as any} entity={entity} />
          <div>
            <Typography variant="body1">
              {truncate(entity.metadata.title ?? entity.metadata.name, {
                length: 80,
              })}
            </Typography>
            {children}
          </div>
        </Link>
      );

    // Link
    default:
      return (
        <Link
          to={url}
          className={[classes.wrapper, classes[variant]].join(' ')}
        >
          <div className={classes.linkBody}>
            <div className={classes.linkIcon}>
              <EntityIcon size="md" kind={entity.kind as any} entity={entity} />
            </div>
            <div>
              <Typography variant="body1">
                {truncate(entity.metadata.title ?? entity.metadata.name, {
                  length: 80,
                })}
              </Typography>
              {children}
            </div>
          </div>
          <div>
            {showFavoriteButton && (
              // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
              <div onClick={e => e.preventDefault()}>
                <FavoriteEntity
                  entity={entity}
                  className={classes.starGridItem}
                  size="small"
                />
              </div>
            )}
          </div>
        </Link>
      );
  }
};
