import { ToolEntity } from '@lego/plugin-baseplate-common';
import { Typography } from '@material-ui/core';
import React from 'react';
import { HighlightedToolCard } from '../components/HighlightedToolCard';
import {
  EntityLink,
  OverviewPageCardGrid,
  TooltipIcon,
  baseplateStyles,
} from '@lego/plugin-baseplate-core-components';
import { Content, Page } from '@backstage/core-components';
import { pavedPathTools } from '../utils/pavedPathTools';
import { sortTools } from '../utils/sortTools';

type Props = {
  title: string;
  description: string;
  entities: ToolEntity[];
};

const useStyles = baseplateStyles(theme => ({
  header: {
    padding: `3rem ${theme.primitive.dimensions.page.horizontalPadding}`,
    background: theme.semantic.background.neutral[1],
  },
  pavedPathContainer: {
    marginTop: '1.5rem',
    padding: '1rem',
    borderRadius: theme.primitive.borderRadius.default,
    backgroundColor: theme.semantic.background.neutral[2],
  },
  otherContainer: {
    padding: `3rem ${theme.primitive.dimensions.page.horizontalPadding}`,
  },
}));

export const ToolsCategoryPage = ({ title, description, entities }: Props) => {
  const classes = useStyles();
  const [highlightedTools, otherTools] = React.useMemo(() => {
    const highlighted: ToolEntity[] = [];
    let other: ToolEntity[] = [];

    entities.forEach(entity => {
      if (pavedPathTools.includes(entity.metadata.name)) {
        highlighted.push(entity);
      } else {
        other.push(entity);
      }
    });

    other = sortTools(other);

    return [highlighted, other];
  }, [entities]);

  return (
    <Page themeId="tool">
      <Content noPadding>
        <header className={classes.header}>
          <Typography variant="h2">{title}</Typography>
          <Typography variant="body1">{description}</Typography>
          <div className={classes.pavedPathContainer}>
            <Typography variant="subtitle2">
              LEGO recommended tools{' '}
              <TooltipIcon content='"Paved path" tools are the preferred choice for tasks within the LEGO Group.' />
            </Typography>
            <br />
            <OverviewPageCardGrid>
              {highlightedTools.map(tool => (
                <HighlightedToolCard
                  key={tool.metadata.name}
                  tool={tool}
                  pavedPath
                />
              ))}
            </OverviewPageCardGrid>
          </div>
        </header>
        <div className={classes.otherContainer}>
          <div>
            <Typography variant="subtitle2">
              Other tools in this category{' '}
              <TooltipIcon content="Even though these tools aren't fully supported, you can still use them at your own risk." />
            </Typography>
            <br />
          </div>
          <OverviewPageCardGrid>
            {otherTools.map(tool => (
              <EntityLink
                key={tool.metadata.name}
                variant="thumbnail"
                entity={tool}
              />
            ))}
          </OverviewPageCardGrid>
        </div>
      </Content>
    </Page>
  );
};
