// In case of a user entity, we want to display the user's name instead of the entity's title.

import { Entity, isUserEntity } from '@backstage/catalog-model';

// This will still make handleChange true, as it returns the employee id, and the user's name.
export const getTitle = (entity: Entity) => {
  if (isUserEntity(entity) && entity.spec.profile?.displayName) {
    return `${entity.metadata.name} - ${entity.spec.profile.displayName}`;
  }
  return `${entity.metadata.name} - ${entity.metadata.title ?? 'untitled'}`;
};

export const getName = (option: Entity) => option.metadata.name;
