import { useAnalytics } from '@backstage/core-plugin-api';
import { BackstageTheme } from '@backstage/theme';
import { BaseplateAnalyticsEventAttributes } from '@lego/plugin-baseplate-common';
import { Drawer, Grid, IconButton, makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect, useState } from 'react';

type SidePanelProps = {
  /** Header title displayed in the top of the side panel */
  title: string;
  /** Boolean determining if the side panel is opened */
  isOpen: boolean;
  /** Callback to change the state of isOpen (closing the sidepanel) */
  setIsOpen: (state: boolean) => void;
  /** Text to be displayed in the Save button. Default value: "Save" */
  saveText?: string;
  /** Callback function for the Save button. If not defined, no save button is rendered */
  onSave?: () => void;
  /** JSX elements to be rendered as children of the side panel component */
  children: JSX.Element | JSX.Element[];
  /** Sizing of the side panel.
   * Small: 25%
   * Medium: 40%
   * Large: 60%
   * Default value: Medium
   */
  size?: 'small' | 'medium' | 'large';
  /** Optional option to opt out of tracking the sidepanel events */
  noTrack?: boolean;
  trackingAttributes?: BaseplateAnalyticsEventAttributes;
};

export const SidePanel = (props: SidePanelProps) => {
  const {
    isOpen,
    setIsOpen,
    size,
    title,
    children,
    onSave,
    saveText,
    noTrack,
    trackingAttributes,
  } = props;

  const analytics = useAnalytics();

  const [hasBeenOpened, setHasBeenOpened] = useState(false);

  useEffect(() => {
    if (!noTrack) {
      if (isOpen) {
        analytics.captureEvent(`sidepanel open`, title, {
          attributes: trackingAttributes,
        });
        setHasBeenOpened(true);
      }
      if (hasBeenOpened) {
        analytics.captureEvent(`sidepanel closed`, title, {
          attributes: trackingAttributes,
        });
        setHasBeenOpened(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, noTrack]);

  let width: string;

  switch (size) {
    case 'small':
      width = '25%';
      break;
    case 'medium':
      width = '40%';
      break;
    case 'large':
      width = '60%';
      break;
    default:
      width = '40%';
      break;
  }

  const useStyles = makeStyles<BackstageTheme>(theme => ({
    container: {
      backgroundColor: theme.palette.background.paper,
      minHeight: '100%',
      width: width,
      padding: theme.spacing(4),
    },
    header: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
    },
    title: {
      maxWidth: '80%',
    },
    body: {
      height: '80%',
    },
    footer: {
      height: '10%',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
    },
  }));

  const classes = useStyles();

  return (
    <>
      <Drawer
        anchor="right"
        classes={{ paper: classes.container }}
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <Grid className={classes.header}>
          <h1 className={classes.title}>{title}</h1>
          <IconButton onClick={() => setIsOpen(false)}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid className={classes.body}>{children}</Grid>
        <Grid className={classes.footer} container>
          {onSave && (
            <Button variant="contained" color="primary" onClick={onSave}>
              {saveText ?? 'Save'}
            </Button>
          )}
        </Grid>
      </Drawer>
    </>
  );
};
