import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function BoltSlash(props) {
  return (
    <SvgIcon
      viewBox="0 0 41 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="m2.417 4.354 11.812 9.25 10.75-9.187c.313-.25.688-.438 1.125-.438 1.188 0 2.063 1.188 1.75 2.313l-3.375 11.75h5.375c1.188 0 2.188.937 2.188 2.125 0 .687-.313 1.25-.813 1.687l-3.062 2.625 11.25 8.875c.687.5.812 1.438.25 2.063-.5.687-1.438.812-2.063.25l-37-29c-.687-.5-.812-1.438-.25-2.063.5-.687 1.438-.812 2.063-.25Zm14.25 11.125 4.437 3.5 2.75-9.687-7.187 6.187Zm7.062 5.5 2 1.625 1.875-1.625H23.73Zm-2.687 5.625 2.375 1.875-8.375 7.125c-.313.25-.688.375-1.125.375-1.188 0-2.063-1.125-1.75-2.25l3.312-11.562 2.563 2-1.875 6.562 4.875-4.125ZM9.604 17.542l5.625 4.437h-5.125c-1.125 0-2.125-.937-2.125-2.062 0-.625.313-1.25.75-1.625l.875-.75Z" />
    </SvgIcon>
  );
}
