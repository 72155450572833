export type MacBuildCloudNodeConfigRequestBody = {
  nodeId?: string;
  drainMode?: boolean;
};

export enum MacState {
  Offline = 'Offline',
  Active = 'Active',
  Inactive = 'Inactive (Invalid License)',
  Updating = 'Updating',
  Unhealthy = 'Unhealthy',
}

export type Mac = {
  ipAddress: string;
  cpuCount: number;
  capacity: number;
  nodeName: string;
  vmCount: number;
  usbDevices: undefined | number;
  vCpuCount: number;
  nodeId: string;
  cpuUtil: number;
  vRam: number;
  ramUtil: number;
  state: MacState;
  vCpuOverride: number;
  ankaVersion: AnkaVersion;
  freeDiskSpace: number;
  ankaDiskUsage: number;
  diskSize: number;
  ram: number;
  ramOverride: number;
  capacityMode: string;
  images: Array<{
    tag: string;
    name: string;
    uuid: string;
  }>;
};

type AnkaVersion = {
  build: number;
  product: string;
  version: string;
  license: string;
};

export type Job = {
  instanceId: string;
  name: string;
  externalId: string;
  vm?: {
    ankaRegistry: string;
    arch: string;
    ts: string;
    progress: number;
    vmInfo?: {
      ip: string;
      status: string;
      cpuCores: number;
      name: string;
      portForwarding: Array<PortForwarding>;
      ram: string;
      vncPort: number;
      nodeId: string;
      vncConnectionString: string;
      hostIp: string;
      uuid: string;
    };
    tag: string;
    instanceState?: JobState;
    crTime: string;
    vmId: string;
    nodeId: string;
  };
};

type PortForwarding = {
  name: string;
  protocol: string;
  hostPort: number;
  guestPort: number;
};

export type Image = {
  id: string;
  name: string;
  size: number;
};

export type MbcClientConfig = {
  clientId: string;
  clientSecret: string;
  scope: string;
  host: string;
};

export type ValidationResponse = {
  count: number;
  status?: string;
  message?: string;
  expectedCount: number;
  macs: Array<Mac>;
};

export type MacConfigResponse = {
  status: string;
  message: string;
};

export type ChartData = {
  name: string;
  value: number;
};

export enum JobState {
  Scheduling = 'Scheduling',
  Pulling = 'Pulling',
  Started = 'Started',
  Stopping = 'Stopping',
  Stopped = 'Stopped',
  Terminating = 'Terminating',
  Terminated = 'Terminated',
  Error = 'Error',
  Pushing = 'Pushing',
}

export type DataEnvelope<T> = {
  data: T[];
};

export type MockReplyDataSet = {
  macs: DataEnvelope<Mac>;
  jobs: DataEnvelope<Job>;
  images: DataEnvelope<Image>;
  validation: ValidationResponse;
  config: MacConfigResponse;
};

export type ProblemJsonResponse = {
  title: string;
  status: number;
  detail: string;
  type?: string;
  invalidParams?: InvalidParameterError[];
  correlationId: string;
};

export type InvalidParameterError = {
  name: string;
  reason: string;
  value?: string;
};
