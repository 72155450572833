import React from 'react';
import { Entity } from '@backstage/catalog-model';
import { Button } from '@lego/plugin-baseplate-core-components';
import { Grid, Typography } from '@material-ui/core';
import { ANNOTATION_VIEW_URL } from '@backstage/catalog-model';

const EmptyProductRelationsTree = (product: Entity) => {
  return (
    <>
      <Typography variant="h6">This product has no ownership...</Typography>
      <Typography variant="body1"> Documentation</Typography>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid lg={8}>
          <Typography variant="body2">
            Documentation relevant outside of your team should be available on
            Baseplate
          </Typography>
        </Grid>
        <Grid>
          <Button
            variant="secondary"
            size="small"
            href="/templates/docs-select-type"
          >
            {' '}
            Add documentation{' '}
          </Button>
        </Grid>
      </Grid>
      <Typography variant="body1"> Applications</Typography>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid lg={8}>
          <Typography variant="body2">
            Applications are created and connected in LeanIX
          </Typography>
        </Grid>
        <Grid>
          <Button
            href={product.metadata.annotations?.[ANNOTATION_VIEW_URL] ?? '#'}
            variant="secondary"
          >
            Go to Leanix
          </Button>
        </Grid>
      </Grid>
      <Typography variant="body1"> API</Typography>
      <Grid container justifyContent="space-between">
        <Grid lg={8}>
          <Typography variant="body2">
            Do you have functionality other teams could use. Add you API!
          </Typography>
        </Grid>
        <Grid>
          <Button variant="secondary" size="small" href="/register-api">
            {' '}
            Register API{' '}
          </Button>
        </Grid>
      </Grid>
      <Typography variant="body1"> Tools</Typography>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid lg={8}>
          <Typography variant="body2">
            Help you colleagues discover what tools we have available, how to
            use them and how to get access.
          </Typography>
        </Grid>
        <Grid>
          <Button
            variant="secondary"
            size="small"
            href="/create/templates/default/tool-scaffolder"
          >
            {' '}
            Add tool{' '}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default EmptyProductRelationsTree;
