import React from 'react';
import { Dialog, DialogProps, Typography } from '@material-ui/core';
import { Button } from '../button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useDialogStyles } from './DestructiveActionWarningDialog.styles';
import { Callout } from '../callout';

type Props = Omit<DialogProps, 'onClose'> & {
  title: string;
  description?: string;
  buttonText: string;
  error?: string | null;
  success?: string | null;
  children?: React.ReactNode;
  onConfirm: () => void;
  onClose: () => void;
};

export const DestructiveActionWarningDialog = ({
  title,
  description,
  buttonText,
  open,
  onClose,
  onConfirm,
  error,
  success,
  children,
  ...props
}: Props) => {
  const classes = useDialogStyles();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={classes.container}
      {...props}
    >
      <DialogTitle>
        <Typography variant="subtitle1">{title}</Typography>
      </DialogTitle>
      <DialogContent>
        {description && <Typography variant="body1">{description}</Typography>}
        {children}
        {error && (
          <Callout type="error" title="Something went wrong" body={error} />
        )}
        {success && <Callout type="success" title="Success" body={success} />}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button variant="secondary" onClick={onClose}>
          {!!success ? 'Close' : 'Cancel'}
        </Button>
        {!success && (
          <Button variant="destructive" onClick={onConfirm}>
            {buttonText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
