import { baseplateStyles } from '../../utils';

const leftIndent = '1.5rem';

export const useTreeViewStyles = baseplateStyles(theme => {
  const borderLeft = {
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '1px',
      height: '100%',
      backgroundColor: theme.semantic.border.default,
    },
  };
  return {
    container: {
      padding: 0,
      margin: 0,
      '& li': {
        listStyleType: 'none',
      },
    },
    rootLevel: {
      padding: 0,
    },
    level1: {
      position: 'relative',
      marginLeft: leftIndent,
      padding: 0,
      ...borderLeft,
    },
    level2: {
      paddingLeft: `calc(2 * ${leftIndent})`,
    },
    categoryLabel: {
      position: 'relative',
      paddingLeft: leftIndent,
      paddingTop: '0.5rem',
      marginLeft: leftIndent,
      ...borderLeft,
    },
    item: {
      listStyleType: 'none',
      position: 'relative',
      paddingTop: '0.5rem',
      marginLeft: leftIndent,
      // Vertical line
      '&::after': {
        content: '""',
        position: 'absolute',
        top: 0,
        height: '100%',
        left: `-${leftIndent}`,
        width: '1px',
        backgroundColor: theme.semantic.border.default,
      },
      // Horizontal line
      '&::before': {
        content: '""',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        left: `-${leftIndent}`,
        height: '1px',
        width: leftIndent,
        backgroundColor: theme.semantic.border.default,
      },
      '&$last::after': {
        height: '50%',
      },
    },
    last: {
      '&::after': {
        height: '50%',
      },
    },
  };
});
