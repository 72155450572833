import {
  Entity,
  RELATION_OWNED_BY,
  parseEntityRef,
  stringifyEntityRef,
} from '@backstage/catalog-model';
import {
  getEntityRelations,
  useEntityList,
} from '@backstage/plugin-catalog-react';
import {
  Spinner,
  Table,
  TableRowType,
} from '@lego/plugin-baseplate-core-components';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ManageDocumentationLinkDeletionDialog } from './ManageDocumentationLinkDeletionDialog';

type DocumentationTableData = {
  entityRef: string;
};

const isLinkEntity = (entity: Entity) => {
  return entity.spec?.type === 'external-link';
};

export const ManageDocumentationTable = () => {
  const navigate = useNavigate();
  const { entities, loading } = useEntityList();
  const [selectedEntityRef, setSelectedEntityRef] = React.useState<string>('');
  const [deleteDialogOpen, setDeleteDialogOpen] =
    React.useState<boolean>(false);

  const [ownedEntitiesData, setOwnedEntitiesData] = React.useState<
    TableRowType<DocumentationTableData>[]
  >([]);

  useEffect(() => {
    if (loading) {
      return;
    }
    const newOwnedEntitiesData: TableRowType<DocumentationTableData>[] =
      entities.map(entity => {
        const entityRef = stringifyEntityRef(entity);
        const ownedByRelations = getEntityRelations(entity, RELATION_OWNED_BY);
        const emptyOwner = {
          kind: '',
          namespace: '',
          name: '',
        };
        const owner =
          ownedByRelations && ownedByRelations.length > 0
            ? stringifyEntityRef(ownedByRelations[0])
            : stringifyEntityRef(emptyOwner);

        return {
          id: entityRef,
          columns: {
            entity: {
              type: 'entity',
              value: entityRef,
            },
            owner: {
              type: 'entity',
              value: owner,
            },
          },
          disableMenuAction: !isLinkEntity(entity),
          data: { entityRef },
        };
      });

    setOwnedEntitiesData(newOwnedEntitiesData);
  }, [entities, loading]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <Table<DocumentationTableData>
        title="Your documentation"
        headers={[
          {
            key: 'entity',
            label: 'Name',
          },
          {
            key: 'owner',
            label: "What's being documented",
          },
        ]}
        rowMenuActions={[
          {
            label: 'Edit documentation link',
            icon: 'pen',
            onClick: row => {
              if (row.data) {
                const { name } = parseEntityRef(row.data.entityRef);
                navigate(`/docs/manage/${name}`);
              }
            },
          },
          {
            label: 'Delete documentation link',
            icon: 'trash',
            onClick: row => {
              if (row.data) {
                setSelectedEntityRef(row.data.entityRef);
                setDeleteDialogOpen(true);
              }
            },
          },
        ]}
        data={ownedEntitiesData}
      />
      <ManageDocumentationLinkDeletionDialog
        selectedEntityRef={selectedEntityRef}
        deleteDialogOpen={deleteDialogOpen}
        setDeleteDialogOpen={setDeleteDialogOpen}
      />
    </>
  );
};
