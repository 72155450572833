import { useEnvironmentStage } from '@lego/plugin-baseplate-core-components';
import React from 'react';
import { DevLogo } from './DevLogo';
import LogoFull from '../Root/LogoFull';
import { Link } from '@backstage/core-components';
import { useNavigationStyles } from './Navigation.styles';

type Props = {
  label: string;
};

export function NavigationLogo({ label }: Props) {
  const environmentStages = useEnvironmentStage();
  const classes = useNavigationStyles();

  const showDevLogo =
    environmentStages.isDevEnvironment ||
    environmentStages.isTestEnvironment ||
    environmentStages.isLocalEnvironment;

  return (
    <Link underline="none" to="/" className={classes.logo}>
      {showDevLogo ? <DevLogo /> : <LogoFull />}
      <span>{label}</span>
    </Link>
  );
}
