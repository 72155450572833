import { Typography } from '@material-ui/core';
import React from 'react';
import { useSideMenuStyles } from './SideMenu.styles';

interface Props {
  /**
   * The label of the item
   */
  label: string;
  className?: string;
}

export const SideMenuSection = ({ label, className }: Props) => {
  const classes = useSideMenuStyles();
  return (
    <li className={[classes.section, className].filter(Boolean).join(' ')}>
      <Typography variant="overline" color="secondary">
        {label}
      </Typography>
    </li>
  );
};
