import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ArrowRightFromArc(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M7 20c0 7.188 5.813 13 13 13 .813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5-8.875 0-16-7.125-16-16 0-8.813 7.125-16 16-16 .813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5-7.188 0-13 5.875-13 13Zm21.5-7.563 7 6.5c.313.25.5.688.5 1.063 0 .438-.188.875-.5 1.125l-7 6.5c-.625.563-1.563.5-2.125-.063a1.549 1.549 0 0 1 .063-2.125l4.187-3.937H15.5c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5h15.125l-4.188-3.875c-.625-.563-.625-1.5-.062-2.125s1.5-.625 2.125-.063Z" />
    </SvgIcon>
  );
}
