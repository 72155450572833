import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Loader(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M21.5 5.5v5c0 .875-.688 1.5-1.5 1.5-.875 0-1.5-.625-1.5-1.5v-5c0-.813.625-1.5 1.5-1.5.813 0 1.5.688 1.5 1.5Zm0 24v5c0 .875-.688 1.5-1.5 1.5-.875 0-1.5-.625-1.5-1.5v-5c0-.813.625-1.5 1.5-1.5.813 0 1.5.688 1.5 1.5ZM4 20c0-.813.625-1.5 1.5-1.5h5c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5h-5c-.875 0-1.5-.625-1.5-1.5Zm25.5-1.5h5c.813 0 1.5.688 1.5 1.5 0 .875-.688 1.5-1.5 1.5h-5c-.875 0-1.5-.625-1.5-1.5 0-.813.625-1.5 1.5-1.5Zm1.813-9.813c.562.626.562 1.563 0 2.126l-3.563 3.562c-.563.563-1.563.563-2.125 0-.563-.563-.563-1.563 0-2.125l3.563-3.563c.562-.562 1.5-.562 2.125 0Zm-17 19.126-3.563 3.5a1.37 1.37 0 0 1-2.063 0 1.369 1.369 0 0 1 0-2.063l3.5-3.563c.563-.562 1.563-.562 2.126 0 .562.563.562 1.5 0 2.125ZM8.686 8.688a1.471 1.471 0 0 1 2.063 0l3.563 3.562c.562.563.562 1.563 0 2.125-.563.563-1.5.563-2.126 0l-3.5-3.563c-.624-.562-.624-1.5 0-2.124Zm19.063 17 3.563 3.562c.562.563.562 1.5 0 2.063-.625.625-1.563.625-2.125 0l-3.563-3.5c-.563-.563-.563-1.563 0-2.125.563-.563 1.563-.563 2.125 0Z" />
    </SvgIcon>
  );
}
