import { Chip } from '@lego/plugin-baseplate-core-components';
import { Product } from '@lego/plugin-baseplate-people-to-product-common';
import { Tooltip, Typography } from '@material-ui/core';
import React from 'react';

interface TooltipChipProps {
  products: Product[];
}

function TooltipChip({ products }: TooltipChipProps) {
  return (
    <Tooltip
      title={
        <>
          {products.map(product => (
            <Typography key={product.id}>{product.name}</Typography>
          ))}
        </>
      }
    >
      <Chip label={`+${products.length}`} />
    </Tooltip>
  );
}

export default TooltipChip;
