import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function BatteryExclamation(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M15 10v3H7c-1.125 0-2 .938-2 2v10c0 1.125.875 2 2 2h7.063c-.063.375-.063.688-.063 1 0 .75.125 1.438.375 2H7c-2.813 0-5-2.188-5-5V15c0-2.75 2.188-5 5-5h8Zm16 20h-7.438c.25-.563.438-1.25.438-2 0-.313-.063-.625-.125-1H31c1.063 0 2-.875 2-2V15c0-1.063-.938-2-2-2h-8v-3h8c2.75 0 5 2.25 5 5v1c1.063 0 2 .938 2 2v4c0 1.125-.938 2-2 2v1c0 2.813-2.25 5-5 5ZM19 10c.813 0 1.5.688 1.5 1.5v11c0 .875-.688 1.5-1.5 1.5-.875 0-1.5-.625-1.5-1.5v-11c0-.813.625-1.5 1.5-1.5Zm-2 18c0-1.063.875-2 2-2 1.063 0 2 .938 2 2 0 1.125-.938 2-2 2-1.125 0-2-.875-2-2Z" />
    </SvgIcon>
  );
}
