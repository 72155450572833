import { makeStyles } from '@material-ui/core';

export const useLoaderStyles = makeStyles<any>(_theme => ({
  spinner: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    height: 'calc(50vh)',
    zIndex: 999,

    '& > div': {
      margin: 'auto',
    },
  },

  backdrop: {
    zIndex: 99999,
    backgroundColor: '#00000027',

    spinner: {
      position: 'absolute',
    },
  },
}));
