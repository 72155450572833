import {
  EntityKindFilter,
  useEntityList,
} from '@backstage/plugin-catalog-react';
import { EntityLink, Spinner } from '@lego/plugin-baseplate-core-components';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';
import { createDomainArray } from './utils';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'grid',
    width: '100%',
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
    gap: '1rem',
    '& a': {
      display: 'block',
    },
  },
  content: {
    margin: `${theme.spacing(2)}px 0`,
  },
  title: {
    fontSize: '19px',
    fontStyle: 'normal',
    fontWeight: 500,
  },
  domainTitle: {
    marginTop: '3rem',
  },
  subDomainTitle: {
    marginTop: '1rem',
    padding: '12px 0px 12px 0px', // TODO: USE SPACING WHEN WE CAN CUSTOMIZE THE THEME
  },
}));

export const ProductsEntitiesList = () => {
  const classes = useStyles();
  const { entities, updateFilters, loading } = useEntityList();

  useEffect(() => {
    updateFilters({
      kind: new EntityKindFilter('product'),
    });
  }, [updateFilters]);

  const domains = createDomainArray(entities);

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      {domains.map(({ id, name, subDomains }) => (
        <Grid key={id} className={classes.content}>
          <Typography variant="h3" className={classes.domainTitle}>
            {name}
          </Typography>
          {subDomains.map(subDomain => (
            <Grid key={subDomain.id}>
              <Typography
                variant="subtitle2"
                className={classes.subDomainTitle}
              >
                {subDomain.name}
              </Typography>
              <Grid className={classes.container}>
                {subDomain.products.map(product => (
                  <EntityLink
                    showFavoriteButton
                    variant="thumbnail"
                    entity={product}
                    key={product.metadata.name}
                  />
                ))}
              </Grid>
            </Grid>
          ))}
        </Grid>
      ))}
    </>
  );
};
