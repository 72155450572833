import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Wifi(props) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path d="M2.464 15.61c-.625.562-1.563.5-2.063-.126a1.493 1.493 0 0 1 .125-2.125c5.188-4.562 12-7.375 19.5-7.375 7.438 0 14.25 2.813 19.438 7.375.625.563.687 1.5.187 2.125-.562.625-1.5.688-2.125.125-4.687-4.125-10.812-6.625-17.5-6.625a26.45 26.45 0 0 0-17.562 6.625Zm17.562 4.374a15.34 15.34 0 0 0-10.5 4.125c-.625.563-1.562.5-2.125-.125a1.549 1.549 0 0 1 .063-2.125c3.312-3 7.687-4.875 12.562-4.875 4.813 0 9.188 1.875 12.5 4.875.563.563.625 1.5.063 2.125-.563.625-1.5.688-2.125.125a15.3 15.3 0 0 0-10.438-4.125Zm3.5 10.5a3.518 3.518 0 0 1-1.75 3.063c-1.125.625-2.437.625-3.5 0-1.125-.625-1.75-1.75-1.75-3.063 0-1.25.625-2.375 1.75-3 1.063-.625 2.375-.625 3.5 0 1.063.625 1.75 1.75 1.75 3Z" />
    </SvgIcon>
  );
}
