import {
  createRoutableExtension,
  createRouteRef,
} from '@backstage/core-plugin-api';
import { userSettingsPlugin } from '@backstage/plugin-user-settings';

export const settingsRouteRef = createRouteRef({
  id: userSettingsPlugin.getId(),
});

export const UserSettingsPage = userSettingsPlugin.provide(
  createRoutableExtension({
    name: 'UserSettingsPage',
    component: () => import('./SettingsPage').then(m => m.SettingsPage),
    mountPoint: settingsRouteRef,
  }),
);
