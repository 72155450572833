import React from 'react';
import { ApiEntity } from '@backstage/catalog-model';
import { type ReactElement } from 'react';
import { ApiMetricsPage } from '@lego/plugin-baseplate-api-metrics';
import { EspMetricsPage } from '@lego/backstage-plugin-baseplate-esp-status';

export interface MetricsTabProps {
  type: ApiEntity['spec']['type'];
}

export function MetricsTab({ type }: MetricsTabProps): ReactElement {
  if (['openapi', 'graphql'].includes(type)) {
    return <ApiMetricsPage />;
  } else {
    return <EspMetricsPage />;
  }
}
