import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

export default function LocationCheck(props: any) {
  return (
    <SvgIcon
      viewBox="0 0 40 40"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1425 7.6248C13.1425 4.64355 10.7237 2.2248 7.74248 2.2248C4.76123 2.2248 2.34248 4.64355 2.34248 7.6248C2.34248 8.0898 2.51123 8.8098 2.91623 9.7698C3.30998 10.6998 3.86873 11.7273 4.50998 12.7661C5.57873 14.4986 6.81623 16.1711 7.74248 17.3636C8.27227 16.6842 8.90186 15.8491 9.54249 14.9359C9.54415 15.7739 9.7332 16.5679 10.07 17.2782C9.52598 18.0105 9.02658 18.65 8.63123 19.1448C8.16998 19.7186 7.31498 19.7186 6.85373 19.1448C4.92998 16.7373 0.54248 10.9023 0.54248 7.6248C0.54248 3.6498 3.76748 0.424805 7.74248 0.424805C11.7175 0.424805 14.9425 3.6498 14.9425 7.6248C14.9425 8.16899 14.8215 8.78368 14.611 9.44148C13.827 9.50233 13.0891 9.72755 12.4317 10.0827C12.4794 9.9773 12.5251 9.87295 12.5687 9.7698C12.9737 8.8098 13.1425 8.0898 13.1425 7.6248ZM8.94248 7.6248C8.94248 7.30654 8.81605 7.00132 8.59101 6.77628C8.36596 6.55123 8.06074 6.4248 7.74248 6.4248C7.42422 6.4248 7.119 6.55123 6.89395 6.77628C6.66891 7.00132 6.54248 7.30654 6.54248 7.6248C6.54248 7.94306 6.66891 8.24829 6.89395 8.47333C7.119 8.69838 7.42422 8.8248 7.74248 8.8248C8.06074 8.8248 8.36596 8.69838 8.59101 8.47333C8.81605 8.24829 8.94248 7.94306 8.94248 7.6248ZM4.74248 7.6248C4.74248 6.82915 5.05855 6.06609 5.62116 5.50348C6.18377 4.94088 6.94683 4.6248 7.74248 4.6248C8.53813 4.6248 9.30119 4.94088 9.8638 5.50348C10.4264 6.06609 10.7425 6.82915 10.7425 7.6248C10.7425 8.42045 10.4264 9.18352 9.8638 9.74612C9.30119 10.3087 8.53813 10.6248 7.74248 10.6248C6.94683 10.6248 6.18377 10.3087 5.62116 9.74612C5.05855 9.18352 4.74248 8.42045 4.74248 7.6248ZM23.1823 10.0321C23.5495 10.3953 23.5495 10.9891 23.1823 11.3563L16.9323 17.6024C16.569 17.9696 15.9752 17.9696 15.6081 17.6024L12.4831 14.4774C12.1159 14.1141 12.1198 13.5203 12.4831 13.1531C12.8463 12.786 13.4401 12.786 13.8073 13.1531L16.2721 15.618L21.8581 10.0321C22.2213 9.66487 22.8151 9.66877 23.1823 10.0321Z"
      />
    </SvgIcon>
  );
}
