import { createRouteRef, createSubRouteRef } from '@backstage/core-plugin-api';

export const paths = {
  mentors: '/mentors',
  profile: '/profile-overview/:id',
  updateProfile: '/profile-update',
  mentor: '/mentors/:id',
  lnd: '/learning-and-development',
  events: '/events',
  myEvent: '/my-events/:id',
  event: '/events/:id',
  myEvents: '/my-events',
  manageEvents: '/manage-events/:id',
  manageAttendees: '/manage-attendees/:id',
  communities: '/communities',
};

export const rootRouteRef = createRouteRef({
  id: 'learning-and-development',
});

export const subRoutesRef = {
  communitiesRouteRef: createSubRouteRef({
    id: 'communities',
    path: paths.communities,
    parent: rootRouteRef,
  }),
  mentorRouteRef: createSubRouteRef({
    id: 'mentor',
    path: paths.mentor,
    parent: rootRouteRef,
  }),
  mentorsRouteRef: createSubRouteRef({
    id: 'mentors',
    path: paths.mentors,
    parent: rootRouteRef,
  }),
  profileRouteRef: createSubRouteRef({
    id: 'profile',
    path: paths.profile,
    parent: rootRouteRef,
  }),
  updateProfileRouteRef: createSubRouteRef({
    id: 'update-profile',
    path: paths.updateProfile,
    parent: rootRouteRef,
  }),
  eventsRouteRef: createSubRouteRef({
    id: 'events',
    path: paths.events,
    parent: rootRouteRef,
  }),
  manageEventRouteRef: createSubRouteRef({
    id: 'manage-events',
    path: paths.manageEvents,
    parent: rootRouteRef,
  }),
  eventRouteRef: createSubRouteRef({
    id: 'events',
    path: paths.event,
    parent: rootRouteRef,
  }),
  myEventRouteRef: createSubRouteRef({
    id: 'my-events',
    path: paths.myEvent,
    parent: rootRouteRef,
  }),
  myEventsRouteRef: createSubRouteRef({
    id: 'my-events',
    path: paths.myEvents,
    parent: rootRouteRef,
  }),
  manageAttendeesRouteRef: createSubRouteRef({
    id: 'manage-attendees',
    path: paths.manageAttendees,
    parent: rootRouteRef,
  }),
};
