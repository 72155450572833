import {
  EXTERNAL_JOB_CODE,
  Employee,
  EmployeeProductRoleRecord,
  OpenPosition,
  OpenPositionProductRecord,
} from '@lego/plugin-baseplate-people-to-product-common';

export const filterEmployeesWithoutProducts = (employee: Employee): boolean =>
  !employee.products ||
  (employee.products.length === 0 &&
    (!employee.generalOverheadProducts ||
      employee.generalOverheadProducts.length === 0));

export const filterEmployeesWithSpecificProducts = (
  employee: Employee,
  products: string[],
): boolean =>
  employee.products.some(p =>
    products.includes(p.node.id.toLocaleLowerCase()),
  ) ||
  employee.generalOverheadProducts.some(p =>
    products.includes(p.node.id.toLocaleLowerCase()),
  );

export const filterEmployeesWithProducts = (employee: Employee): boolean =>
  (employee.products && employee.products.length > 0) ||
  (employee.generalOverheadProducts &&
    employee.generalOverheadProducts.length > 0);

export const filterExternalEmployees = (employee: Employee): boolean =>
  employee.jobCode === EXTERNAL_JOB_CODE;

export const filterInternalEmployees = (employee: Employee): boolean =>
  employee.jobCode !== EXTERNAL_JOB_CODE;

export const filterOpenPositionsWithoutProducts = (
  openPosition: OpenPosition,
): boolean =>
  !openPosition.product ||
  (!openPosition.product && !openPosition.generalOverheadProduct);

export const filterOpenPositionsWithProducts = (
  openPosition: OpenPosition,
): boolean =>
  openPosition.product !== null || openPosition.generalOverheadProduct !== null;

export const filterExternalOpenPositions = (
  openPosition: OpenPosition,
): boolean => openPosition.jobCode === EXTERNAL_JOB_CODE;

export const filterInternalOpenPositions = (
  openPosition: OpenPosition,
): boolean => openPosition.jobCode !== EXTERNAL_JOB_CODE;

export const filterInternalEmployeeRecords = (
  employee: EmployeeProductRoleRecord,
): boolean => employee.jobCode !== EXTERNAL_JOB_CODE;

export const filterInternalOpenPositionRecords = (
  openPosition: OpenPositionProductRecord,
): boolean => openPosition.jobCode !== EXTERNAL_JOB_CODE;
